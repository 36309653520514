import { Spin } from "antd";
import React from "react";

function TableLoadingRender(): React.ReactChild {
    return (
        <div className="table-loading">
            <Spin />
        </div>
    );
}

export default TableLoadingRender;
