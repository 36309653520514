import { Button, Switch } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { HiMenu } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { Product } from "../../../../slices/products";
import { getDefaultImage, getImage } from "../../../../util/helper";

interface AssignItemsProps {
    category_id?: number;
    name?: string;
    onSave?: any;
    onCancel?: any;
    selectedProducts?: Product[];
}

const ArrangeItemsModal = (props: AssignItemsProps): JSX.Element => {
    const intl = useIntl();
    const [showImages, setShowImages] = useState(true);
    const [selected, setSelected] = useState<Product[]>([]);
    const {
        lan,
        selectedProducts: sp,
        type,
    } = useSelector((state: RootState) => ({
        products: state?.products?.products,
        selectedProducts: state?.categories?.products,
        type: state?.store?.storeInformation?.store_categories,
        lan: state?.setting?.lan,
    }));

    const selectedProducts = props.selectedProducts ?? sp;

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        backgroundColor: isDragging ? "lightgreen" : "white",
        ...draggableStyle,
    });

    const getListStyle = () => ({
        backgroundColor: "white",
    });

    const onDragEnd = (result: DropResult) => {
        setSelected((prev) => {
            const productsArr = _.cloneDeep(prev);
            const [removed] = productsArr.splice(result.source.index, 1);
            productsArr.splice(Number(result.destination?.index), 0, removed);
            return productsArr;
        });
    };

    const renderProduct = (product: Product, i: number) => (
        <Draggable key={product.product_id} draggableId={product.product_id.toString()} index={i}>
            {(provided, snapshot) => (
                <div
                    className="arrange-product-item"
                    key={product.product_id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <div className="arrange-drag-indicator">
                        <HiMenu />
                    </div>
                    {showImages ? (
                        <div className="arrange-image">
                            <div>
                                <img
                                    alt=""
                                    src={product?.img ? getImage(product?.img) : getDefaultImage(type)}
                                    onError={(e: any) => {
                                        e.target.onError = null;
                                        e.target.src = getDefaultImage(type);
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="arrange-product-name">{product.name[lan ?? ""]}</div>
                </div>
            )}
        </Draggable>
    );

    const renderProductsList = () => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} style={getListStyle()}>
                            {selected.filter((p) => p.name?.en?.toLowerCase?.()?.includes?.("")).map(renderProduct)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    useEffect(() => {
        setSelected(selectedProducts);
    }, [selectedProducts]);

    return (
        <div>
            <div className="assign-description">
                <FormattedMessage id="drag_drop_products" />
            </div>
            <div className="assign-list">{renderProductsList()}</div>
            <div className="assign-items-actions">
                <div className="assign-items-switch">
                    <FormattedMessage id="show_images" />
                    <Switch checked={showImages} onClick={() => setShowImages(!showImages)} />
                </div>
                <div className="assign-items-buttons">
                    <Button size="large" onClick={props.onCancel}>
                        {intl.formatMessage({ id: "cancel" })}
                    </Button>
                    <Button size="large" type="primary" onClick={() => props.onSave(selected)}>
                        {intl.formatMessage({ id: "confirm" })}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ArrangeItemsModal;
