import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { getStoreAvailabilitySuccess, setAddingCode, cancelAddCode } from "../slices/store-availability";
import { getIntl } from "../locale";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";

export const getStoreAvailability = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    try {
        const response: any = await oauth("STORE_AVAILABILITY")({
            method: "GET",
        });
        switch (response.RC) {
            case 200:
                dispatch(getStoreAvailabilitySuccess(response.records));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const updateStoreAvailability =
    (params?: Record<string, any>) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("STORE_AVAILABILITY")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getStoreAvailability());
                    dispatch(setAddingCode(0));
                    toast(createMessage(intl.formatMessage({ id: "store_information_saved" }), HiInformationCircle));
                    break;
                case 400:
                    dispatch(cancelAddCode());
                    Modal.error({
                        title: intl.formatMessage({ id: "code_already_exists" }),
                        content: intl.formatMessage({ id: "use_a_new_code" }),
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
