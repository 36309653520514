import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import SearchProducts from "./search-products";
import OrderItems from "./order-items";
import "./order-detail.scss";
import SearchDialog from "./search-dialog";
import OrderPrices from "./order-prices";
import StoreDiscountDialog from "./store-discount-dialog";
import { setState } from "slices/create-order";
import _ from "lodash";
import { Button } from "antd";
import QuickAddModal from "./quick-add-modal";
import { MdArrowBack } from "react-icons/md";
import moment from "moment";
import helper from "util/helper";
import history from "app/history";

class OrderDetail extends Component<any, any> {
    state = {};

    componentDidMount = () => {
        if (_.isEmpty(this.props.items)) {
            this.props.setState({ quickAddModal: this.props.currUser?.is_use_pos_mode });
        }
    };

    /**
     * Get the formatted draft order created date time.
     * if the year is same with the current year, omit the year
     *
     * @param dateTime - date and time
     * @returns formatted date and time
     */
    getFormattedCreateAtDateTime = (dateTime: any) => {
        const DATE_TIME_FORMAT_WITHOUT_YEAR = "MMM Do @LT";
        const DATE_TIME_FORMAT_WITH_YEAR = "MMM Do, YYYY @LT";
        let formattedDateTime = helper.utcToLocal(dateTime, { type: "moment_format" });
        if (moment(formattedDateTime).year() === new Date().getFullYear()) {
            formattedDateTime = moment(formattedDateTime).format(DATE_TIME_FORMAT_WITHOUT_YEAR).toString();
        } else {
            formattedDateTime = moment(formattedDateTime).format(DATE_TIME_FORMAT_WITH_YEAR).toString();
        }
        return formattedDateTime;
    };

    renderSaveButton = () => {
        return (
            <Button
                onClick={() => {
                    this.props.setState({
                        saveButtonClicked: true,
                        isSaveButtonEnabled: false,
                    });
                }}
                disabled={
                    _.isEmpty(this.props?.items) || !this.props.state?.qid || !this.props.state?.isSaveButtonEnabled
                }
                type="link"
            >
                <FormattedMessage id="save" />
            </Button>
        );
    };

    renderQuoteInfo = () => {
        // render qid and created date time
        return this.props.state?.qid ? (
            <div className="section-titile-order-quote-info">
                {`#${this.props.state?.qid}`}
                <br />
                {`Draft order created: ${this.getFormattedCreateAtDateTime(this.props.state?.draftOrderCreatedAt)}`}
            </div>
        ) : null;
    };

    renderSectionTitle = () => {
        return (
            <div className="section-title">
                <div style={{ alignItems: "center", display: "flex" }}>
                    <Button type="link" onClick={() => helper.goBackPreviousPage(history)}>
                        <MdArrowBack size="20" />
                    </Button>
                    <FormattedMessage id="order_detail" />
                    {this.renderQuoteInfo()}
                </div>

                <div>
                    <Button
                        onClick={() =>
                            this.props.setState({
                                quickAddModalSearchInput: "",
                                quickAddModal: true,
                            })
                        }
                        type="link"
                    >
                        <FormattedMessage id="pos_mode" />
                    </Button>
                    {this.renderSaveButton()}
                </div>
            </div>
        );
    };

    getShareProps = () => ({
        parentState: this.props.state,
        setParentState: (values: any) => this.props.setState(values),
    });

    render() {
        return (
            <div style={{ paddingBottom: 0, marginBottom: 0 }} className="order-detail-container">
                {this.renderSectionTitle()}
                <SearchProducts {...this.getShareProps()} />
                <OrderItems {...this.getShareProps()} />
                <OrderPrices />
                {!this.props.state.quickAddModal ? <SearchDialog {...this.getShareProps()} /> : null}
                <StoreDiscountDialog />
                {this.props.state.quickAddModal ? <QuickAddModal /> : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    payment: _.get(state, "createOrder.payment", {}),
    items: _.get(state, "createOrder.items", []),
    customer: _.get(state, "customers.customer", {}),
    currUser: _.get(state, "user", {}),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetail)));
