import { Pagination } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import { getGiftcardAccounts } from "services/giftcards";
import { setGiftcardState } from "slices/giftcards";

const selector = (state: RootState) => ({
    total: state?.giftcards?.accountsPaging?.total || 0,
    lan: state?.setting?.lan,
    currentId: state?.giftcards?.currentId,
    title: state?.giftcards?.title,
    pageSize: state?.giftcards?.accountsPageSize,
    isHideZeroBalance: state?.giftcards?.isHideZeroBalance,
});

function GiftCardPagination(): JSX.Element {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const { total, title, currentId, pageSize, isHideZeroBalance } = useSelector(selector);

    const paginationProps: PaginationProps = {
        total,
        current: page,
        pageSize: pageSize,
        onChange: (page, pageSize) => {
            setPage(page);
            dispatch(setGiftcardState({ accountsPageSize: pageSize }));
            dispatch(
                getGiftcardAccounts({
                    page,
                    product_id: currentId,
                    product_name: title,
                    limit: pageSize,
                    is_hide_zero_balance: isHideZeroBalance,
                })
            );
        },
    };

    return (
        <div className="gift-card-account-pagination">
            <Pagination {...paginationProps} />
        </div>
    );
}

export default GiftCardPagination;
