function emptyFunction() {
    // does nothing
}

export const selectWithPlaceId = async (placeId: Record<string,any> | any, callBack: any = emptyFunction) : Promise<any> => {
    try {
        const request = {
            placeId,
            language: 'en'
        };

        new Promise((resolve, reject) => {
            new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                if (String(status) === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject()
                }
            });
        }).then(callBack)
    } catch (e) {
        // Handle error
    }
}