import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Select } from "antd";
import config from "../../../../config";
import { getKeyByValue, isEtransferAllowed } from "../../../../util/helper";
import { setState } from "../../../../slices/create-order";
import GiftCardSection from "../gift-card-section";
import PointsSection from "../points-section";

const PAYMENT_OPTIONS = [config.PAYMENT_MAPPING_TO_NUMBERIC.pay_later, config.PAYMENT_MAPPING_TO_NUMBERIC.cash];

class PaymentMethod extends Component<any, any> {
    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id: id || " " }, values);

    renderTitle = () => {
        return (
            <div className="section-title">
                <div>
                    <FormattedMessage id={"payment_method"} />
                </div>
            </div>
        );
    };

    getPaymentOptions = () => {
        const paymentOptions = _.cloneDeep(PAYMENT_OPTIONS);
        if (isEtransferAllowed(this.props.store)) {
            paymentOptions.push(config.PAYMENT_MAPPING_TO_NUMBERIC.etransfer);
        }
        return paymentOptions;
    };

    getOptions = () => {
        const paymentOptions = this.getPaymentOptions().map((key) => ({
            label: this.str(getKeyByValue(config.PAYMENT_MAPPING_TO_NUMBERIC, key)),
            value: key,
        }));
        return paymentOptions;
    };

    renderOtherPayment = () => {
        return this.str(getKeyByValue(config.PAYMENT_MAPPING_TO_NUMBERIC, this.props.paymentMethod));
    };

    renderInput = () => {
        return this.getPaymentOptions().includes(this.props.paymentMethod) || !this.props.paymentMethod ? (
            <Select
                id="create-order-payment-method-select"
                showAction={["click", "focus"]}
                style={{ width: 250 }}
                size="large"
                onChange={(v) => this.props.setState({ paymentMethod: v })}
                value={this.props.paymentMethod}
                options={this.getOptions()}
            />
        ) : (
            this.renderOtherPayment()
        );
    };
    render() {
        return (
            <div className="section">
                <div className="createOrder-list-item-container">
                    <div className="createOrder-list-item-section-one">
                        <FormattedMessage id={"payment_method"} />
                    </div>
                    <div className="createOrder-list-item-section-two"> {this.renderInput()} </div>
                </div>
                <GiftCardSection />
                <PointsSection />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    paymentMethod: _.get(state, "createOrder.paymentMethod", ""),
    store: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethod)));
