import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";

interface ReferralsModalValueProps {
    renderValue: any;
    value: any;
}

function ReferralsModalValueRenderer(props: ReferralsModalValueProps): JSX.Element | null {
    const records = useSelector((state: RootState) => state?.referrals?.customerReferrals);
    const { renderValue } = props;

    const record = records?.find((r: any) => r?._id === props?.value);

    return record ? <div>{renderValue(record)}</div> : null;
}

export default ReferralsModalValueRenderer;
