import React from "react";
import { Upload, message, Modal, Image } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import config from "../../config";
import { runImageCacheHelper } from "../../services/image-cache";
import { injectIntl } from "react-intl";
import { HiX } from "react-icons/hi";

function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file: any, imageExt: any = "") {
    let allowedType = `JPG/PNG`;
    let isAllowed = file.type === "image/jpeg" || file.type === "image/png";

    if (imageExt && !Array.isArray(imageExt)) {
        isAllowed = file.type === `image/${imageExt}`;
        allowedType = imageExt;
    }

    if (imageExt && Array.isArray(imageExt)) {
        isAllowed = imageExt.map((ext) => `image/${ext}`).includes(file.type);
        allowedType = imageExt.join("/");
    }

    if (!isAllowed) {
        message.error(`You can only upload ${allowedType} file!`);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isAllowed && isLt2M;
}

const getImage = (url: string) => {
    return config.IMG_PREFIX + url;
};

class ImageUpload extends React.Component<any> {
    state = {
        imageUrl: "",
        loading: false,
        uid: "",
        ext: "",
        fileList: [],
        file: {},
    };

    componentDidMount = () => {
        this.initImageUpload();
    };

    initImageUpload = () => {
        this.setState({
            imageUrl: this.props.currentImage ? getImage(this.props.currentImage) : "",
            fileList: this.props.currentImage
                ? [
                      {
                          uid: this.props.currentImage,
                          name: this.props.currentImage,
                          status: "done",
                          url: getImage(this.props.currentImage),
                      },
                  ]
                : [],
        });
    };

    handleChange = (info: any) => {
        const { fileList } = info;
        const newState = info?.file?.originFileObj instanceof File ? { fileList, file: info?.file } : {};
        if (info?.file?.status === "uploading") {
            getBase64(info?.file?.originFileObj, (imageUrl: any) =>
                this.setState({
                    ...newState,
                    imageUrl,
                    loading: false,
                })
            );
        } else if (info?.file?.status === "done") {
            // Get this url from response in real world.
            getBase64(this.state.file, (imageUrl: any) =>
                this.setState({
                    ...newState,
                    imageUrl,
                    loading: false,
                })
            );
        }
    };

    uploadToImageCache = async ({ file, onSuccess }: any) => {
        await runImageCacheHelper({
            action: "add_image_cache",
            type: this.props.type,
            imageType: this.props.imageType ?? "",
            user_id: this.props.user.id,
            name: file.uid,
            "fileToUpload[]": file,
        });
        if (this.props.uploadCallback) {
            this.props.uploadCallback(this.props.imageType, file.name);
        }
        onSuccess("ok");
    };

    handleRemove = (file: any) => {
        Modal.confirm({
            title: this.props.intl?.formatMessage({ id: "confirm_delete" }),
            content: this.props.intl?.formatMessage({ id: "are_you_sure_delete_image" }),
            onOk: async () => {
                const { fileList } = this.state;
                await runImageCacheHelper({
                    action: "delete_image_cache",
                    type: this.props.type,
                    imageType: this.props.imageType ?? "",
                    user_id: this.props.user.id,
                    image_file: file.uid + ".jpeg",
                });

                if (this.props.deleteCallback) {
                    this.props.deleteCallback(this.props.imageType, file.name);
                }

                this.setState({ imageUrl: "", fileList: fileList.filter((item: any) => item?.uid !== file?.uid) });
            },
        });
    };

    uploadedImage = () => {
        const { imageUrl, fileList } = this.state;

        return (
            <div className="item-image-container">
                <div className="item-image-delete" onClick={() => this.handleRemove(fileList?.[0])}>
                    <HiX />
                </div>
                <Image src={imageUrl} />
            </div>
        );
    };

    render() {
        const { loading, imageUrl, fileList } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div style={{ marginLeft: 14 }} className={this.props.className ?? ""}>
                {!imageUrl ? (
                    <Upload.Dragger
                        customRequest={this.uploadToImageCache}
                        beforeUpload={(file) => beforeUpload(file, this.props.imageExt)}
                        onChange={this.handleChange}
                        onRemove={this.handleRemove}
                        showUploadList={false}
                        fileList={fileList}
                        maxCount={1}
                    >
                        <div className="upload-image">{uploadButton}</div>
                    </Upload.Dragger>
                ) : (
                    this.uploadedImage()
                )}
            </div>
        );
    }
}

export default injectIntl(ImageUpload);
