import _ from "lodash";
import config from "../../config";

/**
 * Used for converting API request into objects more usable
 * in the front end.
 *
 * Models are formatted in this way:
 * API_OBJECT_KEY,          FRONT_END_OBJECT_KEY,   REQUIRES_TRANSLATION,   FLAG;
 *
 * Example:
 * general_info.store_nm,   store_nm,               true,                   flag number eg: 11;
 */
class ObjectModel {
    data: any = {};
    object: any = {};
    model: "";

    constructor(model: Record<string, any> | any, object?: Record<string, any> | any) {
        this.model = model;
        this.object = object;

        if (!_.isEmpty(this.object)) {
            const lines: string[] = this.model.split(";");
            lines.forEach((line) => {
                if (line.trim()) {
                    const parameters = line.split(",");
                    const original = parameters?.[0].trim();
                    const mapped = parameters?.[1].trim();
                    const translated = parameters?.[2]?.trim() === "true";
                    const flag = parameters?.[3]?.trim() ?? null;
                    if (translated) {
                        config.LANGUAGES.forEach((lan) => {
                            _.set(this.data, mapped + "_" + lan, _.get(this.object, original + "." + lan));
                        });
                    } else {
                        if (flag !== undefined && flag !== null) {
                            const flags = _.get(this.object, original)?.split(",");
                            _.set(this.data, mapped, _.get(flags, Number(flag)));
                        } else {
                            _.set(this.data, mapped, _.get(this.object, original));
                        }
                    }
                }
            });
        }
    }

    convertToPostData = (post: Record<string, any> | any, records: any = {}, location?: string): any => {
        const data: any = {};
        const lines: string[] = this.model.split(";");
        const flagRecords: any = {};
        lines.forEach((line) => {
            if (line.trim()) {
                const parameters = line.split(",");
                const original = parameters[0].trim();
                const mapped = parameters[1].trim();
                const translated = parameters[2]?.trim() === "true";
                const flag = parameters[3]?.trim() ?? null;

                if (flag !== undefined && flag !== null && !flagRecords[original] && !_.isEmpty(records)) {
                    flagRecords[original] = records?.[original]?.split(",") ?? [];
                }

                if (translated) {
                    config.LANGUAGES.forEach((lan) => {
                        if (
                            flag == undefined &&
                            _.isEmpty(_.get(post, mapped + "_" + lan)) &&
                            !_.isEmpty(_.get(post, mapped + "_en"))
                        ) {
                            _.set(data, original + "." + lan, _.get(post, mapped + "_en"));
                        } else if (
                            flag !== undefined &&
                            flag == "no_map_default" &&
                            _.isEmpty(_.get(post, mapped + "_" + lan))
                        ) {
                            //Bypass translation of that field to english
                            return;
                        } else {
                            _.set(data, original + "." + lan, _.get(post, mapped + "_" + lan));
                        }
                    });
                } else {
                    if (flag !== undefined && flag !== null && flagRecords?.[original]?.[Number(flag)]) {
                        _.set(
                            flagRecords,
                            `${original}.${Number(flag)}`,
                            _.get(post, mapped) ?? flagRecords?.[original]?.[Number(flag)]
                        );

                        if (location === "store_information" && Number(flag) === 39) {
                            _.set(flagRecords, `${original}.${Number(flag)}`, 0);
                        }
                    } else {
                        _.set(data, original, _.get(post, mapped));
                    }
                }
            }
        });
        Object.keys(flagRecords).forEach((key) => {
            data[key] = flagRecords[key].join(",");
        });
        return data;
    };

    getData = (): any => {
        return this.data;
    };
}

export default ObjectModel;
