import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { setState } from "../../../../slices/create-order";
import TextArea from "antd/lib/input/TextArea";

class OrderNote extends Component<any, any> {
    state: any = { isAutoSized: false };

    componentDidUpdate = () => {
        // autosize is executed after first update
        if (!this.state.isAutoSized) {
            this.setState({ isAutoSized: true });
        }
    };

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    getComment = () => this.props.state?.notes || "";

    getAddedParted = () =>
        `${this.props.state?.notes && this.props.state?.storeDiscountNotes ? `, ` : ""}${
            this.props.state?.storeDiscountNotes || ""
        }`;

    getValue = () => {
        return this.state.focus ? this.getComment() : this.getComment() + this.getAddedParted();
    };

    render() {
        return (
            <div>
                <div>
                    <FormattedMessage id="notes" />
                </div>
                {this.state.isAutoSized ? (
                    <TextArea
                        value={this.getValue()}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                        autoSize={{ minRows: 1, maxRows: 2 }}
                        onChange={(e) => {
                            this.props.setState({
                                notes: e?.target?.value,
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", true),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderNote)));
