import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SignUpView } from "../../constants";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import usePlacesAutocomplete from "../../../../hooks/usePlacesAutoComplete";
import _ from "lodash";
import { selectWithPlaceId } from "../../../../util/helper/google";
import { getStoreTypes } from "../../../../services/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { useHistory } from "react-router";
import { api } from "../../../../util/api";
import clearText from '../../../../images/icons/icon_clear_text.png'

const schema = yup.object().shape({
    email: yup.string().email().required(),
    phone: yup.string().required(),
    store_name: yup.string().required(),
    store_address: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    does_delivery: yup.number().required(),
});

interface SignupInputs {
    email: string;
    phone: string;
    referral_code: string;
    floor_suite: string;
    store_name: string;
    first_name: string;
    last_name: string;
    does_delivery: number;
    business_type: any;
}

const ALL_TYPES = 1;

enum DeliveryOptions {
    No,
    Yes,
}

function AssistedSignup(props: Record<string, any>): JSX.Element {
    const { setView } = props;
    const { control, handleSubmit, errors, watch, reset } = useForm<SignupInputs>({
        resolver: yupResolver(schema),
    });
    const lan = useSelector((state: RootState) => state?.setting?.lan);
    const types = useSelector((state: RootState) => state?.store?.types);
    const intl = useIntl();
    const {
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete();
    const dispatch = useDispatch();
    const history = useHistory();

    const businessTypeOptions = [
        <Select.Option key="restaurant" value="restaurant">
            <FormattedMessage id="restaurant" />
        </Select.Option>,
    ];

    const deliveryTypeOptions = [
        { value: DeliveryOptions.No, label: intl.formatMessage({ id: "no" }) },
        { value: DeliveryOptions.Yes, label: intl.formatMessage({ id: "yes" }) },
    ];

    const getGoogleOptions = () => {
        return data.map((option: any) => ({
            value: option?.place_id,
            label: option?.description,
        }));
    };

    const getTypeOptions = () => {
        return Object.keys(types)
            .reduce((acc: any, key) => {
                return [...acc, ...types[key]];
            }, [])
            .filter((type: any) => type?.id !== ALL_TYPES) // Don't show ALL
            .map((type: any) => ({
                value: type?.id,
                label: type?.name,
            }));
    };

    const addressId = watch("store_address");

    const onSubmit = async (data: SignupInputs) => {
        const submit = {};
        const set = (path: string, value: any) => _.set(submit, path, value);

        await selectWithPlaceId(addressId, async (address: any) => {
            const components = address?.address_components ?? [];

            set("store_addr.post_code", components?.find((c: any) => c?.types?.includes("postal_code"))?.short_name);
            set("store_addr.city", components?.find((c: any) => c?.types?.includes("locality"))?.short_name);
            set(
                "store_addr.street",
                `${components?.find((c: any) => c?.types?.includes("street_number"))?.short_name} ${components?.find((c: any) => c?.types?.includes("route"))?.short_name
                }`
            );
            set(
                "store_addr.province",
                components?.find((c: any) => c?.types?.includes("administrative_area_level_1"))?.short_name
            );
            set("store_addr.country_code", components?.find((c: any) => c?.types?.includes("country"))?.short_name);
            set("store_addr.lat", address?.geometry?.location?.lat?.());
            set("store_addr.lon", address?.geometry?.location?.lng?.());
            set("store_addr.unit", data.floor_suite);
            set("general_info.store_nm.en", data.store_name);
            set("general_info.phone", data.phone);
            set("general_info.email", data.email);
            set("general_info.owner", data.first_name + " " + data.last_name);
            set("does_delivery", data.does_delivery);
            set("cid", data.business_type.join(":"));

            const response = await api("ASSISTED_SIGNUP")({
                method: "POST",
                body: JSON.stringify({ data: submit }),
            });

            if (response?.RC === 200) {
                Modal.info({
                    title: intl.formatMessage({ id: "assisted_signup_complete" }),
                    content: intl.formatMessage({ id: "assisted_signup_complete_desc" }),
                    onOk() {
                        history.push("/");
                    },
                });
            } else {
                Modal.error({
                    title: intl.formatMessage({ id: "error" }),
                    content: response?.MSG,
                });
            }
        });
    };

    const inputs = [
        [
            [
                {
                    name: "store_name",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "store_name" }),
                    },
                },
            ],
            [
                {
                    name: "store_address",
                    component: Select,
                    options: getGoogleOptions(),
                    props: {
                        placeholder: intl.formatMessage({ id: "store_address" }),
                        showSearch: true,
                        onSearch: setValue,
                        filterOption: (input: any, option: any) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    },
                },
            ],
            [
                {
                    name: "floor_suite",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "floor_suite_optional" }),
                    },
                },
            ],
        ],
        [
            [
                {
                    name: "first_name",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "first_name" }),
                    },
                },
                {
                    name: "last_name",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "last_name" }),
                    },
                },
            ],
            [
                {
                    name: "email",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "email" }),
                    },
                },
            ],
            [
                {
                    name: "phone",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "phone" }),
                    },
                },
            ],
        ],
        [
            [
                {
                    name: "business_type",
                    component: Select,
                    options: getTypeOptions(),
                    props: {
                        placeholder: intl.formatMessage({ id: "business_type" }),
                        children: businessTypeOptions,
                        mode: "multiple",
                        showSearch: true,
                        filterOption: (input: any, option: any) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                    },
                },
            ],
            [
                {
                    name: "does_delivery",
                    component: Select,
                    options: deliveryTypeOptions,
                    props: {
                        placeholder: intl.formatMessage({ id: "does_delivery" }),
                    },
                },
            ],
        ],
    ];

    const getErrorMessage = (message: any) => {
        return message ? (
            <React.Fragment>{intl.formatMessage({ id: message?.split(" ")?.[0] + "_error" })}.</React.Fragment>
        ) : null;
    };

    const renderInput = (input: any) => (
        <Col key={input?.name}>
            <Form.Item
                name={input?.name}
                // @ts-ignore
                help={getErrorMessage(errors?.[input?.name]?.message)}
                // @ts-ignore
                validateStatus={errors?.[input?.name]?.message && "error"}
            >
                <Controller as={input?.component} control={control} name={input?.name} {...input?.props}>
                    {input?.options?.length
                        ? input?.options.map((option: any) => {
                            return (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            );
                        })
                        : null}
                </Controller>
                {!(input.name === 'store_address' || input.name === 'does_delivery') && <img onClick={() => reset({ ...control.getValues(), [input.name]: '' })} style={{ width: '13px', position: 'absolute', right: '10px', top: '10px', zIndex: 1, cursor: "pointer" }} src={clearText} alt='clear text icon' />
                }
            </Form.Item>
        </Col>
    );

    const renderRows = (col: any, j: number, row: any) =>
        row !== null ? (
            <Row gutter={6} key={"row-" + j}>
                {col.map(renderInput)}
            </Row>
        ) : null;

    const renderInputs = (row: any, i: number) => (
        <div className="signup-form-group" key={"inputs" + i}>
            {row.map((col: any, j: number) => renderRows(col, j, row))}
        </div>
    );

    useEffect(() => {
        dispatch(getStoreTypes({ lan }));
    }, [dispatch, lan]);

    return (
        <Form className="signup-form">
            {inputs.map(renderInputs)}
            <Button className="signup-button" type="primary" onClick={handleSubmit(onSubmit)}>
                <FormattedMessage id="signup" />
            </Button>

            <Button className="signup-button" onClick={() => setView(SignUpView.SelectMethod)}>
                <FormattedMessage id="go_back" />
            </Button>
        </Form>
    );
}

export default AssistedSignup;
