import React, { Component } from "react";
import { Modal, Col, Button, Row, Checkbox } from "antd";
import _ from "lodash";
import _helper from "util/helper";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import "./_item-edit-dialog.scss";
import {
    ITEM_CHANGE_ACTION_REPLACE,
    ITEM_CHANGE_ACTION_REMOVE,
    ITEM_CHANGE_ACTION_ADD,
    ITEM_CHANGE_REASON_CUSTOMER_REQUEST,
    ITEM_CHANGE_REASON_OUT_OF_STOCK_INFINITE,
    ITEM_CHANGE_REASON_OUT_OF_STOCK_TODAY,
    ITEM_CHANGE_LOCALE_ACTION_REMOVE,
    ITEM_CHANGE_LOCALE_ACTION_UPDATE,
    ITEM_CHANGE_LOCALE_ACTION_REPLACE,
    ITEM_CHANGE_LOCALE_ACTION_REVERSE,
    ITEM_CHANGE_ACTION_CHECK_PRICES,
    sortChangeItems,
} from "../helper";
import _actions from "slices/live-order";
import { getItemOptionsArray } from "pages/order-history/helper";

class ItemEditorDialog extends Component {
    state = {};

    componentDidMount() {
        this.checkItemUpdate({});
    }

    componentDidUpdate(prevsProps) {
        this.checkItemUpdate(prevsProps);
    }

    shouldShow = () => _.get(this.props, "state.showItemEditDialog", false);

    getOrderId = () => _.get(this.props, "order.ord_id", "");

    getOriginalItem = (props = this.props) => _.get(props, "state.item", {});

    getRenderingItem = (props = this.props) => _.get(props, "state.renderingItem", {});

    getItemChanges = () => {
        const itemChanges = _.get(this.props, "state.itemChanges", {});
        return Array.isArray(itemChanges) ? itemChanges : [];
    };

    getExistingItemChangeData = () => {
        const currentItem = this.getOriginalItem() ? this.getOriginalItem() : {};
        const itemChanges = this.getItemChanges();
        let index = -1;
        let object = itemChanges.find((change, i) => {
            const found = String(_.get(change, "item_id")) === String(currentItem.item_id);
            if (found) {
                index = i;
                return true;
            }
            return false;
        });
        return {
            object,
            index,
        };
    };

    getChangeAction = () => {
        let itemAction = _.get(this.getExistingItemChangeData(), "object.action");
        //if replace it can also be update
        if (Number(itemAction) === ITEM_CHANGE_ACTION_REPLACE) {
            const newProductPid = _.get(this.getExistingItemChangeData(), "object.new_product.pid");
            const itemPid = _.get(this.getExistingItemChangeData(), "object.pid");
            if (newProductPid === itemPid) itemAction = ITEM_CHANGE_LOCALE_ACTION_UPDATE;
        }
        if (Number(itemAction) === ITEM_CHANGE_ACTION_ADD) {
            itemAction = ITEM_CHANGE_LOCALE_ACTION_REVERSE;
        }
        const stateAction = _.get(this.props, "state.local_action", "");
        const result = stateAction ? stateAction : itemAction ? itemAction : ITEM_CHANGE_LOCALE_ACTION_REPLACE;
        return result;
    };

    getChangeReason = () => {
        const itemReason = _.get(
            this.getExistingItemChangeData(),
            "object.reason",
            ITEM_CHANGE_REASON_OUT_OF_STOCK_TODAY
        );
        const stateReason = _.get(this.props, "state.reason", "");
        return stateReason ? stateReason : itemReason;
    };

    str = (id) => {
        let { lan } = this.props;
        return _helper.getTransString(id, lan);
    };

    checkItemUpdate = (prevsProps) => {
        const prevItemPid = String(_.get(this.getOriginalItem(prevsProps), "pid", ""));
        const currentItemPid = String(_.get(this.getOriginalItem(), "pid", ""));
        const itemUpdate = prevItemPid !== currentItemPid && currentItemPid;
        if (itemUpdate && !currentItemPid.includes("add")) {
            this.props.getProductDetail({
                pid: currentItemPid,
                lan: this.props.lan,
            });
        }
    };

    onCloseClick = () => this.props.setState({ showItemEditDialog: false });

    onSubmitClick = () => {
        const localAction = this.getChangeAction();
        if (Number(localAction) === ITEM_CHANGE_LOCALE_ACTION_REMOVE) {
            this.remove();
        }
        if (Number(localAction) === ITEM_CHANGE_LOCALE_ACTION_REPLACE) {
            this.replace();
        }
        if (Number(localAction) === ITEM_CHANGE_LOCALE_ACTION_UPDATE) {
            this.update();
        }
        if (Number(localAction) === ITEM_CHANGE_LOCALE_ACTION_REVERSE) {
            this.reverse();
        }
    };

    /**
     * Check the remained item is only one with the item change requests in the order
     *
     * @returns true if the remained item is only one
     */
    isLastItem = () => {
        const itemChanges = this.getItemChanges();
        const totalItem = this.props.orderItems.length;
        const removedItem = itemChanges.reduce((acc, item) => {
            if (item.action === ITEM_CHANGE_LOCALE_ACTION_REMOVE) {
                acc += 1;
            }
            return acc;
        }, 0);
        return totalItem - removedItem <= 1;
    };

    getActionCheckBoxesData = () => {
        const existingItemChangeData = this.getExistingItemChangeData();
        const action = _.get(existingItemChangeData, "object.action");
        const index = _.get(existingItemChangeData, "index");

        let result = [
            {
                value: ITEM_CHANGE_LOCALE_ACTION_UPDATE,
                title: "update_original_item",
                onClick: () => this.update(),
                show: Number(action) !== ITEM_CHANGE_ACTION_ADD,
            },
            {
                value: ITEM_CHANGE_LOCALE_ACTION_REPLACE,
                title: "out_of_stock_replace",
                onClick: () => this.replace(),
                show: Number(action) !== ITEM_CHANGE_ACTION_ADD,
            },
            {
                value: ITEM_CHANGE_LOCALE_ACTION_REMOVE,
                title: "out_of_stock_remove",
                onClick: () => this.remove(),
                show: Number(action) !== ITEM_CHANGE_ACTION_ADD && Number(action) !== ITEM_CHANGE_ACTION_REMOVE,
                isDisabled: this.isLastItem(),
            },
            {
                value: ITEM_CHANGE_LOCALE_ACTION_REVERSE,
                show: index !== -1,
                title: "reverse_current_change",
                onClick: () => this.reverse(),
            },
        ];

        return result;
    };

    getReasonCheckBoxesData = () => {
        return [
            {
                value: ITEM_CHANGE_REASON_CUSTOMER_REQUEST,
                title: "customer_request",
            },
            {
                value: ITEM_CHANGE_REASON_OUT_OF_STOCK_TODAY,
                title: "out_of_stock_today",
            },
            {
                value: ITEM_CHANGE_REASON_OUT_OF_STOCK_INFINITE,
                title: "out_of_stock_indefinitely",
            },
        ];
    };

    getQtyWidth = () => {
        const item = this.getRenderingItem();
        const quanity = _.get(item, "cnt", 0);
        var base = 5,
            add = 0;
        //find the correct add percetage
        //each add mean it has increase 1 digit
        while (quanity > Math.pow(10, add)) {
            add++;
        }
        return `${base + add * 2}%`;
    };

    update = () => {
        const currentItem = this.getOriginalItem() ? this.getOriginalItem() : {};
        this.props.setState({
            showItemEditDetailDialog: true,
            reason: "",
            local_action: "",
            itemChangeObject: {
                pid: currentItem?.pid,
                item_id: currentItem?.item_id,
                action: ITEM_CHANGE_ACTION_REPLACE,
                reason: this.getChangeReason(),
                new_product: {
                    pid: currentItem?.pid,
                    item_id: currentItem?.item_id,
                    cnt: currentItem?.cnt,
                    pcIn: currentItem?.spc ?? currentItem?.pc,
                    opts: currentItem?.opts,
                    nm: currentItem?.nm,
                    sku: currentItem?.sku,
                },
            },
            showItemEditDialog: false,
            onEdit:false,
        });
    };

    replace = () => {
        const currentItem = this.getOriginalItem() ? this.getOriginalItem() : {};
        this.props.setState({
            reason: "",
            local_action: "",
            showItemEditDetailDialog: true,
            itemChangeObject: {
                reason: this.getChangeReason(),
                item_id: currentItem.item_id,
                pid: currentItem.pid,
                action: ITEM_CHANGE_ACTION_REPLACE,
                new_product: {},
            },
            showItemEditDialog: false,
        });
    };

    remove = () => {
        const currentItem = this.getOriginalItem() ? this.getOriginalItem() : {};
        const currentItemChange = {
            pid: currentItem.pid,
            item_id: currentItem.item_id,
            action: ITEM_CHANGE_ACTION_REMOVE,
            reason: this.getChangeReason(),
        };
        //modifying the itemChanges
        let itemChanges = this.getItemChanges();
        const index = this.getExistingItemChangeData().index;
        //if some changes has been performed
        if (index !== -1) {
            itemChanges[index] = currentItemChange;
        } else {
            itemChanges.push(currentItemChange);
        }
        itemChanges = sortChangeItems(this.props.orderItems, itemChanges);
        this.props.setState({
            reason: "",
            local_action: "",
            itemChanges: itemChanges,
            showItemEditDialog: false,
            item: {},
            renderingItem: {},
            onEdit: true,
        });
        this.props.updateSuggestions({
            order_id: this.getOrderId(),
            item_changes: itemChanges,
            action: ITEM_CHANGE_ACTION_CHECK_PRICES,
        });
    };

    reverse = () => {
        let itemChanges = this.getItemChanges();
        const index = this.getExistingItemChangeData().index;
        if (index !== -1) {
            itemChanges.splice(index, 1);
        }
        const onEdit = !_.isEmpty(itemChanges) || !_.isEmpty(_.get(this.props, "order.item_changes", []));
        this.props.setState({
            itemChanges: itemChanges,
            showItemEditDialog: false,
            item: {},
            renderingItem: {},
            onEdit: onEdit,
            reason: "",
            local_action: "",
        });
        if (_.isEmpty(itemChanges)) {
            let newPrices = _.cloneDeep(this.props.newPrices);
            newPrices[this.getOrderId()] = { fake: 0 }; //to have some data, to show locale data
            this.props.setLiveOrderState({
                newPrices: newPrices,
            });
        } else {
            this.props.updateSuggestions({
                order_id: this.getOrderId(),
                item_changes: itemChanges,
                action: ITEM_CHANGE_ACTION_CHECK_PRICES,
            });
        }
    };

    renderCurrentItem = () => {
        const item = this.getRenderingItem();
        const { storeCurrency } = this.props;
        const amt = item.amt ? item.amt : item.cnt * item.pc;
        return (
            <Row className="current-item-row">
                <div style={{ width: this.getQtyWidth() }} className="item-cnt">
                    {item.cnt} x
                </div>
                <div className="item-name">
                    {this.str(item.nm)}
                    {_helper.isRestaurant(this.props.orderStore) ? "" : <div>(SKU: {item.sku})</div>}
                </div>
                <Col span={8}>
                    <div className="item-price">{_helper.getCurrencyFormattedString(amt, storeCurrency)}</div>
                </Col>
            </Row>
        );
    };

    renderCurrentItemOptions = () => {
        const item = this.getRenderingItem();
        const subOptions = getItemOptionsArray(item.opts, this.props.lan);
        return subOptions.length ? (
            <div className="item-options">
                <span
                    style={{
                        width: this.getQtyWidth(),
                    }}
                >
                    &nbsp;
                </span>
                <div>
                    {subOptions.map((opt) => {
                        return (
                            <div key={`item-option-${opt.id}`}>
                                <span>·&nbsp;</span>
                                <span className="option-name"> {opt.nm}: </span>
                                <span className="option-values">{opt.optStr}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            ""
        );
    };

    renderActionCheckboxes = () => {
        return (
            <div>
                <div className="item-edit-dialog-checkboxes-title">
                    <FormattedMessage id="choose_an_action" />
                </div>
                {this.getActionCheckBoxesData()
                    .filter((checkbox) => checkbox.show)
                    .map((checkbox) => {
                        return (
                            <Checkbox
                                key={checkbox.value}
                                style={{ paddingRight: 8 }}
                                checked={Number(this.getChangeAction()) === checkbox.value}
                                disabled={checkbox.isDisabled || false}
                                onChange={() => {
                                    this.props.setState({ local_action: checkbox.value });
                                    if (checkbox.value === ITEM_CHANGE_LOCALE_ACTION_UPDATE) {
                                        this.props.setState({ reason: ITEM_CHANGE_REASON_CUSTOMER_REQUEST });
                                    }
                                }}
                            >
                                <b>
                                    {" "}
                                    <FormattedMessage id={checkbox.title} />
                                </b>
                            </Checkbox>
                        );
                    })}
            </div>
        );
    };

    renderReasonCheckboxes = () => {
        if (Number(this.getChangeAction()) !== ITEM_CHANGE_LOCALE_ACTION_REVERSE) {
            return (
                <div>
                    <div className="item-edit-dialog-checkboxes-title">
                        <FormattedMessage id="choose_a_reason" />
                    </div>
                    {this.getReasonCheckBoxesData().map((checkbox) => (
                        <Checkbox
                            key={checkbox.value}
                            style={{ width: "50%" }}
                            checked={Number(this.getChangeReason()) === checkbox.value}
                            onChange={() => this.props.setState({ reason: checkbox.value })}
                        >
                            <b>
                                {" "}
                                <FormattedMessage id={checkbox.title} />
                            </b>
                        </Checkbox>
                    ))}
                </div>
            );
        }
    };

    renderFooter = () => {
        const withReasonAndAction = this.getChangeAction() && this.getChangeReason();
        const actionIsReverse = Number(this.getChangeAction()) === ITEM_CHANGE_LOCALE_ACTION_REVERSE;
        const showFooter = withReasonAndAction || actionIsReverse;
        if (showFooter) {
            return [
                <Button key="item-edit-dialog-button-cancel" onClick={() => this.onCloseClick()}>
                    <b>
                        {" "}
                        <FormattedMessage id="cancel" />
                    </b>
                </Button>,
                <Button key="item-edit-dialog-button-continue" type="primary" onClick={() => this.onSubmitClick()}>
                    <b>
                        {" "}
                        <FormattedMessage id="continue" />
                    </b>
                </Button>,
            ];
        }
        return [
            <Button key="item-edit-dialog-button-cancel" onClick={() => this.onCloseClick()}>
                <b>
                    {" "}
                    <FormattedMessage id="cancel" />
                </b>
            </Button>,
        ];
    };

    render() {
        return (
            <div>
                <Modal
                    visible={this.shouldShow()}
                    title={<FormattedMessage id="update_item" />}
                    onCancel={() => this.onCloseClick()}
                    footer={this.renderFooter()}
                >
                    <div className="item-edit-dialog-container">
                        {this.renderCurrentItem()}
                        {this.renderCurrentItemOptions()}
                        {this.renderActionCheckboxes()}
                        {this.renderReasonCheckboxes()}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    newPrices: _.get(state, "liveOrders.newPrices", {}),
});

const mapDispatchToProps = {
    getProductDetail: _actions.getProductDetail,
    updateSuggestions: _actions.updateSuggestions,
    setLiveOrderState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemEditorDialog));
