import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import SearchDialog from "pages/liveorder/_components/order-items/item-edit-dialog/item-detail-editor-dialog-main";
import { setState } from "slices/create-order";
import { generateResultMap } from "pages/liveorder/_components/order-items/helper";
import helper from "util/helper";

class DetailSearchDialog extends Component<any, any> {
    state = {};

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    getItems = () => (Array.isArray(this.props.items) ? this.props.items : []);

    showSearchDialog = () => {
        this.props.setParentState({
            showItemEditDetailDialog: true,
            item: {
                pid: `add_${Math.random().toString(36).substr(2, 9)}`,
            },
        });
    };

    render() {
        const matchedStore = helper.getStoreWithGid(this.props.storeRecords?.g_id, this.props.storesData);

        return (
            <SearchDialog
                id="search-dialog"
                state={this.props.state}
                orderStore={matchedStore}
                setState={(values: any) => this.props.setState(values)}
                onSubmit={(params: any) => {
                    let newItems = _.cloneDeep(this.props.items);
                    const currentItemChange = _.get(params, "currentItemChange");
                    const originalIndex = _.get(params, "currentItemChange.new_product.i");
                    if (originalIndex === 0 || originalIndex) {
                        newItems[originalIndex] = _.get(currentItemChange, "new_product");
                    } else {
                        const resultMap = generateResultMap(this.props.items, [currentItemChange]) || {};
                        newItems = Object.values(resultMap).map((product) => ({
                            ..._.get(product, "product", {}),
                            qty: _.get(product, "qty", 1),
                        }));
                    }
                    this.props.setState({ items: newItems });
                }}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    items: _.get(state, "createOrder.items", []),
    state: _.get(state, "createOrder", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DetailSearchDialog)));
