import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export interface Product {
    name: any;
    availability: number;
    has_options: boolean;
    product_id: number;
    product_type: number;
    type: number;
    img: string | null;
    sku: string;
    price: number;
    sold_count: number;
    status: number;
    stock: number;
    tax: number;
    weight: number;
    category_ids: number[];
}

export interface ProductDetail {
    name: any;
    product_id: string | null;
    product_type: number | null;
    category_ids: number[] | null;
    sku: string | null;
    weight: number | null;
    weight_unit: string | null;
    price: number | null;
    stock: number | null;
    tel: string | null;
    time: string | null;
    sold_count: number | null;
    status: number | null;
    tax_class: number | null;
    description: string | null;
    short_description: string | null;
    url_key: string | null;
    meta_title: string | null;
    meta_keywords: string | null;
    meta_desc: string | null;
    related_products: number[] | null;
    upsell_products: number[] | null;
    related_product_list: Product[] | null;
    upsell_product_list: Product[] | null;
    updated_at: string | null;
    bookvalue: number | null;
    options: any[] | null;
    images: string[] | null;
    special_price: number | null;
    special_from_date: string | null;
    special_to_date: string | null;
    start_date: string | null;
    end_date: string | null;
    cost: number | null;
    barcode: string | null;
    v_url: string | null;
    show_store_link: number | null;
    is_hidden: boolean | null;
    max_sale_qty: number | null;
    min_sale_qty: number | null;
    enable_special_request: number | null;
}

interface ProductState {
    products: Product[];
    product: ProductDetail;
    totalProducts: number;
    lastSearch?: any;
    loading?: boolean;
    shareModal?: false;
    duplicate?: boolean;
    addEditItemDuplicate: any;
    additionalProducts: Product[];
    values: any;
    imageList: any;
    optionsChanges: any;
    addEditItemOptionDuplicate: any;
}

const defaultProduct: ProductDetail = {
    name: "",
    product_id: "",
    product_type: null,
    category_ids: [],
    sku: "",
    weight: null,
    weight_unit: null,
    price: null,
    stock: null,
    tel: "",
    time: "",
    sold_count: null,
    status: null,
    tax_class: null,
    description: "",
    short_description: "",
    url_key: "",
    meta_title: "",
    meta_keywords: "",
    meta_desc: "",
    related_products: [],
    upsell_products: [],
    related_product_list: [],
    upsell_product_list: [],
    updated_at: "",
    bookvalue: null,
    options: [],
    images: [],
    special_price: null,
    special_from_date: "",
    special_to_date: "",
    start_date: "",
    end_date: "",
    cost: null,
    barcode: "",
    v_url: "",
    show_store_link: null,
    is_hidden: false,
    max_sale_qty: null,
    min_sale_qty: null,
    enable_special_request: null,
};

const initialState: ProductState = {
    products: [],
    product: defaultProduct,
    totalProducts: 0,
    loading: false,
    duplicate: false,
    addEditItemDuplicate: {},
    additionalProducts: [],
    values: {},
    imageList: [],
    optionsChanges: {},
    addEditItemOptionDuplicate: {},
};

const getProductThumbnailPath = (images: string[]) => {
    if (!images || _.isEmpty(images)) {
        return null;
    } else {
        return images[0];
    }
};

const slice = createSlice({
    name: "products",
    initialState,
    reducers: {
        showShareModal(state, { payload }) {
            state.shareModal = payload.value;
        },
        setProductState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        getProductsSuccess(state, { payload }) {
            if (Array.isArray(payload.records)) {
                if (payload.infinite) {
                    state.products = [...state.products, ...payload?.records?.map?.((category: any) => category)];
                } else {
                    state.products = payload?.records?.map?.((category: any) => category);
                }
            }
            state.totalProducts = payload?.paging?.total;
        },
        getProductSuccess(state, { payload }) {
            state.product = payload;
        },
        updateProductSuccess(state, { payload }) {
            if (!_.isPlainObject(payload)) {
                return;
            }

            Object.keys(defaultProduct).forEach((key) => {
                if (payload[key] === undefined) {
                    // @ts-ignore
                    state.product[key] = _.cloneDeep(defaultProduct[key]);
                } else {
                    // @ts-ignore
                    state.product[key] = payload[key];
                }
            });

            state.products.forEach((product) => {
                if (product.product_id === payload?.product_id) {
                    product.name = payload.name;
                    product.availability = payload.availability;
                    product.has_options = payload.has_options;
                    product.product_type = payload.product_type;
                    product.type = payload.type;
                    product.img = getProductThumbnailPath(payload.images);
                    product.sku = payload.sku;
                    product.price = payload.price;
                    product.sold_count = payload.sold_count;
                    product.status = payload.status;
                    product.stock = payload.stock;
                    product.tax = payload.tax_class;
                    product.weight = payload.weight;
                    product.category_ids = payload.category_ids;
                }
            });
        },
        addProductSuccess(state, { payload }) {
            if (!_.isPlainObject(payload)) {
                return;
            }

            const newProduct = _.cloneDeep(payload);
            Object.keys(defaultProduct).forEach((key) => {
                if (payload[key] === undefined) {
                    // @ts-ignore
                    newProduct[key] = _.cloneDeep(defaultProduct[key]);
                } else {
                    newProduct[key] = payload[key];
                }
            });

            state.products.unshift(newProduct);
        },
        resetProduct(state) {
            state.product = _.cloneDeep(defaultProduct);
        },
        setLastSearch(state, { payload }) {
            state.lastSearch = payload;
        },
        getAdditionalProductSuccess(state, { payload }) {
            state.additionalProducts.push(payload);
        },
        getValuesSuccess(state, { payload }) {
            state.values = Object.assign(state.values, payload);
        },
        getImageListSuccess(state, { payload }) {
            state.imageList = payload;
        },
        getOptionsChangeSuccess(state, { payload }) {
            state.optionsChanges = payload;
        },
        getAddEditItemOptionDuplicateSuccess(state, { payload }) {
            state.addEditItemOptionDuplicate = { ...state.addEditItemOptionDuplicate, ...payload };
        },
        setValuesSuccess(state, { payload }) {
            state.values = payload;
        },
    },
});

export const {
    getProductsSuccess,
    getProductSuccess,
    updateProductSuccess,
    addProductSuccess,
    setLastSearch,
    resetProduct,
    setProductState,
    showShareModal,
    getAdditionalProductSuccess,
    getValuesSuccess,
    getImageListSuccess,
    getOptionsChangeSuccess,
    getAddEditItemOptionDuplicateSuccess,
    setValuesSuccess,
} = slice.actions;

export const products = slice.reducer;
