import React from "react";
import { Popover, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import helper from "../../../util/helper";
import cH from "../helper";
import rH from "../../settings/pages/referral-settings/helper";
import { setMessage } from "../../../slices/customers";
import { getReferralCode } from "../../../services/customers";

function BulkActions(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state.user);
    const gid = useSelector((state: RootState) => state.store?.records?.g_id);
    const showReferrals = useSelector((state: RootState) => state.store?.storeReferrals?.allowCustRefs);
    const selectedCustomers = useSelector((state: RootState) => state?.customers?.selectedCustomers);

    const renderReferralActions = (
        <>
            <p className="popover-item" onClick={() => bulkEnable()}>
                <FormattedMessage id="enable_referrals" />
            </p>
            <p className="popover-item" onClick={() => bulkDisable()}>
                <FormattedMessage id="disable_referrals" />
            </p>
        </>
    );

    const bulkActions = (
        <React.Fragment>
            <p className="popover-item" onClick={() => sendMessages()}>
                <FormattedMessage id="send_message" />
            </p>
            <p className="popover-item" onClick={() => bulkGenerate()}>
                <FormattedMessage id="generate_referrals" />
            </p>
            {showReferrals ? renderReferralActions : null}
            {!helper.isSuperUser(currUser) ? null : (
                <p className="popover-item" onClick={() => bulkDelete()}>
                    <FormattedMessage id="delete_referrals" />
                </p>
            )}
        </React.Fragment>
    );

    const sendMessages = () => {
        dispatch(setMessage({ value: true }));
    };

    const bulkEnable = () => {
        let allAlready = true;
        selectedCustomers?.map((cust: any, index: number) => {
            const bulk = index === selectedCustomers.length - 1 ? rH.BULK.LAST : rH.BULK.TRUE;
            if (cust?.referral_code && cust.referral_code_status !== cH.REF_ENABLED) {
                allAlready = false;
                dispatch(getReferralCode({ gid, user_id: cust?.id, action: rH.REFERRAL_ACTION.ENABLE, bulk }));
            }
        });
        if (allAlready) {
            Modal.warning({ title: intl.formatMessage({ id: "all_referrals_were_already_enabled" }) });
        }
    };
    const bulkDisable = () => {
        let allAlready = true;
        selectedCustomers?.map((cust: any, index: number) => {
            const bulk = index === selectedCustomers.length - 1 ? rH.BULK.LAST : rH.BULK.TRUE;
            if (cust?.referral_code && cust.referral_code_status === cH.REF_ENABLED) {
                allAlready = false;
                dispatch(getReferralCode({ gid, user_id: cust?.id, action: rH.REFERRAL_ACTION.DISABLE, bulk }));
            }
        });
        if (allAlready) {
            Modal.warning({ title: intl.formatMessage({ id: "all_referrals_were_already_disabled" }) });
        }
    };
    const bulkGenerate = () => {
        let allAlready = true;
        selectedCustomers?.map((cust: any, index: number) => {
            const bulk = index === selectedCustomers.length - 1 ? rH.BULK.LAST : rH.BULK.TRUE;
            if (!cust?.referral_code) {
                allAlready = false;
                dispatch(getReferralCode({ gid, user_id: cust?.id, action: rH.REFERRAL_ACTION.GENERATE, bulk }));
            }
        });
        if (allAlready) {
            Modal.warning({ title: intl.formatMessage({ id: "all_referrals_already_generated" }) });
        }
    };
    const bulkDelete = () => {
        selectedCustomers?.map((cust: any, index: number) => {
            const bulk = index === selectedCustomers.length - 1 ? rH.BULK.LAST : rH.BULK.TRUE;
            if (cust?.referral_code && cust?.referral_trxn_count === rH.MIN) {
                dispatch(getReferralCode({ gid, user_id: cust?.id, action: rH.REFERRAL_ACTION.DELETE, bulk }));
            }
        });
    };

    return (
        <Popover content={bulkActions} placement="bottom">
            <div className="bulk-actions pHover mr-1 mt-2">
                <FormattedMessage id="bulk_actions" />
            </div>
        </Popover>
    );
}

export default BulkActions;
