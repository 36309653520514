import { Button, Col, Form, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
    getFormItem,
    getMultiSelectInput,
    getNumberInput,
    getSelectInput,
    getTextInput,
} from "../../../../components/form";
import config from "../../../../config";
import { setProductState } from "../../../../slices/products";
import useProductQuickAdd from "./useProductQuickAdd";
import { useDispatch } from "react-redux";
import { NAME_PREFIX, DESCRIPTION_PREFIX, SHORT_DESCRIPTION_PREFIX, JODIT_EDITOR_PROPS } from "../../constants";
import { allStringsEmpty } from "../../helper";
import { useState } from "react";
import { useEffect } from "react";
import JoditEditor from "components/jodit-editor";

const DESCRIPTION_FIELD_KEY = "description-field-";

function ProductQuickAdd(props: any = {}): JSX.Element {
    const {
        form,
        onValuesChange,
        lan,
        getModalLanguageSelect,
        categoryOptions,
        getOptions,
        setValues,
        onFinish,
        history,
        values,
    } = useProductQuickAdd(props);

    const [resizeJoditEditor, setResizeJoditEditor] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setResizeJoditEditor(true);
    }, [lan]);

    useEffect(() => {
        if (resizeJoditEditor) {
            setResizeJoditEditor(false);
        }
    }, [resizeJoditEditor]);

    const goToAdvanced = () => {
        // @ts-ignore
        dispatch(setProductState({ addEditItemDuplicate: form.getFieldsValue() }));
        history.push("/products/addEditItem?duplicate=1");
    };

    const renderFormContents = (
        <React.Fragment>
            <Row gutter={16}>
                {config.LANGUAGES.map((l) => (
                    <Col key={NAME_PREFIX + l} flex={12} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem("item_title", getTextInput({ name: NAME_PREFIX + l, allowClear: true }))}
                    </Col>
                ))}
                <Col>{getFormItem("price", getNumberInput("price"))}</Col>
            </Row>
            <Row gutter={16}>
                <Col flex={12}>
                    {getFormItem("categories", getMultiSelectInput("category_ids", false, categoryOptions))}
                </Col>
                <Col>
                    {getFormItem("tax_class", getSelectInput("tax_class", false, getOptions(config.TAX_CLASS_OPTIONS)))}
                </Col>
            </Row>
            <div>
                {config.LANGUAGES.map((l) => (
                    <div key={SHORT_DESCRIPTION_PREFIX + l} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem(
                            "short_description",
                            getTextInput({ name: SHORT_DESCRIPTION_PREFIX + l, rows: 2, allowClear: true })
                        )}
                    </div>
                ))}
                {config.LANGUAGES.map((l) => (
                    <div key={DESCRIPTION_FIELD_KEY + l} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem(
                            "description",
                            <JoditEditor
                                {...JODIT_EDITOR_PROPS}
                                id={DESCRIPTION_FIELD_KEY + l}
                                value={props?.values?.["description_" + l] ?? ""}
                                onChange={(content: string) => {
                                    form.setFieldsValue({ [DESCRIPTION_PREFIX + l]: content });
                                    setValues((prev: any) => ({ ...prev, [DESCRIPTION_PREFIX + l]: content }));
                                }}
                                resize={resizeJoditEditor}
                            />
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );

    const shouldDisable = () => {
        return allStringsEmpty(
            Object.keys(values ?? {})
                .filter((k: string) => k.startsWith(NAME_PREFIX))
                .map((k: string) => values?.[k])
        );
    };

    return (
        <div className="product-quick-add">
            <div className="modal-lan-select">{getModalLanguageSelect()}</div>
            {/* 3 : tax class gst */}
            <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish} initialValues={{ tax_class: 3 }}>
                {renderFormContents}
                <div className="product-quick-add-bottom-row">
                    <Button style={{ marginRight: 12 }} onClick={goToAdvanced}>
                        <FormattedMessage id="advanced_mode" />
                    </Button>
                    <Button type="primary" htmlType="submit" className="save-button-handler" disabled={shouldDisable()}>
                        <FormattedMessage id="submit" />
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default ProductQuickAdd;
