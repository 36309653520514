import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import "./order-list-list.scss";
import Pagination from "../pagination/order-list-pagination";
import ListGroup from "./order-list-list-group";
import Empty from "../empty";

class OrderListTable extends Component {
    state = {};

    isEmpty = () => _.isEmpty(this.getList());

    getList = () => (this.props.orderList ? this.props.orderList : {});

    renderList = () => {
        return (
            <div className="order-list-list-wrapper">
                {Object.keys(this.getList()).map((key) => {
                    const list = Array.isArray(_.get(this.getList(), `${key}.list`))
                        ? _.get(this.getList(), `${key}.list`)
                        : [];
                    const title = _.get(this.getList(), `${key}.title`) ? _.get(this.getList(), `${key}.title`) : {};
                    return <ListGroup key={key} title={title} list={list} />;
                })}
            </div>
        );
    };

    renderEmpty = () => {
        return <Empty {...this.props} />;
    };

    renderPagination = () => {
        return <Pagination />;
    };

    render() {
        return (
            <div>
                <div className="order-list-wrapper" style={{ height: this.props.height }}>
                    {this.isEmpty() ? this.renderEmpty() : this.renderList()}
                </div>
                {this.renderPagination()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTable));
