import { Button as AntdButton, Modal } from "antd";
import _ from "lodash";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import _actions from "slices/live-order";
import { setDoordashDeliveryDetails } from "slices/store";
import { cancelDoordashDelivery } from "../../../../services/store";
//import { setDoordashDeliveryDetails } from "../../../../../src/slices/store";
import "./_cancel-delivery-modal.scss";
import errorIMG from "../../../../images/icons/box-important.png";
import actions from "slices/order-history";
class CancelDeliveryModal extends Component {
    getOrderId = () => _.get(this.props.order, "ord_id");
    getDeliveryId = () => _.get(this.props.order, "delivery_id");

    renderConfirmFooter = () => {
        return (
            <div className="footer-confirm-button">
                <AntdButton
                    size="large"
                    type="primary"
                    style={{ justifyContent: "center", width: "200px" }}
                    onClick={() => {
                        this.props.setDoordashDeliveryDetails({ name: "isCancelled", value: false });
                        this.props.setDoordashDeliveryDetails({ name: "cancelFailure", value: false });
                    }}
                >
                    <b>
                        <FormattedMessage id="ok" />
                    </b>
                </AntdButton>
            </div>
        );
    };

    renderFailFooter = () => {
        return (
            <div className="footer-confirm-button">
                <AntdButton
                    size="large"
                    type="primary"
                    style={{ justifyContent: "center", width: "200px" }}
                    onClick={() => {
                        this.props.setDoordashDeliveryDetails({ name: "isCancelled", value: false });
                        this.props.setDoordashDeliveryDetails({ name: "cancelFailure", value: false });
                    }}
                >
                    <b>
                        <FormattedMessage id="ok" />
                    </b>
                </AntdButton>
            </div>
        );
    };

    cancellationErrorModal = () => {
        <Modal footer={this.renderConfirmFooter()}></Modal>;
    };

    onCloseClick = () => {
        if (this.props.onCloseClick) this.props.onCloseClick();
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: "",
        });
        this.props.setOrderState({
            activeId: this.getOrderId(),
            modelController: "",
        });
    };

    cancelDoordashDelivery = () => {
        this.props.cancelDoordashDelivery({ order_id: this.getOrderId(), delivery_id: this.getDeliveryId() });
        this.onCloseClick();
    };

    renderFooter = () => {
        return (
            <div className="eta-dialog-buttons">
                <div className="eta-dialog-button">
                    <AntdButton size="large" onClick={() => this.onCloseClick()}>
                        <b>
                            {" "}
                            <FormattedMessage id="cancel" />
                        </b>
                    </AntdButton>
                </div>
                <div className="eta-dialog-button">
                    <AntdButton
                        size="large"
                        type="primary"
                        onClick={() => {
                            this.cancelDoordashDelivery();
                        }}
                    >
                        <b>
                            <FormattedMessage id="confirm" />
                        </b>
                    </AntdButton>
                </div>
            </div>
        );
    };
    renderCancelFailureBody = () => {
        return (
            <div className="cancel-failure-body">
                <div className="cancel-failure-subtitle-body">
                    <img
                        src={errorIMG}
                        alt="picture"
                        style={{ maxWidth: "35x", maxHeight: "35px", marginRight: "13px", marginLeft: "-10px" }}
                    />
                    <div>
                        <FormattedMessage id="cancel_failure_subtitle_one" /> <br />
                        <span className="cancel-failure-phone-number">855-599-7099</span>
                        <FormattedMessage id="cancel_failure_subtitle_two" />
                    </div>
                </div>
                <div className="cancel-failure-list-title">
                    <FormattedMessage id="cancel_failure_list_title" />
                </div>
                <div className="cancel-failure-list">
                    <ul>
                        <li>
                            <FormattedMessage id="cancel_failure_doordash_id" />
                            <span className="cancel-failure-list-point">{this.props.deliveryID}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_customer_name" />
                            <span className="cancel-failure-list-point">{this.props?.order?.c_name}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_customer_phone_number" />
                            <span className="cancel-failure-list-point">{this.props?.order?.c_tn}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_store_name" />
                            <span className="cancel-failure-list-point">{this.props?.order?.st_name}</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };
    render() {
        let { intl } = this.props;

        let { formatMessage } = intl;

        return (
            <>
                <Modal
                    width={400}
                    visible={this.props.doordashDeliveryDetails.cancelFailure}
                    footer={this.renderFailFooter()}
                    closable={false}
                    title={formatMessage({ id: "failed_cancel_delivery_request" })}
                >
                    {this.renderCancelFailureBody()}
                </Modal>
                <Modal
                    width={400}
                    visible={this.props.doordashDeliveryDetails.isCancelled}
                    footer={this.renderConfirmFooter()}
                    onCancel={() => this.onCloseClick()}
                    title={formatMessage({ id: "request_cancelled" })}
                >
                    <div>
                        <div>
                            <FormattedMessage id="successful_cancellation" />
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={this.props.showModal}
                    footer={this.renderFooter()}
                    title={formatMessage({ id: "cancellation_confirm_title" })}
                    onCancel={() => this.onCloseClick()}
                >
                    <div>
                        <FormattedMessage id="cancel_confirmation_msg" />
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    liveOrderState: _.get(state, "liveOrders", {}),
    doordashDeliveryDetails: _.get(state, "store.doordashDeliveryDetails", {}),
    ownProps: ownProps,
});

const mapDispatchToProps = {
    setState: _actions.setState,
    setOrderState: actions.setState,
    cancelDoordashDelivery: cancelDoordashDelivery,
    setDoordashDeliveryDetails: setDoordashDeliveryDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CancelDeliveryModal));
