import React, { useEffect } from "react";
import { isBool } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput } from "./payment-functions";
import { getPreviouslySetCredentialCode, getPreviouslySetMerchantCode } from "../helper";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { unionpay_enabled, unionpay_merch_id, unionpay_credential } = paymentOptions;

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, []);

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, [unionpay_enabled]);

    const copyDataOfCertainBlankFields = () => {
        if (!unionpay_enabled) {
            return;
        }

        if (!unionpay_merch_id) {
            setState("unionpay_merch_id", getPreviouslySetMerchantCode(paymentOptions));
        }
        if (!unionpay_credential) {
            setState("unionpay_credential", getPreviouslySetCredentialCode(paymentOptions));
        }
    };

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop("unionpay_debit", "unionpay_debit_desc", "unionpay_enabled", unionpay_enabled, switchChange)}
            {unionpay_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("merchant_id", "unionpay_merch_id", unionpay_merch_id, setState, false, true)}
                    {renderHalfTxtInput(
                        "credential",
                        "unionpay_credential",
                        unionpay_credential,
                        setState,
                        false,
                        true
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default App;
