import React, { useState } from "react";
import { Button, Popover, Switch, Modal } from "antd";
import _ from "lodash";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FaRulerHorizontal } from "react-icons/fa";
import { RootState } from "../../../../../../app/reducer";
import RuleModal from "./rule-modal";
import { switchNum, isBool } from "../../../../../../util/helper";
import { setC2CProps, fillC2CProps, clearC2CProps } from "../../../../../../slices/shipping";
import { setShippingState } from "../../../../../../slices/store";

function emptyFunction() {
    // does nothing
}

function App(): null | JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const expressEnable = useSelector((state: RootState) => state.store?.storeShipping?.c2cExpShip?.enable);
    const expressSettings = useSelector((state: RootState) => state.store?.storeShipping?.c2cExpShip?.settings);
    const regularEnable = useSelector((state: RootState) => state.store?.storeShipping?.c2cRegShip?.enable);
    const regularSettings = useSelector((state: RootState) => state.store?.storeShipping?.c2cRegShip?.settings);
    const c2cProps = useSelector((state: RootState) => state.shipping?.C2CProps);
    const expressActive = useSelector((state: RootState) => state.shipping?.C2CProps?.express);
    const ruleModalOpen = useSelector((state: RootState) => state.shipping?.C2CProps?.ruleModalOpen);
    const [editTable, setEditTable] = useState<boolean>(false);
    const cols = ["country", "price", "min_shipping_days", "max_shipping_days", "action"];

    const setState = (name: string, value: any) => {
        dispatch(setShippingState({ name, value }));
    };
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value) }));
    const setC2CState = (name: string, value: any) => dispatch(setC2CProps({ name, value }));

    const addRules = () => {
        const rulesList = _.cloneDeep(expressActive ? expressSettings : regularSettings) ?? [];
        const newRule = _.cloneDeep(c2cProps);
        newRule.country ? newRule.country : (newRule.country = "US");
        newRule.price ? newRule.price : (newRule.price = 0.0);
        newRule.min_day ? newRule.min_day : (newRule.min_day = 0);
        newRule.max_day ? newRule.max_day : (newRule.max_day = 0);
        delete newRule.ruleModalOpen;
        delete newRule.express;
        const index = newRule.index;
        if (index && rulesList[index] !== undefined) {
            // update the existing rule
            rulesList.splice(index, 1, newRule);
        } else {
            editTable ? rulesList.splice(index, 1, newRule) : rulesList.push({ ...newRule });
        }
        delete newRule.index;
        setState(expressActive ? "c2cExpShip.settings" : "c2cRegShip.settings", rulesList ?? []);
        setC2CState("ruleModalOpen", false);
        dispatch(clearC2CProps());
        setEditTable(false);
    };

    const deleteRule = (index: number, express: boolean) => {
        const newRules = _.cloneDeep(express ? expressSettings : regularSettings) ?? [];
        if (newRules.length === 1) {
            setState(express ? "c2cExpShip.settings" : "c2cRegShip.settings", []);
        } else {
            newRules.splice(index, 1);
            setState(express ? "c2cExpShip.settings" : "c2cRegShip.settings", newRules ?? []);
        }
    };

    const editRule = (rule: any, index: number, express: boolean) => {
        setEditTable(true);
        dispatch(fillC2CProps({ ...rule, ruleModalOpen: true, index, express }));
    };

    const actions = (rule: any, index: number, express: boolean) => (
        <React.Fragment>
            <p className="popover-item" onClick={() => editRule(rule, index, express)}>
                <FormattedMessage id="edit_rule" />
            </p>
            <p className="popover-item" onClick={() => deleteRule(index, express)}>
                <FormattedMessage id="delete_rule" />
            </p>
        </React.Fragment>
    );

    const renderTableActions = (rule: any, index: number, express: boolean) => (
        <div>
            <Popover content={actions(rule, index, express)} placement="left">
                <HiDotsHorizontal size="19" color="#3898c8" className="customers-actions-button" />
            </Popover>
        </div>
    );

    const renderRows = (express: boolean) => {
        const settingz = _.cloneDeep(express ? expressSettings : regularSettings);
        return (
            <React.Fragment>
                {Array.isArray(settingz) && settingz?.length > 0
                    ? settingz
                          ?.filter((el) => !el.delete)
                          .map((rule: any, index: number) => (
                              <div key={`table-rule-row-key-${index}`} className="table-rule-row pHover d-flex">
                                  <div className="d-flex w100" onClick={emptyFunction}>
                                      <div style={{ flex: "1 1 20%" }}>{rule?.country}</div>
                                      <div style={{ flex: "1 1 20%" }}>$ {rule?.price}</div>
                                      <div style={{ flex: "1 1 20%" }}>
                                          {rule?.min_day} <FormattedMessage id="days" />
                                      </div>
                                      <div style={{ flex: "1 1 20%" }}>
                                          {rule?.max_day} <FormattedMessage id="days" />
                                      </div>
                                      <div style={{ flex: "1 1 20%" }}>{renderTableActions(rule, index, express)}</div>
                                  </div>
                              </div>
                          ))
                    : renderEmptyWarn}
            </React.Fragment>
        );
    };

    const renderHeader = (cols: any) => (
        <div className="w100 table-header d-flex">
            {cols.map((col: string, index: number) => (
                <div key={`table-header-col-key-${index}`} style={{ flex: "1 1 20%" }}>
                    <FormattedMessage id={col ?? " "} />
                </div>
            ))}
        </div>
    );

    const renderEmptyWarn = (
        <div className="shipping-inactive-empty-warn w100">
            <FaRulerHorizontal size="50" color="lightgrey" className="mb-2" />
            <FormattedMessage id={"no_rules"} />
        </div>
    );

    const renderRules = (express: boolean) => (
        <div className="w100">
            {renderHeader(cols)}
            {renderRows(express)}
        </div>
    );

    const openSettingModal = (title: string) => {
        title === "express_shipping" ? setC2CState("express", true) : setC2CState("express", false);
        setC2CState("ruleModalOpen", true);
    };

    const renderEnableSection = (title: string, name: string, value: any) => (
        <div className="w100">
            <div className="text-subhead mb-2">
                <FormattedMessage id={title ?? " "} />
            </div>
            <div className="w100 d-flex align-items-center justify-content-between mb-3">
                <div className="enableSwitch">
                    <Switch checked={isBool(value)} onChange={() => switchChange(name, value)} />
                    <div className="payment-subtitle ml-3 mt-1">
                        <FormattedMessage id="enable" />
                    </div>
                </div>
                <Button onClick={() => openSettingModal(title)} type="primary">
                    <FormattedMessage id="add_rule" />
                </Button>
            </div>
        </div>
    );

    const getTitle = () => {
        return editTable
            ? expressActive
                ? "edit_c2c_express_rule"
                : "edit_c2c_regular_rule"
            : expressActive
            ? "add_c2c_express_rule"
            : "add_c2c_regular_rule";
    };

    const renderRuleModal = (
        <Modal
            visible={ruleModalOpen}
            title={intl.formatMessage({ id: getTitle() })}
            width="800px"
            onOk={() => addRules()}
            okText={intl.formatMessage({ id: editTable ? "update_rule" : "add_rule" })}
            onCancel={() => setC2CState("ruleModalOpen", false)}
        >
            <RuleModal />
        </Modal>
    );

    return _.isEmpty(c2cProps) ? null : (
        <React.Fragment>
            <div className="white-layered-block table-rate-tab">
                {renderEnableSection("regular_shipping", "c2cRegShip.enable", regularEnable)}
                {renderRules(false)}
            </div>
            <div className="white-layered-block table-rate-tab">
                {renderEnableSection("express_shipping", "c2cExpShip.enable", expressEnable)}
                {renderRules(true)}
            </div>
            {renderRuleModal}
        </React.Fragment>
    );
}

export default App;
