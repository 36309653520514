import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Tooltip, Button, Pagination } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import h from "../helper";
import config from "./../../../config";
import { isBool } from "../../../util/helper";
import {
    getCancelButton,
    getTextfield,
    revExists,
    getRating,
    showProductReplyField,
    showServiceReplyField,
    getStoreProfile,
    sendProductButton,
    sendServiceButton,
    replyHandler,
    getCustomerProfile,
    renderEmptyWarn,
} from "./review-functions";
import { getReviews, reviewReply } from "../../../services/reviews";
import { setFilters, setScope } from "../../../slices/reviews";

const ReviewTab = (props: any = {}): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const storeInfo = useSelector((state: RootState) => state?.store?.storeInformation);
    const tabReviews = useSelector((state: RootState) => state?.reviews);
    const searchValue = useSelector((state: RootState) => state?.reviews?.searchValue) ?? undefined;
    const lan = useSelector((state: RootState) => state?.setting?.lan ?? "en");
    const filters = useSelector((state: RootState) => state?.reviews?.filters) ?? {};
    const totalReviews = useSelector((state: RootState) => state?.reviews?.totalReviews) ?? 0;
    const storeTimezone = useSelector((state: RootState) => state?.store?.storeInformation?.address_time_zone);
    const [currReview, setCurrReview] = useState<any>(undefined);
    const [currComment, setCurrComment] = useState<string>();
    const [hovered, setHovered] = useState<number>(NaN);
    const reviewTypes = ["service", "description", "delivery", "general"];
    const tabTypes = ["orderReviews", "storeReviews", "productReviews"];
    const currTabReviews = _.get(tabReviews, `${tabTypes[props?.props]}`);
    const storeName = _.get(storeInfo, `store_nm_${lan}`, "Store");
    const timezone = storeTimezone ?? "America/Vancouver";
    const scope = props?.props + 1;

    useEffect(() => {
        dispatch(setScope({ scope }));
        dispatch(getReviews({ scope, ...filters }));
    }, [filters, scope, dispatch]);

    const sendProductReview = (review: any, customerReview: any) => {
        const currReviewCpy = _.cloneDeep(customerReview);
        currReviewCpy.approved = true;
        currReviewCpy?.p_review?.map((productReview: any) => {
            if (productReview?.pid === review?.pid) {
                productReview.s_response = currComment;
                productReview.approved = true;
            }
        });
        delete currReviewCpy?.s_review;
        dispatch(reviewReply({ review: currReviewCpy, scope }));
        setCurrReview(undefined);
        setCurrComment("");
    };

    const sendServiceReview = (customerReview: any, type: string) => {
        const currReviewCpy = _.cloneDeep(customerReview);
        _.set(currReviewCpy, `s_review.${type}.s_response`, currComment);
        _.set(currReviewCpy, `s_review.${type}.approved`, true);
        delete currReviewCpy?.p_review;
        dispatch(reviewReply({ review: currReviewCpy, scope }));
        setCurrReview(undefined);
        setCurrComment("");
    };

    const toggleProductApproval = (review: any, customerReview: any, approved: any) => {
        const currReviewCpy = _.cloneDeep(customerReview);
        currReviewCpy?.p_review?.map((productReview: any) => {
            if (productReview?.pid === review?.pid) {
                productReview.approved = !isBool(approved);
            }
        });
        dispatch(reviewReply({ review: currReviewCpy, scope, approved: true }));
    };

    const toggleServiceApproval = (review: any, approved: any, type?: string) => {
        const currReviewCpy = _.cloneDeep(review);
        currReviewCpy.approved = true;
        _.set(currReviewCpy, `s_review.${type}.approved`, !isBool(approved));
        dispatch(reviewReply({ review: currReviewCpy, scope, approved: true }));
    };

    const getReplyButton = (props: any, reply: boolean) => (
        <Button
            className="mr-2 text-on-white-background"
            type="default"
            onClick={() => replyHandler(props, setCurrReview, setCurrComment)}
        >
            <FormattedMessage id={reply ? "reply" : "edit"} />
        </Button>
    );

    const getServiceReview = (type: string, customerReview: any) => {
        const id = scope === h.ORDER_SCOPE ? customerReview?.oid : customerReview?.cid;
        const showReplyField = showServiceReplyField(id, type, currReview, scope);
        const hasReply = _.get(customerReview, `s_review.${type}.reply`, false);
        return (
            <div>
                <div className="single-review w100 d-flex justify-content-between">
                    <div className="review-detail-col d-flex flex-column">
                        <div className="review-subhead ">
                            {`${intl.formatMessage({ id: type })}:`}
                            {getRating(_.get(customerReview, `s_review.${type}.rat`, 3))}
                        </div>
                        <div className="review-content">
                            {Object.values(_.get(customerReview, `s_review.${type}.s_cmt`, {}))?.map(
                                (item: any, index) => <div key={`review-content-key-${index}`}>{item}</div>
                            ) ?? <FormattedMessage id="user_didnt_leave_rating" />}
                        </div>
                        {showReplyField && !hasReply ? getTextfield(currComment, setCurrComment) : null}
                    </div>
                    <div className="review-date-col d-flex flex-column justify-content-start align-items-end w100">
                        {_.get(customerReview, `s_review.${type}.upd_dt`, "")
                            ? moment(_.get(customerReview, `s_review.${type}.upd_dt`, ""))
                                  .tz(timezone)
                                  .format("MMMM D, YYYY, h:mm A")
                            : ""}
                        {getServiceReviewActions(type, customerReview, id, showReplyField, hasReply)}
                    </div>
                </div>
                {_.get(customerReview, `s_review.${type}.reply`, false)
                    ? getServiceReplies(customerReview, type)
                    : null}
            </div>
        );
    };

    const getServiceReviewActions = (
        type: string,
        customerReview: any,
        id: any,
        showReplyField: any,
        hasReply: any
    ) => {
        const approved =
            scope === h.ORDER_SCOPE
                ? _.get(customerReview, `s_review.${type}.approved`, false)
                : customerReview?.approved;
        const isHovered = () => id === hovered;
        return (
            <div className="comment-actions mt-2 mb-1">
                {!currReview && !hasReply ? getReplyButton({ full: customerReview, type }, true) : null}
                {currReview && showReplyField && !hasReply
                    ? sendServiceButton(customerReview, type, sendServiceReview)
                    : null}
                {showReplyField && !hasReply ? getCancelButton(setCurrReview) : null}
                <div
                    className={`approveButton ${approved ? "" : "false"}`}
                    onMouseEnter={() => setHovered(id)}
                    onMouseLeave={() => setHovered(NaN)}
                    onClick={() => toggleServiceApproval(customerReview, approved, type)}
                >
                    <FormattedMessage
                        id={
                            approved && !isHovered()
                                ? "approved"
                                : approved && isHovered()
                                ? "unapprove"
                                : !approved && !isHovered()
                                ? "unapproved"
                                : "approve"
                        }
                    />
                </div>
            </div>
        );
    };

    const getServiceReplies = (customerReview: any, type: string) => {
        const id = scope === h.ORDER_SCOPE ? customerReview?.oid : customerReview?.cid;
        const showReplyField = showServiceReplyField(id, type, currReview, scope);
        return (
            <div className="d-flex">
                {getStoreProfile(storeName)}
                <div className="single-review w100 d-flex justify-content-between">
                    <div className="review-detail-col d-flex flex-column ml-2">
                        <div className="review-head d-flex align-items-center">{storeName}</div>
                        <div className="review-content">{_.get(customerReview, `s_review.${type}.reply.cmt`, "")}</div>
                        {showReplyField ? getTextfield(currComment, setCurrComment) : null}
                    </div>
                    <div className="review-date-col d-flex flex-column justify-content-start align-items-end w100">
                        {_.get(customerReview, `s_review.${type}.reply.upd_dt`, "")
                            ? moment(_.get(customerReview, `s_review.${type}.reply.upd_dt`, ""))
                                  .tz(timezone)
                                  .format("MMMM D, YYYY, h:mm A")
                            : ""}
                        {getServiceReplyActions(customerReview, type, showReplyField)}
                    </div>
                </div>
            </div>
        );
    };

    const getServiceReplyActions = (customerReview: any, type: string, showReplyField: any) => (
        <div className="comment-actions mt-2 mb-1">
            {!currReview ? getReplyButton({ full: customerReview, type }, false) : null}
            {currReview && showReplyField ? sendServiceButton(customerReview, type, sendServiceReview) : null}
            {showReplyField ? getCancelButton(setCurrReview) : null}
        </div>
    );

    const getProductReview = (review: any, customerReview: any) => {
        const showReplyField = showProductReplyField(customerReview?.oid, review?.pid, currReview);
        const hasReply = review?.reply;
        return (
            <div>
                <div className="single-review w100 d-flex justify-content-between">
                    <div className="review-detail-col d-flex flex-column">
                        <a
                            className="review-subhead link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${config.H5_URL}product/${review?.pid}?lan=${lan}`}
                        >
                            <Tooltip title={intl.formatMessage({ id: "click_open_product_page" })}>
                                {review?.name}: {getRating(review?.rat)}
                            </Tooltip>
                        </a>
                        <div className="review-content">
                            {scope === h.ORDER_SCOPE
                                ? Object.values(review?.s_cmt ?? {})?.map((item: any, index) => (
                                      <div key={`reveiw-content-${index}`}>{item}</div>
                                  )) ?? <FormattedMessage id="user_didnt_leave_rating" />
                                : <div>{review?.cmt}</div> ?? <FormattedMessage id="user_didnt_leave_rating" />}
                        </div>
                        {showReplyField && !hasReply ? getTextfield(currComment, setCurrComment) : null}
                    </div>
                    <div className="review-date-col d-flex flex-column justify-content-start align-items-end w100">
                        {review?.upd_dt ? moment(review?.upd_dt).tz(timezone).format("MMMM D, YYYY, h:mm A") : ""}
                        {getProductReviewActions(review, customerReview, showReplyField, hasReply)}
                    </div>
                </div>
                {review?.reply ? getProductReplies(review, customerReview) : null}
            </div>
        );
    };

    const getProductReviewActions = (review: any, customerReview: any, showReplyField: boolean, hasReply: boolean) => {
        const approved = review?.approved;
        const id = review?.pid;
        const isHovered = () => id === hovered;
        return (
            <div className="comment-actions mt-2 mb-1">
                {!currReview && !hasReply
                    ? getReplyButton({ full: customerReview, specific: review?.pid }, true)
                    : null}
                {currReview && showReplyField && !hasReply
                    ? sendProductButton(review, customerReview, sendProductReview)
                    : null}
                {showReplyField && !hasReply ? getCancelButton(setCurrReview) : null}
                <div
                    className={`approveButton ${approved ? "" : "false"}`}
                    onMouseEnter={() => setHovered(id)}
                    onMouseLeave={() => setHovered(NaN)}
                    onClick={() => toggleProductApproval(review, customerReview, approved)}
                >
                    <FormattedMessage
                        id={
                            approved && !isHovered()
                                ? "approved"
                                : approved && isHovered()
                                ? "unapprove"
                                : !approved && !isHovered()
                                ? "unapproved"
                                : "approve"
                        }
                    />
                </div>
            </div>
        );
    };

    const getProductReplyActions = (review: any, customerReview: any, showReplyField: boolean) => (
        <div className="comment-actions mt-2 mb-1">
            {!currReview ? getReplyButton({ full: customerReview, specific: review?.pid }, false) : null}
            {currReview && showReplyField ? sendProductButton(review, customerReview, sendProductReview) : null}
            {showReplyField ? getCancelButton(setCurrReview) : null}
        </div>
    );

    const getProductReplies = (review: any, customerReview: any) => {
        const showReplyField = showProductReplyField(customerReview?.oid, review?.pid, currReview);
        return (
            <div className="d-flex">
                {getStoreProfile(storeName)}
                <div className="single-review w100 d-flex justify-content-between">
                    <div className="review-detail-col d-flex flex-column ml-2">
                        <div className="review-head d-flex align-items-center">{storeName}</div>
                        <div className="review-content">{review?.reply?.cmt ?? ""}</div>
                        {showReplyField ? getTextfield(currComment, setCurrComment) : null}
                    </div>
                    <div className="review-date-col d-flex flex-column justify-content-start align-items-end w100">
                        {review?.reply?.upd_dt
                            ? moment(review?.reply?.upd_dt).tz(timezone).format("MMMM D, YYYY, h:mm A")
                            : ""}
                        {getProductReplyActions(review, customerReview, showReplyField)}
                    </div>
                </div>
            </div>
        );
    };

    const getReview = (customerReview: any, i: number) => (
        <div className="w100 full-review d-flex mb-1">
            {customerReview?.c_name ? getCustomerProfile(customerReview?.c_name, i) : getCustomerProfile("A", i)}
            <div className="ml-2 w100">
                <div className="review-head">{customerReview?.c_name ?? intl.formatMessage({ id: "anonymous" })}</div>
                {scope !== h.ORDER_SCOPE ? null : (
                    <div className="review-subhead mb-1">
                        <FormattedMessage id="order_id" />
                        {customerReview.oid}
                    </div>
                )}
                {customerReview?.p_review
                    ? customerReview?.p_review?.map((review: any) =>
                          revExists(review) ? getProductReview(review, customerReview) : null
                      )
                    : null}
                {reviewTypes?.map((type: string) => {
                    if (_.get(customerReview, `s_review.${type}`, false)) {
                        return getServiceReview(type, customerReview);
                    }
                })}
            </div>
        </div>
    );

    const renderPagination = () => {
        const onPageSizeChange = (current: number, pageSize: number) => {
            dispatch(setFilters({ name: "page_size", value: pageSize }));
        };
        const onPageChange = (page: number) => {
            dispatch(setFilters({ name: "page", value: page }));
        };
        return (
            <div className="w100 d-flex justify-content-end mt-3">
                <Pagination
                    showSizeChanger
                    onShowSizeChange={onPageSizeChange}
                    onChange={onPageChange}
                    defaultCurrent={filters?.page}
                    defaultPageSize={filters?.page_size}
                    pageSizeOptions={["10", "20", "50", "100"]}
                    total={totalReviews}
                />
                <div className="pagination-total">
                    <FormattedMessage id="total" />: {totalReviews}
                </div>
            </div>
        );
    };

    const getTabReviews = () => {
        const doesInclude = (val: string, x?: boolean) => {
            return x ? (val + "").indexOf(searchValue) > -1 : val?.toLowerCase()?.includes(searchValue?.toLowerCase());
        };
        const filterReviews = (review: any) => {
            if (scope === h.PRODUCT_SCOPE && Array.isArray(review?.p_review?.[0]?.name)) {
                return false;
            }
            return searchValue
                ? doesInclude(review?.c_name ?? "")
                    ? true
                    : doesInclude(review?.oid ?? "", true)
                    ? true
                    : false
                : true;
        };
        return currTabReviews
            ?.filter((rev: any) => filterReviews(rev))
            ?.map((review: any, index: number) => getReview(review, index));
    };

    return (
        <div className="w100 white-layered-block">
            {!_.isEmpty(currTabReviews) ? getTabReviews() : renderEmptyWarn}
            {!_.isEmpty(currTabReviews) ? renderPagination() : null}
        </div>
    );
};

export default ReviewTab;
