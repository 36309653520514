import React, { Component } from "react";
import _ from "lodash";
import helper from "util/helper";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import StyledCheckbox from "components/styled-checkbox";
import StyledMultiplePicker from "components/styled-mutiple-picker";
import StyledButton from "components/style-option-picker-button-style";
import StyleMupltipleButton from "components/style-option-mupltiple-picker-button-style";
import "./_options-picker.scss";
import cx from "classnames";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Collapse } from "@material-ui/core";

const TITLE_REF = "option-title-ref-prefix-";

class ItemEditorDialog extends Component {
    state = {};

    setDefaultPicked = () => {
        const options = Array.isArray(_.get(this.props.product, "opt")) ? _.get(this.props.product, "opt") : [];
        options.forEach((option) => {
            const subOptions = Array.isArray(_.get(option, "opts")) ? _.get(option, "opts") : [];
            if (!this.isMultipleSubOption(option) && subOptions.length > 0) {
                this.handleOptionSelection(subOptions[0], option);
            }
        });
    };

    str = (id) => {
        const { lan } = this.props;
        return helper.getTransString(id, lan);
    };

    isMultipleSubOption = (option) => {
        return String(_.get(option, "qty_input")) === "1" || _.get(option, "qty_input");
    };

    getName = (nm) => {
        return helper.getTransString(nm, this.props.lan);
    };

    validateOptions = (currentSelected = {}) => {
        currentSelected = this.formatOptions(currentSelected);

        const product = this.props.product;
        let invalids = {};
        const options = Array.isArray(_.get(product, "opt", [])) ? _.get(product, "opt", []) : [];

        const validateSubOption = (option, selectedSubOpts) => {
            let subOptionInvalid = [];
            if (this.isMultipleSubOption(option)) {
                const subOptions = Array.isArray(option.opts) ? option.opts : [];
                subOptions.forEach((subOpt) => {
                    const subOptMin = _.get(subOpt, "min", 0);
                    const subOptMax = _.get(subOpt, "max", 1);
                    const subOptId = _.get(subOpt, "id", 0);
                    const selectedSubOpt =
                        selectedSubOpts.filter((selectedSubOpt) => selectedSubOpt.length === subOptId).length || 0;
                    if (selectedSubOpt < subOptMin || selectedSubOpt > subOptMax) subOptionInvalid.push(subOptId);
                });
            }
            return subOptionInvalid;
        };

        Object.keys(currentSelected).forEach((id) => {
            const option = options.find((opt) => String(opt.id) === String(id));
            const min = _.get(option, "min", 0);
            const max = _.get(option, "max", 0);
            const selectedSubOpts = currentSelected[id] || [];
            if (selectedSubOpts.length < min || (selectedSubOpts.length > max && option)) {
                invalids[option.id] = validateSubOption(option, selectedSubOpts);
            }
        });
        return invalids;
    };

    getPickedOptions = () => (Array.isArray(_.get(this.props, "picked", [])) ? _.get(this.props, "picked", []) : []);

    getFormattedOptions = () => {
        return this.formatOptions(this.getPickedOptions());
    };

    getPickedOption = (option = {}) => {
        const currentPicks = this.getPickedOptions();
        var currentPickedOptIndex = -1;
        const currentPickedOpt = currentPicks.find((op, i) => {
            if (String(op.id) === String(option.id)) {
                currentPickedOptIndex = i;
                return true;
            }
            return false;
        });
        return {
            index: currentPickedOptIndex,
            object: currentPickedOpt,
        };
    };

    getSelectionMessage = (option) => {
        const max = _.get(option, "max", 1);
        const min = _.get(option, "min", 0);

        if (max === 1 && min === 1) {
            return "pick_one";
        } else if (min > 0 && max === min) {
            return "pick_amount";
        } else if (min > 0 && max > min) {
            return "pick_range_required";
        } else if (min === 0 && max === 1) {
            return "pick_optional";
        } else if (min === 0) {
            return "pick_range_up_to";
        }

        return "pick_one";
    };

    getSingleOptionPickedSubOptions = (option) => {
        const currentPickedOpt = this.getPickedOptions().find((opt) => opt.id === option.id);
        const currentPickedOptSubOpt = Array.isArray(_.get(currentPickedOpt, "opts", []))
            ? _.get(currentPickedOpt, "opts", [])
            : [];
        let allOpts = [];
        currentPickedOptSubOpt.map((subOpt) => allOpts.push(_.get(subOpt, "id", "")));
        return allOpts;
    };

    getSubOptQty = (subOption, option) => {
        const optionId = option.id;
        const subOptionId = subOption.id;
        const selected = this.getFormattedOptions();
        const selectedOption = Array.isArray(_.get(selected, optionId)) ? _.get(selected, optionId) : [];
        return selectedOption.filter((selectedSubOptionId) => selectedSubOptionId === subOptionId).length || 0;
    };

    formatOptions = (options) => {
        let result = {};
        options.forEach((option) => {
            const optionId = _.get(option, "id");
            const subOpts = Array.isArray(_.get(option, "opts")) ? _.get(option, "opts") : [];
            result[optionId] = subOpts.reduce((acc, subOpt) => {
                const qty = _.get(subOpt, "qty", 1);
                const subOptId = _.get(subOpt, "id");
                if (subOptId) {
                    acc = acc.concat(Array(qty).fill(subOptId));
                }
                return acc;
            }, []);
        });
        return result;
    };

    //sub options add/remove
    allowSubOptionAdd = (subOption, option) => {
        const max = _.get(option, "max", 1);
        const sq = _.get(subOption, "sq", 0);
        const subMax = _.get(subOption, "max", max);

        const optionId = option.id;
        const subOptionId = subOption.id;
        const prevSelected = this.getFormattedOptions();
        const prevOptions = Array.isArray(_.get(prevSelected, optionId)) ? _.get(prevSelected, optionId) : [];
        const prevSubOptions = prevOptions.filter((prevSubOptionId) => prevSubOptionId === subOptionId).length || 0;
        //check all options has reach option max
        if (prevOptions.length >= max) {
            return false;
        }
        //check has reached max allowed sub option value
        if (prevSubOptions >= subMax) {
            return false;
        }
        //check enough qty
        if (prevSubOptions + 1 > sq) {
            return false;
        }
        return true;
    };

    handleSubOptionAdd = (subOption, option, product) => {
        if (!this.allowSubOptionAdd(subOption, option, product)) {
            return;
        }
        var pickedOptions = _.clone(this.getPickedOptions());
        var pickedOption = _.clone(this.getPickedOption(option).object);
        var pickedOptionIndex = this.getPickedOption(option).index;
        var pickedSubOptions = Array.isArray(_.get(pickedOption, "opts", []))
            ? _.clone(_.get(pickedOption, "opts", []))
            : [];
        const subOptPickedIndex = _.findIndex(
            pickedSubOptions,
            (pickedSubOpt) => String(pickedSubOpt.id) === String(subOption.id)
        );
        const isSubOptPicked = subOptPickedIndex !== -1;
        if (pickedOptionIndex === -1) {
            //add new options
            pickedOptions.push({
                id: option.id,
                nm: option.nm,
                opts: [
                    {
                        nm: subOption.nm,
                        id: subOption.id,
                        qty: 1,
                        pcIn: subOption.pc, //internal price for calculation only
                    },
                ],
            });
        } else if (isSubOptPicked) {
            //picked sub opt remove
            const subOptPicked = _.get(pickedSubOptions, subOptPickedIndex);
            const qty = _.get(subOptPicked, "qty", 1);
            pickedSubOptions.splice(subOptPickedIndex, 1, {
                nm: subOption.nm,
                id: subOption.id,
                qty: qty + 1,
                pcIn: subOption.pc, //internal price for calculation only
            });
            pickedOption.opts = pickedSubOptions;
            pickedOptions.splice(pickedOptionIndex, 1, pickedOption);
        } else if (!isSubOptPicked) {
            //add new sub opt
            pickedOption.opts = [
                ...pickedSubOptions,
                {
                    nm: subOption.nm,
                    id: subOption.id,
                    qty: 1,
                    pcIn: subOption.pc, //internal price for calculation only
                },
            ];
            pickedOptions.splice(pickedOptionIndex, 1, pickedOption);
        }
        this.props.onChange(pickedOptions, this.validateOptions(pickedOptions));
        this.setState({
            errors: this.validateOptions(pickedOptions),
        });
    };

    allowSubOptionRemove = (subOption, option) => {
        const subMin = _.get(subOption, "min", 0);

        const optionId = option.id;
        const subOptionId = subOption.id;
        const prevSelected = this.getFormattedOptions();
        const prevOptions = Array.isArray(_.get(prevSelected, optionId)) ? _.get(prevSelected, optionId) : [];
        const prevSubOptions = prevOptions.filter((prevSubOptionId) => prevSubOptionId === subOptionId).length || 0;
        //check if selected
        if (prevSubOptions <= 0) {
            return false;
        }
        //check has reached min allowed sub option value
        if (prevSubOptions <= subMin) {
            return false;
        }
        return true;
    };

    handleSubOptionRemove = (subOption, option) => {
        if (!this.allowSubOptionRemove(subOption, option)) {
            return;
        }
        var pickedOptions = _.clone(this.getPickedOptions());
        var pickedOption = _.clone(this.getPickedOption(option).object);
        var pickedOptionIndex = this.getPickedOption(option).index;
        var pickedSubOptions = Array.isArray(_.get(pickedOption, "opts", []))
            ? _.clone(_.get(pickedOption, "opts", []))
            : [];
        const subOptPickedIndex = _.findIndex(
            pickedSubOptions,
            (pickedSubOpt) => String(pickedSubOpt.id) === String(subOption.id)
        );
        const isSubOptPicked = subOptPickedIndex !== -1;
        if (isSubOptPicked) {
            //picked sub opt remove
            const subOptPicked = _.get(pickedSubOptions, subOptPickedIndex);
            const qty = _.get(subOptPicked, "qty", 1);
            const newQty = qty - 1;
            if (newQty > 0) {
                pickedSubOptions.splice(subOptPickedIndex, 1, {
                    nm: subOption.nm,
                    id: subOption.id,
                    qty: newQty,
                    pcIn: subOption.pc, //internal price for calculation only
                });
                pickedOption.opts = pickedSubOptions;
            } else {
                pickedSubOptions.splice(subOptPickedIndex, 1);
                pickedOption.opts = pickedSubOptions;
            }
            pickedOptions.splice(pickedOptionIndex, 1, pickedOption);
        }
        this.props.onChange(pickedOptions, this.validateOptions(pickedOptions));
        this.setState({
            errors: this.validateOptions(pickedOptions),
        });
    };

    handleOptionSelection = (subOpt = {}, option = {}) => {
        const min = _.get(option, "min", 0);
        const max = _.get(option, "max", 0);
        var pickedOptions = _.clone(this.getPickedOptions());
        var pickedOption = _.clone(this.getPickedOption(option).object);
        var pickedOptionIndex = this.getPickedOption(option).index;
        var pickedSubOptions = Array.isArray(_.get(pickedOption, "opts")) ? _.clone(_.get(pickedOption, "opts")) : [];
        const isSubOptPicked = !_.isEmpty(
            pickedSubOptions.find((pickedSubOpt) => String(pickedSubOpt.id) === String(subOpt.id))
        );
        if (pickedOptionIndex === -1) {
            //add new options
            pickedOptions.push({
                id: option.id,
                nm: option.nm,
                opts: [
                    {
                        nm: subOpt.nm,
                        id: subOpt.id,
                        pcIn: subOpt.pc, //internal price for calculation only
                    },
                ],
            });
        } else if (isSubOptPicked && pickedSubOptions.length > min) {
            //picked sub opt remove
            pickedSubOptions = pickedSubOptions.filter((pickedSubOpt) => pickedSubOpt.id !== subOpt.id);
            pickedOption.opts = pickedSubOptions;
            pickedOptions.splice(pickedOptionIndex, 1, pickedOption);
        } else if (!isSubOptPicked) {
            //add new sub opt
            pickedOption.opts = [
                ...pickedSubOptions,
                {
                    nm: subOpt.nm,
                    id: subOpt.id,
                    pcIn: subOpt.pc, //internal price for calculation only
                },
            ];
            //remove one if too many
            if (pickedOption.opts.length > max) pickedOption.opts.shift();
            //update picked
            pickedOptions.splice(pickedOptionIndex, 1, pickedOption);
        }
        this.props.onChange(pickedOptions);
    };

    renderOptionsPicker = () => {
        const { product } = this.props;
        const options = Array.isArray(_.get(product, "opt")) ? _.get(product, "opt") : [];
        return (
            <div className="productContainerOptions">
                {options.map((option) => {
                    const opts = Array.isArray(_.get(option, "opts")) ? _.get(option, "opts") : [];
                    const optionId = _.get(option, "id");
                    const ref = `${TITLE_REF}${optionId}`;
                    return (
                        <div id={ref} className="product-container-option-list" key={optionId}>
                            <div className="product-container-option-pointer" />
                            {this.renderOptionTitle(option)}
                            {this.renderOptionSubOptionPicker(opts, option, product)}
                        </div>
                    );
                })}
            </div>
        );
    };

    showOptionError = (option) => {
        const optionId = String(_.get(option, "id"));
        return Object.keys(this.state.errors || {}).includes(optionId);
    };

    renderOptionTitle = (option) => {
        const optionId = _.get(option, "id");
        const showError = this.showOptionError(option);
        const id = `${TITLE_REF}${optionId}`;

        const toggle = () => {
            this.setState({
                collapsed: this.state?.collapsed?.includes?.(id)
                    ? (this.state.collapsed ?? []).filter((c) => c !== id)
                    : [...(this.state.collapsed ?? []), id],
            });
        };

        return (
            <div
                className={cx({
                    "product-container-option-title": true,
                    "product-container-option-error": showError,
                })}
            >
                <div className="product-container-option-title-section-one">
                    <div variant="caption" className="product-container-option-title-txt">
                        {this.getName(option.nm)}
                    </div>
                    <div
                        variant="caption"
                        component="div"
                        className={cx({
                            "product-container-select-message": true,
                            "product-container-option-error": showError,
                        })}
                    >
                        (<FormattedMessage id={this.getSelectionMessage(option)} values={option} />)
                    </div>
                </div>
                <div className="collapse-icon" onClick={toggle}>
                    {this.state?.collapsed?.includes?.(optionId) ? <FaChevronRight /> : <FaChevronDown />}
                </div>
            </div>
        );
    };

    renderOptionSubOptionPicker = (subOptions, option, product) => {
        const optionId = _.get(option, "id");
        const ref = `${TITLE_REF}${optionId}`;
        const show = this.state?.collapsed?.includes?.(ref);
        return (
            <Collapse in={!show}>
                <div className="product-container-option-button-group">
                    {subOptions.map((subOption) =>
                        this.isMultipleSubOption(option)
                            ? this.renderOptionSubOptionMuliplePicker(subOption, option, product)
                            : this.renderOptionSubOptionCheckbox(subOption, option)
                    )}
                </div>
            </Collapse>
        );
    };

    renderOptionSubOptionCheckbox = (subOption, option) => {
        const symbol = subOption.pc.toString()[0] === "-" ? "-" : "+";
        const currentSelected = this.getSingleOptionPickedSubOptions(option);
        const availableQty = _.get(subOption, `sq`, 1);
        const isAvailable = availableQty > 0;
        const error = this.showOptionError(option);

        const style1 = (
            <StyledCheckbox
                disabled={!isAvailable}
                error={error}
                isSelected={currentSelected.includes(subOption.id)}
                onSelect={() => {
                    this.handleOptionSelection(subOption, option);
                }}
            >
                {this.getName(subOption.nm)}
                {subOption.pc ? "(" + symbol + helper.getCurrencyFormattedString(Math.abs(subOption.pc), this.props.storeCurrency) + ")" : ""}
            </StyledCheckbox>
        );

        const style2 = (
            <StyledButton
                style={{ padding: 0, alignItems: "flex-start", justifyContent: "flex-start" }}
                disabled={!isAvailable}
                error={error}
                isSelected={currentSelected.includes(subOption.id)}
                onSelect={() => {
                    this.handleOptionSelection(subOption, option);
                }}
            >
                <div className="options-button-name-wrapper">
                    <div className="options-button-name">{this.getName(subOption.nm)}</div>
                </div>
                <div className="options-button-price">
                    {subOption.pc ? symbol + helper.getCurrencyFormattedString(Math.abs(subOption.pc)) : ""}
                </div>
            </StyledButton>
        );

        return this.props.buttonStyle ? style2 : style1;
    };

    renderOptionSubOptionMuliplePicker = (subOption, option, product) => {
        const symbol = subOption.pc.toString()[0] === "-" ? "-" : "+";
        const name = `${this.getName(subOption.nm)} ${
            subOption.pc ? "(" + symbol + helper.getCurrencyFormattedString(Math.abs(subOption.pc)) + ")" : ""
        }`;
        const qty = this.getSubOptQty(subOption, option);
        const error = this.showOptionError(option);

        const style1 = (
            <StyledMultiplePicker
                name={name}
                quantity={qty}
                error={error}
                allowAdd={this.allowSubOptionAdd(subOption, option, product)}
                allowRemove={this.allowSubOptionRemove(subOption, option)}
                onPlusClick={() => this.handleSubOptionAdd(subOption, option, product)}
                onMinusClick={() => this.handleSubOptionRemove(subOption, option)}
            />
        );

        const style2 = (
            <StyleMupltipleButton
                id={`${product.pid}-${option.id}-${subOption.id}`}
                name={this.getName(subOption.nm)}
                pc={subOption.pc ? symbol + helper.getCurrencyFormattedString(Math.abs(subOption.pc)) : ""}
                quantity={qty}
                error={error}
                allowAdd={this.allowSubOptionAdd(subOption, option, product)}
                allowRemove={this.allowSubOptionRemove(subOption, option)}
                onPlusClick={() => this.handleSubOptionAdd(subOption, option, product)}
                onMinusClick={() => this.handleSubOptionRemove(subOption, option)}
            />
        );

        return this.props.buttonStyle ? style2 : style1;
    };

    getStyle = () => {
        let result = {};
        if (this.props.height) {
            result.height = this.props.height;
        }
        return result;
    };

    render() {
        return (
            <div className="options-picker-container" id="options-container">
                {this.renderOptionsPicker()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    showItemEditDetailDialog: _.get(state, "createOrder.showItemEditDetailDialog", false),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ItemEditorDialog));
