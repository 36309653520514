import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import OrderDetail from "../order-history/order-detail/index";
import qs from "querystring";
import actions from "../../slices/order-history";
import _ from "lodash";
import Wrapper from "../../components/wrapper";
import "./index.scss";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "/orders",
            breadcrumbName: "order_detail",
        },
    ],
};

const OrderDetailsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const orderId = qs.parse(location.search)?.["?ord_id"];
    const back = qs.parse(location.search)?.["back"];

    useEffect(() => {
        if (orderId) {
            dispatch(actions.fetchOrderDetail({ orderId }));
        }
    }, [orderId, dispatch]);

    return (
        <Wrapper
            paddingBottom={0}
            helmet={{ title: "order_detail" }}
            breadcrumb={breadcrumb}
            hideNav={true}
            onBack={back ? () => history.push(`${back}`) : null}
        >
            <div className="order-detail-page">
                <OrderDetail showCondition={(ord: any) => !_.isEmpty(ord)} standAlone={true} />
            </div>
        </Wrapper>
    );
};

export default OrderDetailsPage;
