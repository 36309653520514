import { Button, Input, InputNumber, Popover } from "antd";
import React, { useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { editProduct } from "../../../../services/products";
import helper, { formatCurrency, hasSpecialPrice } from "../../../../util/helper";
import config from "config";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    type: state?.store?.storeInformation?.store_categories,
    allowed: state?.pages?.allowed,
});

const NUMBER_STYLE = {
    width: "100%",
    justifyContent: "flex-end",
};

interface InputRendererProps {
    name: string;
    type: any;
    format: string;
    value: string;
    translated: boolean;
    currency: string;
}

function InputRenderer(props: InputRendererProps): React.ReactChild | null {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { products, lan, allowed } = useSelector(selector);
    const [currentEditPopover, setCurrentEditPopover] = useState<any>(undefined);
    const [currentHoverPopover, setCurrentHoverPopover] = useState<any>(undefined);
    const [value, setValue] = useState<any>(undefined);
    const { lan: popoverLan, getModalLanguageSelect } = useLanguageSelect();

    const record: any = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    const getPopover = (record: any, name = "", content: any, child: any, translated = false) => {
        const id = record?.product_id;
        const onSubmit = () => {
            dispatch(editProduct({ product_id: record.product_id, [name]: value }));
            setCurrentEditPopover("");
        };

        const editKey = `edit-${id}-${name}`;
        const hoverKey = `hover-${id}-${name}`;

        const contentProp = (
            <div className="edit-popover">
                {translated ? <div className="modal-lan-select-popover">{getModalLanguageSelect()}</div> : null}
                <div>{content}</div>
                <div className="edit-popover-buttons">
                    <Button
                        onClick={() => {
                            setCurrentEditPopover("");
                        }}
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button type="primary" onClick={onSubmit} className="save-button-handler">
                        <FormattedMessage id="submit" />
                    </Button>
                </div>
            </div>
        );

        const onVisibleChange = () => {
            if (currentEditPopover === editKey) {
                setCurrentEditPopover("");
            } else {
                setCurrentEditPopover(editKey);
            }
        };

        const showSpecialPrice = (record: any) => {
            return props.name === "price" && hasSpecialPrice(record);
        };

        return (
            <Popover
                placement="rightTop"
                content={contentProp}
                title={intl.formatMessage({ id: "edit" })}
                trigger="click"
                onVisibleChange={onVisibleChange}
                visible={currentEditPopover === editKey && allowed}
            >
                <div
                    className="popover-area"
                    onMouseEnter={() => setCurrentHoverPopover(hoverKey)}
                    onMouseLeave={() => setCurrentHoverPopover("")}
                >
                    <div className="popover-child d-flex flex-column">
                        <span
                            style={{
                                textDecoration: showSpecialPrice(record) ? "line-through" : "none",
                            }}
                        >
                            {child}
                        </span>
                        {showSpecialPrice(record) ? (
                            <span className="special-price">
                                {formatCurrency(record?.special_price, props?.currency ?? "CAD")}
                            </span>
                        ) : null}
                    </div>
                    <div
                        className="popover-icon"
                        style={{ opacity: currentHoverPopover === hoverKey && allowed ? 1 : 0 }}
                    >
                        <HiOutlinePencilAlt />
                    </div>
                </div>
            </Popover>
        );
    };

    const getTextInput = (record: any, name: any, translated = false) => {
        const v = translated ? record?.[name]?.[popoverLan] : record?.[name];
        const editValue = translated ? value?.[popoverLan] : value;
        return (
            <Input
                value={editValue ?? v}
                onChange={(e) => {
                    const input = e.target.value;
                    setValue(
                        translated
                            ? {
                                  ...(value ?? {}),
                                  [popoverLan]: input,
                              }
                            : input
                    );
                }}
                allowClear
            />
        );
    };

    const getNumberInput = (record: any, name: any) => {
        const v = record?.[name];
        const editValue = value;
        return (
            <InputNumber
                className="input-number-product-table"
                value={editValue ?? v}
                max={1000000}
                min={0}
                onChange={(e) => {
                    const input = e;
                    setValue(input);
                }}
            />
        );
    };

    const getInput = props?.type === "number" ? getNumberInput : getTextInput;

    const setContentOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }
        const editKey = `edit-${record?.product_id}-${props?.name}`;
        ref.onclick = (e) => {
            setCurrentEditPopover(editKey);
            e.stopPropagation();
        };
    };

    return record ? (
        <div
            className="product-title-image"
            ref={setContentOnClickHandler}
            style={props.type === "number" ? NUMBER_STYLE : undefined}
        >
            <div className="popover-container">
                {getPopover(
                    record,
                    // 3 possibilities: SKU, Stock, Price
                    props?.name,
                    getInput(record, props?.name, props?.translated),
                    // prettier-ignore
                    props?.translated ? helper.getTransString(record?.[props?.name], lan)
                    : record.product_type === config.PRODUCT_TYPE_TO_NUMERIC.group_purchase && props?.name === "price"? "-"
                    : props?.format === "currency" ? formatCurrency(record?.[props?.name], props?.currency ?? "CAD")
                    : record?.[props?.name],
                    props?.translated
                )}
            </div>
        </div>
    ) : null;
}

export default InputRenderer;
