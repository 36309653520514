import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    published: 0,
    last_published: "",
    last_updated: "",
    pubLoad: false,
    unPubLoad: false,
};

const slice = createSlice({
    name: "publishManager",
    initialState,
    reducers: {
        pubLoad: (state) => {
            state.pubLoad = true;
        },
        unPubLoad: (state) => {
            state.unPubLoad = true;
        },
        getPublishSuccess: (state, { payload }) => {
            state.published = payload.status;
            state.last_published = payload.last_publish_dt;
            state.last_updated = payload.last_update_dt;
            state.unPubLoad = false;
            state.pubLoad = false;
        },
    },
});

export const { getPublishSuccess, unPubLoad, pubLoad } = slice.actions;

export const publishManager = slice.reducer;
