import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { CustomerInfo } from "../types/customer-list";

const initPaging = {
    page: 1,
    perPage: 10,
    pageStart: 0,
    pageEnd: 0,
    total: 0,
};

const initialState: any = {
    activeCols: [0, 1, 1, 0, 1, 1, 0, 1],
    searchValue: {},
    customers: [],
    allCustomers: [],
    totalCustomers: 0,
    filterOpen: false,
    messageOpen: false,
    messageType: 0,
    filters: {
        id: undefined,
        last_order_date_from: undefined,
        last_order_date_to: undefined,
        amt_from: undefined,
        amt_to: undefined,
        qty_from: undefined,
        qty_to: undefined,
        lastname: undefined,
        firstname: undefined,
        email: undefined,
        phone: undefined,
    },
    selectedCustomers: [],
    coupons: [],
    update: 0,
    loading: false,
    paging: { ...initPaging },
};

const slice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        setCustomersState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setAllSelectedCustomers: (state, { payload }) => {
            state.selectedCustomers = [payload?.value];
        },
        updateCustomerList: (state) => {
            state.update = Math.random();
        },
        setMessageType: (state, { payload }) => {
            state.messageType = payload.value;
        },
        setSelected: (state, { payload }) => {
            let sCustCopy = _.cloneDeep(state.selectedCustomers);
            sCustCopy = payload;
            state.selectedCustomers = sCustCopy;
        },
        setMessage: (state, { payload }) => {
            state.messageOpen = payload?.value;
        },
        setFilters: (state, { payload }) => {
            const filtersCopy = _.cloneDeep(state.filters);
            _.set(filtersCopy, payload?.name, payload?.value);
            state.filters = filtersCopy;
        },
        clearFilters: (state) => {
            const cleared = {
                id: NaN,
                last_order_date_to: NaN,
                last_order_date_from: NaN,
                amt_from: NaN,
                amt_to: NaN,
                qty_from: NaN,
                qty_to: NaN,
                lastname: "",
                firstname: "",
                email: "",
                phone: "",
            };
            state.filters = cleared;
        },
        setFilter: (state, { payload }) => {
            state.filterOpen = payload?.value;
        },
        setSearchValue: (state, { payload }) => {
            state.searchValue = payload;
        },
        toggleActiveCols: (state, { payload }) => {
            const activeColsCopy = _.cloneDeep(state.activeCols);
            activeColsCopy[payload?.index] = activeColsCopy?.[payload?.index] ? 0 : 1;
            state.activeCols = activeColsCopy;
        },
        setAdminState: (state, { payload }) => {
            const adCopy = { ...state.profile };
            _.set(adCopy, payload?.name, payload?.value);
            state.profile = adCopy;
        },
        getCouponsSuccess: (state, { payload }) => {
            state.coupons = payload;
        },
        getCustomersSuccess: (state, { payload }) => {
            state.customers = payload?.records;
            state.totalCustomers = payload?.paging?.total;
            const newSel: any = [];
            const selCustCopy = _.cloneDeep(state.selectedCustomers);
            const allCustCopy = _.cloneDeep(state.customers);
            selCustCopy.map((customer: any) => newSel.push(allCustCopy.find((cust: any) => customer.id === cust.id)));
            state.selectedCustomers = newSel;
        },
        getAllCustomersSuccess: (state, { payload }) => {
            state.allCustomers = payload?.records;
            state.paging = payload?.paging;
        },
        getCustomerSuccess: (state, { payload }) => {
            state.customer = payload?.record;
        },
        getCustomerAddressesSuccess: (state, { payload }) => {
            state.customerAddresses = payload?.records;
        },
        getCustomerPointsHistorySuccess: (state, { payload }) => {
            state.pointsHistory = payload?.res?.res;
        },
        getCustomerGiftCardSuccess: (state, { payload }) => {
            state.giftcards = payload?.records;
        },
        clearAllCustomer: (state, { payload }: { payload: string | null }) => {
            const t = _.cloneDeep(state.allCustomers);
            state.allCustomers = t.filter((c: CustomerInfo) => String(c.id) === payload);
            state.paging = initPaging;
        },
    },
});

export const {
    setAllSelectedCustomers,
    getCouponsSuccess,
    updateCustomerList,
    setMessageType,
    setMessage,
    setSelected,
    clearFilters,
    setSearchValue,
    setFilter,
    setFilters,
    toggleActiveCols,
    getCustomersSuccess,
    getAllCustomersSuccess,
    setCustomersState,
    getCustomerSuccess,
    getCustomerAddressesSuccess,
    getCustomerPointsHistorySuccess,
    getCustomerGiftCardSuccess,
    clearAllCustomer,
} = slice.actions;

export const customers = slice.reducer;
