import { RootState } from "app/reducer";
import _ from "lodash";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getContentPages, removeContentPage, updateContentPageList } from "services/content-pages";
import Wrapper from "../../../../components/wrapper";
import { Row, Col, Switch, Dropdown, Menu, Button, Spin, Empty, Modal } from "antd";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DraggingStyle,
    NotDraggingStyle,
} from "react-beautiful-dnd";
import { ContentPageSummary } from "./models/content-pages-responses";
import { FormattedMessage, useIntl } from "react-intl";
import "./styles.scss";
import { HiDotsHorizontal, HiMenu } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { GetContentPageParams, UpdatedContentPage } from "./models/content-pages-requests";
import { useState } from "react";
import { setState } from "slices/content-pages";
import config from "config";
import { toast } from "react-toastify";
import { createMessage } from "components/message";
import { getPublish } from "services/publish";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/contentPages", breadcrumbName: "content_pages" },
    ],
};

const DRAGGABLE_COL_SPAN = 1;
const NAME_COL_SPAN = 12;
const URL_KEY_COL_SPAN = 7;
const ENABLED_COL_SPAN = 2;
const ACTIONS_COL_SPAN = 2;

interface ContentPagesStateProps {
    contentPageList: ContentPageSummary[];
    isFetching: boolean;
    isUpdating: boolean;
    lan: string;
    message: string;
}

interface ContentPagesProps extends ContentPagesStateProps, PropsFromRedux {}

const ContentPages = (props: ContentPagesProps) => {
    const history = useHistory();
    const intl = useIntl();

    const [contentPageListUpdates, setContentPageListUpdates] = useState<UpdatedContentPage[]>([]);
    const [initialContentPageListState, setInitialContentPageListState] = useState<ContentPageSummary[]>([]);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        getContentPages();
        setInitialLoad(false);

        const message = localStorage.getItem("@toast");
        if (message) {
            toast(createMessage(intl.formatMessage({ id: message })));
            localStorage.removeItem("@toast");
        }
    }, []);

    useEffect(() => {
        if (!props.isFetching && !props.isUpdating && !initialLoad) {
            setInitialContentPageListState(props.contentPageList);
        }
    }, [props.isFetching, props.isUpdating]);

    useEffect(() => {
        if (!props.isUpdating && !initialLoad) {
            props.getPublish();
        }
    }, [props.isUpdating]);

    useEffect(() => {
        if (initialLoad) {
            return;
        }
        setContentPageListUpdatesHelper(contentPageListUpdates);
    }, [initialContentPageListState]);

    useEffect(() => {
        if (props.message) {
            if (props.message === "deleted_content_page_success") {
                getContentPages();
            }

            toast(createMessage(intl.formatMessage({ id: props.message })));
            props.setState({ message: "" });
        }
    }, [props.message]);

    const getContentPages = () => {
        const params: GetContentPageParams = {
            lan: props.lan,
        };

        props.getContentPages(params);
    };

    /**
     * Checks whether the updatedList differs from the initial content page list
     * and only sets the pages that differ in the content page updates list
     *
     * @param updatedList - a list of content pages that have been modified
     */
    const setContentPageListUpdatesHelper = (updatedList: UpdatedContentPage[]) => {
        let initialPage;
        const contentPageUpdates: UpdatedContentPage[] = [];

        updatedList.forEach((update) => {
            initialPage = initialContentPageListState.find((contentPage) => update._id === contentPage._id);
            if (initialPage && (initialPage.position !== update.position || initialPage.enabled !== update.enabled)) {
                contentPageUpdates.push(update);
            }
        });

        setContentPageListUpdates(contentPageUpdates);
    };

    const addContentPage = () => {
        history.push("/settings/contentPages/addEditContentPage");
    };

    const editContentPage = (id: string) => {
        history.push("/settings/contentPages/addEditContentPage/" + id);
    };

    const deleteContentPage = (id: string) => {
        props.removeContentPage(Array.of(id));
    };

    /**
     * Builds a new list of content page updates where the position is set
     * as the order that it is displayed
     *
     * @returns the reordered list of page updates
     */
    const buildReorderedPageUpdates = () => {
        const reorderedList: UpdatedContentPage[] = [];
        props.contentPageList.forEach((contentPage, index) => {
            const updatedPage: UpdatedContentPage = {
                _id: contentPage._id,
                position: index,
                enabled: contentPage.enabled,
            };
            reorderedList.push(updatedPage);
        });

        return reorderedList;
    };

    const saveChanges = () => {
        props.updateContentPageList(buildReorderedPageUpdates());
    };

    /**
     * Updates the state of content page list updates and the state of content page list
     * with the new enabled value
     *
     * @param id - id of the content page
     * @param isEnabled - whether the user toggled the switch to enabled or disabled
     * @param index - index of the content page in the content page list
     */
    const handleToggleEnabled = (id: string | undefined, isEnabled: boolean, index: number) => {
        if (!id) {
            return;
        }

        // update content page list updates
        const updatesClone = _.cloneDeep(contentPageListUpdates);
        const updatedPageIndexOfUpdatesList = updatesClone.findIndex(
            (updatedContentPage) => id === updatedContentPage._id
        );
        if (updatedPageIndexOfUpdatesList !== -1) {
            updatesClone[updatedPageIndexOfUpdatesList].enabled = isEnabled;
        } else {
            const updatedContentPage: UpdatedContentPage = {
                _id: id,
                position: index,
                enabled: isEnabled,
            };
            updatesClone.push(updatedContentPage);
        }
        setContentPageListUpdatesHelper(updatesClone);

        // update content page list
        const contentPageListClone = _.cloneDeep(props.contentPageList);
        contentPageListClone[index].enabled = isEnabled;
        props.setState({ contentPageList: contentPageListClone });

        saveChanges();
    };

    /**
     * Change the position of the content page list
     * and set content page updates
     *
     * @param result - the drop result
     */
    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const contentPageListClone = _.cloneDeep(props.contentPageList);
        const draggedContent = contentPageListClone.find((contentPage) => draggableId === contentPage._id);

        if (draggedContent) {
            contentPageListClone.splice(source.index, 1);
            contentPageListClone.splice(destination.index, 0, draggedContent);

            contentPageListClone[source.index].position = source.index;
            contentPageListClone[destination.index].position = destination.index;

            const updatesList: UpdatedContentPage[] = [];
            contentPageListClone.forEach((contentPage) => {
                const update: UpdatedContentPage = {
                    _id: contentPage._id,
                    position: contentPage.position,
                    enabled: contentPage.enabled,
                };
                updatesList.push(update);
            });

            setContentPageListUpdatesHelper(updatesList);
            props.setState({ contentPageList: contentPageListClone });

            saveChanges();
        }
    };

    const renderActionBar = () => {
        return (
            <Row className="content-page-action-bar-container">
                <Col span={12}></Col>
                <Col span={12} className="add-content-page-button-container">
                    <Button className="add-content-page-button" type="primary" size="large" onClick={addContentPage}>
                        <FormattedMessage id="add_content_page" />
                    </Button>
                </Col>
            </Row>
        );
    };

    const renderActions = (id: string) => {
        return (
            <Menu>
                <Menu.Item onClick={() => editContentPage(id)}>
                    <FormattedMessage id="edit" />
                </Menu.Item>
                <Menu.Item
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: intl.formatMessage({ id: "warning" }),
                            content: intl.formatMessage({ id: "delete_content_page_confirmation" }),
                            okText: intl.formatMessage({ id: "delete" }),
                            okType: "danger",
                            cancelText: intl.formatMessage({ id: "cancel" }),
                            onOk() {
                                deleteContentPage(id);
                            },
                        });
                    }}
                >
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );
    };

    const renderContentPagesTableHeader = () => {
        return (
            <div className="content-page-row-header">
                <Row gutter={8}>
                    <Col span={DRAGGABLE_COL_SPAN} className="content-page-header"></Col>
                    <Col span={NAME_COL_SPAN} className="content-page-header">
                        <FormattedMessage id="name" />
                    </Col>
                    <Col span={URL_KEY_COL_SPAN} className="content-page-header">
                        <FormattedMessage id="url_key" />
                    </Col>
                    <Col span={ENABLED_COL_SPAN} className="content-page-header">
                        <FormattedMessage id="enabled" />
                    </Col>
                    <Col span={ACTIONS_COL_SPAN} className="content-page-header">
                        <FormattedMessage id="actions" />
                    </Col>
                </Row>
            </div>
        );
    };

    const getDraggableStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => {
        return {
            backgroundColor: isDragging ? "lightgreen" : "white",
            ...draggableStyle,
        };
    };

    const renderEnabledSwitch = (contentPage: ContentPageSummary, index: number) => (
        <div className="clickable-row-parent">
            <div className="clickable-row" onClick={() => editContentPage(contentPage._id)}></div>
            <Switch
                checked={contentPage.enabled}
                onChange={(isEnabled) => handleToggleEnabled(contentPage._id, isEnabled, index)}
            />
        </div>
    );

    const renderActionsDropDown = (id: string) => {
        return (
            <Dropdown overlay={renderActions(id)}>
                <Button type="link">
                    <HiDotsHorizontal />
                </Button>
            </Dropdown>
        );
    };

    const renderContentPageDetails = (contentPage: ContentPageSummary, index: number) => {
        return (
            <Draggable key={contentPage._id} draggableId={contentPage._id} index={index}>
                {(provided, snapshot) => (
                    <div
                        className="content-page-row"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={getDraggableStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                        <Row gutter={8}>
                            <Col
                                span={DRAGGABLE_COL_SPAN}
                                className="content-page-details"
                                onClick={() => editContentPage(contentPage._id)}
                            >
                                <div key={contentPage._id} {...provided.dragHandleProps}>
                                    <HiMenu />
                                </div>
                            </Col>
                            <Col
                                span={NAME_COL_SPAN}
                                className="content-page-details"
                                onClick={() => editContentPage(contentPage._id)}
                            >
                                {contentPage.name}
                            </Col>
                            <Col
                                span={URL_KEY_COL_SPAN}
                                className="content-page-details"
                                onClick={() => editContentPage(contentPage._id)}
                            >
                                {contentPage.url_key}
                            </Col>
                            <Col span={ENABLED_COL_SPAN} className="content-page-details enabled-col">
                                {renderEnabledSwitch(contentPage, index)}
                            </Col>
                            <Col span={ACTIONS_COL_SPAN} className="content-page-details">
                                {renderActionsDropDown(contentPage._id)}
                            </Col>
                        </Row>
                    </div>
                )}
            </Draggable>
        );
    };

    const renderContentPageList = () => {
        let contentPages;

        if (props.isFetching) {
            contentPages = null;
        } else if (props.contentPageList?.length > 0) {
            contentPages = props.contentPageList.map((contentPage: ContentPageSummary, index) => {
                return renderContentPageDetails(contentPage, index);
            });
        } else {
            contentPages = (
                <div className="empty-content-pages-container">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            );
        }

        return contentPages;
    };

    return (
        <Wrapper helmet={{ title: "content_pages" }} breadcrumb={breadcrumb} description={"content_pages_description"}>
            <Spin spinning={props.isFetching || props.isUpdating}>
                <div className="content-page-list-container">
                    {renderActionBar()}
                    <div className="spacing-12" />
                    <div className="content-page-list-table-container">
                        {renderContentPagesTableHeader()}
                        <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)}>
                            <Droppable droppableId={"droppable"}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {renderContentPageList()}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </Spin>
        </Wrapper>
    );
};

const mapStateToProps = (state: RootState) => {
    const stateProps: ContentPagesStateProps = {
        contentPageList: _.get(state, "contentPages.contentPageList", []),
        isFetching: _.get(state, "contentPages.isFetching", false),
        isUpdating: _.get(state, "contentPages.isUpdating", false),
        lan: _.get(state, "settings.lan", config.LANGUAGE_CODES.en),
        message: _.get(state, "contentPages.message", ""),
    };
    return stateProps;
};

const mapDispatchToProps = {
    setState,
    getContentPages,
    updateContentPageList,
    removeContentPage,
    getPublish,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContentPages);
