import React from "react";
import { View, Text } from "@react-pdf/renderer";

const scanOrder = (styles: React.CSSProperties | any): JSX.Element => (
    <View style={styles.scanTxt}>
        <View style={styles.flexRow}>
            <Text>Scan</Text>
            <Text style={styles.scanTxtFirst}>to</Text>
            <Text style={styles.scanTxtSecond}>Order</Text>
        </View>
    </View>
);
export default scanOrder;
