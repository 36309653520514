import React, { useState } from "react";
import _ from "lodash";
import { Modal, Input, Checkbox, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { STATUS_DISABLED, STATUS_ENABLED } from "../../constants";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import config from "../../../../config";
import { useDispatch } from "react-redux";
import { setProductState } from "../../../../slices/products";

const DEFAULT_DUPLICATE = ["price", "stock", "images", "video", "options", "related_products", "upsell_products"];

interface useAddEditProductPageDuplicateProps {
    getValues: any;
    options: any[];
    images: string;
}

function useAddEditProductPageDuplicate({ getValues, options, images }: useAddEditProductPageDuplicateProps): any {
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan: selectedLan, getInputLanguageSelect } = useLanguageSelect();

    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateState, setDuplicateState] = useState<any>({});
    const [selectedToDuplicate, setSelectedToDuplicate] = useState<any[]>(DEFAULT_DUPLICATE);

    const onDuplicateChange = (key: string, value: any) => {
        const newDuplicateState = _.cloneDeep(duplicateState);
        newDuplicateState[key] = value;
        setDuplicateState(newDuplicateState);
    };

    const duplicateOptions = DEFAULT_DUPLICATE.map((value) => ({
        value,
        label: intl.formatMessage({ id: value ?? " " }),
    }));

    const onDuplicateProduct = () => {
        const v = _.cloneDeep(getValues());
        v.options = options;
        v.images = images;
        v.status = STATUS_DISABLED;
        setDuplicateState(v);
        setDuplicateModalOpen(true);
    };

    const onDuplicateProductOk = () => {
        const addEditItem = _.cloneDeep(duplicateState);

        DEFAULT_DUPLICATE.forEach((duplicate) => {
            if (!selectedToDuplicate.includes(duplicate)) {
                delete addEditItem[duplicate];
            }
        });
        delete addEditItem.product_id;
        delete addEditItem.sku;
        delete addEditItem.barcode;

        dispatch(setProductState({ addEditItemDuplicate: addEditItem }));
        // @ts-ignore
        history.push("/products/addEditItem?duplicate=1");
    };

    const closeDuplicateProductModal = () => {
        setDuplicateModalOpen(false);
    };

    const getDuplicateModal = () => {
        return (
            <Modal
                visible={duplicateModalOpen}
                title={intl.formatMessage({ id: "duplicate" })}
                onOk={onDuplicateProductOk}
                onCancel={closeDuplicateProductModal}
            >
                {getInputLanguageSelect(
                    config.LANGUAGES.map((l) => (
                        <div key={`item_title_${l}`} style={{ display: l !== selectedLan ? "none" : "block" }}>
                            <div className="duplicate-label">
                                <FormattedMessage id="item_title" />
                            </div>
                            <div className="duplicate-content">
                                <Input
                                    value={duplicateState?.["name_" + l]}
                                    onChange={(e) => onDuplicateChange("name_" + l, e.target.value)}
                                    style={{ width: "100%" }}
                                    placeholder={intl.formatMessage({ id: "item_title" })}
                                />
                            </div>
                        </div>
                    ))
                )}
                <div className="duplicate-label">
                    <FormattedMessage id="select_details_to_copy" />
                </div>
                <div className="duplicate-content">
                    <Checkbox.Group
                        className="duplicate-checkbox-group"
                        value={selectedToDuplicate}
                        onChange={setSelectedToDuplicate}
                        options={duplicateOptions}
                    />
                </div>
                <div className="duplicate-label">
                    <FormattedMessage id="status" />
                </div>
                <div className="duplicate-content">
                    <Switch
                        checked={duplicateState?.status === STATUS_ENABLED}
                        onChange={() => {
                            onDuplicateChange(
                                "status",
                                duplicateState?.status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED
                            );
                        }}
                    />
                </div>
            </Modal>
        );
    };

    return {
        duplicateModalOpen,
        onDuplicateProductOk,
        closeDuplicateProductModal,
        duplicateState,
        onDuplicateChange,
        duplicateOptions,
        selectedToDuplicate,
        setSelectedToDuplicate,
        onDuplicateProduct,
        getDuplicateModal,
    };
}

export default useAddEditProductPageDuplicate;
