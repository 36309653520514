import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { getManagedStoresSuccess, getStoreListSuccess } from "../slices/managed-stores";
import { getIntl } from "../locale";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";

export const getManagedStores =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("MANAGED_STORES")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getManagedStoresSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getStoreList =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("STORE_LIST")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getStoreListSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateManagedStores =
    (params?: string | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("MANAGED_STORES")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getManagedStores());
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "managed_stores" })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
