import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse, Drawer, Form, Table } from "antd";
import { FormattedMessage } from "react-intl";
import useLanguageSelect from "hooks/useLanguageSelect";
import { getFormItem, getSelectInput, getSwitchInput, getTextInput, mapBooleansToNumbers } from "components/form";
import config from "config";
import { updateCategory } from "services/categories";
import ObjectModel from "util/models";
import StoreCategory from "util/models/store-category";
import { RootState } from "app/reducer";
import useModal from "hooks/useModal";
import AssignItemsModal from "components/assign-items-modal";
import { Product } from "slices/products";
import { useHistory } from "react-router-dom";
import { getTimeList } from "services/time";
import SelectedProductsDisplay from "./selected-products-display";
import ArrangeItemsModal from "../../_components/arrange-items-modal";
import helper from "util/helper";
import { AiOutlineClose } from "react-icons/ai";
import { ColumnsType } from "antd/lib/table";
import { TimeListItem } from "slices/time";
import { submitCategoryData } from "../../helper";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
    selectedProducts: state?.categories?.products,
    language: state?.setting?.lan,
    timeList: state?.time?.timeList,
    allowed: state?.pages?.allowed,
    languages: state?.store?.storeDisplayOptions?.product_display_languages,
});

function AddEditCategoryDrawer(props: any = {}): JSX.Element {
    const history = useHistory();
    const dispatch = useDispatch();
    const { lan, getInputLanguageSelect } = useLanguageSelect();
    const { categories, selectedProducts: sp, timeList, allowed, languages } = useSelector(categoriesPageSelector);
    const defaultLanguage = useSelector((state: RootState) => state?.store?.records?.dflt_lan);
    const [form] = Form.useForm();
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);
    const {
        openModal: openModalArrange,
        getModal: getModalArrange,
        closeModal: closeModalArrange,
    } = useModal("assign-items");
    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [panel, setPanel] = useState<string | string[]>([]);

    const getEditCategory = () => {
        return categories.find((c) => c.category_id === props.cid);
    };

    const category: any = getEditCategory();

    const showForm = () => {
        if (props?.cid) {
            return !_.isEmpty(getEditCategory());
        }
        return true;
    };

    const getCategoriesAsOptions = useCallback(() => {
        return (
            categories
                ?.filter((c) => c.level < config.CATEGORY_LEVELS.sub)
                .map((c) => ({
                    value: c.category_id,
                    label: helper.getTransString(c.name, lan),
                })) ?? {}
        );
    }, [categories, lan]);

    const getTimeListAsOptions = useCallback(() => {
        return timeList?.map((t) => ({
            value: t.code,
            label: `${t.code} - ${t.name}`,
        }));
    }, [timeList]);

    const onSaveAssign = (selected: Product[]) => {
        setSelectedProducts(selected);
        setShowAssignItemsModal(false);
    };

    const submitData = (data: any) => {
        let path = "1"; // Start of the path name starts with 1
        const level = (categories.find((c) => c.category_id === data.parent_category)?.level ?? 1) + 1;

        if (level === config.CATEGORY_LEVELS.sub) {
            path =
                path +
                "/" +
                (categories.find((c) => c.category_id === data.parent_category)?.parent_id ?? 1) +
                "/" +
                data.parent_category;
        } else {
            path = path + "/" + data.parent_category;
        }

        const updateObj = {
            ...new ObjectModel(StoreCategory).convertToPostData(data),
            level: (categories.find((c) => c.category_id === data.parent_category)?.level ?? 1) + 1,
            category_products: selectedProducts.map((product) => product.product_id),
            position: categories.length,
        };

        if (props.cid) {
            updateObj.category_id = props.cid;
            updateObj.position = getEditCategory()?.position;
        } else {
            updateObj.path = path;
        }

        dispatch(updateCategory(updateObj));
        setSelectedProducts([]);
        props.onClose();
        form.resetFields();
        history.push("/categories");
    };

    const onFinish = (unmapped: any) => {
        const submittedValues = mapBooleansToNumbers(unmapped, ["is_active", "is_hidden"]);
        const originalValues = new ObjectModel(StoreCategory, getEditCategory()).getData();

        submitCategoryData({ submittedValues, originalValues, languages, defaultLanguage, submitData });
    };

    const getTimeTable = () => {
        const renderName = (record: any) => record.code + " - " + record.name;
        const renderAvailableTime = (record: any) => {
            return (
                <div>
                    {record.available_time.map((a: any, index: number) => (
                        <div key={`available-time-record-${index}`} className="available-time-record">
                            <div>{a.days.map((d: any) => d.charAt(0).toUpperCase() + d.slice(1)).join(", ")}</div>
                            <div>
                                {a.hours.map((h: any, index2: number) => (
                                    <div key={`hours-${index2}`}>
                                        {h.open} - {h.close}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            );
        };

        const columns: ColumnsType<TimeListItem> = [
            {
                title: <FormattedMessage id="name" />,
                render: renderName,
            },
            {
                title: <FormattedMessage id="available_time" />,
                render: renderAvailableTime,
            },
        ];

        return (
            <div className="time-table-category">
                <Collapse onChange={(key) => setPanel(key)}>
                    <Collapse.Panel
                        header={
                            <FormattedMessage
                                id={panel.includes("show_time_table") ? "hide_time_table" : "show_time_table"}
                            />
                        }
                        key="show_time_table"
                        showArrow={false}
                    >
                        <Table size="small" bordered dataSource={timeList} columns={columns} pagination={false} />
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    };

    useEffect(() => {
        if (props.visible) {
            dispatch(getTimeList());
            form.resetFields();
        }
    }, [props.visible, dispatch, form]);

    useEffect(() => {
        if (getCategoriesAsOptions().length && !props?.cid) {
            form.setFieldsValue({
                parent_category: getCategoriesAsOptions()[0].value,
            });
        }
    }, [getCategoriesAsOptions, props, form]);

    useEffect(() => {
        if (getTimeListAsOptions().length && !props?.cid) {
            form.setFieldsValue({
                time_code_setting: getTimeListAsOptions()[0].value,
            });
        }
    }, [getTimeListAsOptions, props, form]);

    useEffect(() => {
        setSelectedProducts(sp);
    }, [sp]);

    const onSave = (selected: Product[]) => {
        setSelectedProducts(selected);

        setShowAssignItemsModal(false);
        closeModalArrange();
    };

    const assignModal = () => {
        return showAssignItemsModal ? (
            <AssignItemsModal
                title={<FormattedMessage id="assigning_items" />}
                onSave={onSaveAssign}
                onCancel={() => setShowAssignItemsModal(false)}
                selectedProducts={selectedProducts}
            />
        ) : null;
    };

    const arrangeModal = getModalArrange(
        <ArrangeItemsModal
            name={helper.getTransString(category?.name, lan)}
            category_id={category?.category_id}
            onSave={onSave}
            onCancel={closeModalArrange}
            selectedProducts={selectedProducts}
        />,
        {
            title: (
                <FormattedMessage
                    id="arranging_category"
                    values={{ category: helper.getTransString(category?.name, lan) }}
                />
            ),
            onCancel: closeModalArrange,
            footer: null,
        }
    );

    const getForm = () => (
        <Form
            form={form}
            className="category-form"
            onFinish={onFinish}
            initialValues={new ObjectModel(StoreCategory, getEditCategory()).getData()}
        >
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={`category_title_${l}`} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem("category_title", getTextInput({ name: "category_title_" + l }))}
                    </div>
                ))
            )}
            {getFormItem(
                "enabled",
                getSwitchInput(
                    "is_active",
                    new ObjectModel(StoreCategory, getEditCategory()).getData(),
                    undefined,
                    false,
                    {
                        paddingBottom: "6px",
                    }
                ),
                null,
                "",
                true
            )}
            {getFormItem(
                "hide_on_frontend",
                getSwitchInput(
                    "is_hidden",
                    new ObjectModel(StoreCategory, getEditCategory()).getData(),
                    undefined,
                    false,
                    {
                        paddingBottom: "6px",
                    }
                ),
                null,
                "",
                true,
                "is_hidden_tip"
            )}
            {getFormItem("parent_category", getSelectInput("parent_category", false, getCategoriesAsOptions()))}
            <SelectedProductsDisplay
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                openModal={() => setShowAssignItemsModal(true)}
                openModalArrange={openModalArrange}
            />
            {getFormItem(
                "time_code_setting",
                getSelectInput("time_code_setting", true, getTimeListAsOptions()),
                "time_code_setting_desc"
            )}
            {getTimeTable()}
            <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                <Button
                    type="primary"
                    size="large"
                    style={{ marginLeft: 14 }}
                    htmlType="submit"
                    className="save-button-handler"
                    disabled={!allowed}
                >
                    <FormattedMessage id="submit" />
                </Button>
            </div>
        </Form>
    );

    return (
        <Drawer
            forceRender
            className="category-drawer"
            visible={props.visible}
            placement="right"
            width={768}
            title={props.cid ? <FormattedMessage id="edit_category" /> : <FormattedMessage id="add_category" />}
            onClose={props.onClose}
        >
            <div className="categoryDrawerHeader">
                <div className="name">
                    {props.cid ? <FormattedMessage id="edit_category" /> : <FormattedMessage id="add_category" />}
                </div>
                <div className="closeBox pHover" onClick={props.onClose}>
                    <AiOutlineClose size="20" />
                </div>
            </div>
            {showForm() ? getForm() : null}
            {assignModal()}
            {arrangeModal}
        </Drawer>
    );
}

export default AddEditCategoryDrawer;
