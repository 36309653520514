import { Button, Col, Row } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCustomerGiftcards } from "../../../../services/customers";
import { setGiftcardState } from "../../../../slices/giftcards";
import moment from "moment";
import helper from "../../../../util/helper";
import { toast } from "react-toastify";
import { HiInformationCircle } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";
import { createMessage } from "../../../../components/message";
import { getCustomerId } from "./helper";

class GiftCards extends Component {
    componentDidMount() {}

    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCid = (props = this.props) => getCustomerId(props);

    showHistory = () => {
        return this.state.showHistory && !_.isEmpty(this.getHistory());
    };

    getHistory = () => (Array.isArray(this.props.giftcards) ? this.props.giftcards : []);

    renderTitle = () => {
        const cards = this.props.customer?.num_giftcards;
        const customer = this.props.customer;
        return (
            <div className="customer-section-title">
                <FormattedMessage id="giftcard" />
                {cards ? (
                    <Button
                        onClick={() => {
                            this.props.getCustomerGiftcards(
                                {
                                    c_id: customer?.id,
                                },
                                () =>
                                    this.props.setGiftcardState({
                                        showCustomerGiftCardsModal: true,
                                        customerFirstName: customer?.firstname,
                                        customerLastName: customer?.lastname,
                                        customerPhone: customer?.phone,
                                        customerEmail: customer?.email,
                                    })
                            );
                        }}
                        size="large"
                        type="link"
                    >
                        <FormattedMessage id="view_all_records" />
                    </Button>
                ) : null}
            </div>
        );
    };

    renderSummaryInfo = () => {
        const cards = this.props.customer?.num_giftcards;
        if (cards)
            return (
                <div className="customer-giftcard-summary ">
                    <div>
                        <FormattedMessage id="x_gift_card_found" values={{ x: cards }} />
                    </div>
                    {this.showHistory() ? null : (
                        <div>
                            <Button
                                onClick={() => {
                                    this.props.getCustomerGiftcards({
                                        page: 1,
                                        limit: 3,
                                        customer_record: true,
                                        c_id: this.getCid(),
                                    });
                                    this.setState({
                                        showHistory: true,
                                    });
                                }}
                                size="large"
                                type="link"
                            >
                                <FormattedMessage id="show_last_3_records" />
                            </Button>
                        </div>
                    )}
                </div>
            );
    };

    renderHistory = () => {
        const copyCode = (code) => {
            const str = this.str;
            navigator.clipboard.writeText(code).then(
                function () {
                    toast(
                        createMessage(
                            str("copied_to_clipboard_success", { what: str("gift_card_code") }),
                            HiInformationCircle
                        )
                    );
                },
                function () {
                    // Handle error
                }
            );
        };

        return this.showHistory() ? (
            <div className="customer-history-table">
                <Row className="customer-history-title-row">
                    <Col span={8}>
                        <FormattedMessage id="purchase_date_giftcard" />
                    </Col>
                    <Col span={10}>
                        <FormattedMessage id="gift_card_code" />
                    </Col>
                    <Col style={{ textAlign: "end" }} span={6}>
                        <FormattedMessage id="balance" />
                    </Col>
                </Row>
                <div>
                    {this.getHistory().map((history, index) => (
                        <Row
                            key={`customer-history-table-row-${history?.code}-${index}`}
                            className="customer-history-table-row"
                        >
                            <Col span={8}>{moment(history.date_created, "YYYY-MM-DD").format("MMM D")}</Col>
                            <Col span={10}>
                                {history.code}
                                <span
                                    onClick={() => {
                                        copyCode(history.code);
                                    }}
                                    className="customer-giftcard-code-copy"
                                >
                                    <MdContentCopy />
                                </span>
                            </Col>
                            <Col style={{ textAlign: "end" }} span={6}>
                                {helper.formatCurrency(Math.abs(history.balance))}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        ) : null;
    };

    render() {
        const cards = this.props.customer?.num_giftcards;
        return cards ? (
            <div className="customer-detail-section customer-giftcard section-padding">
                {this.renderTitle()}
                {this.renderSummaryInfo()}
                {this.renderHistory()}
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    giftcards: state?.customers?.giftcards,
});

const mapDispatchToProps = {
    getCustomerGiftcards,
    setGiftcardState,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(GiftCards)));
