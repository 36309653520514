import { Tabs } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import Wrapper from "../../../../components/wrapper";
import qs from "querystring";
import AutoDiscountsTab from "./_components/auto-discounts/auto-discs-tab";
import DiscountCodesTab from "./_components/discount-codes/disc-codes-tab";
import DiscountedUpSellTab from "./_components/smart-upsell-tab";
import { RootState } from "./../../../../app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDiscounts } from "../../../../services/discounts";
import "./discounts.scss";
import "../../index.scss";
import "./discounts-mobile.scss";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/discountOptions", breadcrumbName: "discount_options" },
    ],
};

export enum DiscountTabs {
    DiscountCodes = "discount_codes",
    AutomaticDiscounts = "auto_discounts",
    DiscountedUpSell = "discounted_upsell",
}

const tabs: any = [
    { key: DiscountTabs.DiscountCodes, tab: DiscountCodesTab },
    { key: DiscountTabs.AutomaticDiscounts, tab: AutoDiscountsTab },
    { key: DiscountTabs.DiscountedUpSell, tab: DiscountedUpSellTab },
];

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const discounts = useSelector((state: RootState) => state.discounts);
    const tab = String(qs.parse(location.search)?.["?tab"] ?? DiscountTabs.DiscountCodes);
    const onChangeTab = (tab: string) => history.push("/settings/discountOptions?tab=" + tab);
    useEffect(() => {
        dispatch(getStoreDiscounts());
    }, [dispatch]);

    return !_.isEmpty(discounts) ? (
        <Wrapper helmet={{ title: "discount_options" }} breadcrumb={breadcrumb}>
            <div className="settings-pickup-page">
                <Tabs
                    type="card"
                    activeKey={tab}
                    defaultActiveKey={tab}
                    onChange={onChangeTab}
                    style={{ marginTop: 14 }}
                >
                    {tabs.map((tab: any) => (
                        <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                            <tab.tab />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </Wrapper>
    ) : null;
}

export default App;
