const StoreReviewBooster = `
    review_booster_setting.enable_automated_review_sms,             enable_automated_review_sms,                  false;
    review_booster_setting.style,                                   style,                                        false;
    review_booster_setting.social_review_url,                       social_review_url,                            false;
    review_booster_setting.review_hint,                             review_hint,                                  false;
    review_booster_setting.review_delay_time,                       review_delay_time,                            false;
    review_booster_setting.no_duplicate_review_same_day,            no_duplicate_review_same_day,                 false;
    review_booster_setting.message_limit_per_month,                 message_limit_per_month,                      false;
    review_booster_setting.reward_coupon_code,                      reward_coupon_code,                           false;
    review_booster_setting.coupon_hint_message,                     coupon_hint_message,                          false;
    review_booster_setting.coupon_expire_dt,                        coupon_expire_dt,                             false;
`;
export default StoreReviewBooster;
