import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import useLanguageSelect from "./../../../hooks/useLanguageSelect";
import { Popover, Modal } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { RootState } from "./../../../app/reducer";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import h from "../helper";
import helper from "./../../../util/helper";
import PasswordModal from "./account-modal/password-modal";
import { saveAdminProfile, getAdminRoles, getDefaultRoles } from "./../../../services/admin-users";
import {
    toggleEditDrawer,
    setAccountModal,
    setAccountTab,
    setAccountModalType,
    setShowPassModal,
} from "../../../slices/admin-users";

const ADMIN_USERS_FLAG_INDEX = 26;

const hasAdminUserAccess = (currUserAccessFlags: any[]) => 
    !_.isEmpty(currUserAccessFlags) && +currUserAccessFlags[ADMIN_USERS_FLAG_INDEX] === 2;

const AdminUserEditDrawer = (): JSX.Element => {
    const dispatch = useDispatch();
    const intl = useIntl();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const QRCode = require("qrcode.react");
    const { lan } = useLanguageSelect();
    const currUser = useSelector((state: RootState) => state.user);
    const user = useSelector((state: RootState) => state.adminUsers.user);
    const showDrawer = useSelector((state: RootState) => state.adminUsers.editDrawer);
    const showPassModal = useSelector((state: RootState) => state?.adminUsers?.showPassModal);
    const [showWechatModal, setShowWechatModal] = useState<boolean>(false);
    const defaultRoles = useSelector((state: RootState) => state.adminUsers.defaultRoles);
    const storeRoles = useSelector((state: RootState) => state.adminUsers.storeRoles);
    const notSelf = _.get(user, "id", 0) !== _.get(currUser, "id", 0) ? true : false;
    const EDIT_ACCOUNT = 1;
    const PERSONAL_INFO = 0;
    const PERMISSIONS = 1;
    let currUserAccessFlags = currUser.access?.split(",") ?? [""];
    currUserAccessFlags = currUserAccessFlags.map((flag: string) => flag.split(":")[0]);

    useEffect(() => {
        dispatch(getDefaultRoles());
        dispatch(getAdminRoles());
    }, [dispatch]);

    const getName = (user: any) => `${user.first_name ?? ""} ${user.last_name ?? ""}`;
    const getSince = (since: any) => moment(since).format("YYYY-MM-DD HH:MM:SS");
    const getActive = (active: number) => {
        return active === 1 ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" });
    };

    const toggleIsActive = () => {
        dispatch(
            saveAdminProfile({
                profile: {
                    first_name: user?.first_name,
                    username: user?.username,
                    id: user?.id,
                    is_active: h.isActive(user?.is_active) ? 0 : 1,
                },
            })
        );
    };

    const getInfoDetail = (title: any, value: any) => (
        <div className="admin-detail-container my-2">
            <div className="detail-header">{intl.formatMessage({ id: title ?? " " }).toUpperCase()}</div>
            <div className="detail-body">{value}</div>
        </div>
    );

    const openAccountModal = (modalType: number, activeAccountTab?: number) => {
        dispatch(setAccountModal(true));
        dispatch(setAccountModalType(modalType));
        dispatch(setAccountTab(activeAccountTab ?? PERSONAL_INFO));
    };

    const popoverContent = (
        <div className="d-flex m-1 flex-column">
            <div className="popoverTxt" onClick={() => openAccountModal(EDIT_ACCOUNT)}>
                <FormattedMessage id="edit_personal_information" />
            </div>
            <div className="popoverTxt" onClick={() => openAccountModal(EDIT_ACCOUNT, PERMISSIONS)}>
                <FormattedMessage id="edit_permissions" />
            </div>
            {notSelf ? (
                <div className="popoverTxt" onClick={() => toggleIsActive()}>
                    {user.is_active ? (
                        <FormattedMessage id="deactivate_account" />
                    ) : (
                        <FormattedMessage id="activate_account" />
                    )}
                </div>
            ) : null}
        </div>
    );

    const renderEditHeader = (
        <div className="adminHeader">
            <div className="closeBox pHover" onClick={() => dispatch(toggleEditDrawer())}>
                <AiOutlineClose size="20" />
            </div>
            <div className="name">{getName(user)}</div>
            {
                hasAdminUserAccess(currUserAccessFlags)
                ? (
                    <Popover className="adminPopover" placement="bottomLeft" content={popoverContent}>
                        <div className="editBox pHover">
                            <BiDotsHorizontalRounded size="30" />
                        </div>
                    </Popover>
                )
                : (
                    <div className="adminPopover">
                        <div className="editBox"></div> 
                    </div>
                )
            }
        </div>
    );
    const renderEditBody = (
        <div className="adminBody m-2">
            <div className="d-flex justify-content-between w100 align-items-center">
                <div className="admin-sub-head">
                    <FormattedMessage id="personal_information" />
                </div>
                {
                    hasAdminUserAccess(currUserAccessFlags)
                    ? (
                        <div className="admin-sub-sub-head lightBlue pHover" onClick={() => openAccountModal(EDIT_ACCOUNT)}>
                            <FormattedMessage id="edit" />
                        </div>
                    )
                    : null
                }
            </div>
            {getInfoDetail("username", _.get(user, "username", ""))}
            {getInfoDetail("email", _.get(user, "email", ""))}
            {getInfoDetail("first_name", _.get(user, "first_name", ""))}
            {getInfoDetail("last_name", _.get(user, "last_name", ""))}
            {getInfoDetail("country_code", h.getAreaCodeStr(user))}
            {getInfoDetail("phone", helper.formatPhone(_.get(user, "phone.phone_number", "")))}
            {getInfoDetail("promo_code", intl.formatMessage({ id: "promo_code" }))}
            {getInfoDetail("registration_time", getSince(_.get(user, "created", "")))}
            {getInfoDetail("active", getActive(_.get(user, "is_active", "")))}
            <div className="admin-detail-container my-2"></div>
            <div className="admin-detail-container my-2" onClick={() => dispatch(setShowPassModal({ value: true }))}>
                <div className="detail-header">{intl.formatMessage({ id: "password" }).toUpperCase()}</div>
                <div className="detail-body lightBlue pHover">{intl.formatMessage({ id: "reset_password" })}</div>
            </div>
            <div className="admin-detail-container my-2" onClick={() => setShowWechatModal(true)}>
                <div className="detail-header">{intl.formatMessage({ id: "wechat_bind" }).toUpperCase()}</div>
                <div className="detail-body lightBlue pHover">{intl.formatMessage({ id: "binded_user_id" })}</div>
            </div>
        </div>
    );

    const getRoles = () => {
        const roles = _.get(user, "roles", []);
        let txt = "";
        const DEFAULT_ROLES_NUM = 4;

        const isDefaultRole = (i: number) => {
            defaultRoles?.map((role: any) => {
                if (parseInt(role?.role_id) === parseInt(roles?.[i])) {
                    const roleName = role?.role_name;
                    txt += txt ? `, ${roleName}` : roleName;
                }
            });
        };

        const isStoreRole = (i: number) => {
            storeRoles?.map((role: any) => {
                if (parseInt(role?._id) === parseInt(roles?.[i])) {
                    const roleName = helper.getTransString(role?.name, lan);
                    txt += txt ? `, ${roleName}` : roleName;
                }
            });
        };

        if (Array.isArray(roles)) {
            for (let i = 0; i < roles?.length; i++) {
                roles?.[i] <= DEFAULT_ROLES_NUM ? isDefaultRole(i) : isStoreRole(i);
            }
        }
        return txt;
    };

    const renderPermissions = (
        <div className="adminBody m-2">
            <div className="d-flex justify-content-between w100 align-items-center">
                <div className="admin-sub-head">
                    <FormattedMessage id="permissions" />
                </div>
                {
                    hasAdminUserAccess(currUserAccessFlags)
                    ? (
                        <div className="admin-sub-sub-head lightBlue pHover" onClick={() => openAccountModal(EDIT_ACCOUNT, 1)}>
                            <FormattedMessage id="edit" />
                        </div>
                    )
                    : null
                }
            </div>
            {getInfoDetail("roles", getRoles())}
        </div>
    );

    const getPasswordModal = () => (
        <Modal
            width="400px"
            footer={null}
            bodyStyle={{ padding: "10px" }}
            onCancel={() => dispatch(setShowPassModal({ value: false }))}
            visible={showPassModal}
        >
            <PasswordModal />
        </Modal>
    );

    const stepOneURL = "https://weixin.qq.com/r/xijdxT7EV2MorXqm933U";
    const WechatModal = () => (
        <Modal
            width="700px"
            footer={null}
            bodyStyle={{ padding: "10px" }}
            onCancel={() => setShowWechatModal(false)}
            visible={showWechatModal}
        >
            <div className="qr-code-modal-container m-4 d-flex flex-column">
                <div className="payment-subtitle mb-4">
                    <FormattedMessage id="wechat_bind" />
                </div>
                <div className="d-flex flex-wrap justify-space-between">
                    <div className="qr-code-step-container d-flex flex-column justify-content-center mb-3">
                        <div className="payment-description mb-4">
                            <FormattedMessage id="wechat_step_one" />
                        </div>
                        <QRCode value={stepOneURL} size={200} renderAs="svg" />
                    </div>
                    <div className="qr-code-step-container d-flex flex-column justify-content-center">
                        <div className="payment-description mb-4">
                            <FormattedMessage id="wechat_step_two" />
                        </div>
                        <QRCode value={stepOneURL} size={200} renderAs="svg" />
                    </div>
                </div>
            </div>
        </Modal>
    );

    const renderEditDrawer = (
        <div className="admin-edit-drawer " style={{ right: showDrawer ? "0px" : "-500px" }}>
            {renderEditHeader}
            {renderEditBody}
            {renderPermissions}
            {getPasswordModal()}
            {WechatModal()}
        </div>
    );

    return renderEditDrawer;
};

export default AdminUserEditDrawer;
