import { Button, Modal } from "antd";
import { RootState } from "app/reducer";
import AssignItemsModal from "components/assign-items-modal";
import { SelectOption } from "components/form";
import useLanguageSelect from "hooks/useLanguageSelect";
import _ from "lodash";
import { getCategoriesAsOptions } from "pages/categories/helper";
import { getProductsAsOptions } from "pages/products/helper";
import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updateCoupon } from "services/discounts";
import {
    clearCurrDisc,
    removeSelectedProduct,
    setCurrDisc,
    setSelectedCategories,
    setSelProdOptions,
    setShowSummary,
} from "slices/discounts";
import helper from "util/helper";
import "../../discounts.scss";
import dH, { STATUS } from "../helper";
import {
    renderFullMultiSel,
    renderHalfDateInput,
    renderHalfNumInput,
    renderHalfSwitch,
    renderHalfTxtInput,
    renderHalfTxtSelec,
    renderMockMultiSel,
} from "./form-functions";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
});

function CreateModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    const { categories, products } = useSelector(categoriesPageSelector);
    const discountCodes = useSelector((state: RootState) => state.discounts?.storeDiscountCodes);
    const currCode = useSelector((state: RootState) => state.discounts?.currentDisc);
    const selectProds = useSelector((state: RootState) => state.discounts?.selectedProducts);
    const selectOptions = useSelector((state: RootState) => state.discounts?.selProdSkus);
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);

    const discountTypes: SelectOption[] = [
        { value: "by_percent", label: intl.formatMessage({ id: "by_percent" }) },
        { value: "by_fixed", label: intl.formatMessage({ id: "by_fixed" }) },
        { value: "cart_fixed", label: intl.formatMessage({ id: "cart_fixed" }) },
    ];

    const byType: SelectOption[] = [
        { value: "all", label: intl.formatMessage({ id: "apply_to_all" }) },
        { value: "sku", label: intl.formatMessage({ id: "by_products" }) },
        { value: "category_ids", label: intl.formatMessage({ id: "by_categories" }) },
    ];

    const onSaveAssign = (selected: any[]) => {
        const options = selected.map((prd) => prd.sku) ?? [];
        dispatch(setSelProdOptions([...options]));
        setShowAssignItemsModal(false);
    };

    const checkFilled = () => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        const unique = intl.formatMessage({ id: "use_unique_code" });
        if (!currCode.to_date) {
            Modal.error({ title: intl.formatMessage({ id: "missing_to_date" }), content: req });
        }
        if (!currCode.discount) {
            Modal.error({ title: intl.formatMessage({ id: "discount_missing" }), content: req });
        }
        if (!currCode.name) {
            Modal.error({ title: intl.formatMessage({ id: "name_missing" }), content: req });
        }
        if (!currCode.from_date) {
            Modal.error({ title: intl.formatMessage({ id: "missing_from_date" }), content: req });
        }
        if (!currCode.condition) {
            Modal.error({ title: intl.formatMessage({ id: "name_missing" }), content: req });
        }
        if (!currCode?.product_id && Array.isArray(discountCodes)) {
            discountCodes.forEach((code: any) => {
                if (code.code === currCode.code) {
                    Modal.error({ title: intl.formatMessage({ id: "code_exists" }), content: unique });
                }
            });
        }
    };

    const saveCode = () => {
        checkFilled();
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        const {
            condition,
            name,
            code,
            discount,
            from_date,
            to_date,
            per_coupon,
            per_customer,
            publicly_available,
            product_id,
            product_type,
        } = currCode;
        const category_ids = condition?.list;

        let { type, status } = currCode;
        if (!status) {
            setCurr("status", STATUS.ACTIVE);
        }
        if (String(condition?.type) === "sku" && _.isEmpty(selectOptions)) {
            Modal.error({ title: intl.formatMessage({ id: "products_missing" }), content: req });
        }
        if (String(condition?.type) === "category_ids" && _.isEmpty(category_ids)) {
            Modal.error({ title: intl.formatMessage({ id: "categories_missing" }), content: req });
        }
        const cond = dH.getConditions(selectOptions, currCode);
        type = type ?? "by_percent";
        status = status ?? STATUS.INACTIVE;
        dispatch(
            updateCoupon({
                add: false,
                coupon: {
                    product_type,
                    product_id,
                    publicly_available,
                    type,
                    ...cond,
                    name,
                    discount,
                    code,
                    from_date,
                    to_date,
                    apply_to_products: selectOptions,
                    category_ids,
                    per_coupon,
                    per_customer,
                    status,
                },
            })
        );
    };

    const setCurr = (name: string, value: any) => {
        dispatch(setCurrDisc({ name, value }));
    };

    const deselect = (e: any) => {
        dispatch(removeSelectedProduct({ value: e }));
    };

    const renderActionButtons = () => {
        return (
            <div className="filter-modal-footer w100 d-flex justify-content-end mr-4">
                <Button type="primary" onClick={() => saveCode()}>
                    <FormattedMessage id="save_discount_code" />
                </Button>
            </div>
        );
    };

    const renderAssignItemsModal = () => {
        return showAssignItemsModal ? (
            <AssignItemsModal
                title={<FormattedMessage id="assigning_items" />}
                onSave={onSaveAssign}
                onCancel={() => setShowAssignItemsModal(false)}
                selectedProducts={selectProds}
            />
        ) : null;
    };

    const leaveSummary = () => {
        dispatch(clearCurrDisc());
        dispatch(setShowSummary(false));
    };

    const renderNavHead = (
        <div className="white-single-block w100 d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-center">
                <FaChevronLeft className="pHover" size={20} onClick={() => leaveSummary()} />
                <div className="ml-5 sum-title">{helper.getTransString(currCode.name, lan)}</div>
            </div>
            <MdClose className="pHover" size={30} onClick={() => leaveSummary()} />
        </div>
    );

    const renderPubliclyAvailable = (
        <div className="sum-info">
            &bull;{" "}
            <FormattedMessage id={currCode.publicly_available ? "publicly_available" : "not_publicly_available"} />
        </div>
    );

    const renderDiscountString = () => {
        const amount = _.get(currCode, "discount.amount", "0");
        const value = String(currCode.type) === "by_percent" ? `${Math.round(amount)}% ` : `$${Math.round(amount)} `;
        const off = intl.formatMessage({ id: "off_l" });
        const cond =
            String(currCode.type) === "cart_fixed"
                ? "entire_cart"
                : String(currCode.condition?.type) === "sku"
                ? "select_products"
                : String(currCode.condition?.type) === "all"
                ? "any_product"
                : "select_product_categories";
        return <div className="sum-info d-flex">&bull; {`${value} ${off} ${intl.formatMessage({ id: cond })}`}</div>;
    };
    const renderMinString = (
        <div className="sum-info d-flex">
            &bull; <FormattedMessage id="with_min_purchase" /> ${currCode.condition?.min_amt}{" "}
        </div>
    );
    const renderUsesPerCoupon = (
        <div className="sum-info d-flex">
            &bull; {currCode.per_coupon} <FormattedMessage id="uses_per_coupon" />
        </div>
    );
    const renderUsesPerCustomer = (
        <div className="sum-info d-flex">
            &bull; {currCode.per_customer} <FormattedMessage id="uses_per_customer" />
        </div>
    );
    const renderPerformance = (
        <React.Fragment>
            <div className="divider" />
            <div className="sum-title">
                <FormattedMessage id="performance" />
            </div>
            <div className="sum-info d-flex">
                &bull; {currCode.used} <FormattedMessage id="used" />
            </div>
        </React.Fragment>
    );
    const renderSummary = (
        <div className="discount-summary-container">
            <div className="white-single-block discount-summary-container flex-column">
                <div className="summary-head">
                    <FormattedMessage id="summary" />
                </div>
                <div className="d-flex w100 justify-content-between align-items-center">
                    <div className="sum-title">{helper.getTransString(currCode.name, lan)}</div>
                    <div
                        className="active-color"
                        style={{ background: Number(currCode.status) === STATUS.ACTIVE ? "#57a857" : "#ff5151" }}
                    >
                        <FormattedMessage id={Number(currCode.status) === STATUS.ACTIVE ? "active" : "inactive"} />
                    </div>
                </div>
                {renderDiscountString()}
                {currCode.condition?.min_amt ? renderMinString : null}
                {currCode.per_coupon ? renderUsesPerCoupon : null}
                {currCode.per_customer ? renderUsesPerCustomer : null}
                {renderPubliclyAvailable}
                {renderPerformance}
            </div>
        </div>
    );

    const renderSwitches = (
        <div className="halfish-sum-size w100 d-flex justify-content-between align-items-center">
            {renderHalfSwitch("enable", "status", currCode?.status, setCurr, true)}
            {renderHalfSwitch("publicly_available", "publicly_available", currCode?.publicly_available, setCurr, true)}
        </div>
    );

    const setSelCategories = (name: string, value: any) => {
        dispatch(setSelectedCategories({ name, value }));
    };

    const renderCreateForm = () => (
        <div className="summary-page d-flex">
            <div className="w100 d-flex flex-wrap justify-content-start align-items-start">
                {renderHalfTxtInput(
                    "discount_name",
                    `name.${lan}`,
                    _.get(currCode, `name.${lan}`, ""),
                    setCurr,
                    true,
                    false,
                    true
                )}
                {renderHalfTxtInput("discount_code", "code", currCode?.code ?? "", setCurr, false, false, true)}
                {renderHalfDateInput("date_start", "from_date", currCode?.from_date ?? null, setCurr, true, true)}
                {renderHalfDateInput("date_expire", "to_date", currCode?.to_date ?? null, setCurr, true, true)}
                {renderSwitches}
                {renderHalfNumInput(
                    "uses_per_coupon",
                    "per_coupon",
                    currCode?.per_coupon ?? NaN,
                    setCurr,
                    false,
                    "use_per_coup_tip",
                    true
                )}
                {renderHalfNumInput(
                    "uses_per_customer",
                    "per_customer",
                    currCode?.per_customer ?? NaN,
                    setCurr,
                    false,
                    "use_per_cust_tip",
                    true
                )}
                {renderHalfTxtSelec(
                    "discount_type",
                    "type",
                    currCode?.type,
                    discountTypes,
                    setCurr,
                    true,
                    "discount_type_tip",
                    true
                )}
                {renderHalfNumInput(
                    "discount_amount",
                    "discount.amount",
                    _.get(currCode, "discount.amount", currCode.price),
                    setCurr,
                    true,
                    undefined,
                    true
                )}
                {renderHalfNumInput(
                    "min_order_amount",
                    "condition.min_amt",
                    _.get(currCode, "condition.min_amt", NaN),
                    setCurr,
                    false,
                    "min_amount_tip",
                    true
                )}
                {renderHalfTxtSelec(
                    "discount_rule",
                    "condition.type",
                    _.get(currCode, "condition.type", "all"),
                    byType,
                    setCurr,
                    true,
                    undefined,
                    true
                )}
                {String(currCode?.condition?.type) !== "category_ids" ? null : (
                    <div className="halfish-sum-size">
                        {renderFullMultiSel(
                            "apply_to_categories",
                            "category_ids",
                            currCode?.condition?.list,
                            getCategoriesAsOptions(categories, lan),
                            setSelCategories
                        )}
                    </div>
                )}
                {String(currCode?.condition?.type) !== "sku" ? null : (
                    <div className="halfish-sum-size">
                        {renderMockMultiSel(
                            "apply_to_products",
                            "apply_to_products",
                            selectOptions,
                            getProductsAsOptions(products, lan),
                            setCurr,
                            () => setShowAssignItemsModal(true),
                            deselect
                        )}
                    </div>
                )}
                {renderActionButtons()}
                {renderAssignItemsModal()}
            </div>
            {renderSummary}
        </div>
    );

    return (
        <React.Fragment>
            {renderNavHead}
            {renderCreateForm()}
        </React.Fragment>
    );
}

export default CreateModal;
