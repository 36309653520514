import React from "react";
import { View, Image } from "@react-pdf/renderer";

const QR = (styles: React.CSSProperties | any, props: { dataURLS: string, storeLogo: string }): JSX.Element => (
    <View style={styles.qrHolder}>
        <View style={styles.qrBlock}>
            <Image src={props.dataURLS} />
        </View>
    </View>
);
export default QR;
