import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    allReviews: undefined,
    orderReviews: undefined,
    storeReviews: undefined,
    productReviews: undefined,
    totalReviews: 0,
    filterOpen: false,
    serachValue: "",
    scope: 1,
    rangeFilters: {
        dateFilters: 0,
        upDateFilters: 0,
    },
    filters: {
        customer_id: undefined,
        dt_start: undefined,
        dt_end: undefined,
        update_dt_start: undefined,
        update_dt_end: undefined,
        pid: NaN,
        page: 1,
        page_size: 10,
    },
    loading: false,
};

const slice = createSlice({
    name: "reviews",
    initialState,
    reducers: {
        clearFilters: (state) => {
            state.filters = {
                customer_id: undefined,
                dt_start: undefined,
                dt_end: undefined,
                update_dt_start: undefined,
                update_dt_end: undefined,
                pid: NaN,
                page: state.page,
                page_size: state.page_size,
            };
            state.rangeFilters = { dateFilters: 0, upDateFilters: 0 };
        },
        setRangeFilters: (state, { payload }) => {
            const filtersCopy = _.cloneDeep(state.rangeFilters);
            _.set(filtersCopy, payload?.name, payload?.value);
            state.rangeFilters = filtersCopy;
        },
        setFilters: (state, { payload }) => {
            const filtersCopy = _.cloneDeep(state.filters);
            _.set(filtersCopy, payload?.name, payload?.value);
            state.filters = filtersCopy;
        },
        setSearchValue: (state, { payload }) => {
            state.searchValue = payload;
        },
        setFilterModal: (state, { payload }) => {
            state.filterOpen = payload.value;
        },
        setScope: (state, { payload }) => {
            state.scope = payload?.scope;
        },
        getAllReviewsSuccess: (state, { payload }) => {
            state.allReviews = payload.records;
            state.totalReviews = payload?.paging?.total;
        },
        getOrderReviewsSuccess: (state, { payload }) => {
            state.orderReviews = payload.records;
            state.totalReviews = payload?.paging?.total;
        },
        getProductReviewsSuccess: (state, { payload }) => {
            state.productReviews = payload.records;
            state.totalReviews = payload?.paging?.total;
        },
        getStoreReviewsSuccess: (state, { payload }) => {
            state.storeReviews = payload.records;
            state.totalReviews = payload?.paging?.total;
        },
        setProductState: (state, { payload }) => {
            state.loading = payload.loading;
        },
    },
});

export const {
    setScope,
    setSearchValue,
    setFilters,
    clearFilters,
    setFilterModal,
    setRangeFilters,
    getAllReviewsSuccess,
    getOrderReviewsSuccess,
    getProductReviewsSuccess,
    getStoreReviewsSuccess,
    setProductState,
} = slice.actions;

export const reviews = slice.reducer;
