import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "app/reducer";
import { checkoutCartTotalSuccess } from "slices/create-order";
import { oauth } from "util/api";
import { store } from "app/store";
import _ from "lodash";
import actions from "slices/live-order";
import { getIntl } from "locale";
import { toast } from "react-toastify";
import { createMessage } from "components/message";
import { HiInformationCircle } from "react-icons/hi";
import { setCustomersState } from "slices/customers";
import { setState, clearData } from "slices/create-order";
import config from "config";
import { ASAP } from "app/constants";

/**
 * Calls cart total api
 * The api information is {@link https://wiki.goopter.com/index.php?title=Admin_Cart_Total_V9}
 *
 * @param params - it is a POST data
 */
export const checkoutCart =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ checkoutButtonLoading: true }));
        try {
            let paramsClone = _.cloneDeep(params);
            const shouldRedirectPage = _.get(paramsClone, "shouldRedirectPage", false);
            paramsClone = _.omit(paramsClone, "shouldRedirectPage");
            const response: any = await oauth("CART_TOTAL")({
                method: "POST",
                body: JSON.stringify({ data: paramsClone }),
            });

            switch (response?.RC) {
                case 200:
                    // if shouldRedirectPage is true, don't update create order states
                    if (!shouldRedirectPage) {
                        dispatch(
                            checkoutCartTotalSuccess({
                                ...response,
                                store: _.get(store.getState(), "store"),
                            })
                        );
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ checkoutButtonLoading: false }));
    };

export const checkoutOrder =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ checkoutButtonLoading: true }));
        try {
            const createOrder = params?.state;
            const customer = params?.customer;

            let exp_dt_start = "",
                exp_dt_end = "";
            if (_.isString(createOrder?.deliveryDate) && _.isString(createOrder?.deliveryTime)) {
                if (createOrder?.deliveryTime.includes(ASAP)) {
                    exp_dt_start = config.DELIVERY_TIME_ASAP;
                    exp_dt_end = config.DELIVERY_TIME_ASAP;
                } else {
                    const times = createOrder?.deliveryTime.split("-");
                    const startTime = times[0];
                    const endTime = times[1];
                    exp_dt_start = createOrder?.deliveryDate + " " + startTime;
                    exp_dt_end = createOrder?.deliveryDate + " " + endTime;
                }
            }
            const postData = {
                pay_mtd: _.get(createOrder, "paymentMethod"),
                cc: {},
                shp_mtd: _.get(createOrder, "shippingMethod"),
                shp_cd: "",
                shp_dsc: "",
                adr_lst: [
                    {
                        "address_id": _.get(createOrder, "address.id") || _.get(customer?.default_address, "id"),
                        "type": config.ADDRESS_TYPES.shipping,
                    },
                ],
                tips: _.get(createOrder, "payment.tips"),
                pt_amt: _.get(createOrder, "pointsUsed"),
                ord_cmt: `${createOrder?.comment ? `${createOrder?.notes}, ` : ""}${
                    createOrder?.storeDiscountNotes || ""
                }`,
                gc_cd: _.get(createOrder, "selectedGiftCard.code"),
                pt_cnt: _.get(createOrder, "partySize"), //party size
                tb_num: _.get(createOrder, "tableNumber"), //table number
                exp_dt_start,
                exp_dt_end,
                q_id: _.get(createOrder, "qid"),
                dy_fee: _.get(createOrder, "payment.shippingFee"),
                gp_id: _.get(createOrder, "gp_id"),
                pickup_location_id: _.get(createOrder, "pickupLocationId"),
                store_dis: _.get(createOrder, "payment.storeDiscountValue"), // Store discount. Currently not accepted by API
            };
            const response: any = await oauth("AIO")({
                method: "POST",
                body: JSON.stringify({ data: postData }),
            });
            if (response?.RC === 200) {
                dispatch(actions.getLiveOrdersPart({ order_ids: [response?.records?.ord_id] }));
                dispatch(setCustomersState({ customer: null }));
                dispatch(clearData());
                if (params?.callBack) params?.callBack();
            } else {
                toast(createMessage(`${getIntl().formatMessage({ id: "unexpect_error" })}`, HiInformationCircle));
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ checkoutButtonLoading: false }));
    };
