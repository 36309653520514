import { RootState } from "app/reducer";
import config from "config";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { setPaymentState } from "slices/store";
import { isBool } from "util/helper";
import "../payment.scss";
import { renderHalfSwitch } from "../_components/payment-functions";
import {
    renderHalfMultiNumberInput,
    renderHalfMultiTextInput,
    renderHalfTxtInput,
    renderHalfTxtSelec,
    renderTop,
} from "./payment-functions";

const CardPresentKeys = {
    vendor: "card_present_vendor",
    integration_type: "card_present_integration_type",
    merchant_id: "card_present_merch_id",
    access_token: "card_present_access_token",
    pos_id: "card_present_pos_id",
    enabled: "card_present_enabled",
    device_ids: "card_present_device_ids",
    customer_ids: "card_present_customer_ids",
    is_debug: "card_present_is_debug",
    app_id: "card_present_app_id",
    store_id: "card_present_pos_store_id",
    enable_tips: "card_present_enable_tips",
    apiSecret: "card_present_api_secret",
    authKey: "card_present_auth_key",
    configCode: "card_present_config_code",
};

const VENDOR_OPTIONS = [
    {
        label: <FormattedMessage id="clover" />,
        value: config.CARD_PRESENT_VENDORS.clover,
    },
    {
        label: <FormattedMessage id="global_payment" />,
        value: config.CARD_PRESENT_VENDORS.global_payment,
    },
    {
        label: <FormattedMessage id="moneris" />,
        value: config.CARD_PRESENT_VENDORS.moneris,
    },
];

const CLOVER_INTEGRATION_TYPE_OPTIONS = [
    {
        label: <FormattedMessage id="rest_pay_api" />,
        value: config.CLOVER_INTEGRATION_TYPES.rest_pay_api,
    },
    {
        label: <FormattedMessage id="remote_pay_sdk" />,
        value: config.CLOVER_INTEGRATION_TYPES.remote_pay_sdk,
    },
];

const MONERIS_INTEGRATION_TYPE_OPTIONS = [
    {
        label: <FormattedMessage id="cloud_solution" />,
        value: config.MONERIS_INTEGRATION_TYPES.cloud_solution,
    },
    {
        label: <FormattedMessage id="semi_integrated_solution" />,
        value: config.MONERIS_INTEGRATION_TYPES.semi_integrated_solution,
    },
];

interface CardPresentStateProps {
    paymentOptions: any;
    roles: any;
}

interface CardPresentProps extends CardPresentStateProps, PropsFromRedux {
    lan: string;
}

const CardPresent = (props: CardPresentProps) => {
    const { setPaymentState, paymentOptions } = props;
    const {
        card_present_vendor,
        card_present_merch_id,
        card_present_access_token,
        card_present_pos_id,
        card_present_enabled,
        card_present_device_ids,
        card_present_customer_ids,
        card_present_is_debug,
        card_present_app_id,
        card_present_pos_store_id,
        card_present_enable_tips,
        card_present_api_secret,
        card_present_auth_key,
        card_present_config_code,
    } = paymentOptions;
    const card_present_integration_type = Number(paymentOptions.card_present_integration_type);

    const [deviceId, setDeviceId] = useState("");
    const [customerId, setCustomerId] = useState<number | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(true);
        if (!card_present_vendor) {
            setPaymentState({
                name: CardPresentKeys.vendor,
                value: VENDOR_OPTIONS[0].value,
            });
        }

        //Check enable tips starting value. If undefined/null, set to enabled by default
        if (card_present_enable_tips == null) {
            setPaymentState({
                name: CardPresentKeys.enable_tips,
                value: 1,
            });
        }
    }, []);

    useEffect(() => {
        if (isClover() && isLoaded) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: config.CLOVER_INTEGRATION_TYPES.rest_pay_api,
            });
        } else if (isMoneris() && isLoaded) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: config.MONERIS_INTEGRATION_TYPES.cloud_solution,
            });
        } else if (!isClover() && !isMoneris()) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: null,
            });
        }
    }, [card_present_vendor]);

    const setState = (name: string, value: any) => setPaymentState({ name, value });

    const isClover = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.clover;
    };

    const isCloverRESTPayAPI = () => {
        return isClover() && card_present_integration_type === config.CLOVER_INTEGRATION_TYPES.rest_pay_api;
    };

    const isCloverRemotePaySDK = () => {
        return isClover() && card_present_integration_type === config.CLOVER_INTEGRATION_TYPES.remote_pay_sdk;
    };

    const isMoneris = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.moneris;
    };

    const isMonerisSemiIntegrated = () => {
        return (
            isMoneris() && card_present_integration_type === config.MONERIS_INTEGRATION_TYPES.semi_integrated_solution
        );
    };

    const isMonerisCloud = () => {
        return isMoneris() && card_present_integration_type === config.MONERIS_INTEGRATION_TYPES.cloud_solution;
    };

    const isGlobalPayment = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.global_payment;
    };
    const isSuperAdmin = () => {
        return props.roles.includes(999);
    };
    const shouldRender = (key: string): boolean => {
        switch (key) {
            case CardPresentKeys.store_id:
                return isMonerisCloud();
            case CardPresentKeys.integration_type:
                return isClover() || isMoneris();
            case CardPresentKeys.merchant_id:
                return !isGlobalPayment() && (!isClover() || isCloverRESTPayAPI());
            case CardPresentKeys.app_id:
                return (!isClover() && !isMoneris()) || isCloverRemotePaySDK() || isGlobalPayment();
            case CardPresentKeys.pos_id:
                return !isClover() && !isMoneris() && !isGlobalPayment();
            case CardPresentKeys.device_ids:
                return !isClover() && !isMoneris() && !isGlobalPayment();
            case CardPresentKeys.customer_ids:
                return (!isClover() && !isMoneris() && !isGlobalPayment()) || isCloverRemotePaySDK();
            case CardPresentKeys.apiSecret:
                return isGlobalPayment() && isSuperAdmin();
            case CardPresentKeys.authKey:
                return isGlobalPayment() && isSuperAdmin();
            case CardPresentKeys.access_token:
                return !isMonerisSemiIntegrated();
            case CardPresentKeys.configCode:
                return isMonerisSemiIntegrated();
            default:
                return true;
        }
    };

    const handleOnDeviceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceId(event.target.value);
    };

    const handleRemoveDeviceId = (index: number) => {
        const deviceIds = _.cloneDeep(card_present_device_ids) ?? [];
        deviceIds.splice(index, 1);
        setState(CardPresentKeys.device_ids, deviceIds);
    };

    const handleAddDeviceId = (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: string) => {
        if (value) {
            const deviceIds = _.cloneDeep(card_present_device_ids) ?? [];
            deviceIds.push(value);
            setState(CardPresentKeys.device_ids, deviceIds);
        }

        setDeviceId("");
    };

    const handleOnCustomerIdChange = (value?: number) => {
        setCustomerId(value);
    };

    const handleRemoveCustomerId = (index: number) => {
        const customerId = _.cloneDeep(card_present_customer_ids) ?? [];
        customerId.splice(index, 1);
        setState(CardPresentKeys.customer_ids, customerId);
    };

    const handleAddCustomerId = (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: number) => {
        if (value) {
            const customerId = _.cloneDeep(card_present_customer_ids) ?? [];
            customerId.push(value);
            setState(CardPresentKeys.customer_ids, customerId);
        }

        setCustomerId(undefined);
    };

    const switchChange = (name: string, value: any) => setPaymentState({ name, value: isBool(!value) });

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "card_present_setting",
                "card_present_setting_tip",
                `${CardPresentKeys.enabled}`,
                card_present_enabled,
                switchChange
            )}
            {card_present_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtSelec(
                        "vendor",
                        `${CardPresentKeys.vendor}`,
                        card_present_vendor,
                        VENDOR_OPTIONS,
                        setState,
                        true
                    )}
                    {shouldRender(CardPresentKeys.integration_type) &&
                        renderHalfTxtSelec(
                            "integration_type",
                            `${CardPresentKeys.integration_type}`,
                            Number(card_present_integration_type),
                            isClover() ? CLOVER_INTEGRATION_TYPE_OPTIONS : MONERIS_INTEGRATION_TYPE_OPTIONS,
                            setState,
                            true
                        )}
                    {shouldRender(CardPresentKeys.app_id) &&
                        renderHalfTxtInput(
                            "app_id",
                            `${CardPresentKeys.app_id}`,
                            card_present_app_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.store_id) &&
                        renderHalfTxtInput(
                            "store_id",
                            `${CardPresentKeys.store_id}`,
                            card_present_pos_store_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.merchant_id) &&
                        renderHalfTxtInput(
                            "merchant_id",
                            `${CardPresentKeys.merchant_id}`,
                            card_present_merch_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.access_token) &&
                        renderHalfTxtInput(
                            "access_token",
                            `${CardPresentKeys.access_token}`,
                            card_present_access_token,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.apiSecret) &&
                        renderHalfTxtInput(
                            "apiSecret",
                            `${CardPresentKeys.apiSecret}`,
                            card_present_api_secret,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.authKey) &&
                        renderHalfTxtInput(
                            "authKey",
                            `${CardPresentKeys.authKey}`,
                            card_present_auth_key,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.pos_id) &&
                        renderHalfTxtInput(
                            "pos_id",
                            `${CardPresentKeys.pos_id}`,
                            card_present_pos_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.configCode) &&
                        renderHalfTxtInput(
                            "config_code",
                            `${CardPresentKeys.configCode}`,
                            card_present_config_code,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.device_ids) &&
                        renderHalfMultiTextInput({
                            name: `${CardPresentKeys.device_ids}`,
                            label: "device_ids",
                            values: _.isArray(card_present_device_ids) ? card_present_device_ids : [],
                            inputValue: deviceId,
                            onChange: handleOnDeviceIdChange,
                            required: true,
                            onAdd: handleAddDeviceId,
                            onRemove: handleRemoveDeviceId,
                        })}
                    {shouldRender(CardPresentKeys.customer_ids) &&
                        renderHalfMultiNumberInput({
                            name: `${CardPresentKeys.customer_ids}`,
                            label: "customer_ids",
                            values: _.isArray(card_present_customer_ids) ? card_present_customer_ids : [],
                            inputValue: customerId,
                            onChange: handleOnCustomerIdChange,
                            required: false,
                            onAdd: handleAddCustomerId,
                            onRemove: handleRemoveCustomerId,
                        })}
                    <div className="card-present-switch-row">
                        {renderHalfSwitch(
                            "debug",
                            `${CardPresentKeys.is_debug}`,
                            isBool(card_present_is_debug),
                            switchChange
                        )}
                        {renderHalfSwitch(
                            "enable_tips",
                            `${CardPresentKeys.enable_tips}`,
                            isBool(card_present_enable_tips),
                            switchChange
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: RootState): CardPresentStateProps => ({
    paymentOptions: _.get(state, "store.storePaymentOptions", {}),
    roles: _.get(state, "user.roles", []),
});

const mapDispatchToProps = {
    setPaymentState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardPresent);
