import _ from "lodash";
import { getIntl } from "../../../../locale";
import { DeliveryOptions } from "../../../../slices/delivery_request";
import { CustomerInfo } from "../../../../types/customer-list";
import { SelectOption } from "../Form/FormInput";

/**
 * JSX helper functions
 */

const intl = getIntl();

/**
 * @desc
 * map customer address to select options
 *
 * @param {
 *  CustomerInfo[]
 * }
 * @returns {
 *  {
 *      name: string;
 *      label: React.ReactNode;
 *      value: string;
 *  }[]
 * }
 */
export const getAddressSelectOptions = (src: CustomerInfo[]): SelectOption[] => {
    const map = new Map();

    src.map((el, i) => {
        const { id, street } = el;
        const label = formatAddress(el as unknown as AddressLabel);

        if (!_.isEmpty(street))
            map.set(String(id), {
                name: String(id) + i + "key",
                value: String(id),
                label,
            });
    });

    return Array.from(map.values());
};

export interface AddressLabel {
    id: string | number;
    firstname: string;
    lastname: string;
    phone: string;
    street: string;
    city: string;
    region: string;
    zip_code: string;
    customerId: string | undefined;

    country_id: string;
    email?: string;
    delivery_option?: DeliveryOptions | null | undefined;
    buzz?: string | null | undefined;
    unit?: string | null | undefined;
    delivery_instructions?: string | null | undefined;
}

export const formatAddress = (el: AddressLabel, oneLine?: boolean): React.ReactNode => {
    if (!el) return;
    const formatPhoneNumber = (v: string): string => {
        if (v.includes("(") || v.includes(")")) return v;
        return `(${v.substring(0, 3)}) ${v.substring(3)}`;
    };

    const {
        firstname,
        lastname,
        phone,
        street,
        city,
        region,
        zip_code,
        delivery_option,
        buzz,
        unit,
        delivery_instructions,
    } = el;

    const line1 = [];
    if (!_.isEmpty(firstname)) line1.push(firstname);
    if (!_.isEmpty(lastname)) line1.push(lastname);

    const line2 = [];
    if (!_.isEmpty(street)) line2.push(street);
    if (!_.isEmpty(city)) line2.push("\n" + city);
    if (!_.isEmpty(region)) line2.push(", " + region);
    if (!_.isEmpty(zip_code)) line2.push(", " + zip_code);
    if (!_.isEmpty(buzz)) line2.push("\n" + `${intl.formatMessage({ id: "buzz" })} ` + buzz);
    if (!_.isEmpty(unit))
        line2.push(`${!_.isEmpty(buzz) ? ", " : "\n"}` + `${intl.formatMessage({ id: "unit" })} ` + unit);
    if (!_.isEmpty(delivery_option)) line2.push("\n" + `${intl.formatMessage({ id: delivery_option })}`);
    if (!_.isEmpty(delivery_instructions))
        line2.push(`${!_.isEmpty(delivery_option) ? ", " : "\n"}` + delivery_instructions);

    return (
        <div style={{ whiteSpace: "pre-line", lineHeight: "20px" }}>
            {!oneLine ? (
                <>
                    <span style={{ fontWeight: "bold" }}>{`${formatPhoneNumber(String(phone))} · ${line1.join(
                        " "
                    )}\n`}</span>
                    <span>{line2.join("")}</span>
                </>
            ) : (
                <>
                    <span style={{ fontWeight: "bold" }}>{`${formatPhoneNumber(String(phone))}\n`}</span>
                    <span>{`${line1.join(" ")}\n`}</span>
                    <span>{line2.join("")}</span>
                </>
            )}
        </div>
    );
};

export const grpAddressByPhoneAndName = (src: CustomerInfo[]): Record<string, CustomerInfo[]> => {
    if (src) {
        const init: Record<string, CustomerInfo[]> = {};

        return src.reduce((prev, curr) => {
            const k = `${curr.phone ?? ""} · ${curr.firstname ?? ""} ${curr.lastname ?? ""}` as keyof typeof init;
            const c = { [k]: prev[k] ? [...(prev[k] as unknown as CustomerInfo[]), curr] : [curr] };
            return { ...prev, ...c };
        }, init);
    }
    return {};
};
