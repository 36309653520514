import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { getTimeListSuccess } from "../slices/time";
import { oauth } from "../util/api";

export const getTimeList = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    try {
        const response: any = await oauth("TIME_LIST")({
            method: "GET",
        });
        switch (response.RC) {
            case 200:
                dispatch(getTimeListSuccess(response));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};
