import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal } from "antd";
import PosterModal from "../poster-format-modal";
import _ from "lodash";

interface FormatModalProps {
    setState: (...args: boolean | any) => any;
    gid: string | any;
    selfServe: (type: any) => any;
    lan: string | any;
    customDiscount: number | any;
    customOrderLabel: string | any;
    customBottomLabel: string | any;
    bgColor: string | any;
    paperSize: string | any;
    qrStyle: string | any;
    dataURLS: string | any;
    codeTid: string | any;
    showRewards: boolean | any;
    addMargin: boolean | any;
    bleedingMargin: number | any;
    paymentMethodsChecked: boolean;
}

export const FormatModal = (props: FormatModalProps): JSX.Element => {
    const intl = useIntl();
    const {
        setState,
        gid,
        selfServe,
        lan,
        customDiscount,
        customOrderLabel,
        customBottomLabel,
        bgColor,
        paperSize,
        qrStyle,
        dataURLS,
        codeTid,
        showRewards,
        addMargin,
        bleedingMargin,
        paymentMethodsChecked,
    } = props;

    const openFormatModal = _.get(selfServe, "openFormatModal", false);

    const onConfirmFormat = () => {
        setTimeout(() => {
            setState("showDownloadModal", true);
        }, 1500);
        setState("openFormatModal", false);
    };

    const renderFormatModalFooter = () => (
        <div className="bottomFooter">
            <Button onClick={() => onConfirmFormat()}>
                <FormattedMessage id="confirm_format" />
            </Button>
        </div>
    );

    return (
        <Modal
            visible={openFormatModal}
            title={intl.formatMessage({ id: "format_qr_codes" })}
            width="90vw"
            footer={renderFormatModalFooter()}
            onCancel={() => setState("openFormatModal", false)}
        >
            <PosterModal
                g_id={gid}
                storeName={_.get(selfServe, `store_name_${lan}`, "")}
                customDiscount={customDiscount}
                customOrderLabel={customOrderLabel}
                customBottomLabel={customBottomLabel}
                bgColor={bgColor}
                paperSize={paperSize}
                qrStyle={qrStyle}
                dataURLS={dataURLS}
                codeTid={codeTid}
                showRewards={showRewards}
                addMargin={addMargin}
                bleedingMargin={bleedingMargin}
                paymentMethodsChecked={paymentMethodsChecked}
            />
        </Modal>
    );
};
