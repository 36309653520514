import { Button } from "antd";
import { RootState } from "app/reducer";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { ACTIONS_MAPPING, cancelDeliveryTask } from "../../../../services/delivery_request";
import {
    clearFormState,
    clearSearchParameters,
    EmptyFunction,
    MessageModalPages,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setMessageModalCallback,
    setShouldMessageModalOpen,
    setShouldModalOpen,
    setUpdateForm,
    TaskModalPages,
    updateOnActiveTask,
} from "../../../../slices/delivery_request";
import { buildUpdatedOnActivedTask } from "../helper";

const PopUpMessageModalFooter: React.FC<PropsFromRedux> = ({
    clearFormState,
    setShouldModalOpen,
    setShouldMessageModalOpen,
    setMessageModalCallback,
    callback,
    currPage,
    onActiveTask,
    cancelDeliveryTask,
    clearSearchParameters,
    setCurrentMessageModalPage,
    inputModalPage,
    setUpdateForm,
    updateOnActiveTask,
    originalOnActivetask,
    setCurrentModalPage,
}) => {
    const handleCancelTask = () => {
        if (onActiveTask?.delivery_id && onActiveTask?.order_number) {
            cancelDeliveryTask({
                delivery_id: onActiveTask?.delivery_id,
                order_number: onActiveTask?.order_number,
                action: ACTIONS_MAPPING.CANCELL_DELIVERY,
            });
        } else setShouldMessageModalOpen(false);
    };

    const renderFooter = () => {
        switch (currPage) {
            case MessageModalPages.DISCARD_UPDATE:
                return (
                    <>
                        <Button
                            onClick={() => {
                                setShouldMessageModalOpen(false);
                            }}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                if (inputModalPage === TaskModalPages.EDIT_ORDER_ID) {
                                    setUpdateForm({ key: "orderId", value: "" });
                                    const updatedTask = buildUpdatedOnActivedTask(
                                        onActiveTask,
                                        "orderId",
                                        originalOnActivetask?.order_number
                                    );
                                    if (updatedTask) updateOnActiveTask(updatedTask);
                                }
                                setShouldMessageModalOpen(false);
                                setShouldModalOpen(false);
                            }}
                        >
                            <FormattedMessage id="discard_changes" />
                        </Button>
                    </>
                );

            case MessageModalPages.DISCARD_NEW_TASK:
                return (
                    <>
                        <Button
                            onClick={() => {
                                setShouldMessageModalOpen(false);
                            }}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                clearFormState("newTask");
                                clearFormState("addressInfo");
                                clearFormState("validation");
                                clearSearchParameters();
                                setCurrentModalPage(TaskModalPages.HIDDEN);
                                setShouldMessageModalOpen(false);
                                setShouldModalOpen(false);
                            }}
                        >
                            <FormattedMessage id="discard_task" />
                        </Button>
                    </>
                );
            case MessageModalPages.MULTIPLE_TIME_WRRNING_DROPOFF:
            case MessageModalPages.MULTIPLE_TIME_WRRNING_PICKUP:
                return (
                    <>
                        <Button
                            onClick={() => {
                                if (currPage === MessageModalPages.MULTIPLE_TIME_WRRNING_DROPOFF) {
                                    setCurrentMessageModalPage(MessageModalPages.DROPOFF_TIME_PICKER);
                                }
                                if (currPage === MessageModalPages.MULTIPLE_TIME_WRRNING_PICKUP) {
                                    setCurrentMessageModalPage(MessageModalPages.PICKUP_TIME_PICKER);
                                }
                            }}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                setShouldMessageModalOpen(false);
                                callback?.();
                                setMessageModalCallback(EmptyFunction);
                            }}
                        >
                            <FormattedMessage id="proceed" />
                        </Button>
                    </>
                );
            case MessageModalPages.CANCEL_DELIVERY_TASK_CONFIRM:
                return (
                    <>
                        <Button
                            onClick={() => {
                                setShouldMessageModalOpen(false);
                            }}
                        >
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button onClick={handleCancelTask}>
                            <FormattedMessage id="confirm" />
                        </Button>
                    </>
                );
            case MessageModalPages.DELIVERY_FEE_DETAIL:
                return (
                    <Button
                        className="blue-button"
                        style={{ width: "90%", margin: "12px" }}
                        onClick={() => {
                            setShouldMessageModalOpen(false);
                        }}
                    >
                        <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                            <FormattedMessage id="ok" />
                        </span>
                    </Button>
                );
            case MessageModalPages.PICKUP_TIME_PICKER:
            case MessageModalPages.DROPOFF_TIME_PICKER:
                return (
                    <Button
                        className="blue-button"
                        style={{ width: "100%" }}
                        onClick={() => {
                            setShouldMessageModalOpen(false);
                        }}
                    >
                        <FormattedMessage id="continue" />
                    </Button>
                );
            case MessageModalPages.CANCELL_DELIVERY_TASK_ERR:
                return (
                    <Button
                        style={{ width: "100%", height: "60px", fontSize: "16px", fontWeight: "bold" }}
                        className="blue-button"
                        onClick={() => {
                            setShouldMessageModalOpen(false);
                        }}
                    >
                        <FormattedMessage id="ok" />
                    </Button>
                );
            default:
                return (
                    <Button
                        onClick={() => {
                            setShouldMessageModalOpen(false);
                        }}
                    >
                        <FormattedMessage id="ok" />
                    </Button>
                );
        }
    };

    return <div>{renderFooter()}</div>;
};

const mapStateToProps = (state: RootState) => {
    const messageModalState = state.deliveryRequests.modal.messageModal;
    return {
        currPage: messageModalState.currPage,
        callback: messageModalState.callback,
        onActiveTask: state.deliveryRequests.onActiveTask,
        originalOnActivetask: state.deliveryRequests.originalOnActivetask,
        inputModalPage: state.deliveryRequests.modal.newTaskModal.currentPage,
    };
};

const mapDispatchToProps = {
    setShouldMessageModalOpen,
    setShouldModalOpen,
    clearFormState,
    setMessageModalCallback,
    cancelDeliveryTask,
    clearSearchParameters,
    setCurrentMessageModalPage,
    setUpdateForm,
    updateOnActiveTask,
    setCurrentModalPage,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(PopUpMessageModalFooter));
