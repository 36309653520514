import { Modal, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import { setGiftcardState } from "slices/giftcards";
import { getGiftcardAccounts } from "services/giftcards";
import GiftCardAccountTable from "./gift-card-account-table";
import GiftCardAddCreditModal from "./gift-card-add-credit-modal";
import GiftCardChargeModal from "./gift-card-charge-modal";
import GiftCardHistoryModal from "./gift-card-history-modal";
import { useIntl } from "react-intl";

const selector = (state: RootState) => ({
    currentId: state?.giftcards?.currentId,
    title: state?.giftcards?.title,
    showGiftCardAccountModal: state?.giftcards?.showGiftCardAccountModal,
    pageSize: state?.giftcards?.accountsPageSize,
    isHideZeroBalance: state?.giftcards?.isHideZeroBalance,
});

function GiftCardAccountModal(): JSX.Element {
    const { currentId, title, showGiftCardAccountModal, pageSize, isHideZeroBalance } = useSelector(selector);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isPageLoaded) {
            dispatch(
                getGiftcardAccounts({
                    product_id: currentId,
                    product_name: title,
                    limit: pageSize,
                    is_hide_zero_balance: isHideZeroBalance,
                })
            );
        } else {
            setIsPageLoaded(true);
        }
    }, [isHideZeroBalance]);

    const handleClose = () => {
        dispatch(setGiftcardState({ accounts: [], showGiftCardAccountModal: false }));
    };

    const renderHideZeroBalanceGiftcard = () => {
        return (
            <div className="giftcard-hide-zero-balance-checkbox">
                <Checkbox
                    onChange={(e) => dispatch(setGiftcardState({ isHideZeroBalance: Number(e.target.checked) }))}
                    checked={Boolean(isHideZeroBalance)}
                >
                    <span className="giftcard-hide-zero-balance-checkbox-text">
                        {intl.formatMessage({ id: "hide_zero_balance_giftcard" })}
                    </span>
                </Checkbox>
            </div>
        );
    };

    return (
        <Modal visible={showGiftCardAccountModal} onCancel={handleClose} width={1024} title={title} footer={null}>
            {renderHideZeroBalanceGiftcard()}
            <GiftCardAccountTable />
            <GiftCardAddCreditModal />
            <GiftCardChargeModal />
            <GiftCardHistoryModal />
        </Modal>
    );
}

export default GiftCardAccountModal;
