import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";

interface CustomerGiftCardAccountValueProps {
    renderValue: any;
    value: string;
}

function CustomerGiftCardAccountValueRenderer(props: CustomerGiftCardAccountValueProps): JSX.Element | null {
    const { renderValue } = props;
    const accounts = useSelector((state: RootState) => state?.giftcards?.accounts);

    const record = accounts?.find((account: any) => Number(account?.giftcardaccount_id) === Number(props?.value));

    return record ? <div>{renderValue(record)}</div> : null;
}

export default CustomerGiftCardAccountValueRenderer;
