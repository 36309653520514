import en from "./en";
import zh from "./zh";
import kr from "./kr";
import zhHant from "./zh-Hant";
import de from "./de";
import fr from "./fr";
import { store } from "../app/store";
import { createIntl } from "react-intl";

const messages: { [key: string]: any } = {
    en,
    zh,
    kr,
    "zh-Hant": zhHant,
    de,
    fr,
};

function getIntlMessages(language = "en"): Record<string, string> {
    const currentMessages = messages[language] || {};
    return currentMessages;
}

export function getIntl(): Record<string, any> {
    const state = store.getState();
    // @ts-ignore
    const { lan = "en" } = state?.setting ?? {};
    return createIntl({ locale: lan, messages: getIntlMessages(lan) });
}

export function getLocaleString(id: string, lan: string, values?: Record<string, any>): Record<string, any> | any {
    return createIntl({
        locale: lan,
        messages: getIntlMessages(lan),
    }).formatMessage({ id }, values);
}

export default {
    getIntlMessages,
    getLocaleString,
};
