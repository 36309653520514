import { createSlice } from "@reduxjs/toolkit";

export interface PricePlan {
    details: string;
    id: number;
    name: string;
    setup_fee: number;
    monthly_fee: number;
    lan_count: number;
}

export interface PricePlanState {
    plans: PricePlan[];
}

const initialState: PricePlanState = {
    plans: [],
};

const slice = createSlice({
    name: "price-plan",
    initialState,
    reducers: {
        getPlansSuccess: (state, { payload }) => {
            state.plans = payload;
        },
    },
});

export const { getPlansSuccess } = slice.actions;

export const pricePlan = slice.reducer;
