import { InputNumber, Modal } from "antd";
import React, { useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "app/reducer";
import { createMessage } from "components/message";
import { getGiftcards, manageGiftCard } from "services/giftcards";
import { setGiftcardState, updateGiftcardBalance } from "slices/giftcards";
import { ADD_CREDIT } from "../../constants";
import helper from "util/helper";

const selector = (state: RootState) => ({
    giftcardaccount_id: state?.giftcards?.addCreditId,
    oldSearch: state?.giftcards?.oldSearch,
});

function GiftCardAddCreditModal(): JSX.Element {
    const [value, setValue] = useState<string | number | undefined>(0);
    const { giftcardaccount_id, oldSearch } = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(setGiftcardState({ addCreditId: "" }));
    };

    const handleOk = () => {
        dispatch(
            manageGiftCard(
                {
                    giftcardaccount_id,
                    action: ADD_CREDIT,
                    amount: value,
                },
                () => {
                    dispatch(
                        updateGiftcardBalance({ updatedGifitcardAccountId: giftcardaccount_id, amount: Number(value) })
                    );
                    dispatch(setGiftcardState({ addCreditId: "" }));
                    dispatch(getGiftcards(oldSearch));
                    toast(
                        createMessage(
                            intl.formatMessage({ id: "successfully_added_credit" }, { value }),
                            HiInformationCircle
                        )
                    );
                }
            )
        );
    };

    return (
        <Modal
            visible={giftcardaccount_id ? true : false}
            onCancel={handleClose}
            title={<FormattedMessage id="add_credit" />}
            onOk={handleOk}
        >
            <InputNumber
                style={{ width: "100%" }}
                value={Number(value)}
                formatter={(value: any) => helper.formatMoney(value, intl)}
                parser={(value: any) => helper.parseMoney(value, intl)}
                onChange={(value) => setValue(typeof value === "number" ? value : value?.replace?.(/[^0-9.]/g, ""))}
            />
        </Modal>
    );
}

export default GiftCardAddCreditModal;
