export const FORMAT = "h:mmA";

export const DAYS: any = {
    SUN: 'sun',
    MON: 'mon',
    TUE: 'tue',
    WED: 'wed',
    THU: 'thu',
    FRI: 'fri',
    SAT: 'sat',
};

export const TIME_STEP = 5;