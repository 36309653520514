import { Button, Collapse, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../app/reducer";
import { Product } from "../../../../slices/products";
import helper from "../../../../util/helper";

function SelectedProductsDisplay(props: any = {}): JSX.Element {
    const history = useHistory();
    const { selectedProducts, setSelectedProducts, openModal, openModalArrange } = props;
    const { lan } = useSelector((state: RootState) => ({
        lan: state?.setting?.lan,
    }));
    const [panel, setPanel] = useState<string | string[]>([]);

    const renderProduct = (sp: Product) => {
        const onEdit = () => {
            history.push("/products/addEditItem/" + sp?.product_id);
        };

        const onRemove = () => {
            setSelectedProducts(selectedProducts.filter((p: Product) => p?.product_id !== sp?.product_id));
        };

        const menu = () => (
            <Menu onClick={(e) => e.domEvent.stopPropagation()}>
                <Menu.Item onClick={onEdit}>
                    <FormattedMessage id="edit_item" />
                </Menu.Item>
                <Menu.Item danger onClick={onRemove}>
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );
        return (
            <div key={sp?.product_id} className="category-selected-product">
                <div>{helper.getTransString(sp?.name, lan)}</div>
                <Dropdown overlay={menu()}>
                    <div className="category-selected-product-dots">
                        <HiDotsHorizontal />
                    </div>
                </Dropdown>
            </div>
        );
    };

    const renderPanelContent = () => {
        return (
            <div>
                <div className="selected-products-display-header-buttons">
                    <Button onClick={openModalArrange} className="assign-items-button">
                        <FormattedMessage id="arrange_products" />
                    </Button>
                    <Button onClick={openModal} className="assign-items-button">
                        <FormattedMessage id="assign_items" /> ({selectedProducts.length})
                    </Button>
                    <Button onClick={() => history.push("/products/addEditItem")} className="assign-items-button">
                        <FormattedMessage id="add_item" />
                    </Button>
                </div>

                <div className="category-selected-products">
                    {selectedProducts?.map?.(renderProduct)}

                    {selectedProducts?.length === 0 ? (
                        <div className="selected-products-empty">
                            <Button onClick={openModal} className="assign-items-button">
                                <FormattedMessage id="assign_items" /> ({selectedProducts.length})
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="selected-products-display-header">
                <div className="selected-products-display-header-title">
                    <FormattedMessage id="items" />
                </div>
            </div>

            <div className="show-items-category">
                <Collapse onChange={(key) => setPanel(key)}>
                    <Collapse.Panel
                        header={<FormattedMessage id={panel.includes("show_items") ? "hide_items" : "show_items"} />}
                        key="show_items"
                        showArrow={false}
                    >
                        {renderPanelContent()}
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    );
}

export default SelectedProductsDisplay;
