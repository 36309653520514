import React from "react";
import config from "../../.../../../../config";
import { FormattedMessage } from "react-intl";
import Format1 from "./poster-formats/format1";
import { QRCode } from "react-qrcode-logo";
import GoopterLogo from "../../../../images/icons/goopter-logo.svg";
import Cursor from "../../../../images/icons/pointer.png";
import Star from "../../../../images/icons/star.png";
import iPhone from "../../../../images/icons/iphone-02.png";
import Phone from "../../../../images/icons/phone.png";
import "./../../index.scss";
import "./format-poster.scss";
import PaymentCont from "./_components/payment-cont/index";
import { GOOPTER_EMAIL, GOOPTER_PHONE } from "./constants";

const renderQRCode = (
    tableNum: number | any,
    gid: number | any,
    exclude: boolean | any,
    qrStyle: string | any,
    logoImage: string | any,
    codeType: string | any,
    qrSize: number | any,
    qrWidth: number | any,
    qrHeight: number | any
) => {
    let QRUrl = `${config.H5_URL}store/${gid}`;
    QRUrl = exclude ? QRUrl : QRUrl + `?lid=${tableNum}&type=${codeType}`;
    return (
        <QRCode
            value={QRUrl}
            size={qrSize}
            quietZone={0}
            logoWidth={qrWidth}
            logoHeight={qrHeight}
            logoImage={logoImage}
            qrStyle={qrStyle ?? "squares"}
        />
    );
};

const BottomInfoBar = () => {
    return (
        <div className="goopter-container">
            <div className="goopter-box">
                <div className="powered-by">
                    <p>Powered by </p>
                </div>
                <div className="goopter-logo">
                    <img src={GoopterLogo} alt="Goopter" />
                </div>
            </div>
            <div className="support-phone">
                <FormattedMessage id="support" />
                <img className="phone-logo" src={Phone} alt="" />
                {GOOPTER_PHONE}
            </div>
            <div className="support-email">{GOOPTER_EMAIL}</div>
        </div>
    );
};

const ScanText = () => {
    return (
        <div className="qr-code-text-cont">
            <FormattedMessage id="scan_and_order" />
        </div>
    );
};

interface renderFormat1Props {
    g_id: number;
    exclude: boolean;
    storeURL: string;
    startNum: number;
    dark: boolean;
    bgColor: string;
    logoImage: string | any;
    qrStyle: string | any;
    codeType: string | any;
    customDiscount: number;
    customPhone: number | any;
    customOrderLabel: string;
    customBottomLabel: string;
    paymentMethodsChecked: string[];
}

// 5 cm wide qr code with table num and scan & order first
export const renderFormat1 = (props: renderFormat1Props): JSX.Element => {
    const {
        g_id,
        exclude,
        storeURL,
        startNum,
        dark,
        bgColor,
        logoImage,
        qrStyle,
        codeType,
        customDiscount,
        customPhone,
        customOrderLabel,
        customBottomLabel,
        paymentMethodsChecked,
    } = props;
    return (
        <div className="format-poster">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-holder">
                    <div className="qr-code-url-subtext">{storeURL}</div>
                    <img className="cursor" src={Cursor} alt=""></img>
                    <img className="star" src={Star} alt=""></img>
                    <img className="iphone" src={iPhone} alt=""></img>
                    <div className="custom-discount-box">{customDiscount}% OFF</div>
                    <div className="custom-discount-text">{customOrderLabel}</div>
                    <ScanText />
                    <div className="qr-block">
                        {renderQRCode(startNum, g_id, exclude, qrStyle, logoImage, codeType, "300", "100", "100")}
                    </div>
                    <div></div>
                    <div className="custom-phone-box">
                        <img className="phone-icon" src={Phone} alt="" />
                        {customPhone}7783797918
                    </div>
                    <div className="custom-payment-box ">
                        <PaymentCont paymentMethodsChecked={paymentMethodsChecked} />
                    </div>

                    <div className="custom-order-box">{customOrderLabel}</div>
                    <div className="custom-bottom-box">{customBottomLabel}</div>
                </div>
                <BottomInfoBar />
            </div>
        </div>
    );
};

export const FORMATS = [
    {
        value: "format1",
        renderer: renderFormat1,
        component: Format1,
    },
];

export default {
    FORMATS,
    renderFormat1,
};
