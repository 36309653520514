import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { Moment } from "moment";
import { Task as TaskType } from "types/get-delivery-task-respone";
import { PAGE_LIMIT_DEFAULT, PAGE_SIZE_OPTIONS_DEFAULT } from "../pages/delivery_request/constants";
import { AddressLabel } from "../pages/delivery_request/_components/helper/AddressSlectOptions";
import { Paths } from "../types";

export const EmptyFunction = (): void => {
    // do nothing
};

/**
 * enum
 */
export enum TaskModalPages {
    HIDDEN,
    NEW_TASK_MAIN,
    NEW_TASK_ORDER,
    NEW_TASK_ADDRESS,
    NEW_TASK_ITEMS,
    READ_ONLY_ADDRESS,
    EDIT_ADDRESS,
    EDIT_ADDRESS_NEW_TASK,
    EDIT_ORDER_ID,
    SEARCH_CUSTOMER,
    ADD_BALANCE
}

export enum MessageModalPages {
    INSUFFICIENT_FUND_ERR,
    EXCEED_DELIVERY_DISTANCE_ERR,
    FAILED_TO_CREATE_DELIVERY_TASK_ERR,
    ADD_NEW_ADDRESS_ERR,
    UNEXPECTED_ERROR,
    CANCELL_DELIVERY_TASK_ERR,
    CANCEL_DELIVERY_TASK_CONFIRM,
    UPDATE_DELIVERY_TASK_ERR,
    UPDATE_DELIVERY_TASK_SUCESS,
    DISCARD_NEW_TASK,
    DISCARD_UPDATE,
    MULTIPLE_TIME_WRRNING_PICKUP,
    MULTIPLE_TIME_WRRNING_DROPOFF,
    GET_DELIVERY_FEE_ERR,
    TRACK_DELIVERY_TASK_ERR,
    TRACK_DELIVERY_TASK,
    UPDATE_ADDRESS_CUSTOMER_ID_ERR,
    DELIVERY_FEE_DETAIL,
    PICKUP_TIME_PICKER,
    DROPOFF_TIME_PICKER,
    PICKUP_TIME_PICKER_EDIT,
    DROPOFF_TIME_PICKER_EDIT,
    NOT_AUTHORIZED_DELIVERY,
}

export enum EditModalPages {
    HIDDEN,
    NEW_TASK_ITEM_NAME,
    NEW_TASK_ITEM_OPTION,
    NEW_TASK_ADD_ITEM_OPTION,
    NEW_ORDER_VALUE_AND_TIP_AMOUNT,
    UPDATE_ITEM_NAME,
    UPDATE_ITEM_OPTION,
    UPDATE_ITEM_ADD_OPTION,
    UPDATE_ORDER_VALUE_AND_TIP_AMOUNT,
}

export enum DeliveryOptions {
    LEAVE_AT_DOOR = "leave_at_door",
    MEET_AT_DOOR = "meet_at_door",
    MEET_OUTSIDE = "meet_outside",
}
/**
 * interfaces
 */
export interface SetDeliveryTasks {
    payload: {
        paginations: {
            total: number;
            summaryByStatus: summaryByStatusType;
        };
        tasks: TaskType[];
    };
}
export interface InputSelectOptions {
    timesLot: Record<string, string[]>;
    // for getting customer info TOBE REMOVE
    searchParams: string;
}

export interface FormValidation {
    isValid: boolean;
    missingField: string;
}

export interface Paginations {
    currentPage: number;
    limit: number;
    total: number;
    pageLimitOptions: string[];
    searchConditions: {
        statuses: StatusesSearchKey[];
        quickSearch: string;
        drawerFilter: DrawerFilter;
    };
    delivery_fee_total: number;
    tips_total: number;
    tax_total: number;
    fee_total: number;
    summaryByStatus: SummaryByStatus;
    balance: number;
}

export interface SummaryByStatus {
    new_pending: number;
    ongoing: number;
    delivered: number;
    cancelled: number;
}

export interface Modal {
    newTaskModal: {
        currentPage: TaskModalPages;
        shouldOpen: boolean;
    };
    messageModal: {
        shouldOpen: boolean;
        currPage: MessageModalPages;
        errorMessage: string;
        callback: () => void;
    };
    editModal: {
        currPage: EditModalPages;
        itemIndex: number | null;
        optionIndex: number | null;
    },
    addBalanceModal: {
        currPage: TaskModalPages;
        shouldOpen: boolean;
    }
}

export interface Forms {
    inputSelections: InputSelectOptions;
    newTask: NewTaskForm;
    updateTask: Partial<NewTaskForm>;
    addressInfo: AddressInfo;
    balanceInfo: BalanceInfo;
    validation: FormValidation;
}
export interface Drawer {
    shouldOpen: boolean;
}

/**
 *  main structs
 */
export interface deliveryRequestsState {
    shouldRefresh: boolean;
    isLoading: boolean;
    deliveryTasks: TaskType[];
    isFiltersCollapsed: boolean;
    isStoreOpen: boolean;
    // pull out the task for task detail page
    // null when not actived
    onActiveTask: ActivedTask | null;
    originalOnActivetask: ActivedTask | null;
    paginations: Paginations;
    modal: Modal;
    forms: Forms;
    drawer: Drawer;
    inputRefs: { [key: string]: any };
}
export interface TaskStatuses extends SummaryByStatus {
    all: number;
}

export interface ActivedTask extends TaskType {
    pickUpAddressLabel: AddressLabel;
    dropOffAddressLabel: AddressLabel;
}

export interface DrawerFilter {
    courierName: string;
    createdDateRange: [Moment | null, Moment | null];
    assignedDateRange: [Moment | null, Moment | null];
    deliveredDateRange: [Moment | null, Moment | null];
}

export interface NewTaskForm {
    optionModalOpen: boolean;
    itemModalOpen: boolean;
    orderId: string;
    isPickUpAddress: boolean;
    address: string;
    deliveryFee: number | null;
    estDropOffTime: string;
    estPickUpTime: string;
    feeBalance: number | null;
    pickUp: {
        date: string;
        time: string;
    };
    dropOff: {
        date: string;
        time: string;
    };
    additionalInfo: {
        itemsInfo: Item[];
        itemsCategory: string;
        orderValue: number | null;
        orderTax: number | null;
        orderTaxPercent: number;
        totalValue: number | null;
        tipAmount: number | null;
        containsAlcohol: boolean;
        comment: string;
        subTotal: number | null;
    };
}

export interface Item {
    name: string;
    price: number;
    quantity: number;
    options: string[];
}
export interface AddressInfo {
    id: string | null | undefined;
    customerId: string | undefined;
    countryCode: string;
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    addressLineTwo: string;
    buzzCode: string;
    componentName: string;
    deliveryOptions: DeliveryOptions | null;
    deliveryRemark: string;
    zipCode: string;
    city: string;
    region: string;
    street: string;
}

export interface BalanceInfo {
    balance: number | null;
    amount: number | null;
    newBalance: number | null;
    balanceType: string;
}

/**
 * types
 */
export type ItemsInfoType = deliveryRequestsState["forms"]["newTask"]["additionalInfo"]["itemsInfo"];

export type StatusesSearchKey = keyof TaskStatuses;

export type TaskStatusesObjectEntries = Array<[StatusesSearchKey, number]>;

export type PaginationsType = deliveryRequestsState["paginations"];

export type SearchConditionsType = deliveryRequestsState["paginations"]["searchConditions"];

export type summaryByStatusType = deliveryRequestsState["paginations"]["summaryByStatus"];

export type NewTasksType = deliveryRequestsState["forms"]["newTask"];

export type AddressInfoType = deliveryRequestsState["forms"]["addressInfo"];

export type BalanceInfoType = deliveryRequestsState["forms"]["balanceInfo"];

export type DrawerFilterKey = keyof DrawerFilter;

export type SearchConditionsTypeKey = keyof SearchConditionsType;

export type SetDrawerFilter = { [key in keyof DrawerFilter]?: string };

export type ItemadditionalInfo = NewTasksType["additionalInfo"];

export type InputSelectOptionsType = deliveryRequestsState["forms"]["inputSelections"];

export type FormValidationType = deliveryRequestsState["forms"]["validation"];

export type FormKey = keyof deliveryRequestsState["forms"];

export type SearchConditionsPath = Paths<SearchConditionsType>;

export type NewTasksPath = Paths<NewTaskForm>;

export type AddressInfoPath = Paths<AddressInfoType>;

export type BalanceInfoPath = Paths<BalanceInfo>;

export type ItemsInfoPath = Paths<ItemsInfoType>;

export type InputSelectOptionsPath = Paths<InputSelectOptionsType>;

export type FormValidationPath = Paths<FormValidationType>;

export type UpdateTaskPath = Paths<NewTaskForm>;

/**
 * apart initialState, separate form's / input's initialState for reset
 */
const initialDrawerFrilterState: DrawerFilter = {
    courierName: "",
    createdDateRange: [null, null],
    assignedDateRange: [null, null],
    deliveredDateRange: [null, null],
};

const initialAddressInfoState: AddressInfoType = {
    id: null,
    customerId: undefined,
    countryCode: "",
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    addressLineTwo: "",
    buzzCode: "",
    componentName: "",
    deliveryOptions: null,
    deliveryRemark: "",
    zipCode: "",
    city: "",
    region: "",
    street: "",
};

const initialBalanceInfoState: BalanceInfoType = {
    balance: 0,
    amount: 0,
    balanceType: "",
    newBalance: 0
};

export const initialNewTaskRequestState: NewTasksType = {
    orderId: "",
    isPickUpAddress: false,
    address: "",
    deliveryFee: null,
    estDropOffTime: "",
    estPickUpTime: "",
    feeBalance: null,
    pickUp: {
        date: "",
        time: "",
    },
    dropOff: {
        date: "",
        time: "",
    },
    optionModalOpen: false,
    itemModalOpen: false,
    additionalInfo: {
        itemsInfo: [],
        itemsCategory: "",
        orderValue: null,
        orderTax: null,
        orderTaxPercent: 0.1,
        totalValue: null,
        tipAmount: null,
        containsAlcohol: false,
        comment: "",
        subTotal: null,
    },
};

const initialInputSelections: InputSelectOptions = {
    timesLot: {},
    searchParams: "",
};

const initialFormValidation: FormValidation = {
    isValid: false,
    missingField: "",
};

/**
 *  clear state mapping
 */
export const INITIAL_FORM_STATE_MAPPING: {
    [key in FormKey]: any;
} = {
    updateTask: {},
    newTask: initialNewTaskRequestState,
    addressInfo: initialAddressInfoState,
    balanceInfo: initialBalanceInfoState,
    inputSelections: initialInputSelections,
    validation: initialFormValidation,
};

/**
 * initial state
 */
const initialState: deliveryRequestsState = {
    shouldRefresh: false,
    isLoading: false,
    isStoreOpen: true,
    isFiltersCollapsed: false,
    deliveryTasks: [],
    onActiveTask: null,
    originalOnActivetask: null,
    paginations: {
        currentPage: 1,
        limit: PAGE_LIMIT_DEFAULT,
        total: 0,
        pageLimitOptions: PAGE_SIZE_OPTIONS_DEFAULT,
        delivery_fee_total: 0,
        tips_total: 0,
        tax_total: 0,
        fee_total: 0,
        balance: 0,
        searchConditions: {
            statuses: ["ongoing", "new_pending"],
            quickSearch: "",
            drawerFilter: {
                ...initialDrawerFrilterState,
            },
        },
        summaryByStatus: {
            new_pending: 0,
            ongoing: 0,
            delivered: 0,
            cancelled: 0,
        },
    },
    modal: {
        newTaskModal: {
            shouldOpen: false,
            currentPage: TaskModalPages.NEW_TASK_MAIN,
        },
        messageModal: {
            shouldOpen: false,
            currPage: MessageModalPages.UNEXPECTED_ERROR,
            errorMessage: "",
            callback: EmptyFunction,
        },
        editModal: {
            currPage: EditModalPages.HIDDEN,
            itemIndex: null,
            optionIndex: null,
        },
        addBalanceModal: {
            currPage: TaskModalPages.ADD_BALANCE,
            shouldOpen: false,
        }
    },
    forms: {
        updateTask: {},
        newTask: {
            ...initialNewTaskRequestState,
        },
        addressInfo: {
            ...initialAddressInfoState,
        },
        balanceInfo: {
            ...initialBalanceInfoState,
        },
        inputSelections: {
            ...initialInputSelections,
        },
        validation: {
            ...initialFormValidation,
        },
    },
    drawer: {
        shouldOpen: false,
    },
    inputRefs: {},
};

const slice = createSlice({
    name: "delivery_request",
    initialState,
    reducers: {
        /**
         * @setter
         * edit the exitings state
         */
        setIsLoading: (state, { payload }: { payload: boolean }) => {
            state.isLoading = payload;
        },
        setPaginations: (state, { payload }: { payload: { limit: number; currentPage: number } }) => {
            state.paginations.limit = payload.limit;
            state.paginations.currentPage = payload.currentPage;
        },
        setQuickSearchValue: (state, { payload }: { payload: string }) => {
            state.paginations.searchConditions.quickSearch = payload;
        },
        setDeliveryTasks: (state, { payload }: SetDeliveryTasks) => {
            state.paginations = Object.assign(state.paginations, payload.paginations);
            state.deliveryTasks = payload.tasks;
        },
        setSearchStatues: (state, { payload }: { payload: StatusesSearchKey | StatusesSearchKey[] }) => {
            const exitingStatus = state.paginations.searchConditions.statuses;
            if (_.isEmpty(payload)) state.paginations.searchConditions.statuses = ["all"];
            if (Array.isArray(payload)) {
                if (payload.includes("all") && payload.indexOf("all") !== 0) {
                    state.paginations.searchConditions.statuses = ["all"];
                } else if (payload.includes("all")) {
                    state.paginations.searchConditions.statuses = payload.filter((v) => v !== "all");
                } else {
                    state.paginations.searchConditions.statuses = payload;
                }
            } else {
                if (payload === "all") {
                    state.paginations.searchConditions.statuses = ["all"];
                } else if (exitingStatus.includes("all")) {
                    state.paginations.searchConditions.statuses = [payload];
                } else if (exitingStatus.includes(payload)) {
                    const status = exitingStatus.filter((status) => status !== payload);
                    state.paginations.searchConditions.statuses = _.isEmpty(status) ? ["all"] : status;
                } else {
                    state.paginations.searchConditions.statuses.push(payload);
                }
            }
        },
        setShouldModalOpen: (state, { payload }: { payload: boolean }) => {
            state.modal.newTaskModal.shouldOpen = payload;
        },
        setCurrentModalPage: (state, { payload }: { payload: TaskModalPages }) => {
            state.modal.newTaskModal.currentPage = payload;
        },
        setShouldMessageModalOpen: (state, { payload }: { payload: boolean }) => {
            state.modal.messageModal.shouldOpen = payload;
        },
        setCurrentMessageModalPage: (state, { payload }: { payload: MessageModalPages }) => {
            state.modal.messageModal.currPage = payload;
        },
        setShouldDrawerOpen: (state, { payload }: { payload: boolean }) => {
            state.drawer.shouldOpen = payload;
        },
        setSearchQuery: (state, { payload }: { payload: { key: SearchConditionsPath; value: any } }) => {
            const { key, value } = payload;
            state.paginations.searchConditions = _.set(state.paginations.searchConditions, key, value);
        },
        setOnActiveTask: (state, { payload }: { payload: ActivedTask }) => {
            state.onActiveTask = payload;
            state.originalOnActivetask = payload;
        },
        updateOnActiveTask: (state, { payload }: { payload: ActivedTask }) => {
            state.onActiveTask = payload;
        },
        setNewTaskFormState: (state, { payload }: { payload: { key: NewTasksPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.newTask = _.set(state.forms.newTask, key, value);
        },
        setAddressInfo: (state, { payload }: { payload: { key: AddressInfoPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.addressInfo = _.set(state.forms.addressInfo, key, value);
        },
        setBalanceInfo: (state, { payload }: { payload: { key: BalanceInfoPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.balanceInfo = _.set(state.forms.balanceInfo, key, value);
        },
        setAddressInfoState: (state, { payload }: { payload: Partial<AddressInfo> }) => {
            state.forms.addressInfo = Object.assign(state.forms.addressInfo, payload);
        },
        setBalanceInfoState: (state, { payload }: { payload: Partial<BalanceInfo> }) => {
            state.forms.balanceInfo = Object.assign(state.forms.balanceInfo, payload);
        },
        // only tigger when api called
        setTimesLotOptions: (
            state,
            { payload }: { payload: { timesLot: Record<string, string[]>; isStoreOpen: boolean } }
        ) => {
            state.forms.inputSelections.timesLot = payload.timesLot;
            state.isStoreOpen = payload.isStoreOpen;
        },
        setOptionModal: (state, { payload }: { payload: boolean }) => {
            state.forms.newTask.optionModalOpen = payload;
        },
        setItemModal: (state, { payload }: { payload: boolean }) => {
            state.forms.newTask.itemModalOpen = payload;
        },
        setInputSelectionState: (state, { payload }: { payload: { key: InputSelectOptionsPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.inputSelections = _.set(state.forms.inputSelections, key, value);
        },
        setIsPickUpAddress: (state, { payload }: { payload: boolean }) => {
            state.forms.newTask.isPickUpAddress = payload;
        },
        setFormValidation: (state, { payload }: { payload: { key: FormValidationPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.validation = _.set(state.forms.validation, key, value);
        },
        setNewTaskItemsInfoState: (state, { payload }: { payload: { key: ItemsInfoPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.newTask.additionalInfo.itemsInfo = _.set(
                state.forms.newTask.additionalInfo.itemsInfo,
                key as any,
                value
            );
        },
        setErrorMessage: (state, { payload }: { payload: string }) => {
            state.modal.messageModal.errorMessage = payload;
        },
        setUpdateForm: (state, { payload }: { payload: { key: UpdateTaskPath; value: any } }) => {
            const { key, value } = payload;
            state.forms.updateTask = _.set(state.forms.updateTask, key, value);
        },
        setEditModal: (state, { payload }: { payload: Partial<Modal["editModal"]> }) => {
            state.modal.editModal = Object.assign(state.modal.editModal, payload);
        },
        setIsFiltersCollapsed: (state, { payload }: { payload: boolean }) => {
            state.isFiltersCollapsed = payload;
        },
        setMessageModalCallback: (state, { payload }: { payload: () => void }) => {
            state.modal.messageModal.callback = payload;
        },
        setShouldRefresh: (state, { payload }: { payload: boolean }) => {
            state.shouldRefresh = payload;
        },
        setInputRefs: (state, { payload }: { payload: { key: string; ref: any } }) => {
            state.inputRefs = Object.assign(state.inputRefs, { [payload.key]: _.cloneDeep(payload.ref) });
        },
        /**
         * @claer
         * function to reset the state
         */
        clearSearchQuery: (state) => {
            state.paginations.searchConditions = initialState.paginations.searchConditions;
        },
        clearQuickSearch: (state) => {
            state.paginations.searchConditions.quickSearch = "";
        },
        clearOnActiveTask: (state) => {
            state.onActiveTask = null;
            state.originalOnActivetask = null;
        },
        clearFormState: (state, { payload }: { payload: FormKey }) => {
            state.forms = _.set(state.forms, payload, INITIAL_FORM_STATE_MAPPING[payload]); // //Object.assign(state.forms, INITIAL_STATE_MAPPING[payload]);
        },
        clearUpdateTaskForm: (state) => {
            state.onActiveTask = state.originalOnActivetask;
            state.forms.updateTask = {};
        },
        clearSearchParameters: (state) => {
            state.forms.inputSelections.searchParams = "";
        },
        clearInputRefs: (state) => {
            state.inputRefs = {};
        },
        /**
         * @adder
         * push a empty record
         */
        addNewTaskItem: (state) => {
            state.forms.newTask.additionalInfo.itemsInfo.push({
                name: "",
                price: 0,
                quantity: 0,
                options: [],
            });
        },
        addNewOption: (state, { payload }: { payload: { index: number; content: string } }) => {
            const index = payload.index;
            const content = payload.content;
            state.forms.newTask.additionalInfo.itemsInfo[index].options.push(content);
        },
        addNewUpdateTaskItem: (state) => {
            const newItem = {
                name: "",
                price: 0,
                quantity: 0,
                options: [],
            };
            let value;
            if (state?.forms?.updateTask?.additionalInfo?.itemsInfo) {
                value = [...state?.forms?.updateTask?.additionalInfo?.itemsInfo, newItem];
            } else {
                value = [newItem];
            }
            state.forms.updateTask = _.set(state.forms.updateTask, "additionalInfo.itemsInfo", value);
        },
        addNewUpdateOption: (state, { payload }: { payload: { index: number; content: string } }) => {
            const index = payload.index;
            const content = payload.content;
            let value;
            if (state?.forms?.updateTask?.additionalInfo?.itemsInfo[index]?.options)
                value = [...state?.forms?.updateTask?.additionalInfo?.itemsInfo[index]?.options, content];
            else value = [content];
            state.forms.updateTask = _.set(state.forms.updateTask, `additionalInfo.itemsInfo.${index}.options`, value);
        },
        calculateBalance: (state) => {
            const balance = state.forms.balanceInfo.balance??0;
            const amount = state.forms.balanceInfo.amount??0;
            const balanceType = state.forms.balanceInfo.balanceType;
            if (balanceType.toLowerCase() === "credit") {
                state.forms.balanceInfo.newBalance = balance + amount;
            } else {
                state.forms.balanceInfo.newBalance = balance - amount;
            }
        }
    },
});

export const {
    // setter
    setIsLoading,
    setPaginations,
    setQuickSearchValue,
    setDeliveryTasks,
    setSearchStatues,
    setShouldModalOpen,
    setCurrentModalPage,
    setShouldDrawerOpen,
    setSearchQuery,
    setOnActiveTask,
    setNewTaskFormState,
    setAddressInfo,
    setBalanceInfo,
    setOptionModal,
    setTimesLotOptions,
    setNewTaskItemsInfoState,
    setInputSelectionState,
    setIsPickUpAddress,
    setFormValidation,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    setErrorMessage,
    setItemModal,
    setUpdateForm,
    updateOnActiveTask,
    setEditModal,
    setIsFiltersCollapsed,
    setMessageModalCallback,
    setAddressInfoState,
    setBalanceInfoState,
    setShouldRefresh,
    setInputRefs,
    // clear
    clearFormState,
    clearSearchQuery,
    clearOnActiveTask,
    clearQuickSearch,
    clearUpdateTaskForm,
    clearSearchParameters,
    clearInputRefs,
    // add
    addNewTaskItem,
    addNewOption,
    addNewUpdateTaskItem,
    addNewUpdateOption,
    calculateBalance
} = slice.actions;

export const deliveryRequests = slice.reducer;
