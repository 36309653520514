import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getStoreList, switchStore } from "../../../../services/store-switch";
import { ApprovedStore } from "../../../../slices/store-switch";
import { useHistory } from "react-router-dom";
import h from "../../../admin-users/helper";
import { PermissionFeatures, usePermission } from "../../../../hooks/usePermission";

function StoreSwitchForm(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state?.user);
    const stores = useSelector((state: RootState) => state?.user?.stores) ?? [];
    const [selectedApprovedStore, setSelectedApprovedStore] = useState<ApprovedStore>();
    const history = useHistory();
    const { hasAccess } = usePermission();

    const getOptions = () => {
        return stores?.map?.((store) => ({
            value: `${store?.name?.toLowerCase()}|${store.id}`,
            label: (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <span>{store.name}</span>
                    <span>{store.id}</span>
                </div>
            ),
        }));
    };

    const handleSelectApproved = (value: any) => {
        const id = Number(value.split("|")[1]);
        const store = stores?.find?.((store) => store?.id === id);

        setSelectedApprovedStore(store);
    };

    const onSwitchApproved = () => {
        dispatch(switchStore(selectedApprovedStore));
    };

    const onApplyNewBranch = () => {
        history.push("/storeSignUp");
    };

    useEffect(() => {
        dispatch(getStoreList());
    }, [dispatch]);

    return (
        <div className="store-switcher-container">
            <div>
                <Select
                    showSearch
                    placeholder={intl.formatMessage({ id: "store" })}
                    options={getOptions()}
                    style={{ width: "100%" }}
                    onSelect={handleSelectApproved}
                />
            </div>
            <div style={{ paddingTop: 12 }}>
                <Button type="primary" disabled={!selectedApprovedStore} onClick={onSwitchApproved}>
                    <FormattedMessage id="switch_approved" />
                </Button>
                {h.isManagerUser(currUser) && hasAccess(PermissionFeatures.CreateStore) ? (
                    <Button className="apply-new-branch-btn" type="primary" onClick={onApplyNewBranch}>
                        <FormattedMessage id="apply_new_branch" />
                    </Button>
                ) : null}
            </div>
        </div>
    );
}

export default StoreSwitchForm;
