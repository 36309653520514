import { DINE_IN } from "app/constants";
import _ from "lodash";
import config from "config";
import { getKeyByValue, reviseDeliveryMethodWithStore } from "util/helper";
import { getTransString } from "util/helper";
import { getLocaleString } from "locale";

export const getTipsSetting = (store: any = {}): any => {
    return {
        percents: _.get(store, "tips.pct", [0, 10, 12, 15, 20]), //Array of percentage tips
        amts: _.get(store, "tips.amt", [0, 2, 3, 4, 5]), // Array of $ tips
        cut: _.get(store, "tips.limit", 20), //if the order total is over the limit then show percentage, otherwise show dollar amount,
        default_percent: _.get(store, "tips.dflt_pct", [12, 12, 0]), //1 delivery, 2 dine in, 3 pickup
        default_amt: _.get(store, "tips.dflt_amt", [2, 2, 0]),
        is_enable: String(_.get(store, "tips.is_enabled", 1)) === "1",
    };
};

/**
 * check if the current store is available for dine in
 *
 * @param props - current props for store and shippingMethod
 * @returns true if the current store is available for dine in
 */
export const isDineIn = (props = {}): boolean => {
    const methods = config.SHIPPING_MAPPING_TO_NUMERIC;
    return (
        reviseDeliveryMethodWithStore(_.get(props, "store", {}), getKeyByValue(methods, _.get(props, "state.shippingMethod", 1))) ===
        DINE_IN
    );
};

/**
 * get shipping method string id for current store
 *
 * @param props - current props for store and shippingMethod
 * @returns current shipping method string id
 */
export const getShippingMethodStrID= (props = {}): string  => {
    const shippingMethod = _.get(props, "state.shippingMethod", 1);
    let shippingMethodStrID = null;
    if ( shippingMethod !== config.SHIPPING_MAPPING_TO_NUMERIC.eatin ) {
        shippingMethodStrID = config.SHIPPING_MAPPING_TO_TEXT[shippingMethod as keyof typeof config.SHIPPING_MAPPING_TO_TEXT];
    } else {
        shippingMethodStrID = isDineIn(props) ? "dine_in" : "in_store"
    }
    return shippingMethodStrID ?? " ";
};

export const getProductPrice = (product: any = {}): number => {
    if (product?.pc) return product?.pc
    //get product base price
    let result = 0
    result += Number(product?.pcIn) || 0;
    //get opts accu price
    const opts: any[] = Array.isArray(product.opts) ? product.opts : [];
    result += opts.reduce((acc2, opt) => {
        //get sub opts accu price
        const subOpts: any[] = Array.isArray(opt.opts) ? opt.opts : [];
        acc2 += subOpts.reduce((acc3, subOpt) => {
            const singlePrice = Number(subOpt.pcIn) || 0;
            const qty = Number(subOpt.qty) || 1;
            acc3 += singlePrice * qty;
            return acc3;
        }, 0);
        return acc2;
    }, 0);
    return result;
}

export const getMenuAvailableCategories = (menu: any=[], catList: any[]): any[]=> {
   const cids = Array.isArray(_.get(menu, "ctids")) ? _.get(menu, "ctids") : [];
   //get the categories data
   const cidList = Array.isArray(catList) ? catList : [];
   //add the data info to the current menu categoreis
   const cidsWithData = cids.map((cid: any) => {
       const info = cidList.find((cat: any) => cat?.id === cid) || {};
       return { id: cid, ...info };
   });
   //those only has count
   const menuCids = cidsWithData.filter((cat: any) => cat?.c_cnt > 0);
   return _.isEmpty(menuCids) ? cidList : menuCids;
}

export const getDefaultOpts = (product: any[], lan: string): any[] => {
    const options = Array.isArray(_.get(product, "opt")) ? _.get(product, "opt") : [];
    const result: Array<any> = [];
    options.forEach((option: any) => {
        const min = _.get(option, "min", 0);
        const subOpitons = _.get(option, "opts", []);
        const subOptionsLength = subOpitons.length;
        const max = subOptionsLength > min ? min : subOptionsLength;
        const subOpts = [];
        for (let i = 0; i < max; i++) {
            const currentSubOpt = subOpitons[i];
            if (currentSubOpt) {
                subOpts.push({
                    id: currentSubOpt.id,
                    nm: { [lan]: currentSubOpt.nm },
                    pcIn: currentSubOpt.pc, //price for internal calculation only
                });
            }
        }
        if (!_.isEmpty(subOpts)) {
            result.push({
                id: option.id,
                nm: { [lan]: option.nm },
                opts: subOpts,
            });
        }
    });
    return result;
};

/**
 * get the category name of the current language
 *
 * @param category - category object
 * @param lan - current language
 * @returns string of current category name
 */
export const getCategoryName = (category: any=[], lan: string): any => {
    return category.id !== config.CATEGORY_ID_SEARCH_OPTIONS.uncategorized_products
        ? getTransString(category.name, lan)
        : getLocaleString("uncategorized_items", lan)
}

export {};
