import React from "react";
import PaymentLogos from "../../../../../../images/icons/payment-icon.svg";
import "./payment.scss";

const PaymentCont = (): JSX.Element => {
    return (
        <div className="payment-container">
            <img src={PaymentLogos} alt="Payment Logos" />
        </div>
    );
};

export default PaymentCont;
