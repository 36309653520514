import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import {
    getStatusOptions,
    getTypeOptions,
    FILTER_KEYS,
    getOptionValue,
    getAllOptionsValue,
} from "../../../helper-order-list";
import { Button } from "antd";

class FilterDrawerCollapseStatusType extends Component {
    isStatus = () => this.props.type === "status";

    getButtonSize = () => (this.isStatus() ? 3 : 2);

    getOptions = () => {
        let result;
        if (this.isStatus()) {
            result = getStatusOptions({}, this.props.storeRecords);
        } else {
            result = getTypeOptions();
        }
        return Array.isArray(result) ? result : [];
    };

    getKey = () => (this.isStatus() ? FILTER_KEYS.status : FILTER_KEYS.type);

    getCurrentFilter = () => {
        const key = this.getKey();
        const filterDrawerValue = _.get(this.props, `drawerState.${key}`, "");
        return filterDrawerValue || [];
    };

    renderContent = () => {
        const options = this.getOptions();
        const buttonSize = this.getButtonSize();
        let rows = [];
        for (var i = 0; i < options.length; i += buttonSize) {
            rows.push(_.cloneDeep(options).splice(i, buttonSize));
        }
        return rows.map((row, i) => (
            <div className="filter-drawer-button-row" key={`filter-drawer-button-row-${this.props.type}-${i}`}>
                {row.map((option, index, arr) => this.renderButton(option, arr))}
            </div>
        ));
    };

    renderButton = (option, arr) => {
        const label = this.isStatus() ? `category_${option.label}` : option.label ? option.label : " ";
        const width = 100 / arr.length - 2;
        const filter = this.getCurrentFilter();

        const onChange = () => {
            const searchValue = getOptionValue(option, this.props.type, filter, "button", this.props.storeRecords);
            this.props.setDrawerState({ [this.getKey()]: searchValue });
        };

        const type = () => {
            const isAll = option.label === "all";
            const value = option.value;
            let isOn = false;
            if (isAll) {
                isOn = _.isEqual(filter, value);
            } else {
                isOn = filter.includes(value) && !_.isEqual(filter, getAllOptionsValue(this.props.type));
            }
            return isOn ? "primary" : "default";
        };

        return (
            <Button
                type={type()}
                onClick={() => onChange()}
                className="filter-drawer-button"
                key={`filter-drawer-button-${this.props.type}-${label}`}
                style={{ width: `${width}%`, padding: 0 }}
            >
                <b>
                    <FormattedMessage id={label} />
                </b>
            </Button>
        );
    };

    render() {
        return <div>{this.renderContent()}</div>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterDrawerCollapseStatusType));
