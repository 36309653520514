import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import { FaAngleRight } from "react-icons/fa";
import { setState } from "../../../../slices/create-order";
import helper from "../../../../util/helper";
import PointsPickerDialog from "./points-picker-dialog";
import "./points-picker-section.scss";
import { Modal } from "antd";

class PointsPicker extends React.Component<any, any> {
    state = { width: window.innerWidth };

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    getActualStr = () => {
        const placeHolder = this.props.intl.formatMessage({
            id: "set_points",
        });
        return this.getPointsStr() ? this.getPointsStr() : placeHolder;
    };

    getPointsStr = () => {
        const pointsUsed = helper.formatNumber(this.props.pointsUsed);
        const pointsPayment = helper.formatCurrency(this.props.pointsPayment, this.props.currency);
        return this.props.pointsUsed ? `${pointsUsed} (${pointsPayment})` : 0;
    };

    onRowClick = () => {
        const isGiftCardsSelected = !_.isEmpty(this.props.selectedGiftCard);
        if (isGiftCardsSelected) {
            Modal.warning({
                okText: this.str("confirm"),
                content: this.str("points_gift_cards_alert_message"),
            });
        } else {
            this.props.setState({
                displayModalName: "points",
                pointsDialogUsed: this.props.pointsUsed,
            });
        }
    };

    renderItemValue = () => (
        <div className="payment-method-picker-containter">
            <div className={`payment-picker-text ${this.getPointsStr() ? "" : "grey-out"}`}>
                <FormattedMessage id={this.getActualStr()} />
            </div>
            <span className="payment-picker-value-icon">
                <FaAngleRight />
            </span>
        </div>
    );

    render() {
        return this.props.currentCustomer?.points_balance > 0 ?
            <>
                <div className="createOrder-list-item-container" onClick={() => this.onRowClick()}>
                    <div className="createOrder-list-item-section-one">
                        <FormattedMessage id="redeem_points_dialog_title" />
                    </div>
                    <div className="createOrder-list-item-section-two"> {this.renderItemValue()} </div>
                </div>
                <PointsPickerDialog />
            </> : 
            null;
    }
}

const mapStateToProps = (state: any) => ({
    lan: state.setting.lan,
    pointsUsed: _.get(state, "createOrder.pointsUsed", 0),
    pointsPayment: _.get(state, "createOrder.displayPriceInfo.pointsPayment", 0),
    pointsBalance: _.get(state, "createOrder.payment.p_bal", 0),
    currency: _.get(state, "createOrder.storeInstance.currency", 0),
    selectedGiftCard: _.get(state, "createOrder.selectedGiftCard", {}),
    currentCustomer: _.get(state, "customers.customer", {})
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PointsPicker));
