import React from "react";
import { QRCode } from "react-qrcode-logo";
import config from "../../.../../../../config";

export const getOriginalIndex = (
    g_id: number,
    qrStyle: string | any,
    storeImages: string | any,
    setQrCodes: (type: any) => any
): boolean => {
    const renderQRCode = () => {
        const QRUrl = `${config.H5_URL}store/${g_id}`;
        return (
            <QRCode
                value={QRUrl}
                size={130}
                ecLevel="Q"
                enableCORS={true}
                qrStyle={qrStyle}
                logoWidth={40}
                logoHeight={40}
                logoImage={storeImages.store_qr_logo}
                key={QRUrl}
            />
        );
    };

    const getQrCodes = () => {
        const codes: any = [];
        codes.push(renderQRCode());
        setQrCodes(codes);
    };

    getQrCodes();

    return true;
};

export const QRCodeRender = (props: string | any): JSX.Element => (
    <div className="hiddenQRS">{props.qrCodes || []}</div>
);
