import React from "react";
import { View, Text } from "@react-pdf/renderer";

const storeInfo = (styles: React.CSSProperties | any, props: { storeURL: string }): JSX.Element => (
    <React.Fragment>
        <View style={styles.qr_code_url_subtext}>
            <Text>{props.storeURL}</Text>
        </View>
    </React.Fragment>
);
export default storeInfo;
