import React, { useEffect } from "react";
import h, { getPreviouslySetMerchantCode } from "../helper";
import { Switch } from "antd";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { isBool, switchNum } from "../../../../../util/helper";
import { Prompt } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput } from "./payment-functions";

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));
    const switchNumChange = (name: string, value: any) => {
        dispatch(setPaymentState({ name, value: switchNum(value) }));
    };
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const {
        braintree_enabled,
        braintree_pub_key,
        braintree_pri_key,
        braintree_google_merchant_id,
        braintree_allow_paypal,
    } = paymentOptions;
    const braintree_merch_id = paymentOptions.braintree_merch_id;

    useEffect(() => {
        if (!braintree_merch_id) {
            setState("braintree_merch_id", getPreviouslySetMerchantCode(paymentOptions));
        }
    }, []);

    const renderAllowPaypalSwitch = () => (
        <div className="d-flex w100 mt-1 payment-subtitle">
            <FormattedMessage id="braintree_allow_paypal" />
            <div className="enableSwitch ml-3">
                <Switch
                    checked={isBool(braintree_allow_paypal)}
                    onChange={() => switchNumChange("braintree_allow_paypal", braintree_allow_paypal)}
                />
            </div>
        </div>
    );

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            <Prompt
                when={h.checkRequiredFilled(paymentOptions, "braintree_setting")}
                message={intl.formatMessage({ id: "payment_option_disabled_unless_required" })}
            />
            {renderTop(
                "braintree_setting",
                "braintree_setting_tip",
                "braintree_enabled",
                braintree_enabled,
                switchChange
            )}
            {braintree_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("merchant_id", "braintree_merch_id", braintree_merch_id, setState, false, true)}
                    {renderHalfTxtInput("public_key", "braintree_pub_key", braintree_pub_key, setState, false, true)}
                    {renderHalfTxtInput("private_key", "braintree_pri_key", braintree_pri_key, setState, false, true)}
                    {renderHalfTxtInput(
                        "braintree_google_merchant_id",
                        "braintree_google_merchant_id",
                        braintree_google_merchant_id,
                        setState,
                        false,
                        true
                    )}
                    {renderAllowPaypalSwitch()}
                </div>
            ) : null}
        </div>
    );
}

export default App;
