const StorePickupDelivery = `
    store_flg,          allow_delivery,                                 false,          7;
    store_flg,          allow_takeout,                                  false,          6;
    store_flg,          allow_eatin,                                    false,          8;
    store_flg,          specify_delivery,                               false,          60;
    store_flg,          specify_pickup,                                 false,          59;
    store_flg,          flat_delivery_fee_distance,                      false,          20;
    store_flg,          base_delivery_fee,                              false,          19;
    store_flg,          extra_dollar_per_km,                            false,          35;
    store_flg,          delivery_time_range,                            false,          49;
    store_flg,          pickup_time_range,                              false,          50;
    store_flg,          max_delivery_distance,                          false,          43;
    store_flg,          min_delivery_amount,                            false,          44;
    store_flg,          min_pickup_amount,                              false,          55;
    store_flg,          min_pickup_amount,                              false,          55;
    store_flg,          delivery_preparation_time,                      false,          36;
    store_flg,          order_ahead_days,                               false,          61;
    store_flg,          prepare_order_threshold,                        false,          58;
    store_flg,          preferred_delivery_method,                      false,          53;
    store_flg,          distance_unit,                                  false,          70;
    store_flg,          pickup_preparation_time,                        false,          72;
    store_flg,          optional_delivery_method,                       false,          74;
    store_flg,          create_3rdparty_delivery_task_on_accept_order,  false,          93;
    store_flg,          enable_3rdparty_local_delivery,                 false,          94;
`;

export default StorePickupDelivery;
