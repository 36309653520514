import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal, Select, Button, Spin, Collapse } from "antd";
import "./export-order-section.scss";
import FilterCollapse from "./export-order-filter-collaspse";
import TypeCheckbox from "./export-order-fitler-type";
import {
    FILTER_EXPORT_TYPE_MAPPING,
    FILTER_KEYS,
    FILTER_RETURN_TYPE_MAPPING,
    getDefaultFilters,
    getOrderId,
    sortFilterSearchValues,
} from "../../../helper-order-list";
import actions from "../../../../../slices/order-history";
import liveOrderActions from "../../../../../slices/live-order";

const { Option } = Select;
const { Panel } = Collapse;
const EXPORT_TYPE_PANEL_KEY = "export_type";

class ExportOrderModel extends Component {
    state = {
        modalState: { lan: this.props.lan },
    };

    componentDidUpdate(prevProps) {
        if (prevProps.lan !== this.props.lan) {
            const modalStateClone = _.cloneDeep(this.state.modalState);
            modalStateClone.lan = this.props.lan;
            this.setState({ modalState: modalStateClone });
        }
    }

    getModalDefaultFilters = () => ({
        ...getDefaultFilters(),
        ..._.get(this.props, "state.searchConditions.search_condition"),
    });

    getState = () => {
        return {
            lan: this.props.lan,
            ...this.getModalDefaultFilters(),
            ...this.state.modalState,
        };
    };

    getSelectedOrderExportOptions = () => {
        return {
            [FILTER_KEYS.order_id]: this.props.selectedExportOrders.map((selectedOrder) => getOrderId(selectedOrder)),
            [FILTER_KEYS.lan]: this.state.modalState[FILTER_KEYS.lan],
            [FILTER_KEYS.export_type]:
                this.state.modalState[FILTER_KEYS.export_type] ?? FILTER_EXPORT_TYPE_MAPPING.all_in_one_report_excel, // default export type to all in one report excel
            [FILTER_KEYS.return_type]: FILTER_RETURN_TYPE_MAPPING.csv,
        };
    };

    clearState = () => {
        this.setState({ modalState: { lan: this.props.lan, ...this.getModalDefaultFilters() } });
    };

    showModel = () => _.get(this.props, "state.showExportDialog", false);

    onCancelClick = () => {
        this.props.setState({
            showExportDialog: false,
            doneExportSelection: false,
            useExportSelection: this.props.doneExportSelection,
        });
        this.clearState();
    };

    updateValues = (values) => {
        let updateObject = {
            modalState: { ...this.state.modalState, ...values },
        };
        this.setState(updateObject);
    };

    renderLanSelect = () => {
        const storeLans = () => {
            const storeDatas = Array.isArray(_.get(this.props, "storesData")) ? _.get(this.props, "storesData") : [];
            let result = [];
            storeDatas.forEach((store) => {
                const lans = Array.isArray(_.get(store, "general_info.lan_lst"))
                    ? _.get(store, "general_info.lan_lst")
                    : [];
                lans.forEach((lan) => {
                    if (!result.includes(lan)) result.push(lan);
                });
            });
            return result;
        };

        return (
            <div className="export-order-section">
                <span className="export-order-section-title">
                    <FormattedMessage id="item_lan" />
                </span>
                <span className="export-order-item-lan-select">
                    <Select
                        onChange={(value) => this.updateValues({ lan: value })}
                        style={{ width: 150 }}
                        value={this.state.modalState.lan}
                    >
                        {storeLans().map((lan) => (
                            <Option key={`export-order-lan-${lan}`} value={lan}>
                                <FormattedMessage id={lan} />
                            </Option>
                        ))}
                    </Select>
                </span>
            </div>
        );
    };

    renderSelectedOrderIds = (selectedOrder, index) => {
        return index === this.props.selectedExportOrders.length - 1
            ? getOrderId(selectedOrder)
            : getOrderId(selectedOrder) + ", ";
    };

    renderSelectedOrders = () => {
        return (
            <div className="selected-export-order-container">
                <div className="selected-export-order-header">
                    <FormattedMessage id="selected_order_ids" />
                </div>
                <div className="selected-export-order-id-container">
                    {this.props.selectedExportOrders.map(this.renderSelectedOrderIds)}
                </div>
            </div>
        );
    };

    renderCollapse = () => {
        return (
            <div className="export-order-section">
                <div className="export-order-section-title">
                    <FormattedMessage id="export_order_filter_title" />
                </div>
                <div className="export-order-filters export-order-collaspse">
                    <FilterCollapse
                        modalState={this.getState()}
                        setModalState={(values) => this.updateValues(values)}
                    />
                </div>
            </div>
        );
    };

    renderTypeSelection = () => {
        return (
            <div className="export-order-section">
                <Collapse expandIconPosition="right">
                    <Panel
                        header={
                            <div className="export-order-section-title">
                                <FormattedMessage id="export_type" />
                            </div>
                        }
                        key={EXPORT_TYPE_PANEL_KEY}
                    >
                        <div className="export-order-filters">
                            <TypeCheckbox
                                modalState={this.getState()}
                                setModalState={(values) => this.updateValues(values)}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
        );
    };

    renderButtons = () => {
        const data = this.props.doneExportSelection
            ? this.getSelectedOrderExportOptions()
            : sortFilterSearchValues(this.getState());

        return (
            <div className="export-order-buttons">
                <Button
                    onClick={() => {
                        this.props.exportOrders({ data });
                    }}
                    type="primary"
                >
                    <b>
                        <FormattedMessage id="export" />
                    </b>
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Modal
                bodyStyle={{ padding: `12px 24px` }}
                width={550}
                onCancel={() => this.onCancelClick()}
                title={<FormattedMessage id="export_orders" />}
                footer={null}
                visible={this.showModel()}
            >
                <Spin spinning={this.props.isLiveOrderLoading}>
                    <div className="order-list-export-modal">
                        {this.renderLanSelect()}
                        {this.props.doneExportSelection ? this.renderSelectedOrders() : this.renderCollapse()}
                        {this.renderTypeSelection()}
                        {this.renderButtons()}
                    </div>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    isLiveOrderLoading: _.get(state, "liveOrders.isListLoading", false),
    doneExportSelection: _.get(state, "orders-page.doneExportSelection", false),
    selectedExportOrders: _.get(state, "orders-page.selectedExportOrders", []),
});

const mapDispatchToProps = {
    setState: actions.setState,
    exportOrders: liveOrderActions.exportOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportOrderModel));
