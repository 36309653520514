import React from "react";
import images from "../../images";

function Logo(): JSX.Element {
    return (
        <div>
            <img src={images["goopter-logo"]?.default} alt="" className="goopter-logo"></img>
        </div>
    );
}

export default Logo;
