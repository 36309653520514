import React from "react";
import _ from "lodash";
import { RootState } from "../../../../../../app/reducer";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAutoDiscounts, setShipModal } from "../../../../../../slices/discounts";

function ShippingModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const discounts = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts);
    const min_order = _.get(discounts, "disc_free_delivery.data.min_order", NaN);
    const distance = _.get(discounts, "disc_free_delivery.data.distance", NaN);
    const enabled = _.get(discounts, "disc_free_delivery.data.is_enabled", 1);

    const saveDiscount = () => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        if (distance && min_order) {
            dispatch(setShipModal(false));
            dispatch(setAutoDiscounts({ name: `disc_free_delivery.data.is_enabled`, value: enabled }));
        } else if (!distance) {
            Modal.error({ title: intl.formatMessage({ id: "distance_missing" }), content: req });
        } else {
            Modal.error({ title: intl.formatMessage({ id: "missing_min_order" }), content: req });
        }
    };

    const setShipping = (name: string, value: string) => {
        dispatch(setAutoDiscounts({ name: `disc_free_delivery.data.${name}`, value: parseInt(value) }));
    };

    const renderDiscountDetails = (
        <div className="d-flex discount-details">
            <div className="d-flex flex-column discount-details-field discount-details-mr">
                <div className="filter-subtitle">
                    <FormattedMessage id="minimum_amount" />
                </div>
                <input
                    className="custom-input-textfield number-input"
                    type="number"
                    value={min_order ?? NaN}
                    onChange={(e) => setShipping("min_order", e.target.value)}
                />
            </div>
            <div className="d-flex flex-column discount-details-field">
                <div className="filter-subtitle">
                    <FormattedMessage id="distance" />
                </div>
                <input
                    className="custom-input-textfield number-input"
                    type="number"
                    value={distance ?? NaN}
                    onChange={(e) => setShipping("distance", e.target.value)}
                />
            </div>
        </div>
    );

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => dispatch(setShipModal(false))}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button type="primary" onClick={() => saveDiscount()}>
                <FormattedMessage id="update_discount" />
            </Button>
        </div>
    );

    const renderCreateForm = () => (
        <div className="filter-modal d-flex flex-column justify-content-start align-items-start">
            {renderDiscountDetails}
            {renderActions}
        </div>
    );

    return renderCreateForm();
}

export default ShippingModal;
