// @ts-nocheck

import Papa from "papaparse";

export const downloadCSV = (args: Record<string,any>) : Record<string,any> => {
    let filename = args?.filename || "export";
    const columns = args?.columns || null;
    let blob;
    if (args.data) {
        const csv = Papa.unparse(args.data, { columns: columns });
        if (csv == null) return;
        blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    } else if (args?.blob) {
        blob = args?.blob;
    }

    if (!blob) {
        return;
    }

    filename = `${filename}.csv`;

    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
