import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";
import { useEffect, useCallback } from "react";
import { HiLink } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setReferralsState } from "../../../../slices/referrals";
import helper from "../../../../util/helper";
import ReferralsModalValueRenderer from "./value";

const selector = (state: RootState) => ({
    records: state?.referrals?.customerReferrals,
    paging: state?.referrals?.customerReferralsPaging,
    searchConditions: state?.referrals?.customerReferralsSearchConditions,
    noRecords: state?.referrals?.noRecords,
});

function ModalTable(): JSX.Element | null {
    const { records, searchConditions, noRecords } = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    let gridApi: any;
    const orderHistoryRenderer = (record: any) => {
        const getOrderHistory = () => {
            dispatch(
                setReferralsState({
                    orderHistorySearchConditions: {
                        ref_code: searchConditions.ref_code,
                        user_id: record?._id,
                    },
                })
            );
        };

        return (
            <div className="table-open-table" onClick={getOrderHistory}>
                <div>{record?.customer_name}</div>
                <div className="table-open-icon">
                    <HiLink />
                </div>
            </div>
        );
    };

    const columnDefs = [
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "name" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: orderHistoryRenderer,
            },
        },
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "phone" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => helper.formatPhone(record?.customer_phone),
            },
        },
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "referred_orders_amount" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) =>
                    `${record?.referred_order_count} · ${helper.formatCurrency(record?.referred_order_amt)}`,
            },
        },
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "earned_points" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => record?.points_earned?.toFixed?.(2),
            },
        },
    ];

    const getTableObject: any = () => ({
        columnDefs,
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: ReferralsModalValueRenderer,
        },
        rowData: noRecords ? [] : records,
        getRowHeight: () => 50,
        getRowNodeId: (row: any) => row?._id,
        immutableData: true,
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const getTable = () => {
        const tableObject = getTableObject();
        return records?.length
            ? React.createElement(
                  "div",
                  {
                      className: "customers-table-container customers-page referral-modal-table ag-theme-alpine",
                  },
                  React.createElement(AgGridReact, { ...tableObject })
              )
            : null;
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    return getTable();
}

export default ModalTable;
