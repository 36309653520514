import React from "react";
import storeInfo from "../_components/storeInfo";
import qrScan from "../_components/qrcode-scantxt";
import GoopterIcon from "../_components/goopter-icon";
import customTextBox from "../_components/custom-text-cont";
import tableNum from "../_components/tablenum-comp";
import paymentIcons from "../_components/payment-comp";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import "../serve-print.scss";

interface Format11Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    customdark: boolean | any;
    dataURLS: string | any;
    paperSize: string | any;
    storeName: string;
    storeURL: string | any;
    phone: string | number;
    storeLogo: string | any;
    customText1: string;
    customText2: string;
}

function Format11(props: Format11Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            padding: `${props.bleedingMargin}mm`,
        },
        qrPrintPdfContainer: {
            position: "relative",
            width: "10.12cm",
            height: "11cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            marginBottom: `${props.bleedingMargin ?? 0}mm`,
            justifyContent: "center",
            backgroundColor: props.customdark ? "#504F4F" : props.bgColor ?? "#20817b",
            flexDirection: "column",
            borderRadius: "10pt",
            fontSize: "16pt",
        },
        qrCodeCardBlock: {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: "100%",
            padding: "15pt",
        },
        tableNumCont: {
            position: "relative",
            width: "100%",
            display: "flex",
            height: "40px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10pt",
            marginTop: "0pt",
            opacity: 0.85,
        },
        tableNumHolder: {
            position: "relative",
            height: "2.3cm",
            width: "2.3cm",
            borderRadius: "2cm",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            justifyContent: "center",
            alignItems: "center",
            color: props.customdark ? "white" : "red",
            fontSize: "24pt",
            fontWeight: 700,
        },
        qrHolder: {
            position: "relative",
            height: "110pt",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        qrCodeTextCont: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "110pt",
            marginTop: "7pt",
            height: "20pt",
            borderRadius: "5pt",
            fontWeight: 500,
            fontSize: "12pt",
            marginBottom: "50px",
            color: props.customdark ? "white" : "red",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            opacity: 0.85,
        },
        paymentContainer: {
            width: `2.5cm`,
            marginBottom: "10pt",
            marginTop: "25pt",
        },
        goopterContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            width: "120pt",
            alignItems: "center",
            justifyContent: "center",
            height: "20pt",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            borderRadius: "5pt",
            opacity: 0.85,
        },
        poweredBy: {
            fontSize: "12pt",
            fontWeight: 600,
            color: props.customdark ? "white" : "#626161",
            opacity: 1,
        },
        goopterLogo: {
            width: "1.5cm",
            marginTop: "2px",
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        storeName: {
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "16pt",
            color: "white",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            margin: "5pt",
            height: "auto",
        },
        websiteURL: {
            display: "flex",
            position: "relative",
            color: "white",
            fontWeight: "bold",
            fontSize: "2vw",
            width: "100%",
            height: "15px",
            justifyContent: "center",
            textAlign: "center",
        },
        phone: {
            position: "relative",
            color: "white",
            fontWeight: 500,
            fontSize: "15pt",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "10pt",
            height: "auto",
        },
        logo: {
            position: "absolute",
            display: "flex",
            width: "24pt",
            height: "24pt",
            top: "5px",
        },
        Title: {
            display: "flex",
            position: "relative",
            width: "100%",
            height: "50pt",
            top: "-60pt",
        },
        Top: {
            display: "flex",
            position: "relative",
            flexDirection: "row",
            width: "100%",
            height: "100pt",
        },
        topLeft: {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
        },
        topRight: {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: "50%",
            height: "100%",
        },
        Bottom: {
            display: "flex",
            position: "absolute",
            width: "100%",
            height: "50px",
            bottom: "-45pt",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "15pt",
        },
        earnPoints: {
            display: "flex",
            position: "relative",
            width: "100%",
            height: "100%",
            fontSize: "10pt",
            fontWeight: "extrabold",
            margin: "5pt",
            color: "white;",
            textAlign: "center",
        },
        customTextCont: {
            display: "flex",
            position: "relative",
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        },
        CustomtxtHolder: {
            justifyContent: "center",
            fontSize: "12pt",
        },
        CustomtxtBox: {
            marginBottom: "3px",
        },
    });
    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() =>
                        props.dataURLS?.map((url: any, index: number) => {
                            return (
                                <View
                                    key={`qrPrint-pdf-container-${index}`}
                                    style={styles.qrPrintPdfContainer}
                                    wrap={false}
                                >
                                    <View style={styles.qrCodeCardBlock}>
                                        <View style={styles.Title}>{storeInfo(styles, props)}</View>
                                        <View style={styles.Top}>
                                            <View style={styles.topLeft}>
                                                {tableNum(styles, url)}

                                                {paymentIcons(styles)}
                                            </View>
                                            <View style={styles.topRight}>{qrScan(styles, url, props)}</View>
                                        </View>
                                        <View style={styles.Bottom}>
                                            {customTextBox(styles, props)}
                                            {GoopterIcon(styles)}
                                        </View>
                                    </View>
                                </View>
                            );
                        })
                    }
                />
            </Page>
        </Document>
    );
}
export default Format11;
