import { Button } from "antd";
import { FormattedMessage } from "react-intl";
interface PrintLayoutSwitchProps {
    isPrintLayout: boolean;
    agGridRef: React.MutableRefObject<any>;
    setIsPrintLayout: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void);
}
const PrintLayoutSwitch = (props: PrintLayoutSwitchProps): JSX.Element => {
    const { isPrintLayout, agGridRef, setIsPrintLayout } = props;
    const onClick = () => {
        if (isPrintLayout) {
            agGridRef?.current?.api.setDomLayout();
            setIsPrintLayout(false);
        } else {
            agGridRef?.current?.api.setDomLayout("print");
            setIsPrintLayout(true);
        }
    };
    return (
        <div className="status-container">
            <Button onClick={onClick} className={isPrintLayout ? "online-switch-checked " : ""} size="large">
                <div className={isPrintLayout ? "status-label-white" : "status-label"}>
                    <FormattedMessage id={isPrintLayout ? "print_layout_on" : "print_layout_off"} />
                </div>
            </Button>
        </div>
    );
};
export default PrintLayoutSwitch;
