import _ from "lodash";
import React, { Component } from "react";
import Wrapper from "../../../../components/wrapper";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./new-customer.scss";
import { createCustomers } from "../../../../services/customers";
import { withRouter } from "react-router-dom";
import CustomerForm from "./customer-form";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/customers", breadcrumbName: "customers" },
        { path: "/customers/new", breadcrumbName: "new_customer" },
    ],
};

class NewCustomerPage extends Component {
    state = {};

    render() {
        return (
            <Wrapper helmet={{ title: "new_customer" }} breadcrumb={breadcrumb}>
                <CustomerForm />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
});

const mapDispatchToProps = {
    createCustomers,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewCustomerPage)));
