import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Tag } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import "./quotes-applied-filters.scss";
import { setState } from "slices/quotes";
import config from "config";
import React from "react";

const FILTERS_KEYS = {
    SHIPPING_METHOD: "shp_mtd",
    MIN_TOTAL: "min_total",
    MAX_TOTAL: "max_total",
    MIN_ITEM_COUNT: "min_item_count",
    MAX_ITEM_COUNT: "max_item_count",
};

class QuotesAppliedFilters extends Component<any, any> {
    getAllFilters = () => {
        const allFilters = _.cloneDeep(this.props.filterOptions);
        const filteredKey = { ...allFilters };
        return filteredKey;
    };

    renderAllTags = () => {
        const foundFilters = this.getAllFilters();
        return (
            <>
                {foundFilters[FILTERS_KEYS.MIN_TOTAL] !== undefined &&
                foundFilters[FILTERS_KEYS.MAX_TOTAL] !== undefined
                    ? this.renderMinMaxTotalTag(
                          foundFilters[FILTERS_KEYS.MIN_TOTAL],
                          foundFilters[FILTERS_KEYS.MAX_TOTAL]
                      )
                    : foundFilters[FILTERS_KEYS.MIN_TOTAL] !== undefined
                    ? this.renderMinTotalTag(foundFilters[FILTERS_KEYS.MIN_TOTAL])
                    : foundFilters[FILTERS_KEYS.MAX_TOTAL] !== undefined
                    ? this.renderMaxTotalTag(foundFilters[FILTERS_KEYS.MAX_TOTAL])
                    : null}
                {foundFilters[FILTERS_KEYS.MIN_ITEM_COUNT] !== undefined &&
                foundFilters[FILTERS_KEYS.MAX_ITEM_COUNT] !== undefined
                    ? this.renderMinMaxItemCountTag(
                          foundFilters[FILTERS_KEYS.MIN_ITEM_COUNT],
                          foundFilters[FILTERS_KEYS.MAX_ITEM_COUNT]
                      )
                    : foundFilters[FILTERS_KEYS.MIN_ITEM_COUNT] !== undefined
                    ? this.renderMinItemCountTag(foundFilters[FILTERS_KEYS.MIN_ITEM_COUNT])
                    : foundFilters[FILTERS_KEYS.MAX_ITEM_COUNT] !== undefined
                    ? this.renderMaxItemCountTag(foundFilters[FILTERS_KEYS.MAX_ITEM_COUNT])
                    : null}
            </>
        );
    };
    renderShippingMethodTag = (shippingMethod: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id={_.get(config.SHIPPING_MAPPING_TO_TEXT, String(shippingMethod))} />{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: { ...this.props.filterOptions, [FILTERS_KEYS.SHIPPING_METHOD]: undefined },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMinMaxTotalTag = (minTotal: number, maxTotal: number) => {
        const content = (
            <span>
                {" "}
                ${minTotal}-${maxTotal}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: {
                    ...this.props.filterOptions,
                    [FILTERS_KEYS.MIN_TOTAL]: undefined,
                    [FILTERS_KEYS.MAX_TOTAL]: undefined,
                },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMinTotalTag = (minTotal: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id="min" />: {minTotal}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: { ...this.props.filterOptions, [FILTERS_KEYS.MIN_TOTAL]: undefined },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMaxTotalTag = (maxTotal: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id="max" />: {maxTotal}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: { ...this.props.filterOptions, [FILTERS_KEYS.MAX_TOTAL]: undefined },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMinMaxItemCountTag = (minCount: number, maxCount: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id="qty" />
                {minCount}-{maxCount}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: {
                    ...this.props.filterOptions,
                    [FILTERS_KEYS.MIN_ITEM_COUNT]: undefined,
                    [FILTERS_KEYS.MAX_ITEM_COUNT]: undefined,
                },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMinItemCountTag = (minCount: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id="min" />
                <FormattedMessage id="qty" />: {minCount}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({
                filterOptions: { ...this.props.filterOptions, [FILTERS_KEYS.MIN_ITEM_COUNT]: undefined },
            });
        };
        return this.renderTag(content, handleClose);
    };

    renderMaxItemCountTag = (maxCount: number) => {
        const content = (
            <span>
                {" "}
                <FormattedMessage id="max" />
                <FormattedMessage id="qty" />: {maxCount}{" "}
            </span>
        );
        const handleClose = () => {
            this.props.setState({ ...this.props.filterOptions, [FILTERS_KEYS.MIN_ITEM_COUNT]: undefined });
        };
        return this.renderTag(content, handleClose);
    };

    renderTag = (content: any, handleClose: any) => {
        return (
            <Tag
                className={"quotes-applied-filter-tag"}
                color={"blue"}
                closable={true}
                onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                }}
                onClose={(e) => {
                    e.preventDefault();
                    handleClose();
                }}
            >
                {content}
            </Tag>
        );
    };

    render() {
        return (
            <div id="quotes-applied-filters" className="quotes-applied-filters">
                {this.renderAllTags()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "quotes", {}),
    filterOptions: _.get(state, "quotes.filterOptions", {}),
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(QuotesAppliedFilters));
