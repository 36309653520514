import { Row, Col, Dropdown, Menu, Modal, Select, Input, Button, Spin, Collapse, Checkbox } from "antd";
import { RootState } from "app/reducer";
import { createIcon } from "components/icon-with-color";
import { createMessage } from "components/message";
import config from "config";
import _ from "lodash";
import {
    AddOrderTrackingRequestBody,
    OrderTrackingInfo,
    DeleteOrderTrackingParams,
    UpdateOrderTrackingRequestBody,
} from "components/shipment-tracking/models/shipment-tracking-requests";
import { CourierMethod } from "components/shipment-tracking/models/shipment-tracking-responses";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BiCheck, BiError } from "react-icons/bi";
import { HiDotsHorizontal, HiExternalLink } from "react-icons/hi";
import { IconContext } from "react-icons/lib";
import { MdClose } from "react-icons/md";
import { TiCancel } from "react-icons/ti";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import {
    addOrderTrackingInfo,
    deleteOrderTrackingInfo,
    getCourierMethods,
    getOrderTrackingInfo,
    updateOrderTrackingInfo,
} from "services/shipment-tracking";
import { setState } from "slices/shipment-tracking";
import "./styles.scss";
import liveOrderActions from "slices/live-order";

interface ShipmentTrackingStateProps {
    courierMethods: CourierMethod[];
    orderCarriers: OrderTrackingInfo[];
    isLoading: boolean;
    errorMessage: string;
    liveOrders: any[];
    orderList: any[];
}

interface ShipmentTrackingProps extends ShipmentTrackingStateProps, PropsFromRedux {
    orderStatus: string;
    orderId: string;
}

interface SelectOption {
    label: string;
    value: string;
}

const CODE_COL_SPAN = 10;
const TRACK_NUMBER_COL_SPAN = 10;
const ACTIONS_COL_SPAN = 4;
const ROW_GUTTER = 8;
const DEFAULT_EDIT_INDEX_VALUE = -1;
const HALF_COL_SPAN = 12;
const TRACK_NUMBER_MIN_LEN = 5;

const { Panel } = Collapse;

const ShipmentTracking = (props: ShipmentTrackingProps) => {
    const intl = useIntl();

    const [courierMethodsSelectOptions, setCourierMethodsSelectOptions] = useState<SelectOption[]>([]);
    const [selectedCarrierOption, setSelectedCarrierOption] = useState<SelectOption | undefined>(undefined);
    const [trackNumber, setTrackNumber] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(DEFAULT_EDIT_INDEX_VALUE);
    const [editCarrierOption, setEditCarrierOption] = useState<SelectOption | undefined>(undefined);
    const [editTrackNumber, setEditTrackNumber] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showAddShipmentTrackingModal, setShowAddShipmentTrackingModal] = useState(false);
    const [notifyCustomer, setNotifyCustomer] = useState(false);

    useEffect(() => {
        if (_.isEmpty(props.courierMethods)) {
            props.getCourierMethods();
        }
    }, []);

    useEffect(() => {
        buildCourierMethodsSelectOptions();
    }, [props.courierMethods]);

    useEffect(() => {
        if (!props.orderId) {
            return;
        } else if (!isOrderCompleted()) {
            props.setState({ orderCarriers: [] });
        }

        props.setState({ orderCarriers: getOrderTrackingInfo() });
    }, [props.orderId]);

    useEffect(() => {
        if (!_.isEqual(props.orderCarriers, getOrderTrackingInfo())) {
            const liveOrdersClone = _.cloneDeep(props.liveOrders);
            const currOrder = liveOrdersClone.find((order) => order.ord_id === props.orderId);
            if (currOrder) {
                currOrder.shipment = _.cloneDeep(props.orderCarriers);
            }

            props.setLiveOrderState({ liveOrderList: liveOrdersClone });
        }
    }, [props.orderCarriers]);

    useEffect(() => {
        if (props.errorMessage) {
            // Toast Error Message design from live-order.ts
            toast(createMessage(intl.formatMessage({ id: props.errorMessage }), BiError, "white", "white"), {
                autoClose: false,
                closeOnClick: true,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "white !important",
                },
                closeButton: createIcon(MdClose, "white"),
            });

            props.setState({ errorMessage: "" });
        }
    }, [props.errorMessage]);

    /**
     * Use the carrier methods sent back from the api to build
     * antd's select options to display
     */
    const buildCourierMethodsSelectOptions = () => {
        const options: SelectOption[] = [];
        props.courierMethods.forEach((method) => {
            options.push({
                label: method.title,
                value: method.code,
            });
        });
        setCourierMethodsSelectOptions(options);
    };

    const handleOnCourierMethodChange = (value: string, option: any) => {
        setSelectedCarrierOption({
            label: option.label,
            value,
        });
    };

    const handleOnEditCourierMethodChange = (value: string, option: any) => {
        setEditCarrierOption({
            label: option.label,
            value,
        });
    };

    /**
     * Looks for shipment information for the current order from live orders data
     * and orders list
     *
     * @returns the list of shipment tracking records for the current order
     */
    const getOrderTrackingInfo = () => {
        const orderTrackingInfoInLiveOrder = props.liveOrders?.find((order) => {
            return order.ord_id === props.orderId;
        })?.shipment;
        const orderTrackingInfoInOrderHistory = props.orderList?.find((order) => {
            return order.ord_id === props.orderId;
        })?.shipment;

        return orderTrackingInfoInLiveOrder || orderTrackingInfoInOrderHistory || [];
    };

    /**
     * Checks whether the carrier is a duplicate
     *
     * @param otherCarrier - carrier to compare with existing carriers
     * @returns whether the carrier is a duplicate
     */
    const isDuplicateOrderTrackingInfo = (otherCarrier: OrderTrackingInfo) => {
        return (
            props.orderCarriers.find(
                (carrier) =>
                    carrier.carrier_code === otherCarrier.carrier_code &&
                    carrier.track_number === otherCarrier.track_number
            ) !== undefined
        );
    };

    /**
     * Add order tracking info
     */
    const addOrderTrackingInfo = () => {
        const carrier: OrderTrackingInfo = {
            carrier_code: selectedCarrierOption?.value ?? "",
            track_number: trackNumber,
            title: selectedCarrierOption?.label ?? "",
        };
        if (isDuplicateOrderTrackingInfo(carrier)) {
            props.setState({ errorMessage: "duplicate_order_tracking_message" });
            return;
        }

        const body: AddOrderTrackingRequestBody = {
            data: {
                order_id: props.orderId,
                carrier_code: selectedCarrierOption?.value ?? "",
                track_number: trackNumber,
                notify_customer: notifyCustomer,
            },
        };

        props.addOrderTrackingInfo(body);

        setSelectedCarrierOption(undefined);
        setTrackNumber("");
        setNotifyCustomer(false);
    };

    /**
     * Delete order tracking record
     * If the order is already completed, changes to order tracking are sent directly to the api,
     * otherwise, prepare order tracking info locally
     *
     * @param carrierToRemove - the carrier to remove
     * @param index - the index of the carrier to remove
     */
    const deleteOrderTrackingInfo = (carrierToRemove: OrderTrackingInfo, index: number) => {
        if (carrierToRemove._id) {
            const params: DeleteOrderTrackingParams = {
                track_id: carrierToRemove._id,
            };

            props.deleteOrderTrackingInfo(params);
        } else {
            const carriersClone = _.cloneDeep(props.orderCarriers);
            carriersClone.splice(index, 1);

            props.setState({ orderCarriers: carriersClone });
        }
    };

    const isOrderCompleted = () => {
        return props.orderStatus === config.ORDER_STATUS_MAPPING_TO_NUMERIC.completed;
    };

    const renderActions = (carrier: OrderTrackingInfo, index: number) => {
        return (
            <Menu>
                <Menu.Item onClick={() => handleOnEditClick(carrier, index)}>
                    <FormattedMessage id="edit" />
                </Menu.Item>
                <Menu.Item
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: intl.formatMessage({ id: "warning" }),
                            content: intl.formatMessage({ id: "delete_order_tracking_confirmation" }),
                            okText: intl.formatMessage({ id: "delete" }),
                            okType: "danger",
                            cancelText: intl.formatMessage({ id: "cancel" }),
                            onOk() {
                                deleteOrderTrackingInfo(carrier, index);
                            },
                        });
                    }}
                >
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );
    };

    const renderActionsDropDown = (carrier: OrderTrackingInfo, index: number) => {
        let actions = null;

        if (index === editIndex) {
            actions = (
                <div className="actions-container">
                    <IconContext.Provider value={{ size: "1.5em" }}>
                        <TiCancel className="cancel-icon" onClick={exitEditMode} />
                    </IconContext.Provider>
                    <span className="h-spacing-8" />
                    <IconContext.Provider value={{ size: "1.5em" }}>
                        <BiCheck className="confirm-icon" onClick={() => handleOnFinishEdit(index)} />
                    </IconContext.Provider>
                </div>
            );
        } else if (!editMode) {
            actions = (
                <div className="actions-container">
                    <Dropdown overlay={renderActions(carrier, index)}>
                        <Button type="link">
                            <HiDotsHorizontal />
                        </Button>
                    </Dropdown>
                </div>
            );
        }

        return actions;
    };

    /**
     * Set the initial edit values to the carrier to be edited,
     * set edit mode to true and set the index of the carrier to be edited
     *
     * @param carrier - the carrier to edit
     * @param index - the index of the carrier to edit
     */
    const handleOnEditClick = (carrier: OrderTrackingInfo, index: number) => {
        setEditCarrierOption({
            label: carrier.title,
            value: carrier.carrier_code,
        });
        setEditTrackNumber(carrier.track_number);
        setEditMode(true);
        setEditIndex(index);
    };

    const exitEditMode = () => {
        setEditMode(false);
        setEditIndex(DEFAULT_EDIT_INDEX_VALUE);
    };

    /**
     * Update the order tracking record and exit out of edit mode
     *
     * @param index - the index of the order tracking record to update
     */
    const handleOnFinishEdit = (index: number) => {
        if (index < 0 || index >= props.orderCarriers.length) {
            return;
        }

        const carriersClone = _.cloneDeep(props.orderCarriers);
        const carrierToEdit = carriersClone[index];

        const updatedCarrier: UpdateOrderTrackingRequestBody = {
            data: {
                _id: carrierToEdit._id,
                carrier_code: editCarrierOption?.value,
                title: editCarrierOption?.label,
                track_number: editTrackNumber,
            },
        };

        props.updateOrderTrackingInfo(updatedCarrier);

        exitEditMode();
    };

    const renderShipmentTrackingHeader = () => {
        return (
            <Row gutter={ROW_GUTTER} className="shipment-tracking-col-headers">
                <Col span={CODE_COL_SPAN}>
                    <FormattedMessage id="carrier" />
                </Col>
                <Col span={TRACK_NUMBER_COL_SPAN}>
                    <FormattedMessage id="number" />
                </Col>
                <Col span={ACTIONS_COL_SPAN}>
                    <div className="actions-container">
                        <FormattedMessage id="actions" />
                    </div>
                </Col>
            </Row>
        );
    };

    const renderCarrierSelect = (value: string | undefined, onChange: (value: string, option?: any) => void) => {
        return (
            <Select
                className="courier-select"
                placeholder={<FormattedMessage id="select_carrier" />}
                options={courierMethodsSelectOptions}
                value={value}
                onChange={(value, option) => onChange(value, option)}
            />
        );
    };

    const renderTrackingNumberInput = (value: string | undefined, onChange: (value: string) => void) => {
        return (
            <Input
                placeholder={intl.formatMessage({ id: "track_number" })}
                onChange={(event) => onChange(event.target.value)}
                value={value}
            />
        );
    };

    /**
     * If the user is editing this record, render a carrier select menu
     * otherwise render the carrier title
     *
     * @param index - index of the carrier
     * @param carrierTitle - carrier title
     * @returns carrier title or carrier select options
     */
    const renderCarrierTitle = (index: number, carrierTitle: string) => {
        return index === editIndex
            ? renderCarrierSelect(editCarrierOption?.value, handleOnEditCourierMethodChange)
            : carrierTitle;
    };

    /**
     * If the user is editing this record, render an input for the tracking number
     * otherwise render the tracking number
     *
     * @param index - index of the carrier
     * @param trackingNumber - tracking number
     * @param url - link to the carrier site
     * @returns the tracking number or an input for the tracking number
     */
    const renderTrackingNumber = (index: number, trackingNumber: string, url?: string) => {
        return index === editIndex ? (
            renderTrackingNumberInput(editTrackNumber, setEditTrackNumber)
        ) : (
            <a className={url ? "tracking-number-link" : ""} href={url} target="_blank" rel="noreferrer">
                {trackingNumber}
                <span className="h-spacing-4" />
                <HiExternalLink />
            </a>
        );
    };

    const renderOrderCarriers = () => {
        return (
            <div className="shipment-tracking-details-container">
                {props.orderCarriers.map((carrier, index) => {
                    return (
                        <Row
                            gutter={ROW_GUTTER}
                            key={`${carrier.carrier_code}-${carrier.track_number}-${index}`}
                            className="shipment-tracking-details-row"
                        >
                            <Col span={CODE_COL_SPAN}>{renderCarrierTitle(index, carrier.title)}</Col>
                            <Col span={TRACK_NUMBER_COL_SPAN}>
                                {renderTrackingNumber(index, carrier.track_number, carrier.url)}
                            </Col>
                            <Col span={ACTIONS_COL_SPAN}>{renderActionsDropDown(carrier, index)}</Col>
                        </Row>
                    );
                })}
            </div>
        );
    };

    const renderAddOrderTrackingInfoButton = (
        onClick: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void,
        useDisable?: boolean
    ) => {
        return (
            <div className="actions-container">
                <Button
                    disabled={useDisable && (!selectedCarrierOption || trackNumber.length < TRACK_NUMBER_MIN_LEN)}
                    type="primary"
                    onClick={onClick}
                >
                    <FormattedMessage id="add" />
                </Button>
            </div>
        );
    };

    const renderAddOrderCarrierSection = () => {
        return (
            <div className="add-shipment-tracking-container">
                <Row gutter={ROW_GUTTER}>
                    <Col span={CODE_COL_SPAN} />
                    <Col span={TRACK_NUMBER_COL_SPAN} />
                    <Col span={ACTIONS_COL_SPAN}>
                        {renderAddOrderTrackingInfoButton(() => setShowAddShipmentTrackingModal(true))}
                    </Col>
                </Row>
            </div>
        );
    };

    const renderPanelHeader = () => {
        return (
            <>
                <FormattedMessage id="shipment_tracking_info" />
                {_.isEmpty(props.orderCarriers) ? null : ` · ${props.orderCarriers.length}`}
            </>
        );
    };

    const renderSendCustomerTrackingInformation = () => {
        return (
            <>
                <Checkbox
                    checked={notifyCustomer}
                    onChange={(e) => {
                        setNotifyCustomer(e.target.checked);
                    }}
                />
                <span className="h-spacing-8" />
                <span className="send-customer-email-text">
                    <FormattedMessage id="notify_customer" />
                </span>
            </>
        );
    };

    const renderAddShipmentTrackingModalContent = () => {
        return (
            <div className="add-shipment-tracking-modal-content-container">
                <Row gutter={ROW_GUTTER}>
                    <Col span={HALF_COL_SPAN}>
                        <div className="shipment-tracking-header">
                            <FormattedMessage id="carrier" />
                        </div>
                        <div className="spacing-8" />
                        {renderCarrierSelect(selectedCarrierOption?.value, handleOnCourierMethodChange)}
                    </Col>
                    <Col span={HALF_COL_SPAN}>
                        <div className="shipment-tracking-header">
                            <FormattedMessage id="track_number" />
                        </div>
                        <div className="spacing-8" />
                        {renderTrackingNumberInput(trackNumber, setTrackNumber)}
                    </Col>
                </Row>
                <div className="spacing-16" />
                {renderSendCustomerTrackingInformation()}
            </div>
        );
    };

    const renderDuplicateOrderTrackingModal = () => {
        return (
            <Modal
                title={<FormattedMessage id="error" />}
                visible={showErrorModal}
                footer={[
                    <Button type="primary" key="back" onClick={() => setShowErrorModal(false)}>
                        <FormattedMessage id="ok" />
                    </Button>,
                ]}
                onCancel={() => setShowErrorModal(false)}
            >
                {renderAddShipmentTrackingModalContent()}
            </Modal>
        );
    };

    const renderAddShipmentTrackingModal = () => {
        return (
            <Modal
                title={
                    <span className="shipment-tracking-modal-title">
                        <FormattedMessage id="add_tracking" />
                    </span>
                }
                visible={showAddShipmentTrackingModal}
                onCancel={() => setShowAddShipmentTrackingModal(false)}
                footer={[
                    renderAddOrderTrackingInfoButton(() => {
                        setShowAddShipmentTrackingModal(false);
                        addOrderTrackingInfo();
                    }, true),
                ]}
            >
                {renderAddShipmentTrackingModalContent()}
            </Modal>
        );
    };

    return (
        <div className="shipment-tracking-container">
            <Collapse defaultActiveKey="shipment-tracking-info" expandIconPosition="right">
                <Panel className="shipment-tracking-header" header={renderPanelHeader()} key="shipment-tracking-info">
                    <Spin spinning={props.isLoading}>
                        <div className="shipment-tracking-table-container">
                            {renderShipmentTrackingHeader()}
                            {renderOrderCarriers()}
                        </div>
                        {editMode ? null : renderAddOrderCarrierSection()}
                    </Spin>
                </Panel>
            </Collapse>
            {renderDuplicateOrderTrackingModal()}
            {renderAddShipmentTrackingModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const shipmentTrackingInfoStateProps: ShipmentTrackingStateProps = {
        courierMethods: _.get(state, "shipmentTracking.courierMethods", []),
        orderCarriers: _.get(state, "shipmentTracking.orderCarriers", []),
        isLoading: _.get(state, "shipmentTracking.isLoading", false),
        errorMessage: _.get(state, "shipmentTracking.errorMessage", ""),
        liveOrders: _.get(state, "liveOrders.liveOrderList", []),
        orderList: _.get(state, "orders-page.orderList", []),
    };
    return shipmentTrackingInfoStateProps;
};

const mapDispatchToProps = {
    setState,
    getCourierMethods,
    getOrderTrackingInfo,
    addOrderTrackingInfo,
    deleteOrderTrackingInfo,
    updateOrderTrackingInfo,
    setLiveOrderState: liveOrderActions.setState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ShipmentTracking);
