import React from "react";
import _ from "lodash";
import moment from "moment";
import dH from "../helper";
import { RootState } from "../../../../../../app/reducer";
import { SelectOption } from "../../../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCoupons } from "../../../../../../services/discounts";
import { setFilter, setFilters, clearFilters } from "../../../../../../slices/discounts";
import "../../discounts.scss";

function FilterModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();

    const discounts = useSelector((state: RootState) => state.discounts);
    const discType = _.get(discounts, "filters.discountType", undefined);
    const priceFrom = _.get(discounts, "filters.price_from", undefined);
    const priceTo = _.get(discounts, "filters.price_to", undefined);
    const filters = _.get(discounts, "filters", {});

    const discountTypes: SelectOption[] = [
        { value: "", label: "" },
        { value: "by_percent", label: intl.formatMessage({ id: "by_percent" }) },
        { value: "by_fixed", label: intl.formatMessage({ id: "by_fixed" }) },
        { value: "cart_fixed", label: intl.formatMessage({ id: "cart_fixed" }) },
    ];

    const clearFilter = () => {
        dispatch(clearFilters());
        dispatch(setFilter({ value: false }));
    };

    const filterCodes = () => {
        const { price_from, price_to } = filters;
        dispatch(setFilter({ value: false }));
        dispatch(getCoupons({ price_from, price_to, product_type: dH.COUPON }));
    };

    const renderNameAndDiscount = (
        <React.Fragment>
            <div className="filter-subtitle top">
                <FormattedMessage id="discount_type" />
            </div>
            <Select
                className="filter-input d-flex align-items-center"
                value={discType}
                onChange={(e) => dispatch(setFilters({ name: "discountType", value: e }))}
            >
                {discountTypes.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </React.Fragment>
    );

    const renderDiscountRange = (
        <React.Fragment>
            <div className="filter-subtitle">
                <FormattedMessage id="discount_range" />
            </div>
            <div className="d-flex filter-discount-range">
                <input
                    className="custom-input-textfield filter-range"
                    type="number"
                    value={priceFrom ? priceFrom : NaN}
                    placeholder={intl.formatMessage({ id: "from" })}
                    onChange={(e) => dispatch(setFilters({ name: "price_from", value: parseInt(e.target.value) }))}
                />
                <input
                    className="custom-input-textfield filter-range"
                    type="number"
                    value={priceTo ? priceTo : NaN}
                    placeholder={intl.formatMessage({ id: "to" })}
                    onChange={(e) => dispatch(setFilters({ name: "price_to", value: parseInt(e.target.value) }))}
                />
            </div>
        </React.Fragment>
    );

    const renderDateRange = (
        <div className="d-flex filter-date-range">
            <div className="d-flex flex-column mr-4 filter-range-column">
                <div className="filter-subtitle">
                    <FormattedMessage id="date_start" />
                </div>
                <DatePicker
                    className="datepicker custom-input-textfield"
                    onChange={(e: any) =>
                        dispatch(setFilters({ name: "date_start", value: moment(e).format("YYYY-MM-DD") }))
                    }
                />
            </div>
            <div className="d-flex flex-column filter-range-column">
                <div className="filter-subtitle">
                    <FormattedMessage id="date_expire" />
                </div>
                <DatePicker
                    className="filter-range custom-input-textfield"
                    onChange={(e: any) =>
                        dispatch(setFilters({ name: "date_expire", value: moment(e).format("YYYY-MM-DD") }))
                    }
                />
            </div>
        </div>
    );

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => clearFilter()}>
                <FormattedMessage id="reset" />
            </Button>
            <Button type="primary" onClick={() => filterCodes()}>
                <FormattedMessage id="apply_filters" />
            </Button>
        </div>
    );

    const renderFilterForm = () => (
        <div className="filter-modal d-flex flex-column justify-content-start align-items-start">
            {renderNameAndDiscount}
            {renderDiscountRange}
            {renderDateRange}
            {renderActions}
        </div>
    );

    return renderFilterForm();
}

export default FilterModal;
