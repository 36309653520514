import { Button, Input } from "antd";
import React, { useState } from "react";
import { BiSliderAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setReferralsState } from "../../../../slices/referrals";
import ReferralFilterDrawer from "./filter-drawer";

const selector = (state: RootState) => ({
    transactions: state?.referrals?.customerReferrals,
    paging: state?.referrals?.customerReferrals,
    searchConditions: state?.referrals?.customerReferralsSearchConditions,
    referrals: state?.referrals?.referrals,
});

function ReferralModalFilterBar(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { searchConditions } = useSelector(selector);
    const [filterDrawer, setFilterDrawer] = useState(false);

    const onSearch = (value: any) => {
        dispatch(
            setReferralsState({
                customerReferralsSearchConditions: {
                    ...searchConditions,
                    c_name: value?.target?.value ?? "",
                },
            })
        );
    };

    const renderSearch = (
        <div className="d-flex">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    <Input.Search
                        className="codes-search-input"
                        placeholder={intl.formatMessage({ id: "search_dot" })}
                        allowClear
                        value={searchConditions.c_name}
                        onChange={onSearch}
                        size="large"
                    />
                    <Button
                        className="filter-button"
                        icon={<BiSliderAlt size={20} />}
                        onClick={() => setFilterDrawer(true)}
                    >
                        <div className="icon-button-text d-inline">
                            <FormattedMessage id="filters" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="codes-search-bar d-flex ">
            {renderSearch}
            <ReferralFilterDrawer filter={filterDrawer} setFilter={setFilterDrawer} />
        </div>
    );
}

export default ReferralModalFilterBar;
