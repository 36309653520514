import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { getIntl } from "../locale";
import qs from "querystring";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import reviewHelper from "../pages/reviews/helper";
import {
    getAllReviewsSuccess,
    getOrderReviewsSuccess,
    getProductReviewsSuccess,
    getStoreReviewsSuccess,
    setProductState,
} from "../slices/reviews";

export const getReviews =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setProductState({ loading: true }));
        try {
            const response: any = await oauth(
                "STORE_REVIEWS",
                qs.stringify(params)
            )({
                method: "GET",
            });
            switch (response?.RC) {
                case 200:
                    params?.scope === reviewHelper.ORDER_SCOPE
                        ? dispatch(getOrderReviewsSuccess(response))
                        : params?.scope === reviewHelper.PRODUCT_SCOPE
                        ? dispatch(getProductReviewsSuccess(response))
                        : params?.scope === reviewHelper.STORE_SCOPE
                        ? dispatch(getStoreReviewsSuccess(response))
                        : dispatch(getAllReviewsSuccess(response));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setProductState({ loading: false }));
    };

export const getReviewDetails = (params?: Record<string, any> | any) => async (): Promise<any> => {
    try {
        const response: any = await oauth(
            "ORDER_REVIEWS",
            undefined,
            params.order
        )({
            method: "GET",
        });
        switch (response?.RC) {
            case 200:
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const reviewReply =
    (params?: Record<string, any>, notification = false) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("REPLY_REVIEWS")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params?.review },
                }),
            });
            const intl = getIntl();
            switch (response?.RC) {
                case 200:
                    dispatch(getReviews({ scope: params?.scope }));
                    if (params?.approved) {
                        if (!notification)
                            toast(
                                createMessage(
                                    intl.formatMessage({ id: "review_approval_updated" }),
                                    HiInformationCircle
                                )
                            );
                    } else {
                        if (!notification)
                            toast(createMessage(intl.formatMessage({ id: "reply_sent" }), HiInformationCircle));
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
