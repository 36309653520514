import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "antd";
import config from "../../../../config";
import "./_order-payment-dialog.scss";
import { getIntl } from "../../../../locale";
import { allowPayment } from "../../../liveorder/helper";
import helper from "../../../../util/helper";
import { getOrderId } from "../../helper-order-list";

//payments available changed to
const paymentsMap = config.PAYMENT_MAPPING_TO_NUMBERIC;
export const AVAILABLE_CHANGED_DEFAULT_PAYMENTS = [paymentsMap.cash, paymentsMap.pay_later];
export const AVAILABLE_CHANGED_STORE_PAYMENTS = [
    paymentsMap.wechat_pay,
    paymentsMap.alipay,
    paymentsMap.union_pay,
    paymentsMap.union_pay_express,
    paymentsMap.etransfer,
];

const BUTTON_NUM_ROW = 2;

export default class OrderPaymentDialog extends Component {
    // inspired by https://github.com/GA-MO/react-confirm-alert
    static defaultProps = {
        title: " ",
        message: "",
        allowRequestClose: true,
        buttons: [
            {
                label: "Close",
                type: "default",
                onClick: () => {},
            },
        ],
    };

    constructor(props) {
        super(props);

        this.state = {
            display: false,
        };
    }

    str = (id) => getIntl().formatMessage({ id });

    getOrder = () => this.props.order ?? {};

    getPaymentButtons = () => {
        let paymentButtons = [];
        const store = this.props.orderStore;
        const availablePayments = AVAILABLE_CHANGED_DEFAULT_PAYMENTS.concat(
            AVAILABLE_CHANGED_STORE_PAYMENTS.filter((payment) => allowPayment(store, payment, this.getOrder()))
        );
        availablePayments.forEach((button) => {
            const str = helper.getKeyByValue(paymentsMap, button) ? helper.getKeyByValue(paymentsMap, button) : " ";
            paymentButtons.push({
                message: this.str(str),
                onClickValue: button,
            });
        });
        return paymentButtons;
    };

    handlePaymentClick = (value, message) => {
        const { updateOrderPayment } = this.props;
        const handleCancel = () => this.handleCancel();
        const data = {};
        data["payment_method"] = value;
        data["order_id"] = getOrderId(this.getOrder());
        data["action"] = 5;
        Modal.confirm({
            title: this.str("warning"),
            content: this.str("change_payment_to") + '"' + message + '"',
            onOk() {
                updateOrderPayment(data);
                handleCancel();
            },
            okText: this.str("ok"),
            cancelText: this.str("cancel"),
        });
    };

    handleCancel = () => {
        destroyOrderPaymentDialog();
    };

    renderPaymentButtons = () => {
        const paymentButtons = this.getPaymentButtons();

        const button = (paymentButton, i) => (
            <Button
                key={i}
                style={{ width: `${100 / BUTTON_NUM_ROW - 5}%` }}
                size="large"
                className="payment-button"
                onClick={() => this.handlePaymentClick(paymentButton.onClickValue, paymentButton.message)}
            >
                {paymentButton.message}
            </Button>
        );

        return paymentButtons.map((paymentButton, i) => button(paymentButton, i));
    };

    renderFooter = () => {
        return (
            <Button
                onClick={() => {
                    this.handleCancel();
                }}
            >
                {this.str("cancel")}
            </Button>
        );
    };

    render() {
        return (
            <div>
                <Modal
                    visible={true}
                    title={this.str("edit_payment_method")}
                    onCancel={this.handleCancel}
                    footer={this.renderFooter()}
                >
                    <div className="order-payment-dialog">{this.renderPaymentButtons()}</div>
                </Modal>
            </div>
        );
    }
}

function destroyOrderPaymentDialog() {
    const target = document.getElementById("order-payment-dialog-container");
    ReactDOM.unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
}

function createOrderPaymentDialog(props) {
    const divTarget = document.createElement("div");
    divTarget.id = "order-payment-dialog-container";
    document.body.appendChild(divTarget);

    ReactDOM.render(<OrderPaymentDialog {...props} />, divTarget);
}

export function orderPaymentDialog(props) {
    createOrderPaymentDialog(props);
}
