import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Button, Drawer } from "antd";
import DrawerCollapse from "./filter-drawer-collapse";
import { getDefaultFilters } from "../../../helper-order-list";
import actions from "../../../../../slices/order-history";

class FilterDrawer extends Component {
    state = { drawerState: {} };

    getCurrentSearchFilters = () => _.get(this.props, "liveOrderState.searchConditions.search_condition");

    getDrawerDefaultFilters = () => ({
        ...getDefaultFilters(),
        ...this.getCurrentSearchFilters(),
    });

    getState = () => ({
        ...this.getDrawerDefaultFilters(),
        ...this.state.drawerState,
    });

    showDrawer = () => _.get(this.props, "liveOrderState.showFilterDrawer", false);

    onCloseClick = () => {
        this.setState({ drawerState: {} });
        this.props.setOrderState({
            showFilterDrawer: false,
        });
    };

    renderCollapse = () => {
        return (
            <div className="order-list-fitler-drawer-collaspe">
                <DrawerCollapse
                    drawerState={this.getState()}
                    setDrawerState={(values) => {
                        let updateObject = {
                            drawerState: {
                                ...this.state.drawerState,
                                ...values,
                            },
                        };
                        this.setState(updateObject);
                    }}
                />
            </div>
        );
    };

    renderButtons = () => {
        const buttonShareProps = {
            style: { width: 150 },
            size: "large",
        };

        const onClearClick = () => {
            this.props.fetchOrderList({
                ...getDefaultFilters(),
                clear: true,
            });
            this.onCloseClick();
        };

        const onApplyClick = () => {
            this.props.fetchOrderList({
                ...this.getDrawerDefaultFilters(),
                ...this.state.drawerState,
                paging: { page: 1 },
            });
            this.onCloseClick();
        };

        return (
            <div className="order-list-fitler-drawer-controller">
                <Button onClick={() => onClearClick()} {...buttonShareProps} type="default">
                    <b>
                        {" "}
                        <FormattedMessage id="reset" />
                    </b>
                </Button>
                <Button onClick={() => onApplyClick()} {...buttonShareProps} type="primary">
                    <b>
                        {" "}
                        <FormattedMessage id="apply" />
                    </b>
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Drawer
                title={<FormattedMessage id="order_filter" />}
                width={550}
                bodyStyle={{ padding: 0 }}
                onClose={() => this.onCloseClick()}
                visible={this.showDrawer()}
                destroyOnClose={true}
            >
                <div className="order-list-filter-drawer">
                    {this.renderCollapse()}
                    {this.renderButtons()}
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    liveOrderState: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    setOrderState: actions.setState,
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterDrawer));
