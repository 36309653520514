import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { setState as createOrderSetState } from "slices/create-order";
import _ from "lodash";
import { Button, Tooltip } from "antd";
import helper, { formatCurrency, getGSaleURL } from "util/helper";
//@ts-ignore
import InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "@material-ui/core/CircularProgress";
import actions from "slices/live-order";
import { CATEGORIES_ID, CATEGORY_BUTTON_ID } from "./categories";
import cx from "classnames";
import { ITEM_CHANGE_ACTION_POS_MODE } from "pages/liveorder/_components/order-items/helper";
import { getDefaultOpts } from "pages/create-order/helper";
import { Badge, withStyles } from "@material-ui/core";
import config from "config";
import { HiExternalLink } from "react-icons/hi";
import { IconContext } from "react-icons/lib";
import { IoIosCheckmarkCircle } from "react-icons/io";

const PRODUCT_ROW_NUMBER = 6;

const StyledBadge = withStyles(() => ({
    badge: {
        right: 10,
        top: 3,
        backgroundColor: "#ed2d29",
        border: `2px solid white`,
        padding: "8px 2px",
        color: "white",
    },
}))(Badge);

class ProductsSection extends Component<any, any> {
    categories: any[] = [];
    state = {
        isProductLoaded: false,
        reachedBottom: true,
    };

    componentDidMount = () => {
        this.initCategoryLoad();
    };

    componentDidUpdate = (prevsProps: any) => {
        this.checkCategoryIsLoaded(prevsProps);
        this.checkReloadProducts(prevsProps);
    };

    componentWillUnmount = () => {
        this.props.liveOrderSetState({
            products: [],
            lastSearch: undefined,
        });
    };

    initCategoryLoad = () => {
        if (!_.isEmpty(this.props.categories)) {
            this.categories = _.cloneDeep(this.props.categories);
        }
    };

    checkCategoryIsLoaded = (prevsProps: any) => {
        if (_.isEmpty(prevsProps.categories) && !_.isEmpty(this.props.categories)) {
            this.setState({ isProductLoaded: true });
            this.categories = _.cloneDeep(this.props.categories);
        }
    };

    getProductQty = (product: any) => {
        const items = Array.isArray(this.props.items) ? this.props.items : [];
        const allItems = items.filter((item) => String(item?.pid) === String(product?.pid));
        return allItems.reduce((acc, item) => {
            acc += item?.qty || 0;
            return acc;
        }, 0);
    };

    //to generate product button row based on the number
    getProductsRow = () => {
        const rows: any = [];
        let temp: any = [];
        const products = this.props.products;
        products.forEach((product: any, i: any) => {
            temp.push(product);
            if (i % PRODUCT_ROW_NUMBER === PRODUCT_ROW_NUMBER - 1 || i === products.length - 1) {
                rows.push(temp);
                temp = [];
            }
        });
        return rows;
    };

    /**
     * Check that the products list should be reloaded in POS modal.
     * The product list is reloaded below cases.
     * 1. selected menu is changed
     * 2. search input becomes an empty string
     * 3. reload button is manually clicked
     *
     * @param prevsProps - previous props
     */
    checkReloadProducts = (prevsProps: any) => {
        const isMenuUpdated = !_.isEqual(prevsProps.menuId, this.props.menuId);
        const isEmptyKeyword =
            !_.isEmpty(prevsProps.quickAddModalSearchInput) && _.isEmpty(this.props.quickAddModalSearchInput);
        const shouldReloadProducts = this.props.shouldReloadProducts;
        if ((isMenuUpdated || isEmptyKeyword || shouldReloadProducts) && !this.props.isLoadingProducts) {
            this.reloadProducts(isEmptyKeyword);
        }
    };

    /**
     * Reload Products in POS modal
     * it resets the reached Bottom state, current categories and products states
     *
     * @param isEmptyKeyword - the current search keyword is empty or not
     */
    reloadProducts = (isEmptyKeyword: boolean) => {
        this.setState({ reachedBottom: true });
        this.props.liveOrderSetState({
            products: [],
            lastSearch: isEmptyKeyword
                ? _.omit({ ...this.props.searchCondition, reset: false }, "keywords")
                : { ...this.props.searchCondition, reset: false },
        });
        this.props.createOrderSetState({ shouldReloadProducts: false });
        this.categories = _.cloneDeep(this.props.categories);
    };

    hasMore = () => {
        return !this.props.isLoadingProducts && this.categories.length > 0 && !this.props.searchCondition?.reset;
    };

    onProductScroll = () => {
        //find current cat id
        const topPoint =
            document.getElementById("quick-add-modal-categoreis-product-divider")?.getBoundingClientRect()?.top || 0;
        let min = 0;
        let currentCatId = "";
        let currentOffSet: number | undefined = 0;
        this.getProductsRow().forEach((row: any, i: any) => {
            const el = document.getElementById(`products-row-${i}`)?.getBoundingClientRect();
            const minEl = document.getElementById(`products-row-${min}`)?.getBoundingClientRect();

            const elTop = el?.top || 0;
            const minElTop = minEl?.top || 0;
            const gap = Math.abs(topPoint - elTop);
            const minGap = Math.abs(topPoint - minElTop);
            const closestToTop = gap < minGap;
            if (!currentCatId || closestToTop) {
                //find the first available cat
                const productsCats = Array.isArray(_.get(row, "0.ctids")) ? _.get(row, "0.ctids") : [];
                const categories = Array.isArray(this.props.categories) ? this.props.categories : [];
                const currentCat = categories.find((cat) => productsCats.includes(cat?.id));
                currentCatId = currentCat?.id;
                min = i;

                const categoryEle = document.getElementById(`${CATEGORY_BUTTON_ID}${currentCatId}`);
                currentOffSet = categoryEle?.offsetLeft;
            }
        });
        const catList = document.getElementById(CATEGORIES_ID);
        if (catList) catList.scrollTo({ left: currentOffSet, behavior: "smooth" });
        this.props.setParentState({ catId: currentCatId });
        this.checkReachedBottom();
    };

    /**
     * Check the current POS modal scroll is reached to bottom
     */
    checkReachedBottom = () => {
        const LOAD_PRODUCT_SCROLL_PRELOAD_THRESHOLD = 0.7;
        const element: any = document.getElementById("products-container");

        if ((element.scrollHeight - element.scrollTop) * LOAD_PRODUCT_SCROLL_PRELOAD_THRESHOLD < element.clientHeight) {
            if (!this.state.reachedBottom) {
                this.setState({ reachedBottom: true });
            }
        } else {
            if (this.state.reachedBottom) {
                this.setState({ reachedBottom: false });
            }
        }
    };

    loadMore = () => {
        if (this.props.isLoadingProducts || !this.state.reachedBottom) {
            return;
        }
        const conditions = _.cloneDeep(this.props.searchCondition);
        const currentCategory = this.categories.shift();

        conditions.gid = this.props.store?.g_id;
        conditions.cids = currentCategory?.id;
        conditions.page = 0;
        conditions.mid = this.props.menuId;
        conditions.reset = false;
        conditions.lan = this.props.lan;
        conditions.cachedProducts = this.props.cachedProducts;
        this.props.getProducts(conditions);
        this.checkReachedBottom();
    };

    addProduct = (product: any) => {
        const items = Array.isArray(this.props.items) ? _.clone(this.props.items) : [];
        const index = items.findIndex((item) => String(item?.pid) === String(product?.pid));
        if (index === -1) {
            items.push({
                pid: product?.pid,
                ctids: product?.ctids,
                qty: 1,
                opts: [],
                nm: product?.nm,
                sku: product?.sku,
                sq: product?.sq,
                pcIn: helper.getPrice(product), //internal price for calculation only
            });
        } else {
            const originalItem = _.clone(items[index]);
            items[index] = {
                ...originalItem,
                qty: ++originalItem.qty,
            };
        }
        this.props.setParentState({ items });
    };

    deleteProduct = (product: any) => {
        let items = Array.isArray(this.props.items) ? _.clone(this.props.items) : [];
        const index = items.findIndex((item) => String(item?.pid) === String(product?.pid));
        if (index !== -1) {
            const originalItem = _.clone(items[index]);
            items[index] = {
                ...originalItem,
                qty: originalItem.qty > 0 ? --originalItem.qty : originalItem.qty,
            };
            items = items.filter((item) => item.qty > 0);
            this.props.setParentState({ items });
        }
    };

    hasToolTip = (productID: number) => {
        const BUTTON_OFFSET = 2;
        const button = document.getElementById(`product-button-name-${productID}`);
        const span = document.getElementById(`product-name-${productID}`);

        const buttonHeight = button?.offsetHeight || 0;
        const spanHeight = span?.offsetHeight || 0;

        return buttonHeight + BUTTON_OFFSET < spanHeight;
    };

    handleOnClickGroupPurchaseProduct = (productId: number) => {
        if (this.props.gpId === productId) {
            this.props.createOrderSetState({ gp_id: null });
        } else {
            this.props.createOrderSetState({ gp_id: productId });
        }
    };

    renderCheckMark = () => {
        return (
            <div className="check-mark-icon-container">
                <IconContext.Provider value={{ size: "24px" }}>
                    <IoIosCheckmarkCircle className="check-mark-icon" />
                </IconContext.Provider>
            </div>
        );
    };

    renderProductPrice = (product: any) => {
        const hasSpecialPrice = helper.hasSpecialPrice(product);

        return (
            <span>
                <span className={cx({ "product-original-price-line-through": hasSpecialPrice })}>
                    {formatCurrency(product?.pc, this.props.storeCurrency)}
                </span>
                {hasSpecialPrice ? ` · ${formatCurrency(product?.spc, this.props.storeCurrency)}` : null}
            </span>
        );
    };

    renderProductButton = (pro: any) => {
        return (
            <StyledBadge
                key={`products-button-${pro.pid}`}
                style={{
                    width: `${100 / PRODUCT_ROW_NUMBER}%`,
                }}
                badgeContent={this.getProductQty(pro)}
            >
                <div className="product-button" id={`products-button-${pro.pid}`}>
                    {this.renderProduct(pro)}
                </div>
            </StyledBadge>
        );
    };

    renderProductRow = (row: any, i: any) => {
        const TOOLTIP_MOUSE_ENTER_DELAY_SEC = 0.5;
        return (
            <div key={`products-row-${i}`} id={`products-row-${i}`} className="products-row">
                {row?.map((pro: any) => {
                    if (this.hasToolTip(pro.pid)) {
                        return (
                            <Tooltip
                                key={pro.pid}
                                placement="top"
                                mouseEnterDelay={TOOLTIP_MOUSE_ENTER_DELAY_SEC}
                                title={pro.nm}
                            >
                                {this.renderProductButton(pro)}
                            </Tooltip>
                        );
                    } else {
                        return this.renderProductButton(pro);
                    }
                })}
            </div>
        );
    };

    renderProduct = (product: any) => {
        const leftButtonId = `product-button-controller-ui-left-${product.pid}`;
        const rightButtonId = `product-button-controller-ui-right-${product.pid}`;
        const isGroupPurchase = _.get(product, "pt") === config.PRODUCT_TYPE_TO_NUMERIC.group_purchase;
        const withOpt = !_.isEmpty(_.get(product, "opt"));
        const disabled = _.get(product, "sq") <= 0;

        const renderProductInfo = (showPrice?: boolean) => {
            return (
                <>
                    <div className={`product-button-name-wrapper`}>
                        <div
                            id={`product-button-name-${product.pid}`}
                            className={cx({
                                "product-button-name": true,
                                "product-button-name-disabled": disabled,
                            })}
                        >
                            <span id={`product-name-${product.pid}`}>{product.nm}</span>
                        </div>
                    </div>
                    <div
                        className={cx({
                            "product-button-price": true,
                            "product-button-price-disabled": disabled,
                        })}
                    >
                        {showPrice && this.renderProductPrice(product)}
                    </div>
                </>
            );
        };

        //controller to trigger on click
        const renderProductRealController = (
            <div
                onClick={() => this.props.setParentState({ product, opts: [], qty: 1 })}
                className={cx({
                    "product-button-controller": true,
                    "product-button-real-controller": true,
                    "product-button-controller-disabled": disabled,
                })}
            >
                <div
                    onClick={() => {
                        this.deleteProduct(product);
                    }}
                    onPointerEnter={() => {
                        const el = document.getElementById(leftButtonId);
                        if (el) el.focus();
                    }}
                    onPointerLeave={() => {
                        const el = document.getElementById(leftButtonId);
                        if (el) el.blur();
                    }}
                    className="product-button-controller-left"
                />
                <div
                    onClick={() => {
                        if (!disabled) {
                            this.addProduct(product);
                        }
                    }}
                    onPointerEnter={() => {
                        const el = document.getElementById(rightButtonId);
                        if (el) el.focus();
                    }}
                    onPointerLeave={() => {
                        const el = document.getElementById(rightButtonId);
                        if (el) el.blur();
                    }}
                    className="product-button-controller-right"
                />
            </div>
        );

        //controller for ui
        const renderProductController = (
            <div className="product-button-controller">
                <Button
                    disabled={disabled}
                    id={leftButtonId}
                    danger
                    type="primary"
                    className="product-button-controller-left"
                >
                    {" "}
                </Button>
                <div
                    className={cx({
                        "product-button-controller-divider": true,
                        "product-button-controller-divider-disabled": disabled,
                    })}
                />
                <Button
                    disabled={disabled}
                    id={rightButtonId}
                    danger
                    type="primary"
                    className="product-button-controller-right"
                >
                    {" "}
                </Button>
            </div>
        );

        const renderWithoutOpt = (
            <>
                {renderProductInfo(true)}
                {renderProductController}
                {renderProductRealController}
            </>
        );

        const renderWithOpt = (
            <Button
                style={{ padding: 0 }}
                onClick={() => {
                    this.props.createOrderSetState({
                        showItemEditDetailDialog: true,
                        itemChangeObject: {
                            action: ITEM_CHANGE_ACTION_POS_MODE,
                            new_product: {
                                ...product,
                                cnt: 1,
                                pc: undefined,
                                pcIn: helper.getPrice(product),
                                opts: getDefaultOpts(product, this.props.lan),
                            },
                        },
                    });
                }}
                className="product-button-inner"
                key={product?.pid}
                danger
                disabled={disabled}
                type="primary"
            >
                {renderProductInfo(true)}
            </Button>
        );

        const renderGroupPurchaseProduct = () => {
            const selected = this.props.gpId === product?.pid;

            return (
                <div className="product-button-inner">
                    <Button
                        style={{ padding: 0 }}
                        className={cx({
                            "product-button-inner": true,
                            "selected": selected,
                        })}
                        key={product?.pid}
                        danger
                        disabled={disabled}
                        type="primary"
                        onClick={() => this.handleOnClickGroupPurchaseProduct(product?.pid)}
                    >
                        {renderProductInfo()}
                    </Button>
                    <div className="external-link-icon-container">
                        <a href={getGSaleURL(product?.pid ?? "", this.props.lan)} target="_blank" rel="noreferrer">
                            <IconContext.Provider value={{ size: "14px" }}>
                                <HiExternalLink className="external-link-icon" />
                            </IconContext.Provider>
                        </a>
                    </div>
                    {selected && this.renderCheckMark()}
                </div>
            );
        };

        let renderedProduct;
        if (isGroupPurchase) {
            renderedProduct = renderGroupPurchaseProduct();
        } else if (withOpt) {
            renderedProduct = renderWithOpt;
        } else {
            renderedProduct = renderWithoutOpt;
        }

        return renderedProduct;
    };

    renderLoader = () => {
        return (
            <div key="quick-add-modal-loader" className="gift-card-picker-loader-container">
                <CircularProgress size={24} color="secondary" />
            </div>
        );
    };

    render() {
        const hasMore = this.hasMore();
        return (
            <InfiniteScroll pageStart={0} loadMore={() => this.loadMore()} hasMore={hasMore} useWindow={false}>
                <div onScroll={() => this.onProductScroll()} className="quick-add-products" id="products-container">
                    {this.getProductsRow()?.map((row: any, i: any) => this.renderProductRow(row, i))}
                    {hasMore ? this.renderLoader() : null}
                </div>
            </InfiniteScroll>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    state: _.get(state, "createOrder", {}),
    store: _.get(state, "store.records", {}),
    products: _.get(state, "liveOrders.products", []),
    isLoadingProducts: _.get(state, "liveOrders.isGettingProducts", false),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    cachedProducts: _.get(state, "liveOrders.cachedProducts", {}),
    quickAddModalSearchInput: _.get(state, "createOrder.quickAddModalSearchInput"),
    shouldReloadProducts: _.get(state, "createOrder.shouldReloadProducts", false),
    gpId: _.get(state, "createOrder.gp_id", null),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    createOrderSetState,
    liveOrderSetState: actions.setState,
    getProducts: actions.getProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductsSection)));
