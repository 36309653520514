import React from "react";
import GoogleMapReact from "google-map-react";
import config from "../../../../../../config";
import { RootState } from "../../../../../../app/reducer";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Descriptions, Dropdown, Form, Input, InputNumber, Menu, Modal, Select } from "antd";
import {
    getDescriptionItem,
    getGoogleDataOptions,
    getNumberInput,
    getSelectInput,
} from "../../../../../../components/form";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormInstance } from "antd/lib/form";
import { selectWithPlaceId } from "../../../../../../util/helper/google";
import { setEditId } from "../../../../../../slices/pickup-delivery";
//@ts-ignore
import simplify from "simplify-geometry";
import "./styles.scss";

const POLYGON_COLOR = "blue";
const POLYGON_EDIT_COLOR = "red";
const MIN_SEARCH_LENGTH = 6;
const DEFAULT_LAT = 40.73;
const DEFAULT_LNG = -73.93;

class ZoneEditorModalContent extends React.Component<any, any> {
    map: any;
    polygon: any = {};
    drawing: any;

    formRef = React.createRef<FormInstance>();

    state: any = {
        currentEditZone: {},
        hoverId: null,
        editBoundaryDataMessage: "",
        suggestions: [],
        default_address: {},
    };

    resetState = () =>
        this.setState({
            currentEditZone: {
                name: "New Boundary",
                availabilities: [
                    {
                        days: ["mo"],
                        hours: [
                            {
                                open: "09:00",
                                close: "17:00",
                            },
                        ],
                    },
                ],
            },
            hoverId: null,
        });

    componentDidMount = () => {
        this.setState({ currentEditZone: this.getCurrentEditZone() });
    };

    initMap = ({ map, maps }: any) => {
        this.map = map;
        this.initDrawingManager(maps);
        this.initPolygons();
    };

    initPolygons = (polygons = this.getPolygons()) => {
        polygons.forEach(this.initPolygon);
        this.zoomToZone(this.map);
    };

    initPolygon = (polygon: any) => {
        const map = this.map;
        if (!_.isEmpty(polygon)) {
            this.polygon[polygon.id] = new window.google.maps.Polygon({
                editable: true,
                paths: polygon.paths,
                strokeColor: POLYGON_COLOR,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: POLYGON_COLOR,
                fillOpacity: 0.35,
            });

            window.google.maps.event.addListener(this.polygon[polygon.id], "click", () => {
                if (this.state.hoverId !== null) {
                    this.polygon[this.state.hoverId].setOptions({
                        strokeColor: POLYGON_COLOR,
                        fillColor: POLYGON_COLOR,
                    });
                }

                this.polygon[polygon.id].setOptions({
                    strokeColor: POLYGON_EDIT_COLOR,
                    fillColor: POLYGON_EDIT_COLOR,
                });

                this.setState({ hoverId: polygon.id });
            });

            this.polygon[polygon.id].setMap(map);
        } else {
            this.drawing.setMap(map);
        }
    };

    initDrawingManager(maps: any) {
        this.drawing = new maps.drawing.DrawingManager({
            drawingMode: maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: maps.ControlPosition.TOP_CENTER,
                drawingModes: [maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
                fillColor: "#BCDCF9",
                fillOpacity: 0.5,
                strokeWeight: 2,
                strokeColor: "#57ACF9",
                clickable: false,
                editable: true,
                zIndex: 1,
                draggable: true,
            },
        });
        this.drawing.setMap(null);
        const drawing = this.drawing;
        window.google.maps.event.addListener(this.drawing, "polygoncomplete", (polygon) => {
            this.polygon[Math.random().toString(36).substring(7)] = polygon;
            drawing.setMap(null);
        });
    }

    zoomToZone = (map: any) => {
        const polygon = this.polygon[0];

        if (map && polygon) {
            map.fitBounds(this.getPolygonsBounds(polygon));
        }
    };

    getPolygonsBounds = (polygon: any) => {
        let bounds = new window.google.maps.LatLngBounds();
        if (polygon) {
            if (polygon.getBounds) {
                bounds = polygon.getBounds;
            } else {
                polygon
                    .getPath()
                    .getArray()
                    .map((element: any) => {
                        bounds.extend(element);
                        return element;
                    });
            }
        }
        return bounds;
    };

    getCurrentEditZone = () => {
        const { editId, slots } = this.props;

        return Object.assign(
            {},
            {
                name: "New Boundary",
                ...(this.props.showMap
                    ? {
                          min_delivery_amt: 35,
                          min_pickup_amt: 0,
                          delivery_fee: 6,
                          free_delivery_amt: 60,
                      }
                    : {}),
                availabilities: [
                    {
                        days: ["mo"],
                        hours: [
                            {
                                open: "09:00",
                                close: "17:00",
                            },
                        ],
                    },
                ],
            },
            slots.find((s: any) => s?._id === editId) ?? {},
            this.state.currentEditZone ?? {}
        );
    };

    getPolygons = () => {
        const zone = this.getCurrentEditZone();
        const polygon: any = {};
        const polygons: any[] = [];

        if (zone) {
            const coords: any[] = zone?.boundary?.coordinates ?? [];

            if (Array.isArray(coords)) {
                coords.forEach((c, i) => {
                    if (c && c.length) {
                        const paths: any[] = [];

                        c.forEach((p: any) => {
                            if (p && p.length === 2) {
                                paths.push({
                                    lat: p[1],
                                    lng: p[0],
                                });
                            }
                        });

                        if (paths.length > 2) {
                            polygon.id = i;
                            polygon.paths = paths;

                            polygons[i] = {};
                            polygons[i].id = i;
                            polygons[i].paths = paths;
                        }
                    }
                });
            }
        }

        return polygons;
    };

    getPolygonsFromEdit = (coords: any) => {
        const polygons: any[] = [];

        if (Array.isArray(coords)) {
            coords.forEach((c, i) => {
                if (c && c.length) {
                    const paths: any[] = [];

                    c.forEach((p: any) => {
                        if (p && p.length === 2) {
                            paths.push({
                                lat: p[1],
                                lng: p[0],
                            });
                        }
                    });

                    if (paths.length > 2) {
                        polygons[i] = {};
                        polygons[i].id = i;
                        polygons[i].paths = paths;
                    }
                }
            });
        }
        return polygons;
    };

    getPolygonPathsArray(polygon: any) {
        const polygonArray: any[] = [];
        let firstPoint: any[] = [];
        for (let i = 0; i < polygon.getPath().getLength(); i++) {
            const current = polygon.getPath().getAt(i).toUrlValue(6).split(",");
            //reverse the zone to match server
            if (current.length === 2) {
                const reverse = [];
                reverse.push(parseFloat(current[1]));
                reverse.push(parseFloat(current[0]));
                polygonArray.push(reverse);
                if (i === 0) {
                    firstPoint = reverse;
                } else if (i === polygon.getPath().getLength() - 1) {
                    polygonArray.push(firstPoint);
                }
            }
        }
        return polygonArray;
    }

    tConvert(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }

    onChange = (name: string) => (e: any) => {
        const value = _.cloneDeep(e?.target?.value ?? e);
        this.setState((prev: any) => ({
            currentEditZone: {
                ...(prev.currentEditZone ?? {}),
                [name]: value,
            },
        }));
    };

    onSearch = (name: string) => (e: any) => {
        const value = _.cloneDeep(e?.target?.value ?? e);
        if (value.length > 0) {
            this.setState((prev: any) => ({
                currentEditZone: {
                    ...(prev.currentEditZone ?? {}),
                    [name]: value,
                },
            }));
        }
    };

    getDisplayLatLon = () => {
        return {
            lat: _.get(this.state, "latLngDisplay.lat", DEFAULT_LAT),
            lng: _.get(this.state, "latLngDisplay.lng", DEFAULT_LNG),
        };
    };

    handleAddressSearch = (address: string) => {
        const googleAutoService = new window.google.maps.places.AutocompleteService();
        const addressLengthSuffice = address.length >= MIN_SEARCH_LENGTH;

        if (addressLengthSuffice && googleAutoService) {
            googleAutoService.getPlacePredictions(
                {
                    input: address,
                    location: new window.google.maps.LatLng({
                        lat: this.getDisplayLatLon().lat,
                        lng: this.getDisplayLatLon().lng,
                    }),
                    radius: 100 * 1000,
                },
                (displaySuggestions) => {
                    const suggestions = _.isEmpty(displaySuggestions) ? [] : displaySuggestions;
                    this.setState({ suggestions });
                    this.forceUpdate();
                }
            );
        }
    };

    handleAddressSelect = (val: string) => {
        selectWithPlaceId(val, (address: any) => {
            const components = address?.address_components ?? [];
            const pickupAddress = {
                country: components.find((c: any) => c?.types?.includes("country"))?.long_name,
                street:
                    components.find((c: any) => c?.types?.includes("street_number"))?.long_name +
                    " " +
                    components.find((c: any) => c?.types?.includes("route"))?.long_name,
                city: components.find((c: any) => c?.types?.includes("locality"))?.long_name,
                region: components.find((c: any) => c?.types?.includes("administrative_area_level_1"))?.short_name,
                postal_code: components.find((c: any) => c?.types?.includes("postal_code"))?.long_name,
                lat: address?.geometry?.location?.lat?.(),
                lon: address?.geometry?.location?.lng?.(),
            };

            this.setState((prev: any) => ({
                currentEditZone: {
                    ...prev.currentEditZone,
                    pickup_location: {
                        ...(prev.currentEditZone?.pickup_location ?? {}),
                        address: {
                            ...(prev.currentEditZone?.pickup_location?.address ?? {}),
                            ...pickupAddress,
                        },
                    },
                },
            }));
        });
    };

    onAddressChange = (name: string) => (e: any) => {
        const value = _.cloneDeep(e.target.value);
        this.setState((prev: any) => ({
            currentEditZone: {
                ...prev.currentEditZone,
                pickup_location: {
                    ...(prev.currentEditZone?.pickup_location ?? {}),
                    address: {
                        ...(prev.currentEditZone?.pickup_location?.address ?? {}),
                        [name]: value,
                    },
                },
            },
        }));
    };

    onPickupChange = (name: string) => (e: any) => {
        const value = _.cloneDeep(e.target.value);
        this.setState((prev: any) => ({
            currentEditZone: {
                ...prev.currentEditZone,
                pickup_location: {
                    ...(prev.currentEditZone?.pickup_location ?? {}),
                    [name]: value,
                },
            },
        }));
    };

    getSlots = () => {
        return this.props.slots?.filter?.((ds: any) => {
            if (this.props.showMap) {
                return _.isEmpty(ds?.pickup_location);
            } else {
                return !_.isEmpty(ds?.pickup_location);
            }
        });
    };

    getSlotOptions = () => {
        return this.getSlots()?.map?.((slot: any) => ({
            value: slot?._id,
            label: slot?.name,
        }));
    };

    onSelectZone = (value: any) => {
        this.props.setEditId(value);
    };

    getForm = () =>
        !_.isEmpty(this.getCurrentEditZone()) || this.props.editId === null ? (
            <>
                <Form initialValues={this.getCurrentEditZone()}>
                    <Descriptions bordered size="small">
                        {getDescriptionItem(
                            this.props.showMap ? "zone_name" : "location_name",
                            3,
                            <Select
                                showSearch
                                value={this.state?.currentEditZone?.name}
                                onSearch={this.onSearch("name")}
                                onChange={this.onSelectZone}
                                style={{ width: "100%" }}
                                filterOption={(input, option) => {
                                    if (typeof option?.children === "string") {
                                        return String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    } else {
                                        return false;
                                    }
                                }}
                            >
                                {this.getSlotOptions()?.map((slot: any) => (
                                    <Select.Option key={slot.value} value={slot.value}>
                                        {slot.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                        {this.props.showMap ? (
                            <>
                                {getDescriptionItem(
                                    "min_delivery_amount",
                                    3,
                                    getNumberInput("min_delivery_amt", this.onChange("min_delivery_amt"))
                                )}
                                {getDescriptionItem(
                                    "delivery_fee",
                                    3,
                                    getNumberInput("delivery_fee", this.onChange("delivery_fee"))
                                )}
                                {getDescriptionItem(
                                    "free_delivery_amount",
                                    3,
                                    getNumberInput("free_delivery_amt", this.onChange("free_delivery_amt"))
                                )}
                                {getDescriptionItem(
                                    "cut_off_time",
                                    3,
                                    getNumberInput("cut_off_time", this.onChange("cut_off_time"))
                                )}
                            </>
                        ) : null}
                    </Descriptions>
                </Form>
                {this.props.showMap ? null : (
                    <>
                        <div className="address-zone-input">
                            <div className="text-subhead mb-2">
                                <FormattedMessage id="enter_address_to_autofill" />
                            </div>
                            {getSelectInput(
                                "address",
                                true,
                                getGoogleDataOptions(this.state.suggestions ?? []),
                                this.handleAddressSearch,
                                this.handleAddressSelect,
                                false,
                                "_placeholder"
                            )}
                        </div>
                        <Descriptions className="pickup-delivery-description" bordered size="small">
                            {getDescriptionItem(
                                "unit",
                                3,
                                <Input
                                    value={this.getCurrentEditZone()?.pickup_location?.address?.["unit"]}
                                    onChange={this.onAddressChange("unit")}
                                />
                            )}
                            {getDescriptionItem(
                                "street",
                                3,
                                this.getCurrentEditZone()?.pickup_location?.address?.["street"]
                            )}
                            {getDescriptionItem(
                                "city",
                                3,
                                this.getCurrentEditZone()?.pickup_location?.address?.["city"]
                            )}
                            {getDescriptionItem(
                                "province",
                                3,
                                this.getCurrentEditZone()?.pickup_location?.address?.["region"]
                            )}
                            {getDescriptionItem(
                                "country",
                                3,
                                this.getCurrentEditZone()?.pickup_location?.address?.["country"]
                            )}
                            {getDescriptionItem(
                                "postal_code",
                                3,
                                <Input
                                    value={`${this.getCurrentEditZone()?.pickup_location?.address?.["postal_code"]}`}
                                    onChange={this.onAddressChange("postal_code")}
                                />
                            )}
                            {getDescriptionItem(
                                "phone",
                                3,
                                <Input
                                    value={this.getCurrentEditZone()?.pickup_location?.["phone"]}
                                    onChange={this.onPickupChange("phone")}
                                />
                            )}
                            {getDescriptionItem(
                                "min_pickup_amount",
                                3,
                                <InputNumber
                                    value={this.getCurrentEditZone()?.min_pickup_amt}
                                    onChange={this.onChange("min_pickup_amt")}
                                />
                            )}
                            {getDescriptionItem(
                                "cut_off_time",
                                3,
                                <InputNumber
                                    value={this.getCurrentEditZone()?.cut_off_time}
                                    onChange={this.onChange("cut_off_time")}
                                />
                            )}
                            {getDescriptionItem(
                                "comment",
                                3,
                                <Input.TextArea
                                    value={this.getCurrentEditZone()?.comment}
                                    onChange={this.onChange("comment")}
                                />
                            )}
                        </Descriptions>
                    </>
                )}
            </>
        ) : null;

    getHoursOptions = () => {
        const options: any = [];

        for (let i = 0; i < 24; i++) {
            options.push({
                label: this.tConvert(`${("0" + i).slice(-2)}:00`),
                value: `${("0" + i).slice(-2)}:00`,
            });

            options.push({
                label: this.tConvert(`${("0" + i).slice(-2)}:30`),
                value: `${("0" + i).slice(-2)}:30`,
            });
        }

        return options;
    };

    getAvailableDays = (values: any[]) => {
        const days = ["mo", "tu", "we", "th", "fr", "sa", "su"];
        return days.filter((day) => {
            let filter = true;
            this.getCurrentEditZone()?.availabilities?.forEach?.((a: any) => {
                a?.days?.forEach((d: any) => {
                    if (a?.days.includes(day) && !values.includes(d)) {
                        filter = false;
                    }
                });
            });
            return filter;
        });
    };

    addTimeSlot = () => {
        this.setState((prev: any) => {
            const newAvailabilities = _.cloneDeep(prev.currentEditZone.availabilities);
            const index = newAvailabilities.length;
            newAvailabilities[index] = {};
            newAvailabilities[index].hours = [
                {
                    open: "9:00",
                    close: "17:00",
                },
            ];

            newAvailabilities[index].days = [];

            return {
                currentEditZone: {
                    ...prev.currentEditZone,
                    availabilities: newAvailabilities,
                },
            };
        });
    };

    toggleAvailabilityAllDay = (a: any, i: any) => {
        if (a?.hours === "ALL") {
            this.setState((prev: any) => {
                const newAvailabilities = _.cloneDeep(prev.currentEditZone.availabilities);
                newAvailabilities[i].hours = [
                    {
                        open: "9:00",
                        close: "17:00",
                    },
                ];

                return {
                    currentEditZone: {
                        ...prev.currentEditZone,
                        availabilities: newAvailabilities,
                    },
                };
            });
        } else {
            this.setState((prev: any) => {
                const newAvailabilities = _.cloneDeep(prev.currentEditZone.availabilities);
                newAvailabilities[i].hours = "ALL";

                return {
                    currentEditZone: {
                        ...prev.currentEditZone,
                        availabilities: newAvailabilities,
                    },
                };
            });
        }
    };

    renderHours = (hours: any, h: any, i: any) => {
        const onChange = (index: any) => (value: any) => {
            this.setState((prev: any) => {
                const newAvailabilities = _.cloneDeep(prev.currentEditZone.availabilities);
                newAvailabilities[i].hours[h][index] = value;

                return {
                    currentEditZone: {
                        ...prev.currentEditZone,
                        availabilities: newAvailabilities,
                    },
                };
            });
        };

        return (
            <div className="time-select">
                <div>
                    <Select value={hours.open} onChange={onChange("open")}>
                        {this.getHoursOptions().map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Select value={hours.close} onChange={onChange("close")}>
                        {this.getHoursOptions().map((option: any) => (
                            <Select.Option value={option.value} key={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    };

    getDayEditor = (a: any, i: any) => (
        <div className="time-slot-desc">
            <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder={"days_placeholder"}
                value={a?.days}
                onChange={(value) =>
                    this.setState((prev: any) => {
                        const newAvailabilities = _.cloneDeep(prev.currentEditZone.availabilities);
                        newAvailabilities[i].days = value;

                        return {
                            currentEditZone: {
                                ...prev.currentEditZone,
                                availabilities: newAvailabilities,
                            },
                        };
                    })
                }
            >
                {this.getAvailableDays(a?.days).map((day) => (
                    <Select.Option key={day} value={day}>
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    getTimeSlots = () =>
        !_.isEmpty(this.getCurrentEditZone()) || this.props.editId === null ? (
            <Form>
                {this.getCurrentEditZone()?.availabilities ? (
                    <Descriptions bordered>
                        {this.getCurrentEditZone()?.availabilities?.map?.((a: any, i: number) => (
                            <Descriptions.Item key={`time-slots-key-${i}`} label={this.getDayEditor(a, i)} span={3}>
                                {a?.hours?.map?.((hours: any, h: any) => this.renderHours(hours, h, i))}
                                {a?.hours === "ALL" ? <FormattedMessage id="all_day" /> : null}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                ) : null}
                <div className="edit-time-button">
                    <Button disabled={!this.getAvailableDays([]).length} type="primary" onClick={this.addTimeSlot}>
                        <FormattedMessage id="add_time_slot" />
                    </Button>
                </div>
            </Form>
        ) : null;

    getTopRow = () => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "calc(50% - 12px)" }}>{this.getForm()}</div>
            <div style={{ width: "calc(50% - 12px)" }}>{this.getTimeSlots()}</div>
        </div>
    );

    deletePolygon = () => {
        Object.keys(this.polygon).forEach((id) => {
            this.polygon?.[id].setMap(null);
            delete this.polygon?.[id];
        });
    };

    modalOnOk = () => {
        try {
            const reduced = JSON.parse(this.state.currentEditBoundary).map((parsed: any) => simplify(parsed, 0.01));
            const polygonsFromEdit = this.getPolygonsFromEdit(reduced);

            if (!_.isEmpty(polygonsFromEdit) && Array.isArray(JSON.parse(this.state.currentEditBoundary))) {
                this.deletePolygon();

                this.initPolygons(polygonsFromEdit);

                this.setState({ editBoundaryDataMessage: "", editBoundaryEditor: false });
            } else {
                this.setState({ editBoundaryDataMessage: "Invalid data syntax" });
            }
        } catch (e: unknown) {
            _.isEmpty(this.state.currentEditBoundary)
                ? this.setState({ editBoundaryDataMessage: "Data cannot be empty" })
                : this.setState({ editBoundaryDataMessage: "Invalid data syntax" });
        }
    };

    modalOnCancel = () => {
        this.setState({ editBoundaryEditor: false });
        this.setState({ editBoundaryDataMessage: "" });
    };

    boundaryDataHasError = (): boolean => {
        return !_.isEmpty(this.state.editBoundaryDataMessage);
    };

    getBoundaryDataEditor = () => (
        <Modal
            onOk={this.modalOnOk}
            onCancel={this.modalOnCancel}
            title={this.props.intl.formatMessage({ id: "edit_boundary_data" })}
            visible={this.state.editBoundaryEditor}
        >
            <Input.TextArea
                value={this.state.currentEditBoundary}
                rows={8}
                onChange={(e) => {
                    this.setState({ currentEditBoundary: e.target.value ?? "" });
                }}
                status={this.boundaryDataHasError() ? "error" : ""}
            />
            <span
                className="error-message"
                style={{
                    display: this.boundaryDataHasError() ? "block" : "none",
                }}
            >
                {this.state.editBoundaryDataMessage}
            </span>
        </Modal>
    );

    getContextMenu = () => {
        const addNewBoundary = () => {
            this.drawing.setMap(this.map);
        };

        const removePolygon = () => {
            this.polygon?.[this.state.hoverId].setMap(null);
            delete this.polygon?.[this.state.hoverId];
            this.setState({ hoverId: null });
        };

        const editBoundaryText = () => {
            const converted = this.getNewBoundaries()?.boundary?.coordinates;
            this.setState({
                currentEditBoundary: JSON.stringify(converted),
                editBoundaryEditor: true,
            });
        };

        return (
            <Menu>
                <Menu.Item key="1" onClick={addNewBoundary}>
                    <FormattedMessage id="add_new_boundary" />
                </Menu.Item>
                {this.state.hoverId !== null ? (
                    <Menu.Item key="2" onClick={removePolygon}>
                        <FormattedMessage id="remove_selected_boundary" />
                    </Menu.Item>
                ) : null}
                <Menu.Item key="3" onClick={editBoundaryText}>
                    <FormattedMessage id="edit_boundary_data" />
                </Menu.Item>
            </Menu>
        );
    };

    getGoogleMap = () => (
        <Dropdown overlay={this.getContextMenu()} trigger={["contextMenu"]}>
            <div className="google-map-container">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.GOOGLE_API_KEY }}
                    defaultZoom={11}
                    defaultCenter={{
                        lat: this.props?.coords?.lat ?? 49.2827,
                        lng: this.props?.coords?.lng ?? 123.1207,
                    }}
                    onGoogleApiLoaded={this.initMap}
                    yesIWantToUseGoogleMapApiInternals
                />
            </div>
        </Dropdown>
    );

    getNewBoundaries = () => {
        if (!_.isEmpty(this.polygon)) {
            return {
                boundary: {
                    type: "Polygon",
                    coordinates: Object.keys(this.polygon)
                        .map((id) => this.getPolygonPathsArray(this.polygon[id]))
                        .map((polygon) => polygon.map((p) => simplify(p, 0.01))),
                },
            };
        }

        return {};
    };

    render() {
        return (
            <div className="settings-general-page zone-editor-modal">
                {this.getBoundaryDataEditor()}
                {this.getTopRow()}
                {this.props.showMap ? this.getGoogleMap() : null}
                <div className="setting-actions" style={{ marginTop: 14 }}>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            const saved = this.props.onSave(
                                Object.assign(
                                    {},
                                    this.state.currentEditZone,
                                    { id: this.props.editId },
                                    this.getNewBoundaries()
                                )
                            );

                            if (saved) {
                                this.resetState();
                            }
                        }}
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    editId: state?.pickupDelivery?.editId,
    slots: state?.pickupDelivery?.deliveryTimeSlots,
    coords: state?.location?.coordinates,
});

const mapDispatchToProps = {
    setEditId,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ZoneEditorModalContent));
