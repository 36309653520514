import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Row, Col, InputNumber, Empty } from "antd";
import _ from "lodash";
import { formatCurrency } from "../../../../util/helper";
import { getItemOptionsArray } from "../../../order-history/helper";
import { setState } from "../../../../slices/create-order";
import { MdClose } from "react-icons/md";
import Collapse from "@material-ui/core/Collapse";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { getProductPrice } from "../../helper";
import { ITEM_CHANGE_ACTION_REMOVE, ITEM_CHANGE_ACTION_REPLACE } from "pages/liveorder/_components/order-items/helper";

const COL_WIDTH = {
    name: 15,
    qt: 4,
    pc: 3,
    action: 2,
};

const ORDER_DETAIL_SHOWN_HEIGHT = 177;
const ORDER_DETAIL_HIDDEN_HEIGHT = 42;
const ORDER_QUANTITY_MAX_LEN = 3;
const ORDER_QUANTITY_MAX_QTY = 999;

class OrderItems extends Component<any, any> {
    state: any = { in: true, showOrderDetails: true, onEdit:false};

    componentDidMount = () => {
        this.setListeners();
    };

    componentDidUpdate = () => {
        if (this.state.showOrderDetails !== this.props.showOrderDetails) {
            this.setState({ showOrderDetails: this.props.showOrderDetails });
            this.setHeight();
        }
    };

    componentWillUnmount = () => {
        this.removeListeners();
    };

    getItems = () => (Array.isArray(this.props.items) ? this.props.items : []);

    setListeners = () => {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    setHeight = () => {
        const minusRowIds: any = ["checkout-button-container", "search-products-row"];

        const sectionTitleHeight = 50;
        //50 buffer
        let result = window.innerHeight - sectionTitleHeight;
        minusRowIds.forEach((id: any) => {
            const minus = document.getElementById(id);
            if (minus && minus.offsetHeight) {
                result -= minus.offsetHeight;
            }
        });
        if (this.props.showOrderDetails !== false) {
            // height of the order details when shown
            result -= ORDER_DETAIL_SHOWN_HEIGHT;
        } else {
            // height of the order details when only total due is shown
            result -= ORDER_DETAIL_HIDDEN_HEIGHT;
        }
        this.setState({ height: result > 0 ? result : "30vh" });
    };

    renderProducts = () => {
        return (
            <div className="create-order-items-section" style={{ height: this.state.height }}>
                {this.renderTitle()}
                <Collapse in={this.state.in}>{this.getItems().map((item, i) => this.renderProduct(item, i))}</Collapse>
            </div>
        );
    };

    getTotalItemQtys = () => {
        return this.props.items.reduce((acc: number, item: any) => {
            acc += item.qty;
            return acc;
        }, 0);
    };

    renderTitle = () => {
        return (
            <div className="create-order-title-row" onClick={() => this.setState({ in: !this.state.in })}>
                <Row gutter={8}>
                    <Col span={COL_WIDTH.name} className="create-order-item-title">
                        <FormattedMessage id="item_s" />
                        {` · ${this.getTotalItemQtys()}`}
                    </Col>
                    <Col span={COL_WIDTH.qt} className="create-order-item-title create-order-item-quantity">
                        <FormattedMessage id="quantity" />
                    </Col>
                    <Col span={COL_WIDTH.pc} className="create-order-item-title create-order-item-total-title">
                        <FormattedMessage id="total" />
                    </Col>
                    <Col span={COL_WIDTH.action} className="create-order-item-title create-order-item-action-title">
                        {this.state.in ? <DownOutlined /> : <RightOutlined />}
                    </Col>
                </Row>
            </div>
        );
    };

    renderProduct = (item: any, i: number) => {
        return (
            <Row gutter={8} className="create-order-item-row">
                {this.renderName(item, i)}
                {this.renderQuantity(item, i)}
                {this.renderTotal(item)}
                {this.renderClose(item, i)}
            </Row>
        );
    };

    renderName = (item: any, i: number) => {
        const subOptions = getItemOptionsArray(item?.opts, this.props.lan);
        return (
            <Col span={COL_WIDTH.name}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        this.props.setState({
                            showItemEditDetailDialog: true,
                            itemChangeObject: {
                                pid: item.pid,
                                action: ITEM_CHANGE_ACTION_REPLACE,
                                new_product: { ...item, i },
                            },
                        });
                    }}
                >
                    <div className="item-name">{_.get(item, "nm")}</div>
                    <div>
                        {subOptions.map((opt) => {
                            return (
                                <div key={`item-option-${opt.id}`}>
                                    <span>-&nbsp;</span>
                                    <span className="option-name"> {opt.nm}: </span>
                                    <span className="option-values">{opt.optStr}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Col>
        );
    };

    renderQuantity = (item: any, i: number) => {
        return (
            <Col className="right-display" span={COL_WIDTH.qt}>
                <InputNumber
                    min={1}
                    max={item?.sq || ORDER_QUANTITY_MAX_QTY}
                    maxLength={ORDER_QUANTITY_MAX_LEN}
                    value={item?.qty}
                    onChange={(qty) => {
                        const items = _.cloneDeep(this.props.items);
                        items[i] = {
                            ...item,
                            qty,
                        };
                        this.props.setState({ items, itemChangeAction: ITEM_CHANGE_ACTION_REMOVE });
                    }}
                />
            </Col>
        );
    };

    renderTotal = (item: any) => {
        const total = getProductPrice(item) * item.qty;
        return (
            <Col className="right-display" span={COL_WIDTH.pc}>
                {formatCurrency(total, this.props.storeCurrency)}
            </Col>
        );
    };

    renderClose = (item: any, i: number) => {
        return (
            <Col className="create-order-remove center-display" span={COL_WIDTH.action}>
                <MdClose
                    onClick={() => {
                        const items = _.cloneDeep(this.props.items);
                        items.splice(i, 1);
                        this.props.setState({ items, itemChangeAction: ITEM_CHANGE_ACTION_REMOVE });
                    }}
                />
            </Col>
        );
    };

    renderEmpty = () => {
        return (
            <div className="create-order-items-section replace-item-empty-box" style={{ height: this.state.height }}>
                <Empty description={<FormattedMessage id="no_item" />} />
            </div>
        );
    };

    render() {
        return _.isEmpty(this.getItems()) ? this.renderEmpty() : this.renderProducts();
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "setting.lan", "en"),
    items: _.get(state, "createOrder.items", []),
    showOrderDetails: _.get(state, "createOrder.showOrderDetails"),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderItems)));
