import { Button, Dropdown, Form, Menu, Modal, Popover, Spin, Tabs } from "antd";
import { RootState } from "app/reducer";
import qs from "querystring";
import { useEffect } from "react";
import { FiShare } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { getGSaleURL, getProductURL } from "util/helper";
import PosterDialog from "../../../../components/poster-dialog";
import RefreshButton from "../../../../components/refresh";
import Wrapper from "../../../../components/wrapper";
import config from "../../../../config";
import { getProduct } from "../../../../services/products";
import { getValuesSuccess, setValuesSuccess } from "../../../../slices/products";
import { ProductTypes } from "../../constants";
import useAddEditProductPage from "./useAddEditProductPage";
import ItemInformationTab from "./_components/item-information";
import ItemSEOTab from "./_components/item-seo";
import OptionsTab from "./_components/options";
import RelatedUpsellProducts from "./_components/related-upsell-products";

export enum ItemTabs {
    ItemInformation = "item_info",
    Fullfilment = "fullfilment",
    Options = "options",
    Seo = "seo",
    RelatedUpsellProducts = "related_upsell_products",
}

const allTabs = [
    {
        key: ItemTabs.ItemInformation,
        tab: ItemInformationTab,
    },
    {
        key: ItemTabs.Options,
        tab: OptionsTab,
    },
    {
        key: ItemTabs.Seo,
        tab: ItemSEOTab,
    },
    {
        key: ItemTabs.RelatedUpsellProducts,
        tab: RelatedUpsellProducts,
    },
];

function AddEditProduct(): JSX.Element {
    const {
        compareOriginal,
        history,
        intl,
        QRCode,
        QrCode,
        tab,
        form,
        values,
        setValues,
        submitting,
        showQR,
        setShowQR,
        product,
        lan,
        lastSearch,
        storeLogoUrl,
        options,
        setOptions,
        getValues,
        onDuplicateProduct,
        handleValuesChanged,
        onSaveAndContinue,
        onFinish,
        onChangeTab,
        openWeChatQRModal,
        copyProduct,
        getLastDisabled,
        getNextDisabled,
        getFirst,
        getLast,
        getNext,
        getPrev,
        onDeleteProduct,
        pid,
        clearForm,
        showForm,
        images,
        setImages,
        getDuplicateModal,
        openPosterModal,
        showPoster,
        setShowPoster,
        barcodeErrorMessage,
        setBarcodeErrorMessage,
    } = useAddEditProductPage();
    const dispatch = useDispatch();
    const { loading } = useSelector((state: RootState) => ({
        loading: state?.products?.loading,
    }));

    const share = () => {
        const social_url: string =
            product?.product_type === ProductTypes.GROUP_PRODUCT ? getGSaleURL(pid, lan) : getProductURL(pid, lan);
        const message = intl.formatMessage({ id: "share_product" });

        return (
            <div className="d-flex flex-column justofy-content-start align-items-start">
                <FacebookShareButton url={social_url} quote={message}>
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_facebook" />
                    </p>
                </FacebookShareButton>
                <a
                    target="_blank"
                    href={`mailto:?subject=${message}&body=${message} ${social_url}`}
                    rel="noopener noreferrer"
                >
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_email" />
                    </p>
                </a>
                <TwitterShareButton url={social_url} title={message} via={message}>
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_twitter" />
                    </p>
                </TwitterShareButton>
                <p className="popover-item mb-1" onClick={() => openWeChatQRModal()}>
                    <FormattedMessage id="share_qrcode" />
                </p>
                <p className="popover-item mb-1" onClick={() => openPosterModal()}>
                    <FormattedMessage id="poster" />
                </p>
                <p className="popover-item mb-1" onClick={() => window.open(social_url, "_blank")}>
                    <FormattedMessage id="open_product_webpage" />
                </p>
                <p className="popover-item mb-1" onClick={() => copyProduct(social_url)}>
                    <FormattedMessage id="copy_product_url" />
                </p>
            </div>
        );
    };

    const getQRModal = () => {
        const social_url: string =
            product?.product_type === ProductTypes.GROUP_PRODUCT ? getGSaleURL(pid, lan) : getProductURL(pid, lan);
        return (
            <Modal
                width="300px"
                footer={null}
                title={intl.formatMessage({ id: "share_qrcode" })}
                onCancel={() => setShowQR(false)}
                bodyStyle={{ padding: "15px", margin: "0px", display: "flex", justifyContent: "center" }}
                visible={showQR}
            >
                <div className="d-flex flex-column mt-1 align-items-center">
                    <QRCode
                        value={`${social_url}`}
                        size={250}
                        logoImage={`${config.IMG_PREFIX}${product?.images?.[0] ?? storeLogoUrl}`}
                        enableCORS={true}
                    />
                    <div className="product-qr-code mt-2" onClick={() => QrCode()}>
                        <FormattedMessage id="download_QR" />
                    </div>
                </div>
            </Modal>
        );
    };

    const renderPosterModal = (
        <PosterDialog type="product" product={product} open={showPoster} onClose={() => setShowPoster(false)} />
    );

    const moreActionsMenu = (
        <Menu>
            <Menu.Item onClick={onDuplicateProduct}>
                <FormattedMessage id="duplicate" />
            </Menu.Item>
            <Menu.Item onClick={onDeleteProduct} danger>
                <FormattedMessage id="delete" />
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        //when you first open a product if it has duplicate in the url then duplicate state should be true
        //reset redux stat info and preform logic
        dispatch(setValuesSuccess({}));
        if (!window.location.href?.includes("duplicate")) {
            dispatch(getValuesSuccess({ duplicate: false }));
        } else {
            dispatch(getValuesSuccess({ duplicate: true }));
        }
    }, []);

    /**
     * Render save buttons section.
     * When "Save & Close" is clicked, item will be created/updated
     *  and user will be redirected to products page.
     * When "Save & Continue" is clicked, item will be created/updated
     *  and user will remain on the item's page.
     *
     * @returns Save button(s).
     */
    const renderSaveSection = () => {
        return (
            <>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={compareOriginal()}
                    className="save-n-close-button"
                >
                    <FormattedMessage id="save_n_close" />
                </Button>
                <Button
                    type="primary"
                    size="large"
                    onClick={onSaveAndContinue}
                    disabled={compareOriginal()}
                    className="save-button-handler"
                >
                    <FormattedMessage id="save_n_continue" />
                </Button>
            </>
        );
    };

    const renderSettingActionBar = () => (
        <div className="setting-actions floating-actions">
            <div className="prev-next-buttons">
                {pid ? (
                    <Button.Group>
                        <Button onClick={getFirst} disabled={getLastDisabled()}>
                            <FormattedMessage id="first" />
                        </Button>
                        <Button onClick={getPrev} disabled={getLastDisabled()}>
                            <FormattedMessage id="previous" />
                        </Button>
                        <Button onClick={getNext} disabled={getNextDisabled()}>
                            <FormattedMessage id="next" />
                        </Button>
                        <Button onClick={getLast} disabled={getNextDisabled()}>
                            <FormattedMessage id="last" />
                        </Button>
                    </Button.Group>
                ) : null}
            </div>
            <div>
                {pid ? (
                    <Button.Group style={{ marginRight: 14 }}>
                        <Dropdown overlay={moreActionsMenu} placement="top">
                            <Button>
                                <FormattedMessage id="more_actions" />
                            </Button>
                        </Dropdown>
                        <Popover content={share()} placement="top">
                            <Button>
                                <FiShare />
                            </Button>
                        </Popover>
                    </Button.Group>
                ) : null}
                <Button size="large" disabled={compareOriginal()} onClick={clearForm} className="discard-button" danger>
                    <FormattedMessage id="discard" />
                </Button>
                {renderSaveSection()}
            </div>
        </div>
    );

    useEffect(() => {
        form.setFieldsValue({ ...getValues(), ...values });
    });
    /**
     * If product already exists, render all tabs.
     * If product is being created, render only Item Info and SEO tabs.
     *
     * @returns Product detail header tabs.
     */
    const renderTabs = () => {
        //const tabsToRender = pid ? allTabs : createProductTabs;
        const tabsToRender = allTabs;
        return (
            <Tabs
                type="card"
                activeKey={tab}
                defaultActiveKey={tab}
                onChange={onChangeTab}
                size="large"
                tabBarExtraContent={
                    <RefreshButton
                        style={{ marginRight: "calc(100vw - 700px)" }}
                        refreshPage={refreshPage}
                        loading={loading}
                    />
                }
            >
                {tabsToRender.map((tab) =>
                    tab.key === ItemTabs.Options && values.product_type === ProductTypes.GROUP_PRODUCT ? null : (
                        <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                            <tab.tab
                                pid={pid}
                                images={images}
                                setImages={setImages}
                                values={values}
                                setValues={setValues}
                                options={options}
                                setOptions={setOptions}
                                initialValues={{ ...getValues(), ...values }}
                                form={form}
                                barcodeErrorMessage={tab.key === ItemTabs.ItemInformation ? barcodeErrorMessage : null}
                                setBarcodeErrorMessage={setBarcodeErrorMessage}
                                lan={lan}
                            />
                        </Tabs.TabPane>
                    )
                )}
            </Tabs>
        );
    };

    const refreshPage = () => {
        dispatch(getProduct(pid));
    };

    const breadcrumb = {
        routes: [
            {
                path: "dashboard",
                breadcrumbName: "nav_dashboard",
            },
            {
                path: "products",
                breadcrumbName: "items",
            },
            {
                path: "addEditProduct",
                breadcrumbName: !pid ? "add_item" : "edit_item",
            },
        ],
    };

    return (
        <Wrapper
            helmet={{ title: !pid ? "add_item" : "edit_item" }}
            breadcrumb={breadcrumb}
            onBack={() => {
                dispatch(getValuesSuccess({}));
                history.push("/products?" + qs.stringify(lastSearch));
            }}
        >
            <Prompt
                when={!compareOriginal()}
                message={(location) => {
                    if (submitting) {
                        return true;
                    }

                    if (location?.pathname?.includes("addEditItem")) {
                        return true;
                    }
                    return intl.formatMessage({ id: "alert_leaving_without_save" });
                }}
            />
            {showForm() ? (
                <Spin spinning={loading}>
                    <Form
                        className="items-info-form"
                        initialValues={getValues()}
                        form={form}
                        onValuesChange={handleValuesChanged}
                        size="large"
                        onFinish={onFinish}
                        style={{ width: "100%" }}
                    >
                        {renderTabs()}
                        {renderSettingActionBar()}
                    </Form>
                </Spin>
            ) : (
                <div className="loading-products">
                    <Spin />
                </div>
            )}
            {getQRModal()}
            {getDuplicateModal()}
            {renderPosterModal}
        </Wrapper>
    );
}

export default AddEditProduct;
