import { Pagination, PaginationProps } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import qs from "querystring";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";

function ReferralsPagnation(): JSX.Element {
    const history = useHistory();
    const location = useLocation();
    const search = qs.parse(location.search.slice(1));
    const { navigationWidth, total } = useSelector((state: RootState) => ({
        navigationWidth: state?.ui?.navigationWidth,
        total: state?.referrals?.paging?.total,
    }));

    const paginationProps: PaginationProps = {
        total,
        pageSize: Number(search?.limit ?? DEFAULT_PAGE_SIZE),
        current: Number(search?.page),
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            const query = _.cloneDeep(search);
            query.page = (page || 1).toString();
            if (pageSize) {
                query.limit = pageSize.toString();
            }
            history.push("/referrals?" + qs.stringify(query));
        },
    };

    return (
        <div
            className="pagination-products"
            style={{
                width: `calc(100vw - ${navigationWidth}px)`,
            }}
        >
            <Pagination {...paginationProps} />
            <div className="pagination-products-total">
                <FormattedMessage id="total" />: {total}
            </div>
        </div>
    );
}

export default ReferralsPagnation;
