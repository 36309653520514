import { Button, Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import qs from "querystring";
import { useHistory } from "react-router-dom";
import config from "../../../../../config";

function emptyFunction() {
    // does nothing
}

const ReferralsFilter = (props: any = {}): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const cloned: any = _.cloneDeep(data);

        _.each(cloned, (value, key) => {
            if (_.isUndefined(value) || value === "") {
                delete cloned[key];
            }
        });

        const query: any = qs.stringify(cloned);

        history.push("/superAdmin/newStoreManagement?" + query);

        props.closeModal();
    };

    const resetFilters = () => {
        form.resetFields();

        history.push("/superAdmin/newStoreManagement");

        props.closeModal();
    };

    useEffect(() => {
        form.resetFields();
    }, [props.search, form]);

    const clearButton = (callback = emptyFunction) => (
        <div className="clear-filter-btn-container">
            <Button type="link" className="clear-filter-btn" onClick={callback}>
                <FormattedMessage id="clear" />
            </Button>
        </div>
    );

    const renderNameRow = (
        <>
            <div className="d-flex">
                <div>
                    <FormattedMessage id="name" />
                    <Form.Item name="sname">
                        <Input
                            placeholder={intl.formatMessage({
                                id: "type_here",
                            })}
                        />
                    </Form.Item>
                    {clearButton(() => {
                        form.setFieldsValue({ sname: "" });
                    })}
                </div>
            </div>
        </>
    );

    const renderCityRow = (
        <>
            <div className="d-flex">
                <div>
                    <FormattedMessage id="city" />
                    <Form.Item name="city">
                        <Input
                            placeholder={intl.formatMessage({
                                id: "type_here",
                            })}
                        />
                    </Form.Item>
                    {clearButton(() => {
                        form.setFieldsValue({ city: "" });
                    })}
                </div>
            </div>
        </>
    );

    const getStatusOptions = () =>
        Object.keys(config.STORE_STATUS_MAPPING).map((key: any) => (
            <Select.Option value={key} key={key}>
                {/* @ts-ignore */}
                {intl.formatMessage({ id: config.STORE_STATUS_MAPPING[key] })}
            </Select.Option>
        ));

    const renderStatusRow = (
        <>
            <div className="d-flex">
                <div>
                    <FormattedMessage id="status" />
                    <Form.Item name="status">
                        <Select placeholder={intl.formatMessage({ id: "select_type" })}>{getStatusOptions()}</Select>
                    </Form.Item>
                    {clearButton(() => {
                        form.setFieldsValue({ status: "" });
                    })}
                </div>
            </div>
        </>
    );

    return (
        <div className="new-store-filter">
            <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish} initialValues={props.search}>
                {renderNameRow}
                {renderCityRow}
                {renderStatusRow}
                <div className="footer-filter">
                    <Button type="link" onClick={resetFilters}>
                        <FormattedMessage id="reset_filters" />
                    </Button>
                    <Button type="primary" htmlType="submit" className="save-button-handler">
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ReferralsFilter;
