import _ from "lodash";
import { useReducer } from "react";

interface AddEditOptionState {
    id?: any;
    nm?: any;
    type?: string;
    min?: number;
    max?: number;
    opts: Options[];
}

interface Options {
    id?: any;
    nm?: any;
    pc?: number;
    sq?: number;
    availability?: number;
    min?: number;
    max?: number;
}

export enum AddEditOptionTypes {
    Init,
    OnChange,
    OnChangeOption,
    AddOption,
    RemoveOption,
    Reset,
    SwapOptionPostions,
}

const reducer = (state: AddEditOptionState, { type, payload }: any) => {
    const updated = _.cloneDeep(state);
    switch (type) {
        case AddEditOptionTypes.Init:
            if (payload !== undefined) {
                return Object.assign({}, updated, payload);
            } else {
                return Object.assign({}, defaultState);
            }
        case AddEditOptionTypes.OnChange:
            if (payload.name === "min") {
                const newMin = !isNaN(Number(payload.value)) ? Number(payload.value) : 0;
                if (!isNaN(Number(updated.max))) {
                    _.set(updated, "min", newMin <= Number(updated.max) ? newMin : Number(updated.max));
                } else {
                    _.set(updated, "min", 0);
                    _.set(updated, "max", 0);
                }
            } else if (payload.name === "max") {
                const newMax = !isNaN(Number(payload.value)) ? Number(payload.value) : 0;
                if (!isNaN(Number(updated.min))) {
                    _.set(updated, "max", newMax >= Number(updated.min) ? newMax : Number(updated.min));
                } else {
                    _.set(updated, "min", newMax);
                    _.set(updated, "max", newMax);
                }
            } else if (payload.isTranslated) {
                _.set(updated, `${payload.name}.${payload.lan}`, payload.value);
            } else if (payload.valueType) {
                if (payload.valueType === "qty_input") {
                    _.set(updated, `qty_input`, payload.value ? 1 : 0);
                    if (payload.value) {
                        _.set(updated, `type`, "checkbox");
                    }
                } else {
                    _.set(updated, `${payload.name}`, payload.value);
                }
            } else {
                _.set(updated, `${payload.name}`, payload.value);
            }
            return updated;
        case AddEditOptionTypes.OnChangeOption:
            if (payload.name === "min") {
                const newMin = !isNaN(Number(payload.value)) ? Number(payload.value) : 0;
                const currMax = Number(_.get(updated, `opts.${payload.index}.max`, 0));
                _.set(updated, `opts.${payload.index}.min`, newMin <= currMax ? newMin : currMax);
                // in case max is currently empty
                _.set(updated, `opts.${payload.index}.max`, currMax);
            } else if (payload.name === "max") {
                const newMax = !isNaN(Number(payload.value)) ? Number(payload.value) : 0;
                const currMin = Number(_.get(updated, `opts.${payload.index}.min`, 0));
                _.set(updated, `opts.${payload.index}.max`, newMax >= currMin ? newMax : currMin);
                // in case min is currently empty
                _.set(updated, `opts.${payload.index}.min`, currMin);
            } else if (payload.isTranslated) {
                _.set(updated, `opts.${payload.index}.${payload.name}.${payload.lan}`, payload.value);
            } else {
                _.set(updated, `opts.${payload.index}.${payload.name}`, payload.value);
            }
            return updated;
        case AddEditOptionTypes.AddOption:
            updated.opts = [
                ...(updated.opts ?? []),
                {
                    id: updated?.opts?.length + 1,
                    pc: 0,
                    sq: 1000,
                    availability: 1,
                    min: 0,
                    max: 1,
                },
            ];
            return updated;
        case AddEditOptionTypes.RemoveOption: {
            const index = updated?.opts?.findIndex?.((opt) => opt.id === payload);
            if (index !== -1) {
                updated?.opts?.splice?.(index, 1);
            }
            return updated;
        }
        case AddEditOptionTypes.SwapOptionPostions: {
            const list = _.cloneDeep(updated.opts);
            const [removed] = list.splice(payload.source?.index, 1);
            list.splice(payload.destination?.index ?? 0, 0, removed);
            updated.opts = list.map((item, i) => ({
                ...item,
                id: i + 1,
            }));
            return updated;
        }
        default:
            return updated;
    }
};

const defaultState = {
    nm: {},
    min: 1,
    max: 1,
    type: "multiple",
    opts: [],
};

function useAddEditOption(): Record<string, unknown> {
    const [addEditOptionState, dispatchAddEditOptionState]: any[] = useReducer<any>(reducer, defaultState);

    return {
        addEditOptionState,
        dispatchAddEditOptionState,
    };
}

export default useAddEditOption;
