import React, { useState } from "react";
import _ from "lodash";
import dH from "../helper";
import { RootState } from "../../../../../../app/reducer";
import { SelectOption } from "../../../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, Button, Modal } from "antd";
import { getCategoriesAsOptions } from "../../../../../categories/helper";
import { getProductsAsOptions } from "../../../../../products/helper";
import { useDispatch, useSelector } from "react-redux";
import { setAutoDiscounts, setUserModal } from "../../../../../../slices/discounts";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
    language: state?.setting?.lan,
});

function ShippingModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const discounts = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts);
    const { categories, language, products } = useSelector(categoriesPageSelector);
    const rewards = _.get(discounts, "disc_new_user.data.rewards", NaN);
    const discount = _.get(discounts, "disc_new_user.data.discount", NaN);
    const discount_type = _.get(discounts, "disc_new_user.data.discount_type", NaN);
    const scope = _.get(discounts, "disc_new_user.data.scope", NaN);
    const product_skus = _.get(discounts, "disc_new_user.data.product_skus", []);
    const category_ids = _.get(discounts, "disc_new_user.data.category_ids", []);
    const [conditionType, setConditionType] = useState<number>(
        _.isEmpty(category_ids) ? (_.isEmpty(product_skus) ? dH.ALL : dH.PRODUCT) : dH.CATEGORY
    );
    const ALL = 1;

    const discountTypes: SelectOption[] = [
        { value: dH.PERCENT, label: intl.formatMessage({ id: "percent" }) },
        { value: dH.AMOUNT, label: intl.formatMessage({ id: "amount" }) },
    ];

    const applyTypes: SelectOption[] = [
        { value: dH.INCLUDE, label: intl.formatMessage({ id: "include" }) },
        { value: dH.EXCLUDE, label: intl.formatMessage({ id: "exclude" }) },
    ];

    const APPLY_TO_ALL = 0;
    const EXCLUDE_PRODUCTS = 1;
    const EXCLUDE_CATEGORIES = 2;
    const condition: SelectOption[] = [
        { value: APPLY_TO_ALL, label: intl.formatMessage({ id: "apply_to_all" }) },
        { value: EXCLUDE_PRODUCTS, label: intl.formatMessage({ id: "apply_selected_products" }) },
        { value: EXCLUDE_CATEGORIES, label: intl.formatMessage({ id: "apply_selected_categories" }) },
    ];

    const saveDiscount = () => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        if (discount) {
            if (!scope) {
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.scope`, value: dH.INCLUDE }));
            }
            if (!discount_type) {
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.discount_type`, value: dH.PERCENT }));
            }
            if (conditionType === dH.ALL) {
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.category_ids`, value: undefined }));
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.product_skus`, value: undefined }));
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.scope`, value: ALL }));
            }
            if (conditionType === dH.PRODUCT) {
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.category_ids`, value: undefined }));
            }
            if (conditionType === dH.CATEGORY) {
                dispatch(setAutoDiscounts({ name: `disc_new_user.data.product_skus`, value: undefined }));
            }
            dispatch(setUserModal(false));
        } else {
            Modal.error({ title: intl.formatMessage({ id: "discount_missing" }), content: req });
        }
    };

    const setUser = (name: string, value: any) => {
        dispatch(setAutoDiscounts({ name: `disc_new_user.data.${name}`, value }));
    };

    const renderDiscountDetails = (
        <div className="d-flex flex-column discount-details">
            <div className="filter-subtitle top">
                <FormattedMessage id="user_reward_points" />
            </div>
            <input
                className="custom-input-textfield number-input"
                type="number"
                value={rewards ?? NaN}
                onChange={(e) => setUser("rewards", e.target.value)}
            />
        </div>
    );

    const renderType = (
        <React.Fragment>
            <div className="d-flex discount-details">
                <div className="d-flex flex-column discount-details-field discount-details-mr">
                    <div className="filter-subtitle">
                        <FormattedMessage id="discount_type" />
                    </div>
                    <Select
                        className="filter-input half-width d-flex align-items-center"
                        value={discount_type ? discount_type : dH.PERCENT}
                        onChange={(e) => setUser("discount_type", e)}
                    >
                        {discountTypes.map((option: SelectOption) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="d-flex flex-column discount-details-field">
                    <div className="filter-subtitle">
                        <FormattedMessage id="new_user_discount" />
                    </div>
                    <input
                        className="custom-input-textfield number-input"
                        type="number"
                        value={discount ?? NaN}
                        onChange={(e) => setUser("discount", e.target.value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const renderConditionSelect = (
        <div className="d-flex flex-column discount-details">
            <div className="filter-subtitle">
                <FormattedMessage id="discount_rule" />
            </div>
            <Select
                className="filter-input d-flex auto-h align-items-center "
                value={conditionType ?? 0}
                onChange={(e) => setConditionType(e)}
            >
                {condition.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    const renderCategorySelect = () => {
        const getCategories = getCategoriesAsOptions(categories, language);
        return (
            <React.Fragment>
                <div className="filter-subtitle">
                    <FormattedMessage id="discount_condition" />
                </div>
                <div className="filter-subtitle mt-zero d-flex w100">
                    <Select
                        className="filter-input d-flex auto-h align-items-center short-select"
                        value={scope ? scope : dH.INCLUDE}
                        onChange={(e) => setUser("scope", e)}
                    >
                        {applyTypes.map((option: SelectOption) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <div style={{ lineHeight: "42px" }}>
                        <FormattedMessage id="selected_categories" />
                    </div>
                </div>
                <div className="discount-details">
                    <Select
                        mode="multiple"
                        className="multi-input d-flex align-items-center discount-details-field"
                        value={category_ids ?? []}
                        onChange={(e) => setUser("category_ids", e)}
                    >
                        {getCategories.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </React.Fragment>
        );
    };

    const renderProductSelect = () => {
        const getProducts = getProductsAsOptions(products, language);
        return (
            <React.Fragment>
                <div className="filter-subtitle">
                    <FormattedMessage id="discount_condition" />
                </div>
                <div className="filter-subtitle mt-zero d-flex w100">
                    <Select
                        className="filter-input d-flex auto-h align-items-center short-select"
                        value={scope ? scope : dH.INCLUDE}
                        onChange={(e) => setUser("scope", e)}
                    >
                        {applyTypes.map((option: SelectOption) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <div style={{ lineHeight: "42px" }}>
                        <FormattedMessage id="selected_products" />
                    </div>
                </div>
                <div className="discount-details">
                    <Select
                        mode="multiple"
                        className="multi-input d-flex align-items-center discount-details-field"
                        value={product_skus ?? []}
                        onChange={(e) => setUser("product_skus", e)}
                    >
                        {getProducts.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </React.Fragment>
        );
    };

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => dispatch(setUserModal(false))}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button type="primary" onClick={() => saveDiscount()}>
                <FormattedMessage id="update_discount" />
            </Button>
        </div>
    );

    const renderCreateForm = () => (
        <div className="filter-modal d-flex flex-column justify-content-start align-items-start">
            {renderDiscountDetails}
            {renderType}
            {renderConditionSelect}
            {conditionType === dH.PRODUCT ? renderProductSelect() : null}
            {conditionType === dH.CATEGORY ? renderCategorySelect() : null}
            {renderActions}
        </div>
    );

    return renderCreateForm();
}

export default ShippingModal;
