import React from "react";
import { View, Text } from "@react-pdf/renderer";

const storeNameURL = (
    styles: React.CSSProperties | any,
    props: { storeName: string, storeURL: string | any }
): JSX.Element => (
    <React.Fragment>
        <View style={styles.storeName}>
            <Text>{props.storeName}</Text>
        </View>
        <View style={styles.websiteURL}>
            <Text>{props.storeURL}</Text>
        </View>
    </React.Fragment>
);
export default storeNameURL;
