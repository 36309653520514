import { Button, Modal } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import { getStoreAvailability, updateStoreAvailability } from "./../../../../services/store-availability";
import { setEditIndex, setShowEditModal, addNewCode, cancelAddCode } from "./../../../../slices/store-availability";
import helper from "../../../../util/helper";
import HoursSelect from "./hours-select";
import "./../../index.scss";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/availabilitySettings", breadcrumbName: "availability_setting" },
    ],
};

const App = (): JSX.Element | null => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const availability = useSelector((state: RootState) => state.storeAvailability);
    const showEditModal = _.get(availability, "showEditModal", 0);
    const addingCode = _.get(availability, "addingCode", 0);
    const tableLabels = ["code", "name", "hours", "actions"];
    const tableWidths = ["10%", "30%", "45%", "15%"];

    useEffect(() => {
        dispatch(getStoreAvailability());
    }, [dispatch]);

    const getAvailTable = (labels: string[], widths: string[]) => {
        const deleteCodeHandler = (code: any) => {
            Modal.confirm({
                title: intl.formatMessage({ id: "delete_availability_code" }),
                content: intl.formatMessage({ id: "are_you_sure" }),
                onOk() {
                    dispatch(updateStoreAvailability({ action: 4, code }));
                },
                okType: "danger",
            });
        };

        const renderFooter = () => {
            return (
                <React.Fragment>
                    <div className="availTable-footer">
                        <Button
                            type="primary"
                            size="large"
                            style={{ margin: "5px" }}
                            onClick={() => {
                                dispatch(addNewCode());
                            }}
                        >
                            <FormattedMessage id="add_availability_code" />
                        </Button>
                    </div>
                </React.Fragment>
            );
        };

        return (
            <React.Fragment>
                <div className="white-single-block-table-header">
                    {labels.map((label, i) => (
                        <div
                            key={`availTable-label-key-${i}`}
                            className="availTable-label"
                            style={{ width: `${widths[i]}`, justifyContent: i === 3 ? "center" : "flex-start" }}
                        >
                            <FormattedMessage id={label ?? " "} />
                        </div>
                    ))}
                </div>
                {availability?.timeSettings.length > 0 && Array.isArray(availability.timeSettings)
                    ? availability.timeSettings.map((codeInfo: any, index: any) => (
                          <div
                              key={`availTable-row-container-${codeInfo?.code}-${index}`}
                              className="availTable-row-container clickable-row-parent"
                          >
                              <div
                                  className="clickable-row"
                                  onClick={() => {
                                      if (codeInfo.code !== "A")
                                          dispatch(setEditIndex(index)) && dispatch(setShowEditModal(1));
                                  }}
                              ></div>
                              <div className="availTable-col" style={{ width: `${widths[0]}` }}>
                                  {codeInfo.code}
                              </div>
                              <div className="availTable-col" style={{ width: `${widths[1]}` }}>
                                  {codeInfo.name}
                              </div>
                              <div
                                  className="availTable-col"
                                  style={{ width: `${widths[2]}` }}
                                  dangerouslySetInnerHTML={{ __html: helper.formatDayAndHours(codeInfo) }}
                              />
                              {codeInfo.code === "A" ? null : (
                                  <div className="availTable-col-C" style={{ width: `${widths[3]}` }}>
                                      <Button
                                          type="default"
                                          className="text-on-white-background"
                                          style={{ margin: "5px" }}
                                          onClick={() => dispatch(setEditIndex(index)) && dispatch(setShowEditModal(1))}
                                      >
                                          <FormattedMessage id="update" />
                                      </Button>
                                      <Button
                                          danger
                                          style={{ margin: "5px" }}
                                          onClick={() => deleteCodeHandler(codeInfo.code)}
                                      >
                                          <FormattedMessage id="delete" />
                                      </Button>
                                  </div>
                              )}
                          </div>
                      ))
                    : null}
                {renderFooter()}
            </React.Fragment>
        );
    };

    const onAddCancel = () => {
        dispatch(setShowEditModal(0));
        addingCode && dispatch(cancelAddCode());
    };

    const renderEditModal = () => (
        <Modal
            visible={showEditModal}
            title={intl.formatMessage({ id: "edit_availability" })}
            width="75vw"
            footer={null}
            onCancel={() => onAddCancel()}
        >
            <HoursSelect />
        </Modal>
    );

    const renderDescription = () => (
        <div className="white-single-block">
            <FormattedMessage id="availability_description" />
        </div>
    );

    return !_.isEmpty(availability) ? (
        <Wrapper helmet={{ title: "availability_setting" }} breadcrumb={breadcrumb}>
            {renderDescription()}
            {getAvailTable(tableLabels, tableWidths)}
            {renderEditModal()}
        </Wrapper>
    ) : null;
};

export default App;
