import { Button, Modal } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AddressForm from "../new-customer-page/address-info-form";
import { updateCustomerAddress } from "../../../../services/customers";
import { validateAddress } from "../../helper";
import { getCustomerId } from "./helper";

class EditAddressDetailModal extends Component {
    getCid = (props = this.props) => getCustomerId(props);

    shouldOpen = () => this.props?.parentState?.openEditDetail;

    closeModal = () => {
        let closeObject = { openEditDetail: false };
        if (this.props.onClose) this.props.onClose();
        this.props.setParentState(closeObject);
    };

    validateAddress = () => {
        const defaultAddress = this.props.parentState?.default_address || {};
        const { errorFields, valid, withDefaultAddress } = validateAddress(defaultAddress);
        this.props.setParentState(errorFields);
        return valid && withDefaultAddress;
    };

    renderSaveButton = () => {
        return (
            <div className="edit-customer-address-detail">
                <Button
                    onClick={() => {
                        if (this.validateAddress()) {
                            this.props.updateCustomerAddress({
                                customer_id: this.getCid(),
                                ...this.props.parentState?.default_address,
                            });
                            this.closeModal();
                        }
                    }}
                    type="primary"
                    size="large"
                >
                    <FormattedMessage id="save" />
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Modal
                destroyOnClose
                onCancel={() => this.closeModal()}
                visible={this.shouldOpen()}
                title={null}
                footer={null}
            >
                <div className="edit-customer-address-detail-modal">
                    <AddressForm
                        powerByBottomMargin={5}
                        formState={this.props.parentState}
                        setFormState={(values) => {
                            this.props.setParentState(values);
                        }}
                        setFormCustomerState={(values) => this.props.setParentState(values)}
                    />
                    {this.renderSaveButton()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

const mapDispatchToProps = {
    updateCustomerAddress,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditAddressDetailModal)));
