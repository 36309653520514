import React, { Component } from "react";
import _helper from "../../../util/helper";
import _config from "../../../config";
import classnames from "classnames";
import _locale from "../../../locale";
import "./_print-view.scss";
import _ from "lodash";
import moment from "moment";
import { QRCode } from "react-qrcode-logo";
import { getStoreTimeZone } from "../../liveorder/helper";
import {
    getItemOptionsArray,
    getFormattedDistance,
    getOrderMenuString,
    getOrderRewardPoints,
    getOrderRewardBalance,
    getOrderStatusNumber,
    formatOrderId,
} from "../../order-history/helper";
import ReactDOM from "react-dom";
import OrderDetailPrintPortal from "./order-detail-print-portal";
import { formatPickupLocation, requestUtensil } from "../helper";
import {
    RECEIPT_TYPE,
    printRequestUtil,
    getCustomerReceiptDueTime,
    getChefReceiptDueTime,
    SPECIAL_REQUEST_POSITION_OPTIONS,
    SPECIAL_REQUEST_POSITION_KEY,
} from "./print-helper";
import config from "../../../config";
import { store } from "app/store";

export const ORDER_PRINT_STATUS_NEW_PAID = "new_paid";
export const ORDER_PRINT_STATUS_NEW_NOT_PAID = "new_not_paid";
export const ORDER_PRINT_STATUS_ACCEPTED = "order_accepted";
export const ORDER_PRINT_STATUS_UPDATED = "order_update";
export const ORDER_PRINT_STATUS_CANCEL = "order_cancel";
export const ORDER_PRINT_STATUS_COMPLETE = "order_complete";
export const ORDER_PRINT_STATUS_START_PREPARING = "order_start_preparing";
export const ORDER_PRINT_STATUS_PENDING_CUSTOMER = "order_pending_customer_confirm";
export const ORDER_PRINT_STATUS_ALL = "all";

const OrderDetailPrintView = class OrderDetailPrintView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templates: [],
            shopName: "GoopterQA Test",
            storeType: 1,
            shopPhone: "(xxx) xxx-xxxx",
            shopAddress: {
                unit: 123,
                buzz: 1234,
                zipcd: "PostCode",
                city: "City",
                street: "4110 xxxx St",
                region: "Provice",
                cntry: "CA",
            },
            shopVAT: "xxxxxxx",
            orderId: "233422",
            deliveryMethod: "",
            dueTime: "",
            orderTime: "xxxx-xx-xx xx:xx",
            customerName: "default Name",
            customerPhone: "(xxx) xxx-xxxx",
            deliAddress: {
                unit: 123,
                buzz: 1234,
                zipcd: "V63w36",
                city: "richmond",
                street: "8888 royak drive mall",
                region: "BC",
                cntry: "CA",
            },
            partySize: 0,
            tableNumber: "",
            paymentMethod: 1,
            creditCard: {},
            currency: "CAD",
            orderItems: [
                {
                    nm: {
                        en: "cool product",
                        zh: "cool productszzzz",
                    },
                    pid: 122334,
                    pc: 5.26,
                    cnt: 2,
                    amt: 10.52,
                    opts: null,
                },
                {
                    nm: {
                        en: "cool product wif opt",
                        zh: "cool productszzzz wif opt",
                    },
                    pid: 122334,
                    pc: 5.26,
                    cnt: 2,
                    amt: 10.52,
                    opts: [
                        {
                            nm: {
                                en: "size",
                                zh: "sizaaa",
                            },
                            opts: [
                                {
                                    nm: {
                                        en: "small",
                                        zh: "smallaaa",
                                    },
                                },
                            ],
                        },
                        {
                            nm: {
                                en: "color",
                                zh: "coloraaaa",
                            },
                            opts: [
                                {
                                    nm: {
                                        en: "orange",
                                        zh: "olangee",
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
            subtotal: 2.2,
            discount: 0,
            deliveryFee: 3,
            storeSurcharge: 0,
            storeSurchargeLabel: { en: "Surcharge" },
            orderSurcharge: 0,
            orderSurchargeLabel: { en: "Surcharge" },
            orderSurchargeInTotal: false,
            relatedOrderId: null,
            itemTotal: 2.2,
            totalBeforeTax: 5,
            tax: 1,
            tips: 1,
            orderTotal: 6.2,
            giftCardPayment: 0,
            pointsPayment: 0,
            totalRefunded: 0,
            totalDue: 6.2,
            comment: "",
            orderStatus: 1,
            orderStatusChanged: "",
            shopUrl: "",
            mainLan: "en",
            subLan: "",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginright: 0,
            receiptTemplates: [],
            styling: {
                id: 1,
                name: "Regular",
                header: { hide: false },
                footer: { hide: false },
                prices: { hide: false },
                shopName: {
                    size: "large",
                    bold: true,
                    position: "center",
                    hide: false,
                },
                shopPhone: {
                    size: "med",
                    bold: false,
                    position: "center",
                    hide: false,
                },
                shopAddress: {
                    size: "med",
                    bold: false,
                    position: "center",
                    hide: false,
                },
                shopHST: {
                    size: "med",
                    bold: false,
                    position: "center",
                    hide: false,
                },
                orderId: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                deliveryMethod: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                dueTime: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                orderTime: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                customerName: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                customerPhone: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                deliAddress: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                partySize: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                paymentMethod: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                orderItem: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                orderItemOpts: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                subtotal: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                deliveryFee: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                totalBeforeTax: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                tax: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                tips: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                orderTotal: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                totalDue: {
                    size: "large",
                    bold: true,
                    position: "default",
                    hide: false,
                },
                comment: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
                shopUrl: {
                    size: "med",
                    bold: false,
                    position: "default",
                    hide: false,
                },
            },
        };
    }

    componentDidMount() {
        const paramsCopy = _.cloneDeep(this.props.params);

        //clean state
        let nullValue = {};

        const notClearKeys = ["receiptTemplates", "styling"];

        Object.keys(this.state).forEach((key) => {
            if (!notClearKeys.includes(key)) {
                nullValue[key] = "";
            }
        });

        // In store admin, Since we can't change the print language setting, the mainLan is always undefined from the state.
        // so just choose the current admin store language from the state.
        const state = store.getState();
        this.setState({
            ...nullValue,
            ...paramsCopy,
            mounted: true,
            mainLan: _.get(state, "setting.lan", "en"),
        });
    }

    createHeader = () => {
        let { styling, mainLan } = this.state;
        const getStoreAddress1 = () => {
            return _helper.formatAddress(this.state.shopAddress, {
                without: ["city", "region", "cntry", "zipcd"],
                size: 1,
                lan: mainLan,
            })[0];
        };

        const getStoreAddress2 = () => {
            return _helper.formatAddress(this.state.shopAddress, {
                without: ["buzz", "street", "unit", "cntry"],
                size: 1,
                lan: mainLan,
            })[0];
        };

        const getStoreName = () => {
            const storeNamesObj = _.get(this.state, "storeData.general_info.store_nm", {});
            return _helper.getTransString(storeNamesObj, mainLan);
        };

        if (!styling?.header?.hide) {
            return (
                <div className={`header table-wrapper ${this.getStyle("section_spacing")} ${this.getStyle("header")}`}>
                    {[
                        {
                            valueKey: "shopName",
                            styleKey: "shop_name",
                            value: getStoreName(),
                        },
                        { valueKey: "shopPhone", styleKey: "shop_phone" },
                        { valueKey: "shopAddress", styleKey: "shop_address" },
                        { valueKey: "shopVAT", styleKey: "shop_vat" },
                    ].map((headerObj) => {
                        if (!styling?.[headerObj.valueKey]?.hide && styling?.[headerObj.valueKey]) {
                            if (headerObj.valueKey === "shopAddress") {
                                return (
                                    <div key={`header-line-item-${headerObj.styleKey}`}>
                                        <div className={this.getStyle(headerObj.styleKey)}>{getStoreAddress1()}</div>
                                        <div className={this.getStyle(headerObj.styleKey)}>{getStoreAddress2()}</div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={`header-line-item-${headerObj.styleKey}`}
                                        className={this.getStyle(headerObj.styleKey)}
                                    >
                                        {headerObj.value || this.state[headerObj.valueKey]}
                                    </div>
                                );
                            }
                        }
                        //default
                        return <div key={`header-line-item-${headerObj.styleKey}`}></div>;
                    })}
                </div>
            );
        }
    };

    createOrderChangeStatus = () => {
        let { mainLan, orderStatus, orderStatusChanged, styling } = this.state;
        if (orderStatus === 5 && Number(styling.type) === 11) {
            //5: cancelled, 11: resturant chef
            orderStatusChanged = ORDER_PRINT_STATUS_CANCEL;
        }
        if (orderStatusChanged === ORDER_PRINT_STATUS_UPDATED || orderStatusChanged === ORDER_PRINT_STATUS_CANCEL) {
            return (
                <div
                    className={classnames({
                        "line-item": true,
                        "extra-large-text": true,
                        bold: true,
                    })}
                >
                    <span className="double-under-line">
                        {_helper.getPrinterTransString(mainLan, orderStatusChanged)}
                    </span>
                </div>
            );
        }
    };

    renderOrderMenus = () => {
        const order = _.get(this.state, "orderDetail.data", {});
        const lan = this.state.mainLan;
        const menuStr = getOrderMenuString(order, lan);
        const menuTitle = _helper.getPrinterTransString(lan, "menu");
        return menuStr ? (
            <div
                className={classnames({
                    [this.getStyle("order_menu")]: true,
                    "print-receipt-order-menu": true,
                })}
            >
                {menuTitle}: {menuStr}
            </div>
        ) : null;
    };

    renderOrderItems = () => {
        let { orderItems, mainLan, subLan, styling, orderStatus, currency, hide_option_title } = this.state;
        const shouldCross = Number(orderStatus) === 5 && Number(styling.type) === RECEIPT_TYPE.chef; //5: cancelled,
        const showOptionTitle = !hide_option_title && Number(styling.type) !== RECEIPT_TYPE.label;
        const showCount = Number(styling.type) !== RECEIPT_TYPE.label;
        const showPrice = !styling.prices.hide;
        const nameWidth = ({ showPrice = true, showCount = true }) => {
            let result = 100;
            if (showCount) result = result - 10;
            if (showPrice) result = result - 25;
            return `${result}%`;
        };
        const renderItemMainLan = (item) => {
            return (
                <div
                    className={classnames({
                        "line-item": true,
                        [this.getStyle("order_item")]: true,
                    })}
                >
                    {showCount ? (
                        <span
                            className={classnames({
                                "item-count": true,
                                bold: item.cnt > 1,
                                cross: shouldCross,
                            })}
                        >
                            {item.cnt}
                        </span>
                    ) : null}
                    <span
                        style={{ width: nameWidth({ showCount, showPrice }) }}
                        className={classnames({
                            cross: shouldCross,
                            "item-name": true,
                        })}
                    >
                        {_helper.getTransString(item.nm, mainLan)}
                    </span>
                    {showPrice ? (
                        <span className="item-price">
                            {Number(styling.type) === RECEIPT_TYPE.label ? (
                                <span>{_helper.getCurrencyFormattedString(item.pc, currency)}</span>
                            ) : (
                                <span>{_helper.getCurrencyFormattedString(item.amt, currency)}</span>
                            )}
                        </span>
                    ) : null}
                </div>
            );
        };
        const renderItemSubLan = (item) => {
            return subLan ? (
                <div
                    className={classnames({
                        "line-item": true,
                        [this.getStyle("order_item")]: true,
                    })}
                >
                    {showCount ? (
                        <span
                            className={classnames({
                                "item-count": true,
                            })}
                        >
                            {_.get(item, "cnt", "").toString().replace(/[0-9]/g, "\xa0\xa0")}
                        </span>
                    ) : null}
                    <span
                        style={{ width: nameWidth({ showCount, showPrice }) }}
                        className={classnames({
                            cross: shouldCross,
                            "item-name": true,
                            "sub-lan": true && Number(styling.type) !== RECEIPT_TYPE.label,
                        })}
                    >
                        {_helper.getTransString(item.nm, subLan)}
                    </span>
                    {showPrice ? (
                        <span className="item-price">
                            {Number(styling.type) === RECEIPT_TYPE.label ? (
                                <span>{_helper.getCurrencyFormattedString(item.pc, currency)}</span>
                            ) : (
                                <span>{_helper.getCurrencyFormattedString(item.amt, currency)}</span>
                            )}
                        </span>
                    ) : null}
                </div>
            ) : null;
        };
        const renderItemOptions = (item) => {
            const mainOptionsValues = getItemOptionsArray(item.opts, mainLan);
            const subOptionsValues = getItemOptionsArray(item.opts, subLan);
            return mainOptionsValues.length
                ? mainOptionsValues.map((opt, i) => {
                      return (
                          <div key={`order-item-options-${opt.nm}-${opt.id}`}>
                              <div
                                  className={classnames({
                                      "product-option": true,
                                      "product-option-wrapper": Number(styling.type) !== RECEIPT_TYPE.label,
                                      "product-option-wrapper-small": Number(styling.type) === RECEIPT_TYPE.label,
                                      "product-option-spacing": true,
                                      [this.getStyle("order_item_opts")]: true,
                                      [this.getStyle("order_item_spacing")]: true,
                                  })}
                              >
                                  {showCount ? <div className="item-count">&nbsp;</div> : null}
                                  <div
                                      style={{ width: nameWidth({ showCount, showPrice: false }) }}
                                      className={classnames({
                                          cross: shouldCross,
                                          "item-name": true,
                                      })}
                                  >
                                      -&nbsp;
                                      {showOptionTitle ? <span>{opt.nm}:&nbsp;</span> : null}
                                      <span>{opt.optStr}</span>
                                  </div>
                              </div>
                              {subLan ? (
                                  <div
                                      className={classnames({
                                          "product-option": true,
                                          "product-option-wrapper": Number(styling.type) !== RECEIPT_TYPE.label,
                                          "product-option-wrapper-small": Number(styling.type) === RECEIPT_TYPE.label,
                                          "product-option-spacing": true,
                                          [this.getStyle("order_item_opts")]: true,
                                          [this.getStyle("order_item_spacing")]: true,
                                          "sub-lan": true,
                                      })}
                                  >
                                      {showCount ? <div className="item-count">&nbsp;</div> : null}
                                      <div
                                          style={{ width: nameWidth({ showCount, showPrice: false }) }}
                                          className={classnames({
                                              cross: shouldCross,
                                              "item-name": true,
                                          })}
                                      >
                                          {showOptionTitle ? (
                                              <span>-{_.get(subOptionsValues, `${i}.nm`)}: </span>
                                          ) : null}
                                          <span>{_.get(subOptionsValues, `${i}.optStr`)}</span>
                                      </div>
                                  </div>
                              ) : null}
                          </div>
                      );
                  })
                : null;
        };
        if (orderItems.length) {
            return orderItems.map((item, itemIndex) => {
                return (
                    <div
                        key={`order-item-options-${item.nm}-${itemIndex}`}
                        className={classnames({
                            "order-item-wrapper": true,
                            [this.getStyle("order_item_spacing")]: true,
                        })}
                    >
                        {renderItemMainLan(item)}
                        {renderItemSubLan(item)}
                        {renderItemOptions(item)}
                    </div>
                );
            });
        }
    };

    showOrderSurchargeInTotal = () => {
        return this.state.orderSurchargeInTotal;
    };

    showItemTotal = () => {
        const { orderSurcharge, storeSurcharge, orderSurchargeInTotal } = this.state;
        return (orderSurcharge && orderSurchargeInTotal) || storeSurcharge;
    };

    renderOrderIdLine = () => {
        const { storeType, orderId, orderItemId, deliveryMethod, mainLan, subLan } = this.state;
        return (
            <div className="line-item">
                <div className={this.getStyle("order_id")}>
                    #{this.getReformmatedOrderId(orderId)}
                    {orderItemId && deliveryMethod ? (
                        <span>
                            {" "}
                            ·{" "}
                            <span>
                                {_helper.getPrinterTransString(
                                    mainLan,
                                    _helper.reviseDeliveryMethod(
                                        storeType,
                                        _config.SHIPPING_MAPPING_TO_TEXT[deliveryMethod]
                                    )
                                )}
                            </span>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                {orderItemId ? (
                    <div className={`line-item-value ${this.getStyle("order_id")}`}>{orderItemId}</div>
                ) : deliveryMethod ? (
                    <div className={`line-item-value ${this.getStyle("order_type")}`}>
                        <span>
                            {_helper.getPrinterTransString(
                                mainLan,
                                _helper.reviseDeliveryMethod(
                                    storeType,
                                    _config.SHIPPING_MAPPING_TO_TEXT[deliveryMethod]
                                )
                            )}
                        </span>
                        {subLan ? (
                            <span className="sub-lan">
                                {" "}
                                (
                                {subLan
                                    ? _helper.getPrinterTransString(
                                          subLan,
                                          _helper.reviseDeliveryMethod(
                                              storeType,
                                              _config.SHIPPING_MAPPING_TO_TEXT[deliveryMethod]
                                          )
                                      )
                                    : ""}
                                )
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    createOrderInfo = (type) => {
        let {
            mainLan,
            deliveryMethod,
            currency,
            orderTime,
            customerName,
            customerPhone,
            staffName,
            tableNumber,
            partySize,
            deliAddress,
            pickupAddress,
            subtotal,
            discount,
            deliveryFee,
            totalBeforeTax,
            tax,
            taxes,
            tips,
            totalPaid,
            orderTotal,
            itemTotal,
            giftCardPayment,
            pointsPayment,
            storeSurchargeLabel,
            orderSurchargeLabel,
            storeSurcharge,
            orderSurcharge,
            styling,
            delvieryZoneName,
            storeData,
            orderDetail,
            creditCard,
            paymentMethod,
        } = this.state;
        let arr;

        const getAddressName = (address) => {
            return _.get(address, "fn", "");
        };

        const getAddressPhone = (address) => {
            const tn = _.get(address, "tn", "");
            return tn ? _helper.formatPhone(tn) : "";
        };

        const getAddress1 = (address) => {
            return _helper.formatAddress(address, {
                without: ["city", "region", "cntry", "zipcd", "buzz"],
                size: 1,
                lan: mainLan,
            })[0];
        };

        const getAddress2 = (address) => {
            return _helper.formatAddress(address, {
                without: ["buzz", "street", "unit", "cntry"],
                size: 1,
                lan: mainLan,
            })[0];
        };

        const getAddress3 = (address) => {
            return _helper.formatAddress(address, {
                without: ["city", "region", "cntry", "zipcd", "street", "unit"],
                size: 1,
                lan: mainLan,
            })[0];
        };

        const getETALabel = () => {
            if (Number(deliveryMethod) === 1) {
                return "delivery_time";
            }
            if (Number(deliveryMethod) === 2) {
                return "pickup_time";
            }
            return " ";
        };

        const getCustomerDueTime = () => {
            const withDueTime = Number(deliveryMethod) === 1 || Number(deliveryMethod) === 2;
            const duetime = getCustomerReceiptDueTime(orderDetail, storeData, "en");
            const dueTimeArray = _helper.isString(duetime) ? [duetime] : Array.isArray(duetime) ? duetime : [];
            const result = withDueTime ? dueTimeArray : "";
            return result;
        };

        const getChefDueTime = () => {
            const withDueTime = Number(deliveryMethod) === 1 || Number(deliveryMethod) === 2;
            const dueTimeStr = getChefReceiptDueTime(orderDetail, "en");
            const result = withDueTime ? dueTimeStr : "";
            return result;
        };

        if (String(type) === "info") {
            if (Number(styling.type) === 11) {
                //chef
                arr = [
                    {
                        name: "due_time",
                        value: _.get(getChefDueTime(), 0, ""),
                        styleKey: "due_time",
                    },
                    {
                        name: "",
                        value: _.get(getChefDueTime(), 1, ""),
                        styleKey: "due_time",
                    },
                    {
                        name: "order_time",
                        value: _helper.utcToLocal(orderTime, {
                            type: "short",
                            time: true,
                            date: true,
                            asap: true,
                            local: true,
                            timeZone: getStoreTimeZone(storeData),
                        }),
                        styleKey: "order_time",
                    },
                    {
                        name: "customer",
                        value: customerName,
                        styleKey: "customer_name",
                    },
                    {
                        name: "tel",
                        value: _helper.formatPhone(customerPhone),
                        styleKey: "customer_phone",
                    },
                    {
                        name: "party_size",
                        value: Number(deliveryMethod) === 3 ? partySize : null,
                        styleKey: "party_size",
                    },
                    {
                        name: "table_number",
                        value: Number(deliveryMethod) === 3 ? tableNumber : null,
                        styleKey: "table_num",
                    },
                    {
                        name: "payment",
                        value: _helper.getPaymentStringWithCreditCardInfo(creditCard, paymentMethod, mainLan),
                        styleKey: "payment_method",
                    },
                    {
                        name: "server",
                        value: staffName,
                        styleKey: "staff_name",
                    },
                ];
            } else {
                arr = [
                    {
                        name: "order_time",
                        value: _helper.utcToLocal(orderTime, {
                            type: "short",
                            time: true,
                            date: true,
                            asap: true,
                            local: true,
                            timeZone: getStoreTimeZone(storeData),
                        }),
                        styleKey: "order_time",
                    },
                    {
                        name: "customer",
                        value: customerName,
                        styleKey: "customer_name",
                    },
                    {
                        name: "tel",
                        value: _helper.formatPhone(customerPhone),
                        styleKey: "customer_phone",
                    },
                    {
                        name: getETALabel(),
                        value: _.get(getCustomerDueTime(), 0, ""),
                        styleKey: "due_time",
                    },
                    {
                        name: "",
                        value: _.get(getCustomerDueTime(), 1, ""),
                        styleKey: "due_time",
                    },
                    {
                        name: "party_size",
                        value: Number(deliveryMethod) === 3 ? partySize : null,
                        styleKey: "party_size",
                    },
                    {
                        name: "table_number",
                        value: Number(deliveryMethod) === 3 ? tableNumber : null,
                        styleKey: "table_num",
                    },
                    {
                        name: "delivery_location",
                        value: Number(deliveryMethod) === 1 ? delvieryZoneName : null,
                        styleKey: "delivery_address",
                    },
                    {
                        name: "addr_short",
                        value: Number(deliveryMethod) === 1 ? getAddress1(deliAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "deli-address-1",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 1 ? getAddress2(deliAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "deli-address-2",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 1 ? getAddress3(deliAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "deli-address-3",
                    },
                    {
                        name: "pickup",
                        value: Number(deliveryMethod) === 2 ? getAddressName(pickupAddress) : null,
                        styleKey: "delivery_address",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 2 ? getAddressPhone(pickupAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "pickup-address-1",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 2 ? getAddress1(pickupAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "pickup-address-2",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 2 ? getAddress2(pickupAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "pickup-address-3",
                    },
                    {
                        name: "",
                        value: Number(deliveryMethod) === 2 ? getAddress3(pickupAddress) : null,
                        styleKey: "delivery_address",
                        sideKey: "pickup-address-4",
                    },
                    {
                        name: "payment",
                        value: _helper.getPaymentStringWithCreditCardInfo(creditCard, paymentMethod, mainLan),
                        styleKey: "payment_method",
                    },
                    {
                        name: "server",
                        value: staffName,
                        styleKey: "staff_name",
                    },
                ];
            }
        } else if (String(type) === "totals") {
            arr = [
                {
                    name: "item_total",
                    value: this.showItemTotal() ? _helper.getCurrencyFormattedString(itemTotal, currency) : 0,
                    styleKey: "subtotal",
                },
                {
                    name: _helper.getTransString(storeSurchargeLabel, mainLan) || "store_surcharge",
                    value: _helper.getCurrencyFormattedString(storeSurcharge, currency),
                    styleKey: "surcharge",
                },
                {
                    name: _helper.getTransString(orderSurchargeLabel, mainLan) || "order_surcharge",
                    value: this.showOrderSurchargeInTotal()
                        ? _helper.getCurrencyFormattedString(orderSurcharge, currency)
                        : 0,
                    styleKey: "surcharge",
                },
                {
                    name: "subtotal",
                    value: _helper.getCurrencyFormattedString(subtotal, currency),
                    styleKey: "subtotal",
                    defaultShow: true,
                },
                {
                    name: "discount",
                    value: _helper.getCurrencyFormattedString(Math.abs(discount), currency, "-"),
                    styleKey: "discount",
                },
            ];

            if (Number(deliveryMethod) === 1) {
                arr.push({
                    name: "delivery_fee_with_distance",
                    nameValues: {
                        distance: getFormattedDistance(_.get(orderDetail, "data", {})),
                    },
                    value: _helper.getCurrencyFormattedString(deliveryFee, currency),
                    styleKey: "delivery_fee",
                    defaultShow: true,
                });
            }

            arr.push({
                name: "total_before_tax",
                value: subtotal !== totalBeforeTax ? _helper.getCurrencyFormattedString(totalBeforeTax, currency) : 0,
                styleKey: "total_before_tax",
            });

            //taxes objects
            if (!_.isEmpty(taxes)) {
                Object.keys(taxes).forEach((key) => {
                    const value = parseFloat(taxes[key]);
                    if (!Number.isNaN(value)) {
                        arr.push({
                            name: key,
                            value: _helper.getCurrencyFormattedString(value, currency),
                            styleKey: "tax",
                        });
                    }
                });
            } else {
                arr.push({
                    name: "tax",
                    value: _helper.getCurrencyFormattedString(tax, currency),
                    styleKey: "tax",
                });
            }

            arr.push(
                {
                    name: "tips",
                    value: _helper.getCurrencyFormattedString(tips, currency),
                    styleKey: "tips",
                },
                {
                    name: "giftcard_payment_with_giftcard",
                    nameValues: {
                        card: formatOrderId(_.get(orderDetail, "data.gc_cd")),
                    },
                    value: _helper.getCurrencyFormattedString(Math.abs(giftCardPayment), currency, "-"),
                    styleKey: "giftcard_payment",
                },
                {
                    name: "points_payment_with_points",
                    nameValues: {
                        points: _helper.formatNumber(_.get(orderDetail, "data.pt_num")),
                    },
                    value: _helper.getCurrencyFormattedString(Math.abs(pointsPayment), currency, "-"),
                    styleKey: "points_payment",
                },
                {
                    name: "order_total",
                    value: _helper.getCurrencyFormattedString(orderTotal, currency),
                    styleKey: "order_total",
                    defaultShow: true,
                }
            );
        } else if (String(type) === "paid-totals") {
            arr = [
                {
                    name: "total_paid",
                    value: _helper.getCurrencyFormattedString(Math.abs(totalPaid), currency, "-"),
                    styleKey: "total_paid",
                },
            ];
        }

        let result = arr.map((item) => {
            const { name, value, styleKey, sideKey, nameValues, defaultShow } = item;
            const withValue = value && String(value) !== _helper.getCurrencyFormattedString(0, currency);
            if (withValue || defaultShow) {
                return this.createLineItem(name, value, styleKey, sideKey, type, nameValues);
            }
            // default
            return null;
        });

        return result;
    };

    createLineItem = (name, value, styleKey, sideKey, type, nameValues = {}) => {
        let { mainLan } = this.state;

        let mainTrans = name ? _helper.getPrinterTransString(mainLan, name, nameValues) : "";

        const getValueStyle = () => {
            if (String(type) === "totals") {
                return {};
            }
            if (mainTrans) {
                return {};
            }
            return {
                width: "100vw",
            };
        };

        const getTitleStyle = () => {
            const isCN = mainLan === "zh" || mainLan === "zh-Hant";
            if (isCN && String(styleKey) === "due_time") {
                return {
                    marginRight: 19,
                };
            }
            if (!isCN && String(styleKey) === "due_time") {
                return {
                    marginRight: 0,
                };
            }
            return {};
        };

        if (value)
            return (
                <div
                    key={`line-item-${name}-${sideKey}`}
                    className={classnames({
                        "line-item": true,
                        [this.getStyle(styleKey)]: true,
                    })}
                >
                    {mainTrans ? (
                        <div style={getTitleStyle()} className="line-item-title">
                            {mainTrans}
                        </div>
                    ) : (
                        ""
                    )}
                    {Array.isArray(value) ? (
                        <div
                            style={getValueStyle()}
                            className={classnames({
                                "line-item-value-array": true,
                            })}
                        >
                            {value.map((ele, index) => (
                                <span key={`line-item-value-key-${index}`}> {ele} </span>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={getValueStyle()}
                            className={classnames({
                                "line-item-value": true,
                            })}
                        >
                            {value}
                        </div>
                    )}
                </div>
            );
    };

    getPrinterTransString = (lan, name, values = {}) => {
        let transObj = _locale.getIntlMessages(lan, "printer-page");

        const updateStrWithValues = (str) => {
            if (_helper.isString(str)) {
                let splitedStrs = {};
                let currentStr = "";
                for (const c of str) {
                    if (String(c) === "{" || String(c) === "}") {
                        splitedStrs[currentStr] = currentStr;
                        currentStr = "";
                    } else {
                        currentStr += c;
                    }
                }
                if (currentStr) splitedStrs[currentStr] = currentStr;
                Object.keys(values).forEach((key) => {
                    splitedStrs[key] = values[key];
                });
                return Object.values(splitedStrs).join("");
            }
            return str;
        };

        let result = "";
        if (transObj && transObj[name]) {
            result = transObj[name];
        } else {
            let enTransObj = _locale.getIntlMessages("en", "printer-page");
            if (enTransObj && enTransObj[name]) {
                result = enTransObj[name];
            } else {
                result = name;
            }
        }
        return updateStrWithValues(result);
    };

    getStyle = (key) => {
        let { styling } = this.state;
        let styleClass = [];

        if (styling[key]) {
            switch (styling[key]["size"]) {
                case 1:
                    styleClass.push("extra-small-text");
                    break;
                case 2:
                    styleClass.push("small-text");
                    break;
                case 3:
                    styleClass.push("med-text");
                    break;
                case 4:
                    styleClass.push("large-text");
                    break;
                case 5:
                    styleClass.push("extra-large-text");
                    break;
                default:
                    break;
            }

            if (styling[key]["hide"]) {
                styleClass.push("hide");
            }

            if (styling[key]["bold"]) {
                styleClass.push("bold");
            }

            if (styling[key]["alignment"]) {
                switch (styling[key]["alignment"]) {
                    case 1:
                        styleClass.push("left");
                        break;
                    case 2:
                        styleClass.push("center");
                        break;
                    case 3:
                        styleClass.push("right");
                        break;
                    default:
                        break;
                }
            }

            if (styling[key]["margin"]) {
                switch (styling[key]["margin"]) {
                    case 1:
                        styleClass.push("extra-small-margin");
                        break;
                    case 2:
                        styleClass.push("small-margin");
                        break;
                    case 3:
                        styleClass.push("med-margin");
                        break;
                    case 4:
                        styleClass.push("large-margin");
                        break;
                    case 5:
                        styleClass.push("extra-large-margin");
                        break;
                    default:
                        break;
                }
            }

            if (styling[key]["padding"]) {
                switch (styling[key]["padding"]) {
                    case 1:
                        styleClass.push("extra-small-padding");
                        break;
                    case 2:
                        styleClass.push("small-padding");
                        break;
                    case 3:
                        styleClass.push("med-padding");
                        break;
                    case 4:
                        styleClass.push("large-padding");
                        break;
                    case 5:
                        styleClass.push("extra-large-padding");
                        break;
                    default:
                        break;
                }
            }
        }

        return styleClass.join(" ");
    };

    getReformmatedOrderId = (orderNumber) => {
        const ORDER_NUMBER_LENGTH = 4;
        const id = orderNumber || " ";
        const dashLength = _helper.isString(orderNumber) ? orderNumber.replace(/[^-]/g, "").length : 0;
        const dashRightLength = _helper.isString(orderNumber) ? _.get(orderNumber.split("-"), "1", []).length : 0;
        const subStringLengt = ORDER_NUMBER_LENGTH + dashLength + dashRightLength;
        return id.length <= subStringLengt ? id : id.substring(id.length - subStringLengt);
    };

    renderReprintMessage = () => {
        const lan = _.get(this.state, "mainLan", "en");
        const type = _.get(this.state, "styling.type", 10);
        const ptDt = _.get(this.state, "printDt", 10);
        const status = _.get(this.state, "status", 10);
        const shouldPrintDate = Number(type) === 10 || Number(type) === 11;
        if (ptDt && shouldPrintDate && String(status) === "manual") {
            return (
                <div className="reprint-text">
                    <span>****</span>
                    &nbsp;{_helper.getPrinterTransString(lan, "reprint")}&nbsp;
                    <span>****</span>
                </div>
            );
        } else {
            return "";
        }
    };

    reformatedComments = () => {
        //add tips reminder
        const COMMENT_TYPE = {
            system: -1,
            admin: 1,
            customer: 0,
            order_status_change: 2,
        };
        const { tips, comment, mainLan, orderTime, styling, storeData, orderDetail, receipt } = this.state;
        const order = orderDetail ? orderDetail.data : {};
        let requests = Array.isArray(comment) ? _.cloneDeep(comment) : [];
        let payments = [];
        let includesTips = [];
        //add generated comment
        if (tips > 0 && styling && styling.footer && !styling.footer.hide) {
            includesTips.push({
                is_admin: true,
                comment: _helper.getPrinterTransString(mainLan, "total_included_tips"),
                create_at: orderTime,
            });
        }
        if (printRequestUtil(receipt, storeData, orderDetail)) {
            requests.unshift({
                is_admin: true,
                comment: _helper.getPrinterTransString(
                    mainLan,
                    requestUtensil(order) ? "require_utensil" : "no_require_utensil"
                ),
                create_at: orderTime,
            });
        }
        //seperate request and payment comments (system and others)
        payments = payments.concat(
            _.concat(requests).filter((comment) => [COMMENT_TYPE.system].includes(_.get(comment, "is_admin")))
        );
        requests = requests.filter((comment) =>
            [COMMENT_TYPE.customer, COMMENT_TYPE.admin].includes(_.get(comment, "is_admin"))
        );
        //remove comments
        if (styling && Number(styling.type) === RECEIPT_TYPE.chef) {
            payments = [];
        }
        if (styling && Number(styling.type) === RECEIPT_TYPE.label) {
            // on the label printing you can remove the comment
            requests = [];
            payments = [];
            includesTips = [];
        }
        return {
            payments: payments,
            requests: requests,
            includesTips: includesTips,
        };
    };

    renderOrderTotal = () => {
        let { totalPaid, styling, totalDue, currency, totalRefunded } = this.state;
        return (
            <div>
                {!styling.total?.hide ? (
                    <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                        {this.createOrderInfo("totals")}
                    </div>
                ) : (
                    ""
                )}
                {!styling.total?.hide && totalPaid ? (
                    <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                        {this.createOrderInfo("paid-totals")}
                    </div>
                ) : (
                    ""
                )}
                {!styling.total?.hide && totalRefunded ? (
                    <div className={`tablformatNumbere-wrapper ${this.getStyle("section_spacing")}`}>
                        {this.createLineItem(
                            "total_refunded",
                            _helper.getCurrencyFormattedString(totalRefunded, currency),
                            "total_refund"
                        )}
                    </div>
                ) : (
                    ""
                )}
                {!styling.total?.hide ? (
                    <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                        {this.createLineItem(
                            "total_due",
                            _helper.getCurrencyFormattedString(totalDue, currency),
                            "total_due"
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    renderOrderSurchargeNotInTotal = () => {
        const { mainLan, styling, currency, orderSurcharge, orderSurchargeInTotal, relatedOrderId } = this.state;
        //show value
        const showOrderSurchargeNotInTotal = orderSurcharge && !orderSurchargeInTotal;
        const showValue = !styling.total?.hide && showOrderSurchargeNotInTotal;
        //label
        const labelId = relatedOrderId ? "surcharge_paid" : "surcharge_unpaid";

        const showSurchargeInfo = () => {
            return this.createLineItem(
                labelId,
                _helper.getCurrencyFormattedString(orderSurcharge, currency),
                "total_due"
            );
        };
        const showPaidMessage = () => {
            const showMessage = relatedOrderId;
            return showMessage ? (
                <div>{_helper.getPrinterTransString(mainLan, "surcharege_paid_message", { id: relatedOrderId })}</div>
            ) : (
                ""
            );
        };
        return showValue ? (
            <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                {showSurchargeInfo()}
                {showPaidMessage()}
            </div>
        ) : null;
    };

    renderOrderSurchargePaidToMessage = () => {
        const { mainLan, orderSurcharge, relatedOrderId } = this.state;
        const showMessage = !orderSurcharge && relatedOrderId;
        return showMessage ? (
            <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                {_helper.getPrinterTransString(mainLan, "surcharge_paid_to_message", { id: relatedOrderId })}
            </div>
        ) : null;
    };

    renderOrderRewardPoints = () => {
        const { mainLan, orderDetail, styling } = this.state;
        //show value
        const order = _.get(orderDetail, "data", {});
        const showValue = !styling?.total?.hide && (getOrderRewardBalance(order) || getOrderRewardPoints(order));

        const renderPoints = () => {
            return (
                <div>
                    {getOrderRewardPoints(order)
                        ? this.createLineItem(
                              "reward_points",
                              `+${_helper.formatNumber(getOrderRewardPoints(order))}`,
                              "total_due"
                          )
                        : null}
                    {getOrderRewardBalance(order)
                        ? this.createLineItem(
                              "points_balance",
                              _helper.formatNumber(getOrderRewardBalance(order)),
                              "total_due"
                          )
                        : null}
                </div>
            );
        };
        const renderExtraMessage = () => {
            const showMessage =
                getOrderStatusNumber(order) !== config.ORDER_STATUS_MAPPING_TO_NUMERIC.completed &&
                getOrderRewardPoints(order);
            return showMessage ? (
                <div style={{ marginTop: 3 }}>
                    {_helper.getPrinterTransString(mainLan, "reward_points_extra_customer")}
                </div>
            ) : null;
        };
        return showValue ? (
            <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                {renderPoints()}
                {renderExtraMessage()}
            </div>
        ) : null;
    };

    renderTopComments = () => {
        const { mainLan } = this.state;
        const settingPosition = Object.values(SPECIAL_REQUEST_POSITION_OPTIONS).includes(
            this.state[SPECIAL_REQUEST_POSITION_KEY]
        )
            ? this.state[SPECIAL_REQUEST_POSITION_KEY]
            : SPECIAL_REQUEST_POSITION_OPTIONS.top;

        const comments = this.reformatedComments();
        const requests =
            Array.isArray(comments.requests) && String(settingPosition) === SPECIAL_REQUEST_POSITION_OPTIONS.top
                ? comments.requests
                : [];

        const renderSpecialRequest = () => {
            if (requests.length)
                return (
                    <>
                        <span className="bold"> {_helper.getPrinterTransString(mainLan, "special_request")}: </span>
                        {requests.map((com, i) => renderComment(com, i))}
                    </>
                );
        };

        const renderComment = (com, i) => {
            return (
                <span key={`receipt-top-comment-${i}`}>
                    <span
                        style={{ marginRight: 3 }}
                        className={classnames({
                            bold: true,
                        })}
                    >
                        {" "}
                        {com.comment}{" "}
                    </span>
                </span>
            );
        };

        return (
            <div
                className={classnames({
                    [this.getStyle("comment")]: true,
                })}
            >
                {renderSpecialRequest()}
            </div>
        );
    };

    renderBottomComments = () => {
        //reformat comment to add tips reminder
        const { mainLan, styling } = this.state;
        const settingPosition = Object.values(SPECIAL_REQUEST_POSITION_OPTIONS).includes(
            this.state[SPECIAL_REQUEST_POSITION_KEY]
        )
            ? this.state[SPECIAL_REQUEST_POSITION_KEY]
            : SPECIAL_REQUEST_POSITION_OPTIONS.top;

        const comments = this.reformatedComments();
        const payments = Array.isArray(comments.payments) ? comments.payments : [];
        const requests =
            Array.isArray(comments.requests) && String(settingPosition) === SPECIAL_REQUEST_POSITION_OPTIONS.bottom
                ? comments.requests
                : [];
        const includesTips = Array.isArray(comments.includesTips) ? comments.includesTips : [];
        const all = payments.concat(requests, includesTips);

        const renderPayments = () => {
            if (payments.length)
                return <div>{payments.map((com, i, arr) => renderComment(com, i, arr, "payment"))}</div>;
        };

        const renderIncludesTips = () => {
            if (includesTips.length)
                return <div> {includesTips.map((com, i, arr) => renderComment(com, i, arr, "tips"))} </div>;
        };

        const renderSpecialRequest = () => {
            if (requests.length)
                return (
                    <div>
                        <span className="bold"> {_helper.getPrinterTransString(mainLan, "special_request")}: </span>
                        {requests.map((com, i, arr) => renderComment(com, i, arr, "request"))}
                    </div>
                );
        };

        const renderComment = (com, i, arr, key) => {
            const isPayment = String(key) === "payment";
            return (
                <span key={`${key}-${i}`}>
                    <span
                        style={{
                            marginRight: 3,
                        }}
                        className={classnames({
                            bold: !isPayment,
                        })}
                    >
                        {" "}
                        {com.comment}{" "}
                    </span>
                </span>
            );
        };

        return (
            <div>
                {!_.isEmpty(all) ? (
                    <div
                        className={classnames({
                            [this.getStyle("comment")]: true,
                        })}
                    >
                        {renderSpecialRequest()}
                        {renderIncludesTips()}
                        {renderPayments()}
                        {!styling.footer.hide ? (
                            <div>
                                {" "}
                                <br />{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    renderWelcomeText = () => {
        const { shopUrl, mainLan } = this.state;
        return shopUrl ? (
            <div>
                <div className="center-normal-text uppercase">
                    {_helper.getPrinterTransString(mainLan, "welcome_text1")}
                </div>
                <div className="center-normal-text">{shopUrl}</div>
            </div>
        ) : null;
    };

    renderQRCode = () => {
        const { mainLan, with_QR_Code, config } = this.state;
        const websiteRoot = config && config.WEBSITE_ROOT ? config.WEBSITE_ROOT : "https://h5.goopter.com";
        const gid = _.get(this.state, "storeData.general_info.g_id", "");
        const QRUrl = `${websiteRoot}/store/${gid}`;
        const showQR = _helper.isBool(with_QR_Code) ? with_QR_Code : true;
        if (showQR)
            return (
                <div>
                    <div className="center-normal-text uppercase extra-small-text">
                        {_helper.getPrinterTransString(mainLan, "scan_qr_to_order")}
                    </div>
                    <div className="center-normal-text">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <QRCode ecLevel="L" id="store-qr-code" value={QRUrl} size={90} enableCORS={true} />
                        </div>
                    </div>
                </div>
            );
    };

    renderPrintDate = () => {
        const lan = _.get(this.state, "mainLan", "en");
        const type = _.get(this.state, "styling.type", 10);
        const shouldPrintDate = Number(type) === 10 || Number(type) === 11;
        return shouldPrintDate ? (
            <div>
                {_helper.getPrinterTransString(lan, "print_time")}: &nbsp;
                {moment().format("MMM DD, YYYY hh:mmA")}
            </div>
        ) : null;
    };

    render() {
        let { shopUrl, styling, marginTop, marginBottom, marginLeft, marginRight, mounted } = this.state;

        return mounted ? (
            <OrderDetailPrintPortal closeWindowPortal={this.props.closeWindowPortal}>
                <div
                    className="print-view-container"
                    style={{
                        marginTop: marginTop ? `${marginTop}cm` : "0",
                        marginBottom: marginBottom ? `${marginBottom}cm` : "0",
                        marginLeft: marginLeft ? `${marginLeft}cm` : "0",
                        marginRight: marginRight ? `${marginRight}cm` : "0",
                    }}
                >
                    {this.createOrderChangeStatus()}
                    {this.createHeader()}
                    <div className={`table-wrapper ${this.getStyle("section_spacing")}`}>
                        {this.renderReprintMessage()}
                        {this.renderOrderIdLine()}
                        {this.createOrderInfo("info")}
                        {this.renderTopComments()}
                    </div>
                    <div
                        className={classnames({
                            "table-wrapper": true,
                            [this.getStyle("section_spacing")]: true,
                            "table-wrapper-no-border": Number(styling.type) === 12,
                        })}
                    >
                        {this.renderOrderMenus()}
                        {this.renderOrderItems()}
                    </div>
                    {this.renderOrderTotal()}
                    {this.renderOrderSurchargeNotInTotal()}
                    {this.renderOrderSurchargePaidToMessage()}
                    {this.renderOrderRewardPoints()}
                    {!_.isEmpty(this.reformatedComments()) || (shopUrl && !styling.footer?.hide) ? (
                        <div
                            className={classnames({
                                [this.getStyle("section_spacing")]: true,
                                "extra-small-text": Number(styling.type) === 12,
                            })}
                        >
                            {this.renderBottomComments()}
                            {!styling.footer?.hide ? (
                                <div>
                                    {this.renderWelcomeText()}
                                    {this.renderQRCode()}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {this.renderPrintDate()}
                </div>
            </OrderDetailPrintPortal>
        ) : (
            false
        );
    }
};

export const handlePrintReceipt = (params) => {
    const divTarget = document.createElement("div");
    document.body.appendChild(divTarget);

    let { orderDetail, storeData, orderStatus } = params;

    // format order detail data
    let baseParams = {
        orderDetail: orderDetail,
        storeData: storeData,
        storeType: _helper.getRealStoreType(_.get(storeData, "store_flg", "")),
        shopPhone: _helper.formatPhone(_.get(storeData, "general_info.phone", "")),
        shopAddress: _.get(storeData, "store_addr", {}),
        deliAddress: _.get(orderDetail, "data.shipping_adr", {}),
        delvieryZoneName: _.get(orderDetail, "data.delivery_zone.name", ""),
        pickupAddress: formatPickupLocation(_.get(orderDetail, "data.pickup_location", {})),
        shopVAT: _helper.getTaxNumber(
            _.get(storeData, "general_info.vat"),
            _.get(storeData, "store_addr.cntry"),
            _.get(storeData, "store_addr.city")
        ),
        orderId: orderDetail.data.ord_id,
        deliveryMethod: orderDetail.data.shp_mtd,
        orderTime: orderDetail.data.ord_dt,
        customerName: orderDetail.data.c_full_name,
        customerPhone: orderDetail.data.c_tn,
        partySize: 0,
        paymentMethod: orderDetail.data.pay_mtd,
        creditCard: orderDetail.data.cc,
        currency: orderDetail.data.currency,
        orderItems: orderDetail.data.ord_itm,
        orderItemsId: 0,
        tableNumber: orderDetail.data.tb_num,
        staffName: orderDetail.data.staff,
        subtotal: orderDetail.data.sb_ttl,
        discount: orderDetail.data.ttl_dis,
        deliveryFee: orderDetail.data.dy_fee,
        storeSurcharge: orderDetail.data.store_surcharge,
        storeSurchargeLabel: orderDetail.data.store_surcharge_label,
        orderSurcharge: orderDetail.data.order_surcharge,
        orderSurchargeLabel: orderDetail.data.order_surcharge_label,
        orderSurchargeInTotal: orderDetail.data.order_surcharge_in_total,
        relatedOrderId: orderDetail.data.related_order_id,
        itemTotal: orderDetail.data.item_ttl,
        totalBeforeTax: orderDetail.data.ttl_before_tax,
        tax: orderDetail.data.tax,
        taxes: orderDetail.data.taxes,
        tips: orderDetail.data.tips,
        orderTotal: orderDetail.data.ttl_ord,
        totalPaid: orderDetail.data.ttl_pd,
        totalRefunded: orderDetail.data.rf_amt,
        totalDue: orderDetail.data.ttl_due,
        giftCardPayment: orderDetail.data.gc_amt,
        pointsPayment: orderDetail.data.pt_amt,
        orderStatus: orderDetail.data.status,
        orderStatusChanged: orderStatus,
        comment: orderDetail.data.cmt_list,
        shopUrl: _.get(storeData, "general_info.url", ""),
        printDt: orderDetail.data.print_dt,
    };
    ReactDOM.render(
        <OrderDetailPrintView
            params={baseParams}
            closeWindowPortal={() => {
                ReactDOM.unmountComponentAtNode(divTarget);
                divTarget.parentNode.removeChild(divTarget);
            }}
        />,
        divTarget
    );

    return true;
};
