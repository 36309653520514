import { useState } from "react";
import CopyIcon from "../copy-icon";

function CopyArea(props: any = {}): JSX.Element {
    const {
        iconPosition = "end",
        copyData = " ",
        messageId = " ",
        children = "",
        showAlways = false,
        ...remainingProps
    } = props;

    const [showCopyIcon, toggleShowCopyIcon] = useState<boolean>(showAlways);

    const handleMouseEnter = () => {
        toggleShowCopyIcon(true);
    };

    const handleMouseLeave = () => {
        toggleShowCopyIcon(showAlways ? true : false);
    };

    if (iconPosition === "start") {
        return (
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...remainingProps}>
                <CopyIcon copyData={copyData} messageId={messageId} show={showCopyIcon} />
                {children}
            </div>
        );
    } else if (iconPosition === "end" || iconPosition === undefined) {
        return (
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...remainingProps}>
                {children}
                <CopyIcon copyData={copyData} messageId={messageId} show={showCopyIcon} />
            </div>
        );
    } else {
        return children;
    }
}

export default CopyArea;
