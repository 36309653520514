import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setReferralsState } from "../../../../slices/referrals";
import { RootState } from "../../../../app/reducer";
import { transactionType } from "../../constants";

function emptyFunction() {
    // does nothing
}

const selector = (state: RootState) => {
    return {
        transactions: state?.referrals?.transactions,
        paging: state?.referrals?.transactionsPaging,
        searchConditions: state?.referrals?.transactionsSearchConditions,
        referrals: state?.referrals?.referrals,
    };
};

interface ReferralsFilterProps {
    closeModal: () => void;
    search: any;
}

const ReferralsFilter = (props: ReferralsFilterProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { searchConditions } = useSelector(selector);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const cloned: any = _.cloneDeep(data);
        _.each(cloned, (value, key) => {
            if (_.isUndefined(value) || value === "") {
                delete cloned[key];
            }
        });

        if (cloned["date_range"]) {
            cloned.s_date = cloned["date_range"][0].utc().format("YYYY-MM-DD hh:mm:ss");
            cloned.e_date = cloned["date_range"][1].utc().format("YYYY-MM-DD hh:mm:ss");
        }

        delete cloned["date_range"];

        dispatch(
            setReferralsState({
                transactionsSearchConditions: {
                    customer_id: searchConditions?.customer_id,
                    limit: searchConditions?.limit,
                    ...cloned,
                    page: 1,
                },
            })
        );

        props.closeModal();
    };

    const resetFilters = () => {
        dispatch(
            setReferralsState({
                transactionsSearchConditions: {
                    customer_id: searchConditions?.customer_id,
                    limit: searchConditions?.limit,
                    page: 1,
                },
            })
        );

        const setter: any = {};

        Object.keys(searchConditions).forEach((key) => {
            setter[key] = undefined;
        });

        form.setFieldsValue(setter);

        props.closeModal();
    };

    const resetFields = (arr: any) => {
        const setter: any = {};

        arr.forEach((key: any) => {
            setter[key] = undefined;
        });

        form.setFieldsValue(setter);
    };

    const getTypeOptions = () =>
        transactionType.map((type, i) => (
            <Select.Option value={i} key={i}>
                {intl.formatMessage({ id: type ?? " " })}
            </Select.Option>
        ));

    const clearButton = (callback = emptyFunction) => (
        <div className="clear-filter-btn-container">
            <Button type="link" className="clear-filter-btn" onClick={callback}>
                <FormattedMessage id="clear" />
            </Button>
        </div>
    );

    const renderOrderId = (
        <>
            <FormattedMessage id="order_id" />
            <Form.Item name="order_id">
                <Input
                    placeholder={intl.formatMessage({
                        id: "type_here",
                    })}
                />
            </Form.Item>
            {clearButton(() => {
                resetFields(["order_id"]);
            })}
        </>
    );

    const renderBalance = (
        <>
            <FormattedMessage id="balance" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_points_bal">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_points_bal">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
            {clearButton(() => {
                resetFields(["min_points_bal", "max_points_bal"]);
            })}
        </>
    );

    const renderRangeAndType = (
        <>
            <FormattedMessage id="date_range" />
            <Form.Item name="date_range">
                <DatePicker.RangePicker />
            </Form.Item>
            {clearButton(() => {
                resetFields(["date_range"]);
            })}
            <FormattedMessage id="type" />
            <Form.Item name="type">
                <Select placeholder={intl.formatMessage({ id: "select_type" })}>{getTypeOptions()}</Select>
            </Form.Item>
            {clearButton(() => {
                resetFields(["type"]);
            })}
        </>
    );

    useEffect(() => {
        form.setFieldsValue(searchConditions);
    }, [form, searchConditions]);

    return (
        <div className="referral-filter">
            <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish} initialValues={searchConditions}>
                {renderRangeAndType}
                {renderBalance}
                {renderOrderId}
                <div className="footer-filter">
                    <Button type="link" onClick={resetFilters}>
                        <FormattedMessage id="reset_filters" />
                    </Button>
                    <Button type="primary" htmlType="submit">
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ReferralsFilter;
