import { Button, Form, Select } from "antd";
import _ from "lodash";
import {
    getMultiSelectInput,
    getSelectInput,
    mapBooleansToNumbers,
    getSwitchInput,
    getSingleBlock,
    SelectOption,
    getDescBlock,
    getSwitchDescBlock,
    getNumberInput,
    getDoubleBlockOptions,
} from "./../../../../components/form";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import { getStoreDetails, updateStoreDetails } from "./../../../../services/store";
import ObjectModel from "./../../../../util/models";
import StoreDisplayOptions from "../../../../util/models/store-display-options";
import { Prompt } from "react-router-dom";
import config from "config";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/displaySettings", breadcrumbName: "display_options" },
    ],
};

const DEFAULT_SOLD_QUANTITY_THRESHOLD = 10;

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const displayOptions = useSelector((state: RootState) => state.store?.storeDisplayOptions);
    const records = useSelector((state: RootState) => state.store?.records);
    const [touched, setTouched] = useState(false);
    const [showAmount, setShowAmount] = useState(0);
    const [showStoreQtyDisplayThreshold, setShowStoreQtyDisplayThreshold] = useState(0);
    const [soldQtyDisplayThreshold, setSoldQtyDisplayThreshold] = useState(0);
    const [showSoldQtyDisplayThreshold, setShowSoldQtyDisplayThreshold] = useState(0);
    const distanceUnit = useSelector((state: RootState) => state.store?.storePickupDelivery?.distance_unit);
    const [currDistanceUnit, setcurrDistanceUnit] = useState();
    const [form] = Form.useForm();

    enum DISTANCE_UNIT {
        KM = "1",
        MILE = "2",
    }

    const prodDisplayForm: SelectOption[] = [
        { value: "0", label: intl.formatMessage({ id: "regular" }) },
        { value: "1", label: intl.formatMessage({ id: "super_market_style" }) },
        // { value: "2", label: intl.formatMessage({ id: "one_page_store" }) },
        { value: "3", label: intl.formatMessage({ id: "regular_style_hidden_cat" }) },
        { value: "4", label: intl.formatMessage({ id: "horizontal_category_display" }) },
    ];

    const prodDetailForm: SelectOption[] = [
        { value: "0", label: intl.formatMessage({ id: "product_detail_display_format0" }) },
        { value: "1", label: intl.formatMessage({ id: "product_detail_display_format1" }) },
        { value: "2", label: intl.formatMessage({ id: "product_detail_display_format2" }) },
        { value: "3", label: intl.formatMessage({ id: "product_detail_display_format3" }) },
        { value: "4", label: intl.formatMessage({ id: "product_detail_display_format4" }) },
        { value: "5", label: intl.formatMessage({ id: "product_detail_display_format5" }) },
    ];

    const storeDisplayStyle: SelectOption[] = [
        { value: "0", label: intl.formatMessage({ id: "no_store_info_and_review_tab" }) },
        { value: "1", label: intl.formatMessage({ id: "with_store_info_tab_no_review_tab" }) },
        { value: "2", label: intl.formatMessage({ id: "with_store_info_and_review" }) },
    ];

    const qtyDisplay: SelectOption[] = [
        { value: "-1", label: intl.formatMessage({ id: "dont_show_stock_quantity" }) },
        { value: "0", label: intl.formatMessage({ id: "always_show_stock_quantity" }) },
    ];

    if (showAmount > 0) {
        qtyDisplay.push({
            value: showAmount,
            label: intl.formatMessage({ id: "show_stock_quantity_when_under_certain_amount" }),
        });
    } else {
        qtyDisplay.push({
            value: "1",
            label: intl.formatMessage({ id: "show_stock_quantity_when_under_certain_amount" }),
        });
    }

    const soldQtyDisplayOptions: SelectOption[] = [
        {
            value: String(config.SOLD_QTY_DISPLAY_OPTIONS.hide),
            label: intl.formatMessage({ id: "dont_show_sold_quantity" }),
        },
        {
            value: String(config.SOLD_QTY_DISPLAY_OPTIONS.show),
            label: intl.formatMessage({ id: "always_show_sold_quantity" }),
        },
        {
            value: soldQtyDisplayThreshold > 0 ? soldQtyDisplayThreshold : DEFAULT_SOLD_QUANTITY_THRESHOLD,
            label: intl.formatMessage({ id: "show_sold_quantity_when_over_certain_amount" }),
        },
    ];

    const getLanguageSelectOptions = (): SelectOption[] => {
        const lanOptions: SelectOption[] = [];
        Object.keys(config.LANGUAGE_MAP).forEach((lan: string) => {
            lanOptions.push({
                value: lan,
                label: intl.formatMessage({ id: lan }),
            });
        });

        return lanOptions;
    };

    const languages: SelectOption[] = getLanguageSelectOptions();

    const distanceUnits: SelectOption[] = [
        { value: "1", label: intl.formatMessage({ id: "km" }) },
        { value: "2", label: intl.formatMessage({ id: "miles" }) },
    ];

    useEffect(() => {
        if (Number(displayOptions.store_qty_display) > 0) {
            setShowAmount(displayOptions.store_qty_display);
            setShowStoreQtyDisplayThreshold(displayOptions.store_qty_display);
        }

        dispatch(getStoreDetails());
    }, [dispatch, displayOptions.store_qty_display]);

    useEffect(() => {
        if (displayOptions.sold_qty_display > 0) {
            setSoldQtyDisplayThreshold(displayOptions.sold_qty_display);
            setShowSoldQtyDisplayThreshold(displayOptions.sold_qty_display);
        }
    }, [displayOptions.sold_qty_display]);

    useEffect(() => {
        setcurrDistanceUnit(distanceUnit);
    }, [distanceUnit]);

    const setDistanceUnit = (e: any) => {
        setcurrDistanceUnit(e);
    };

    const onFinish = (data: any) => {
        if (!currDistanceUnit || (currDistanceUnit !== DISTANCE_UNIT.KM && currDistanceUnit !== DISTANCE_UNIT.MILE)) {
            form.setFieldsValue({ "distance_unit": DISTANCE_UNIT.KM });
        }
        setTouched(false);
        const mapped = mapBooleansToNumbers(data, [
            "show_product_place_holder_image",
            "pagination",
            "hot_category_enabled",
            "onsale_category_enabled",
            "use_land",
            "store_qty_display",
            "sold_qty_display",
        ]);
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreDisplayOptions).convertToPostData(mapped, records, "display_options")
            )
        );
    };

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const renderWideSelect = (name: string, options: any) => (
        <Form.Item name={name} style={{ marginBottom: "auto", minWidth: "500px" }}>
            <Select style={{ width: "100%" }} placeholder={intl.formatMessage({ id: "type_here" })}>
                {options.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    const renderForm = () => {
        const storeQtyDisplayLabels = ["store_qty_display"];
        const storeQtyDisplayDescriptions = ["store_qty_display_tip"];
        const storeQtyDisplayNodes = [
            getSelectInput("store_qty_display", false, qtyDisplay, undefined, (value: any) =>
                setShowStoreQtyDisplayThreshold(value)
            ),
        ];

        const soldQtyDisplayLabels = ["sold_qty_display"];
        const soldQtyDisplayDescriptions = ["sold_qty_display_tip"];
        const soldQtyDisplayNodes = [
            getSelectInput("sold_qty_display", false, soldQtyDisplayOptions, undefined, (value: any) =>
                setShowSoldQtyDisplayThreshold(value)
            ),
        ];

        const addThresholdInput = (
            labels: string[],
            descriptions: string[],
            nodes: any[],
            name: string,
            onChange: any
        ) => {
            labels.push(`${name}_amount`);
            descriptions.push(`${name}_amount_tip`);
            nodes.push(getNumberInput(name, onChange));
        };

        if (showStoreQtyDisplayThreshold > 0) {
            addThresholdInput(
                storeQtyDisplayLabels,
                storeQtyDisplayDescriptions,
                storeQtyDisplayNodes,
                "store_qty_display",
                (value: number) => setShowAmount(value)
            );
        }

        if (showSoldQtyDisplayThreshold > 0) {
            addThresholdInput(
                soldQtyDisplayLabels,
                soldQtyDisplayDescriptions,
                soldQtyDisplayNodes,
                "sold_qty_display",
                (value: number) => setSoldQtyDisplayThreshold(value)
            );
        }

        return (
            <Form
                form={form}
                name="display-settings"
                onFinish={onFinish}
                initialValues={displayOptions}
                onValuesChange={handleValuesChanged}
            >
                {getSingleBlock(
                    "product_display_languages",
                    true,
                    getMultiSelectInput("product_display_languages", false, languages, { mode: "multiple" })
                )}
                {getSingleBlock("default_language", true, getSelectInput("default_language", false, languages))}
                {getSingleBlock(
                    "store_display_style",
                    true,
                    getSelectInput("store_display_style", false, storeDisplayStyle)
                )}

                {getDescBlock(
                    "product_display_format",
                    true,
                    "product_display_format_tip",
                    getSelectInput("product_display_format", false, prodDisplayForm)
                )}
                {getDescBlock(
                    "product_detail_display_format",
                    true,
                    "product_detail_desc",
                    renderWideSelect("product_detail_display_format", prodDetailForm)
                )}

                {getSwitchDescBlock("pagination", "pagination_tip", getSwitchInput("pagination", displayOptions))}
                {getDoubleBlockOptions(storeQtyDisplayLabels, storeQtyDisplayDescriptions, storeQtyDisplayNodes)}
                {getDoubleBlockOptions(soldQtyDisplayLabels, soldQtyDisplayDescriptions, soldQtyDisplayNodes)}
                {getSwitchDescBlock(
                    "show_product_place_holder_image",
                    "show_product_place_holder_image_tip",
                    getSwitchInput("show_product_place_holder_image", displayOptions)
                )}
                {getSwitchDescBlock(
                    "hot_category_enabled",
                    "hot_category_enabled_tip",
                    getSwitchInput("hot_category_enabled", displayOptions)
                )}
                {getSwitchDescBlock(
                    "onsale_category_enabled",
                    "onsale_category_enabled_tip",
                    getSwitchInput("onsale_category_enabled", displayOptions)
                )}
                {getSwitchDescBlock("use_land", "use_land_tip", getSwitchInput("use_land", displayOptions))}
                {getSingleBlock(
                    "distance_unit",
                    true,
                    getSelectInput("distance_unit", false, distanceUnits, undefined, (e: any) => setDistanceUnit(e))
                )}

                <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                    <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!touched}
                        className="save-button-handler"
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </Form>
        );
    };

    return !_.isEmpty(displayOptions) ? (
        <Wrapper helmet={{ title: "display_options" }} breadcrumb={breadcrumb} paddingBottom={true}>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="display-options-page">{renderForm()}</div>
        </Wrapper>
    ) : null;
}

export default App;
