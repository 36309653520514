import React from "react";
import { injectIntl } from "react-intl";
import { FaMinus, FaPlus } from "react-icons/fa";
import "./alipay-muliple-picker.scss";
import { ListItem } from "@material-ui/core";
import cx from "classnames";
import _ from "lodash";

class AlipayMultiPicker extends React.Component {
    onAddClick = () => {
        this.props.onPlusClick && this.props.onPlusClick();
    };

    renderQtyButtons = () => {
        const qt = this.props.quantity;
        const allowAdd = _.isBoolean(this.props.allowAdd) ? this.props.allowAdd : true;
        const allowRemove = _.isBoolean(this.props.allowRemove) ? this.props.allowRemove : !qt;

        return (
            <div className="alipay-multiple-picker-qty-buttons">
                <div
                    className={cx({
                        storeProductButton: true,
                        removeProduct: true,
                        storeProductButtonDisabled: !allowRemove,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.onMinusClick && this.props.onMinusClick();
                    }}
                >
                    <FaMinus />
                </div>
                <div className="storeProductQty">{qt || 0}</div>
                <div
                    className={cx({
                        storeProductButton: true,
                        addProduct: true,
                        addProductDisabled: !allowAdd,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.onAddClick();
                    }}
                >
                    <FaPlus />
                </div>
            </div>
        );
    };

    renderProductName = () => {
        return (
            <div
                className={cx({
                    error: this.props.error,
                })}
            >
                {this.props.name}
            </div>
        );
    };

    render() {
        return (
            <div className="alipay-multiple-picker-container">
                <ListItem onClick={() => this.onAddClick()} button>
                    <div className="alipay-multiple-picker-content">
                        {this.renderProductName()}
                        {this.renderQtyButtons()}
                    </div>
                </ListItem>
            </div>
        );
    }
}

export default injectIntl(AlipayMultiPicker);
