import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { copyToClipBoard } from "../../util/helper/clipboard";
import { Popover } from "antd";
import { MdContentCopy } from "react-icons/md";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { getIntl } from "../../locale";

const filledCircleStyle = {
    marginRight: 5,
};

type CopierProps = {
    copyContent: any,
    copyLabel?: any,
    side?: string,
    style?: Record<string, any>,
};

function Copier(props: CopierProps): JSX.Element {
    const copyContent = props.copyContent;
    const copyContentLabel = props.copyLabel ?? props.copyContent;
    const side = props.side ?? "right";
    const otherStyles = props.style || {};

    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [showCopyMessage, setShowCopyMessage] = useState<boolean>(false);

    const str = (id: string) => getIntl().formatMessage({ id });

    const content = () => (
        <div style={{ color: copySuccess ? "rgb(27, 133, 4)" : "#ff4d4f" }}>
            {copySuccess ? (
                <CheckCircleFilled style={filledCircleStyle} />
            ) : (
                <CloseCircleFilled style={filledCircleStyle} />
            )}

            <FormattedMessage
                id={`copied_to_clipboard_${copySuccess ? "success" : "failed"}`}
                values={{
                    what: str(copyContentLabel ?? " "),
                }}
            />
        </div>
    );

    const onCopyResponse = (success: boolean) => {
        setCopySuccess(success);
        setShowCopyMessage(true);
        setTimeout(() => {
            setShowCopyMessage(false);
        }, 2000);
    };

    const onCopyClick = (e: any) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        copyToClipBoard(copyContent, onCopyResponse);
    };

    const getStyle = () => ({
        marginLeft: side === "left" ? 0 : 5,
        marginRight: side === "left" ? 5 : 0,
        cursor: "pointer",
        ...otherStyles,
    });
    return (
        <Popover visible={showCopyMessage} content={content}>
            <MdContentCopy style={getStyle()} onClick={onCopyClick} />
        </Popover>
    );
}

export default Copier;
