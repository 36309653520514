import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    quotes: [],
    selected: [],
};

const slice = createSlice({
    name: "quotes",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state) => {
            Object.assign(state, initialState);
        },
        getQuotesSuccess: (state, { payload }) => {
            state.quotes = Array.isArray(payload?.response?.res) ? payload?.response?.res : [];
            state.search = payload?.response?.paging || {};
        },
    },
});

export const { setState, clearData, getQuotesSuccess } = slice.actions;

export const quotes = slice.reducer;
