import config from "config";
import _ from "lodash";
import { isBool } from "../../../../util/helper";

const reqFields: any = {
    braintree_setting: [
        "braintree_enabled",
        "braintree_merch_id",
        "braintree_pub_key",
        "braintree_pri_key",
        "braintree_google_merchant_id",
    ],
    paypal_account_setting: [
        "paypal_enabled",
        "paypal_merch_name",
        "paypal_merch_phone",
        "paypal_partner",
        "paypal_api_pass",
        "paypal_api_user",
        "paypal_card_types",
        "paypal_merch_login",
    ],
    paypal_express_checkout: ["paypal_exp_enabled", "paypal_exp_id", "paypal_exp_secret"],
    stripe_account_setting: [
        "stripe_enabled",
        "stripe_merch_name",
        "stripe_api_key",
        "stripe_x_type",
        "stripe_card_types",
    ],
    unionpay_debit: ["unionpay_enabled", "unionpay_merch_id", "unionpay_credential"],
    unionpay_credit: ["unionpayexpress_enabled", "unionpayexpress_merch_id", "unionpayexpress_credential"],
    alipay_account_setting: [
        "alipay_enabled",
        "alipay_merch_id",
        "alipay_credential",
        "alipay_exc_enabled",
        "alipay_exc_merch_id",
        "alipay_exc_credential",
    ],
    wechat_account_setting: ["wechat_enabled", "wechat_merch_id", "wechat_credential", "wechat_app_id"],
    card_present_setting: [
        "card_present_enabled",
        "card_present_vendor",
        "card_present_merch_id",
        "card_present_access_token",
        "card_present_pos_id",
        "card_present_device_ids",
        "card_present_app_id",
        "card_present_pos_store_id",
        "card_present_auth_key",
        "card_present_api_secret",
        "card_present_config_code",
    ],
    clover: ["clover_enabled", "clover_merch_id", "clover_api_key", "clover_access_token"],
    global_payment: [
        "global_payment_enabled",
        "global_payment_app_id",
        "global_payment_auth_key",
        "global_payment_api_key",
        "global_payment_api_secret",
    ],
    ppcp_account_setting: [
        "ppcp_enabled",
        "ppcp_merchant_id",
        "ppcp_txn_type",
        "ppcp_merch_name",
        "ppcp_merch_phone",
        "ppcp_payment_google_pay",
        "ppcp_payment_apple_pay",
        "ppcp_payment_paypal",
        "ppcp_contingency_3d_secure",
        "ppcp_partner_fee",
        "ppcp_mode",
        "ppcp_thirdparty_token",
    ],
};

const requiredCloverRESTPayAPIFields = ["card_present_enabled", "card_present_merch_id", "card_present_access_token"];

const requiredCloverRemotePaySDKFields = ["card_present_enabled", "card_present_app_id", "card_present_access_token"];

const requiredMonerisSemiIntegratedFields = [
    "card_present_enabled",
    "card_present_merch_id",
    "card_present_config_code",
];

const requiredAlipayAccountSettingWithEXC = [
    "alipay_enabled",
    "alipay_merch_id",
    "alipay_credential",
    "alipay_exc_merch_id",
    "alipay_exc_credential",
];

const requiredAlipayAccountSettingWithOutEXC = ["alipay_enabled", "alipay_merch_id", "alipay_credential"];

const requiredMonerisFields = [
    "card_present_enabled",
    "card_present_merch_id",
    "card_present_access_token",
    "card_present_pos_store_id",
];

const requiredGlobalPaymentFields = [
    "card_present_enabled",
    "card_present_access_token",
    "card_present_app_id",
    "card_present_auth_key",
    "card_present_api_secret",
];

export const DEFAULT_CC_GATEWAY_VALUE_MAP: any = {
    "paypal": "1",
    "braintree": "3",
    "stripe": "2",
    "offline": "0",
    "ppcp": "4",
};

type CallBackFunction = (hasMissingField: boolean, missingField: string) => void;

const checkRequiredFilled = (
    paymentOptions: Record<string, any>,
    tab: string,
    callback?: CallBackFunction
): boolean | undefined => {
    let currTabReqs = reqFields[tab];
    if (tab === "card_present_setting") {
        if (paymentOptions?.card_present_vendor === config.CARD_PRESENT_VENDORS.clover) {
            if (
                Number(paymentOptions?.card_present_integration_type) === config.CLOVER_INTEGRATION_TYPES.rest_pay_api
            ) {
                currTabReqs = requiredCloverRESTPayAPIFields;
            } else if (
                Number(paymentOptions?.card_present_integration_type) === config.CLOVER_INTEGRATION_TYPES.remote_pay_sdk
            ) {
                currTabReqs = requiredCloverRemotePaySDKFields;
            }
        } else if (paymentOptions?.card_present_vendor === config.CARD_PRESENT_VENDORS.moneris) {
            if (
                Number(paymentOptions?.card_present_integration_type) ===
                config.MONERIS_INTEGRATION_TYPES.semi_integrated_solution
            ) {
                currTabReqs = requiredMonerisSemiIntegratedFields;
            } else {
                currTabReqs = requiredMonerisFields;
            }
        } else if (paymentOptions?.card_present_vendor === config.CARD_PRESENT_VENDORS.global_payment) {
            currTabReqs = requiredGlobalPaymentFields;
        }
    }

    if (tab === "alipay_account_setting") {
        if (Number(paymentOptions?.alipay_exc_enabled)) {
            currTabReqs = requiredAlipayAccountSettingWithEXC;
        } else {
            currTabReqs = requiredAlipayAccountSettingWithOutEXC;
        }
    }

    if (currTabReqs && isBool(paymentOptions?.[currTabReqs?.[0]])) {
        // const findMissingFields = (field: string, index: number): boolean => {
        //     return index !== 0 && _.isEmpty(paymentOptions?.[field]);
        // };

        // Checks if the given value is empty, taking into account the value's type.
        const isEmpty = (value: string | boolean, index: number) => {
            // If the value is a boolean, it's not considered empty, so return false.
            if (typeof value === "boolean") {
                return false;
            }
            // If the value is a string, check if it's empty.
            return index !== 0 && _.isEmpty(value);
        };
        const findMissingFields = (field: string, index: number): boolean => {
            if (field == "ppcp_partner_fee" || field == "ppcp_thirdparty_token") {
                return false;
            }
            return index !== 0 && isEmpty(paymentOptions?.[field], index);
        };
        const missingField = currTabReqs.find(findMissingFields);
        if (!_.isEmpty(missingField)) {
            if (callback) callback(true, missingField);
            return true;
        } else {
            return false;
        }
    }

    return false;
};

/**
 *
 * @param paymentOptions
 * @param callback find the first missing field
 * @returns hasmissingfield : boolean, missing field: string
 *
 * @returns boolean true = has missing field
 */
const areRequiredPaymentOptionsFieldsFilled = (
    paymentOptions: Record<string, any>,
    callback?: CallBackFunction
): boolean => {
    const reqTabs = Object.keys(reqFields);
    for (const tab of reqTabs) {
        if (checkRequiredFilled(paymentOptions, tab, callback)) {
            return true;
        }
    }

    return false;
};

const getPreviouslySetPaymentField = (storePaymentOptions: Record<string, any>, field: string) => {
    const filledField = Object.keys(storePaymentOptions).find((key) => {
        return key.endsWith(field) && storePaymentOptions[key] != null && storePaymentOptions[key] !== "";
    });

    // @ts-ignore
    return storePaymentOptions[filledField];
};

export const getPreviouslySetMerchantCode = (storePaymentOptions: Record<string, any>): boolean => {
    return getPreviouslySetPaymentField(storePaymentOptions, "merch_id");
};

export const getPreviouslySetCredentialCode = (storePaymentOptions: Record<string, any>): boolean => {
    return getPreviouslySetPaymentField(storePaymentOptions, "credential");
};

export default {
    checkRequiredFilled,
    areRequiredPaymentOptionsFieldsFilled,
};
