import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { handlePrintReport } from "../../helper";
import moment from "moment";
import { fetchReport } from "../../../../services/report";
import { RootState } from "../../../../app/reducer";

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm";
const FROM_TIME = 0;
const TO_TIME = 1;

export const useSalesOperations = (): Record<string, any> => {
    const intl = useIntl();
    const str = (id: string) => intl.formatMessage({ id });
    const dispatch = useDispatch();
    const { staffList, report, lan, loadingState, storeCurrency } = useSelector((state: RootState) => ({
        staffList: [],
        report: state.report.report,
        lan: state.setting.lan,
        loadingState: state.report.loadingState,
        storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
    }));

    // Base State
    const [state, setUseState] = useState<any>({
        fromDate: moment(new Date()),
        fromDateString: "",
        fromTimeString: "06:00",
        toDate: moment().add(1, "days"),
        toDateString: "",
        toTimeString: "06:00",
        selectedDateButton: 0,

        exportType: "PDF",

        scope: 4,
        rpTitle: "entire_sales_summary",

        staffIndex: 0,
        staffId: null,

        includeOrderDetail: true,
        includeTips: true,
        showTaxDetails: false,

        showReport: false,
        showAlert: false,
        shouldPrint: false,
    });

    const {
        fromDate,
        toDate,
        selectedDateButton,
        printers,
        showReport,
        includeOrderDetail,
        includeTips,
        showTaxDetails,
        showAlert,
        exportType,
        scope,
        rpTitle,
        staffIndex,
    } = state;

    const setState = (update: any) =>
        setUseState((prev: any) => ({
            ...prev,
            ...update,
        }));

    // Component Methods
    const handleDateChange = (date: any, dateString: any, which: any) => {
        switch (which) {
            case FROM_TIME:
                setState({
                    fromDate: date,
                    fromDateString: dateString,
                });
                break;
            case TO_TIME:
                setState({
                    toDate: date,
                    toDateString: dateString,
                });
                break;
        }
        setState({ selectedDateButton: 0 });
    };

    const handleTimeChange = (time: any, timeString: any, which: any) => {
        switch (which) {
            case FROM_TIME:
                setState({
                    fromTimeString: timeString,
                });
                break;
            case TO_TIME:
                setState({
                    toTimeString: timeString,
                });
                break;
        }
    };

    const disabledStartDate = (startValue: any) => {
        const endValue = state.toDate;
        const biggerThanToday = startValue.valueOf() > moment(new Date());
        if (!startValue || !endValue) {
            return biggerThanToday;
        }
        return startValue.valueOf() > endValue.valueOf() || biggerThanToday;
    };

    const disabledEndDate = (endValue: any) => {
        const startValue = state.fromDate;
        const biggerThanTomorrow = endValue.valueOf() > moment().add(1, "days");
        if (!endValue || !startValue) {
            return biggerThanTomorrow;
        }
        return endValue.valueOf() <= startValue.valueOf() || biggerThanTomorrow;
    };

    const handleBottomButtonsClick = () => {
        const { staffId, fromDateString, toDateString, fromTimeString, toTimeString, scope, showTaxDetails } = state;

        setState({
            showReport: true,
            fromDate: fromDate ?? moment().local(),
            toDate: toDate ?? moment().add(1, "days").local(),
        });

        dispatch(
            fetchReport({
                scope,
                startDate: fromDateString ? fromDateString + " " + fromTimeString : "",
                endDate: toDateString ? toDateString + " " + toTimeString : "",
                staffId: staffId ? staffId : "",
                showTaxDetails,
            })
        );
    };

    const dateTimePickers = [
        {
            valueDate: fromDate,
            defaultTime: moment("06:00:00", TIME_FORMAT),
            disabledDate: disabledStartDate,
            message: "from",
            type: FROM_TIME,
        },
        {
            valueDate: toDate,
            defaultTime: moment("06:00:00", TIME_FORMAT),
            disabledDate: disabledEndDate,
            message: "to",
            type: TO_TIME,
        },
    ];

    // Render Methods
    const dateTimeButtonsS1 = [
        {
            id: 1,
            content: str("today"),
            onClick: () =>
                setState({
                    fromDate: moment().local(),
                    fromDateString: moment().local().format(DATE_FORMAT),
                    toDate: moment().add(1, "days").local(),
                    toDateString: moment().add(1, "days").local().format(DATE_FORMAT),
                    selectedDateButton: 1,
                }),
        },
        {
            id: 2,
            content: str("yesterday"),
            onClick: () =>
                setState({
                    fromDate: moment().subtract(1, "days").local(),
                    fromDateString: moment().subtract(1, "days").local().format(DATE_FORMAT),
                    toDate: moment().local(),
                    toDateString: moment().local().format(DATE_FORMAT),
                    selectedDateButton: 2,
                }),
        },
        {
            id: 3,
            content: str("this_week"),
            onClick: () =>
                setState({
                    fromDate: moment().subtract(6, "days"),
                    fromDateString: moment().subtract(6, "days").format(DATE_FORMAT),
                    toDate: moment().add(1, "days").local(),
                    toDateString: moment().add(1, "days").local().format(DATE_FORMAT),
                    selectedDateButton: 3,
                }),
        },
    ];

    const dateTimeButtonsS2 = [
        {
            id: 4,
            content: str("last_week"),
            onClick: () =>
                setState({
                    fromDate: moment().subtract(13, "days"),
                    fromDateString: moment().subtract(13, "days").format(DATE_FORMAT),
                    toDate: moment().subtract(6, "days"),
                    toDateString: moment().subtract(6, "days").format(DATE_FORMAT),
                    selectedDateButton: 4,
                }),
        },
        {
            id: 5,
            content: str("this_month"),
            onClick: () =>
                setState({
                    fromDate: moment().startOf("month"),
                    fromDateString: moment().startOf("month").format(DATE_FORMAT),
                    toDate: moment().add(1, "days").local(),
                    toDateString: moment().add(1, "days").local().format(DATE_FORMAT),
                    selectedDateButton: 5,
                }),
        },
        {
            id: 6,
            content: str("last_month"),
            onClick: () =>
                setState({
                    fromDate: moment().subtract(1, "months").startOf("month"),
                    fromDateString: moment().subtract(1, "months").startOf("month").format(DATE_FORMAT),
                    toDate: moment().startOf("month"),
                    toDateString: moment().startOf("month").format(DATE_FORMAT),
                    selectedDateButton: 6,
                }),
        },
    ];

    const scopeDropDownSelection = [
        str("staff_sales_detail"),
        str("entire_store_sales_detail"),
        str("self_order_sales_summary"),
        str("entire_sales_summary"),
    ];

    const scopeDropDownSelectionRawValues = [
        "staff_sales_detail",
        "entire_store_sales_detail",
        "self_order_sales_summary",
        "entire_sales_summary",
    ];

    const staffDropDownSelection = staffList
        ? [str("all")].concat(
              staffList.map((staff) => {
                  return staff["name"];
              })
          )
        : ["all"];

    type DropDownSection = {
        value?: any,
        message?: any,
        selectItems: any[],
        handleChange?: any,
        width?: any,
        widthCondition?: any,
    };

    const dropDownSections: DropDownSection[] = [
        {
            value: scopeDropDownSelection[scope - 1],
            message: "report_type",
            selectItems: scopeDropDownSelection,
            handleChange: (value: any) => {
                setState({
                    scope: scopeDropDownSelection.indexOf(value) + 1,
                    rpTitle: scopeDropDownSelectionRawValues[scopeDropDownSelection.indexOf(value)],
                });
            },
        },
    ];

    /**
     * scope 1: normal_staff_report
     * scope 3: staff_summary
     */
    if (Number(scope) === 1 || Number(scope) === 3) {
        dropDownSections.push({
            value: staffDropDownSelection[staffIndex],
            message: "staff",
            selectItems: staffDropDownSelection,
            handleChange: (value: any) => {
                if (String(value) === str("all")) {
                    setState({ staffId: null, staffIndex: 0 });
                } else {
                    const selectedStaff = staffList.find((staff) => {
                        return staff["name"] === value;
                    });
                    const staffIndex = staffDropDownSelection.indexOf(value);
                    setState({
                        staffId: selectedStaff ? selectedStaff["id"] : null,
                        staffIndex: staffIndex === -1 ? 0 : staffIndex,
                    });
                }
            },
        });
    }

    const switchSections = [
        {
            message: "includes_tips",
            value: includeTips,
            onChange: () => setState({ includeTips: !includeTips }),
        },
        {
            message: "show_tax_details",
            value: showTaxDetails,
            onChange: () => setState({ showTaxDetails: !showTaxDetails }),
        },
    ];

    if (Number(scope) === 1 || Number(scope) === 2) {
        switchSections.push({
            message: "includes_order_detail",
            value: includeOrderDetail,
            onChange: () => setState({ includeOrderDetail: !includeOrderDetail }),
        });
    }

    const bottomButtonsSection = [
        {
            message: "show_report",
            onclick: () => handleBottomButtonsClick(),
        },
    ];

    if (showReport && report && report["start_dt"] && report["end_dt"]) {
        bottomButtonsSection.push({
            message: "print_report",
            onclick: () => handlePrintReport(),
        });
    }

    return {
        staffList,
        report,
        lan,
        storeCurrency,
        state,
        setState,
        fromDate,
        toDate,
        selectedDateButton,
        printers,
        showReport,
        includeOrderDetail,
        includeTips,
        showAlert,
        exportType,
        scope,
        rpTitle,
        staffIndex,
        handleDateChange,
        handlePrintReport,
        handleTimeChange,
        handleBottomButtonsClick,
        dateTimePickers,
        dateTimeButtonsS1,
        dateTimeButtonsS2,
        dropDownSections,
        bottomButtonsSection,
        switchSections,
        loadingState,
    };
};
