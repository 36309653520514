import { Pagination } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import _ from "lodash";
import qs from "querystring";
import { useHistory, useLocation } from "react-router-dom";
import { getProducts } from "../../../../services/products";
import { GIFT_CARD_TYPE } from "../../constants";
import { getCategories } from "../../../../services/categories";

const selector = (state: RootState) => ({
    total: state?.products?.totalProducts,
    lan: state?.setting?.lan,
});

function GiftCardPagination(): JSX.Element {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { total } = useSelector(selector);

    const search = useMemo(() => qs.parse(location.search.slice(1)) ?? {}, [location.search]);

    const getSearch = useCallback(
        (page?: any, limit?: any) => {
            return {
                product_type: GIFT_CARD_TYPE,
                ...search,
                paging: {
                    page: page ?? search?.page ?? 1,
                    limit: limit ?? search?.limit ?? 20,
                },
            };
        },
        [search]
    );

    const paginationProps: PaginationProps = {
        total,
        pageSize: Number(20),
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            const query = _.cloneDeep(search);
            query.page = page.toString();
            if (pageSize) {
                query.limit = pageSize.toString();
            }
            history.push("/giftCards?" + qs.stringify(query));
        },
    };

    useEffect(() => {
        dispatch(getProducts(getSearch()));
        dispatch(getCategories());
    }, [dispatch, getSearch, location.search]);

    return (
        <div className="gift-card-pagination">
            <Pagination {...paginationProps} />
        </div>
    );
}

export default GiftCardPagination;
