import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/reducer';
import { switchNum, formatMoney, parseMoney } from "../../../../../../util/helper";
import { renderEnableTop, renderNumberInput, renderCountriesSelection } from '../shipping-functions'
import { setShippingState } from '../../../../../../slices/store'

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const enable = useSelector((state: RootState) => state.store?.storeShipping?.freeEnable);
    const minOrderAmount = useSelector((state: RootState) => state.store?.storeShipping?.freeMinOrderAmount);
    const shipCountryType = useSelector((state: RootState) => state.store?.storeShipping?.freeShipCountryType);
    const allowCountry = useSelector((state: RootState) => state.store?.storeShipping?.freeAllowCountry);

    const setState = (name: string, value: any) => { dispatch(setShippingState({ name, value })) }
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value) }))

    const renderCountriesSection = (
        <div className="d-flex w100 align-items-center justify-content-between country-selector">
            {renderCountriesSelection("freeShipCountryType", shipCountryType, "freeAllowCountry", allowCountry, setState, intl)}
        </div>
    )

    return (
        <div className="white-layered-block w100 table-rate-tab">
            {renderEnableTop("freeEnable", enable, switchChange)}
            {renderCountriesSection}
            <div className="d-flex flex-wrap w100 mt-3">
                {renderNumberInput("min_order_amount", "freeMinOrderAmount", minOrderAmount, setState, {
                    formatter: (value: any) => formatMoney(value, intl),
                    parser: (value: any) => parseMoney(value, intl)
                })}
            </div>
        </div>
    )
}

export default App;