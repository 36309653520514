import React from "react";
import { Select, Switch, InputNumber, Input, Radio } from "antd";
import { SelectOption } from "./../../../../../components/form";
import { FormattedMessage, IntlShape } from "react-intl";
import { isBool } from "../../../../../util/helper";
import { getCountrySelectOptions, countries } from "../helper";

function emptyFunction() {
    // does nothing
}

export const renderEnableTop = (name: string, value: boolean | any, onChange: any = emptyFunction): JSX.Element => (
    <div className="w100 d-flex align-items-center mb-3">
        <div className="enableSwitch">
            <Switch checked={isBool(value)} onChange={() => onChange(name, value)} />
            <div className="payment-subtitle ml-3 mt-1">
                <FormattedMessage id="enable" />
            </div>
        </div>
    </div>
);

export const renderCountriesSelection = (
    shipName: string,
    shipValue: any = {},
    allowName: string,
    allowValue: string,
    setState: any = emptyFunction,
    intl: IntlShape
): JSX.Element => (
    <div className="d-flex w100 align-items-center justify-content-between country-selector">
        <div className="d-flex justify-content-center align-items-center">
            <div className="text-subhead">
                <FormattedMessage id="ship_to" />:
            </div>
            <Radio.Group
                className="d-flex ml-3"
                onChange={(e: any) => setState(shipName, e.target.value)}
                value={shipValue}
            >
                <Radio value={countries.ALL}>
                    <FormattedMessage id="all_countries" />
                </Radio>
                <Radio value={countries.SPECIFIC}>
                    <FormattedMessage id="specific_countries" />
                </Radio>
            </Radio.Group>
            {shipValue !== countries.SPECIFIC ? null : (
                <div className="d-flex flex-column">
                    <Select
                        className="input-customization d-flex align-items-center country-select"
                        value={allowValue}
                        mode="multiple"
                        onChange={(e: any) => setState(allowName, e)}
                        placeholder={intl.formatMessage({ id: "allowed_countries" })}
                    >
                        {getCountrySelectOptions()?.map((option: SelectOption) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}
        </div>
    </div>
);

export const renderTextInput = (
    title: string,
    name: string,
    value: string,
    onChange: any = emptyFunction,
    disabled?: boolean
): JSX.Element => (
    <div className="w50 d-flex flex-column mb-3 pr-5">
        <div className="payment-subtitle mb-1">
            <FormattedMessage id={title ?? " "} />
        </div>
        <Input
            value={value}
            className="input-customization mr-5 w100"
            onChange={(e: any) => onChange(name, e.target.value)}
            disabled={disabled ?? false}
        />
    </div>
);

export const renderNumberInput = (
    title: string,
    name: string,
    value: number | any,
    onChange: any = emptyFunction,
    extra = {},
    disabled?: boolean
): JSX.Element => (
    <div className="w50 d-flex flex-column mb-3 pr-5">
        <div className="payment-subtitle mb-1">
            <FormattedMessage id={title ?? " "} />
        </div>
        <InputNumber
            defaultValue={0}
            value={value}
            className="input-customization mr-5 w100"
            onChange={(e: any) => onChange(name, e)}
            disabled={disabled ?? false}
            {...extra}
        />
    </div>
);

export const renderSelectInput = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction,
    options: Array<any> | any
): JSX.Element => (
    <div className="w50 d-flex flex-column mb-3 pr-5">
        <div className="payment-subtitle mb-1">
            <FormattedMessage id={title ?? " "} />
        </div>
        <Select
            className="input-customization mr-5 w100"
            defaultValue={options[0]}
            value={value}
            onChange={(e: any) => onChange(name, e)}
        >
            {options?.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderMultiSelectInput = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction,
    options: Array<any> | any
): JSX.Element => (
    <div className="w50 d-flex flex-column mb-3 pr-5">
        <div className="payment-subtitle mb-1">
            <FormattedMessage id={title ?? " "} />
        </div>
        <Select
            className="multi-ship-input input-customization mr-5 w100"
            value={value}
            mode="multiple"
            onChange={(e: any) => onChange(name, e)}
        >
            {options?.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);
