const StoreBasic = `
    store_flg,          price_range,                        false,          14;
    store_flg,          value_to_points,                    false,          15;
    store_flg,          points_to_value,                    false,          16;
    store_flg,          allow_online_order,                 false,          38;
    store_flg,          allow_preorder_when_closed,         false,          10;
    store_flg,          preorder_x_time_before_open,        false,          10;
    store_flg,          stop_x_time_before_close,           false,          40;
    store_flg,          unpaid_cancellation_time,           false,          56;
    store_flg,          allow_upselling,                    false,          9;
    store_flg,          allow_qr_checkout,                  false,          48;
    store_flg,          require_utensil,                    false,          68;
    store_flg,          print_utensil,                      false,          69;
`

export default StoreBasic;