import { Button, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import { getStoreDetails, updateStoreDetails } from "./../../../../services/store";
import ObjectModel from "./../../../../util/models";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import StorePublicNotice from "../../../../util/models/store-public-notice";
import config from "../../../../config";
import "./index-mobile.scss";
import helper from "../../../../util/helper";
import { DINE_IN, IN_STORE } from "../../../../app/constants";

const breadcrumb = {
    routes: [
        {
            path: "",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "/settings",
            breadcrumbName: "settings_overview",
        },
        {
            path: "/settings/publicNotice",
            breadcrumbName: "public_notice_setting",
        },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const publicAnnouncement = useSelector((state: RootState) => state.store?.storePublicNotice);
    const storePickupDelivery = useSelector((state: RootState) => state.store?.storePickupDelivery);
    const languages = useSelector((state: RootState) => state?.store?.storeDisplayOptions?.product_display_languages);
    const records = useSelector((state: RootState) => state.store?.records);
    const storeGid = useSelector((state: RootState) => state?.store?.storeInformation?.g_id);
    const managedStores = useSelector((state: RootState) => state?.admin?.profile?.managed_store);
    const { lan, getModalLanguageSelect } = useLanguageSelect();
    const [form, setFormData] = useState<any>([]);

    const allowedMethods: any = useMemo(
        () => ({
            [Number(config.SHIPPING_MAPPING_TO_NUMERIC.delivery)]: storePickupDelivery.allow_delivery,
            [Number(config.SHIPPING_MAPPING_TO_NUMERIC.pickup)]: storePickupDelivery.allow_takeout,
            [Number(config.SHIPPING_MAPPING_TO_NUMERIC.eatin)]: storePickupDelivery.allow_eatin,
        }),
        [storePickupDelivery]
    );

    const shippingMethods = useMemo(() => [1, 2, 3], []);

    const onSave = () => {
        const newPublicAnnouncement = _.cloneDeep(publicAnnouncement);

        newPublicAnnouncement.store_pn = form
            .filter((f: any) => f?.type?.length > 0)
            .map((f: any) => {
                const msg: any = _.cloneDeep(f.msg);
                const valid = msg?.[Object.keys(msg)?.find((key) => msg[key]) ?? ""] ?? "";
                languages.forEach((key: any) => {
                    if (!msg[key]) {
                        msg[key] = valid;
                    }
                });
                return {
                    ...f,
                    msg,
                };
            });

        dispatch(
            updateStoreDetails(
                new ObjectModel(StorePublicNotice).convertToPostData(
                    newPublicAnnouncement,
                    records,
                    "public_notice_setting"
                )
            )
        );
    };

    const getShippingMethodsAsOptions = useCallback(() => {
        const currentStore = helper.getStoreWithGid(storeGid, managedStores);
        return Object.keys(config.SHIPPING_MAPPING_TO_NUMERIC)
            ?.map((key) => {
                // @ts-ignore
                const value = Number(config.SHIPPING_MAPPING_TO_NUMERIC[key]);
                if (key === "eatin") {
                    key = helper.isRestaurant(currentStore) ? DINE_IN : IN_STORE;
                }
                return {
                    value,
                    label: key,
                };
            })
            .filter((option) => shippingMethods.includes(Number(option.value)))
            .filter((option) => Number(allowedMethods[Number(option.value)]) === 1);
    }, [shippingMethods, allowedMethods]);

    const getUsedTypes = () => {
        return form
            .reduce((arr: any, val: any) => {
                return arr.concat(val.type);
            }, [])
            ?.map((val: any) => Number(val));
    };

    const addType = () => {
        setFormData([
            ...form,
            {
                type: [],
                msg: {},
            },
        ]);
    };

    const deleteType = (r: any) => {
        setFormData(form.filter((f: any) => !_.isEqual(f, r)));
    };

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    useEffect(() => {
        // Filter out unavailable/empty types.
        const pns = publicAnnouncement?.store_pn
            .map((pn: any = {}) => {
                const sm = getShippingMethodsAsOptions();
                const type = pn.type?.filter((t: any) => !_.isEmpty(sm?.find((s) => Number(s.value) === Number(t))));

                return {
                    ...pn,
                    type,
                };
            })
            .filter((pn: any = {}) => pn.type?.length);

        if (pns.length) {
            setFormData(pns);
        } else {
            setFormData([
                {
                    type: [],
                    msg: {},
                },
            ]);
        }
    }, [getShippingMethodsAsOptions, publicAnnouncement]);

    const renderFormItem = (f: any, i: any) => {
        // Logic for whether to show button for adding another public notice. Will prevent duplicate types and empty records.
        const isLastRemainingOption = getShippingMethodsAsOptions().length - getUsedTypes().length === 1;
        const hasRemainingOptions = getUsedTypes().length < getShippingMethodsAsOptions().length;
        const isLast = i === form.length - 1;
        const isLastWithOneRemainingOption = isLast && isLastRemainingOption && f.type?.length === 0;
        const hasAtleastOneType = f.type?.length > 0;
        const showAdd = hasRemainingOptions && isLast && !isLastWithOneRemainingOption && hasAtleastOneType;

        const renderButtons = () => (
            <div className="setting-actions" style={{ marginRight: "10px", marginTop: "30px" }}>
                {form.length !== 1 ? (
                    <Button type="primary" size="large" onClick={() => deleteType(f)}>
                        <FormattedMessage id="delete" />
                    </Button>
                ) : null}
                {showAdd ? (
                    <Button type="primary" size="large" onClick={addType} style={{ marginLeft: 12 }}>
                        <FormattedMessage id="add" />
                    </Button>
                ) : null}
            </div>
        );

        const getTypeSelectOptions = () =>
            getShippingMethodsAsOptions()
                .filter((shipping) => {
                    return (
                        !getUsedTypes()?.includes(Number(shipping.value)) || f?.type?.includes(Number(shipping.value))
                    );
                })
                ?.map((shipping) => (
                    <Select.Option key={`select-order-type-options-${shipping.label}`} value={Number(shipping.value)}>
                        <FormattedMessage id={shipping.label} />
                    </Select.Option>
                ));

        const renderTypeSelect = () => (
            <Select
                mode="multiple"
                value={f.type.map((i: any) => Number(i))}
                placeholder={<FormattedMessage id="select_order_types" />}
                onChange={(value) => {
                    const newForm = _.cloneDeep(form);

                    _.set(newForm[i], `type`, value);

                    setFormData(newForm);
                }}
                className="select-order-types"
            >
                {getTypeSelectOptions()}
            </Select>
        );

        const renderPNTextArea = () => (
            <Input.TextArea
                style={{ marginLeft: "14px", marginRight: "14px" }}
                value={f?.msg?.[lan]}
                placeholder={intl.formatMessage({ id: "type_here" })}
                maxLength={220}
                showCount
                rows={4}
                allowClear={true}
                onChange={(e) => {
                    const value = e.target.value;
                    const newForm = _.cloneDeep(form);

                    _.set(newForm[i], `msg.${lan}`, value);

                    setFormData(newForm);
                }}
            />
        );

        return (
            <React.Fragment key={i}>
                {renderTypeSelect()}
                {renderPNTextArea()}
                {renderButtons()}
            </React.Fragment>
        );
    };

    const renderForm = () => (
        <React.Fragment>
            <div>
                <p className="ant-descriptions-description">
                    <FormattedMessage id="public_notice_setting_description" />
                </p>
            </div>
            {form?.map(renderFormItem)}
            <div className="setting-actions" style={{ marginRight: "10px", marginTop: "30px" }}>
                <Button type="primary" size="large" onClick={() => onSave()} className="save-button-handler">
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        </React.Fragment>
    );

    return !_.isEmpty(publicAnnouncement) ? (
        <Wrapper helmet={{ title: "public_notice_setting" }} breadcrumb={breadcrumb}>
            <div className="public-notice-page">
                <div className="setting-actions">{getModalLanguageSelect()}</div>
                {renderForm()}
            </div>
        </Wrapper>
    ) : null;
}

export default App;
