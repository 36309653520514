import React, { Component } from "react";
import _helper, { getDeliveryServiceProvider } from "util/helper";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import _actionsOrders from "slices/live-order";
import SurchargeDialog from "../order-surcharge-dialog";
import { connect } from "react-redux";
import "./_orderDetails.scss";
import TipsDialog from "../order-tips-dialog";
import cx from "classnames";
import {
    isOnlinePay,
    formatOrderId,
    getFormattedDistance,
    getOrderRewardPoints,
    getOrderStatusNumber,
    getOrderRewardBalance,
} from "pages/order-history/helper";
import { Button, withStyles } from "@material-ui/core";
import { isGroupedOrder, isLiveOrder } from "../../helper";
import config from "config";
import helper from "util/helper";

const StyledButton = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        borderColor: "#1890ff",
        color: "#1890ff",
        "&:hover": {
            color: "#016cd0",
            borderColor: "#016cd0",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            color: "#016cd0",
            borderColor: "#016cd0",
        },
    },
})(Button);

class OrderDetailTotal extends Component {
    static defaultProps = {
        status: 1,
        ordShippingMethod: 1,
        subTotal: 0,
        tax: 0,
        totalOrder: 0,
        totalPaid: 0,
        totalDue: 0,
        storeDis: 0,
        cpnDis: 0,
        totalDis: 0,
        orderItems: [],
        deliveryFee: 0,
        giftCardPayment: 0,
        giftCardCode: 0,
        pointsUsed: 0,
        pointsPayment: 0,
        currentSection: "order-detail",
        showBackground: true,
        creditCard: null,
    };

    getStyle = (props = this.props) => _.get(props, "state.listStyle", "list");

    isListStyle = () => {
        return _.get(this.props, `state.listStyle`, "list") === "list";
    };

    getOrderProperty = (key) => {
        return _.get(this.props, `order.${key}`, 0);
    };

    getDistance = () => {
        return getFormattedDistance(_.get(this.props, `order`));
    };

    getStoreSurcharge = () => {
        const { order } = this.props;
        return {
            value: _.get(order, "store_surcharge.value", 0),
            label: _helper.getTransString(_.get(order, "store_surcharge.label", {}), this.props.lan) || (
                <FormattedMessage id="store_surcharge" />
            ),
        };
    };

    getOrderSucharge = () => {
        const { order } = this.props;
        return {
            value: _.get(order, "order_surcharge.value", 0),
            label: _helper.getTransString(_.get(order, "order_surcharge.label", {}), this.props.lan) || (
                <FormattedMessage id="order_surcharge" />
            ),
            tax: _.get(order, "order_surcharge.tax_class", 0),
            inTotal: _.get(order, "order_surcharge.is_separate", 0) === 0,
        };
    };

    renderControlPanel = () => {
        return isLiveOrder(this.props.order) ? (
            <div className="live-order-order-detail-total-panel-buttons">
                {this.renderSurchargeButton()}
                {!isOnlinePay(this.props.order) ? <div> {this.renderTipsButton()} </div> : ""}
            </div>
        ) : null;
    };

    renderSurchargeButton = () => {
        const buttonText = this.getOrderSucharge().value ? "modify_surcharge" : "add_surcharge";
        const disabled = isGroupedOrder(this.props.order) && this.getOrderSucharge().value;
        return (
            <StyledButton
                variant="outlined"
                className="modify-button"
                color="secondary"
                disabled={disabled}
                onClick={() =>
                    this.props.setState({
                        showSurchargeDialog: true,
                    })
                }
            >
                <b>
                    <FormattedMessage id={buttonText} />
                </b>
            </StyledButton>
        );
    };

    renderTipsButton = () => {
        const tipsText = this.getOrderProperty("tips") ? "modify_tips" : "add_tips";
        const disabled = isGroupedOrder(this.props.order) && this.getOrderProperty("tips");
        return (
            <StyledButton
                variant="outlined"
                className="modify-button"
                color="secondary"
                disabled={disabled}
                onClick={() =>
                    this.props.setState({
                        showTipsDialog: true,
                    })
                }
            >
                <b>
                    <FormattedMessage id={tipsText} />
                </b>
            </StyledButton>
        );
    };

    renderOrderSurcharge = (withPaidInfo = false) => {
        const label = this.getOrderSucharge().label;
        const value = this.getOrderSucharge().value;
        const relatedOrderId = this.getOrderProperty("related_order_id");

        const renderSurchargeInfo = () => {
            return (
                <div className="live-order-total-line">
                    <div
                        className={cx({
                            "live-order-total-line-title": true,
                            "live-order-total-line-title-list": this.isListStyle(),
                        })}
                    >
                        {label || <FormattedMessage id="order_surcharge" />}
                        {withPaidInfo ? (
                            <span>
                                (<FormattedMessage id={relatedOrderId ? "paid" : "unpaid"} />)
                            </span>
                        ) : null}
                    </div>
                    <div>{_helper.formatCurrency(Math.abs(value), this.props.storeCurrency)}</div>
                </div>
            );
        };

        const renderSurchargePaidMessage = () => {
            return withPaidInfo && relatedOrderId ? (
                <div className="order-surcharge-message">
                    <FormattedMessage
                        id="surcharege_paid_message"
                        values={{
                            id: <span className="link">{relatedOrderId}</span>,
                        }}
                    />
                </div>
            ) : null;
        };

        return value ? (
            <div>
                {renderSurchargeInfo()}
                {renderSurchargePaidMessage()}
            </div>
        ) : null;
    };

    renderSurchargeRelatedMessage = () => {
        const { order_surcharge, related_order_id, currentSection } = this.props;
        const showMessage = !order_surcharge && related_order_id;
        return showMessage ? (
            <div
                style={{ paddingTop: 5 }}
                onClick={() => {
                    if (currentSection === "order-detail") this.props.fetchOrderDetail({ orderId: related_order_id });
                }}
                className="dash-line-top order-surcharge-message"
            >
                <FormattedMessage
                    id="surcharge_paid_to_message"
                    values={{
                        id: <span className="link">{formatOrderId(related_order_id)}</span>,
                    }}
                />
            </div>
        ) : null;
    };

    renderRewardPointsSection = () => {
        const points = getOrderRewardPoints(this.props.order);
        const balance = getOrderRewardBalance(this.props.order);
        const showMessage =
            getOrderStatusNumber(this.props.order) !== config.ORDER_STATUS_MAPPING_TO_NUMERIC.completed && points;
        const showSection = points || balance;

        const renderExtraMessage = () => {
            return showMessage ? (
                <div className="order-reward-points-extra-message">
                    <FormattedMessage id="reward_points_extra_admin" />
                </div>
            ) : null;
        };

        const renderLineItem = (label, value, suffix) => {
            return (
                <div
                    className={cx({
                        "live-order-total-line": true,
                        "content-text": true,
                    })}
                >
                    <div
                        className={cx({
                            "live-order-total-line-title": true,
                            "live-order-total-line-title-list": this.isListStyle(),
                        })}
                    >
                        <FormattedMessage id={label} />
                    </div>
                    <div
                        className={cx({
                            "live-order-total-line-prices": true,
                            "live-order-total-line-prices-list": this.isListStyle(),
                        })}
                    >
                        {suffix || ""}
                        {_helper.formatNumber(value)}
                    </div>
                </div>
            );
        };

        return showSection ? (
            <div
                style={{ paddingTop: 2 }}
                onClick={() => this.directToRelatedOrder()}
                className="dash-line-top order-reward-section"
            >
                {points ? renderLineItem("reward_points", points, "+") : null}
                {balance ? renderLineItem("points_balance", balance) : null}
                {renderExtraMessage()}
            </div>
        ) : null;
    };

    getPricesData = () => {
        let data = [
            {
                hide: !(
                    (this.getOrderSucharge().value && this.getOrderSucharge().inTotal) ||
                    this.getStoreSurcharge().value
                ),
                key: "item_ttl",
                label: "item_total",
            },
            {
                key: "store_surcharge",
                value: this.getStoreSurcharge().value,
                label: this.getStoreSurcharge().label,
            },
            {
                key: "order_surcharge",
                hide: !(this.getOrderSucharge().inTotal && this.getOrderSucharge().value),
                label: this.getOrderSucharge().label,
                value: this.getOrderSucharge().value,
            },
            {
                key: "sb_ttl",
                label: "subtotal",
                default: true,
                bold: true,
            },
        ];

        data.push(
            {
                key: "store_dis",
                label: "store_discount",
                prefix: "-",
            },
            {
                key: "cpn_dis",
                label: "coupon_discount",
                prefix: "-",
            }
        );

        if (helper.isDelivery(this.props.shippingMethod) || this.getOrderProperty("dy_fee")) {
            const deliveryServiceProvider = getDeliveryServiceProvider(this.props.store);
            const distance = this.getDistance();

            data.push({
                key: "dy_fee",
                label:
                    distance &&
                    deliveryServiceProvider !== config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.third_party_courier
                        ? "shipping_and_handling_with_distance"
                        : "shipping_and_handling",
                labelKeywords: { distance },
                default: true,
            });
        }

        //total before tax
        const showTotalBeforeTax = this.getOrderProperty("ttl_b4_tax") !== this.getOrderProperty("sb_ttl");
        if (showTotalBeforeTax) {
            data.push({
                key: "ttl_b4_tax",
                label: "total_before_tax",
                default: true,
            });
        }

        //taxes
        const taxes = this.getOrderProperty("taxes");
        if (!_.isEmpty(taxes) && _.isEmpty(this.getNewPricesData())) {
            Object.keys(taxes).forEach((key) => {
                const value = parseFloat(taxes[key]);
                if (!Number.isNaN(value)) data.push({ value: value, label: key });
            });
        } else {
            data.push({
                key: "tax",
                label: "tax",
            });
        }

        data.push(
            {
                key: "tips",
                label: "tips",
            },
            {
                key: "gc_amt",
                label: "giftcard_payment_with_giftcard",
                labelKeywords: { card: formatOrderId(this.getOrderProperty("gc_cd")) },
                prefix: "-",
            },
            {
                key: "pt_amt",
                label: "points_payment_with_points",
                labelKeywords: { points: helper.formatNumber(this.getOrderProperty("pt_num")) },
                prefix: "-",
            },
            {
                key: "ord_ttl",
                label: "order_total",
                default: true,
            },
            {
                key: "total_paid",
                label: "ttl_paid",
                prefix: "-",
            },
            {
                key: "rf_amt",
                label: "total_refunded",
            },
            {
                key: "ttl_due",
                label: "total_due",
                default: true,
                bold: true,
            }
        );
        return data;
    };

    getNewPricesData = () => {
        const orderId = this.getOrderProperty("ord_id");
        const apiChangesPrices = _.isEmpty(this.getOrderProperty("item_changes"))
            ? {}
            : this.getOrderProperty("item_change_summary");
        const localChangesPrices = this.props.newPrices[orderId];
        const result = localChangesPrices ? localChangesPrices : apiChangesPrices ? apiChangesPrices : {};
        return result;
    };

    renderPriceLine = (priceObject, key) => {
        const storeCurrency = this.props.storeCurrency;
        const value = Math.abs(priceObject.value || this.getOrderProperty(priceObject.key));
        const prefix = priceObject.prefix;
        const newPrice = Math.abs(this.getNewPricesData()[priceObject.key]);
        const show = !priceObject.hide && (priceObject.default || value || newPrice);
        const label = priceObject.label ? priceObject.label : " ";
        const displayNewPrice = newPrice || newPrice === 0;
        if (show)
            return (
                <div key={key} className={`live-order-total-line ${priceObject.bold ? "content-text" : ""}`}>
                    <div
                        className={cx({
                            "live-order-total-line-title": true,
                            "live-order-total-line-title-list": this.isListStyle(),
                        })}
                    >
                        <FormattedMessage id={label} values={priceObject.labelKeywords} />
                    </div>
                    <div
                        className={cx({
                            "live-order-total-line-prices": true,
                            "live-order-total-line-prices-list": this.isListStyle(),
                        })}
                    >
                        <div className={displayNewPrice ? "cross" : ""}>
                            {_helper.getCurrencyFormattedString(value, storeCurrency, prefix)}
                        </div>
                        {displayNewPrice ? (
                            <div className="live-order-new-price">
                                {_helper.getCurrencyFormattedString(newPrice, storeCurrency, prefix)}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            );

        if (priceObject.componnent && !priceObject.hide) {
            return priceObject.componnent;
        }
    };

    render() {
        return (
            <div className="live-order-two-column-column">
                <div className="live-order-order-totals">
                    {this.getPricesData().map((obj, key) => this.renderPriceLine(obj, key))}
                    {!this.getOrderSucharge().inTotal && (
                        <div className="content-text dash-line-top">{this.renderOrderSurcharge(true)}</div>
                    )}
                    {this.renderSurchargeRelatedMessage()}
                    {this.renderRewardPointsSection()}
                    {this.renderControlPanel()}
                </div>
                <SurchargeDialog
                    {...{
                        ...this.props,
                        order_surcharge: this.getOrderSucharge().value,
                        orderId: this.getOrderProperty("ord_id"),
                        order_surcharge_tax_class: this.getOrderSucharge().tax,
                    }}
                />
                <TipsDialog
                    {...{
                        ...this.props,
                        tips: this.getOrderProperty("tips"),
                        orderId: this.getOrderProperty("ord_id"),
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    lan: _.get(state, "setting.lan", "en"),
    newPrices: _.get(state, "liveOrders.newPrices", {}),
    state: _.get(state, "liveOrders", {}),
    store: _.get(state, "store", {}),
    shippingMethod: _.get(ownProps, "order.shp_mtd", null),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    fetchOrderDetail: _actionsOrders.fetchOrderDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetailTotal));
