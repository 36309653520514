import React, { useEffect, useState } from "react";
import { Button, InputNumber, Radio, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "../../../../app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { editProducts } from "../../../../services/products";
import config from "../../../../config";
import { Product } from "../../../../slices/products";
import helper from "../../../../util/helper";

const selector = (state: RootState) => ({
    product: state?.products?.product,
    lan: state?.setting?.lan,
    categories: state?.categories?.categories,
});

enum Methods {
    BY_AMOUNT = "by_amount",
    BY_PERCENTAGE = "by_percentage",
    SET_AMOUNT = "set_amount",
}

enum Actions {
    ADD,
    SUBTRACT,
    SET,
}

enum Round {
    NONE = "no_rounding",
    ZERO_DECIMAL = "round_to_dollar",
    ONE_DECIMAL = "round_to_one_decimal",
    TWO_DECIMAL = "round_to_two_decimals",
}
const DEFAULT_VALUE = 1;

interface BulkEditProps {
    type: any;
    pids: any;
    getOptions: any;
    onClose: any;
}

function BulkEdit(props: BulkEditProps): JSX.Element {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [value, setValue] = useState<any>(1);
    const [method, setMethod] = useState<Methods>(Methods.BY_PERCENTAGE);
    const [action, setAction] = useState<Actions>(Actions.ADD);
    const [round, setRound] = useState<Round>(Round.NONE);
    const { categories, lan } = useSelector(selector);

    const onSubmit = () => {
        if (props?.type === "price") {
            dispatch(
                editProducts(
                    props?.pids,
                    {
                        [props?.type]: value,
                    },
                    {},
                    undefined,
                    {
                        price(_: any, record: Product) {
                            let price = Number(record?.price);

                            if (action === Actions.SET) {
                                return _;
                            } else {
                                if (action === Actions.ADD) {
                                    if (method === Methods.BY_AMOUNT) {
                                        price = price + Number(value);
                                    } else if (method === Methods.BY_PERCENTAGE) {
                                        price = price + price * (Number(value) / 100);
                                    }
                                } else {
                                    if (method === Methods.BY_AMOUNT) {
                                        price = price - Number(value);
                                    } else if (method === Methods.BY_PERCENTAGE) {
                                        price = price - price * (Number(value) / 100);
                                    }
                                }
                            }

                            if (round === Round.ZERO_DECIMAL) {
                                price = Math.round(price);
                            }

                            if (round === Round.ONE_DECIMAL) {
                                price = Math.round(price * 10) / 10;
                            }

                            if (round === Round.TWO_DECIMAL) {
                                price = Math.round(price * 100) / 100;
                            }

                            return price;
                        },
                    }
                )
            );
        } else {
            dispatch(
                editProducts(props?.pids, {
                    [props?.type]: value,
                })
            );
        }
        setValue("");
        props.onClose();
    };

    const getPriceInput = () => {
        const options = (
            <>
                <Select.Option key={Methods.BY_AMOUNT} value={Methods.BY_AMOUNT}>
                    {intl.formatMessage({ id: Methods.BY_AMOUNT })}
                </Select.Option>
                <Select.Option key={Methods.BY_PERCENTAGE} value={Methods.BY_PERCENTAGE}>
                    {intl.formatMessage({ id: Methods.BY_PERCENTAGE })}
                </Select.Option>
            </>
        );
        return props?.type === "price" ? (
            <div>
                <div className="bulk-label">
                    <FormattedMessage id="increase_or_decrease_message" />
                </div>
                <div className="bulk-content">
                    <Radio.Group value={action} onChange={(e) => setAction(e?.target?.value)}>
                        <Radio value={Actions.ADD}>
                            <FormattedMessage id="increase" />
                        </Radio>
                        <Radio value={Actions.SUBTRACT}>
                            <FormattedMessage id="decrease" />
                        </Radio>
                        <Radio value={Actions.SET}>
                            <FormattedMessage id="set_amount" />
                        </Radio>
                    </Radio.Group>
                </div>
                {action !== Actions.SET ? (
                    <div className="bulk-label">
                        <FormattedMessage id="choose_percentage_amount" />
                    </div>
                ) : null}
                {action !== Actions.SET ? (
                    <div className="bulk-content">
                        <Select
                            value={method}
                            onChange={(v) => {
                                setMethod(v);
                            }}
                            style={{ width: "100%" }}
                        >
                            {options}
                        </Select>
                    </div>
                ) : null}
                <div className="bulk-label">
                    <FormattedMessage id="enter_the_value" />
                </div>
                <div className="bulk-content">
                    <InputNumber
                        value={value}
                        onChange={(v) => setValue(v)}
                        style={{ width: "100%" }}
                        placeholder={intl.formatMessage({ id: "amount" })}
                    />
                </div>
                {action !== Actions.SET ? (
                    <div className="bulk-label">
                        <FormattedMessage id="select_to_round" />
                    </div>
                ) : null}
                {action !== Actions.SET ? (
                    <div className="bulk-content">
                        <Radio.Group value={round} onChange={(e) => setRound(e?.target?.value)}>
                            <Radio value={Round.NONE}>
                                <FormattedMessage id={Round.NONE} />
                            </Radio>
                            <Radio value={Round.ZERO_DECIMAL}>
                                <FormattedMessage id={Round.ZERO_DECIMAL} />
                            </Radio>
                            <Radio value={Round.ONE_DECIMAL}>
                                <FormattedMessage id={Round.ONE_DECIMAL} />
                            </Radio>
                            <Radio value={Round.TWO_DECIMAL}>
                                <FormattedMessage id={Round.TWO_DECIMAL} />
                            </Radio>
                        </Radio.Group>
                    </div>
                ) : null}
            </div>
        ) : null;
    };

    const getCategoriesAsOptions = () => {
        // Only allow child categories
        return [
            ...(categories
                .filter((c) => c.level > config.CATEGORY_LEVELS.root)
                .map((c) => ({
                    value: c.category_id,
                    label: helper.getTransString(c.name, lan),
                })) ?? []),
        ];
    };

    const getCategoriesInput = () => {
        const children = getCategoriesAsOptions().map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
                {option.label}
            </Select.Option>
        ));

        return props?.type === "category_ids" ? (
            <Select
                mode="multiple"
                value={value}
                onChange={(v) => {
                    setValue(v);
                }}
                style={{ width: "100%" }}
            >
                {children}
            </Select>
        ) : null;
    };

    const getNumberInput = () => {
        return props?.type === "stock" ? (
            <InputNumber
                value={value}
                onChange={(v) => setValue(v)}
                style={{ width: "100%" }}
                placeholder={intl.formatMessage({ id: "amount" })}
            />
        ) : null;
    };

    const getTaxInput = () => {
        const children = props?.getOptions(config.TAX_CLASS_OPTIONS).map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
                {option.label}
            </Select.Option>
        ));

        return props?.type === "tax_class" ? (
            <Select
                value={value}
                onChange={(v) => {
                    setValue(v);
                }}
                style={{ width: "100%" }}
            >
                {children}
            </Select>
        ) : null;
    };

    const getAvailabilityInput = () => {
        const children = props?.getOptions(config.AVAILABILITY_OPTIONS).map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
                {option.label}
            </Select.Option>
        ));

        return props?.type === "availability" ? (
            <Select
                value={value}
                onChange={(v) => {
                    setValue(v);
                }}
                style={{ width: "100%" }}
            >
                {children}
            </Select>
        ) : null;
    };

    useEffect(() => {
        if (props?.type === "availability") {
            setValue(DEFAULT_VALUE);
        } else if (props?.type === "category_ids") {
            setValue([]);
        } else {
            setValue(DEFAULT_VALUE);
        }
    }, [props]);

    return (
        <div style={{ position: "relative" }}>
            {getPriceInput()}
            {getNumberInput()}
            {getAvailabilityInput()}
            {getTaxInput()}
            {getCategoriesInput()}
            <div className="footer-item-changes">
                <Button type="primary" onClick={onSubmit}>
                    <FormattedMessage id="apply" />
                </Button>
            </div>
        </div>
    );
}

export default BulkEdit;
