import React, { useEffect, useRef, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { AgGridReact } from "ag-grid-react";
import { setSelected } from "../../../../slices/customers";
import CustomerGiftCardAccountValueRenderer from "./customer-gift-card-account-value-renderer";
import CustomerGiftCardAccountCurrencyRenderer from "./customer-gift-card-account-currency-renderer";
import CustomerGiftCardAccountActionMenuRenderer from "./customer-gift-card-account-action-menu-renderer";

const selector = (state: RootState) => ({
    customerFirstName: state?.giftcards?.customerFirstName,
    customerLastName: state?.giftcards?.customerLastName,
    customerPhone: state?.giftcards?.customerPhone,
    customerEmail: state?.giftcards?.customerEmail,
});

const COLUMN_WIDTHS = {
    NAME_MIN: 190,
    CODE_MIN: 130,
    BALANCE_MIN: 90,
    BOOK_MIN: 90,
    CREATED_DATE_MIN: 110,
    UPDATED_DATE_MIN: 170,
    ACTIONS_MIN: 175,
};

function CustomerGiftCardsTable(): JSX.Element {
    const { customerFirstName, customerLastName, customerPhone, customerEmail } = useSelector(selector);
    const accounts = useSelector((state: RootState) => state?.giftcards?.accounts);
    const tableRef = useRef<any>(null);
    const intl = useIntl();
    const dispatch = useDispatch();

    let gridApi: any;

    const getColumnDefs = () => {
        return [
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.NAME_MIN,
                headerName: intl.formatMessage({ id: "giftcard_name" }),
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.product_name;
                    },
                },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.CODE_MIN,
                headerName: intl.formatMessage({ id: "giftcard_code" }),
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.code;
                    },
                },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.BALANCE_MIN,
                headerName: intl.formatMessage({ id: "balance" }),
                cellRenderer: "currencyRenderer",
                cellClass: "right-align",
                type: "numericColumn",
                cellRendererParams: { name: "balance" },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.BOOK_MIN,
                headerName: intl.formatMessage({ id: "book_value" }),
                cellRenderer: "currencyRenderer",
                cellClass: "right-align",
                type: "numericColumn",
                cellRendererParams: { name: "book_value" },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.CREATED_DATE_MIN,
                headerName: intl.formatMessage({ id: "date_created" }),
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.date_created;
                    },
                },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.UPDATED_DATE_MIN,
                headerName: intl.formatMessage({ id: "last_updated" }),
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.last_updated;
                    },
                },
            },
            {
                field: "giftcardaccount_id",
                minWidth: COLUMN_WIDTHS.ACTIONS_MIN,
                headerName: intl.formatMessage({ id: "action" }),
                cellRenderer: "actionsRenderer",
                cellRendererParams: {
                    CustomerGiftCardAccountActionMenuRenderer,
                },
            },
        ];
    };

    const getTableObject: any = () => ({
        columnDefs: getColumnDefs(),
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: CustomerGiftCardAccountValueRenderer,
            currencyRenderer: CustomerGiftCardAccountCurrencyRenderer,
            actionsRenderer: CustomerGiftCardAccountActionMenuRenderer,
        },
        rowData: accounts,
        getRowHeight: () => 50,
        stopEditingWhenCellsLoseFocus: true,
        singleClickEdit: true,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        getRowNodeId: (row: any) => row?.id,
        immutableData: true,
        onSelectionChanged: (event: any) => {
            dispatch(setSelected(event?.api?.getSelectedNodes().map((node: any) => node?.data)));
        },
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const onResize = () => {
        if (tableRef?.current?.firstChild?.style?.height) {
            tableRef.current.firstChild.style.height = tableRef?.current?.offsetHeight + "px";
        }
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const title = () => (
        <div className="d-flex mb-3">
            <div className="mr-3">
                <FormattedMessage id="customer_name" />:{" "}
                <b>
                    {customerFirstName ? customerFirstName : "Name"} {customerLastName ? customerLastName : "Surname"}
                </b>
            </div>
            <div className="mr-3">
                <FormattedMessage id="phone" />: <b>{customerPhone ? customerPhone : "Phone"}</b>
            </div>
            <div className="mr-3">
                <FormattedMessage id="email" />: <b>{customerEmail ? customerEmail : "Email"}</b>
            </div>
        </div>
    );

    const renderTable = () => (
        <div className="customer-gift-cards-table-container ag-theme-alpine">
            {title()}
            <AgGridReact {...getTableObject()}></AgGridReact>
        </div>
    );

    return <div>{accounts?.length ? renderTable() : null}</div>;
}

export default CustomerGiftCardsTable;
