import { Modal } from "antd";
import React, { ReactChild, ReactNode } from "react";
import { AnyStyle } from "../../constants";

interface ModalsProps {
    title: ReactNode;
    handleOk?: () => void;
    handleCancel?: () => void;
    shouldOpen: boolean;
    children: ReactChild | ReactNode;
    footer?: ReactChild | ReactNode;
    width?: number | string | undefined;
    bodyStyle?: AnyStyle;
    zIndex?: number;
    okButtonProps?: AnyStyle;
    cancelButtonProps?: AnyStyle;
    closable?: boolean;
}

/**
 * @usage
 * import { ModalsComponent } from "pages/delivery_request/_components"
 * ...
 * <ModalsComponent
 *  title={string}
 *  handleOk={() => void}
 *  handleCancel={() => void}
 *  showOpen={boolean}
 * >
 * {children} // content on modal JSX / ReactNode
 * </ModalsComponent>
 */
const Modals: React.FC<ModalsProps> = ({
    footer,
    children,
    shouldOpen,
    handleOk,
    handleCancel,
    title,
    width,
    zIndex,
    okButtonProps,
    cancelButtonProps,
    closable,
}) => {
    return (
        <Modal
            closable={closable}
            visible={shouldOpen}
            bodyStyle={{ maxHeight: "65vh", overflow: "auto" }}
            title={title}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={footer}
            width={width}
            zIndex={zIndex}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
        >
            {children}
        </Modal>
    );
};

export default Modals;
