import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getDefaultImage, getImage } from "../../../../util/helper";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    type: state?.store?.storeInformation?.store_categories,
    allowed: state?.pages?.allowed,
});

interface ImageRendererProps {
    openProductImages: any;
    openImageModal: any;
    value: string;
}

function ImageRenderer(props: ImageRendererProps): React.ReactChild | null {
    const { openProductImages, openImageModal } = props;
    const { products, type } = useSelector(selector);
    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));
    const dispatch = useDispatch();

    const setContentOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }

        ref.onclick = (e) => {
            dispatch(openProductImages(record?.product_id));
            openImageModal();
            e.stopPropagation();
        };
    };

    return record ? (
        <div className="product-title-image">
            <div className="product-image" ref={setContentOnClickHandler}>
                <img
                    alt=""
                    src={record.img ? getImage(record.img) : getDefaultImage(type)}
                    onError={(e: any) => {
                        e.target.onError = null;
                        e.target.src = getDefaultImage(type);
                    }}
                />
            </div>
        </div>
    ) : null;
}

export default ImageRenderer;
