import { Button, Modal, Slider } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setRedeemModalState } from "../../../../slices/referrals";
import { FormattedMessage } from "react-intl";
import { makeReferralTransaction } from "../../../../services/referrals";
import { ReferralTransactionActions } from "../../constants";
import { formatNumber, formatCurrency } from "../../../../util/helper";

/**
 * display customer's current point balance
 * also can be used for dynamic value display
 *
 * @param formattedId - the title , localized string, of the displayed value
 * @param displayValue - The value displayed by the function
 * @param pointsToValueRate - the points-to-value rate set by merchants
 *
 * @example Balance: 1387328 ($13873.28)
 * @returns a value and the corresponding dollar amount
 */
export const displayCurrValue = (
    formattedId: string,
    displayValue: number,
    pointsToValueRate: number,
    currency?: string
): JSX.Element => {
    return (
        <div>
            <FormattedMessage id={formattedId} />: {formatNumber(displayValue)}
            <span className="curr-points-to-value">
                {"("}
                {currency
                    ? formatCurrency(pointsToValueRate != 0 ? displayValue / pointsToValueRate : 0, currency)
                    : formatCurrency(pointsToValueRate != 0 ? displayValue / pointsToValueRate : 0)}
                {")"}
            </span>
        </div>
    );
};

function RedeemRewards(): JSX.Element {
    const dispatch = useDispatch();
    const [p, setPoints] = useState<number>(0);
    const { redeemModal, pointsToValue, redeemBalance, storeCurrency } = useSelector((state: RootState) => ({
        redeemModal: state?.referrals?.redeemModal,
        redeemBalance: Number(state?.referrals?.redeemModal?.reward_balance) ?? 0,
        pointsToValue: Number(state?.store?.storeReferrals?.pointsToValue) ?? 1,
        storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
    }));

    const points = Number(p);

    const modalBody = (
        <div className="redeem-rewards-modal">
            <div className="redeem-amounts">
                {displayCurrValue("balance", redeemBalance, pointsToValue, storeCurrency)}
                {displayCurrValue("redeem", points, pointsToValue, storeCurrency)}
            </div>
            <Slider
                min={0}
                step={pointsToValue}
                max={redeemModal?.reward_balance}
                value={points}
                onChange={(value: any) => setPoints(value)}
            />
            <div className="redeem-all-button">
                <Button onClick={() => setPoints(redeemModal?.reward_balance ?? 0)}>
                    <FormattedMessage id="redeem_all" />
                </Button>
            </div>
        </div>
    );

    const onRedeem = () => {
        if (points > 0) {
            dispatch(
                makeReferralTransaction({
                    action: ReferralTransactionActions.RedeemPoints,
                    points,
                    customer_id: redeemModal?.customer_id,
                    refresh_customer: redeemModal?.refresh_customer,
                })
            );
        }
    };

    return (
        <Modal
            destroyOnClose={true}
            visible={Boolean(redeemModal?.customer_id)}
            onOk={onRedeem}
            onCancel={() =>
                dispatch(setRedeemModalState({ customer_id: "", customer_name: "", refresh_customer: false }))
            }
            title={<FormattedMessage id="redeem_rewards_points_title" values={redeemModal} />}
        >
            {modalBody}
        </Modal>
    );
}

export default RedeemRewards;
