import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { getStoreListSuccess } from "../slices/store-switch";
import { getIntl } from "../locale";
import { createMessage } from "../components/message";
import { toast } from "react-toastify";
import { HiInformationCircle } from "react-icons/hi";

export const getStoreList = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    try {
        const response: any = await oauth("STORE_LIST")({
            method: "GET",
        });

        switch (response.RC) {
            case 200:
                dispatch(getStoreListSuccess(response.records));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const switchStore = (params?: Record<string, any> | any) => async () : Promise<any> => {
    try {
        const intl = getIntl();
        const body = {
            profile: {
                current_store: {
                    id: params.id,
                    status: params.status,
                },
            },
        };

        const response: any = await oauth("STORE_SWITCH")({
            method: "POST",
            body: JSON.stringify(body),
        });

        switch (response.RC) {
            case 200:
                toast(createMessage(intl.formatMessage({ id: "store_switch_successful" }), HiInformationCircle));
                window.location.reload();
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};
