import React from "react";
import Wrapper from "../../components/wrapper";
import ForgotHead from "./forgot-header";
import ForgotForm from "./forgot-form";
import "../login/login.scss";
import Logo from "../../components/logo";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

function App(): JSX.Element {
    const history = useHistory();
    return (
        <Wrapper helmet={{ title: "Login" }} public={true}>
            <div className="login-page">
                <div className="login-page-container">
                    <Logo />
                    <ForgotHead />
                    <ForgotForm />
                </div>
                <div>
                    <Button type="link" onClick={() => history.push("/signup")}>
                        <FormattedMessage id="signup_instead" />
                    </Button>
                    <Button type="link" onClick={() => history.push("/")}>
                        <FormattedMessage id="signin_instead" />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default App;
