import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { Pagination, Select } from "antd";
import helper from "../../../../util/helper";
import actions from "../../../../slices/order-history";
import config from "config";
import { getSavedOrderHistoryPageSize } from "pages/order-history/helper-order-list";

const { Option } = Select;

class OrderListTablePagination extends Component {
    state = { pageSize: null };

    componentDidMount() {
        this.setState({
            pageSize: getSavedOrderHistoryPageSize(),
        });
    }

    showOtherInfo = () => !_.isEmpty(_.get(this.props, "orderListState.orderList"));

    str = (id, values = {}) => this.props.intl.formatMessage({ id }, values);

    getPagingInformation = () => {
        return _.get(this.props, "orderListState.orderListPaging");
    };

    getTotal = () => _.get(this.getPagingInformation(), "total", 0);

    getCurrentPage = () => _.get(this.getPagingInformation(), "page", 0);

    getSize = () => this.state.pageSize || _.get(this.getPagingInformation(), "size", 0);

    getOrderList = (page, limit) => {
        this.props.fetchOrderList({ paging: { page, limit } });
    };

    getOrderTotal = () => {
        const total = _.get(this.props, "orderListState.summary.order_totals", 0);
        return helper.getCurrencyFormattedString(total, this.props.storeCurrency);
    };

    renderOtherInfo = () => {
        if (this.showOtherInfo())
            return (
                <div className="order-list-pagination-total-text">
                    <b> {` ${this.getTotal()} · ${this.getOrderTotal()}`} </b>
                </div>
            );
    };

    renderPagination = () => {
        return (
            <Pagination
                showSizeChanger={false}
                size="small"
                showLessItems={true}
                onChange={(page, pageSize) => this.getOrderList(page, pageSize)}
                pageSize={this.getSize()}
                current={this.getCurrentPage()}
                total={this.getTotal()}
            />
        );
    };

    renderPageSelect = () => {
        const options = [];

        for (var i = 0, j = 1; i < this.getTotal(); i += this.getSize(), j++) {
            options.push(j);
        }
        return (
            <Select
                style={{ marginRight: 3 }}
                size="small"
                value={this.getCurrentPage()}
                onChange={(page) => this.getOrderList(page, this.getSize())}
            >
                {options.map((opt) => (
                    <Option value={opt} key={opt}>
                        {opt}
                    </Option>
                ))}
            </Select>
        );
    };

    renderPageSizer = () => {
        const sizers = [10, 20, 50];
        return (
            <Select
                size="small"
                value={this.getSize()}
                onChange={(pageSize) => {
                    localStorage.setItem(config.LOCAL_STORAGE_KEYS.order_history_page_size, String(pageSize));
                    this.setState({ pageSize: pageSize });
                    this.getOrderList(this.getCurrentPage(), pageSize);
                }}
            >
                {sizers.map((size) => (
                    <Option value={size} key={size}>
                        {size}
                    </Option>
                ))}
            </Select>
        );
    };

    render() {
        return (
            <div className="order-list-pagination-row" id="order-list-pagination-row">
                {this.renderOtherInfo()}
                <div className="order-list-pagination-pagination">
                    {this.renderPagination()}
                    {this.renderPageSelect()}
                    {this.renderPageSizer()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    storesData: _.get(state, "managedStores.activeStores", []),
    orderListState: _.get(state, "orders-page", {}),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTablePagination));
