import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { api } from "util/api";
import { useDispatch, useSelector } from "react-redux";
import { userLoginSuccess } from "slices/user";
import { FormattedMessage, useIntl } from "react-intl";
import { setLanguage } from "slices/setting";
import { useHistory } from "react-router";
import { RootState } from "app/reducer";
import clearText from "../../../../images/icons/icon_clear_text.png";

interface LoginFormInputs {
    username: string;
    password: string;
}

const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().min(6).required(),
});

function App(): JSX.Element {
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { control, handleSubmit, errors, reset, watch } = useForm<LoginFormInputs>({
        resolver: yupResolver(schema),
    });
    // watch() will return the value of specified fields. Similar to getValues() but it rerenders, so X button can be updated.
    const watchUsername = watch("username");
    const watchPassword = watch("password");
    const history = useHistory();
    const deviceToken = useSelector((state: RootState) => state?.user?.deviceToken);

    const onSubmit = async (data: LoginFormInputs) => {
        setIsLoggingIn(true);

        if (!isLoggingIn) {
            const response = await api("LOGIN")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        ...data,
                        device_token: deviceToken,
                        mobile_os: "firebase",
                    },
                }),
            });

            dispatch(setLanguage(response?.records?.profile?.lan ?? "en"));

            dispatch(userLoginSuccess(response.records));

            if (response?.RC === 200) {
                requestPermission();
                history.replace("/dashboard");
            }
            setIsLoggingIn(false);
        }
    };

    /**
     * Requests the user for notifications permission.
     */
    const requestPermission = () => {
        try {
            Notification.requestPermission().then((permission) => {
                if (permission !== "granted") {
                    // Handle notifications disabled
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getErrorMessage = (message: any) => {
        return message ? <React.Fragment>{message.charAt(0).toUpperCase() + message.slice(1)}.</React.Fragment> : null;
    };

    return (
        <React.Fragment>
            <Form layout="vertical">
                <div style={{ width: 350, margin: "auto" }}>
                    <Form.Item
                        help={getErrorMessage(errors.username?.message)}
                        validateStatus={errors.username?.message && "error"}
                    >
                        <Controller
                            as={Input}
                            name="username"
                            control={control}
                            defaultValue=""
                            placeholder={formatMessage({ id: "username" })}
                        />
                        <img
                            onClick={() => reset({ ...control.getValues(), username: "" })}
                            style={{
                                display: watchUsername?.length > 0 ? "" : "none",
                                width: "13px",
                                position: "absolute",
                                right: "13px",
                                top: "11px",
                                zIndex: 1,
                                cursor: "pointer",
                            }}
                            src={clearText}
                            alt="clear text icon"
                        />
                    </Form.Item>

                    <Form.Item
                        help={getErrorMessage(errors.password?.message)}
                        validateStatus={errors.password?.message && "error"}
                    >
                        <Controller
                            as={<Input.Password onPressEnter={handleSubmit(onSubmit)} />}
                            name="password"
                            control={control}
                            defaultValue=""
                            placeholder={formatMessage({ id: "password" })}
                        />
                        <img
                            onClick={() => reset({ ...control.getValues(), password: "" })}
                            style={{
                                display: watchPassword?.length > 0 ? "" : "none",
                                width: "13px",
                                position: "absolute",
                                right: "35px",
                                top: "10px",
                                zIndex: 1,
                                cursor: "pointer",
                            }}
                            src={clearText}
                            alt="clear text icon"
                        />
                        <Button type="link" onClick={() => history.push("/forgot")}>
                            <FormattedMessage id="forgot_password" />
                        </Button>
                    </Form.Item>

                    <Form.Item style={{ paddingTop: 16 }}>
                        <Button
                            type="primary"
                            size="large"
                            style={{ width: "100%" }}
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoggingIn}
                        >
                            <FormattedMessage id="login" />
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </React.Fragment>
    );
}

export default App;
