import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { getDashboardSuccess, setDashboardState } from "../slices/dashboard";
import { oauth } from "../util/api";
import _ from "lodash";

export const getDashboard =
    (input?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setDashboardState({ loading: true }));
        try {
            const params = _.cloneDeep(input);

            if (params?.period > 0) {
                delete params.e_date;
                delete params.s_date;
            }

            const r: any = await oauth("DASHBOARD")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });

            switch (r?.RC) {
                case 200:
                    dispatch(getDashboardSuccess(r?.response));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setDashboardState({ loading: false }));
    };
