import { Button, Collapse, Form, Spin } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";
import { IoIosAddCircleOutline, IoIosArrowForward } from "react-icons/io";
import { IoSwapHorizontal } from "react-icons/io5";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import config from "../../../../config";
import { getAllCustomers } from "../../../../services/customers";
import {
    autocompleteProxy,
    generateOrderId,
    geoCodeProxy,
    getDeliveryTimeslot,
} from "../../../../services/delivery_request";
import { clearAllCustomer } from "../../../../slices/customers";
import {
    addNewTaskItem,
    AddressInfoPath,
    BalanceInfoPath,
    clearFormState,
    DeliveryOptions,
    EditModalPages,
    InputSelectOptionsPath,
    Item,
    MessageModalPages,
    NewTasksPath,
    setAddressInfo,
    setBalanceInfo,
    setAddressInfoState,
    setBalanceInfoState,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setEditModal,
    setFormValidation,
    setInputSelectionState,
    setIsLoading,
    setIsPickUpAddress,
    setItemModal,
    setNewTaskFormState,
    setOptionModal,
    setShouldMessageModalOpen,
    setUpdateForm,
    TaskModalPages,
    calculateBalance,
} from "../../../../slices/delivery_request";
import { CustomerInfo } from "../../../../types/customer-list";
import { DoordashSetting } from "../../../../types/doordash-setting";
import { TipsSettings } from "../../../../types/store-details";
import { customerActions } from "../../../customers/constants";
import {
    ADDRESS_INFOS_REQUIRED_FIELDS,
    AnyStyle,
    COUNTRY_CODE_MAPPING,
    DEFAULT_LAT,
    DEFAULT_LNG,
    PRODUCT_CATEGORY_PICKER_OPTIONS,
    Row,
    TAX_OPTIONS,
} from "../../constants";
import "../../style.scss";
import { FormInputComponent, INPUT_TYPE } from "../Form";
import { INPUT_STATUS, SelectOption } from "../Form/FormInput";
import {
    getAddressSelectOptions,
    getDeliveryFee,
    getOrderValues,
    inputValidation,
    labelBuilder,
    newDeliveryTaskFormValidation,
    popArrayByIndex,
} from "../helper";
import { grpAddressByPhoneAndName } from "../helper/AddressSlectOptions";
export interface RowProps {
    hidden?: boolean;
    title?: string | React.ReactNode;
    content?: React.ReactNode;
    extraContent?: React.ReactNode;
    titleStyle?: AnyStyle;
    contentStyle?: AnyStyle;
    containerStyle?: AnyStyle;
    fullWidth?: boolean;
    key: string;
    onClick?: (e?: any) => void;
}

const _TITLE_STYLE = { fontWeight: "bold", fontSize: "16px", color: "#3e91f7" };
const _COLLAPSE_KEY = "additional";
/**
 * @desc
 * render the new task modal content,
 * base on the state from redux : currPage return diff page
 * @enum {
 *  TaskModalPages:
 *   NEW_TASK_MAIN,
 *   NEW_TASK_ADDRESS,
 *   NEW_TASK_ITEMS,
 *   EDIT_ADDRESS,
 * }
 *
 */
const NewTaskModalContentLayout: React.FC<PropsFromRedux> = ({
    //setter
    setCurrentModalPage,
    setNewTaskFormState,
    setAddressInfo,
    setBalanceInfo,
    setIsPickUpAddress,
    setIsLoading,
    setFormValidation,
    setEditModal,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    setAddressInfoState,
    setBalanceInfoState,
    setInputSelectionState,
    setUpdateForm,
    calculateBalance,
    //adder
    addNewTaskItem,
    //clear
    clearFormState,
    clearAllCustomer,
    //api
    generateOrderId,
    getAllCustomers,
    getDeliveryFee,
    getDeliveryTimeslot,
    geoCodeProxy,
    autocompleteProxy,
    // from native stata from delivery request slice
    currPage,
    isModalOpen,
    newTaskState,
    addressInfoState,
    balanceInfoState,
    inputSelections,
    isLoading,
    formValidation,
    updatedFormState,
    balanceValue,
    // from other slice
    storeTipsSettings,
    defaultDeliverySettings,
    allCustomers,
    storeInformation,
    customersAddressPaging,
    symbol,
}) => {
    const { additionalInfo, isPickUpAddress, pickUp, dropOff } = newTaskState;
    const { searchParams } = inputSelections;
    const { tipAmount, orderValue, itemsCategory, itemsInfo, subTotal, orderTaxPercent } = additionalInfo;
    const intl = useIntl();
    const { default_order_value, default_item_name, default_tip_amount, mandatory_fields } = defaultDeliverySettings;
    const { address_lat: storeLat, address_lon: storeLng, address_country_code: storeCountryCode } = storeInformation;
    // state for auto completion
    const [addressAutoCompletion, setAddressAutoCompletion] = useState<SelectOption[]>([]);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [searchAddressDelay, setSearchAddressDelay] = useState<null | NodeJS.Timeout>(null);
    /**
     * use effect hooks for initial the form default values
     */
    useEffect(() => {
        if (isModalOpen) {
            switch (currPage) {
                case TaskModalPages.NEW_TASK_MAIN:
                    initNewTaskMainPageValue();
                    break;
                case TaskModalPages.NEW_TASK_ITEMS:
                    initEditItemsPageValue();
                    break;
                case TaskModalPages.NEW_TASK_ADDRESS:
                    intiAddressInfoValue();
                    break;
                case TaskModalPages.ADD_BALANCE:
                    initAddBalancePageValue();
                    break;
                default:
                    break;
            }
        }
    }, [currPage, isModalOpen]);

    /**
     * use effect hooks to validate the input value in form
     */
    useEffect(() => {
        const getState = () => {
            switch (currPage) {
                case TaskModalPages.NEW_TASK_MAIN:
                    return newTaskState;
                case TaskModalPages.EDIT_ADDRESS:
                case TaskModalPages.NEW_TASK_ADDRESS:
                case TaskModalPages.EDIT_ADDRESS_NEW_TASK:
                    return addressInfoState;
                case TaskModalPages.ADD_BALANCE:
                    return balanceInfoState;
                default:
                    return {};
            }
        };

        const getRequiredFields = () => {
            switch (currPage) {
                case TaskModalPages.NEW_TASK_MAIN:
                    return mandatory_fields as unknown as NewTasksPath[];
                case TaskModalPages.EDIT_ADDRESS:
                case TaskModalPages.NEW_TASK_ADDRESS:
                case TaskModalPages.EDIT_ADDRESS_NEW_TASK:
                    return ADDRESS_INFOS_REQUIRED_FIELDS;
                default:
                    return [];
            }
        };

        const [isValid, missingField] = newDeliveryTaskFormValidation(getState(), getRequiredFields() as any);
        setFormValidation({ key: "isValid", value: isValid });
        setFormValidation({ key: "missingField", value: missingField });
    }, [newTaskState, addressInfoState, currPage]);

    /**
     * get delivery fee when address changes
     */
    useEffect(() => {
        if (currPage === TaskModalPages.NEW_TASK_MAIN) getDeliveryFee(newTaskState);
    }, [isPickUpAddress, newTaskState.address, newTaskState.pickUp.time, newTaskState.dropOff.time]);

    // when adding a new item or removing an existing item, re-render the subtotal section
    useEffect(() => {
        if (itemsInfo.length > 0) {
            const [subTotal, orderTax, totalValue] = getOrderValues(itemsInfo, orderTaxPercent);
            if (Number(subTotal) > 0) handleNewTaskOnChange({ key: "additionalInfo.subTotal", value: subTotal });
            if (Number(orderTax) > 0)
                handleNewTaskOnChange({
                    key: "additionalInfo.orderTax",
                    value: orderTax,
                });
            if (Number(totalValue) > 0) {
                handleNewTaskOnChange({
                    key: "additionalInfo.totalValue",
                    value: totalValue,
                });
                handleNewTaskOnChange({
                    key: "additionalInfo.orderValue",
                    value: totalValue,
                });
            }
            handleNewTaskOnChange({
                key: "additionalInfo.tipAmount",
                value: Number(totalValue ?? orderValue) * ((storeTipsSettings.default_pct_delivery ?? 0) / 100),
            });
        }
    }, [itemsInfo]);

    useEffect(() => {
        const inputDelay = setTimeout(() => {
            handleNewAddressOnSearch(searchParams);
        }, 350);

        return () => {
            if (inputDelay) clearTimeout(inputDelay);
        };
    }, [searchParams]);

    useEffect(() => {
        const inputDelay = setTimeout(() => {
            handleSearchCustomerByPhone(addressInfoState.phone);
        }, 500);

        return () => {
            if (inputDelay) clearTimeout(inputDelay);
        };
    }, [addressInfoState.phone]);

    const initEditItemsPageValue = () => {
        if (_.isEmpty(itemsCategory)) {
            const value = default_item_name;
            handleNewTaskOnChange({ key: "additionalInfo.itemsCategory", value });
        }
    };

    const initNewTaskMainPageValue = async () => {
        await getDeliveryTimeslot();

        if (!tipAmount || tipAmount <= 0) {
            const value = default_tip_amount;
            handleNewTaskOnChange({ key: "additionalInfo.tipAmount", value });
        }
        if (!orderValue || orderValue <= 0) {
            const value = default_order_value;
            handleNewTaskOnChange({ key: "additionalInfo.orderValue", value });
            if (itemsInfo.length <= 0) {
                const tax = value * Number(orderTaxPercent);
                const total = Number(tax) + value;
                handleNewTaskOnChange({ key: "additionalInfo.subTotal", value });
                handleNewTaskOnChange({ key: "additionalInfo.orderTax", value: tax });
                handleNewTaskOnChange({ key: "additionalInfo.totalValue", value: total });
            }
        }

        if (!itemsCategory || _.isEmpty(itemsCategory)) {
            const value = default_item_name;
            handleNewTaskOnChange({ key: "additionalInfo.itemsCategory", value });
        }
    };

    const intiAddressInfoValue = () => {
        if (_.isEmpty(addressInfoState.countryCode))
            handleAddressInfoOnChange({ key: "countryCode", value: storeCountryCode });
    };

    const  initAddBalancePageValue: () => void = () => {
        //throw new Error("Function not implemented.");
        handleBalanceInfoOnChange({ key: "balanceType", value: "Credit" });
        handleBalanceInfoOnChange({ key: "balance", value: balanceValue });
        handleBalanceInfoOnChange({ key: "newBalance", value: balanceValue });
    }

    /**
     * onChange handler
     */
    const handleNewTaskOnChange = ({ key, value }: { key: NewTasksPath; value: any }) => {
        setNewTaskFormState({ key, value });
    };

    const handleAddressInfoOnChange = ({ key, value }: { key: AddressInfoPath; value: any }) => {
        setAddressInfo({ key, value });
    };

    const handleBalanceInfoOnChange = ({ key, value }: { key: BalanceInfoPath; value: any }) => {
        setBalanceInfo({ key, value });
    };

    const handleNewAddressOnSearch = (value: string) => {
        if (value?.length >= 6) {
            autocompleteProxy({
                location: [Number(storeLat) ?? DEFAULT_LAT, Number(storeLng) ?? DEFAULT_LNG],
                input: value,
                radius: 1000 * 100,
                callback: (res) => {
                    if (Array.isArray(res) && res.length > 0) {
                        const selections: SelectOption[] = res.map((record) => {
                            return {
                                label: record.description,
                                name: record.description,
                                value: record.description,
                            };
                        });
                        setAddressAutoCompletion(selections);
                    } else {
                        setAddressAutoCompletion([]);
                    }
                },
            });
        } else {
            setAddressAutoCompletion([]);
        }
    };

    const handleAddressOnSearch = async (value: string, limit?: number): Promise<void> => {
        if (searchAddressDelay) clearTimeout(searchAddressDelay);

        const searchDelay = setTimeout(async () => {
            const isNumber = !isNaN(Number(value));
            if ((isNumber && value.length >= 4) || (!isNumber && value.length >= 2)) {
                setIsLoading(true);
                await getAllCustomers({ action: customerActions.ADDRESS, quick_search: value, limit: limit ?? 5 });
                setIsLoading(false);
            } else {
                clearAllCustomer(newTaskState.address);
                handleAddressInfoOnChange({ key: "address", value: "" });
            }
        }, 350);

        setSearchAddressDelay(searchDelay);
    };

    const handleAddressEditOrAddBtnOnClick = (addressSrc?: CustomerInfo) => {
        const { address: addressInState } = newTaskState;
        const addressDetail = addressSrc ?? allCustomers.find((c) => String(c.id) === String(addressInState));
        const fullAddress = [
            addressDetail?.street,
            addressDetail?.city,
            addressDetail?.region,
            addressDetail?.country_id,
        ];
        setAddressInfoState({
            id: String(addressDetail?.id ?? ""),
            countryCode: addressDetail?.country_id ?? "",
            phone: addressDetail?.phone ?? "",
            firstName: addressDetail?.firstname ?? "",
            lastName: addressDetail?.lastname ?? "",
            email: addressDetail?.email ?? "",
            address: fullAddress.join(", "),
            addressLineTwo: addressDetail?.unit ?? "",
            buzzCode: addressDetail?.buzz ?? "",
            componentName: addressDetail?.business_name ?? "",
            deliveryOptions: addressDetail?.delivery_option ?? null,
            zipCode: addressDetail?.zip_code ?? "",
            city: addressDetail?.city ?? "",
            region: addressDetail?.region ?? "",
            street: addressDetail?.street ?? "",
        });
    };

    const handleSearchCustomerByPhone = async (value: string) => {
        if (value.length >= 10 && currPage === TaskModalPages.NEW_TASK_ADDRESS) {
            setIsLoading(true);
            const res = await getAllCustomers({ action: customerActions.ADDRESS, quick_search: value });
            if (res && res.records.length > 1) {
                setCurrentModalPage(TaskModalPages.SEARCH_CUSTOMER);
            } else if (res && res.records.length > 0) {
                if (res && res?.records[0]) {
                    const add = res?.records[0];
                    setAddressInfoState({
                        id: String(add?.id ?? ""),
                        countryCode: add?.country_id ?? "",
                        firstName: add?.firstname ?? "",
                        lastName: add?.lastname ?? "",
                        email: add?.email ?? "",
                        address: add?.street ?? "",
                        addressLineTwo: add?.unit ?? "",
                        buzzCode: add?.buzz ?? "",
                        componentName: add?.business_name ?? "",
                        deliveryOptions: add?.delivery_option ?? null,
                        zipCode: add?.zip_code ?? "",
                        deliveryRemark: add?.delivery_instructions ?? "",
                        region: add?.region ?? "",
                        city: add?.city ?? "",
                        street: add?.street ?? "",
                    });
                    setCurrentModalPage(TaskModalPages.EDIT_ADDRESS_NEW_TASK);
                }
            }
            setIsLoading(false);
        }
    };

    /**
     *  Layout renderer
     */

    // return one row in modal layout
    const renderRow = (props: RowProps) => {
        if (props.hidden) return null;
        return (
            <div
                className="modal-row"
                onClick={() => {
                    props?.onClick?.();
                }}
                style={props.containerStyle}
                key={props.key}
            >
                {props.fullWidth ? (
                    props.content
                ) : (
                    <>
                        <div className="title" style={props.titleStyle}>
                            {props.title}
                        </div>
                        <div className="content" style={props.contentStyle}>
                            {props.content}
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderInputContent = <T,>(props: Row<T>) => {
        const { extraContent, ...rest } = props;
        return (
            <>
                <FormInputComponent {...rest} />
                {extraContent}
            </>
        );
    };

    const renderCategorisePicker = () => {
        const intl = useIntl();
        return (
            <div className="new-delivery-request-categorise-picker">
                {PRODUCT_CATEGORY_PICKER_OPTIONS.map((v) => {
                    const isActive = itemsCategory.toLowerCase() === v.toLowerCase();
                    return (
                        <div
                            onClick={() => {
                                handleNewTaskOnChange({ key: "additionalInfo.itemsCategory", value: v });
                            }}
                            className={`categorise-picker-options ${isActive ? "active" : ""}`}
                            key={v}
                        >
                            {intl.formatMessage({ id: v })}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderItemPicker = () => {
        const _ITEMINFO_INPUT_FIELDS: ["label" | "input" | "button", string][] = [
            ["input", "price"],
            ["label", "x"],
            ["input", "quantity"],
            ["button", "x"],
        ];

        const renderTitle = (index: number) => {
            /**
             * update the input status
             */
            const getCurrInputState = (): INPUT_STATUS | undefined => {
                const isError = _.get(itemsInfo, `${index}.name`).length < 2;
                return isError ? INPUT_STATUS.ERR : undefined;
            };
            return renderInputContent({
                name: `item ${index} name`,
                state: newTaskState,
                inputDataPath: `additionalInfo.itemsInfo.${index}.name` as NewTasksPath,
                inputType: INPUT_TYPE.TEXT,
                handleOnChange: handleNewTaskOnChange,
                placeholder: "Item Name",
                inputStatus: getCurrInputState(),
                handleOnClick: () => {
                    setEditModal({ currPage: EditModalPages.NEW_TASK_ITEM_NAME, itemIndex: index });
                },
            });
        };

        const renderContent = (index: number) => {
            const _style = { marginLeft: "10px", merginRight: "10px" };
            return _ITEMINFO_INPUT_FIELDS.map(([type, key], i) => {
                /**
                 * update the input status
                 */
                const getCurrInputState = (): INPUT_STATUS | undefined => {
                    const isError = _.get(itemsInfo, `${index}.${key}`) <= 0;
                    return isError ? INPUT_STATUS.ERR : undefined;
                };

                switch (type) {
                    case "label":
                        return (
                            <div style={_style} key={i}>
                                {key}
                            </div>
                        );
                    case "input":
                        return (
                            <div style={_style} key={i}>
                                {renderInputContent({
                                    name: `item {index} {key}`
                                        .replace("{index}", index.toString())
                                        .replace("{key}", key),
                                    state: newTaskState,
                                    inputDataPath: `additionalInfo.itemsInfo.${index}.${key}`
                                        .replace("{index}", index.toString())
                                        .replace("{key}", key) as NewTasksPath,
                                    inputType: INPUT_TYPE.TEXT,
                                    handleOnChange: handleNewTaskOnChange,
                                    style: { width: "20%", minWidth: "60px" },
                                    addonBefore: key === "price" && symbol,
                                    inputStatus: getCurrInputState(),
                                })}
                            </div>
                        );
                    case "button":
                        return (
                            <div style={_style} key={i}>
                                {
                                    <Button
                                        onClick={() => {
                                            // save the updated array to redux
                                            handleNewTaskOnChange({
                                                key: "additionalInfo.itemsInfo",
                                                value: popArrayByIndex(index, [...itemsInfo]),
                                            });
                                        }}
                                    >
                                        x
                                    </Button>
                                }
                            </div>
                        );
                    default:
                        return null;
                }
            });
        };

        return (
            <div className="new-delivery-task-item-list-container">
                {itemsInfo.map((item, index: number) => {
                    return (
                        <div className="new-delivery-task-item" key={`new task item ${index} ${item.name}`}>
                            {renderRow({
                                key: `new task item ${index}`,
                                titleStyle: { width: "50%", paddingRight: "10px" },
                                title: renderTitle(index),
                                contentStyle: { width: "50%", justifyContent: "space-around", alignItems: "center" },
                                content: renderContent(index),
                            })}
                            {renderOptionsList(item, index)}
                            <Button
                                className="outlined-blue-button"
                                onClick={() => {
                                    setEditModal({
                                        currPage: EditModalPages.NEW_TASK_ADD_ITEM_OPTION,
                                        itemIndex: index,
                                    });
                                }}
                            >
                                <span>{intl.formatMessage({ id: "add_an_option" })}</span>
                            </Button>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderOptionsList = (item: Item, itemIndex: number) => {
        const { options } = item;
        return (
            <div className="new-delivery-task-teim-list-options-container">
                {options.map((o, i) => {
                    return renderRow({
                        key: `new task option ${i} ${o}`,
                        titleStyle: { width: "100%" },
                        contentStyle: { width: "0px" },
                        containerStyle: {
                            backgroundColor: "#f8f8f8",
                            margin: "8px 4px",
                            paddingLeft: "8px",
                            cursor: "pointer",
                        },
                        title: o,
                        onClick: () => {
                            setEditModal({
                                currPage: EditModalPages.NEW_TASK_ITEM_OPTION,
                                itemIndex,
                                optionIndex: i,
                            });
                        },
                    });
                })}
            </div>
        );
    };

    const renderDeliveryOptionsPicker = () => {
        const handleOnclick = (option: any) => {
            handleAddressInfoOnChange({
                key: "deliveryOptions",
                value: addressInfoState.deliveryOptions === option ? null : option,
            });
        };

        return (
            <>
                {Object.values(DeliveryOptions).map((option) => {
                    return (
                        <div
                            className={`delivery-new-address-info-delivery-option ${
                                addressInfoState.deliveryOptions === option ? "delivery-option-active" : ""
                            }`}
                            key={option}
                            onClick={() => {
                                if (currPage === TaskModalPages.READ_ONLY_ADDRESS) return;
                                handleOnclick(option);
                            }}
                        >
                            {intl.formatMessage({ id: option })}
                        </div>
                    );
                })}
            </>
        );
    };

    const renderAdditionalSession = (): React.ReactNode => {
        const _AdditionalSessionLayout: RowProps[] = [
            {
                key: "edit items btn",
                title: intl.formatMessage({ id: "add_edit_item" }),
                content: renderInputContent({
                    style: { width: "100%" },
                    name: "edit items",
                    state: newTaskState,
                    inputDataPath: "additionalInfo.itemsCategory",
                    inputType: INPUT_TYPE.BUTTON,
                    label: !_.isEmpty(itemsCategory) ? itemsCategory : intl.formatMessage({ id: "add_edit_item" }),
                    handleOnClick: (e?: React.MouseEvent) => {
                        e?.stopPropagation();
                        setCurrentModalPage(TaskModalPages.NEW_TASK_ITEMS);
                    },
                    extraContent: !isCollapsed && (
                        <div
                            style={{
                                paddingLeft: "12px",
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <IoIosArrowForward style={{ width: "25px", height: "25px" }} />
                        </div>
                    ),
                }),
            },
            {
                key: "order value and tips",
                containerStyle: { width: "70%", marginLeft: "30%" },
                titleStyle: { width: "calc(55% - 10px)", marginRight: "10px" },
                contentStyle: { width: "calc(45% - 10px)", marginLeft: "10px" },
                title: renderRow({
                    key: "order value",
                    titleStyle: { width: "0" },
                    contentStyle: { width: "100%" },
                    content: renderInputContent({
                        addonBefore: symbol,
                        name: "orderValue",
                        state: newTaskState,
                        inputDataPath: "additionalInfo.orderValue",
                        inputType: INPUT_TYPE.TEXT,
                        placeholder: intl.formatMessage({ id: "order_value" }),
                        handleOnClick: (e?: React.MouseEvent) => {
                            e?.stopPropagation();
                            setEditModal({ currPage: EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT });
                        },
                    }),
                }),
                content: renderRow({
                    key: "tips amount",
                    titleStyle: { width: "0" },
                    contentStyle: { width: "100%" },
                    content: renderInputContent({
                        addonBefore: symbol,
                        name: "tipAmount",
                        state: newTaskState,
                        inputDataPath: "additionalInfo.tipAmount",
                        placeholder: intl.formatMessage({ id: "tips" }),
                        inputType: INPUT_TYPE.TEXT,
                        handleOnClick: (e?: React.MouseEvent) => {
                            e?.stopPropagation();
                            setEditModal({ currPage: EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT });
                        },
                    }),
                }),
            },
            {
                key: "comment",
                containerStyle: { alignItems: "flex-start" },
                content: renderInputContent({
                    handleOnChange: handleNewTaskOnChange,
                    name: "comment",
                    state: newTaskState,
                    inputDataPath: "additionalInfo.comment",
                    inputType: INPUT_TYPE.TEXT_AREA,
                    placeholder: intl.formatMessage({ id: "comment" }),
                    rows: 2,
                    maxRows: 4,
                }),
            },
            {
                key: "orderHasAlcohol",
                title: intl.formatMessage({ id: "contains_alcohol" }),
                content: renderInputContent({
                    handleOnChange: handleNewTaskOnChange,
                    name: "orderHasAlcohol",
                    state: newTaskState,
                    inputDataPath: "additionalInfo.containsAlcohol",
                    inputType: INPUT_TYPE.CHECK_BOXES,
                    label: "this order contains alcohol",
                    checkBoxOptions: [intl.formatMessage({ id: "yes" })],

                }),
            },
        ];

        const renderPriceInfo = (): React.ReactNode | null => {
            return !isCollapsed ? null : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "70%" }}>
                    <div style={{ textAlign: "right" }}>
                        <div style={{ fontSize: "14px" }}>
                            <FormattedMessage id={"order_value"} />
                        </div>
                        <div style={{ fontSize: "12px" }}>{`${symbol} ${orderValue}`}</div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div style={{ fontSize: "14px" }}>
                            <FormattedMessage id={"tip_amount"} />
                        </div>
                        <div style={{ fontSize: "12px" }}>{`${symbol} ${tipAmount}`}</div>
                    </div>
                </div>
            );
        };

        return (
            <Collapse
                onChange={(key: string[] | string) => {
                    setIsCollapsed(!key.includes(_COLLAPSE_KEY));
                }}
                className="new-task-additional-info"
                ghost
                expandIconPosition="right"
                expandIcon={
                    isCollapsed
                        ? undefined
                        : () => {
                              return <></>;
                          }
                }
            >
                <Collapse.Panel
                    header={renderRow({
                        key: "additionali-info-label",
                        title: intl.formatMessage({ id: "additional_info" }),
                        titleStyle: _TITLE_STYLE,
                        content: renderPriceInfo(),
                        contentStyle: { width: "100%", justifyContent: "flex-end" },
                    })}
                    key={_COLLAPSE_KEY}
                >
                    {_AdditionalSessionLayout.map((row) => {
                        return renderRow(row);
                    })}
                </Collapse.Panel>
            </Collapse>
        );
    };

    const renderAddressPicker = () => {
        return (
            <div>
                {Object.entries(grpAddressByPhoneAndName(allCustomers))?.map(([key, value], index) => {
                    return (
                        <div key={key + index}>
                            <div className="search-customer-address-picker-title">{key}</div>
                            <div>
                                {value?.map((v) => {
                                    return (
                                        <div
                                            className="search-customer-address-picker-option"
                                            onClick={() => {
                                                handleAddressEditOrAddBtnOnClick(v);
                                                setCurrentModalPage(TaskModalPages.EDIT_ADDRESS_NEW_TASK);
                                            }}
                                            key={v.id}
                                        >{`${v.unit ? v.unit + " - " : ""} ${v.street ?? ""}, ${v.city ?? ""}, ${
                                            v.region
                                        }, ${v.zip_code ?? ""}`}</div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    /**
     * layout
     */
    const _ORDER_ID_GENERATOR_ROWS: RowProps[] = [
        {
            key: "order pop up",
            titleStyle: { width: "70%", paddingRight: "20px" },
            contentStyle: { width: "30%" },
            title: renderInputContent({
                state: currPage === TaskModalPages.EDIT_ORDER_ID ? updatedFormState : newTaskState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "orderId",
                name: "orderId",
                placeholder: intl.formatMessage({ id: "order_id" }),
                handleOnChange: (props) => {
                    if (currPage === TaskModalPages.NEW_TASK_ORDER) handleNewTaskOnChange(props);
                    if (currPage === TaskModalPages.EDIT_ORDER_ID) setUpdateForm(props);
                },
            }),
            content: (
                <Button
                    type="primary"
                    onClick={() => {
                        generateOrderId();
                    }}
                >
                    Generate Order #
                </Button>
            ),
        },
    ];

    const _MAIN_PAGE_LAYOUT: RowProps[] = [
        {
            key: "orderId",
            title: intl.formatMessage({ id: "order_id" }),
            titleStyle: _TITLE_STYLE,
            content: <>{_ORDER_ID_GENERATOR_ROWS.map((row) => renderRow(row))}</>,
        },
        { title: intl.formatMessage({ id: "pickup" }), key: "pickLabel", titleStyle: _TITLE_STYLE },
        {
            hidden: !isPickUpAddress,
            key: "pickup address",
            title: intl.formatMessage({ id: "address" }),
            contentStyle: { display: "flex", alignItems: "center" },
            content: renderInputContent({
                showSearch: true,
                allowClear: true,
                state: newTaskState,
                name: "pickup.address",
                inputDataPath: "address",
                inputType: INPUT_TYPE.SELECT,
                selectOptions: getAddressSelectOptions(allCustomers),
                style: { width: "calc(100% - 60px)", height: "auto", wordWrap: "break-word" },
                filterOption: false,
                handleOnChange: handleNewTaskOnChange,
                onSearch: handleAddressOnSearch,
                placeholder: intl.formatMessage({ id: "search_address_placeholder" }),
                extraContent: (
                    <div>
                        {!_.isEmpty(newTaskState.address) && (
                            <div
                                className="edit-address-btn"
                                onClick={(e?: React.MouseEvent) => {
                                    e?.stopPropagation();
                                    handleAddressEditOrAddBtnOnClick();
                                    setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS);
                                }}
                            >
                                <BsPencilSquare style={{ width: "25px", height: "25px" }} />
                            </div>
                        )}
                        <div
                            className="edit-address-btn"
                            onClick={(e?: React.MouseEvent) => {
                                e?.stopPropagation();
                                clearFormState("addressInfo");
                                setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS);
                            }}
                        >
                            <IoIosAddCircleOutline style={{ width: "28px", height: "28px" }} />
                        </div>
                    </div>
                ),
            }),
        },
        {
            key: "pickUp time",
            title: intl.formatMessage({ id: "time" }),
            content: renderInputContent({
                name: "pickUp.time",
                state: newTaskState,
                inputDataPath: "pickUp.time",
                inputType: INPUT_TYPE.BUTTON,
                style: { width: "100%" },
                className: "outlined-blue-button",
                label: labelBuilder(pickUp, "date", "time", intl.formatMessage({ id: "input_pickup_time" })),
                handleOnClick: (e?: React.MouseEvent) => {
                    e?.stopPropagation();
                    setShouldMessageModalOpen(true);
                    setCurrentMessageModalPage(MessageModalPages.PICKUP_TIME_PICKER);
                },
            }),
        },
        {
            key: "dropoff label",
            title: intl.formatMessage({ id: "drop_off" }),
            titleStyle: _TITLE_STYLE,
            contentStyle: { display: "flex", alignItems: "center", justifyContent: "center" },
            content: (
                <div
                    onClick={() => {
                        setIsPickUpAddress(!isPickUpAddress);
                    }}
                    style={{
                        borderRadius: "10px",
                        border: "#3e91f7 1px solid",
                        cursor: "pointer",
                    }}
                >
                    <IoSwapHorizontal
                        style={{
                            transform: "rotate(90deg)",
                            color: "#3e91f7",
                            height: "25px",
                            width: "25px",
                            margin: "5px 10px 0px 10px",
                        }}
                    />
                </div>
            ),
        },
        {
            hidden: isPickUpAddress,
            key: "dropoff address",
            title: intl.formatMessage({ id: "address" }),
            contentStyle: { display: "flex", alignItems: "center" },
            content: renderInputContent({
                state: newTaskState,
                showSearch: true,
                onSearch: handleAddressOnSearch,
                filterOption: false,
                allowClear: true,
                name: "dropoff.address",
                inputDataPath: "address",
                inputType: INPUT_TYPE.SELECT,
                style: { width: "calc(100% - 60px)", height: "auto", wordWrap: "break-word" },
                selectOptions: getAddressSelectOptions(allCustomers),
                handleOnChange: handleNewTaskOnChange,
                placeholder: intl.formatMessage({ id: "search_address_placeholder" }),
                required: true,
                extraContent: (
                    <div>
                        {!_.isEmpty(newTaskState.address) && (
                            <div
                                className="edit-address-btn"
                                onClick={(e?: React.MouseEvent) => {
                                    e?.stopPropagation();
                                    handleAddressEditOrAddBtnOnClick();
                                    setCurrentModalPage(TaskModalPages.EDIT_ADDRESS_NEW_TASK);
                                }}
                            >
                                <BsPencilSquare style={{ width: "25px", height: "25px" }} />
                            </div>
                        )}
                        <div
                            className="edit-address-btn"
                            onClick={(e?: React.MouseEvent) => {
                                e?.stopPropagation();
                                clearFormState("addressInfo");
                                setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS);
                            }}
                        >
                            <IoIosAddCircleOutline style={{ width: "28px", height: "28px" }} />
                        </div>
                    </div>
                ),
            }),
        },
        {
            key: "dropoff time",
            title: intl.formatMessage({ id: "time" }),
            content: renderInputContent({
                name: "dropOff.time",
                state: newTaskState,
                inputDataPath: "dropOff.time",
                inputType: INPUT_TYPE.BUTTON,
                style: { width: "100%" },
                className: "outlined-blue-button",
                label: labelBuilder(dropOff, "date", "time", intl.formatMessage({ id: "input_dropoff_time" })),
                handleOnClick: (e?: React.MouseEvent) => {
                    e?.stopPropagation();
                    setShouldMessageModalOpen(true);
                    setCurrentMessageModalPage(MessageModalPages.DROPOFF_TIME_PICKER);
                },
            }),
        },
        {
            key: "additionali-info-label-session",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderAdditionalSession(),
        },
    ];

    const _ADD_BALANCE_LAYOUT :RowProps[] = [
        { title: intl.formatMessage({ id: "delivery_fee_balance_update" }), key: "delivery_fee_balance_update", titleStyle: _TITLE_STYLE },
        {
            key: "current_balance",
            title: intl.formatMessage({ id: "current_balance" }),
            contentStyle: { display: "flex", alignItems: "center" },
            content:<>{balanceInfoState.balance}</>
        },
        {
            key: "add_balance_action",
            containerStyle: { justifyContent: "flex-start" },
            title: <>{intl.formatMessage({ id: "add_balance_action" })}</>,
            contentStyle: { width: "50%", paddingLeft: "10px" },
            content: renderInputContent({
                state: balanceInfoState,
                inputType: INPUT_TYPE.RADIO,
                name: "add_balance_action",
                inputDataPath: "balanceType",
                placeholder: intl.formatMessage({ id: "add_balance_action" }),
                defaultValue: balanceInfoState.balanceType,
                handleOnChange: ({key ,value}: {key: BalanceInfoPath; value: any}) => {
                    handleBalanceInfoOnChange({key, value});
                    calculateBalance();
                },
                radioBoxOptions: [intl.formatMessage({ id: "add_balance_credit_label" }),intl.formatMessage({ id: "add_balance_debit_label" })],
            }),
        },
        {
            key: "amount",
            title: intl.formatMessage({ id: "amount" }),
            content: renderInputContent({
                state: balanceInfoState,
                inputType: INPUT_TYPE.INPUT_NUMBER,
                inputDataPath: "amount",
                name: "amount",
                placeholder: intl.formatMessage({ id: "amount" }),
                required: true,
                defaultValue: balanceInfoState.amount,
                handleOnChange: ({key ,value}: {key: BalanceInfoPath; value: any}) => {
                    let amount = parseFloat(value);
                    amount = amount > 2000? 2000 : amount;
                    
                    setBalanceInfoState({...balanceInfoState, amount: amount? amount : 0});
                    handleBalanceInfoOnChange({key, value : amount? amount : 0});
                    calculateBalance();
                },
                inputStatus: formValidation.missingField === "amount" ? INPUT_STATUS.ERR : undefined,
            }),
        },
        {
            key: "new_balance",
            title: intl.formatMessage({ id: "new_balance" }),
            contentStyle: { display: "flex", alignItems: "center" },
            content:<>{balanceInfoState.newBalance?.toFixed(2)}</>
        },
    ]

    const _ADDRESS_INFO_PAGE_ROWS: RowProps[] = [
        {
            title: intl.formatMessage({ id: "contact_info" }),
            key: "contact lable",
            titleStyle: _TITLE_STYLE,
        },
        {
            key: "address info phone number",
            title: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.SELECT,
                inputDataPath: "countryCode",
                name: "phoneCountryCode",
                handleOnChange: handleAddressInfoOnChange,
                selectOptions: COUNTRY_CODE_MAPPING,
                required: true,
                placeholder: intl.formatMessage({ id: "please_select" }),
                style: { width: "90%" },
                maxLength: 12,
            }),
            contentStyle: { display: "flex" },
            content: (
                <>
                    {renderInputContent({
                        state: addressInfoState,
                        inputType: INPUT_TYPE.TEXT,
                        inputDataPath: "phone",
                        name: "phone",
                        placeholder: intl.formatMessage({ id: "phone" }),
                        required: true,
                        inputStatus: formValidation.missingField === "phone" ? INPUT_STATUS.ERR : undefined,
                        handleOnChange: ({ key, value }: { key: AddressInfoPath; value: any }) => {
                            handleAddressInfoOnChange({ key, value });
                        },
                    })}
                    {currPage !== TaskModalPages.READ_ONLY_ADDRESS && (
                        <Button
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                setCurrentModalPage(TaskModalPages.SEARCH_CUSTOMER);
                            }}
                            className="outlined-blue-button"
                        >
                            <AiOutlineSearch />
                        </Button>
                    )}
                </>
            ),
        },
        {
            key: "address info customer name",
            titleStyle: { width: "50%", paddingRight: "20px" },
            contentStyle: { width: "50%" },
            containerStyle: { justifyContent: "flex-start" },
            title: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "firstName",
                name: "firstName",
                placeholder: intl.formatMessage({ id: "first_name" }),
                required: true,
                handleOnChange: handleAddressInfoOnChange,
                inputStatus: formValidation.missingField === "firstName" ? INPUT_STATUS.ERR : undefined,
            }),
            content: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "lastName",
                name: "lastName",
                handleOnChange: handleAddressInfoOnChange,
                required: true,
                placeholder: intl.formatMessage({ id: "last_name" }),
                inputStatus: formValidation.missingField === "lastName" ? INPUT_STATUS.ERR : undefined,
            }),
        },
        {
            key: "address info email",
            titleStyle: { width: "0px" },
            contentStyle: { width: "100%" },
            content: renderInputContent({
                inputStatus: formValidation.missingField === "email" ? INPUT_STATUS.ERR : undefined,
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "email",
                name: "email",
                placeholder: intl.formatMessage({ id: "email" }),
                handleOnChange: handleAddressInfoOnChange,
            }),
        },
        {
            title: intl.formatMessage({ id: "address" }),
            key: "address info address label",
            titleStyle: _TITLE_STYLE,
            contentStyle: { display: "flex", justifyContent: "flex-end" },
            content: TaskModalPages.EDIT_ADDRESS === currPage && (
                <Button
                    className="outlined-blue-button"
                    onClick={() => {
                        setCurrentModalPage(TaskModalPages.EDIT_ADDRESS_NEW_TASK);
                        setAddressInfoState({
                            id: "",
                            address: "",
                            addressLineTwo: "",
                            buzzCode: "",
                            componentName: "",
                            deliveryOptions: null,
                            zipCode: "",
                            deliveryRemark: "",
                        });
                    }}
                >
                    <FormattedMessage id={"new_address"} />
                </Button>
            ),
        },
        {
            key: "address info address",
            titleStyle: { width: "80%", paddingRight: "10px" },
            contentStyle: { width: "20%", paddingLeft: "10px" },
            title: renderInputContent({
                onSearch: (value: string) => {
                    setInputSelectionState({ key: "searchParams", value });
                },
                state: addressInfoState,
                inputType: INPUT_TYPE.SELECT,
                inputDataPath: "address",
                name: "address info street",
                placeholder: intl.formatMessage({ id: "address" }),
                required: true,
                handleOnChange: ({ key, value }: { key: AddressInfoPath; value: any }) => {
                    geoCodeProxy({
                        address: value,
                        callback: (res) => {
                            if (Array.isArray(res) && res.length > 0) {
                                const { address_components, formatted_address } = res[0];
                                const length = address_components.length;
                                handleAddressInfoOnChange({ key, value: formatted_address ?? "" });
                                handleAddressInfoOnChange({
                                    key: "street",
                                    value: formatted_address.split(",")[0] ?? "",
                                });
                                handleAddressInfoOnChange({
                                    key: "countryCode",
                                    value: address_components[length - 2]?.short_name ?? "",
                                });
                                handleAddressInfoOnChange({
                                    key: "zipCode",
                                    value: address_components[length - 1]?.long_name ?? "",
                                });
                                handleAddressInfoOnChange({
                                    key: "region",
                                    value: address_components[length - 3]?.short_name ?? "",
                                });
                                handleAddressInfoOnChange({
                                    key: "city",
                                    value: address_components[length - 5]?.long_name ?? "",
                                });
                            }
                        },
                    });
                },
                filterOption: false,
                showSearch: true,
                style: { width: "100%" },
                selectOptions: addressAutoCompletion,
                inputStatus:
                    addressAutoCompletion.length > 0 && _.isEmpty(addressInfoState.address)
                        ? INPUT_STATUS.ERR
                        : undefined,
            }),
            content: renderInputContent({
                state: addressInfoState,
                inputStatus: formValidation.missingField === "zipCode" ? INPUT_STATUS.ERR : undefined,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "zipCode",
                name: "address info zip code",
                placeholder: intl.formatMessage({ id: "zipcode" }),
                required: true,
                handleOnChange: handleAddressInfoOnChange,
                maxLength: 8,
            }),
        },
        {
            key: "address info address line two and region",
            titleStyle: { width: "50%" },
            containerStyle: { justifyContent: "flex-start" },
            title: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "addressLineTwo",
                name: "addressLineTwo",
                placeholder: intl.formatMessage({ id: "addressLineTwo" }),
                handleOnChange: handleAddressInfoOnChange,
                inputStatus: inputValidation(/^\d*[\d#-\s]*\d$/, addressInfoState.addressLineTwo, (pass) => {
                    if (!pass && formValidation.isValid !== pass) {
                        setFormValidation({ key: "isValid", value: pass });
                        setFormValidation({ key: "missingField", value: "addressLineTwo" });
                    }
                })
                    ? undefined
                    : INPUT_STATUS.ERR,
            }),
            contentStyle: { width: "50%", paddingLeft: "10px" },
            content: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "buzzCode",
                name: "buzzCode",
                placeholder: intl.formatMessage({ id: "buzzCode" }),
                handleOnChange: handleAddressInfoOnChange,
                inputStatus: inputValidation(/^\d*$/, addressInfoState.buzzCode, (pass) => {
                    if (!pass && formValidation.isValid !== pass) {
                        setFormValidation({ key: "isValid", value: pass });
                        setFormValidation({ key: "missingField", value: "buzzCode" });
                    }
                })
                    ? //
                      undefined
                    : INPUT_STATUS.ERR,
            }),
        },
        {
            key: "address info componentName and buzzcode",
            titleStyle: { width: "100%" },
            contentStyle: { width: "0px" },
            containerStyle: { justifyContent: "flex-start" },
            title: renderInputContent({
                state: addressInfoState,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "componentName",
                name: "componentName",
                placeholder: intl.formatMessage({ id: "componentName" }),
                handleOnChange: handleAddressInfoOnChange,
            }),
        },
        {
            title: intl.formatMessage({ id: "delivery_option" }),
            key: "delivery option label",
            titleStyle: _TITLE_STYLE,
        },
        {
            key: "delivery options picker",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderDeliveryOptionsPicker(),
        },
        {
            key: "delivery remark",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderInputContent({
                handleOnChange: handleAddressInfoOnChange,
                name: "delivery remark",
                state: addressInfoState,
                inputDataPath: "deliveryRemark",
                inputType: INPUT_TYPE.TEXT_AREA,
                placeholder: intl.formatMessage({ id: "delivery_remark_placeholer" }),
                rows: 2,
                maxRows: 4,
            }),
        },
    ];

    const _EDIT_ITEMS_PAGE_ROWS: RowProps[] = [
        {
            key: "category label",
            title: intl.formatMessage({ id: _.isEmpty(itemsCategory) ? " " : itemsCategory }),
            titleStyle: _TITLE_STYLE,
        },
        {
            key: "category picker",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderCategorisePicker(),
        },
        { key: "item label", title: `item(${itemsInfo.length})`, titleStyle: _TITLE_STYLE },
        {
            key: "item picker",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderItemPicker(),
        },
        {
            key: "add new item button",
            title: (
                <Button
                    className="blue-button"
                    onClick={() => {
                        addNewTaskItem();
                    }}
                >
                    <span>{intl.formatMessage({ id: "add_an_item" })}</span>
                </Button>
            ),
        },
        {
            key: "subtotal label",
            titleStyle: { width: "70%", display: "flex", justifyContent: "flex-end", paddingRight: "16px" },
            contentStyle: { width: "30%" },
            title: "Subtotal:",
            content: renderInputContent({
                state: newTaskState,
                inputType: INPUT_TYPE.INPUT_NUMBER,
                inputDataPath: "additionalInfo.subTotal",
                name: "additionalInfo.subTotal",
                placeholder: "Subtotal",
                // handleOnChange: handleNewTaskOnChange,
                handleOnChange: ({ key, value }: { key: NewTasksPath; value: any }) => {
                    const tax = orderTaxPercent * value;
                    handleNewTaskOnChange({
                        key: "additionalInfo.orderTax",
                        value: tax,
                    });
                    handleNewTaskOnChange({
                        key: "additionalInfo.totalValue",
                        value: tax + value,
                    });
                    handleNewTaskOnChange({
                        key: "additionalInfo.orderValue",
                        value,
                    });
                    handleNewTaskOnChange({ key, value });
                },
                addonBefore: "$",
            }),
        },
        {
            key: "tax label",
            titleStyle: { width: "70%", display: "flex", justifyContent: "flex-end", paddingRight: "16px" },
            contentStyle: { width: "30%" },
            title: "Tax:",
            content: (
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    {renderInputContent({
                        state: newTaskState,
                        style: { width: "100px" },
                        inputType: INPUT_TYPE.INPUT_NUMBER,
                        inputDataPath: "additionalInfo.orderTax",
                        name: "additionalInfo.orderTax",
                        placeholder: "Tax",
                        handleOnChange: ({ key, value }: { key: NewTasksPath; value: any }) => {
                            handleNewTaskOnChange({
                                key: "additionalInfo.totalValue",
                                value: value + (subTotal ?? orderValue ?? default_order_value),
                            });
                            handleNewTaskOnChange({ key, value });
                        },
                        addonBefore: "$",
                    })}
                    {renderInputContent({
                        state: newTaskState,
                        inputType: INPUT_TYPE.SELECT,
                        inputDataPath: "additionalInfo.orderTaxPercent",
                        name: "additionalInfo.orderTaxPercent",
                        selectOptions: TAX_OPTIONS,
                        handleOnChange: ({ key, value }: { key: NewTasksPath; value: any }) => {
                            const tax = (subTotal ?? orderValue ?? default_order_value) * value;
                            handleNewTaskOnChange({
                                key: "additionalInfo.orderTax",
                                value: tax,
                            });
                            handleNewTaskOnChange({
                                key: "additionalInfo.totalValue",
                                value: tax + (subTotal ?? orderValue ?? default_order_value),
                            });
                            handleNewTaskOnChange({ key, value });
                        },
                        addonBefore: "$",
                    })}
                </div>
            ),
        },
        {
            key: "total label",
            titleStyle: { width: "70%", display: "flex", justifyContent: "flex-end", paddingRight: "16px" },
            contentStyle: { width: "30%" },
            title: "Total:",
            content: renderInputContent({
                state: newTaskState,
                inputType: INPUT_TYPE.INPUT_NUMBER,
                inputDataPath: "additionalInfo.totalValue",
                name: "additionalInfo.totalValue",
                placeholder: "Total",
                handleOnChange: handleNewTaskOnChange,
                addonBefore: "$",
                disabled: true,
            }),
        },
    ];

    const _SEARCH_CUSTOMER_PAGE: RowProps[] = [
        {
            key: "search customer input",
            contentStyle: { width: "100%" },
            titleStyle: { width: "0px" },
            content: renderInputContent({
                state: inputSelections,
                inputType: INPUT_TYPE.TEXT,
                inputDataPath: "searchParams",
                name: "customer search input",
                handleOnChange: (params: { key: InputSelectOptionsPath; value: any }) => {
                    setInputSelectionState({ key: params.key, value: params.value });
                    handleAddressOnSearch(params.value);
                },
                placeholder: intl.formatMessage({ id: "type_to_search" }),
                allowClear: true,
            }),
        },
        {
            key: "search customer result label",
            title: intl.formatMessage({ id: "search_result_msg" }, { records: allCustomers.length }),
            titleStyle: { fontSize: "12px" },
        },
        {
            key: "search customer result address picker",
            titleStyle: { width: "100%" },
            contentStyle: { width: "0px" },
            title: renderAddressPicker(),
        },
        {
            key: "saerch customer lead more button",
            titleStyle: { width: "100%", margin: "0px 50px", minWidth: "100px" },
            contentStyle: { width: "0px" },
            hidden: customersAddressPaging.pageEnd >= customersAddressPaging.total,
            title: (
                <Button
                    style={{ width: "100%", color: "#000000", borderColor: "#000000" }}
                    className="outlined-blue-button"
                    onClick={(e?: React.MouseEvent) => {
                        e?.stopPropagation();
                        const newLimit = customersAddressPaging.perPage + 5;
                        handleAddressOnSearch(searchParams, newLimit);
                    }}
                >
                    {intl.formatMessage({ id: "load_more" })}
                </Button>
            ),
        },
        {
            key: "search customer input address button",
            titleStyle: { width: "100%", margin: "0px 75px", minWidth: "100px" },
            contentStyle: { width: "0px" },
            title: (
                <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                        setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS);
                    }}
                    className="blue-button"
                >
                    {intl.formatMessage({ id: "input_address" })}
                </Button>
            ),
        },
    ];

    const getModalContent = (): RowProps[] => {
        switch (currPage) {
            case TaskModalPages.NEW_TASK_MAIN:
                return _MAIN_PAGE_LAYOUT;
            case TaskModalPages.NEW_TASK_ITEMS:
                return _EDIT_ITEMS_PAGE_ROWS;
            case TaskModalPages.EDIT_ADDRESS:
            case TaskModalPages.NEW_TASK_ADDRESS:
            case TaskModalPages.EDIT_ADDRESS_NEW_TASK:
            case TaskModalPages.READ_ONLY_ADDRESS:
                return _ADDRESS_INFO_PAGE_ROWS;
            case TaskModalPages.NEW_TASK_ORDER:
            case TaskModalPages.EDIT_ORDER_ID:
                return _ORDER_ID_GENERATOR_ROWS;
            case TaskModalPages.SEARCH_CUSTOMER:
                return _SEARCH_CUSTOMER_PAGE;
            case TaskModalPages.ADD_BALANCE:
                return _ADD_BALANCE_LAYOUT;
            default:
                return [];
        }
    };

    return (
        <Form className="new-task-form-container">
            <Spin spinning={isLoading}>
                {getModalContent().map((row) => {
                    return renderRow(row);
                })}
            </Spin>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => {
    const formsState = state.deliveryRequests.forms;
    const modalState = state.deliveryRequests.modal;
    const deliveryRequestsState = state.deliveryRequests;
    const currency: keyof typeof config.CURRENCY_SYMBOL =
        state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
    const symbol = config.CURRENCY_SYMBOL[currency];
    return {
        // from native stata from delivery request slice
        currPage: modalState.newTaskModal.currentPage,
        isModalOpen: modalState.newTaskModal.shouldOpen,
        newTaskState: formsState.newTask,
        addressInfoState: formsState.addressInfo,
        balanceInfoState: formsState.balanceInfo,
        inputSelections: formsState.inputSelections,
        isLoading: deliveryRequestsState.isLoading,
        formValidation: deliveryRequestsState.forms.validation,
        updatedFormState: deliveryRequestsState.forms.updateTask,
        // from other slice
        storeTipsSettings: (state.store.storeTipsSettings ?? {}) as TipsSettings,
        storeInformation: state.store.storeInformation ?? {},
        defaultDeliverySettings: (state.store.storeThirdPartyDelivery.doordash ?? {}) as DoordashSetting,
        balanceValue: state.deliveryRequests.paginations?.balance ?? 0,
        currTaxRate: state.deliveryRequests.forms.newTask.additionalInfo.orderTaxPercent,
        allCustomers: (state.customers.allCustomers ?? []) as CustomerInfo[],
        customersAddressPaging: (state.customers.paging ?? {}) as {
            page: number;
            perPage: number;
            pageStart: number;
            pageEnd: number;
            total: number;
        },
        symbol,
    };
};

const mapDispatchToProps = {
    //setter
    setCurrentModalPage,
    setNewTaskFormState,
    setAddressInfo,
    setBalanceInfo,
    setOptionModal,
    setItemModal,
    setIsPickUpAddress,
    setIsLoading,
    setFormValidation,
    setEditModal,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    setAddressInfoState,
    setBalanceInfoState,
    setInputSelectionState,
    setUpdateForm,
    //adder
    addNewTaskItem,
    //api
    generateOrderId,
    getAllCustomers,
    getDeliveryFee,
    getDeliveryTimeslot,
    geoCodeProxy,
    autocompleteProxy,
    //clear
    clearFormState,
    clearAllCustomer,
    calculateBalance
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(NewTaskModalContentLayout);


