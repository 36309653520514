import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import qs from "querystring";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { setState as setAppModalState } from "slices/app-modal";
import { RootState } from "../app/reducer";
import { createMessage } from "../components/message";
import { getIntl } from "../locale";
import { customerActions } from "../pages/customers/constants";
import { PASSWORD_ACTIONS } from "../pages/customers/helper";
import h from "../pages/settings/pages/referral-settings/helper";
import { setState as setCreateOrderState } from "../slices/create-order";
import {
    clearAllCustomer,
    getAllCustomersSuccess,
    getCouponsSuccess,
    getCustomerAddressesSuccess,
    getCustomerGiftCardSuccess,
    getCustomersSuccess,
    getCustomerSuccess,
    setCustomersState,
    updateCustomerList,
} from "../slices/customers";
import { getGiftcardAccountsSuccess, setGiftcardState } from "../slices/giftcards";
import { oauth } from "../util/api";

const CUSTOMER_ERROR_MAP: any = {
    449: "customer_phone_exists",
    450: "customer_email_exists",
    451: "customer_exists_in_other_store",
};
const CONFIRM_CREATE_CUSTOMER_IN_ANOTHER_STORE = "confirm-create-customer-in-other-store";
const CUSTOMER_PHONE_EMAIL_EXISTS = "customer-phone-email-exists";
const WARNING_TITLE = "warning";

export const getCustomers =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setCustomersState({ loading: true }));
        try {
            const response: any = await oauth("CUSTOMER_LIST")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        ...params,
                        action: customerActions.GET,
                    },
                }),
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getCustomersSuccess(response));
                    break;
                case 204:
                    dispatch(
                        getCustomersSuccess({
                            records: [],
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setCustomersState({ loading: false }));
    };

export const getAllCustomers =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const { action, ...rest } = params;
            const response: any = await oauth("CUSTOMER_LIST")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        ...rest,
                        action: action ?? customerActions.GET,
                    },
                }),
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getAllCustomersSuccess(response));
                    return response;
                default:
                    dispatch(clearAllCustomer(null));
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateAdminDetails =
    (params?: Record<string, any> | any, notification = false) =>
    async (): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("PROFILE")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params.profile },
                }),
            });
            switch (response?.RC) {
                case 200: {
                    const savedModel = params.changeLocation ?? "store_information";
                    if (!notification)
                        toast(
                            createMessage(
                                `${intl.formatMessage({ id: savedModel })} ${intl.formatMessage({ id: "saved" })}`,
                                HiInformationCircle
                            )
                        );
                    break;
                }
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const messageCustomers =
    (params?: Record<string, any>, notification = false) =>
    async (): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("CUSTOMER_MESSAGE")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params },
                }),
            });
            switch (response?.RC) {
                case 200:
                    if (!notification)
                        toast(createMessage(`${intl.formatMessage({ id: "message_sent" })}`, HiInformationCircle));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getCoupons =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("PRODUCTS")({
                method: "POST",
                body: JSON.stringify({ search_condition: params }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getCouponsSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getCustomerGiftcards =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("GIFT_CARD_ACCOUNTS")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    if (response?.records?.length > 0 && params.customer_record) {
                        dispatch(getCustomerGiftCardSuccess(response));
                    }
                    if (response?.records?.length > 0) {
                        dispatch(setGiftcardState({ title: params?.product_name, currentId: params?.product_id }));
                        dispatch(getGiftcardAccountsSuccess(response));
                    } else {
                        toast(createMessage(intl.formatMessage({ id: "no_record" }), HiInformationCircle));
                    }
                    break;
                case 4701:
                    toast(createMessage(intl.formatMessage({ id: "no_record" }), HiInformationCircle));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getReferralCode =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        let bulk = h.BULK.FALSE;

        if (params?.bulk) {
            bulk = params?.bulk === h.BULK.LAST ? h.BULK.LAST : h.BULK.TRUE;
            delete params?.bulk;
        }
        try {
            const response: any = await oauth("REFERRAL_CODE", qs.stringify(params))({ method: "GET" });
            const sendMessage = bulk === h.BULK.FALSE || bulk === h.BULK.LAST;
            switch (response?.RC) {
                case 200:
                    if (sendMessage) {
                        const message =
                            params.action === h.REFERRAL_ACTION.GENERATE
                                ? "referral_code_generated"
                                : params.action === h.REFERRAL_ACTION.DISABLE
                                ? "referral_code_disabled"
                                : params.action === h.REFERRAL_ACTION.ENABLE
                                ? "referral_code_enabled"
                                : "referral_code_deleted";
                        toast(createMessage(`${intl.formatMessage({ id: message })}`, HiInformationCircle));
                        dispatch(updateCustomerList());
                    }
                    if (params.refreshCustomer) {
                        dispatch(getCustomer({ customer_id: params.user_id }));
                    }
                    break;
                case 333:
                    if (sendMessage) {
                        toast(
                            createMessage(
                                `${intl.formatMessage({ id: "referral_code_cannot_delete" })}`,
                                HiInformationCircle
                            )
                        );
                    }
                    break;
                default:
                    if (sendMessage) {
                        toast(
                            createMessage(
                                `${intl.formatMessage({ id: "referral_code_generated_error" })} ${response?.msg}`,
                                HiInformationCircle
                            )
                        );
                    }
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getCustomer =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth(
                "GET_CUSTOMER",
                "",
                `${params.customer_id}`
            )({
                method: "GET",
            });

            switch (response?.RC) {
                case 200:
                    dispatch(
                        getCustomerSuccess({
                            record: {
                                id: params.customer_id,
                                ...response?.record,
                            },
                        })
                    );
                    break;
                default:
                    toast(createMessage(intl.formatMessage({ id: "unexpect_error" }), HiInformationCircle));
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateCustomer =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("GET_CUSTOMER")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    dispatch(getCustomer({ customer_id: params?.id }));
                    toast(createMessage(intl.formatMessage({ id: "update_customer_success" }), HiInformationCircle));
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({ id: CUSTOMER_ERROR_MAP[response?.RC] || "unexpect_error" }),
                            HiInformationCircle
                        )
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

const setCustomerErrorAppModalState = (response: any, dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(
        setAppModalState({
            visible: true,
            title: WARNING_TITLE,
            content: CUSTOMER_ERROR_MAP[response?.RC],
            code: response.RC === 451 ? CONFIRM_CREATE_CUSTOMER_IN_ANOTHER_STORE : CUSTOMER_PHONE_EMAIL_EXISTS,
            additionalInfo: {
                name: `${response?.records.firstname ?? ""} ${response?.records.lastname ?? ""}`,
                email: response?.records.email,
                phone: response?.records.phone,
            },
        })
    );
};
export const createCustomers =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("CREATE_CUSTOMER")({
                method: "POST",
                body: JSON.stringify({ data: params?.values }),
            });
            const responseCode = response?.RC;

            switch (responseCode) {
                case 200:
                    params?.redirect(response?.id);
                    toast(createMessage(intl.formatMessage({ id: "create_customer_success" }), HiInformationCircle));
                    break;
                case 449: // Falls through
                case 450: // Falls through
                case 451: // Falls through
                    setCustomerErrorAppModalState(response, dispatch);
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({ id: CUSTOMER_ERROR_MAP[responseCode] || "unexpect_error" }),
                            HiInformationCircle
                        )
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getCustomerAddresses =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth(
                "GET_CUSTOMER_ADDRESS",
                `customer_id=${params.customer_id}`
            )({
                method: "GET",
            });

            switch (response?.RC) {
                case 200:
                    dispatch(getCustomerAddressesSuccess(response));
                    break;
                default:
                    toast(createMessage(intl.formatMessage({ id: "unexpect_error" }), HiInformationCircle));
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateCustomerAddress =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("GET_CUSTOMER_ADDRESS")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    dispatch(getCustomerAddresses(params));
                    dispatch(getCustomer(params));
                    dispatch(
                        setCreateOrderState({
                            address: {
                                id: params?.id,
                                ...response?.records,
                            },
                        })
                    );
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({ id: CUSTOMER_ERROR_MAP[response?.RC] || "unexpect_error" }),
                            HiInformationCircle
                        )
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const deleteCustomer = (params: Record<string, any> | any) => async (): Promise<any> => {
    const intl = getIntl();

    try {
        const response: any = await oauth("DELETE_CUSTOMER", "", params.id)({ method: "DELETE" });
        switch (response?.RC) {
            default:
                toast(
                    createMessage(response?.msg ?? intl.formatMessage({ id: "unexpect_error" }), HiInformationCircle)
                );
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const deleteCustomerAddress =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth(
                "GET_CUSTOMER_ADDRESS",
                "",
                params.id
            )({
                method: "DELETE",
            });

            switch (response?.RC) {
                case 200:
                    dispatch(getCustomerAddresses(params));
                    dispatch(setCreateOrderState({ address: null }));
                    break;
                default:
                    toast(
                        createMessage(
                            intl.formatMessage({ id: CUSTOMER_ERROR_MAP[response?.RC] || "unexpect_error" }),
                            HiInformationCircle
                        )
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateCustomerPassword = (params: Record<string, any>) => async (): Promise<any> => {
    const intl = getIntl();

    try {
        const response: any = await oauth("UPDATE_CUSTOMER_PASSWORD")({
            method: "POST",
            body: JSON.stringify({ data: params }),
        });

        switch (response?.RC) {
            case 200:
                toast(
                    createMessage(
                        intl.formatMessage({
                            id:
                                params.action === PASSWORD_ACTIONS.reset
                                    ? "password_confirmation_sent"
                                    : "password_changed",
                        }),
                        HiInformationCircle
                    )
                );
                break;
            default:
                toast(
                    createMessage(
                        intl.formatMessage({ id: CUSTOMER_ERROR_MAP[response?.RC] || "unexpect_error" }),
                        HiInformationCircle
                    )
                );
                break;
        }
    } catch (e) {
        // Handle error
    }
};
