import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";

interface ReferralsValueRendererProps {
    value: any[];
    renderValue: (p: any) => any;
}

function ReferralsValueRenderer(props: ReferralsValueRendererProps): JSX.Element | null {
    const { renderValue } = props;
    const records = useSelector((state: RootState) => state?.referrals?.referrals);

    const record = records?.find((r: any) => r?._id === props?.value);

    return record ? <div>{renderValue(record)}</div> : null;
}

export default ReferralsValueRenderer;
