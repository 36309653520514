import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Button, Popover, Modal } from "antd";
import { HiDotsHorizontal } from "react-icons/hi";
import { deleteQuotes, getQuoteDetail } from "services/quotes";
import { setState } from "slices/create-order";
import { getCustomer } from "services/customers";
import helper from "util/helper";
import history from "app/history";

class Actions extends Component<any, any> {
    state = {
        clicked: false,
        hovered: false,
    };

    handleHoverChange = (visible: any) => {
        this.setState({
            hovered: visible,
            clicked: false,
        });
    };

    handleClickChange = (visible: any) => {
        this.setState({
            clicked: visible,
            hovered: false,
        });
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    handleDelete = () => {
        const deleteQuote = () =>
            this.props.deleteQuotes({
                quote_ids: [this.props.data?.quote_id],
                lan: this.props.lan,
                shouldShowQuoteDeletedMsg: true,
            });
        Modal.confirm({
            title: this.str("warning"),
            content: this.str("delete_draft_confirmation"),
            onOk() {
                deleteQuote();
            },
        });
    };

    handleEdit = () => {
        const data = this.props.data;
        this.props.getCustomer({ customer_id: data?.customer_id });
        this.props.getQuoteDetail({
            qid: data?.quote_id,
            lan: this.props.lan,
            callBack: () => {
                helper.goToCreateOrderPage(window, history);
            },
        });
    };

    renderMenu = () => (
        <div className="d-flex flex-column justify-content-start align-items-start">
            <p className="popover-item mb-1" onClick={() => this.handleEdit()}>
                <FormattedMessage id="view_edit" />
            </p>
            <p className="popover-item danger mb-1" onClick={this.handleDelete}>
                <FormattedMessage id="delete" />
            </p>
        </div>
    );

    render() {
        return (
            <Popover
                overlayClassName={"quote-page-column-actions"}
                content={this.renderMenu()}
                placement="left"
                trigger="hover"
                visible={this.state.hovered}
                onVisibleChange={this.handleHoverChange}
            >
                <Popover
                    overlayClassName={"quote-page-column-actions"}
                    content={this.renderMenu()}
                    placement="left"
                    trigger="click"
                    visible={this.state.clicked}
                    onVisibleChange={this.handleClickChange}
                >
                    <Button type="link">
                        <HiDotsHorizontal size="20" />
                    </Button>
                </Popover>
            </Popover>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state?.setting?.lan,
});

const mapDispatchToProps = {
    deleteQuotes,
    getCustomer,
    getQuoteDetail,
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Actions)));
