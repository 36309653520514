import React from 'react';
import _ from 'lodash';
import { getIntl } from "../../../../../locale";
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { RiCouponFill } from 'react-icons/ri';
import { formatCurrency } from 'util/helper';
import config from "config";

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = {[key in TypeKey]: string}
const currencySymbols: Type = config.CURRENCY_SYMBOL;

const ALL = 0;
const PRODUCT = 1;
const CATEGORY = 2;
const COUPON = 4
const PERCENT = 1;
const AMOUNT = 2;
const DELIVERY = 1;
const PICKUP = 0;
const INCLUDE = 2;
const EXCLUDE = 3;
const ENABLED = 1;
const DISABLED = 2;
const ALL_PRODUCTS = 0;

export enum STATUS { ACTIVE = 1, INACTIVE = 2 }

export enum modType { delivery = 0, pickup = 1, instore = 2 }

const titles = ["minimum_amount", "discount", "discount_condition", " "];
const rowFields = ["min", "discount", "excluded", "actions"];

const removeComma = (str: string): string => str.slice(0, str.length - 1);

const renderMini = (row: any = {}, currency: string): JSX.Element => (
    <>
        {formatCurrency(+_.get(row, `${rowFields[0]}`), currency)}
    </>
);

const renderDisc = (row: any = {}, perc: boolean, currency: TypeKey = "CAD"): JSX.Element => (
    <>
        {!perc && currencySymbols[currency]}{_.get(row, `${rowFields[1]}`)}{perc && "%"}
    </>
);

const renderCond = (row: any = {}, type: number): JSX.Element => (
    <div>{type === ALL ? <FormattedMessage id="apply_to_all" /> :
        (<div><b><FormattedMessage id="exclude" />:</b> {removeComma(_.get(row, `${rowFields[2]}`))}</div>)}</div>
);

const deleteRowConfirm = (row: any = {}, deleteRow: any = {}): void => {
    const intl = getIntl();
    Modal.confirm({
        title: intl.formatMessage({ id: "delete_discount_rule" }),
        content: intl.formatMessage({ id: "are_you_sure" }),
        onOk() { deleteRow(row) },
        okType: 'danger',
    });
};

const getExcludedNames = (exclude: number[], type: number, products: any = [], categories: any = [], lan: string):string => {
    let excluded = ""
    if (!_.isEmpty(exclude)) {
        if (type === PRODUCT) {
            exclude.forEach((item: any) => {
                products.forEach((prod: any) => String(item) === String(prod.sku) && (excluded += ` ${(_.get(prod, `name.${lan}`))},`))
            })
            excluded = excluded.slice(0, -1)
        } else {
            exclude.forEach((item: any) => {
                categories.forEach((c: any) => String(item) === String(c.category_id) && (excluded += `${(_.get(c, `name.${lan}`))}, `))
            })
            excluded = excluded.slice(0, -1)
        }
    }
    return excluded;
};

const renderCol = (width: string, value: string, trans: boolean, key: number): JSX.Element => (
    <div className="table-col" style={{ width: width }} key={key}>
        {trans ? <FormattedMessage id={value} /> : value}
    </div>
);

const renderTableHeader = (columns: any = {}):JSX.Element => (
    <div className="table-header d-flex">
        {columns.map((col: any, index: number) => renderCol(col.width, titles[index], true, index))}
    </div>
);

const renderEmptySet = (
    <div className="emptySet">
        <RiCouponFill size={80} color="#e1e0e0" />
        <div className="empty-text-holder">
            <div className="empty-head mb-2"><FormattedMessage id="no_discount_rules" /></div>
            <div className="empty-body"><FormattedMessage id="create_new_rule" /></div>
        </div>
    </div>
);

const renderRowMobile = (hasData: boolean, key: number, columns: any = {}, row: any = {}, type: number, percent: boolean): JSX.Element => hasData ? (
    <div className="d-flex flex-column" key={key}>
        {columns.map((col: any, index: number) => (
            <div className="d-flex flex-row table-header-value my-1" key={index}>
                <div className="table-col table-header mr-1" style={{ width: "40%" }} >
                    <FormattedMessage id={titles[index] ?? " "} />
                </div>
                <div className="table-col table-value" style={{ width: "60%" }}>
                    {col.component(row, type, percent)}
                </div>
            </div>
        ))}
    </div>
) : renderEmptySet;

const getConditions = (apply_to_products: any[], currCode: Record<string, any>): Record<string, any> => {
    const category_ids = (String(_.get(currCode, 'condition.type', '')) === "category_ids" && currCode.category_ids.length !== currCode.condition.list.length)
    ? currCode.condition.list
    : currCode.category_ids;
    
    const cond = {
        condition: {
            type: _.get(currCode, 'condition.type', 'all'),
            list: [],
            min_amt: _.get(currCode, 'condition.min_amt', 0)
        }
    };
    cond.condition.list = String(_.get(currCode, 'condition.type', '')) === "category_ids" ?
        category_ids ?? [] : String(_.get(currCode, 'condition.type', '')) === "sku" ?
            apply_to_products ?? [] : [];
    return cond;
};

const isValidDiscountPercentage = (discountType: number, discountValue: number):number | any => {
    return discountType === PERCENT && discountValue > 100
}

export default {
    ALL,
    PRODUCT,
    CATEGORY,
    COUPON,
    PERCENT,
    AMOUNT,
    DELIVERY,
    PICKUP,
    INCLUDE,
    EXCLUDE,
    ENABLED,
    DISABLED,
    ALL_PRODUCTS,
    STATUS,
    titles,
    renderEmptySet,
    modType,
    getConditions,
    removeComma,
    renderTableHeader,
    renderCol,
    deleteRowConfirm,
    renderMini,
    renderDisc,
    renderCond,
    getExcludedNames,
    renderRowMobile,
    isValidDiscountPercentage
}