import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form, Input } from "antd";
import { FILTER_KEYS } from "../../../helper-order-list";
import InputNumber from "../../../../../components/number-input";
import helper from "../../../../../util/helper";
import actions from "../../../../../slices/order-history";
import config from 'config';

const currencySymbols = config.CURRENCY_SYMBOL;

class FilterDrawerCollapseSearchBy extends Component {
    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getFormLayout = () => ({
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    });

    getFormData = () => {
        return [
            { label: "order_id", key: FILTER_KEYS.order_id, component: Input },
            { label: "phone_number", key: FILTER_KEYS.phone_number, component: Input },
            { label: "first_name", key: FILTER_KEYS.first_name, component: Input },
            { label: "last_name", key: FILTER_KEYS.last_name, component: Input },
            { label: "min_amount", key: FILTER_KEYS.min_amount, component: InputNumber },
            { label: "max_amount", key: FILTER_KEYS.max_amount, component: InputNumber },
        ];
    };

    renderFormContent = () => {
        const props = (item) => {
            const key = item.key;
            const value = () => {
                const filterDrawerValue = _.get(this.props, `drawerState.${key}`, "");
                return filterDrawerValue || undefined;
            };
            let result = {
                allowClear: true,
                placeholder: this.str("type_here"),
                onChange: (e) => {
                    const value = (e && e.target && e.target.value) || e || undefined;
                    this.props.setDrawerState({
                        [key]: helper.isObject(value) ? undefined : value,
                    });
                },
                value: value(),
                style: {
                    width: "100%",
                },
            };
            if (key === FILTER_KEYS.phone_number) {
                result.type = "tel";
            }
            if (key === FILTER_KEYS.min_amount || key === FILTER_KEYS.max_amount) {
                result.prefix = currencySymbols[this.props.storeCurrency];
                result.formatter = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                result.parser = (value) => value.replace(/\$\s?|(,*)/g, "");
                result.precision = 2;
                result.min = 0;
                result.max = 1000000000;
            }
            return result;
        };

        return this.getFormData().map((item) => (
            <Form.Item
                key={item.key}
                labelAlign={"left"}
                label={<FormattedMessage id={item.label ? item.label : " "} />}
            >
                {<item.component {...props(item)} />}
            </Form.Item>
        ));
    };

    render() {
        return <Form {...this.getFormLayout()}>{this.renderFormContent()}</Form>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterDrawerCollapseSearchBy));
