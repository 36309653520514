import { Button, Modal } from "antd";
import config from "config";
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { autocompleteProxy, geoCodeProxy } from "../../../../services/delivery_request";
import { FormInputComponent, INPUT_TYPE } from "../../../delivery_request/_components/Form";
import { SelectOption } from "../../../delivery_request/_components/Form/FormInput";
import "./google-place-modal.scss";
export interface PROPS_TYPE {
    showGoogleModal: boolean;
    setShowGoogleModal: (val: boolean) => void;
    onChange: (e: any) => void;
}

export const DEFAULT_LAT = 40.73;

export const DEFAULT_LNG = -73.93;

const GooglePlaceModal = (props: PROPS_TYPE): JSX.Element | null => {
    const { showGoogleModal, setShowGoogleModal } = props;

    // hooks
    const dispatch = useDispatch();
    const intl = useIntl();

    // state redux
    const storeLat = useSelector((state: RootState) => state.store.records.store_addr.lat);
    const storeLong = useSelector((state: RootState) => state.store.records.store_addr.lon);
    // states
    const [placeID, setPlaceID] = useState<string>("");
    const [placeSearch, setPlaceSearch] = useState<string>("");
    const [place, setPlace] = useState<string>("");
    const [placeSelection, setPlaceSelection] = useState<SelectOption[]>([]);
    const [center, setCenter] = useState<[number, number]>([
        Number(storeLat) ?? DEFAULT_LAT,
        Number(storeLong) ?? DEFAULT_LNG,
    ]);

    useEffect(() => {
        const inputDelay = setTimeout(() => {
            handleAddressOnSearch(placeSearch);
        }, 500);

        return () => {
            if (inputDelay) clearTimeout(inputDelay);
        };
    }, [placeSearch]);

    useEffect(() => {
        dispatch(
            geoCodeProxy({
                address: place,
                callback: (res) => {
                    if (Array.isArray(res) && res.length > 0) {
                        setPlaceID(res[0]?.place_id);
                        setCenter([
                            res[0]?.geometry?.location?.lat ?? DEFAULT_LAT,
                            res[0]?.geometry?.location?.lng ?? DEFAULT_LNG,
                        ]);
                    }
                },
            })
        );
    }, [place]);

    const handleAddressOnSearch = (value: string) => {
        if (value?.length >= 4) {
            dispatch(
                autocompleteProxy({
                    location: [Number(storeLat) ?? DEFAULT_LAT, Number(storeLong) ?? DEFAULT_LNG],
                    input: value,
                    radius: 1000,
                    callback: (res) => {
                        if (Array.isArray(res) && res.length > 0) {
                            const selections: SelectOption[] = res.map((record) => {
                                return {
                                    label: record.description,
                                    name: record.description,
                                    value: record.description,
                                };
                            });
                            setPlaceSelection(selections);
                        } else {
                            setPlaceSelection([]);
                        }
                    },
                })
            );
        } else {
            setPlaceSelection([]);
        }
    };

    const renderGoogleModalFooter = () => {
        return (
            <div>
                <Button
                    type="primary"
                    onClick={() => {
                        setPlaceID("");
                        setPlaceSelection([]);
                    }}
                    disabled={!placeID}
                >
                    {intl.formatMessage({ id: "reset" })}
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        props.onChange(`https://search.google.com/local/writereview?placeid=${placeID}`);
                        setShowGoogleModal(false);
                    }}
                    disabled={!placeID}
                >
                    {intl.formatMessage({ id: "confirm" })}
                </Button>
            </div>
        );
    };

    const Marker = (props: { lat: number; lng: number }) => {
        const { lat: _lat, lng: _lng } = props;
        return <FaMapMarkerAlt style={{ width: "30px", height: "30px", color: "red" }} />;
    };

    return (
        <Modal
            width="80vw"
            bodyStyle={{ width: "80vw", height: "75vh" }}
            visible={showGoogleModal}
            onCancel={() => {
                setShowGoogleModal(false);
            }}
            footer={renderGoogleModalFooter()}
        >
            <div style={{ height: "100%", width: "100%" }}>
                <FormInputComponent
                    name={"search"}
                    state={{ place }}
                    inputDataPath={"place"}
                    inputType={INPUT_TYPE.SELECT}
                    showSearch
                    allowClear
                    style={{ width: "80%", marginLeft: "10%", marginBottom: "1rem" }}
                    placeholder="search"
                    selectOptions={placeSelection}
                    filterOption={false}
                    onSearch={(v: string) => {
                        setPlaceSearch(v);
                    }}
                    handleOnChange={({ value }: { value: string }) => {
                        setPlace(value);
                    }}
                />
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.GOOGLE_API_KEY }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{
                        lat: DEFAULT_LAT,
                        lng: DEFAULT_LNG,
                    }}
                    center={{ lat: center[0], lng: center[1] }}
                    defaultZoom={16}
                >
                    <Marker lat={center[0]} lng={center[1]} />
                </GoogleMapReact>
            </div>
        </Modal>
    );
};

export default GooglePlaceModal;
