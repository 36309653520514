import { createSlice } from "@reduxjs/toolkit";

export interface StoreSwitch {
    approvedStores: ApprovedStore[];
    wipStores: WorkInProgressStore[];
}

export interface ApprovedStore {
    id: number;
    status: number;
    name: string;
}

export interface WorkInProgressStore {
    id: number;
    status: number;
    name: string;
}

const initialState: StoreSwitch = {
    approvedStores: [],
    wipStores: [],
};

const slice = createSlice({
    name: "storeSwitch",
    initialState,
    reducers: {
        getStoreListSuccess: (state, { payload }) => {
            state.approvedStores = payload.stores;
            state.wipStores = payload.temp_stores;
        },
    },
});

export const { getStoreListSuccess } = slice.actions;

export const storeSwitch = slice.reducer;
