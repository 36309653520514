import { Row, Col } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import Wrapper from "components/wrapper";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getCustomer } from "services/customers";
import { setCustomersState } from "slices/customers";
import { withRouter } from "react-router-dom";
import "./customer-detail-page.scss";
import CustomerSummary from "./customer-summary";
import RecentOrders from "./recent-orders";
import CustomerInfo from "./customer-info";
import EmailMarketing from "./email-marketing";
import ReferralCode from "./referral-code";
import TagsInfo from "./tags-info";
import ResetPassword from "./reset-password";
import PointsBalance from "./points-balance";
import GiftCards from "./gift-cards";
import PointsHistory from "pages/referrals/_components/transaction-history";
import RedeemRewards from "pages/referrals/_components/redeem-rewards";
import AddRewards from "pages/referrals/_components/add-rewards";
import CustomerGiftCardsModal from "../giftcards-components";
import CustomerDelete from "./customer-delete";
import ShippingOptionsModal from "components/shipping-options-modal";
import { getManagedStores } from "services/managed-stores";
import qs from "querystring";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/customers", breadcrumbName: "customers" },
        { path: "/customers/customer/:cid", breadcrumbName: "customer" },
    ],
};

class CustomerDetailPage extends Component {
    componentDidMount() {
        const cid = Number(_.get(this.props, "match.params.cid"));
        this.props.getCustomer({ customer_id: cid });
        this.props.getManagedStores();
    }

    wrapperOnBack = () => {
        let path = "/customers";

        if (qs.stringify(this.props.searchValue)) {
            path += `?${qs.stringify(this.props.searchValue)}`;
        }

        this.props.history.push(path);
    };

    render() {
        return (
            <Wrapper helmet={{ title: "customer" }} breadcrumb={breadcrumb} onBack={this.wrapperOnBack}>
                <div className="customer-detail-page">
                    <Row gutter={16}>
                        <Col span={14}>
                            <CustomerSummary />
                            <RecentOrders />
                        </Col>
                        <Col span={10}>
                            <CustomerInfo />
                            <EmailMarketing />
                            <ReferralCode />
                            <TagsInfo />
                            <ResetPassword />
                            <PointsBalance />
                            <GiftCards />
                            <CustomerDelete deleteCallback={() => this.props.history.push("/customers")} />
                        </Col>
                    </Row>
                </div>
                <PointsHistory />
                <RedeemRewards refreshCustomer />
                <AddRewards refreshCustomer />
                <CustomerGiftCardsModal />
                <ShippingOptionsModal
                    {...{
                        shippingOptionsModal: this.props.shippingOptionsModal,
                        setState: (values) => this.props.setCustomersState(values),
                    }}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    shippingOptionsModal: _.get(state, "customers.shippingOptionsModal", false),
    searchValue: _.get(state, "customers.searchValue", {}),
});

const mapDispatchToProps = {
    getCustomer,
    setCustomersState,
    getManagedStores,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerDetailPage)));
