import React, { Component } from "react";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import config from "config";
import { IoIosArrowDown } from "react-icons/io";
import { ReactComponent as CSVDownload } from "../../../../images/icons/csv-export.svg";
import _actions from "slices/live-order";
import "./title-section.scss";
import ExportModal from "./csv-export-modal";
import { MdRefresh, MdAdd } from "react-icons/md";
import cx from "classnames";
import ShippingMethodTabs from "./shipping-method-filter-tabs";
import helper from "util/helper";
import { withRouter } from "react-router-dom";

class TitleSection extends Component {
    componentDidMount() {}

    getDropDownCurrentValue = () => {
        return _.get(this.props, "state.listFilteredStatus", "all");
    };

    isListStyle = () => {
        return String(_.get(this.props, "state.listStyle", "list")) === "list";
    };

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getDropDownData = () => {
        const sortedList = _.get(this.props, "state.groupedLiveOrderList", {});
        var allListArray = Object.values(sortedList);
        const flattenArray = Array.prototype.concat.apply([], allListArray);
        const options = {
            all: flattenArray.length,
        };
        const categoriesMap = config.ORDER_SHORTEN_CATEGORY_STATUS_MAPPING;
        Object.keys(categoriesMap).forEach((status) => {
            let label = "";
            if (status === "serve") {
                const storeFlags = _.get(this.props.storeRecords, "store_flg", "");
                label = helper.isRestaurantFromFlags(storeFlags) ? (status = "serve_restaurant") : "serve_others";
            } else {
                label = status;
            }
            options[label] = _.get(sortedList, status, []).length;
        });
        return options;
    };

    renderStartSection = () => {
        return <div className="hambuger-icon"></div>;
    };

    renderDropDownMenu = () => {
        const onClick = ({ key }) => {
            let result = [],
                updateData = { listFilteredStatus: key };
            //handle active id
            const liveOrders = _.get(this.props, "state.liveOrderList");
            const groupedList = _.get(this.props, "state.groupedLiveOrderList", {});
            const flattenGroupArray = Array.prototype.concat.apply([], Object.values(groupedList));
            const isListStyle = this.isListStyle();
            if (String(key) === "all") {
                result = isListStyle ? flattenGroupArray : liveOrders;
            } else {
                result = _.get(groupedList, key, []);
            }
            const activeId = _.get(result, "0.ord_id", "");
            updateData.activeId = activeId;
            //expand order if list style
            if (isListStyle && activeId) {
                updateData.expansionControllers = {
                    [activeId]: { showDetail: true },
                };
            }
            this.props.setState(updateData);
        };

        return (
            <Menu style={{ maxWidth: 150 }} onClick={onClick}>
                {Object.keys(this.getDropDownData()).map((status) => (
                    <Menu.Item key={status}>
                        <span>{this.str(`category_${status}`)} </span>
                        <span> ({this.getDropDownData()[status]}) </span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    renderCenterSection = () => {
        const dropDownFilter = this.getDropDownCurrentValue();
        const dropDrownLabel = `category_${dropDownFilter}`;
        return (
            <div className="title-center-section">
                <div className="title-center-content">
                    <div className="live-order-title">
                        <FormattedMessage id="live_order" />
                    </div>
                    <Dropdown overlay={this.renderDropDownMenu()}>
                        <div className="drop-down-text">
                            <span>{this.str(dropDrownLabel)} </span>
                            <span>({this.getDropDownData()[dropDownFilter]})</span>
                            <span className="drop-down-icon">
                                <IoIosArrowDown />
                            </span>
                        </div>
                    </Dropdown>
                </div>
                <MdRefresh
                    onClick={() => {
                        this.props.getLiveOrdersList();
                    }}
                    className={cx({
                        "order-list-title-refresh": true,
                        "spin": this.props.isListLoading,
                    })}
                />
            </div>
        );
    };

    renderEndSection = () => {
        return (
            <div className="order-list-title-end-section">
                <MdAdd
                    onClick={() => {
                        this.props.setState({
                            shippingOptionsModal: true,
                        });
                    }}
                    className="order-list-title-refresh"
                />
                <CSVDownload
                    onClick={() => {
                        this.props.setState({
                            showExportDialog: true,
                        });
                    }}
                    className="order-list-export-order-icon"
                />
            </div>
        );
    };

    renderExportModal = () => {
        return <ExportModal />;
    };

    renderShippingMethodsFilter = () => {
        return <ShippingMethodTabs />;
    };

    render() {
        return (
            <div id="live-order-list-drawer-title-section" className="live-order-list-drawer-title-section">
                <div className="live-order-list-drawer-title-upper-section">
                    {this.renderStartSection()}
                    {this.renderCenterSection()}
                    {this.renderEndSection()}
                </div>
                <div
                    id="live-order-list-drawer-title-lower-section"
                    className="live-order-list-drawer-title-lower-section"
                >
                    {this.renderShippingMethodsFilter()}
                </div>
                {this.renderExportModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    flags: _.get(state, "availabilitySettings", {}),
    isListLoading: _.get(state, "liveOrders.isListLoading", false),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
    getLiveOrdersList: _actions.getLiveOrdersList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(TitleSection)));
