import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { Typography, ListSubheader, ListItemSecondaryAction } from "@material-ui/core";
import { FaCheck } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { Drawer } from "antd";
import actions from "slices/live-order";
import _ from "lodash";
import "./categories-drawer.scss";
import { getCategoryName } from "../../../helper";

class StoreCategoriesDrawer extends Component<any, any> {
    state = {
        anchorEl: null,
    };

    handleCategoryClick = (cat: any) => {
        const conditions = _.cloneDeep(this.props.searchCondition);
        conditions.cids = cat.id;
        conditions.page = 0;
        conditions.reset = true;
        conditions.cachedProducts = this.props.cachedProducts;
        this.props.getProducts(conditions);
        this.props.setParentState({ catId: cat.id });
    };

    handleClose = () => {
        this.props.setParentState({ showCategoryDrawer: false });
    };

    getActiveCat = () => {
        return this.props?.currentCat;
    };

    drawerListSubheader = () => (
        <ListSubheader component="div" id="nested-list-subheader" style={{ backgroundColor: "white", padding: 12 }}>
            <Typography variant="h6">
                <FormattedMessage id="select_category" />
            </Typography>
        </ListSubheader>
    );

    drawerCategoryListRender = (cat: any) => {
        const isActiveCat = String(this.getActiveCat()) === String(cat?.id);
        return (
            <ListItem
                button
                key={cat.id}
                id={cat.id}
                style={{
                    padding: 16,
                    color: isActiveCat ? "#ed2d29" : "initial",
                }}
                onClick={() => {
                    this.handleClose();
                    this.handleCategoryClick(cat);
                }}
            >
                <ListItemText
                    primary={
                        <Typography
                            variant={isActiveCat ? "body1" : "body2"}
                            component="p"
                            style={{ wordWrap: "break-word", paddingRight: 24, display: "flex", alignItems: "center" }}
                        >
                            <FaCheck
                                style={{
                                    paddingRight: 6,
                                    opacity: isActiveCat ? 1 : 0,
                                    display: "flex",
                                }}
                            />
                            {getCategoryName(cat, this.props.lan)}
                        </Typography>
                    }
                />
                <ListItemSecondaryAction>
                    <Typography
                        variant={isActiveCat ? "body1" : "body2"}
                        style={{
                            color: isActiveCat ? "#ed2d29" : "initial",
                        }}
                    >
                        {cat.c_cnt}
                    </Typography>
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    drawerRender = () => {
        const categories = Array.isArray(this.props.categories) ? this.props.categories : [];
        return (
            <Drawer
                bodyStyle={{ padding: 0 }}
                visible={Boolean(this.props?.parentState?.showCategoryDrawer)}
                onClose={() => this.handleClose()}
            >
                <List subheader={this.drawerListSubheader()}>{categories.map(this.drawerCategoryListRender)}</List>
            </Drawer>
        );
    };

    render() {
        return <React.Fragment>{this.drawerRender()}</React.Fragment>;
    }
}

const mapStateToProps = (state: any) => ({
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    cachedProducts: _.get(state, "liveOrders.cachedProducts", {}),
});

const mapDispatchToProps = {
    getProducts: actions.getProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(StoreCategoriesDrawer)));
