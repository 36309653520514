import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import { PermissionTypes, usePermission } from "../../hooks/usePermission";
import { setPagesState } from "../../slices/pages";

function Preloader(props: any = {}): JSX.Element | null {
    const { route } = props;
    const { getFlag } = usePermission();
    const stableGetFlag = useCallback(getFlag, []);
    const dispatch = useDispatch();
    const { allowed, loaded } = useSelector((state: RootState) => ({
        allowed: state?.pages?.allowed,
        loaded: state?.pages?.loaded,
    }));

    // In cases where button is changed to enabled when it should not be, set to disabled.
    const pageRef = () => (current: any) => {
        const disableButtons = (current: any, classname = "") => {
            const saveBtns = current?.getElementsByClassName(classname);
            for (let i = 0; i < saveBtns?.length; i++) {
                saveBtns[i].disabled = true;

                const observer = new window.MutationObserver(function (mutations) {
                    mutations.forEach((mutation) => {
                        //@ts-ignore
                        if (mutation?.attributeName === "disabled" && mutation?.target?.disabled === false) {
                            if (allowed === false) {
                                saveBtns[i].disabled = true;
                            }
                        }
                    });
                });

                observer.observe(saveBtns[i], {
                    attributes: true,
                });
            }
        };
        if (allowed === false) {
            disableButtons(current, "save-button-handler");
            disableButtons(current, "add-button-handler");
        }
    };

    // Handles whether user can save data or not.
    useEffect(() => {
        if (route?.index !== undefined ? stableGetFlag(route?.index) === PermissionTypes.ReadOnly.toString() : false) {
            dispatch(setPagesState({ allowed: false, loaded: true }));
        } else {
            dispatch(setPagesState({ allowed: true, loaded: true }));
        }
    }, [route, stableGetFlag, dispatch]);

    return loaded ? <div ref={pageRef()}>{props.children}</div> : null;
}

export default Preloader;
