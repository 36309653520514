import {
    getStoreDeliveryRange,
    getStorePickupRange,
    getPrintUtensilValue,
    PRINT_UTENSIL_SETTING_MAP,
    getDefaultPrintUtensilValue,
    isRestaurant,
} from "pages/liveorder/helper";
import {
    getDefaultETA,
    getShippingMethodNumber,
    requestUtensil,
    requestUtensilNumber,
} from "pages/order-history/helper";
import helper, { APP_LAN_MOMENT_LAN_MAP, updateMoment } from "util/helper";
import _ from "lodash";
import moment from "moment";
import locale from "locale";
import { ASAP } from "app/constants";

export const RECEIPT_TYPE = {
    customer: 10,
    chef: 11,
    label: 12,
};

//request untensil related
export const PRINT_UTENSIL_KEY = "print_untensil";
export const showPrintUtensil = (store = {}) => {
    const storeSettingOn = getDefaultPrintUtensilValue(store);
    return storeSettingOn;
};
export const printRequestUtil = (receipt, store, orderData) => {
    const order = _.get(orderData, "data", {});
    //receipt print
    const receiptPrint = helper.isBool(_.get(receipt, PRINT_UTENSIL_KEY))
        ? _.get(receipt, PRINT_UTENSIL_KEY)
        : showPrintUtensil(store);

    //store print
    const restarant = isRestaurant(store);
    const withUtensil = requestUtensil(order);
    const storeValue = getPrintUtensilValue(store);
    const valueMatch =
        storeValue === PRINT_UTENSIL_SETTING_MAP.always_print ||
        (storeValue === PRINT_UTENSIL_SETTING_MAP.print_when_not_request && !withUtensil) ||
        (storeValue === PRINT_UTENSIL_SETTING_MAP.print_when_request && withUtensil);
    const storePrint = restarant && valueMatch;

    //order print
    const orderPrint = requestUtensilNumber(order) !== "-1";

    return receiptPrint && storePrint && orderPrint;
};

//special request position
export const SPECIAL_REQUEST_POSITION_KEY = "sepcial_request_position";
export const SPECIAL_REQUEST_POSITION_OPTIONS = {
    top: "top",
    bottom: "bottom",
};

//due date realted
const SERVER_FORMAT = "YYYY-MM-DD HH:mm";
const TIME_FORMAT = "hh:mm A";
const DATE_FORMAT = "ddd MMM DD";
export const getCustomerReceiptDueTime = (order = {}, store = {}, lan = "en") => {
    const orderData = _.get(order, "data", {});
    const formattedETA = getFormattedETA(orderData, store, lan);
    const formattedExpTime = getPrintFullDate(orderData.exp_dt_start, orderData.exp_dt_end, lan);
    return orderData.est_customer_ready_dt ? formattedETA : orderData.exp_dt_start ? formattedExpTime : [];
};
const getAppLan = () => {
    const momentLan = moment.locale();
    return helper.getKeyByValue(APP_LAN_MOMENT_LAN_MAP, momentLan);
};
const getPrintFullDate = (startDate, endDate, lan) => {
    const appLan = getAppLan();

    if (startDate && startDate.includes("2000-01-01 00:00")) {
        return [ASAP];
    }

    updateMoment(lan);
    const startMoment = moment(startDate, SERVER_FORMAT);
    const endMomment = moment(endDate, SERVER_FORMAT);

    if (startMoment.isValid() && endMomment.isValid()) {
        const startDate = startMoment.format(DATE_FORMAT);
        const endDate = endMomment.format(DATE_FORMAT);
        const startTime = startMoment.format(TIME_FORMAT);
        const endTime = endMomment.format(TIME_FORMAT);
        updateMoment(appLan);
        if (startDate === endDate) {
            return [`${startTime}~${endTime}`, `${startDate}`];
        } else {
            return [`${startDate}, ${startTime}`, `~${endDate}, ${endTime}`];
        }
    }
    return [ASAP];
};
const getFormattedETA = (order, store, lan) => {
    const eta = _.get(order, "est_customer_ready_dt");
    const shippingMethod = getShippingMethodNumber(order);
    const appLan = getAppLan();
    const etaMoment = moment(eta, SERVER_FORMAT);
    if (etaMoment.isValid() && !eta.includes("2000-01-01 00:00")) {
        let range = 0;
        if (helper.isDelivery(shippingMethod)) {
            range = getStoreDeliveryRange(store);
        } //delivery
        if (helper.isPickup(shippingMethod)) {
            range = getStorePickupRange(store);
        } //pickup
        if (range) {
            const endTime = etaMoment.add(range, "minutes").format(SERVER_FORMAT);
            return getPrintFullDate(eta, endTime, lan);
        } else {
            helper.updateMoment(lan);
            const etaUpdatedLan = moment(eta, SERVER_FORMAT);
            const result = [`${etaUpdatedLan.format(TIME_FORMAT)},`, etaUpdatedLan.format(DATE_FORMAT)];
            helper.updateMoment(appLan);
            return [result];
        }
    }

    return [ASAP];
};
export const getChefReceiptDueTime = (order = {}, store = {}, lan = "en") => {
    const appLan = getAppLan();
    const orderData = _.get(order, "data", {});

    const time = orderData.est_dt
        ? orderData.est_dt
        : orderData.exp_dt_start
        ? getDefaultETA(orderData, store).format(SERVER_FORMAT)
        : [];

    if (!moment(time, SERVER_FORMAT).isValid() || time.includes("2000-01-01 00:00")) {
        return [ASAP];
    }

    helper.updateMoment(lan);
    const timeInMoment = moment(time, SERVER_FORMAT);
    const result = [`${timeInMoment.format(TIME_FORMAT)}`, timeInMoment.calendar(null, getCalenderFormat(lan))];
    helper.updateMoment(appLan);

    return result;
};
const getCalenderFormat = (lan) => {
    const string = (id) => locale.getIntlMessages(lan)[id];
    const formats = {
        nextDay: `[${string("tomorrow")}], ${DATE_FORMAT}`,
        sameDay: `[${string("today")}], ${DATE_FORMAT}`,
        lastDay: `[${string("yesterday")}], ${DATE_FORMAT}`,
        sameWeek: DATE_FORMAT,
        lastWeek: DATE_FORMAT,
        nextWeek: DATE_FORMAT,
        sameYear: DATE_FORMAT,
        sameElse: DATE_FORMAT,
    };
    return formats;
};
