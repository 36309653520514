import React, { useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import dH from "../helper";
import { RootState } from "../../../../../../app/reducer";
import { SelectOption } from "../../../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, Button, Modal } from "antd";
import { modType } from "../helper";
import { getCategoriesAsOptions } from "../../../../../categories/helper";
import { getProductsAsOptions } from "../../../../../products/helper";
import { useDispatch, useSelector } from "react-redux";
import {
    setCurrData,
    setAddDelModal,
    setAutoDiscounts,
    clearCurrData,
    finishEdit,
} from "../../../../../../slices/discounts";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
    language: state?.setting?.lan,
});

function AddModeModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const discounts = useSelector((state: RootState) => state.discounts);
    const { categories, language, products } = useSelector(categoriesPageSelector);
    const autoDiscounts = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts);
    const deliveryData = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts?.disc_delivery?.data);
    const pickupData = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts?.disc_pickup?.data);
    const instoreData = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts?.disc_instore?.data);
    const excludeCats = useRef<any>([]);
    const excludeProd = useRef<any>([]);
    const currCondition = _.get(
        discounts,
        "currData.condition",
        _.isEmpty(excludeCats.current) ? (_.isEmpty(excludeProd.current) ? dH.ALL : dH.PRODUCT) : dH.CATEGORY
    );
    const modalType = _.get(discounts, "modType");
    const currData = _.get(discounts, "currData", {});
    const titles = ["disc_delivery", "disc_pickup", "disc_instore"];
    const editIndex = _.get(discounts, "editIndex");

    const getModalTypeData = useCallback(() => {
        let modalTypeData;
        if (Number(modalType === modType.delivery)) {
            modalTypeData = deliveryData;
        } else if (Number(modalType === modType.pickup)) {
            modalTypeData = pickupData;
        } else {
            modalTypeData = instoreData;
        }
        return modalTypeData;
    }, [modalType, deliveryData, pickupData, instoreData]);

    const getExcludeCats = useCallback(() => {
        excludeCats.current = _.get(getModalTypeData(), "exclude_category_ids", []);
    }, [getModalTypeData]);

    const getExcludeProd = useCallback(() => {
        excludeProd.current = _.get(getModalTypeData(), "exclude_product_skus", []);
    }, [getModalTypeData]);

    const setDefaultCurrData = () => {
        const defaultType = currData.type ?? dH.PERCENT;
        dispatch(setCurrData({ name: "type", value: defaultType }));
    };

    useEffect(() => {
        setDefaultCurrData();
    }, []);

    useEffect(() => {
        getExcludeCats();
        getExcludeProd();
    }, [autoDiscounts, getExcludeCats, getExcludeProd]);

    const discountTypes: SelectOption[] = [
        { value: dH.PERCENT, label: intl.formatMessage({ id: "percent" }) },
        { value: dH.AMOUNT, label: intl.formatMessage({ id: "amount" }) },
    ];

    const APPLY_TO_ALL = 0;
    const EXCLUDE_PRODUCTS = 1;
    const EXCLUDE_CATEGORIES = 2;
    const condition: SelectOption[] = [
        { value: APPLY_TO_ALL, label: intl.formatMessage({ id: "apply_to_all" }) },
        { value: EXCLUDE_PRODUCTS, label: intl.formatMessage({ id: "exclude_selected_products" }) },
        { value: EXCLUDE_CATEGORIES, label: intl.formatMessage({ id: "exclude_selected_categories" }) },
    ];

    const closeDelModal = () => {
        dispatch(clearCurrData());
        dispatch(setAddDelModal({ value: false }));
    };

    const isPercentage = () => {
        if (!currData.type) {
            return true;
        }

        return currData.type === dH.PERCENT;
    };

    const checkFilled = () => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        const missMin = intl.formatMessage({ id: "min_amount_missing" });
        const missDisc = intl.formatMessage({ id: "discount_missing" });
        const missProd = intl.formatMessage({ id: "products_missing" });
        const missCat = intl.formatMessage({ id: "categories_missing" });
        if (!currData.min) {
            Modal.error({ title: missMin, content: req });
            return false;
        }
        if (!currData.discount) {
            Modal.error({ title: missDisc, content: req });
            return false;
        }
        if (Number(currData.condition) === dH.PRODUCT && _.isEmpty(currData.exclude_product_skus)) {
            Modal.error({ title: missProd, content: req });
            return false;
        }
        if (Number(currData.condition) === dH.CATEGORY && _.isEmpty(currData.exclude_category_ids)) {
            Modal.error({ title: missCat, content: req });
            return false;
        }

        return true;
    };

    /**
     * Checks the the current discount entered does not have a discount higher
     * than the discount after it, or a discount lower than the discount before it.
     *
     * @param discountData - discount data
     * @param discountMinAmounts - the minimum amounts required for the discount to be applied
     *                              (keys of the discountData)
     * @param i - index of discountMinAmounts to perform comparisions
     * @returns whether the current discount is valid
     */
    const compareDiscounts = (discountData: any, discountMinAmounts: string[], i: number) => {
        const numOfDiscounts = discountMinAmounts.length;
        const minAmount = i < numOfDiscounts ? Number(discountMinAmounts[i]) : undefined;

        const currMinAmount = Number(currData.min);
        const currDiscount = Number(currData.discount);

        // Set index adjustments of the next and previous discount to compare with
        let editDiffLow = 0;
        let editDiffHigh = 0;
        if (editIndex === i - 1) {
            editDiffLow = 1;
        } else if (editIndex === i + 1 && currMinAmount === minAmount) {
            editDiffHigh = 2;
        } else if (editIndex === i || currMinAmount === minAmount) {
            editDiffHigh = 1;
        }

        // Set the next and previous minimum amounts and discounts
        const prevDiscount =
            i > 0 + editDiffLow ? Number(discountData[discountMinAmounts[i - 1 - editDiffLow]]) : undefined;
        const nextDiscount =
            i < numOfDiscounts - editDiffHigh ? Number(discountData[discountMinAmounts[i + editDiffHigh]]) : undefined;
        const prevMinAmount = i > 0 + editDiffLow ? Number(discountMinAmounts[i - 1 - editDiffLow]) : undefined;
        const nextMinAmount =
            i < numOfDiscounts - editDiffHigh ? Number(discountMinAmounts[i + editDiffHigh]) : undefined;

        let discountErrMessage;
        let isValid = true;

        // Assign the values to display in the formatted message
        const options = {
            lt: nextDiscount,
            gt: prevDiscount,
            dollar: !isPercentage() ? "$" : undefined,
            percent: isPercentage() ? "%" : undefined,
        };

        // Set appropriate error message to show
        if (!nextMinAmount) {
            discountErrMessage = "invalid_discount_low";
        } else if (!prevMinAmount) {
            discountErrMessage = "invalid_discount_high";
        } else {
            discountErrMessage = "invalid_discount_between";
        }

        // Checks that the discount is in a valid range
        if (
            (nextMinAmount &&
                currMinAmount < nextMinAmount &&
                ((nextDiscount && currDiscount >= nextDiscount) || (prevDiscount && currDiscount <= prevDiscount))) ||
            (currMinAmount === minAmount &&
                ((prevDiscount && currDiscount <= prevDiscount) || (nextDiscount && currDiscount >= nextDiscount))) ||
            (i === numOfDiscounts && prevDiscount && currDiscount <= prevDiscount)
        ) {
            isValid = false;
        }

        if (!isValid) {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: discountErrMessage }, options),
            });
        }
        return isValid;
    };

    /**
     * Checks whether the discount fields are filled and
     * checks whether the discount entered is valid
     *
     * @returns whether the discount information entered is valid
     */
    const isValidDiscount = () => {
        /**
         * Finds the discounts to compare to check the entered discount's validity
         *
         * @returns whether the current discount entered is valid
         */
        const isValidDiscountHelper = () => {
            const discountData = getModalTypeData() ?? {};
            const discountMinAmounts = Object.keys(discountData);
            for (let i = 0; i < discountMinAmounts.length; i++) {
                const minAmount = Number(discountMinAmounts[i]);

                if ((i === 0 || currData.min >= discountMinAmounts[i - 1]) && currData.min <= minAmount) {
                    return compareDiscounts(discountData, discountMinAmounts, i);
                }
                if (i === discountMinAmounts.length - 1 && currData.min >= minAmount) {
                    return compareDiscounts(discountData, discountMinAmounts, i + 1);
                }
            }

            return true;
        };

        return checkFilled() && isValidDiscountHelper();
    };

    const saveDiscount = () => {
        if (isValidDiscount()) {
            dispatch(setAddDelModal({ value: false }));
            dispatch(finishEdit());
            const combinedData = _.cloneDeep(getModalTypeData() || {});
            if (currData.key) {
                delete combinedData[currData.key];
            }
            combinedData[currData.min] = parseInt(currData.discount);
            const value: any = { data: { ...combinedData }, type: currData.type ?? dH.PERCENT };
            if (Number(currData.condition) === dH.PRODUCT) {
                value.exclude_product_skus = currData.exclude_product_skus;
            } else if (Number(currData.condition) === dH.CATEGORY) {
                value.exclude_category_ids = currData.exclude_category_ids;
            }
            dispatch(
                setAutoDiscounts({
                    name: titles[modalType],
                    value,
                })
            );
        }
    };

    const handleDiscountTypeChange = (value: any) => {
        if (dH.isValidDiscountPercentage(value, currData.discount)) {
            dispatch(setCurrData({ name: "discount", value: 100 }));
        }

        dispatch(setCurrData({ name: "type", value: value }));
    };

    const renderType = (
        <React.Fragment>
            <div className="discount-details">
                <div className="filter-subtitle top">
                    <FormattedMessage id="discount_type" />
                </div>
                <Select
                    className="filter-input d-flex align-items-center discount-details-field"
                    value={currData.type ?? dH.PERCENT}
                    onChange={handleDiscountTypeChange}
                >
                    {discountTypes.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );

    const formatMinAmountNumberInput = (value: string) => {
        return value === "" ? NaN : Number(parseFloat(value).toFixed(2));
    };

    const formatDiscountNumberInput = (value: string) => {
        return value === "" ? NaN : Number(parseInt(value));
    };

    const handleMinChange = (e: any) => {
        const newMinAmount = formatMinAmountNumberInput(e.target.value);

        if (_.isNumber(newMinAmount)) {
            dispatch(setCurrData({ name: "min", value: newMinAmount }));
        }
    };

    const handleDiscountChange = (e: any) => {
        let newDiscount = formatDiscountNumberInput(e.target.value);

        if (dH.isValidDiscountPercentage(currData.type, newDiscount)) {
            newDiscount = 100;
        }

        if (_.isNumber(newDiscount)) {
            dispatch(setCurrData({ name: "discount", value: newDiscount }));
        }
    };

    const renderDiscountDetails = (
        <div className="d-flex discount-details">
            <div className="d-flex flex-column discount-details-field discount-details-mr">
                <div className="filter-subtitle">
                    <FormattedMessage id="minimum_amount" />
                </div>
                <input
                    className="custom-input-textfield number-input"
                    type="number"
                    min="0"
                    value={currData.min ?? `${currData.min}`}
                    onChange={handleMinChange}
                />
            </div>
            <div className="d-flex flex-column discount-details-field">
                <div className="filter-subtitle">
                    <FormattedMessage id="discount" />
                </div>
                <input
                    className="custom-input-textfield number-input"
                    type="number"
                    min="0"
                    value={currData.discount ?? `${currData.discount}`}
                    onChange={handleDiscountChange}
                />
            </div>
        </div>
    );

    const renderConditionSelect = (
        <React.Fragment>
            <div className="discount-details">
                <div className="filter-subtitle">
                    <FormattedMessage id="discount_condition" />
                </div>
                <Select
                    className="filter-input d-flex auto-h align-items-center discount-details-field"
                    value={currCondition ?? dH.ALL}
                    onChange={(e) => dispatch(setCurrData({ name: "condition", value: e }))}
                >
                    {condition.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );

    const renderCategorySelect = () => {
        const getCategories = getCategoriesAsOptions(categories, language);
        return (
            <React.Fragment>
                <div className="discount-details">
                    <div className="filter-subtitle">
                        <FormattedMessage id="exclude_selected_categories" />
                    </div>
                    <Select
                        mode="multiple"
                        className="multi-input d-flex align-items-center discount-details-field"
                        value={currData.exclude_category_ids ?? []}
                        onChange={(e) => dispatch(setCurrData({ name: "exclude_category_ids", value: e }))}
                    >
                        {getCategories.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </React.Fragment>
        );
    };

    const renderProductSelect = () => {
        const getProducts = getProductsAsOptions(products, language);
        return (
            <React.Fragment>
                <div className="discount-details">
                    <div className="filter-subtitle">
                        <FormattedMessage id="exclude_selected_products" />
                    </div>
                    <Select
                        mode="multiple"
                        className="multi-input d-flex align-items-center discount-details-field"
                        value={currData.exclude_product_skus ?? []}
                        onChange={(e) => dispatch(setCurrData({ name: "exclude_product_skus", value: e }))}
                    >
                        {getProducts.map((option: any) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </React.Fragment>
        );
    };

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => closeDelModal()}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button type="primary" onClick={() => saveDiscount()}>
                <FormattedMessage id="update_discount" />
            </Button>
        </div>
    );

    const renderCreateForm = () => (
        <div className="filter-modal d-flex flex-column justify-content-start align-items-start">
            {renderType}
            {renderDiscountDetails}
            {renderConditionSelect}
            {Number(currCondition) === dH.PRODUCT ? renderProductSelect() : null}
            {Number(currCondition) === dH.CATEGORY ? renderCategorySelect() : null}
            {renderActions}
        </div>
    );

    return renderCreateForm();
}

export default AddModeModal;
