import React from "react";
import ReactDOM from "react-dom";

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
        if (styleSheet.cssRules) {
            // true for inline styles
            const newStyleEl = sourceDoc.createElement("style");

            Array.from(styleSheet.cssRules).forEach((cssRule) => {
                newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
            });

            targetDoc.head.appendChild(newStyleEl);
        } else if (styleSheet.href) {
            // true for stylesheets loaded from a URL
            const newLinkEl = sourceDoc.createElement("link");

            newLinkEl.rel = "stylesheet";
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
        }
    });
}

class OrderDetailPrintPortal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.containerEl = document.createElement("div");
        this.externalWindow = null;
    }

    componentDidMount() {
        this.externalWindow = window.open("", "", "width=600,height=400,left=200,top=200");

        this.externalWindow.document.body.appendChild(this.containerEl);

        this.externalWindow.document.title = "Print Order";
        copyStyles(document, this.externalWindow.document);

        this.externalWindow.document.close();
        this.externalWindow.focus();
        this.externalWindow.print();
        this.externalWindow.close();

        this.externalWindow.addEventListener("beforeunload", () => {
            this.props.closeWindowPortal();
        });
    }

    componentWillUnmount() {
        this.externalWindow.close();
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }
}

export default OrderDetailPrintPortal;
