import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { setState } from "slices/create-order";
import { Row, Col } from "antd";
import helper, { formatCurrency } from "util/helper";
import TipsSection from "./tips-section";
import NumberInput from "components/number-input";
import cx from "classnames";
import OrderComment from "./order-comment";
import { IoIosArrowDropdown, IoIosArrowDropright } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { MAX_PRICE } from "app/constants";
import config from "config";

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = {[key in TypeKey]: string}
const currencySymbols: Type = config.CURRENCY_SYMBOL;

const TOTAL_DUE_KEY = "grandTotal";
const DISPLAY_PRICE_INFO_TOTALDUE = "totalDue";
const STORE_DISCOUNT_KEY = "storeDiscountValue";
const DISPLAY_PRICE_INFO_STORE_DISCOUNT_AMOUNT = "storeDiscountAmount";

class OrderPrice extends Component<any, any> {
    state = { showOrderDetails: true };

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    /**
     * Returns a price value using a price object key from payment or displayPriceInfo
     *
     * @param key - price object key to find a price value
     * @returns price value
     */
    getPriceValue = (key: any) => {
        const valueFromPayment = _.get(this.props, `payment.${key}`);
        let valueFromDisplayPriceInfo = _.get(this.props, `displayPriceInfo.${key}`);

        if (key === TOTAL_DUE_KEY) {
            const totalDue = _.get(this.props, `displayPriceInfo.${DISPLAY_PRICE_INFO_TOTALDUE}`);
            if (_.isNumber(totalDue)) {
                valueFromDisplayPriceInfo = Number(totalDue);
            }
        }

        if (key === STORE_DISCOUNT_KEY) {
            const storeDiscountAmount = _.get(
                this.props,
                `displayPriceInfo.${DISPLAY_PRICE_INFO_STORE_DISCOUNT_AMOUNT}`
            );
            if (storeDiscountAmount) {
                valueFromDisplayPriceInfo = Number(storeDiscountAmount);
            }
        }
        if (_.isNil(valueFromDisplayPriceInfo) && _.isNil(valueFromPayment)) {
            return 0;
        } else if (_.isNil(valueFromDisplayPriceInfo)) {
            return Number(valueFromPayment);
        } else {
            return Number(valueFromDisplayPriceInfo);
        }
    };

    getPrice = (priceObj: any) => {
        return Math.abs(priceObj.number || this.getPriceValue(priceObj.key));
    };

    getDisplayRawData = () => {
        const datas: any = [
            {
                title: "surcharge",
                key: "surCharge",
            },
            {
                title: "subtotal",
                key: "subTotal",
                type: "default",
            },
            {
                title: "store_discount",
                key: "storeDiscountValue",
                prefix: "-",
                type: "default",
                onClick: this.getPriceValue("subTotal")
                    ? () =>
                          this.props.setState({
                              showStoreDiscountModal: true,
                          })
                    : null,
            },
            {
                title: "coupon_discount",
                key: "couponDiscount",
                prefix: "-",
            },
        ];

        if (helper.isDelivery(this.props.shippingMethod)) {
            datas.push({
                title: "shipping_and_handling",
                key: "shippingFee",
                allowInput: true,
                type: "default",
            });
        }

        datas.push(
            {
                title: "tax",
                key: "tax",
                type: "default",
            },
            {
                component: <TipsSection />,
                key: "tips",
                type: "default",
            },
            {
                title: "gift_card_payment",
                key: "giftcardPayment",
                prefix: "-",
            },
            {
                title: "points_payment",
                key: "pointsPayment",
                prefix: "-",
            },
            {
                title: "total_due",
                key: TOTAL_DUE_KEY,
                titleStyle: { fontWeight: "bold" },
                rowStyle: { marginTop: 0, paddingRight: 15 },
                type: "default",
            }
        );
        return datas;
    };

    getDisplayData = () => {
        return this.getDisplayRawData().filter((item: any) => {
            const priceValue = this.getPriceValue(item.key);
            return priceValue || item.type === "default";
        });
    };

    updatePrices = (v: any, key: any) => {
        this.props.setState({
            payment: {
                ...this.props.payment,
                [key]: v,
            },
        });
    };

    renderNote = () => {
        if (this.state.showOrderDetails) return <OrderComment />;

        return <div></div>;
    };

    toggleOrderDetails = () => {
        this.setState({ showOrderDetails: !this.state.showOrderDetails });
        this.props.setState({ showOrderDetails: !this.state.showOrderDetails });
    };

    renderDetailsIndicator = () => {
        return (
            <div style={{ position: "absolute", right: 0 }}>
                {this.state.showOrderDetails ? (
                    <IconContext.Provider value={{ color: "dodgerblue" }}>
                        <IoIosArrowDropright />
                    </IconContext.Provider>
                ) : (
                    <IconContext.Provider value={{ color: "dodgerblue" }}>
                        <IoIosArrowDropdown />
                    </IconContext.Provider>
                )}
            </div>
        );
    };

    renderPriceTitle = (priceObj: any) => {
        return (
            <div
                //@ts-ignore
                style={priceObj.titleStyle}
                className={cx({
                    "order-detail-price-title": true,
                    "order-detail-price-title-clickable": priceObj.onClick,
                })}
            >
                <FormattedMessage id={priceObj.title || " "} />
            </div>
        );
    };

    getFormattedValue = (priceObj: any) => {
        return formatCurrency(this.getPrice(priceObj)) || (priceObj.key === TOTAL_DUE_KEY ? formatCurrency(0) : "-");
    };

    getFormattedPriceValue = (priceObj: any) => (
        // IF the title is "store_discount" AND either the cart is empty OR the price is 0
        priceObj.title === "store_discount" && (_.isEmpty(this.props.items) || !this.getPrice(priceObj))
        ? "-"
        : formatCurrency(this.getPrice(priceObj), this.props.storeCurrency, priceObj.prefix)
    );

    renderPriceValue = (priceObj: any) => {
        return (
            <div className="order-detail-price-value">
                {priceObj.key === TOTAL_DUE_KEY ? this.renderDetailsIndicator() : undefined}
                {priceObj.allowInput ? (
                    <NumberInput
                        placeholder={formatCurrency(0, this.props.storeCurrency, priceObj.prefix, false)}
                        min={0}
                        max={MAX_PRICE}
                        precision={2}
                        value={this.getPrice(priceObj || 0)}
                        onChange={(v: any) => this.updatePrices(v, priceObj.key)}
                        name="numberformat"
                        className="create-order-manaul-input"
                        prefix={currencySymbols[this.props.storeCurrency as TypeKey]}
                    />
                ) : (
                    <div style={priceObj.key === TOTAL_DUE_KEY ? { fontWeight: "bold" } : {}}>
                        {this.getFormattedPriceValue(priceObj)}
                    </div>
                )}
            </div>
        );
    };

    renderPriceComponent = (priceObj: any) => {
        return priceObj.component ? (
            <div key={priceObj.key}>{priceObj.component}</div>
        ) : (
            <div
                //@ts-ignore
                style={priceObj.rowStyle}
                key={priceObj.key}
                className="order-detail-price-row"
                onClick={() => {
                    return priceObj.onClick
                        ? priceObj.onClick()
                        : priceObj.key === TOTAL_DUE_KEY
                        ? this.toggleOrderDetails()
                        : null;
                }}
            >
                {this.renderPriceTitle(priceObj)}
                {this.renderPriceValue(priceObj)}
            </div>
        );
    };

    renderPrices = () => {
        return this.getDisplayData().map((priceObj: any) => {
            if (this.state.showOrderDetails || priceObj.key === TOTAL_DUE_KEY) {
                return this.renderPriceComponent(priceObj);
            }

            return null;
        });
    };

    render() {
        return (
            <div id="create-order-price-section" className="create-order-price-section">
                <Row gutter={16}>
                    <Col span={12}>{this.renderNote()}</Col>
                    <Col span={12}>{this.renderPrices()}</Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    items: _.get(state, "createOrder.items", []),
    payment: _.get(state, "createOrder.payment", {}),
    state: _.get(state, "createOrder", true),
    displayPriceInfo: _.get(state, "createOrder.displayPriceInfo", {}),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderPrice)));
