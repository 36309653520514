import { Pagination, PaginationProps } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";
import { setReferralsState } from "../../../../slices/referrals";

function ModalPagnation(): JSX.Element {
    const dispatch = useDispatch();
    const { total, search } = useSelector((state: RootState) => ({
        navigationWidth: state?.ui?.navigationWidth,
        total: state?.referrals?.transactionsPaging?.total,
        search: state?.referrals?.transactionsSearchConditions,
    }));

    const paginationProps: PaginationProps = {
        total,
        pageSize: Number(search?.limit ?? DEFAULT_PAGE_SIZE),
        current: Number(search?.page),
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            dispatch(
                setReferralsState({
                    transactionsSearchConditions: {
                        ...search,
                        page: page ? page : 1,
                        limit: pageSize,
                    },
                })
            );
        },
    };

    return (
        <div className="referral-modal-pagination">
            <Pagination {...paginationProps} />
            <div className="pagination-products-total">
                <FormattedMessage id="total" />: {total}
            </div>
        </div>
    );
}

export default ModalPagnation;
