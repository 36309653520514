import React from "react";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";

function App(): JSX.Element {
    return (
        <React.Fragment>
            <div className="login-page-head">
                <Typography.Title level={5}>
                    <FormattedMessage id="sign_in_welcome" />
                </Typography.Title>
                <Typography.Paragraph>
                    <FormattedMessage id="sign_in_head_hint" />
                </Typography.Paragraph>
            </div>
        </React.Fragment>
    );
}

export default App;
