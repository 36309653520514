import * as React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import config from "../../../../config";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    allowed: state?.pages?.allowed,
});

interface TypeRendererProps {
    value: number | string;
}

function TypeRenderer(props: TypeRendererProps): React.ReactChild {
    const intl = useIntl();
    const { products } = useSelector(selector);

    const record: any = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    return (
        <div className="overflow-dots">
            {/* @ts-ignore */}
            <span>{intl.formatMessage({ id: config.TYPE_OPTIONS?.[String(record?.product_type)] ?? " " })}</span>
        </div>
    );
}

export default TypeRenderer;
