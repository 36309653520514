const StoreProduct = `
    name,                   name,                   true;
    product_id,             product_id,             false;
    product_type,           product_type,           false;
    category_ids,           category_ids,           false;
    sku,                    sku,                    false;
    weight,                 weight,                 false;
    price,                  price,                  false;
    stock,                  stock,                  false;
    tel,                    tel,                    false;
    sold_count,             sold_count,             false;
    status,                 status,                 false;
    tax_class,              tax_class,              false;
    description,            description,            true;
    short_description,      short_description,      true;
    url_key,                url_key,                false;
    meta_title,             meta_title,             true;
    meta_keywords,           meta_keywords,         true;
    meta_desc,              meta_desc,              true;
    related_products,       related_products,       false;
    upsell_products,        upsell_products,        false;
    related_product_list,   related_product_list,   false;
    upsell_product_list,    upsell_product_list,    false;
    updated_at,             updated_at,             false;
    bookvalue,              bookvalue,              false;
    options,                options,                false;
    images,                 images,                 false;
    special_price,          special_price,          false;
    special_from_date,      special_from_date,      false;
    special_to_date,        special_to_date,        false;
    start_date,             start_date,             false;
    end_date,               end_date,               false;
    time,                   time,                   false;
    availability,           availability,           false;
    tel,                    tel,                    false;
    v_url,                  video,                  false;
    start_date,             start_date,             false;
    end_date,               end_date,               false;
    cost,                   cost,                   false;
    barcode,                barcode,                false;
    weight_unit,            weight_unit,            false;
    show_store_link,        show_store_link,        false;
    is_hidden,              is_hidden,              false;
    enable_special_request, enable_special_request, false;
    max_sale_qty,           max_sale_qty,           false;
    min_sale_qty,           min_sale_qty,           false;
`;
export default StoreProduct;
