import { Select } from "antd";
import { RootState } from "app/reducer";
import config from "config";
import _ from "lodash";
import { FILTER_KEYS } from "pages/order-history/helper-order-list";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import liveOrderActions from "slices/live-order";
import "./styles.scss";
import { Paging } from "models/paging";
import { getLocaleString } from "locale";

interface GroupPurchaseListFilterStateProps {
    lan: string;
    groupPurchaseList: any[];
    groupPurchaseListPaging: Paging;
    categoryList: any[];
}

interface GroupPurchaseListFilterProps extends GroupPurchaseListFilterStateProps, PropsFromRedux {
    setModalState: any;
    modalState: any;
}

const LOAD_MORE_VALUE = "-1";

const GroupPurchaseListFilter = (props: GroupPurchaseListFilterProps) => {
    const [categoryListAsOptions, setCategoryListAsOptions] = useState<any[]>([]);
    const [groupPurchaseListAsOptions, setGroupPurchaseListAsOptions] = useState<any[]>([]);
    const [currentCategory, setCurrentCategory] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        setInitialLoad(false);
        props.getGroupPurchaseList({ lan: props.lan });
    }, []);

    useEffect(() => {
        buildCategoryListAsOptions();
    }, [props.categoryList]);

    useEffect(() => {
        buildGroupPurchaseListAsOptions();
    }, [props.groupPurchaseList]);

    useEffect(() => {
        if (initialLoad) {
            return;
        }

        props.getGroupPurchaseList({
            lan: props.modalState.lan,
            cids: currentCategory ? [currentCategory] : undefined,
            limit: props.groupPurchaseList.length || undefined,
        });
        buildCategoryListAsOptions();
    }, [props.modalState.lan]);

    const buildCategoryListAsOptions = () => {
        const options: any[] = [];
        options.push({
            label: getLocaleString("all", props.modalState.lan),
            value: "",
        });
        options.push({
            label: getLocaleString("uncategorized_items", props.modalState.lan),
            value: String(config.CATEGORY_ID_SEARCH_OPTIONS.uncategorized_products),
        });
        props.categoryList.forEach((category) => {
            options.push({
                label: category.name[props.modalState.lan],
                value: String(category.category_id),
            });
        });

        setCategoryListAsOptions(options);
    };

    const buildGroupPurchaseListAsOptions = () => {
        const options: any[] = [];
        props.groupPurchaseList.forEach((product) => {
            options.push({
                label: product.name,
                value: product._id,
            });
        });

        if (props.groupPurchaseListPaging.total > props.groupPurchaseList.length) {
            options.push({
                label: <b>{getLocaleString("load_more", props.modalState.lan)}</b>,
                value: LOAD_MORE_VALUE,
            });
        }

        setGroupPurchaseListAsOptions(options);
    };

    const handleFilterGroupPurchaseListByCategory = (value: string) => {
        setCurrentCategory(value);
        props.getGroupPurchaseList({ lan: props.lan, cids: value ? [value] : undefined });
    };

    const handleSelectGroupPurchaseProduct = (value: any[]) => {
        if (!_.isEmpty(value) && value[value.length - 1] === LOAD_MORE_VALUE) {
            props.getGroupPurchaseList({
                lan: props.lan,
                cids: [currentCategory],
                page: props.groupPurchaseListPaging.page + 1,
            });
        } else {
            props.setModalState({ [FILTER_KEYS.group_purchase_ids]: value });
        }
    };

    return (
        <div className="group-purchase-filter-container">
            <div className="select-category-row">
                <div className="left">
                    <FormattedMessage id="select_category" />
                </div>
                <div className="right">
                    <Select
                        className="category-select-filter"
                        options={categoryListAsOptions}
                        defaultActiveFirstOption
                        defaultValue={getLocaleString("all", props.modalState.lan)}
                        onChange={handleFilterGroupPurchaseListByCategory}
                    />
                </div>
            </div>
            <div className="spacing-8" />
            <Select
                className="gp-select-filter"
                mode="multiple"
                options={groupPurchaseListAsOptions}
                value={props.modalState[FILTER_KEYS.group_purchase_ids]}
                onChange={handleSelectGroupPurchaseProduct}
                allowClear
                placeholder={<FormattedMessage id="select_group_purchase_product" />}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const groupPurchaseListFilterStateProps: GroupPurchaseListFilterStateProps = {
        lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
        groupPurchaseList: _.get(state, "liveOrders.groupPurchaseList", []),
        groupPurchaseListPaging: _.get(state, "liveOrders.groupPurchaseListPaging", {}),
        categoryList: _.get(state, "store.records.cat_list", []),
    };
    return groupPurchaseListFilterStateProps;
};

const mapDispatchToProps = {
    setLiveOrderState: liveOrderActions.setState,
    getGroupPurchaseList: liveOrderActions.getGroupPurchaseList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupPurchaseListFilter);
