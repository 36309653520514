import { Button, Col, Form, Input, Row, Modal } from "antd";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { SignUpView } from "../../constants";
import { api } from "../../../../util/api";
import { useHistory } from "react-router";
import clearText from '../../../../images/icons/icon_clear_text.png'

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(6).required(),
    referral_code: yup.string(),
});

interface SignupInputs {
    email: string;
    password: string;
    referral_code: string;
}

function SelfSignup(props: Record<string, any>): JSX.Element {
    const { setView } = props;
    const { control, handleSubmit, errors, reset } = useForm<SignupInputs>({
        resolver: yupResolver(schema),
    });

    const intl = useIntl();
    const history = useHistory();

    const inputs = [
        [
            [
                {
                    name: "email",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "email" }),
                    },
                },
            ],
            [
                {
                    name: "password",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "password" }),
                        type: "password",
                    },
                },
            ],
            [
                {
                    name: "referral_code",
                    component: Input,
                    props: {
                        placeholder: intl.formatMessage({ id: "referral_code" }),
                    },
                },
            ],
        ],
    ];

    const onSubmit = async (data: SignupInputs) => {
        const response = await api("REGISTER")({
            method: "POST",
            body: JSON.stringify({ ...data }),
        });

        if (response?.RC === 200) {
            Modal.info({
                title: intl.formatMessage({ id: "verification_email_sent" }),
                content: intl.formatMessage({ id: "verification_email_sent_desc" }),
                onOk() {
                    history.push("/");
                },
            });
        } else {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: response?.MSG,
            });
        }
    };

    const getErrorMessage = (message: any) => {
        return message ? (
            <React.Fragment>{intl.formatMessage({ id: message?.split(" ")?.[0] + "_error" })}.</React.Fragment>
        ) : null;
    };

    const renderInput = (input: any) => (
        <Col key={input?.name}>
            <Form.Item
                name={input?.name}
                // @ts-ignore
                help={getErrorMessage(errors?.[input?.name]?.message)}
                // @ts-ignore
                validateStatus={errors?.[input?.name]?.message && "error"}
            >
                <Controller as={input?.component} control={control} name={input?.name} {...input?.props} />
                <img onClick={() => reset({ ...control.getValues(), [input.name]: '' })} style={{ width: '13px', position: 'absolute', right: '10px', top: '10px', zIndex: 1, cursor: "pointer" }} src={clearText} alt='clear text icon' />
            </Form.Item>
        </Col>
    );

    const renderRows = (col: any, j: number, row: any) =>
        row !== null ? (
            <Row gutter={6} key={j}>
                {col.map(renderInput)}
            </Row>
        ) : null;

    const renderInputs = (row: any, i: number) => (
        <div className="signup-form-group" key={i}>
            {row.map((col: any, j: number) => renderRows(col, j, row))}
        </div>
    );

    return (
        <Form className="signup-form">
            {inputs.map(renderInputs)}
            <Button className="signup-button" type="primary" onClick={handleSubmit(onSubmit)}>
                <FormattedMessage id="signup" />
            </Button>

            <Button className="signup-button" onClick={() => setView(SignUpView.SelectMethod)}>
                <FormattedMessage id="go_back" />
            </Button>
        </Form>
    );
}

export default SelfSignup;
