import { List, Modal, Col, Row, Button } from "antd";
import _ from "lodash";
import React from "react";
import { IoMdAddCircle, IoMdClose, IoMdRemoveCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setState } from "../../../../../slices/create-order";
import { formatCurrency, getDiffProperties, getTransString } from "../../../../../util/helper";
import { getProductPrice } from "../../../helper";

const SUMMARY_KEY_PREFIX = "summary-list-";
let summary_key = 0;

class ShoppingCartModal extends React.Component<any, any> {
    getItems = () => {
        return Array.isArray(this.props.items) ? this.props.items : [];
    };

    closeModal = () => {
        this.props.setState({ showCart: false });
    };

    saveChanges = () => {
        let itemsClone = _.cloneDeep(this.getItems());
        itemsClone = itemsClone.filter((item) => item.qty > 0);
        this.props.setParentState({ items: itemsClone });
        this.closeModal();
    };

    addProduct = (product: any) => {
        const itemsClone = _.cloneDeep(this.getItems());

        if (_.isEmpty(this.props.itemsError)) {
            const index = itemsClone.findIndex((item) => {
                return _.isEmpty(getDiffProperties(product, item));
            });

            if (index !== -1) {
                itemsClone[index].qty++;
            }
        }

        this.props.setParentState({ items: itemsClone });
    };

    deleteProduct = (product: any) => {
        const itemsClone = _.cloneDeep(this.getItems());

        if (_.isEmpty(this.props.itemsError)) {
            const index = itemsClone.findIndex((item) => {
                return _.isEmpty(getDiffProperties(product, item));
            });

            if (index !== -1 && itemsClone[index].qty > 0) {
                itemsClone[index].qty--;
            }
        }

        this.props.setParentState({ items: itemsClone });
    };

    clearProduct = (product: any) => {
        const items = this.getItems();
        const itemsClone = _.cloneDeep(items);

        if (_.isEmpty(this.props.itemsError)) {
            const index = items.findIndex((item) => {
                return _.isEmpty(getDiffProperties(product, item));
            });

            if (index !== -1) {
                itemsClone.splice(index, 1);
            }
        }

        this.props.setParentState({ items: itemsClone });
    };

    renderRemove = (item: any) => {
        return (
            <IconContext.Provider value={{ size: "2em" }}>
                <IoMdRemoveCircleOutline className="quick-add-remove-icon" onClick={() => this.deleteProduct(item)} />
            </IconContext.Provider>
        );
    };

    renderAdd = (item: any) => {
        return (
            <IconContext.Provider value={{ size: "2em" }}>
                <IoMdAddCircle className="quick-add-add-icon" onClick={() => this.addProduct(item)} />
            </IconContext.Provider>
        );
    };

    renderItemCount = (count: number) => {
        return <div>{count}</div>;
    };

    renderItemOptions = (option: any) => {
        const selections = Array.isArray(option.opts) ? option.opts : [];
        return (
            <div key={SUMMARY_KEY_PREFIX + summary_key++} className="quick-add-selection-list">
                {"- " + getTransString(option.nm) + ": "}
                {selections.map((selection: any) => {
                    return (
                        <span key={SUMMARY_KEY_PREFIX + summary_key++} className="quick-add-selection-item">
                            {getTransString(selection.nm)}
                        </span>
                    );
                })}
            </div>
        );
    };

    renderItemName = (item: any) => {
        const opts = Array.isArray(item.opts) ? item.opts : [];
        return (
            <>
                <div className="quick-add-product-name">{item.nm}</div>
                {opts.map((option: any) => {
                    if (option.qty_input === 0) {
                        return null;
                    }

                    return this.renderItemOptions(option);
                })}
            </>
        );
    };

    renderItemPrice = (item: any) => {
        return <div className="quick-add-product-price">{formatCurrency(getProductPrice(item) * item.qty)}</div>;
    };

    renderClearItemIcon = (item: any) => {
        return (
            <div className="quick-add-product-clear">
                <IconContext.Provider value={{ size: "2em" }}>
                    <IoMdCloseCircle className="quick-add-clear-icon" onClick={() => this.clearProduct(item)} />
                </IconContext.Provider>
            </div>
        );
    };

    renderCloseIcon = () => {
        return (
            <div className="quick-add-close-icon-container">
                <IconContext.Provider value={{ size: "1.5em" }}>
                    <IoMdClose className="quick-add-close-icon" />
                </IconContext.Provider>
            </div>
        );
    };

    getTotal = (): number => {
        return this.getItems().reduce((acc, item: any) => {
            acc += Number(item.qty) || 0;
            return acc;
        }, 0);
    };

    getAmountTotal = (): number => {
        return this.getItems().reduce((acc, item: any) => {
            //get product price
            const singlePrice = getProductPrice(item);
            const qty = Number(item?.qty) || 1;
            acc += singlePrice * qty;
            return acc;
        }, 0);
    };

    renderTitle = () => {
        return (
            <div className="quick-add-summary-title">
                {this.getTotal() + " "}
                <FormattedMessage id="item_s" />
                {" · " + formatCurrency(this.getAmountTotal())}
            </div>
        );
    };

    renderItem = (item: any, index: number) => {
        return (
            <List.Item key={item.pid + "-" + index} style={{ width: "100%" }}>
                <Row gutter={16} style={{ width: "100%" }}>
                    <Col span={2} className="quick-add-product-count-action">
                        {this.renderRemove(item)}
                    </Col>
                    <Col span={1} className="quick-add-product-count">
                        {item.qty}
                    </Col>
                    <Col span={2} className="quick-add-product-count-action">
                        {this.renderAdd(item)}
                    </Col>
                    <Col span={13}>{this.renderItemName(item)}</Col>
                    <Col span={4}>{this.renderItemPrice(item)}</Col>
                    <Col span={2}>{this.renderClearItemIcon(item)}</Col>
                </Row>
            </List.Item>
        );
    };

    render() {
        return (
            <Modal
                className="quick-add-summary-modal"
                title={this.renderTitle()}
                visible={this.props.state.showCart}
                onCancel={this.closeModal}
                style={{ top: 40 }}
                footer={[
                    <Button className="quick-add-confirm-button" key="submit" type="primary" onClick={this.saveChanges}>
                        <FormattedMessage id="done" />
                    </Button>,
                ]}
                closeIcon={this.renderCloseIcon()}
            >
                <List
                    className="quick-add-summary-content"
                    itemLayout="horizontal"
                    dataSource={this.getItems()}
                    renderItem={(item: any, index: number) => {
                        return this.renderItem(item, index);
                    }}
                />
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    store: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingCartModal)));
