export const PERIODS = {
    ENTER_PERIOD: '0',
    LAST_SEVEN_DAYS: '7',
    LAST_THIRTY_DAYS: '30',
    THIS_WEEK: '-1',
    THIS_MONTH: '-2',
    LAST_MONTH: '-3',
    THIS_YEAR: '-4',
    LAST_YEAR: '-5',
};

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const BEGIN_TIME = {
    HOUR: 6,
    MINUTE: 0,
    SECOND: 0,
};