import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Collapse, Radio } from "antd";
import { FILTER_EXPORT_TYPE_MAPPING, FILTER_KEYS } from "../../../order-history/helper-order-list";

const { Panel } = Collapse;

class ExportDrawerFilterType extends Component {
    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCurentValue = () => _.get(this.props, `modalState.${FILTER_KEYS.export_type}`, false);

    onCollapseCallBack = (key) => {
        const keyValue = _.get(key, key.length - 1);
        this.props.setModalState({ [FILTER_KEYS.export_type]: keyValue });
    };

    getPanelDatas = () => {
        return Object.keys(FILTER_EXPORT_TYPE_MAPPING).map((key) => {
            return {
                label: key,
                value: FILTER_EXPORT_TYPE_MAPPING[key],
            };
        });
    };

    renderPanels = () => {
        return this.getPanelDatas().map((panel) => this.renderPanel(panel));
    };

    renderPanel = (panel) => {
        return (
            <Panel collapsible={false} showArrow={false} header={this.renderPanelHead(panel)} key={panel.value}></Panel>
        );
    };

    renderPanelHead = (panel) => {
        const isCheck = this.getCurentValue() === panel.value;
        return (
            <div>
                <Radio checked={isCheck}>
                    {" "}
                    <FormattedMessage id={panel.label} />
                </Radio>
            </div>
        );
    };

    render() {
        return (
            <Collapse activeKey={[]} onChange={(key) => this.onCollapseCallBack(key)}>
                {this.renderPanels()}
            </Collapse>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportDrawerFilterType));
