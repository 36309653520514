import { Descriptions } from "antd";
import React from "react";
import Wrapper from "../../components/wrapper";
import _ from "lodash";
import "./pending-review.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { getDescriptionItem } from "../../components/form";
import { useSelector } from "react-redux";
import { RootState } from "../../app/reducer";

const breadcrumb = {
    routes: [
        {
            path: "storePendingReview",
            breadcrumbName: "new_store_pending_review",
        },
    ],
};

function StorePendingReview(): JSX.Element {
    const intl = useIntl();
    const { lan, plans, records, store } = useSelector((state: RootState) => ({
        lan: state?.setting?.lan,
        plans: state?.pricePlan?.plans,
        records: state.store?.records,
        store: state.store,
    }));

    const currentPlan = plans.find((plan) => Number(plan.id) === Number(store?.storeInformation?.price_plan));
    const storeAddr = _.get(records, "store_addr", {});

    const renderCurrentPricePlan = () =>
        !_.isEmpty(currentPlan) ? (
            <div className="grouped-inputs general-section" style={{ marginBottom: 18 }}>
                <div className="form-title-description">
                    <h1 className="ant-descriptions-title">
                        <FormattedMessage id="price_plan" />
                    </h1>
                    <p className="ant-descriptions-description">
                        <FormattedMessage id="price_plan_description" />
                    </p>
                </div>
                <Descriptions bordered>
                    {getDescriptionItem("name", 3, currentPlan?.name)}
                    {getDescriptionItem("details", 3, currentPlan?.details)}
                    {getDescriptionItem("setup_fee", 3, currentPlan?.setup_fee)}
                    {getDescriptionItem("monthly_fee", 3, currentPlan?.monthly_fee)}
                    {getDescriptionItem("maximum_languages", 3, currentPlan?.lan_count)}
                </Descriptions>
            </div>
        ) : null;

    const renderStoreInfoSection = () => (
        <div className="grouped-inputs general-section" style={{ marginBottom: 18 }}>
            <div className="form-title-description">
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="store_info" />
                </h1>
            </div>
            <Descriptions bordered>
                {getDescriptionItem("store_name", 3, records?.general_info?.store_nm?.[lan ?? "en"])}
                {getDescriptionItem("store_description", 3, records?.general_info?.store_desc?.[lan ?? "en"])}
                {getDescriptionItem("contact_name", 3, records?.general_info?.owner)}
                {getDescriptionItem("contact_number", 3, records?.general_info?.phone)}
                {getDescriptionItem(
                    "product_display_languages",
                    3,
                    (records?.general_info?.lan_lst ?? [])
                        .map((lan: string) => intl.formatMessage({ id: lan }))
                        .join(", ")
                )}
                {getDescriptionItem(
                    "allow_delivery",
                    3,
                    intl.formatMessage({
                        id: Number(store?.storePickupDelivery?.allow_delivery ?? "0") === 1 ? "yes" : "no",
                    })
                )}
            </Descriptions>
        </div>
    );

    const renderStoreAddressSection = () => (
        <div className="grouped-inputs general-section" style={{ marginBottom: 18 }}>
            <div className="form-title-description">
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="store_address" />
                </h1>
            </div>
            <Descriptions bordered>
                {getDescriptionItem("postal_code", 3, storeAddr?.post_code)}
                {getDescriptionItem("city", 3, storeAddr?.city)}
                {getDescriptionItem("street", 3, storeAddr?.street)}
                {getDescriptionItem("province", 3, storeAddr?.province)}
                {getDescriptionItem("country_code", 3, storeAddr?.country_code)}
                {getDescriptionItem("latitude", 3, storeAddr?.lat)}
                {getDescriptionItem("longitude", 3, storeAddr?.lon)}
                {getDescriptionItem("timezone", 3, storeAddr?.timezone)}
                {getDescriptionItem("unit", 3, storeAddr?.unit)}
            </Descriptions>
        </div>
    );

    return (
        <Wrapper helmet={{ title: "new_store_pending_review" }} breadcrumb={breadcrumb} paddingBottom={72}>
            <div className="pending-review-container">
                {renderCurrentPricePlan()}
                {renderStoreInfoSection()}
                {renderStoreAddressSection()}
            </div>
        </Wrapper>
    );
}

export default StorePendingReview;
