const StoreRestaurantExtras = `
    store_flg,          allow_alcohol,                          false,          25;
    store_flg,          environment,                            false,          26;
    store_flg,          attire,                                 false,          27;
    store_flg,          noise_level,                            false,          30;
    store_flg,          allow_waitlist,                         false,          17;
    store_flg,          waiter_service,                         false,          18;
    store_flg,          good_for_group,                         false,          28;
    store_flg,          good_for_kids,                          false,          29;
    store_flg,          takes_reservation,                      false,          21;
    store_flg,          good_for_business,                      false,          33;
    store_flg,          has_outdoor_seating,                    false,          31;
    store_flg,          bike_parking,                           false,          32;
    store_flg,          tv_available,                           false,          22;
    store_flg,          wifi,                                   false,          24;
    store_flg,          parking,                                false,          23;
    store_flg,          allow_office_delivery,                  false,          54;
`

export default StoreRestaurantExtras;