import { Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectOption } from "../../../../components/form";
import { DEFAULTS, ORDER_TIMES } from "./constants";

interface OrderTimeProps {
    orderTime: number;
    setState: any;
}

export const OrderTime = (props: OrderTimeProps): JSX.Element => {
    const intl = useIntl();

    const orderTimeOptions: any[] = [
        { value: ORDER_TIMES.ANY, label: intl.formatMessage({ id: "auto_accept_time_any_time" }) },
        { value: ORDER_TIMES.STORE_HOURS, label: intl.formatMessage({ id: "auto_accept_time_store_hours_only" }) },
    ];

    return (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="auto_accept_order_time" />
                </div>
                <Select
                    className="surTax-field"
                    value={props.orderTime ?? DEFAULTS.ORDER_TIME}
                    onChange={(val) => props.setState("order_time", val)}
                >
                    {orderTimeOptions.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};
