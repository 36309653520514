import React from "react";
import Wrapper from "../../components/wrapper";
import "./index.scss";
import StoreSwitchForm from "./_components/store-switch-form";

const breadcrumb = {
    routes: [
        {
            path: "",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "storeSwitch",
            breadcrumbName: "store_switch",
        },
    ],
};

function App(): JSX.Element {
    return (
        <Wrapper helmet={{ title: "Store Switch" }} breadcrumb={breadcrumb}>
            <StoreSwitchForm />
        </Wrapper>
    );
}

export default App;
