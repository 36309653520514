import { Button, Descriptions, Modal } from "antd";
import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { AiOutlineClockCircle, AiOutlineEdit } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getDescriptionItem } from "../../../../components/form";
import Wrapper from "../../../../components/wrapper";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import ObjectModel from "../../../../util/models";
import StoreHours from "../../../../util/models/store-hours";
import "./index.scss";
import { DAYS } from "./constants";
import { reducer, getHoursFormattedAsObject, formatHoursAsString, canSaveChanges } from "./helpers";
import { HoursSelectRow } from "./_components/hours-select-row";
import { HoursView } from "./_components/hours-view";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/businessHours", breadcrumbName: "business_hours" },
    ],
};

const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const hours = useSelector((state: RootState) => state.store.storeHours);
    const [dHours, dHoursDispatch]: any[] = useReducer<any>(reducer, {});
    const [bHours, bHoursDispatch]: any[] = useReducer<any>(reducer, {});
    const [bViewMode, setBViewMode]: any[] = useState<boolean>(true);
    const [dViewMode, setDViewMode]: any[] = useState<boolean>(true);

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    useEffect(() => {
        dHoursDispatch({
            type: "loadHours",
            payload: getHoursFormattedAsObject("delivery_hours", hours),
        });

        bHoursDispatch({
            type: "loadHours",
            payload: getHoursFormattedAsObject("business_hours", hours),
        });
    }, [hours]);

    const onSubmit = () => {
        if (!_.isEmpty(document.getElementsByClassName("hours-range-error"))) {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: "error_hours_overlap" }),
            });
            return;
        }

        const postData = new ObjectModel(StoreHours).convertToPostData(
            {
                delivery_hours: formatHoursAsString(dHours),
                business_hours: formatHoursAsString(bHours),
            },
            null,
            "business_hours"
        );
        dispatch(updateStoreDetails(postData));
    };

    const onEditOrCancel = (bHoursEdit: boolean, isEdit: boolean) => {
        if (!_.isEmpty(document.getElementsByClassName("hours-range-error"))) {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: "error_hours_overlap" }),
            });
            return;
        }

        if (bHoursEdit) {
            setBViewMode(!isEdit);
        } else {
            setDViewMode(!isEdit);
        }
    };

    const hoursSection = (viewMode: boolean, hoursType: boolean, hours: any, dispatchFunc: any) => {
        if (viewMode) {
            // view
            return !_.isEmpty(hours) ? (
                <div className="hours-view-section">
                    <AiOutlineClockCircle className="hours-view-clock-icon" />
                    <Descriptions bordered className="general-section-view">
                        {Object.keys(DAYS).map((day, key) => {
                            return getDescriptionItem(DAYS[day], 3, <HoursView day={day} hours={hours} key={key} />);
                        })}
                    </Descriptions>
                    <Button className="edit-hours-btn" onClick={() => onEditOrCancel(hoursType, true)}>
                        <AiOutlineEdit />
                    </Button>
                </div>
            ) : null;
        }

        // edit
        return !_.isEmpty(hours) ? (
            <Descriptions bordered className="general-section">
                {Object.keys(DAYS).map((key) => {
                    return getDescriptionItem(
                        DAYS[key],
                        3,
                        <HoursSelectRow day={key} hours={hours} dispatch={dispatchFunc} />
                    );
                })}
                {getDescriptionItem(
                    " ",
                    3,
                    <div className="cancel-hours-btn-row">
                        <Button className="cancel-hours-btn" onClick={() => onEditOrCancel(hoursType, false)}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </div>
                )}
            </Descriptions>
        ) : null;
    };

    // hoursType: true - business, false - delivery
    const renderHoursSection = (hoursType: boolean) => (
        <>
            <div className={`form-title-description ${hoursType ? "" : "general-section"}`}>
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id={hoursType ? "business_hours" : "delivery_hours"} />
                </h1>
                <p className="ant-descriptions-description">
                    <FormattedMessage id={hoursType ? "business_hours_description" : "delivery_hours_description"} />
                </p>
            </div>
            {hoursSection(
                hoursType ? bViewMode : dViewMode,
                hoursType,
                hoursType ? bHours : dHours,
                hoursType ? bHoursDispatch : dHoursDispatch
            )}
        </>
    );

    return (
        <Wrapper helmet={{ title: "business_hours" }} breadcrumb={breadcrumb}>
            <div className="settings-general-page business-hours-container">
                {renderHoursSection(true)}
                {renderHoursSection(false)}
                <div className="setting-actions" style={{ marginTop: 14 }}>
                    <Button
                        type="primary"
                        size="large"
                        onClick={onSubmit}
                        className="save-button-handler"
                        disabled={!canSaveChanges([bHours, dHours])}
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};

export default App;
