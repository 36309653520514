import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import helper, { formatNumberWithCommas } from "../../../util/helper";
import ReferralsActionRenderer from "../_components/table/referrals-action-renderer";
import ReferralsValueRenderer from "../_components/table/referrals-value-renderer";
import useReferralsSearchCondition from "./useReferralsSearchConditions";

function useReferralsTable(): any {
    useReferralsSearchCondition();
    const referrals = useSelector((state: RootState) => state?.referrals?.referrals);
    const tableRef = useRef<any>(null);
    const intl = useIntl();

    let gridApi: any;

    const columnDefs = [
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "customer_name" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.customer_name,
            },
        },
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "phone" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => helper.formatPhone(record?.customer_phone),
            },
        },
        {
            field: "_id",
            width: 350,
            headerName: intl.formatMessage({ id: "email" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.customer_email,
            },
        },
        {
            field: "_id",
            width: 200,
            headerName: intl.formatMessage({ id: "referrals_count" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => formatNumberWithCommas(record?.customer_referrals_count),
            },
        },
        {
            field: "_id",
            width: 200,
            headerName: intl.formatMessage({ id: "reward_balance" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => formatNumberWithCommas(record?.reward_balance),
            },
        },
        {
            field: "_id",
            width: 150,
            resizable: false,
            headerName: intl.formatMessage({ id: "actions" }),
            cellRenderer: "actionRenderer",
        },
    ];

    const getTableObject: any = () => ({
        columnDefs,
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: ReferralsValueRenderer,
            actionRenderer: ReferralsActionRenderer,
        },
        rowData: referrals,
        getRowHeight: () => 50,
        getRowNodeId: (row: any) => row?._id,
        immutableData: true,
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const getTable = () => {
        const tableObject = getTableObject();
        return React.createElement(
            "div",
            { className: "customers-table-container ag-theme-alpine" },
            React.createElement(AgGridReact, { ...tableObject })
        );
    };

    const onResize = () => {
        if (tableRef?.current?.firstChild?.style?.height) {
            tableRef.current.firstChild.style.height = tableRef?.current?.offsetHeight + "px";
        }
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return {
        getTable,
    };
}

export default useReferralsTable;
