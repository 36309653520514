import { Button, Form, Input, InputNumber, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import qs from "querystring";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../app/reducer";
import { useSelector } from "react-redux";
import config from "../../../../config";
import helper from "../../../../util/helper";
import { MIN_BARCODE_LEN, MAX_BARCODE_LEN } from "../../constants";

const selector = (state: RootState) => ({
    categories: state?.categories?.categories,
    language: state?.setting?.lan,
});

const ALWAYS_AVAILABLE = "1";
const ALL_TYPES = "-1";
const ALL_STATUS = "0";

interface ProductFilterProps {
    closeModal: any;
    search: any;
}

const ProductFilter = (props: ProductFilterProps): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    const { categories, language } = useSelector(selector);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const cloned: any = _.cloneDeep(data);

        _.each(cloned, (value, key) => {
            if (_.isUndefined(value)) {
                delete cloned[key];
            }

            if (String(value) === "") {
                delete cloned[key];
            }
        });

        if (String(cloned.product_type) === ALL_TYPES) {
            delete cloned.product_type;
        }

        if (String(cloned.availability) === ALWAYS_AVAILABLE) {
            delete cloned.availability;
        }

        if (String(cloned.status) === ALL_STATUS) {
            delete cloned.status;
        }

        const query: any = qs.stringify(cloned);

        history.push("/products?" + query);

        props.closeModal();
    };

    const resetFilters = () => {
        form.resetFields();

        history.push("/products");

        props.closeModal();
    };

    const getCategoriesAsOptions = () => {
        return [
            {
                value: "",
                label: intl.formatMessage({ id: "all_categories" }),
            },
            {
                value: config.CATEGORY_ID_SEARCH_OPTIONS.uncategorized_products,
                label: intl.formatMessage({ id: "uncategorized_items" }),
            },
            ...(categories
                .filter((c) => Number(c.level) !== config.CATEGORY_LEVELS.root)
                .map((c) => ({
                    value: c.category_id,
                    label: helper.getTransString(c.name, language),
                })) ?? []),
        ];
    };

    const getOptions = (config: any, exclude: any = []) => {
        const options: any = [];

        Object.keys(config).forEach((key: any) => {
            if (!exclude.includes(key)) {
                options.push({
                    value: key,
                    label: intl.formatMessage({ id: config[key] }),
                });
            }
        });

        return options;
    };

    const renderOptions = (options: any[]) =>
        options.map((o) => (
            <Select.Option value={String(o.value)} key={o.value}>
                {o.label}
            </Select.Option>
        ));

    useEffect(() => {
        form.resetFields();
    }, [form, props.search]);

    return (
        <div>
            <Form
                labelCol={{ span: 6 }}
                form={form}
                onFinish={onFinish}
                initialValues={Object.assign(
                    {
                        availability: ALWAYS_AVAILABLE,
                        product_type: ALL_TYPES,
                        category_id: "",
                        status: ALL_STATUS,
                    },
                    props.search
                )}
            >
                <div className="d-flex">
                    <div style={{ flex: 1, paddingRight: 6 }}>
                        <FormattedMessage id="name" />
                        <Form.Item name="product_name">
                            <Input placeholder={intl.formatMessage({ id: "type_here" })} />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1, paddingLeft: 6 }}>
                        <FormattedMessage id="product_type" />
                        <Form.Item name="product_type">
                            {/* don't allow filter coupon */}
                            <Select placeholder={intl.formatMessage({ id: "select_option" })}>
                                {renderOptions(getOptions(config.TYPE_OPTIONS, ["4"]))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className="d-flex">
                    <div style={{ flex: 1, paddingRight: 6 }}>
                        <FormattedMessage id="sku" />
                        <Form.Item name="product_sku">
                            <Input placeholder={intl.formatMessage({ id: "type_here" })} />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1, paddingLeft: 6 }}>
                        <FormattedMessage id="availability" />
                        <Form.Item name="availability">
                            <Select placeholder={intl.formatMessage({ id: "select_option" })}>
                                {renderOptions(getOptions(config.AVAILABILITY_FILTER_OPTIONS))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <FormattedMessage id="barcode" />
                <Form.Item name="barcode">
                    <Input
                        placeholder={intl.formatMessage({ id: "barcode" })}
                        minLength={MIN_BARCODE_LEN}
                        maxLength={MAX_BARCODE_LEN}
                        allowClear={true}
                    />
                </Form.Item>
                <FormattedMessage id="category" />
                <Form.Item name="category_id">
                    <Select placeholder={intl.formatMessage({ id: "select_option" })}>
                        {renderOptions(getCategoriesAsOptions())}
                    </Select>
                </Form.Item>
                <FormattedMessage id="status" />
                <Form.Item name="status">
                    <Select placeholder={intl.formatMessage({ id: "select_option" })}>
                        {renderOptions(getOptions(config.STATUS_FILTER_OPTIONS))}
                    </Select>
                </Form.Item>
                <FormattedMessage id="price_range" />
                <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                    <Form.Item name="price_from">
                        <InputNumber className="w100" placeholder={intl.formatMessage({ id: "price_from" })} />
                    </Form.Item>
                    <hr className="dash" />
                    <Form.Item name="price_to">
                        <InputNumber className="w100" placeholder={intl.formatMessage({ id: "price_to" })} />
                    </Form.Item>
                </div>
                <FormattedMessage id="quantity_range" />
                <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                    <Form.Item name="quantity_from">
                        <InputNumber className="w100" placeholder={intl.formatMessage({ id: "quantity_from" })} />
                    </Form.Item>
                    <hr className="dash" />
                    <Form.Item name="quantity_to">
                        <InputNumber className="w100" placeholder={intl.formatMessage({ id: "quantity_to" })} />
                    </Form.Item>
                </div>
                <div className="footer-filter">
                    <Button type="link" onClick={resetFilters}>
                        <FormattedMessage id="reset_filters" />
                    </Button>
                    <Button type="primary" htmlType="submit" className="save-button-handler">
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ProductFilter;
