export const PREFERRED_DELIVERY_METHOD_VALUES = {
    self_local_delivery: "1",
    third_party_local: "2",
    third_party_courier: "3",
};

export const RESTORE_OPTIONS = {
    overwrite: 1,
    append: 2,
};

export const MANDATORY_FIELDS_DISPLAY = [
    { name: 'mandatory_fields_for_delivery_task', type: 'TEXT' },
    { name: "order_id", disable: false, type: 'CHECKBOX' },
    { name: "items", disable: false, type: 'CHECKBOX' },
    { name: "order_value", disable: false, type: 'CHECKBOX' },
    { name: "tips", disable: false, type: 'CHECKBOX' },
    { name: "pickup_time", disable: false, type: 'CHECKBOX' },
    { name: "delivery_time", disable: false, type: 'CHECKBOX' },
    { name: "address", disable: true, type: 'CHECKBOX' }
]