import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import CustomerSearch from "./customer-search";
import AddCustomerDialog from "./add-customer-dialog";
import "./customer-info.scss";
import CustomerDetail from "./customer-detail";
import { setState } from "../../../../slices/create-order";
import Collapse from "@material-ui/core/Collapse";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

class Customer extends Component<any, any> {
    state = { in: true };

    withCustomer = () => !_.isEmpty(this.props?.customer) && this.props?.customer?.id !== 0;

    getShareProps = () => ({
        parentState: this.props.state,
        setParentState: (values: any) => this.props.setState(values),
    });

    renderTitle = () => {
        return (
            <div
                onClick={() => {
                    if (this.withCustomer()) {
                        this.setState({ in: !this.state.in });
                    }
                }}
                style={{ marginBottom: this.state.in ? 20 : 0 }}
                className="section-title"
            >
                <div>
                    <FormattedMessage id={this.withCustomer() ? "customer" : "find_or_create_a_customer"} />
                </div>
                {this.withCustomer() ? (
                    <div className="section-title-actions">
                        <div className="create-order-shrinkable">
                            {this.state.in ? <DownOutlined /> : <RightOutlined />}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    renderPickCustomer = () => {
        return (
            <div>
                {this.renderTitle()}
                <CustomerSearch {...this.getShareProps()} />
                <AddCustomerDialog {...this.getShareProps()} />
            </div>
        );
    };

    renderCustomer = () => {
        return (
            <div>
                {this.renderTitle()}
                <Collapse in={this.state.in}>
                    <CustomerDetail {...this.getShareProps()} />
                </Collapse>
            </div>
        );
    };

    render() {
        return <div className="section">{this.withCustomer() ? this.renderCustomer() : this.renderPickCustomer()}</div>;
    }
}

const mapStateToProps = (state: any) => ({
    customer: _.get(state, "customers.customer", []),
    state: _.get(state, "createOrder", {}),
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Customer)));
