import moment from "moment";
import momentTz from "moment-timezone";
import helper, { num2Binary, sprintf, strToCharArray } from "util/helper";
import config from "config";
import _ from "lodash";
import locale from "locale";
import { getDeliveryStatusNumber, getOrderStatusNumber, getShippingMethodNumber } from "pages/order-history/helper";
import {
    getCustomerName,
    getCustomerPhone,
    getOrderId,
    getOrderTableOriginal,
} from "pages/order-history/helper-order-list";

export const ITEM_CHANGE_ACTION_CHECK_PRICES = 4;

export const LIVE_ORDER_CARD_ID_PREFIX = "live-order-card-id-";

export const sortList = (liveOrderList) => {
    const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
    const SORT_BY = "ord_dt";
    let list = _.cloneDeep(liveOrderList);
    let sortedList = _.sortBy(list, (order) => {
        let timeValue = 0;
        if (order[SORT_BY]) {
            timeValue = moment(order[SORT_BY], TIME_FORMAT).valueOf();
        }
        return timeValue;
    });
    sortedList = _.reverse(sortedList);
    sortedList = _.unionBy(sortedList, (order) => getOrderId(order));
    return Array.isArray(sortedList) ? _.cloneDeep(sortedList) : [];
};

export const isGroupedOrder = (order) => {
    const orderIds = Array.isArray(_.get(order, "orderIds")) ? _.get(order, "orderIds") : [];
    return orderIds.length > 1;
};

export const groupList = (paramList, otherParams) => {
    var groupedList = {},
        sortedList = {};
    const categoriesStatuses = config.ORDER_SHORTEN_CATEGORY_STATUS_MAPPING;
    let list = _.cloneDeep(paramList);
    let filter = _.get(otherParams, "filteredKeyword", "");
    let filterShipping = _.get(otherParams, "filteredShippingMethod", "");
    let groupByTable = _.get(otherParams, "groupByTable", false);

    const matchFilter = (order) => {
        let filterFit = true,
            shippngFit = true;
        if (helper.isString(filter) && filter) {
            filter = filter.toLowerCase();
            const specialCharacterRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/\s]/gi;
            const id = getOrderId(order).toLowerCase();
            const customerName = getCustomerName(order).toLowerCase();
            const customerPhone = getCustomerPhone().replace(specialCharacterRegex, "");
            const customerTable = getOrderTableOriginal(order).toLowerCase();
            const filterWithDigit = filter.replace(specialCharacterRegex, "");
            const idFits = id.includes(filter);
            const nameFits = customerName.includes(filter);
            const phoneFits = customerPhone.includes(filterWithDigit) && filterWithDigit;
            const tableFits = customerTable.includes(filter);
            filterFit = idFits || nameFits || phoneFits || tableFits;
        }
        if (Number(filterShipping) > 0) {
            shippngFit = Number(filterShipping) === getShippingMethodNumber(order);
        }
        return filterFit && shippngFit;
    };

    if (groupByTable) {
        //group order by table
        let tableGroupedOrder = {};
        list.forEach((order) => {
            const table = getOrderTableOriginal(order);
            const gid = _.get(order, "g_id");
            const tableSignature = table + gid;
            const originalValue = Array.isArray(tableGroupedOrder[tableSignature])
                ? tableGroupedOrder[tableSignature]
                : [];
            if (table) {
                tableGroupedOrder[tableSignature] = [...originalValue, order];
            }
        });
        //reformat grouped orders
        let handledIds = [];
        let newList = [];
        list.forEach((order) => {
            const groupedLists = Object.values(tableGroupedOrder);
            //find out if inside a goup
            //if yes, merge order
            //else returned as a normal order
            const groupedList = groupedLists.find((groupedOrders) => {
                return groupedOrders.find((ord) => getOrderId(ord) === getOrderId(order));
            });
            if (handledIds.includes(getOrderId(order))) {
                return;
            }
            if (groupedList) {
                let newOrder = {
                    ..._.get(groupedList, 0, {}),
                    comments: [],
                    orders: [],
                    orderIds: [],
                };
                groupedList.forEach((ord, i) => {
                    if (i > 0) {
                        //get smallest order status
                        const statusRelatedFields = ["ord_st", "pay_status", "delivery_status", "courier_status"];
                        statusRelatedFields.forEach((field) => {
                            newOrder[field] = ord[field] < newOrder[field] ? ord[field] : newOrder[field];
                        });

                        //money realted are added on
                        const moneyRelatedFields = [
                            "tips",
                            "sb_ttl",
                            "ord_ttl",
                            "display_amt",
                            "dy_fee",
                            "dis_ttl",
                            "store_dis",
                            "cpn_dis",
                            "tax",
                            "gc_amt",
                            "ttl_pd",
                            "ttl_due",
                            "gc_cd",
                            "pt_num",
                            "pt_amt",
                            "rf_amt",
                            "item_ttl",
                            "ttl_b4_tax",
                            "taxes",
                        ];
                        moneyRelatedFields.forEach((field) => {
                            const value = ord[field];
                            const oldValue = Number(newOrder[field]) ? newOrder[field] : 0;
                            if (_.isObject(value)) {
                                Object.keys(value).forEach((key) => {
                                    const oldKeyValue = Number(_.get(newOrder, `${field}.${key}`))
                                        ? _.get(newOrder, `${field}.${key}`)
                                        : 0;
                                    const newKeyValue = Number(value[key]) ? value[key] : 0;
                                    newOrder[field][key] = oldKeyValue + newKeyValue;
                                });
                            } else if (Number(value)) {
                                newOrder[field] = oldValue + Number(value);
                            }
                        });

                        //date realted fields
                        const datteRelatedFields = ["est_dt", "est_customer_ready_dt", "exp_dt_start", "exp_dt_end"];
                        datteRelatedFields.forEach((field) => {
                            const newValue = moment(ord[field]).isValid() ? moment(ord[field]).valueOf() : 0;
                            const oldValue = moment(newOrder[field]).isValid() ? moment(newOrder[field]).valueOf() : 0;
                            if (newValue) {
                                newOrder[field] = newValue > oldValue ? ord[field] : newOrder[field];
                            }
                        });

                        newOrder.item_changes = [
                            ...(Array.isArray(_.get(newOrder, "item_changes")) ? _.get(newOrder, "item_changes") : []),
                            ...(Array.isArray(_.get(ord, "item_changes")) ? _.get(ord, "item_changes") : []),
                        ];

                        newOrder.menu_ids = _.unionBy(
                            [
                                ...(Array.isArray(_.get(newOrder, "menu_ids")) ? _.get(newOrder, "menu_ids") : []),
                                ...(Array.isArray(_.get(ord, "menu_ids")) ? _.get(ord, "menu_ids") : []),
                            ],
                            "id"
                        );

                        //merge items
                        const oldItems = Array.isArray(_.get(newOrder, "ord_itm")) ? _.get(newOrder, "ord_itm") : [];
                        const newItems = Array.isArray(_.get(ord, "ord_itm")) ? _.get(ord, "ord_itm") : [];
                        let itemsGroups = {};
                        //create and id based on pid and options selection and group them
                        const groupByItem = (item) => {
                            const pid = _.get(item, "pid");
                            let optionIdStr = "";
                            const opts = Array.isArray(_.get(item, "opts")) ? _.get(item, "opts") : [];
                            opts.forEach((opt) => {
                                const optId = _.get(opt, "id", "");
                                optionIdStr += optId;
                                const subOpts = Array.isArray(_.get(opt, "opts")) ? _.get(opt, "opts") : [];
                                subOpts.forEach((subOpt) => {
                                    const subOptId = _.get(subOpt, "id", "");
                                    const subOptQty = _.get(subOpt, "qty", "");
                                    optionIdStr += subOptId;
                                    optionIdStr += subOptQty;
                                });
                            });
                            const itemSignature = pid + optionIdStr;
                            itemsGroups[itemSignature] = {
                                item,
                                itemQty: _.get(itemsGroups, `${itemSignature}.itemQty`, 0) + _.get(item, "cnt", 0),
                            };
                        };
                        oldItems.forEach((item) => groupByItem(item));
                        newItems.forEach((item) => groupByItem(item));
                        newOrder.ord_itm = Object.keys(itemsGroups).map((key) => {
                            return {
                                ..._.get(itemsGroups, `${key}.item`, {}),
                                cnt: _.get(itemsGroups, `${key}.itemQty`, 1),
                            };
                        });
                    }
                    newOrder.comments = [
                        ...(Array.isArray(_.get(newOrder, "comments")) ? _.get(newOrder, "comments") : []),
                        ...(Array.isArray(_.get(ord, "comments"))
                            ? _.get(ord, "comments").map((comment) => {
                                  comment.orderId = getOrderId(ord);
                                  return comment;
                              })
                            : []),
                    ].filter((cmt) => parseInt(cmt.is_admin) === 0);
                    newOrder.orders = [...newOrder.orders, ord];
                    newOrder.orderIds = [...newOrder.orderIds, getOrderId(ord)];
                    handledIds.push(getOrderId(ord));
                });
                newList.push(newOrder);
            } else {
                handledIds.push(getOrderId(order));
                newList.push(order);
            }
        });
        list = _.cloneDeep(newList);
    }

    list.forEach((order) => {
        const deliveryStatus = getDeliveryStatusNumber(order);
        Object.keys(categoriesStatuses).forEach((key) => {
            const includedStatues = categoriesStatuses[key];
            if (includedStatues.includes(String(deliveryStatus)) && matchFilter(order)) {
                var currentList = _.get(groupedList, key, []);
                currentList.push(order);
                groupedList[key] = currentList;
            }
        });
    });

    Object.keys(categoriesStatuses).map((key) => {
        const groupedArray = _.get(groupedList, key);
        if (!_.isEmpty(groupedArray)) sortedList[key] = groupedArray;
        return key;
    });
    return sortedList;
};

export const getCaculateRemainTime = (time, order = {}, store = {}, showOverDue = false, lan) => {
    const timeZone = getStoreTimeZone(store);
    let now = timeZone ? moment().tz(timeZone) : moment();
    let eta = moment(time);
    let toReturn = "";
    const showStatuses = [
        config.ORDER_STATUS_MAPPING_TO_NUMERIC.pending,
        config.ORDER_STATUS_MAPPING_TO_NUMERIC.processing,
    ];
    if (
        helper.isString(time) &&
        !time.includes("2000-01-01") &&
        eta.isValid() &&
        showStatuses.includes(getOrderStatusNumber(order))
    ) {
        let duration = moment.duration(eta.diff(now));
        if (Math.abs(duration.asHours()) >= 24) {
            const hours = Math.abs(duration.asHours());
            const days = parseInt(hours / 24);
            const remainHours = parseInt(hours % 24);
            toReturn = `${days}d${remainHours ? ` ${remainHours}h` : ""}`;
        } else if (Math.abs(duration.asMinutes()) >= 60) {
            const mins = Math.abs(duration.asMinutes());
            const hours = parseInt(mins / 60);
            const remainMins = parseInt(mins % 60);
            toReturn = `${hours}h${remainMins ? ` ${remainMins}m` : ""}`;
        } else if (Math.abs(duration.asMinutes()) > 1) {
            toReturn = Math.abs(duration.asMinutes().toFixed(0)) + "m";
        } else if (Math.abs(duration.asSeconds()) > 0) {
            toReturn = "1m";
        }
        if (now.isAfter(time) && showOverDue) {
            const translated = locale.getIntlMessages(lan, "common")["over_due_x"];
            toReturn = sprintf(translated, toReturn);
        }
        if (!showOverDue && now.isAfter(time)) {
            toReturn = "";
        }
    }
    return toReturn;
};

export const shouldShowRed = (time, store) => {
    const timeZone = getStoreTimeZone(store);
    let now = timeZone ? moment().tz(timeZone) : moment();
    let eta = moment(time);
    let toReturn = false;
    if (time && helper.isString(time) && !time.includes("2000-01-01 00:00:00") && eta.isValid()) {
        toReturn = now.isAfter(time);
    }
    return toReturn;
};

//got from store helper
const STORE_DELIVERY_PREP_TIME_FLAG_INDEX = 36; //30 means it takes 30min to prepare the food before pickup/delivery can happen. Default: 0
const STORE_PREP_OREDER_THRESHOLD = 58; // Default: 60. Time in minutes for which an order can wait before it's preparation begins. (added 2020-10-21)
const STORE_REQUIRE_UTENSIL_FLAG_INDEX = 68;
const STORE_PRINT_UTENSIL_PREFERENCE_FLAG_INDEX = 69;
const STORE_PICKUP_PREP_TIME_FLAG_INDEX = 72;
const STORE_OFFLINE_PAYMENT_FLAG = 73;
const STORE_DELIVERY_RANGE = 49;
const STORE_PICKUP_RANGE = 50;
export const STORE_FLAGS = {
    delivery_prep_time: 36, //30 means it takes 30min to prepare the food before pickup/delivery can happen. Default: 0
    allow_online_order: 38,
    prep_order_threshold: 58, // Default: 60. Time in minutes for which an order can wait before it's preparation begins. (added 2020-10-21)
    delivery_range: 49,
    pickup_range: 50,
    requrie_untensil: 68,
    print_untinsil_preference: 69,
    pickup_prep_time: 72,
    allow_offline_pay: 73,
    require_due_time_for_in_store_order: 76,
};

export const getStorePickupRange = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const time = flagsArr[STORE_PICKUP_RANGE];
    return Number.isNaN(time) ? 0 : time;
};

export const getStoreDeliveryRange = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const time = flagsArr[STORE_DELIVERY_RANGE];
    return Number.isNaN(time) ? 0 : time;
};

export const getStorePrepTime = (store, shippingMethod) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const storePrepTime =
        flagsArr[
            helper.isPickup(shippingMethod) ? STORE_PICKUP_PREP_TIME_FLAG_INDEX : STORE_DELIVERY_PREP_TIME_FLAG_INDEX
        ];
    return Number.isNaN(storePrepTime) ? 0 : storePrepTime;
};

export const getStorePrepOrderThreshold = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const storePrepTime = flagsArr[STORE_PREP_OREDER_THRESHOLD];
    return Number.isNaN(storePrepTime) ? 0 : storePrepTime;
};

export const getStoreWithGid = (gid, storesData) => {
    const isArray = Array.isArray(storesData);
    if (isArray && storesData.length > 1) {
        return storesData.find((store) => {
            return String(gid) === String(_.get(store, "general_info.g_id", ""));
        });
    } else if (isArray && Number(storesData.length) === 1) {
        return storesData[0];
    } else {
        return storesData;
    }
};

export const getStoreTimeZone = (store) => {
    const timeZone = _.get(store, "tz");
    const zones = momentTz.tz.names();
    const zonesArr = Array.isArray(zones) ? zones : [];
    if (zonesArr.includes(timeZone)) {
        return timeZone;
    }
    return moment.tz.guess();
};

export const isRestaurant = (store) => {
    const cidsFromStore = _.get(store, "general_info.cids", []);
    const cids = Array.isArray(cidsFromStore) ? cidsFromStore : [];
    return cids.includes(1); //resturant
};

//utensil related
export const REQUIRE_UTENSIL_MAP = {
    do_not_show: "0",
    show_default_not_selected: "1",
    show_default_selected: "2",
};

export const getRequireUtensilValue = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    return flagsArr[STORE_REQUIRE_UTENSIL_FLAG_INDEX];
};

export const showRequireUtensil = (store) => {
    const value = getRequireUtensilValue(store);
    const resturant = isRestaurant(store);
    const showOnValues = [REQUIRE_UTENSIL_MAP.show_default_not_selected, REQUIRE_UTENSIL_MAP.show_default_selected];
    const defaultOn = showOnValues.includes(value);
    return resturant && defaultOn;
};

export const PRINT_UTENSIL_SETTING_MAP = {
    do_not_print: "0",
    always_print: "1",
    print_when_request: "2",
    print_when_not_request: "3",
};

export const getPrintUtensilValue = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    return flagsArr[STORE_PRINT_UTENSIL_PREFERENCE_FLAG_INDEX];
};

export const getDefaultPrintUtensilValue = (store) => {
    const value = getPrintUtensilValue(store);
    return value !== PRINT_UTENSIL_SETTING_MAP.do_not_print;
};

//store: manage store
//payment: payment method interger
const shippingMap = config.SHIPPING_MAPPING_TO_NUMERIC;
export const OFFLINE_PAYMENT_BINARY_INDEX = {
    [shippingMap.eatin]: 0,
    [shippingMap.pickup]: 1,
    [shippingMap.delivery]: 2,
    [shippingMap.free_shipping]: 3,
    [shippingMap.quick_pay]: 4,
};
export const OFFLINE_PAYMENTS = [config.PAYMENT_MAPPING_TO_NUMBERIC.pay_later, config.PAYMENT_MAPPING_TO_NUMBERIC.cash];
export const allowPayment = (store, payment, order) => {
    //payment check
    const paymentStr = Number(payment) ? helper.getKeyByValue(config.PAYMENT_MAPPING_TO_NUMBERIC, payment) : payment;
    const paymentPflgCheckIndex = _.get(config.STORE_PFLG_INDEX_MAPPING, paymentStr, []);
    const storePflg = Array.isArray(_.get(store, "pflg")) ? _.get(store, "pflg") : [];
    const singleAllow = (index) => Number(_.get(storePflg, index, 0)) === 1;
    let paymentAllow = true;
    if (Array.isArray(paymentPflgCheckIndex)) {
        //find any that not allowed, no allowed -> allow
        paymentAllow = !paymentPflgCheckIndex.find((index) => !singleAllow(index));
    } else {
        paymentAllow = singleAllow(paymentPflgCheckIndex);
    }

    //off line payment with method logic
    const method = getShippingMethodNumber(order);
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const value = flagsArr[STORE_OFFLINE_PAYMENT_FLAG];
    const digitValue = Number(value) ? Number(value) : 0;
    const binaryStr = num2Binary(digitValue, Object.keys(OFFLINE_PAYMENT_BINARY_INDEX).length);
    const enableValues = strToCharArray(binaryStr);
    const methodValue = Number(method) ? String(method) : _.get(shippingMap, method);
    const checkIndex = _.get(OFFLINE_PAYMENT_BINARY_INDEX, methodValue, -1);
    const methodAllow = String(_.get(enableValues, checkIndex)) !== "0";

    //if it's off line payment, also check with method
    const paymentNumeric = Number(payment) ? Number(payment) : _.get(config.PAYMENT_METHOD_NUMERIC_MAPPING, payment);
    if (OFFLINE_PAYMENTS.includes(paymentNumeric)) {
        return paymentAllow && methodAllow;
    }
    return paymentAllow;
};

//require dine in due time
export const requireDineInDueTime = (store) => {
    const flags = _.get(store, "store_flg", "") + "";
    const flagsArr = flags.split(",");
    const flagValue = flagsArr[STORE_FLAGS.require_due_time_for_in_store_order];
    return String(flagValue) === "1" || flagValue === "true";
};

export const isLiveOrder = (order) => {
    const status = getOrderStatusNumber(order);
    return (
        status === config.ORDER_STATUS_MAPPING_TO_NUMERIC.pending ||
        status === config.ORDER_STATUS_MAPPING_TO_NUMERIC.processing
    );
};

export default {
    getStoreWithGid,
};
