import { Button } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../components/wrapper";
import { getPublish, updatePublish } from "../../services/publish";
import { pubLoad, unPubLoad } from "../../slices/publish";
import { getStoreDetails } from "../../services/store";
import { RootState } from "../../app/reducer";
import "./publish.scss";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "/publish",
            breadcrumbName: "publish_manager",
        },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const publish = useSelector((state: RootState) => state.publishManager);
    const storeInfo = useSelector((state: RootState) => state.store.storeInformation);
    const tz = _.get(storeInfo, "address_time_zone", "America/Vancouver");
    const titles = ["current_status", "last_published", "last_updated"];
    const monthList = ["jan", "feb", "mar", "apr", "may", "jun", "july", "aug", "sep", "oct", "nov", "dec"];
    const pubLoading = publish.pubLoad;
    const unPubLoading = publish.unPubLoad;

    useEffect(() => {
        dispatch(getPublish());
        dispatch(getStoreDetails());
    }, [dispatch]);

    const dateChangeFormat = (date?: any) => {
        const dateSplit = date.split(" ");
        const month = parseInt(dateSplit[0].split("-")[1]);
        const retDate =
            intl.formatMessage({ id: monthList[month - 1] }) +
            " " +
            dateSplit[0].split("-")[2] +
            ",\xa0" +
            dateSplit[1] +
            "\xa0\xa0" +
            dateSplit[2];
        return retDate;
    };

    const changeByTimeZone = (time: any) => moment.utc(time).tz(tz).format("YYYY-MM-DD HH:mm:ss z");

    const renderDate = (date: string) => (date ? dateChangeFormat(changeByTimeZone(date)) : "");

    const renderPublished = () => <div className="publishedStatusVal"> {renderStatus()} </div>;

    const renderStatus = () => {
        if (publish.published) {
            if (publish.last_update !== null && Date.parse(publish.last_updated) < Date.parse(publish.last_published)) {
                return (
                    <div>
                        <FormattedMessage id="published" />
                        <span className="inSync">
                            <FormattedMessage id="in_sync" />
                        </span>
                    </div>
                );
            } else {
                return (
                    <div>
                        <FormattedMessage id="published" />
                        <span className="outSync">
                            <FormattedMessage id="out_sync" />
                        </span>
                    </div>
                );
            }
        } else {
            return (
                <div style={{ color: "red" }}>
                    {" "}
                    <FormattedMessage id="not_published" />{" "}
                </div>
            );
        }
    };

    const childs = [
        renderPublished(),
        renderDate(_.get(publish, "last_published", "")),
        renderDate(_.get(publish, "last_updated", "")),
    ];

    const renderCol = (title: string, child: any) => (
        <div className="pubTable-col">
            <div className="pubTableTitle">
                <FormattedMessage id={title} />
            </div>
            <div className="pubTableValue">{child}</div>
        </div>
    );

    return !_.isEmpty(publish) ? (
        <Wrapper helmet={{ title: "publish_manager" }} breadcrumb={breadcrumb}>
            <div className="publish-container">
                <div className="pubTable-row-container">
                    {titles.map((title, index) => renderCol(title, childs[index]))}
                </div>
            </div>
            <div className="pub-actions-cont">
                <Button
                    danger
                    ghost
                    loading={unPubLoading}
                    disabled={pubLoading || unPubLoading}
                    style={{ margin: "10px", fontWeight: "bold" }}
                    onClick={() => {
                        dispatch(unPubLoad());
                        dispatch(updatePublish({ action: 2 }));
                    }}
                >
                    <FormattedMessage id="unpublish" />
                </Button>
                <Button
                    type="primary"
                    loading={pubLoading}
                    disabled={pubLoading || unPubLoading}
                    style={{ margin: "10px", fontWeight: "bold" }}
                    onClick={() => {
                        dispatch(pubLoad());
                        dispatch(updatePublish({ action: 1 }));
                    }}
                >
                    <FormattedMessage id="republish" />
                </Button>
            </div>
        </Wrapper>
    ) : null;
}

export default App;
