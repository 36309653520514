import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Report {
    loadingState?: ReportLoadingState;
    report?: any;
}

enum ReportLoadingState {
    IDLE,
    REPORT_IS_LOADING,
}

const initialState: Report = {
    loadingState: ReportLoadingState.IDLE,
};

const slice = createSlice({
    name: "report",
    initialState,
    reducers: {
        startLoadingReport: (state) => {
            state.loadingState = ReportLoadingState.REPORT_IS_LOADING;
        },
        fetchReportSuccess: (state, { payload }: PayloadAction<any>) => {
            state.report = payload;
            state.loadingState = ReportLoadingState.IDLE;
        },
    },
});

export const { startLoadingReport, fetchReportSuccess } = slice.actions;

export const report = slice.reducer;
