import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RootState } from "app/reducer";
import _ from "lodash";
import React from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { getDeliveryTimeslot } from "../../../../services/delivery_request";
import {
    AddressInfo,
    clearOnActiveTask,
    EditModalPages,
    MessageModalPages,
    setAddressInfoState,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setEditModal,
    setShouldMessageModalOpen,
    setShouldModalOpen,
    setUpdateForm,
    TaskModalPages,
    updateOnActiveTask,
} from "../../../../slices/delivery_request";
import { REQUEST_TIME_FORMAT } from "../../constants";
import "../../style.scss";
import { formatAddress, formatPrice, getTime } from "../helper";
import { AddressLabel } from "../helper/AddressSlectOptions";

const _S = {
    padding: "12px",
    width: "50%",
};

const _C = {
    display: "flex",
};

const TaskDetailTab: React.FC<PropsFromRedux> = ({
    onActiveTaskState,
    setCurrentModalPage,
    setShouldModalOpen,
    setEditModal,
    setUpdateForm,
    updateOnActiveTask,
    setAddressInfoState,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    getDeliveryTimeslot,
}) => {
    useIntl();

    const POINTER_STYLE = { cursor: "pointer", marginLeft: "20px" };

    const AddressEditOnClick = (address: AddressLabel | null | undefined) => {
        if (address) {
            const obj: AddressInfo = {
                id: String(address.id),
                customerId: address.customerId,
                phone: address.phone,
                firstName: address.firstname,
                lastName: address.lastname,
                email: address.email ?? "",
                address: [address.street, address.city, address.region, address.country_id].join(", "),
                street: address.street,
                addressLineTwo: address.unit ?? "",
                buzzCode: address.buzz ?? "",
                componentName: "",
                deliveryOptions: address.delivery_option ?? null,
                deliveryRemark: address.delivery_instructions ?? "",
                zipCode: address.zip_code,
                city: address.city,
                region: address.region,
                countryCode: address.country_id,
            };
            setAddressInfoState(obj);
        }
        const allowEdit = Number(onActiveTaskState?.status) >= 110 && Number(onActiveTaskState?.status) <= 250;
        setCurrentModalPage(allowEdit ? TaskModalPages.EDIT_ADDRESS : TaskModalPages.READ_ONLY_ADDRESS);
        setShouldModalOpen(true);
    };

    const orderValueModalOnClick = () => {
        setEditModal({ currPage: EditModalPages.UPDATE_ORDER_VALUE_AND_TIP_AMOUNT });
    };

    const handleHasAlcoholChange = (e: CheckboxChangeEvent) => {
        const value = e.target.checked;
        if (onActiveTaskState) {
            updateOnActiveTask({ ...onActiveTaskState, contains_alcohol: value ? 1 : 0 });
            setUpdateForm({ key: "additionalInfo.containsAlcohol", value });
        }
    };

    const renderItemInfo = (): React.ReactNode => {
        const _ROWS: { title: React.ReactNode; value: React.ReactNode; onEdit?: (e?: React.MouseEvent) => void }[] = [
            {
                title: <FormattedMessage id="requested_pickup_time" />,
                // shows the full time range
                value: onActiveTaskState?.pickup_window_end_time
                    ? `${onActiveTaskState?.pickup_window_start_time?.split?.(" ")[1] ?? ""} - ${getTime(
                          onActiveTaskState?.pickup_window_end_time,
                          REQUEST_TIME_FORMAT
                      )}`
                    : getTime(onActiveTaskState?.pickup_window_start_time, REQUEST_TIME_FORMAT),
                onEdit: async (e) => {
                    e?.stopPropagation();
                    await getDeliveryTimeslot();
                    setShouldMessageModalOpen(true);
                    setCurrentMessageModalPage(MessageModalPages.PICKUP_TIME_PICKER_EDIT);
                },
            },
            {
                title: <FormattedMessage id="requested_dropoff_time" />,
                value: onActiveTaskState?.delivery_window_end_time
                    ? `${onActiveTaskState?.delivery_window_start_time?.split?.(" ")[1] ?? ""} - ${getTime(
                          onActiveTaskState?.delivery_window_end_time,
                          REQUEST_TIME_FORMAT
                      )}`
                    : getTime(onActiveTaskState?.delivery_window_start_time, REQUEST_TIME_FORMAT),
                onEdit: async (e) => {
                    e?.stopPropagation();
                    await getDeliveryTimeslot();
                    setShouldMessageModalOpen(true);
                    setCurrentMessageModalPage(MessageModalPages.DROPOFF_TIME_PICKER_EDIT);
                },
            },
            {
                title: <FormattedMessage id="est_pickup_time" />,
                value: onActiveTaskState?.estimated_pickup_time ? (
                    getTime(onActiveTaskState?.estimated_pickup_time, REQUEST_TIME_FORMAT)
                ) : (
                    <FormattedMessage id="ASAP" />
                ),
            },
            {
                title: <FormattedMessage id="est_dropoff_time" />,
                value: onActiveTaskState?.estimated_delivery_time ? (
                    getTime(onActiveTaskState?.estimated_delivery_time, REQUEST_TIME_FORMAT)
                ) : (
                    <FormattedMessage id="ASAP" />
                ),
            },
        ];

        return (
            <>
                <div className="task-detail-header">
                    <FormattedMessage id={"time_info"} />
                </div>
                {_ROWS.map((row, i) => {
                    return (
                        <div
                            style={{ display: "flex", justifyContent: "space-between" }}
                            className="task-detail-row"
                            key={`item info detail ${i}`}
                        >
                            {row.onEdit ? (
                                <div style={{ display: "flex", minWidth: "195px", justifyContent: "space-between" }}>
                                    <div>{row.title}</div>
                                    <div>
                                        <BsPencilSquare style={POINTER_STYLE} onClick={() => row.onEdit?.()} />
                                    </div>
                                </div>
                            ) : (
                                <div>{row.title}</div>
                            )}
                            <div style={{ width: "50%" }}>{row.value}</div>
                        </div>
                    );
                })}
            </>
        );
    };

    const renderPickUp = () => {
        const _ROWS = [
            <FormattedMessage key={"from row 3"} id="order_value" />,
            <FormattedMessage key={"from row 4"} id="tips" />,
            <FormattedMessage key={"from row 5"} id="comment" />,
            <FormattedMessage key={"from row 6"} id="contains_alcohol" />,
        ];

        return (
            <>
                {_ROWS.map((r, i) => (
                    <div key={"from " + i} className="task-detail-row">
                        {r}
                    </div>
                ))}
            </>
        );
    };

    const renderDropOff = () => {
        return (
            <>
                <div
                    className="task-detail-row"
                    style={{ display: "flex", justifyContent: "space-between", width: "65%" }}
                >
                    <div>{formatPrice(onActiveTaskState?.order_value)}</div>
                    <div>
                        <BsPencilSquare style={POINTER_STYLE} onClick={orderValueModalOnClick} />
                    </div>
                </div>
                <div
                    className="task-detail-row"
                    style={{ display: "flex", justifyContent: "space-between", width: "65%" }}
                >
                    <div>{formatPrice(onActiveTaskState?.tip)}</div>
                    <div>
                        <BsPencilSquare style={POINTER_STYLE} onClick={orderValueModalOnClick} />
                    </div>
                </div>
                <div className="task-detail-row">
                    {_.isEmpty(onActiveTaskState?.comment) ? "N/A" : onActiveTaskState?.comment}
                </div>
                <div className="task-detail-row">
                    <Checkbox checked={!!onActiveTaskState?.contains_alcohol} onChange={handleHasAlcoholChange} />
                </div>
            </>
        );
    };

    const renderAddressSection = () => {
        const renderAddress = () => {
            return (
                <div style={_C}>
                    <div style={_S}>
                        <div
                            className="task-detail-header"
                            style={{ display: "flex", justifyContent: "space-between", width: "65%" }}
                        >
                            <div>
                                <FormattedMessage id="pick_up" />
                            </div>
                            <div>
                                {onActiveTaskState?.customer?.customer_type === "pickup" && (
                                    <BsPencilSquare
                                        style={POINTER_STYLE}
                                        onClick={() => {
                                            AddressEditOnClick(onActiveTaskState?.pickUpAddressLabel);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {onActiveTaskState && formatAddress(onActiveTaskState?.pickUpAddressLabel, true)}
                    </div>
                    <div style={_S}>
                        <div
                            className="task-detail-header"
                            style={{ display: "flex", justifyContent: "space-between", width: "65%" }}
                        >
                            <div>
                                <FormattedMessage id="dropoff" />
                            </div>
                            <div>
                                {onActiveTaskState?.customer?.customer_type === "dropoff" && (
                                    <BsPencilSquare
                                        style={POINTER_STYLE}
                                        onClick={() => {
                                            AddressEditOnClick(onActiveTaskState?.dropOffAddressLabel);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        {onActiveTaskState && formatAddress(onActiveTaskState?.dropOffAddressLabel, true)}
                    </div>
                </div>
            );
        };

        return (
            <>
                {renderAddress()}
                <div style={_C}>
                    <div style={_S}>{renderPickUp()}</div>
                    <div style={_S}>{renderDropOff()}</div>
                </div>
            </>
        );
    };

    return (
        <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "50%" }}>{renderAddressSection()}</div>
            <div style={_S}>{renderItemInfo()}</div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        onActiveTaskState: state.deliveryRequests.onActiveTask,
    };
};

const mapDispatchToProps = {
    clearOnActiveTask,
    setCurrentModalPage,
    setShouldModalOpen,
    TaskModalPages,
    setEditModal,
    setUpdateForm,
    updateOnActiveTask,
    setAddressInfoState,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    getDeliveryTimeslot,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(TaskDetailTab));
