import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import _actions from "../../slices/live-order";
import "./_order-page.scss";
import _ from "lodash";
import Wrapper from "../../components/wrapper";
import { getManagedStores } from "../../services/managed-stores";
import LiveOrderListHandler from "./_components/live-order-list-handler";
import { Spin } from "antd";
import ShippingOptionsModal from "components/shipping-options-modal";
import { getCourierMethods } from "services/shipment-tracking";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "/liveOrders",
            breadcrumbName: "live_order",
        },
    ],
};

class LiveOrdersPage extends Component {
    componentDidMount() {
        const { getLiveOrdersList, getManagedStores } = this.props;
        getLiveOrdersList();
        getManagedStores();
        this.props.getCourierMethods();
        this.clearData();
    }

    clearData = () => {
        this.props.setState({
            newPrices: {},
        });
    };

    render() {
        return (
            <Wrapper paddingBottom={0} helmet={{ title: "live_order" }} breadcrumb={breadcrumb}>
                <div className="order-page-container">
                    <div className="order-page-content">
                        <Spin size="large" spinning={this.props.isListLoading}>
                            <LiveOrderListHandler />
                        </Spin>
                    </div>
                </div>
                <ShippingOptionsModal
                    {...{
                        shippingOptionsModal: this.props.shippingOptionsModal,
                        setState: (values) => this.props.setState(values),
                    }}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    isListLoading: _.get(state, "liveOrders.isListLoading", false),
    shippingOptionsModal: _.get(state, "liveOrders.shippingOptionsModal", false),
});

const mapDispatchToProps = {
    getLiveOrdersList: _actions.getLiveOrdersList,
    setState: _actions.setState,
    getManagedStores,
    getCourierMethods,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(LiveOrdersPage)));
