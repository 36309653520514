import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import helper, { formatName, isBool } from "../../../util/helper";
import _ from "lodash";
import CustomerActionsRenderer from "../_components/table-components/customer-actions-renderer";
import CustomerCurrencyRenderer from "../_components/table-components/customer-currency-renderer";
import CustomerRefcodeRenderer from "../_components/table-components/customer-refcode-renderer";
import CustomerValueRenderer from "../_components/table-components/customer-value-renderer";
import { useHistory } from "react-router-dom";

interface useCustomerProps {
    openOrdersPage: (value: any) => any;
    openCustomerDetailPage: (value: any) => any;
    openCustomerGiftCards: (value: any) => any;
    openCouponMessage: (value: any) => any;
    sendMessage: (value: any) => any;
    showCustomer: (value: any) => any;
    setSelected: (value: any) => any;
    getRef?: () => any;
}

const useCustomerTable = (props: useCustomerProps): JSX.Element => {
    const {
        openOrdersPage,
        openCustomerDetailPage,
        openCustomerGiftCards,
        openCouponMessage,
        sendMessage,
        showCustomer,
        setSelected,
        getRef
    } = props;
    const history = useHistory();
    const customers = useSelector((state: RootState) => state?.customers?.customers);
    const tableRef = useRef<any>(null);
    const p2V = useSelector((state: RootState) => state.store?.storeBasic?.points_to_value);
    const showReferrals = useSelector((state: RootState) => state.store?.storeReferrals?.allowCustRefs);
    const intl = useIntl();
    const dispatch = useDispatch();
    const [table, setTable] = useState(true);
    const lan = useSelector((state: RootState) => state.setting.lan);

    let gridApi: any;

    /**
     * When the table size is changed, resize the columns to fill the table
     * If the table size is changed such that the minimum width of each column
     * exceeds the table size, add a horizontal scroll bar to the table.
     *
     * @param params AgGrid params
     */
    const onGridSizeChanged = (params: any) => {
        const gridWidth = document.getElementById("customers-table-container-id")?.offsetWidth;
        if (!gridWidth) {
            return;
        }
        const columnsToShow = [];
        const columnsToHide = [];
        let totalColsWidth = 0;
        const allColumns = params.columnApi.getAllColumns();
        for (let i = 0; i < allColumns.length; i++) {
            const column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }

        if (_.isEmpty(columnsToHide)) {
            params.api.sizeColumnsToFit();
        }
    };

    const COLUMNS_WIDTH = {
        FULL_NAME_MIN_WIDTH: 120,
        PHONE_MIN_WIDTH: 100,
        EMAIL_MIN_WIDTH: 120,
        ORDER_AMOUNT_MIN_WIDTH: 80,
        ORDER_COUNT_MIN_WIDTH: 60,
        POINTS_BALANCE_MIN_WIDTH: 80,
        LAST_ORDER_DATE_MIN_WIDTH: 100,
        REFERRAL_CODE_MIN_WIDTH: 80,
        ACTIONS_MIN_WIDTH: 75,
        ACTIONS_MAX_WIDTH: 75,
    };

    const getTableObject: any = () => ({
        columnDefs: [
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.FULL_NAME_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "full_name" }),
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return formatName(record);
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.PHONE_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "phone" }),
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return helper.formatPhone(record?.phone);
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.EMAIL_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "email" }),
                cellRenderer: "valueRenderer",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.email;
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.ORDER_AMOUNT_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "total_order_amount" }),
                headerClass: "right-align",
                cellRenderer: "currencyRenderer",
                cellClass: "right-align",
                type: "numericColumn",
                cellRendererParams: { name: "ord_amt" },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.ORDER_COUNT_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "number_of_orders" }),
                headerClass: "right-align",
                cellRenderer: "valueRenderer",
                cellClass: "right-align",
                type: "numericColumn",
                cellRendererParams: {
                    renderValue(record: any) {
                        return record?.ord_cnt;
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.POINTS_BALANCE_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "points_balance" }),
                headerClass: "right-align",
                cellRenderer: "valueRenderer",
                cellClass: "right-align",
                type: "numericColumn",
                cellRendererParams: {
                    renderValue(record: any) {
                        return helper.formatNumber(record?.points_balance);
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.LAST_ORDER_DATE_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "last_order_date" }),
                headerClass: "center-align",
                cellRenderer: "valueRenderer",
                cellClass: "center-align",
                cellRendererParams: {
                    renderValue(record: any) {
                        return helper.utcToLocal(record?.last_ord_dt);
                    },
                },
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.REFERRAL_CODE_MIN_WIDTH,
                headerName: intl.formatMessage({ id: "referral_code" }),
                headerClass: "center-align",
                cellRenderer: "refCodeRenderer",
                cellClass: "center-align",
            },
            {
                field: "id",
                minWidth: COLUMNS_WIDTH.ACTIONS_MIN_WIDTH,
                maxWidth: COLUMNS_WIDTH.ACTIONS_MAX_WIDTH,
                headerName: intl.formatMessage({ id: "actions" }),
                headerClass: "center-align",
                cellRenderer: "actionsRenderer",
                cellClass: "center-align",
                cellRendererParams: {
                    openOrdersPage,
                    openCustomerDetailPage,
                    openCustomerGiftCards,
                    openCouponMessage,
                    sendMessage,
                },
            },
        ],
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: CustomerValueRenderer,
            currencyRenderer: CustomerCurrencyRenderer,
            actionsRenderer: CustomerActionsRenderer,
            refCodeRenderer: CustomerRefcodeRenderer,
        },
        rowData: customers.filter((customer: any) => showCustomer(customer)),
        getRowHeight: () => 50,
        stopEditingWhenCellsLoseFocus: true,
        singleClickEdit: true,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        getRowNodeId: (row: any) => row?.id,
        immutableData: true,
        onSelectionChanged: (event: any) => {
            dispatch(setSelected(event?.api?.getSelectedNodes().map((node: any) => node?.data)));
        },
        onCellClicked: (event: any) => {
            const cellRenderer = _.get(event, "column.colDef.cellRenderer", "");
            if (cellRenderer !== "refCodeRenderer") {
                const id = _.get(event, "data.id");
                history.push(`/customers/customer/${id}`);
            }
        },
        onGridReady: (params: any) => {
            gridApi = params?.api;
            gridApi?.sizeColumnsToFit?.();
        },
        onFirstDataRendered: (params: any) => {
            params.api.sizeColumnsToFit();
        },
        onGridSizeChanged,
    });

    const renderTable = () => {
        const tableObject = getTableObject();
        if (!isBool(showReferrals)) {
            tableObject.columnDefs = tableObject?.columnDefs?.filter(
                (el: any) => !el.headerName || el.headerName !== intl.formatMessage({ id: "referral_code" })
            );
        }
        if (!p2V) {
            tableObject.columnDefs = tableObject?.columnDefs?.filter(
                (el: any) => !el.headerName || el.headerName !== intl.formatMessage({ id: "points_balance" })
            );
        }
        return (
            <div id="customers-table-container-id" className="ag-theme-alpine" ref={tableRef}>
                {/* @ts-ignore */}
                {table ? <AgGridReact {...tableObject} ref={getRef()}  /> : null}
            </div>
        );
    };

    const showTable = () => {
        setTable(true);
    };

    useEffect(() => {
        setTable(false);
        setTimeout(showTable, 100);
    }, [lan]);

    return renderTable();
};

export default useCustomerTable;
