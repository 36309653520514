import React, { useEffect } from "react";
import { isBool } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput } from "./payment-functions";
import { getPreviouslySetCredentialCode, getPreviouslySetMerchantCode } from "../helper";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { unionpayexpress_enabled, unionpayexpress_merch_id, unionpayexpress_credential } = paymentOptions;

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, []);

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, [unionpayexpress_enabled]);

    const copyDataOfCertainBlankFields = () => {
        if (!unionpayexpress_enabled) {
            return;
        }

        if (!unionpayexpress_merch_id) {
            setState("unionpayexpress_merch_id", getPreviouslySetMerchantCode(paymentOptions));
        }
        if (!unionpayexpress_credential) {
            setState("unionpayexpress_credential", getPreviouslySetCredentialCode(paymentOptions));
        }
    };

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "unionpay_credit",
                "unionpay_debit_desc",
                "unionpayexpress_enabled",
                unionpayexpress_enabled,
                switchChange
            )}
            {unionpayexpress_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput(
                        "merchant_id",
                        "unionpayexpress_merch_id",
                        unionpayexpress_merch_id,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput(
                        "credential",
                        "unionpayexpress_credential",
                        unionpayexpress_credential,
                        setState,
                        false,
                        true
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default App;
