import React, { Component } from "react";
import _ from "lodash";
import { Modal, Button } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CommentInput from "./comment-input";
import _actions from "../../../../slices/live-order";

class CommentDialog extends Component {
    shouldShow = () => {
        return _.get(this.props, "state.showCommentDialog", false);
    };

    closeDialog = () => {
        this.props.setState({
            showCommentDialog: false,
            orderComment: "",
        });
    };

    onConfirmClick = () => {
        this.props.updateOrderComment({
            "order_id": this.getOrderId(),
            "comment": this.getComment(),
        });
        this.closeDialog();
    };

    onCloseClick = () => {
        this.closeDialog();
    };

    getFormLabelProps = () => {
        return {
            wrapperCol: { span: 24 },
            labelAlign: "left",
        };
    };

    getOrderId = () => {
        return _.get(this.props, "order.ord_id", "");
    };

    getComment = () => {
        return _.get(this.props, "state.orderComment", "");
    };

    getFooter = () => {
        const buttons = [
            {
                label: "cancel",
                onClick: () => this.onCloseClick(),
                disabled: () => {
                    return false;
                },
                type: "default",
            },
            {
                label: "submit",
                onClick: () => this.onConfirmClick(),
                disabled: () => {
                    const enable = this.getComment();
                    return !enable;
                },
                type: "primary",
            },
        ];
        return buttons.map((btn, i) => {
            return (
                <Button
                    type={btn.type}
                    disabled={btn.disabled()}
                    key={`surcharge-dialog-button-${i}`}
                    onClick={() => btn.onClick()}
                >
                    <FormattedMessage id={btn.label} />
                </Button>
            );
        });
    };

    getSharedProps = () => {
        return {
            form: this.getFormLabelProps(),
            ...this.props,
        };
    };

    render() {
        return (
            <div>
                <Modal
                    visible={this.shouldShow()}
                    title={<FormattedMessage id="add_comment" />}
                    onCancel={() => this.onCloseClick()}
                    footer={this.getFooter()}
                >
                    <CommentInput {...this.getSharedProps()} comment={this.getComment()} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    updateOrderComment: (payload) => _actions.updateOrderComment(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CommentDialog));
