import { InputAdornment, TextField, withStyles } from "@material-ui/core";
import { Button, Checkbox, DatePicker, Select, Radio} from "antd";
import _ from "lodash";
import moment from "moment";
import { FilterFunc } from "rc-select/lib/Select";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paths } from "types/";
import { RootState } from "../../../../app/reducer";
import { setInputRefs, TaskModalPages } from "../../../../slices/delivery_request";
import { AnyStyle, DATE_FORMAT } from "../../constants";
import "./style.scss";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3898c8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3898c8",
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#3898c8",
            },
        },
        "& fieldset": {
            "& legend": {
                width: "unset",
            },
        },
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
})(TextField);

export interface FormInputProps<TState> {
    defaultValue?: any;
    name: string;
    placeholder?: string;
    className?: string;
    state: TState; // Type of State
    inputDataPath: Paths<TState>; // paths for input's state
    inputType: INPUT_TYPE;
    selectOptions?: SelectOption[];
    checkBoxOptions?: string[];
    radioBoxOptions?: string[];
    handleOnChange?: (params: { key: Paths<TState>; value: any }) => void;
    handleOnClick?: (e?: React.MouseEvent) => void;
    style?: AnyStyle;
    rows?: number;
    maxLength?: number;
    maxRows?: number;
    allowClear?: boolean;
    inputStatus?: INPUT_STATUS | undefined;
    addonAfter?: React.ReactNode;
    addonBefore?: React.ReactNode;
    disabled?: boolean;
    onSearch?: (value: string) => void;
    showSearch?: boolean;
    label?: string | React.ReactNode;
    filterOption?: boolean | ((input: string, options: SelectOption[]) => boolean);
    required?: boolean;
}

export interface SelectOption {
    name: string;
    value: string | number | Record<string, string>;
    label: string | number | React.ReactNode;
}

export enum INPUT_STATUS {
    ERR = "error",
    WARN = "warning",
}

export enum INPUT_TYPE {
    TEXT,
    SELECT,
    DATE_RANGE_PICKER,
    CHECK_BOXES,
    BUTTON,
    AMOUNT,
    TEXT_AREA,
    INPUT_NUMBER,
    CHECK_BOX,
    RADIO
}

const FormInput = <TState,>({
    state,
    inputType,
    inputDataPath,
    placeholder,
    selectOptions,
    name,
    className,
    style,
    handleOnClick,
    handleOnChange,
    defaultValue,
    checkBoxOptions,
    radioBoxOptions,
    rows,
    maxLength,
    maxRows,
    allowClear,
    inputStatus,
    addonAfter,
    addonBefore,
    disabled,
    onSearch,
    showSearch,
    label,
    filterOption,
    required,
}: FormInputProps<TState>): JSX.Element => {
    const currModalPage = useSelector((state: RootState) => state.deliveryRequests.modal.newTaskModal.currentPage);
    const isMainPage = currModalPage === TaskModalPages.NEW_TASK_MAIN;
    const isAddressPage = currModalPage === TaskModalPages.NEW_TASK_ADDRESS;

    const valueInState = _.get(state, `${inputDataPath}`, null);
    const value = defaultValue ?? (_.isEmpty(String(valueInState)) ? null : valueInState);
    const dispatch = useDispatch();
    const ref: any = useRef();

    const isDisabled = disabled || currModalPage === TaskModalPages.READ_ONLY_ADDRESS;

    /**
     * only save ref on new delivery task dialog main page
     */
    useEffect(() => {
        if (!_.isEmpty(ref.current) && (isMainPage || isAddressPage)) {
            dispatch(setInputRefs({ key: name, ref: _.clone(ref) }));
        }
    }, []);

    const renderInput = () => {
        switch (inputType) {
            case INPUT_TYPE.DATE_RANGE_PICKER:
                return (
                    <DatePicker.RangePicker
                        ref={ref}
                        className={className}
                        format={DATE_FORMAT}
                        value={value}
                        disabledDate={(current) => moment() <= current}
                        onChange={(date) => {
                            handleOnChange?.({ key: inputDataPath, value: [date?.[0], date?.[1]] });
                        }}
                    />
                );

            case INPUT_TYPE.TEXT:
                return (
                    <CssTextField
                        InputLabelProps={{ shrink: !_.isEmpty(value) || undefined }}
                        required={required}
                        variant="outlined"
                        label={placeholder}
                        size={"small"}
                        className={className}
                        name={name}
                        style={{ width: "100%", ...style }}
                        inputRef={ref}
                        value={value ?? ""}
                        onChange={(e) => {
                            handleOnChange?.({ key: inputDataPath, value: e.target.value });
                        }}
                        onClick={(e: any) => {
                            handleOnClick?.(e);
                        }}
                        inputProps={{
                            maxLength,
                        }}
                        InputProps={{
                            startAdornment: addonBefore && (
                                <InputAdornment position="start">{addonBefore}</InputAdornment>
                            ),
                            endAdornment: addonAfter && <InputAdornment position="end">{addonAfter}</InputAdornment>,
                        }}
                        error={inputStatus === INPUT_STATUS.ERR}
                        disabled={isDisabled}
                    />
                );

            case INPUT_TYPE.SELECT:
                return (
                    <Select
                        disabled={isDisabled}
                        allowClear={allowClear}
                        size="large"
                        ref={ref}
                        showSearch={showSearch}
                        style={{ ...{ height: "auto", wordWrap: "break-word" }, ...style }}
                        className={"testing"}
                        onChange={(value) => {
                            handleOnChange?.({ key: inputDataPath, value });
                        }}
                        placeholder={placeholder}
                        value={value}
                        onSearch={onSearch}
                        status={inputStatus}
                        filterOption={filterOption as FilterFunc<SelectOption[]> | boolean}
                    >
                        {selectOptions?.map((option) => {
                            return (
                                <Select.Option className={"word-break"} key={option.name} value={option.value}>
                                    <>{option.label}</>
                                </Select.Option>
                            );
                        })}
                    </Select>
                );

            case INPUT_TYPE.CHECK_BOXES:
                return (
                    <Checkbox.Group
                        onChange={(value) => {
                            handleOnChange?.({ key: inputDataPath, value });
                        }}
                        value={value}
                    >
                        {checkBoxOptions?.map((option) => {
                            return (
                                <Checkbox key={option} value={option}>
                                    {option}
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                );

            case INPUT_TYPE.BUTTON:
                return (
                    <Button style={style} onClick={(e) => handleOnClick?.(e)}>
                        {label ?? name}
                    </Button>
                );

            case INPUT_TYPE.INPUT_NUMBER:
                return (
                    <CssTextField
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: !_.isEmpty(value) || undefined }}
                        required={required}
                        variant="outlined"
                        label={placeholder}
                        type="number"
                        size={"small"}
                        className={className}
                        name={name}
                        style={{ width: "100%", ...style }}
                        inputRef={ref}
                        value={value ?? ""}
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/^0+/g, "");
                            handleOnChange?.({ key: inputDataPath, value: e.target.value });
                        }}
                        onClick={(e: any) => {
                            handleOnClick?.(e);
                        }}
                        inputProps={{
                            maxLength,
                        }}
                        InputProps={{
                            startAdornment: addonBefore && (
                                <InputAdornment position="start">{addonBefore}</InputAdornment>
                            ),
                            endAdornment: addonAfter && <InputAdornment position="end">{addonAfter}</InputAdornment>,
                        }}
                        error={inputStatus === INPUT_STATUS.ERR}
                    />
                );

            case INPUT_TYPE.TEXT_AREA:
                return (
                    <CssTextField
                        disabled={isDisabled}
                        InputLabelProps={{ shrink: !_.isEmpty(value) || undefined }}
                        multiline={true}
                        rows={rows}
                        rowsMax={maxRows}
                        required={required}
                        variant="outlined"
                        label={placeholder}
                        size={"small"}
                        className={className}
                        name={name}
                        style={{ width: "100%", ...style }}
                        inputRef={ref}
                        value={value ?? ""}
                        onChange={(e) => {
                            handleOnChange?.({ key: inputDataPath, value: e.target.value });
                        }}
                        onClick={(e: any) => {
                            handleOnClick?.(e);
                        }}
                        inputProps={{
                            maxLength,
                        }}
                        InputProps={{
                            startAdornment: addonBefore && (
                                <InputAdornment position="start">{addonBefore}</InputAdornment>
                            ),
                            endAdornment: addonAfter && <InputAdornment position="end">{addonAfter}</InputAdornment>,
                        }}
                        error={inputStatus === INPUT_STATUS.ERR}
                    />
                );
            case INPUT_TYPE.RADIO:
                return (
                    <Radio.Group onChange={(e) =>{
                        handleOnChange?.({ key: inputDataPath, value: e.target.value });
                    }} value={value}>
                        {radioBoxOptions?.map((option) => { 
                            return (
                                <Radio key={option} value={option}>
                                    {option}
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                );

            default:
                return <></>;
        }
    };

    return renderInput();
};

export default FormInput;
