import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { Input } from "antd";
import { FILTER_KEYS } from "../../helper-order-list";
import actions from "../../../../slices/order-history";

class SearchBar extends Component {
    state = { value: undefined, onEdit: false };

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getSearchCurrentValue = () =>
        _.get(this.props, `state.searchConditions.search_condition.${FILTER_KEYS.name_number}`, undefined);

    getValue = () => {
        const isEditing = this.state.onEdit;
        return isEditing ? this.state.value : this.getSearchCurrentValue();
    };

    onChange = (e) => {
        const value = e.target.value;
        this.setState({ value, onEdit: true });
    };

    onSearch = (value) => {
        const clearSearch = !value && !this.state.value && this.getSearchCurrentValue();
        const valid = value || clearSearch;
        if (!valid) return;
        this.props.fetchOrderList({
            [FILTER_KEYS.name_number]: this.state.value,
            callBack: () => this.setState({ value: undefined, onEdit: false }),
        });
    };

    render() {
        return (
            <div className="order-list-search-bar">
                <Input.Search
                    value={this.getValue()}
                    onSearch={(value, event) => this.onSearch(value, event)}
                    className="order-list-search-bar-input"
                    size="middle"
                    onChange={this.onChange}
                    allowClear={true}
                    placeholder={this.str("search_order")}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    isListLoading: _.get(state, "orders-page.isListLoading", {}),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchBar));
