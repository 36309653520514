import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../../../app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../../../services/products";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import config from "../../../../config";
import RichTextEditor from "../../../../components/rich-text-editor";
import _ from "lodash";
import { copyDataToEmptyLanguageFieldsSingleField } from "util/helper/forms";

const selector = (state: RootState) => ({
    product: state?.products?.product,
    displayLanguages: state?.store?.storeDisplayOptions?.product_display_languages ?? [],
});

interface ProdDescProps {
    onClose: any;
}

function ProductDescription(props: ProdDescProps): JSX.Element {
    const dispatch = useDispatch();
    const { product, displayLanguages } = useSelector(selector);
    const [value, setValue] = useState<any>({});
    const { lan, getModalLanguageSelect } = useLanguageSelect();

    const onSubmit = () => {
        const clonedValues = _.cloneDeep(value);
        copyDataToEmptyLanguageFieldsSingleField({
            values: clonedValues,
            displayLanguages: displayLanguages,
        });

        dispatch(
            editProduct({
                product_id: product?.product_id,
                description: clonedValues,
            })
        );

        props.onClose();
    };

    useEffect(() => {
        if (product?.description) {
            setValue(product?.description);
        } else {
            setValue({});
        }
    }, [product]);

    return (
        <div style={{ position: "relative" }}>
            <div className="modal-lan-select">{getModalLanguageSelect()}</div>
            {config.LANGUAGES.map((l) => (
                <div key={`description_${l}`} style={{ display: lan === l ? "block" : "none", height: "100%" }}>
                    <RichTextEditor
                        lan={lan}
                        value={value[l] ?? ""}
                        onChange={(content: any) => setValue((prev: any) => ({ ...prev, [l]: content }))}
                    />
                </div>
            ))}
            <div className="footer-item-changes">
                <Button type="primary" onClick={onSubmit}>
                    <FormattedMessage id="apply" />
                </Button>
            </div>
        </div>
    );
}

export default ProductDescription;
