import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { reducer, RootState } from "./reducer";
//persist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
    key: "goopterAdminTool",
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ["user", "store"],
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducer);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
