import React, { useState } from "react";
import { Button, Popover, Modal } from "antd";
import _ from "lodash";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FaRulerHorizontal } from "react-icons/fa";
import { RootState } from "../../../../../../app/reducer";
import h from "../../helper";
import RuleModal from "./rule-modal";
import { switchNum } from "../../../../../../util/helper";
import { renderEnableTop, renderCountriesSelection } from "../shipping-functions";
import { setTableProps, fillTableProps, clearTableProps, initialTableProps } from "../../../../../../slices/shipping";
import { setShippingState } from "../../../../../../slices/store";
import { Prompt } from "react-router-dom";

function emptyFunction() {
    // does nothing
}

function App(): null | JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const initialRules = useSelector((state: RootState) => state.store?.records?.shipping_setting.table?.rules);
    const tableProps = useSelector((state: RootState) => state.shipping?.tableProps);
    const ruleModalOpen = useSelector((state: RootState) => state.shipping?.tableProps?.ruleModalOpen);
    const enable = useSelector((state: RootState) => state.store?.storeShipping?.tableEnable);
    const rules = useSelector((state: RootState) => state.store?.storeShipping?.tableRules);
    const copiedRules = useSelector((state: RootState) => state.store?.storeShippingCopy?.tableRules);
    const [editTable, setEditTable] = useState<boolean>(false);
    const allowCountry = useSelector((state: RootState) => state.store?.storeShipping?.tableAllowCountry);
    const shipCountryType = useSelector((state: RootState) => state.store?.storeShipping?.tableShipCountryType);
    const cols = ["shipping_fee", "country", "region", "postal", "price", "action"];

    const setState = (name: string, value: any) => {
        dispatch(setShippingState({ name, value }));
    };
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value) }));
    const setTableState = (name: string, value: any) => dispatch(setTableProps({ name, value }));

    const addRules = () => {
        const newRules = _.cloneDeep(rules) ?? [];
        const newRuleMinusModal = _.cloneDeep(tableProps);
        delete newRuleMinusModal.ruleModalOpen;
        if (newRuleMinusModal.tempId) {
            newRules.splice(newRuleMinusModal.tempId, 1, newRuleMinusModal);
        } else {
            // create a temporary rule id
            newRuleMinusModal.tempId = newRules.length;
            const index = _.findIndex(rules, ["table_rate_id", newRuleMinusModal?.table_rate_id]);
            index === -1 ? newRules.push(newRuleMinusModal) : newRules.splice(index, 1, newRuleMinusModal);
        }
        setState("tableRules", newRules ?? []);
        updateTableRulesCopy(newRuleMinusModal);
        setTableState("ruleModalOpen", false);
        setEditTable(false);
        dispatch(clearTableProps());
    };

    const deleteRule = (rule: any) => {
        const newRules = _.cloneDeep(rules) ?? [];
        let index = _.findIndex(rules, ["table_rate_id", rule?.table_rate_id]);
        index === -1 ? (index = _.findIndex(rules, ["tempId", rule?.tempId])) : index;
        newRules[index].delete = h.DELETE;
        setState("tableRules", newRules);
        updateTableRulesCopy(newRules[index]);
    };

    const updateTableRulesCopy = (newRule: any) => {
        const newRules = _.cloneDeep(copiedRules) || [];
        const index = _.findIndex(copiedRules, ["table_rate_id", newRule?.table_rate_id]);
        // if the newRule.tempId matches any of the tempIds in the newRules, then replace it with the newRule
        if (newRules.length > 0 && newRule.tempId && newRules.find((rule: any) => rule.tempId === newRule.tempId)) {
            const indexOfRuleEdited = newRules.indexOf(newRules.find((rule: any) => rule.tempId === newRule.tempId));
            // replace the old rule with the new one
            newRules.splice(indexOfRuleEdited, 1, newRule);
        } else {
            index === -1 ? newRules.push(newRule) : newRules.splice(index, 1, newRule);
        }
        newRules.forEach((rule: any) => {
            if (rule.delete && rule.tempId) {
                const originalIndex = newRules.indexOf((obj: any) => obj.tempId === rule.tempId);
                // remove the rule from the copied rules
                newRules.splice(newRules[originalIndex], 1);
                newRules.splice(index, 1);
            }
        });
        setState("tableRulesNewRule", newRules);
    };

    const editRule = (rule: any) => {
        setEditTable(true);
        dispatch(fillTableProps({ ...rule, ruleModalOpen: true }));
    };

    const actions = (rule: any) => (
        <React.Fragment>
            <p className="popover-item" onClick={() => editRule(rule)}>
                <FormattedMessage id="edit_rule" />
            </p>
            <p className="popover-item" onClick={() => deleteRule(rule)}>
                <FormattedMessage id="delete_rule" />
            </p>
        </React.Fragment>
    );

    const renderPrompt = () => (
        <Prompt
            when={!_.isEqual(initialRules, rules)}
            message={intl.formatMessage({ id: "alert_leaving_without_save" })}
        />
    );

    const renderTableActions = (rule: any) => (
        <Popover content={actions(rule)} placement="left">
            <HiDotsHorizontal size="19" color="#3898c8" className="customers-actions-button" />
        </Popover>
    );

    const renderRows = () => (
        <React.Fragment>
            {Array.isArray(rules) && rules?.filter((el) => !el?.delete).length > 0
                ? rules
                      ?.filter((el) => !el?.delete)
                      .map((rule: any, index: number) => (
                          <div key={`table-rule-row-key-${index}`} className="table-rule-row pHover d-flex">
                              <div className="d-flex w100" onClick={emptyFunction}>
                                  <div onClick={() => editRule(rule)} style={{ flex: "1 1 16.5%" }}>
                                      $ {rule?.handling_fee}
                                  </div>
                                  <div onClick={() => editRule(rule)} style={{ flex: "1 1 16.5%" }}>
                                      {rule?.country}
                                  </div>
                                  <div onClick={() => editRule(rule)} style={{ flex: "1 1 16.5%" }}>
                                      {rule?.region}
                                  </div>
                                  <div onClick={() => editRule(rule)} style={{ flex: "1 1 16.5%" }}>
                                      {rule?.postal}
                                  </div>
                                  <div onClick={() => editRule(rule)} style={{ flex: "1 1 16.5%" }}>
                                      $ {rule?.price}
                                  </div>
                                  <div style={{ flex: "1 1 16.5%" }}>{renderTableActions(rule)}</div>
                              </div>
                          </div>
                      ))
                : renderEmptyWarn}
        </React.Fragment>
    );

    const renderHeader = (cols: any) => (
        <div className="w100 table-header d-flex">
            {cols.map((col: string, index: number) => (
                <div
                    key={`table-header-col-key-${index}`}
                    style={{
                        flex: `1 1 16.5%`,
                    }}
                >
                    <FormattedMessage id={col ?? " "} />
                </div>
            ))}
        </div>
    );

    const renderCountriesSection = (
        <div className="d-flex w100 align-items-center justify-content-between country-selector">
            {renderCountriesSelection(
                "tableShipCountryType",
                shipCountryType,
                "tableAllowCountry",
                allowCountry,
                setState,
                intl
            )}
            <Button
                onClick={() => {
                    dispatch(fillTableProps({ ...initialTableProps, ruleModalOpen: true }));
                }}
                type="primary"
            >
                <FormattedMessage id="add_rule" />
            </Button>
        </div>
    );

    const renderEmptyWarn = (
        <div className="inactive-empty-warn w100">
            <FaRulerHorizontal size="60" color="lightgrey" className="mb-2" />
            <FormattedMessage id={"no_rules"} />
        </div>
    );

    const renderRules = (
        <div className="w100">
            {renderHeader(cols)}
            {renderRows()}
        </div>
    );

    const renderRuleModal = (
        <Modal
            visible={ruleModalOpen}
            title={intl.formatMessage({ id: editTable ? "edit_table_rate_rule" : "add_table_rate_rule" })}
            width="800px"
            onOk={() => addRules()}
            okText={intl.formatMessage({ id: editTable ? "update_rule" : "add_rule" })}
            onCancel={() => setTableState("ruleModalOpen", false)}
        >
            <RuleModal />
        </Modal>
    );

    return _.isEmpty(tableProps) ? null : (
        <div className="white-layered-block table-rate-tab">
            {renderEnableTop("tableEnable", enable, switchChange)}
            {renderPrompt()}
            {renderCountriesSection}
            {renderRuleModal}
            {renderRules}
        </div>
    );
}

export default App;
