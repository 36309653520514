import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import "./order-page.scss";
import _ from "lodash";
import OrderList from "./order-list";
import OrderDetail from "./order-detail";
import SplitPane from "react-split-pane";
import TitleFilters from "./page-components/order-list-title-filters";
import TableController from "./page-components/order-list-controllers";
import AppliedFilters from "./page-components/order-list-applied-filters";
import InaccurateAlert from "./page-components/order-list-inaccurate-alert";
import { Spin } from "antd";
import { getDefaultFilters } from "./helper-order-list";
import Collapse from "@material-ui/core/Collapse";
import actions from "../../slices/order-history";
import { withRouter } from "react-router-dom";
import qs from "querystring";

const getDefaultHeight = () => {
    const navBarHeight = 140;
    const windowHeight = window && window.innerHeight ? window.innerHeight : 900;
    return windowHeight - navBarHeight;
};

class OrdersPage extends Component {
    state = {
        height: getDefaultHeight(),
    };

    componentDidMount() {
        this.props.fetchOrderList(this.getFilters());
        this.setListeners();
    }

    getFilters = () => {
        const search = _.get(this.props, "location.search", "");
        const searchObject = qs.parse(search.slice(1)) ?? {};
        return {
            ...getDefaultFilters(),
            ...searchObject,
        };
    };

    componentWillUnmount = () => {
        this.removeListeners();
    };

    componentDidUpdate(prevsProps) {
        this.checkHeightChange(prevsProps);
    }

    isListLoading = () => {
        return this.props.isListLoading || this.props.isLiveOrderLoading;
    };

    shouldShowSplit = () => _.get(this.props, "state.showOrderDetail", false);

    showAlert = (props = this.props) => _.get(props, "state.showListAlert", false);

    showContollerRow = (props = this.props) => _.get(props, "state.showBulkUpdate", false);

    bulkUpdate = (props = this.props) => _.get(props, "state.bulkUpdating", false);

    setListeners = () => {
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    checkHeightChange = (prevsProps) => {
        const alertChange = this.showAlert(prevsProps) !== this.showAlert();
        const onShowChange = this.showContollerRow(prevsProps) !== this.showContollerRow();
        const orderListChange = !_.isEqual(this.props.orderList, prevsProps.orderList);
        const orderChange = !_.isEqual(this.props.order, prevsProps.order);
        const onBulkingChange = this.bulkUpdate(prevsProps) !== this.bulkUpdate();
        const onUseExportSelection = this.props.useExportSelection;
        if (alertChange || onShowChange || orderListChange || orderChange || onBulkingChange || onUseExportSelection) {
            setTimeout(() => {
                this.setHeight();
            }, 100);
        }
    };

    setHeight = () => {
        const minusRowIds = ["order-list-title-filters"];
        if (this.showContollerRow()) minusRowIds.push("order-list-title-controller", "order-list-applied-filters");
        if (this.showAlert()) minusRowIds.push("order-list-inaccurate-alert");
        var result = getDefaultHeight();
        minusRowIds.forEach((id) => {
            const row = document.getElementById(id);
            if (row && row.offsetHeight) {
                result -= row.offsetHeight;
            }
        });
        this.props.setState({ contentHeight: result > 0 ? result : getDefaultHeight() });
    };

    renderOtherComponents = () => {
        const show = _.get(this.props, "state.showBulkUpdate", true);
        return (
            <div className="header-section-order-history">
                <TitleFilters />
                <InaccurateAlert />
                <Collapse in={show}>
                    <TableController />
                    <AppliedFilters />
                </Collapse>
            </div>
        );
    };

    renderNormalOrderList = () => {
        return <OrderList listStyle="table" />;
    };

    renderSplitOrderList = () => {
        return (
            <div style={{ height: this.props.height }}>
                <SplitPane minSize={400} maxSize={400} style={{ height: "initial" }} split="vertical">
                    <OrderList listStyle="list" />
                    <OrderDetail />
                </SplitPane>
            </div>
        );
    };

    renderActualContent = () => {
        return (
            <div className="order-page-actual-content">
                {this.shouldShowSplit() ? this.renderSplitOrderList() : this.renderNormalOrderList()}
            </div>
        );
    };

    render() {
        return (
            <Spin spinning={this.isListLoading()}>
                <div className="order-page-container">
                    <div className="order-page-content">
                        {this.renderOtherComponents()}
                        {this.renderActualContent()}
                    </div>
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, "orders-page"),
    isListLoading: _.get(state, "orders-page.isListLoading", false),
    isLiveOrderLoading: _.get(state, "liveOrders.isListLoading", false),
    order: _.get(state, "orders-page.orderDetailInstance", []),
    orderList: _.get(state, "orders-page.orderList", []),
    height: _.get(state, "orders-page.contentHeight", ""),
    useExportSelection: _.get(state, "orders-page.useExportSelection", false),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
    setState: actions.setState,
};

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersPage)));
