import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import "./order-list-title-filters.scss";
import StatusFilters from "./status-filters";
import TypeFilters from "./type-filters";
import SearchBar from "./search-bar";
import { MdRefresh } from "react-icons/md";
import cx from "classnames";
import actions from "../../../../slices/order-history";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

class OrderListTitleFilters extends Component {
    isListLoading = () => {
        return this.props.isListLoading || this.props.isLiveOrderLoading;
    };

    renderTitle = () => {
        return (
            <div className="order-list-title">
                <FormattedMessage id="manage_order" />
                <MdRefresh
                    onClick={() => {
                        this.props.fetchOrderList();
                    }}
                    className={cx({
                        "order-list-title-refresh": true,
                        "spin": this.isListLoading(),
                    })}
                />
            </div>
        );
    };

    renderStatusFilters = () => {
        return <StatusFilters />;
    };

    renderTypeFitlers = () => {
        return <TypeFilters />;
    };

    renderSearchBar = () => {
        return <SearchBar />;
    };

    renderShrinkIcons = () => {
        const show = _.get(this.props, "state.showBulkUpdate", true);
        const onIconClick = () => this.props.setState({ showBulkUpdate: !show });
        return show ? <DownOutlined onClick={onIconClick} /> : <RightOutlined onClick={onIconClick} />;
    };

    render() {
        return (
            <div id="order-list-title-filters" className="order-list-title-filters-wrapper">
                <div className="order-list-title-filters-section-start">
                    {this.renderTitle()}
                    {this.renderStatusFilters()}
                    {this.renderTypeFitlers()}
                </div>
                <div className="order-list-title-filters-section-end">
                    {this.renderSearchBar()}
                    {this.renderShrinkIcons()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    isListLoading: _.get(state, "orders-page.isListLoading", false),
    isLiveOrderLoading: _.get(state, "liveOrders.isListLoading", false),
    state: _.get(state, "orders-page", false),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTitleFilters));
