import { FormattedMessage, useIntl } from "react-intl";
import { InputNumber } from "antd";
import { MAX_DUE_TIME_MINUTES } from "./constants";

interface DueTimeProps {
    currHours: number;
    currMins: number;
    setState: any;
}

export const DueTime = (props: DueTimeProps): JSX.Element => {
    const intl = useIntl();

    const setDueTimeValue = (hours: number | undefined, mins: number | undefined) => {
        let dueTimeVal = 0;
        if (isNaN(Number(hours)) && !isNaN(Number(mins))) {
            dueTimeVal = props.currHours * 60 + (Number(mins) < 60 ? Number(mins) : 0);
        } else if (!isNaN(Number(hours)) && isNaN(Number(mins))) {
            dueTimeVal = (Number(hours) > MAX_DUE_TIME_MINUTES / 60 ? 0 : Number(hours)) * 60 + props.currMins;
        } else if (!isNaN(Number(hours)) && !isNaN(Number(mins))) {
            dueTimeVal =
                (Number(hours) > MAX_DUE_TIME_MINUTES / 60 ? 0 : Number(hours)) * 60 +
                (Number(mins) < 60 ? Number(mins) : 0);
        } else {
            dueTimeVal = 0;
        }
        props.setState("due_time_value", dueTimeVal > MAX_DUE_TIME_MINUTES ? MAX_DUE_TIME_MINUTES : dueTimeVal);
    };

    return (
        <div className="white-single-block">
            <div className="d-flex w100 space-between align-items-center">
                <div className="d-flex w100 flex-column justify-content-start align-items-start">
                    <div className="option-title" style={{ marginBottom: "10px" }}>
                        <FormattedMessage id="auto_accept_due_time_value" />
                    </div>
                    <div className="d-flex due-time-value">
                        <span className="mr-2 due-time-within" style={{ fontWeight: 400 }}>
                            {intl.formatMessage({ id: "auto_accept_due_time_within" })}
                        </span>
                        <div className="d-flex align-items-center flex-row">
                            <InputNumber
                                min={0}
                                max={MAX_DUE_TIME_MINUTES / 60}
                                type="number"
                                className="custom-input-textfield surAmount-field ml-1"
                                value={props.currHours}
                                onChange={(val) => setDueTimeValue(val, undefined)}
                            />
                            <span className="ml-0 mr-2" style={{ alignSelf: "center", fontWeight: 400 }}>
                                {intl.formatMessage({ id: "auto_accept_due_time_hours" })}
                            </span>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <InputNumber
                                min={0}
                                max={59}
                                type="number"
                                className="custom-input-textfield surAmount-field ml-1"
                                value={props.currMins}
                                onChange={(val) => setDueTimeValue(undefined, val)}
                            />
                            <span className="ml-0" style={{ alignSelf: "center", fontWeight: 400 }}>
                                {intl.formatMessage({ id: "auto_accept_due_time_minutes" })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
