import React, { useCallback, useEffect, useState } from "react";
import { Button, Select, Switch } from "antd";
import _ from "lodash";
import config from "../../../../config/config.json";
import { SelectOption } from "../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { getStoreDetailsParts, updateStoreDetailsParts } from "../../../../services/store";
import { setSurchargeState } from "../../../../slices/store";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { InputNumber } from "antd";
import ObjectModel from "./../../../../util/models";
import StoreBasic from "../../../../util/models/store-surcharge";
import { copyDataToEmptyLanguageFieldsSingleField } from "util/helper/forms";
import { OrderType } from "./order-type";
import { isBool, num2Binary, strToCharArray } from "util/helper";
import { ORDER_TYPES } from "./constants";

const breadcrumb = {
    routes: [
        {
            path: "",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "/settings",
            breadcrumbName: "settings_overview",
        },
        {
            path: "/settings/surcharge",
            breadcrumbName: "surcharge_settings",
        },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const surcharge = useSelector((state: RootState) => state.store?.storeSurcharge);
    const country = useSelector((state: RootState) => state.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const displayLanguages = useSelector((state: RootState) => state.store?.storeDisplayOptions?.product_display_languages) ?? []
    const [orderType, setOrderType] = useState<any>({});
    const getKeyVal = <T extends any, U extends keyof T>(obj: T) => (key: U) => obj[key];
    const currency = Object.prototype.hasOwnProperty.call(config.CURRENCY_SYMBOL, country) ? getKeyVal(config.CURRENCY_SYMBOL)(country) : "$";
    const { lan, getModalLanguageSelect } = useLanguageSelect();
    const isDollar = Number(_.get(surcharge, "surcharge_type", 1)) === 1;

    const taxClass: SelectOption[] = [
        { value: 1, label: intl.formatMessage({ id: "no_tax_goods" }) },
        { value: 2, label: intl.formatMessage({ id: "taxable_goods" }) },
        { value: 3, label: intl.formatMessage({ id: "gst_only" }) },
        { value: 4, label: intl.formatMessage({ id: "alcohol" }) },
        { value: 5, label: intl.formatMessage({ id: "food" }) },
        { value: 6, label: intl.formatMessage({ id: "shipping" }) },
    ];

    const type: SelectOption[] = [
        { value: 1, label: intl.formatMessage({ id: "dollar" }) },
        { value: 2, label: intl.formatMessage({ id: "percentage" }) },
    ];

    const setOrderTypes = useCallback((num: any) => {
        const newOrderTypes: any = {};
        const binType = num2Binary(Number(num), Object.keys(ORDER_TYPES).length);
        const binTypeArray = strToCharArray(binType);
        Object.keys(ORDER_TYPES).forEach((type: any, i) => {
            // get binary string of type
            newOrderTypes[type] = isBool(binTypeArray[i]);
        });
        setOrderType(newOrderTypes);
    }, []);

    useEffect(() => {
        dispatch(getStoreDetailsParts({ page: "surcharge_setting" }));
    }, [dispatch]);

    useEffect(() => {
        setOrderTypes(surcharge.surcharge_order_type);
    }, [setOrderTypes, surcharge]);

    const setState = (name: string, value: any) => dispatch(setSurchargeState({ name, value }));

    const saveChanges = () => {
        const surchargeClone = _.cloneDeep(surcharge)
        copyDataToEmptyLanguageFieldsSingleField({ values: surchargeClone.surcharge_label, displayLanguages })
        Object.keys(surchargeClone.surcharge_label).forEach(lan => {
            surchargeClone[`surcharge_label_${lan}`] = surchargeClone.surcharge_label[lan]
        })
        
        dispatch(updateStoreDetailsParts(new ObjectModel(StoreBasic).convertToPostData(surchargeClone)));
    };

    const renderNameAndEnable = () => {
        const renderEnable = () => {
            const checked = Number(surcharge.is_enabled) === 1;
            return (
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="option-title d-flex" style={{ marginBottom: "10px" }}>
                        <FormattedMessage id="enabled" />
                    </div>
                    <Switch
                        style={{ margin: "15px 0px" }}
                        checked={checked}
                        onChange={() => setState("is_enabled", checked ? 0 : 1)}
                    />
                </div>
            );
        };

        const renderName = () => (
            <div style={{ width: 320 }} className="d-flex flex-column justify-content-start align-items-start">
                <div className="option-title d-flex" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_label_name" />
                    <div className="setting-actions">{getModalLanguageSelect()}</div>
                </div>
                <input
                    type="text"
                    className="custom-input-textfield surName-field ta-left"
                    value={_.get(surcharge, `surcharge_label.${lan}`, "")}
                    onChange={(e) => { 
                        setState(`surcharge_label.${lan}`, e.target.value)
                    }}
                />
            </div>
        );
        return (
            <div className="white-single-block">
                <div className="surcharge-name-enable-wrapper">
                    {renderName()}
                    {renderEnable()}
                </div>
            </div>
        );
    };

    const renderType = () => (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_type" />
                </div>
                <Select
                    className="surTax-field"
                    value={surcharge.surcharge_type}
                    onChange={(e) => setState("surcharge_type", e)}
                >
                    {type.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
                
            </div>
        </div>
    );

    const renderOrderType = () =>(
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <OrderType orderType={orderType} setState={setState}/>
            </div>
        </div>
    )

    const renderAmount = () => {
        const renderValue = () => (
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_amount" />
                </div>
                <div className="d-flex align-items-center">
                    {isDollar ? currency : null}
                    <InputNumber
                        min={0}
                        type="number"
                        className="custom-input-textfield surAmount-field ml-1"
                        value={_.get(surcharge, "surcharge", 0)}
                        onChange={(e) => setState("surcharge", e)}
                    />
                    {isDollar ? null : "%"}
                </div>
            </div>
        );

        const renderMin = () => (
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_min" />
                </div>
                <div className="d-flex align-items-center">
                    {currency}
                    <InputNumber
                        min={0}
                        type="number"
                        className="custom-input-textfield surAmount-field ml-1"
                        value={_.get(surcharge, "surcharge_min", 0)}
                        onChange={(e) => setState("surcharge_min", e)}
                    />
                </div>
            </div>
        );

        const renderMax = () => (
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_max" />
                </div>
                <div className="d-flex align-items-center">
                    {currency}
                    <InputNumber
                        min={0}
                        type="number"
                        className="custom-input-textfield surAmount-field ml-1"
                        value={_.get(surcharge, "surcharge_max", 0)}
                        onChange={(e) => setState("surcharge_max", e)}
                    />
                </div>
            </div>
        );

        return (
            <div className="white-single-block">
                <div className="d-flex w100 space-between align-items-center">
                    {renderValue()}
                    {isDollar ? null : (
                        <>
                            {renderMin()}
                            {renderMax()}
                        </>
                    )}
                </div>
            </div>
        );
    };

    const renderTaxClass = () => (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="surcharge_tax_class" />
                </div>
                <Select
                    className="surTax-field"
                    value={surcharge.surcharge_tax_class}
                    onChange={(e) => setState("surcharge_tax_class", e)}
                >
                    {taxClass.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    );

    const renderSave = () => (
        <div className="setting-actions" style={{ marginRight: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return !_.isEmpty(surcharge) ? (
        <Wrapper helmet={{ title: "surcharge_settings" }} breadcrumb={breadcrumb}>
            {renderNameAndEnable()}
            {renderOrderType()}
            {renderType()}
            {renderAmount()}
            {renderTaxClass()}
            {renderSave()}
        </Wrapper>
    ) : null;
}

export default App;
