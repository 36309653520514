import React from "react";
import { IconType } from "react-icons/lib";

interface Props {
    icon?: any;
    color?: string;
}

const Icon = (props: Props): JSX.Element => {
    return <props.icon style={{ color: props.color }} />;
};

export const createIcon = (icon?: IconType, color?: string): JSX.Element => (
    <Icon icon={icon} color={color ?? "#3898c8"} />
);

export default Icon;
