import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import "./tips-section.scss";
import { FaCheck } from "react-icons/fa";
import { updateTips } from "../../../../../slices/create-order";
import { formatCurrency } from "util/helper";

class TipsSelectButton extends React.Component {
    static defaultProps = {
        value: 0,
        tipsType: "percent",
        width: 18,
    };

    getTotalValue = () => {
        return this.props.totalValue;
    };

    getShippingFee = () => {
        return this.props.shippingFee;
    };

    getTips = () => {
        return Number(this.props.tips);
    };

    getButtonType = () => {
        return this.props.tipsType;
    };

    getButtonValue = () => {
        return Number(this.props.value);
    };

    getButtonWidth = () => {
        return this.props.width;
    };

    getDisplayValue = () => {
        const value = this.props.value;
        return this.getButtonType() === "percent" ? `${value}%` : formatCurrency(value, this.props.storeCurrency);
    };

    getButtonValueCalculatedTips = () => {
        const totalValue = this.getTotalValue() + this.getShippingFee();
        const tipsPercentage = parseFloat(this.getButtonValue()) * 0.01;
        return _.round(totalValue * tipsPercentage, 2);
    };

    isActive = () => {
        let isActive = this.getTips() === this.getButtonValueCalculatedTips();
        if (this.getButtonType() === "flat") {
            isActive = this.getTips() === this.getButtonValue();
        }
        return isActive;
    };

    updateTips = () => {
        this.props.updateTips({
            tipsType: this.getButtonType(),
            amount: this.getButtonValue(),
        });
    };

    render() {
        return (
            <div
                style={{ width: `${this.getButtonWidth()}%` }}
                className={`checkout-tips-button ${this.isActive() ? "active" : ""}`}
                onClick={() => this.updateTips()}
            >
                <div className={`checkout-tips-button-text`}>{this.getDisplayValue()}</div>
                {this.isActive() ? (
                    <div className="checkout-tips-button-check-icon">
                        <FaCheck />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    totalValue: _.get(state, "createOrder.payment.grandTotal", 0),
    shippingFee: _.get(state, "createOrder.payment.shippingfee", 0),
    tips: _.get(state, "createOrder.payment.tips", 0),
    lan: _.get(state, "setting.lan", "en"),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    //from slice
    updateTips,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TipsSelectButton));
