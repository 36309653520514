import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    allowed: false,
    loaded: false,
    isPageRefreshed: true,
};

const slice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        setPagesState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
    },
});

export const { setPagesState } = slice.actions;

export const pages = slice.reducer;
