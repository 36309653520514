import React, { Component } from "react";
import { Row, Col, Pagination, Empty, Button } from "antd";
import _ from "lodash";
import "./order-list-one-page.scss";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import LiveOrderCard from "../liveOrderCard";
import _actions from "../../../../slices/live-order";
import { LIVE_ORDER_CARD_ID_PREFIX, groupList } from "../../helper";
import SearchBar from "../search-bar";
import TitleSection from "../title-section";

class LiveOrderOnePageStyle extends Component {
    state = {};

    componentDidMount() {
        this.checkActiveIdUpdate({});
        this.checkListUpdate({});
        this.checkStyleUpdate({});
        this.setListeners();
    }

    componentDidUpdate(prevsProps) {
        this.checkActiveIdUpdate(prevsProps);
        this.checkListUpdate(prevsProps);
        this.checkStyleUpdate(prevsProps);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    getPageSize = () => 4;

    getStyle = (props = this.props) => _.get(props, "liveOrdersState.listStyle", "list");

    getActiveId = (props = this.props) => {
        return _.get(props, "liveOrdersState.activeId", "");
    };

    getLiveOrdersList = (props = this.props) => {
        const list = Array.isArray(_.get(props, "liveOrdersState.liveOrderList", []))
            ? _.get(props, "liveOrdersState.liveOrderList", [])
            : [];
        const filteredStatus = _.get(props, "liveOrdersState.listFilteredStatus", "all");
        const sortedList = groupList(list, _.get(props, "liveOrdersState"));
        var allListArray = Object.values(sortedList);
        const flattenArray = Array.prototype.concat.apply([], allListArray);
        if (filteredStatus === "all") {
            return flattenArray;
        }
        if (Array.isArray(sortedList[filteredStatus])) {
            return sortedList[filteredStatus];
        }
        return [];
    };

    getCurrentPage = () => {
        const activeId = this.getActiveId();
        const liveOrderList = this.getLiveOrdersList();
        const index = _.findIndex(liveOrderList, (order) => String(_.get(order, "ord_id")) === String(activeId)) + 1;
        let result = 1;
        if (index !== 0) {
            result = Math.ceil(index / this.getPageSize());
        }
        return result;
    };

    getCurrentList = () => {
        const liveOrderList = this.getLiveOrdersList();
        let startIndex = (this.getCurrentPage() - 1) * this.getPageSize();
        let cloneList = _.cloneDeep(liveOrderList);
        return cloneList.splice(startIndex, this.getPageSize());
    };

    checkListUpdate = (prevsProps) => {
        const update =
            !_.isEqual(this.getLiveOrdersList(prevsProps), this.getLiveOrdersList()) &&
            !_.isEmpty(this.getLiveOrdersList());
        if (update) this.scrollToAciveId();
    };

    checkActiveIdUpdate = (prevsProps) => {
        const update = this.getActiveId(prevsProps) !== this.getActiveId() && this.getActiveId();
        if (update) this.scrollToAciveId();
    };

    checkStyleUpdate = (prevsProps) => {
        const update = this.getStyle(prevsProps) !== this.getStyle() && this.getStyle();
        if (update) this.scrollToAciveId();
    };

    scrollToAciveId = () => {
        const orderId = this.getActiveId();
        const ref = document.getElementById(`${LIVE_ORDER_CARD_ID_PREFIX}${orderId}`);
        if (ref) {
            ref.scrollIntoView({ block: "nearest" });
        }
    };

    setListeners = () => {
        this.setHeight();
        window.addEventListener("resize", _.throttle(this.setHeight, 500));
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    setHeight = () => {
        const paginationRow = document.getElementById("live-order-pagination-row");
        const titleRow = document.getElementById("live-order-list-drawer-title-section");
        const searchBar = document.getElementById("live-order-list-drawer-search-bar");
        const navBarHeight = 100;
        const buffer = 50;
        const windowHeight = window.innerHeight;
        var result = "67vw";
        if (windowHeight) {
            result = windowHeight - navBarHeight - buffer;
            if (paginationRow && paginationRow.offsetHeight) {
                result -= paginationRow.offsetHeight;
            } else {
                result -= 35;
            }
            if (titleRow && titleRow.offsetHeight) {
                result -= titleRow.offsetHeight;
            }
            if (searchBar && searchBar.offsetHeight) {
                result -= searchBar.offsetHeight;
            }
        }
        this.setState({ height: result > 0 ? result : "67vw" });
        this.scrollToAciveId();
    };

    renderList = () => {
        const { lan, updateLiveOrder } = this.props;
        return (
            <Row className="live-order-list-row" style={{ height: this.state.height }} gutter={4} type="flex">
                {this.getCurrentList().map((item) => {
                    return (
                        <Col
                            id={`${LIVE_ORDER_CARD_ID_PREFIX}${item.ord_id}`}
                            key={item.ord_id}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                        >
                            <LiveOrderCard lan={lan} order={item} updateOrder={updateLiveOrder} />
                        </Col>
                    );
                })}
            </Row>
        );
    };

    renderPagination = () => {
        const liveOrderList = this.getLiveOrdersList();
        return (
            <div id="live-order-pagination-row" className="pagination-row">
                <Pagination
                    current={this.getCurrentPage()}
                    pageSize={this.getPageSize()}
                    onChange={(page) => {
                        const index = (page - 1) * this.getPageSize();
                        const activeId = _.get(liveOrderList, `${index}.ord_id`);
                        this.props.setState({ activeId: activeId });
                    }}
                    total={liveOrderList.length}
                />
            </div>
        );
    };

    renderEmpty = () => {
        const { getLiveOrdersList } = this.props;
        return (
            <Empty>
                <Button onClick={() => getLiveOrdersList()} type="primary">
                    <FormattedMessage id="reload" />
                </Button>
            </Empty>
        );
    };

    render() {
        const liveOrderList = this.getLiveOrdersList();
        return (
            <div className="live-order-one-page-style-wrapper">
                <TitleSection />
                <SearchBar />
                {liveOrderList.length ? (
                    <div>
                        {this.renderList()}
                        {this.renderPagination()}
                    </div>
                ) : (
                    this.renderEmpty()
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    liveOrdersState: _.get(state, "liveOrders", {}),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    updateLiveOrder: _actions.updateLiveOrder,
    getLiveOrdersList: _actions.getLiveOrdersList,
    setState: _actions.setState,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveOrderOnePageStyle));
