import { Button, Checkbox, Input, Select, Modal, Spin } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import useLanguageSelect from "../../hooks/useLanguageSelect";
import { usePrevious } from "../../hooks/usePrevious";
import { getCategories } from "../../services/categories";
import { getProducts } from "../../services/products";
import { Product } from "../../slices/products";
import _ from "lodash";
import helper from "../../util/helper";
import config from "config";
import "./styles.scss";

interface AssignItemsProps {
    name?: string;
    title: any;
    category_id?: number;
    onSave: any;
    onCancel: any;
    selectedProducts?: Product[];
    desc?: boolean;
    allowMultipleSelection?: boolean;
}

const AssignItemsModal = (props: AssignItemsProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("");
    const [hiddenProductsOption, setHiddenProductsOption] = useState(config.IS_HIDDEN_FILTER_OPTIONS.default);
    const [productStatusOption, setProductStatusOption] = useState(config.PRODUCT_STATUS_FILTER_OPTIONS.enabled);
    const [selected, setSelected] = useState<Product[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const { lan, getModalLanguageSelect } = useLanguageSelect();
    const {
        products,
        selectedProducts: sp,
        loading,
        total,
        categories,
    } = useSelector((state: RootState) => ({
        products: state?.products?.products,
        selectedProducts: state?.categories?.products,
        lan: state?.setting?.lan,
        loading: state?.products?.loading,
        total: state?.products?.totalProducts,
        categories: state?.categories?.categories,
    }));

    const selectedProducts = props?.selectedProducts ?? sp;

    const prevSelected = usePrevious(selectedProducts);

    const page = useRef(1);

    const ENABLED_OPTIONS = [
        {
            label: <FormattedMessage id="all" />,
            value: config.PRODUCT_STATUS_FILTER_OPTIONS.default,
        },
        {
            label: <FormattedMessage id="enabled_products" />,
            value: config.PRODUCT_STATUS_FILTER_OPTIONS.enabled,
        },
        {
            label: <FormattedMessage id="disabled_products" />,
            value: config.PRODUCT_STATUS_FILTER_OPTIONS.disabled,
        },
    ];

    const VISIBILITY_OPTIONS = [
        {
            label: <FormattedMessage id="all" />,
            value: config.IS_HIDDEN_FILTER_OPTIONS.default,
        },
        {
            label: <FormattedMessage id="visible_products" />,
            value: config.IS_HIDDEN_FILTER_OPTIONS.not_hidden,
        },
        {
            label: <FormattedMessage id="hidden_products" />,
            value: config.IS_HIDDEN_FILTER_OPTIONS.hidden,
        },
    ];

    useEffect(() => {
        if (!_.isEqual(prevSelected, selectedProducts)) {
            setSelected(selectedProducts);
        }
    }, [prevSelected, selectedProducts]);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    useEffect(() => {
        page.current = 1;
        callGetProducts();
        document.getElementById("assign-list")?.scrollTo(0, 0);
    }, [dispatch, selectedCategory, productStatusOption, searchInput, hiddenProductsOption]);

    const getCategoriesAsOptions = () => {
        // Only allow child categories
        return [
            {
                value: null,
                label: intl.formatMessage({ id: "all" }),
            },
            {
                value: config.CATEGORY_ID_SEARCH_OPTIONS.uncategorized_products,
                label: intl.formatMessage({ id: "uncategorized_items" }),
            },
            ...(categories
                .filter((c) => c.level > config.CATEGORY_LEVELS.root)
                .map((c) => ({
                    value: c.category_id,
                    label: helper.getTransString(c.name, lan),
                })) ?? []),
        ];
    };

    const isChecked = (id: number) => Boolean(selected.find((product) => product?.product_id === id));

    const onRowClick = (id: number) => () => {
        const checked = isChecked(id);
        if (!checked) {
            const product: Product | undefined = products?.find?.((product) => product?.product_id === id);
            if (product) {
                if (props?.allowMultipleSelection) {
                    setSelected((prev) => [...prev, product]);
                } else {
                    setSelected([product]);
                }
            }
        } else {
            setSelected((prev) => prev.filter((product) => product?.product_id !== id));
        }
    };

    const handleLoadMore = () => {
        if (!loading) {
            page.current++;
            callGetProducts(true);
        }
    };

    const callGetProducts = (isInfinite = false) => {
        dispatch(
            getProducts(
                {
                    paging: { page: page.current },
                    category_id: selectedCategory,
                    product_status: productStatusOption,
                    product_name: searchInput,
                    is_hidden: hiddenProductsOption,
                },
                false,
                isInfinite
            )
        );
    };

    const renderLanguageSelect = () => {
        return <div className="modal-lan-select-assign">{getModalLanguageSelect()}</div>;
    };

    const renderDescription = () => {
        if (props?.desc) {
            if (props?.allowMultipleSelection) {
                return (
                    <div className="assign-description">
                        <FormattedMessage
                            id="assign_items_desc"
                            values={{ count: selected.length, category: props?.name }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="assign-description">
                        <FormattedMessage id="choose_product_desc" values={{ category: props?.name }} />
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    const renderSearch = () => {
        return (
            <div className="assign-search">
                <Input
                    size="large"
                    placeholder={intl.formatMessage({ id: "type_to_search" })}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <Select
                    placeholder={intl.formatMessage({ id: "category" })}
                    onChange={(value) => setSelectedCategory(value)}
                    value={selectedCategory}
                >
                    {getCategoriesAsOptions().map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        );
    };

    const renderLoadMore = () => {
        const shouldShowLoadMore = products?.length < total;
        return shouldShowLoadMore ? (
            <div className="assign-loadmore">
                <Button className="assign-loadmore-button" onClick={handleLoadMore}>
                    <FormattedMessage id="load_more" />
                </Button>
            </div>
        ) : null;
    };

    const renderHideDisabledProduct = () => {
        return (
            <div className="select-container">
                <span className="select-label">
                    <FormattedMessage id="enabled_status" />
                </span>
                <div className="spacing-8" />
                <Select
                    className="select-filter"
                    options={ENABLED_OPTIONS}
                    onChange={(value: number) => setProductStatusOption(value)}
                    value={productStatusOption}
                />
            </div>
        );
    };

    const renderShowHiddenProducts = () => {
        return (
            <div className="select-container">
                <span className="select-label">
                    <FormattedMessage id="product_visibility" />
                </span>
                <div className="spacing-8" />
                <Select
                    className="select-filter"
                    options={VISIBILITY_OPTIONS}
                    onChange={(value: number) => setHiddenProductsOption(value)}
                    value={hiddenProductsOption}
                />
            </div>
        );
    };

    const renderProductsList = () => {
        return (
            <Spin spinning={loading}>
                <div id="assign-list" className="assign-list">
                    {products.map((product) => (
                        <div
                            className="assign-product-item"
                            key={product?.product_id}
                            onClick={onRowClick(product?.product_id)}
                        >
                            <div className="assign-product-checkmark">
                                <Checkbox checked={isChecked(product?.product_id)} />
                            </div>
                            <div className="assign-product-name">{product?.name[lan ?? ""]}</div>
                        </div>
                    ))}
                    {renderLoadMore()}
                </div>
            </Spin>
        );
    };

    const renderFooter = () => {
        return (
            <div className="assign-items-actions">
                <Button size="large" onClick={props?.onCancel}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button size="large" type="primary" onClick={() => props?.onSave(selected)}>
                    <FormattedMessage id="confirm" />
                </Button>
            </div>
        );
    };

    return (
        <Modal
            visible={true}
            title={props?.title}
            className={"assign-items"}
            footer={renderFooter()}
            maskClosable={false}
            onCancel={props?.onCancel}
        >
            <div className="assign-items-body">
                {renderLanguageSelect()}
                {renderDescription()}
                {renderSearch()}
                <div className="assign-checkbox">
                    {renderHideDisabledProduct()}
                    {renderShowHiddenProducts()}
                </div>
                {renderProductsList()}
            </div>
        </Modal>
    );
};

AssignItemsModal.defaultProps = {
    desc: true,
    allowMultipleSelection: true,
};

export default AssignItemsModal;
