export enum ReferralTransactionActions {
    View = 1,
    AddPoints = 2,
    RedeemPoints = 3,
}

export const transactionType = Object.freeze([
    "reward_action_admin",
    "reward_action_order",
    "reward_action_register",
    "reward_action_newsletter",
    "reward_action_invitation_customer",
    "reward_action_invitation_order",
    "reward_action_review",
    "reward_action_tag",
    "reward_action_order_extra",
    "reward_action_creditmemo",
    "reward_action_salesrule",
    "reward_action_revert",
    "reward_action_creditmemo_void",
]);
