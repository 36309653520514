import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import "./order-list-controller.scss";
import BulkUpdateButton from "./bulk-update-button";
import FilterButton from "./filter-section";
import BulkUpdateController from "./bulk-update-controller";
import ExportButton from "./export-order-section";
import { MdAdd } from "react-icons/md";
import { withRouter } from "react-router-dom";
import { setCustomersState } from "slices/customers";
import actions from "slices/order-history";
import { Button, Checkbox } from "antd";

class OrderListController extends Component {
    state = { allOrdersSelected: false };

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.selectedExportOrders, this.props.selectedExportOrders)) {
            this.setState({
                allOrdersSelected: this.props.orderList.length === this.props.selectedExportOrders.length,
            });
        }
    }

    showBulkContoller = () => {
        return _.get(this.props, "state.bulkUpdating", false);
    };

    renderAddButton = () => {
        return (
            <MdAdd
                onClick={() => {
                    this.props.setState({
                        shippingOptionsModal: true,
                    });
                }}
                className="order-list-title-refresh"
            />
        );
    };

    showNormalButtons = () => {
        return (
            <div className="order-list-title-controller">
                <BulkUpdateButton />
                <FilterButton />
                {this.renderAddButton()}
                <ExportButton />
            </div>
        );
    };

    renderExportButtons = () => {
        return (
            <div className="order-list-bulk-update-controller">
                {this.renderExportAll()}
                <div>
                    {this.renderCancelExportButton()}
                    <span className="h-spacing-12" />
                    {this.renderDoneExportButton()}
                </div>
            </div>
        );
    };

    renderExportAll = () => {
        return (
            <div className="order-list-bulk-update-controller-start-section">
                <div className="order-list-bulk-update-check-box">
                    <Checkbox
                        onChange={(e) => {
                            e.target.checked
                                ? this.props.setState({ selectedExportOrders: this.props.orderList })
                                : this.props.setState({ selectedExportOrders: [] });
                        }}
                        checked={this.state.allOrdersSelected}
                    >
                        <span className="order-list-bulk-update-text">
                            <FormattedMessage id="all" />
                        </span>
                        <span className="order-list-bulk-update-text">({this.props.orderList.length})</span>
                    </Checkbox>
                </div>
            </div>
        );
    };

    renderCancelExportButton = () => {
        return (
            <Button
                type="default"
                className="order-list-bulk-done-update-button order-list-bulk-update-button"
                onClick={() => this.props.setState({ useExportSelection: false, selectedExportOrders: [] })}
            >
                <FormattedMessage id="cancel" />
            </Button>
        );
    };

    renderDoneExportButton = () => {
        return (
            <Button
                type="primary"
                className="order-list-bulk-done-update-button order-list-bulk-update-button"
                onClick={() => {
                    this.props.setState({
                        showExportDialog: true,
                        useExportSelection: false,
                        doneExportSelection: true,
                    });
                }}
                disabled={_.isEmpty(this.props.selectedExportOrders)}
            >
                <FormattedMessage id="continue" />
            </Button>
        );
    };

    renderController = () => {
        let controller;
        if (this.showBulkContoller()) {
            controller = <BulkUpdateController />;
        } else if (this.props.useExportSelection) {
            controller = this.renderExportButtons();
        } else {
            controller = this.showNormalButtons();
        }
        return controller;
    };

    render() {
        return <div id="order-list-title-controller">{this.renderController()}</div>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    orderList: _.get(state, "orders-page.orderList", []),
    useExportSelection: _.get(state, "orders-page.useExportSelection", false),
    selectedExportOrders: _.get(state, "orders-page.selectedExportOrders", []),
});

const mapDispatchToProps = {
    setCustomersState,
    setState: actions.setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListController)));
