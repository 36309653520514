import React from "react";
import { DEFAULT_CC_GATEWAY_VALUE_MAP } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "../../../../../components/form";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderHalfTxtSelec } from "./payment-functions";
import { FormattedMessage } from "react-intl";
import { isBool } from "../../../../../util/helper";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { default_cc_gateway } = paymentOptions;

    const getCardGateWaysOptions = () => {
        const options: SelectOption[] = [];
        Object.keys(DEFAULT_CC_GATEWAY_VALUE_MAP).forEach((key) => {
            const enableKeyName = `${key}_enabled`;
            if (isBool(paymentOptions[enableKeyName])) {
                options.push({
                    value: DEFAULT_CC_GATEWAY_VALUE_MAP[key],
                    label: <FormattedMessage id={key} />,
                });
            }
        });
        options.push({ value: DEFAULT_CC_GATEWAY_VALUE_MAP.offline, label: <FormattedMessage id="offline_gateway" /> });
        return options;
    };

    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            <div className="payment-input-container">
                {renderHalfTxtSelec(
                    "default_cc_gateway",
                    "default_cc_gateway",
                    default_cc_gateway,
                    getCardGateWaysOptions(),
                    setState,
                    true
                )}
            </div>
        </div>
    );
}

export default App;
