import { Form, Row, Col, Button } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import CustomerInfoForm from "./customer-info-form";
import AddressInfoForm from "./address-info-form";
import { createCustomers } from "../../../../services/customers";
import { withRouter } from "react-router-dom";
import { validateAddress, DEFAULT_ADDRESS, validateCustomer } from "../../helper";

class NewCustomerForm extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            default_address: DEFAULT_ADDRESS,
            customerInfo: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                subscribe_email: true,
                language: props.lan,
                country_code: "CA",
                customer_note: "",
                tags: [],
                delivery_options: "",
                delivery_instructions: "",
            },
        };
    }

    str = (id: any, values: any) => this.props.intl.formatMessage({ id }, values);

    getDefautlValues = () => {
        return {
            language: this.props.lan,
            country_code: "CA",
            subscribe_email: true,
            tags: [],
        };
    };

    layout = () => ({
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    });

    getColLayout = () =>
        this.props.colLayout || {
            xs: 24,
            sm: 24,
            md: 12,
        };
    /* eslint-disable no-template-curly-in-string */

    validateMessages = () => ({
        required: this.str("please_input_valid_x", { x: "${label}" }),
        types: {
            email: this.str("please_input_valid_x", { x: "${label}" }),
            number: this.str("please_input_valid_x", { x: "${label}" }),
        },
    });

    onFinish = (values: any) => {
        const valid = this.validateDefaultAddress();
        const address = _.get(this.state, "default_address.street") ? this.state.default_address : {};
        if (valid) {
            this.props.createCustomers({
                values: {
                    ...values,
                    ...address,
                    ...(this.state.customerInfo || {}),
                },
                redirect: (id: any) => {
                    this.props.history.push(`/customers/customer/${id}`);
                },
            });
        }
    };

    onFinishFailed = () => {
        this.validateDefaultAddress();
    };

    validateDefaultAddress = () => {
        const defaultAddress = this.state.default_address || {};
        const { errorFields, valid } = validateAddress(defaultAddress);
        this.setState(errorFields);
        return valid;
    };

    validateCustomerInfo = () => {
        const customerInfo = this.state.customerInfo || {};
        const { errorFields, valid } = validateCustomer(customerInfo);
        this.setState(errorFields);
        return valid;
    };

    render() {
        return (
            <div style={this.props.style} className="new-customer-page">
                <Form
                    id="new-customer-form"
                    {...this.layout()}
                    name="nest-messages"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    validateMessages={this.validateMessages()}
                    initialValues={this.state.customerInfo}
                >
                    <Row gutter={24}>
                        <Col {...this.getColLayout()}>
                            <CustomerInfoForm
                                // @ts-ignore
                                withoutTitle={this.props.withoutInfoTitle}
                                formState={this.state}
                                setFormState={(values: any) => this.setState(values, () => this.validateCustomerInfo())}
                            />
                        </Col>
                        <Col {...this.getColLayout()}>
                            <AddressInfoForm
                                // @ts-ignore
                                formState={this.state}
                                setFormState={(values: any) =>
                                    this.setState(values, () => this.validateDefaultAddress())
                                }
                                setFormCustomerState={(values: any) =>
                                    this.setState(values, () => this.validateCustomerInfo())
                                }
                            />
                        </Col>
                    </Row>
                    {_.isBoolean(this.props.submitTrigger) ? null : (
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <div className="new-customer-submit-button">
                                <Button type="primary" htmlType="submit">
                                    <FormattedMessage id="submit" />
                                </Button>
                            </div>
                        </Form.Item>
                    )}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
});

const mapDispatchToProps = {
    createCustomers,
};

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewCustomerForm)));
