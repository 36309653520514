import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal, Select, Button } from "antd";
import _actions from "../../../../slices/live-order";
import TypeCheckbox from "./export-order-fitler-type";
import { EXPORT_DEFAULT_VALUES, FILTER_KEYS } from "../../../order-history/helper-order-list";

const { Option } = Select;

class ExportOrderModel extends Component {
    state = {
        ...EXPORT_DEFAULT_VALUES,
        lan: this.props.lan,
    };

    getState = () => {
        return {
            ...this.state,
            [FILTER_KEYS.export_order_id]: this.getLiveOrderIds(),
        };
    };

    clearState = () => {
        let updateObject = {};
        Object.keys(this.state).forEach((key) => {
            if (key === "lan") {
                updateObject[key] = this.props.lan;
            } else {
                updateObject[key] = "";
            }
        });
        this.setState({
            ...updateObject,
            ...EXPORT_DEFAULT_VALUES,
        });
    };

    getLiveOrderIds = () => {
        const liverOrders = Array.isArray(this.props.state?.liveOrderList) ? this.props.state?.liveOrderList : [];
        return liverOrders.map((order) => _.get(order, "ord_id"));
    };

    showModel = () => _.get(this.props, "state.showExportDialog", false);

    onCancelClick = () => this.props.setState({ showExportDialog: false });

    renderLanSelect = () => {
        const storeLans = () => {
            return Array.isArray(this.props.lans) ? this.props.lans : ["en"];
        };

        return (
            <div className="export-order-section">
                <span className="export-order-section-title">
                    <FormattedMessage id="item_lan" />
                </span>
                <span className="export-order-item-lan-select">
                    <Select
                        onChange={(key) => this.setState({ lan: key })}
                        style={{ width: 150 }}
                        value={this.state.lan}
                    >
                        {storeLans().map((lan) => (
                            <Option key={`export-order-lan-${lan}`} value={lan}>
                                <FormattedMessage id={lan} />
                            </Option>
                        ))}
                    </Select>
                </span>
            </div>
        );
    };

    renderTypeSelection = () => {
        return (
            <div className="export-order-section">
                <div className="export-order-section-title">
                    <FormattedMessage id="export_type" />
                </div>
                <div className="export-order-filters">
                    <TypeCheckbox modalState={this.getState()} setModalState={(values) => this.setState(values)} />
                </div>
            </div>
        );
    };

    renderButtons = () => {
        return (
            <div className="export-order-buttons">
                <Button
                    onClick={() => {
                        this.props.exportOrders({ data: this.getState() });
                        this.clearState();
                        this.props.setState({ showExportDialog: false });
                    }}
                    type="primary"
                >
                    <b>
                        <FormattedMessage id="export" />
                    </b>
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Modal
                bodyStyle={{ padding: `12px 24px` }}
                width={550}
                onCancel={() => this.onCancelClick()}
                title={<FormattedMessage id="export_orders" />}
                footer={null}
                visible={this.showModel()}
            >
                <div className="order-list-export-modal">
                    {this.renderLanSelect()}
                    {this.renderTypeSelection()}
                    {this.renderButtons()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    lans: _.get(state, "store.storeDisplayOptions.product_display_languages"),
});

const mapDispatchToProps = {
    setState: _actions.setState,
    exportOrders: _actions.exportOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportOrderModel));
