import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import { setState } from "../../../../slices/create-order";
import helper from "../../../../util/helper";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import { MIN_POINTS_AMOUNT } from "../../../../slices/create-order";

const StyledSlider = withStyles({
    valueLabel: {
        top: -59,
        left: "-18px !important",
        "& *": {
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
})(Slider);

class PointsPickerPicker extends React.Component<any, any> {
    renderTextInfo = () => {
        const storeP2V = this.props.p2v;
        const pointsDialogPayment = helper.formatCurrency(this.props.pointsDialogUsed / storeP2V);
        const x = `${pointsDialogPayment}`;
        return (
            <div className="checkout-points-picker-dialog-text-info">
                <div>
                    <FormattedMessage
                        id="point_balance"
                        values={{ balance: helper.formatNumber(this.props.pointsBalance) }}
                    />
                </div>
                <div>
                    <FormattedMessage id="redeem_x" values={{ x }} />
                </div>
            </div>
        );
    };

    renderSlider = () => {
        const storeP2V = this.props.p2v;
        const totalBalance = this.props.pointsBalance;
        const dueBalance = this.props.totalDue * storeP2V + this.props.pointsUsed;
        const max = totalBalance > dueBalance ? dueBalance : totalBalance;
        const min = storeP2V * MIN_POINTS_AMOUNT;
        return (
            <div className="checkout-points-picker-dialog-slider-container">
                <div className="checkout-points-picker-dialog-slider">
                    <StyledSlider
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-always"
                        valueLabelFormat={(v) => helper.formatNumber(v)}
                        onChange={(e, v) => {
                            if (v >= min || v === 0) {
                                this.props.setState({ pointsDialogUsed: v });
                            }
                        }}
                        step={min}
                        max={max}
                        value={this.props.pointsDialogUsed}
                        color="secondary"
                    />
                </div>
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.renderTextInfo()}
                {this.renderSlider()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    pointsDialogUsed: _.get(state, "createOrder.pointsDialogUsed", 0),
    pointsUsed: _.get(state, "createOrder.pointsUsed", 0),
    pointsBalance: _.get(state, "createOrder.payment.pointsBalance", 0),
    currency: _.get(state, "createOrder.storeInstance.currency", 0),
    p2v: state.store?.storeBasic?.points_to_value,
    totalDue: _.get(state, "createOrder.displayPriceInfo.totalDue", 0),
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PointsPickerPicker));
