import React, { useEffect, useContext } from "react";
import _ from "lodash";
import { RootState } from "../../../../../../app/reducer";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popover, Modal, Switch, Radio } from "antd";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiCouponFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDiscounts } from "../../../../../../services/discounts";
import { setAutoDiscounts, setShipModal } from "../../../../../../slices/discounts";
import { updateStoreDiscounts } from "../../../../../../services/discounts";
import ObjectModel from "../../../../../../util/models";
import DiscountsModel from "../../../../../../util/models/store-auto-discounts";
import ShippingModal from "./shipping-modal";
import { IsMobileContext } from "../../../../../../util/helper/isMobileContext";
import { formatCurrency } from "../../../../../../util/helper";
import "./styles.scss";

function ShippingSubTab(): JSX.Element | null {
    const intl = useIntl();
    const { isMobile } = useContext(IsMobileContext);
    const dispatch = useDispatch();
    const openModal = useSelector((state: RootState) => state?.discounts?.shipModal);
    const discounts = useSelector((state: RootState) => state?.discounts?.storeAutoDiscounts);
    const storeCurrency =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const min_order = _.get(discounts, "disc_free_delivery.data.min_order", NaN) ?? 0;
    const distance = _.get(discounts, "disc_free_delivery.data.distance", NaN);
    const enabled = _.get(discounts, "disc_free_delivery.data.is_enabled", true);
    const waiveLimit = _.get(discounts, "disc_free_delivery.data.over_limit_waive_base_delivery_fee", true);
    const titles = ["minimum_amount", "max_distance", "when_exceeding_max_distance", "enabled", " "];
    const FULL_FEE = 0;
    const FEE_MINUS_DISTANCE = 1;

    useEffect(() => {
        dispatch(getStoreDiscounts());
    }, [dispatch]);

    const saveChanges = () => {
        dispatch(
            updateStoreDiscounts(
                new ObjectModel(DiscountsModel).convertToPostData(discounts, null, "shipping_discounts")
            )
        );
    };

    const deleteRowConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: "delete_discount_rule" }),
            content: intl.formatMessage({ id: "are_you_sure" }),
            onOk() {
                deleteRow();
            },
            okType: "danger",
        });
    };

    const deleteRow = () => {
        dispatch(setAutoDiscounts({ name: "disc_free_delivery", value: undefined }));
    };

    const actions = () => (
        <React.Fragment>
            <p className="discount-rule-action popover-item" onClick={() => dispatch(setShipModal(true))}>
                <FormattedMessage id="edit" />
            </p>
            <p className="discount-rule-action popover-item danger" onClick={() => deleteRowConfirm()}>
                <FormattedMessage id="delete" />
            </p>
        </React.Fragment>
    );

    const switchEnabled = (value: any) => {
        dispatch(setAutoDiscounts({ name: `disc_free_delivery.data.is_enabled`, value }));
    };

    const switchWaiveLimit = (value: any) => {
        dispatch(setAutoDiscounts({ name: `disc_free_delivery.data.over_limit_waive_base_delivery_fee`, value }));
    };

    const renderEmptySet = (
        <div className="emptySet">
            <RiCouponFill size={80} color="#e1e0e0" />
            <div className="empty-text-holder">
                <div className="empty-head mb-2">
                    <FormattedMessage id="no_discount_rules" />
                </div>
                <div className="empty-body">
                    <FormattedMessage id="create_new_rule" />
                </div>
            </div>
        </div>
    );

    const renderActs = isMobile() ? (
        <div className="actions-col">{actions()}</div>
    ) : (
        <div className="actions-col">
            <Popover content={actions()}>
                <HiDotsHorizontal size={25} color="#3898c8" />
            </Popover>
        </div>
    );

    const getMessage = (
        <div onClick={() => dispatch(setShipModal(true))}>
            {waiveLimit ? (
                intl.formatMessage({ id: "free_shipping_within_x" }, { x: distance })
            ) : (
                <FormattedMessage id="charge_full_delivery_fee" />
            )}
        </div>
    );

    type clickableColumnWrapperProps = { children?: React.ReactNode };
    const ClickableColumnWrapper: React.FC = ({ children }: clickableColumnWrapperProps) => (
        <div className="clickable-row-parent" onClick={() => dispatch(setShipModal(true))}>
            <div className="clickable-row"></div>
            {children}
        </div>
    );

    const columns = [
        {
            width: "18%",
            component: (
                <ClickableColumnWrapper>
                    <div>
                        {formatCurrency(min_order, storeCurrency)} <FormattedMessage id="before_tax" />
                    </div>
                </ClickableColumnWrapper>
            ),
        },
        {
            width: "18%",
            component: (
                <ClickableColumnWrapper>
                    <div>{distance}km</div>
                </ClickableColumnWrapper>
            ),
        },
        {
            width: "34%",
            component: <ClickableColumnWrapper>{getMessage}</ClickableColumnWrapper>,
        },
        {
            width: "15%",
            component: (
                <div>
                    <Switch checked={enabled} onChange={(e) => switchEnabled(e)} />
                </div>
            ),
        },
        { width: "15%", component: renderActs },
    ];

    const renderRow = (
        <div className="table-row d-flex">
            {distance
                ? columns.map((col, index) => (
                      <div className="table-col d-flex" style={{ width: col.width }} key={index}>
                          {col.component}
                      </div>
                  ))
                : renderEmptySet}
        </div>
    );

    const renderCol = (width: string, value: any, trans: boolean, key: any) => (
        <div className="table-col" style={{ width: width }} key={key}>
            {trans ? <FormattedMessage id={value ?? " "} /> : value}
        </div>
    );

    const renderTableHeader = (
        <div className="table-header d-flex">
            {columns.map((col, index) => renderCol(col.width, titles[index], true, index))}
        </div>
    );

    const renderRowMobile = distance ? (
        <div className="d-flex flex-column">
            {columns.map((col, index) => (
                <div className="d-flex flex-row table-header-value my-1" key={index}>
                    <div className="table-col table-header mr-1" style={{ width: "40%" }}>
                        <FormattedMessage id={titles[index] ?? " "} />
                    </div>
                    <div className="table-col table-value" style={{ width: "60%" }}>
                        {col.component}
                    </div>
                </div>
            ))}
        </div>
    ) : (
        renderEmptySet
    );

    const shippingModal = (
        <Modal
            visible={openModal}
            title={intl.formatMessage({ id: "create_automatic_shipping_discount" })}
            width="700px"
            footer={null}
            onCancel={() => dispatch(setShipModal(false))}
        >
            <ShippingModal />
        </Modal>
    );

    const renderAddRuleButton = (
        <div className="w100 d-flex mt-3 justify-content-end">
            <Button
                type="default"
                className="text-on-white-background"
                disabled={distance}
                onClick={() => dispatch(setShipModal(true))}
            >
                <FormattedMessage id="add_rule" />
            </Button>
            <Button type="primary" className="ml-2 save-button-handler" onClick={() => saveChanges()}>
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    const renderOverLimit = (
        <div className="d-flex flex-column mt-3">
            <div className="disc-sub-text mb-2">
                <FormattedMessage id="when_exceeding_distance_limit" />
            </div>
            <Radio.Group
                className="d-flex flex-column"
                onChange={(e) => switchWaiveLimit(e.target.value)}
                value={waiveLimit}
            >
                <Radio value={FEE_MINUS_DISTANCE}>
                    {intl.formatMessage({ id: "free_shipping_within_x" }, { x: distance })}
                </Radio>
                <Radio value={FULL_FEE}>
                    <FormattedMessage id="charge_full_delivery_fee" />
                </Radio>
            </Radio.Group>
        </div>
    );

    return !_.isEmpty(discounts) ? (
        <div className="subTab-table-container">
            {isMobile() ? (
                renderRowMobile
            ) : (
                <>
                    {distance ? renderTableHeader : null}
                    {renderRow}
                </>
            )}

            {distance ? renderOverLimit : null}
            {renderAddRuleButton}
            {shippingModal}
        </div>
    ) : null;
}

export default ShippingSubTab;
