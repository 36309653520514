import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Divider } from "antd";
import { getCustomerFullName } from "pages/customers/helper";
import _ from "lodash";
import Copier from "components/clipboard-copier";
import helper, { formatPhone } from "util/helper";
import EditCustomerInfoModel from "pages/customers/_components/customer-detail-page/edit-customer-info-model";
import EditCustomerAddressModal from "pages/customers/_components/customer-detail-page/edit-customer-address-modal";
import { setState } from "slices/create-order";
import { MdClose } from "react-icons/md";
import { setCustomersState } from "slices/customers";
import { CUSTOMER_ROLES } from "pages/customers/constants";

class CustomerDetail extends Component<any, any> {
    state = {};

    getCustomer = () => this.props.customer || {};

    getAddress = () => (!_.isEmpty(this.props.address) ? this.props.address : this.getCustomer().default_address || {});

    renderName = () => {
        return (
            <div className="detail-customer-full-name">
                {getCustomerFullName(this.getCustomer())}
                <div
                    style={{ marginLeft: 15 }}
                    className="create-order-remove"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ in: true });
                        this.props.setState({ address: {} });
                        this.props.setCustomersState({ customer: null });
                        this.props.setState({ selectedGiftCard: null, pointsUsed: 0, pointsDialogUsed: 0 });
                    }}
                >
                    {<MdClose />}
                </div>
            </div>
        );
    };

    renderDivider = () => {
        return <Divider />;
    };

    renderSubSectionTitle = (id: string, modalKey: string) => {
        return (
            <div className="detail-sub-section-title-row">
                <div className="detail-sub-section-title">
                    <FormattedMessage id={id || " "} />
                </div>
                <div
                    onClick={() =>
                        this.props.setParentState({
                            [modalKey]: true,
                        })
                    }
                    className="detail-sub-section-edit-text"
                >
                    <FormattedMessage id="edit" />
                </div>
            </div>
        );
    };

    renderInfoLine = (info: any, name: string) => {
        return (
            <div className="detail-info-line">
                {info ? (
                    <>
                        <div>{Array.isArray(info) ? info.map((info) => <div key={info}>{info}</div>) : info}</div>
                        <div>
                            <Copier
                                style={{ fontSize: 20 }}
                                copyContent={Array.isArray(info) ? info.join(", ") : info}
                                copyLabel={name}
                            />
                        </div>
                    </>
                ) : (
                    <FormattedMessage id={`no_${name}`} />
                )}
            </div>
        );
    };

    renderContactInfo = () => {
        return (
            <div>
                {this.renderSubSectionTitle("contact_information", "openEditInfo")}
                {this.renderInfoLine(this.getCustomer().email, "email")}
                {this.renderInfoLine(
                    !this.getCustomer().phone || !String(this.getCustomer().phone).replaceAll("0", "")
                        ? null
                        : formatPhone(this.getCustomer().phone),
                    "phone_number"
                )}
            </div>
        );
    };

    renderShippingAddress = () => {
        const formattedAddress = helper.formatAddress(this.getAddress(), {
            without: ["cntry"],
            withCustInfo: true,
        });
        return (
            <div>
                {this.renderSubSectionTitle("shipping_address", "openEditAddress")}
                {this.renderInfoLine(formattedAddress, "shipping_address")}{" "}
            </div>
        );
    };

    renderEditAddressModal = () => (
        <EditCustomerAddressModal
            // @ts-ignore
            onAddressClick={(address: any) => {
                this.props.setState({ address });
                this.props.setParentState({ openEditAddress: false });
            }}
            selectedAddress={this.getAddress()}
            {...this.props}
        />
    );

    render() {
        return (
            <div className="create-order-customer-detail">
                {this.renderName()}
                {this.getCustomer().role !== CUSTOMER_ROLES.default ? this.renderDivider() : null}
                {this.getCustomer().role !== CUSTOMER_ROLES.default ? this.renderContactInfo() : null}
                {helper.isDelivery(this.props.shippingMethod) ? (
                    <>
                        {this.renderDivider()}
                        {this.renderShippingAddress()}
                    </>
                ) : null}
                <EditCustomerInfoModel {...this.props} />
                {this.renderEditAddressModal()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    customer: _.get(state, "customers.customer", {}),
    address: _.get(state, "createOrder.address", {}),
    state: _.get(state, "createOrder", {}),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
});

const mapDispatchToProps = {
    setState,
    setCustomersState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerDetail)));
