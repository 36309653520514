import { Button, Form, Input, InputNumber } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import qs from "querystring";
import { useHistory } from "react-router-dom";

interface ReferralsFilterProps {
    closeModal: () => any;
    search: any;
}

const ReferralsFilter = (props: ReferralsFilterProps): JSX.Element => {
    const intl = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const cloned: any = _.cloneDeep(data);

        _.each(cloned, (value, key) => {
            if (_.isUndefined(value) || value === "") {
                delete cloned[key];
            }
        });

        const query: any = qs.stringify(cloned);

        history.push("/referrals?" + query);

        props.closeModal();
    };

    const resetFilters = () => {
        form.resetFields();

        history.push("/referrals");

        props.closeModal();
    };

    useEffect(() => {
        form.resetFields();
    }, [props.search, form]);

    const renderBalanceRange = (
        <>
            <FormattedMessage id="referral_balance_range" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_points_bal">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_points_bal">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
        </>
    );

    const renderOrderRange = (
        <>
            <FormattedMessage id="referral_order_range" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_num_ord">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_num_ord">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
        </>
    );

    const renderReferralRange = (
        <>
            <FormattedMessage id="referral_range" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_num_ref">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_num_ref">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
        </>
    );

    const renderNameRow = (
        <>
            <div className="d-flex">
                <div style={{ flex: 1, paddingRight: 6 }}>
                    <FormattedMessage id="name" />
                    <Form.Item name="c_name">
                        <Input
                            placeholder={intl.formatMessage({
                                id: "type_here",
                            })}
                        />
                    </Form.Item>
                </div>
                <div style={{ flex: 1, paddingRight: 6 }}>
                    <FormattedMessage id="code" />
                    <Form.Item name="ref_code">
                        <Input
                            placeholder={intl.formatMessage({
                                id: "type_here",
                            })}
                        />
                    </Form.Item>
                </div>
            </div>
        </>
    );

    return (
        <div>
            <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish} initialValues={props.search}>
                {renderNameRow}
                {renderReferralRange}
                {renderOrderRange}
                {renderBalanceRange}
                <div className="footer-filter">
                    <Button type="link" onClick={resetFilters}>
                        <FormattedMessage id="reset_filters" />
                    </Button>
                    <Button type="primary" htmlType="submit" className="save-button-handler">
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ReferralsFilter;
