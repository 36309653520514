import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import "./quotes.scss";
import { withRouter } from "react-router-dom";
import Wrapper from "components/wrapper";
import { getQuotes } from "services/quotes";
import Table from "./page-components/table";
import ActionBar from "./page-components/action-bar";
import _ from "lodash";
import { Quote } from "./models/quote";
import QuoteCard from "./page-components/quote-card";
import { getManagedStores } from "services/managed-stores";
import { getKeyByValue, getShippingOptions, reviseDeliveryMethodWithStore } from "util/helper";
import config from "config";
import { DINE_IN } from "app/constants";
import { Spin, Pagination, PaginationProps, Radio, RadioChangeEvent } from "antd";
import { setState } from "slices/quotes";
import { getShippingMethodMappingInfo } from "./helper";
import QuotesAppliedFilters from "./page-components/quotes-applied-filters";
import ShippingOptionsModal from "components/shipping-options-modal";
import { RiDraftLine } from "react-icons/ri";
import PrintLayoutSwitch from "../../components/print-layout-switch";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "/quotes",
            breadcrumbName: "cloud_pos",
        },
    ],
};

const SHIPPING_METHOD_OPTION_ALL = 0;
const DEFAULT_SORT_OPTION = config.QUOTE_API_SORTABLE_FIELDS.updated_at;
const DEFAULT_PAGE_SIZE = 20;
const PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE.toString(), "50", "100"];

class Quotes extends Component<any, any> {
    state = {
        isDineIn: false,
        isPrintLayout: false,
    };

    agGridRef = React.createRef();

    componentDidMount = () => {
        this.initData();
    };

    componentDidUpdate = (prevProps: any) => {
        if (!_.isEqual(prevProps.storeRecords, this.props.storeRecords)) {
            this.setState({ isDineIn: this.isDineIn() });
        }
    };

    componentWillUnmount = () => {
        this.props.setState({ filterOptions: {}, isEditMode: false, selected: [] });
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    initData = () => {
        // Quotes are retrieved when filter options are changed
        this.props.getQuotes({
            sortd: DEFAULT_SORT_OPTION,
            lan: this.props.lan,
        });
        this.props.getManagedStores();
        this.setState({ isDineIn: this.isDineIn() });
    };

    setIsPrintLayout = (value: boolean) => {
        this.setState({
            ...this.state,
            isPrintLayout: value,
        });
    };

    getQuotes = () => {
        let quotes: Quote[] = [];
        if (Array.isArray(this.props.quotes)) {
            quotes = this.props.quotes;
        }
        return quotes;
    };

    isDineIn = () => {
        const methods = config.SHIPPING_MAPPING_TO_NUMERIC;
        return (
            reviseDeliveryMethodWithStore(this.props.storeRecords, getKeyByValue(methods, methods.eatin)) === DINE_IN
        );
    };

    /**
     * When filter order type button is clicked, call getQuotes api with a filter option
     *
     * @param e - filter order type radio change event
     */
    handleFilterOrderTypeButtonClicked = (e: RadioChangeEvent) => {
        if (e.target.value !== SHIPPING_METHOD_OPTION_ALL) {
            this.props.setState({ filterOptions: { ...this.props.filterOptions, shp_mtd: e.target.value } });
        } else {
            this.props.setState({ filterOptions: { ...this.props.filterOptions, shp_mtd: undefined } });
        }
    };

    renderQuotes = () => {
        return this.getQuotes().map((quote) => {
            return (
                <div className={"quote-card-outer-wrapper"} key={quote.quote_id}>
                    <QuoteCard {...quote} isDineIn={this.state.isDineIn} />
                </div>
            );
        });
    };

    renderListView = () => {
        return !this.props.gettingStoreDetails ? (
            <Table
                // @ts-ignore
                isDineIn={this.state.isDineIn}
                agGridRef={this.agGridRef}
            />
        ) : null;
    };

    renderQuotesGrid = () => {
        const quotes = this.getQuotes();
        if (quotes.length === 0) {
            return (
                <div className="inactive-empty-warn w100">
                    <RiDraftLine size="60" color="lightgrey" className="mb-2" />
                    <FormattedMessage id="no_draft_order" />
                </div>
            );
        } else {
            return <div className="quotes-grid-container">{this.renderQuotes()}</div>;
        }
    };

    /**
     * When the user selects a different page, add page number and the amount of quotes
     * to fetch to the filter options, and remove all selected quotes
     *
     * @param page the page number to fetch
     * @param pageSize the number of quotes to fetch
     */
    handlePageChange = (page: number, pageSize?: number) => {
        this.props.setState({
            filterOptions: {
                ...this.props.filterOptions,
                page,
                limit: pageSize,
            },
            selected: [],
        });
    };

    renderShippingMethodLength = (shippingMethod: number) => {
        const shippingMethodLength = _.get(this.props.pagingInfo?.summary_by_shpmtd, shippingMethod) || 0;
        return (
            <>
                {shippingMethodLength > 0
                    ? " (" + this.props.pagingInfo?.summary_by_shpmtd[shippingMethod] + ")"
                    : null}
            </>
        );
    };

    renderRadioButton = (shippingMethod: number) => {
        const [shippingMethodStringID, appendedClassName] = getShippingMethodMappingInfo(
            shippingMethod,
            this.state.isDineIn
        );
        return (
            <Radio.Button
                key={shippingMethod}
                className={"quotes-filter-order-type-button quotes-filter-order-type-button-" + appendedClassName}
                value={shippingMethod}
            >
                <FormattedMessage id={shippingMethodStringID} />
                {this.renderShippingMethodLength(shippingMethod)}
            </Radio.Button>
        );
    };

    renderFilterButtons = () => {
        return (
            <Radio.Group
                className="quotes-filter-order-type-buttons"
                buttonStyle="solid"
                defaultValue={SHIPPING_METHOD_OPTION_ALL}
                onChange={this.handleFilterOrderTypeButtonClicked}
                value={this.props.filterOptions?.shp_mtd || SHIPPING_METHOD_OPTION_ALL}
            >
                <Radio.Button
                    key={SHIPPING_METHOD_OPTION_ALL}
                    className="quotes-filter-order-type-button quotes-filter-order-type-button-all"
                    value={SHIPPING_METHOD_OPTION_ALL}
                >
                    {`All${this.props.pagingInfo?.total > 0 ? " (" + this.props.pagingInfo?.total + ")" : ""}`}
                </Radio.Button>
                {getShippingOptions(this.props.storeRecords).map((shippingOption: any) => {
                    return this.renderRadioButton(shippingOption.value);
                })}
            </Radio.Group>
        );
    };

    renderPagination = () => {
        const pageSize = this.props.pagingInfo?.limit ?? DEFAULT_PAGE_SIZE;
        const currPage = this.props.pagingInfo?.page ?? 1;
        const total = this.props.pagingInfo?.total ?? 0;
        const paginationProps: PaginationProps = {
            total,
            pageSize,
            current: currPage,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            onChange: (page, pageSize) => this.handlePageChange(page, pageSize),
        };
        return <Pagination {...paginationProps} />;
    };

    actionBarProps = () => {
        return {
            ...this.state,
            defaultSortOption: DEFAULT_SORT_OPTION,
            setParentState: (values: any) => this.setState(values),
        };
    };

    renderQuoteContainerTop = () => {
        return !this.props.useListView ? (
            <div className="quotes-pagination-container-top">
                {this.renderFilterButtons()}
                <QuotesAppliedFilters />
                {this.renderPagination()}
            </div>
        ) : (
            <div className="quotes-pagination-container-top quotes-list-view-top">
                <div>
                    {this.renderFilterButtons()}
                    <QuotesAppliedFilters />
                </div>

                <PrintLayoutSwitch
                    isPrintLayout={this.state.isPrintLayout}
                    setIsPrintLayout={this.setIsPrintLayout}
                    agGridRef={this.agGridRef}
                />
            </div>
        );
    };

    renderQuoteContainerContent = () => {
        return this.props.useListView ? this.renderListView() : this.renderQuotesGrid();
    };

    renderQuoteContainerBottom = () => {
        return this.props.useListView ? (
            <div className="quotes-pagination-container-bottom">{this.renderPagination()}</div>
        ) : null;
    };

    render() {
        return (
            <Wrapper paddingBottom={0} breadcrumb={breadcrumb} helmet={{ title: "cloud_pos" }}>
                <ActionBar {...this.actionBarProps()} />
                <Spin spinning={this.props.gettingQuotes || this.props.gettingStoreDetails}>
                    {this.renderQuoteContainerTop()}
                    <div id="quotes-container" className="ag-theme-alpine">
                        <div className="quotes-view-container">{this.renderQuoteContainerContent()}</div>
                        {this.renderQuoteContainerBottom()}
                    </div>
                </Spin>
                <ShippingOptionsModal
                    {...{
                        shippingOptionsModal: this.props.shippingOptionsModal,
                        setState: (values: any) => this.props.setState(values),
                    }}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state?.setting?.lan,
    gettingQuotes: _.get(state, "quotes.gettingQuotes", false),
    gettingStoreDetails: _.get(state, "store.loading", false),
    quotes: _.get(state, "quotes.quotes", []),
    filterOptions: _.get(state, "quotes.filterOptions", {}),
    useListView: _.get(state, "quotes.useListView", true),
    pagingInfo: _.get(state, "quotes.search", {}),
    storeRecords: _.get(state, "store.records", {}),
    shippingOptionsModal: _.get(state, "quotes.shippingOptionsModal", false),
});

const mapDispatchToProps = {
    setState,
    getQuotes,
    getManagedStores,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Quotes)));
