import React from "react";
import Wrapper from "../../components/wrapper";
import FilterBar from "./_components/filter-bar";
import ReferralsPagnation from "./_components/pagination";
import useReferralsTable from "./_hooks/useReferralsTable";
import "./index.scss";
import CustomerReferrals from "./_components/customer-referrals";
import OrderHistory from "./_components/order-history";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "referrals",
            breadcrumbName: "referrals",
        },
    ],
};

function ReferralsPage(): JSX.Element {
    const { getTable } = useReferralsTable();
    return (
        <Wrapper helmet={{ title: "referrals" }} breadcrumb={breadcrumb} paddingBottom={72}>
            <div className="customers-page products-page">
                <FilterBar />
                {getTable()}
                <OrderHistory />
                <CustomerReferrals />
                <ReferralsPagnation />
            </div>
        </Wrapper>
    );
}

export default ReferralsPage;
