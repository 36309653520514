import _ from "lodash";

export const ITEM_CHANGE_ACTION_REMOVE = 1;
export const ITEM_CHANGE_ACTION_REPLACE = 2;
export const ITEM_CHANGE_ACTION_ADD = 3;
export const ITEM_CHANGE_ACTION_CHECK_PRICES = 4;
export const ITEM_CHANGE_ACTION_POS_MODE = 5;
export const ITEM_CHANGE_ACTION_SEARCH_BAR = 6;
export const ITEM_CHANGE_ACTION_SUGGEST = 1;
export const ITEM_CHANGE_ACTION_APPLIED = 2;
export const ITEM_CHANGE_ACTION_CANCEL_ALL = 3;

export const ITEM_CHANGE_REASON_CUSTOMER_REQUEST = 1;
export const ITEM_CHANGE_REASON_OUT_OF_STOCK_TODAY = 2;
export const ITEM_CHANGE_REASON_OUT_OF_STOCK_INFINITE = 3;
export const ITEM_CHANGE_LOCALE_ACTION_REMOVE = 1;
export const ITEM_CHANGE_LOCALE_ACTION_REPLACE = 2;
export const ITEM_CHANGE_LOCALE_ACTION_REVERSE = 4;
export const ITEM_CHANGE_LOCALE_ACTION_UPDATE = 5;

//generated id example: pid:opiton1[{subOption1:qty},{subOption2:qty},{subOption3:qty}]:options2[{subOption1:qty},{subOption2:qty}]~price
export const generateItemId = (item) => {
    const pid = _.get(item, "pid", "");
    const pc = _.get(item, "pc", "");
    let optionsId = "";
    const opts = Array.isArray(_.get(item, "opts")) ? _.get(item, "opts") : [];
    opts.map((opt) => {
        //genearateg sub opt id
        let subOptIdStr = "";
        const subOpts = Array.isArray(_.get(item, "opts")) ? _.get(item, "opts") : [];
        subOpts.map((subOpt) => {
            const subOptId = _.get(subOpt, "id");
            const subSubOpts = Array.isArray(_.get(subOpt, "opts")) ? _.get(subOpt, "opts") : [];
            let subSubOptIdStr = "";
            subSubOpts.map((subSubOpt) => {
                const subSubOptId = _.get(subSubOpt, "id");
                const subSubOptQty = _.get(subSubOpt, "qty", 1);
                const subSubOptFullStr = `{${subSubOptId}:${subSubOptQty}}`;
                subSubOptIdStr += subSubOptIdStr ? `,${subSubOptFullStr}` : subSubOptFullStr;
                return "";
            });
            subOptIdStr += subOptIdStr ? `,${subOptId}[${subSubOptIdStr}]` : `${subOptId}[${subSubOptIdStr}]`;
            return "";
        });
        //two part of id to generate opt id
        let optId = _.get(opt, "id");
        const optIdStr = (optId += subOptIdStr ? `[${subOptIdStr}]` : "");
        optionsId += `:${optIdStr}`;
        return "";
    });
    return `${pid}${optionsId}~${pc}`;
};

//sort with orignal items and item changes -> to generate correct item changes
//Step 1 to genearate a map with result
//Step 2 compare to the orignal and make correct changes
export const sortChangeItems = (originalItems, itemChanges) => {
    //Step 1
    const resultMap = generateResultMap(originalItems, itemChanges);
    //Step 2
    return generateCorrectChanges(originalItems, resultMap);
};

//sort with orignal items and item changes -> to know whether all items has been cleared
//Step 1 to genearate a map with result
//Step 2 to check if qty is 0 in the result map
export const isNoMoreItems = (originalItems, itemChanges) => {
    //Step 1
    const resultMap = generateResultMap(originalItems, itemChanges);
    //Step 2
    const itemQty = getItemsTotalQty(resultMap);
    return itemQty <= 0;
};

//Step 1 of above function
//to generate a result items map that after the changes
//map is consists of using unique item id as key
//product detail: obj,  quantity: int, replaced_id: [] as values
const quantityKey = "qty";
const productDetailKey = "product";
const replaceIdKey = "replace_id";
export const generateResultMap = (originalItems, itemChanges) => {
    const actionsHandleSequeceMap = {
        ITEM_CHANGE_ACTION_REMOVE: 1,
        ITEM_CHANGE_ACTION_REPLACE: 2,
        ITEM_CHANGE_ACTION_ADD: 3,
    };
    let resultMap = {};

    const originalItemsArr = Array.isArray(originalItems) ? originalItems : [];
    const itemChangesArr = Array.isArray(itemChanges)
        ? _.sortBy(itemChanges, (change) => {
              //sort by action
              return _.get(actionsHandleSequeceMap, _.get(change, "action"));
          })
        : [];
    //hanle original item
    originalItemsArr.forEach((item) => {
        const id = generateItemId(item);

        //qty
        const originalItem = _.get(resultMap, id, {});
        const originalQty = _.get(originalItem, quantityKey, 0);
        const newQty = _.get(item, `cnt`, 0);

        resultMap[id] = {
            [quantityKey]: originalQty + newQty,
            [productDetailKey]: item,
        };
    });
    //handle new item
    itemChangesArr.forEach((change) => {
        const action = _.get(change, "action");
        const reason = _.get(change, "reason") ? _.get(change, "reason") : ITEM_CHANGE_REASON_CUSTOMER_REQUEST;
        if (action === ITEM_CHANGE_ACTION_REMOVE) {
            const originalItem = originalItemsArr.find((item) => item.item_id === change.item_id);
            const originalItemId = generateItemId(originalItem);
            const itemMapObj = _.get(resultMap, originalItemId, {});
            //qty
            const originalQty = _.get(itemMapObj, quantityKey, 0);
            const newQty = _.get(originalItem, `cnt`, 0);
            resultMap[originalItemId] = {
                ...itemMapObj,
                reason,
                [quantityKey]: originalQty - newQty,
            };
        }
        if (action === ITEM_CHANGE_ACTION_ADD || !action) {
            const newItem = _.get(change, "new_product");
            const newItemId = generateItemId(newItem);
            const newItemObj = _.get(resultMap, newItemId, {});
            //qty
            const originalQty = _.get(newItemObj, quantityKey, 0);
            const newQty = _.get(newItem, `cnt`, 0);
            resultMap[newItemId] = {
                ...newItemObj,
                reason,
                [quantityKey]: originalQty + newQty,
                [productDetailKey]: newItem,
            };
        }
        if (Number(action) === ITEM_CHANGE_ACTION_REPLACE) {
            //original
            const newItem = _.get(change, "new_product");
            const originalItem = originalItemsArr.find((item) => item.item_id === change.item_id);
            const originalItemId = generateItemId(originalItem);
            const originalItemMapObj = _.get(resultMap, originalItemId, {});
            const originalQty = _.get(originalItemMapObj, quantityKey, 0);
            const originalNewQty = _.get(originalItem, `cnt`, 0);
            resultMap[originalItemId] = {
                ...originalItemMapObj,
                reason,
                [productDetailKey]: newItem,
                [quantityKey]: originalQty - originalNewQty,
            };

            //new
            const newItemId = generateItemId(newItem);
            const newItemMapObj = _.get(resultMap, newItemId, 0);
            const newOrignalQty = _.get(newItemMapObj, quantityKey, 0);
            const newNewQty = _.get(newItem, `cnt`, 0);
            resultMap[newItemId] = {
                ...newItemMapObj,
                reason,
                [quantityKey]: newOrignalQty + newNewQty,
                [productDetailKey]: newItem,
                [replaceIdKey]: [..._.get(newItemMapObj, replaceIdKey, []), originalItemId],
            };
        }
    });
    return resultMap;
};

//Step 2 of above function
//to generate the correct changes
//use the final result and compare to original item to make changes
const generateCorrectChanges = (originalItems, resultMap) => {
    var changes = [],
        addedIndex = 0;
    const originalItemsArr = Array.isArray(originalItems) ? originalItems : [];
    Object.keys(resultMap).forEach((id) => {
        const updatedItem = resultMap[id];
        const newQty = _.get(updatedItem, quantityKey, 0);
        const originalItem = originalItemsArr.find((item) => id === generateItemId(item));
        const originalQty = _.get(originalItem, "cnt");
        const replaceMainId = _.get(updatedItem, `${replaceIdKey}.0`, "");
        const newProduct = _.get(updatedItem, productDetailKey, {});
        const reason = _.get(updatedItem, "reason", {});

        var changesObject = {};
        if (!_.isEmpty(originalItem) && newQty > 0 && newQty !== originalQty) {
            //update
            changesObject = {
                pid: originalItem.pid,
                item_id: originalItem.item_id,
                action: ITEM_CHANGE_ACTION_REPLACE,
                reason,
                new_product: {
                    ...newProduct,
                    cnt: newQty,
                },
            };
        } else if (!_.isEmpty(originalItem) && newQty === 0) {
            //remove
            changesObject = {
                pid: originalItem.pid,
                item_id: originalItem.item_id,
                action: ITEM_CHANGE_ACTION_REMOVE,
                reason,
            };
        } else if (replaceMainId && newQty > 0) {
            //replace
            const originalReplacedItem = originalItemsArr.find((item) => replaceMainId === generateItemId(item));
            const replacedItem = originalReplacedItem ? originalReplacedItem : {};
            const replacedItemResult = resultMap[replaceMainId];
            const replacedItemResultQty = _.get(replacedItemResult, quantityKey, 0);
            if (replacedItemResultQty === 0) {
                //if original item has been totally replaced
                //then will be actually replaced
                changesObject = {
                    pid: replacedItem.pid,
                    item_id: replacedItem.item_id,
                    action: ITEM_CHANGE_ACTION_REPLACE,
                    reason,
                    new_product: {
                        ...newProduct,
                        cnt: newQty,
                    },
                };

                //filter out the removed object -> to update
                changes = _.cloneDeep(
                    changes.filter((change) => String(change.item_id) !== String(replacedItem.item_id))
                );
                //remove future handling of this object
                delete resultMap[replaceMainId];
            } else {
                //if the replaced item still have some qty
                //then the replacing item should be adding
                changesObject = {
                    pid: newProduct.pid,
                    item_id: originalItemsArr.length + addedIndex,
                    action: ITEM_CHANGE_ACTION_ADD,
                    new_product: {
                        ...newProduct,
                        cnt: newQty,
                    },
                };
                addedIndex++;
            }
        } else if (newQty > 0 && _.isEmpty(originalItem)) {
            //add
            changesObject = {
                pid: newProduct.pid,
                item_id: originalItemsArr.length + addedIndex,
                action: ITEM_CHANGE_ACTION_ADD,
                new_product: {
                    ...newProduct,
                    cnt: newQty,
                },
            };
            addedIndex++;
        }
        if (!_.isEmpty(changesObject)) {
            changes.push(changesObject);
        }
    });
    return changes;
};

const getItemsTotalQty = (resultMap) => {
    let qty = 0;
    Object.values(resultMap).forEach((item) => {
        const newQty = _.get(item, quantityKey, 0);
        qty += newQty;
    });
    return qty;
};
