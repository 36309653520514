// @ts-nocheck
import { Form, Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import qs from "querystring";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { useIntl } from "react-intl";
import { QRCode } from "react-qrcode-logo";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ItemTabs } from ".";
import { RootState } from "../../../../app/reducer";
import { createMessage } from "../../../../components/message";
import config from "../../../../config";
import { runImageCacheHelper } from "../../../../services/image-cache";
import { deleteProduct, editProduct, getProduct } from "../../../../services/products";
import { getValuesSuccess, resetProduct } from "../../../../slices/products";
import { oauth } from "../../../../util/api";
import helper from "../../../../util/helper";
import ObjectModel from "../../../../util/models";
import StoreProduct from "../../../../util/models/store-product";
import { MAX_BARCODE_LEN, MIN_BARCODE_LEN, ProductTypes, STATUS_DISABLED, STATUS_ENABLED } from "../../constants";
import { validateBarcode, validateStrInput } from "../../helper";
import useAddEditProductPageDuplicate from "./useAddEditProductPageDuplicate";
const INPUT_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

interface LocationState {
    addEditItem: any;
    record: any;
    duplicate: any;
}

function useAddEditProductPage(): any {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { pid = "" } = useParams<{ pid?: string }>();
    const tab = String(qs.parse(location.search)?.["?tab"] ?? ItemTabs.ItemInformation);
    const useDuplicate = String(qs.parse(location.search)?.["?duplicate"]) === "1";
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [showQR, setShowQR] = useState<boolean>(false);
    const [showPoster, setShowPoster] = useState<boolean>(false);
    const shareModal = useSelector((state: RootState) => state.products?.shareModal);
    const { product, gid, user, lan, products, lastSearch, languages, addEditItemDuplicate, mproducts } = useSelector(
        (state: RootState) => ({
            product: state?.products?.product,
            products: state?.products?.products,
            user: state?.user,
            lan: state?.setting?.lan,
            gid: state?.store?.records?.g_id,
            lastSearch: state?.products?.lastSearch,
            languages: state?.store?.storeDisplayOptions?.product_display_languages,
            addEditItemDuplicate: state?.products?.addEditItemDuplicate,
            mproducts: state?.products,
            state: state,
        })
    );

    const [touched, setTouched] = useState(false);
    const [images, setImages] = useState<any>([]);
    const setValues = (payload: any) => dispatch(getValuesSuccess(payload));
    const values = useSelector((state: RootState) => state?.products?.values);
    const [originalValues, setOriginalValues] = useState<any>({ duplicate: false });
    const storeLogoUrl = useSelector((state: RootState) => state?.store?.storeImages?.store_logo);
    const [options, setOptions] = useState<any[]>([]);
    const [barcodeErrorMessage, setBarcodeErrorMessage] = useState("");
    const getValuesState = () => values;
    const getValues = useCallback(
        (shouldUseLocationState = true) => {
            let productObj = location?.state?.record ?? {
                product_type: ProductTypes.NORMAL_PRODUCT,
                tax_class: 1, // no tax goods
                status: STATUS_ENABLED,
                time: "",
            };
            if (pid) {
                productObj = product;
            } else if (useDuplicate) {
                productObj = addEditItemDuplicate;
            }

            const v: any = new ObjectModel(StoreProduct, productObj).getData();

            const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];

            dks.forEach((dk) => {
                if (dk === "start_date") {
                    v[dk] = moment(v[dk] || new Date(), "YYYY-MM-DD HH:mm:ss");
                } else if (dk === "end_date") {
                    v[dk] = v[dk]
                        ? moment(v[dk], "YYYY-MM-DD HH:mm:ss")
                        : moment(new Date(), "YYYY-MM-DD HH:mm:ss").add(10, "days");
                } else {
                    v[dk] = v[dk] ? moment(v[dk] ?? new Date(), "YYYY-MM-DD HH:mm:ss") : "";
                }
            });
            return Object.assign({}, v, shouldUseLocationState && useDuplicate ? addEditItemDuplicate ?? {} : {});
        },
        [location, pid, product, useDuplicate, addEditItemDuplicate]
    );

    const { onDuplicateProduct, getDuplicateModal } = useAddEditProductPageDuplicate({ getValues, images, options });
    const stableOnDuplicateProduct = useCallback(onDuplicateProduct, []);

    useMemo(() => {
        // mount state to from's values
        form.setFieldsValue({ ...values });
    }, [values]);

    const handleValuesChanged = (_: any, newValues: any) => {
        const validatedValues = validateBarcode(validateStrInput(newValues));
        setValues({ ...validatedValues });
        setTouched(form.isFieldsTouched());
    };

    const initialFormValidationPassed = (): boolean => {
        const barcodeInput = document.getElementById("barcode-input") as HTMLInputElement;

        if (barcodeInput?.value && barcodeInput?.value.length < MIN_BARCODE_LEN) {
            setBarcodeErrorMessage(
                intl.formatMessage(
                    { id: "barcode_length_invalid" },
                    { minlen: MIN_BARCODE_LEN, maxlen: MAX_BARCODE_LEN }
                )
            );
            barcodeInput.focus();
            return false;
        }
        return true;
    };

    const submitData = async (
        data: any,
        callback = (productId: any) => {
            return productId;
        }
    ) => {
        if (product?.product_id) {
            data.product_id = product?.product_id;
        }

        const post = _.cloneDeep(data);

        if (images.filter((i: any) => i.includes("http")).length) {
            const upload = await oauth("IMAGE_UPLOAD")({
                method: "POST",
                body: JSON.stringify({
                    data: {
                        imgs: images
                            .filter((i: any) => i.includes("http"))
                            .map((i: any) => ({
                                url: i,
                                type: "product",
                            })),
                        gid,
                    },
                }),
            });

            post.images = await Promise.all(
                images.map(async (image: any) => {
                    if (image.includes("http")) {
                        return upload?.records?.[image];
                    }

                    return image;
                })
            );
        } else {
            // images should still be included when updating the product even though no images are uploaded.
            // ie. images are removed
            post.images = images;
        }

        post.options = options;

        const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];

        dks.forEach((dk) => {
            if (data[dk] && moment(data[dk]).format(INPUT_DATE_FORMAT) !== "Invalid date") {
                post[dk] = moment(data[dk]).format(INPUT_DATE_FORMAT);
            } else {
                post[dk] = "";
            }
        });

        post["status"] =
            post["status"] === true || post["status"] === STATUS_ENABLED
                ? STATUS_ENABLED
                : STATUS_DISABLED; /* 1 enabled; 2 false*/

        if (post["product_type"] === 6 /* Group Product Type */) {
            post["availability"] = 1 /* Available */;
            delete post.price;
        }

        Object.keys(post ?? {}).forEach((key) => {
            if (key !== "product_id" && !dks.includes(key) && key !== "images") {
                //@ts-ignore
                if (_.isEqual(post?.[key], product?.[key])) {
                    delete post[key];
                }
            }
        });

        post.options = post?.options?.map((opt: any, i: number) => ({
            ...opt,
            id: i + 1,
            opts: opt?.opts?.map((o: any, j: number) => ({
                ...o,
                id: j + 1,
                max: o?.max || 1,
            })),
        }));

        dispatch(editProduct(new ObjectModel(StoreProduct).convertToPostData(post), {}, callback, setOriginalValues));
    };

    const onSaveAndContinue = (data: any) => {
        const callback = (productId: any) => {
            if (productId) {
                history.push("/products/addEditItem/" + String(productId));
            } else {
                window.location.reload();
            }
            setTouched(false);
            setSubmitting(false);
        };
        onFinish(data, callback);
    };
    /**
     *
     * TODO: Somehow the empty string values are not being saved
     */
    const onFinish = async (
        data: any,
        callback = (productId: any) => {
            clearForm();
            setSubmitting(false);
            return productId;
        }
    ) => {
        if (!initialFormValidationPassed()) {
            return;
        }

        setSubmitting(true);
        const dataCopy = { ...getValues(), ...values };
        const cleaned = _.cloneDeep(dataCopy);
        Object.keys(cleaned).forEach((key) => {
            if (cleaned[key] === undefined) {
                delete cleaned[key];
            }
        });

        const submit = _.cloneDeep({ ...cleaned });

        const getEmptyNames = () => {
            const emptyNames: any = {};
            languages.forEach((language: any) => {
                if (submit?.["name_" + language] == null || submit?.["name_" + language] === "") {
                    emptyNames[language] = submit?.["name_" + language];
                }
            });
            return emptyNames;
        };

        const emptyNames = getEmptyNames();
        const getSameNames = () => {
            const same: any = {};
            languages.forEach((language: any) => {
                if (
                    submit?.["name_" + language] &&
                    submit?.["name_" + language] === originalValues?.["name_" + language]
                ) {
                    same[language] = submit?.["name_" + language];
                }
            });
            return same;
        };

        const getNewNames = () => {
            const newNames: any = {};
            languages.forEach((language: any) => {
                if (submit?.["name_" + language] !== originalValues?.["name_" + language]) {
                    newNames[language] = submit?.["name_" + language];
                }
            });
            return newNames;
        };

        const sameNames = getSameNames();
        const newNames = getNewNames();

        if (!_.isEmpty(sameNames) && !_.isEmpty(newNames)) {
            const lans = Object.keys(sameNames)?.join(", ");
            const values = lans
                .split(", ")
                .map((l) => `"${sameNames?.[l]}"`)
                .join(", ");
            const changedLans = Object.keys(newNames)?.join(", ");
            const changed = changedLans
                .split(", ")
                .map((l) => `"${newNames?.[l]}"`)
                .join(", ");
            const languagesFullNames = Object.keys(sameNames)
                ?.map((lan) => {
                    // @ts-ignore
                    return config.LANGUAGES_FULL_NAME[lan];
                })
                ?.join(", ");
            const changedLanguagesFullNames = Object.keys(newNames)
                ?.map((lan) => {
                    // @ts-ignore
                    return config.LANGUAGES_FULL_NAME[lan];
                })
                ?.join(", ");
            Modal.confirm({
                title: intl.formatMessage({ id: "item_title" }),
                content: intl.formatMessage(
                    {
                        id: _.isEmpty(emptyNames)
                            ? "item_title_language_content"
                            : "item_title_missing_language_content",
                    },
                    { languagesFullNames, changedLanguagesFullNames, values, changed }
                ),
                onOk() {
                    submitData({ ...getValues(), ...getValuesState() }, callback);
                },
                onCancel() {
                    setSubmitting(false);
                },
            });
        } else {
            submitData({ ...getValues(), ...getValuesState() }, callback);
        }
    };

    const onChangeTab = (tab: string) => {
        const search = qs.parse(location.search.slice(1));
        search.tab = tab;
        history.push((pid ? `/products/addEditItem/${pid}?` : "/products/addEditItem?") + qs.stringify(search));
    };

    const clearForm = () => {
        form.resetFields();
        history.push("/products?" + qs.stringify(lastSearch));
        setTouched(false);
    };

    const showForm = useCallback(() => {
        if (pid) {
            return !_.isEmpty(product);
        }
        return true;
    }, [pid, product]);

    const openWeChatQRModal = () => {
        setShowQR(true);
    };

    const openPosterModal = () => setShowPoster(true);

    const copyProduct = (url: string) => {
        navigator.clipboard.writeText(url).then(
            function () {
                toast(createMessage(intl.formatMessage({ id: "product_url_copied" }), HiInformationCircle));
            },
            function () {
                // Handle error
            }
        );
    };

    const QrCode = () => {
        const canvas: any = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas?.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${helper.getTransString(product?.name, lan)}-${pid}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowQR(false);
    };

    const getCurrentIndex = () => products?.findIndex?.((p) => Number(p.product_id) === Number(pid));

    const getLastDisabled = () => {
        const current = getCurrentIndex();

        if (current === -1) {
            return true;
        }

        if (current === 0) {
            return true;
        }

        return products?.length === 0;
    };

    const getNextDisabled = () => {
        const current = getCurrentIndex();

        if (current === -1) {
            return true;
        }

        if (current === products.length - 1) {
            return true;
        }

        return products?.length === 0;
    };

    const getFirst = () => {
        history.push(`/products/addEditItem/${products?.[0]?.product_id}`);
    };

    const getLast = () => {
        history.push(`/products/addEditItem/${products?.[products?.length - 1]?.product_id}`);
    };

    const getPrev = () => {
        history.push(`/products/addEditItem/${products?.[getCurrentIndex() - 1]?.product_id}`);
    };

    const getNext = () => {
        history.push(`/products/addEditItem/${products?.[getCurrentIndex() + 1]?.product_id}`);
    };

    const onDeleteProduct = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: "warning" }),
            content: intl.formatMessage({ id: "confirm_delete_product" }),
            onOk() {
                dispatch(deleteProduct({ pid }));
                history.push("/products?" + qs.stringify(lastSearch));
            },
        });
    };

    useEffect(() => {
        runImageCacheHelper({
            action: "clear_image_cache",
            user_id: user.id,
            imageType: "",
            type: "store",
        });
    }, [user.id]);

    useEffect(() => {
        dispatch(resetProduct());

        if (pid) {
            dispatch(getProduct(Number(pid)));
        }

        form.resetFields();
    }, [dispatch, pid, form]);

    useEffect(() => {
        const values = {
            ...getValues(),
            related_products: getValues()?.related_products ?? [],
            upsell_products: getValues()?.upsell_products ?? [],
        };
        setOriginalValues({ ...getValues(false), duplicate: false });
        
        if(initialRender.current ){
            setImages([])
        }
        else if(originalValues.product_id == '' && product.product_id != null){
            setImages(product?.images ?? getValues()?.images ?? [] );
        }else{ 
            setImages(useDuplicate ? addEditItemDuplicate?.images ?? [] : images);
        }
        setOptions(getValues()?.options ?? product?.options ?? []);
        form.resetFields();
        form.setFieldsValue(values);

        if (location?.state?.duplicate) {
            stableOnDuplicateProduct();
        }
    }, [getValues, product, form, location, stableOnDuplicateProduct]);

    useEffect(() => {
        if (!location.pathname.includes("addEditItem")) {
            setTouched(false);
            form.resetFields();
        }
    }, [location, form]);

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current && showForm()) initialRender.current = false;
        else {
            setTouched(true);
        }
    }, [options, images, showForm]);

    const compareOriginal = () => {
        const original = _.cloneDeep(originalValues);
        const newValues = _.cloneDeep(values);
        const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];
        let bool = true;

        //checks though the objects and see if they are equal
        Object.entries(newValues).forEach(([k, v]) => {
            bool =
                bool &&
                (_.isEqual(original[k], v) ||
                    (_.isEmpty(v?.toString()) && _.isEmpty(original[k]?.toString())) ||
                    Number(original[k]) == Number(v));
        });

        dks.forEach((dk) => {
            if (original[dk] && moment(original[dk]).format(INPUT_DATE_FORMAT) !== "Invalid date") {
                original[dk] = moment(original[dk]).format(INPUT_DATE_FORMAT);
            } else {
                original[dk] = "";
            }

            if (newValues[dk] && moment(newValues[dk]).format(INPUT_DATE_FORMAT) !== "Invalid date") {
                newValues[dk] = moment(newValues[dk]).format(INPUT_DATE_FORMAT);
            } else {
                newValues[dk] = "";
            }
        });
        original.images = product?.images ?? [];
        newValues.images = images ?? [];
        
        const originalImagesShort = _.chain(original.images)
                                     .map((img) => {return _.last(_.split(img, '/'))})
                                     .value();
        const newImagesShort = _.chain(newValues.images)
                                     .map((img) => {return _.first(_.split(_.last(_.split(img, '/')), '.'))})
                                     .value();
        const areImagesEqual = _.isEqual(originalImagesShort, newImagesShort);

        original.options = product?.options ?? [];
        newValues.options = options ?? [];
       
        const hasNewOptionValues = !_.isEmpty(mproducts?.addEditItemOptionDuplicate);
        const hasDuplicateOptions = hasNewOptionValues
            ? _.isEqual(mproducts?.addEditItemOptionDuplicate, ...original.options)
            : _.isEqual(original.options, newValues.options);

        return bool && areImagesEqual && hasDuplicateOptions;
    };

    return {
        compareOriginal,
        history,
        intl,
        QRCode,
        QrCode,
        tab,
        form,
        values,
        setValues,
        submitting,
        showQR,
        setShowQR,
        shareModal,
        product,
        gid,
        user,
        lan,
        products,
        lastSearch,
        languages,
        touched,
        setTouched,
        originalValues,
        setOriginalValues,
        storeLogoUrl,
        options,
        setOptions,
        getValues,
        onDuplicateProduct,
        handleValuesChanged,
        onSaveAndContinue,
        onFinish,
        onChangeTab,
        openWeChatQRModal,
        copyProduct,
        getLastDisabled,
        getNextDisabled,
        getFirst,
        getLast,
        getNext,
        getPrev,
        onDeleteProduct,
        pid,
        clearForm,
        showForm,
        images,
        setImages,
        getDuplicateModal,
        showPoster,
        setShowPoster,
        openPosterModal,
        barcodeErrorMessage,
        setBarcodeErrorMessage,
    };
}

export default useAddEditProductPage;
