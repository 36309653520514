import React from "react";
import { Collapse, InputNumber, Radio } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { CodeStyle } from "./format-common/code-style";
import sH from "../helper";
import { SwitchDesc } from "./format-common/switch-desc";

interface FormatPropertiesProps {
    qrStyle: string | any;
    setQRStyle: (type: any) => any;
    addMargin: boolean;
    setAddMargin: (type: any) => any;
    bleedingMargin: number;
    setBleedingMargin: (type: any) => any;
    paperSize: string;
    setPaperSize: (type: any) => any;
    bgColor: string;
    setBgColor: (type: any) => any;
    switchOnChange: (value: number | any, name: string, local?: (type: any) => any | any) => any | null;
}

export const FormatProperties = (props: FormatPropertiesProps): JSX.Element => {
    const intl = useIntl();
    const {
        qrStyle,
        setQRStyle,
        addMargin,
        setAddMargin,
        bleedingMargin,
        setBleedingMargin,
        paperSize,
        setPaperSize,
        bgColor,
        setBgColor,
        switchOnChange,
    } = props;

    const renderPaperstyle = () => (
        <div className="d-flex mb-3">
            <div className="store_name-title mr-3">
                <FormattedMessage id="paper_size" />:
            </div>
            <Radio.Group onChange={(e: any) => setPaperSize(e.target.value)} value={paperSize}>
                <Radio value={sH.paperSizes.A4}>
                    <FormattedMessage id="a4" />
                </Radio>
                <Radio value={sH.paperSizes.A3}>
                    <FormattedMessage id="a3" />
                </Radio>
                <Radio value={sH.paperSizes.LEGAL}>
                    <FormattedMessage id="legal" />
                </Radio>
            </Radio.Group>
        </div>
    );

    const renderAddMargin = () => (
        <div className="d-flex mb-1 align-items-center">
            <SwitchDesc
                description={"add_margin_between_qrs"}
                value={addMargin}
                name=""
                local={setAddMargin}
                switchOnChange={switchOnChange}
            />
        </div>
    );

    const renderAddBleed = () => (
        <div className="d-flex mb-3 align-items-center">
            <div className="store_name-title mr-3">
                <FormattedMessage id="bleeding_margin" />:
            </div>
            <InputNumber value={bleedingMargin} onChange={(e: any) => setBleedingMargin(parseInt(e))} />
        </div>
    );

    const getColorOptions = () => [
        sH.bgColours.TURQUOISE,
        sH.bgColours.BLUE,
        sH.bgColours.GREEN,
        sH.bgColours.PURPLE,
        sH.bgColours.ORANGE,
    ];

    const renderColorSquare = (color: string) => (
        <>
            <div className="colorSquare" style={{ background: color }} />
            <Radio value={color} />
        </>
    );

    const renderColorSquares = (color: any) => (
        <div className="d-flex flex-column" key={color}>
            {renderColorSquare(color)}
        </div>
    );

    const renderColorOptions = () => (
        <div className="d-flex mb-3 align-items-center">
            <div className="store_name-title mr-3">
                <FormattedMessage id="background_colour" />:
            </div>
            <Radio.Group className="d-flex" onChange={(e: any) => setBgColor(e.target.value)} value={bgColor}>
                {getColorOptions().map(renderColorSquares)}
            </Radio.Group>
        </div>
    );

    return (
        <div className="white-layered-block d-flex flex-row justify-content-start align-items-start">
            <div className="serve-bottom-left-section">
                <div className="store_name-title">
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
                        <Collapse.Panel header={intl.formatMessage({ id: "style_settings" })} key="2">
                            <CodeStyle qrStyle={qrStyle} setQRStyle={setQRStyle} />
                            {renderPaperstyle()}
                            {renderColorOptions()}
                            {renderAddMargin()}
                            {renderAddBleed()}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};
