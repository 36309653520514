import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../app/reducer";
import { renderPasswordInput } from "../admin-users/_components/account-modal/account-sections";
import { setPassword } from "../../slices/admin-users";
import Wrapper from "../../components/wrapper";
import Logo from "../../components/logo";
import { api } from "../../util/api";
import { useLocation } from "react-router-dom";
import { getIntl } from "../../locale";
import { useHistory } from "react-router";
import "../forgot/forgot.scss";
import { minPasswordLength } from "../forgot/constants";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const newPassword = useSelector((state: RootState) => state.adminUsers.password);
    const [passView, setPassView] = useState<boolean>(true);
    const passwordHandler = (name: string, password: any) => {
        dispatch(setPassword({ password }));
    };
    const search = useLocation().search;
    const history = useHistory();
    const intl = getIntl();

    function resetPassword() {
        const id = new URLSearchParams(search).get("id");
        const key = new URLSearchParams(search).get("key");
        if (newPassword.length < minPasswordLength) {
            Modal.error({
                title: intl.formatMessage({ id: "error" }),
                content: intl.formatMessage({ id: "password_length_check" }),
                okText: intl.formatMessage({ id: "ok" }),
                cancelText: "",
            });
            return;
        }

        api("CUSTOMER_RESET_PASSWORD")({
            method: "POST",
            body: JSON.stringify({
                password: newPassword,
                id: id,
                key: key,
            }),
        }).then((response) => {
            const RC: any = response?.RC;
            if (RC && Number(RC) === 200) {
                Modal.info({
                    title: intl.formatMessage({ id: "password_changed" }),
                    okText: intl.formatMessage({ id: "ok" }),
                    cancelText: "",
                    onOk() {
                        history.push("/");
                    },
                });
            } else {
                Modal.error({
                    title: intl.formatMessage({ id: "error" }),
                    okText: intl.formatMessage({ id: "ok" }),
                    cancelText: "",
                    content: response?.MSG,
                });
            }
        });
    }

    const enterNewPasswordContainer = () => (
        <div className="white-single-block forgot-password-reset-container codes-container flex-column">
            {intl.formatMessage({ id: "please_enter_new_password" })}
            {renderPasswordInput("password", "password", newPassword, passwordHandler, passView, setPassView, "100%")}
            <Button
                type="primary"
                htmlType="submit"
                className="save-button-handler w-100"
                style={{ height: "42px" }}
                onClick={() => resetPassword()}
            >
                <FormattedMessage id="submit" />
            </Button>
        </div>
    );

    return (
        <Wrapper helmet={{ title: "Login" }} public={true}>
            <div className="login-page">
                <div className="login-page-container">
                    <Logo />
                </div>
                {enterNewPasswordContainer()}
                <div>
                    <Button type="link" onClick={() => history.push("/signup")}>
                        <FormattedMessage id="signup_instead" />
                    </Button>
                    <Button type="link" onClick={() => history.push("/")}>
                        <FormattedMessage id="signin_instead" />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default App;
