import { ROLE_ID } from "./../../config/config.json";
import h from "util/helper";
import _ from "lodash";

/* Permission Setting Indicies */
const DASHBOARD = 0;
const ORDER_HISTORY = 1;
const LIVE_ORDER = 2;
const PRODUCT_AVAILABILITY = 3;
const STORE_AVAILABILITY = 4;
const MENU_SETTINGS = 5;
const GENERAL_INFO_SETTINGS = 6;
const PICKUP_DELIVERY_SETTINGS = 7;
const SELF_SERVE_SETTINGS = 8;
const HOURS_SETTINGS = 9;
const DISCOUNT_SETTINGS = 10;
const MANAGED_STORES_SETTINGS = 11;
const ORDER_NOTIFICATION_SETTINGS = 12;
const PAYMENT_SETTINGS = 13;
const SURCHARGE_SETTINGS = 14;
const ITEM_AVAILABILITY_SETTINGS = 15;
const SWITCH_STORE_SETTINGS = 16;
const CATEGORY_MANAGEMENT = 17;
const PRODUCT_MANAGEMENT = 18;
const COUPON_MANAGEMENT = 19;
const GIFTCARD_MANAGEMENT = 20;
const CUSTOMER = 21;
const PUBLISH = 22;
const PRODUCT_REVIEW = 23;
const CUSTOMER_REVIEW = 24;
const REPORT = 25;
const ADMIN_USERS = 26;
const ORDER_EXPORT = 27;
const USER_PROFILE = 28;
const CREATE_STORE = 29;
const DISPLAY_SETTINGS = 30;
const ROLES_PERMISSIONS_SETTINGS = 31;
const TIPS_SETTINGS = 32;
const NOTIFICATION_SETTINGS = 33;
const STORE_INFO = 34;
const LOCAL_DELIVERY = 35;
const ORDER_REVIEW = 36;
const PUBLIC_NOTICE = 37;
const BUSINESS_HOURS = 38;
const REFERRAL_PAGE = 39;
const CREATE_ORDER = 40;
/* Permission Setting Indicies */

const VERIFY_CODE = 5;
const SEND_VERIFICATION_CODE = 1;

const DEFAULT_ROLES_NUM = 4;

const NO_ACCESS = 0;
const READ_ONLY = 1;
const READ_WRITE = 2;

const MIN_PASSWORD = 6;
const MIN_USERNAME = 5;

// MODAL TYPES
const ADD_ADMIN_USER = 2;
const ADMIN_PROFILE = 3;

const AREA_CODES: any = {
    canada_phone: "1",
    usa_phone: "1",
};

const MANAGER_ROLES: any[] = [ROLE_ID.SUPER_USER, ROLE_ID.FRANCHISE_MANAGER, ROLE_ID.STORE_MANAGER];

const PHONE_RC_MESSAGE_MAP: any = {
    204: "phone_not_registered",
    408: "vc_expired",
    429: "too_many_vc_requests",
    452: "admin_already_exists",
};

const getAreaCode = (user: any = {}, ifUndefined: number | string | undefined): number | string | undefined =>
    _.get(user, "phone.area_code", ifUndefined);

const getAreaCodeStr = (user: any = {}): string =>
    getAreaCode(user, undefined) ? `+${getAreaCode(user, undefined)}` : " ";

const isActive = (active: number): boolean => (active === 1 || active ? true : false);

/**
 * Check if the role is a Super User or Franchise Manager.
 *
 * @param role - User's role.
 * @returns True if the user's role is Super User of Franchise Manager.
 */
const isSuperManagerRole = (role: any = {}): boolean => {
    return Number(role) === ROLE_ID.FRANCHISE_MANAGER || h.isSuperUserRole(role);
};

/**
 * Check if the user is a Super User or Franchise Manager.
 *
 * @param user - user.
 * @returns True if the user's role is Super User of Franchise Manager.
 */
const isSuperManagerUser = (user: any = {}): any => {
    return user?.roles?.includes(ROLE_ID.FRANCHISE_MANAGER) || h.isSuperUser(user);
};

/**
 * Determine if the role is a Super User, Franchise Manager, or
 * Store Manager.
 *
 * @param role - User's role.
 * @returns True if the user's role is Super User, Franchise Manager,
 *          or Store Manager.
 */
const isManagerRole = (role: any = {}): boolean => {
    return (
        Number(role) === ROLE_ID.STORE_MANAGER || Number(role) === ROLE_ID.FRANCHISE_MANAGER || h.isSuperUserRole(role)
    );
};

/**
 * Determine if the user is is a Super User, Franchise Manager, or
 * Store Manager.
 *
 * @param user - user.
 * @returns True if the user is Super User, Franchise Manager,
 *          or Store Manager.
 */
const isManagerUser = (user: any = {}): boolean => {
    return (
        user?.roles?.includes(ROLE_ID.STORE_MANAGER) ||
        user?.roles?.includes(ROLE_ID.FRANCHISE_MANAGER) ||
        h.isSuperUser(user)
    );
};

const permFlagsToString = (flags: any = []): string => {
    let flagStr = "";
    for (let i = 0; i < flags.length; i++) {
        flagStr += `${flags?.[i]?.toString() || "0"}:-1${i === Number(flags?.length) - 1 ? "" : ","}`;
    }
    return flagStr;
};

/**
 * Each flag has two parts:
 * - The first part is for permission control (0 not allowed; 1 read only; 2 writable);
 * - The second part defines how old records user can check (e.g. 24 means user can
 *   only view records created in 24 hours).
 *
 * @see Permission Setting Indicies
 *
 * @param user - User whose permissions are being checked.
 * @param currUserRoles - current user's roles.
 * @param permIndex - Permission's access flag index.
 * @returns User's permission for the specified permission access flag.
 */
const getPermission = (user: any = {}, permIndex: number): number => {
    if (h.isSuperUser(user)) return READ_WRITE;
    const accessFlagsStr = _.get(user, "access", null) || _.get(user, "access_flags", "");
    const permissions = accessFlagsStr.split(",").map((flagStr: string) => {
        const permParts = flagStr.split(":");
        const perm =
            permParts.length > 0 &&
            permParts[0].trim() !== "" &&
            Number(permParts[0].trim()) >= NO_ACCESS &&
            Number(permParts[0].trim()) <= READ_WRITE
                ? Number(permParts[0].trim())
                : NO_ACCESS;
        return perm;
    });
    return permIndex >= 0 && permIndex < permissions.length ? permissions[permIndex] : NO_ACCESS;
};

/**
 * Get the greatest manager roles of a user.
 *
 * @param userRoles - Array of user's roles.
 * @returns Sorted manager roles of the user.
 */
const getGreatestRoles = (userRoles: number[]): number[] => {
    return userRoles
        .filter((r: number) => MANAGER_ROLES.includes(r))
        .map((r: number) => MANAGER_ROLES.indexOf(r))
        .sort();
};

/**
 * Check if the current user's highest role is greater
 * than the selected user's highest role.
 *
 * @param currUserRoles - Roles of the current admin user.
 * @param selectedUserRoles - Roles of the selected admin user.
 * @returns True if the current user has a greater role
 *          as the selected user; false otherwise.
 */
const hasGreaterRoles = (currUserRoles: number[], selectedUserRoles: number[]): boolean => {
    // Check current admin user roles
    const currUserGreatestRoles = getGreatestRoles(currUserRoles);
    if (currUserGreatestRoles.length === 0) {
        return false;
    }

    // Check selected admin user roles
    const selectedUserGreatestRoles = getGreatestRoles(selectedUserRoles);
    if (selectedUserGreatestRoles.length === 0) {
        return true;
    }

    return currUserGreatestRoles[0] < selectedUserGreatestRoles[0];
};

/**
 * Check if current user's highest role is at least the same or greater
 * than the selected user's highest role.
 *
 * @param currUserRoles - Roles of the current admin user.
 * @param selectedUserRoles - Roles of the selected admin user.
 * @returns True if the current user has at least the same or greater role
 *          as the selected user; false otherwise.
 */
const hasSameOrGreaterRole = (currUserRoles: number[], selectedUserRoles: number[]): boolean => {
    // Check current admin user roles
    const currUserGreatestRoles = getGreatestRoles(currUserRoles);
    if (currUserGreatestRoles.length === 0) {
        return false;
    }

    // Check selected admin user roles
    const selectedUserGreatestRoles = getGreatestRoles(selectedUserRoles);
    if (selectedUserGreatestRoles.length === 0) {
        return true;
    }

    return currUserGreatestRoles[0] <= selectedUserGreatestRoles[0];
};

export default {
    getAreaCode,
    permFlagsToString,
    getAreaCodeStr,
    isActive,
    isSuperManagerRole,
    isSuperManagerUser,
    getPermission,
    hasGreaterRoles,
    hasSameOrGreaterRole,
    isManagerRole,
    isManagerUser,
    MIN_PASSWORD,
    MIN_USERNAME,
    ADD_ADMIN_USER,
    ADMIN_PROFILE,
    PHONE_RC_MESSAGE_MAP,
    AREA_CODES,
    VERIFY_CODE,
    SEND_VERIFICATION_CODE,
    DEFAULT_ROLES_NUM,
    NO_ACCESS,
    READ_ONLY,
    READ_WRITE,
    DASHBOARD,
    ORDER_HISTORY,
    LIVE_ORDER,
    LOCAL_DELIVERY,
    ORDER_REVIEW,
    PRODUCT_AVAILABILITY,
    STORE_AVAILABILITY,
    MENU_SETTINGS,
    GENERAL_INFO_SETTINGS,
    PICKUP_DELIVERY_SETTINGS,
    SELF_SERVE_SETTINGS,
    HOURS_SETTINGS,
    DISCOUNT_SETTINGS,
    MANAGED_STORES_SETTINGS,
    ORDER_NOTIFICATION_SETTINGS,
    PAYMENT_SETTINGS,
    SURCHARGE_SETTINGS,
    ITEM_AVAILABILITY_SETTINGS,
    SWITCH_STORE_SETTINGS,
    CATEGORY_MANAGEMENT,
    PRODUCT_MANAGEMENT,
    COUPON_MANAGEMENT,
    GIFTCARD_MANAGEMENT,
    CUSTOMER,
    PUBLISH,
    PRODUCT_REVIEW,
    CUSTOMER_REVIEW,
    REPORT,
    ADMIN_USERS,
    ORDER_EXPORT,
    USER_PROFILE,
    CREATE_STORE,
    DISPLAY_SETTINGS,
    ROLES_PERMISSIONS_SETTINGS,
    TIPS_SETTINGS,
    NOTIFICATION_SETTINGS,
    STORE_INFO,
    PUBLIC_NOTICE,
    BUSINESS_HOURS,
    REFERRAL_PAGE,
    CREATE_ORDER,
};
