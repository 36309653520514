import { Button } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import {
    MessageModalPages,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setShouldMessageModalOpen,
    setShouldModalOpen,
    setUpdateForm,
    TaskModalPages,
    updateOnActiveTask,
} from "slices/delivery_request";
import {
    ACTIONS_MAPPING,
    AddBalanceRequest,
    addBalanceToDeliveryTask,
    addNewCustomerAddress,
    CreateAddressRequest,
    CreateDeliveryParam,
    createDeliveryTask,
    requestTaskType,
    UpdateAddressRequest,
    updateDeliveryAddress,
} from "../../../../services/delivery_request";
import { DoordashSetting } from "../../../../types/doordash-setting";
import { MANDATORY_FIELDS_MAPPING } from "../../constants";
import {
    buildUpdatedOnActivedTask,
    closeDialogActions,
    formatPrice,
    getNestedValueInObject,
    timeSeperator,
} from "../helper";

interface Props {}

interface ModalFooterLayoutProps extends Props, PropsFromRedux {}

const ModalFooterLayout: React.FC<ModalFooterLayoutProps> = ({
    // state
    currPage,
    newForm,
    addressInfo,
    balanceInfo,
    onActiveTask,
    formValidation,
    inputRefs,
    totalDelieryFee,
    isLoading,
    newTaskModalCurrPage,
    updatedTask,
    deliveryFeeValue,
    newTaskState,
    defaultDeliverySettings,
    // setter
    setCurrentModalPage,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    updateOnActiveTask,
    setUpdateForm,
    setShouldModalOpen,
    //api
    createDeliveryTask,
    addNewCustomerAddress,
    updateDeliveryAddress,
    addBalanceToDeliveryTask,
    // actions
    closeDialogActions,
}) => {
    const intl = useIntl();
    const { isValid, missingField } = formValidation;
    const inputLabel = intl.formatMessage({ id: "input" });
    const missingFieldId = _.isEmpty(missingField) ? " " : missingField;
    const missingFieldLabel = intl.formatMessage({ id: missingFieldId });

    /**
     * onclick handler
     */
    const handleCancel = () => {
        closeDialogActions(newTaskModalCurrPage, newTaskState, onActiveTask, updatedTask, defaultDeliverySettings);
    };

    //
    const handleOk = () => {
        setCurrentModalPage(TaskModalPages.NEW_TASK_MAIN);
    };

    const handleSubmitNewDeliveryRequest = () => {
        const { orderId, isPickUpAddress, address, pickUp, dropOff, additionalInfo } = newForm;
        const { itemsInfo, itemsCategory, orderValue, tipAmount, containsAlcohol, orderTax } = additionalInfo;
        const [pickup_window_start_time, pickup_window_end_time] = timeSeperator(pickUp.date, pickUp.time);
        const [delivery_window_start_time, delivery_window_end_time] = timeSeperator(dropOff.date, dropOff.time);
        const body: CreateDeliveryParam = {
            action: ACTIONS_MAPPING.CREATE_WITHOUT_GOOPTER_ORDER,
            request_type: requestTaskType.WITHOUT_GOOPTER,
            order_value: orderValue,
            item_category: itemsCategory,
            contains_alcohol: containsAlcohol ? 1 : 0,
            items: itemsInfo ?? undefined,
            tip: tipAmount ?? undefined,
            order_number: orderId ?? undefined,
            tax: orderTax ?? undefined,
            pickup_window_start_time,
            pickup_window_end_time,
            delivery_window_start_time,
            delivery_window_end_time,
        };
        if (isPickUpAddress) body.pickup_address_id = address;
        else body.dropoff_address_id = address;
        createDeliveryTask(body);
    };

    const handleSubmitAddBalancerRequest : () => void = () =>{
        const { amount , balanceType } = balanceInfo;
        const finalAmount = amount??0 * (balanceType == 'C' ? 1 :-1);
        const body : AddBalanceRequest = {
            action: ACTIONS_MAPPING.MAKE_PAYMENT,
            amount: finalAmount,
        }
        addBalanceToDeliveryTask(body);
    }

    const handleSubmitNewAddress = () => {
        const req: CreateAddressRequest["req"] = {
            id: addressInfo.id ?? undefined,
            firstname: addressInfo.firstName,
            lastname: addressInfo.lastName,
            phone: addressInfo.phone,
            country_code: addressInfo.countryCode,
            email: addressInfo.email,
            street: addressInfo.street,
            city: addressInfo.city,
            region: addressInfo.region,
            zip_code: addressInfo.zipCode,
            // optional as below
            buzz: addressInfo.buzzCode,
            unit: addressInfo.addressLineTwo,
            delivery_option: addressInfo.deliveryOptions ?? undefined,
            delivery_instructions: addressInfo.deliveryRemark,
            business_name: addressInfo.componentName,
        };
        addNewCustomerAddress({ currPage, req });
    };

    const handleEditAddress = () => {
        if (onActiveTask && currPage !== TaskModalPages.EDIT_ADDRESS_NEW_TASK) {
            const data: UpdateAddressRequest["data"] = {
                id: addressInfo.id ?? undefined, // provide address_id if the request is to update the address. If not provided, the request would create a new address
                customer_id: addressInfo.customerId ?? undefined, //if the customer id is provided, then should verify this id, avoid creating customer record;
                firstname: addressInfo.firstName,
                lastname: addressInfo.lastName,
                phone: addressInfo.phone,
                country_code: addressInfo.countryCode,
                email: addressInfo.email,
                unit: addressInfo.addressLineTwo,
                buzz: addressInfo.buzzCode,
                city: addressInfo.city,
                street: addressInfo.street,
                zip_code: addressInfo.zipCode,
                delivery_option: addressInfo.deliveryOptions,
                delivery_instructions: addressInfo.deliveryRemark,
                business_name: addressInfo.componentName,
                region: addressInfo.region,
            };
            updateDeliveryAddress({ data });
        } else {
            handleSubmitNewAddress();
        }
    };

    const handleNotValidatedOnClick = () => {
        switch (missingField) {
            case MANDATORY_FIELDS_MAPPING.address:
                if (TaskModalPages.NEW_TASK_MAIN === currPage) {
                    if (newForm.isPickUpAddress) {
                        inputRefs["pickup.address"]?.current?.focus?.();
                    } else {
                        inputRefs["dropoff.address"]?.current?.focus?.();
                    }
                } else {
                    inputRefs["address info street"]?.current?.focus?.();
                }
                break;

            case MANDATORY_FIELDS_MAPPING.order_id:
                inputRefs[missingField as unknown as keyof typeof inputRefs]?.current?.focus?.();
                break;

            case "additionalInfo.itemsInfo":
                setCurrentModalPage(TaskModalPages.NEW_TASK_ITEMS);
                break;

            case "zipCode":
                inputRefs["address info zip code"]?.current?.focus?.();
                break;

            case "dropOff.time":
                setShouldMessageModalOpen(true);
                setCurrentMessageModalPage(MessageModalPages.DROPOFF_TIME_PICKER);
                break;
            case "pickUp.time":
                setShouldMessageModalOpen(true);
                setCurrentMessageModalPage(MessageModalPages.PICKUP_TIME_PICKER);
                break;
            case MANDATORY_FIELDS_MAPPING.amount:
                setShouldMessageModalOpen(true);
               // setCurrentMessageModalPage(MessageModalPages.ADD_BALANCE_AMOUNT);
               break;

            default:
                inputRefs[missingField as unknown as keyof typeof inputRefs]?.current?.focus?.();
                break;
        }
    };

    const handleDeliveryFeeDetailClick = () => {
        setCurrentMessageModalPage(MessageModalPages.DELIVERY_FEE_DETAIL);
        setShouldMessageModalOpen(true);
    };

    const handleUpdateOrderId = () => {
        const key = "orderId";
        const value = updatedTask.orderId ?? "";
        setUpdateForm({ key, value });
        const t = buildUpdatedOnActivedTask(onActiveTask, key, value);
        if (t) updateOnActiveTask(t);
        setShouldModalOpen(false);
    };

    const newTaskMainFooter = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ paddingLeft: "10px", cursor: "pointer" }} onClick={handleDeliveryFeeDetailClick}>
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {intl.formatMessage({ id: "delivery_fee_total" })}
                    </div>
                    <div
                        style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            fontSize: "20px",
                            color: "#40a9ff",
                            display: "flex",
                            alignItems: "base",
                        }}
                    >
                        {formatPrice(
                            getNestedValueInObject(
                                { totalDelieryFee: Number(deliveryFeeValue) > 0 ? totalDelieryFee : null },
                                intl.formatMessage({ id: "TBD" }),
                                "totalDelieryFee"
                            )
                        )}
                        <div style={{ marginLeft: "5px" }}>
                            <AiFillInfoCircle style={{ color: "#40a9ff", width: "20px", height: "20px" }} />
                        </div>
                    </div>
                </div>
                <div>
                    {renderFooterButtons([
                        {
                            name: "cancel",
                            onClick: handleCancel,
                            className: "outlined-blue-button",
                            label: intl.formatMessage({ id: "cancel" }),
                        },
                        {
                            name: "submit",
                            className: "blue-button",
                            disabled: isLoading,
                            onClick: isValid ? handleSubmitNewDeliveryRequest : handleNotValidatedOnClick,
                            label: isValid
                                ? intl.formatMessage({ id: "submit" })
                                : `${inputLabel} ${missingFieldLabel}`,
                        },
                    ])}
                </div>
            </div>
        );
    };

    const newTaskOrderFooter = () => {
        return renderFooterButtons([
            { name: "cancel", onClick: handleCancel, label: intl.formatMessage({ id: "cancel" }) },
            { name: "ok", onClick: handleOk, label: intl.formatMessage({ id: "ok" }) },
        ]);
    };
    const newTaskItemsFooter = () => {
        return renderFooterButtons([
            { name: "cancel", onClick: handleCancel, label: intl.formatMessage({ id: "cancel" }) },
            { name: "ok", onClick: handleOk, label: intl.formatMessage({ id: "ok" }) },
        ]);
    };

    const newTaskAddressFooter = () => {
        return renderFooterButtons([
            {
                name: "cancel",
                onClick: handleOk,
                className: "outlined-blue-button",
                label: intl.formatMessage({ id: "cancel" }),
            },
            {
                name: "save",
                className: "blue-button",
                disabled: isLoading,
                onClick: isValid ? handleSubmitNewAddress : handleNotValidatedOnClick,
                label: isValid ? intl.formatMessage({ id: "save" }) : `${inputLabel} ${missingFieldLabel}`,
            },
        ]);
    };

    const addBalanceFooter = () => {
        return renderFooterButtons([
            {
                name: "cancel",
                onClick: handleCancel,
                className: "outlined-blue-button",
                label: intl.formatMessage({ id: "cancel" }),
            },
            {
                name: "confirm",
                className: "blue-button",
                disabled: isLoading,
                onClick:  isValid? handleSubmitAddBalancerRequest : handleNotValidatedOnClick,
                label: isValid ? intl.formatMessage({ id: "confirm" }) : `${inputLabel} ${missingFieldLabel}`,
            },
        ]);
    };

    const editAddressFooter = () => {
        return renderFooterButtons([
            { name: "cancel", onClick: handleCancel, label: intl.formatMessage({ id: "cancel" }) },
            {
                disabled: isLoading,
                name: "save",
                onClick: isValid ? handleEditAddress : handleNotValidatedOnClick,
                label: isValid ? intl.formatMessage({ id: "save" }) : `${inputLabel} ${missingFieldLabel}`,
            },
        ]);
    };

    const editTaskOrderId = () => {
        return renderFooterButtons([
            { name: "cancel", onClick: handleCancel, label: intl.formatMessage({ id: "cancel" }) },
            { name: "ok", onClick: handleUpdateOrderId, label: intl.formatMessage({ id: "ok" }) },
        ]);
    };

    const readOnlyFooter = () => {
        return renderFooterButtons([{ name: "ok", onClick: handleCancel, label: intl.formatMessage({ id: "ok" }) }]);
    };

    /**
     * layout renderer
     */

    interface ButtonProps {
        name: string;
        onClick: () => void;
        label: string | React.ReactNode;
        disabled?: boolean;
        className?: string;
    }

    const renderFooterButtons = (buttons: ButtonProps[]) => {
        return (
            <div>
                {buttons.map((button, index) => {
                    return (
                        <Button
                            className={button.className}
                            onClick={button.onClick}
                            disabled={button.disabled}
                            key={button.name + index}
                        >
                            {button.label}
                        </Button>
                    );
                })}
            </div>
        );
    };

    const getLayout = (): React.ReactElement => {
        switch (currPage) {
            case TaskModalPages.EDIT_ORDER_ID:
                return editTaskOrderId();
            case TaskModalPages.NEW_TASK_MAIN:
                return newTaskMainFooter();
            case TaskModalPages.NEW_TASK_ORDER:
                return newTaskOrderFooter();
            case TaskModalPages.NEW_TASK_ITEMS:
                return newTaskItemsFooter();
            case TaskModalPages.EDIT_ADDRESS:
            case TaskModalPages.EDIT_ADDRESS_NEW_TASK:
                return editAddressFooter();
            case TaskModalPages.NEW_TASK_ADDRESS:
                return newTaskAddressFooter();
            case TaskModalPages.ADD_BALANCE:
                return addBalanceFooter();
            case TaskModalPages.READ_ONLY_ADDRESS:
                return readOnlyFooter();

            default:
                return <></>;
        }
    };

    return <>{getLayout()}</>;
};

const mapStateToProps = (state: RootState) => {
    const deliveryRequestsState = state.deliveryRequests;
    const deliveryFeeValue = state.deliveryRequests.forms.newTask.deliveryFee;
    const additionalInfo = state.deliveryRequests.forms.newTask.additionalInfo;
    const { orderTax, tipAmount } = additionalInfo;
    const totalDelieryFee = Number(orderTax) + Number(deliveryFeeValue) + Number(tipAmount);
    return {
        currPage: deliveryRequestsState.modal.newTaskModal.currentPage,
        newForm: deliveryRequestsState.forms.newTask,
        formValidation: deliveryRequestsState.forms.validation,
        addressInfo: deliveryRequestsState.forms.addressInfo,
        balanceInfo: deliveryRequestsState.forms.balanceInfo,
        onActiveTask: deliveryRequestsState.onActiveTask,
        newTaskState: deliveryRequestsState.forms.newTask,
        inputRefs: deliveryRequestsState.inputRefs,
        totalDelieryFee,
        deliveryFeeValue,
        isLoading: deliveryRequestsState.isLoading,
        newTaskModalCurrPage: deliveryRequestsState.modal.newTaskModal.currentPage,
        updatedTask: deliveryRequestsState.forms.updateTask,
        defaultDeliverySettings: (state.store.storeThirdPartyDelivery.doordash ?? {}) as DoordashSetting,
    };
};

const mapDispatchToProps = {
    //setter
    setCurrentModalPage,
    setShouldModalOpen,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    updateOnActiveTask,
    setUpdateForm,
    //api
    createDeliveryTask,
    addNewCustomerAddress,
    updateDeliveryAddress,
    addBalanceToDeliveryTask,
    // actions
    closeDialogActions,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(ModalFooterLayout);
