import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Wrapper from "../../components/wrapper";
import "./index.scss";
import useDashboard from "./useDashboard";
import DashboardActionBar from "./_components/dashboard-action-bar";
import DashboardData from "./_components/dashboard-data";
import DashboardGraph from "./_components/dashboard-graph";
import { HiHome } from "react-icons/hi";
import { PermissionFeatures, usePermission } from "hooks/usePermission";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
    ],
};

function App() {
    const { hasAccess } = usePermission();

    useDashboard();
    return (
        <Wrapper helmet={{ title: "dashboard" }} breadcrumb={breadcrumb}>
            <div className="dashboard-page">
                {hasAccess(PermissionFeatures.Dashboard) ? (
                    <>
                        <DashboardActionBar />
                        <DashboardGraph />
                        <DashboardData />
                    </>
                ) : (
                    <div className="inactive-empty-warn w100">
                        <HiHome size="60" color="lightgrey" className="mb-2" />
                        <FormattedMessage id="no_authorization" />
                    </div>
                )}
            </div>
        </Wrapper>
    );
}

export default injectIntl(App);
