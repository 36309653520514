import React from "react";
import helper from "../../../../util/helper";
import config from "../../.../../../../config";
import { FormattedMessage } from "react-intl";
import Format1 from "./qr-formats/format1";
import Format2 from "./qr-formats/format2";
import Format3 from "./qr-formats/format3";
import Format4 from "./qr-formats/format4";
import Format5 from "./qr-formats/format5";
import Format6 from "./qr-formats/format6";
import Format7 from "./qr-formats/format7";
import Format8 from "./qr-formats/format8";
import Format9 from "./qr-formats/format9";
import Format10 from "./qr-formats/format10";
import Format11 from "./qr-formats/format11";
import Format12 from "./qr-formats/format12";
import Format13 from "./qr-formats/format13";
import { QRCode } from "react-qrcode-logo";
import PaymentLogos from "../../../../images/icons/payment-icon.svg";
import GoopterLogo from "../../../../images/icons/goopter-logo.svg";
import "./../../index.scss";
import PaymentCont from "./_components/payment-cont/index";

const renderQRCode = (
    tableNum: any,
    gid: any,
    exclude: any,
    qrStyle: any,
    logoImage: string,
    codeType: any,
    qrSize: any,
    qrWidth: any,
    qrHeight: any
) => {
    let QRUrl = `${config.H5_URL}store/${gid}`;
    QRUrl = exclude ? QRUrl : QRUrl + `?lid=${tableNum}&tid=${codeType}`;
    return (
        <QRCode
            value={QRUrl}
            size={qrSize}
            quietZone={0}
            logoWidth={qrWidth}
            logoHeight={qrHeight}
            logoImage={logoImage}
            qrStyle={qrStyle ?? "squares"}
        />
    );
};

const defaultFormatProps = {
    qrSize: 119,
    qrHeight: 40,
    qrWidth: 40,
};

const goopterCont = (props: any) => {
    return (
        <div
            className="goopter-container"
            style={{ backgroundColor: props.dark ? "#504F4F" : "white", color: props.dark ? "white" : "#626161" }}
        >
            <FormattedMessage id="powered_by" />
            <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
        </div>
    );
};

const goopterContDark = (props: any) => {
    return (
        <div
            className="goopter-container"
            style={{
                backgroundColor: props.customdark ? "#504F4F" : "white",
                color: props.customdark ? "white" : "#626161",
            }}
        >
            <FormattedMessage id="powered_by" />
            <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
        </div>
    );
};

const getTableNumFontSize = (tableNum: string) => {
    switch (tableNum.length) {
        case 1:
            return 48;
        case 2:
            return 42;
        case 3:
            return 36;
        default:
            return 28;
    }
};

// 5 cm wide qr code with table num and scan & order first
export const renderFormat1 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const { g_id, exclude, startNum, dark, logoImage, bgColor, qrStyle, codeType, qrSize, qrHeight, qrWidth } = params;
    return (
        <div className="format">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block">
                    <div className="tableNum-cont">
                        <div
                            className="tableNum-holder"
                            style={{
                                color: dark ? "white" : "red",
                                background: dark ? "none" : "white",
                                fontSize: getTableNumFontSize(startNum),
                            }}
                        >
                            {startNum}
                        </div>
                    </div>
                    <div className="qr-holder">
                        <div className="qr-block">
                            {renderQRCode(
                                startNum,
                                g_id,
                                exclude,
                                qrStyle,
                                logoImage,
                                codeType,
                                qrSize,
                                qrWidth,
                                qrHeight
                            )}
                        </div>
                        <div className="store-logo"></div>
                    </div>
                    <div
                        className="qr-code-text-cont"
                        style={{
                            color: dark ? "white" : "red",
                            background: dark ? "none" : "white",
                        }}
                    >
                        <FormattedMessage id="scan_and_order" />
                    </div>
                    <PaymentCont />
                    {goopterCont(props)}
                </div>
            </div>
        </div>
    );
};

// 5 cm wide qr code with table num and scan & order first
export const renderFormat2 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const { g_id, exclude, startNum, dark, logoImage, bgColor, qrStyle, codeType, qrSize, qrHeight, qrWidth } = params;
    return (
        <div className="format format2">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block">
                    <div
                        className="qr-code-text-cont"
                        style={{
                            color: dark ? "white" : "red",
                            background: dark ? "none" : "white",
                        }}
                    >
                        <FormattedMessage id="scan_and_order" />
                    </div>
                    <div className="qr-holder">
                        <div className="qr-block">
                            {renderQRCode(
                                startNum,
                                g_id,
                                exclude,
                                qrStyle,
                                logoImage,
                                codeType,
                                qrSize,
                                qrWidth,
                                qrHeight
                            )}
                        </div>
                    </div>
                    <div className="tableNum-cont">
                        <div
                            className="tableNum-holder"
                            style={{
                                color: dark ? "white" : "red",
                                background: dark ? "none" : "white",
                                fontSize: getTableNumFontSize(startNum),
                            }}
                        >
                            {startNum}
                        </div>
                    </div>
                    <PaymentCont />
                    {goopterCont(props)}
                </div>
            </div>
        </div>
    );
};
// 5 cm wide qr code without table num and store info first
export const renderFormat3 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
    } = params;
    return (
        <div className="format format3">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block">
                    <div className="qr-code-white-text">{storeName}</div>
                    <div className="qr-code-url-subtext">{storeURL}</div>
                    <div className="qr-code-white-subtext">{helper.formatPhone(phone)}</div>
                    <div className="qr-holder">
                        <div className="qr-block">
                            {renderQRCode(
                                startNum,
                                g_id,
                                exclude,
                                qrStyle,
                                logoImage,
                                codeType,
                                qrSize,
                                qrWidth,
                                qrHeight
                            )}
                        </div>
                    </div>
                    <div
                        className="qr-code-text-cont"
                        style={{
                            color: dark ? "white" : "red",
                            background: dark ? "none" : "white",
                        }}
                    >
                        <FormattedMessage id="scan_and_order" />
                    </div>
                    <div className="bottom-cont">
                        <PaymentCont />
                        {goopterCont(props)}
                    </div>
                </div>
            </div>
        </div>
    );
};
// 5 cm wide qr code with table num and store info and table num first
export const renderFormat4 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
    } = params;
    return (
        <div className="format format4">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block">
                    <div className="qr-code-white-text">{storeName}</div>
                    <div className="qr-code-url-subtext">{storeURL}</div>
                    <div className="tableNum-cont">
                        <div
                            className="tableNum-holder"
                            style={{
                                color: dark ? "white" : "red",
                                background: dark ? "none" : "white",
                                fontSize: getTableNumFontSize(startNum),
                            }}
                        >
                            {startNum}
                        </div>
                    </div>
                    <div className="qr-holder">
                        <div className="qr-block">
                            {renderQRCode(
                                startNum,
                                g_id,
                                exclude,
                                qrStyle,
                                logoImage,
                                codeType,
                                qrSize,
                                qrWidth,
                                qrHeight
                            )}
                        </div>
                    </div>
                    <div
                        className="qr-code-text-cont"
                        style={{
                            color: dark ? "white" : "red",
                            background: dark ? "none" : "white",
                        }}
                    >
                        <FormattedMessage id="scan_and_order" />
                    </div>
                    <PaymentCont />
                    {goopterCont(props)}
                </div>
            </div>
        </div>
    );
};
// 10 cm wide qr code without table num on right and qr on left
export const renderFormat5 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
    } = params;
    return (
        <div className="format format5 format-wide">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section d-flex w100">
                        <div className="w50">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont-5"
                                style={{
                                    color: dark ? "white" : "red",
                                    background: dark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="w50 d-flex flex-column">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: dark ? "white" : "red",
                                        background: dark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                            </div>
                            {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            <PaymentCont />
                            <div className="qr-code-white-subtext w100 mt-3">
                                <FormattedMessage id="order_online_earn_points" />
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                        {goopterCont(props)}
                    </div>
                </div>
            </div>
        </div>
    );
};
// 10 cm wide qr code with table num on left, qr in center, and goopter icon on right
export const renderFormat6 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
    } = params;

    return (
        <div className="format format6 format-wide">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section d-flex w100">
                        <div className="w30">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: dark ? "white" : "red",
                                        background: dark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                                {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            </div>
                        </div>
                        <div className="w40">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: dark ? "white" : "red",
                                    background: dark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="w30">
                            <div
                                className="goopter-container"
                                style={{ backgroundColor: props.dark ? "#504F4F" : "white" }}
                            >
                                <div
                                    className="powered-by inline-center d-flex"
                                    style={{ color: props.dark ? "white" : "#626161" }}
                                >
                                    <FormattedMessage id="powered_by" />
                                    <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section mt-3">
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                        <div className="payment-container">
                            <img src={PaymentLogos} alt="Payment Logos" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
// 10 cm wide qr code with table num in center, qr on left, and goopter icon on right
export const renderFormat7 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
    } = params;
    return (
        <div className="format format7 format-wide">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section d-flex w100">
                        <div className="w40">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: dark ? "white" : "red",
                                    background: dark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="w200 d-flex justify-content-center flex-column align-items-center">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: dark ? "white" : "red",
                                        background: dark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                            </div>
                            {label ? <div className="qr-code-white-subtext w100 mt-2 mb-4">{label}</div> : null}
                            <div className="qr-code-white-subtext w100 mt-3">
                                <FormattedMessage id="order_online_earn_points" />
                            </div>
                        </div>
                        <div className="rotatedSection">
                            <div
                                className="goopter-container"
                                style={{ backgroundColor: props.dark ? "#504F4F" : "white" }}
                            >
                                <div
                                    className="powered-by inline-center d-flex"
                                    style={{ color: props.dark ? "white" : "#626161" }}
                                >
                                    <FormattedMessage id="powered_by" />
                                    <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section mt-3">
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                        <div className="payment-container">
                            <img src={PaymentLogos} alt="Payment Logos" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
// 10 cm wide qr code without table num, with pay icons on left, qr in center, and goopter icon on right
export const renderFormat8 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        storeURL,
        startNum,
        storeName,
        dark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrHeight,
        qrWidth,
    } = params;
    return (
        <div className="format format8 format-wide">
            <div className="qr-print-pdf-container" style={{ background: dark ? "#504F4F" : bgColor ?? "#20817B" }}>
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-5">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section d-flex w100">
                        <div className="rotatedPaymentSection">
                            <div className="payment-container">
                                <img src={PaymentLogos} alt="Payment Logos" />
                            </div>
                        </div>
                        <div className="w40">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        "119",
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: dark ? "white" : "red",
                                    background: dark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="rotatedSection">
                            <div
                                className="goopter-container"
                                style={{ backgroundColor: props.dark ? "#504F4F" : "white" }}
                            >
                                <div
                                    className="powered-by inline-center d-flex"
                                    style={{ color: props.dark ? "white" : "#626161" }}
                                >
                                    <FormattedMessage id="powered_by" />
                                    <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section mt-3">
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                        <div className="qr-code-white-subtext w100 mt-3">
                            <FormattedMessage id="order_online_earn_points" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
// 4.8cm pure qr code only
export const renderFormat9 = (props: Record<string, any>): JSX.Element => {
    const { g_id, exclude, startNum, logoImage, qrStyle, codeType } = props;
    return (
        <div className="qr-holder-big">
            <div className="qr-big">
                {renderQRCode(startNum, g_id, exclude, qrStyle, logoImage, codeType, "375", "100", "100")}
            </div>
        </div>
    );
};
//Custom Text
export const renderFormat10 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        customdark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
        customText1,
        customText2,
    } = params;
    return (
        <div className="format format10 format10-wide-custom">
            <div
                className="qr-print-pdf-container"
                style={{ background: customdark ? "#504F4F" : bgColor ?? "#20817B" }}
            >
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                    </div>
                    <div className="middle-section-custom d-flex w100">
                        <div className="w50">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: customdark ? "white" : "red",
                                    background: customdark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="w50 d-flex flex-column">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: customdark ? "white" : "red",
                                        background: customdark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                            </div>
                            {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            <PaymentCont />
                            <div className="qr-code-white-subtext w100 mt-3">
                                <FormattedMessage id="order_online_earn_points" />
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section-custom">
                        <div className="custom-text-box">{customText1}</div>
                        <div className="custom-text-box">{customText2}</div>
                        {goopterContDark(props)}
                    </div>
                </div>
            </div>
        </div>
    );
};
//Custom Text
export const renderFormat11 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        customdark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
        customText1,
        customText2,
    } = params;
    return (
        <div className="format format11 format11-wide-custom">
            <div
                className="qr-print-pdf-container"
                style={{ background: customdark ? "#504F4F" : bgColor ?? "#20817B" }}
            >
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                        {phone ? <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div> : null}
                    </div>
                    <div className="middle-section d-flex w100">
                        <div className="w50 d-flex flex-column">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: customdark ? "white" : "red",
                                        background: customdark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                            </div>
                            {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            <PaymentCont />
                        </div>
                        <div className="w50">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: customdark ? "white" : "red",
                                    background: customdark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <div className="custom-text-box">{customText1}</div>
                        <div className="custom-text-box">{customText2}</div>
                        {goopterContDark(props)}
                    </div>
                </div>
            </div>
        </div>
    );
};
//Custom Text
export const renderFormat12 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        customdark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
        customText1,
        customText2,
    } = params;
    return (
        <div className="format format12 format12-wide-custom">
            <div
                className="qr-print-pdf-container"
                style={{ background: customdark ? "#504F4F" : bgColor ?? "#20817B" }}
            >
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section-custom d-flex w100">
                        <div className="w40">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: customdark ? "white" : "red",
                                    background: customdark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="w40 topRight">
                            <div className="tableNum-cont">
                                <div
                                    className="tableNum-holder"
                                    style={{
                                        color: customdark ? "white" : "red",
                                        background: customdark ? "none" : "white",
                                        fontSize: getTableNumFontSize(startNum),
                                    }}
                                >
                                    {startNum}
                                </div>
                            </div>
                            {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            <div className="points-text w100 ">
                                <FormattedMessage id="order_online_earn_points" />
                            </div>
                            <PaymentCont />
                        </div>
                        <div className="w15 d-flex flex-column">
                            <div
                                className="goopter-container"
                                style={{
                                    backgroundColor: props.customdark ? "#504F4F" : "white",
                                    color: props.customdark ? "white" : "#626161",
                                }}
                            >
                                <FormattedMessage id="powered_by" />
                                <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section-custom-row">
                        {phone ? <div className="qr-code-white-subtext w50">{helper.formatPhone(phone)}</div> : null}
                        <div className="custom-cont">
                            <div className="custom-text-box">{customText1}</div>
                            <div className="custom-text-box">{customText2}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
//Custom Text
export const renderFormat13 = (props: Record<string, any>): JSX.Element => {
    const params = Object.assign({}, defaultFormatProps, props);
    const {
        g_id,
        exclude,
        phone,
        label,
        storeURL,
        startNum,
        storeName,
        customdark,
        logoImage,
        bgColor,
        qrStyle,
        codeType,
        qrSize,
        qrHeight,
        qrWidth,
        customText1,
        customText2,
    } = params;
    return (
        <div className="format format13 format13-wide-custom">
            <div
                className="qr-print-pdf-container"
                style={{ background: customdark ? "#504F4F" : bgColor ?? "#20817B" }}
            >
                <div className="qr-code-card-block w100">
                    <div className="top-section mb-3">
                        <div className="qr-code-white-text w100">{storeName}</div>
                        <div className="qr-code-white-subtext w100">{storeURL}</div>
                    </div>
                    <div className="middle-section-custom d-flex w100">
                        <div className="w50 d-flex flex-column">
                            {phone ? (
                                <div className="qr-code-white-subtext w100">{helper.formatPhone(phone)}</div>
                            ) : null}
                            {label ? <div className="qr-code-white-subtext w100 mt-2">{label}</div> : null}
                            <div className="points-text w100 mt-3">
                                <FormattedMessage id="order_online_earn_points" />
                            </div>
                            <PaymentCont />
                            <div className="custom-cont">
                                <div className="custom-text-box">{customText1}</div>
                                <div className="custom-text-box">{customText2}</div>
                            </div>
                        </div>
                        <div className="w40">
                            <div className="qr-holder">
                                <div className="qr-block">
                                    {renderQRCode(
                                        startNum,
                                        g_id,
                                        exclude,
                                        qrStyle,
                                        logoImage,
                                        codeType,
                                        qrSize,
                                        qrWidth,
                                        qrHeight
                                    )}
                                </div>
                            </div>
                            <div
                                className="qr-code-text-cont"
                                style={{
                                    color: customdark ? "white" : "red",
                                    background: customdark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="scan_and_order" />
                            </div>
                        </div>
                        <div className="custom-goopter">
                            <div
                                className="goopter-container"
                                style={{
                                    color: customdark ? "white" : "red",
                                    background: customdark ? "none" : "white",
                                }}
                            >
                                <FormattedMessage id="powered_by" />
                                <img className="goopter-logo" src={GoopterLogo} alt="Goopter Logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const FORMATS = [
    {
        value: "format1",
        showsTableNum: true,
        renderer: renderFormat1,
        component: Format1,
        dark: false,
    },
    {
        value: "format2",
        showsTableNum: true,
        renderer: renderFormat2,
        component: Format2,
        dark: false,
    },
    {
        value: "format3",
        showsTableNum: false,
        renderer: renderFormat3,
        component: Format3,
        dark: false,
    },
    {
        value: "format4",
        showsTableNum: true,
        renderer: renderFormat4,
        component: Format4,
        dark: false,
    },
    {
        value: "format1-Dark",
        showsTableNum: true,
        renderer: renderFormat1,
        component: Format1,
        dark: true,
    },
    {
        value: "format2-Dark",
        showsTableNum: true,
        renderer: renderFormat2,
        component: Format2,
        dark: true,
    },
    {
        value: "format3-Dark",
        showsTableNum: false,
        renderer: renderFormat3,
        component: Format3,
        dark: true,
    },
    {
        value: "format4-Dark",
        showsTableNum: true,
        renderer: renderFormat4,
        component: Format4,
        dark: true,
    },
    {
        value: "format5",
        showsTableNum: true,
        renderer: renderFormat5,
        component: Format5,
        dark: false,
    },
    {
        value: "format6",
        showsTableNum: true,
        renderer: renderFormat6,
        component: Format6,
        dark: false,
    },
    {
        value: "format7",
        showsTableNum: true,
        renderer: renderFormat7,
        component: Format7,
        dark: false,
    },
    {
        value: "format8",
        showsTableNum: true,
        renderer: renderFormat8,
        component: Format8,
        dark: false,
    },
    {
        value: "format5-Dark",
        showsTableNum: true,
        renderer: renderFormat5,
        component: Format5,
        dark: true,
    },
    {
        value: "format6-Dark",
        showsTableNum: true,
        renderer: renderFormat6,
        component: Format6,
        dark: true,
    },
    {
        value: "format7-Dark",
        showsTableNum: true,
        renderer: renderFormat7,
        component: Format7,
        dark: true,
    },
    {
        value: "format8-Dark",
        showsTableNum: true,
        renderer: renderFormat8,
        component: Format8,
        dark: true,
    },
    {
        value: "format9",
        showsTableNum: false,
        renderer: renderFormat9,
        component: Format9,
        dark: false,
    },
];

export const CUSTOMFORMATS = [
    {
        value: "format10",
        showsTableNum: true,
        renderer: renderFormat10,
        component: Format10,
        customdark: false,
    },
    {
        value: "format11",
        showsTableNum: true,
        renderer: renderFormat11,
        component: Format11,
        customdark: false,
    },
    {
        value: "format12",
        showsTableNum: true,
        renderer: renderFormat12,
        component: Format12,
        customdark: false,
    },
    {
        value: "format13",
        showsTableNum: true,
        renderer: renderFormat13,
        component: Format13,
        customdark: false,
    },
    {
        value: "format10-Dark",
        showsTableNum: true,
        renderer: renderFormat10,
        component: Format10,
        customdark: true,
    },
    {
        value: "format11-Dark",
        showsTableNum: true,
        renderer: renderFormat11,
        component: Format11,
        customdark: true,
    },
    {
        value: "format12-Dark",
        showsTableNum: true,
        renderer: renderFormat12,
        component: Format12,
        customdark: true,
    },
    {
        value: "format13-Dark",
        showsTableNum: true,
        renderer: renderFormat13,
        component: Format13,
        customdark: true,
    },
];

export default {
    FORMATS,
    CUSTOMFORMATS,
    renderFormat1,
    renderFormat2,
    renderFormat3,
    renderFormat4,
    renderFormat5,
    renderFormat6,
    renderFormat7,
    renderFormat8,
    renderFormat9,
    renderFormat10,
    renderFormat11,
    renderFormat12,
    renderFormat13,
};
