import { createSlice } from "@reduxjs/toolkit";
import { getValuesFromEnumAsArray } from "../util/helper";

export enum ViewControls {
    OrderSales = 1,
    Customers = 2,
    ItemSales = 3,
    Referrals = 5,
    LeastSaleItems = 6,
}

interface DashboardState {
    loading: boolean;
    parameters?: {
        period?: string | number | undefined,
        s_date?: string | undefined,
        e_date?: string | undefined,
        view_control?: number[],
    };
    data?: {
        order_sales?: {
            summary?: {
                total_amount?: number,
                total_orders?: number,
                avg_order?: number,
            },
            details?: {
                date?: string,
                amount?: number,
                orders?: number,
            }[],
        },
        customers_data?: {
            ttl_customers: number,
            new_customers: number,
            ret_customers: number,
        },
        item_sales?: {
            name: any,
            price: number,
            quantity: number,
        }[],
        least_sale_items?: {
            name: any,
            price: number,
            quantity: number,
        }[],
        referrals_data?: {
            ttl_referrals?: number,
            new_referrals?: number,
            new_orders?: number,
            avg_orders?: number,
        },
    };
}

const initialState: DashboardState = {
    loading: false,
    parameters: {
        period: 30, // Number of days for a period of which the summary is wanted, default to 30.
        view_control: getValuesFromEnumAsArray(ViewControls),
    },
};

const slice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDashboardState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getDashboardSuccess: (state, { payload }) => {
            state.data = payload;
        },
    },
});

export const { setDashboardState, getDashboardSuccess } = slice.actions;

export const dashboard = slice.reducer;
