import React from "react";
import { View, Text } from "@react-pdf/renderer";

const tablePoints = (styles: React.CSSProperties | any, url: { tableNum: number | string }): JSX.Element => (
    <View style={styles.tableNumCont}>
        <View style={styles.tableNumHolder}>
            <Text>{url.tableNum}</Text>
        </View>
        <View style={styles.earnPoints}>
            <Text> Order online to earn rewards </Text>
        </View>
    </View>
);
export default tablePoints;
