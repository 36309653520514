import React, { Component } from "react";
import _ from "lodash";
import { Input, Form } from "antd";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

const { TextArea } = Input;

class CommentInput extends Component {
    handleInputChange = (e) => {
        this.props.setState({ orderComment: e.target.value });
    };

    getValue = () => {
        return this.props.comment;
    };

    render() {
        return (
            <div>
                <Form.Item {...this.props.form}>
                    <TextArea rows={4} onChange={(e) => this.handleInputChange(e)} value={this.getValue()} />
                </Form.Item>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CommentInput));
