import { Button, Dropdown, Menu, Modal } from "antd";
import config from "config";
import _ from "lodash";
import { getStoreWithGid, isGroupedOrder, isRestaurant, requireDineInDueTime } from "pages/liveorder/helper";
import CancelModal from "pages/liveorder/_components/cancel-model";
import SurchargeDialog from "pages/liveorder/_components/order-surcharge-dialog";
import ProcessingModal from "pages/liveorder/_components/processing-model";
import { Component } from "react";
import { IoMdArrowDropup } from "react-icons/io";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import liveOrderActions from "slices/live-order";
import actions from "slices/order-history";
import helper, { getDeliveryServiceProvider, showMarkAsPaidOrUnpaid } from "util/helper";
import LiveOrder from "util/model/live-order";
import {
    allowEditPaymemnt,
    getDeliveryStatusNumber,
    getShippingMethodNumber,
    ORDER_DELIVERY_STATUS_ACTIONS,
    ORDER_DETAIL_ACTIONS,
} from "../helper";
import { handlePrintReceipt } from "../order-print/order-detail-print-view";
import {
    BULK_UPDATE_API_TYPE,
    BULK_UPDATE_STATUES,
} from "../page-components/order-list-controllers/bulk-update-controller/bulk-update-process-modal";
import { orderPaymentDialog } from "../page-components/order-payment-dialog";
import RefundModal from "../page-components/order-refund-dialog";
import CancelDeliveryModal from "pages/liveorder/_components/cancel-delivery-modal";
import { cancelDoordashDelivery } from "services/store";
import DoordashTrackingModal from "pages/liveorder/_components/doordash-tracking-modal";
import { getDeliveryURL } from "services/store";
class OrderDetailContoller extends Component {
    state = {};

    str = (values) => this.props.intl.formatMessage(values);

    getOrder = () => _.get(this.props, "order", {});

    getOrderId = () => _.get(this.getOrder(), "ord_id");

    getStore = () => {
        const orderGid = _.get(this.props, "order.g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return getStoreWithGid(orderGid, storesData);
    };

    getOrderDeliveryStatus = () => getDeliveryStatusNumber(this.getOrder());

    getPayStatus = () => _.get(this.getOrder(), "pay_status", config.PAY_STATUS_TO_NUMERIC.default);

    getOrderStatus = () => _.get(this.getOrder(), "ord_st", 0);

    getDeliveryTaskId = () => _.get(this.getOrder(), "delivery_id", "");

    getButtons = () => {
        //delivery status
        // "100": "order_canceled",
        // "110": "pending_for_store_confirmation",
        // "112": "pending_for_customer_confirmation",
        // "115": "store_accepted",
        // "120": "store_is_preparing",
        // "130": "ready_for_pick_up",
        // "131": "ready_for_courier_pick_up",
        // "201": "rejected_by_courier",
        // "210": "courier_assigned",
        // "220": "courier_accepted",
        // "230": "courier_heading_to_store",
        // "240": "courier_arrive_at_the_store",
        // "250": "courier_collected_the_package",
        // "260": "courier_heading_to_customer",
        // "270": "courier_arrive_at_customer",
        // "280": "delivered_order"

        //order status
        // "1": "pending",
        // "2": "processing",
        // "3": "payment_review",
        // "4": "completed",
        // "5": "canceled",
        // "6": "closed",
        // "7": "pending_payment"

        //order type
        // "delivery": "1",
        // "pickup": "2",
        // "takeout": "2",
        // "eatin": "3",
        // "flatrate": "4",
        // "freeShipping": "5"
        // "instantCheckout": "6"

        //button map:
        //key: order type-order status-delivery status-courier status
        //value: the buttons show in this case
        const buttonMap = {
            "any-1,2-110-false": [
                this.renderAcceptButton,
                this.renderRejectButton,
                ["complete", "print", "edit_payment", "add_new_item"],
            ],
            "any-2-115-false": [
                this.renderStartPreparingButton,
                this.renderReadyForPickupButton,
                ["complete", "print", "cancel", "edit_payment"],
            ],
            "3-2-115-false": [
                this.renderServeButton,
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "add_surcharge", "add_new_item", "cancel"],
            ],
            "2-2-120-false": [
                this.renderReadyForPickupButton,
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "add_surcharge", "add_new_item", "cancel"],
            ],
            "1-2-120-false":
                getDeliveryServiceProvider(this.props.store) ===
                config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.third_party_courier
                    ? [
                          this.renderReadyForCourierPickupButton,
                          this.renderCompleteButton,
                          ["complete", "print", "edit_payment", "add_surcharge", "add_new_item", "cancel"],
                      ]
                    : [
                          this.renderReadyForCourierPickupButton,
                          this.renderStartDeliveryButton,
                          ["complete", "print", "edit_payment", "add_surcharge", "add_new_item", "cancel"],
                      ],
            "1-2-120-true": [
                this.renderReadyForCourierPickupButton,
                ["print", "edit_payment", "add_surcharge", "request_delivery_service", "add_new_item", "cancel"],
            ],
            "3-2-120-false": [
                this.renderServeButton,
                this.renderCompleteButton,
                [
                    "complete",
                    "print",
                    "edit_payment",
                    "request_delivery_service",
                    "add_surcharge",
                    "add_new_item",
                    "cancel",
                ],
            ],
            "2-2-130-any": [
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "add_surcharge", "cancel"],
            ],
            "1-2-131-false": [
                this.renderStartDeliveryButton,
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "add_surcharge", "cancel"],
            ],
            "1-2-130-true": [["cancel", "print", "edit_payment", "add_surcharge"]],
            "any-4-any-any": [this.renderPrintButton, ["refund", "edit_payment", "print"]],
            "any-6-any-any": [this.renderPrintButton, ["print"]],
            "any-5-any-any": [this.renderPrintButton],
            "any-1,2-112-any": [
                this.renderFinalizeButton,
                ["complete", "print", "cancel", "edit_payment", "add_surcharge"],
            ],
            "1-2-250,260,270,280-true": [this.renderPrintButton, ["print", "cancel", "complete"]],
            "1-2-250,260,270,280-false": [this.renderCompleteButton, ["complete", "print", "cancel"]],
            "3,4,5,6-2-120-any": [
                this.renderAddItemButton,
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "add_surcharge", "add_new_item", "cancel"],
            ],
            "any-1,2-200-false": [
                this.renderCompleteButton,
                ["complete", "print", "edit_payment", "cancel_delivery", "track_delivery", "cancel"],
            ],
            other: [this.renderPrintButton, ["print"]],
        };

        //the index after above button map key split with "-"
        const buttonMapKeyIndexesMap = {
            0: "shp_mtd",
            1: "ord_st",
            2: "delivery_status",
            3: "courier_status",
        };

        //based on current order, find the correct buttons
        const order = _.get(this.props, "order");
        const result = Object.keys(buttonMap).find((key) => {
            const keyStrings = key.split("-");
            //find out invalid item
            const invalid = keyStrings.find((value, index) => {
                const orderValue = _.get(order, buttonMapKeyIndexesMap[index]);
                if (value === "any") {
                    //require any
                    //invalid -> never
                    return false;
                } else if (value === "false") {
                    //require not exist
                    //invalid -> exist
                    return orderValue;
                } else if (value === "true") {
                    //require exist
                    //invalid -> not exist
                    return !orderValue;
                } else {
                    //require fit in certain statuses
                    //invalid -> not fit
                    const includedStatuses = value.split(",");
                    return !includedStatuses.includes(String(orderValue));
                }
            });
            //if there is not invalid, correct find
            return !invalid;
        });
        let buttons = [];
        if (result) {
            buttons = buttonMap[result];
        } else {
            buttons = buttonMap["other"];
        }
        return buttons;
    };

    getButtonShareProps = () => {
        return {
            type: "primary",
            style: {
                width: "100%",
                fontWeight: "bold",
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            size: "large",
        };
    };

    getButtonWrapperShareProps = (params) => {
        const style = () => {
            let result = {};
            const buttonNum = params && params.buttonNum;
            if (buttonNum === 1) {
                result.width = "30%";
                result.alignItems = "flex-end";
            } else if (buttonNum === 2) {
                result.width = "45%";
            } else if (buttonNum === 3) {
                result.width = "30%";
            }
            return result;
        };

        return {
            style: style(),
            className: "order-detail-control-button-wrapper",
        };
    };

    onAcceptClick = () => {
        const shippingMethod = getShippingMethodNumber(this.getOrder());
        const isEatIn = helper.isEatIn(shippingMethod);
        const requireDueTime = requireDineInDueTime(this.getStore());
        const updateStatus = () =>
            this.props.updateLiveOrderDeliveryStatus({
                action: [ORDER_DETAIL_ACTIONS.to_processing],
                order_id: this.getOrderId(),
            });
        if (isEatIn && !requireDueTime) {
            this.props.requireDineInConfirmation
                ? Modal.confirm({
                      title: this.str({ id: "warning" }),
                      content: this.str({ id: "start_processing_message" }),
                      onOk() {
                          updateStatus();
                      },
                      okText: <b>{this.str({ id: "confirm" })}</b>,
                      cancelText: <b>{this.str({ id: "cancel" })}</b>,
                  })
                : updateStatus();
        } else {
            this.setState({ showProccess: true });
        }
    };

    updateOrders = (action, post = {}, apiType = BULK_UPDATE_API_TYPE.live_order) => {
        if (isGroupedOrder(this.getOrder())) {
            this.updateOrderInGroup(action, post, apiType);
        } else {
            this.updateOrderSingular(action, post, apiType);
        }
    };

    updateOrderInGroup = (action, post, apiType) => {
        let orders = Array.isArray(_.get(this.getOrder(), "orders"))
            ? _.cloneDeep(_.get(this.getOrder(), "orders"))
            : [];
        this.props.setState({
            selectedOrders: _.reverse(orders),
        });
        this.props.setState({
            bulkUpdateData: {
                showProcessingDialog: true,
                updateStatus: BULK_UPDATE_STATUES.updating,
                postData: {
                    action: action,
                    ...post,
                },
                apiType,
            },
        });
    };

    updateOrderSingular = (action, post, apiType) => {
        const apiAction =
            apiType === BULK_UPDATE_API_TYPE.delivery_status
                ? this.props.updateLiveOrderDeliveryStatus
                : this.props.updateOrder;
        apiAction({
            action,
            order_id: this.getOrderId(),
            order: this.getOrder(),
            orderStore: this.getStore(),
            ...post,
        });
    };

    renderConfirmServeModal = () => {
        const updateStatus = () =>
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_served, {}, BULK_UPDATE_API_TYPE.delivery_status);
        const contentId = isRestaurant(this.getStore())
            ? "start_serving_message_restaurant"
            : "start_serving_message_other";
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: contentId }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    onServeClick = () => {
        this.props.setLiveOrderState({ activeId: this.getOrderId() });
        if (this.props.requireDineInConfirmation) {
            this.renderConfirmServeModal();
        } else {
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_served, {}, BULK_UPDATE_API_TYPE.delivery_status);
        }
    };

    onStartPreparingClick = () => {
        const updateStatus = () =>
            this.props.updateLiveOrderDeliveryStatus({
                action: 4,
                order_id: this.getOrderId(),
            });
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_start_preparing" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "continue" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    onReadyForPickupClick = () => {
        const updateStatus = () =>
            this.props.updateLiveOrderDeliveryStatus({
                action: 2,
                order_id: this.getOrderId(),
            });
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_ready_for_pick_up" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    onReadyForCourierPickupClick = () => {
        const updateStatus = () =>
            this.props.updateLiveOrderDeliveryStatus({
                action: 2,
                order_id: this.getOrderId(),
            });
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_ready_for_delivery" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    onStartDeliveryClick = () => {
        const updateStatus = () =>
            this.props.updateLiveOrderDeliveryStatus({
                action: 3,
                order_id: this.getOrderId(),
            });
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_start_delivery" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    onFinalizeClick = () => {
        this.props.setLiveOrderState({
            modelController: `${this.getOrderId()}:finalizeModal`,
            onEdit: false,
        });
    };

    showCancelDeliveryModal = () => {
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: `${this.getOrderId()}:cancelDeliveryModal`,
        });
    };

    showTrackDeliveryModal = () => {
        this.props.getDeliveryURL({
            delivery_id: this.getDeliveryTaskId(),
            openNewWindows: () =>
                this.props.setState({
                    activeId: this.getOrderId(),
                    modelController: `${this.getOrderId()}:doordashTrackingModal`,
                }),
        });
    };

    onAddItemClick = () => {
        this.props.setLiveOrderState({
            modelController: `${this.getOrderId()}:addItemModal`,
        });
    };

    onCancelClick = () => {
        this.setState({ showCancel: true });
    };

    onCompleteClick = () => {
        const updateLiveOrder = () =>
            this.props.updateLiveOrder({
                action: ORDER_DETAIL_ACTIONS.to_complete,
                order_id: this.getOrderId(),
                order: this.getOrder(),
                orderStore: this.getStore(),
            });
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "mark_as_complete_message" }),
            okText: <b>{this.str({ id: "ok" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
            onOk() {
                updateLiveOrder();
            },
        });
    };

    onPrintClick = () => {
        const order = this.getOrder();
        const orderDetailInstance = new LiveOrder(order);

        handlePrintReceipt({
            orderDetail: orderDetailInstance,
            storeData: this.getStore(),
            orderStatus: "manual",
        });
    };

    onChangePaymentMethodClick = () => {
        orderPaymentDialog({
            order: this.getOrder(),
            updateOrderPayment: (payload) => this.props.updateLiveOrder(payload),
            orderStore: this.getStore(),
        });
    };

    onAddSurchargeClick = () => {
        this.setState({ showSurchargeDialog: true });
    };

    onRefundClick = () => {
        this.props.setState({ showRefundDialog: true, allowRefundSubmit: true });
    };

    renderAcceptButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onAcceptClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="accept" />
                </Button>
            </div>
        );
    };

    renderRejectButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onCancelClick()} {...this.getButtonShareProps()} type="danger">
                    <FormattedMessage id="reject" />
                </Button>
            </div>
        );
    };

    renderServeButton = (params) => (
        <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
            <Button onClick={this.onServeClick} {...this.getButtonShareProps()}>
                <FormattedMessage id={isRestaurant(this.getStore()) ? "serve_restaurant" : "serve_others"} />
            </Button>
        </div>
    );

    renderStartPreparingButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onStartPreparingClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="start_preparing" />
                </Button>
            </div>
        );
    };

    renderReadyForPickupButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onReadyForPickupClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="ready_for_pick_up" />
                </Button>
            </div>
        );
    };

    renderCompleteButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onCompleteClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="complete" />
                </Button>
            </div>
        );
    };

    renderReadyForCourierPickupButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onReadyForCourierPickupClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="ready_for_delivery" />
                </Button>
            </div>
        );
    };

    renderStartDeliveryButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onStartDeliveryClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="start_delivery" />
                </Button>
            </div>
        );
    };

    renderCancelButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onCancelClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="cancel" />
                </Button>
            </div>
        );
    };

    renderFinalizeButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onFinalizeClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="apply_changes" />
                </Button>
            </div>
        );
    };

    renderAddItemButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onAddItemClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="add_new_item" />
                </Button>
            </div>
        );
    };

    renderPrintButton = (params) => {
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Button onClick={() => this.onPrintClick()} {...this.getButtonShareProps()}>
                    <FormattedMessage id="print" />
                </Button>
            </div>
        );
    };

    renderBorder = () => {
        return <div className="order-list-contoller-border-top" />;
    };

    renderButtons = () => {
        const buttons = this.getButtons();

        return (
            <div className="order-list-contoller-buttons">
                {this.getButtons().map((button, index) => {
                    if (helper.isFunction(button)) {
                        return button({ buttonNum: buttons.length, key: index });
                    } else if (Array.isArray(button)) {
                        return this.renderMoreActionButton({
                            buttonNum: buttons.length,
                            moreContent: button,
                            key: index,
                        });
                    }
                    // default:
                    return null;
                })}
            </div>
        );
    };

    renderCancelModal = () => {
        const showModal = this.state.showCancel;
        return (
            <CancelModal
                updateOrder={this.props.updateLiveOrder}
                onCloseClick={() => {
                    this.setState({ showCancel: false });
                }}
                displayModal={showModal}
                order={this.getOrder()}
            />
        );
    };

    renderCancelDeliveryModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentControllerKey = `${this.getOrderId()}:cancelDeliveryModal`;
        const showModal = modelController === currentControllerKey;
        return (
            <CancelDeliveryModal
                showModal={showModal}
                order={this.props.order}
                deliveryID={this.getDeliveryTaskId()}
                cancelDoordashDelivery={this.props.cancelDoordashDelivery}
                onSubmitUpdate={() => this.updateOrders(ORDER_DETAIL_ACTIONS.to_cancel)}
            />
        );
    };

    renderDoordashTrackingModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentControllerKey = `${this.getOrderId()}:doordashTrackingModal`;
        const showModal = modelController === currentControllerKey;

        return (
            <DoordashTrackingModal
                showModal={showModal}
                link={this.props.order?.tracking_url}
                onCloseClick={() => {
                    this.props.setState({ modelController: "" });
                }}
            />
        );
    };

    renderProcessingModal = () => {
        const showModal = this.state.showProccess;
        const estimatedDeliveryFee = this.props?.store?.doordashDeliveryDetails?.estDeliveryFee;
        return (
            <ProcessingModal
                estimatedDeliveryFee={estimatedDeliveryFee}
                onCloseClick={() => {
                    this.setState({ showProccess: false });
                }}
                displayModal={showModal}
                orderStore={this.getStore()}
                order={this.getOrder()}
            />
        );
    };

    renderSurchargeDialog = () => {
        const getOrderSucharge = () => {
            const order = this.getOrder();
            return {
                value: _.get(order, "order_surcharge.value", 0),
                tax: _.get(order, "order_surcharge.tax_class", 0),
                inTotal: _.get(order, "order_surcharge.is_separate", 0) === 0,
            };
        };
        return (
            <SurchargeDialog
                {...{
                    state: this.state,
                    setState: (values) => this.setState(values),
                    order_surcharge: getOrderSucharge().value,
                    orderId: this.getOrderId(),
                    order_surcharge_tax_class: getOrderSucharge().tax,
                }}
            />
        );
    };

    renderRefundDialog = () => {
        const orderId = this.getOrderId();
        const amountPaid = _.get(this.getOrder(), "ttl_pd", 0);
        const totalRefunded = _.get(this.getOrder(), "rf_amt", 0);
        // reward points amount is actuall money amount ex) $5
        const rewardPointsAmount = _.get(this.getOrder(), "pt_amt", 0);
        // reward points number is the number of points ex) 500, $5 = 500 points
        const rewardPointsNumber = _.get(this.getOrder(), "pt_num", 0);
        const giftCardAmount = _.get(this.getOrder(), "gc_amt", 0);
        const config = {
            show: this.props?.state?.showRefundDialog,
            onCancel: () => this.props.setState({ showRefundDialog: false }),
            orderId,
            amountPaid,
            totalRefunded,
            rewardPointsAmount,
            rewardPointsNumber,
            giftCardAmount,
            onSubmitClick: (payload) => this.props.updateLiveOrder(payload),
        };

        return <RefundModal {...config} />;
    };

    renderMoreActionButton = (params) => {
        const dropDownList = Array.isArray(params.moreContent) ? params.moreContent : [];
        const isPaid = this.getPayStatus() === config.PAY_STATUS_TO_NUMERIC.paid;

        const handleMenuClick = (e) => {
            const key = _.get(e, "key");
            switch (key) {
                case "complete":
                    this.onCompleteClick();
                    break;
                case "print":
                    this.onPrintClick();
                    break;
                case "update_payment_method":
                    this.onChangePaymentMethodClick();
                    break;
                case "cancel":
                    this.onCancelClick();
                    break;
                case "add_surcharge":
                    this.onAddSurchargeClick();
                    break;
                case "refund":
                    this.onRefundClick();
                    break;
                case "add_new_item":
                    this.onAddItemClick();
                    break;
                case "mark_as_unpaid":
                    this.props.markAsUnPaid(this.getOrderId());
                    break;
                case "mark_as_paid":
                    this.props.markAsPaid(this.getOrderId());
                    break;
                case "cancel_delivery":
                    this.showCancelDeliveryModal();
                    break;
                case "track_delivery":
                    this.showTrackDeliveryModal();
                    break;
                default:
                    break;
            }
        };

        const showMenu = (key) => {
            if (key === "edit_payment") return allowEditPaymemnt(this.getOrder());
            return true;
        };
        const filteredMenu = dropDownList.filter((menu) => showMenu(menu));
        if (showMarkAsPaidOrUnpaid(this.getOrder())) {
            filteredMenu.push(isPaid ? "mark_as_unpaid" : "mark_as_paid");
        }

        const menu = () => {
            return (
                <Menu onClick={handleMenuClick}>
                    {filteredMenu.map((menu) => (
                        <Menu.Item key={menu}>
                            {
                                <span className={`order-detail-controller-more-action-${menu}`}>
                                    <FormattedMessage id={menu} />
                                </span>
                            }
                        </Menu.Item>
                    ))}
                </Menu>
            );
        };
        return (
            <div {...this.getButtonWrapperShareProps(params)} key={params.key}>
                <Dropdown placement="top" overlay={menu}>
                    <Button {...this.getButtonShareProps()} type="default">
                        <span>
                            <FormattedMessage id="more_actions" />
                        </span>
                        <IoMdArrowDropup />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    render() {
        return (
            <div id="order-detail-detail-controller" className="order-detail-detail-controller">
                {this.renderBorder()}
                {this.renderButtons()}
                {this.renderCancelModal()}
                {this.renderProcessingModal()}
                {this.renderSurchargeDialog()}
                {this.renderRefundDialog()}
                {this.renderCancelDeliveryModal()}
                {this.renderDoordashTrackingModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    orderList: _.get(state, "orders-page.orderList", []),
    order: _.get(state, "orders-page.orderDetailInstance", {}),
    store: _.get(state, "store", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    state: _.get(state, "orders-page", []),
    requireDineInConfirmation: _.get(state, "setting.requireDineInConfirmation", false),
});

const mapDispatchToProps = {
    setState: actions.setState,
    setLiveOrderState: liveOrderActions.setState,
    updateLiveOrderDeliveryStatus: liveOrderActions.updateLiveOrderDeliveryStatus,
    updateLiveOrder: (payload) => liveOrderActions.updateOrder({ data: payload }),
    markAsPaid: liveOrderActions.markAsPaid,
    markAsUnPaid: liveOrderActions.markAsUnPaid,
    cancelDoordashDelivery,
    getDeliveryURL,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetailContoller));
