import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "../../../../../components/form";
import { RootState } from "../../../../../app/reducer";
import { isBool } from "../../../../../util/helper";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput, renderHalfMultiSel, renderHalfTxtSelec } from "./payment-functions";

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { stripe_merch_name, stripe_api_key, stripe_x_type, stripe_card_types, stripe_enabled } = paymentOptions;

    const payAction: SelectOption[] = [
        { value: "authorize", label: intl.formatMessage({ id: "authorization" }) },
        { value: "capture", label: intl.formatMessage({ id: "authorize_and_capture" }) },
    ];

    const cardTypes: SelectOption[] = [
        { value: "VI", label: "Visa" },
        { value: "MC", label: "Mastercard" },
        { value: "AE", label: "American Express" },
        { value: "MC", label: "Mastercard" },
        { value: "DI", label: "Discover" },
        { value: "SM", label: "Swicth/Maestro" },
    ];

    const setState = (name: string, value: any) => {
        if (!stripe_x_type && name !== "stripe_x_type") {
            dispatch(setPaymentState({ name: "stripe_x_type", value: "authorize" }));
        }
        dispatch(setPaymentState({ name, value }));
    };

    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "stripe_account_setting",
                "stripe_account_setting_tip",
                "stripe_enabled",
                stripe_enabled,
                switchChange
            )}
            {stripe_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("title", "stripe_merch_name", stripe_merch_name, setState, false, true)}
                    {renderHalfTxtInput("api_key", "stripe_api_key", stripe_api_key, setState, false, true)}
                    {renderHalfTxtSelec("payment_action", "stripe_x_type", stripe_x_type, payAction, setState, true)}
                    {renderHalfMultiSel(
                        "allowed_credit_card_types",
                        "stripe_card_types",
                        stripe_card_types,
                        cardTypes,
                        setState,
                        true
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default App;
