import { Button, Row, Col, Modal, Switch, Select, Table, Popover, Form, Collapse } from "antd";
import { RootState } from "app/reducer";
import {
    getDateInput,
    getFormItem,
    getMultiSelectInput,
    getNumberInput,
    getSelectInput,
    getSwitchInputSpecifyCheckedValue,
    renderToolTipInline,
    SelectOption,
} from "components/form";
import config from "config";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { getProducts } from "services/products";
import { getStoreDetailsParts, updateStoreDetailsParts } from "services/store";
import { getTimeList } from "services/time";
import { TimeListItem } from "slices/time";
import { buildTranslatedSelectOption, formatCurrency } from "util/helper";
import {
    AddDiscountedUpSellRequest,
    DeleteDiscountedUpSellRequest,
    DiscountedUpSell,
    DiscountedUpSellWithKey,
    DiscountedUpSellSettings,
    UpdateDiscountedUpSellRequest,
} from "./models/smart-upsell-requests";
import "./styles.scss";
import RefreshButton from "components/refresh";

const LOAD_MORE_VALUE = -1;
const AVAILABILITY_KEY = "availability-";
const HOURS_KEY = "hours-";
const PRODUCT_LIMIT = 99999;
const DISCOUNTED_UPSELL_FORM_ID = "add-smart-upsell-rule";

/**
 * Function applied to antd's Select search filterOption prop
 *
 * @param inputValue - user input
 * @param option - option to filter
 * @returns whether to display the option
 */
const discountedUpSellItemsOptionsFilter = (inputValue: string, option: any) => {
    const productName = option.children;
    return _.isString(productName) && productName?.toLowerCase().includes(inputValue.toLowerCase());
};

interface DiscountedUpSellStateProps {
    lan: string;
    store: any;
    discounted_upsell_settings: DiscountedUpSellSettings;
    products: any[];
    timeList: any[];
    lastSearch: any;
    totalProducts: number;
    categoryProducts: any[];
    isLoadingProducts: boolean;
    isLoadingStoreParts: boolean;
    storeCurrency: string;
}

interface DiscountedUpSellTabProps extends DiscountedUpSellStateProps, PropsFromRedux {}

const DiscountedUpSellTab = (props: DiscountedUpSellTabProps) => {
    const [showAddDiscountedUpSellModal, setShowAddDiscountedUpSellModal] = useState(false);
    const [selectedRuleId, setSelectedRuleId] = useState<null | number>(null);

    const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
    const [availabilityCodes, setAvailabilityCodes] = useState<SelectOption[]>([]);

    const [panel, setPanel] = useState<string | string[]>([]);
    const [rerender, setRerender] = useState(0);

    const [wrappedDiscountedUpSellData, setWrappedDiscountedUpSellData] = useState<DiscountedUpSellWithKey[]>([]);

    const [form] = Form.useForm();
    const intl = useIntl();

    const AMOUNT_TYPE_OPTIONS = [
        buildTranslatedSelectOption("grand_total", config.DISCOUNTED_UPSELL_AMOUNT_TYPE_OPTIONS.grand_total),
        buildTranslatedSelectOption("net_amount", config.DISCOUNTED_UPSELL_AMOUNT_TYPE_OPTIONS.net_amount),
    ];

    useEffect(() => {
        initializeDiscountedUpsellData();
    }, []);

    useEffect(() => {
        buildAvailabilityCodeOptions();
    }, [props.timeList]);

    useEffect(() => {
        buildProductOptions();
    }, [props.products]);

    useEffect(() => {
        const discountedUpsellSettingsDataClone = _.cloneDeep(props.discounted_upsell_settings.data);
        const discountedUpsellSettingsDataWithKey: DiscountedUpSellWithKey[] = [];

        if (discountedUpsellSettingsDataClone) {
            discountedUpsellSettingsDataClone.forEach((discount) => {
                const discountWithKey: DiscountedUpSellWithKey = {
                    key: String(discount._id),
                    ...discount,
                };
                discountedUpsellSettingsDataWithKey.push(discountWithKey);
            });
        }

        setWrappedDiscountedUpSellData(discountedUpsellSettingsDataWithKey);
    }, [props.discounted_upsell_settings]);

    const initializeDiscountedUpsellData = () => {
        props.getStoreDetailsParts({ page: "discounted_upsell_settings" });
        props.getProducts({
            product_type: config.PRODUCT_TYPE_TO_NUMERIC.discounted_upsell,
            paging: { limit: PRODUCT_LIMIT },
        });
        props.getTimeList();
    };

    const getFormattedStatusValue = (checked: boolean) => {
        return checked ? 1 : 0;
    };

    /**
     * Convert product data as selct options
     */
    const buildProductOptions = () => {
        const options: SelectOption[] = [];
        props.products.forEach((product) => {
            options.push({
                label: product.name[props.lan],
                value: product.product_id,
            });
        });
        if (props.products.length < props.totalProducts) {
            options.push(buildTranslatedSelectOption("load_more", LOAD_MORE_VALUE));
        }

        setProductOptions(options);
    };

    /**
     * Convert availability codes as select options
     */
    const buildAvailabilityCodeOptions = () => {
        const options: SelectOption[] = [];
        options.push({
            value: "",
            label: intl.formatMessage({ id: "use_category_time_setting" }),
        });
        props.timeList.forEach((time) => {
            options.push({
                value: time.code,
                label: `${time.code} - ${time.name}`,
            });
        });

        setAvailabilityCodes(options);
    };

    /**
     * Convert form data to the format that is to be sent to the API
     *
     * @param formData - form data
     * @returns formatted form data
     */
    const buildFormDataAsDiscountedUpsell = (formData: any): DiscountedUpSell => {
        return {
            status: getFormattedStatusValue(formData.status),
            start_dt: formData.start_dt?.format("YYYY-MM-DD HH:mm:ss") || "",
            end_dt: formData.end_dt?.format("YYYY-MM-DD HH:mm:ss") || "",
            availability_code: formData.availability_code,
            min_amount: formData.min_amount,
            upsell_item_ids: formData.upsell_item_ids,
        };
    };

    /**
     * Checks that there are discounted up-sell items selected in the rule
     *
     * @returns whether the form inputs are valid
     */
    const isValidFormInput = () => {
        const isEmptyUpsellList = _.isEmpty(form.getFieldValue("upsell_item_ids"));

        if (isEmptyUpsellList) {
            form.setFields([{ name: "upsell_item_ids", errors: [intl.formatMessage({ id: "field_required" })] }]);
        }

        return !isEmptyUpsellList;
    };

    const handleOnChangeStatus = (id: number, checked: boolean, event: any) => {
        event.stopPropagation();
        props.updateStoreDetailsParts({
            discounted_upsell_settings: {
                amount_type: props.discounted_upsell_settings.amount_type,
                status: props.discounted_upsell_settings.status,
                data: [
                    {
                        _id: id,
                        status: getFormattedStatusValue(checked),
                    },
                ],
            },
        });
    };

    const handleOnChangeFormStatus = (checked: boolean) => {
        setRerender(rerender + 1);
        form.setFieldsValue({ status: getFormattedStatusValue(checked) });
    };

    const handleOnCloseDiscountedUpSellModal = () => {
        setSelectedRuleId(null);
        setShowAddDiscountedUpSellModal(false);
        form.resetFields();
    };

    const handleAddDiscountedUpSellRule = (formData: any) => {
        if (!isValidFormInput()) {
            return;
        }

        const dataAsDiscountedUpSell = buildFormDataAsDiscountedUpsell(formData);
        const body: AddDiscountedUpSellRequest = {
            discounted_upsell_settings: {
                amount_type: props.discounted_upsell_settings.amount_type,
                status: props.discounted_upsell_settings.status,
                data: [dataAsDiscountedUpSell],
            },
        };

        props.updateStoreDetailsParts(body);
        handleOnCloseDiscountedUpSellModal();
    };

    const handleOnClickEditRule = (id: number) => {
        setSelectedRuleId(id);
        const rule: any = _.cloneDeep(props.discounted_upsell_settings.data?.find((rule) => rule._id === id));
        if (rule) {
            rule.start_dt = rule.start_dt ? moment(rule.start_dt, "YYYY-MM-DD HH:mm") : undefined;
            rule.end_dt = rule.end_dt ? moment(rule.end_dt, "YYYY-MM-DD HH:mm") : undefined;
            const upsellProducts: any[] = [];
            rule.upsell_item_ids.forEach((id: number) => {
                const upsellProduct = props.products.find((product) => product.product_id === id);
                if (upsellProduct) {
                    upsellProducts.push(upsellProduct);
                }
            });
        }

        const fieldData = Object.keys(rule).map((key) => {
            return {
                name: key,
                value: rule[key],
            };
        });

        form.setFields(fieldData);
        setShowAddDiscountedUpSellModal(true);
    };

    const handleOnClickDeleteRule = (id: number) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "warning" }),
            content: intl.formatMessage({ id: "delete_rule_confirmation" }),
            okText: intl.formatMessage({ id: "delete" }),
            okType: "danger",
            cancelText: intl.formatMessage({ id: "cancel" }),
            onOk() {
                handleDeleteDiscountedUpSellRule(id);
            },
        });
    };

    const handleEditDiscountedUpSellRule = (formData: any) => {
        if (_.isNil(selectedRuleId) || !isValidFormInput()) {
            return;
        }

        const dataAsDiscountedUpSell = buildFormDataAsDiscountedUpsell(formData);
        const body: UpdateDiscountedUpSellRequest = {
            discounted_upsell_settings: {
                amount_type: props.discounted_upsell_settings.amount_type,
                status: props.discounted_upsell_settings.status,
                data: [
                    {
                        ...dataAsDiscountedUpSell,
                        _id: selectedRuleId,
                    },
                ],
            },
        };

        props.updateStoreDetailsParts(body);
        handleOnCloseDiscountedUpSellModal();
    };

    const handleDeleteDiscountedUpSellRule = (id: number) => {
        const body: DeleteDiscountedUpSellRequest = {
            discounted_upsell_settings: {
                data: [
                    {
                        _id: id,
                        delete: 1,
                    },
                ],
            },
        };

        props.updateStoreDetailsParts(body);
        setSelectedRuleId(null);
    };

    const renderAvailableTime = (record: TimeListItem) => {
        return (
            <div>
                {record.available_time.map((a: any) => {
                    return (
                        <div key={AVAILABILITY_KEY + record.code} className="available-time-record">
                            <div>
                                {a.days
                                    .map((d: any) => {
                                        if (_.isString(d) && d.length > 1) {
                                            return d.charAt(0).toUpperCase() + d.slice(1);
                                        }
                                    })
                                    .join(", ")}
                            </div>
                            <div>
                                {a.hours.map((h: any) => (
                                    <div key={HOURS_KEY + record.code}>
                                        {h?.open} - {h?.close}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderTimeTable = () => (
        <div className="time-table-category">
            <Collapse onChange={(key) => setPanel(key)}>
                <Collapse.Panel
                    header={
                        <FormattedMessage
                            id={panel.includes("show_time_table") ? "hide_time_table" : "show_time_table"}
                        />
                    }
                    key="show_time_table"
                    showArrow={false}
                >
                    <Table
                        size="small"
                        bordered
                        dataSource={props.timeList}
                        pagination={false}
                        columns={[
                            {
                                title: <FormattedMessage id="name" />,
                                render: (value, record) => record.code + " - " + record.name,
                            },
                            {
                                title: <FormattedMessage id="available_time" />,
                                render: (value, record) => renderAvailableTime(record),
                            },
                        ]}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );

    const renderActions = (id: number) => {
        return (
            <>
                <p
                    className="popover-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnClickEditRule(id);
                    }}
                >
                    <FormattedMessage id="edit" />
                </p>
                <p
                    className="popover-item danger"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOnClickDeleteRule(id);
                    }}
                >
                    <FormattedMessage id="delete" />
                </p>
            </>
        );
    };

    const renderMinAmount = (min: number) => {
        return <div className="text-align-center">{formatCurrency(min, props.storeCurrency)}</div>;
    };

    const renderStatus = (status: number, record: any) => {
        return (
            <div className="switch-status-container">
                <Switch
                    checked={status ? true : false}
                    onChange={(checked, event) => handleOnChangeStatus(record._id, checked, event)}
                />
            </div>
        );
    };

    const renderUpSellItems = (upSellIds: number[]) => {
        const formattedProducts = upSellIds.map((id) => {
            return props.products.find((product) => {
                return product.product_id === id;
            })?.name[props.lan];
        });
        return <div className="text-overflow-ellipsis-3">{formattedProducts.join(", ")}</div>;
    };

    const renderAvailabilityCode = (code: string) => {
        return <div className="text-align-center">{code}</div>;
    };

    const renderActionsMenu = (id: number) => {
        return (
            <div className="actions-container">
                <Popover content={renderActions(id)} placement="bottom" className="actions-container">
                    <HiDotsHorizontal size="19" />
                </Popover>
            </div>
        );
    };

    const buildDiscountedUpsellHeaders = (data: {
        title: string,
        alignCenter?: boolean,
        dataIndex: string,
        width?: number,
        render?: any,
    }) => {
        const { title, alignCenter, dataIndex, width, render } = data;
        return {
            title: alignCenter ? (
                <div className="text-align-center">
                    <FormattedMessage id={title} />
                </div>
            ) : (
                <FormattedMessage id={title} />
            ),
            dataIndex,
            width,
            render,
        };
    };

    const DISCOUNTED_UPSELL_HEADERS = [
        buildDiscountedUpsellHeaders({
            title: "min_amount",
            alignCenter: true,
            dataIndex: "min_amount",
            width: 60,
            render: renderMinAmount,
        }),
        buildDiscountedUpsellHeaders({
            title: "created_date",
            dataIndex: "create_dt",
            width: 100,
        }),
        buildDiscountedUpsellHeaders({
            title: "start_date",
            dataIndex: "start_dt",
            width: 100,
        }),
        buildDiscountedUpsellHeaders({
            title: "end_date",
            dataIndex: "end_dt",
            width: 100,
        }),
        buildDiscountedUpsellHeaders({
            title: "status",
            alignCenter: true,
            dataIndex: "status",
            width: 60,
            render: renderStatus,
        }),
        buildDiscountedUpsellHeaders({
            title: "discounted_upsell_items",
            dataIndex: "upsell_item_ids",
            width: 200,
            render: renderUpSellItems,
        }),
        buildDiscountedUpsellHeaders({
            title: "availability",
            alignCenter: true,
            dataIndex: "availability_code",
            width: 60,
            render: renderAvailabilityCode,
        }),
        buildDiscountedUpsellHeaders({
            title: "actions",
            alignCenter: true,
            dataIndex: "_id",
            width: 30,
            render: renderActionsMenu,
        }),
    ];

    const renderModalFooter = () => {
        return (
            <>
                <Button key="back" onClick={handleOnCloseDiscountedUpSellModal}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button form={DISCOUNTED_UPSELL_FORM_ID} key="submit" type="primary" htmlType="submit">
                    <FormattedMessage id={selectedRuleId ? "update" : "add"} />
                </Button>
            </>
        );
    };

    const renderMultiSelectInput = (opts: {
        name: string,
        showSearch?: boolean,
        options: SelectOption[],
        onSearch?: any,
        onChange?: any,
        disabled?: boolean,
        placeholder?: string,
        filterOption?: any,
    }) => {
        const { name, showSearch, options, onChange, disabled, placeholder, filterOption } = opts;
        return getMultiSelectInput(name, showSearch, options, onChange, disabled, placeholder, filterOption);
    };

    const renderDiscountedUpSellForm = () => {
        return (
            <Form
                form={form}
                id={DISCOUNTED_UPSELL_FORM_ID}
                onFinish={_.isNil(selectedRuleId) ? handleAddDiscountedUpSellRule : handleEditDiscountedUpSellRule}
            >
                <Row gutter={12}>
                    <Col span={12}>{getFormItem("min_amount", getNumberInput("min_amount"))}</Col>
                    <Col span={12}>
                        {getFormItem(
                            "status",
                            getSwitchInputSpecifyCheckedValue(
                                "status",
                                undefined,
                                1,
                                handleOnChangeFormStatus,
                                form.getFieldValue("status")
                            )
                        )}
                    </Col>
                    <Col span={12}>{getFormItem("start_date", getDateInput("start_dt"))}</Col>
                    <Col span={12}>{getFormItem("end_date", getDateInput("end_dt"))}</Col>
                    <Col span={24}>
                        {getFormItem(
                            "discounted_upsell_items",
                            renderMultiSelectInput({
                                name: "upsell_item_ids",
                                showSearch: true,
                                options: productOptions,
                                placeholder: intl.formatMessage({ id: "type_here" }),
                                filterOption: discountedUpSellItemsOptionsFilter,
                            })
                        )}
                    </Col>
                    <Col span={24}>
                        {getFormItem(
                            "time_code_setting",
                            getSelectInput("availability_code", false, availabilityCodes),
                            "time_code_setting_desc"
                        )}
                        {renderTimeTable()}
                    </Col>
                </Row>
            </Form>
        );
    };

    const renderAddDiscountedUpSellModal = () => {
        return (
            <Modal
                title={
                    <FormattedMessage
                        id={selectedRuleId ? "edit_discounted_upsell_rule" : "add_discounted_upsell_rule"}
                    />
                }
                visible={showAddDiscountedUpSellModal}
                onCancel={handleOnCloseDiscountedUpSellModal}
                footer={renderModalFooter()}
                forceRender
            >
                {renderDiscountedUpSellForm()}
            </Modal>
        );
    };

    const renderGlobalDiscountedUpsellSettings = (settings: {
        amountType: React.ReactNode,
        enabled: React.ReactNode,
        refresh?: boolean,
    }) => {
        const { amountType, enabled, refresh } = settings;
        return (
            <div className="global-discounted-upsell-settings-container">
                <div className="amount-type-container">{amountType}</div>
                <span className="h-spacing-16" />
                <div className="enabled-container">{enabled}</div>
                <span className="h-spacing-16" />
                <div className={`refresh-button-container${refresh ? "" : "-placeholder"}`}>
                    <RefreshButton
                        refreshPage={initializeDiscountedUpsellData}
                        loading={props.isLoadingProducts || props.isLoadingStoreParts}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="smart-upsell-container">
                {renderGlobalDiscountedUpsellSettings({
                    amountType: (
                        <b>
                            <FormattedMessage id="amount_type" />
                            <span className="h-spacing-8" />
                            {renderToolTipInline("amount_type_tip")}
                        </b>
                    ),
                    enabled: (
                        <b>
                            <FormattedMessage id="enabled" />
                        </b>
                    ),
                    refresh: true,
                })}
                {renderGlobalDiscountedUpsellSettings({
                    amountType: (
                        <Select
                            options={AMOUNT_TYPE_OPTIONS}
                            className="w100"
                            value={props.discounted_upsell_settings.amount_type}
                            onChange={(value) => {
                                props.updateStoreDetailsParts({
                                    discounted_upsell_settings: {
                                        amount_type: value,
                                    },
                                });
                            }}
                        />
                    ),
                    enabled: (
                        <Switch
                            checked={props.discounted_upsell_settings.status ? true : false}
                            onChange={(checked) => {
                                props.updateStoreDetailsParts({
                                    discounted_upsell_settings: {
                                        status: getFormattedStatusValue(checked),
                                    },
                                });
                            }}
                        />
                    ),
                })}
            </div>
            <div className="spacing-8" />
            <div className="smart-upsell-container">
                <div className="smart-upsell-actions-container">
                    <Button type="primary" onClick={() => setShowAddDiscountedUpSellModal(true)}>
                        <FormattedMessage id="add_rule" />
                    </Button>
                </div>
                <div className="spacing-16" />
                <div className="smart-upsell-rules-container">
                    <Table
                        columns={DISCOUNTED_UPSELL_HEADERS}
                        dataSource={wrappedDiscountedUpSellData}
                        pagination={false}
                        rowClassName="discounted-upsell-row"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    record._id && handleOnClickEditRule(record._id);
                                },
                            };
                        }}
                    />
                </div>
                {renderAddDiscountedUpSellModal()}
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    const DiscountedUpSellStateProps: DiscountedUpSellStateProps = {
        lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
        store: _.get(state, "store.records", {}),
        discounted_upsell_settings: _.get(state, "store.discounted_upsell_settings", {}),
        products: _.get(state, "products.products", []),
        timeList: _.get(state, "time.timeList", []),
        lastSearch: _.get(state, "products.lastSearch", {}),
        totalProducts: _.get(state, "products.totalProducts", 0),
        categoryProducts: _.get(state, "categories.products", []),
        isLoadingProducts: _.get(state, "products.loading", false),
        isLoadingStoreParts: _.get(state, "store.storeDetailsPartsLoading", false),
        storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
    };
    return DiscountedUpSellStateProps;
};

const mapDispatchToProps = {
    getStoreDetailsParts,
    updateStoreDetailsParts,
    getProducts,
    getTimeList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DiscountedUpSellTab);
