import React, { Component } from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import List from "../order-list-list-pane";
import OrderDetail from "../order-list-list-style-order-detail";
import SplitPane from "react-split-pane";

class LiveOrderListStyle extends Component {
    render() {
        return (
            <SplitPane style={{ position: "initial" }} minSize={375} maxSize={375} split="vertical">
                <List />
                <OrderDetail />
            </SplitPane>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveOrderListStyle));
