import { TimePicker, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { FORMAT, TIME_STEP } from "../constants";

interface HourPickerProps {
    value: string;
    placeholder: string;
    onChange: any;
    set24: any;
}

export const HourPicker = (props: HourPickerProps): JSX.Element => {
    const intl = useIntl();

    return (
        <TimePicker
            use12Hours
            format={FORMAT}
            showNow={false}
            minuteStep={TIME_STEP}
            disabled={props.value === "closed"}
            onChange={props.onChange}
            placeholder={props.placeholder ?? intl.formatMessage({ id: "select_time" })}
            value={props.value === "closed" ? null : moment(props.value, FORMAT)}
            renderExtraFooter={() => (
                <Button size="small" type="primary" className="w100" onClick={props.set24}>
                    <FormattedMessage id="24_hours" />
                </Button>
            )}
            allowClear={false}
            clearIcon={null}
            suffixIcon={null}
            size={"middle"}
            inputReadOnly={true}
        />
    );
};
