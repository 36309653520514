import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import TimePicker from "./time-picker";
import { setState, saveDeliveryTime } from "slices/create-order";
import config from "config";
import helper from "util/helper";

class PeriodsPicker extends React.Component<any, any> {
    static defaultProps = {};

    componentDidMount = () => {
        const allowedPeriods = _.get(this.props.state, "allowedPeriods", {});
        if (Object.keys(allowedPeriods).length && this.props.state?.items.length > 0) {
            this.initDatePeriodTimes(allowedPeriods);
        }
    };

    /**
     * Initialize the default date(2000-01-01) and period times(00:00:00-01:30:00)
     *
     * @param allowedPeriods - allowed delivery date and time periods
     */
    initDatePeriodTimes = (allowedPeriods: string[]) => {
        const expectedDeliveryTimeStart = _.get(this.props.state, "expectedDeliveryTimeStart", "");
        const expectedDeliveryTimeEnd = _.get(this.props.state, "expectedDeliveryTimeEnd", "");

        let defaultDate = undefined;
        let defaultPeriodTimes = undefined;

        // check whether it is loaded quote and has ASAP delivery time
        if (
            !_.isNil(expectedDeliveryTimeStart) &&
            !_.isNil(expectedDeliveryTimeEnd) &&
            !_.isEqual(expectedDeliveryTimeStart, config.DELIVERY_TIME_ASAP)
        ) {
            defaultDate = String(expectedDeliveryTimeStart).split(" ")[0];
            defaultPeriodTimes =
                String(expectedDeliveryTimeStart).split(" ")[1] + "-" + String(expectedDeliveryTimeEnd).split(" ")[1];
        } else {
            defaultDate = Object.keys(allowedPeriods)[0];
            defaultPeriodTimes = _.get(allowedPeriods, defaultDate)[0] || config.DELIVERY_TIME_ASAP;
        }

        if (defaultDate && defaultPeriodTimes) {
            this.props.setState({
                deliveryDisplayDate: defaultDate,
                deliveryDate: defaultDate,
                deliveryTime: defaultPeriodTimes,
            });
        }
    };

    getCheckoutState = () => {
        return this.props.state;
    };

    getShouldShow = () => {
        return this.getCheckoutState().displayDeliveryTimePicker;
    };

    getShippingMethod = () => {
        return this.getCheckoutState().shippingMethod;
    };

    getAllowedPeriod = () => {
        return this.getCheckoutState().allowedPeriods;
    };

    getDisplayDate = () => {
        return this.getCheckoutState().deliveryDisplayDate;
    };

    getDeliveryDate = () => {
        return this.getCheckoutState().deliveryDate;
    };

    getDeliveryTime = () => {
        return this.getCheckoutState().deliveryTime;
    };

    getTitle = () => {
        const formatMessageId = helper.isDelivery(this.props.shippingMethod) ? "delivery_time" : "pickup_time";
        return this.props.intl.formatMessage({ id: formatMessageId });
    };

    saveDeliveryTime = (name: any, value: any) => {
        return this.props.saveDeliveryTime({ name, value });
    };

    toggleDeliveryTimePopup = () => {
        this.props.setState({ displayDeliveryTimePicker: false });
    };

    render() {
        return (
            <TimePicker
                show={this.getShouldShow()}
                key="time-picker-popup"
                title={this.getTitle()}
                allowedPeriods={this.getAllowedPeriod()}
                saveDeliveryTime={(name: any, value: any) => this.saveDeliveryTime(name, value)}
                deliveryDisplayDate={this.getDisplayDate()}
                deliveryDate={this.getDeliveryDate()}
                deliveryTime={this.getDeliveryTime()}
                toggleDeliveryTimePopup={() => this.toggleDeliveryTimePopup()}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", true),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
});

const mapDispatchToProps = {
    setState,
    saveDeliveryTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PeriodsPicker));
