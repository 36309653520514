import React from "react";
import { Alert, Layout, Modal, PageHeader } from "antd";
import { Helmet } from "react-helmet";
import AdminAccount from "../../pages/admin-users/_components/account-modal/admin-account";
import Navigation from "../navigation/navigation";
import { FormattedMessage } from "react-intl";
import { ToastContainer } from "react-toastify";
import { RootState } from "./../../app/reducer";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import useWrapper from "./useWrapper";
import config from "../../config";
import { QRCode } from "react-qrcode-logo";
import PosterDialog from "../poster-dialog";
import GiftCardAccountModal from "../../pages/gift-card-account/_components/gift-card-account-modal";
import CustomerGiftCardsModal from "../../pages/customers/_components/giftcards-components/index";
import ReferralTransactionHistory from "../../pages/referrals/_components/transaction-history";
import RedeemRewards from "../../pages/referrals/_components/redeem-rewards";
import AddRewards from "../../pages/referrals/_components/add-rewards";
import { IsMobileContext } from "../../util/helper/isMobileContext";

const Wrapper = (props: any = {}): JSX.Element => {
    const showAccountModal = useSelector((state: RootState) => state?.adminUsers?.showAccountModal);
    const allowed = useSelector((state: RootState) => state?.pages?.allowed);
    const {
        currUser,
        height,
        isMobile,
        pageHeaderProps,
        canViewPage,
        storeName,
        storeGid,
        setShowQR,
        lan,
        storeLogoUrl,
        showQR,
        intl,
        setShowPoster,
        showPoster,
    } = useWrapper(props);

    const QrCode = () => {
        const canvas: any = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas?.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${storeName}-${storeGid}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowQR(false);
    };

    const renderAccountModal = (
        <Modal width="800px" closable={false} footer={null} bodyStyle={{ padding: "0px" }} visible={showAccountModal}>
            <AdminAccount />
        </Modal>
    );

    const getQRModal = () => {
        const social_url = `${config.H5_URL}store/${storeGid}?lan=${lan}`;
        return (
            <Modal
                width="300px"
                footer={null}
                title={intl.formatMessage({ id: "share_qrcode" })}
                onCancel={() => setShowQR(false)}
                bodyStyle={{ padding: "15px", margin: "0px", display: "flex", justifyContent: "center" }}
                visible={showQR}
            >
                <div className="d-flex flex-column mt-1 align-items-center">
                    <QRCode
                        value={`${social_url}`}
                        size={250}
                        logoImage={`${config.IMG_PREFIX}${storeLogoUrl}`}
                        enableCORS={true}
                    />
                    <div className="product-qr-code mt-2" onClick={() => QrCode()}>
                        <FormattedMessage id="download_QR" />
                    </div>
                </div>
            </Modal>
        );
    };

    const renderPosterModal = <PosterDialog type="store" open={showPoster} onClose={() => setShowPoster(false)} />;

    const renderGiftCardAccountModal = <GiftCardAccountModal />;
    const renderCustomerGiftCardsModal = <CustomerGiftCardsModal />;
    const renderTransactionHistoryReferralModal = (
        <>
            <ReferralTransactionHistory />
            <RedeemRewards />
            <AddRewards />
        </>
    );

    return (
        <IsMobileContext.Provider value={{ isMobile: isMobile }}>
            <Layout>
                <Helmet
                    titleTemplate={`${storeName} - %s`}
                    {...props.helmet}
                    title={props?.helmet?.title ? intl.formatMessage({ id: props?.helmet?.title }) : ""}
                ></Helmet>
                <Layout>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    {currUser?.bearer && !props.hideNav && !isMobile() ? <Navigation /> : null}
                    <Layout.Content
                        style={{
                            minHeight: height,
                            display: "flex",
                            flexDirection: "column",
                            padding: 8,
                            paddingBottom: props.paddingBottom
                                ? typeof props.paddingBottom === "boolean"
                                    ? 128
                                    : props.paddingBottom
                                : 0,
                        }}
                    >
                        {!props?.withoutHeader && currUser?.bearer ? (
                            <PageHeader {...pageHeaderProps()}>
                                {props?.description ? <FormattedMessage id={props?.description ?? ""} /> : null}
                            </PageHeader>
                        ) : null}
                        {canViewPage() ? (
                            <div className="wrapper-child">
                                {allowed ? null : (
                                    <Alert
                                        className="read-only-message"
                                        message={<FormattedMessage id="warning" />}
                                        description={<FormattedMessage id="read_only_message" />}
                                        type="warning"
                                        showIcon
                                    />
                                )}
                                {props.children}
                            </div>
                        ) : (
                            <div style={{ backgroundColor: "white", padding: 12 }}>
                                <FormattedMessage id="insufficient_permissions" />
                            </div>
                        )}
                    </Layout.Content>
                </Layout>
                {getQRModal()}
                {renderPosterModal}
                {renderAccountModal}
                {renderGiftCardAccountModal}
                {renderCustomerGiftCardsModal}
                {renderTransactionHistoryReferralModal}
            </Layout>
        </IsMobileContext.Provider>
    );
};

Wrapper.defaultProps = {
    paddingBottom: true,
};

export default Wrapper;
