import React from "react";
import Wrapper from "../../components/wrapper";
import GiftCardTable from "./_components/gift-card-table";
import "./index.scss";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "giftCardAccount",
            breadcrumbName: "gift_card_account",
        },
    ],
};

function GiftCardAccountPage(): JSX.Element {
    return (
        <Wrapper helmet={{ title: "gift_card_account" }} breadcrumb={breadcrumb} paddingBottom={true}>
            <GiftCardTable />
        </Wrapper>
    );
}

export default GiftCardAccountPage;
