import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { getMenuSuccess } from "../slices/menu";
import { getIntl } from "../locale";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";

export const getMenuDetails =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("MENU")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getMenuSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateMenuDetails =
    (params?: string[] | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("MENU")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params },
                }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getMenuDetails());
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "menu_setting" })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                default:
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "error_occurred" })} ${intl.formatMessage({
                                id: "menu_setting",
                            })}`,
                            HiInformationCircle
                        )
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
