import { Button, Input, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { editProduct } from "../../../../services/products";
import helper from "../../../../util/helper";
import { validateStrInput, allStringsEmpty } from "../../helper";
import { MAX_LEN_NAME } from "../../constants";
import _ from "lodash";
import { copyDataToEmptyLanguageFieldsSingleField } from "util/helper/forms";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    type: state?.store?.storeInformation?.store_categories,
    allowed: state?.pages?.allowed,
    displayLanguages: state?.store?.storeDisplayOptions?.product_display_languages ?? [],
});

interface NameRendererProps {
    value: string;
}

function NameRenderer(props: NameRendererProps): React.ReactChild | null {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { products, lan, allowed, displayLanguages } = useSelector(selector);
    const [currentEditPopover, setCurrentEditPopover] = useState<any>(undefined);
    const [currentHoverPopover, setCurrentHoverPopover] = useState<any>(undefined);
    const [value, setValue] = useState<any>(undefined);
    const { lan: popoverLan, getModalLanguageSelect } = useLanguageSelect();

    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    const getPopover = (record: any, name = "", content: any, child: any, translated = false) => {
        const id = record?.product_id;
        const onSubmit = () => {
            const clonedValues = _.cloneDeep(value);
            copyDataToEmptyLanguageFieldsSingleField({
                values: clonedValues,
                displayLanguages: displayLanguages,
            });

            dispatch(editProduct({ product_id: record.product_id, [name]: clonedValues }));
            setCurrentEditPopover("");
        };

        const shouldDisable = () => {
            return allStringsEmpty(Object.values(value ?? {}));
        };

        const editKey = `edit-${id}-${name}`;
        const hoverKey = `hover-${id}-${name}`;

        const onVisibleChange = () => {
            if (currentEditPopover === editKey) {
                setCurrentEditPopover("");
                setValue(undefined);
            } else {
                setCurrentEditPopover(editKey);
            }
        };

        const contentProp = (
            <div className="edit-popover name-edit-popover">
                {translated ? <div className="modal-lan-select-popover">{getModalLanguageSelect()}</div> : null}
                <div>{content}</div>
                <div className="edit-popover-buttons">
                    <Button onClick={onVisibleChange}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        className="save-button-handler"
                        disabled={shouldDisable()}
                    >
                        <FormattedMessage id="submit" />
                    </Button>
                </div>
            </div>
        );

        return (
            <Popover
                placement="bottomLeft"
                content={contentProp}
                title={intl.formatMessage({ id: "edit" })}
                trigger=""
                onVisibleChange={onVisibleChange}
                visible={currentEditPopover === editKey && allowed}
            >
                <div
                    className="popover-area"
                    onMouseEnter={() => setCurrentHoverPopover(hoverKey)}
                    onMouseLeave={() => setCurrentHoverPopover("")}
                >
                    <div className="popover-child overflow-dots">
                        <span>{child}</span>
                    </div>
                    <div
                        className="popover-icon"
                        style={{ opacity: currentHoverPopover === hoverKey && allowed ? 1 : 0 }}
                    >
                        <HiOutlinePencilAlt />
                    </div>
                </div>
            </Popover>
        );
    };

    const getTextInput = (record: any, name: any, translated?: boolean) => {
        const v = translated ? record?.[name]?.[popoverLan] : record?.[name];
        const editValue = translated ? value?.[popoverLan] : value;
        return (
            <Input
                value={editValue ?? v}
                onChange={(e) => {
                    const fieldsToCheck = [{ prefix: "name", maxLen: MAX_LEN_NAME }];
                    const validatedValues = validateStrInput({ name: e?.target?.value }, fieldsToCheck);
                    setValue(
                        translated
                            ? {
                                  ...(value ?? {}),
                                  [popoverLan]: validatedValues.name,
                              }
                            : validatedValues.name
                    );
                }}
                allowClear
            />
        );
    };

    const setContentOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }
        const editKey = `edit-${record?.product_id}-name`;
        ref.onclick = (e) => {
            setCurrentEditPopover(editKey);
            e.stopPropagation();
        };
    };

    useEffect(() => {
        setValue(record?.name);
    }, [record]);

    return record ? (
        <div className="product-title-image" ref={setContentOnClickHandler}>
            <div className="popover-container">
                {getPopover(
                    record,
                    "name",
                    getTextInput(record, "name", true),
                    helper.getTransString(record?.name, lan),
                    true
                )}
            </div>
        </div>
    ) : null;
}

export default NameRenderer;
