import React, { Component } from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import _actions from "slices/live-order";
import ListGroup from "./order-list-group";

class OrderList extends Component {
    state = {};

    componentDidMount() {
        this.setListeners();
        this.setHeight();
    }

    componentDidUpdate(prevsProps) {
        this.checkHeightChange(prevsProps);
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    setListeners = () => {
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    checkHeightChange = (prevsProps) => {
        const loadingChange = this.props.isListLoading !== prevsProps.isListLoading && !this.props.isListLoading;
        const setHeightChange = this.props.setHeight !== prevsProps.setHeight;
        if (loadingChange || setHeightChange) {
            setTimeout(this.setHeight, 500);
        }
    };

    getListData = () => (this.props.list ? this.props.list : {});

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    renderListGroup = (key) => {
        return <ListGroup key={key} categoryKey={key} listData={this.getListData()} />;
    };

    setHeight = () => {
        const minusRowIds = ["live-order-list-drawer-title-lower-section"];
        const windowHeight = window.innerHeight;
        var result = "53vh";
        if (windowHeight) {
            result = windowHeight * 0.64;
            minusRowIds.map((id) => {
                const row = document.getElementById(id);
                if (row && row.offsetHeight) {
                    result -= row.offsetHeight;
                }
                return id;
            });
        }
        this.setState({ height: result > 0 ? result : "33vh" });
    };

    render() {
        return (
            <div style={{ height: this.state.height }} className="live-order-list-drawer-list">
                {Object.keys(this.getListData()).map((key) => this.renderListGroup(key))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    isListLoading: _.get(state, "liveOrders.isListLoading", false),
    setHeight: _.get(state, "liveOrders.setHeight", false),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderList));
