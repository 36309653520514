import { Form } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { copyDataToEmptyLanguageFieldsFormData } from "util/helper/forms";
import { RootState } from "../../../../app/reducer";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { getCategories } from "../../../../services/categories";
import { editProduct } from "../../../../services/products";
import ObjectModel from "../../../../util/models";
import StoreProduct from "../../../../util/models/store-product";
import { ProductTypes, STATUS_ENABLED } from "../../constants";
import { validateStrInput } from "../../helper";
import config from "config";

export default function useProductQuickAdd(props: any = {}): any {
    const { onClose } = props;

    const [values, setValues] = useState<any>({});

    const dispatch = useDispatch();

    const intl = useIntl();

    const history = useHistory();

    const { lan, getModalLanguageSelect } = useLanguageSelect();

    const { categories, displayLanguages } = useSelector((state: RootState) => ({
        categories: state?.categories?.categories,
        displayLanguages: state?.store?.storeDisplayOptions?.product_display_languages ?? [],
    }));

    const [form] = Form.useForm();

    const getCategoriesAsOptions = () => {
        // level 1 is parent category of all categories and cannot be selected as option
        return (
            categories
                ?.filter?.((c) => c.level > config.CATEGORY_LEVELS.root)
                .map((c) => ({
                    value: c.category_id,
                    label: c.name[lan],
                })) ?? {}
        );
    };

    const onValuesChange = (_: any, values: any) => {
        const validatedValues = validateStrInput(values);
        form.setFieldsValue(validatedValues);
        setValues(validatedValues);
    };

    const getOptions = (config: any, exclude: any = []) => {
        const options: any = [];

        Object.keys(config).forEach((key: any) => {
            if (!exclude?.includes?.(key)) {
                options.push({
                    value: Number(key),
                    label: intl.formatMessage({ id: config[key] }),
                });
            }
        });

        return options;
    };

    const onCancel = () => {
        onClose();
        form.resetFields();
        setValues({});
    };

    const onFinish = (data: any = {}) => {
        const languageFieldNames = ["name", "short_description", "description"];
        copyDataToEmptyLanguageFieldsFormData({
            languageFieldNames: languageFieldNames,
            formData: data,
            displayLanguages: displayLanguages,
        });

        data.product_type = ProductTypes.NORMAL_PRODUCT;
        data.status = STATUS_ENABLED;
        dispatch(editProduct(new ObjectModel(StoreProduct).convertToPostData(data)));
        onClose();
        form.resetFields();
        setValues({});
    };

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    return {
        form,
        lan,
        categoryOptions: getCategoriesAsOptions(),
        getModalLanguageSelect,
        onValuesChange,
        values,
        setValues,
        getOptions,
        intl,
        onCancel,
        onFinish,
        history,
    };
}
