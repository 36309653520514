import { Checkbox, DatePicker, Descriptions, Form, Input, InputNumber, Select, Switch, Tooltip } from "antd";
import classnames from "classnames";
import React from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { getIntl } from "../../locale";
import "./form.scss";

function emptyFunction() {
    // does nothing
}
const intl = getIntl();

export const renderFormItem = (options: {
    name: string;
    child: React.ReactNode;
    desc?: string;
    extras?: any;
    displayFlex?: boolean;
    tip?: string;
}): JSX.Element => {
    const { name, child, desc, extras, displayFlex, tip } = options;
    return getFormItem(name, child, desc, extras, displayFlex, tip);
};

export const getFormItem = (
    name: string,
    child: React.ReactNode,
    desc?: string | null,
    extras: any = "",
    displayFlex = false,
    tip?: string
): JSX.Element => {
    return (
        <div
            className={classnames({
                "standard-form-item": true,
                "d-flex": displayFlex,
            })}
        >
            <div className="form-item-label">
                <div>
                    <FormattedMessage id={name} />
                    {tip ? (
                        <>
                            <span className="h-spacing-8" />
                            {renderToolTipInline(tip)}
                        </>
                    ) : null}
                </div>
                {extras ? extras : null}
            </div>
            {desc ? (
                <div className="form-item-desc">
                    <FormattedMessage id={desc} />
                </div>
            ) : null}
            {child}
        </div>
    );
};

interface TextInputProps {
    name: string;
    rows?: number;
    onChange?: any;
    disabled?: boolean;
    required?: boolean;
    max?: number;
    allowClear?: boolean;
    message?: string;
    placeholder?: string;
    onBlur?: any;
    prefix?: string;
    suffix?: string;
}

export const getTextInput = (props: TextInputProps): JSX.Element => {
    const { name, rows, disabled, required, allowClear, message, placeholder, prefix, suffix } = props;
    const max = props.max || 0,
        onChange = props.onChange || emptyFunction,
        onBlur = props.onBlur || emptyFunction;
    return (
        <Form.Item
            name={name}
            rules={[
                {
                    required: required,
                    message: message ? intl.formatMessage({ id: message }) : undefined,
                },
            ]}
        >
            {rows ? (
                <Input.TextArea
                    placeholder={placeholder || intl.formatMessage({ id: "type_here" })}
                    rows={rows}
                    onChange={onChange}
                    disabled={disabled}
                    {...(max > 0 && {
                        maxLength: max,
                        showCount: true,
                    })}
                    allowClear={allowClear}
                    onBlur={onBlur}
                />
            ) : (
                <Input
                    placeholder={placeholder || intl.formatMessage({ id: "type_here" })}
                    onChange={onChange}
                    disabled={disabled}
                    {...(max > 0 && {
                        maxLength: max,
                        showCount: true,
                    })}
                    allowClear={allowClear}
                    onBlur={onBlur}
                    prefix={prefix}
                    suffix={suffix}
                />
            )}
        </Form.Item>
    );
};

export const renderNumberInput = (options: {
    name: string;
    onChange?: any;
    disabled?: boolean;
    extra?: any;
    required?: boolean;
    label?: string;
    formWidth?: string;
    min?: number;
    max?: number;
    prefix?: string;
    suffix?: string;
}): JSX.Element => {
    const { name, onChange, disabled, extra, required, label, formWidth, min, max, prefix, suffix } = options;
    return getNumberInput(name, onChange, disabled, extra, required, label, formWidth, min, max, prefix, suffix);
};

export const getNumberInput = (
    name: string,
    onChange: any = emptyFunction,
    disabled = false,
    extra: any = {},
    required = false,
    label?: string,
    formWidth?: string,
    min?: number,
    max?: number,
    prefix?: string,
    suffix?: string
): JSX.Element => {
    const intl = getIntl();

    const options = Object.assign(
        {},
        {
            min: min ?? 0,
            max: max ?? 1000000,
        },
        extra
    );

    const formatPrefixSuffix = (value: number | string | undefined) => {
        const formattedValue = `${String(value)?.replace?.(/[^0-9.]/g, "") ?? ""}`;
        if (prefix) {
            return prefix + formattedValue;
        } else if (suffix) {
            return formattedValue + suffix;
        }
        return formattedValue;
    };

    return (
        <div className="d-flex">
            <Form.Item
                name={name}
                style={{ marginBottom: "0px", width: "100%" }}
                rules={[
                    {
                        required: required,
                    },
                ]}
            >
                <InputNumber
                    style={{ width: "100%" }}
                    placeholder={intl.formatMessage({ id: "type_here" })}
                    onChange={onChange}
                    disabled={disabled}
                    formatter={(val) => formatPrefixSuffix(val)}
                    min={0}
                    {...options}
                />
            </Form.Item>
            {label ? (
                <span className="ml-2" style={{ alignSelf: "center", fontWeight: 400 }}>
                    {intl.formatMessage({ id: label })}
                </span>
            ) : null}
        </div>
    );
};

export const getSwitchInput = (
    name: string,
    data: any = {},
    onChange: any = emptyFunction,
    disabled = false,
    additionalStyles: any = {}
): JSX.Element => (
    <Form.Item
        name={name}
        style={{ ...additionalStyles, marginBottom: "0px", marginLeft: "30px" }}
    >
        <Switch
            defaultChecked={
                data[name] === "1" ||
                data[name] === true ||
                data[name] === 1 ||
                data[name] === "true" ||
                Number(data[name]) > 0
            }
            onChange={(checked) => {
                onChange(checked);
            }}
            disabled={disabled}
        />
    </Form.Item>
);

export const getSwitchInputSpecifyCheckedValue = (
    name: string,
    data: any = {},
    checkedValue: any = "1",
    onChange: any = emptyFunction,
    checked?: boolean
): JSX.Element => {
    return (
        <Form.Item
            name={name}
            style={{ marginBottom: "0px" }}
            shouldUpdate={(prevValues, currValues) => prevValues[name] !== currValues[name]}
        >
            <Switch defaultChecked={data[name] === checkedValue} checked={checked} onChange={onChange} />
        </Form.Item>
    );
};

export interface SelectOption {
    value: string | number;
    label: any;
}

export const renderSelectInput = (opts: {
    name: string;
    showSearch?: boolean;
    options: SelectOption[];
    onSearch?: any;
    onChange?: any;
    disabled?: boolean;
    placeholder?: string;
}): JSX.Element => {
    const { name, showSearch, options, onSearch, onChange, disabled, placeholder } = opts;
    return getSelectInput(name, showSearch, options, onSearch, onChange, disabled, placeholder);
};

export const getSelectInput = (
    name: string,
    showSearch?: boolean,
    options: SelectOption[] = [],
    onSearch: any = emptyFunction,
    onChange: any = emptyFunction,
    disabled = false,
    placeholder: any = undefined
): JSX.Element => {
    const intl = getIntl();
    return (
        <Form.Item name={name} style={{ marginBottom: "auto" }}>
            <Select
                showSearch={showSearch}
                style={{ width: "100%" }}
                placeholder={intl.formatMessage({ id: placeholder ?? "type_here" })}
                onChange={onChange}
                onSearch={(address: any) => onSearch(address)}
                filterOption={() => true}
                disabled={disabled}
            >
                {options.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export const getDateInput = (name: string): JSX.Element => {
    return (
        <Form.Item name={name} style={{ marginBottom: "auto" }}>
            <DatePicker showTime showSecond={false} format={"YYYY-MM-DD HH:mm"} allowClear />
        </Form.Item>
    );
};

export const getMultiSelectInput = (
    name: string,
    showSearch?: boolean,
    options: SelectOption[] = [],
    onChange: any = emptyFunction,
    disabled = false,
    placeholder?: string,
    filterOption: any = () => true
): JSX.Element => {
    const intl = getIntl();
    return (
        <Form.Item name={name} style={{ marginBottom: "auto" }}>
            <Select
                showSearch={showSearch}
                style={{ width: "100%" }}
                mode="multiple"
                placeholder={placeholder || intl.formatMessage({ id: name })}
                onChange={onChange}
                filterOption={filterOption}
                disabled={disabled}
            >
                {options.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export const getGoogleDataOptions = (data: any = []): SelectOption[] => {
    return Array.isArray(data)
        ? data.map((option: any) => ({
              value: option?.place_id,
              label: option?.description,
          }))
        : [];
};

export const getDescriptionItem = (
    label: string,
    span: number,
    child: React.ReactNode,
    desc?: string,
    warningMessage?: string
): JSX.Element => {
    const intl = getIntl();

    return typeof child !== "object" ? (
        <Descriptions.Item
            label={intl.formatMessage({ id: label ?? " " })}
            span={span}
            className="descriptionItem-element"
            key={label}
        >
            <div className="descriptionItem-child" style={{ color: "#C8C8C8" }}>
                {child}
            </div>
            {desc ? (
                <div className="form-desc">
                    <FormattedMessage id={desc ?? " "} />
                </div>
            ) : null}
            {warningMessage ? (
                <div className="form-warn">
                    <FormattedMessage id={warningMessage ?? " "} />
                </div>
            ) : null}
        </Descriptions.Item>
    ) : (
        <Descriptions.Item
            label={intl.formatMessage({ id: label ?? " " })}
            span={span}
            className="descriptionItem-element"
            key={label}
        >
            <div className="descriptionItem-child">{child}</div>
            {desc ? (
                <div className="form-desc">
                    <FormattedMessage id={desc ?? " "} />
                </div>
            ) : null}
            {warningMessage ? (
                <div className="form-warn">
                    <FormattedMessage id={warningMessage ?? " "} />
                </div>
            ) : null}
        </Descriptions.Item>
    );
};

export const mapBooleansToNumbers = (data: any = {}, booleans: any[] = []): any => {
    const mapped: any = {};
    Object.keys(data).forEach((key) => {
        if (booleans.includes(key) && typeof data[key] === "boolean") {
            mapped[key] = data[key] ? "1" : "0";
        } else {
            mapped[key] = data[key];
        }
    });

    return mapped;
};

const renReq = () => <div style={{ display: "inline-block", color: "red" }}>*</div>;

export const getSingleBlock = (label: string, req: boolean, child: React.ReactNode): JSX.Element => {
    return (
        <div className="white-single-block">
            <div className="option-title">
                <FormattedMessage id={label ?? " "} /> {req && renReq()}
            </div>
            {child}
        </div>
    );
};

export const getDescBlock = (label: string, req: boolean, description: string, child: React.ReactNode): JSX.Element => {
    return (
        <div className="white-layered-block">
            <div className="option-title" style={{ marginBottom: "10px" }}>
                <FormattedMessage id={label ?? " "} /> {req && renReq()}
            </div>
            {child}
            <div style={{ marginTop: "10px", marginBottom: "0px" }}>
                <p className="ant-descriptions-description">
                    <FormattedMessage id={description ?? " "} />
                </p>
            </div>
        </div>
    );
};

export const getBlockOptions = (label: string, description: string, child: React.ReactNode): JSX.Element => {
    return (
        <div className="white-layered-block">
            <div className="option-title" style={{ marginBottom: "10px" }}>
                <FormattedMessage id={label ?? " "} />
            </div>
            <div style={{ marginBottom: "10px" }}>
                <p className="ant-descriptions-description">
                    <FormattedMessage id={description ?? " "} />
                </p>
            </div>
            {child}
        </div>
    );
};

export const getDoubleBlockOptions = (labels: string[], descriptions: string[], kids: any[]): JSX.Element => {
    return (
        <div className="white-single-block">
            {labels.map((label, i) => {
                return (
                    <div key={label + "-" + i} className="white-block-double-splitter">
                        <div className="option-title" style={{ marginBottom: "10px" }}>
                            <FormattedMessage id={label ?? " "} />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <p className="ant-descriptions-description">
                                <FormattedMessage id={descriptions[i] ?? " "} />
                            </p>
                        </div>
                        {kids[i]}
                    </div>
                );
            })}
        </div>
    );
};

export const getSwitchSingleBlock = (label: string, child: React.ReactNode): JSX.Element => (
    <div className="white-layered-block">
        <div className="switch-title">
            <FormattedMessage id={label ?? " "} />
            {child}
        </div>
    </div>
);

export const getSwitchDescBlock = (label: string, description: string, child: React.ReactNode): JSX.Element => (
    <div className="white-layered-block">
        <div className="switch-title">
            <FormattedMessage id={label ?? " "} />
            {child}
        </div>
        <div style={{ width: "100%" }}>
            <p className="single-layered-description">
                <FormattedMessage id={description ?? " "} />
            </p>
        </div>
    </div>
);

export const getCheckMarkGroup = (name: string, checkmarks: any[] = []): JSX.Element => (
    <Form.Item name={name}>
        <Checkbox.Group options={checkmarks} />
    </Form.Item>
);

export const renderToolTip = (tip: string): JSX.Element => {
    const intl = getIntl();
    return (
        <div className="toolTip-container">
            <Tooltip title={intl.formatMessage({ id: tip ?? " " })}>
                <HiQuestionMarkCircle />
            </Tooltip>
        </div>
    );
};

export const renderToolTipInline = (tip: string): JSX.Element => {
    const intl = getIntl();
    return (
        <div className="tool-tip-container-inline">
            <Tooltip title={intl.formatMessage({ id: tip ?? " " })}>
                <HiQuestionMarkCircle />
            </Tooltip>
        </div>
    );
};
