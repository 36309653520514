import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Empty, Button } from "antd";
import { getDefaultFilters } from "../../helper-order-list";
import "./empty.scss";
import actions from "../../../../slices/order-history";

class OrderListEmpty extends Component {
    getCurrentSearchFilters = () => _.get(this.props, "state.searchConditions.search_condition");

    showContent = () => {
        return !_.isEqual(this.getCurrentSearchFilters(), getDefaultFilters());
    };

    getHeight = () => {
        const { height = 0 } = this.props;
        if (isNaN(height)) {
            return 0;
        }
        return height - 2;
    };

    onViewAllClick = () => {
        this.props.fetchOrderList({ clear: true });
    };

    renderDescription = () => (
        <div className="order-list-table-empty-main-message">
            <b>
                {" "}
                <FormattedMessage id="no_record" />
            </b>
        </div>
    );

    renderEmpty = () => {
        return <Empty description={this.renderDescription()}>{this.renderEmptyContent()}</Empty>;
    };

    renderEmptyContent = () => {
        if (this.showContent())
            return (
                <div>
                    <div className="order-list-table-empty-try-other-message">
                        <FormattedMessage id="try_other_filters" />
                    </div>
                    <div className="order-list-table-empty-view-all-button">
                        <Button type="primary" onClick={this.onViewAllClick}>
                            <FormattedMessage id="view_all_orders" />
                        </Button>
                    </div>
                </div>
            );
    };

    render() {
        return (
            <div style={{ height: isNaN(this.getHeight()) ? 0 : this.getHeight() }} className="order-list-table-empty">
                {this.renderEmpty()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListEmpty));
