// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ThirdPartyDelivery = (args): string => {
    //list[0]: index of doordash
    //list[1]: index of deliveryxp
    const list = [];
    args["3rd_party_local_delivery_setting"].forEach((e, i) => {
        if (e.partner_name == "doordash") {
            list[0] = `[${i}]`;
        }
        if (e.partner_name == "deliveryxp") {
            list[1] = `[${i}]`;
        }
    });
    return `
    3rd_party_local_delivery_setting${list[1]}.api_key,                       deliveryxp.api_key,                  false;
    3rd_party_local_delivery_setting${list[1]}.store_id,                      deliveryxp.store_id,                 false;
    3rd_party_local_delivery_setting${list[1]}.update_dt,                     deliveryxp.update_date,              false;
    3rd_party_local_delivery_setting${list[1]}.create_dt,                     deliveryxp.create_date,              false;
    3rd_party_local_delivery_setting${list[1]}.is_enabled,                    deliveryxp.is_enabled,               false;
    3rd_party_local_delivery_setting${list[1]}.is_production,                 deliveryxp.is_production,            false;
    3rd_party_local_delivery_setting${list[1]}.organization_name,             deliveryxp.organization,             false;
    3rd_party_local_delivery_setting${list[1]}.store_name,                    deliveryxp.store_name,               false;
    3rd_party_local_delivery_setting${list[1]}.partner_name,                  deliveryxp.partner_name,           false;

    3rd_party_local_delivery_setting${list[0]}.is_production,                 doordash.is_production,              false;
    3rd_party_local_delivery_setting${list[0]}.is_enabled,                    doordash.is_enabled,                 false;
    3rd_party_local_delivery_setting${list[0]}.update_dt,                     doordash.update_date,                false;
    3rd_party_local_delivery_setting${list[0]}.create_dt,                     doordash.create_date,                false;
    3rd_party_local_delivery_setting${list[0]}.developer_id,                  doordash.developer_id,               false;
    3rd_party_local_delivery_setting${list[0]}.key_id,                        doordash.key_id,                     false;
    3rd_party_local_delivery_setting${list[0]}.signing_secret,                doordash.signing_secret,             false;
    3rd_party_local_delivery_setting${list[0]}.partner_name,                  doordash.partner_name,               false;
    3rd_party_local_delivery_setting${list[0]}.default_item_name,             doordash.default_item_name,          false;
    3rd_party_local_delivery_setting${list[0]}.default_order_value,           doordash.default_order_value,        false;
    3rd_party_local_delivery_setting${list[0]}.default_tip_amount,            doordash.default_tip_amount,         false;
    3rd_party_local_delivery_setting${list[0]}.pick_up_instructions,          doordash.pick_up_instructions,       false;
    3rd_party_local_delivery_setting${list[0]}.contains_alcohol,              doordash.contains_alcohol,           false;
    3rd_party_local_delivery_setting${list[0]}.timeslot_delta,                doordash.timeslot_delta,             false;
    3rd_party_local_delivery_setting${list[0]}.timeslot_gap,                  doordash.timeslot_gap,               false;
    3rd_party_local_delivery_setting${list[0]}.default_pick_up_delay_time,    doordash.default_pick_up_delay_time, false;
    3rd_party_local_delivery_setting${list[0]}.pre_order_days,                doordash.pre_order_days,             false;
    3rd_party_local_delivery_setting${list[0]}.delivery_fee_markup,           doordash.delivery_fee_markup,        false;
    3rd_party_local_delivery_setting${list[0]}.delivery_fee_credit_limit,     doordash.delivery_fee_credit_limit,  false;
    3rd_party_local_delivery_setting${list[0]}.should_send_notifications,     doordash.should_send_notifications,    false;
    3rd_party_local_delivery_setting${list[0]}.mandatory_fields,              doordash.mandatory_fields,             false;
`;
};

export default ThirdPartyDelivery;
