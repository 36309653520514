import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";

interface CustomerValueProps {
    renderValue: any;
    value: string;
}

function CustomerValueRenderer(props: CustomerValueProps): JSX.Element | null {
    const { renderValue } = props;
    const customers = useSelector((state: RootState) => state?.customers?.customers);

    const record = customers?.find((customer: any) => Number(customer?.id) === Number(props?.value));

    return record ? <div>{renderValue(record)}</div> : null;
}

export default CustomerValueRenderer;
