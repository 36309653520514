import { RootState } from "app/reducer"
import _ from "lodash"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { connect, ConnectedProps } from "react-redux"
import { Modal, Radio, RadioChangeEvent, Space, Button } from "antd"
import helper from "util/helper"
import { setState } from "slices/create-order"
import "./styles.scss"
import { FaAngleRight } from "react-icons/fa"
import { ASAP } from "app/constants"

interface PickupLocationSelectorStateProps {
    pickupLocationId?: number
    pickupLocations: any[]
}

interface PickupLocationSelectorProps extends PickupLocationSelectorStateProps, PropsFromRedux {

}

let addressKey = 0

const PickupLocationSelector = (props: PickupLocationSelectorProps) => {
    const [showSelectPickupLocationModal, setShowSelectPickupLocationModal] = useState(false)
    const [pickupLocationId, setPickupLocationId] = useState<number | undefined>()

    useEffect(() => {
        if (pickupLocationId !== props.pickupLocationId) {
            setPickupLocationId(props.pickupLocationId)
            const allowedPeriods = getSelectedPickupLocation()?.timeslots ?? {}
            const deliveryDates = Object.keys(allowedPeriods)
            const hasDeliveryDates = !_.isEmpty(deliveryDates)
            const deliveryTimes = hasDeliveryDates ? allowedPeriods[deliveryDates[0]] : []
            const hasDeliveryTime = !_.isEmpty(deliveryTimes)

            props.setState({
                allowedPeriods: _.isEmpty(allowedPeriods) ? [] : allowedPeriods,
                deliveryDate: hasDeliveryDates ? deliveryDates[0] : "",
                deliveryTime: hasDeliveryTime ? deliveryTimes[0] : ASAP,
                deliveryDisplayDate: hasDeliveryDates ? deliveryDates[0] : "",
            })
        }
    }, [props.pickupLocationId])

    const getSelectedPickupLocation = () => {
        return props.pickupLocations.find(pickupLocation => {
            return pickupLocation.id === props.pickupLocationId
        })
    }

    const closeSelectPickupLocationModal = () => {
        setShowSelectPickupLocationModal(false)
    }

    const handleOnChangePickupLocation = (e: RadioChangeEvent) => {
        setPickupLocationId(e.target.value)
    }

    const handleOnConfirmPickupLocation = () => {
        props.setState({ pickupLocationId: pickupLocationId })
        closeSelectPickupLocationModal()
    }

    const renderAddressRow = (row: string) => {
        return <div key={`address-${addressKey++}`}>{row}</div>
    }

    const renderAddress = (pickupLocation: any) => {
        const formattedAddress = helper.formatAddress(pickupLocation?.address, {})
        return formattedAddress.map(renderAddressRow)
    }

    const renderPickupLocation = (pickupLocation: any) => {
        return <div>
            <div className="pickup-location-name">{pickupLocation?.name}</div>
            {renderAddress(pickupLocation)}
            <div>{pickupLocation?.phone}</div>
        </div>
    }

    const renderPickupLocations = () => {
        return props.pickupLocations.map((pickupLocation) => {
            return <Radio key={`pickup-location-${pickupLocation?.id}`} value={pickupLocation?.id} className="pickup-location-radio-button">
                {renderPickupLocation(pickupLocation)}
            </Radio>
        })
    }

    const renderSelectPickupLocationModalFooter = () => {
        return <>
            <Button
                type="default"
                onClick={closeSelectPickupLocationModal}
            >
                <FormattedMessage id="cancel" />
            </Button>
            <Button
                type="primary"
                onClick={handleOnConfirmPickupLocation}
            >
                <FormattedMessage id="ok" />
            </Button>
        </>
    }

    const renderSelectPickupLocationModal = () => {
        return <Modal
            visible={showSelectPickupLocationModal}
            title={<FormattedMessage id="select_pickup_location" />}
            onCancel={closeSelectPickupLocationModal}
            footer={renderSelectPickupLocationModalFooter()}
        >
            <div className="pickup-locations-container">
                <Radio.Group onChange={handleOnChangePickupLocation} value={pickupLocationId}>
                    <Space direction="vertical">
                        {renderPickupLocations()}
                    </Space>
                </Radio.Group>
            </div>
        </Modal>
    }

    return _.isEmpty(props.pickupLocations) ? null :
        <div className="pickup-location-container">
            <div className="pickup-location-header">
                <FormattedMessage id="pickup_location" />
            </div>
            <div className="pickup-location" onClick={() => setShowSelectPickupLocationModal(true)}>
                {props.pickupLocationId ? renderPickupLocation(getSelectedPickupLocation()) : <FormattedMessage id="select_pickup_location" />}
                <div className="h-spacing-8" />
                <div className="select-icon">
                    <FaAngleRight />
                </div>
            </div>
            {renderSelectPickupLocationModal()}
        </div>
}

const mapStateToProps = (state: RootState): PickupLocationSelectorStateProps => {
    return {
        pickupLocationId: _.get(state, "createOrder.pickupLocationId"),
        pickupLocations: _.get(state, "createOrder.pickupLocations", []),
    }
}

const mapDispatchToProps = {
    setState,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PickupLocationSelector)
