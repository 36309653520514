import React from "react";
import SignInForm from "./_components/sign-in-form";
import SignInHead from "./_components/sign-in-header";
import "./login.scss";
import Logo from "../../components/logo";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { setPagesState } from "slices/pages";
import { useDispatch } from "react-redux";

function App(): JSX.Element {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPagesState({ isPageRefreshed: true }));
    }, []);

    return (
        <div className="login-page">
            <div className="login-page-container">
                <Logo />
                <SignInHead />
                <SignInForm />
            </div>
            <div>
                <Button type="link" onClick={() => history.push("/signup")}>
                    <FormattedMessage id="signup_instead" />
                </Button>
            </div>
        </div>
    );
}

export default App;
