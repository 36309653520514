import React from "react";
import { HiCreditCard } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { GiftCardAccountHistory } from "../../../../slices/giftcards";
import helper, { formatCurrency } from "../../../../util/helper";
import GiftCardHistoryPagination from "./gift-card-history-pagination";

const selector = (state: RootState) => ({
    cards: state?.giftcards?.history,
    lan: state?.setting?.lan,
    storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
});

interface TableColumn {
    key?: string;
    render?: any;
    title?: any;
    className?: string;
}

function GiftCardHistoryTable(): JSX.Element {
    const { cards, storeCurrency } = useSelector(selector);

    const columns: TableColumn[] = [
        {
            key: "order",
            className: "order-column",
            render: (value: any, record: GiftCardAccountHistory) => record?.additional_info,
        },
        {
            key: "time",
            render: (value: any, record: GiftCardAccountHistory) => helper.utcToLocal(record?.updated_at),
        },
        {
            key: "delta",
            className: "align-right justify-content-end",
            render: (value: any, record: GiftCardAccountHistory) =>
                formatCurrency(+record?.balance_delta, storeCurrency),
        },
        {
            key: "balance",
            className: "align-right justify-content-end",
            render: (value: any, record: GiftCardAccountHistory) =>
                formatCurrency(+record?.balance_amount, storeCurrency),
        },
    ];

    // @ts-ignore
    const getColumn = (column: TableColumn, card: any, i: any) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            {i === 0 ? getColumnHeader(column) : null}
            {/* @ts-ignore */}
            <div className={"gift-card-column-content " + column?.className ?? ""}>
                {/* @ts-ignore */}
                {column?.render ? column?.render(card?.[column?.key], card) : card?.[column?.key]}
            </div>
        </div>
    );

    const getColumnHeader = (column: TableColumn) => (
        <div className={"gift-card-column-header header-border-top " + column?.className ?? ""} key={column.key}>
            {column?.title ? column.title : <FormattedMessage id={column?.key} />}
        </div>
    );

    const getTable = () => (
        <div>
            {cards?.length ? (
                cards?.map((card, i) => (
                    <div className="gift-card-row" key={card?.history_id}>
                        {columns.map((column) => getColumn(column, card, i))}
                    </div>
                ))
            ) : (
                <div className="inactive-empty-warn w100">
                    <HiCreditCard size="60" color="lightgrey" className="mb-2" />
                    <FormattedMessage id="no_gift_cards" />
                </div>
            )}
        </div>
    );

    return (
        <div className="gift-card-table">
            {getTable()}
            <GiftCardHistoryPagination />
        </div>
    );
}

export default GiftCardHistoryTable;
