import config from "config";

/**
 * From a quote api response, make a create-order state object
 *
 * @param quote - quote api response
 * @returns create-order state object
 */
export const getCreateOrderState = (quote: any = {}): any => {
    return {
        cid: quote?.customer_id,
        qid: quote?.quote_id,
        draftOrderCreatedAt: quote?.created_at,
        items: quote?.items,
        shippingMethod: quote?.shp_mtd,
        address: quote?.shp_addr,
        partySize: quote?.party_size,
        tableNumber: quote?.table_no,
        notes: quote?.notes,
        storeDiscountNotes: quote?.store_dis_notes,
        paymentMethod: quote?.pay_mtd,
        expectedDeliveryTimeStart: quote?.exp_dt_start,
        expectedDeliveryTimeEnd: quote?.exp_dt_end,
        selectedGiftCard: quote?.gc ? { code: quote?.gc } : undefined,
        allowedPeriods: quote?.allowed_periods ?? [],
        payment: {
            surCharge: quote?.surcharge,
            subTotal: quote?.subtotal,
            tax: quote?.tax,
            storeDiscountValue: Math.abs(quote?.store_dis),
            totalDiscount: quote?.ttl_dis,
            couponDiscount: quote?.cpn_dis,
            grandTotal: quote?.g_total,
            tips: 0,
            shippingFee: quote?.shp_fee ?? 0,
            giftCardsCount: quote?.gc_count,
            pointsBalance: quote?.p_bal,
        },
        pickupLocationId: quote?.pickup_location_id,
    };
};

/**
 * Get the shipping method string ID and appended class name
 *
 * @param shippingMethod - shipping method number
 * @param isDineIn - store type
 * @returns shipping method string ID and appended class name
 */
export const getShippingMethodMappingInfo = (shippingMethod: number, isDineIn?: boolean): any => {
    let shippingMethodStringID = "";
    let appendedClassName = "";
    switch (shippingMethod) {
        case config.SHIPPING_MAPPING_TO_NUMERIC.delivery:
            shippingMethodStringID = "delivery";
            appendedClassName = "delivery";
            break;
        case config.SHIPPING_MAPPING_TO_NUMERIC.eatin:
            shippingMethodStringID = isDineIn ? "dine_in" : "in_store";
            appendedClassName = "eatin";
            break;
        case config.SHIPPING_MAPPING_TO_NUMERIC.pickup:
            shippingMethodStringID = "pickup";
            appendedClassName = "pickup";
            break;
        case config.SHIPPING_MAPPING_TO_NUMERIC.quick_pay:
            shippingMethodStringID = "quick_pay";
            appendedClassName = "instant-checkout";
            break;
    }
    return [shippingMethodStringID, appendedClassName];
};
