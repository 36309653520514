export const copyToClipBoard = (string: string, onSuccess: (isSucess: boolean) => any) : Record<string, any> | any=> {
    try {
        navigator?.clipboard?.writeText(string).then(function () {
            onSuccess(true)
        }, function () {
            onSuccess(false)
        });
    } catch (e) {
        onSuccess(false)
    }
}