import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { Button } from "antd";
import helper, { formatCurrency } from "../../../../util/helper";
import { ViewControls } from "../../../../slices/dashboard";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import _ from "lodash";
import moment from "moment";

const selector = (state: RootState) => ({
    data: state?.dashboard?.data,
    controls: state?.dashboard?.parameters?.view_control,
    currency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
});

enum Lines {
    Amount,
    Orders,
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="custom-tooltip-label">{label}</div>
                <div className="custom-tooltip-price">{helper.formatCurrency(payload[0]?.payload?.sales)}</div>
                <div className="custom-tooltip-orders">
                    <FormattedMessage id="orders" />: {payload[0]?.payload?.orders}
                </div>
                <div className="custom-tooltip-orders">
                    <FormattedMessage id="average_order_price" />:{" "}
                    {helper.formatCurrency(payload[0]?.payload?.sales / payload[0]?.payload?.orders)}
                </div>
            </div>
        );
    }

    return null;
};

function DashboardGraph(): JSX.Element | null {
    const intl = useIntl();
    const { data, controls, currency } = useSelector(selector);
    const [lines, setLines] = useState<number[]>([Lines.Amount]);
    const [animationActive, setAnimationActive] = useState<boolean>(false);

    const datasetsV2: any = [];

    const chartRef = useRef();

    const exportChart = () => {
        if (chartRef && chartRef.current) {
            // @ts-ignore
            chartRef?.current?.export?.();
        }
    };

    data?.order_sales?.details?.forEach?.((detail, i) => {
        // Set sales amount and orders

        // Format records as  { name: string, sales: number, orders: number }
        const nameComps: string[] | undefined = detail?.date?.split?.("-");
        if (!_.isUndefined(nameComps) && moment(nameComps[0]).isSame(new Date(), "year")) {
            nameComps.shift();
        }
        _.set(datasetsV2, `[${i}].name`, nameComps?.join?.("/") ?? " ");
        _.set(datasetsV2, `[${i}].sales`, detail?.amount ?? 0);
        _.set(datasetsV2, `[${i}].orders`, detail?.orders ?? 0);
    });

    const getDashboardGraphHeader = () => {
        const onClick = (line: any) => () => {
            setLines([line]);
            setAnimationActive(true);
        };

        const buttons = [
            {
                onClick: onClick(Lines.Amount),
                type: lines?.includes(Lines.Amount) ? "primary" : "default",
                children: intl.formatMessage({ id: "sales" }),
            },
            {
                onClick: onClick(Lines.Orders),
                type: lines?.includes(Lines.Orders) ? "primary" : "default",
                children: intl.formatMessage({ id: "orders" }),
            },
        ];

        return (
            <div className="dashboard-graph-header">
                <div className="dashboard-graph-header-title">
                    <FormattedMessage id="overview" />
                </div>
                <div className="dashboard-graph-header-toggles">
                    <Button.Group>
                        {buttons.map((btn: any, i: number) => (
                            <Button key={i} {...btn} />
                        ))}
                    </Button.Group>
                </div>
            </div>
        );
    };

    const getDashboardGraphInnerHeader = () => {
        return (
            <div className="dashboard-graph-header">
                <div>
                    <div>
                        <FormattedMessage id={lines?.includes(Lines.Orders) ? "orders" : "sales"} />
                    </div>
                    <div className="dashboard-graph-header-title">
                        {lines?.includes(Lines.Orders)
                            ? data?.order_sales?.summary?.total_orders
                            : formatCurrency(data?.order_sales?.summary?.total_amount ?? 0, currency)}
                    </div>
                </div>
                <div>
                    <Button onClick={exportChart}>
                        <FormattedMessage id="export" />
                    </Button>
                </div>
            </div>
        );
    };

    return controls?.includes(ViewControls.OrderSales) ? (
        <div className="dashboard-graph-container">
            {getDashboardGraphHeader()}
            <div className="dashboard-graph">
                {getDashboardGraphInnerHeader()}
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={datasetsV2}>
                        {lines?.includes(Lines.Amount) ? (
                            <Line type="linear" dataKey="sales" stroke="#8884d8" isAnimationActive={animationActive} />
                        ) : null}
                        {lines?.includes(Lines.Orders) ? (
                            <Line type="linear" dataKey="orders" stroke="#8884d8" isAnimationActive={animationActive} />
                        ) : null}
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis
                            tickFormatter={(value) =>
                                // @ts-ignore
                                `${
                                    lines?.includes(Lines.Amount)
                                        ? helper.formatCurrency(value, currency).split(".")[0]
                                        : value
                                }`
                            }
                        />
                        <Tooltip content={<CustomTooltip />} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    ) : null;
}

export default DashboardGraph;
