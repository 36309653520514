import { Modal } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setGiftcardState } from "../../../../slices/giftcards";
import GiftCardHistoryTable from "./gift-card-history-table";

const selector = (state: RootState) => ({
    accounts: state?.giftcards?.accounts,
    history: state?.giftcards?.history,
    title: state?.giftcards?.title,
});

function GiftCardHistoryModal(): JSX.Element {
    const { title, history } = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(setGiftcardState({ history: [] }));
    };

    return (
        <Modal
            visible={(history?.length ?? 0) > 0}
            onCancel={handleClose}
            width={1024}
            title={title + " " + intl.formatMessage({ id: "changes" })}
            footer={null}
        >
            <GiftCardHistoryTable />
        </Modal>
    );
}

export default GiftCardHistoryModal;
