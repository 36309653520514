const StoreNotifications = `
    printer_setting.auto_print_sp,                      auto_printer,           false;
    printer_setting.auto_print_browser,                 auto_browser,           false;
    printer_setting.sp_1st_lan,                         first_lan,              false;
    printer_setting.sp_2nd_lan,                         second_lan,             false;
    printer_setting.sp_model,                           printer_model,          false;
    printer_setting.sp_user,                            printer_username,       false;
    printer_setting.sp_pass,                            printer_password,       false;
    store_flg,         text_order_notification,                        false,          78;
    store_flg,         enable_notification_validation,                 false,          80;
`;

export default StoreNotifications;
