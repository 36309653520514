import React from "react";
import GoopterLogo from "../../../../../images/icons/official-goopter-logo.png";
import { View, Image, Text } from "@react-pdf/renderer";

const GoopterIcon = (styles: React.CSSProperties | any): JSX.Element => (
    <View style={styles.goopterContainer}>
        <View style={styles.poweredBy}>
            <Text>Powered by </Text>
        </View>
        <View style={styles.goopterLogo}>
            <Image src={GoopterLogo} />
        </View>
    </View>
);
export default GoopterIcon;
