import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { ResizableBox } from "react-resizable";

const RichTextEditor = ({ value, onChange, height = 200 }: Record<string, any>): JSX.Element => {
    const editor: any = useRef(null);
    return useMemo(
        () => (
            <ResizableBox
                width={Infinity}
                height={height}
                maxConstraints={[Infinity, Infinity]}
                minConstraints={[470, 200]}
            >
                <JoditEditor
                    ref={editor}
                    value={value}
                    onBlur={() => {
                        onChange(editor?.current?.value);
                    }}
                    config={{
                        buttons: [
                            "source",
                            "|",
                            "bold",
                            "strikethrough",
                            "underline",
                            "italic",
                            "|",
                            "ul",
                            "ol",
                            "|",
                            "outdent",
                            "indent",
                            "|",
                            "font",
                            "fontsize",
                            "paragraph",
                            "|",
                            "table",
                            "link",
                            "|",
                            "align",
                            "|",
                            "hr",
                            "eraser",
                            "|",
                            "symbol",
                            "fullsize",
                        ],
                        readonly: false,
                        //@ts-ignore
                        toolbarAdaptive: false,
                    }}
                />
            </ResizableBox>
        ),
        [value, onChange]
    );
};

export default RichTextEditor;
