import { Modal } from "antd";
import _ from "lodash";
import helper from "../../util/helper";
import config from "../../config";
import { getIntl } from "../../locale";
const NONE_FILLED = 0;
const SOME_FILLED = 1;
const ALL_FILLED = 2;

export function submitCategoryData({
    submittedValues,
    originalValues,
    languages,
    defaultLanguage,
    submitData,
}: Record<string, any>): void {
    const intl = getIntl();

    const getCategoryTitles = () => {
        const title: any = {};
        languages.forEach((language: any) => {
            title["category_title_" + language] = submittedValues?.["category_title_" + language];
        });
        return title;
    };
    const titleInAllLanguage = getCategoryTitles();
    Object.keys(submittedValues)
          .filter(key => key.includes("category_title_"))
          .forEach((key) => {
            if(!Object.keys(titleInAllLanguage).includes(key)){
                delete submittedValues[key];
            }
          });

    const checkIfTitleEmpty = () => {
        let numOfEmptyNames = 0;
        let status: number;
        for (const key in titleInAllLanguage) {
            if (_.isEmpty(titleInAllLanguage[key])) {
                numOfEmptyNames += 1;
            }
        }
        if (numOfEmptyNames === Object.keys(titleInAllLanguage).length) {
            // If title is empty in all languages
            return (status = NONE_FILLED);
        } else if (numOfEmptyNames < Object.keys(titleInAllLanguage).length && numOfEmptyNames !== 0) {
            // If title is only empty in one language
            return (status = SOME_FILLED);
        } else {
            // all languages have title
            status = ALL_FILLED;
            return status;
        }
    };
    const showWarning = () => {
        Modal.warning({
            title: intl.formatMessage({ id: "category_title" }),
            content: intl.formatMessage({ id: "category_title_empty_warning" }),
            onOk() {
                // empty function
            },
        });
    };

    const secondaryLanguages = { ...titleInAllLanguage };
    delete secondaryLanguages["category_title_" + defaultLanguage];
    switch (checkIfTitleEmpty()) {
        case NONE_FILLED:
            showWarning();
            break;
        case SOME_FILLED:
            if (titleInAllLanguage["category_title_" + defaultLanguage]) {
                Object.keys(secondaryLanguages).forEach((key) => {
                    titleInAllLanguage[key] = titleInAllLanguage["category_title_" + defaultLanguage];
                });
                Object.keys(submittedValues).forEach((key) => {
                    if (!submittedValues[key]) {
                        submittedValues[key] = titleInAllLanguage[key];
                    }
                });
                submitData(submittedValues);
            } else {
                showWarning();
            }
            break;
        case ALL_FILLED:
            if (originalValues) submitData(submittedValues);
            break;
        default:
    }
}

export const getCategoriesAsOptions = (categories: any = {}, language: string): any[] => {
    if (Array.isArray(categories)) {
        return (
            categories
                .filter((c) => c.level < config.CATEGORY_LEVELS.sub)
                .map((c) => ({
                    value: `${c.category_id}`,
                    label: helper.getTransString(c.name, language),
                })) ?? []
        );
    }
    return [];
};

export default {
    getCategoriesAsOptions,
    submitCategoryData,
};
