import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import "./tips-section.scss";
import TipsSelectButton from "./tips-select-button";
import TipsManualInput from "./tips-manual-input";
import { getTipsSetting } from "../../../helper";

class TipsSection extends React.Component {
    static defaultProps = {
        count: 0,
    };

    state = {};

    getStore = () => {
        return this.props.store;
    };

    getTotalValue = () => {
        return this.props.totalValue;
    };

    getButtonData = () => {
        const isPercent = this.getTotalValue() > getTipsSetting(this.getStore()).cut;
        const array = isPercent ? getTipsSetting(this.getStore()).percents : getTipsSetting(this.getStore()).amts;
        return array.map((value) => {
            return {
                tipsType: isPercent ? "percent" : "flat",
                value: value,
                width: 100 / array.length - 2,
            };
        });
    };

    renderTipsRow = () => {
        return (
            <div className="tips-section-title-text">
                <FormattedMessage id={"tips"} />
                <TipsManualInput />
            </div>
        );
    };

    renderButtonRow = () => {
        return (
            <div className="tips-button-list">
                {this.getButtonData().map((button, i) => {
                    const key = `tips-percentage-button-${button.value}-${i}`;
                    return <TipsSelectButton key={key} {...button} />;
                })}
            </div>
        );
    };

    render() {
        return (
            <div className="tips-section-container">
                {this.renderTipsRow()}
                {this.renderButtonRow()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    totalValue: _.get(state, "createOrder.payment.grandTotal", 0),
    store: _.get(state, "createOrder.storeInstance", {}),
    lan: _.get(state, "setting.lan", "en"),
});

const mapDispatchToProps = {
    //from service
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TipsSection));
