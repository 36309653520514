import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FormattedMessage } from "react-intl";

function NoRowsRender(): React.ReactChild {
    return (
        <div className="no-rows-items">
            <div className="no-rows-items-icon">
                <AiOutlineExclamationCircle />
            </div>
            <div className="no-rows-items-message">
                <FormattedMessage id="no_items" />
            </div>
        </div>
    );
}

export default NoRowsRender;
