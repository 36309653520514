import _ from "lodash";

function importAll(r: any) {
    const images: any = {};
    r.keys().forEach((item: any) => {
        images[_.trimStart(item, "./").split(".").slice(0, -1).join(".")] = r(item);
    });
    return images;
}
// @ts-ignore
const images = importAll(require.context("./icons", false, /\.(png|jpe?g|svg)$/));

export default images;
