import React from "react";
import { View, Image } from "@react-pdf/renderer";

const QR = (
    styles: React.CSSProperties | any,
    url: { dataUrl: string | any },
    props: { storeLogo: string | any }
): JSX.Element => (
    <View style={styles.qrHolder}>
        <View style={styles.qrBlock}>
            <Image src={url.dataUrl} />
        </View>
        <View style={styles.logo}>
            <Image src={props.storeLogo} />
        </View>
    </View>
);
export default QR;
