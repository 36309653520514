import { Button, Checkbox, Form, Input, Modal, Radio, Select, Space, Spin, Switch, Table, Tooltip } from "antd";
import config from "config";
import _, { isString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { RootState } from "../../../../../../app/reducer";
import {
    getDescBlock,
    getNumberInput,
    getSingleBlock,
    getSwitchDescBlock,
    getSwitchInput,
    getTextInput,
    mapBooleansToNumbers,
} from "../../../../../../components/form";
import useModal from "../../../../../../hooks/useModal";
import {
    appendDeliveryZoneTimeSlots,
    createUpdateDeliveryZoneTimeSlots,
    deleteDeliveryZoneTimeSlot,
    overwriteDeliveryZoneTimeSlots,
} from "../../../../../../services/pickup-delivery";
import { updateStoreDetails } from "../../../../../../services/store";
import { setEditId } from "../../../../../../slices/pickup-delivery";
import { setPickupDeliveryState, setThirdPartyDeliveryState, setStoreflag } from "../../../../../../slices/store";
import helper, {
    isBool,
    isNum,
    isRestaurantFromFlags,
    readTextFileFromInput,
    saveTextToFile,
    switchNum,
    convertInitialValuesToBool,
} from "../../../../../../util/helper";
import ObjectModel from "../../../../../../util/models";
import StorePickupDelivery from "../../../../../../util/models/store-pickup-delivery";
import { PREFERRED_DELIVERY_METHOD_VALUES, RESTORE_OPTIONS } from "../../constants";
import "./styles.scss";
import ZoneEditorModalContent from "./zone-editor-modal-content";

//type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
//enum TypeKey {"CAD","GBP","CNY","USD","EUR"}
//type Type = {[keyof in TypeKey]: string}
const currencySymbols: any = config.CURRENCY_SYMBOL;

const AVAILABILITY_KEY = "availability";
const DAYS_KEY = "days";
const MAX_DELIVERY_DISTANCE = 9999;

const DeliveryTab = (): JSX.Element | null => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const lan = useSelector((state: RootState) => state.setting?.lan);
    const initialValues = useSelector((state: RootState) => state.store?.storePickupDelivery);
    const specifyDelivery = useSelector((state: RootState) => state.store?.storePickupDelivery?.specify_delivery);
    const deliverySlots = useSelector((state: RootState) => state.pickupDelivery?.deliveryTimeSlots);
    const records = useSelector((state: RootState) => state.store?.records);
    const getAllow = useSelector((state: RootState) => state.store?.storePickupDelivery?.allow_delivery);
    const editId = useSelector((state: RootState) => state.pickupDelivery?.editId);
    const distanceUnit = useSelector((state: RootState) => state.store?.storePickupDelivery?.distance_unit);
    const store = useSelector((state: RootState) => state.store);
    const create_delivery_task = records?.store_flg.split(",");
    const isLoading = useSelector((state: RootState) => state.pickupDelivery?.isLoading);
    const storeCurrency: any =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const [specify, setSpecify] = useState(isBool(specifyDelivery));
    const [originalSpecify, setOriginalSpecify] = useState<boolean>();
    const { openModal, getModal, closeModal } = useModal();
    const [touched, setTouched] = useState(false);
    const [newSaving, setNewSaving] = useState(false);
    const [currUnit, setCurrUnit] = useState();
    const [showRestoreOptions, setShowRestoreOptions] = useState(false);
    const [restoreOption, setRestoreOption] = useState(RESTORE_OPTIONS.overwrite);
    const preferred_delivery_method = useSelector(
        (state: RootState) => state.store?.storePickupDelivery.preferred_delivery_method
    );
    const optional_delivery_method = useSelector(
        (state: RootState) => state.store?.storePickupDelivery.optional_delivery_method
    );
    const setState = (name: string, value: any) => {
        dispatch(setThirdPartyDeliveryState({ name, value }));
    };
    const [allow, setAllow] = useState(getAllow);
    const [showThirdPartyCheckbox, setShowThirdPartyCheckbox] = useState(preferred_delivery_method == "1");
    enum UNIT {
        KM = "1",
        MILE = "2",
    }
    const MIN_ZONE = 1;

    useEffect(() => {
        setOriginalSpecify(isBool(specifyDelivery));
    }, [specifyDelivery]);

    const resetFormatFields = useCallback(() => {
        const resetFields: any = [];
        const oldValues = form.getFieldsValue(true);
        Object.keys(oldValues).forEach((key) => (oldValues[key] === undefined ? delete oldValues[key] : {}));
        Object.keys(oldValues).forEach((key) => resetFields.push(key));
        form.resetFields(resetFields);
        form.setFieldsValue(oldValues);
    }, [form]);

    useEffect(() => {
        resetFormatFields();
    }, [resetFormatFields, lan]);

    useEffect(() => {
        setCurrUnit(distanceUnit);
    }, [initialValues, distanceUnit]);

    const getDeliverySlots = useCallback(() => {
        return deliverySlots?.filter((ds: any) => !Object.prototype.hasOwnProperty.call(ds, "pickup_location"));
    }, [deliverySlots]);

    useEffect(() => {
        if (getDeliverySlots()?.length < MIN_ZONE) {
            form.setFieldsValue({ "specify_delivery": false });
            setSpecify(false);
        } else {
            setSpecify(isBool(specifyDelivery));
        }
    }, [getDeliverySlots, deliverySlots, form, specifyDelivery]);

    useEffect(() => {
        setSpecify(initialValues?.specify_delivery === "1");
    }, [initialValues]);

    const onAllowChange = (checked: boolean) => {
        setAllow(isNum(checked));
    };

    const onFinish = (data: any) => {
        if (!currUnit || (currUnit !== UNIT.KM && currUnit !== UNIT.MILE)) {
            form.setFieldsValue({ "distance_unit": UNIT.KM });
        }
        if (specify && getDeliverySlots()?.length < MIN_ZONE) {
            Modal.error({ title: intl.formatMessage({ id: "need_atleast_address_for_multiple" }) });
        } else {
            setTouched(false);
            const mapped = mapBooleansToNumbers(data, ["allow_delivery", "specify_delivery"]);
            dispatch(
                updateStoreDetails(
                    new ObjectModel(StorePickupDelivery).convertToPostData(mapped, records, "pickup_delivery")
                )
            );
        }
    };

    const enableAndOrderAhead = (switcher: any, days: any) => (
        <div className="white-layered-block d-flex flex-row">
            <div className="switch-title">
                <FormattedMessage id="allow_delivery" />
                {switcher}
            </div>
            {allow === "1" && (
                <div className="switch-title">
                    <div className="mr-0">
                        <FormattedMessage id="order_ahead_days" />
                    </div>
                    <div className="toolTip-container mr-3">
                        <Tooltip title={intl.formatMessage({ id: "order_ahead_days_tip" })}>
                            <HiQuestionMarkCircle />
                        </Tooltip>
                    </div>
                    {days}
                </div>
            )}
        </div>
    );

    const switchSpecify = (e: any) => {
        setSpecify(e);
        dispatch(setPickupDeliveryState({ name: "specify_delivery", value: isNum(e).toString() }));
        form.setFieldsValue({ "specify_delivery": e });
        setTouched(e !== originalSpecify);
    };

    const getSwitchSpecify = () => (
        <Form.Item name={"specify_delivery"} style={{ marginBottom: "0px", marginLeft: "30px" }}>
            <Switch defaultChecked={isBool(specifyDelivery)} onChange={(e) => switchSpecify(e)} checked={specify} />
        </Form.Item>
    );

    const getKMOrMilesLabel = () => {
        const unit = currUnit ? currUnit : distanceUnit ?? UNIT.KM;
        return unit === UNIT.KM ? "km" : "miles";
    };

    const getRenderFormRemaing = () => {
        const preferredDeliveryMethod = _.get(initialValues, "preferred_delivery_method");
        const RENDER_VALUE_MAP: any = {
            [PREFERRED_DELIVERY_METHOD_VALUES.self_local_delivery]: renderSelfLocalDeliveryForm(),
            [PREFERRED_DELIVERY_METHOD_VALUES.third_party_courier]: null,
            [PREFERRED_DELIVERY_METHOD_VALUES.third_party_local]: null,
        };
        return RENDER_VALUE_MAP[preferredDeliveryMethod] || null;
    };

    const getPreferredDeliveryOptions = () => {
        const storeFlags = _.get(store, "records.store_flg", "");
        const isRestaurant = isRestaurantFromFlags(storeFlags);
        //remove courier option if restaurant
        const options: any = isRestaurant
            ? _.omit(_.cloneDeep(PREFERRED_DELIVERY_METHOD_VALUES), ["third_party_courier"])
            : PREFERRED_DELIVERY_METHOD_VALUES;

        return Object.keys(options).map((key) => ({
            label: <FormattedMessage id={key} />,
            value: options[key],
        }));
    };
    const thirdPartyCheckBox = () => {
        if (showThirdPartyCheckbox) {
            return (
                <div className="d-flex align-items-center w50">
                    <div className="local-switch font-weight-bold">
                        <FormattedMessage id="third_party_local_delivery_service" />
                    </div>
                    <Checkbox
                        style={{ margin: 10 }}
                        checked={create_delivery_task[94] == "1"}
                        onChange={(e) => {
                            setTouched(true);
                            create_delivery_task[94] = (+e.target.checked).toString();
                            dispatch(setStoreflag(create_delivery_task.join(",")));
                            dispatch(
                                setPickupDeliveryState({
                                    name: "optional_delivery_method",
                                    value: +e.target.checked,
                                })
                            );
                        }}
                    />
                </div>
            );
        }
    };

    const getSwitchOption = (title: string, name: string, value: any, onChange?: (e: boolean) => void) => {
        return (
            <div className="d-flex white-layered-block w100 flex-row align-center justify-between">
                {thirdPartyCheckBox()}
                <div className="d-flex align-items-center w50">
                    <div className="local-switch mr-2 font-weight-bold">
                        <FormattedMessage id={title} />
                    </div>
                    <Switch
                        className="ml-2"
                        checked={!!Number(value)}
                        disabled={!(optional_delivery_method == "1")}
                        onChange={(e) => {
                            if (onChange) {
                                setTouched(true);
                                onChange?.(e);
                            } else {
                                setTouched(true);
                                setState(name, switchNum(value));
                            }
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderForm = () => (
        <>
            {enableAndOrderAhead(
                getSwitchInput("allow_delivery", initialValues, onAllowChange),
                getNumberInput(
                    "order_ahead_days",
                    undefined,
                    false,
                    {
                        formatter: (value: any) => helper.formatDays(value, intl),
                    },
                    false,
                    "days",
                    "50px"
                )
            )}
            {allow === "1" && (
                <>
                    {getDescBlock(
                        "estimated_preparation_time",
                        true,
                        "estimated_preparation_time_desc",
                        getNumberInput(
                            "delivery_preparation_time",
                            undefined,
                            false,
                            {
                                formatter: (value: any) => helper.formatMinutes(value, intl),
                                max: 9999,
                            },
                            false,
                            "minutes",
                            "50px"
                        )
                    )}
                    {getDescBlock(
                        "prepare_order_threshold",
                        true,
                        "prepare_order_threshold_desc",
                        getNumberInput(
                            "prepare_order_threshold",
                            undefined,
                            false,
                            {
                                formatter: (value: any) => helper.formatMinutes(value, intl),
                                max: 9999,
                            },
                            false,
                            "minutes",
                            "50px"
                        )
                    )}
                    {getSwitchDescBlock("specify_delivery", "specify_delivery_desc", getSwitchSpecify())}
                    {!specify ? (
                        <>
                            {getDescBlock(
                                "preferred_delivery_method",
                                true,
                                " ",
                                <div className="thirdPartyExtraOption">
                                    <Form.Item name="preferred_delivery_method" style={{ marginBottom: "0px" }}>
                                        <Select
                                            value={preferred_delivery_method}
                                            onChange={(value) => {
                                                //if the value is "1" then show the checkbox else hide checkbox
                                                //value = preferred_delivery_method (preferred_deliver_method is the service dropdown menu)
                                                if (value == "1") {
                                                    setShowThirdPartyCheckbox(true);
                                                } else {
                                                    setShowThirdPartyCheckbox(false);
                                                }
                                                dispatch(
                                                    setPickupDeliveryState({
                                                        name: "preferred_delivery_method",
                                                        value: String(value),
                                                    })
                                                );
                                            }}
                                            style={{ width: 450 }}
                                            options={getPreferredDeliveryOptions()}
                                        />
                                    </Form.Item>
                                </div>
                            )}
                            {getSwitchOption(
                                "auto_create_delivery_task",
                                "deliveryxp.delivery_task",
                                create_delivery_task[
                                    config.STORE_FLAG_INDEX_MAP.create_3rdparty_delivery_task_on_accept_order
                                ],
                                (e: boolean) => {
                                    create_delivery_task[
                                        config.STORE_FLAG_INDEX_MAP.create_3rdparty_delivery_task_on_accept_order
                                    ] = String(+e);
                                    dispatch(setStoreflag(create_delivery_task.join(",")));
                                }
                            )}
                            {getSingleBlock(
                                "min_delivery_amount",
                                true,
                                getTextInput({
                                    name: "min_delivery_amount",
                                    prefix: currencySymbols[storeCurrency ?? "CAD"],
                                })
                            )}
                            {showThirdPartyCheckbox && getRenderFormRemaing()}
                        </>
                    ) : null}
                </>
            )}
        </>
    );

    const renderSelfLocalDeliveryForm = () => (
        <>
            {getSingleBlock(
                "flat_delivery_fee_distance",
                true,
                getNumberInput(
                    "flat_delivery_fee_distance",
                    undefined,
                    false,
                    { max: 9999 },
                    false,
                    getKMOrMilesLabel()
                )
            )}
            {getSingleBlock(
                "base_delivery_fee",
                true,
                getTextInput({
                    name: "base_delivery_fee",
                    prefix: currencySymbols[storeCurrency ?? "CAD"],
                })
            )}
            {getSingleBlock(
                currUnit === UNIT.KM ? "extra_dollar_per_km" : "extra_dollar_per_mile",
                true,
                getTextInput({
                    name: "extra_dollar_per_km",
                    prefix: currencySymbols[storeCurrency ?? "CAD"],
                })
            )}
            {getDescBlock(
                "delivery_time_range",
                true,
                "delivery_time_range_desc",
                getNumberInput(
                    "delivery_time_range",
                    undefined,
                    false,
                    {
                        formatter: (value: any) => helper.formatMinutes(value, intl),
                        max: 9999,
                        min: 5,
                    },
                    false,
                    "minutes",
                    "50px"
                )
            )}
            {getSingleBlock(
                "max_delivery_distance",
                true,
                getNumberInput(
                    "max_delivery_distance",
                    undefined,
                    false,
                    { max: MAX_DELIVERY_DISTANCE },
                    false,
                    getKMOrMilesLabel()
                )
            )}
            {getSingleBlock(
                "min_delivery_amount",
                true,
                getTextInput({
                    name: "min_delivery_amount",
                    prefix: currencySymbols[storeCurrency ?? "CAD"],
                })
            )}
        </>
    );

    function tConvert(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }

    const rowOnClick = (record: any) => {
        dispatch(setEditId(record?._id ?? null));
        openModal();
    };

    const renderDays = (_: any, record: any) => {
        return record?.availabilities?.map?.((a: any) => (
            <div key={`${DAYS_KEY}-${record._id}`} onClick={() => rowOnClick(record)}>
                {a?.days?.map?.((d: any) => (isString(d) ? d.charAt(0).toUpperCase() + d.slice(1) : ""))?.join?.(", ")}
            </div>
        ));
    };

    const renderAvailability = (_: any, record: any) => {
        let index = 0;
        return record?.availabilities?.map?.((a: any) => {
            index++;
            return (
                <div key={`${AVAILABILITY_KEY}-${record._id}`} onClick={() => rowOnClick(record)}>
                    {a?.hours?.map?.((h: any) => (
                        <div key={`${AVAILABILITY_KEY}-${record._id}-${index}`}>
                            {tConvert(h?.open)} - {tConvert(h?.close)}
                        </div>
                    ))}
                    {a?.hours === "ALL" ? <FormattedMessage id="all" /> : null}
                </div>
            );
        });
    };

    const renderActions = (_: any, record: any) => {
        return (
            <div className="clickable-row-parent">
                <div className="clickable-row" onClick={() => rowOnClick(record)}></div>
                <Button
                    style={{ marginRight: 12 }}
                    onClick={() => {
                        dispatch(setEditId(record?._id ?? null));
                        openModal();
                    }}
                >
                    <FormattedMessage id="update" />
                </Button>
                <Button
                    danger
                    onClick={() => {
                        Modal.confirm({
                            title: intl.formatMessage({ id: "confirm_delete" }),
                            content: intl.formatMessage({ id: "are_you_sure_delete_slot" }),
                            onOk: () => deleteDelivery(record?._id),
                        });
                    }}
                >
                    <FormattedMessage id="delete" />
                </Button>
            </div>
        );
    };

    const deleteDelivery = (id: any) => {
        if (getDeliverySlots()?.length === 1) {
            form.setFieldsValue({ "specify_delivery": false });
            setSpecify(false);
            setTouched(false !== originalSpecify);
        }
        dispatch(deleteDeliveryZoneTimeSlot(id));
    };

    const renderLocationName = (_: any, record: any) => (
        <div className="clickable-row-parent">
            <div className="clickable-row" onClick={() => rowOnClick(record)}>
                {record?.name}
            </div>
        </div>
    );

    const getTableColumns = () => [
        {
            title: intl.formatMessage({ id: "zone_name" }),
            render: renderLocationName,
        },
        {
            title: intl.formatMessage({ id: "days" }),
            render: renderDays,
        },
        {
            title: intl.formatMessage({ id: "availability" }),
            render: renderAvailability,
        },
        {
            title: intl.formatMessage({ id: "actions" }),
            render: renderActions,
        },
    ];

    const saveDeliverySettingsToFile = () => {
        saveTextToFile(JSON.stringify(getDeliverySlots()), "delivery-settings.json");
    };

    const restoreDeliverySlots = (e: any) => {
        const onLoad = (e: ProgressEvent<FileReader>) => {
            try {
                const result = e.target?.result;
                if (!result) {
                    return;
                }

                // @ts-ignore
                const data = JSON.parse(result);

                if (restoreOption === RESTORE_OPTIONS.overwrite) {
                    const deliveryTimeSlotIds: any[] = [];
                    getDeliverySlots().forEach((deliveryTimeSlot: any) => {
                        deliveryTimeSlotIds.push(deliveryTimeSlot._id);
                    });
                    dispatch(overwriteDeliveryZoneTimeSlots(data, deliveryTimeSlotIds));
                } else if (restoreOption === RESTORE_OPTIONS.append) {
                    dispatch(appendDeliveryZoneTimeSlots(data));
                }
            } catch (e) {
                // Handle error
            }
        };

        readTextFileFromInput(e, onLoad);
    };

    const renderRestoreOptionModal = () => {
        return (
            <Modal
                visible={showRestoreOptions}
                title={<FormattedMessage id="restore_options" />}
                onCancel={() => setShowRestoreOptions(false)}
                footer={[
                    <Button key="submit">
                        <label>
                            <FormattedMessage id="continue" />
                            <Input type="file" className="file-input" onChange={restoreDeliverySlots} accept=".json" />
                        </label>
                    </Button>,
                ]}
            >
                <FormattedMessage id="restore_options_message_delivery" />
                <div className="spacing-8" />
                <Radio.Group onChange={(e) => setRestoreOption(e.target.value)} value={restoreOption}>
                    <Space direction="vertical">
                        <Radio value={RESTORE_OPTIONS.overwrite}>
                            <FormattedMessage id="overwrite_existing_records" />
                        </Radio>
                        <Radio value={RESTORE_OPTIONS.append}>
                            <FormattedMessage id="append_to_existing_records" />
                        </Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        );
    };

    const renderTable = () => (
        <Spin spinning={isLoading}>
            <Table
                size="large"
                columns={getTableColumns()}
                dataSource={getDeliverySlots() ?? []}
                pagination={false}
                rowClassName="pickup-location-row"
                footer={() => {
                    return (
                        <div className="delivery-zone-table-footer">
                            <div>
                                <Button onClick={saveDeliverySettingsToFile}>
                                    <FormattedMessage id="backup" />
                                </Button>
                                <span className="h-spacing-8" />
                                <Button onClick={() => setShowRestoreOptions(true)}>
                                    <FormattedMessage id="restore" />
                                </Button>
                            </div>
                            <div>
                                <Button onClick={openModal} className="add-button-handler">
                                    <FormattedMessage id="add_delivery_availability" />
                                </Button>
                            </div>
                        </div>
                    );
                }}
            />
        </Spin>
    );

    const onSaveModal = (zone: any) => {
        setNewSaving(true);
        if (!zone.group_id) {
            zone.group_id = records.g_id;
        }
        dispatch(setEditId(null));
        dispatch(createUpdateDeliveryZoneTimeSlots(zone));
        closeModal();

        setTimeout(() => {
            setNewSaving(false);
        }, 1000);
        return true;
    };

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        setAllow(getAllow);
        form.resetFields();
        setTouched(false);
    };

    return !_.isEmpty(initialValues) && distanceUnit ? (
        <div className="display-options-page has-floating-submit">
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <Form
                name="delivery_settings"
                form={form}
                initialValues={convertInitialValuesToBool(initialValues)}
                onFinish={onFinish}
                onValuesChange={handleValuesChanged}
            >
                {renderForm()}
                {specify ? renderTable() : null}
                {specify
                    ? getSingleBlock(
                          "max_delivery_distance",
                          true,
                          getNumberInput(
                              "max_delivery_distance",
                              undefined,
                              false,
                              { max: MAX_DELIVERY_DISTANCE },
                              false,
                              getKMOrMilesLabel()
                          )
                      )
                    : null}
                <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                    <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!touched}
                        className="save-button-handler"
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </Form>

            {deliverySlots.map((slot: any) =>
                slot?._id === editId ? (
                    <>
                        {getModal(<ZoneEditorModalContent showMap={true} onSave={onSaveModal} />, {
                            title: intl.formatMessage({ id: "edit_zone" }),
                            width: 1368,
                            onCancel: () => dispatch(setEditId(null)),
                            footer: null,
                        })}
                    </>
                ) : null
            )}
            {editId === null && newSaving === false
                ? getModal(<ZoneEditorModalContent showMap={true} onSave={onSaveModal} />, {
                      title: intl.formatMessage({ id: "edit_zone" }),
                      width: 1368,
                      onCancel: () => dispatch(setEditId(null)),
                      footer: null,
                  })
                : null}
            {renderRestoreOptionModal()}
        </div>
    ) : null;
};

export default DeliveryTab;
