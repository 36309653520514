import { Input, Select } from "antd";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import { StoreSignUpStep, StoreStatus } from "../constants";
import { selectWithPlaceId } from "../../../util/helper/google";
import { getStoreTypes, createStore } from "../../../services/store";
import ObjectModel from "../../../util/models";
import StoreGeneralInfo from "../../../util/models/store-general-info";
import config from "../../../config";
import { getPricePlanList } from "../../../services/price-plan";
import useStoreSignUpOptions from "./useStoreSignUpOptions";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
    contact_name: yup.string().required(),
    contact_number: yup.string().matches(phoneRegExp).required(),
    current_revenue_optional: yup.string(),
    delivery: yup.number(),
    floor_suite_optional: yup.string(),
    number_of_locations: yup.string(),
    price_plan: yup.string().required(),
    product_display_languages: yup.array().required(),
    restaurant: yup.array(),
    service: yup.array(),
    shopping: yup.array(),
    store_address: yup.string().required(),
    store_description: yup.string().required(),
    store_name: yup.string().required(),
});

function useStoreSignUp(): any {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan, plans, records, store } = useSelector((state: RootState) => ({
        lan: state?.setting?.lan,
        plans: state?.pricePlan?.plans,
        records: state.store?.records,
        store: state.store,
    }));
    const {
        setValue,
        locationsOptions,
        deliveryOptions,
        languages,
        getGoogleOptions,
        getPlanOptions,
        getCategoryValues,
        getTypeOptions,
    } = useStoreSignUpOptions();
    const [step, setStep] = useState<StoreSignUpStep>(StoreSignUpStep.PricePlanContactDetails);
    const {
        control,
        handleSubmit,
        errors,
        watch,
        setValue: setFormValue,
        getValues,
    } = useForm<any>({
        resolver: yupResolver(schema),
    });
    const stableSetValue = useCallback(setValue, []);
    const stableGetCategoryValues = useCallback(getCategoryValues, []);
    const stableSetFormValue = useCallback(setFormValue, []);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const pricePlan = watch("price_plan");
    const selectedTypes = watch(["restaurant", "shopping", "service"]);
    const addressId = watch("store_address");

    const currentPlan = plans.find((plan) => Number(plan.id) === Number(pricePlan));

    const getTimeZoneFromAddress = async (address: any) => {
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${address?.geometry?.location?.lat?.()},${address?.geometry?.location?.lng?.()}&timestamp=${Math.round(
            new Date().getTime() / 1000
        ).toString()}&key=${config.GOOGLE_API_KEY}`;
        const response = await fetch(url);
        return (await response?.json())?.timeZoneId;
    };

    const onSubmit: any = async (data: any, review = false) => {
        await selectWithPlaceId(addressId, (address: any) => {
            getTimeZoneFromAddress(address).then((timeZone) => {
                const submit: any = {};
                const components = address?.address_components ?? [];
                submit.store_nm_en = data.store_name;
                submit.store_desc_en = data.store_description;
                submit.owner = data.contact_name;
                submit.phone = data.contact_number;
                submit.price_plan = data.price_plan;
                submit.pp = data.price_plan;
                submit.address_country_code = components.find((c: any) => c?.types?.includes("country"))?.short_name;
                submit.address_city = components.find((c: any) => c?.types?.includes("locality"))?.short_name;
                submit.address_province = components.find((c: any) =>
                    c?.types?.includes("administrative_area_level_1")
                )?.short_name;
                submit.address_postal_code = components.find((c: any) => c?.types?.includes("postal_code"))?.short_name;
                submit.address_lat = address?.geometry?.location?.lat?.();
                submit.address_lon = address?.geometry?.location?.lng?.();
                submit.address_street = `${
                    components.find((c: any) => c?.types?.includes("street_number"))?.short_name
                } ${components.find((c: any) => c?.types?.includes("route"))?.short_name}`;
                submit.address_time_zone = timeZone;

                let subCategories: any = [];

                ["restaurant", "shopping", "service"].forEach((key) => {
                    if (data[key]) {
                        subCategories = [...subCategories, ...data[key]];
                    }
                });

                submit.sub_categories = subCategories.join(":");
                const storeCategories = updateCategoryFlags(_.pick(data, ["restaurant", "shopping", "service"]));
                submit.store_categories = storeCategories;
                submit.c_id = storeCategories;

                if (review) {
                    submit.g_id = records?.g_id;
                    submit.store_status = StoreStatus.NeedApproval;
                }

                dispatch(
                    createStore(
                        new ObjectModel(StoreGeneralInfo).convertToPostData(submit, records, "store_info"),
                        () => {
                            //history.push("/storePendingReview");
                            setDisableSubmit(true);
                        }
                    )
                );
            });
        });
    };

    const submitForReview = () => {
        onSubmit(getValues(), true);
    };

    const updateCategoryFlags = (formData: any) => {
        const values: any = [];
        for (const [key, value] of Object.entries(config?.CATEGORY_MAPPING_TO_NUMBER)) {
            if (formData[key]) {
                values.push(value);
            }
        }
        return values.join(":");
    };

    const steps = [
        // Price Plan & Contact Details Step
        [
            [
                [
                    {
                        name: "price_plan",
                        component: Select,
                        options: getPlanOptions(),
                        props: {
                            placeholder: intl.formatMessage({ id: "price_plan" }),
                        },
                    },
                    {
                        name: "store_name",
                        component: Input,
                        props: {
                            placeholder: intl.formatMessage({ id: "store_name" }),
                        },
                    },
                ],
                [
                    {
                        name: "contact_name",
                        component: Input,
                        props: {
                            placeholder: intl.formatMessage({ id: "contact_name" }),
                        },
                    },
                    {
                        name: "contact_number",
                        component: Input,
                        props: {
                            placeholder: intl.formatMessage({ id: "contact_number" }),
                        },
                    },
                ],
            ],
        ],

        // General Information Step
        [
            [
                [
                    {
                        name: "store_address",
                        component: Select,
                        options: getGoogleOptions(),
                        props: {
                            placeholder: intl.formatMessage({ id: "store_address" }),
                            showSearch: true,
                            onSearch: setValue,
                            filterOption: (input: any, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                        },
                    },
                    {
                        name: "floor_suite_optional",
                        component: Input,
                        props: {
                            placeholder: intl.formatMessage({ id: "floor_suite_optional" }),
                        },
                    },
                ],
                [
                    {
                        name: "product_display_languages",
                        component: Select,
                        options: languages,
                        props: {
                            placeholder: intl.formatMessage({ id: "product_display_languages" }),
                            mode: "multiple",
                        },
                    },
                    {
                        name: "delivery",
                        component: Select,
                        options: deliveryOptions,
                        props: {
                            placeholder: intl.formatMessage({ id: "delivery" }),
                        },
                    },
                ],
                [
                    {
                        name: "number_of_locations",
                        component: Select,
                        options: locationsOptions,
                        props: {
                            placeholder: intl.formatMessage({ id: "number_of_locations" }),
                        },
                    },
                    {
                        name: "current_revenue_optional",
                        component: Select,
                        props: {
                            placeholder: intl.formatMessage({ id: "current_revenue_optional" }),
                        },
                    },
                ],
            ],
        ],

        // Store Type & Categories Step
        [
            [
                [
                    {
                        name: "restaurant",
                        component: Select,
                        options: getTypeOptions(config?.CATEGORY_MAPPING_TO_NUMBER?.["restaurant"]),
                        hide: selectedTypes?.service?.length || selectedTypes?.shopping?.length,
                        props: {
                            placeholder: intl.formatMessage({ id: "store_categories" }),
                            mode: "multiple",
                            showSearch: true,
                            filterOption: (input: any, option: any) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
                        },
                    },
                ],
                [
                    {
                        name: "shopping",
                        component: Select,
                        options: getTypeOptions(config?.CATEGORY_MAPPING_TO_NUMBER?.["shopping"]),
                        hide: selectedTypes?.restaurant?.length,
                        props: {
                            mode: "multiple",
                            showSearch: true,
                            placeholder: intl.formatMessage({ id: "store_categories" }),
                        },
                    },
                ],
                [
                    {
                        name: "service",
                        component: Select,
                        options: getTypeOptions(config?.CATEGORY_MAPPING_TO_NUMBER?.["service"]),
                        hide: selectedTypes?.restaurant?.length,
                        props: {
                            mode: "multiple",
                            showSearch: true,
                            placeholder: intl.formatMessage({ id: "store_categories" }),
                        },
                    },
                ],
                [
                    {
                        name: "store_description",
                        component: Input.TextArea,
                        props: {
                            placeholder: intl.formatMessage({ id: "store_description" }),
                        },
                    },
                ],
            ],
        ],
    ];

    useEffect(() => {
        dispatch(getPricePlanList({ lan }));
        dispatch(getStoreTypes({ lan }));
    }, [dispatch, lan]);

    useEffect(() => {
        stableSetFormValue("price_plan", plans?.[0]?.id?.toString());
    }, [stableSetFormValue, plans]);

    useEffect(() => {
        const types = stableGetCategoryValues();
        stableSetFormValue("store_name", store?.records?.general_info?.store_nm?.en ?? "");
        stableSetFormValue("store_description", store?.records?.general_info?.store_desc?.en ?? "");
        stableSetFormValue("contact_name", store?.records?.general_info?.owner ?? "");
        stableSetFormValue("contact_number", store?.records?.general_info?.phone ?? "");
        stableSetFormValue("product_display_languages", store?.records?.general_info?.lan_lst ?? []);
        stableSetFormValue("restaurant", types?.restaurant ?? []);
        stableSetFormValue("shopping", types?.shopping ?? []);
        stableSetFormValue("service", types?.service ?? []);
        stableSetValue(
            `${store?.records?.store_addr?.post_code} ${store?.records?.store_addr?.street}` ?? "",
            true,
            (data: any) => {
                stableSetFormValue("store_address", data[0]?.place_id);
            }
        );
    }, [stableGetCategoryValues, stableSetFormValue, stableSetValue, store]);

    return {
        step,
        errors,
        control,
        steps,
        records,
        currentPlan,
        setStep,
        handleSubmit,
        onSubmit,
        submitForReview,
        disableSubmit,
    };
}

export default useStoreSignUp;
