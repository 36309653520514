import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { FiFilter } from "react-icons/fi";
import FilterDrawer from "./filter-drawer";
import "./filter-section.scss";
import actions from "../../../../../slices/order-history";

class FilterSection extends Component {
    onFilterButtonClick = () => this.props.setState({ showFilterDrawer: true });

    render() {
        return (
            <>
                <div onClick={() => this.onFilterButtonClick()} className="order-list-controller-button-type-a">
                    <span className="order-list-controller-button-type-a-icon">
                        <FiFilter />
                    </span>
                    <FormattedMessage id="filter" />
                </div>
                <FilterDrawer />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterSection));
