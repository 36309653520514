import { createContext } from "react";

/**
 * IsMobileContext provides 'isMobile' function to determine if current device should use mobile layout
 * (or accomodate mobile device widths).
 * 
 * Here the default value and function signature for the context and 'isMobile' function is provided.
 * 
 * Usage:
 * Go to a top-level component, such as <Wrapper /> and wrap all its children/child components inside
 * <IsMobileContext.Provider value={}></IsMobileContext.Provider>, and there provide actual value of the context
 * inside 'value={}', such as 'value={{ isMobile: function(){ // logic here } }}'. 
 * 
 * All of those child components, and their sub-child components can access the 'isMobile' function
 * via const {isMobile} = useContext(IsMobileContext)
 */
export const IsMobileContext = createContext({
    isMobile: (): boolean => {
        return false;
    }
});