import { Modal, Button, Popconfirm } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteCustomerAddress, getCustomerAddresses, updateCustomerAddress } from "../../../../services/customers";
import helper from "../../../../util/helper";
import { FaRegCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import cx from "classnames";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import EditDetailModal from "./edit-customer-address-detail-modal";
import { validateAddress, DEFAULT_ADDRESS } from "../../helper";
import { getCustomerId } from "./helper";
import { MdCheck } from "react-icons/md";

class EditAddressModal extends Component {
    state = {};

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.getCid(prevProps) !== this.getCid() && this.getCid()) {
            this.getData();
        }
    }

    getCid = (props = this.props) => getCustomerId(props);

    getData = () => {
        this.props.getCustomerAddresses({
            customer_id: this.getCid(),
        });
    };

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    shouldOpen = () => this.props?.parentState?.openEditAddress;

    closeModal = () => {
        this.props.setParentState({ openEditAddress: false });
    };

    renderTitle = () => (
        <div className="edit-cusotmer-modal-title">
            <FormattedMessage id="edit_address" />
        </div>
    );

    renderAddressList = () => {
        const addresses = Array.isArray(this.props.addresses) ? this.props.addresses : [];
        return <div>{addresses.map((address) => this.renderAddress(address))}</div>;
    };

    renderAddress = (address) => {
        //name
        const name = `${_.get(address, "fn") ? `${_.get(address, "fn")} ` : ""}${_.get(address, "ln") || ""}`;
        //company
        const company = _.get(address, "company");
        //address line
        const addresstLine = `${_.get(address, "unit") ? `#${_.get(address, "unit")}-` : ""}${Object.values(
            _.pick(address, ["street", "city", "region", "zipcd"])
        ).join(", ")}`;
        //buzz
        const addressBuzzLine = `${_.get(address, "buzz") ? `Buzz: ${_.get(address, "buzz")}` : ""}`;
        //phone
        const phone = helper.formatPhone(_.get(address, "tn"));
        //buttons
        const renderButtons = () => {
            const isDefault = _.get(address, "is_default");

            const renderDefaultButton = (
                <div
                    onClick={() => {
                        if (!isDefault) {
                            this.props.updateCustomerAddress({
                                customer_id: this.getCid(),
                                id: _.get(address, "id"),
                                is_default: true,
                            });
                        }
                    }}
                    className={cx({
                        "edit-address-item-set-default-button": true,
                        "edit-address-item-default-button": isDefault,
                    })}
                >
                    <span
                        className={cx({
                            "edit-address-item-button-icon": true,
                            "edit-address-item-default-icon": isDefault,
                        })}
                    >
                        {isDefault ? <IoIosCheckmarkCircle /> : <FaRegCircle />}
                    </span>
                    <FormattedMessage id={isDefault ? "default" : "set_as_default"} />
                </div>
            );

            const renderModifyButton = (
                <div
                    onClick={() => {
                        this.setState({
                            default_address: {
                                ...DEFAULT_ADDRESS,
                                ...address,
                                placeId: Object.values(_.pick(address, ["street", "city", "region", "zipcd"])).join(
                                    ", "
                                ),
                            },
                            openEditDetail: true,
                        });
                    }}
                    className="edit-address-item-modify-button"
                >
                    <span className="edit-address-item-button-icon">
                        <FiEdit />
                    </span>
                    <FormattedMessage id={"modify"} />
                </div>
            );

            const renderDeleteButton = (
                <Popconfirm
                    title={this.str("are_you_sure")}
                    onConfirm={() => {
                        this.props.deleteCustomerAddress({
                            customer_id: this.getCid(),
                            id: _.get(address, "id"),
                        });
                    }}
                    okText={this.str("confirm")}
                    cancelText={this.str("cancel")}
                >
                    <div className="edit-address-item-delete-button">
                        <span className="edit-address-item-button-icon">
                            <FiTrash2 />
                        </span>
                        <FormattedMessage id={"delete"} />
                    </div>
                </Popconfirm>
            );

            return (
                <div className="edit-address-item-buttons">
                    {renderDefaultButton}
                    {renderModifyButton}
                    {renderDeleteButton}
                </div>
            );
        };

        const renderInfo = () => (
            <div>
                <div>
                    {name}&nbsp;&nbsp;{phone}
                </div>
                <div> {company || ""}</div>
                <div> {addresstLine}</div>
                <div> {addressBuzzLine || ""}</div>
            </div>
        );

        const renderSelectedSign = () => {
            const isSelected = this.props.selectedAddress?.id === address.id;
            return isSelected ? (
                <div className="selected-sign">
                    <MdCheck />
                </div>
            ) : null;
        };

        return (
            <div className="edit-address-list-item">
                <div
                    style={{ cursor: this.props.onAddressClick ? "pointer" : "initial" }}
                    onClick={() => (this.props.onAddressClick ? this.props.onAddressClick(address) : {})}
                    className="edit-address-list-item-upper-section"
                >
                    {renderInfo()}
                    {renderSelectedSign()}
                </div>
                {renderButtons()}
            </div>
        );
    };

    renderAddAddressButton = () => {
        return (
            <div
                onClick={() => {
                    this.setState({
                        default_address: DEFAULT_ADDRESS,
                        openEditDetail: true,
                    });
                }}
                className="edit-address-add-address"
            >
                <Button type="link" size="large">
                    <FormattedMessage id="add_address" />
                </Button>
            </div>
        );
    };

    renderEditDetailModal = () => {
        const validateAddressLocal = () => {
            const defaultAddress = this.state.default_address || {};
            const { errorFields } = validateAddress(defaultAddress);
            this.setState(errorFields);
        };
        return (
            <EditDetailModal
                onClose={() =>
                    this.props.onAddressClick
                        ? setTimeout(() => {
                              this.closeModal();
                          }, 200)
                        : {}
                }
                initialAddresValues={this.state.initialAddresValues}
                parentState={this.state}
                setParentState={(values) => {
                    this.setState(values, () => validateAddressLocal());
                }}
            />
        );
    };

    render() {
        return (
            <Modal
                destroyOnClose
                onCancel={() => this.closeModal()}
                visible={this.shouldOpen()}
                title={null}
                footer={null}
            >
                <div className="edit-customer-address-modal">
                    {this.renderTitle()}
                    {this.renderAddressList()}
                    {this.renderAddAddressButton()}
                    {this.renderEditDetailModal()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    addresses: state?.customers?.customerAddresses,
});

const mapDispatchToProps = {
    getCustomerAddresses,
    updateCustomerAddress,
    deleteCustomerAddress,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditAddressModal)));
