import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Collapse, Input } from "antd";
import Date from "../filter-section/filter-drawer-collapse-date";
import Checkboxes from "./export-order-filter-collapse-checkbox-group";
import { FILTER_KEYS } from "../../../helper-order-list";
import actions from "../../../../../slices/order-history";
import GroupPurchaseList from "../filter-section/group-purchase-list";

const { Panel } = Collapse;

class ExportDrawerCollapse extends Component {
    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getActiveKeys = () => (Array.isArray(this.state.activeKeys) ? this.state.activeKeys : []);

    onCollapseCallBack = (key) => {
        const keyValue = _.get(key, key.length - 1);
        if (keyValue === "sku_prefix") return;
        this.setState({ activeKeys: key });
    };

    getPanelDatas = () => {
        return [
            { title: "status", component: Checkboxes, props: { type: "status" } },
            { title: "type", component: Checkboxes, props: { type: "type" } },
            { title: "payment_method", component: Checkboxes, props: { type: "payment" } },
            { title: "date", component: Date },
            { title: "group_purchase_product", component: GroupPurchaseList },
            {
                title: "sku_prefix",
                titleComponent: this.renderSKUInput(),
                component: Date,
                hideArrow: true,
                hideCollapse: true,
            },
        ];
    };

    renderSKUInput = () => {
        return (
            <span className="export-order-modal-sku-input">
                <Input
                    style={{ width: 150 }}
                    allowClear
                    size="small"
                    onChange={(e) => {
                        const value = e.target.value;
                        this.setState({ [FILTER_KEYS.sku_prefix]: value });
                    }}
                    placeholder={this.str("type_here")}
                />
            </span>
        );
    };

    renderPanels = () => {
        return this.getPanelDatas().map((panel) => this.renderPanel(panel));
    };

    renderPanel = (panel) => {
        return (
            <Panel
                collapsible={!panel.hideCollapse}
                showArrow={!panel.hideArrow}
                header={
                    <div>
                        <FormattedMessage id={"filter_by_x"} values={{ x: this.str(panel.title) }} />
                        {panel.titleComponent}
                    </div>
                }
                key={panel.title}
            >
                <panel.component {...this.props} {...(panel.props ? panel.props : {})} />
            </Panel>
        );
    };

    render() {
        return (
            <Collapse
                activeKey={this.getActiveKeys()}
                expandIconPosition={"right"}
                onChange={(key) => this.onCollapseCallBack(key)}
            >
                {this.renderPanels()}
            </Collapse>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportDrawerCollapse));
