import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getShippingWithDefault } from "../pages/settings/pages/shipping-settings/helper";

const initialState: any = {
    storeInformation: {},
    storePaymentOptions: {
        require_bill_address: "0",
        alipay_is_from: 0,
        alipay_is_from_one: 1,
    },
    online: false,
    types: {},
    storeThirdPartyDelivery: {
        connected: false,
    },
    storeSelfServe: {
        openFormatModal: false,
        showDownloadModal: false,
    },
    setCustomText: false,
    loading: true,
    thirdPartyOption: 0,
    doordashDeliveryDetails: {
        estDeliveryFee: 0,
        doordashCreated: false,
        isCancelled: false,
        delivery_id: "",
        delivery_url: "",
        estDeliveryTime: "",
        cancelFailure: false,
        doordashCreatedFail: false,
        showInsufficientFunds: false,
    },
};

const slice = createSlice({
    name: "store",
    initialState,
    reducers: {
        setStoreState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setCheckoutState: (state, { payload }) => {
            const naCopy = { ...state.storeCheckout };
            _.set(naCopy, payload?.name, payload?.value);
            state.storeCheckout = naCopy;
        },
        setPickupDeliveryState: (state, { payload }) => {
            const naCopy = { ...state.storePickupDelivery };
            _.set(naCopy, payload?.name, payload?.value);
            state.storePickupDelivery = naCopy;
        },
        setNotifyState: (state, { payload }) => {
            const naCopy = { ...state.storeNotifications };
            _.set(naCopy, payload?.name, payload?.value);
            state.storeNotifications = naCopy;
        },
        setPublicNoticeState: (state, { payload }) => {
            const paCopy = { ...state.storePublicNotice };
            _.set(paCopy, payload?.name, payload?.value);
            state.storePublicNotice = paCopy;
        },
        setPaymentState: (state, { payload }) => {
            const payCopy = { ...state.storePaymentOptions };
            _.set(payCopy, payload?.name, payload?.value);
            state.storePaymentOptions = payCopy;
        },
        setEMTState: (state, { payload }) => {
            state.emt_setting = Object.assign(state.emt_setting, payload);
        },
        setSurchargeState: (state, { payload }) => {
            const surCopy = { ...state.storeSurcharge };
            _.set(surCopy, payload?.name, payload?.value);
            state.storeSurcharge = surCopy;
        },
        setAutoAcceptOrderState: (state, { payload }) => {
            const autoAccCopy = { ...state.autoAcceptOrder };
            _.set(autoAccCopy, payload?.name, payload?.value);
            state.autoAcceptOrder = autoAccCopy;
        },
        resetTipState: (state, { payload }) => {
            state.storeTipsSettings = payload;
        },
        setTipsState: (state, { payload }) => {
            const tipsCopy = { ...state.storeTipsSettings };
            _.set(tipsCopy, payload?.name, payload?.value);
            state.storeTipsSettings = tipsCopy;
        },
        setSelfServeState: (state, { payload }) => {
            const serveCopy = { ...state.storeSelfServe };
            _.set(serveCopy, payload?.name, payload?.value);
            state.storeSelfServe = serveCopy;
        },
        setGenerateQrState: (state, { payload }) => {
            const generateCopy = { ...state.storeGenerateQr };
            _.set(generateCopy, payload?.name, payload?.value);
            state.storeGenerateQr = generateCopy;
        },
        setShippingState: (state, { payload }) => {
            const shipCopy = { ...state.storeShipping };
            _.set(shipCopy, payload?.name, payload?.value);
            state.storeShipping = shipCopy;
            //saved the edited values
            const storeShippingCopy = state.storeShippingCopy || {};
            if (payload?.name === "tableRulesNewRule") {
                _.set(storeShippingCopy, "tableRules", payload?.value);
            } else if (payload?.name !== "tableRules") {
                _.set(storeShippingCopy, payload?.name, payload?.value);
            }
            state.storeShippingCopy = storeShippingCopy;
        },
        setReferralState: (state, { payload }) => {
            const refCopy = { ...state.storeReferrals };
            _.set(refCopy, payload?.name, payload?.value);
            state.storeReferrals = refCopy;
        },
        setThirdPartyDeliveryState: (state, { payload }) => {
            if (payload?.name !== "connected") {
                state.storeThirdPartyDelivery.connected = false;
            }
            const refCopy = { ...state.storeThirdPartyDelivery };
            _.set(refCopy, payload?.name, payload?.value);
            state.storeThirdPartyDelivery = refCopy;
        },
        setReviewBooster: (state, { payload }) => {
            const refCopy = { ...state.storeReviewBooster };
            _.set(refCopy, payload?.name, payload?.value);
            state.storeReviewBooster = refCopy;
        },
        getStoreDetailSuccess: (state, { payload }) => {
            state.storeInformation = payload?.storeInformation;
            state.storeSEO = payload?.storeSEO;
            state.storeImages = payload?.storeImages;
            state.storeCurrencyAndPricePlan = payload?.storeCurrencyAndPricePlan;
            state.storePublicNotice = payload?.storePublicNotice;
            state.storeDisplayOptions = payload?.storeDisplayOptions;
            state.storePaymentOptions = payload?.storePaymentOptions || {};
            state.storePaymentOptions.touched = false;
            state.storeBasic = payload?.storeBasic;
            state.storeRestaurantExtras = payload?.storeRestaurantExtras;
            state.storeTipsSettings = payload?.storeTipsSettings;
            state.storeHours = payload?.storeHours;
            state.storePickupDelivery = payload?.storePickupDelivery;
            state.storeSelfServe = payload?.storeSelfServe;
            state.storeGenerateQr = payload?.storeGenerateQr;
            state.storeAutoDiscounts = payload?.storeAutoDiscounts;
            state.storeNotifications = payload?.storeNotifications;
            //shipping
            const defaultShipping = getShippingWithDefault(payload?.storeShipping);
            state.storeShipping = Object.assign({}, payload?.storeShipping, defaultShipping);
            state.storeShippingCopy = defaultShipping;
            state.storeReferrals = payload?.storeReferrals;
            state.storeThirdPartyDelivery = payload?.storeThirdPartyDelivery;
            state.records = payload?.records;
            state.storeCheckout = payload?.storeCheckout;
            state.autoAcceptOrder = {
                ...state.autoAcceptOrder,
                auto_accept_order_enabled: payload?.autoAcceptOrder.auto_accept_order_enabled,
            };
            state.storeReviewBooster = payload?.storeReviewBooster;
        },
        getStoreDetailPartSuccess: (state, { payload }) => {
            Object.keys(payload || {}).forEach((key) => {
                const value = payload[key] || {};
                state[key] = {
                    ...state[key],
                    ...value,
                };
            });
        },
        setOnlineStatusSuccess: (state, { payload }) => {
            state.online = payload;
        },
        getStoreTypesSuccess: (state, { payload }) => {
            state.types = payload;
        },
        setThirdPartyOption: (state, { payload }) => {
            state.thirdPartyOption = payload;
        },
        setStoreflag: (state, { payload }) => {
            state.records.store_flg = payload;
        },
        setDoordashDeliveryDetails: (state, { payload }) => {
            const refCopy = { ...state.doordashDeliveryDetails };
            _.set(refCopy, payload?.name, payload?.value);
            state.doordashDeliveryDetails = refCopy;
        },
    },
});

export const {
    getStoreDetailSuccess,
    getStoreDetailPartSuccess,
    setPickupDeliveryState,
    setThirdPartyDeliveryState,
    setTipsState,
    resetTipState,
    setReferralState,
    setShippingState,
    setSelfServeState,
    setGenerateQrState,
    setSurchargeState,
    setAutoAcceptOrderState,
    setPublicNoticeState,
    setPaymentState,
    setEMTState,
    setNotifyState,
    setCheckoutState,
    setOnlineStatusSuccess,
    getStoreTypesSuccess,
    setStoreState,
    setThirdPartyOption,
    setStoreflag,
    setDoordashDeliveryDetails,
    setReviewBooster,
} = slice.actions;

export const store = slice.reducer;
