import { Descriptions, Modal } from "antd";
import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { getDescriptionItem } from "../../../../../components/form";
import helper from "../../../../../util/helper";

interface NewStoreInfoDialogProps {
    data: {
        admin_id: string,
        category_id: string,
        city: string,
        contact_name: string,
        country_code: string,
        created_at: string,
        currency: string,
        default_language: string,
        default_name: string,
        delivery_hours: string,
        discount_info: any,
        flag: string,
        language: string,
        lat: number,
        lon: number,
        name: string,
        open_hours: string,
        price_plan: string,
        province: string,
        signup_id: string,
        status: string,
        store_address: any,
        store_announcement: any,
        store_contact_number: string,
        store_description: string,
        time_zone: string,
        unit_number: string,
        updated_at: string,
        zip_code: string,
        vat_number: string,
    };
    setData: any;
}

function NewStoreInfoDialog(props: NewStoreInfoDialogProps): JSX.Element {
    const { data, setData } = props;
    const intl = useIntl();

    const getValueOrNA = (val: any) => {
        return val ? val : intl.formatMessage({ id: "n/a" });
    };

    return (
        <Modal
            visible={!_.isEmpty(data)}
            footer={null}
            title={intl.formatMessage({ id: "store" })}
            onCancel={() => {
                setData({});
            }}
            width="900px"
        >
            <div className="d-flex justify-content-around">
                <Descriptions bordered style={{ width: "400px" }}>
                    {getDescriptionItem("contact_name", 3, getValueOrNA(data?.contact_name))}
                    {getDescriptionItem("city", 3, getValueOrNA(data?.city))}
                    {getDescriptionItem("post_code", 3, getValueOrNA(data?.zip_code))}
                    {getDescriptionItem("vat_number", 3, getValueOrNA(data?.vat_number))}
                    {getDescriptionItem("latitude", 3, getValueOrNA(data?.lat))}
                    {getDescriptionItem("time_zone", 3, getValueOrNA(data?.time_zone))}
                    {getDescriptionItem("created_at", 3, getValueOrNA(data?.created_at))}
                    {getDescriptionItem("open_hour", 3, getValueOrNA(data?.open_hours))}
                    {getDescriptionItem("store_contact_number", 3, getValueOrNA(data?.store_contact_number))}
                </Descriptions>
                <Descriptions bordered style={{ width: "400px" }}>
                    {getDescriptionItem(
                        "store_address",
                        3,
                        getValueOrNA(data?.store_address ? helper.formatAddress(data?.store_address, {}) : null)
                    )}
                    {getDescriptionItem("province", 3, getValueOrNA(data?.province))}
                    {getDescriptionItem("unit", 3, getValueOrNA(data?.unit_number))}
                    {getDescriptionItem("country_code", 3, getValueOrNA(data?.country_code))}
                    {getDescriptionItem("longitude", 3, getValueOrNA(data?.lon))}
                    {getDescriptionItem("discount_info", 3, getValueOrNA(data?.discount_info))}
                    {getDescriptionItem("updated_at", 3, getValueOrNA(data?.updated_at))}
                    {getDescriptionItem("delivery_hours", 3, getValueOrNA(data?.delivery_hours))}
                    {getDescriptionItem("store_announcement", 3, getValueOrNA(data?.store_announcement))}
                    {getDescriptionItem("store_description", 3, getValueOrNA(data?.store_description))}
                </Descriptions>
            </div>
        </Modal>
    );
}

export default NewStoreInfoDialog;
