import { Drawer } from "antd";
import React from "react";
import ReferralsFilter from "./referrals-filter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { FormattedMessage } from "react-intl";

interface ReferralFilterDrawerProps {
    filter: boolean;
    setFilter: (p: boolean) => void;
}

function ReferralFilterDrawer(props: ReferralFilterDrawerProps): JSX.Element {
    const search = useSelector((state: RootState) => state?.referrals?.searchConditions);
    const { filter, setFilter } = props;

    return (
        <Drawer visible={filter} onClose={() => setFilter(false)} title={<FormattedMessage id="filter" />} width={380}>
            <ReferralsFilter search={search} closeModal={() => setFilter(false)} />
        </Drawer>
    );
}

export default ReferralFilterDrawer;
