import { RootState } from "app/reducer";
import React from "react";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { MessageModalPages } from "../../../../slices/delivery_request";

const PopUpMessageModalHeader: React.FC<PropsFromRedux> = ({ currPage }) => {
    const intl = useIntl();

    const renderLayout = (): React.ReactNode => {
        switch (currPage) {
            case MessageModalPages.UPDATE_DELIVERY_TASK_SUCESS:
                return <div>{intl.formatMessage({ id: "success" })}</div>;
            case MessageModalPages.INSUFFICIENT_FUND_ERR:
            case MessageModalPages.EXCEED_DELIVERY_DISTANCE_ERR:
            case MessageModalPages.FAILED_TO_CREATE_DELIVERY_TASK_ERR:
                return (
                    <>
                        <div>{intl.formatMessage({ id: "failed_to_create_delivery_message_line1" })}</div>
                        <div>{intl.formatMessage({ id: "failed_to_create_delivery_message_line2" })}</div>
                    </>
                );
            case MessageModalPages.UPDATE_DELIVERY_TASK_ERR:
                return <div>{intl.formatMessage({ id: "failed_to_update_delivery_task" })}</div>;
            case MessageModalPages.TRACK_DELIVERY_TASK_ERR:
                return <div>{intl.formatMessage({ id: "error" })}</div>;
            case MessageModalPages.DISCARD_UPDATE:
                return <div>{intl.formatMessage({ id: "discard_change" })}</div>;
            case MessageModalPages.DISCARD_NEW_TASK:
                return <div>{intl.formatMessage({ id: "discard_task" })}</div>;
            case MessageModalPages.MULTIPLE_TIME_WRRNING_DROPOFF:
            case MessageModalPages.MULTIPLE_TIME_WRRNING_PICKUP:
                return <div>{intl.formatMessage({ id: "warning" })}</div>;
            case MessageModalPages.TRACK_DELIVERY_TASK:
                return <div>{intl.formatMessage({ id: "track_delivery" })}</div>;
            case MessageModalPages.CANCEL_DELIVERY_TASK_CONFIRM:
                return <div>{intl.formatMessage({ id: "confim" })}</div>;
            case MessageModalPages.DELIVERY_FEE_DETAIL:
            case MessageModalPages.CANCELL_DELIVERY_TASK_ERR:
                return null;
            case MessageModalPages.PICKUP_TIME_PICKER:
                return (
                    <div style={{ fontWeight: "bold", width: "100%", textAlign: "center", fontSize: "16px" }}>
                        {intl.formatMessage({ id: "input_pickup_time" })}
                    </div>
                );
            case MessageModalPages.DROPOFF_TIME_PICKER:
                return (
                    <div style={{ fontWeight: "bold", width: "100%", textAlign: "center", fontSize: "16px" }}>
                        {intl.formatMessage({ id: "input_dropoff_time" })}
                    </div>
                );
            case MessageModalPages.NOT_AUTHORIZED_DELIVERY:
                return <div>{intl.formatMessage({ id: "not_authorized" })}</div>;
            default:
                return <div>{intl.formatMessage({ id: "error" })}</div>;
        }
    };

    return <>{renderLayout()}</>;
};

const mapStateToProps = (state: RootState) => {
    const messageModalState = state.deliveryRequests.modal.messageModal;
    return {
        currPage: messageModalState.currPage,
    };
};

const mapDispatchToProps = {};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(PopUpMessageModalHeader);
