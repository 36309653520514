import { Button, Collapse, Input } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import React, { ChangeEvent, ReactNode } from "react";
import { MdOutlineAdd, MdRefresh } from "react-icons/md";
import { useIntl } from "react-intl";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { getDeliveryTasks } from "services/delivery_request";
import {
    setCurrentModalPage,
    setIsFiltersCollapsed,
    setQuickSearchValue,
    setShouldModalOpen,
    TaskModalPages,
} from "../../../slices/delivery_request";
import "../style.scss";
import { buildSearchRequestBody } from "./helper";
import { FilterButtonsComponent } from "./index";

const _FILTER_COLLAPSE_KEY = "filters_btns";
const TableHeader: React.FC<PropsFromRedux> = ({
    searchConditions,
    setQuickSearchValue,
    getDeliveryTasks,
    paginations,
    setShouldModalOpen,
    setCurrentModalPage,
    setIsFiltersCollapsed,
    onActiveTask,
    isLoading,
}) => {
    const intl = useIntl();
    const is_super_admin = useSelector((state: RootState) => state.user?.roles)?.includes(999);

    const handleGetDeliveryTasks = (value?: string) => {
        const request = buildSearchRequestBody(paginations, onActiveTask);
        // for search text input and click the search button
        if (!_.isEmpty(value)) request.body.quick_search = value;
        getDeliveryTasks(request);
    };

    const renderRefreshButton = (): ReactNode => {
        const onRefreshBtnClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            handleGetDeliveryTasks();
        };

        return (
            <div className={`refresh-button-container`}>
                <span className="refresh-button-title">{intl.formatMessage({ id: "refresh" })}</span>
                <MdRefresh className={`${isLoading ? "spin" : ""} refresh-button-icon`} onClick={onRefreshBtnClick} />
            </div>
        );
    };

    const renderSearchBar = (): ReactNode => {
        const { quickSearch } = searchConditions;

        const handleOnSearch = (value: string) => {
            handleGetDeliveryTasks(value);
        };

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
            setQuickSearchValue(e.target.value);
        };

        return (
            <Input.Search
                width={"400px"}
                onSearch={handleOnSearch}
                allowClear={true}
                placeholder="Search"
                onChange={handleOnChange}
                value={quickSearch}
            />
        );
    };

    const renderNewTaskButton = (): ReactNode => {
        const handleNewTaskClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            setShouldModalOpen(true);
            setCurrentModalPage(TaskModalPages.NEW_TASK_MAIN);
        };
        return (
            <Button type="primary" onClick={handleNewTaskClick}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <MdOutlineAdd style={{ height: "20px", width: "20px" }} />
                    {intl.formatMessage({ id: "new_task_btn_label" })}
                </div>
            </Button>
        );
    };

    const renderPaymentInfo = (): ReactNode => {
        return (
            <div className="delivery-request-paymentinfo-container">
                <Input
                    className="delivery-request-paymentinfo"
                    disabled={true}
                    value={`${intl.formatMessage({ id: "balance" })}: ${paginations.balance.toFixed(2)}`}
                />
            </div>
        );
    };

    const renderAddBalance: () => ReactNode = (): ReactNode => {
        const handlAddBalanceClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            setShouldModalOpen(true);
            setCurrentModalPage(TaskModalPages.ADD_BALANCE);
        };
        return (
            <div style={{paddingLeft:20}}>
                <Button
                    type="primary"
                    onClick={handlAddBalanceClick}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <MdOutlineAdd style={{ height: "20px", width: "20px" }} />
                        {intl.formatMessage({ id: "add_balance" })} 
                    </div>
                </Button>
             </div>
        )
    }

    const renderCollapseHeader = () => (
        <div className="delivery-requset-title-container">
            <div className="delivery-requset-title-start-session">
                {renderRefreshButton()}
                {renderPaymentInfo()}
                {is_super_admin && renderAddBalance()}
            </div>
            <div className="delivery-requset-title-end-session">
                <div className="delivery-requset-search-bar-container">{renderSearchBar()}</div>
                {renderNewTaskButton()}
            </div>
        </div>
    );

    return (
        <Collapse
            expandIconPosition="right"
            ghost
            onChange={(v: string | string[]) => {
                setIsFiltersCollapsed(v.includes(_FILTER_COLLAPSE_KEY));
            }}
            className="delivery-table-header-container"
        >
            <Collapse.Panel
                key={_FILTER_COLLAPSE_KEY}
                header={renderCollapseHeader()}
                className="delivery-table-header-filter-buttons-container"
            >
                <FilterButtonsComponent />
            </Collapse.Panel>
        </Collapse>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        paginations: state.deliveryRequests.paginations,
        searchConditions: state.deliveryRequests.paginations.searchConditions,
        onActiveTask: state.deliveryRequests.onActiveTask,
        isLoading: state.deliveryRequests.isLoading,
    };
};

const mapDispatchToProps = {
    setQuickSearchValue,
    getDeliveryTasks,
    setShouldModalOpen,
    setCurrentModalPage,
    setIsFiltersCollapsed,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(TableHeader);
