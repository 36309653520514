import React, { useState } from "react";
import { Button } from "antd";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { RootState } from "../../../../app/reducer";
import { FormattedMessage } from "react-intl";
import h from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../services/admin-users";

const PasswordModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.adminUsers.user);
    const [passView, setPassView] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");

    const renderShowPassword = () => {
        const props = {
            className: "pHover mt-1 showPassword",
            onClick: () => setPassView(!passView),
            size: 25,
        };
        return passView ? <AiFillEye {...props} /> : <AiFillEyeInvisible {...props} />;
    };

    return (
        <div className="password-modal mt-3 mx-4">
            <div className="d-flex flex-column justify-content-start align-items-start">
                <div className="payment-subtitle mb-4">
                    <FormattedMessage id="change_password" />
                </div>
                <div className="w100 d-flex flex-column justify-content-start align-items-end">
                    <div className="d-flex w100 container-input-passview">
                        <input
                            type={passView ? "text" : "password"}
                            onChange={(e) => setPassword(e.target.value)}
                            className="custom-input-textfield w100 textLeft mb-2 mr-2"
                            value={password}
                            minLength={h.MIN_PASSWORD}
                        />
                        {renderShowPassword()}
                    </div>
                    {password.length < h.MIN_PASSWORD ? (
                        <span className="align-self-start">
                            <FormattedMessage id="ensure_password_six" />
                        </span>
                    ) : null}
                    <Button
                        type="primary"
                        disabled={password.length < h.MIN_PASSWORD}
                        className="mb-1 mt-2"
                        onClick={() => dispatch(changePassword({ password, id: user?.id }))}
                    >
                        <FormattedMessage id="change_password" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PasswordModal;
