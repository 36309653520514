import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    profile: {},
};

const slice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setAdminState: (state, { payload }) => {
            const adCopy = { ...state.profile };
            _.set(adCopy, payload.name, payload.value);
            state.profile = adCopy;
        },
        getAdminSuccess: (state, { payload }) => {
            state.profile = { ...payload.admin };
        },
    },
});

export const { setAdminState, getAdminSuccess } = slice.actions;

export const admin = slice.reducer;
