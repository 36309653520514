// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useRef, useState } from "react";
import { debounce as _debounce } from "../util/helper";
import useLatest from "./useLatest";

export const loadApiErr =
    "💡 use-places-autocomplete: Google Maps Places API library must be loaded. See: https://github.com/wellyshen/use-places-autocomplete#load-the-library";

type RequestOptions = Omit<google.maps.places.AutocompletionRequest, "input">;
export interface HookArgs {
    requestOptions?: RequestOptions;
    debounce?: number;
    googleMaps?: any;
    callbackName?: string;
    defaultValue?: string;
}
type Suggestion = google.maps.places.AutocompletePrediction;
interface Suggestions {
    loading: boolean;
    status: string;
    data: Suggestion[];
}
interface SetValue {
    (val: string, shouldFetchData?: boolean, cb?: any): void;
}
interface HookReturn {
    ready: boolean;
    value: string;
    suggestions: Suggestions;
    setValue: SetValue;
    clearSuggestions: () => void;
}

function emptyFunction() {
    // does nothing
}

const usePlacesAutocomplete = ({
    requestOptions,
    debounce = 200,
    googleMaps,
    callbackName,
    defaultValue = "",
}: HookArgs = {}): HookReturn => {
    const [ready, setReady] = useState<boolean>(false);
    const [value, setVal] = useState<string>(defaultValue);
    const [suggestions, setSuggestions] = useState<Suggestions>({
        loading: false,
        status: "",
        data: [],
    });
    const asRef = useRef(null);
    const requestOptionsRef = useLatest<RequestOptions | undefined>(requestOptions);
    const googleMapsRef = useLatest(googleMaps);

    const init = useCallback(() => {
        const { google } = window;
        const { current: gMaps } = googleMapsRef;
        const placesLib = gMaps?.places || google?.maps?.places;

        if (!placesLib) {
            console.error(loadApiErr);
            return;
        }

        asRef.current = new placesLib.AutocompleteService();
        setReady(true);
    }, []);

    const clearSuggestions = useCallback(() => {
        setSuggestions({ loading: false, status: "", data: [] });
    }, []);

    const fetchPredictions = useCallback(
        _debounce((val: string, cb = emptyFunction) => {
            if (!val) {
                clearSuggestions();
                return;
            }

            if (val.length > 4) {
                // To keep the previous suggestions
                setSuggestions((prevState) => ({ ...prevState, loading: true }));

                // @ts-expect-error
                asRef?.current?.getPlacePredictions?.(
                    { ...requestOptionsRef.current, input: val },
                    (data: Suggestion[] | null, status: string) => {
                        setSuggestions({ loading: false, status, data: data || [] });
                        cb(data);
                    }
                );
            }
        }, debounce),
        [debounce, clearSuggestions]
    );

    const setValue: SetValue = useCallback(
        (val, shouldFetchData = true, cb = emptyFunction) => {
            setVal(val);
            if (shouldFetchData) fetchPredictions(val, cb);
        },
        [fetchPredictions]
    );

    useEffect(() => {
        const { google } = window;

        if (!googleMapsRef.current && !google?.maps && callbackName) {
            window[callbackName] = init;
        } else {
            init();
        }

        return () => {
            // @ts-expect-error
            if (window[callbackName]) delete window[callbackName];
        };
    }, [callbackName, init]);

    return { ready, value, suggestions, setValue, clearSuggestions };
};

export default usePlacesAutocomplete;
