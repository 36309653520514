import _ from "lodash";
import moment from "moment";
import { getDescriptionItem } from "./../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, Switch, Descriptions, TimePicker } from "antd";
import h from "./helper";
import { updateStoreAvailability } from "./../../../../services/store-availability";
import {
    setTime,
    toggleOpenHours,
    addHours,
    removeHours,
    updateCodeName,
    nameCode,
    setShowEditModal,
} from "./../../../../slices/store-availability";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../../../app/reducer";
import "./../../index.scss";

const days: any = {
    MON: "mon",
    TUE: "tue",
    WED: "wed",
    THU: "thu",
    FRI: "fri",
    SAT: "sat",
    SUN: "sun",
};

const HoursSelect = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const codeIndex = useSelector((state: RootState) => state.storeAvailability.editCodeIndex);
    const thisCode = useSelector((state: RootState) => state.storeAvailability.timeSettings[codeIndex]);
    const addingCode = useSelector((state: RootState) => state.storeAvailability.addingCode);
    const original = thisCode;
    const TIME_STEP = 5;

    const getDayHours = (dayK: string) => {
        const day = dayK.substring(0, 2);
        const allHours = thisCode?.available_time;
        for (let i = 0; i < allHours?.length; i++) {
            for (let x = 0; x < allHours[i].days.length; x++) {
                if (allHours[i].days[x] === day) {
                    return { hours: allHours[i].hours, index1: i };
                }
            }
        }
        return [];
    };

    const getHourSelect = (value: any, onChange: any) => (
        <TimePicker
            format={"hh:mm A"}
            showNow={false}
            use12Hours
            minuteStep={TIME_STEP}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: "select_time" })}
            value={value ? moment(value, "hh:mm A") : undefined}
        />
    );

    const getHoursSelect = (dayL: string, hoursInfo: any) => {
        const hours = hoursInfo.hours ?? [];
        const day = dayL.substring(0, 2);
        const code = codeIndex;
        const removeHour = (i: any) => dispatch(removeHours({ code, day, i, hours }));
        const addHour = () => dispatch(addHours({ code, day, hours }));
        const toggleOpen = (value: any) => dispatch(toggleOpenHours({ code, day, value }));
        const changeHours = (value: any, open: number, i: any) => {
            const newTime = value ? moment(value, ["h:mm A"]).format("HH:mm") : undefined;
            const hourSet = _.cloneDeep(thisCode.available_time[findDay(thisCode.available_time)].hours);
            open === h.ACTIVE_CHANGE ? (hourSet[i].open = newTime) : (hourSet[i].close = newTime);
            dispatch(setTime({ hourSet, open, i, value, code, day }));
        };

        const findDay = (hourSet: any) => {
            for (let i = 0; i < hourSet.length; i++) {
                for (let x = 0; x < hourSet[i].days?.length; x++) {
                    if (hourSet[i].days[x] === day) return i;
                }
            }
            return 0;
        };

        // UNUSED FUNCTION: PRESERVED FOR FUTURE USE
        // const checkIfCloseBeforeOpen = (hourSets: any) => {
        //     let bad = false;
        //     let lastClose = "00:00";
        //     for (const hourSet of hourSets) {
        //         bad = moment(hourSet.open, "hh:mm").isAfter(moment(hourSet.close, "hh:mm")) ? true : bad;
        //         bad =
        //             hourSets.length > 1 && moment(lastClose, "hh:mm").isAfter(moment(hourSet.open, "hh:mm"))
        //                 ? true
        //                 : bad;
        //         lastClose = hourSet.close;
        //     }
        //     return bad;
        // };

        if (_.isEmpty(hours)) {
            return (
                <div className="hour-range-select">
                    <div className="hours-active">
                        <Switch checked={false} onChange={toggleOpen} />
                    </div>
                </div>
            );
        } else {
            return hours.map((dayhour: any, i: number) => {
                return (
                    <div key={`hour-range-select-${i}`} className="hour-range-select">
                        <div className="hours-active">
                            {i === 0 ? <Switch checked={true} onChange={toggleOpen} /> : null}
                        </div>
                        <div className="hours-range-option">
                            {getHourSelect(dayhour.open, (time: any, timeString: string) =>
                                changeHours(timeString, 1, i)
                            )}
                        </div>
                        <div className="hours-range-option">
                            {getHourSelect(dayhour.close, (time: any, timeString: string) =>
                                changeHours(timeString, 0, i)
                            )}
                        </div>
                        <div className="hours-actions">
                            {i !== 0 ? <Button onClick={() => removeHour(i)}>-</Button> : null}
                            {i === hours.length - 1 && i < 2 ? (
                                <Button onClick={() => addHour()} disabled={dayhour.open === "closed"}>
                                    +
                                </Button>
                            ) : null}
                        </div>
                    </div>
                );
            });
        }
    };

    const saveAvailCodeUpdates = () => {
        const newCode = addingCode ? 1 : 2;
        const availableTime = thisCode.available_time;
        let missingRange = false;
        availableTime.forEach((availability: any) => {
            availability?.hours?.forEach((period: any) => {
                if (!period.open || !period.close) {
                    missingRange = true;
                }
            });
        });
        if (missingRange) {
            return Modal.error({
                title: intl.formatMessage({ id: "missing_fields" }),
                content: intl.formatMessage({ id: "missing_time_ranges" }),
            });
        }

        if (thisCode.name && thisCode.code) {
            dispatch(setShowEditModal(0));
            dispatch(
                updateStoreAvailability({
                    action: newCode,
                    code: thisCode.code,
                    name: thisCode.name,
                    available_time: thisCode.available_time,
                })
            );
        } else if (thisCode.code) {
            Modal.error({ title: intl.formatMessage({ id: "empty_code_name_input" }) });
        } else {
            Modal.error({ title: intl.formatMessage({ id: "empty_code_input" }) });
        }
    };

    const getHoursSection = () => {
        const changeCodeName = (e: any) => dispatch(updateCodeName({ code: codeIndex, value: e.target.value }));
        const changeCode = (e: any) => dispatch(nameCode({ code: codeIndex, value: e.target.value }));

        return (
            <div className="hoursSection-container">
                <div className="form-title-description">
                    <div className="codeName-container">
                        <div className="codeNameInput-container">
                            <FormattedMessage id="code" />:
                            <input
                                className="codeInput-textbox"
                                maxLength={2}
                                disabled={!addingCode}
                                onChange={(e) => changeCode(e)}
                                value={thisCode?.code}
                            />
                        </div>
                        <div>
                            <FormattedMessage id="name" />:
                            <input
                                className="codeName-textbox"
                                maxLength={40}
                                onChange={(e) => changeCodeName(e)}
                                value={thisCode?.name}
                            />
                        </div>
                    </div>
                </div>
                <Descriptions bordered className="general-section">
                    {Object.keys(days).map((day) => {
                        return getDescriptionItem(days[day], 3, getHoursSelect(days[day], getDayHours(days[day])));
                    })}
                </Descriptions>
                <div className="editCodeModal-footer">
                    <Button
                        type="default"
                        size="large"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            if (!_.isEqual(original, thisCode)) {
                                Modal.confirm({
                                    title: intl.formatMessage({ id: "unsaved_changes" }),
                                    content: intl.formatMessage({ id: "sure_to_exit" }),
                                    onOk: () => {
                                        dispatch(setShowEditModal(0));
                                    },
                                });
                            } else {
                                dispatch(setShowEditModal(0));
                            }
                        }}
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => saveAvailCodeUpdates()}
                        className="save-button-handler"
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </div>
        );
    };

    return getHoursSection();
};

export default HoursSelect;
