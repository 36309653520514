import React, { Component } from "react";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import SearchStep from "./item-detail-editor-dialog-search-step";
import ReplaceItemStep from "./item-detail-editor-dialog-new-item-step";
import "./_replace-item-editor.scss";
import "app/goopter-styles/_buttons.scss";
import { Modal, Button } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import {
    sortChangeItems,
    ITEM_CHANGE_ACTION_ADD,
    ITEM_CHANGE_ACTION_CHECK_PRICES,
    ITEM_CHANGE_ACTION_POS_MODE,
    ITEM_CHANGE_ACTION_SEARCH_BAR,
} from "../helper";
import actions from "slices/live-order";
import { getDiffProperties, formatCurrency } from "util/helper";
import { IconContext } from "react-icons/lib";
import { IoMdRemoveCircleOutline, IoMdAddCircle } from "react-icons/io";
import _config from "config";
import { MAX_PRICE } from "app/constants";
import { Input, withStyles } from "@material-ui/core";
import InputNumber from "components/number-input";
import { getProductPrice } from "pages/create-order/helper";
import { setState as setCreateOrderState } from "slices/create-order";

const StyledInput = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 20,
        fontWeight: "bold",
    },
})(Input);

class ReplaceItemEditor extends Component {
    state = {
        collapseKey: 1,
        height: window.innerHeight,
        width: window.innerWidth,
    };

    componentDidMount() {
        window.addEventListener("resize", (e) => this.updateWindowDimensions(e));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", (e) => this.updateWindowDimensions(e));
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    getModalHeight = () => {
        const MODAL_HEIGHT_RATIO = 0.65;
        return this.state.height * MODAL_HEIGHT_RATIO;
    };

    getNewProduct = () => _.get(this.props, "state.itemChangeObject.new_product", {});

    getProductPrice = (item) => {
        return item.pc || item.pc == 0 ? item.pc : getProductPrice(item);
    };

    getOrderId = () => _.get(this.props, "order.ord_id", "");

    isOnStepSearch = () => !_.get(this.props, "state.itemChangeObject.new_product.pid");

    shouldShow = () => {
        return this.props.state.showItemEditDetailDialog;
    };

    getTitleId = () => {
        const changeAction = _.get(this.props, "state.itemChangeObject.action", {});
        const newProductPid = _.get(this.props, "state.itemChangeObject.new_product.pid", {});
        const originalProductPid = _.get(this.props, "state.itemChangeObject.pid", {});
        const i = _.get(this.props, "state.itemChangeObject.new_product.i");
        const withI = i === 0 || i > 0;
        const withAdd = String(_.get(this.props, "state.item.pid", "")).includes("add");
        if (newProductPid === originalProductPid || withI) {
            return "update_an_item";
        }
        if (changeAction === ITEM_CHANGE_ACTION_ADD || withAdd) {
            return "choose_an_item";
        }
        return "replace_an_item";
    };

    getWidth = () => {
        const maxWidth = this.isOnStepSearch() ? 700 : 545;
        const width = this.state.width * 0.9 > maxWidth ? maxWidth : this.state.width * 0.9;
        return width;
    };

    onCloseClick = () => {
        // make the products list as default
        const conditions = {
            keywords: "",
            page: 1,
            reset: true,
        };
        this.props.getProducts(conditions);
        this.props.setLiveOrderState({ itemEditDialogSearchInput: "" });
        this.props.setState({ showItemEditDetailDialog: false });
    };

    getItemChangeObject = () => _.get(this.props, "state.itemChangeObject", {});

    handleBackButtonClick = () => {
        let itemChangeObject = _.cloneDeep(this.getItemChangeObject());
        itemChangeObject.new_product = {};
        this.props.setState({ itemChangeObject });
    };

    renderBackButton = () => {
        const onSearch = this.isOnStepSearch();
        return !onSearch ? (
            <div className="item-back-button">
                <IoIosArrowBack onClick={this.handleBackButtonClick} />
            </div>
        ) : null;
    };

    renderTitle = () => {
        const onSearch = this.isOnStepSearch();

        // itemName can be an Object {en:"example",kr:"예시"} or string
        const newProductPid = _.get(this.props, "state.itemChangeObject.new_product.pid", {});
        const originalProductPid = _.get(this.props, "state.itemChangeObject.pid", {});
        let itemName = _.get(this.props, "state.itemChangeObject.new_product.nm", "");
        if (typeof itemName === "object") {
            itemName = _.get(itemName, this.props.lan) || Object.values(itemName)[0];
        }

        return (
            <div className="replace-item-editor-dialog-title">
                {newProductPid === originalProductPid ? null : this.renderBackButton()}
                {onSearch ? <FormattedMessage id={this.getTitleId()} /> : <span>{itemName} </span>}
            </div>
        );
    };

    renderQtyInput = () => {
        let item = _.cloneDeep(this.getNewProduct());
        const cntOrQty = _.get(item, "cnt") ? "cnt" : "qty";
        const onChange = (add, newQty) => {
            let itemChangeObject = _.cloneDeep(this.getItemChangeObject());
            const newQtyStr = newQty + " ";
            const newQtyChanged = newQtyStr.replace(/[^\d]/g, "")
                ? parseInt(newQtyStr.replace(/[^\d]/g, ""))
                : add
                ? ++item[cntOrQty]
                : --item[cntOrQty];
            item[cntOrQty] = newQtyChanged > 1 ? newQtyChanged : 1;
            itemChangeObject.new_product = item;
            this.props.setState({ itemChangeObject });
        };
        return (
            <div className="replace-item-select-button-group">
                <div className="replace-selection-item-button replace-removeProduct" onClick={() => onChange(false)}>
                    <IconContext.Provider value={{ size: "2em" }}>
                        <IoMdRemoveCircleOutline className="quick-add-remove-icon" />
                    </IconContext.Provider>
                </div>
                <div className="replace-item-select-quantity">
                    <StyledInput
                        id={"item-detail-editor-replace-item-quantity"}
                        onChange={(e) => {
                            onChange(false, e.target.value);
                        }}
                        value={item.cnt || item.qty}
                        inputProps={{
                            style: { textAlign: "center" },
                        }}
                    />
                </div>
                <div className="replace-selection-item-button replace-addProduct" onClick={() => onChange(true)}>
                    <IconContext.Provider value={{ size: "2em" }}>
                        <IoMdAddCircle className="quick-add-add-icon" />
                    </IconContext.Provider>
                </div>
            </div>
        );
    };

    renderCurrencyInput = () => {
        const { storeCurrency } = this.props;
        const symbol = _config.CURRENCY_SYMBOL[storeCurrency];
        let item = _.cloneDeep(this.getNewProduct());
        const onChange = (value) => {
            let itemChangeObject = _.cloneDeep(this.getItemChangeObject());
            item.pc = value;
            itemChangeObject.new_product = item;
            this.props.setState({ itemChangeObject });
        };
        return (
            <div className="replace-item-input">
                <InputNumber
                    id={`item-detail-editor-replace-item-input-${this.props.id}`}
                    placeholder={formatCurrency(0, this.props.storeCurrency, undefined, false)}
                    size="large"
                    min={0}
                    prefix={symbol}
                    style={{ width: "100%" }}
                    max={MAX_PRICE}
                    precision={2}
                    value={this.getProductPrice(item)}
                    onChange={onChange}
                />
            </div>
        );
    };

    getSharedProps = () => {
        const height = this.getModalHeight();
        return {
            height: height,
            ...this.props,
        };
    };

    addProductWithOptions = (currentItemChange) => {
        const items =
            currentItemChange.action === ITEM_CHANGE_ACTION_POS_MODE
                ? _.get(this.props, "items", [])
                : this.props.createOrderItems;
        const product = currentItemChange.new_product;
        let itemsClone = _.cloneDeep(items);
        if (_.isEmpty(this.props.itemsError)) {
            const newProduct = {
                item_id: product?.item_id,
                pid: product?.pid,
                ctids: product?.ctids,
                qty: product.cnt,
                opts: product.opts,
                nm: product?.nm,
                sku: product?.sku,
                sq: product?.sq,
                pc: product?.pc,
                pcIn: product?.pcIn,
            };

            const index = items.findIndex((product) => {
                return _.isEmpty(_.without(getDiffProperties(newProduct, product), "qty", "item_id"));
            });

            if (index === -1) {
                newProduct.qty = product.cnt;
                itemsClone.push(newProduct);
            } else {
                itemsClone[index].qty += product.cnt;
            }
        }
        if (currentItemChange.action === ITEM_CHANGE_ACTION_POS_MODE) {
            this.props.setParentState({ items: itemsClone });
        } else {
            this.props.setCreateOrderState({ items: itemsClone });
        }
    };

    // handle submit
    handleSubmit = () => {
        const stateItemChanges = _.get(this.props, "state.itemChanges", []);
        let currentItemChange = _.cloneDeep(_.get(this.props, "state.itemChangeObject", {}));

        if (
            currentItemChange.action === ITEM_CHANGE_ACTION_POS_MODE ||
            currentItemChange.action === ITEM_CHANGE_ACTION_SEARCH_BAR
        ) {
            this.addProductWithOptions(currentItemChange);
            this.props.setState({ showItemEditDetailDialog: false, itemChangeAction: ITEM_CHANGE_ACTION_POS_MODE });
            return;
        }
        if (!_.isEmpty(this.props.state?.optionsErrors)) return;

        //modifying the itemChanges
        let itemChanges = Array.isArray(stateItemChanges) ? _.cloneDeep(stateItemChanges) : [];
        //set the final price of the new item

        if (_.isNil(currentItemChange.new_product?.pc)) {
            currentItemChange.new_product.pc = this.getProductPrice(currentItemChange.new_product);
        }

        const index = _.findIndex(itemChanges, (item) => item.item_id === _.get(currentItemChange, "item_id"));
        //if some changes has been performed
        if (index !== -1) {
            itemChanges[index] = currentItemChange;
        } else {
            itemChanges.push(currentItemChange);
        }

        itemChanges = sortChangeItems(this.props.orderItems, itemChanges);
        const onEdit = !_.isEmpty(itemChanges) || !_.isEmpty(_.get(this.props, "order.item_changes", []));

        this.props.setState({
            showItemEditDialog: false,
            showItemEditDetailDialog: false,
            itemChanges: itemChanges,
            itemChangeAction: this.props.state.itemChangeObject.action,
            itemChangeObject: {},
            item: {},
            renderingItem: {},
            onEdit: onEdit,
        });
        if (this.getOrderId()) {
            this.props.updateSuggestions({
                order_id: this.getOrderId(),
                item_changes: itemChanges,
                action: ITEM_CHANGE_ACTION_CHECK_PRICES,
            });
        }
        if (this.props.onSubmit) {
            this.props.onSubmit({
                currentItemChange,
                itemChanges,
                onEdit,
            });
        }
    };

    getFooter = () => {
        const item = this.getNewProduct();
        const itemTotal = formatCurrency((item.cnt || item.qty) * this.getProductPrice(item), this.props.storeCurrency);

        if (!this.isOnStepSearch()) {
            return (
                <>
                    <div className="item-detail-editor-qty-item-price-row">
                        {this.renderQtyInput()}
                        {this.renderCurrencyInput()}
                    </div>
                    <Button key="submit" className="confirm-button" type="primary" onClick={() => this.handleSubmit()}>
                        <b>
                            <FormattedMessage id="confirm" />
                            <span>{` - ${itemTotal}`}</span>
                        </b>
                    </Button>
                </>
            );
        }
        return null;
    };

    render() {
        const height = this.getModalHeight();
        const onSearch = this.isOnStepSearch();
        return (
            <Modal
                className="item-detail-editor-dialog"
                maskClosable={false}
                bodyStyle={{ height: `${height}px` }}
                width={this.getWidth()}
                visible={this.shouldShow()}
                title={this.renderTitle()}
                onCancel={() => this.onCloseClick()}
                footer={this.getFooter()}
            >
                {onSearch ? <SearchStep {...this.getSharedProps()} /> : <ReplaceItemStep {...this.getSharedProps()} />}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    isGettingProductDetail: _.get(state, "liveOrders.isGettingProductDetail", {}),
    newPrices: _.get(state, "liveOrders.newPrices", {}),
    createOrderItems: _.get(state, "createOrder.items", []),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    updateSuggestions: actions.updateSuggestions,
    setLiveOrderState: actions.setState,
    setCreateOrderState,
    getProducts: actions.getProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReplaceItemEditor));
