import _ from "lodash";
import { BiTimeFive } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { Task as TaskType } from "../../../types/get-delivery-task-respone";
import { DUE_TIME_FORMAT, REQUEST_TIME_FORMAT, SHORT_TIME_FORMAT } from "../constants";
import "../style.scss";
import {
    getDasherContent,
    getDasherMessage,
    getEtaLabel,
    getEtaTime,
    getPaymentStatusByCode,
    getRequestTime,
    getStatusContainerStyle,
    getTaskStatusCategoryByCode,
    getTime,
} from "./helper";

interface TaskProps {
    task: TaskType;
    displayStyle: TaskStyle;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onActive: boolean;
    timesLot: Record<string, string[]>;
}

export enum TaskStyle {
    TABLE,
    FLEX,
}

export interface RowProps {
    inputDataPath?: string;
    className: string;
    renderLayout: (el: TaskType) => JSX.Element;
    style: {
        width: string;
        [key: string]: string;
    };
}

const _TASKS_CLASS_NAME_PREFIX = "delivery-task-";
const _TASKS_FLEX_CLASS_NAME_PREFIX = "delivery-task-active-";
const Task: React.FC<TaskProps> = ({ task, displayStyle, onClick, onActive, timesLot }) => {
    useIntl();

    /**
     * layout
     */

    const renderDueTime = (task: TaskType) => {
        const _ASAP = "2000-01-01 00:00:00";

        return task?.pickup_window_start_time?.includes?.(_ASAP) && task?.delivery_window_start_time?.includes?.(_ASAP)
            ? "ASAP"
            : getTime(
                  !_.isEmpty(task?.pickup_window_start_time) && !task?.pickup_window_start_time?.includes?.(_ASAP)
                      ? task?.pickup_window_start_time
                      : task?.delivery_window_start_time,
                  DUE_TIME_FORMAT
              );
    };

    const renderEta = (): React.ReactNode => {
        const [etaTime, isOverDue] = getEtaTime(
            Number(task.status) >= 240 ? task.estimated_delivery_time : task.estimated_pickup_time,
            task.status,
            task.last_update_dt
        );

        const color = ["", "red", "green"];
        return (
            <>
                <div className="title" style={{ color: getStatusContainerStyle(task).color }}>
                    {getEtaLabel(task.status)}
                </div>
                <div
                    className="content"
                    style={{
                        color: color[isOverDue],
                    }}
                >
                    {etaTime}
                </div>
            </>
        );
    };

    const _TASKS_LIST_ROW_LAYOUT_FLEX: RowProps[] = [
        {
            className: `${_TASKS_FLEX_CLASS_NAME_PREFIX}order-info`,
            style: { width: "100%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <span>{`#${task.order_number.substring(0, 4)}`}</span>
                        <div className="orderListDot" />
                        <span>{String(task.order_number).substring(String(task.order_number).length - 4)}</span>
                        <div className={"status"} style={getStatusContainerStyle(task)}>
                            <FormattedMessage id={getTaskStatusCategoryByCode(task.status)} />
                        </div>
                    </div>
                    <div className="content">{getTime(task.create_dt, DUE_TIME_FORMAT)}</div>
                </>
            ),
        },
        {
            className: `${_TASKS_FLEX_CLASS_NAME_PREFIX}due-time`,
            style: { width: "100%" },
            renderLayout: (task) => (
                <>
                    <div className="time-container">
                        <div className="title">
                            <FormattedMessage id={"due_time"} />
                        </div>
                        <div className="content">{renderDueTime(task)}</div>
                    </div>
                    <div className="time-container" style={{ textAlign: "right" }}>
                        {renderEta()}
                    </div>
                </>
            ),
        },
        {
            className: `${_TASKS_FLEX_CLASS_NAME_PREFIX}eta-time`,
            style: { width: "100%" },
            renderLayout: (task) => (
                <>
                    <div className="content-container">
                        <BiTimeFive
                            style={{
                                width: "30px",
                                height: "30px",
                                paddingRight: "4px",
                                paddingTop: "4px",
                                color: getStatusContainerStyle(task).color,
                            }}
                        />
                        <div className="content">
                            <div style={{ color: getStatusContainerStyle(task).color }}>
                                <FormattedMessage id={getDasherMessage(task.status)} />
                            </div>
                            <div style={{ color: getStatusContainerStyle(task).color }}>
                                {getDasherContent(task.status, task.dasher?.dasher_phone_number_for_customer)}
                            </div>
                        </div>
                    </div>
                    <div className="time-container">
                        <div className="time">
                            <div className="title">
                                <FormattedMessage id={"pick_up"} />
                            </div>
                            <div className="content">{getTime(task.estimated_pickup_time, SHORT_TIME_FORMAT)}</div>
                        </div>
                        <div className="time">
                            <div className="title">
                                <FormattedMessage id={"drop_off"} />
                            </div>
                            <div className="content">{getTime(task.estimated_delivery_time, SHORT_TIME_FORMAT)}</div>
                        </div>
                    </div>
                </>
            ),
        },
        {
            className: `${_TASKS_FLEX_CLASS_NAME_PREFIX}payment-info`,
            style: { width: "100%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <FormattedMessage id={getPaymentStatusByCode(task.pay_status)} />
                    </div>
                    <div className="content">{task.delivery_fee}</div>
                </>
            ),
        },
    ];

    const _TASKS_LIST_ROW_LAYOUT_TABLE: RowProps[] = [
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}order-info`,
            style: { width: "17%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <span>{`#${task.order_number.substring(0, 4)}`}</span>
                        <div className="orderListDot" />
                        <span>{String(task.order_number).substring(String(task.order_number).length - 4)}</span>
                    </div>
                    <div className="content">
                        <BiTimeFive style={{ width: "30px", height: "30px", paddingRight: "4px" }} />
                        <span style={{ whiteSpace: "pre" }}>
                            {getTime(getRequestTime(timesLot, task.create_dt), REQUEST_TIME_FORMAT)}
                        </span>
                    </div>
                </>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}due-time`,
            style: { width: "15%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <FormattedMessage id={"due_time"} />
                    </div>
                    <div className="content">{renderDueTime(task)}</div>
                </>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}delivery-status`,
            style: { width: "18%", display: "flex" },
            renderLayout: (task) => (
                <>
                    <BiTimeFive
                        style={{
                            width: "30px",
                            height: "30px",
                            paddingRight: "4px",
                            paddingTop: "4px",
                            color: getStatusContainerStyle(task).color,
                        }}
                    />
                    <div>
                        <div className="title" style={{ color: getStatusContainerStyle(task).color }}>
                            {getDasherMessage(task.status)}
                        </div>
                        <div className="content" style={{ color: getStatusContainerStyle(task).color }}>
                            {getDasherContent(task.status, task.dasher?.dasher_phone_number_for_customer)}
                        </div>
                    </div>
                </>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}eta`,
            style: { width: "10%" },
            renderLayout: () => <>{renderEta()}</>,
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}status`,
            style: { width: "10%" },
            renderLayout: (task) => (
                <div style={getStatusContainerStyle(task)} className="content">
                    <FormattedMessage id={getTaskStatusCategoryByCode(task.status)} />
                </div>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}eta ${_TASKS_CLASS_NAME_PREFIX}eta-time`,
            style: { width: "10%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <FormattedMessage id={"pick_up"} />
                    </div>
                    <div className="content">{getTime(task.estimated_pickup_time, SHORT_TIME_FORMAT)}</div>
                </>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}eta ${_TASKS_CLASS_NAME_PREFIX}eta-time`,
            style: { width: "10%" },
            renderLayout: (task) => (
                <>
                    <div className="title">
                        <FormattedMessage id={"drop_off"} />
                    </div>
                    <div className="content">{getTime(task.estimated_delivery_time, SHORT_TIME_FORMAT)}</div>
                </>
            ),
        },
        {
            className: `${_TASKS_CLASS_NAME_PREFIX}payment-info`,
            style: { width: "10%", display: "flex" },
            renderLayout: (task) => (
                <>
                    <span style={{ color: getStatusContainerStyle(task).color }}>
                        <FormattedMessage id={getPaymentStatusByCode(task.pay_status)} />
                    </span>
                    <div className="content">${task.delivery_fee}</div>
                </>
            ),
        },
    ];

    const Row: React.FC<RowProps> = ({ className, style, renderLayout }) => {
        return (
            <div className={className} onClick={onClick} style={style}>
                {renderLayout(task)}
            </div>
        );
    };

    const getLayout = () => {
        switch (displayStyle) {
            case TaskStyle.TABLE:
                return _TASKS_LIST_ROW_LAYOUT_TABLE;
            case TaskStyle.FLEX:
                return _TASKS_LIST_ROW_LAYOUT_FLEX;
            default:
                return [];
        }
    };

    const getClassName = () => {
        switch (displayStyle) {
            case TaskStyle.TABLE:
                return "task-container";
            case TaskStyle.FLEX:
                return "task-flex-container";
            default:
                return "";
        }
    };

    const getActiveClass = () => {
        const style: React.CSSProperties = {};
        if (onActive) style.borderLeft = `${getStatusContainerStyle(task).color} 5px solid`;
        return style;
    };

    return (
        <div className={getClassName()} style={getActiveClass()}>
            {getLayout().map((row: RowProps, i: number) => (
                <Row
                    key={`${task.delivery_id} ${i}`}
                    className={row.className}
                    renderLayout={row.renderLayout}
                    style={row.style}
                />
            ))}
        </div>
    );
};

export default Task;
