import React, { Component } from "react";
import _ from "lodash";
import OrderListTable from "./order-list-table-style";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import "./order-list-section.scss";
import { getStore } from "../helper-order-list";
import helper from "../../../util/helper";
import { getStoreTimeZone } from "../../liveorder/helper";
import OrderListList from "./order-list-list-style";

class OrderList extends Component {
    state = {};

    getHeight = () => {
        const minusRowIds = ["order-list-pagination-row"];
        var result = this.props.height ? this.props.height : 0;
        minusRowIds.forEach((id) => {
            const row = document.getElementById(id);
            if (row && row.offsetHeight) {
                result -= row.offsetHeight;
            }
        });
        return result;
    };

    getFormattedDataList = () => {
        const orderList = Array.isArray(this.props.orderList) ? this.props.orderList : [];
        let groupedOrderList = {};
        orderList.forEach((order) => {
            const store = getStore(order, this.props.storesData);
            const time = _.get(order, "ord_dt");
            const localOrderDate = helper.utcToLocal(time, {
                type: "YYYY-MM-DD",
                timeZone: getStoreTimeZone(store),
                time: false,
            });
            const groupedList = Array.isArray(_.get(groupedOrderList, `${localOrderDate}.list`, []))
                ? _.get(groupedOrderList, `${localOrderDate}.list`, [])
                : [];
            groupedList.push(order);
            groupedOrderList[localOrderDate] = {
                list: groupedList,
                title: {
                    date: localOrderDate,
                    list: groupedList,
                },
            };
        });

        const summary = _.get(this.props, "state.summary.summary_by_date")
            ? _.get(this.props, "state.summary.summary_by_date")
            : {};
        Object.keys(groupedOrderList).forEach((localDate) => {
            const dateIndex = _.findIndex(
                Object.keys(summary),
                (date) => helper.isString(date) && date.includes(localDate)
            );
            const dateValues = _.get(Object.values(summary), dateIndex, {});
            groupedOrderList[localDate].title.amtTotal = _.get(dateValues, "total", 0);
            groupedOrderList[localDate].title.orderTotal = _.get(dateValues, "count", 0);
        });

        return groupedOrderList;
    };

    getShareProps = () => ({
        height: this.getHeight(),
        orderList: this.getFormattedDataList(),
    });

    render() {
        return String(this.props.listStyle) === "list" ? (
            <OrderListList {...this.getShareProps()} />
        ) : (
            <OrderListTable {...this.getShareProps()} />
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    orderList: _.get(state, "orders-page.orderList", []),
    state: _.get(state, "orders-page", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    height: _.get(state, "orders-page.contentHeight", {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderList));
