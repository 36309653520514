import { FormattedMessage } from "react-intl";
import { Switch } from "antd";

interface EnableAutoAcceptOrderProps {
    checked: boolean;
    setState: any;
}

export const EnableAutoAcceptOrder = (props: EnableAutoAcceptOrderProps): JSX.Element => {
    return (
        <div className="white-single-block">
            <div className="surcharge-name-enable-wrapper">
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="option-title d-flex" style={{ marginBottom: "10px" }}>
                        <FormattedMessage id="auto_accept_order_enabled" />
                    </div>
                    <Switch
                        style={{ margin: "15px 0px" }}
                        checked={props.checked}
                        onChange={() => props.setState("auto_accept_order_enabled", props.checked ? 0 : 1)}
                    />
                </div>
            </div>
        </div>
    );
};
