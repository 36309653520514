import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import ExportModal from "./export-orders-modal";
import { ReactComponent as CSVDownload } from "../../../../../images/icons/csv-export.svg";
import actions from "../../../../../slices/order-history";
import { Dropdown, Menu } from "antd";

class ExportOrderSection extends Component {
    renderExportSelectOptions = () => {
        return (
            <Menu>
                <Menu.Item
                    onClick={() => {
                        this.props.setState({ useExportSelection: true, doneExportSelection: false });
                    }}
                >
                    <FormattedMessage id="export_select" />
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        this.props.setState({
                            showExportDialog: true,
                        });
                    }}
                >
                    <FormattedMessage id="export_filter" />
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        return [
            <Dropdown key="export-select-dropdown" overlay={this.renderExportSelectOptions()} placement={'bottomRight'}>
                <CSVDownload className="order-list-export-order-icon " />
            </Dropdown>,
            <ExportModal key="export-modal" />,
        ];
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportOrderSection));
