import { createSlice } from "@reduxjs/toolkit";
import { ContentPageResponse, ContentPageSummary } from "pages/settings/pages/content-pages/models/content-pages-responses";

interface ContentPageState {
    isFetching?: boolean
    isUpdating?: boolean
    contentPageList?: ContentPageSummary[]
    contentPageDetails?: ContentPageResponse
    message?: string
    redirectUrl?: string
}

const initialState: ContentPageState = {
    isFetching: false,
    isUpdating: false,
    contentPageList: [],
};

const slice = createSlice({
    name: "contentPages",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        updateContentPageListSuccess: (state) => {
            state.message = "updated_content_page_list_success"
        },
        getContentPageDetailsSuccess: (state, { payload }) => {
            state.contentPageDetails = payload
        },
        addContentPageSuccess: (state, { payload }) => {
            localStorage.setItem('@toast', "added_content_page_success")
            state.redirectUrl = payload.redirectUrl
        },
        updateContentPageSuccess: (state, { payload }) => {
            if (payload.redirectUrl) {
                state.message = "updated_content_page_success"
            } else {
                localStorage.setItem('@toast', "updated_content_page_success")
            }
            state.redirectUrl = payload.redirectUrl
        },
        deleteContentPageSuccess: (state) => {
            state.message = "deleted_content_page_success"
        },
        deleteContentPageUnsuccessful: (state) => {
            state.message = "delete_content_page_unsuccessful"
        }
    },
});

export const { setState, updateContentPageListSuccess, getContentPageDetailsSuccess, addContentPageSuccess, updateContentPageSuccess, deleteContentPageSuccess, deleteContentPageUnsuccessful } = slice.actions;

export const contentPages = slice.reducer;
