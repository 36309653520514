import React, { Component } from "react";
import { Input } from "antd";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import { groupList } from "../../helper";
import _actions from "../../../../slices/live-order";

class SearchBar extends Component {
    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getLiveOrder = () =>
        Array.isArray(_.get(this.props, "state.liveOrderList")) ? _.get(this.props, "state.liveOrderList") : [];

    getCurrentStyle = () => _.get(this.props, "state.listStyle", "list");

    onChange = (e) => {
        const value = e.target.value;
        const groupedList = groupList(this.getLiveOrder(), {
            ...this.props.state,
            filteredKeyword: value,
        });
        var allListArray = Object.values(groupedList);
        const flattenArray = Array.prototype.concat.apply([], allListArray);
        const firstOrderId = _.get(flattenArray, "0.ord_id");
        let updateObject = {
            groupedLiveOrderList: groupedList,
            activeId: firstOrderId,
            filteredKeyword: value,
        };
        if (firstOrderId && this.getCurrentStyle() === "list") {
            updateObject.expansionControllers = {
                [firstOrderId]: { showDetail: true },
            };
        }
        this.props.setState(updateObject);
    };

    render() {
        const value = _.get(this.props, "state.filteredKeyword", "");
        return (
            <div id="live-order-list-drawer-search-bar" className="live-order-list-drawer-search-bar">
                <Input
                    className="search-bar-input"
                    size="large"
                    value={value}
                    onChange={this.onChange}
                    prefix={<IoIosSearch className="search-bar-icon" />}
                    allowClear={true}
                    placeholder={this.str("search_order_live_order")}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
