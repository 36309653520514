import { Input, Modal } from "antd";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { ORDER_DETAIL_ACTIONS } from "../../../order-history/helper";
import "./_cancelModel.scss";

const { TextArea } = Input;

class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "Your order is cancelled",
        };
    }

    static defaultProps = {};

    onSubmitClick = () => {
        let { updateOrder, onCloseClick, order, onSubmitClick } = this.props;

        let { comment } = this.state;

        if (onSubmitClick) {
            onSubmitClick(comment);
        } else {
            updateOrder({
                order_id: order["ord_id"],
                action: ORDER_DETAIL_ACTIONS.to_cancel,
                comment: comment,
                withMessage: false,
            });
        }

        onCloseClick();
    };

    render() {
        let { displayModal, onCloseClick, intl } = this.props;
        let { formatMessage } = intl;

        return (
            <Modal
                width={400}
                title={formatMessage({ id: "enter_reason_for_rejection" })}
                visible={displayModal}
                onCancel={onCloseClick}
                cancelText={formatMessage({ id: "cancel" })}
                okText={formatMessage({ id: "reject_order" })}
                onOk={() => this.onSubmitClick()}
            >
                <div className="comment-modal-container">
                    <TextArea
                        rows={4}
                        value={this.state.comment}
                        className="comment-row"
                        onChange={(e) => this.setState({ comment: e.target.value })}
                    />
                </div>
            </Modal>
        );
    }
}

export default injectIntl(CancelModal);
