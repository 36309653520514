import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
    coordinates?: any,
} = {
    coordinates: {},
};

const slice = createSlice({
    name: "location",
    initialState,
    reducers: {
        getLocationSuccess: (state, { payload }: PayloadAction<any>) => {
            state.coordinates.lat = payload.lat;
            state.coordinates.lng = payload.lng;
        },
    },
});

export const { getLocationSuccess } = slice.actions;

export const location = slice.reducer;
