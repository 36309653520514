import React from "react";
import Wrapper from "../../components/wrapper";
import GiftCardTable from "./_components/gift-card-table";
import "./index.scss";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "giftCards",
            breadcrumbName: "gift_card",
        },
    ],
};

function GiftCardPage(): JSX.Element {
    return (
        <Wrapper helmet={{ title: "gift_card" }} breadcrumb={breadcrumb} paddingBottom={true}>
            <GiftCardTable />
        </Wrapper>
    );
}

export default GiftCardPage;
