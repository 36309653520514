import { Button, Input } from "antd";
import React from "react";
import qs from "querystring";
import _ from "lodash";
import { BiSliderAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import ReferralFilterDrawer from "./filter-drawer";
import { useDispatch } from "react-redux";
import { setReferralsState } from "../../../../slices/referrals";

function FilterBar(): JSX.Element {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const search = qs.parse(location.search.slice(1));
    const dispatch = useDispatch();

    const onSearch = (value: any) => {
        const query = _.cloneDeep(search);
        query.c_name = value;
        history.push("/referrals?" + qs.stringify(query));
    };

    const setFilter = (value: boolean) => {
        dispatch(
            setReferralsState({
                filterDrawer: value,
            })
        );
    };

    return (
        <div className="codes-search-bar d-flex ">
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <Input.Search
                            className="codes-search-input"
                            placeholder={intl.formatMessage({ id: "search_dot" })}
                            allowClear
                            onSearch={onSearch}
                            size="large"
                        />
                        <Button
                            className="filter-button"
                            icon={<BiSliderAlt size={20} />}
                            onClick={() => setFilter(true)}
                        >
                            <div className="icon-button-text d-inline">
                                <FormattedMessage id="filters" />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            <ReferralFilterDrawer />
        </div>
    );
}

export default FilterBar;
