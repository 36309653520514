import { Switch, Menu, Dropdown, Modal, Drawer } from "antd";
import { Button } from "antd/lib/radio";
import React, { useState } from "react";
import { HiCreditCard, HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../../../app/reducer";
import { deleteProduct, editProduct } from "../../../../services/products";
import helper from "../../../../util/helper";
import GiftCardActionBar from "./gift-card-action-bar";
import GiftCardFilter from "./gift-card-filter";
import GiftCardPagination from "./gift-card-pagination";
import qs from "querystring";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";
import { GIFT_CARD_TYPE, status } from "../../constants";
import { isEnabled } from "../../helper";

const selector = (state: RootState) => ({
    cards: state?.products?.products,
    lan: state?.setting?.lan,
    allowed: state?.pages?.allowed,
    storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
});

interface TableColumn {
    key?: string;
    render?: ((value: any, record: any) => JSX.Element) | ((value: any) => string);
    title?: any;
    className?: string;
}

function GiftCardTable(): JSX.Element {
    const { cards, lan, allowed, storeCurrency } = useSelector(selector);
    const history = useHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();
    const search = qs.parse(location.search.slice(1)) ?? {};
    const [filter, setFilter] = useState(false);

    const getSearch = (page?: any, limit?: any) => {
        return {
            ...search,
            product_type: GIFT_CARD_TYPE,
            paging: {
                page: page ?? search?.page ?? 1,
                limit: limit ?? search?.limit ?? DEFAULT_PAGE_SIZE,
            },
        };
    };

    const renderMenu = (value: any, record: any) => {
        const handleDelete = () => {
            Modal.confirm({
                title: intl.formatMessage({ id: "warning" }),
                content: intl.formatMessage({ id: "confirm_delete_product" }),
                onOk() {
                    dispatch(deleteProduct({ pid: record?.product_id, search: getSearch() }));
                },
            });
        };

        const menu = () => (
            <Menu>
                <Menu.Item
                    key="edit"
                    onClick={() => {
                        history.push("/giftCards/addEditGiftCard/" + record.product_id);
                    }}
                >
                    <FormattedMessage id="edit" />
                </Menu.Item>
                <Menu.Item key="delete" onClick={handleDelete} danger>
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu()}>
                <Button type="link">
                    <HiDotsHorizontal />
                </Button>
            </Dropdown>
        );
    };

    const renderStatus = (value: any, record: any) => (
        <Switch
            checked={isEnabled(record)}
            disabled={!allowed}
            onChange={() => {
                dispatch(
                    editProduct(
                        {
                            product_id: record.product_id,
                            status: isEnabled(record) ? status.DISABLED : status.ENABLED,
                        },
                        getSearch()
                    )
                );
            }}
        />
    );

    const columns: TableColumn[] = [
        {
            key: "name",
            render: (value: any) => helper.getTransString(value, lan),
        },
        {
            key: "sku",
        },
        {
            key: "price",
            render: (value: any) => helper.formatCurrency(value, storeCurrency),
        },
        {
            key: "stock",
        },
        {
            key: "bookvalue",
            render: (value: any) => helper.formatCurrency(value, storeCurrency),
        },
        {
            key: "status",
            render: renderStatus,
        },
        {
            key: "action",
            render: renderMenu,
        },
    ];

    // @ts-ignore
    const getColumn = (column: TableColumn, card: any) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            <div className="gift-card-column-content clickable-row-parent">
                <div
                    className="clickable-row"
                    onClick={() => history.push("/giftCards/addEditGiftCard/" + card.product_id)}
                ></div>
                {/* @ts-ignore */}
                {column?.render ? column?.render(card?.[column?.key], card) : card?.[column?.key]}
            </div>
        </div>
    );

    const getColumnHeader = (column: TableColumn) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            <div className={"gift-card-column-header header-border-top " + column?.className ?? ""} key={column.key}>
                {column?.title ? column.title : <FormattedMessage id={column?.key} />}
            </div>
        </div>
    );

    const renderColumnHeaderRow = (columns: TableColumn[]) => (
        <div className="gift-card-row">{columns.map((column) => getColumnHeader(column))}</div>
    );

    const getTable = () => (
        <>
            {renderColumnHeaderRow(columns)}
            {cards?.length ? (
                cards?.map((card) => (
                    <div className="gift-card-row gift-card-row-entry" key={card.product_id}>
                        {columns.map((column) => getColumn(column, card))}
                    </div>
                ))
            ) : (
                <div className="inactive-empty-warn w100">
                    <HiCreditCard size="60" color="lightgrey" className="mb-2" />
                    <FormattedMessage id="no_gift_cards" />
                </div>
            )}
        </>
    );

    const getFilterDrawer = () => (
        <Drawer visible={filter} onClose={() => setFilter(false)} title={<FormattedMessage id="filter" />} width={380}>
            <GiftCardFilter search={search} closeModal={() => setFilter(false)} />
        </Drawer>
    );

    return (
        <div className="gift-card-table">
            <GiftCardActionBar setFilter={setFilter} />
            {getFilterDrawer()}
            {getTable()}
            <GiftCardPagination />
        </div>
    );
}

export default GiftCardTable;
