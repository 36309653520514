import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Input, Button, Checkbox, Drawer, Row, Col, Dropdown, Menu, Radio, Space, RadioChangeEvent } from "antd";
import RefreshButton from "components/refresh";
import { setState } from "slices/quotes";
import { getQuotes } from "services/quotes";
import _ from "lodash";
import BulkUpdate from "./bulk-update";
import { setCustomersState } from "slices/customers";
import { clearData } from "slices/create-order";
import { IoIosList, IoMdGrid, IoIosSwap } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Quote } from "../../models/quote";
import { QuotesRequestBody } from "../../models/get-quotes-request";
import QuotesFilter from "../quotes-filter";
import { BiSliderAlt } from "react-icons/bi";
import config from "config";

const SEARCH_DELAY_TIME = 500;
const QUOTES_FILTER_DRAWER_WIDTH = 380;

const ASCENDING_ID = 1;
const DESCENDING_ID = 2;
const ASCENDING_LAST_UPDATED_DATE = 3;
const DESCENDING_LAST_UPDATED_DATE = 4;
const ASCENDING_PRICE = 5;
const DESCENDING_PRICE = 6;
const ASCENDING_NAME = 7;
const DESCENDING_NAME = 8;

const SORT_OPTIONS = [
    { value: ASCENDING_ID, keyStringID: "id", descriptionStringID: "ascending" },
    { value: DESCENDING_ID, keyStringID: "id", descriptionStringID: "descending" },
    { value: ASCENDING_LAST_UPDATED_DATE, keyStringID: "last_updated", descriptionStringID: "oldest_first" },
    { value: DESCENDING_LAST_UPDATED_DATE, keyStringID: "last_updated", descriptionStringID: "newest_first" },
    { value: ASCENDING_PRICE, keyStringID: "price", descriptionStringID: "low_to_high" },
    { value: DESCENDING_PRICE, keyStringID: "price", descriptionStringID: "high_to_low" },
    { value: ASCENDING_NAME, keyStringID: "name", descriptionStringID: "a_to_z" },
    { value: DESCENDING_NAME, keyStringID: "name", descriptionStringID: "z_to_a" },
];

class ActionBar extends Component<any, any> {
    state = { showFilter: false, sortValue: DESCENDING_LAST_UPDATED_DATE, enableSortButton: false };
    timers: any[] = [];

    componentDidUpdate = (prevProps: any) => {
        // If the filter options changes, send a request to the api for the new set of quotes
        if (!_.isEqual(prevProps.filterOptions, this.props.filterOptions)) {
            this.props.getQuotes({
                ...this.props.filterOptions,
                lan: this.props.lan,
            });
        }
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    toggleListGridView = () => {
        this.props.setState({ useListView: !this.props.useListView });
    };

    /**
     * Edit mode is on if edit mode is actually turned on by the user or
     * if the user has already selected quotes
     *
     * @returns whether quotes can be bulk edited
     */
    isEditModeOn = () => {
        return this.props.isEditMode || !_.isEmpty(this.props.selectedQuotes);
    };

    toggleEditMode = () => {
        if (this.isEditModeOn()) {
            // if edit mode is being turned off, remove selected quotes
            this.props.setState({ selected: [], isEditMode: false });
        } else {
            this.props.setState({ isEditMode: true });
        }
    };

    /**
     * If the checkbox is changed to checked, set selected to contain
     * all the quote ids on the current page
     *
     * @param e checkbox change event
     */
    handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const allSelection: string[] = [];
            this.props.quotes.forEach((quote: Quote) => {
                allSelection.push(quote.quote_id);
            });
            this.props.setState({ selected: allSelection });
        } else {
            this.props.setState({ selected: [] });
        }
    };

    updateFilteredQuotes = (body: QuotesRequestBody) => {
        this.props.setState({ filterOptions: body });
    };

    /**
     * Search for quotes based on specific keywords after the user has stopped typing for
     * certain amount of time
     *
     * @param e input changed event
     */
    handleSearchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params: QuotesRequestBody = {
            ...this.props.filterOptions,
            quick_search: e.target.value,
        };

        if (!_.isEmpty(this.timers)) {
            clearTimeout(this.timers.pop());
        }
        this.timers.push(setTimeout(() => this.updateFilteredQuotes(params), SEARCH_DELAY_TIME));
    };

    renderSearch = () => {
        return (
            <div className="action-left">
                <Input
                    size="large"
                    placeholder={this.str("type_to_search")}
                    onChange={(e) => this.handleSearchByKeyword(e)}
                    allowClear
                />
            </div>
        );
    };

    renderListGridToggle = () => {
        return (
            <div className="quotes-icon-container">
                <IconContext.Provider value={{ size: "1.5em" }}>
                    {this.props.useListView ? (
                        <IoMdGrid className="list-grid-toggle" onClick={this.toggleListGridView} />
                    ) : (
                        <IoIosList className="list-grid-toggle" onClick={this.toggleListGridView} />
                    )}
                </IconContext.Provider>
            </div>
        );
    };

    renderEditModeButton = () => {
        return (
            <Button
                className="quotes-button"
                type="default"
                size="large"
                onClick={() => {
                    this.toggleEditMode();
                }}
            >
                <FormattedMessage id={this.isEditModeOn() ? "cancel" : "edit"} />
            </Button>
        );
    };

    renderRefreshButton = () => {
        return (
            <RefreshButton
                refreshPage={() => {
                    this.props.getQuotes({ sortd: this.props.defaultSortOption, lan: this.props.lan });
                }}
                loading={this.props.gettingQuotes}
            />
        );
    };

    renderAddButton = () => {
        return (
            <Button
                className="add-button-handler quotes-button"
                type="default"
                size="large"
                onClick={() => {
                    this.props.setState({
                        shippingOptionsModal: true,
                    });
                }}
            >
                <FormattedMessage id="create_order" />
            </Button>
        );
    };

    renderFiltersButton = () => {
        return (
            <Button
                className="quotes-filter-button"
                type="default"
                size="large"
                icon={<BiSliderAlt className="quotes-filter-icon" size={20} />}
                onClick={() => this.setState({ showFilter: true })}
            >
                <FormattedMessage id="filters" />
            </Button>
        );
    };

    /**
     * Sort quotes by specified field and in ascending/descending order
     *
     * @param field quote field to sort by
     * @param ascending sort in ascending order if true, otherwise sort in descending order
     */
    handleSort = (field: string, ascending: boolean) => {
        const params: QuotesRequestBody = {
            ...this.props.filterOptions,
        };
        if (ascending) {
            params.sorta = field;
            params.sortd = undefined;
        } else {
            params.sortd = field;
            params.sorta = undefined;
        }

        this.updateFilteredQuotes(params);
    };

    handleRadioSort = (e: RadioChangeEvent) => {
        switch (e.target.value) {
            case ASCENDING_ID:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.qid, true);
                break;
            case DESCENDING_ID:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.qid, false);
                break;
            case ASCENDING_LAST_UPDATED_DATE:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.updated_at, true);
                break;
            case DESCENDING_LAST_UPDATED_DATE:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.updated_at, false);
                break;
            case ASCENDING_PRICE:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.g_total, true);
                break;
            case DESCENDING_PRICE:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.g_total, false);
                break;
            case ASCENDING_NAME:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.customer_firstname, true);
                break;
            case DESCENDING_NAME:
                this.handleSort(config.QUOTE_API_SORTABLE_FIELDS.customer_firstname, false);
                break;
        }
        this.setState({ sortValue: e.target.value, enableSortButton: true });
    };

    /**
     * When sort radion button clicked,
     * if the button is already clicked, remove it and reset the current sort
     *
     * @param event - click event
     */
    handleSortRadioButtonClicked = (event: any) => {
        const DFAULT_SORT_VALUE = DESCENDING_LAST_UPDATED_DATE;
        if (event.target.value !== DFAULT_SORT_VALUE && Number(event.target.value) === this.state.sortValue) {
            this.handleSort(this.props.defaultSortOption, false);
            this.setState({ sortValue: DFAULT_SORT_VALUE });
        }
    };

    renderRadioButton = (value: number, keyStringID: string, descriptionStringID: string) => {
        return (
            <Radio key={value} value={value} onClick={this.handleSortRadioButtonClicked}>
                <FormattedMessage id={keyStringID} />
                {" ("}
                <FormattedMessage id={descriptionStringID} />
                {")"}
            </Radio>
        );
    };

    renderSortOptions = () => {
        return (
            <Menu className="quotes-sort-menu">
                <div className="quotes-sort-menu-header">
                    <FormattedMessage id="sort_by" />
                </div>
                <Radio.Group onChange={this.handleRadioSort} value={this.state.sortValue}>
                    <Space direction="vertical">
                        {SORT_OPTIONS.map((sortOption: any) => {
                            return this.renderRadioButton(
                                sortOption.value,
                                sortOption.keyStringID,
                                sortOption.descriptionStringID
                            );
                        })}
                    </Space>
                </Radio.Group>
            </Menu>
        );
    };

    renderSortButton = () => {
        return (
            <Dropdown overlay={this.renderSortOptions()}>
                <Button
                    className={`quotes-button quotes-button-sort ${this.state.enableSortButton ? "enable-button" : ""}`}
                    type="default"
                    size="large"
                >
                    <IconContext.Provider value={{ size: "1em" }}>
                        <IoIosSwap className="quotes-sort-icon" />
                    </IconContext.Provider>
                    <FormattedMessage id="sort" />
                </Button>
            </Dropdown>
        );
    };

    renderQuotesFilterDrawer = () => {
        return (
            <Drawer
                visible={this.state.showFilter}
                onClose={() => this.setState({ showFilter: false })}
                title={<FormattedMessage id="filter" />}
                className="quotes-filter-drawer"
                width={QUOTES_FILTER_DRAWER_WIDTH}
            >
                <QuotesFilter closeDrawer={() => this.setState({ showFilter: false })} />
            </Drawer>
        );
    };

    renderMainQuoteActions = () => {
        return (
            <Row className="w100 m0" gutter={8} style={{ margin: 0 }}>
                <Col span={8}>
                    <div className="action-left">{this.renderSearch()}</div>
                </Col>
                <Col span={6}>
                    <div className="action-left">
                        {this.renderFiltersButton()}
                        {this.renderSortButton()}
                        {this.renderListGridToggle()}
                    </div>
                </Col>
                <Col span={10}>
                    <div className="action-right">
                        {this.renderRefreshButton()}
                        {!this.isEditModeOn() && !this.props.useListView ? this.renderEditModeButton() : null}
                        <BulkUpdate />
                        {this.renderAddButton()}
                    </div>
                </Col>
            </Row>
        );
    };

    renderGridViewMultiSelectionActions = () => {
        return (
            <div className="quotes-page-action-bar-container">
                <div className="quotes-page-action-bar quotes-page-action-bar-second-row">
                    <div className="action-left">
                        <Checkbox
                            className="quotes-select-all-checkbox"
                            onChange={this.handleSelectAll}
                            checked={
                                this.props.selectedQuotes.length &&
                                this.props.selectedQuotes.length === this.props.quotes.length
                            }
                        />
                        <div className="quotes-select-all-text">
                            <FormattedMessage id="select_all" />
                        </div>
                    </div>
                    <div className="action-right">{this.renderEditModeButton()}</div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <>
                <div className="quotes-page-action-bar quotes-page-action-bar-first-row">
                    {this.renderMainQuoteActions()}
                </div>
                {this.isEditModeOn() && !this.props.useListView ? this.renderGridViewMultiSelectionActions() : null}
                {this.renderQuotesFilterDrawer()}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state?.setting?.lan,
    gettingQuotes: _.get(state, "quotes.gettingQuotes", false),
    quotes: _.get(state, "quotes.quotes", []),
    selectedQuotes: _.get(state, "quotes.selected", []),
    useListView: _.get(state, "quotes.useListView", true),
    isEditMode: _.get(state, "quotes.isEditMode", false),
    filterOptions: _.get(state, "quotes.filterOptions", {}),
});

const mapDispatchToProps = {
    setState,
    getQuotes,
    setCustomersState,
    clearData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActionBar)));
