import { Pagination } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getGiftcardAccountHistory } from "../../../../services/giftcards";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";

const selector = (state: RootState) => ({
    total: state?.giftcards?.historyTotal,
    historyRecordGiftcardAccountId: state?.giftcards?.historyRecordGiftcardAccountId,
});

function GiftCardHistoryPagination(): JSX.Element {
    const dispatch = useDispatch();
    const [page, setPage] = useState<any>(1);
    const { total, historyRecordGiftcardAccountId } = useSelector(selector);

    const paginationProps: PaginationProps = {
        total,
        pageSize: DEFAULT_PAGE_SIZE,
        current: page,
        onChange: (page) => {
            setPage(page);
        },
    };

    const loaded = useRef(false);

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
        }
    }, []);

    useEffect(() => {
        dispatch(
            getGiftcardAccountHistory({
                giftcardaccount_id: historyRecordGiftcardAccountId,
                page: page,
            })
        );
    }, [dispatch, historyRecordGiftcardAccountId, page]);

    return (
        <div className="gift-card-account-pagination">
            <Pagination {...paginationProps} />
        </div>
    );
}

export default GiftCardHistoryPagination;
