import React, { Component } from "react";
import _ from "lodash";
import { Select, Form } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import config from "../../../../config";
const Option = Select.Option;

class TaxClassInput extends Component {
    handleInputChange = (value) => {
        this.props.setState({ surcharge_tax: value });
    };

    getValue = () => {
        return this.props.taxClass;
    };

    getLabel = () => {
        return this.props.intl.formatMessage({ id: "tax_class" });
    };

    getOptions = () => {
        const optionsData = Object.keys(config.TAX_NUMBER_VALUE_MAP).map((value) => {
            return { value, label: config.TAX_NUMBER_VALUE_MAP[value] };
        });

        return optionsData.map((opt) => (
            <Option {...opt} key={`order-search-dialog-tax-class-${opt.value}`}>
                <FormattedMessage id={opt.label} />
            </Option>
        ));
    };

    render() {
        return (
            <div>
                <Form.Item label={this.getLabel()} {...this.props.form}>
                    <Select
                        placeholder={this.getLabel()}
                        style={{ width: "100%" }}
                        value={this.getValue()}
                        onChange={(value) => this.handleInputChange(value)}
                    >
                        {this.getOptions()}
                    </Select>
                </Form.Item>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TaxClassInput));
