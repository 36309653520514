//@ts-nocheck
import { Button, Col, Collapse, Form, Input, Row, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons/lib";
import { MdVerifiedUser } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { validateProductBarcode } from "services/products";
import { setProductState } from "slices/products";
import { TimeListItem } from "slices/time";
import { RootState } from "../../../../../../app/reducer";
import {
    getDateInput,
    getFormItem,
    getMultiSelectInput,
    getNumberInput,
    getSelectInput,
    getSwitchInputSpecifyCheckedValue,
    getTextInput,
    renderFormItem,
    renderNumberInput,
    SelectOption,
} from "../../../../../../components/form";
import JoditEditor from "../../../../../../components/jodit-editor";
import config from "../../../../../../config";
import useLanguageSelect from "../../../../../../hooks/useLanguageSelect";
import useModal from "../../../../../../hooks/useModal";
import { getCategories } from "../../../../../../services/categories";
import { getTimeList } from "../../../../../../services/time";
import { formatCurrency } from "../../../../../../util/helper";
import {
    DESCRIPTION_PREFIX,
    JODIT_EDITOR_PROPS,
    MAX_BARCODE_LEN,
    MIN_BARCODE_LEN,
    NAME_PREFIX,
    ProductTypes,
    SHORT_DESCRIPTION_PREFIX,
} from "../../../../constants";
import { getCategoriesAsOptions } from "../../../../helper";
import ProductImagesEdit from "../../../../_components/product-images-edit";

const NORMAL_PRODUCT = config.PRODUCT_TYPE_TO_NUMERIC.regular_product;
const GROUP_SALE_PRODUCT = config.PRODUCT_TYPE_TO_NUMERIC.group_purchase;
const CALL_VENDOR_PRODUCT = config.PRODUCT_TYPE_TO_NUMERIC.call_vendor;
const DISCOUNTED_UPSELL_PRODUCT = config.PRODUCT_TYPE_TO_NUMERIC.discounted_upsell;

const TITLE_KEY = "title-";
const SHORT_DESCRIPTION_KEY = "short-description-";
const DESCRIPTION_FIELD_KEY = "description-field-";
const AVAILABILITY_KEY = "availability-";
const HOURS_KEY = "hours-";

const ROW_GUTTER = 8;
const DISABLED = "-disabled";
const READY = "-ready";
const ERROR = "-error";
const SUCCESS = "-success";

const PRODUCT_NAME_CHARACTER_LIMIT = 200;

interface ItemInfoTabProps {
    images: any;
    setImages: any;
    form: any;
    initialValues: any;
    values: any;
    setValues: any;
    setBarcodeErrorMessage: any;
    barcodeErrorMessage: any;
    pid: string;
    options: any;
    setOptions: any;
}

// short description max 160 characters
function ItemInformationTab(props: ItemInfoTabProps): JSX.Element {
    const { images, setImages, form } = props;
    const intl = useIntl();
    const { lan, getLanguageSelect, getInputLanguageSelect } = useLanguageSelect();
    const { pid = "" } = useParams<{ pid?: string }>();
    const dispatch = useDispatch();
    const location = useLocation();
    const { openModal, closeModal, getModal } = useModal("video-modal");
    const { categories, timeList, storeCurrency, loading, state, product, products } = useSelector(
        (state: RootState) => ({
            product: state?.products?.product,
            categories: state?.categories?.categories,
            timeList: state?.time?.timeList,
            storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
            loading: state?.products?.loading,
            state: state,
            products: state?.products,
        })
    );
    const [panel, setPanel] = useState<string | string[]>([]);
    const [barcode, setBarcode] = useState(props.initialValues.barcode || "");
    const [enableBarcodeVerification, setEnableBarcodeVerification] = useState(props.initialValues.barcode);
    const [barcodeValidationMessage, setBarcodeValidationMessage] = useState(
        props.initialValues.barcode ? "validated" : ""
    );
    const [resizeJoditEditor, setResizeJoditEditor] = useState(false);
    const getCurrentType = () => {
        if (products?.values?.duplicate) {
            return Number(products.addEditItemDuplicate?.product_type) ?? Number(ProductTypes.NORMAL_PRODUCT);
        }
        return Number(props?.initialValues?.product_type ?? product?.product_type ?? ProductTypes.NORMAL_PRODUCT);
    };

    const getProfit = () => {
        if (
            props?.initialValues?.price === null ||
            props?.initialValues?.price === undefined ||
            props?.initialValues?.cost === null ||
            props?.initialValues?.cost === undefined
        ) {
            return undefined;
        }
        return Number(props.initialValues.price) - Number(props.initialValues.cost);
    };

    const getMargin = () => {
        const profit = getProfit();
        if (profit === undefined) return undefined;
        return Number(props.initialValues.price) === 0 ? 0 : (profit * 100) / Number(props.initialValues.price);
    };

    const getOptions = (config: any, exclude: any = []) => {
        const options: any = [];
        Object.keys(config).forEach((key: any) => {
            if (!exclude.includes(key)) {
                options.push({
                    value: Number(key),
                    label: intl.formatMessage({ id: config[key] }),
                });
            }
        });
        return options;
    };

    const getTimeListAsOptions = () => {
        return [
            {
                value: "",
                label: intl.formatMessage({ id: "use_category_time_setting" }),
            },
            ...timeList.map((t) => ({
                value: t.code,
                label: `${t.code} - ${t.name}`,
            })),
        ];
    };

    const isGroupPurchaseProduct = () => {
        return getCurrentType() === GROUP_SALE_PRODUCT;
    };

    const isDuplicateProduct = () => {
        const duplicate = new URLSearchParams(location.search).get("duplicate");
        return duplicate !== null;
    };

    const renderAvailableTime = (record: TimeListItem) => {
        return (
            <div>
                {record.available_time.map((a: any) => {
                    return (
                        <div key={AVAILABILITY_KEY + record.code} className="available-time-record">
                            <div>{a.days.map((d: any) => d.charAt(0).toUpperCase() + d.slice(1)).join(", ")}</div>
                            <div>
                                {a.hours.map((h: any) => (
                                    <div key={HOURS_KEY + record.code}>
                                        {h.open} - {h.close}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const getTimeTable = () => (
        <div className="time-table-category">
            <Collapse onChange={(key) => setPanel(key)}>
                <Collapse.Panel
                    header={
                        <FormattedMessage
                            id={panel.includes("show_time_table") ? "hide_time_table" : "show_time_table"}
                        />
                    }
                    key="show_time_table"
                    showArrow={false}
                >
                    <Table
                        size="small"
                        bordered
                        dataSource={timeList}
                        pagination={false}
                        columns={[
                            {
                                title: <FormattedMessage id="name" />,
                                render: (value, record) => record.code + " - " + record.name,
                            },
                            {
                                title: <FormattedMessage id="available_time" />,
                                render: (value, record) => renderAvailableTime(record),
                            },
                        ]}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getTimeList());
    }, [dispatch]);

    useEffect(() => {
        setResizeJoditEditor(true);
    }, [lan]);

    useEffect(() => {
        if (resizeJoditEditor) {
            setResizeJoditEditor(false);
        }
    }, [resizeJoditEditor]);

    const weightUnitOptions: SelectOption[] = [
        { value: "lb", label: intl.formatMessage({ id: "lb" }) },
        { value: "oz", label: intl.formatMessage({ id: "oz" }) },
        { value: "kg", label: intl.formatMessage({ id: "kg" }) },
        { value: "g", label: intl.formatMessage({ id: "grams" }) },
    ];

    const renderProductTypeRow = () => {
        return (
            <Row gutter={ROW_GUTTER}>
                <Col span={16}>
                    {/* TODO */}
                    {getFormItem(
                        "product_type",
                        getSelectInput(
                            "product_type",
                            false,
                            getOptions(config.TYPE_OPTIONS, ["1", "4", "-99", "-1"]),
                            undefined,
                            (e) => {
                                form?.setFieldsValue({ product_type: e });
                            }
                        )
                    )}
                </Col>
                <Col span={8}>
                    {getFormItem("status", getSwitchInputSpecifyCheckedValue("status", props.initialValues, 1))}
                </Col>
            </Row>
        );
    };

    const renderBasicProductInfoRow = () => (
        <Row gutter={ROW_GUTTER}>
            {getCurrentType() === NORMAL_PRODUCT || getCurrentType() === DISCOUNTED_UPSELL_PRODUCT ? (
                <Col span={8}>{getFormItem("price", getNumberInput("price"))}</Col>
            ) : null}
            {!isGroupPurchaseProduct() ? <Col span={8}>{getFormItem("stock", getNumberInput("stock"))}</Col> : null}
            {getCurrentType() !== CALL_VENDOR_PRODUCT && !isGroupPurchaseProduct() ? (
                <Col span={8}>
                    {getFormItem("tax_class", getSelectInput("tax_class", false, getOptions(config.TAX_CLASS_OPTIONS)))}
                </Col>
            ) : null}
            {!isGroupPurchaseProduct() ? (
                <>
                    <Col span={12}>
                        {renderFormItem({
                            name: "max_sale_qty",
                            child: getNumberInput("max_sale_qty"),
                            tip: "max_sale_qty_tip",
                        })}
                    </Col>
                    <Col span={12}>
                        {renderFormItem({
                            name: "min_sale_qty",
                            child: renderNumberInput({ name: "min_sale_qty", min: 1 }),
                            tip: "min_sale_qty_tip",
                        })}
                    </Col>
                </>
            ) : null}
        </Row>
    );

    const renderSpecialPriceSection = () => (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col span={8}>{getFormItem("special_price", getNumberInput("special_price"))}</Col>
                <Col span={8}>{getFormItem("special_from_date", getDateInput("special_from_date"))}</Col>
                <Col span={8}>{getFormItem("special_to_date", getDateInput("special_to_date"))}</Col>
            </Row>
            {renderCostRow()}
        </>
    );

    const renderCostRow = () => (
        <Row gutter={ROW_GUTTER}>
            <Col span={8}>{getFormItem("price_cost", getNumberInput("cost"))}</Col>
            <Col span={8}>
                <div className="form-item-label">
                    <div>
                        <FormattedMessage id="price_margin" />
                    </div>
                </div>
                <div className="price-margin">{getMargin() === undefined ? "-" : `${getMargin()?.toFixed(1)}%`}</div>
            </Col>
            <Col span={8}>
                <div className="form-item-label">
                    <div>
                        <FormattedMessage id="price_profit" />
                    </div>
                </div>
                <div className="price-profit">
                    {getProfit() === undefined
                        ? "-"
                        : formatCurrency(getProfit() ?? 0, storeCurrency, "", true, (getProfit() ?? 0) < 0)}
                </div>
            </Col>
        </Row>
    );

    /**
     * When the user changes the barcode value, reset the validation messages
     * If the barcode length is greater than the min barcode length
     * enable the barcode verification button
     *
     * @param e - input change event
     */
    const handleBarcodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBarcodeValidationMessage("");
        props.setBarcodeErrorMessage("");
        setBarcode(e.target.value);
        setEnableBarcodeVerification(e.target.value.length >= MIN_BARCODE_LEN);
    };

    /**
     * Send a request to the api to verify the inputted barcode if
     * barcode verification is enabled and the barcode was not already
     * verified.
     */
    const verifyBarcode = async () => {
        if (!enableBarcodeVerification || barcodeValidationMessage) {
            return;
        }

        setProductState({ loading: true });
        const message = await validateProductBarcode(barcode, parseInt(pid));
        setBarcodeValidationMessage(message);
        setProductState({ loading: false });
    };

    const renderBarcodeInput = () => (
        <Form.Item
            name="barcode"
            extra={
                <div className={"barcode-extra" + getVerifyIconStatus()}>
                    {props.barcodeErrorMessage ||
                        (barcodeValidationMessage
                            ? intl.formatMessage(
                                  {
                                      id: barcodeValidationMessage,
                                  },
                                  {
                                      minlen: MIN_BARCODE_LEN,
                                      maxlen: MAX_BARCODE_LEN,
                                  }
                              )
                            : null)}
                </div>
            }
        >
            <Input.Search
                placeholder={intl.formatMessage({ id: "barcode" })}
                maxLength={MAX_BARCODE_LEN}
                allowClear={true}
                value={barcode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBarcodeInput(e)}
                enterButton={renderVerifyIcon()}
                loading={loading}
                id="barcode-input"
            />
        </Form.Item>
    );

    const renderWeightInput = () => (
        <div className="weight-info">
            <div className="weight-input">{getFormItem("weight", getNumberInput("weight"))}</div>
            <div className="weight-unit">
                <Form.Item name="weight_unit">
                    <Select style={{ width: "100%" }} filterOption={() => true}>
                        {weightUnitOptions.map((option: SelectOption) => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        </div>
    );

    const getVerifyIconStatus = () => {
        if (props.barcodeErrorMessage) {
            return ERROR;
        }

        if (!enableBarcodeVerification) {
            return DISABLED;
        } else if (enableBarcodeVerification && !barcodeValidationMessage) {
            return READY;
        } else if (barcodeValidationMessage && barcodeValidationMessage !== "validated") {
            return ERROR;
        }

        return SUCCESS;
    };

    /**
     * Add tooltip to verify icon if it is enabled, otherwise
     * just render the icon without the tooltip
     *
     * @returns verify icon
     */
    const renderVerifyIcon = () => {
        const verifyIcon = (
            <div className="verify-icon-container">
                <IconContext.Provider value={{ size: "1.5em" }}>
                    <MdVerifiedUser className={"verify-icon" + getVerifyIconStatus()} onClick={verifyBarcode} />
                </IconContext.Provider>
            </div>
        );

        return getVerifyIconStatus() === READY ? (
            <Tooltip title={intl.formatMessage({ id: "click_to_validate_barcode" })}>{verifyIcon}</Tooltip>
        ) : (
            verifyIcon
        );
    };

    const renderProductBarcodeRow = () => {
        return (
            <Row gutter={ROW_GUTTER} className="barcode-row">
                {getCurrentType() === CALL_VENDOR_PRODUCT ? (
                    <Col span={8}>{getFormItem("phone", getTextInput({ name: "tel", allowClear: true }))}</Col>
                ) : null}
                {!isGroupPurchaseProduct() ? (
                    <>
                        <Col span={8}>{getFormItem("sku_long", getTextInput({ name: "sku", allowClear: true }))}</Col>
                        <Col span={8}>
                            <div className="standard-form-item">
                                <div className="form-item-label">
                                    <FormattedMessage id="barcode" />
                                </div>
                                {renderBarcodeInput()}
                            </div>
                        </Col>
                        <Col span={8}>{renderWeightInput()}</Col>
                    </>
                ) : null}
            </Row>
        );
    };

    const renderGeneralFields = () => (
        <>
            {renderProductTypeRow()}
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => {
                    return (
                        <div style={{ display: l !== lan ? "none" : "block" }} key={TITLE_KEY + l}>
                            {getFormItem(
                                "item_title",
                                getTextInput({
                                    name: NAME_PREFIX + l,
                                    allowClear: true,
                                    max: PRODUCT_NAME_CHARACTER_LIMIT,
                                    onChange: (e) => {
                                        props.setValues({ [NAME_PREFIX + l]: e.target.value });
                                    },
                                })
                            )}
                        </div>
                    );
                })
            )}
            {
                <Row gutter={ROW_GUTTER}>
                    <Col span={24}>
                        {getFormItem(
                            "categories",
                            getMultiSelectInput("category_ids", true, getCategoriesAsOptions(categories, lan), (e) => {
                                props.setValues({ ["category_ids"]: e });
                            })
                        )}
                    </Col>
                    <Col span={8}>
                        {getFormItem(
                            "show_store_link",
                            getSwitchInputSpecifyCheckedValue(
                                "show_store_link",
                                props.initialValues,
                                1,
                                (e) => props.setValues({ show_store_link: e }),
                                props.initialValues?.show_store_link
                            ),
                            undefined,
                            undefined,
                            undefined,
                            "store_link_tip"
                        )}
                    </Col>
                    <Col span={8}>
                        {getFormItem(
                            "hide_on_frontend",
                            getSwitchInputSpecifyCheckedValue(
                                "is_hidden",
                                props.initialValues,
                                1,
                                (e) => {
                                    props.setValues({ is_hidden: e });
                                },
                                props.initialValues?.is_hidden
                            ),
                            undefined,
                            undefined,
                            undefined,
                            "is_hidden_tip"
                        )}
                    </Col>
                    <Col span={8}>
                        {getFormItem(
                            "enable_special_request",
                            getSwitchInputSpecifyCheckedValue(
                                "enable_special_request",
                                props.initialValues,
                                1,
                                (e) => props.setValues({ enable_special_request: e }),
                                props.initialValues?.enable_special_request
                            ),
                            undefined,
                            undefined,
                            undefined,
                            "special_request_tip"
                        )}
                    </Col>
                </Row>
            }
            {renderBasicProductInfoRow()}
            {getCurrentType() === NORMAL_PRODUCT ? renderSpecialPriceSection() : null}
            {renderProductBarcodeRow()}
            {getCurrentType() === GROUP_SALE_PRODUCT ? (
                <Row gutter={ROW_GUTTER}>
                    <Col span={12}>{getFormItem("start_date", getDateInput("start_date"))}</Col>
                    <Col span={12}>{getFormItem("end_date", getDateInput("end_date"))}</Col>
                </Row>
            ) : null}
        </>
    );

    const renderForm = () => (
        <div style={{ maxWidth: "50%", minWidth: "50%" }}>
            {renderGeneralFields()}
            {getCurrentType() === GROUP_SALE_PRODUCT
                ? null
                : getInputLanguageSelect(
                      config.LANGUAGES.map((l) => {
                          return (
                              <div style={{ display: l !== lan ? "none" : "block" }} key={SHORT_DESCRIPTION_KEY + l}>
                                  {getFormItem(
                                      "short_description",
                                      getTextInput({
                                          name: SHORT_DESCRIPTION_PREFIX + l,
                                          rows: 2,
                                          allowClear: true,
                                          onChange: (e) => {
                                              props.setValues({ [SHORT_DESCRIPTION_PREFIX + l]: e.target.value });
                                          },
                                      })
                                  )}
                              </div>
                          );
                      })
                  )}
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div style={{ display: l !== lan ? "none" : "block" }} key={DESCRIPTION_FIELD_KEY + l}>
                        {getFormItem(
                            "description",
                            <JoditEditor
                                {...JODIT_EDITOR_PROPS}
                                id={DESCRIPTION_FIELD_KEY + l}
                                value={
                                    state?.products?.values?.[DESCRIPTION_PREFIX + l] ??
                                    props.initialValues?.[DESCRIPTION_PREFIX + l]
                                }
                                onChange={(content: string) => {
                                    props.setValues({ [DESCRIPTION_PREFIX + l]: content });
                                }}
                                resize={resizeJoditEditor}
                            />
                        )}
                    </div>
                ))
            )}
            {!isGroupPurchaseProduct() ? (
                <>
                    {getFormItem(
                        "time_code_setting",
                        getSelectInput("time", true, getTimeListAsOptions()),
                        "time_code_setting_desc"
                    )}
                    {getTimeTable()}
                    {getFormItem(
                        "availability",
                        getSelectInput("availability", false, getOptions(config.AVAILABILITY_OPTIONS))
                    )}
                </>
            ) : null}
        </div>
    );

    const renderVideoEdit = () => {
        return (
            <div className="video-input">
                {getFormItem("video", getTextInput({ name: "video", allowClear: true }), "video_desc")}
                <Button style={{ marginBottom: 16 }} onClick={openModal} disabled={!props?.initialValues?.video}>
                    <FormattedMessage id="preview_video" />
                </Button>
            </div>
        );
    };

    const renderImageEdit = () => {
        return (
            <div className="item-images-modal">
                <ProductImagesEdit
                    images={images}
                    onChange={(value: any) => {
                        //set form value (shown on screen) and values state when change occurs
                        form.setFieldsValue({ images: value });
                        setImages(value);
                    }}
                    addImage={(cache: any) => {
                        const newImages = cache.filter(
                            (c: any) =>
                                !images?.find((i: any) => {
                                    return i === c;
                                })
                        );
                        form.setFieldsValue({ images: [...images, ...newImages] });
                        setImages([...images, ...newImages]);
                    }}
                    form={form}
                />
            </div>
        );
    };

    return (
        <div className="items-tab">
            {renderForm()}
            <div className="item-info-col">
                {(pid || isDuplicateProduct()) && (
                    <div style={{ padding: "0px 16px" }}>{getFormItem("item_images", renderImageEdit())}</div>
                )}
                {renderVideoEdit()}
            </div>
            {getLanguageSelect()}
            {/*  Modals */}
            {getModal(<ReactPlayer url={props?.initialValues?.video} width="100%" />, {
                title: <FormattedMessage id="video" />,
                onCancel: closeModal,
                footer: null,
                width: 768,
            })}
        </div>
    );
}

export default ItemInformationTab;
