import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { DatePicker, TimePicker, Row, Col, Select, Switch, Button, Spin } from "antd";
import "./index.scss";
import SalesOperationReport from "./sales-operation-report";
import { useSalesOperations } from "./use-sales-operations";

const Option = Select.Option;

const DROP_DOWN_WIDTH = 230;

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm";

function SalesOperations(): JSX.Element {
    const {
        report,
        lan,
        storeCurrency,
        selectedDateButton,
        showReport,
        includeOrderDetail,
        includeTips,
        rpTitle,
        handleDateChange,
        handleTimeChange,
        dateTimePickers,
        dateTimeButtonsS1,
        dateTimeButtonsS2,
        dropDownSections,
        bottomButtonsSection,
        switchSections,
        loadingState,
    } = useSalesOperations();

    const intl = useIntl();
    const str = (id: string) => intl.formatMessage({ id });

    const renderReport = () => (
        <Col span={12}>
            {showReport && report && report["start_dt"] && report["end_dt"] ? (
                <div className="report">
                    <SalesOperationReport
                        reportData={report}
                        rpTitle={str(rpTitle)}
                        storeCurrency={storeCurrency}
                        includeOrderDetail={includeOrderDetail}
                        includeTips={includeTips}
                        lan={lan}
                    />
                </div>
            ) : (
                ""
            )}
        </Col>
    );

    const renderDateTimePickers = () =>
        dateTimePickers.map((section: Record<string, any>, i: React.Key | null | undefined) => (
            <div key={i} className="item">
                <div className="col-2">
                    <FormattedMessage id={section.message ?? " "} />
                </div>
                <div className="col-10 d-flex">
                    <div className="margin-right">
                        <FormattedMessage id="select_date">
                            {(placeholder: any) => (
                                <DatePicker
                                    disabledDate={section.disabledDate}
                                    value={section.valueDate}
                                    placeholder={placeholder}
                                    onChange={(date, dateString) => handleDateChange(date, dateString, section.type)}
                                    format={DATE_FORMAT}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="margin-right">
                        <FormattedMessage id="select_time">
                            {(placeholder: any) => (
                                <TimePicker
                                    defaultValue={section.defaultTime}
                                    placeholder={placeholder}
                                    use12Hours
                                    onChange={(time, timeString) => handleTimeChange(time, timeString, section.type)}
                                    format={TIME_FORMAT}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                </div>
            </div>
        ));

    const renderDateTimeButtons = () => (
        <div className="item">
            <div className="select-date-btns">
                {dateTimeButtonsS1.map((button: Record<string, any>, i: React.Key | null | undefined) => (
                    <div key={i}>
                        <Button
                            type={Number(selectedDateButton) === button.id ? "primary" : "default"}
                            className="day-button"
                            onClick={button.onClick}
                        >
                            {button.content}
                        </Button>
                    </div>
                ))}
                {dateTimeButtonsS2.map((button: Record<string, any>, i: React.Key | null | undefined) => (
                    <div key={i}>
                        <Button
                            type={Number(selectedDateButton) === button.id ? "primary" : "default"}
                            className="day-button"
                            onClick={button.onClick}
                        >
                            {button.content}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderDropdownSection = () =>
        dropDownSections.map((section: Record<string, any>, i: React.Key | null | undefined) => (
            <div key={i} className="item">
                <div className="col-3">
                    <FormattedMessage id={section.message ?? " "} />
                </div>
                <div className="col-9">
                    <div className="one-line-wrapper">
                        <Select
                            value={section.value}
                            style={{ width: section.width && section.widthCondition ? section.width : DROP_DOWN_WIDTH }}
                            onChange={(value) => section.handleChange(value)}
                        >
                            {section
                                ? section.selectItems.map(
                                      (item: Record<string, any>, i: React.Key | null | undefined) => (
                                          <Option key={i} value={item}>
                                              {item}
                                          </Option>
                                      )
                                  )
                                : ""}
                        </Select>
                    </div>
                </div>
            </div>
        ));

    const renderSwitchSection = () =>
        switchSections.map((section: Record<string, any>, i: React.Key | null | undefined) => (
            <div key={i} className="item">
                <div className="col-3">
                    <FormattedMessage id={section.message ?? " "} />
                </div>
                <div className="col-9">
                    <Switch checked={section.value} onChange={section.onChange} />
                </div>
            </div>
        ));

    const renderBottomButtons = () => (
        <div className="item">
            <div />
            <div>
                {bottomButtonsSection.map((section: Record<string, any>, i: React.Key | null | undefined) => (
                    <Button key={i} type="primary" onClick={section.onclick} className="submit-btns-report">
                        <FormattedMessage id={section.message ?? " "} />
                    </Button>
                ))}
            </div>
        </div>
    );

    const renderForm = () => (
        <Col span={12}>
            {renderDateTimePickers()}
            {renderDateTimeButtons()}
            {renderDropdownSection()}
            {renderSwitchSection()}
            {renderBottomButtons()}
        </Col>
    );

    return (
        <div className="sales-operation-sales">
            <Spin className="alert" spinning={Boolean(loadingState)}>
                <Row>
                    {renderForm()}
                    {renderReport()}
                </Row>
            </Spin>
        </div>
    );
}

export default SalesOperations;
