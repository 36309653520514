import { Button, Descriptions, Form, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import { getDescriptionItem, getSelectInput, getTextInput, SelectOption } from "../../../../../../components/form";
import { getStoreDetails, updateStoreDetails } from "../../../../../../services/store";
import ObjectModel from "../../../../../../util/models";
import config from "../../../../../../config";
import StoreCurrencyAndPricePlan from "../../../../../../util/models/store-currency-and-price-plan";
import { getPricePlanList } from "../../../../../../services/price-plan";
import { Prompt, useLocation } from "react-router-dom";
import h from "util/helper";

function StorePricePlanTab(props: { lan: string }): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state.user);
    const storeCurrencyAndPricePlan = useSelector((state: RootState) => state.store?.storeCurrencyAndPricePlan);
    const records = useSelector((state: RootState) => state.store?.records);
    const plans = useSelector((state: RootState) => state.pricePlan?.plans);
    const lan = useSelector((state: RootState) => state.setting.lan);
    const [touched, setTouched] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<any>(storeCurrencyAndPricePlan?.price_plan);
    const [form] = Form.useForm();

    useEffect(() => {
        setSelectedPlan(storeCurrencyAndPricePlan?.price_plan);
    }, [storeCurrencyAndPricePlan]);

    const onFinish = (data: any) => {
        setTouched(false);
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreCurrencyAndPricePlan).convertToPostData(data, records, "price_plan_and_currency")
            )
        );
    };

    const getOptions = () => {
        return plans.map((p) => ({
            value: p.id.toString(),
            label: p.name,
        }));
    };

    const getCurrencyOptions: any = () =>
        Object.keys(config.CURRENCY_NUMBER_MAP).map((key) => ({
            value: key,
            // @ts-ignore
            label: config.CURRENCY_NUMBER_MAP[key],
        }));

    const currentPlan = plans?.find((p) => p.id.toString() === selectedPlan);

    const getPlanSelect = () => (
        <Form.Item name={"price_plan"} style={{ marginBottom: "auto" }}>
            <Select
                style={{ width: "100%", minWidth: "250px" }}
                placeholder={intl.formatMessage({ id: "type_here" })}
                onChange={(e) => setSelectedPlan(e)}
                disabled={!h.isSuperUser(currUser)}
            >
                {getOptions()?.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    const currencyVatSection = () => (
        <div className="grouped-inputs">
            <div className="form-title-description">
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="currency_vat_number" />
                </h1>
            </div>
            <Descriptions bordered>
                {getDescriptionItem("currency", 3, getSelectInput("store_currency", false, getCurrencyOptions()))}
                {getDescriptionItem(
                    "vat_number",
                    3,
                    getTextInput({ name: "store_vat", allowClear: true }),
                    "currency_vat_number_description"
                )}
            </Descriptions>
        </div>
    );
    const pricePlanSection = () => (
        <div className="grouped-inputs general-section">
            <div className="form-title-description">
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="price_plan" />
                </h1>
                <p className="ant-descriptions-description">
                    <FormattedMessage id="price_plan_description" />
                </p>
            </div>
            <Descriptions key={lan} style={{ display: lan !== props.lan ? "none" : "block" }} bordered>
                {getDescriptionItem("price_plan", 3, getPlanSelect())}
                {getDescriptionItem("name", 3, currentPlan?.name)}
                {getDescriptionItem("details", 3, currentPlan?.details)}
                {getDescriptionItem("setup_fee", 3, currentPlan?.setup_fee)}
                {getDescriptionItem("monthly_fee", 3, currentPlan?.monthly_fee)}
                {getDescriptionItem("maximum_languages", 3, currentPlan?.lan_count)}
            </Descriptions>
        </div>
    );

    useEffect(() => {
        dispatch(getStoreDetails());
        dispatch(getPricePlanList({ lan }));
    }, [dispatch, lan]);

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
    }, [location, form]);

    return !_.isEmpty(storeCurrencyAndPricePlan) ? (
        <React.Fragment>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="general-settings has-floating-submit">
                <Form
                    form={form}
                    name="general-settings"
                    onFinish={onFinish}
                    initialValues={storeCurrencyAndPricePlan}
                    onValuesChange={handleValuesChanged}
                >
                    {currencyVatSection()}
                    {pricePlanSection()}
                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StorePricePlanTab;
