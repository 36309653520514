import { Button, Layout, Menu, Typography } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaHourglassHalf, FaReceipt } from "react-icons/fa";
import {
    HiAdjustments,
    HiClipboardList,
    HiCloudUpload,
    HiCollection,
    HiCreditCard,
    HiDocumentReport,
    HiHome,
    HiOutlineViewBoards,
    HiOutlineViewGridAdd,
    HiPencilAlt,
    HiUsers,
} from "react-icons/hi";
import { RiAdminFill, RiDraftLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import { RootState } from "../../app/reducer";
import config from "../../config";
import { PermissionFeatures, usePermission } from "../../hooks/usePermission";
import images from "../../images";
import { StoreStatus } from "../../pages/store-sign-up/constants";
import { setUiState } from "../../slices/user-interface";
import helper from "../../util/helper";
import { isReferralsEnabled } from "../../util/helper/storeHelper";
interface MenuItem {
    index?: number;
    path: string;
    name: string;
    icon?: any;
    permission?: any;
    hide?: any;
    iconSize?: any;
}

const items: MenuItem[] = [
    {
        path: "/storeSignUp",
        name: "store_sign_up",
        icon: HiPencilAlt,
    },
    {
        index: PermissionFeatures.Dashboard,
        path: "/dashboard",
        name: "dashboard",
        icon: HiHome,
    },
    {
        index: PermissionFeatures.LiveOrder,
        path: "/liveOrders",
        name: "live_order",
        icon: FaHourglassHalf,
        iconSize: "1em",
    },
    {
        index: PermissionFeatures.CreateOrder,
        path: "/quotes",
        name: "cloud_pos",
        icon: RiDraftLine,
        iconSize: "1em",
    },
    {
        index: PermissionFeatures.OrderHistory,
        path: "/orders",
        name: "order_history",
        icon: FaReceipt,
        iconSize: "1em",
    },
    {
        index: PermissionFeatures.SalesReport,
        path: "/salesReport",
        name: "sales_report",
        icon: HiDocumentReport,
    },
    {
        index: PermissionFeatures.ProductManagement,
        path: "/products",
        name: "products",
        icon: HiClipboardList,
    },
    {
        index: PermissionFeatures.CategoryManagement,
        path: "/categories",
        name: "categories",
        icon: HiCollection,
    },
    {
        index: PermissionFeatures.Publish,
        path: "/publish",
        name: "publish_manager",
        icon: HiCloudUpload,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCards",
        name: "gift_card",
        icon: HiCreditCard,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCardAccount",
        name: "gift_card_account",
        icon: HiCreditCard,
    },
    {
        index: PermissionFeatures.CustomerReview,
        path: "/reviews",
        name: "reviews",
        icon: BiCommentDetail,
    },
    {
        index: PermissionFeatures.Customer,
        path: "/customers",
        name: "customers",
        icon: BsFillPersonLinesFill,
    },
    {
        index: PermissionFeatures.ReferralPage,
        path: "/referrals",
        name: "referrals",
        icon: HiUsers,
        hide: !isReferralsEnabled,
    },
    {
        index: PermissionFeatures.AdminUsers,
        path: "/adminUsers",
        name: "admin_users",
        icon: RiAdminFill,
    },
    {
        path: "/local_delivery",
        name: "local_delivery",
        icon: TbTruckDelivery,
    },
    {
        path: "/superAdmin/storeSwitch",
        name: "store_switcher",
        icon: HiOutlineViewBoards,
        permission: [config.ROLE_ID.SUPER_USER],
    },
    {
        path: "/superAdmin/newStoreManagement",
        name: "new_store_management",
        icon: HiOutlineViewGridAdd,
        permission: [config.ROLE_ID.SUPER_USER],
    },
    {
        index: PermissionFeatures.SettingMenu,
        path: "/settings",
        name: "setting",
        icon: HiAdjustments,
    },
];

const Navigation = (): JSX.Element => {
    const { hasAccess } = usePermission();
    const currUserRoles = useSelector((state: RootState) => state.user?.roles ?? []);
    const store = useSelector((state: RootState) => state.store);
    const location = useLocation();
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(localStorage.getItem("navCollapsed") === "true");
    const { ref, width } = useResizeObserver<HTMLDivElement>();

    const showItem = (item: MenuItem) => {
        if (
            (store?.records?.general_info?.store_status < StoreStatus.Pending || _.isEmpty(store?.records)) &&
            item.name !== "store_sign_up"
        ) {
            return false;
        }

        if (store?.records?.general_info?.store_status > StoreStatus.NeedApproval && item.name === "store_sign_up") {
            return false;
        }

        if (helper.isFunction(item.hide) && item.hide(store)) {
            return false;
        }

        if (item.index !== undefined) {
            return hasAccess(item.index);
        }

        if (item.permission) {
            return currUserRoles.some((role: any) => item.permission.includes(role));
        } else {
            return true;
        }
    };

    const toggleMenu = () => {
        localStorage.setItem("navCollapsed", (!collapsed).toString());
        setCollapsed(!collapsed);
    };

    const renderMenuItem = (item: MenuItem) =>
        showItem(item) ? (
            <Menu.Item
                key={item.path}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
                icon={
                    <span
                        style={{
                            fontSize: item.iconSize ?? "1.3em",
                            display: "flex",
                            alignItems: "center",
                            marginRight: 6,
                        }}
                    >
                        {item.icon ? <item.icon /> : null}
                    </span>
                }
            >
                <span style={{ color: collapsed ? "white" : "" }}>{<FormattedMessage id={item.name} />}</span>
                <Link to={item.path} />
            </Menu.Item>
        ) : null;

    useEffect(() => {
        if (width !== null && (Number(width) === 80 || Number(width) === 200)) {
            dispatch(
                setUiState({
                    navigationWidth: Number(width),
                })
            );
        }
    }, [width, dispatch]);

    return (
        <>
            <Layout.Sider theme="light" collapsible collapsed={collapsed} trigger={null}></Layout.Sider>
            <Layout.Sider theme="light" collapsible collapsed={collapsed} trigger={null} className="sider-navigation">
                <div ref={ref} className="navigation-container">
                    <div className="navigation-title">
                        <Typography.Title level={4} style={{ flexGrow: 1 }}>
                            {collapsed ? null : "Goopter"}
                        </Typography.Title>
                    </div>
                    <div className="navigation-menu">
                        <Menu
                            mode="inline"
                            style={{ borderRight: 0 }}
                            selectedKeys={[items.find((item) => location.pathname.startsWith(item.path))?.path ?? ""]}
                            theme="light"
                        >
                            {items.map(renderMenuItem).filter((el) => el !== null)}
                        </Menu>
                    </div>
                    <div className="navigation-toggle">
                        <Button onClick={toggleMenu}>
                            <img
                                alt=""
                                className="menu-toggle-button"
                                src={collapsed ? images["right-expand"]?.default : images["left-shrink"]?.default}
                            />
                        </Button>
                    </div>
                </div>
            </Layout.Sider>
        </>
    );
};

export default Navigation;
