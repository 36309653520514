import React, { Component } from "react";
import _ from "lodash";
import _helper from "util/helper";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Empty } from "antd";
import OptionsPicker from "./options-picker";
import { getProductPrice, getDefaultOpts } from "pages/create-order/helper";
import actions from "slices/live-order";
import "./_replace-item-editor.scss";

class ReplaceItemEditorNewItem extends Component {
    componentDidMount = () => {
        this.initData();
    };

    componentDidUpdate = (prevsProps) => {
        this.checkInitData(prevsProps);
    };

    checkInitData = (prevsProps) => {
        const itemChangePid = _.get(this.props, "state.itemChangeObject.new_product.pid", "");
        const prevsItemChangePid = _.get(prevsProps, "state.itemChangeObject.new_product.pid", "");
        if (itemChangePid !== prevsItemChangePid && itemChangePid) {
            this.initData();
        }
    };

    initData = () => {
        const itemChangePid = _.get(this.props, "state.itemChangeObject.new_product.pid", "");
        if (_.isEmpty(this.getProductData())) {
            this.props.getProductDetail({
                pid: itemChangePid,
                lan: this.props.lan,
            });
        }
    };

    str = (id) => {
        let { lan } = this.props;
        return _helper.getTransString(id, lan);
    };

    getItemChangeObject = () => _.get(this.props, "state.itemChangeObject", {});

    getProductData = () => {
        const productDetail = this.props.productDetail;
        const products = Array.isArray(this.props.products) ? [productDetail, ...this.props.products] : [productDetail];
        const itemChangePid = _.get(this.props, "state.itemChangeObject.new_product.pid", "");
        const productData = products.find((product) => product.pid === itemChangePid);
        return _.isEmpty(productData) ? {} : productData;
    };

    getNewProduct = () => _.get(this.props, "state.itemChangeObject.new_product", {});

    getProductPrice = (item) => {
        return item.pc || item.pc == 0 ? item.pc : getProductPrice(item);
    };

    renderOptions = () => {
        const currentItemOpts = _.get(this.getProductData(), "opt", []);
        const currentPickOpts = _.get(this.getNewProduct(), "opts", []);

        const height = this.props.height * 0.85;

        const renderOptionsPicker = () => (
            <OptionsPicker
                height={height}
                product={this.getProductData()}
                options={currentItemOpts}
                picked={
                    !_.isEmpty(currentPickOpts) ? currentPickOpts : getDefaultOpts(this.getNewProduct(), this.props.lan)
                }
                onChange={(value, errors) => {
                    let item = _.cloneDeep(this.getNewProduct());
                    let itemChangeObject = _.cloneDeep(this.getItemChangeObject());
                    item.opts = value;
                    itemChangeObject.new_product = item;
                    this.props.setState({ itemChangeObject, optionsErrors: errors });
                }}
            />
        );

        const renderEmpty = () => (
            <div style={{ height: height }} className="replace-item-empty-box">
                <Empty description={<FormattedMessage id="no_options_available" />} />
            </div>
        );

        return !_.isEmpty(currentItemOpts) ? renderOptionsPicker() : renderEmpty();
    };

    render() {
        return <div className="item-detail-edit-dialog-new-item-step-container">{this.renderOptions()}</div>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    products: _.get(state, "liveOrders.products", []),
    productDetail: _.get(state, "liveOrders.productDetail", []),
});

const mapDispatchToProps = {
    getProductDetail: actions.getProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReplaceItemEditorNewItem));
