import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import "./tips-section.scss";
import NumberInput from "../../../../../components/number-input";
import config from "../../../../../config";
import { updateTips } from "../../../../../slices/create-order";
import { formatCurrency } from "../../../../../util/helper";
import { MAX_PRICE } from "../../../../../app/constants";

const currencySymbols = config.CURRENCY_SYMBOL;

class TipsManualInput extends React.Component {
    static defaultProps = {
        value: 0,
        tipsType: "percent",
    };

    state = {};

    getTips = () => {
        if (this.props.tips === 0) {
            return null;
        }
        return this.props.tips;
    };

    updateTips = (value) => {
        this.props.updateTips({
            tipsType: "flat",
            amount: value,
        });
    };

    onBlur = () => {
        const tips = this.getTips();
        if (!tips) {
            this.props.updateTips({
                tipsType: "flat",
                amount: 0,
            });
        }
    };

    getPrefix = () => {
        return config.CURRENCY_SYMBOL["CAD"];
    };

    render() {
        return (
            <NumberInput
                placeholder={formatCurrency(0, this.props.storeCurrency, undefined, false)}
                min={0}
                max={MAX_PRICE}
                precision={2}
                onBlur={() => this.onBlur()}
                value={this.getTips()}
                onChange={this.updateTips}
                name="numberformat"
                className="checkout-tips-manaul-input-input"
                prefix={currencySymbols[this.props.storeCurrency]}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    tips: _.get(state, "createOrder.payment.tips", ""),
    lan: _.get(state, "setting.lan", ""),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    //from slice
    updateTips,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TipsManualInput));
