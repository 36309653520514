import { Input } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { PermissionFeatures, PermissionTypes, usePermission } from "../../hooks/usePermission";
import "./index.scss";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "settings", breadcrumbName: "settings_overview" },
    ],
};

const settings = [
    {
        title: "general_setting",
        path: "/settings/general",
    },
    {
        index: PermissionFeatures.SettingHours,
        title: "business_hours",
        path: "/settings/businessHours",
    },
    {
        title: "public_notice_setting",
        path: "/settings/publicNotice",
    },
    {
        index: PermissionFeatures.SettingPickupDelivery,
        title: "pickup_delivery_setting",
        path: "/settings/pickupDelivery",
    },
    {
        index: PermissionFeatures.SettingDiscount,
        title: "discount_options",
        path: "/settings/discountOptions",
    },
    {
        index: PermissionFeatures.SettingSelfServe,
        title: "self_serve_ordering_setting",
        path: "/settings/selfServe",
    },
    {
        index: PermissionFeatures.SettingPayment,
        title: "payment_options",
        path: "/settings/paymentOptions",
    },
    {
        title: "display_options",
        path: "/settings/displayOptions",
    },
    {
        index: PermissionFeatures.SettingMenu,
        title: "menu_setting",
        path: "/settings/menu",
    },
    {
        title: "checkout_settings",
        path: "/settings/checkout",
    },
    {
        index: PermissionFeatures.SettingTips,
        title: "tips_settings",
        path: "/settings/tipsSettings",
    },
    {
        index: PermissionFeatures.SettingItemAvailability,
        title: "availability_setting",
        path: "/settings/availability",
    },
    {
        index: PermissionFeatures.SettingNotification,
        title: "notifications_setting",
        path: "/settings/notifications",
    },
    {
        index: PermissionFeatures.SettingManagedStores,
        title: "managed_stores",
        path: "/settings/managedStores",
    },
    {
        title: "referral_settings",
        path: "/settings/referrals",
    },
    {
        index: PermissionFeatures.SettingSurcharge,
        title: "surcharge_settings",
        path: "/settings/surcharge",
    },
    {
        title: "shipping_setting",
        path: "/settings/shipping",
    },
    {
        title: "auto_accept_order_settings",
        path: "/settings/autoAcceptOrder",
    },
    {
        title: "generate_qr_setting",
        path: "/settings/generateQr",
    },
    {
        title: "content_pages",
        path: "/settings/contentPages",
    },
    {
        title: "reward_points_settings",
        path: "/settings/rewardPoints",
    },
    {
        title: "review_booster_setting",
        path: "/settings/reviewBooster",
    },
];

const App = (): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();
    const { getFlag } = usePermission();
    const [search, setSearch] = useState<string>("");

    const onSearch = (e: any) => {
        setSearch(e?.target?.value ?? "");
    };

    const showItem = (item: any) => {
        if (item.index !== undefined) {
            return (
                getFlag(item.index) === PermissionTypes.ReadOnly.toString() ||
                getFlag(item.index) === PermissionTypes.ReadWrite.toString()
            );
        } else {
            return true;
        }
    };

    // Filter settings based on title field.
    const filteredSettings = settings.filter((setting) => {
        return intl
            .formatMessage({ id: setting?.title ?? " " })
            ?.toLowerCase()
            ?.split?.(" ")
            ?.join("")
            ?.includes?.(search?.toLowerCase());
    });

    return (
        <Wrapper helmet={{ title: "settings_overview" }} breadcrumb={breadcrumb}>
            <div>
                <Input onChange={onSearch} value={search} placeholder={intl.formatMessage({ id: "search" })} />
            </div>
            <div className="settings-list-container">
                {filteredSettings.map((setting, key) =>
                    showItem(setting) ? (
                        <div className="setting-card" onClick={() => history.push(setting.path)} key={key}>
                            <div className="setting-card-title">
                                <FormattedMessage id={setting.title ?? " "} />
                            </div>
                            <div className="setting-card-desc">
                                <FormattedMessage id={setting.title + "_description"} />
                            </div>
                            <div className="setting-card-footer">
                                <div />
                                <div>
                                    <FormattedMessage id="edit" />
                                </div>
                            </div>
                        </div>
                    ) : null
                )}
            </div>
        </Wrapper>
    );
};

export default App;
