import React from "react";
import { injectIntl } from "react-intl";
import { FaCheck } from "react-icons/fa";
import { Button, Badge, withStyles } from "@material-ui/core";
import cx from "classnames";

const StyledBadge = withStyles(() => ({
    badge: {
        right: 12,
        top: 6,
        backgroundColor: "#ed2d29",
        border: `2px solid white`,
        padding: "8px 2px",
        color: "white",
    },
}))(Badge);

class AlipayCheckbox extends React.Component {
    render() {
        const { isSelected, onSelect, children } = this.props;
        return (
            <StyledBadge badgeContent={isSelected ? <FaCheck size={10} /> : 0}>
                <Button
                    variant="contained"
                    size="small"
                    className={cx({
                        "product-container-button": true,
                        "product-container-button-not-selected": !isSelected,
                        "product-container-button-selected": isSelected,
                    })}
                    onClick={onSelect}
                >
                    {children}
                </Button>
            </StyledBadge>
        );
    }
}

export default injectIntl(AlipayCheckbox);
