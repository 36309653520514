import { Button, Input } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import config from "../../../../config";
import { useIntl } from "react-intl";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";

interface RenameCategoryProps {
    name: string;
    onSave?: any;
    onCancel?: any;
}

const RenameCategoryModal = (props: RenameCategoryProps): JSX.Element => {
    const intl = useIntl();
    const { lan, getModalLanguageSelect } = useLanguageSelect();
    const [filter, setFilter] = useState<any>({});

    const onNameChange = (e: any) => {
        const value = _.cloneDeep(e.target.value);
        setFilter((prev: any) => ({ ...prev, [lan]: value }));
    };

    useEffect(() => {
        setFilter(props.name);
    }, [props.name]);

    return (
        <div style={{ position: "relative" }}>
            <div className="modal-lan-select">{getModalLanguageSelect()}</div>
            {config.LANGUAGES.map((l) => (
                <div key={`rename-category-${l}`} className="rename-category">
                    <Input
                        size="large"
                        placeholder={intl.formatMessage({ id: "type_to_search" })}
                        style={{ display: lan === l ? "block" : "none" }}
                        value={filter[l] ?? ""}
                        onChange={onNameChange}
                    />
                </div>
            ))}
            <div className="footer-item-changes">
                <Button size="large" type="primary" onClick={() => props.onSave(filter)}>
                    {intl.formatMessage({ id: "confirm" })}
                </Button>
            </div>
        </div>
    );
};

export default RenameCategoryModal;
