import React, { Component } from "react";
import { injectIntl } from "react-intl";
import OrderDetailTotal from "./order-detail";
import "./_orderDetails.scss";
import _actionsLiveOrders from "../../../../slices/live-order";
import { connect } from "react-redux";
import _ from "lodash";

class OrderDetailsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBig: true,
        };
    }

    static defaultProps = {
        order: {},
    };

    render() {
        return (
            <div className="order-total-details-container">
                <OrderDetailTotal {...this.props} state={this.props.state} setState={this.props.setState} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, "liveOrders", {}),
    lan: _.get(state, "setting.lan", "en"),
});

const mapDispatchToProps = {
    setState: _actionsLiveOrders.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetailsSection));
