import { createMessage } from "components/message";
import config from "config";
import { getIntl } from "locale";
import _ from "lodash";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import helper, { getTransString, isCharAscii } from "../../util/helper";
import { MAX_LEN_NAME, MAX_LEN_SHORT_DESC } from "./constants";

const intl = getIntl();

export const getProductsAsOptions = (products: any = [], language: string): any => {
    const COUPON = 4;
    if (Array.isArray(products)) {
        return (
            products
                .filter((c) => c.product_type !== COUPON)
                .map((c) => ({
                    value: c.sku,
                    label: helper.getTransString(c.name, language),
                })) ?? []
        );
    }
    return [];
};

export const getCategoriesAsOptions = (categories: any = [], lan = "en", level = config.CATEGORY_LEVELS.root): any => {
    // Only allow child categories
    return (
        categories
            .filter((c: any) => c.level > level)
            .map((c: any) => ({
                value: c.category_id,
                label: getTransString(c.name, lan),
            })) ?? []
    );
};

export const validateStrInput = (values: any = [], checkFields?: any[], trim?: boolean): any => {
    // values will look like: {name_en: "", name_zh: "", price: "", short_description_en: "", ...}
    // check name_ and short_description_ fields' lengths
    // convert to unicode and check lengths to see if they exceed max length
    // if exceeded then trim until string fits max length
    const fieldsToCheck = checkFields ?? [
        { prefix: "name", maxLen: MAX_LEN_NAME },
        { prefix: "short_description", maxLen: MAX_LEN_SHORT_DESC },
    ];
    if (_.isObject(values)) {
        const keys = Object.keys(values).filter((k) =>
            fieldsToCheck.map((field: any) => field.prefix).some((pre: string) => k.startsWith(pre))
        );
        if (_.isArray(keys)) {
            keys.forEach((k) => {
                let v = helper.isString(values[k]) ? values[k] : "";
                // if the value is just whitespace, it will be trimmed
                if (v.trim() !== "") {
                    v = trim ? values[k].trim() : values[k];
                } else {
                    v = "";
                }
                const prefix = fieldsToCheck.map((f: any) => f.prefix).find((p: string) => k.startsWith(p));
                const maxLen = fieldsToCheck.find((f: any) => f.prefix === prefix)?.maxLen ?? 0;
                let actualCharCount = 0;
                let newStr = "";
                v.split("").forEach((origChar: string) => {
                    // normal ascii character is counted as length of 1 (eg. 'c'), and not encoded on backend
                    // non-ascii character (eg. Chinese or French characters) count as length of 5
                    // since non-ascii character is encoded using unicode on backend, and shows as eg. '\u0043'
                    const currCharCount = isCharAscii(origChar) ? 1 : 5;
                    if (actualCharCount + currCharCount <= maxLen) {
                        newStr += origChar;
                    }
                    actualCharCount += currCharCount;
                });
                values = {
                    ...values,
                    [k]: newStr,
                };
            });
        }
    }

    return values;
};

export const validateBarcode = (values: any = []): any => {
    if (_.isObject(values)) {
        const barcode = _.get(values, "barcode", "").replace(/[^A-Za-z0-9]/g, "");
        if (!_.isEmpty(barcode)) {
            _.set(values, "barcode", barcode);
        }
    }
    return values;
};

export const allStringsEmpty = (values: any = []): any => {
    return values.every((v: any) => v === undefined || v === null || String(v).trim() === "");
};

export const beforeUpload = (file: any = []): boolean => {
    const accepted = ["image/jpeg", "image/png"];
    const isJpgOrPng = accepted.includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
        toast(createMessage(intl.formatMessage({ id: "only_jpg_png" }), HiInformationCircle));
    } else if (!isLt2M) {
        toast(createMessage(intl.formatMessage({ id: "must_be_lt_2m" }), HiInformationCircle));
    }

    return isJpgOrPng && isLt2M;
};

export default {
    getProductsAsOptions,
};
