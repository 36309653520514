import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateUserTokenSecret } from "../../../../slices/user";
import { setLanguage } from "../../../../slices/setting";
import { getAdminDetails } from "../../../../services/admin";
import { RootState } from "../../../../app/reducer";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    //@ts-ignore
    const headers: any = window.__INITIAL_HEADERS__;
    const token = _.get(headers, "Token");
    const secret = _.get(headers, "Secret");
    const queries = location.search?.[0] === "?" ? qs.parse(location.search.substr(1)) : qs.parse(location.search);
    const stateToken = useSelector((state: RootState) => state.user?.token);
    const stateSecret = useSelector((state: RootState) => state.user?.secret);

    useEffect(() => {
        if (token && secret) {
            dispatch(
                updateUserTokenSecret({
                    token,
                    secret,
                    callBack: () => {
                        history.push(`/settings/${queries.page}`);
                    },
                })
            );
        }
    }, [dispatch, token, secret, history, queries]);

    useEffect(() => {
        if (stateToken && stateSecret) {
            dispatch(getAdminDetails());
        }
    }, [dispatch, stateToken, stateSecret]);

    useEffect(() => {
        if (queries.lan) {
            dispatch(setLanguage(queries.lan));
        }
    }, [dispatch, queries]);

    return (
        <div style={{ padding: 20 }}>
            {/* <div>Headers: {JSON.stringify(headers)}</div>
            <div>Passed Token: {token}</div>
            <div>Passed Secret: {secret}</div>
            <div>State Token: {stateToken}</div>
            <div>State Secret: {stateSecret}</div>
            <div>Query: {queries.page}</div>
            <div onClick={() => history.push(`/settings/${queries.page}`)}>Direct</div> */}
        </div>
    );
}

export default App;
