import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { InputNumber, Select, Input } from "antd";
import config from "config";
import helper, { getShippingOptions, getKeyByValue, reviseDeliveryMethodWithStore, isPickup } from "util/helper";
import { setState } from "slices/create-order";
import PeriodPicker from "../period-picker";
import { DINE_IN } from "app/constants";
import { CUSTOMER_ROLES } from "pages/customers/constants";
import { setCustomersState } from "slices/customers";
import PickupLocationSelector from "components/pickup-location-selector";

class ShippingMethod extends Component<any, any> {
    componentDidMount = () => {
        const shippingOptions = getShippingOptions(this.props.storeRecords);
        if (_.isNil(this.props.shippingMethod)) {
            this.props.setState({ shippingMethod: shippingOptions[0]?.value });
        }
    };

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id: id || " " }, values);

    isDineIn = () => {
        const methods = config.SHIPPING_MAPPING_TO_NUMERIC;
        return (
            reviseDeliveryMethodWithStore(
                this.props.storeRecords,
                getKeyByValue(methods, this.props.shippingMethod)
            ) === DINE_IN
        );
    };

    /**
     * Check if the current selected customer is a default guest customer.
     *
     * @returns True if the selected customer is default guest customer.
     */
    isDefaultGuestCustomer = () => {
        const selectedCustomer = this.props.selectedCustomer;
        return selectedCustomer?.role === CUSTOMER_ROLES.default || selectedCustomer?.role === CUSTOMER_ROLES.guest;
    };

    /**
     * Handle when the shipping option is clicked.
     *
     * @param shippingOption - clicked shipping option
     */
    onShippingOptionClicked = (shippingOption: number) => {
        // if a default guest customer is selected and
        // the shipping method is changed to delivery,
        // it removes the selected default guest customer
        if (helper.isDelivery(shippingOption) && this.isDefaultGuestCustomer()) {
            this.props.setCustomersState({ customer: {} });
        }
        if (!helper.isDelivery(shippingOption)) {
            this.props.setState({
                payment: {
                    ...this.props.state?.payment,
                    shippingFee: 0,
                },
            });
        }
        this.props.setState({ shippingMethod: shippingOption });
        this.props.setState({
            expectedDeliveryTimeStart: null,
            expectedDeliveryTimeEnd: null,
        });
    };

    renderTitle = () => {
        return (
            <div className="section-title">
                <div>
                    <FormattedMessage id={"order_type"} />
                </div>
            </div>
        );
    };

    renderInput = () => {
        const methods = config.SHIPPING_MAPPING_TO_NUMERIC;
        return (
            <Select
                id="create-order-shipping-method-select"
                showAction={["click", "focus"]}
                style={{ width: 250 }}
                size="large"
                onChange={(shippingOption: any) => this.onShippingOptionClicked(shippingOption)}
                value={
                    this.props.shippingMethod
                        ? this.str(
                              reviseDeliveryMethodWithStore(
                                  this.props.storeRecords,
                                  getKeyByValue(methods, this.props.shippingMethod)
                              )
                          )
                        : ""
                }
                options={getShippingOptions(this.props.storeRecords)}
            />
        );
    };

    renderOrderType = () => {
        return (
            <div className="createOrder-list-item-container">
                <div className="createOrder-list-item-section-one">
                    <FormattedMessage id={"order_type"} />
                </div>
                <div className="createOrder-list-item-section-two"> {this.renderInput()} </div>
            </div>
        );
    };

    renderPartySize = () => {
        return (
            <div className="createOrder-list-item-container">
                <div className="createOrder-list-item-section-one">
                    <FormattedMessage id={"party_size"} />
                </div>
                <div className="createOrder-list-item-section-two">
                    <InputNumber
                        onChange={(v) =>
                            this.props.setState({
                                partySize: v,
                            })
                        }
                        value={this.props.state.partySize}
                        style={{ width: 250 }}
                        min={1}
                        max={99}
                    />
                </div>
            </div>
        );
    };

    renderTable = () => {
        return (
            <div className="createOrder-list-item-container">
                <div className="createOrder-list-item-section-one">
                    <FormattedMessage id={"table_number"} />
                </div>
                <div className="createOrder-list-item-section-two">
                    <Input
                        maxLength={4}
                        onChange={(e) =>
                            this.props.setState({
                                tableNumber: e.target.value,
                            })
                        }
                        value={this.props.state.tableNumber}
                        style={{ width: 250 }}
                    />
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="section">
                {this.renderOrderType()}
                {isPickup(this.props.shippingMethod) && <PickupLocationSelector />}
                <PeriodPicker />
                {this.isDineIn() ? this.renderTable() : null}
                {this.isDineIn() ? this.renderPartySize() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
    state: _.get(state, "createOrder", ""),
    storeRecords: _.get(state, "store.records", {}),
    selectedCustomer: _.get(state, "customers.customer", {}),
});

const mapDispatchToProps = {
    setState,
    setCustomersState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShippingMethod)));
