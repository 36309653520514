import React from "react";
import { Tabs, Input, Button, Modal, Spin } from "antd";
import _ from "lodash";
import "./index.scss";
import qs from "querystring";
import { AiOutlineClose } from "react-icons/ai";
import { BiSliderAlt } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../app/reducer";
import Wrapper from "../../components/wrapper";
import ReviewTab from "./_components/reviewTab";
import FilterModal from "./_components/filter-modal";
import { setFilterModal, setFilters, setRangeFilters, setSearchValue } from "../../slices/reviews";
import { getReviews } from "./../../services/reviews";
import RefreshButton from "components/refresh";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/reviews", breadcrumbName: "reviews" },
    ],
};

export enum ReviewTabs {
    Orders = "order_review",
    Store = "store_review",
    Product = "product_review",
}

const tabs: any = [
    { key: ReviewTabs.Orders, tab: ReviewTab },
    { key: ReviewTabs.Store, tab: ReviewTab },
    { key: ReviewTabs.Product, tab: ReviewTab },
];

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const filterOpen = useSelector((state: RootState) => state?.reviews?.filterOpen) ?? false;
    const filters = useSelector((state: RootState) => state?.reviews?.filters) ?? {};
    const totalReviews = useSelector((state: RootState) => state?.reviews?.totalReviews) ?? 0;
    const scope = useSelector((state: RootState) => state?.reviews?.scope) ?? 1;
    const tab = String(qs.parse(location.search)?.["?tab"] ?? ReviewTabs.Orders);
    const onChangeTab = (tab: string) => history.push("/reviews?tab=" + tab);

    const renderFilterBox = (name: string, value: any) => (
        <div className="filter-item-holder d-flex align-items-center mr-2">
            <div className="mr-1">
                <FormattedMessage id={name ?? " "} />: {value}
            </div>
            <AiOutlineClose className="pHover" size={13} onClick={() => removeFilter(name)} />
        </div>
    );

    const correctlyClearRangeFilters = (filter: string) => {
        if ((filter === "dt_end" && !filters?.dt_start) || (filter === "dt_start" && !filters?.dt_end)) {
            dispatch(setRangeFilters({ name: "dateFilters", value: 0 }));
        }
        if (
            (filter === "update_dt_end" && !filters?.update_dt_start) ||
            (filter === "update_dt_start" && !filters?.update_dt_end)
        ) {
            dispatch(setRangeFilters({ name: "upDateFilters", value: 0 }));
        }
    };

    const removeFilter = (filter: string) => {
        if (filter !== "customer_id" && filter !== "pid") {
            correctlyClearRangeFilters(filter);
        }
        dispatch(setFilters({ name: filter, value: undefined }));
    };

    const renderFilters = () => {
        const filts: any = [];
        Object.keys(filters)?.forEach((key) => {
            filters[key] &&
                key !== "page" &&
                key !== "page_size" &&
                filts.push({ filter: key, value: filters[key], name: key });
        });
        return (
            <div className="filtersRow my-2 d-flex">
                {filts?.map((filt: any) => renderFilterBox(filt.name, filt.value))}
            </div>
        );
    };

    const searchHandler = (value: string) => {
        dispatch(setSearchValue(value));
        if (_.isEmpty(value)) {
            dispatch(getReviews({ scope, ...filters }));
        } else {
            dispatch(getReviews({ page: 1, limit: totalReviews }));
        }
    };

    const renderFilterBar = (
        <div className="codes-search-bar d-flex">
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <Input.Search
                            className="codes-search-input"
                            placeholder={intl.formatMessage({ id: "search_by_name" })}
                            allowClear
                            pattern="[A-Za-z]"
                            size="large"
                            onChange={(e: any) => searchHandler(e.target.value ?? "")}
                        />
                        <Button
                            className="filter-button"
                            icon={<BiSliderAlt size={20} />}
                            onClick={() => dispatch(setFilterModal({ value: true }))}
                        >
                            <div className="icon-button-text d-inline">
                                <FormattedMessage id="filters" />
                            </div>
                        </Button>
                    </div>
                    {renderFilters()}
                </div>
            </div>
        </div>
    );

    const refreshPage = () => {
        dispatch(getReviews({ scope, ...filters }));
    };

    const { loading } = useSelector((state: RootState) => ({
        loading: state?.reviews?.loading,
    }));

    const renderFilterModal = (
        <Modal
            visible={filterOpen}
            title={intl.formatMessage({ id: "customer_filter" })}
            width="600px"
            footer={null}
            onCancel={() => dispatch(setFilterModal({ value: false }))}
        >
            <FilterModal />
        </Modal>
    );

    return (
        <Wrapper helmet={{ title: "reviews" }} breadcrumb={breadcrumb}>
            <div className="customer-reviews-page w100">
                <Spin spinning={loading}>
                    <Tabs
                        type="card"
                        activeKey={tab}
                        defaultActiveKey={tab}
                        className="w100"
                        onChange={onChangeTab}
                        style={{ marginTop: 14 }}
                        tabBarExtraContent={<RefreshButton refreshPage={refreshPage} loading={loading} />}
                    >
                        {tabs?.map((tab: any, i: number) => (
                            <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                                {renderFilterBar}
                                {renderFilterModal}
                                <tab.tab props={i} />
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </Spin>
            </div>
        </Wrapper>
    );
}

export default App;
