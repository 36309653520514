//@ts-nocheck
import { Button, Modal, Upload, Image, Progress } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "../../../../app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { HiCamera, HiX } from "react-icons/hi";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { runImageCacheHelper } from "../../../../services/image-cache";
import { editProduct } from "../../../../services/products";
import ImgCrop from "antd-img-crop";
import { beforeUpload } from "pages/products/helper";
import { getImage } from "../../../../util/helper/index";
import { getImageListSuccess } from "../../../../slices/products";
const PROGRESS_INDICATOR_WIDTH = 64;

const selector = (state: RootState) => ({
    product: state?.products?.product,
    user: state?.user,
    imageState: state?.products?.imageList,
});

interface ProdImgEditProps {
    images: any;
    onChange: any;
    addImage: any;
    onSave?: any;
}

const _getImage = (url: string) => {
    if (url.includes("cache")) {
        return url;
    } else {
        return getImage(url);
    }
};

const ProductImagesEdit = (props: ProdImgEditProps): JSX.Element => {
    const intl = useIntl();
    const { images } = props;
    const dispatch = useDispatch();
    const [fileList] = useState<UploadFile[]>([]);
    const [imageList, setImagelist] = useState<string[]>([]);
    const setImageState = (e) => dispatch(getImageListSuccess(e));
    const { product, user, imageState } = useSelector(selector);

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        backgroundColor: isDragging ? "lightgreen" : "white",
        marginRight: 16,
        paddingBottom: 16,
        ...draggableStyle,
    });

    const onDragEnd = (result: DropResult) => {
        setImagelist((prev) => {
            const list = _.cloneDeep(prev);
            const [removed] = list.splice(result.source?.index, 1);
            list.splice(result.destination?.index ?? 0, 0, removed);

            props.onChange(list);
            return list;
        });
    };

    const handleChange = ({ fileList }: UploadChangeParam) => {
        setImagelist([...imageList, fileList[fileList.length - 1]?.uid]);
    };

    const uploadToImageCache = async ({ file, onSuccess }: any) => {
        await runImageCacheHelper({
            action: "add_image_cache",
            type: "product",
            imageType: "general",
            user_id: user.id,
            cloudinaryImagesCount: product?.images?.length ?? 0,
            name: file.uid,
            "fileToUpload[]": file,
        });

        const cache: any = await runImageCacheHelper({
            action: "load_image_cache",
            user_id: user.id,
            imageType: "general",
            type: "product",
        });

        //setFileList([...fileList, file]);
        props.addImage(cache);

        onSuccess("ok");
    };

    const onSubmit = async () => {
        dispatch(
            editProduct({
                product_id: product?.product_id,
                images: [...imageList],
            })
        );

        props.onSave();
    };

    const deleteImage = (image: string) => (e: any) => {
        e.stopPropagation();
        Modal.confirm({
            title: intl.formatMessage({ id: "confirm_delete" }),
            content: intl.formatMessage({ id: "are_you_sure_delete_image" }),
            onOk: () => {
                props.onChange([...images.filter((img: any) => img !== image)]);
                setImagelist([...imageList.filter((img: any) => img !== image)]);
                setImageState([...imageList.filter((img: any) => img !== image)]);
                const uid = image.split("/")[image.split("/").length - 1];
                const file = fileList.find((file) => file.uid === uid);
                if (fileList.length == 0 || file) {
                    runImageCacheHelper({
                        action: "delete_image_cache",
                        type: "product",
                        imageType: "general",
                        user_id: user.id,
                        //image_file: file?.uid + ".jpeg",
                        image_file: uid,
                    });
                }
            },
        });
    };

    const handleRemove = async (file: any) => {
        await runImageCacheHelper({
            action: "delete_image_cache",
            type: "product",
            imageType: "general",
            user_id: user.id,
            image_file: file.uid + ".jpeg",
        });
    };

    const renderUploading = (file: any) => {
        return file?.status === "uploading" ? (
            <div className="item-image-container">
                <div className="upload-progress-container">
                    <div className="upload-progress-text">
                        <FormattedMessage id="uploading" />
                    </div>
                    <div>
                        <Progress type="circle" percent={file.percent} width={PROGRESS_INDICATOR_WIDTH} />
                    </div>
                </div>
            </div>
        ) : null;
    };

    const getImg = (item: string, i: number) => {
        return (
            <Draggable key={item} draggableId={item} index={i}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-image"
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="item-image-container">
                            <div className="item-image-delete" onClick={deleteImage(item)}>
                                <HiX />
                            </div>
                            <Image src={_getImage(item)} alt="product image" />
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    const getImages = () => (
        <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
                <div ref={provided.innerRef} style={{ display: "flex", padding: 16, flexWrap: "wrap" }}>
                    {imageList.map(getImg)}
                    {fileList.map(renderUploading)}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );

    const getUploader = () => (
        <div className="item-images-uploader" style={{ display: imageList.length >= 5 ? "none" : "block" }}>
            <ImgCrop grid={true}>
                <Upload.Dragger
                    customRequest={uploadToImageCache}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    fileList={fileList}
                    onRemove={handleRemove}
                    disabled={imageList.length >= 5}
                >
                    <div>
                        <div>
                            <HiCamera />
                        </div>
                    </div>
                    <div>
                        <FormattedMessage id="add_image" />
                    </div>
                </Upload.Dragger>
            </ImgCrop>
        </div>
    );

    useEffect(() => {
        const shouldUpdate = () => {
            return (
                // Cater 2 scenarios
                // 1. At initialize
                // 2. Upload new Image
                (_.isEmpty(imageList) && !_.isEmpty(props?.images)) ||
                (!_.isEmpty(imageList) && _.isEmpty(props?.images)) ||
                (!_.isEmpty(props?.images) && imageState != props?.images)
            );
        };

        if (shouldUpdate()) {
            setImagelist(props?.images);
            setImageState(props?.images);
        } else if (imageState == props?.images && imageState != imageList) {
            setImageState([]);
        }
    }, [props?.images]);

    return (
        <div>
            <div className="item-images-desc">
                <FormattedMessage id="item_images_desc" />
            </div>
            <div className="item-images-uploads">
                <DragDropContext onDragEnd={onDragEnd}>{getImages()}</DragDropContext>
                {getUploader()}
            </div>
            {props.onSave ? (
                <div className="footer-item-changes">
                    <Button type="primary" onClick={onSubmit}>
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default ProductImagesEdit;
