import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { RootState } from "../app/reducer";
import { createMessage } from "../components/message";
import { getIntl } from "../locale";
import { getCategoriesSuccess, setCategoriesState } from "../slices/categories";
import { oauth } from "../util/api";

export const getCategories =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setCategoriesState({ loading: true }));
        try {
            const response: any = await oauth("CATEGORIES")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getCategoriesSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setCategoriesState({ loading: false }));
    };

export const updateCategory =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("CATEGORIES")({
                method: "POST",
                body: JSON.stringify({ data: [params ?? {}] }),
            });
            switch (response.RC) {
                case 200:
                    dispatch(getCategories());
                    toast(createMessage(intl.formatMessage({ id: "store_information_saved" }), HiInformationCircle));

                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateCategories =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("CATEGORIES")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response.RC) {
                case 200:
                    toast(createMessage(intl.formatMessage({ id: "store_information_saved" }), HiInformationCircle));
                    break;
                default:
                    break;
            }

            dispatch(getCategories());
        } catch (e) {
            // Handle error
        }
    };

export const deleteCategory =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth(
                "CATEGORIES_DELETE",
                "",
                params
            )({
                method: "DELETE",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getCategories());
                    toast(createMessage(intl.formatMessage({ id: "category_deleted" }), HiInformationCircle));

                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
