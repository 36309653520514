import React from "react";
import PaymentIcons from "../../../../../images/icons/payment-icon.png";
import GoopterLogo from "../../../../../images/icons/official-goopter-logo.png";
import { View, Image, Text } from "@react-pdf/renderer";

const scanOrderIcons = (styles: React.CSSProperties | any): JSX.Element => (
    <React.Fragment>
        <View style={styles.qrCodeTextCont}>
            <Text>Scan to Order</Text>
        </View>
        <View style={styles.payment_spacing} />
        <View style={styles.paymentContainer}>
            <Image src={PaymentIcons} />
        </View>
        <View style={styles.payment_spacing} />
        <View style={styles.goopterContainer}>
            <View style={styles.poweredBy}>
                <Text>Powered by </Text>
            </View>
            <View style={styles.goopterLogo}>
                <Image src={GoopterLogo} />
            </View>
        </View>
    </React.Fragment>
);
export default scanOrderIcons;
