import React, { useEffect, useState, useCallback } from "react";
import { Collapse, Button, Switch, Modal, Radio, InputNumber, Select } from "antd";
import _ from "lodash";
import sH from "./helper";
import config from "../../.../../../../config";
import { getBlockOptions } from "../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import { setSelfServeState, setStoreState } from "../../../../slices/store";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import ObjectModel from "../../../../util/models";
import StoreSelfServe from "../../../../util/models/store-self-serve";
import helper, { isBool } from "../../../../util/helper";
import { QRCode } from "react-qrcode-logo";
import FormatModal from "./format-modal";
import "./serve-print.scss";
import { setConfirmationDineIn } from "../../../../slices/setting";
import { Prompt } from "react-router-dom";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        {
            path: "/settings/selfServe",
            breadcrumbName: "self_serve_ordering_setting",
        },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selfServe = useSelector((state: RootState) => state.store?.storeSelfServe);
    const records = useSelector((state: RootState) => state.store?.records);

    const setCustomText = useSelector((state: RootState) => state.store?.setCustomText);
    const customText1 = useSelector((state: RootState) => state.store?.customText1);
    const customText2 = useSelector((state: RootState) => state.store?.customText2);
    const requireDineInConfirmation = useSelector((state: RootState) => state.setting?.requireDineInConfirmation);

    const openFormatModal = _.get(selfServe, "openFormatModal", false);
    const addr = _.get(selfServe, "store_addr", {});
    const g_id = _.get(selfServe, "store_id", "");
    const [paperSize, setPaperSize] = useState(sH.paperSizes.A4);
    const [qrStyle, setQRStyle] = useState(sH.qrStyles.SQUARES);
    const [bgColor, setBgColor] = useState(sH.bgColours.TURQUOISE);
    const [addMargin, setAddMargin] = useState<boolean>(false);
    const [codeTid, setCodeTid] = useState(sH.codeTid.TABLE);
    const [bleedingMargin, setBleedingMargin] = useState<any>(3);
    const [showRewards, setShowRewards] = useState<boolean>(false);
    const [exclude, setExclude] = useState(false);
    const [stationNum, setStationNum] = useState<any>(4);
    const [label, setLabel] = useState<any>();
    const [qrCodes, setQrCodes] = useState([]);
    const [startNum, setStartNum] = useState("A1");
    const [tableNums, setTableNums] = useState([]);
    const [dataURLS, setDataURLS] = useState([]);
    const { lan } = useLanguageSelect();
    const [originalValues, setOriginalValues] = useState<any>({});

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    useEffect(() => {
        setOriginalValues(
            new ObjectModel(StoreSelfServe).convertToPostData(selfServe, records, "self_serve_ordering_setting")
        );
    }, [records, selfServe]);

    const compareOriginal = () => {
        const original = _.cloneDeep(originalValues);
        const newValues = new ObjectModel(StoreSelfServe).convertToPostData(
            selfServe,
            records,
            "self_serve_ordering_setting"
        );
        return _.isEqual(original, newValues);
    };

    const setState = (name: string, value: any) => dispatch(setSelfServeState({ name, value }));

    const saveChanges = () => {
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreSelfServe).convertToPostData(selfServe, records, "self_serve_ordering_setting")
            )
        );
        setOriginalValues(
            new ObjectModel(StoreSelfServe).convertToPostData(selfServe, records, "self_serve_ordering_setting")
        );
    };

    const switchOnChange = (value: any, name: string, local?: any) => {
        value = !isBool(value);
        local ? local(value) : dispatch(setSelfServeState({ name, value: helper.isNum(value).toString() }));
    };

    const renderAcceptSelfServeSubSwitches = () => (
        <div>
            {isBool(selfServe.allow_eatin)
                ? renderSwitchDesc(
                      "require_scan_instore",
                      selfServe.require_scan_instore,
                      "require_scan_instore",
                      false
                  )
                : null}
            {isBool(selfServe.allow_eatin)
                ? renderSwitchDesc("require_table_num", selfServe.require_table_num, "require_table_num", false)
                : null}
            {isBool(selfServe.allow_eatin) ? (
                <div className="serve-option-section-title">
                    <FormattedMessage id="order_confirmation" />
                </div>
            ) : null}
            {isBool(selfServe.allow_eatin)
                ? renderSwitchDesc(
                      "require_due_time_for_in_store",
                      selfServe.require_due_time_for_in_store_order,
                      "require_due_time_for_in_store_order",
                      false,
                      true
                  )
                : null}
            {isBool(selfServe.allow_eatin) && !isBool(selfServe.require_due_time_for_in_store_order)
                ? renderSwitchDesc(
                      "dine_in_confirmation",
                      requireDineInConfirmation,
                      "dine_in_confirmation",
                      (value: any) => {
                          dispatch(setConfirmationDineIn(value));
                      },
                      true
                  )
                : null}
        </div>
    );

    const renderAcceptSelfServeSwitch = () => (
        <div>{renderSwitchDesc("accept_self_serve", selfServe.allow_eatin, "allow_eatin", false)}</div>
    );

    // renders the primary Allow Self-Serve Ordering card
    const renderBothToggles = () => (
        <div>
            <div className="serve-option-layer">
                <div className="store_name-title">
                    <FormattedMessage id="num_stations" />
                </div>
                {/* input the number of stations */}
                <InputNumber
                    className="custom-input-textfield"
                    value={stationNum}
                    onChange={(e: number) => setStationNum(e)}
                />
            </div>
            {renderSyncCartSetting()}
            {renderAcceptSelfServeSwitch()}
            {renderAcceptSelfServeSubSwitches()}
        </div>
    );

    const toggleSetCustomText = () => {
        dispatch(setStoreState({ setCustomText: !setCustomText }));
    };

    const renderSwitchDesc = (
        description: string,
        value: any,
        name: string,
        local?: any,
        withDescription: any = false
    ) => (
        <div className="d-flex justify-content-start align-items-center">
            <Switch checked={isBool(value)} onChange={() => switchOnChange(value, name, local)} />
            <div className="serve-desc-holder">
                <p className="ant-descriptions-description">
                    <FormattedMessage id={description} />
                </p>
                {withDescription ? (
                    <p className="serve-desc-holder-description">
                        <FormattedMessage id={`${description}_description`} />
                    </p>
                ) : null}
            </div>
        </div>
    );

    // renders the Sync Cart With Server of the primary card
    const renderSyncCartSetting = () => {
        const options: any = {
            do_not_sync: "0",
            sync_always: "1",
            sync_every_x_sec: "5",
        };
        const actualValue = _.get(selfServe, "sync_cart_with_server", {});
        const optionValue = [options.do_not_sync, options.sync_always].includes(actualValue)
            ? actualValue
            : options.sync_every_x_sec;
        return (
            <div className="serve-option-layer">
                <div className="d-flex flex-column justify-center align-flex-start">
                    <div style={{ marginBottom: 18 }} className="store_name-title">
                        <FormattedMessage id="sync_cart_with_server" />
                    </div>
                    <div style={{ marginBottom: 5 }} className="serve-desc-holder-description">
                        <FormattedMessage id="sync_cart_with_server_description" />
                    </div>
                </div>

                <div className="d-flex justify-content-start align-items-center">
                    <Select
                        style={{ width: 250 }}
                        value={optionValue}
                        onChange={(value) => setState("sync_cart_with_server", value)}
                    >
                        {Object.keys(options).map((label) => (
                            <Select.Option
                                key={`server-option-${label}`}
                                value={options[label]}
                                disabled={String(options[label]) === String(options.sync_always)}
                            >
                                <FormattedMessage id={label} />
                            </Select.Option>
                        ))}
                    </Select>
                    {String(optionValue) === String(options.sync_every_x_sec) ? (
                        <InputNumber
                            min={3}
                            max={10}
                            value={actualValue}
                            onChange={(v: number) => setState("sync_cart_with_server", v)}
                        />
                    ) : null}
                </div>
            </div>
        );
    };

    const renderBottomSection = () => (
        <div className="white-layered-block d-flex flex-row justify-content-start align-items-start">
            <div className="serve-bottom-left-section">
                <div className="store_name-title">
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
                        <Collapse.Panel header={intl.formatMessage({ id: "print_your_qr" })} key="2">
                            <div>
                                <p className="ant-descriptions-description">
                                    <FormattedMessage id="print_your_qr_description" />
                                </p>
                            </div>
                            {renderQROptions()}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );

    const renderQROptions = () => (
        <div className="d-flex flex-column justify-content-start align-items-start">
            {renderSwitchDesc("exclude_station_desc", exclude, "", setExclude)}
            {!isBool(exclude) ? renderNotExclude() : null}
        </div>
    );

    // renders Station Number Settings
    const renderNotExclude = () => (
        <div className="serve-option-layer d-flex justify-content-start align-items-start">
            <div className="d-flex flex-column justify-content-start align-items-start mt-4 mr-3">
                <div className="store_name-title">
                    <FormattedMessage id="station_label" />
                </div>
                <input
                    className="custom-input-textfield w200px ta-left"
                    onChange={(e) => setLabel(e.target.value)}
                    placeholder={intl.formatMessage({ id: "station_label_placeholder" })}
                    value={label}
                />
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start mt-4 ml-3">
                <div className="store_name-title">
                    <FormattedMessage id="start_number" />
                </div>
                <input
                    className="custom-input-textfield"
                    value={startNum}
                    onChange={(e) => setStartNum(e.target.value)}
                />
            </div>
        </div>
    );

    const onChangeCustomText1 = (e: any) => {
        dispatch(
            setStoreState({
                customText1: e.target.value,
            })
        );
    };
    const onChangeCustomText2 = (e: any) => {
        dispatch(
            setStoreState({
                customText2: e.target.value,
            })
        );
    };

    const renderCustomNotExclude = () => (
        <div className="custom-text-cont">
            <div className="d-flex flex-column justify-content-start align-items-start mt-4 mr-3">
                <div className="store_name-title">
                    <FormattedMessage id="custom_text_1" />
                </div>
                <input
                    className="custom-text-input w200px ta-left"
                    onChange={onChangeCustomText1}
                    placeholder={intl.formatMessage({ id: "custom_text_placeholder" })}
                    value={customText1}
                />
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start mt-4">
                <div className="store_name-title">
                    <FormattedMessage id="custom_text_2" />
                </div>
                <input
                    className="custom-text-input w200px "
                    onChange={onChangeCustomText2}
                    placeholder={intl.formatMessage({ id: "custom_text_placeholder_2" })}
                    value={customText2}
                />
            </div>
        </div>
    );

    // handles click on Generate QR Code
    const generateQRS = () => {
        getQRURLS();
        setState("openFormatModal", true);
    };

    const renderTopSection = () => (
        <div className="white-layered-block serve-top-section">
            <div className="serve-store-info-area">
                <div className="store_name-title">{_.get(selfServe, `store_name_${lan}`, "")}</div>
                <div>{`${addr.street}, ${addr.city}, ${addr.province}, ${addr.post_code}`}</div>
                <div>{selfServe.phone}</div>
            </div>
            {isBool(selfServe.allow_eatin) ? (
                <Button type="default" className="text-on-white-background" onClick={() => generateQRS()}>
                    <FormattedMessage id="generate_qr" />
                </Button>
            ) : null}
        </div>
    );

    const renderFormatModal = () => {
        const onConfirmFormat = () => {
            setTimeout(() => {
                setState("showDownloadModal", true);
            }, 1500);
            setState("openFormatModal", false);
        };
        const renderFormatModalFooter = () => (
            <div className="bottomFooter">
                <Button onClick={() => onConfirmFormat()}>
                    <FormattedMessage id="confirm_format" />
                </Button>
            </div>
        );
        return (
            <Modal
                visible={openFormatModal}
                title={intl.formatMessage({ id: "format_qr_codes" })}
                width="90vw"
                footer={renderFormatModalFooter()}
                onCancel={() => setState("openFormatModal", false)}
            >
                <FormatModal
                    g_id={g_id}
                    label={label}
                    exclude={exclude}
                    statNum={stationNum}
                    startNum={startNum}
                    storeName={_.get(selfServe, `store_name_${lan}`, "")}
                    showTable={isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num)}
                    dataURLS={dataURLS}
                    bgColor={bgColor}
                    paperSize={paperSize}
                    qrStyle={qrStyle}
                    codeType={codeTid}
                    showRewards={showRewards}
                    addMargin={addMargin}
                    bleedingMargin={bleedingMargin}
                />
            </Modal>
        );
    };

    const renderSaveSection = () => (
        <div className="setting-actions" style={{ marginTop: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    //========================================================||
    // =========================== RENDER FORMAT SECTION =====||
    //========================================================||

    const renderCodeType = () => (
        <div>
            {isBool(selfServe?.require_table_num) ? (
                <div className="d-flex mb-3">
                    <div className="store_name-title mr-3">
                        <FormattedMessage id="qr_code_type" />:
                    </div>
                    <Radio.Group onChange={(e: any) => setCodeTid(e.target.value)} value={codeTid}>
                        <Radio value={sH.codeTid.TABLE}>
                            <FormattedMessage id="table_numberz" />
                        </Radio>
                        <Radio value={sH.codeTid.KIOSK_ORDER}>
                            <FormattedMessage id="kiosk_number" />
                        </Radio>
                        <Radio value={sH.codeTid.PARKING_LOT}>
                            <FormattedMessage id="parking_lot" />
                        </Radio>
                    </Radio.Group>
                </div>
            ) : null}
        </div>
    );

    const renderCodeStyle = () => (
        <div className="d-flex mb-3">
            <div className="store_name-title mr-3">
                <FormattedMessage id="qr_code_style" />:
            </div>
            <Radio.Group onChange={(e: any) => setQRStyle(e.target.value)} value={qrStyle}>
                <Radio value={sH.qrStyles.SQUARES}>
                    <FormattedMessage id="squares" />
                </Radio>
                <Radio value={sH.qrStyles.DOTS}>
                    <FormattedMessage id="dots" />
                </Radio>
            </Radio.Group>
        </div>
    );

    const renderPaperstyle = () => (
        <div className="d-flex mb-3">
            <div className="store_name-title mr-3">
                <FormattedMessage id="paper_size" />:
            </div>
            <Radio.Group onChange={(e: any) => setPaperSize(e.target.value)} value={paperSize}>
                <Radio value={sH.paperSizes.A4}>
                    <FormattedMessage id="a4" />
                </Radio>
                <Radio value={sH.paperSizes.A3}>
                    <FormattedMessage id="a3" />
                </Radio>
                <Radio value={sH.paperSizes.LEGAL}>
                    <FormattedMessage id="legal" />
                </Radio>
            </Radio.Group>
        </div>
    );

    const renderColorSquare = (color: string) => (
        <>
            <div className="colorSquare" style={{ background: color }} />
            <Radio value={color} />
        </>
    );

    const getColorOptions = () => [
        sH.bgColours.TURQUOISE,
        sH.bgColours.BLUE,
        sH.bgColours.GREEN,
        sH.bgColours.PURPLE,
        sH.bgColours.ORANGE,
    ];

    const renderColorSquares = (color: any) => (
        <div className="d-flex flex-column" key={color}>
            {renderColorSquare(color)}
        </div>
    );

    const renderColorOptions = () => (
        <div className="d-flex mb-3 align-items-center">
            <div className="store_name-title mr-3">
                <FormattedMessage id="background_colour" />:
            </div>
            <Radio.Group className="d-flex" onChange={(e: any) => setBgColor(e.target.value)} value={bgColor}>
                {getColorOptions().map(renderColorSquares)}
            </Radio.Group>
        </div>
    );

    const renderShowReward = () => (
        <div className="d-flex mb-1 align-items-center">
            {renderSwitchDesc("show_rewards_info", showRewards, "", setShowRewards)}
        </div>
    );

    const renderAddMargin = () => (
        <div className="d-flex mb-1 align-items-center">
            {renderSwitchDesc("add_margin_between_qrs", addMargin, "", setAddMargin)}
        </div>
    );

    // this renders if Include Custom Label in Choose QR Code Style card is toggled ON
    const renderCustomtTextOption = () => (
        <div className="custom-text-cont">
            <div className="d-flex justify-content-start align-items-center ">
                <Switch checked={setCustomText} onChange={toggleSetCustomText} />
                <div className="serve-desc-holder mb-3">
                    <FormattedMessage id={"add_custom_text"} />
                </div>
            </div>
            {setCustomText ? renderCustomNotExclude() : null}
        </div>
    );

    const renderAddBleed = () => (
        <div className="d-flex mb-3 align-items-center">
            <div className="store_name-title mr-3">
                <FormattedMessage id="bleeding_margin" />:
            </div>
            <InputNumber value={bleedingMargin} onChange={(e: any) => setBleedingMargin(parseInt(e))} />
        </div>
    );

    const renderFormatProperties = () => {
        return (
            <div className="white-layered-block d-flex flex-row justify-content-start align-items-start">
                <div className="serve-bottom-left-section">
                    <div className="store_name-title">
                        <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
                            <Collapse.Panel header={intl.formatMessage({ id: "format_qr_codes" })} key="2">
                                {renderCodeType()}
                                {renderCodeStyle()}
                                {renderPaperstyle()}
                                {renderColorOptions()}
                                {renderShowReward()}
                                {renderAddMargin()}
                                {renderCustomtTextOption()}
                                {renderAddBleed()}
                            </Collapse.Panel>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    };

    //=================================================||
    // =========================== RENDER QR CODES ====||
    //=================================================||

    // handles mapping out QR code in DOM
    const getQRURLS = () => {
        // datas.tableNum is what gets printed as the QR code label
        const datas = _.cloneDeep(tableNums);

        qrCodes?.map((code: any, index: number) => {
            // find all canvas tags
            const canvas = document.getElementsByTagName("canvas")[index];
            // @ts-ignore
            datas[index].dataUrl = canvas?.toDataURL();
        });
        setDataURLS(datas);
    };

    const renderQRCode = useCallback(
        (tableNum: any, key: number) => {
            let QRUrl = `${config.H5_URL}store/${g_id}`;
            QRUrl = exclude ? QRUrl : QRUrl + `?lid=${tableNum}&tid=${codeTid}`;
            return <QRCode value={QRUrl} size={130} ecLevel="Q" enableCORS={true} qrStyle={qrStyle} key={key} />;
        },
        [g_id, exclude, codeTid, qrStyle]
    );

    const isNum = (s: any) => !isNaN(s - parseFloat(s));
    const isLetter = (char: any) => /[a-zA-Z]/.test(char);

    //Default tablenum config
    const getOriginalIndex = useCallback(() => {
        // letters: is the starting number pure number
        let letters = false;

        //@ts-ignore
        let printedLabel = startNum.toUpperCase();

        let letterAsNum = 0;
        // prettier-ignore
        const alphabet: Array<string> = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

        //processes characters that are not pure numbers
        // i is the length of Start Number - 1
        const charNotNum = (i: number) => {
            // if the last character is a letter, also do
            if (i === startNum.length - 1) {
                letters = true;
            }
        };

        //processes characters that are numbers
        const charIsNum = (i: number) => {
            i === startNum.length - 1
                ? // if i is the last index, then parse the last character into integer
                  parseInt(startNum.charAt(startNum.length - 1))
                : // if i isn't the last index, then parse all the characters from i to the last into integer
                  parseInt(startNum.substring(i, startNum.length - 1));
        };

        //Gives default table number A1, and numbers to continue (called by getQrCodes())
        const getTableNum = (index: any) => {
            {
                // letters default false
                if (letters) {
                    // Base 26 cipher
                    if (letterAsNum > 0) {
                        // skip encription if already incrypted
                        letterAsNum += 1;
                    } else {
                        // encrypt the printedLabel letters into a number
                        for (let i = startNum?.length - 1; i >= 0; i--) {
                            const isCurrentLetter = (element: string) => element === printedLabel[i];
                            letterAsNum +=
                                (alphabet.findIndex(isCurrentLetter) + 1) *
                                Math.pow(alphabet.length, startNum?.length - 1 - i);
                        }
                    }

                    // decrypt letterAsNum
                    if (letterAsNum > 0) {
                        let quotient = letterAsNum;
                        let remainder: number;
                        printedLabel = "";
                        while (quotient !== 0) {
                            const decremented = quotient - 1;
                            quotient = Math.floor(decremented / alphabet.length);
                            remainder = decremented % alphabet.length;
                            printedLabel = alphabet[remainder] + printedLabel;
                        }
                    }
                    return printedLabel;
                } else {
                    // if the last character is a number, increment number
                    let currentNum = "";
                    let currentLetter = "";

                    for (let i = startNum?.length - 1; i >= 0; i--) {
                        if (isNum(printedLabel[i])) {
                            currentNum = printedLabel[i] + currentNum;
                        } else {
                            currentLetter = printedLabel[i] + currentLetter;
                        }
                    }
                    return `${currentLetter}${parseInt(currentNum) + index}`;
                }
            }
        };

        const getQrCodes = () => {
            // codes provide the information needed to generate the QR code
            const codes: any = [];
            // tableNumz is an array of the generated station numbers
            const tableNumz: any = [];
            _.times(stationNum, (index) => {
                const tableNum = getTableNum(index);
                if ((letters && isLetter(tableNum)) || !letters) {
                    tableNumz.push({ tableNum });
                    codes.push(renderQRCode(tableNum, index));
                }
            });
            setTableNums(tableNumz);
            setQrCodes(codes);
        };

        for (let i = startNum?.length - 1; i >= 0; i--) {
            // runs through the Start Number from the last character to the first and determine if is number
            !isNum(startNum.charAt(i)) ? charNotNum(i) : charIsNum(i);
        }
        // after all the indexs have been verified, run getQrCodes()
        getQrCodes();
    }, [renderQRCode, startNum, stationNum]);

    useEffect(() => {
        getOriginalIndex();
    }, [stationNum, startNum, codeTid, qrStyle, getOriginalIndex]);

    const renderCodes = () => <div className="hiddenQRS">{qrCodes?.map((code) => code)}</div>;

    const renderLeavePagePromp = () => {
        return (
            <Prompt
                when={!compareOriginal()}
                message={() => {
                    return intl.formatMessage({ id: "alert_leaving_without_save" });
                }}
            />
        );
    };

    return !_.isEmpty(selfServe) ? (
        <Wrapper helmet={{ title: "self_serve_ordering_setting" }} breadcrumb={breadcrumb}>
            {renderLeavePagePromp()}
            {renderTopSection()}
            {getBlockOptions("allow_self_serve", "allow_self_serve_description", renderBothToggles())}
            {isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num) ? renderBottomSection() : null}
            {isBool(selfServe.allow_eatin) ? renderFormatProperties() : null}
            {renderFormatModal()}
            {renderSaveSection()}
            {renderCodes()}
        </Wrapper>
    ) : null;
}
export default App;
