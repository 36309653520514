import { Modal, Button, Alert, Checkbox } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isBool } from "../../../../util/helper";
import cx from "classnames";
import moment from "moment";
import { updateCustomer } from "../../../../services/customers";
import { getCustomerId } from "./helper";

class EmailMarketing extends Component {
    state = {};

    getCid = (props = this.props) => getCustomerId(props);

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    isSubscribed = () => isBool(_.get(this.props, "customer.subscribe_email"));

    renderTitle = () => {
        return (
            <div className="customer-section-title">
                <FormattedMessage id="email_marketing" />
                <Button onClick={() => this.setState({ openEditInfo: true })} size="large" type="link">
                    <FormattedMessage id="edit_status" />
                </Button>
            </div>
        );
    };

    renderSubscribedText = () => {
        const subscribedDate = moment(_.get(this.props, "customer.subscribe_email_dt"), "YYYY-DD-MM HH:mm:ss");
        return (
            <div className="customer-email-marketing-subscribed-section">
                <div
                    className={cx({
                        "customer-email-marketing-subscribed-text": true,
                        "customer-email-marketing-subscribed-subscribed": this.isSubscribed(),
                    })}
                >
                    <FormattedMessage id={this.isSubscribed() ? "subscribed" : "not_subscribed"} />
                </div>
                {this.isSubscribed() ? (
                    <div>
                        <FormattedMessage
                            id={"subscribed_on_x"}
                            values={{
                                time: subscribedDate.isValid()
                                    ? subscribedDate.local().format("MMM D, YYYY")
                                    : this.str("unknown"),
                            }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    };

    renderSubscribeModal = () => {
        const checkValue = _.isEmpty(this.state.subscribe) ? this.isSubscribed() : isBool(this.state.subscribe);

        const renderTitle = () => (
            <div className="edit-cusotmer-modal-title">
                <FormattedMessage id="edit_email_marketing_status" />
            </div>
        );

        const renderAlert = () =>
            this.state.showAlert ? (
                <div className="email-marketing-alert">
                    <Alert message={this.str("edit_email_marketing_status_warning")} type="warning" showIcon />
                </div>
            ) : null;

        const renderCheckbox = () => (
            <div className="email-marketing-checkbox">
                <Checkbox
                    onChange={() => {
                        this.setState({ showAlert: !checkValue, subscribe: checkValue ? "0" : "1" });
                    }}
                    checked={checkValue}
                >
                    <FormattedMessage id="subscribe_message" />
                </Checkbox>
            </div>
        );

        const renderSave = () => (
            <div className="edit-cusotmer-modal-save-button">
                <Button
                    onClick={() => {
                        if (this.state.subscribe) {
                            this.props.updateCustomer({
                                id: getCustomerId(this.props),
                                subscribe_email: this.state.subscribe,
                            });
                        }
                        this.setState({ openEditInfo: false });
                    }}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="save" />
                </Button>
            </div>
        );

        return (
            <Modal
                onCancel={() => this.setState({ openEditInfo: false })}
                destroyOnClose
                visible={this.state.openEditInfo}
                title={null}
                footer={null}
            >
                <div className="email-marketing-section">
                    {renderTitle()}
                    {renderAlert()}
                    {renderCheckbox()}
                    {renderSave()}
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div className="customer-detail-section email-marketing-section section-padding">
                {this.renderTitle()}
                {this.renderSubscribedText()}
                {this.renderSubscribeModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

const mapDispatchToProps = {
    updateCustomer,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(EmailMarketing)));
