import { Button, Tag, Modal, Select } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateCustomer } from "../../../../services/customers";
import { getCustomerId } from "./helper";

class TagsInfo extends Component {
    componentDidMount() {}

    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCid = (props = this.props) => getCustomerId(props);

    getTags = () => {
        const tags = this.props.customer?.tags;
        return Array.isArray(tags) ? tags : [];
    };

    renderTitle = () => {
        return (
            <div className="customer-section-title">
                <FormattedMessage id="tags" />
                <Button
                    onClick={() =>
                        this.setState({
                            showAddTag: true,
                        })
                    }
                    size="large"
                    type="link"
                >
                    <FormattedMessage id={"view_all_tags"} />
                </Button>
            </div>
        );
    };

    renderAddTag = () => {
        return (
            <div
                onClick={() =>
                    this.setState({
                        showAddTag: true,
                    })
                }
                className="tags-add-tag"
            >
                <FormattedMessage id="add_tag" />
            </div>
        );
    };

    renderTags = () => {
        const renderTag = (tag) => (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    let tags = _.cloneDeep(this.getTags());
                    _.pullAll(tags, [tag]);
                    this.props.updateCustomer({
                        id: getCustomerId(this.props),
                        tags,
                    });
                }}
            >
                {tag}
            </Tag>
        );
        return <div className="edit-customer-tags-list">{this.getTags().map((tag) => renderTag(tag))}</div>;
    };

    renderTagsModal = () => {
        const renderTitle = () => (
            <div className="edit-cusotmer-modal-title">
                <FormattedMessage id="tags" />
            </div>
        );

        const renderSelect = () => (
            <Select
                style={{ width: "100%" }}
                onChange={(values) => this.setState({ tags: values })}
                value={Array.isArray(this.state.tags) ? this.state.tags : this.getTags()}
                mode="tags"
                options={this.getTags().map((tag) => ({ label: tag, value: tag }))}
            />
        );

        const renderSave = () => (
            <div className="edit-cusotmer-modal-save-button">
                <Button
                    onClick={() => {
                        if (Array.isArray(this.state.tags)) {
                            this.props.updateCustomer({
                                id: getCustomerId(this.props),
                                tags: this.state.tags,
                            });
                        }
                        this.setState({ showAddTag: false });
                    }}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="save" />
                </Button>
            </div>
        );

        return (
            <Modal
                onCancel={() => this.setState({ showAddTag: false })}
                destroyOnClose
                footer={null}
                title={null}
                visible={this.state.showAddTag}
            >
                {renderTitle()}
                {renderSelect()}
                {renderSave()}
            </Modal>
        );
    };

    render() {
        return (
            <div className="customer-detail-section tags-info section-padding">
                {this.renderTitle()}
                {this.renderAddTag()}
                {this.renderTags()}
                {this.renderTagsModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

const mapDispatchToProps = {
    updateCustomer,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(TagsInfo)));
