import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import { getFormItem, getMultiSelectInput } from "components/form";
import useLanguageSelect from "hooks/useLanguageSelect";
import { getProducts } from "services/products";
import helper from "util/helper";
import AssignItemsModal from "components/assign-items-modal";

function RelatedUpsellProducts(props: any = {}): JSX.Element {
    const { form, values } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showRelatedProductModal, setShowRelatedProductModal] = useState(false);
    const [showUpsellProductModal, setShowUpsellProductModal] = useState(false);
    const { getLanguageSelect } = useLanguageSelect();

    const { products, lan } = useSelector((state: RootState) => ({
        product: state?.products?.product,
        products: state?.products?.products,
        lan: state?.setting?.lan,
    }));

    useEffect(() => {
        dispatch(getProducts({}));
    }, [dispatch]);

    const getProductsAsOptions = () =>
        products.map((p) => ({
            value: p.product_id,
            label: helper.getTransString(p.name, lan),
        }));

    const getButtonForProductList = () => (
        <Button
            size="middle"
            style={{ marginLeft: 16 }}
            type="primary"
            onClick={() => setShowRelatedProductModal(true)}
        >
            <FormattedMessage id="choose" />
        </Button>
    );

    const getButtonForUpsellList = () => (
        <Button size="middle" style={{ marginLeft: 16 }} type="primary" onClick={() => setShowUpsellProductModal(true)}>
            <FormattedMessage id="choose" />
        </Button>
    );

    const onSave = (selected: any) => {
        form.setFieldsValue({
            related_products: selected.map((s: any) => s.product_id),
        });

        setShowRelatedProductModal(false);
    };

    const onSaveUpsell = (selected: any) => {
        form.setFieldsValue({
            upsell_products: selected.map((s: any) => s.product_id),
        });

        setShowUpsellProductModal(false);
    };

    const renderFormItems = () => {
        return (
            <div>
                {getFormItem(
                    "related_products",
                    getMultiSelectInput("related_products", false, getProductsAsOptions()),
                    "related_products_desc",
                    getButtonForProductList()
                )}
                {getFormItem(
                    "up_sell_products",
                    getMultiSelectInput("upsell_products", false, getProductsAsOptions()),
                    "up_sell_products_desc",
                    getButtonForUpsellList()
                )}
            </div>
        );
    };

    const renderAssignItemsModal = () => {
        return showRelatedProductModal ? (
            <AssignItemsModal
                name={intl.formatMessage({ id: "related_products" })}
                title={<FormattedMessage id="related_products" />}
                onSave={onSave}
                onCancel={() => setShowRelatedProductModal(false)}
                selectedProducts={values?.related_products?.map?.((p: any) => ({ product_id: p }))}
                desc={false}
            />
        ) : null;
    };

    const renderUpsellProductModal = () => {
        return showUpsellProductModal ? (
            <AssignItemsModal
                name={intl.formatMessage({ id: "upsell_products" })}
                title={<FormattedMessage id="upsell_products" />}
                onSave={onSaveUpsell}
                onCancel={() => setShowUpsellProductModal(false)}
                selectedProducts={values?.upsell_products?.map?.((p: any) => ({ product_id: p }))}
                desc={false}
            />
        ) : null;
    };

    return (
        <div className="related-upsell-products">
            {getLanguageSelect()}
            {renderFormItems()}
            {renderAssignItemsModal()}
            {renderUpsellProductModal()}
        </div>
    );
}

export default RelatedUpsellProducts;
