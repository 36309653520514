import { Button, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/reducer';
import { getStoreDetails, updateStoreDetails } from '../../../../../../services/store';
import ObjectModel from '../../../../../../util/models';
import ImageUploadMulti from '../../../../../../components/form/image-upload-multi';
import StoreImages from '../../../../../../util/models/store-images';
import ImageUpload from '../../../../../../components/form/image-upload';
import { runImageCacheHelper } from '../../../../../../services/image-cache';
import { oauth } from '../../../../../../util/api';
import { renderToolTip } from "../../../../../../components/form";


function StoreImagesTab(): JSX.Element | null {
    const dispatch = useDispatch();
    const storeImages = useSelector((state: RootState) => state.store?.storeImages);
    const user = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [deleteImages, setDeleteImages] = useState<string[]>([]);
    const [deleteTypes, setDeleteTypes] = useState<string[]>([]);
    const [form] = Form.useForm()

    const onFinish = async () => {
        setLoading(true);
        const logoCache: any = await runImageCacheHelper({
            action: 'load_image_cache',
            user_id: user.id,
            imageType: 'logo',
            type: 'store'
        })

        const qrLogoCache: any = await runImageCacheHelper({
            action: 'load_image_cache',
            user_id: user.id,
            imageType: 'qrLogo',
            type: 'store'
        })

        const headerCache: any = await runImageCacheHelper({
            action: 'load_image_cache',
            user_id: user.id,
            imageType: 'header',
            type: 'store'
        })

        const mHeaderCache: any = await runImageCacheHelper({
            action: 'load_image_cache',
            user_id: user.id,
            imageType: 'mHeader',
            type: 'store'
        })

        const generalCache: any = await runImageCacheHelper({
            action: 'load_image_cache',
            user_id: user.id,
            imageType: 'general',
            type: 'store'
        })

        const logo = Array.isArray(logoCache) && logoCache[0] ? logoCache[0] : '';
        const qrLogo = Array.isArray(qrLogoCache) && qrLogoCache[0] ? qrLogoCache[0] : '';
        const header = Array.isArray(headerCache) && headerCache[0] ? headerCache[0] : '';
        const mHeader = Array.isArray(mHeaderCache) && mHeaderCache[0] ? mHeaderCache[0] : '';
        const general = Array.isArray(generalCache) ? generalCache : []

        const imgs = [
            header ? { type: 'header', url: header } : null,
            mHeader ? { type: 'mheader', url: mHeader } : null,
            logo ? { type: 'logo', url: logo } : null,
            qrLogo ? { type: 'logo-png', url: qrLogo } : null,
        ].filter(i => i !== null)

        general.forEach(g => {
            imgs.push({
                type: 'general',
                url: g
            })
        })

        const upload = await oauth('IMAGE_UPLOAD')({
            method: 'POST',
            body: JSON.stringify({
                data: {
                    imgs
                }
            })
        })

        const images = upload?.records ?? {};
        const data: any = {}

        if (images[logo] && !deleteTypes?.includes('logo')) {
            data.store_logo = images[logo]
        } else if (deleteTypes?.includes('logo')) {
            data.store_logo = "";
        }

        if (images[qrLogo] && !deleteTypes?.includes('qrLogo')) {
            data.store_qr_logo = images[qrLogo]
        } else if (deleteTypes?.includes('qrLogo')) {
            data.store_qr_logo = "";
        }

        if (images[header] && !deleteTypes?.includes('header')) {
            data.store_header_image = images[header]
        } else if (deleteTypes?.includes('header')) {
            data.store_header_image = "";
        }

        if (images[mHeader] && !deleteTypes?.includes('mHeader')) {
            data.store_mobile_image = images[mHeader]
        } else if (deleteTypes?.includes('mHeader')) {
            data.store_mobile_image = "";
        }

        data.store_images = storeImages.store_images ? storeImages.store_images.filter((i: any) => {
            return !deleteImages.includes(i);
        }) : []

        general.forEach(g => {
            if (images[g]) {
                data.store_images.push(images[g])
            }
        })

        dispatch(updateStoreDetails(new ObjectModel(StoreImages).convertToPostData(data, null, "logo")))
        setLoading(false);
    }


    useEffect(() => {
        runImageCacheHelper({
            action: 'clear_image_cache',
            user_id: user.id,
            imageType: '',
            type: 'store'
        })
        dispatch(getStoreDetails())
    }, [dispatch, user.id]);

    const uploadCallback=(type : any) => {
        setDeleteTypes(deleteTypes.filter(dt => dt !== type));
    }

    const deleteCallback = (type: any) => {
        setDeleteTypes([...deleteTypes, type]);
    }

    const logoAndStoreImagesSection = () => <>
        <div className="logoAndStore-container">
            <div className="logo-area-container">
                <div className="general-section">
                    <h1 className="ant-descriptions-title"><FormattedMessage id="logo" /></h1>
                    <div className="d-flex">
                        <p className="ant-descriptions-description"><FormattedMessage id="store_logo" /></p>
                        {renderToolTip("store_logo_hint")}
                    </div>
                </div>
                <ImageUpload
                    currentImage={storeImages.store_logo}
                    user={user}
                    type="store"
                    imageType="logo"
                    uploadCallback={uploadCallback}
                    deleteCallback={deleteCallback}
                />
            </div>
            <div className="logo-area-container">
                <div className="general-section">
                    <h1 className="ant-descriptions-title"><FormattedMessage id="qr_logo" /></h1>
                    <div className="d-flex">
                        <p className="ant-descriptions-description"><FormattedMessage id="qr_store_logo" /></p>
                        {renderToolTip("qr_logo_hint")}
                    </div>
                </div>
                <ImageUpload
                    currentImage={storeImages.store_qr_logo}
                    user={user}
                    type="store"
                    imageType="qrLogo"
                    uploadCallback={uploadCallback}
                    deleteCallback={deleteCallback}
                    imageExt="png"
                />
            </div>
            <div className="store-images-area-container">
                <div className="general-section">
                    <h1 className="ant-descriptions-title"><FormattedMessage id="store_image" /></h1>
                    <p className="ant-descriptions-description"><FormattedMessage id="store_images_description" /></p>
                </div>
                <ImageUploadMulti
                    currentImages={storeImages.store_images}
                    user={user}
                    type="store"
                    imageType="general"
                    deleteCallback={(image: string) => {
                        setDeleteImages(prev => [...prev, image])
                    }}
                />
            </div>
        </div>

    </>

    const bannerSection = () => <>
        <div className="general-section">
            <h1 className="ant-descriptions-title"><FormattedMessage id="store_header" /></h1>
            <p className="ant-descriptions-description"><FormattedMessage id="banner_desktop_description" /></p>
        </div>
        <ImageUpload
            currentImage={storeImages.store_header_image}
            user={user}
            type="store"
            imageType="header"
            className="upload-banner"
            uploadCallback={uploadCallback}
            deleteCallback={deleteCallback}
        />
    </>

    const mobileBannerSection = () => <>
        <div className="general-section">
            <h1 className="ant-descriptions-title"><FormattedMessage id="store_header_mobile" /></h1>
            <p className="ant-descriptions-description"><FormattedMessage id="banner_mobile_description" /></p>
        </div>
        <ImageUpload
            currentImage={storeImages.store_mobile_image}
            user={user}
            type="store"
            imageType="mHeader"
            className="upload-mobile-banner"
            uploadCallback={uploadCallback}
            deleteCallback={deleteCallback}
        />
    </>

    return !_.isEmpty(storeImages) ? (
        <React.Fragment>
            <div className="general-settings has-floating-submit">
                <Form form={form} name="general-settings" onFinish={onFinish} initialValues={storeImages} style={{ backgroundColor: 'white' }}>
                    {logoAndStoreImagesSection()}
                    {bannerSection()}
                    {mobileBannerSection()}
                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button type="primary" size="large" htmlType="submit" loading={loading} className="save-button-handler"><FormattedMessage id="save_changes" /></Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreImagesTab;
