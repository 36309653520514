import { Button, Descriptions, Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useLocation } from 'react-router-dom';
import { RootState } from '../../../../../../app/reducer';
import { getDescriptionItem, getSelectInput, getSwitchInput, SelectOption, mapBooleansToNumbers, getCheckMarkGroup } from '../../../../../../components/form';
import { getStoreDetails, updateStoreDetails } from '../../../../../../services/store';
import ObjectModel from '../../../../../../util/models';
import StoreRestaurantExtras from '../../../../../../util/models/store-restaurant-extras';


function StoreRestaurantExtrasTab(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const storeInformation = useSelector((state: RootState) => state.store?.storeRestaurantExtras);
    const records = useSelector((state: RootState) => state.store?.records);
    const [touched, setTouched] = useState(false);

    const [form] = Form.useForm()

    const onFinish = (data: any) => {
        setTouched(false);
        const mapped = mapBooleansToNumbers(data, [
            'bike_parking',
            'tv_available',
            'wifi',
            'allow_waitlist',
            'waiter_service',
            'good_for_group',
            'takes_reservation',
            'good_for_kids',
            'good_for_business',
            "has_outdoor_seating",
            "allow_office_delivery",
        ]);
        if (Array.isArray(mapped.parking)) {
            const parking: any[] = ['0', '0', '0'];
            mapped?.parking?.forEach((p: any) => {
                parking[Number(p) - 1] = '1';
            })

            mapped.parking = parking.join('')
        }

        dispatch(updateStoreDetails(new ObjectModel(StoreRestaurantExtras).convertToPostData(
            mapped, records, "business_features")))
    }

    const attireOptions: SelectOption[] = [
        {
            value: "0",
            label: intl.formatMessage({ id: 'casual' })
        },
        {
            value: "1",
            label: intl.formatMessage({ id: 'dressy' })
        },
    ]

    const alcoholOptions: SelectOption[] = [
        {
            value: "0",
            label: intl.formatMessage({ id: 'no_such_info' })
        },
        {
            value: "1",
            label: intl.formatMessage({ id: 'no' })
        },
        {
            value: "2",
            label: intl.formatMessage({ id: 'beer_wine_only' })
        },
        {
            value: "3",
            label: intl.formatMessage({ id: 'full_bar' })
        },
    ]

    const noiseLevelOptions: SelectOption[] = [
        {
            value: "0",
            label: intl.formatMessage({ id: 'no_such_info' })
        },
        {
            value: "1",
            label: intl.formatMessage({ id: 'quiet' })
        },
        {
            value: "2",
            label: intl.formatMessage({ id: 'average' })
        },
        {
            value: "3",
            label: intl.formatMessage({ id: 'loud' })
        },
        {
            value: "4",
            label: intl.formatMessage({ id: 'very_loud' })
        },
    ]

    const environmentOptions: SelectOption[] = [
        {
            value: "0",
            label: intl.formatMessage({ id: 'no_such_info' })
        },
        {
            value: "1",
            label: intl.formatMessage({ id: 'intimate' })
        },
        {
            value: "2",
            label: intl.formatMessage({ id: 'hipster' })
        },
        {
            value: "3",
            label: intl.formatMessage({ id: 'classy' })
        },
        {
            value: "4",
            label: intl.formatMessage({ id: 'upscale' })
        },
    ]

    const parkingCheckMarks: any[] = [
        { label: intl.formatMessage({ id: 'street' }), value: '1' },
        { label: intl.formatMessage({ id: 'free_lot' }), value: '2' },
        { label: intl.formatMessage({ id: 'paid' }), value: '3' },
    ]

    useEffect(() => {
        dispatch(getStoreDetails())
    }, [dispatch])

    const getStoreInformation = () => {
        return !_.isEmpty(storeInformation) ? {
            ...storeInformation,
            parking: storeInformation?.parking?.split('')?.map((p: any, i: number) => Number(p) > 0 ? i + 1 + "" : "0"),
        } : {}
    }

    const parkingSection = () => (
        <Descriptions title={intl.formatMessage({ id: 'store_features' })} bordered>
            {getDescriptionItem('parking', 3, getCheckMarkGroup('parking', parkingCheckMarks))}
            {getDescriptionItem('bike_parking', 1, getSwitchInput('bike_parking', storeInformation))}
            {getDescriptionItem('tv_available', 1, getSwitchInput('tv_available', storeInformation))}
            {getDescriptionItem('wifi', 1, getSwitchInput('wifi', storeInformation))}
        </Descriptions>
    )

    const extrasSection = () => (
        <Descriptions title={intl.formatMessage({ id: 'restaurant_extras' })} className="general-section" bordered>
            {getDescriptionItem('alcohol', 3, getSelectInput('allow_alcohol', false, alcoholOptions))}
            {getDescriptionItem('noise_level', 3, getSelectInput('noise_level', false, noiseLevelOptions))}
            {getDescriptionItem('environment', 3, getSelectInput('environment', false, environmentOptions))}

            {getDescriptionItem('allow_waitlist', 2, getSwitchInput('allow_waitlist', storeInformation))}
            {getDescriptionItem('waiter_service', 2, getSwitchInput('waiter_service', storeInformation))}

            {getDescriptionItem('good_for_group', 2, getSwitchInput('good_for_group', storeInformation))}
            {getDescriptionItem('take_reservation', 2, getSwitchInput('takes_reservation', storeInformation))}

            {getDescriptionItem('good_for_kids', 2, getSwitchInput('good_for_kids', storeInformation))}
            {getDescriptionItem('good_for_business', 2, getSwitchInput('good_for_business', storeInformation))}

            {getDescriptionItem('outdoor_seats', 2, getSwitchInput('has_outdoor_seating', storeInformation))}
            {getDescriptionItem('allow_office_delivery', 2, getSwitchInput('allow_office_delivery', storeInformation))}

            {getDescriptionItem('casual_attire', 3, getSelectInput('attire', false, attireOptions))}
        </Descriptions>
    )

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    }

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    }


    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
    }, [location, form]);

    return !_.isEmpty(storeInformation) ? (
        <React.Fragment>
            <Prompt
                when={touched}
                message={intl.formatMessage({ id: 'unsaved_changes' })}
            />
            <div className="general-settings">
                <Form form={form} name="general-settings has-floating-submit" onFinish={onFinish} initialValues={getStoreInformation()} onValuesChange={handleValuesChanged}>
                    {parkingSection()}
                    {extrasSection()}

                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}><FormattedMessage id="cancel" /></Button>
                        <Button type="primary" size="large" htmlType="submit" disabled={!touched} className="save-button-handler"><FormattedMessage id="save_changes" /></Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreRestaurantExtrasTab;
