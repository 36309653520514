import React from "react";
import _ from "lodash";
import moment from "moment";
import { RootState } from "./../../../app/reducer";
import { SelectOption } from "./../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { getValidTimezone } from "../../../util/helper";
import { Select, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setFilters, setFilterModal, setRangeFilters, clearFilters } from "../../../slices/reviews";

function FilterModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const filters = useSelector((state: RootState) => state?.reviews?.filters) ?? {};
    const dateFilters = useSelector((state: RootState) => state?.reviews?.rangeFilters.dateFilters);
    const upDateFilters = useSelector((state: RootState) => state?.reviews?.rangeFilters.upDateFilters);
    let timezone = useSelector((state: RootState) => state?.store?.storeInformation?.address_time_zone);
    timezone = getValidTimezone(timezone);
    const dt_start = _.get(filters, "dt_start", NaN);
    const dt_end = _.get(filters, "dt_end", NaN);
    const update_dt_start = _.get(filters, "update_dt_start", NaN);
    const update_dt_end = _.get(filters, "update_dt_end", NaN);
    const pid = _.get(filters, "pid", "");
    const customerId = _.get(filters, "customer_id", "");
    const DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
    const ANY_DATE = 0;
    const TODAY = 1;
    const YESTERDAY = 2;
    const THIS_WEEK = 3;
    const THIS_MONTH = 4;
    const THIS_YEAR = 5;
    const CUSTOM = 6;

    const dateRanges: SelectOption[] = [
        { value: ANY_DATE, label: intl.formatMessage({ id: "any_date" }) },
        { value: TODAY, label: intl.formatMessage({ id: "today" }) },
        { value: YESTERDAY, label: intl.formatMessage({ id: "yesterday" }) },
        { value: THIS_WEEK, label: intl.formatMessage({ id: "this_week" }) },
        { value: THIS_MONTH, label: intl.formatMessage({ id: "this_month" }) },
        { value: THIS_YEAR, label: intl.formatMessage({ id: "this_year" }) },
        { value: CUSTOM, label: intl.formatMessage({ id: "custom_range" }) },
    ];

    const clearFilter = () => {
        dispatch(clearFilters());
        dispatch(setFilterModal({ value: false }));
    };

    const updateDateRange = (e: any, name: string, start: string, end: string) => {
        dispatch(setRangeFilters({ name, value: e }));
        const today = moment().tz(timezone).format("YYYY-MM-DD");
        const time = moment().tz(timezone).format("hh:mm:ss");
        const after6AM = moment().tz(timezone).isAfter(`${today} 06:00:00`);
        const yesterday = moment().tz(timezone).subtract(1, "day").format("YYYY-MM-DD");

        if (e === TODAY) {
            if (after6AM) {
                dispatch(setFilters({ name: start, value: `${today} 06:00:00` }));
                dispatch(setFilters({ name: end, value: `${today} ${time}` }));
            } else {
                dispatch(setFilters({ name: start, value: `${yesterday} 06:00:00` }));
                dispatch(setFilters({ name: end, value: `${today} ${time}` }));
            }
        } else if (e === YESTERDAY) {
            const yesterday2 = moment().tz(timezone).subtract(2, "day").format("YYYY-MM-DD");
            if (after6AM) {
                dispatch(setFilters({ name: start, value: `${yesterday} 06:00:00` }));
                dispatch(setFilters({ name: end, value: `${today} 06:00:00` }));
            } else {
                dispatch(setFilters({ name: start, value: `${yesterday2} 06:00:00` }));
                dispatch(setFilters({ name: end, value: `${yesterday} 06:00:00` }));
            }
        } else if (e === THIS_WEEK) {
            const lastWeek = moment().tz(timezone).subtract(7, "day").format("YYYY-MM-DD");
            dispatch(setFilters({ name: start, value: `${lastWeek} 06:00:00` }));
            dispatch(setFilters({ name: end, value: `${today} ${time}` }));
        } else if (e === THIS_MONTH) {
            const lastMonth = moment().tz(timezone).subtract(1, "month").format("YYYY-MM-DD");
            dispatch(setFilters({ name: start, value: `${lastMonth} 06:00:00` }));
            dispatch(setFilters({ name: end, value: `${today} ${time}` }));
        } else if (e === THIS_YEAR) {
            const lastYear = moment().tz(timezone).subtract(1, "year").format("YYYY-MM-DD");
            dispatch(setFilters({ name: start, value: `${lastYear} 06:00:00` }));
            dispatch(setFilters({ name: end, value: `${today} ${time}` }));
        }
    };

    const filterCodes = () => {
        dispatch(setFilterModal({ value: false }));
    };

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => clearFilter()}>
                <FormattedMessage id="reset" />
            </Button>
            <Button type="primary" onClick={() => filterCodes()}>
                <FormattedMessage id="apply_filters" />
            </Button>
        </div>
    );

    const getDoubleFilterInput = (
        title1: string,
        name1: string,
        value1: any,
        title2: string,
        name2: string,
        value2: any
    ) => (
        <div className="w100 mb-3 d-flex">
            <div className="w50">
                <div className="filter-subtitle">
                    <FormattedMessage id={title1} />
                </div>
                <input
                    className="filter-input-field-double"
                    value={value1}
                    placeholder={intl.formatMessage({ id: title1 })}
                    onChange={(e: any) => dispatch(setFilters({ name: name1, value: parseInt(e.target.value) }))}
                    type="number"
                />
            </div>
            <div className="w50">
                <div className="filter-subtitle">
                    <FormattedMessage id={title2} />
                </div>
                <input
                    className="filter-input-field-double"
                    value={value2}
                    placeholder={intl.formatMessage({ id: title2 })}
                    onChange={(e: any) => dispatch(setFilters({ name: name2, value: parseInt(e.target.value) }))}
                    type="number"
                />
            </div>
        </div>
    );

    const renderDateRangeSelect = (title: string, start: string, end: string, rangeType: number, name: string) => (
        <div className="w100 mb-3">
            <div className="filter-subtitle">
                <FormattedMessage id={title} />
            </div>
            <Select className="select-input" value={rangeType} onChange={(e) => updateDateRange(e, name, start, end)}>
                {dateRanges?.map((option: SelectOption) => (
                    <Select.Option key={option?.value} value={option?.value}>
                        {" "}
                        {option?.label}{" "}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    const renderDateRange = (start: string, end: string, startVal: any, endVal: any) => (
        <React.Fragment>
            <div className="filter-subtitle mb-1">
                <FormattedMessage id="date_range" />
            </div>
            <div className="d-flex mb-3">
                <div className="d-flex flex-column mr-4">
                    <DatePicker
                        className="datepicker custom-input-textfield"
                        value={startVal ? moment(startVal) : undefined}
                        format={DATE_FORMAT}
                        placeholder={intl.formatMessage({ id: "from" })}
                        onChange={(date) => formatDate(date, start)}
                    />
                </div>
                <div className="d-flex flex-column">
                    <DatePicker
                        className="filter-range custom-input-textfield"
                        value={endVal ? moment(endVal) : undefined}
                        format={DATE_FORMAT}
                        placeholder={intl.formatMessage({ id: "to" })}
                        onChange={(date) => formatDate(date, end)}
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const formatDate = (date: any, name: string) => {
        dispatch(setFilters({ name, value: date.format(DATE_FORMAT) }));
    };

    const renderFilterForm = () => (
        <div className="customer-filter-modal d-flex flex-column justify-content-start align-items-start">
            {getDoubleFilterInput("customer_id", "customer_id", customerId, "product_id", "pid", pid)}

            {renderDateRangeSelect("review_date", "dt_start", "dt_end", dateFilters, "dateFilters")}
            {dateFilters === CUSTOM ? renderDateRange("dt_start", "dt_end", dt_start, dt_end) : null}

            {renderDateRangeSelect(
                "review_update_date",
                "update_dt_start",
                "update_dt_end",
                upDateFilters,
                "upDateFilters"
            )}
            {upDateFilters === CUSTOM
                ? renderDateRange("update_dt_start", "update_dt_end", update_dt_start, update_dt_end)
                : null}

            {renderActions}
        </div>
    );

    return renderFilterForm();
}

export default FilterModal;
