import { Pagination } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import _ from "lodash";
import qs from "querystring";
import { useHistory, useLocation } from "react-router-dom";
import { getGiftcards } from "../../../../services/giftcards";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";

const selector = (state: RootState) => ({
    total: state?.giftcards?.total,
    lan: state?.setting?.lan,
});

function GiftCardPagination(): JSX.Element {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { total } = useSelector(selector);

    const search = qs.parse(location.search.slice(1)) ?? {};

    const getSearch = useCallback(
        (page?: any, limit?: any) => {
            return {
                ...search,
                page: page ?? search?.page ?? 1,
                limit: limit ?? search?.limit ?? DEFAULT_PAGE_SIZE,
            };
        },
        [search]
    );

    const paginationProps: PaginationProps = {
        total,
        pageSize: Number(getSearch()?.limit),
        current: Number(getSearch()?.page),
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            const query = _.cloneDeep(search);
            query.page = page.toString();
            if (pageSize) {
                query.limit = pageSize.toString();
            }
            history.push("/giftCardAccount?" + qs.stringify(query));
        },
    };

    useEffect(() => {
        dispatch(
            getGiftcards({
                page: 1,
                limit: DEFAULT_PAGE_SIZE,
            })
        );
    }, [dispatch]);

    return (
        <div className="gift-card-pagination">
            <Pagination {...paginationProps} />
        </div>
    );
}

export default GiftCardPagination;
