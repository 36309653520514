import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { ORDER_TYPES } from "./constants";
import { Checkbox } from "antd";
import { bin2Number } from "../../../../util/helper";

interface OrderType {
    DELIVERY: boolean;
    INSTANT_CHECKOUT: boolean;
    INSTANT_PAY: boolean;
    IN_STORE: boolean;
    PICKUP: boolean;
}

interface OrderTypeProps {
    orderType: OrderType;
    setState: any;
}

export const OrderType = (props: OrderTypeProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const orderTypeCheckMarks: any[] = Object.keys(ORDER_TYPES).map((type: any) => ({
        label: intl.formatMessage({ id: type.toLowerCase() }),
        value: Number(ORDER_TYPES[type]),
    }));

    const handleToggleOrderTypes = (list: any[]) => {
        const strArray = new Array(Object.keys(ORDER_TYPES).length).fill("0");
        list.forEach((index) => {
            strArray[index] = "1";
        });
        dispatch(props.setState("order_type", bin2Number(strArray)));
    };

    const renderCheckAll = () => {
        const check = getSelectedOrderTypes().length === Object.keys(ORDER_TYPES).length;
        return (
            <Checkbox
                checked={check}
                onChange={() => {
                    props.setState(
                        "order_type",
                        check ? "0" : bin2Number(new Array(Object.keys(ORDER_TYPES).length).fill("1"))
                    );
                }}
            >
                <FormattedMessage id="auto_accept_order_type_any" />
            </Checkbox>
        );
    };

    const getSelectedOrderTypes = () => {
        const selectedValues = Object.keys(props.orderType || {})
            // @ts-ignore
            .filter((type) => props.orderType?.[type])
            .map((type) => ORDER_TYPES[type]);
        return selectedValues || [];
    };

    return (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="order_type" />
                </div>
                {renderCheckAll()}
                <Checkbox.Group
                    options={orderTypeCheckMarks}
                    onChange={handleToggleOrderTypes}
                    //@ts-ignore
                    value={getSelectedOrderTypes()}
                />
            </div>
        </div>
    );
};
