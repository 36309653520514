import { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { checkoutCart } from "services/create-order";
import { setState, clearData } from "slices/create-order";
import _ from "lodash";
import config from "config";
import helper from "util/helper";
import {
    ITEM_CHANGE_ACTION_REPLACE,
    ITEM_CHANGE_ACTION_POS_MODE,
    ITEM_CHANGE_ACTION_SEARCH_BAR,
    ITEM_CHANGE_ACTION_REMOVE,
} from "pages/liveorder/_components/order-items/helper";
import history from "app/history";
import { ASAP } from "app/constants";

//a renderless component to assist with calling ctotal
class CartAssistant extends Component<any, any> {
    componentDidUpdate = (prevsProps: any) => {
        const quoteChangeReasons = this.getQuoteChangeReasons(prevsProps);
        this.enableSaveButtons(quoteChangeReasons);
        // call callCartTotal function if it has a reason to call it
        if (this.shouldCallCartTotal(quoteChangeReasons)) {
            this.props.setState({ isSaveButtonEnabled: false });
            this.callCartTotal(quoteChangeReasons);
        }
    };

    getCustomerId = (props = this.props) => props?.customer?.id || 0;

    getQid = (props = this.props) => props.state?.qid;

    /**
     * Get reasons of current quote changes compared with previous props
     *
     * @param prevsProps - previous props
     * @returns quote change reasons object
     */
    getQuoteChangeReasons = (prevsProps: any) => {
        const itemChangeActions = this.getItemChangeActions(prevsProps.items);
        const reasons = {
            itemChangeActions: itemChangeActions,
            itemChanged: !_.isEmpty(itemChangeActions),
            customerChanged: !_.isEqual(this.getCustomerId(prevsProps), this.getCustomerId()),
            storeDiscountChanged:
                !_.isEqual(this.props.storeDiscountValue, prevsProps.storeDiscountValue) ||
                !_.isEqual(this.props.storeDiscountType, prevsProps.storeDiscountType) ||
                !_.isEqual(this.props.state?.storeDiscountNotes, prevsProps.state?.storeDiscountNotes),
            addressUpdated: !_.isEqual(this.getAddress(), this.getAddress(prevsProps)) && this.getAddress(),
            shippingMethodChanged: !_.isEqual(this.props.shippingMethod, prevsProps.shippingMethod),
            shippingFeeUpdated: !_.isEqual(this.props.payment?.shippingFee, prevsProps.payment?.shippingFee),
            saveButtonClicked: this.props.state?.saveButtonClicked,
            shouldRedirectPage: this.props.state?.shouldRedirectPage,
            tableChanged: !_.isEqual(this.props.state?.tableNumber, prevsProps.state?.tableNumber),
            partySizeUpdated: !_.isEqual(this.props.state?.partySize, prevsProps.state?.partySize),
            tipUpdated: !_.isEqual(this.props.state?.payment?.tips, prevsProps.state?.payment?.tips),
            notesChanged: !_.isEqual(this.props.state?.notes, prevsProps.state?.notes),
            paymentMethodChanged: !_.isEqual(this.props.state?.paymentMethod, prevsProps.state?.paymentMethod),
            deliveryTimeChanged: this.hasDeliveryTime() ? this.isDeliveryTimeChanged(prevsProps) : false,
            giftCardChanged: !_.isEqual(
                this.props.state?.selectedGiftCard || {},
                prevsProps.state?.selectedGiftCard || {}
            ),
            pickupLocationIdChanged: !_.isEqual(prevsProps.state?.pickupLocationId, this.props.state?.pickupLocationId),
        };
        return reasons;
    };

    /**
     * Check if there is a reason to enable save buttons.
     * If save button isn't clicked and any of the following is updated, save buttons should enable:
     * table number, party size, tip, shipping fee, or notes.
     *
     * @param reasons - quote change reasons
     * @returns true if there is a reason to enable save buttons
     */
    shouldEnableSaveButtons = (reasons: any) => {
        return (
            !reasons.saveButtonClicked &&
            !_.isEmpty(this.props.items) &&
            (reasons.tableChanged ||
                reasons.partySizeUpdated ||
                reasons.tipUpdated ||
                reasons.shippingFeeUpdated ||
                reasons.notesChanged ||
                reasons.paymentMethodChanged ||
                reasons.storeDiscountChanged)
        );
    };

    /**
     * If something is changed on quote, it enable 'save' and 'save&close' buttons
     *
     * @param reasons - it contains the quote change reasons
     */
    enableSaveButtons = (reasons: any) => {
        if (this.shouldEnableSaveButtons(reasons)) {
            this.props.setState({ isSaveButtonEnabled: true });
        }
    };

    /**
     * Get the list of reformatted item sub options of an item option
     *
     * @param opt - an option of item
     * @returns a list of reformatted item sub options
     */
    getReformattedSubOptions = (opt: any) => {
        const reformattedSubOptions: any = [];
        if (Array.isArray(_.get(opt, "opts"))) {
            _.get(opt, "opts").map((subOpt: any) => {
                reformattedSubOptions.push({
                    id: _.get(subOpt, "id"),
                    qty: _.get(subOpt, "qty"),
                });
            });
        }
        return reformattedSubOptions;
    };

    /**
     * Get the list of reformatted options of an item
     *
     * @param item - an item in the current cart
     * @returns a list of reformatted item options
     */
    getReformattedItemOptions = (item: any) => {
        const reformattedOptions: any = [];
        if (Array.isArray(_.get(item, "opts"))) {
            _.get(item, "opts").map((opt: any) => {
                reformattedOptions.push({
                    id: _.get(opt, "id"),
                    opts: this.getReformattedSubOptions(opt),
                });
            });
        }
        return reformattedOptions;
    };

    /**
     * Returns the reformatted form of current items
     * The format of items is for {@link https://wiki.goopter.com/index.php?title=CartTotalItems}
     *
     * @returns a list of reformatted items
     */
    getReformattedItems = () => {
        const items = Array.isArray(this.props.items) ? this.props.items : [];
        const reformattedItems: any = [];
        items.map((item: any) => {
            const options = this.getReformattedItemOptions(item);
            reformattedItems.push({
                pid: _.get(item, "pid"),
                qty: _.get(item, "qty"),
                pc: _.get(item, "pc"),
                options,
            });
        });
        return reformattedItems;
    };

    /**
     * Get the reformatted form of an item
     * The format of items is for {@link https://wiki.goopter.com/index.php?title=Admin_Cart_Total_V9#Input}
     *
     * @param item - an item
     * @returns a reformatted item
     */
    getReformattedItem = (item: any) => {
        let reformattedItem = null;
        reformattedItem = {
            pid: _.get(item, "pid"),
            qty: _.get(item, "qty"),
            pc: _.get(item, "pc"),
            opts: this.getReformattedItemOptions(item),
        };
        return reformattedItem;
    };

    /**
     * Check the item is added using previous items
     *
     * @param item - an item which needs to be checked
     * @param prevItems - it is previous items in the cart
     * @returns a found Item Action if it is added, else return null.
     */
    getAddedItemAction = (item: any, prevItems: any) => {
        const changeAction = _.get(this.props, "state.itemChangeAction", {});
        const matchedIndex =
            changeAction === ITEM_CHANGE_ACTION_POS_MODE
                ? _.findIndex(
                      prevItems,
                      (prevItem: any) => item?.pid === prevItem?.pid && _.isEqual(item?.opts, prevItem?.opts)
                  )
                : _.findIndex(prevItems, (prevItem: any) => item?.pid === prevItem?.pid);
        let foundItemAction = null;

        if (matchedIndex === -1) {
            foundItemAction = {
                action: config.ITEM_CHANGE_ACTION_MAPPING.add,
                new_product: this.getReformattedItem(item),
            };
        }
        return foundItemAction;
    };

    /**
     * Check the item is updated using previous items
     *
     * @param item - an item which needs to be checked
     * @param prevItems - it is previous items in the cart
     * @returns a found Item Action if it is updated, else return null.
     */
    getUpdatedItemAction = (item: any, prevItems: any) => {
        const changeAction = _.get(this.props, "state.itemChangeAction", {});
        let foundItemAction = null;

        prevItems.map((prevItem: any) => {
            if (item?.pid === prevItem?.pid) {
                if (
                    changeAction !== ITEM_CHANGE_ACTION_POS_MODE &&
                    changeAction !== ITEM_CHANGE_ACTION_REMOVE &&
                    (!_.isEqual(item?.opts, prevItem?.opts) || item?.pc !== prevItem?.pc)
                ) {
                    foundItemAction = {
                        action: config.ITEM_CHANGE_ACTION_MAPPING.replace,
                        pid: item?.pid,
                        item_id: item?.item_id,
                        new_product: this.getReformattedItem(item),
                    };
                } else if (_.isEqual(item?.opts, prevItem?.opts) && item?.qty !== prevItem?.qty) {
                    foundItemAction = {
                        action: config.ITEM_CHANGE_ACTION_MAPPING.qty_update,
                        qty: item?.qty,
                        item_id: item?.item_id,
                    };
                }
            }
        });
        return foundItemAction;
    };

    /**
     * Check the previous item is removed using current items
     *
     * @param prevItem - an item which needs to be checked
     * @param items - it is current items in the cart
     * @returns a found Item Action if it is removed, else return null.
     */
    getRemovedItemAction = (prevItem: any, items: any) => {
        const changeAction = _.get(this.props, "state.itemChangeAction", {});
        let foundItemAction = null;

        if (changeAction !== ITEM_CHANGE_ACTION_REPLACE && changeAction !== ITEM_CHANGE_ACTION_SEARCH_BAR) {
            const matchedIndex = _.findIndex(
                items,
                (item: any) => item?.pid === prevItem?.pid && _.isEqual(item?.opts, prevItem?.opts)
            );
            if (matchedIndex === -1) {
                foundItemAction = {
                    action: config.ITEM_CHANGE_ACTION_MAPPING.remove,
                    pid: prevItem?.pid,
                    item_id: prevItem?.item_id,
                };
            }
        }
        return foundItemAction;
    };

    /**
     * Get Item Change Actions which are excuted by user.
     * Item Change Action Information {@link https://wiki.goopter.com/index.php?title=Admin_Order_Update_Suggestions_V10}
     *
     * @param prevItems - it is previous items in the cart
     * @returns a list of Item Change Actions
     */
    getItemChangeActions = (prevItems: any) => {
        const items = Array.isArray(this.props.items) ? this.props.items : [];

        // ignore item_id change
        const itemsWithoutItemID = items.map((item: any) => _.omit(item, "item_id"));
        const prevItemsWithoutItemID = prevItems.map((prevItem: any) => _.omit(prevItem, "item_id"));
        if (_.isEqual(itemsWithoutItemID, prevItemsWithoutItemID)) {
            return [];
        }

        const itemChangeActions: any[] = [];
        let tempAction = null;

        items.map((item: any) => {
            // case1 item is added
            tempAction = this.getAddedItemAction(item, prevItems);
            if (tempAction !== null) {
                itemChangeActions.push(tempAction);
            }
            // case2 item is updated
            tempAction = this.getUpdatedItemAction(item, prevItems);
            if (tempAction !== null) {
                itemChangeActions.push(tempAction);
            }
        });

        prevItems.map((prevItem: any) => {
            // case3 item is removed
            tempAction = this.getRemovedItemAction(prevItem, items);
            if (tempAction !== null) {
                itemChangeActions.push(tempAction);
            }
        });

        this.props.setState({ itemChangeAction: undefined });
        return itemChangeActions;
    };

    getAddress = (props = this.props) => props.address?.id || props.customer?.default_address?.id;

    /**
     * Check if there is a reason to call the cart total api
     * If any of the following is updated, the cart total api should be called:
     * changed item, customer, discount, shipping method, address, save click
     *
     * @param reasons - quote change reasons
     * @returns true if there is a reason to call the cart total api
     */
    shouldCallCartTotal = (reasons: any) => {
        return (
            (!_.isNil(this.props.state.qid) || !_.isEmpty(this.props.items)) &&
            (reasons.itemChanged ||
                reasons.customerChanged ||
                reasons.shippingMethodChanged ||
                reasons.addressUpdated ||
                reasons.saveButtonClicked ||
                reasons.deliveryTimeChanged ||
                reasons.giftCardChanged ||
                reasons.pickupLocationIdChanged)
        );
    };

    hasDeliveryTime = () => {
        return helper.isDelivery(this.props.shippingMethod) || helper.isPickup(this.props.shippingMethod);
    };

    isDeliveryTimeChanged = (prevsProps: any) => {
        return (
            !_.isEqual(this.props.state?.deliveryDate, prevsProps.state?.deliveryDate) ||
            !_.isEqual(this.props.state?.deliveryTime, prevsProps.state?.deliveryTime)
        );
    };

    /**
     * add properties to postData according to store discount
     *
     * @param postData - postData for cart total api
     */
    addStoreDiscountPostData = (postData: any) => {
        if (!_.isNil(this.props.storeDiscountValue)) {
            _.assign(postData, {
                store_dis: this.props.storeDiscountValue,
                store_dis_type: this.props.storeDiscountType,
                store_dis_notes: this.props.state?.storeDiscountNotes,
            });
        }
    };

    /**
     * add properties to postData according to shipping methods
     *
     * @param postData - postData for cart total api
     */
    addShippingMehtodPostData = (postData: any) => {
        if (helper.isEatIn(this.props.shippingMethod)) {
            _.assign(postData, { table_no: this.props.state?.tableNumber });
        } else if (this.hasDeliveryTime()) {
            let exp_dt_start = "";
            let exp_dt_end = "";
            if (this.props.state?.deliveryDate && this.props.state?.deliveryTime) {
                if (this.props.state?.deliveryTime.includes(ASAP)) {
                    exp_dt_start = config.DELIVERY_TIME_ASAP;
                    exp_dt_end = config.DELIVERY_TIME_ASAP;
                } else {
                    const times = this.props.state?.deliveryTime.split("-");
                    const startTime = times[0];
                    const endTime = times[1];
                    exp_dt_start = this.props.state?.deliveryDate + " " + startTime;
                    exp_dt_end = this.props.state?.deliveryDate + " " + endTime;
                }
                _.assign(postData, { exp_dt_start: exp_dt_start, exp_dt_end: exp_dt_end });
            }
        }
    };

    /**
     * Call checkoutCart function which calls the cart total api with payload
     *
     * @param reasons - It contains the reason why we should call cart total api
     */
    callCartTotal = (reasons: any) => {
        // https://wiki.goopter.com/index.php?title=Admin_Cart_Total_V9
        const postData: any = {
            action: this.getQid() === null ? 1 : 2,
            items: this.getQid() === null ? this.getReformattedItems() : null,
            item_changes: this.getQid() === null ? [] : reasons.itemChangeActions,
            cid: this.getCustomerId() ?? 0,
            qid: this.getQid(),
            addr: { address_id: this.getAddress() },
            shp_mtd: this.props.shippingMethod,
            party_size: this.props.state?.partySize,
            shp_fee: this.props.state?.payment?.shippingFee,
            tips: this.props.state?.payment?.tips,
            notes: this.props.state?.notes,
            pay_mtd: this.props.state?.paymentMethod,
            gc: this.props.state?.selectedGiftCard?.code,
            pickup_location_id: this.props.state?.pickupLocationId,
        };

        this.addStoreDiscountPostData(postData);
        this.addShippingMehtodPostData(postData);

        // call cart total api
        this.props.checkoutCart({
            ...postData,
            shouldRedirectPage: this.props.state?.shouldRedirectPage,
        });

        // if one of save buttons is clicked, re-init saveButtonClicked state to false
        if (this.props.state?.saveButtonClicked) {
            this.props.setState({
                saveButtonClicked: false,
            });

            // if save & close button is clicked, go to quotes page
            if (this.props.state?.shouldRedirectPage) {
                helper.goBackPreviousPage(history);
            }
        }
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", []),
    items: _.get(state, "createOrder.items", []),
    customer: _.get(state, "customers.customer", {}),
    address: _.get(state, "createOrder.address", {}),
    payment: _.get(state, "createOrder.payment", ""),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
    storeDiscountType: _.get(state, "createOrder.payment.storeDiscountType", null),
    storeDiscountValue: _.get(state, "createOrder.payment.storeDiscountValue", 0),
    itemChangeAction: _.get(state, "createOrder.itemChangeAction", null),
});

const mapDispatchToProps = {
    checkoutCart,
    setState,
    clearData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CartAssistant)));
