import React from "react";
import Wrapper from "../../../components/wrapper";
import config from "../../../config";
import "./index.scss";
import FilterBar from "./_components/filter-bar";
import NewStoreInfoDialog from "./_components/new-store-info-dialog";
import StoreManagementPagnation from "./_components/pagination";
import useStoreManagementSearchCondition from "./_hooks/useStoreManagementSearchConditions";
import useStoreManagementTable from "./_hooks/useStoreManagementTable";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            breadcrumbName: "super_admin",
        },
        {
            path: "superAdmin/newStoreManagement",
            breadcrumbName: "new_store_management",
        },
    ],
};

const NewStoreManagement = (): JSX.Element => {
    useStoreManagementSearchCondition();
    const { getTable, data, setData } = useStoreManagementTable();
    return (
        <Wrapper
            helmet={{ title: "new_store_management" }}
            breadcrumb={breadcrumb}
            permission={[config.ROLE_ID.SUPER_USER]}
            paddingBottom={75}
        >
            <div className="customers-page products-page">
                <FilterBar />
                {getTable()}
                <StoreManagementPagnation />
                <NewStoreInfoDialog data={data} setData={setData} />
            </div>
        </Wrapper>
    );
};

export default NewStoreManagement;
