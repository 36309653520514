import { Button, Input } from "antd";
import React, { useState } from "react";
import _ from "lodash";
import { BiSliderAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setAddModalState, setRedeemModalState, setReferralsState } from "../../../../slices/referrals";
import ReferralFilterDrawer from "./filter-drawer";
import { formatName } from "../../../../util/helper";

const selector = (state: RootState) => ({
    transactions: state?.referrals?.transactions,
    paging: state?.referrals?.transactionsPaging,
    searchConditions: state?.referrals?.transactionsSearchConditions,
    referrals: state?.referrals?.referrals,
    customers: state?.customers?.customers,
    customer: state?.customers?.customer,
});

function ReferralModalFilterBar(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { referrals, searchConditions, customers, customer, transactions } = useSelector(selector);
    const [filterDrawer, setFilterDrawer] = useState(false);

    let record: any = _.cloneDeep(
        referrals.find((ref) => String(ref?.customer_id) === String(searchConditions?.customer_id)) ?? {}
    );

    if (_.isEmpty(record)) {
        record = _.cloneDeep(
            customers.find((ref: any) => String(ref?.id) === String(searchConditions?.customer_id)) ?? {}
        );
        record.customer_id = record?.id;
        record.customer_name = formatName(record);
        record.reward_balance = transactions?.[0]?.points_balance ?? 0;
    } else if (customer && _.isEmpty(record.customer_id)) {
        record = _.cloneDeep(customer);
        record.customer_id = customer?.id;
        record.customer_name = formatName(record);
        record.reward_balance = transactions?.[0]?.points_balance ?? 0;
    }

    const onSearch = (value: any) => {
        dispatch(
            setReferralsState({
                transactionsSearchConditions: {
                    ...searchConditions,
                    order_id: value?.target?.value ?? "",
                },
            })
        );
    };

    const openRedeemModal = () => {
        dispatch(setRedeemModalState(record));
    };

    const openAddModal = () => {
        dispatch(setAddModalState(record));
    };

    const renderSearch = (
        <div className="d-flex container-codes-search-input">
            <Input.Search
                className="codes-search-input"
                placeholder={intl.formatMessage({ id: "search_dot" })}
                allowClear
                value={searchConditions.order_id}
                onChange={onSearch}
                size="large"
            />
            <Button className="filter-button" icon={<BiSliderAlt size={20} />} onClick={() => setFilterDrawer(true)}>
                <FormattedMessage id="filters" />
            </Button>
        </div>
    );

    const renderButtons = (
        <>
            <Button type="primary" className="filter-button" onClick={openAddModal}>
                <FormattedMessage id="add_points" />
            </Button>
            <Button className="filter-button" onClick={openRedeemModal}>
                <FormattedMessage id="redeem_points" />
            </Button>
        </>
    );

    return (
        <div className="d-flex">
            {renderSearch}
            {renderButtons}
            <ReferralFilterDrawer filter={filterDrawer} setFilter={setFilterDrawer} />
        </div>
    );
}

export default ReferralModalFilterBar;
