import { Button } from "antd";
import React from "react";
import { HiCreditCard } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getGiftcardAccountHistory } from "../../../../services/giftcards";
import { setGiftcardState } from "../../../../slices/giftcards";
import helper, { formatCurrency, formatName } from "../../../../util/helper";
import GiftCardPagination from "./gift-card-account-pagination";
import moment from "moment";

const selector = (state: RootState) => ({
    cards: state?.giftcards?.accounts,
    lan: state?.setting?.lan,
    storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
});

interface TableColumn {
    key?: string;
    render?: any;
    title?: any;
    className?: string;
}

function GiftCardAccountTable(): JSX.Element {
    const { cards, storeCurrency } = useSelector(selector);
    const dispatch = useDispatch();

    const formatNamePhone = (customerData: any) => {
        return (
            <div>
                {formatName(customerData)}
                <br />
                {helper.formatPhone(customerData.phone)}
            </div>
        );
    };

    const renderMenu = (value: any, record: any) => {
        const expires = moment(record?.date_expires);
        const disabled = expires.isBefore();
        const onTransactionHistoryClick = () => {
            dispatch(
                getGiftcardAccountHistory({
                    giftcardaccount_id: record?.giftcardaccount_id,
                    page: 1,
                })
            );
        };

        const onAddCreditClick = () => {
            dispatch(setGiftcardState({ addCreditId: record?.giftcardaccount_id }));
        };

        const onChargeClick = () => {
            dispatch(setGiftcardState({ chargeId: record?.giftcardaccount_id, maxAmount: record?.balance }));
        };

        return (
            <div className="giftcard-account-buttons">
                <div className="giftcard-account-buttons-top">
                    <div>
                        <Button type="primary" onClick={onTransactionHistoryClick}>
                            <FormattedMessage id="transaction_history" />
                        </Button>
                    </div>
                </div>
                <div className="giftcard-account-buttons-bottom">
                    <div>
                        <Button onClick={onAddCreditClick} disabled={disabled}>
                            <FormattedMessage id="add_credit" />
                        </Button>
                    </div>
                    <div>
                        <Button onClick={onChargeClick} disabled={disabled}>
                            <FormattedMessage id="charge" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const columns: TableColumn[] = [
        {
            key: "giftcard_code",
            render: (value: any, record: any) => record?.code,
        },
        {
            key: "name_slash_phone",
            render: (value: any, record: any) => formatNamePhone(record?.customer_data),
        },
        {
            key: "balance",
            className: "align-right justify-content-end",
            render: (value: any) => formatCurrency(value, storeCurrency),
        },
        {
            key: "purchase_date_giftcard",
            render: (value: any, record: any) => moment(record?.date_created).local().format("MMM DD, YYYY"),
        },
        {
            key: "date_expire",
            render: (value: any, record: any) => moment(record?.date_expires).local().format("MMM DD, YYYY"),
        },
        {
            key: "action",
            render: renderMenu,
            className: "action-column",
        },
    ];

    // @ts-ignore
    const getColumn = (column: TableColumn, card: any, i: any) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            {i === 0 ? getColumnHeader(column) : null}
            {/* @ts-ignore */}
            <div className={"gift-card-column-content " + column?.className ?? ""}>
                {/* @ts-ignore */}
                {column?.render ? column?.render(card?.[column?.key], card) : card?.[column?.key]}
            </div>
        </div>
    );

    const getColumnHeader = (column: TableColumn) => (
        <div className={"gift-card-column-header header-border-top " + column?.className ?? ""} key={column.key}>
            {column?.title ? column.title : <FormattedMessage id={column?.key ?? " "} />}
        </div>
    );

    const getTable = () => (
        <div>
            {cards?.length ? (
                cards?.map((card, i) => (
                    <div className="gift-card-row" key={i}>
                        {columns.map((column) => getColumn(column, card, i))}
                    </div>
                ))
            ) : (
                <div className="inactive-empty-warn w100">
                    <HiCreditCard size="60" color="lightgrey" className="mb-2" />
                    <FormattedMessage id="no_gift_cards" />
                </div>
            )}
        </div>
    );

    return (
        <div className="gift-card-table">
            {getTable()}
            <GiftCardPagination />
        </div>
    );
}

export default GiftCardAccountTable;
