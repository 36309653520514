import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import helper from "../../../../util/helper";
import moment from "moment";
import { Checkbox } from "antd";
import actions from "../../../../slices/order-history";
import { allowBulkUpdate } from "../../helper";
import { findOrderInListAndPerformAction, getOrderId, isSameOrder } from "../../helper-order-list";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

class OrderListTableGroupTitle extends Component {
    state = { exportedGroupChecked: false };

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.selectedExportOrders, this.props.selectedExportOrders)) {
            this.updateExportedGroupChecked();
        }
    }

    /**
     * Check whether all the orders in this group are selected
     * If all the orders are selected, set the title checkbox to checked
     * otherwise set the title checkbox to unchecked
     */
    updateExportedGroupChecked = () => {
        let shouldCheck = true;
        this.getOrderList().forEach((order) => {
            findOrderInListAndPerformAction(
                this.props.selectedExportOrders,
                order,
                undefined,
                () => (shouldCheck = false)
            );
        });

        this.setState({ exportedGroupChecked: shouldCheck });
    };

    string = (id) => this.props.intl.formatMessage({ id });

    getSelectedOrders = () => {
        return Array.isArray(_.get(this.props, "state.selectedOrders"))
            ? _.get(this.props, "state.selectedOrders")
            : [];
    };

    showBulkContoller = () => {
        return _.get(this.props, "state.bulkUpdating", false);
    };

    getOrderList = () => {
        return Array.isArray(this.props.list) ? this.props.list : [];
    };

    getDate = () => {
        const date = this.props.date;
        const isChinese = this.props.lan === "zh" || this.props.lan === "zh-Hant";
        const DATE_FORMAT_DISPLAY = isChinese ? "ddd, MMMDo" : "ddd, MMM D";
        const formats = {
            nextDay: `[${this.string("tomorrow")}]`,
            sameDay: `[${this.string("today")}]`,
            lastDay: `[${this.string("yesterday")}]`,
            sameWeek: `${DATE_FORMAT_DISPLAY}`,
            nextWeek: `${DATE_FORMAT_DISPLAY}`,
            lastWeek: `${DATE_FORMAT_DISPLAY}`,
            sameElse: `${DATE_FORMAT_DISPLAY}`,
        };
        return moment(date, "YYYY-MM-DD").isValid() ? moment(date, "YYYY-MM-DD").calendar(null, formats) : "";
    };

    getOrderTotal = () => {
        const total = this.props.orderTotal;
        return `${total} ${this.string("orders")}`;
    };

    getAmountTotal = () => {
        const total = this.props.amtTotal;
        return helper.getCurrencyFormattedString(total, this.props.storeCurrency);
    };

    renderBulkUpdateCheckbox = () => {
        const bulkUpdateOrders = this.getOrderList().reduce((acc, order) => {
            if (allowBulkUpdate(order)) acc.push(order);
            return acc;
        }, []);
        const selectedOrders = this.getSelectedOrders();
        const allowChange = bulkUpdateOrders.length > 0;
        const notIncludedOrders = _.pullAllWith(
            _.cloneDeep(bulkUpdateOrders),
            selectedOrders,
            (a, b) => getOrderId(a) === getOrderId(b)
        );
        const checked = allowChange && _.isEmpty(notIncludedOrders);
        const onChange = (e) => {
            e && e.preventDefault();
            e && e.stopPropagation();
            let result = [];
            if (!checked) {
                result = _.unionWith(
                    _.cloneDeep(selectedOrders),
                    bulkUpdateOrders,
                    (a, b) => getOrderId(a) === getOrderId(b)
                );
            } else {
                result = _.pullAllWith(
                    _.cloneDeep(selectedOrders),
                    bulkUpdateOrders,
                    (a, b) => getOrderId(a) === getOrderId(b)
                );
            }
            this.props.setState({
                selectedOrders: result,
            });
        };

        if (this.showBulkContoller())
            return (
                <span onClick={(e) => onChange(e)} className="order-list-table-group-title-check-box">
                    <Checkbox disabled={!allowChange} checked={checked} />
                </span>
            );
    };

    removeSelectedExportOrders = () => {
        const updatedSelectedExportOrders = [];
        this.props.selectedExportOrders.forEach((selectedOrder) => {
            findOrderInListAndPerformAction(this.getOrderList(), selectedOrder, undefined, () =>
                updatedSelectedExportOrders.push(selectedOrder)
            );
        });

        this.props.setState({ selectedExportOrders: updatedSelectedExportOrders });
    };

    /**
     * If the user checks the box, add all the orders in the group to the selected export orders
     * If the user unchecks the box, remove all the orders in the group from the selected export orders
     *
     * @param e - mouse click event
     */
    handleOnClickExportCheckbox = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.state.exportedGroupChecked) {
            this.removeSelectedExportOrders();
        } else {
            const updatedSelectedExportOrdersList = _.unionWith(
                this.getOrderList(),
                this.props.selectedExportOrders,
                isSameOrder
            );
            this.props.setState({ selectedExportOrders: updatedSelectedExportOrdersList });
        }
    };

    renderExportCheckbox = () => {
        return (
            <span onClick={this.handleOnClickExportCheckbox} className="order-list-table-group-title-check-box">
                <Checkbox checked={this.state.exportedGroupChecked} />
            </span>
        );
    };

    renderMainInfo = () => {
        return (
            <span>
                {this.getDate()} · {this.getOrderTotal()} · {this.getAmountTotal()}
            </span>
        );
    };

    renderIcon = () => {
        return (
            <div className="order-list-table-group-title-group-icon">
                {this.props.show ? <DownOutlined /> : <RightOutlined />}
            </div>
        );
    };

    render() {
        return (
            <div
                key={`order-list-table-group-title-row-${this.props.date}`}
                className="order-list-table-group-title-row"
            >
                <div>
                    {this.props.useExportSelection ? this.renderExportCheckbox() : null}
                    {this.renderBulkUpdateCheckbox()}
                    {this.renderMainInfo()}
                </div>
                {this.renderIcon()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    useExportSelection: _.get(state, "orders-page.useExportSelection", false),
    selectedExportOrders: _.get(state, "orders-page.selectedExportOrders", []),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTableGroupTitle));
