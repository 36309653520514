import { Button } from "antd";
import { RootState } from "app/reducer";
import moment from "moment";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import {
    setSearchStatues,
    setShouldDrawerOpen,
    StatusesSearchKey,
    TaskStatuses,
    TaskStatusesObjectEntries,
} from "../../../slices/delivery_request";
import "../style.scss";
import { objectsToCSV } from "./helper";

const FilterButtons: React.FC<PropsFromRedux> = ({
    summaryByStatus,
    setSearchStatues,
    selectedSearchStatuses,
    setShouldDrawerOpen,
    deliveryTasksList,
}) => {
    const summaryByStatusObjectentries = Object.entries(summaryByStatus) as TaskStatusesObjectEntries;
    interface FilterButtonProps {
        name: StatusesSearchKey;
        value: number;
        isActive: boolean;
    }

    const FilterButton: React.FC<FilterButtonProps> = ({ value, name, isActive }) => {
        const handleOnFilterButtonClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            setSearchStatues(name);
        };

        return (
            <Button
                block
                className="filter-button"
                type={isActive ? "primary" : undefined}
                value={value}
                onClick={handleOnFilterButtonClick}
            >
                <div className="filter-button-content-container">
                    <div>
                        <FormattedMessage id={name} />
                    </div>
                    <div>{`(${value})`}</div>
                </div>
            </Button>
        );
    };

    const handleOpenDrawer = () => {
        setShouldDrawerOpen(true);
    };

    const handleCSV = () => {
        const blob = new Blob([objectsToCSV(deliveryTasksList)], { type: "text/csv;charset=utf-8;" });
        const csvFile = new File([blob], `${moment().format("YYYYMMDD-HHmmss")}-delivery-report.csv`);
        const element = document.createElement("a");
        element.href = URL.createObjectURL(csvFile);
        element.download = `${moment().format("YYYYMMDD-HHmmss")}-delivery-report.csv`;
        document.body.appendChild(element);
        element.click();
        element.parentNode?.removeChild(element);
    };

    return (
        <div className="delivery-request-filter-session">
            <div className="delivery-request-header-filter-buttons-container">
                {summaryByStatusObjectentries.map(([key, value]) => {
                    const isActive = selectedSearchStatuses.includes(key);
                    return <FilterButton key={key} name={key} value={value} isActive={isActive} />;
                })}
            </div>
            <div className="delivery-request-header-filter-container">
                <div className="filter-button" onClick={handleOpenDrawer}>
                    <FiFilter />
                    <FormattedMessage id="filter" />
                </div>
                <div className="filter-button" onClick={handleCSV}>
                    <AiOutlineDownload />
                    <FormattedMessage id="csv" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const summaryByStatusInState = state.deliveryRequests.paginations.summaryByStatus;
    const all = Object.entries(summaryByStatusInState).reduce((prev, [, currValue]) => prev + currValue, 0);
    const summaryByStatus: TaskStatuses = { all, ...state.deliveryRequests.paginations.summaryByStatus } ?? {};
    return {
        summaryByStatus,
        selectedSearchStatuses: state.deliveryRequests.paginations.searchConditions.statuses,
        deliveryTasksList: state.deliveryRequests.deliveryTasks ?? [],
    };
};

const mapDispatchToProps = {
    setSearchStatues,
    setShouldDrawerOpen,
};
const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(FilterButtons));
