import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Menu, Button, Modal, Dropdown } from "antd";
import { deleteQuotes } from "../../../../services/quotes";
import { setState } from "../../../../slices/quotes";
import _ from "lodash";

class BulkUpdate extends Component<any, any> {
    state = {};

    isDisabled = () => this.props.selectedQuotes?.length === 0;

    str = (id: any, values = {}) => this.props.intl.formatMessage({ id: id ?? " " }, values);

    onDeleteProducts = () => {
        const deleteQuotes = () => {
            this.props.deleteQuotes({
                quote_ids: this.props.selectedQuotes,
                lan: this.props.lan,
                shouldShowQuoteDeletedMsg: true,
            });
            this.props.setState({ selected: [] });
        };
        Modal.confirm({
            title: this.str("confirm_delete_quotes"),
            content: this.str("confirm_delete_quotes_content", { count: this.props.selectedQuotes?.length }),
            onOk() {
                deleteQuotes();
            },
        });
    };

    renderMenu = () => (
        <Menu>
            <Menu.Item danger onClick={this.onDeleteProducts}>
                <FormattedMessage id="delete" />
            </Menu.Item>
        </Menu>
    );

    render() {
        return (
            <Dropdown overlay={() => this.renderMenu()} disabled={this.isDisabled()}>
                <Button className="quotes-button" type="default" size="large" disabled={this.isDisabled()}>
                    <>
                        <FormattedMessage id="bulk_edit" /> {`(${this.props.selectedQuotes?.length})`}
                    </>
                </Button>
            </Dropdown>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state?.setting?.lan,
    selectedQuotes: _.get(state, "quotes.selected", []),
    useListView: _.get(state, "quotes.useListView", true),
    isEditMode: _.get(state, "quotes.isEditMode", false),
});

const mapDispatchToProps = {
    deleteQuotes,
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(BulkUpdate)));
