import React, { Component } from "react";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Empty } from "antd";
import OrderCard from "../liveOrderCard";
import Buttons from "../liveOrderCard/live-order-head-row-buttons";
import "./order-list-list-style-order-detail.scss";
import actions from "../../../../slices/live-order";
import { getOrderId } from "../../../order-history/helper-order-list";
import storeHelper from "../../helper";

class OrderDetail extends Component {
    getFilteredStatus = () => {
        return _.get(this.props, "state.listFilteredStatus", "all");
    };

    getOrderList = () => {
        const filteredStatus = this.getFilteredStatus();
        let result;
        if (filteredStatus === "all") {
            const groupedList = _.get(this.props, "state.groupedLiveOrderList", []);
            var allListArray = Object.values(groupedList);
            result = Array.prototype.concat.apply([], allListArray);
        } else {
            const sortedList = _.get(this.props, "state.groupedLiveOrderList", {});
            result = _.get(sortedList, filteredStatus, []);
        }
        return Array.isArray(result) ? result : [];
    };

    getCurrentActiveOrder = () => _.get(this.props, "state.activeId");

    getOrder = () => {
        const order = this.getOrderList().find((order) => getOrderId(order) === this.getCurrentActiveOrder());
        return order ? order : {};
    };

    fetchOrderDetail = () => {
        this.props.fetchLiveOrder({
            order_ids: [getOrderId(this.getOrder())],
        });
    };

    getStore = () => {
        const orderGid = _.get(this.getOrder(), "g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return storeHelper.getStoreWithGid(orderGid, storesData);
    };

    isEmpty = () => _.isEmpty(this.getOrder());

    renderOrderDetail = () => {
        return (
            <div className="order-list-list-style-order-detail-wrapper">
                <OrderCard
                    withoutButton={true}
                    order={this.getOrder()}
                    fetchOrderDetail={() => this.fetchOrderDetail()}
                    isDetailLoading={this.props.isLoading}
                />
            </div>
        );
    };

    renderOrderControlPanel = () => {
        return (
            <div className="order-list-list-style-control-panel">
                <div className="order-list-control-panel-buttons">
                    <Buttons
                        buttonClass="order-list-control-button"
                        orderStore={this.getStore()}
                        order={this.getOrder()}
                    />
                </div>
            </div>
        );
    };

    renderOrder = () => (
        <div>
            {this.renderOrderDetail()}
            {this.renderOrderControlPanel()}
        </div>
    );

    renderEmpty = () => (
        <div className="order-list-detail-empty-order">
            <Empty description={<FormattedMessage id="no_selected_order" />} />
        </div>
    );

    render() {
        return (
            <div className="order-list-list-style-order-detail">
                {this.isEmpty() ? this.renderEmpty() : this.renderOrder()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    isLoading: _.get(state, "liveOrders.isListLoading", false),
});

const mapDispatchToProps = {
    fetchLiveOrder: actions.getLiveOrdersPart,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetail));
