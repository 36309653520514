import { Switch, Col, Form, Row } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import {
    getDateInput,
    getFormItem,
    getMultiSelectInput,
    getNumberInput,
    getSelectInput,
    getTextInput,
} from "../../../../../../components/form";
import config from "../../../../../../config";
import { getCategories } from "../../../../../../services/categories";
import { getTimeList } from "../../../../../../services/time";
import useModal from "../../../../../../hooks/useModal";
import ReactPlayer from "react-player";
import useLanguageSelect from "../../../../../../hooks/useLanguageSelect";
import ProductImagesEdit from "../../../../../products/_components/product-images-edit";
import { GIFT_CARD_TYPE, status } from "../../../../constants";
import { isEnabled } from "../../../../helper";
import RichTextEditor from "../../../../../../components/rich-text-editor";
import { getCategoriesAsOptions } from "../../../../../products/helper";

const NORMAL_PRODUCT = 1;

function emptyFunction() {
    // does nothing
}

// short description max 160 characters
function ItemInformationTab(props: any = {}): JSX.Element {
    const { images, setImages } = props;
    const intl = useIntl();
    const { lan, getLanguageSelect, getInputLanguageSelect } = useLanguageSelect();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { closeModal, getModal } = useModal("video-modal");
    const { categories, product } = useSelector((state: RootState) => ({
        product: state?.products?.product,
        categories: state?.categories?.categories,
        timeList: state?.time?.timeList,
    }));

    const getCurrentType = () => {
        return Number(props?.values?.product_type ?? GIFT_CARD_TYPE);
    };

    const getOptions = (config: any, exclude: any = []) => {
        const options: any = [];

        Object.keys(config ?? {}).forEach((key: any) => {
            if (!exclude?.includes(key)) {
                options.push({
                    value: Number(key),
                    label: intl.formatMessage({ id: config[key] }),
                });
            }
        });

        return options;
    };

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getTimeList());
    }, [dispatch]);

    const getDescField = (l: any) => {
        return (
            <RichTextEditor
                lan={lan}
                value={props?.values?.["description_" + l] ?? ""}
                onChange={(content: any) => {
                    form.setFieldsValue({ ["description_" + l]: content });
                    props.setValues((prev: any) => ({ ...prev, ["description_" + l]: content }));
                }}
            />
        );
    };

    const generalFields = () => (
        <>
            <div style={{ display: "none" }}>
                {getFormItem(
                    "item_type",
                    getSelectInput(
                        "product_type",
                        false,
                        getOptions(config.TYPE_OPTIONS),
                        emptyFunction,
                        emptyFunction,
                        true
                    )
                )}
            </div>
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={`item-title-${l}`} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem("item_title", getTextInput({ name: "name_" + l, allowClear: true }))}
                    </div>
                ))
            )}
            {getFormItem(
                "categories",
                getMultiSelectInput("category_ids", false, getCategoriesAsOptions(categories, lan))
            )}
            {getCurrentType() === NORMAL_PRODUCT ? (
                <>
                    <Row gutter={16}>
                        <Col flex={1}>{getFormItem("price", getNumberInput("price"))}</Col>
                        <Col flex={1}>{getFormItem("special_price", getNumberInput("special_price"))}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col flex={1}>{getFormItem("special_from_date", getDateInput("special_from_date"))}</Col>
                        <Col flex={1}>{getFormItem("special_to_date", getDateInput("special_to_date"))}</Col>
                    </Row>
                </>
            ) : null}
            <Row gutter={16}>
                <Col flex={1}>{getFormItem("bookvalue", getNumberInput("bookvalue"))}</Col>
                <Col flex={1}>{getFormItem("sku", getTextInput({ name: "sku", allowClear: true }))}</Col>
            </Row>
        </>
    );

    const renderEnableSwitchInput = (giftcard: any = {}) => {
        const checked = isEnabled({
            ...giftcard,
            ...props.values,
        });
        return (
            <Form.Item name={"status"} style={{ marginBottom: "0px" }}>
                <Switch
                    checked={checked}
                    onChange={() => {
                        props.setValues({
                            status: checked ? status.DISABLED : status.ENABLED,
                        });
                    }}
                />
            </Form.Item>
        );
    };

    const getForm = () => (
        <div style={{ maxWidth: "50%", width: "50%", minWidth: "50%" }}>
            {getFormItem("status", renderEnableSwitchInput(product))}
            {generalFields()}
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={`short-description-${l}`} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem(
                            "short_description",
                            getTextInput({ name: "short_description_" + l, rows: 2, max: 160, allowClear: true })
                        )}
                    </div>
                ))
            )}
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={`description-${l}`} style={{ display: "none" }}>
                        {getFormItem(
                            "description",
                            getTextInput({ name: "description_" + l, rows: 3, allowClear: true })
                        )}
                    </div>
                ))
            )}
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={`description2-${l}`} style={{ display: l !== lan ? "none" : "block" }}>
                        {getFormItem("description", getDescField(l))}
                    </div>
                ))
            )}
        </div>
    );

    const getImageEdit = () => (
        <div className="item-images-modal">
            <ProductImagesEdit
                images={images}
                onChange={(value: any) => {
                    form.setFieldsValue({ images: value });
                    setImages(value);
                }}
                addImage={(cache: any) => {
                    const newImages = cache.filter(
                        (c: any) =>
                            !images?.find((i: any) => {
                                return i === c;
                            })
                    );
                    form.setFieldsValue({ images: [...images, ...newImages] });
                    setImages([...images, ...newImages]);
                }}
            />
        </div>
    );

    return (
        <div className="items-tab">
            {getForm()}
            <div style={{ padding: "0px 16px" }}>{getFormItem("item_images", getImageEdit())}</div>

            {getLanguageSelect()}

            {/*  Modals */}

            {getModal(<ReactPlayer url={props?.values?.video} width="100%" />, {
                title: <FormattedMessage id="video" />,
                onCancel: closeModal,
                footer: null,
                width: 768,
            })}
        </div>
    );
}

export default ItemInformationTab;
