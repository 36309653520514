import { RootState } from "app/reducer";
import _ from "lodash";
import React, { ReactNode } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { setCurrentModalPage, TaskModalPages } from "../../../../slices/delivery_request";

const ModalTitleLayout: React.FC<PropsFromRedux> = ({ setCurrentModalPage, currPage, currAddress }) => {
    const TitleWrapper = (props: { children: ReactNode; currPage: TaskModalPages; extraContent?: React.ReactNode }) => {
        return (
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {TaskModalPages.NEW_TASK_MAIN !== props.currPage && (
                        <IoArrowBackOutline
                            onClick={() => {
                                if (TaskModalPages.SEARCH_CUSTOMER === props.currPage) {
                                    if (_.isEmpty(currAddress)) setCurrentModalPage(TaskModalPages.NEW_TASK_ADDRESS);
                                    else setCurrentModalPage(TaskModalPages.EDIT_ADDRESS);
                                    return;
                                }else if(TaskModalPages.ADD_BALANCE === props.currPage){
                                    return;
                                }
                                setCurrentModalPage(TaskModalPages.NEW_TASK_MAIN);
                            }}
                            style={{ width: "20px", cursor: "pointer" }}
                        />
                    )}
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>{props.children}</span>
                </div>
                <div>{props.extraContent}</div>
            </div>
        );
    };

    const renderTaskDetailsTitle = () => {
        return <FormattedMessage id={"task_details"} />;
    };

    const renderAddressInfoTitle = () => {
        return <FormattedMessage id={"edit_or_add_address_info"} />;
    };

    const renderEditOrderIdTitle = () => {
        return <FormattedMessage id={"edit_order_id"} />;
    };

    const renderRequestLocalDeliveryTitle = () => {
        return <FormattedMessage id={"request_local_delivery"} />;
    };
    
    const renderSearchCustomerTitle = () => {
        return <FormattedMessage id={"search_customer"} />;
    };
    
    const renderAddBalanceTitle = () => {
        return <FormattedMessage id={"add_balance"} />;
    }


    const getTaskModalLayout = () => {
        switch (currPage) {
            case TaskModalPages.NEW_TASK_MAIN:
                return renderTaskDetailsTitle();
            case TaskModalPages.NEW_TASK_ORDER:
            case TaskModalPages.EDIT_ORDER_ID:
                return renderEditOrderIdTitle();
            case TaskModalPages.NEW_TASK_ITEMS:
                return renderRequestLocalDeliveryTitle();
            case TaskModalPages.NEW_TASK_ADDRESS:
            case TaskModalPages.EDIT_ADDRESS:
                return renderAddressInfoTitle();
            case TaskModalPages.SEARCH_CUSTOMER:
                return renderSearchCustomerTitle();
            case TaskModalPages.ADD_BALANCE:
                return renderAddBalanceTitle();
            default:
                return <></>;
        }
    };
    return <TitleWrapper currPage={currPage}>{getTaskModalLayout()}</TitleWrapper>;
};

const mapStateToProps = (state: RootState) => {
    return {
        currPage: state.deliveryRequests.modal.newTaskModal.currentPage,
        currAddress: state.deliveryRequests.forms.addressInfo.address,
    };
};

const mapDispatchToProps = {
    setCurrentModalPage,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(ModalTitleLayout));


