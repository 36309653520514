import React from 'react';
import { SelectOption } from '../../../../../../components/form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/reducer';
import h from '../../helper'
import { switchNum, formatMoney, parseMoney } from "../../../../../../util/helper";
import { renderEnableTop, renderNumberInput, renderSelectInput, renderTextInput, renderMultiSelectInput } from '../shipping-functions'
import { setShippingState } from '../../../../../../slices/store'


function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const enable = useSelector((state: RootState) => state.store?.storeShipping?.upsEnable);
    const userID = useSelector((state: RootState) => state.store?.storeShipping?.upsUserID);
    const accessKey = useSelector((state: RootState) => state.store?.storeShipping?.upsAccessKey);
    const password = useSelector((state: RootState) => state.store?.storeShipping?.upsPassword);
    const packageType = useSelector((state: RootState) => state.store?.storeShipping?.upsPackageRequestType);
    const container = useSelector((state: RootState) => state.store?.storeShipping?.upsContainer);
    const destinationType = useSelector((state: RootState) => state.store?.storeShipping?.upsDestinationType);
    const weightUnit = useSelector((state: RootState) => state.store?.storeShipping?.upsWeightUnit);
    const pickupMethod = useSelector((state: RootState) => state.store?.storeShipping?.upsPickupMethod);
    const maxWeight = useSelector((state: RootState) => state.store?.storeShipping?.upsMaxWeight);
    const minWeight = useSelector((state: RootState) => state.store?.storeShipping?.upsMinWeight);
    const handlingFee = useSelector((state: RootState) => state.store?.storeShipping?.upsHandlingFee);
    const handlingApply = useSelector((state: RootState) => state.store?.storeShipping?.upsHandlingApply);
    const calcHandlingFee = useSelector((state: RootState) => state.store?.storeShipping?.upsCalcHandlingFee);
    const allowMethods = useSelector((state: RootState) => state.store?.storeShipping?.upsAllowMethods);

    const handleTypes: SelectOption[] = [
        { value: h.handleType.PER_ORDER, label: intl.formatMessage({ id: "per_order" }) },
        { value: h.handleType.PER_ITEM, label: intl.formatMessage({ id: "per_item" }) },
    ]
    const handleCalcs: SelectOption[] = [
        { value: h.handleCalcs.FIXED, label: intl.formatMessage({ id: "fixed" }) },
        { value: h.handleCalcs.PERCENTAGE, label: intl.formatMessage({ id: "percentage" }) },
    ]
    const destinationTypes: SelectOption[] = [
        { value: h.destinationTypes.COMMERCIAL, label: intl.formatMessage({ id: "commercial" }) },
        { value: h.destinationTypes.RESIDENTIAL, label: intl.formatMessage({ id: "residential" }) },
    ]
    const packageTypes: SelectOption[] = [
        { value: h.packageTypes.DIVIDE_EQUAL, label: intl.formatMessage({ id: "divide_equal" }) },
        { value: h.packageTypes.ORIGIN_WEIGHT, label: intl.formatMessage({ id: "origin_weight" }) },
    ]
    const weightUnits: SelectOption[] = [
        { value: "LBS", label: intl.formatMessage({ id: "lbs" }) },
        { value: "KGS", label: intl.formatMessage({ id: "kgs" }) },
    ]
    const containers: SelectOption[] = [
        { value: "CP", label: intl.formatMessage({ id: "customer_packaging" }) },
        { value: "ULE", label: intl.formatMessage({ id: "ups_letter_envelope" }) },
        { value: "CSP", label: intl.formatMessage({ id: "customer_supplied_package" }) },
        { value: "UT", label: intl.formatMessage({ id: "ups_tube" }) },
        { value: "PAK", label: intl.formatMessage({ id: "pak" }) },
        { value: "UEB", label: intl.formatMessage({ id: "ups_express_box" }) },
        { value: "UW25", label: intl.formatMessage({ id: "ups_worldwide_25" }) },
        { value: "UW10", label: intl.formatMessage({ id: "ups_worldwide_10" }) },
        { value: "PLT", label: intl.formatMessage({ id: "pallet" }) },
        { value: "SEB", label: intl.formatMessage({ id: "small_exp_box" }) },
        { value: "MEB", label: intl.formatMessage({ id: "medium_exp_box" }) },
        { value: "LEB", label: intl.formatMessage({ id: "large_exp_box" }) },
    ]
    const pickupMethods: SelectOption[] = [
        { value: "RDP", label: intl.formatMessage({ id: "regular_daily_pickup" }) },
        { value: "OCA", label: intl.formatMessage({ id: "on_call_air" }) },
        { value: "OTP", label: intl.formatMessage({ id: "one_time_pickup" }) },
        { value: "LC", label: intl.formatMessage({ id: "letter_center" }) },
        { value: "CC", label: intl.formatMessage({ id: "customer_counter" }) },
    ]

    const shippingMethods: SelectOption[] = [
        { value: "01", label: intl.formatMessage({ id: "ups_express" }) },
        { value: "02", label: intl.formatMessage({ id: "ups_expedited" }) },
        { value: "07", label: intl.formatMessage({ id: "ups_worldwide_express" }) },
        { value: "08", label: intl.formatMessage({ id: "ups_worldwide_expedited" }) },
        { value: "11", label: intl.formatMessage({ id: "ups_standard" }) },
        { value: "12", label: intl.formatMessage({ id: "ups_three_day_select" }) },
        { value: "14", label: intl.formatMessage({ id: "ups_early_am" }) },
        { value: "65", label: intl.formatMessage({ id: "ups_saver" }) },
    ]

    const setState = (name: string, value: any) => { dispatch(setShippingState({ name, value })) }
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value).toString() }))

    return (
        <div className="white-layered-block w100 table-rate-tab">
            {renderEnableTop("upsEnable", enable, switchChange)}
            <div className="d-flex flex-wrap w100 mt-3">
                {renderTextInput("user_id", "upsUserID", userID, setState)}
                {renderTextInput("access_key", "upsAccessKey", accessKey, setState)}
                {renderTextInput("password", "upsPassword", password, setState)}
                {renderSelectInput("apply_handling", "upsHandlingApply", handlingApply, setState, handleTypes)}
                {renderSelectInput("container", "upsContainer", container, setState, containers)}
                {renderSelectInput("pickup_method", "upsPickupMethod", pickupMethod, setState, pickupMethods)}
                {renderSelectInput("weight_unit", "upsWeightUnit", weightUnit, setState, weightUnits)}
                {renderNumberInput("max_weight", "upsMaxWeight", maxWeight, setState)}
                {renderNumberInput("min_weight", "upsMinWeight", minWeight, setState)}
                {renderNumberInput("handling_fee", "upsHandlingFee", handlingFee, setState, {
                    formatter: (value: any) => formatMoney(value, intl),
                    parser: (value: any) => parseMoney(value, intl)
                })}
                {renderSelectInput("calc_handling_fee", "upsCalcHandlingFee", calcHandlingFee, setState, handleCalcs)}
                {renderSelectInput("destination_type", "upsDestinationType", destinationType, setState, destinationTypes)}
                {renderSelectInput("package_request_type", "upsPackageRequestType", packageType, setState, packageTypes)}
                {renderMultiSelectInput("allowed_methods", "upsAllowMethods", allowMethods, setState, shippingMethods)}
            </div>
        </div>
    )
}

export default App;