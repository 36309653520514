import React from "react";
import { FormattedMessage } from "react-intl";
import { Switch } from "antd";
import { isBool } from "../../../../../../util/helper";

interface SwitchDescProps {
    description: string;
    value: number | any;
    name: string;
    local: (type: any) => any | any;
    switchOnChange: (value: number | any, name: string, local?: (type: any) => any | any) => any | null;
    withDescription?: string | null;
}

export const SwitchDesc = (props: SwitchDescProps): JSX.Element => {
    const { description, value, name, local, switchOnChange, withDescription } = props;

    return (
        <div className="d-flex justify-content-start align-items-center">
            <Switch checked={isBool(value)} onChange={() => switchOnChange(value, name, local)} />
            <div className="serve-desc-holder">
                <p className="ant-descriptions-description">
                    <FormattedMessage id={description} />
                </p>
                {withDescription ? (
                    <p className="serve-desc-holder-description">
                        <FormattedMessage id={`${description}_description`} />
                    </p>
                ) : null}
            </div>
        </div>
    );
};
