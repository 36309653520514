import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ExtraFees {
    tips: boolean;
    deliveryFee: boolean;
    surcharge: boolean;
}

interface FeeRule {
    orderType: string;
    feeType: string;
    feeValue: number;
    feeBasedOn: string;
    extraFees: ExtraFees;
}

interface PartnerFeeState {
    feeRules: FeeRule[];
}

const initialState: PartnerFeeState = {
    feeRules: [],
};

const slice = createSlice({
    name: "partnerFee",
    initialState,
    reducers: {
        addFeeRule: (state, action: PayloadAction<FeeRule>) => {
            state.feeRules.push(action.payload);
        },
        updateFeeRule: (state, action: PayloadAction<FeeRule>) => {
            const index = state.feeRules.findIndex((rule) => rule.orderType === action.payload.orderType);
            if (index !== -1) {
                state.feeRules[index] = action.payload;
            }
        },
        deleteFeeRule: (state, action: PayloadAction<string>) => {
            state.feeRules = state.feeRules.filter((rule) => rule.orderType !== action.payload);
        },
    },
});

export const { addFeeRule, updateFeeRule, deleteFeeRule } = slice.actions;

export const partnerFee = slice.reducer;
