import { Button, Descriptions, Form, Select } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import { getDescriptionItem, getMultiSelectInput, SelectOption } from "../../../../../../components/form";
import { getStoreDetails, getStoreTypes, updateStoreDetails } from "../../../../../../services/store";
import ObjectModel from "../../../../../../util/models";
import StoreGeneralInfo from "../../../../../../util/models/store-general-info";
import config from "../../../../../../config";
import { Prompt, useLocation } from "react-router-dom";
import { setStoreState } from "slices/store";

function StoreTypeAndCategory(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const lan = useSelector((state: RootState) => state?.setting?.lan);
    const storeInformation = useSelector((state: RootState) => state.store?.storeInformation);
    const records = useSelector((state: RootState) => state.store?.records);
    const types = useSelector((state: RootState) => state.store?.types);
    const hasError = useSelector((state: RootState) => state.store?.hasError);
    const [values, setValues] = useState<any>({});
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (hasError) {
            setTouched(true);
            dispatch(setStoreState({ hasError: false }));
        }
    }, [hasError]);

    const onFinish = (formData: any) => {
        const data = _.cloneDeep(storeInformation);
        let values: any = [];
        setTouched(false);
        Object.keys(formData ?? {}).forEach((key) => {
            values = [...values, ...(formData[key] ?? [])];
        });
        data.sub_categories = values.join(":");
        data.store_categories = updateCategoryFlags(formData);
        dispatch(updateStoreDetails(new ObjectModel(StoreGeneralInfo).convertToPostData(data, records, "store_info")));
    };

    const updateCategoryFlags = (formData: any) => {
        const values: any = [];
        for (const [key, value] of Object.entries(config?.CATEGORY_MAPPING_TO_NUMBER)) {
            if (formData[key]) {
                values.push(value);
            }
        }
        return values.join(":");
    };

    const getTypeOptions = useCallback(
        (type: any) => {
            if (Array.isArray(types[type])) {
                return types[type]
                    ?.filter?.((child: any) => child?.name !== "All")
                    ?.reduce?.((acc: any, sub: any) => {
                        if (sub.children) {
                            acc = [...acc, ...sub.children.filter((child: any) => child?.name !== "All")];
                        } else {
                            acc.push(sub);
                        }
                        return acc;
                    }, [])
                    .map((option: any) => ({
                        label: option?.name,
                        value: Number(option?.id),
                    }));
            }
            return [];
        },
        [types]
    );

    const getValues = useCallback(() => {
        const info = _.cloneDeep(storeInformation);
        return info?.sub_categories?.split?.(":")?.reduce?.((acc: any, category: any) => {
            if (getTypeOptions(1)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.restaurant = [...(acc?.restaurant ?? []), Number(category)];
            }
            if (getTypeOptions(4)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.shopping = [...(acc?.shopping ?? []), Number(category)];
            }
            if (getTypeOptions(5)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.service = [...(acc?.service ?? []), Number(category)];
            }
            return acc;
        }, {});
    }, [storeInformation, getTypeOptions]);

    const getRestaurantSearch = (
        <Form.Item name={"restaurant"} style={{ marginBottom: "auto" }}>
            <Select
                showSearch={true}
                filterOption={(inputValue, option) => {
                    if (typeof option?.children === "string") {
                        return String(option.children).toLowerCase().includes(inputValue);
                    } else {
                        return false;
                    }
                }}
                style={{ width: "100%" }}
                mode="multiple"
            >
                {getTypeOptions(config?.CATEGORY_MAPPING_TO_NUMBER?.["restaurant"])?.map((option: SelectOption) => (
                    <Select.Option key={option.value} value={option.value}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    const storeTypeAndCategoriesSection = () => (
        <Descriptions
            title={intl.formatMessage({ id: "store_type_and_categories" })}
            className="general-section"
            bordered
        >
            {!values?.service?.length && !values?.shopping?.length
                ? getDescriptionItem("restaurant", 3, getRestaurantSearch)
                : null}
            {!values?.restaurant?.length
                ? getDescriptionItem("shopping", 3, getMultiSelectInput("shopping", true, getTypeOptions(4)))
                : null}
            {!values?.restaurant?.length
                ? getDescriptionItem("service", 3, getMultiSelectInput("service", true, getTypeOptions(5)))
                : null}
        </Descriptions>
    );

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const handleValuesChanged = (values: any) => {
        setTouched(form.isFieldsTouched());
        setValues(values);
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
        dispatch(getStoreTypes({ lan }));
    }, [form, dispatch, lan, location]);

    useEffect(() => {
        setValues(getValues());
    }, []);

    return !_.isEmpty(storeInformation) && !_.isEmpty(types) ? (
        <React.Fragment>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="general-settings has-floating-submit">
                <Form
                    form={form}
                    name="general-settings"
                    onFinish={onFinish}
                    initialValues={getValues()}
                    onValuesChange={handleValuesChanged}
                >
                    {storeTypeAndCategoriesSection()}
                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreTypeAndCategory;
