import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import _ from "lodash";
import sH from "./helper";
import { setStoreState } from "../../../../slices/store";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import ObjectModel from "../../../../util/models";
import { getBlockOptions } from "../../../../components/form";
import { RootState } from "../../../../app/reducer";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import { setSelfServeState } from "../../../../slices/store";
import helper, { isBool } from "../../../../util/helper";
import StoreGenerateQr from "../../../../util/models/store-generate-qr";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import "./generate-print.scss";
import { getOriginalIndex, QRCodeRender } from "./qr-code-render";
import { FormatModal } from "./_components/format-modal";
import { ArbitraryStyle } from "./_components/arbitrary-style";
import { VariablesProperties } from "./_components/variable-properties";
import { FormatProperties } from "./_components/format-properties";
import { PosterSwitch } from "./_components/format-common/poster-switch";
import { ArbitrarySwitch } from "./_components/format-common/arbitrary-switch";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        {
            path: "/settings/generateQr",
            breadcrumbName: "generate_qr_setting",
        },
    ],
};

function App(): JSX.Element {
    const dispatch = useDispatch();
    const selfServe = useSelector((state: RootState) => state.store?.storeSelfServe);
    const generateQr = useSelector((state: RootState) => state.store?.storeGenerateQr);
    const addr = _.get(selfServe, "store_addr", {});
    const g_id = _.get(selfServe, "store_id", "");
    const records = useSelector((state: RootState) => state.store?.records);
    const { lan } = useLanguageSelect();
    const storeImages = useSelector((state: RootState) => state.store?.storeImages);
    const [bleedingMargin, setBleedingMargin] = useState<any>(3);
    const [paperSize, setPaperSize] = useState(sH.paperSizes.A4);
    const [qrStyle, setQRStyle] = useState(sH.qrStyles.SQUARES);
    const [bgColor, setBgColor] = useState(sH.bgColours.TURQUOISE);
    const [addMargin, setAddMargin] = useState<boolean>(false);
    const showRewards = false;
    const [openPosterStyle, setPosterStyle] = useState<boolean>(false);
    const [openArbitraryCode, setArbitraryCode] = useState<boolean>(false);
    const codeTid = sH.codeTid.TABLE;
    const [deleteTypes, setDeleteTypes] = useState<string[]>([]);
    const [qrCodes, setQrCodes] = useState([]);
    const [dataURLS, setDataURLS] = useState([]);
    const user = useSelector((state: RootState) => state.user);
    const showDownloadModal = _.get(selfServe, "showDownloadModal", false);
    const paymentMethodsChecked = useSelector((state: RootState) => state.store?.paymentMethodsChecked);
    const [customDiscount, setCustomDiscount] = useState(10);
    const [customOrderLabel, setCustomOrderLabel] = useState("Pick-up or Delivery");
    const [customBottomLabel, setCustomBottomLabel] = useState("Order Online & Skip The Line");

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    useEffect(() => {
        getOriginalIndex(g_id, qrStyle, storeImages, setQrCodes);
    }, [g_id, codeTid, qrStyle, storeImages]);

    const setState = (name: string, value: any) => dispatch(setSelfServeState({ name, value }));

    const switchOnChange = (value: any, name: string, local?: any) => {
        value = !isBool(value);
        local ? local(value) : dispatch(setSelfServeState({ name, value: helper.isNum(value).toString() }));
    };

    const saveChanges = () => {
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreGenerateQr).convertToPostData(generateQr, records, "generate_qr_setting")
            )
        );
    };

    const renderSaveSection = () => (
        <div className="setting-actions" style={{ marginTop: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    const getQRURLS = async () => {
        const canvas = document.getElementsByTagName("canvas")[0];
        const pngurl = canvas.toDataURL();
        //@ts-ignore
        setDataURLS(pngurl);
    };

    const generateQRS = () => {
        setState("openFormatModal", true);
        getQRURLS();
    };

    const renderTopSection = () => (
        <div className="white-layered-block serve-top-section">
            <div className="serve-store-info-area">
                <div className="serve-store-info-area">
                    <div className="store_name-title">{_.get(selfServe, `store_name_${lan}`, "")}</div>
                    <div>{`${addr.street}, ${addr.city}, ${addr.province}, ${addr.post_code}`}</div>
                    <div>{selfServe.phone}</div>
                </div>
            </div>
            <Button type="default" className="text-on-white-background" onClick={() => generateQRS()}>
                <FormattedMessage id="generate_qr" />
            </Button>
        </div>
    );

    const renderBothToggles = () => (
        <div>
            <div className="serve-option-layer">
                <div className="store_name-title"></div>
            </div>
            <div className="">
                {!isBool(openArbitraryCode) ? (
                    <PosterSwitch
                        openPosterStyle={openPosterStyle}
                        setPosterStyle={setPosterStyle}
                        switchOnChange={switchOnChange}
                    />
                ) : null}
                {!isBool(openPosterStyle) ? (
                    <ArbitrarySwitch
                        openArbitraryCode={openArbitraryCode}
                        setArbitraryCode={setArbitraryCode}
                        switchOnChange={switchOnChange}
                    />
                ) : null}
            </div>
        </div>
    );

    const uploadCallback = (type: any) => {
        setDeleteTypes(deleteTypes.filter((dt) => dt !== type));
    };

    const deleteCallback = (type: any) => {
        setDeleteTypes([...deleteTypes, type]);
    };

    const onChangeDiscount = (value: any) => {
        setCustomDiscount(value);
        dispatch(
            setStoreState({
                customDiscount: value,
            })
        );
    };

    const onChangePayment = (checkedValue: any) => {
        const methodsChecked = checkedValue;
        dispatch(
            setStoreState({
                paymentMethodsChecked: methodsChecked,
            })
        );
    };

    const onChangeCustomOrderLabel = (e: any) => {
        setCustomOrderLabel(e.target.value);
        dispatch(
            setStoreState({
                customOrderLabel: customOrderLabel,
            })
        );
    };

    const onChangeCustomBottomLabel = (e: any) => {
        setCustomBottomLabel(e.target.value);
        dispatch(
            setStoreState({
                customBottomLabel: customBottomLabel,
            })
        );
    };

    return (
        <Wrapper helmet={{ title: "generate_qr_setting" }} breadcrumb={breadcrumb}>
            <Spin spinning={showDownloadModal}>
                {renderTopSection()}
                {getBlockOptions("qr_code_type", "qr_code_type_description", renderBothToggles())}
                {openPosterStyle ? (
                    <VariablesProperties
                        storeImages={storeImages}
                        user={user}
                        uploadCallback={uploadCallback}
                        deleteCallback={deleteCallback}
                        customDiscount={customDiscount}
                        onChangeDiscount={onChangeDiscount}
                        onChangePayment={onChangePayment}
                        customOrderLabel={customOrderLabel}
                        customBottomLabel={customBottomLabel}
                        onChangeCustomOrderLabel={onChangeCustomOrderLabel}
                        onChangeCustomBottomLabel={onChangeCustomBottomLabel}
                    />
                ) : null}
                {openPosterStyle ? (
                    <FormatProperties
                        qrStyle={qrStyle}
                        setQRStyle={setQRStyle}
                        addMargin={addMargin}
                        setAddMargin={setAddMargin}
                        bleedingMargin={bleedingMargin}
                        setBleedingMargin={setBleedingMargin}
                        paperSize={paperSize}
                        setPaperSize={setPaperSize}
                        bgColor={bgColor}
                        setBgColor={setBgColor}
                        switchOnChange={switchOnChange}
                    />
                ) : null}
                {openArbitraryCode ? (
                    <ArbitraryStyle
                        storeImages={storeImages}
                        user={user}
                        uploadCallback={uploadCallback}
                        deleteCallback={deleteCallback}
                        qrStyle={qrStyle}
                        setQRStyle={setQRStyle}
                    />
                ) : null}
                <FormatModal
                    setState={setState}
                    gid={g_id}
                    selfServe={selfServe}
                    lan={lan}
                    customDiscount={customDiscount}
                    customOrderLabel={customOrderLabel}
                    customBottomLabel={customBottomLabel}
                    bgColor={bgColor}
                    paperSize={paperSize}
                    qrStyle={qrStyle}
                    dataURLS={dataURLS}
                    codeTid={codeTid}
                    showRewards={showRewards}
                    addMargin={addMargin}
                    bleedingMargin={bleedingMargin}
                    paymentMethodsChecked={paymentMethodsChecked}
                />
                {renderSaveSection()}
                <QRCodeRender qrCodes={qrCodes} />
            </Spin>
        </Wrapper>
    );
}
export default App;
