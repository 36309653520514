import React, { Component } from "react";
import { Button, Switch } from "antd";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _actions from "../../../../slices/live-order";
import { getOrderTableOriginal } from "../../../order-history/helper-order-list";
import { withRouter } from "react-router-dom";
import { groupList } from "../../helper";

class ViewHistoryButton extends Component {
    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getLiveOrder = () =>
        Array.isArray(_.get(this.props, "state.liveOrderList")) ? _.get(this.props, "state.liveOrderList") : [];

    getCurrentStyle = () => _.get(this.props, "state.listStyle", "list");

    onClick = () => {
        this.props.history.push("/orders");
    };

    shouldRenderGroupByTableSwitch = () => {
        let tableGroupedOrder = {};
        const list = Array.isArray(_.get(this.props, "state.liveOrderList"))
            ? _.get(this.props, "state.liveOrderList")
            : [];
        list.forEach((order) => {
            const table = getOrderTableOriginal(order);
            const gid = _.get(order, "g_id");
            const tableSignature = table + gid;
            const originalValue = Array.isArray(tableGroupedOrder[tableSignature])
                ? tableGroupedOrder[tableSignature]
                : [];
            if (table) {
                tableGroupedOrder[tableSignature] = [...originalValue, order];
            }
        });
        const groupedItem = Object.values(tableGroupedOrder).find((grouped) => grouped.length > 1);
        return !_.isEmpty(groupedItem);
    };

    renderGroupByTableSwitch = () => {
        const on = _.get(this.props, "state.groupByTable", false);

        const onChange = () => {
            const groupedList = groupList(this.getLiveOrder(), {
                ...this.props.state,
                groupByTable: !on,
            });
            var allListArray = Object.values(groupedList);
            const flattenArray = Array.prototype.concat.apply([], allListArray);
            const firstOrderId = _.get(flattenArray, "0.ord_id");
            let updateObject = {
                groupedLiveOrderList: groupedList,
                activeId: firstOrderId,
                groupByTable: !on,
            };
            if (firstOrderId && this.getCurrentStyle() === "list") {
                updateObject.expansionControllers = {
                    [firstOrderId]: { showDetail: true },
                };
            }
            this.props.setState(updateObject);
        };

        if (this.shouldRenderGroupByTableSwitch())
            return (
                <div>
                    <div className="view-order-history-group-by-table-switch-title">
                        <FormattedMessage id="group_by_table" />
                    </div>
                    <div>
                        <Switch checked={on} onChange={() => onChange()} />
                    </div>
                </div>
            );
    };

    renderViewHistoryButton = () => {
        return (
            <Button size="large" type="primary" onClick={this.onClick}>
                <b>
                    <FormattedMessage id="view_order_history" />
                </b>
            </Button>
        );
    };

    render() {
        return (
            <div
                style={{
                    justifyContent: this.shouldRenderGroupByTableSwitch() ? `space-between` : "center",
                }}
                className="live-order-list-view-history-button"
            >
                {this.renderGroupByTableSwitch()}
                {this.renderViewHistoryButton()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ViewHistoryButton)));
