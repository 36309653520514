import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../app/reducer";
import qs from "querystring";
import { getReferralsList } from "../../../services/referrals";

const selector = (state: RootState) => ({
    searchConditions: state?.referrals?.searchConditions,
});

function useReferralsSearchCondition(): any {
    const dispatch = useDispatch();

    const { searchConditions } = useSelector(selector);
    const { search: s } = useLocation();

    const search = s.substring(1);

    useEffect(() => {
        dispatch(getReferralsList(qs.parse(search)));
    }, [search, dispatch]);

    return {
        searchConditions,
    };
}

export default useReferralsSearchCondition;
