import React from "react";
import { View, Image } from "@react-pdf/renderer";
import PaymentIcons from "../../../../../images/icons/payment-icon.png";

const paymentIcons = (styles: React.CSSProperties | any): JSX.Element => (
    <View style={styles.paymentContainer}>
        <Image src={PaymentIcons} />
    </View>
);
export default paymentIcons;
