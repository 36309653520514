import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { Checkbox } from "antd";
import { KEY_TYPE_MAPPING, getOptionValue, getOptionsWithType } from "../../../helper-order-list";
import helper from "../../../../../util/helper";
import actions from "../../../../../slices/order-history";

class ExportDrawerCollapseCheckbox extends Component {
    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getFilter = () =>
        Array.isArray(_.get(this.props, `modalState.${this.getKey()}`))
            ? _.get(this.props, `modalState.${this.getKey()}`)
            : [];

    getOptions = () => {
        const type = this.props.type;
        const result = getOptionsWithType(type, this.props.state, this.props.storeRecords);
        return Array.isArray(result) ? result : [];
    };

    getKey = () => {
        const type = this.props.type;
        const result = helper.getKeyByValue(KEY_TYPE_MAPPING, type);
        return result;
    };

    renderCheckboxes = () => {
        const key = this.getKey();
        const filter = this.getFilter();
        const type = this.props.type;

        const onCheckboxChange = (obj) => {
            const searchValue = getOptionValue(obj, this.props.type, filter, "normal", this.props.storeRecords);
            this.props.setModalState({ [key]: searchValue });
        };

        const isCheck = (obj) => {
            const isAll = obj.label === "all";
            const value = obj.value;
            if (isAll) {
                return _.isEqual(filter, value);
            }
            return filter.includes(value);
        };

        return this.getOptions().map((obj) => {
            const label = type === "status" ? `category_${obj.label}` : obj.label ? obj.label : " ";
            return (
                <div key={obj.label} className="order-list-title-filter-drop-down-item">
                    <Checkbox checked={isCheck(obj)} onChange={() => onCheckboxChange(obj)}>
                        <span className="order-list-title-filter-drop-down-item-text">{this.str(label)}</span>
                        <span>({obj.total})</span>
                    </Checkbox>
                </div>
            );
        });
    };

    render() {
        return <div>{this.renderCheckboxes()}</div>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExportDrawerCollapseCheckbox));
