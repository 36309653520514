import { Button, Spin } from "antd";
import React from "react";
import { HiCreditCard } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getGiftcardAccounts } from "../../../../services/giftcards";
import { setGiftcardState } from "../../../../slices/giftcards";
import { formatCurrency } from "../../../../util/helper";
import GiftCardPagination from "./gift-card-pagination";
import RefreshButton from "../../../../components/refresh";

const selector = (state: RootState) => ({
    cards: state?.giftcards?.giftcards,
    lan: state?.setting?.lan,
    pageSize: state?.giftcards?.accountsPageSize,
    isHideZeroBalance: state?.giftcards?.isHideZeroBalance,
    storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
    loading: state?.giftcards?.loading,
});

interface TableColumn {
    key?: string;
    render?: any;
    title?: any;
    className?: string;
}

function GiftCardTable(): JSX.Element {
    const { cards, pageSize, isHideZeroBalance, storeCurrency, loading } = useSelector(selector);
    const dispatch = useDispatch();

    const handleClick = (record: any) => {
        dispatch(
            setGiftcardState({
                history: [],
                addCreditId: "",
                chargeId: "",
            })
        );
        dispatch(
            getGiftcardAccounts(
                {
                    product_id: record?.product_id,
                    product_name: record?.product_name,
                    limit: pageSize,
                    is_hide_zero_balance: isHideZeroBalance,
                },
                () => {
                    dispatch(
                        setGiftcardState({
                            showGiftCardAccountModal: true,
                        })
                    );
                }
            )
        );
    };

    const renderMenu = (value: any, record: any) => {
        return (
            <Button onClick={() => handleClick(record)} type="primary">
                <FormattedMessage id="view" />
            </Button>
        );
    };

    const columns: TableColumn[] = [
        {
            key: "product_name",
        },
        {
            key: "count",
            className: "align-right justify-content-end",
        },
        {
            key: "balance",
            className: "align-right justify-content-end",
            render: (value: any) => formatCurrency(value, storeCurrency),
        },
        {
            key: "bookvalue",
            className: "align-right justify-content-end",
            render: (value: any, record: any) => formatCurrency(record?.book_value, storeCurrency),
        },
        {
            key: "action",
            render: renderMenu,
        },
    ];

    // @ts-ignore
    const getColumn = (column: TableColumn, card: any) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            <div className={"gift-card-column-content " + column?.className ?? ""}>
                {/* @ts-ignore */}
                {column?.render ? column?.render(card?.[column?.key], card) : card?.[column?.key]}
            </div>
        </div>
    );

    const getColumnHeader = (column: TableColumn) => (
        <div className={"gift-card-column " + column?.className ?? ""} key={column.key}>
            <div className={"gift-card-column-header header-border-top " + column?.className ?? ""} key={column.key}>
                {column?.title ? column.title : <FormattedMessage id={column?.key} />}
            </div>
        </div>
    );
    const renderColumnHeaderRow = (columns: TableColumn[]) => (
        <div className="gift-card-row">
            {columns.map((column) => getColumnHeader(column))}
            <RefreshButton refreshPage={refreshPage} loading={loading} />
        </div>
    );

    const getTable = () => (
        <>
            {renderColumnHeaderRow(columns)}
            {cards?.length ? (
                cards?.map((card) => (
                    <div
                        className="gift-card-row gift-card-row-entry"
                        key={card?.product_id}
                        onClick={() => handleClick(card)}
                    >
                        {columns.map((column) => getColumn(column, card))}
                    </div>
                ))
            ) : (
                <div className="inactive-empty-warn w100">
                    <HiCreditCard size="60" color="lightgrey" className="mb-2" />
                    <FormattedMessage id="no_gift_cards" />
                </div>
            )}
        </>
    );

    const refreshPage = (record: any) => {
        dispatch(
            getGiftcardAccounts(
                {
                    product_id: record?.product_id,
                    product_name: record?.product_name,
                    limit: pageSize,
                    is_hide_zero_balance: isHideZeroBalance,
                },
                () => {
                    dispatch(
                        setGiftcardState({
                            showGiftCardAccountModal: true,
                        })
                    );
                }
            )
        );
    };

    return (
        <div className="gift-card-table">
            <Spin spinning={loading}>
                {getTable()}
                <GiftCardPagination />
            </Spin>
        </div>
    );
}

export default GiftCardTable;
