import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, Checkbox } from "antd";
import { SelectOption } from "components/form";
import { DUE_TIME_SETTINGS } from "./constants";
import { ASAP } from "app/constants";

interface DueTimeSetting {
    ANY: boolean;
    ASAP: boolean;
    FUTURE_DAY: boolean;
    SAME_DAY: boolean;
    SPECIFY: boolean;
}

interface DueTimeSettingProps {
    currSetting: number;
    dueTimeSetting: DueTimeSetting;
    setState: any;
}

export const DueTimeSetting = (props: DueTimeSettingProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const exclusiveDueTimeOptions = [
        {
            setting: ASAP,
            value: DUE_TIME_SETTINGS.ASAP,
            label: intl.formatMessage({ id: "auto_accept_time_setting_asap" }),
        },
        {
            setting: "SAME_DAY",
            value: DUE_TIME_SETTINGS.SAME_DAY,
            label: intl.formatMessage({ id: "auto_accept_time_setting_same_day" }),
        },
        {
            setting: "SPECIFY",
            value: DUE_TIME_SETTINGS.SPECIFY,
            label: intl.formatMessage({ id: "auto_accept_time_setting_specify" }),
        },
    ];

    const getDefaultExclusiveDueTimeSetting = () => {
        const defaultSetting = exclusiveDueTimeOptions
            .map((options) => options.setting)
            // @ts-ignore
            .find((setting) => props.dueTimeSetting[setting]);
        return defaultSetting ? DUE_TIME_SETTINGS[defaultSetting] : undefined;
    };

    /**
     * Update due time setting state in the store slice
     *
     * @param value - the option value of DUE_TIME_SETTINGS for the changed setting
     * @param selected - true if the changed setting is a checkbox and selected
     */
    const updateDueTimeSettings = (value: number, selected: boolean) => {
        if (Object.values(DUE_TIME_SETTINGS).includes(value)) {
            if (value === DUE_TIME_SETTINGS.ANY && selected) {
                dispatch(props.setState("due_time_setting", DUE_TIME_SETTINGS.ANY));
            } else if (value === DUE_TIME_SETTINGS.ANY && !selected) {
                dispatch(props.setState("due_time_setting", DUE_TIME_SETTINGS.ASAP));
            } else if (value === DUE_TIME_SETTINGS.FUTURE_DAY) {
                // find current due time setting value, and
                // if current value is selected, add to setting value, else subtract from setting value
                dispatch(
                    props.setState("due_time_setting", selected ? props.currSetting + value : props.currSetting - value)
                );
            } else {
                // reset due time setting value to current value + future day (if selected)
                dispatch(
                    props.setState(
                        "due_time_setting",
                        value + (props.dueTimeSetting.FUTURE_DAY ? DUE_TIME_SETTINGS.FUTURE_DAY : 0)
                    )
                );
            }
        }
    };

    const renderDueTimeAny = () => {
        return (
            <Checkbox
                value={`${DUE_TIME_SETTINGS.ANY}`}
                checked={props.dueTimeSetting.ANY}
                onChange={(e) => updateDueTimeSettings(DUE_TIME_SETTINGS.ANY, e.target.checked)}
            >
                {intl.formatMessage({ id: "auto_accept_time_setting_any" })}
            </Checkbox>
        );
    };

    const renderDueTimeToday = () => {
        return !props.dueTimeSetting.ANY ? (
            <>
                <div className="option-title" style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <FormattedMessage id="auto_accept_due_time_same_day" />
                </div>
                <Select
                    className="surTax-field"
                    value={getDefaultExclusiveDueTimeSetting()}
                    onChange={(val) => updateDueTimeSettings(val, true)}
                    disabled={props.dueTimeSetting.ANY}
                >
                    {exclusiveDueTimeOptions.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </>
        ) : null;
    };

    const renderDueTimeFuture = () => {
        return !props.dueTimeSetting.ANY ? (
            <>
                <div className="option-title" style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <FormattedMessage id="auto_accept_due_time_future_day" />
                </div>
                <Checkbox
                    value={`${DUE_TIME_SETTINGS.FUTURE_DAY}`}
                    checked={props.dueTimeSetting.FUTURE_DAY}
                    onChange={(e) => updateDueTimeSettings(DUE_TIME_SETTINGS.FUTURE_DAY, e.target.checked)}
                    disabled={props.dueTimeSetting.ANY}
                >
                    {intl.formatMessage({ id: "auto_accept_time_setting_future_day" })}
                </Checkbox>
            </>
        ) : null;
    };

    const renderDueTimeSettingOptions = () => {
        return (
            <>
                {renderDueTimeAny()}
                {renderDueTimeToday()}
                {renderDueTimeFuture()}
            </>
        );
    };

    return (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="auto_accept_due_time_setting" />
                </div>
                {renderDueTimeSettingOptions()}
            </div>
        </div>
    );
};
