import _config from "..";
import _helper from "../../util/helper";

function CATEGORIES_MAPPING(value) {
    return _helper.getKeyByValue(_config.CATEGORIES_MAPPING, value);
}

function PAYMENT_METHOD_MAPPING(value) {
    return _helper.getKeyByValue(_config.PAYMENT_METHOD_MAPPING, value);
}

function SHIPPING_MAPPING_TO_NUMERIC(value) {
    return _helper.getKeyByValue(_config.SHIPPING_MAPPING_TO_NUMERIC, value);
}

function SHIPPING_MAPPING_TO_TEXT(value) {
    return _helper.getKeyByValue(_config.SHIPPING_MAPPING_TO_TEXT, value);
}

function ORDER_TYPE_MAPPING_TO_TEXT(value) {
    return _helper.getKeyByValue(_config.ORDER_TYPE_MAPPING_TO_TEXT, value);
}

function ORDER_STATUS_MAPPING(value) {
    return _helper.getKeyByValue(_config.ORDER_STATUS_MAPPING, value);
}

function CURRENCY_SYMBOL(value) {
    return _helper.getKeyByValue(_config.CURRENCY_SYMBOL, value);
}

function LANGUAGE_MAP(value) {
    return _helper.getKeyByValue(_config.LANGUAGE_MAP, value);
}

function ORDER_DELIVERY_STATUS_MAP(value) {
    return _helper.getKeyByValue(_config.ORDER_DELIVERY_STATUS_MAPPING, value);
}

export default {
    CATEGORIES_MAPPING,
    PAYMENT_METHOD_MAPPING,
    SHIPPING_MAPPING_TO_NUMERIC,
    SHIPPING_MAPPING_TO_TEXT,
    ORDER_TYPE_MAPPING_TO_TEXT,
    ORDER_STATUS_MAPPING,
    CURRENCY_SYMBOL,
    LANGUAGE_MAP,
    ORDER_DELIVERY_STATUS_MAP,
};
