import React, { useEffect } from "react";
import { Button, Switch, InputNumber } from "antd";
import _ from "lodash";
import { getSwitchDescBlock } from "../../../../components/form";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import ObjectModel from "./../../../../util/models";
import { isBool } from "./../../../../util/helper";
import h from "util/helper";
import rH from "./helper";
import StoreReferrals from "../../../../util/models/store-referrals";
import { getStoreDetails, updateStoreDetails } from "./../../../../services/store";
import { setReferralState } from "./../../../../slices/store";
import { formatNumberInput } from "../../../../util/helper";
import "./index.scss";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/referrals", breadcrumbName: "referral_settings" },
    ],
};

function App(): JSX.Element | null {
    const dispatch = useDispatch();
    const referrals = useSelector((state: RootState) => state.store?.storeReferrals);
    const records = useSelector((state: RootState) => state.store?.records);
    const batchProcess = useSelector((state: RootState) => state.store?.storeReferrals?.batchProcess);
    const allowCustRefs = useSelector((state: RootState) => state.store?.storeReferrals?.allowCustRefs);
    const allowOrdRefs = useSelector((state: RootState) => state.store?.storeReferrals?.allowOrdRefs);
    const refExpiry = useSelector((state: RootState) => state.store?.storeReferrals?.refExpiry);
    const custValToPoints = useSelector((state: RootState) => state.store?.storeReferrals?.custValToPoints);
    const ordsValToPoints = useSelector((state: RootState) => state.store?.storeReferrals?.ordsValToPoints);
    const autoGenRefCode = useSelector((state: RootState) => state.store?.storeReferrals?.autoGenRefCode);
    const currUser = useSelector((state: RootState) => state.user);
    const isSuperUserRole = h.isSuperUser(currUser);

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const setState = (name: string, value: any) => dispatch(setReferralState({ name, value }));

    const onSave = () => {
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreReferrals).convertToPostData(referrals, records, "referral_settings")
            )
        );
    };

    const getSwitch = (name: string, value: any) => (
        <Switch
            className="ml-4"
            checked={isBool(value)}
            disabled={!isSuperUserRole}
            onChange={() =>
                setState(
                    name,
                    value === rH.REFERRAL_STATUS.DISABLE ? rH.REFERRAL_STATUS.ENABLE : rH.REFERRAL_STATUS.DISABLE
                )
            }
        />
    );

    const getNumberInputBox = (title: string, name: string, value: any, min: number, max: number, desc?: string) => (
        <div className="white-layered-block">
            <div className="referral-text d-flex align-items-center">
                <FormattedMessage id={title} />
                <InputNumber
                    className="ml-3 custom-number-field"
                    min={min}
                    max={max}
                    disabled={!isSuperUserRole}
                    value={parseInt(value ?? rH.MIN_STRING)}
                    formatter={formatNumberInput}
                    onChange={(e: any) => setState(name, e)}
                />
            </div>
            <div style={{ width: "100%" }}>
                <p className="single-layered-description">
                    <FormattedMessage id={desc ?? " "} />
                </p>
            </div>
        </div>
    );

    const getNumberInputs = (
        <React.Fragment>
            {getNumberInputBox(
                "ref_expiry_days",
                "refExpiry",
                refExpiry,
                rH.MIN,
                rH.MAX_EXPIRY,
                "ref_expiry_days_desc"
            )}
            {getNumberInputBox(
                "cust_val_points",
                "custValToPoints",
                custValToPoints,
                rH.MIN,
                rH.MAX_RATIO,
                "cust_val_points_desc"
            )}
            {getNumberInputBox(
                "ord_val_points",
                "ordsValToPoints",
                ordsValToPoints,
                rH.MIN,
                rH.MAX_RATIO,
                "ord_val_points_desc"
            )}
        </React.Fragment>
    );

    const getReferralFields = (
        <div className="referral-settings-page d-flex w100 flex-column">
            {getSwitchDescBlock("allow_cust_refs", "allow_cust_refs_desc", getSwitch("allowCustRefs", allowCustRefs))}
            {getSwitchDescBlock("allow_order_refs", "allow_order_refs_desc", getSwitch("allowOrdRefs", allowOrdRefs))}
            {getSwitchDescBlock(
                "batch_process_referral_rewards",
                "batch_process_referral_rewards_desc",
                getSwitch("batchProcess", batchProcess)
            )}
            {allowCustRefs === rH.REFERRAL_STATUS.ENABLE || allowOrdRefs === rH.REFERRAL_STATUS.ENABLE
                ? getNumberInputs
                : null}
            {getSwitchDescBlock(
                "auto_generate_referral_code",
                "auto_generate_referral_code_desc",
                getSwitch("autoGenRefCode", autoGenRefCode)
            )}
        </div>
    );

    return !_.isEmpty(referrals) ? (
        <Wrapper helmet={{ title: "referral_settings" }} breadcrumb={breadcrumb}>
            {getReferralFields}
            <div className="setting-actions mr-2">
                <Button type="primary" size="large" onClick={() => onSave()} className="save-button-handler">
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        </Wrapper>
    ) : null;
}

export default App;
