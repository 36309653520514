import { Dropdown, Menu, Button } from "antd";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootState } from "app/reducer";
import { useSelector } from "react-redux";
import { renderToolTip } from "components/form";
import { AiOutlineDown } from "react-icons/ai";
import helper from "util/helper";

const useLanguageSelect = (extraClass = ""): any => {
    const intl = useIntl();
    const localLan = useSelector((state: RootState) => state.setting.lan);
    const display = useSelector((state: RootState) => state.store.storeDisplayOptions);

    const getAllowedLanguage = () => {
        const allowedLanguages = _.get(display, "product_display_languages", []);
        if (allowedLanguages.includes(localLan)) {
            return localLan;
        } else {
            return helper.getAlternativeLanguage(localLan);
        }
    };

    const [lan, setLan] = useState(getAllowedLanguage());

    useEffect(() => {
        if (!_.isEqual(lan, localLan)) {
            setLan(getAllowedLanguage());
        }
    }, [localLan]);

    const menu = (languages: any, key?: string) => {
        return (
            <Menu>
                <Menu.ItemGroup title={intl.formatMessage({ id: "localized" })}>
                    {languages.map((lang: any) => (
                        <Menu.Item key={`${lang}-${key}`} onClick={() => setLan(lang)}>
                            {intl.formatMessage({ id: lang ?? " " })}
                            {String(lang) === "en" ? (
                                <span style={{ color: "#9d9e9e", marginLeft: "25px" }}>
                                    {intl.formatMessage({ id: "primary" })}
                                </span>
                            ) : null}
                        </Menu.Item>
                    ))}
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const languageDropDown = (languages: any, key?: string) => {
        return (
            <Dropdown overlay={menu(languages, key)} placement="bottom" arrow>
                <Button
                    style={{
                        border: "none",
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: "#3898c8",
                    }}
                    size="small"
                >
                    {intl.formatMessage({ id: lan ?? "" })}
                    <AiOutlineDown size="14" style={{ marginLeft: "8px" }} />
                </Button>
            </Dropdown>
        );
    };

    // product detail - side floating language switch
    const getLanguageSelect = (key?: string) => {
        const languages = _.get(display, "product_display_languages", []);
        const lanExists = Array.isArray(languages);
        return lanExists ? (
            <div style={{ minWidth: "125px" }} className={"get-language-select " + extraClass}>
                <div className="language-select-title">
                    <FormattedMessage id="content_language" />
                </div>
                <div className="language-select">
                    <Dropdown overlay={menu(languages, key)} placement="bottom" arrow>
                        <Button
                            style={{
                                border: "none",
                                boxShadow: "none",
                                width: "90%",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: "#3898c8",
                            }}
                            size="small"
                        >
                            {intl.formatMessage({ id: lan ?? "" })}
                            <AiOutlineDown size="14" />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        ) : null;
    };

    // product list - short/description language switch
    const getModalLanguageSelect = (alignRight = false) => {
        const languages = _.get(display, "product_display_languages", []);
        const lanExists = Array.isArray(languages);
        return lanExists ? (
            <div className="d-flex" style={alignRight ? { alignSelf: "flex-end" } : {}}>
                {languageDropDown(languages)}
                {renderToolTip("language_select_tip")}
            </div>
        ) : null;
    };

    // product detail - title/descriptions language switch
    const getInputLanguageSelect = (child: any, { right = false } = {}, key?: string) => {
        const languages = _.get(display, "product_display_languages", []);
        const lanExists = Array.isArray(languages);
        return lanExists ? (
            <div className={right ? "input-lan-select-parent-right" : "input-lan-select-parent"}>
                {child}
                <div className="input-lan-select d-flex">
                    {languageDropDown(languages, key)}
                    {renderToolTip("language_select_tip")}
                </div>
            </div>
        ) : null;
    };
    return {
        lan,
        getLanguageSelect,
        getModalLanguageSelect,
        getInputLanguageSelect,
    };
};

export default useLanguageSelect;
