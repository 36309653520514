import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import { setState } from "../../../../slices/create-order";
import helper from "../../../../util/helper";
import PointsPickerPicker from "./points-picker-picker";
import { Modal, Button } from "antd";

class PointsPickerDialog extends React.Component<any, any> {
    getShouldOpen = (props = this.props) => {
        return props.state?.displayModalName === "points";
    };

    closeDialog = () => {
        this.props.setState({ displayModalName: "" });
    };

    getButtonData = () => {
        const confirmButton = {
            title: "ok",
            onClick: () => {
                this.props.setState({ pointsUsed: this.props.pointsDialogUsed });
                this.closeDialog();
            },
            type: "primary",
            className: "confirm-button",
        };
        const cancelButton = {
            title: "cancel",
            onClick: () => {
                this.props.setState({ pointsDialogUsed: this.props.pointsUsed });
                this.closeDialog();
            },
            className: "cancel-button",
        };
        const result = [cancelButton, confirmButton];
        return result;
    };

    renderDialogTitle = () => {
        return (
            <div className="payment-picker-dialog-header">
                <div className="payment-picker-dialog-innerHeader">
                    <div className="dialog-header-title-text">
                        <FormattedMessage
                            id="redeem_points_dialog_title"
                            values={{ x: helper.formatNumber(this.props.pointsBalance) }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderDialogContent = () => {
        return <PointsPickerPicker />;
    };

    renderDialogButtons = () => {
        return this.getButtonData().map((button) => (
            <Button {...button} key={`checkout-points-picker-dialog-button-${button.title}`}>
                <FormattedMessage id={button.title ? button.title : " "} />
            </Button>
        ));
    };

    render() {
        return (
            <Modal
                onCancel={() => {
                    this.props.setState({ pointsDialogUsed: this.props.pointsUsed });
                    this.closeDialog();
                }}
                footer={this.renderDialogButtons()}
                title={null}
                key="gift-card-picker-dialog"
                visible={this.getShouldOpen()}
            >
                <div className="gift-card-picker-dialog-container">
                    {this.renderDialogTitle()}
                    {this.renderDialogContent()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state.setting.lan,
    state: _.get(state, "createOrder", {}),
    pointsBalance: _.get(state, "createOrder.payment.pointsBalance", 0),
    pointsDialogUsed: _.get(state, "createOrder.pointsDialogUsed", 0),
    pointsUsed: _.get(state, "createOrder.pointsUsed", 0),
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PointsPickerDialog));
