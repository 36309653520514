import { combineReducers } from "@reduxjs/toolkit";
import { user } from "../slices/user";
import { setting } from "../slices/setting";
import { report } from "../slices/report";
import { storeSwitch } from "../slices/store-switch";
import { store } from "../slices/store";
import { pricePlan } from "../slices/price-plan";
import { pickupDelivery } from "../slices/pickup-delivery";
import { storeAvailability } from "../slices/store-availability";
import { publishManager } from "../slices/publish";
import { location } from "../slices/location";
import { categories } from "../slices/categories";
import { customers } from "../slices/customers";
import { products } from "../slices/products";
import { discounts } from "../slices/discounts";
import { managedStores } from "../slices/managed-stores";
import { admin } from "../slices/admin";
import { time } from "../slices/time";
import { menu } from "../slices/menu";
import { reviews } from "../slices/reviews";
import { adminUsers } from "../slices/admin-users";
import { liveOrder as liveOrders } from "../slices/live-order";
import { permission } from "../slices/permission";
import { giftcards } from "../slices/giftcards";
import { dashboard } from "../slices/dashboard";
import { ordersPage } from "../slices/order-history";
import { pages } from "../slices/pages";
import { referrals } from "../slices/referrals";
import { shipping } from "../slices/shipping";
import { ui } from "../slices/user-interface";
import { storeManagement } from "../slices/store-management";
import { createOrder } from "../slices/create-order";
import { quotes } from "../slices/quotes";
import { contentPages } from "../slices/content-pages";
import { shipmentTracking } from "../slices/shipment-tracking";
import { modal } from "../slices/app-modal";
import { deliveryRequests } from "slices/delivery_request";
import { partnerFee } from "../slices/partnerFee";
import { ppcpOnboard } from "slices/ppcpOnboard";

export const reducer = combineReducers({
    user,
    store,
    report,
    setting,
    reviews,
    location,
    customers,
    pricePlan,
    categories,
    liveOrders,
    storeSwitch,
    managedStores,
    pickupDelivery,
    storeAvailability,
    publishManager,
    permission,
    adminUsers,
    discounts,
    shipping,
    products,
    admin,
    menu,
    time,
    giftcards,
    dashboard,
    "orders-page": ordersPage,
    pages,
    referrals,
    ui,
    storeManagement,
    createOrder,
    quotes,
    contentPages,
    shipmentTracking,
    modal,
    deliveryRequests,
    partnerFee,
    ppcpOnboard,
});

export type RootState = ReturnType<typeof reducer>;
