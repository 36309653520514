import { Col, Collapse, Row, Statistic, Table, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { ViewControls } from "../../../../slices/dashboard";
import helper, { formatCurrency } from "../../../../util/helper";
import config from "../../../../config";

const selector = (state: RootState) => ({
    parameters: state?.dashboard?.parameters,
    data: state?.dashboard?.data,
    lan: state?.setting?.lan,
});

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = {[key in TypeKey]: string}
const currencySymbols: Type = config.CURRENCY_SYMBOL;

function DashboardData(): JSX.Element {
    const intl = useIntl();
    const { parameters, data, lan } = useSelector(selector);
    const [salesTable, setSalesTable] = useState<string>("top_items_by_sale");
    const currency: TypeKey = useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const getSalesAndOrderCollapse = () => {
        const show = parameters?.view_control?.includes(ViewControls?.OrderSales);
        return show ? (
            <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 14 }} expandIconPosition="right">
                <Collapse.Panel header={intl.formatMessage({ id: "sales_and_orders" })} key="1">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "total_amount" })}
                                value={data?.order_sales?.summary?.total_amount}
                                precision={2}
                                prefix={currencySymbols[currency]}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "total_orders" })}
                                value={data?.order_sales?.summary?.total_orders}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "average_order" })}
                                value={data?.order_sales?.summary?.avg_order}
                                precision={2}
                                prefix={config.CURRENCY_SYMBOL[currency]}
                            />
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        ) : null;
    };

    const getCustomerDataCollapse = () => {
        const show = parameters?.view_control?.includes(ViewControls?.Customers);
        return show ? (
            <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 14 }} expandIconPosition="right">
                <Collapse.Panel header={intl.formatMessage({ id: "customer_statistics" })} key="1">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "total_customers" })}
                                value={data?.customers_data?.ttl_customers}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "new_customers" })}
                                value={data?.customers_data?.new_customers}
                            />
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={intl.formatMessage({ id: "ret_customers" })}
                                value={data?.customers_data?.ret_customers}
                            />
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        ) : null;
    };

    const getItemSellingDataCollapse = () => {
        const show = parameters?.view_control?.includes(ViewControls?.ItemSales || ViewControls?.LeastSaleItems);
        const columns: ColumnsType<any> = [
            {
                title: intl.formatMessage({ id: "name" }),
                key: "name",
                render: (value, record) => helper.getTransString(record?.name, lan),
            },
            {
                title: intl.formatMessage({ id: "price" }),
                key: "price",
                render: (value, record) => formatCurrency(record?.price, currency),
            },
            { title: intl.formatMessage({ id: "sold" }), key: "quantity", render: (value, record) => record?.quantity },
        ];
        const onClick = (table: string) => {
            setSalesTable(table);
        };
        const buttons = ["top_items_by_sale", "least_items_by_sale"];

        const leastItemsSaleWithKey = [];
        if (data?.least_sale_items) {
            for (let i = 0; data?.least_sale_items.length > i; i++) {
                leastItemsSaleWithKey.push({ key: i, ...data?.least_sale_items[i] });
            }
        }
        const itemsSaleWithKey = [];
        if (data?.item_sales) {
            for (let i = 0; data?.item_sales.length > i; i++) {
                itemsSaleWithKey.push({ key: i, ...data?.item_sales[i] });
            }
        }

        return show ? (
            <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                <Collapse.Panel header={intl.formatMessage({ id: "item_sales" })} key="1" className="top-items-table">
                    <div className="text-left">
                        <Button.Group>
                            {buttons.map((btn: any, i: number) => (
                                <Button
                                    key={i}
                                    onClick={() => onClick(btn)}
                                    type={salesTable?.includes(btn) ? "primary" : "default"}
                                >
                                    {intl.formatMessage({ id: btn })}
                                </Button>
                            ))}
                        </Button.Group>
                    </div>
                    <Table
                        dataSource={salesTable?.includes("least") ? leastItemsSaleWithKey : itemsSaleWithKey}
                        columns={columns}
                        pagination={false}
                        rowKey="key"
                    />
                </Collapse.Panel>
            </Collapse>
        ) : null;
    };

    const getReferralsDataCollapse = () => {
        const show = parameters?.view_control?.includes(ViewControls?.Referrals);
        return show ? (
            <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 14 }} expandIconPosition="right">
                <Collapse.Panel header={intl.formatMessage({ id: "referrals" })} key="1">
                    <Row gutter={16}>
                        <Col span={6}>
                            <Statistic
                                title={intl.formatMessage({ id: "total_referrals" })}
                                value={data?.referrals_data?.ttl_referrals ?? 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={intl.formatMessage({ id: "new_referrals" })}
                                value={data?.referrals_data?.new_referrals ?? 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={intl.formatMessage({ id: "new_referral_orders" })}
                                value={data?.referrals_data?.new_orders ?? 0}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic
                                title={intl.formatMessage({ id: "average_referral_orders" })}
                                value={data?.referrals_data?.avg_orders ?? 0}
                            />
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        ) : null;
    };

    return (
        <div className="dashboard-data">
            {getSalesAndOrderCollapse()}
            {getCustomerDataCollapse()}
            {getReferralsDataCollapse()}
            {getItemSellingDataCollapse()}
        </div>
    );
}

export default DashboardData;
