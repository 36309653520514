import { RootState } from "app/reducer";
import _ from "lodash";
import { CalendarSpec } from "moment";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import errorIMG from "../../../../images/icons/box-important.png";
import {
    MessageModalPages,
    NewTasksPath,
    setCurrentMessageModalPage,
    setMessageModalCallback,
    setNewTaskFormState,
    setUpdateForm,
    updateOnActiveTask,
} from "../../../../slices/delivery_request";
import { AnyStyle, TIME_PICKER_DATE_FORMAT, TIME_PICKER_STATUS } from "../../constants";
import "../../style.scss";
import {
    buildUpdatedOnActivedTask,
    formatDateTimeStringToObject,
    formatPrice,
    getNestedValueInObject,
    getTime,
} from "../helper";

const PopUpMessageModalLayout: React.FC<PropsFromRedux> = ({
    newTask,
    currPage,
    errMessage,
    onActiveTask,
    deliveryFeeValue,
    totalDelieryFee,
    additionalInfo,
    inputSelections,
    setNewTaskFormState,
    setMessageModalCallback,
    setCurrentMessageModalPage,
    isStoreOpen,
    storeInformation,
    updateOnActiveTask,
    setUpdateForm,
    // setShouldMessageModalOpen,
}) => {
    type TimeObject = {
        date: string;
        time: string;
    };
    const intl = useIntl();
    const { timesLot } = inputSelections;
    const { pickUp, dropOff } = newTask;
    const isEdit =
        currPage === MessageModalPages.PICKUP_TIME_PICKER_EDIT ||
        currPage === MessageModalPages.DROPOFF_TIME_PICKER_EDIT;

    const handleUpdateTime = (key: NewTasksPath, value: string, currState: TimeObject) => {
        const buildValue = (): string => {
            if (key.includes("time")) {
                if (value?.toLowerCase?.()?.includes?.("asap")) return "2000-01-01 00:00:00";
                return `${currState.date} ${value}`;
            } else {
                return `${value} ${currState.time}`;
            }
        };

        const v = buildValue();
        setUpdateForm({ key, value });
        const updatedTask = buildUpdatedOnActivedTask(onActiveTask, key ?? "", v);
        if (updatedTask) updateOnActiveTask(updatedTask);
    };

    const handleOnKeyClick = (
        key: NewTasksPath,
        restKey: any,
        value: string,
        rest: TimeObject,
        currState: TimeObject
    ) => {
        const isASAP = value.toLowerCase().includes("asap");
        const isRestTimeEmprt = _.isEmpty(rest.time);
        const isRestTimeASAP = rest.time.toLowerCase().includes("asap");
        const isDate = key.match(".date");
        if (!isASAP && !isRestTimeEmprt && !isRestTimeASAP && !isDate) {
            if (isEdit) {
                handleUpdateTime(key, value, currState);
            } else {
                setCurrentMessageModalPage(MessageModalPages.MULTIPLE_TIME_WRRNING_DROPOFF);
                setMessageModalCallback(() => {
                    setNewTaskFormState({ key, value });
                    setNewTaskFormState({ key: (restKey + ".date") as NewTasksPath, value: "" });
                    setNewTaskFormState({ key: (restKey + ".time") as NewTasksPath, value: isStoreOpen ? "ASAP" : "" });
                });
            }
        } else {
            if (isEdit) {
                handleUpdateTime(key, value, currState);
            } else {
                setNewTaskFormState({
                    key,
                    value,
                });
            }
        }
    };

    const renderCancelFailureBody = () => {
        return (
            <div className="cancel-failure-body">
                <div className="cancel-failure-subtitle-body">
                    <img
                        src={errorIMG}
                        alt="picture"
                        style={{ maxWidth: "35x", maxHeight: "35px", marginRight: "13px", marginLeft: "-10px" }}
                    />
                    <div>
                        <FormattedMessage id="cancel_failure_subtitle_one" /> <br />
                        <span className="cancel-failure-phone-number">855-599-7099</span>
                        <FormattedMessage id="cancel_failure_subtitle_two" />
                    </div>
                </div>
                <div className="cancel-failure-list-title">
                    <FormattedMessage id="cancel_failure_list_title" />
                </div>
                <div className="cancel-failure-list">
                    <ul>
                        <li>
                            <FormattedMessage id="cancel_failure_doordash_id" />
                            <span className="cancel-failure-list-point">{onActiveTask?.delivery_id}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_customer_name" />
                            <span className="cancel-failure-list-point">{`${onActiveTask?.customer.firstname} ${onActiveTask?.customer.lastname}`}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_customer_phone_number" />
                            <span className="cancel-failure-list-point">{onActiveTask?.customer.phone}</span>
                        </li>
                        <li>
                            <FormattedMessage id="cancel_failure_store_name" />
                            <span className="cancel-failure-list-point">{storeInformation?.store_nm_en ?? ""}</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const renderDeliveryFeeDetailBody = (): React.ReactNode => {
        const _labelStyle = { color: "grey", fontWeight: "500", fontSize: "18px" };
        const _valueStyle = { fontWeight: "bold", fontSize: "20px" };
        const _DriverStyleDotted = { borderBottom: "1px dotted grey" };
        const _DriverStyleSolid = { borderBottom: "1px solid grey" };
        interface LayoutProps {
            label: string;
            isRow?: boolean;
            isDriver?: boolean;
            value?: string | number;
            valueStyle?: AnyStyle;
            labelStyle?: AnyStyle;
            DriverStyle?: AnyStyle;
        }

        const _LAYOUT: LayoutProps[] = [
            {
                label: "Deliver Fee Total",
                isRow: false,
                valueStyle: { fontWeight: "bold", fontSize: "26px", color: "#40a9ff" },
                value: `${formatPrice(
                    getNestedValueInObject({ totalDelieryFee }, intl.formatMessage({ id: "TBD" }), "totalDelieryFee")
                )}`,
            },
            {
                label: "Delivery Fee",
                isRow: true,
                value: `${formatPrice(
                    getNestedValueInObject({ deliveryFeeValue }, intl.formatMessage({ id: "TBD" }), "deliveryFeeValue")
                )}`,
            },
            {
                label: "Tax(GST+PST)",
                isRow: true,
                value: `${formatPrice(
                    getNestedValueInObject(additionalInfo, intl.formatMessage({ id: "TBD" }), "orderTax")
                )}`,
            },
            {
                label: "Tips",
                isRow: true,
                value: `${formatPrice(
                    getNestedValueInObject(additionalInfo, intl.formatMessage({ id: "TBD" }), "tipAmount")
                )}`,
            },
            {
                isDriver: true,
                label: "driver1",
                DriverStyle: _DriverStyleDotted,
            },
            {
                label: "Total Fee",
                isRow: true,
                value: `${formatPrice(
                    getNestedValueInObject({ totalDelieryFee }, intl.formatMessage({ id: "TBD" }), "totalDelieryFee")
                )}`,
            },
            {
                label: "Balance",
                value: `${formatPrice(
                    getNestedValueInObject(newTask, intl.formatMessage({ id: "TBD" }), "feeBalance")
                )}`,
                isRow: true,
            },
            {
                isDriver: true,
                label: "driver2",
                DriverStyle: _DriverStyleSolid,
            },
            {
                label: "Estimated Pick Up Time",
                isRow: false,
                value: getNestedValueInObject(newTask, intl.formatMessage({ id: "TBD" }), "estPickUpTime"),
            },
            {
                label: "driver3",
                isDriver: true,
                DriverStyle: _DriverStyleSolid,
            },
            {
                label: "Estimated Delivery Time",
                isRow: false,
                value: getNestedValueInObject(newTask, intl.formatMessage({ id: "TBD" }), "estDropOffTime"),
            },
        ];

        const getRowStyle = (isRow: boolean | undefined): React.CSSProperties => {
            return isRow
                ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                  }
                : {
                      width: "100%",
                      textAlign: "center",
                  };
        };

        return (
            <div>
                {_LAYOUT.map((row, i) => {
                    const value = Number(deliveryFeeValue) <= 0 ? "TBD" : row.value;
                    return (
                        <div key={row.label + i} style={{ ...getRowStyle(row.isRow), padding: "4px 8px" }}>
                            {row.isDriver ? (
                                <div style={row.DriverStyle} />
                            ) : (
                                <>
                                    <div style={row.labelStyle ?? _labelStyle}>{row.label}</div>
                                    <div style={row.valueStyle ?? _valueStyle}>{value}</div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const getTimeState = (isEdit: boolean, isPickup: boolean): [any, any] => {
        if (isEdit) {
            const pickUp = formatDateTimeStringToObject(
                onActiveTask?.pickup_window_start_time
                // onActiveTask?.pickup_window_end_time
            );
            const dropOff = formatDateTimeStringToObject(
                onActiveTask?.delivery_window_start_time
                // onActiveTask?.delivery_window_end_time
            );
            if (isPickup) {
                return [pickUp, dropOff];
            } else {
                return [dropOff, pickUp];
            }
        } else {
            if (isPickup) {
                return [pickUp, dropOff];
            } else {
                return [dropOff, pickUp];
            }
        }
    };

    const renderTimePicker = (): React.ReactNode => {
        const isPickup =
            currPage === MessageModalPages.PICKUP_TIME_PICKER || currPage === MessageModalPages.PICKUP_TIME_PICKER_EDIT;
        const keyPrefix = isPickup ? "pickUp" : "drop_off";
        const restKeyPrefix = !isPickup ? "pickUp" : "drop_off";

        const [currState, restState] = getTimeState(isEdit, isPickup);
        const values = _.isEmpty(timesLot[currState.date]) ? Object.values(timesLot)[0] : timesLot[currState.date];

        const renderBody = (
            values: string[],
            key: NewTasksPath,
            restKeyPrefix: string,
            currVal: string,
            dateTimeFormat?: CalendarSpec,
            showStatus?: boolean,
            showSelected?: boolean,
            showArrow?: boolean
        ): React.ReactNode => {
            return (
                <div style={{ width: "50%", padding: "4px", maxHeight: "400px", overflow: "auto" }}>
                    {values?.map((v, i) => {
                        const selected = currVal === v;
                        const active = selected || (_.isEmpty(currVal) && i === 0);
                        return (
                            <div
                                key={v + i}
                                className={"timepicker"}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "8px",
                                    margin: "8px 0px",
                                    cursor: "pointer",
                                    alignItems: "end",
                                    backgroundColor: "#F9F9F9",
                                }}
                                onClick={() => {
                                    if (_.isEmpty(currState.date) && !isEdit) {
                                        const [f] = Object.keys(timesLot);
                                        setNewTaskFormState({
                                            key: (keyPrefix + ".date") as unknown as NewTasksPath,
                                            value: f,
                                        });
                                    }
                                    handleOnKeyClick(key, restKeyPrefix, v, restState, currState);
                                }}
                            >
                                <div>
                                    <div style={{ fontSize: "10px", fontWeight: "bold" }}>
                                        {showStatus && getTime(v, TIME_PICKER_STATUS)}
                                    </div>
                                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                                        {!_.isEmpty(dateTimeFormat) && dateTimeFormat ? getTime(v, dateTimeFormat) : v}
                                    </div>
                                </div>
                                <div>
                                    {selected && showSelected && (
                                        <TiTick style={{ width: "20px", height: "20px", color: "green" }} />
                                    )}
                                    {active && showArrow && (
                                        <IoIosArrowForward style={{ width: "20px", height: "20px" }} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        };

        return (
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
            >
                {renderBody(
                    Object.keys(timesLot),
                    (keyPrefix + ".date") as NewTasksPath,
                    restKeyPrefix,
                    currState.date,
                    TIME_PICKER_DATE_FORMAT,
                    true,
                    false,
                    true
                )}
                {renderBody(
                    values,
                    (keyPrefix + ".time") as NewTasksPath,
                    restKeyPrefix,
                    currState.time,
                    undefined,
                    false,
                    true,
                    false
                )}
            </div>
        );
    };

    const renderLayout = (): React.ReactNode => {
        switch (currPage) {
            case MessageModalPages.PICKUP_TIME_PICKER:
            case MessageModalPages.DROPOFF_TIME_PICKER:
            case MessageModalPages.PICKUP_TIME_PICKER_EDIT:
            case MessageModalPages.DROPOFF_TIME_PICKER_EDIT:
                return renderTimePicker();
            case MessageModalPages.UPDATE_DELIVERY_TASK_SUCESS:
                return (
                    <span>
                        <FormattedMessage id="update_success" />
                    </span>
                );
            case MessageModalPages.CANCELL_DELIVERY_TASK_ERR:
                return renderCancelFailureBody();
            case MessageModalPages.INSUFFICIENT_FUND_ERR:
                return (
                    <span>
                        {intl.formatMessage({ id: "insufficient_fund_title" })}
                        {intl.formatMessage({ id: "insufficient_fund_msg" })}
                        {intl.formatMessage({ id: "support_number" })}
                    </span>
                );
            case MessageModalPages.EXCEED_DELIVERY_DISTANCE_ERR:
                return <span>{intl.formatMessage({ id: "distance_error_msg" })}</span>;
            case MessageModalPages.UPDATE_DELIVERY_TASK_ERR:
            case MessageModalPages.FAILED_TO_CREATE_DELIVERY_TASK_ERR:
                return (
                    <div>
                        <span>{intl.formatMessage({ id: "error" })}: </span>
                        <span>{errMessage}</span>
                    </div>
                );
            case MessageModalPages.GET_DELIVERY_FEE_ERR:
                return (
                    <div>
                        <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "get_delivery_fee_err" })}</span>
                        <span>{errMessage}</span>
                    </div>
                );
            case MessageModalPages.DISCARD_UPDATE:
                return <div>{intl.formatMessage({ id: "discard_change_msg" })}</div>;
            case MessageModalPages.ADD_NEW_ADDRESS_ERR:
                return <span>{intl.formatMessage({ id: "new_address_error_msg" })}</span>;
            case MessageModalPages.DISCARD_NEW_TASK:
                return <div>{intl.formatMessage({ id: "cancel_create_task_msg" })}</div>;
            case MessageModalPages.MULTIPLE_TIME_WRRNING_DROPOFF:
                return (
                    <div>
                        {intl.formatMessage({
                            id: isStoreOpen ? "change_pickup_time_warning_msg" : "remove_pickup_time_warning_msg",
                        })}
                    </div>
                );
            case MessageModalPages.MULTIPLE_TIME_WRRNING_PICKUP:
                return (
                    <div>
                        {intl.formatMessage({
                            id: isStoreOpen ? "change_dropoff_time_warning_msg" : "remove_dropoff_time_warning_msg",
                        })}
                    </div>
                );
            case MessageModalPages.TRACK_DELIVERY_TASK_ERR:
                return <div>{intl.formatMessage({ id: "tracking_url_not_found" })}</div>;
            case MessageModalPages.CANCEL_DELIVERY_TASK_CONFIRM:
                return <div>{intl.formatMessage({ id: "cancel_confirmation_msg" })}</div>;
            case MessageModalPages.UPDATE_ADDRESS_CUSTOMER_ID_ERR:
                return <div>{errMessage}</div>;
            case MessageModalPages.TRACK_DELIVERY_TASK:
                return (
                    <iframe
                        src={onActiveTask?.delivery_tracking_url}
                        style={{ width: "100%", height: "750px", overflow: "auto" }}
                    />
                );
            case MessageModalPages.DELIVERY_FEE_DETAIL:
                return renderDeliveryFeeDetailBody();
            case MessageModalPages.NOT_AUTHORIZED_DELIVERY:
                return <div>{intl.formatMessage({ id: "not_authorized_delivery_msg" })}</div>;
            default:
                return <div>{intl.formatMessage({ id: "unexpected_error_msg" })}</div>;
        }
    };

    return <>{renderLayout()}</>;
};

const mapStateToProps = (state: RootState) => {
    const messageModalState = state.deliveryRequests.modal.messageModal;
    const newTask = state.deliveryRequests.forms.newTask;
    const deliveryFeeValue = newTask.deliveryFee;
    const additionalInfo = newTask.additionalInfo;
    const { orderTax, tipAmount } = additionalInfo;
    const totalDelieryFee = Number(orderTax) + Number(deliveryFeeValue) + Number(tipAmount);

    return {
        currPage: messageModalState.currPage,
        errMessage: messageModalState.errorMessage,
        onActiveTask: state.deliveryRequests.onActiveTask,
        newTask,
        deliveryFeeValue,
        additionalInfo,
        totalDelieryFee,
        inputSelections: state.deliveryRequests.forms.inputSelections,
        isStoreOpen: state.deliveryRequests.isStoreOpen,
        storeInformation: state.store.storeInformation ?? {},
    };
};

const mapDispatchToProps = {
    setNewTaskFormState,
    setMessageModalCallback,
    setCurrentMessageModalPage,
    // setShouldMessageModalOpen,
    updateOnActiveTask,
    setUpdateForm,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(PopUpMessageModalLayout));
