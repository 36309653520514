import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import qs from "querystring";
import { toast } from "react-toastify";
import {
    clearFormState,
    clearOnActiveTask,
    clearSearchParameters,
    clearUpdateTaskForm,
    MessageModalPages,
    setCurrentMessageModalPage,
    setCurrentModalPage,
    setDeliveryTasks,
    setErrorMessage,
    setIsLoading,
    setNewTaskFormState,
    setOnActiveTask,
    setShouldMessageModalOpen,
    setShouldModalOpen,
    setShouldRefresh,
    setTimesLotOptions,
    setUpdateForm,
    StatusesSearchKey,
    TaskModalPages,
} from "slices/delivery_request";
import { DeliveryTasksRes, Task } from "types/get-delivery-task-respone";
import { RootState } from "../app/reducer";
import { store } from "../app/store";
import { createMessage } from "../components/message";
import config from "../config";
import { getIntl } from "../locale";
import { buildOnActiveTaskState, getMostRecentTime } from "../pages/delivery_request/_components/helper";
import { getAllCustomersSuccess } from "../slices/customers";
import { AutoComplete } from "../types/autocomplete-res";
import { CreateDeliveryTaskRes } from "../types/create-delivery-task-res";
import { GeoCode } from "../types/geocode-res";
import { OrderIDGenerator } from "../types/order-id-generator-respone";
import { oauth } from "../util/api";
import routes from "../util/api/routes";
import { AddBalanceResponse } from "types/add-balance-res";

/**
 * constants
 */
export enum ACTIONS_MAPPING {
    CREATE_WITH_GOOPTER_ORDER = 1,
    GET_ESTIMATE = 2,
    GET_STATE = 3,
    CANCELL_DELIVERY = 4,
    UPDATE_DELIVERY = 5,
    MAKE_PAYMENT = 6,
    DEPOSIT_BALANCE = 7,
    REFUND_BALANCE = 8,
    VALIDATE_CREDENTIALS = 9,
    GET_DELIVERY_LIST = 10,
    CREATE_WITHOUT_GOOPTER_ORDER = 11,
    GET_DELIVERY_TASK_ESTIMATION_WITHOUT_GOOPTER_ORDER_ID = 12,
}

const intl = getIntl();

export const DOORDASH_API_URL_ID = "DOORDASH_API_URL";

export const ORDER_ID_GENERATOR_URL_ID = "ORDER_ID_GENERATOR";

export const DELIVERY_TIMESLOT_URL_ID = "DELIVERY_TIMESLOT";

export const ADMIN_PAGE_CUSTOMER_ADDRESS_URL_ID = "ADMIN_CUSTOMER_ADDRESS";

export const ADMIN_CUSTOMER_ADDRESS = "GET_CUSTOMER_ADDRESS";

export const GEOCODE_PROXY_URL_ID = "GEOCODE_PROXY";

export const AUTOCOMPLETE_PROXY_URL_ID = "AUTOCOMPLETE_PROXY";
/**
 * Types
 */

export type requsetType = {
    body: requestBodyType;
    currOnActiveTask: Task | null;
};

export enum requestTaskType {
    GOOPTER = 1,
    WITHOUT_GOOPTER = 2,
}

interface requestBodyType extends deliveryTask {
    page: number;
    limit: number;
    sort?: Record<string, any>;
    quick_search?: string;
    statuses?: StatusesSearchKey[] | number[];
    courier_name?: string;
    created_start_dt?: string;
    created_end_dt?: string;
    assigned_start_dt?: string;
    assigned_end_dt?: string;
    delivered_start_dt?: string;
    delivered_end_dt?: string;
}

export interface CreateDeliveryParam extends deliveryTask {
    request_type: requestTaskType;
    order_number?: string;
    pickup_address_id?: string;
    dropoff_address_id?: string;
    pickup_window_start_time?: string;
    delivery_window_start_time?: string;
    pickup_window_end_time?: string;
    delivery_window_end_time?: string;
    order_value?: number | null;
    tip?: number;
    item_category?: string;
    contains_alcohol?: 0 | 1;
    items?: any[];
    tax?: number;
}

export interface CancelTaskRequest extends deliveryTask {
    delivery_id: string;
    order_number: string;
}

export interface UpdateTaskRequest extends deliveryTask {
    delivery_id: string;
    order_number: string;
    pickup_address_id?: string;
    dropoff_address_id?: string;
    pickup_window_start_time?: string;
    delivery_window_start_time?: string;
    order_value?: number;
    tip?: number;
    item_category?: string;
    contains_alcohol?: 0 | 1;
    items?: any[];
    tax?: number;
    sub_total?: number;
}

export interface UpdateAddressRequest {
    data: {
        id?: string | number; // provide address_id if the request is to update the address. If not provided, the request would create a new address
        customer_id?: string | number; //if the customer id is provided, then should verify this id, avoid creating customer record;
        firstname?: string;
        lastname?: string;
        phone?: string | number;
        country_code?: string | number;
        email?: string;
        unit?: string;
        buzz?: string | number;
        city?: string;
        state?: string;
        street?: string;
        zip_code?: string | number;
        delivery_option?: string | null;
        delivery_instructions?: string;
        business_name?: string;
        region?: string;
    };
}

export interface CreateAddressRequest {
    req: {
        id?: string;
        firstname: string;
        lastname: string;
        phone: string;
        country_code: string;
        region: string;
        email: string;
        city: string;
        street: string;
        zip_code: string;
        buzz?: string;
        unit?: string;
        delivery_option?: string;
        delivery_instructions?: string;
        business_name?: string;
    };
    currPage: TaskModalPages;
}

export interface GetDeliveryFee extends deliveryTask {
    pickup_address_id?: string;
    dropoff_address_id?: string;
    order_value?: number;
    pickup_window_start_time?: string;
    pickup_window_end_time?: string;
    delivery_window_start_time?: string;
    delivery_window_end_time?: string;
}

export interface GeoCodeProxy {
    address: string;
    callback?: (res: GeoCode["response"]["results"] | false) => void;
}

export interface AutocompleteProxy {
    input: string;
    location: [number, number];
    radius: number;
    callback?: (result: AutoComplete["response"]["predictions"] | false) => void;
}

export interface AddBalanceRequest extends deliveryTask{
    amount: number;
}

interface deliveryTask {
    action: ACTIONS_MAPPING;
}

export const getDeliveryTasks =
    (req: requsetType) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<DeliveryTasksRes | null> => {
        dispatch(setIsLoading(true));

        try {
            const { body, currOnActiveTask } = req;
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: body }),
            });
            const tasks = _.get(response, "records", []);
            const paginations = {
                total: _.get(response, "paging.total", 0),
                summaryByStatus: {
                    new_pending: _.get(response, "paging.summary_by_status.new_pending", 0),
                    ongoing: _.get(response, "paging.summary_by_status.ongoing", 0),
                    delivered: _.get(response, "paging.summary_by_status.delivered", 0),
                    cancelled: _.get(response, "paging.summary_by_status.cancelled", 0),
                },
                delivery_fee_total: _.get(response, "paging.summary_by_status.delivery_fee_total", 0),
                tips_total: _.get(response, "paging.summary_by_status.tips_total", 0),
                tax_total: _.get(response, "paging.summary_by_status.tax_total", 0),
                fee_total: _.get(response, "paging.summary_by_status.fee_total", 0),
                balance: _.get(response, "paging.summary_by_status.balance", 0),
            };
            dispatch(setDeliveryTasks({ tasks, paginations }));

            if (currOnActiveTask) {
                const redux = store.getState() as unknown as RootState;
                const storeInformation = redux.store.storeInformation ?? {};
                const onActiveTask = tasks.find((t: Task) => t.order_number == currOnActiveTask.order_number) ?? null;
                if (onActiveTask) dispatch(setOnActiveTask(buildOnActiveTaskState(onActiveTask, storeInformation)));
                else dispatch(clearOnActiveTask());
            }

            dispatch(setIsLoading(false));

            return response;
        } catch (error) {
            dispatch(setIsLoading(false));
            return null;
        }
    };

export const generateOrderId =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<OrderIDGenerator | null> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(ORDER_ID_GENERATOR_URL_ID)({
                method: "GET",
            });

            const orderId = _.get(response, "records.order_id", "");
            if (!_.isEmpty(orderId) && response?.RC === 200) {
                const globalState = store.getState() as unknown as RootState;
                const currTaskPage = globalState.deliveryRequests.modal.newTaskModal.currentPage;
                if (currTaskPage === TaskModalPages.EDIT_ORDER_ID) {
                    dispatch(setUpdateForm({ key: "orderId", value: orderId }));
                } else dispatch(setNewTaskFormState({ key: "orderId", value: orderId }));
            }
            dispatch(setIsLoading(false));
            return response;
        } catch (error) {
            dispatch(setIsLoading(false));
            return null;
        }
    };

export const getDeliveryTimeslot =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));

        try {
            const response = await oauth(DELIVERY_TIMESLOT_URL_ID)({
                method: "GET",
            });

            const options = _.get(response, "records", {});

            const globalState = store.getState() as unknown as RootState;

            if (!_.isEmpty(options)) {
                const { isOpen, ...recent } = getMostRecentTime(options);
                dispatch(setTimesLotOptions({ timesLot: options, isStoreOpen: isOpen }));
                const { date: currDate, time: currTime } = globalState.deliveryRequests.forms.newTask.dropOff;
                if (_.isEmpty(currDate) && _.isEmpty(currTime)) {
                    dispatch(setNewTaskFormState({ key: "pickUp", value: { ...recent } }));
                }
            }
        } catch (e) {
            console.error(e);
        }

        dispatch(setIsLoading(false));
    };

export const createDeliveryTask =
    (param: CreateDeliveryParam) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param }),
            });
            dispatch(handleCreateDeliveryTaskResponse(response));
        } catch (e) {
            console.error(e);
        }
        dispatch(setIsLoading(false));
    };

const handleCreateDeliveryTaskResponse =
    (res: CreateDeliveryTaskRes) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const { RC, records } = res;
            switch (RC) {
                case 200:
                    toast(
                        createMessage(
                            `${intl.formatMessage(
                                {
                                    id: "delivery_task_created",
                                },
                                {
                                    order_id: records?.order_number,
                                }
                            )}`
                        )
                    );
                    dispatch(setShouldRefresh(true));
                    dispatch(setShouldModalOpen(false));
                    dispatch(clearFormState("newTask"));
                    dispatch(clearFormState("validation"));
                    dispatch(clearSearchParameters());
                    break;
                case 400:
                case 411:
                case 413:
                    dispatch(setCurrentMessageModalPage(MessageModalPages.FAILED_TO_CREATE_DELIVERY_TASK_ERR));
                    dispatch(setErrorMessage(_.get(res, "message", "")));
                    break;
                case 456:
                    dispatch(setCurrentMessageModalPage(MessageModalPages.INSUFFICIENT_FUND_ERR));
                    break;
                case 410:
                    dispatch(setCurrentMessageModalPage(MessageModalPages.EXCEED_DELIVERY_DISTANCE_ERR));
                    break;
                default:
                    dispatch(setCurrentMessageModalPage(MessageModalPages.UNEXPECTED_ERROR));
                    break;
            }
            dispatch(setIsLoading(false));
            if (RC !== 200) dispatch(setShouldMessageModalOpen(true));
        } catch (e) {
            dispatch(setErrorMessage(String(e)));
            dispatch(setCurrentMessageModalPage(MessageModalPages.UNEXPECTED_ERROR));
            dispatch(setShouldMessageModalOpen(true));
            dispatch(setIsLoading(false));
        }
    };
const handleAddBalanceRespone 
    = (res: AddBalanceResponse) => 
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const { RC, records } = res;
            switch (RC) {
                case 200:
                    toast(
                        createMessage(
                            `${intl.formatMessage(
                                {
                                    id: "balance_updated",
                                },
                                {
                                    balance: records?.balance,
                                }
                            )}`
                        )
                    );
                    dispatch(setShouldRefresh(true));
                    dispatch(setShouldModalOpen(false));
                    dispatch(clearFormState("balanceInfo"));
                    dispatch(clearSearchParameters());
            }
        } catch (e) {
            dispatch(setErrorMessage(String(e)));
            dispatch(setCurrentMessageModalPage(MessageModalPages.UNEXPECTED_ERROR));
            dispatch(setShouldMessageModalOpen(true));
            dispatch(setIsLoading(false))
        }
    };

export const cancelDeliveryTask =
    (param: CancelTaskRequest) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param }),
            });

            if (response?.RC === 200) {
                toast(
                    createMessage(
                        `${intl.formatMessage(
                            {
                                id: "succeeded_msg",
                            },
                            {
                                msg_type: intl.formatMessage({ id: "delivery_cancelled" }, { id: param.order_number }),
                            }
                        )}`
                    )
                );
                dispatch(setShouldRefresh(true));
                dispatch(setShouldMessageModalOpen(false));
            } else {
                dispatch(setCurrentMessageModalPage(MessageModalPages.CANCELL_DELIVERY_TASK_ERR));
                dispatch(setShouldMessageModalOpen(true));
                dispatch(setErrorMessage(_.get(response, "message", "")));
            }
        } catch (e) {
            dispatch(setCurrentMessageModalPage(MessageModalPages.CANCELL_DELIVERY_TASK_ERR));
            dispatch(setErrorMessage(String(e)));
            dispatch(setShouldMessageModalOpen(true));
        }
        dispatch(setIsLoading(false));
    };

export const updateDeliveryTask =
    (param: UpdateTaskRequest) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setIsLoading(true));

        try {
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param }),
            });

            if (response?.RC === 200) {
                dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_DELIVERY_TASK_SUCESS));
                dispatch(clearUpdateTaskForm());
                dispatch(setShouldRefresh(true));
            } else {
                dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_DELIVERY_TASK_ERR));
                dispatch(setErrorMessage(_.get(response, "message", "")));
            }
        } catch (e) {
            dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_DELIVERY_TASK_ERR));
            dispatch(setErrorMessage(String(e)));
        }
        dispatch(setShouldMessageModalOpen(true));
        dispatch(setIsLoading(false));
    };

export const addBalanceToDeliveryTask  =
    (param: AddBalanceRequest) => 
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setIsLoading(true));

        try {
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param }),
            });
            dispatch(handleAddBalanceRespone(response));
        } catch (e) {
            console.error(e);
        }

        dispatch(setIsLoading(false));
    };

export const addNewCustomerAddress =
    (param: CreateAddressRequest) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(ADMIN_PAGE_CUSTOMER_ADDRESS_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param.req }),
            });

            if (response?.RC === 200) {
                toast(
                    createMessage(
                        `${intl.formatMessage(
                            {
                                id: "succeeded_msg",
                            },
                            {
                                msg_type:
                                    param.currPage === TaskModalPages.NEW_TASK_ADDRESS
                                        ? intl.formatMessage({ id: "create_address" })
                                        : intl.formatMessage({ id: "update_address" }),
                            }
                        )}`
                    )
                );
                const addressData = _.get(response, "records", {});
                const addressId = _.get(addressData, "id", "");
                dispatch(clearFormState("addressInfo"));
                dispatch(clearFormState("validation"));
                dispatch(getAllCustomersSuccess({ records: [addressData] }));
                dispatch(setNewTaskFormState({ key: "address", value: String(addressId) }));
                dispatch(setCurrentModalPage(TaskModalPages.NEW_TASK_MAIN));
            } else {
                dispatch(setCurrentMessageModalPage(MessageModalPages.ADD_NEW_ADDRESS_ERR));
                dispatch(setErrorMessage(_.get(response, "message", "")));
                dispatch(setShouldMessageModalOpen(true));
            }
        } catch (e) {
            dispatch(setCurrentMessageModalPage(MessageModalPages.ADD_NEW_ADDRESS_ERR));
            dispatch(setErrorMessage(String(e)));
            dispatch(setShouldMessageModalOpen(true));
        }
        dispatch(setIsLoading(false));
    };

export const updateDeliveryAddress =
    (param: UpdateAddressRequest) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(ADMIN_PAGE_CUSTOMER_ADDRESS_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param.data }),
            });

            if (response?.RC === 200) {
                dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_DELIVERY_TASK_SUCESS));
                dispatch(setShouldRefresh(true));
                dispatch(setShouldModalOpen(false));
                dispatch(clearFormState("validation"));
                dispatch(clearFormState("addressInfo"));
            } else {
                dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_ADDRESS_CUSTOMER_ID_ERR));
                dispatch(setErrorMessage(_.get(response, "message", "")));
            }
        } catch (e) {
            dispatch(setErrorMessage(String(e)));
            dispatch(setCurrentMessageModalPage(MessageModalPages.UPDATE_DELIVERY_TASK_ERR));
        }
        dispatch(setShouldMessageModalOpen(true));
        dispatch(setIsLoading(false));
    };

export const getDeliveryFeeEST =
    (param: GetDeliveryFee) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));
        try {
            const response = await oauth(DOORDASH_API_URL_ID)({
                method: "POST",
                body: JSON.stringify({ data: param }),
            });

            if (response?.RC === 200) {
                const records = response?.records ?? {};
                const { fee, fee_balance, pickup_time, delivery_time, tax } = records;
                dispatch(setNewTaskFormState({ key: "deliveryFee", value: fee }));
                dispatch(setNewTaskFormState({ key: "feeBalance", value: fee_balance }));
                dispatch(setNewTaskFormState({ key: "estPickUpTime", value: pickup_time }));
                dispatch(setNewTaskFormState({ key: "estDropOffTime", value: delivery_time }));
                dispatch(setNewTaskFormState({ key: "additionalInfo.orderTax", value: tax }));
            } else {
                if (response?.RC === 410) dispatch(setNewTaskFormState({ key: "address", value: "" }));
                dispatch(setCurrentMessageModalPage(MessageModalPages.GET_DELIVERY_FEE_ERR));
                dispatch(setErrorMessage(_.get(response, "message", "")));
                dispatch(setNewTaskFormState({ key: "deliveryFee", value: null }));
                dispatch(setNewTaskFormState({ key: "feeBalance", value: null }));
                dispatch(setNewTaskFormState({ key: "estPickUpTime", value: "" }));
                dispatch(setNewTaskFormState({ key: "estDropOffTime", value: "" }));
                dispatch(setShouldMessageModalOpen(true));
            }
        } catch (e) {
            dispatch(setCurrentMessageModalPage(MessageModalPages.GET_DELIVERY_FEE_ERR));
            dispatch(setErrorMessage(String(e)));
            dispatch(setShouldMessageModalOpen(true));
        }
        dispatch(setIsLoading(false));
    };

export const getMessageModalWidth = (currPage: MessageModalPages): number | undefined => {
    switch (currPage) {
        case MessageModalPages.TRACK_DELIVERY_TASK:
            return 1000;
        case MessageModalPages.DELIVERY_FEE_DETAIL:
            return 400;
        default:
            return undefined;
    }
};

export const geoCodeProxy =
    (params: GeoCodeProxy) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));

        const { callback, ...rest } = params;
        try {
            const url = `${config.API_ROOT}${routes[GEOCODE_PROXY_URL_ID]}?${qs.stringify({
                ...rest,
                key: config.GOOGLE_MAP_KET,
            })}`;

            const res = await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            const data = (await res.json()) as GeoCode;

            callback?.(data?.response?.results);
        } catch (e) {
            callback?.(false);
        }
        dispatch(setIsLoading(false));
    };

export const autocompleteProxy =
    (params: AutocompleteProxy) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<void> => {
        dispatch(setIsLoading(true));

        const { callback, ...rest } = params;
        try {
            const url = `${config.API_ROOT}${routes[AUTOCOMPLETE_PROXY_URL_ID]}?${qs.stringify({
                ...rest,
                key: config.GOOGLE_MAP_KET,
            })}`;

            const res = await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            const data = (await res.json()) as AutoComplete;

            callback?.(data.response.predictions);
        } catch (e) {
            callback?.(false);
        }
        dispatch(setIsLoading(false));
    };

