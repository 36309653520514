import React, { useState } from "react";
import { Radio } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setSelfServeState } from "../../../../slices/store";
import { RootState } from "../../../../app/reducer";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import globalHelper, { isBool } from "../../../../util/helper";
import Format1 from "./poster-formats/format1";
import { FORMATS } from "./poster-formats";
import { BlobProvider } from "@react-pdf/renderer";
import "./generate-print.scss";
import "./format-modal.scss";

function PosterModal(props: any = {}): JSX.Element {
    const dispatch = useDispatch();
    const selfServe = useSelector((state: RootState) => state.store?.storeSelfServe);
    const storeURL = useSelector((state: RootState) => state.store.records?.general_info?.url);
    const phone = useSelector((state: RootState) => state.store?.storeInformation?.phone);
    const storeLogo = useSelector((state: RootState) => state.store.records["logo-png"]);

    const setCustomText = useSelector((state: RootState) => state.store?.setCustomText);

    const showDownloadModal = _.get(selfServe, "showDownloadModal", false);
    const [selectedFormat, setSelectedFormat] = useState("format1");
    const g_id = _.get(selfServe, "store_id", "");
    const { lan } = useLanguageSelect();
    const label = _.get(props, "label");
    const exclude = _.get(props, "exclude");
    const bgColor = _.get(props, "bgColor");
    const paperSize = _.get(props, "paperSize");
    const showRewards = _.get(props, "showRewards");
    const addMargin = _.get(props, "addMargin");
    const instructions = _.get(props, "instructions");
    const dataURLS = _.get(props, "dataURLS", []);
    const qrStyle = _.get(props, "qrStyle", []);
    const codeType = _.get(props, "codeType", []);
    const bleedingMargin = _.get(props, "bleedingMargin", []);
    const customPhone = _.get(props, "customPhone", []);
    const customDiscount = _.get(props, "customDiscount", []);
    const customOrderLabel = _.get(props, "customOrderLabel", []);
    const customBottomLabel = _.get(props, "customBottomLabel", []);
    const paymentMethodsChecked = _.get(props, "paymentMethodsChecked", []);

    const setState = (name: string, value: any) => dispatch(setSelfServeState({ name, value }));

    const renderDownloadPDFButton = () => {
        const format = FORMATS.find((format) => selectedFormat === format.value);
        const props = {
            g_id,
            label,
            exclude,
            phone,
            storeURL,
            storeName: _.get(selfServe, `store_name_${lan}`, ""),
            showTable: isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num),
            showInstructions: instructions,
            dataURLS,
            storeLogo: globalHelper.getImage(storeLogo),
            bgColor,
            paperSize,
            showRewards,
            addMargin,
            bleedingMargin,
            qrStyle,
            customDiscount,
            customPhone,
            customOrderLabel,
            customBottomLabel,
            paymentMethodsChecked,
        };
        const getDocument = () => {
            const Cmp = format ? format.component : Format1;
            //@ts-ignore
            return <Cmp {...props} />;
        };
        const resetOnDownload = () => {
            setState("showDownloadModal", false);
        };
        return (
            <BlobProvider document={getDocument()}>
                {({ url }) => {
                    if (url) {
                        window.open(url != null ? url : "");
                        resetOnDownload();
                    } else {
                        return null;
                    }
                    return null;
                }}
            </BlobProvider>
        );
    };

    const renderFormatOption = (value: any, format: any, info: any, key: number) => {
        const props = {
            g_id,
            label,
            exclude,
            phone,
            storeURL,
            storeName: _.get(selfServe, `store_name_${lan}`, ""),
            showTable: isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num),
            showInstructions: instructions,
            dataURLS,
            logoImage: globalHelper.getImage(storeLogo),
            bgColor,
            showRewards,
            qrStyle,
            codeType,
            customDiscount,
            customPhone,
            customOrderLabel,
            customBottomLabel,
            paymentMethodsChecked,
        };

        return (
            <div className="formatOption-container d-flex mb-4" onClick={() => setSelectedFormat(value)} key={key}>
                <Radio className="format-option-radio" checked={selectedFormat === value} />
                {format(props)}
            </div>
        );
    };

    const optionFilter = () => {
        return true;
    };

    const renderFormatOptions = () =>
        !setCustomText
            ? FORMATS.filter(() => optionFilter()).map((format, key) =>
                  renderFormatOption(format.value, format.renderer, format, key)
              )
            : null;

    const renderFormatSection = () => (
        <div className="format-modal">
            <div className="format-options-container d-flex">{renderFormatOptions()}</div>
        </div>
    );

    const renderPrintModal = <div>{showDownloadModal ? renderDownloadPDFButton() : null}</div>;

    return (
        <React.Fragment>
            {renderFormatSection()}

            {renderPrintModal}
        </React.Fragment>
    );
}

export default PosterModal;
