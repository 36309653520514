import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { setState } from "slices/create-order";
import _ from "lodash";
import { Row, Col, Button } from "antd";
import { IoMdArrowDropdown, IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import actions from "slices/live-order";
import { IconContext } from "react-icons/lib";
import { Badge, withStyles } from "@material-ui/core";
import { getCategoryName } from "../../../helper";

const MAX_CATEGORIES_PER_ROW = 6;
const NUM_OF_ROWS = 2;
export const CATEGORIES_ID = "quick-add-categories";
export const CATEGORY_BUTTON_ID = "quick-add-category-button-";

const StyledBadge = withStyles(() => ({
    badge: {
        right: 7,
        top: 2,
        backgroundColor: "#ed2d29",
        border: `2px solid white`,
        padding: "8px 2px",
        color: "white",
    },
}))(Badge);

class CategoriesSection extends Component<any, any> {
    state = { current_section: 0 };

    renderLeftControllers = () => {
        return (
            <div className="categories-action-row">
                <div className="categories-action-left" />
                <div
                    style={{ visibility: this.state.current_section > 0 ? "visible" : "hidden" }}
                    onClick={() => {
                        if (this.state.current_section > 0)
                            this.setState({ current_section: this.state.current_section - 1 });
                    }}
                    className="categories-action-left"
                >
                    <IconContext.Provider value={{ size: "3em" }}>
                        <IoMdArrowDropleft />
                    </IconContext.Provider>
                </div>
            </div>
        );
    };

    getNumberOfAddedItemsInCategory = (id: string) => {
        let count = 0;
        const items = Array.isArray(this.props.items) ? this.props.items : [];
        items.forEach((item: any) => {
            if (item.ctids?.includes(id) || item.cids?.includes(id)) count += item.qty;
        });

        return count;
    };

    renderCategoryButton = (category: any) => {
        const catItemCnt = this.getNumberOfAddedItemsInCategory(category.id);
        return (
            <StyledBadge key={category.cid} badgeContent={catItemCnt}>
                <Button
                    id={`${CATEGORY_BUTTON_ID}${category.id}`}
                    key={category.id}
                    onClick={() => {
                        const conditions = _.cloneDeep(this.props.searchCondition);
                        conditions.cids = category.id;
                        conditions.page = 0;
                        conditions.reset = true;
                        conditions.cachedProducts = this.props.cachedProducts;
                        this.props.getProducts(conditions);
                        this.props.setParentState({ catId: category.id });
                    }}
                    size="large"
                    className="categories-button"
                    danger
                    type={this.props.currentCat === category?.id ? "primary" : "default"}
                >
                    <div className="categories-name-wrapper">
                        <div className="categories-name">{getCategoryName(category, this.props.lan)}</div>
                    </div>
                </Button>
            </StyledBadge>
        );
    };

    formatCategories = () => {
        // reformat categories to 6 categories per row
        const categories = Array.isArray(this.props.categories) ? this.props.categories : [];
        const reformattedCategories: any = [];

        let temp: any = [];
        for (let i = 0, j = 0; i < categories.length; i++) {
            j = i % MAX_CATEGORIES_PER_ROW;
            temp.push(categories[i]);
            if (j === MAX_CATEGORIES_PER_ROW - 1) {
                reformattedCategories.push(temp);
                temp = [];
            }
        }

        // Add incomplete row
        if (categories.length % MAX_CATEGORIES_PER_ROW !== 0) {
            reformattedCategories.push(temp);
        }

        let catRow;
        temp = [];
        const renderedCategorySection = [];
        let renderedCategoryRow;
        // Group sections into 2 rows of 6 categories
        for (let i = 0; i < reformattedCategories.length; i++) {
            catRow = reformattedCategories[i];
            renderedCategoryRow = [];

            for (let j = 0; j < catRow.length; j++) {
                renderedCategoryRow.push(
                    <Col key={`category-col-${j}`} span={4}>
                        {this.renderCategoryButton(catRow[j])}
                    </Col>
                );
            }

            temp.push(renderedCategoryRow);

            if (i !== 0 && (i + 1) % NUM_OF_ROWS === 0) {
                renderedCategorySection.push(
                    <Col span={24}>
                        <Row gutter={[4, 4]}>{temp}</Row>
                    </Col>
                );

                temp = [];
            }
        }

        // Add the section if there are 2 or less rows, or an odd number of rows
        if (reformattedCategories.length <= NUM_OF_ROWS || reformattedCategories.length % NUM_OF_ROWS !== 0) {
            renderedCategorySection.push(
                <Col span={24}>
                    <Row gutter={[4, 4]}>{temp}</Row>
                </Col>
            );
        }

        return renderedCategorySection;
    };

    renderCategories = (section: number) => {
        const renderedCategorySection = this.formatCategories();
        return <div className="quick-add-categories">{renderedCategorySection[section]}</div>;
    };

    renderRightControllers = () => {
        const hasNextCategory =
            this.state.current_section + 1 < this.props.categories.length / (MAX_CATEGORIES_PER_ROW * NUM_OF_ROWS);
        return (
            <div className="categories-action-row">
                <div
                    onClick={() => {
                        this.props.setParentState({ showCategoryDrawer: true });
                    }}
                    className="categories-action-right"
                >
                    <IconContext.Provider value={{ size: "3em" }}>
                        <IoMdArrowDropdown />
                    </IconContext.Provider>
                </div>
                <div
                    style={{ visibility: hasNextCategory ? "visible" : "hidden" }}
                    onClick={() => {
                        if (hasNextCategory) {
                            this.setState({ current_section: this.state.current_section + 1 });
                        }
                    }}
                    className="categories-action-right"
                >
                    <IconContext.Provider value={{ size: "3em" }}>
                        <IoMdArrowDropright />
                    </IconContext.Provider>
                </div>
            </div>
        );
    };

    render() {
        return (
            <Row className="quick-add-categories-row">
                <Col span={1.5}>{this.renderLeftControllers()}</Col>
                <Col span={21}>{this.renderCategories(this.state.current_section)}</Col>
                <Col span={1.5}>{this.renderRightControllers()}</Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    products: _.get(state, "liveOrders.products", []),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    cachedProducts: _.get(state, "liveOrders.cachedProducts", {}),
});

const mapDispatchToProps = {
    setState,
    getProducts: actions.getProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategoriesSection)));
