import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, Checkbox, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { STATUS_DISABLED, STATUS_ENABLED } from "../../constants";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import config from "../../../../config";
import { RootState } from "../../../../app/reducer";
import ObjectModel from "../../../../util/models";
import StoreProduct from "../../../../util/models/store-product";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../../app/constants";
import { setProductState } from "../../../../slices/products";

const DEFAULT_DUPLICATE = ["price", "stock", "images", "video", "options", "related_products", "upsell_products"];

const selector = (state: RootState) => ({
    product: state?.products?.product,
    duplicate: state?.products?.duplicate,
});

function useProductListDuplicate(): any {
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { lan: selectedLan, getInputLanguageSelect } = useLanguageSelect();

    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateState, setDuplicateState] = useState<any>({});
    const [selectedToDuplicate, setSelectedToDuplicate] = useState<any[]>(DEFAULT_DUPLICATE);

    const { product, duplicate } = useSelector(selector);

    const onDuplicateChange = (key: string, value: any) => {
        const newDuplicateState = _.cloneDeep(duplicateState);
        newDuplicateState[key] = value;
        setDuplicateState(newDuplicateState);
    };

    const duplicateOptions = DEFAULT_DUPLICATE.map((value) => ({
        value,
        label: intl.formatMessage({ id: value ?? " " }),
    }));

    const getValues = useCallback(() => {
        const v: any = new ObjectModel(StoreProduct, product).getData();

        const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];

        dks.forEach((dk) => {
            if (dk === "start_date") {
                v[dk] = moment(v[dk] ?? new Date(), DEFAULT_DATE_FORMAT);
            } else if (dk === "end_date") {
                v[dk] = moment(v[dk] ?? new Date(), DEFAULT_DATE_FORMAT).add(10, "days");
            } else {
                v[dk] = v[dk] ? moment(v[dk] ?? new Date(), DEFAULT_DATE_FORMAT) : "";
            }
        });

        return Object.assign({}, v);
    }, [product]);

    const onDuplicateProduct = useCallback(() => {
        const v = _.cloneDeep(getValues());
        v.status = STATUS_DISABLED;
        setDuplicateState(v);
        setDuplicateModalOpen(true);
    }, [getValues]);

    const onDuplicateProductOk = () => {
        const addEditItem = _.cloneDeep(duplicateState);

        DEFAULT_DUPLICATE.forEach((duplicate) => {
            if (!selectedToDuplicate.includes(duplicate)) {
                delete addEditItem[duplicate];
            }
        });
        delete addEditItem.product_id;
        delete addEditItem.sku;
        dispatch(setProductState({ product: {}, duplicate: false, addEditItemDuplicate: addEditItem }));
        // @ts-ignore
        history.push("/products/addEditItem?duplicate=1");
    };

    const closeDuplicateProductModal = () => {
        setDuplicateModalOpen(false);
        dispatch(setProductState({ product: {}, duplicate: false }));
    };

    const getDuplicateTitle = () => (
        <>
            {getInputLanguageSelect(
                config.LANGUAGES.map((l) => (
                    <div key={l} style={{ display: l !== selectedLan ? "none" : "block" }}>
                        <div className="duplicate-label">
                            <FormattedMessage id="item_title" />
                        </div>
                        <div className="duplicate-content">
                            <Input
                                value={duplicateState?.["name_" + l]}
                                onChange={(e) => onDuplicateChange("name_" + l, e.target.value)}
                                style={{ width: "100%" }}
                                placeholder={intl.formatMessage({ id: "item_title" })}
                            />
                        </div>
                    </div>
                ))
            )}
        </>
    );

    const getSelectToDuplicate = () => (
        <>
            <div className="duplicate-label">
                <FormattedMessage id="select_details_to_copy" />
            </div>
            <div className="duplicate-content">
                <Checkbox.Group
                    className="duplicate-checkbox-group"
                    value={selectedToDuplicate}
                    onChange={setSelectedToDuplicate}
                    options={duplicateOptions}
                />
            </div>
        </>
    );

    const getStatus = () => (
        <>
            <div className="duplicate-label">
                <FormattedMessage id="status" />
            </div>
            <div className="duplicate-content">
                <Switch
                    checked={duplicateState?.status === STATUS_ENABLED}
                    onChange={() => {
                        onDuplicateChange(
                            "status",
                            duplicateState?.status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED
                        );
                    }}
                />
            </div>
        </>
    );

    const getDuplicateModal = () => {
        return (
            <Modal
                visible={duplicateModalOpen}
                title={intl.formatMessage({ id: "duplicate" })}
                onOk={onDuplicateProductOk}
                onCancel={closeDuplicateProductModal}
            >
                {getDuplicateTitle()}
                {getSelectToDuplicate()}
                {getStatus()}
            </Modal>
        );
    };

    useEffect(() => {
        if (!_.isEmpty(product) && duplicate) {
            onDuplicateProduct();
        }
    }, [product, duplicate, onDuplicateProduct]);

    return {
        duplicateModalOpen,
        onDuplicateProductOk,
        closeDuplicateProductModal,
        duplicateState,
        onDuplicateChange,
        duplicateOptions,
        selectedToDuplicate,
        setSelectedToDuplicate,
        onDuplicateProduct,
        getDuplicateModal,
    };
}

export default useProductListDuplicate;
