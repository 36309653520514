import Wrapper from "components/wrapper";
import { Spin, Descriptions, Form, Button } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDescriptionItem } from "components/form";
import { renderSelectInput, renderNumberInput, getTextInput } from "components/form";
import {
    isSuperUser,
    getP2VRatio,
    getV2PRatio,
    getMinPointsRedemptionValueAmount,
    getMaxPointsRedemptionType,
    getMaxPointsRedemptionValuePerOrder,
} from "util/helper";
import config from "config";
import { FormattedMessage } from "react-intl";
import "./styles.scss";
import { updateStoreDetails } from "services/store";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/rewardPoints", breadcrumbName: "reward_points_settings" },
    ],
};

const REWARD_POINTS_SETTINGS_FIELDS = [
    { name: "points_to_value_ratio", index: config.STORE_FLAG_INDEX_MAP.p2v },
    { name: "value_to_points_ratio", index: config.STORE_FLAG_INDEX_MAP.v2p },
    {
        name: "min_points_redemption_amount",
        index: config.STORE_FLAG_INDEX_MAP.tips_min_points_redemption_value_amount,
    },
    { name: "max_points_redemption_type", index: config.STORE_FLAG_INDEX_MAP.tips_max_points_redemption_type },
    {
        name: "max_points_redemption_per_order",
        index: config.STORE_FLAG_INDEX_MAP.tips_max_points_redemption_value_per_order,
    },
];

const MAX_REDEMPTION_PER_ORDER_TIP_TEXT = {
    "no_limit": 0,
    "max_redemption_percentage_per_order_tip": 1,
    "max_redemption_amount_per_order_tip": 2,
};

const MAX_REDEMPTION_PER_ORDER_TIP_NUM = {
    0: "no_limit",
    1: "max_redemption_percentage_per_order_tip",
    2: "max_redemption_amount_per_order_tip",
};

interface RewardPointsSettingsStateProps {
    lan: string;
    store: any;
    user: any;
}

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = { [key in TypeKey]: string };
const currencySymbols: Type = config.CURRENCY_SYMBOL;

interface RewardPointsSettingsProps extends RewardPointsSettingsStateProps, PropsFromRedux {}

const RewardPointsSettings = (props: RewardPointsSettingsProps) => {
    const [form] = Form.useForm();
    const [touched, setTouched] = useState(false);
    const { store, user, updateStoreDetails } = props;
    const [maxRedemptionPerOrderTip, setMaxRedemptionPerOrderTip] = useState("");
    const [maxRedemptionPerOrderLabel, setMaxRedemptionPerOrderLabel] = useState("max_points_redemption_per_order");
    const [maxPointsRedemptionType, setMaxPointsRedemptionType] = useState(0);
    const [minPointsRedemptionAmount, setMinPointsRedemptionAmount] = useState(0);
    const [maxPointsRedemptionAmount, setMaxPointsRedemptionAmount] = useState(0);
    const [warningMessage, setWarningMessage] = useState("");
    const showWarn =
        maxPointsRedemptionType === config.MAXIMUM_POINTS_REDEMPTION_TYPE_OPTIONS.dollar_value &&
        maxPointsRedemptionAmount < minPointsRedemptionAmount;
    const currency: TypeKey =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";

    const REDEMPTION_TYPE_OPTIONS = [
        {
            label: <FormattedMessage id="no_limit" />,
            value: config.MAXIMUM_POINTS_REDEMPTION_TYPE_OPTIONS.no_limit,
        },
        {
            label: <FormattedMessage id="percentage_of_order" />,
            value: config.MAXIMUM_POINTS_REDEMPTION_TYPE_OPTIONS.percentage_of_order,
        },
        {
            label: <FormattedMessage id="dollar_value" />,
            value: config.MAXIMUM_POINTS_REDEMPTION_TYPE_OPTIONS.dollar_value,
        },
    ];

    useEffect(() => {
        setupInitialValues();
        setMaxPointsRedemptionType(form.getFieldValue("max_points_redemption_type"));
        setMaxPointsRedemptionAmount(form.getFieldValue("max_points_redemption_per_order"));
        setMinPointsRedemptionAmount(form.getFieldValue("min_points_redemption_amount"));
    }, []);

    const setupWarningMessage = () => {
        let message = "";
        if (showWarn) {
            message = "max_redemption_warn";
        }
        setWarningMessage(message);
    };

    useEffect(() => {
        setupWarningMessage();
    }, [maxPointsRedemptionType, minPointsRedemptionAmount, maxPointsRedemptionAmount]);

    const setupInitialValues = () => {
        const maxPointsRedemptionType = getMaxPointsRedemptionType(store);
        const initialValues = [
            { name: "points_to_value_ratio", value: getP2VRatio(store) },
            { name: "value_to_points_ratio", value: getV2PRatio(store) },
            { name: "min_points_redemption_amount", value: getMinPointsRedemptionValueAmount(store) },
            { name: "max_points_redemption_type", value: maxPointsRedemptionType },
            { name: "max_points_redemption_per_order", value: getMaxPointsRedemptionValuePerOrder(store) },
        ];

        setMaxRedemptionPerOrderTip(
            MAX_REDEMPTION_PER_ORDER_TIP_NUM[maxPointsRedemptionType as keyof typeof MAX_REDEMPTION_PER_ORDER_TIP_NUM]
        );

        form.setFields(initialValues);
    };

    const setMaxRedemptionPerOrderTipState = (optionValue: number) => {
        switch (optionValue) {
            case MAX_REDEMPTION_PER_ORDER_TIP_TEXT.max_redemption_percentage_per_order_tip:
                setMaxRedemptionPerOrderTip("max_redemption_percentage_per_order_tip");
                break;
            case MAX_REDEMPTION_PER_ORDER_TIP_TEXT.max_redemption_amount_per_order_tip:
                setMaxRedemptionPerOrderTip("max_redemption_amount_per_order_tip");
                break;
            default:
                setMaxRedemptionPerOrderTip("no_limit");
        }
    };

    const setMaxRedemptionPerOrderLabelState = (optionValue: number) => {
        switch (optionValue) {
            case MAX_REDEMPTION_PER_ORDER_TIP_TEXT.max_redemption_percentage_per_order_tip:
                setMaxRedemptionPerOrderLabel("max_redemption_percentage_per_order");
                break;
            case MAX_REDEMPTION_PER_ORDER_TIP_TEXT.max_redemption_amount_per_order_tip:
                setMaxRedemptionPerOrderLabel("max_redemption_amount_per_order");
                break;
            default:
                setMaxRedemptionPerOrderLabel("max_points_redemption_per_order");
                break;
        }
    };

    const handleValuesChanged = (values: any) => {
        setTouched(form.isFieldsTouched());

        if ("max_points_redemption_type" in values) {
            setMaxPointsRedemptionType(values["max_points_redemption_type"]);
            setMaxRedemptionPerOrderTipState(values["max_points_redemption_type"]);
            setMaxRedemptionPerOrderLabelState(values["max_points_redemption_type"]);
        } else if ("min_points_redemption_amount" in values) {
            setMinPointsRedemptionAmount(values["min_points_redemption_amount"]);
        } else if ("max_points_redemption_per_order" in values) {
            setMaxPointsRedemptionAmount(values["max_points_redemption_per_order"]);
        }

        setupWarningMessage();
    };

    const handleSaveChanges = (data: any) => {
        const storeFlags = store.store_flg.split(",");
        REWARD_POINTS_SETTINGS_FIELDS.forEach((field) => {
            if (storeFlags.length > field.index) {
                storeFlags[field.index] = data[field.name];
            }
        });
        updateStoreDetails({ store_flg: storeFlags.join(",") });
        setTouched(false);
    };
    return (
        <Wrapper
            helmet={{ title: "reward_points_settings" }}
            breadcrumb={breadcrumb}
            description={"reward_points_settings_description"}
        >
            <Spin spinning={false}>
                <Form
                    className="reward-points-settings-form"
                    form={form}
                    name="reward-points-settings-form"
                    onFinish={!showWarn ? handleSaveChanges : undefined}
                    onValuesChange={handleValuesChanged}
                >
                    <Descriptions bordered>
                        {getDescriptionItem(
                            "points_to_value_ratio",
                            3,
                            renderNumberInput({ name: "points_to_value_ratio", disabled: !isSuperUser(user) }),
                            "points_to_value_ratio_tip"
                        )}
                        {getDescriptionItem(
                            "value_to_points_ratio",
                            3,
                            renderNumberInput({
                                name: "value_to_points_ratio",
                                disabled: !isSuperUser(user),
                                min: 1,
                                max: 999,
                            }),
                            "value_to_points_ratio_tip"
                        )}
                        {getDescriptionItem(
                            "min_points_redemption_amount",
                            3,
                            renderNumberInput({ name: "min_points_redemption_amount", disabled: !isSuperUser(user) }),
                            "min_points_redemption_amount_tip"
                        )}
                        {getDescriptionItem(
                            "max_points_redemption_type",
                            3,
                            renderSelectInput({
                                name: "max_points_redemption_type",
                                options: REDEMPTION_TYPE_OPTIONS,
                                disabled: !isSuperUser(user),
                            }),
                            "max_points_redemption_type_tip"
                        )}
                        {getDescriptionItem(
                            maxRedemptionPerOrderLabel,
                            3,
                            getTextInput({
                                name: "max_points_redemption_per_order",
                                disabled: !isSuperUser(user),
                                suffix: maxPointsRedemptionType === 1 ? "%" : undefined,
                                prefix: maxPointsRedemptionType === 2 ? currencySymbols[currency] : undefined,
                            }),
                            maxRedemptionPerOrderTip,
                            warningMessage
                        )}
                    </Descriptions>
                    <div className="footer-buttons-container">
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </Spin>
        </Wrapper>
    );
};

const mapStateToProps = (state: RootState): RewardPointsSettingsStateProps => ({
    lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
    store: _.get(state, "store.records", {}),
    user: _.get(state, "user", {}),
});

const mapDispatchToProps = {
    updateStoreDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RewardPointsSettings);
