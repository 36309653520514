import React, { Component } from "react";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import ListItem from "./order-list-item";
import { LIVE_ORDER_CARD_ID_PREFIX } from "../../helper";
import helper from "util/helper";
import Collapse from "@material-ui/core/Collapse";
import _actions from "../../../../slices/live-order";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

class OrderListGroup extends Component {
    state = { showList: true };

    str = (id, values) => this.props.intl.formatMessage({ id: id ? id : " " }, values);

    getKey = () => this.props.categoryKey;

    getListData = () => (this.props.listData ? this.props.listData : {});

    renderGroupTitle = (title, list) => {
        const show = this.state.showList;
        return (
            <div onClick={() => this.setState({ showList: !show })} className="draw-list-group-title-wrapper">
                <div className="draw-list-group-place-holder" />
                <div className="draw-list-group-title">
                    <FormattedMessage id={title ? title : " "} />
                    &nbsp;(<span>{list.length}</span>)
                </div>
                <div className="draw-list-group-icon">{show ? <DownOutlined /> : <RightOutlined />}</div>
            </div>
        );
    };

    renderListItem = (order) => {
        return <ListItem key={`${LIVE_ORDER_CARD_ID_PREFIX}${_.get(order, "ord_id")}`} order={order} />;
    };

    render() {
        const key = this.getKey();
        let label = key;
        if (key === "serve") {
            const storeFlags = _.get(this.props.storeRecords, "store_flg", "");
            label = helper.isRestaurantFromFlags(storeFlags) ? "serve_restaurant" : "serve_others";
        }
        const title = `category_${label}`;
        const list = Array.isArray(this.getListData()[key]) ? this.getListData()[key] : [];
        return (
            <div key={key}>
                {this.renderGroupTitle(title, list)}
                <Collapse in={this.state.showList}>{list.map((order) => this.renderListItem(order))}</Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListGroup));
