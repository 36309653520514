import React, { Component } from "react";
import _ from "lodash";
import { Modal, Button } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import SurchargeInput from "./tips-input";
import CommentInput from "./comment-input";
import actions from "../../../../slices/live-order";
import { withRouter } from "react-router-dom";

class SurchargeDialog extends Component {
    shouldShow = () => {
        return _.get(this.props, "state.showTipsDialog", false);
    };

    closeDialog = () => {
        this.props.setState({
            showTipsDialog: false,
            tips: 0,
            tipsComment: "",
        });
    };

    onConfirmClick = (remove) => {
        const updateData = {
            data: {
                "order_id": this.getOrderId(),
                "action": "7",
                "amt_tips": remove ? 0 : this.getTips(),
                "comment": this.getComment(),
            },
            page: this.getPage(),
        };
        this.props.updateLiveOrderPayment(updateData);
        this.closeDialog();
    };

    getPage = () => {
        return _.get(this.props, "state.orderListPaging.page", 1);
    };

    onCloseClick = () => {
        this.closeDialog();
    };

    getFormLabelProps = () => {
        return {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            labelAlign: "left",
        };
    };

    getOrderId = () => {
        return _.get(this.props, "orderId", "");
    };

    getTips = () => {
        return _.get(this.props, "state.tips", 0) || _.get(this.props, "tips", 0);
    };

    getComment = () => {
        return _.get(this.props, "state.tipsComment", "");
    };

    getFooter = () => {
        const buttons = [
            {
                label: "cancel",
                onClick: () => this.onCloseClick(),
                disabled: () => {
                    return false;
                },
                type: "default",
            },
            {
                label: "submit",
                onClick: () => this.onConfirmClick(),
                disabled: () => {
                    const enable = this.getTips();
                    return !enable;
                },
                type: "primary",
            },
        ];
        if (_.get(this.props, "tips", 0)) {
            buttons.splice(1, 0, {
                label: "remove_tips",
                onClick: () => {
                    const str = (id) => this.props.intl.formatMessage({ id });
                    const onConfirmClick = () => this.onConfirmClick(true);
                    Modal.confirm({
                        title: str("warning"),
                        content: str("remove_surcharge_confirm_message"),
                        onOk() {
                            onConfirmClick(true);
                        },
                        okText: str("yes"),
                        cancelText: str("no"),
                    });
                },
                disabled: () => {
                    return false;
                },
                type: "danger",
            });
        }
        return buttons.map((btn, i) => {
            return (
                <Button
                    type={btn.type}
                    disabled={btn.disabled()}
                    key={`surcharge-dialog-button-${i}`}
                    onClick={() => btn.onClick()}
                >
                    <FormattedMessage id={btn.label} />
                </Button>
            );
        });
    };

    getSharedProps = () => {
        return {
            form: this.getFormLabelProps(),
            ...this.props,
        };
    };

    render() {
        return (
            <div>
                <Modal
                    visible={this.shouldShow()}
                    title={<FormattedMessage id="order_surcharge" />}
                    onCancel={() => this.onCloseClick()}
                    footer={this.getFooter()}
                >
                    <SurchargeInput {...this.getSharedProps()} tips={this.getTips()} />
                    <CommentInput {...this.getSharedProps()} comment={this.getComment()} />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    updateOrderPayment: actions.updateOrderPayment,
    updateLiveOrderPayment: actions.updateOrder,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SurchargeDialog)));
