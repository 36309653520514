import _ from "lodash";

export default class LiveOrder {
    constructor(orderDetail) {
        orderDetail = Object.assign({}, {}, orderDetail);
        this.initData(orderDetail);
    }

    initData(order) {
        this.data = {
            ...order,
            "ord_id": order["ord_id"],
            "status": order["ord_st"],
            "g_id": order["g_id"],
            "c_id": order["c_id"],
            "c_fnm": _.get(order, "shipping_adr.fn", ""),
            "c_lnm": _.get(order, "shipping_adr.ln", ""),
            "c_full_name": this.getFullName(order),
            "shipping_adr": order["shipping_adr"],
            "c_tn": _.get(order, "shipping_adr.tn", ""),
            "cpn_cd": order["cpn_cd"],
            "tips": order["tips"],
            // prices info
            "sb_ttl": order["sb_ttl"],
            "ttl_due": order["ttl_due"],
            "tax": order["tax"],
            "store_surcharge": _.get(order, "store_surcharge.value", 0),
            "store_surcharge_label": _.get(order, "store_surcharge.label", {}),
            "order_surcharge": _.get(order, "order_surcharge.value", 0),
            "order_surcharge_label": _.get(order, "order_surcharge.label", {}),
            "order_surcharge_in_total": _.get(order, "order_surcharge.is_separate", 0) === 0,
            "related_order_id": _.get(order, "related_order_id", null),
            "item_ttl": order["item_ttl"],
            "ttl_before_tax": order["ttl_b4_tax"],
            "ttl_pd": order["ttl_pd"],
            "ttl_ord": order["ord_ttl"],
            "currency": order["currency"],
            "gc_amt": order["gc_amt"],
            "pt_amt": order["points_amt"],
            "pt_num": order["points_num"],
            "gc_cd": order["gc_cd"],
            "rf_amt": order["refund_amt"],
            "dy_fee": order["dy_fee"],
            "store_dis": order["store_dis"],
            "cpn_dis": order["cpn_dis"],
            "ttl_dis": order["dis_ttl"],
            "rwd_pts": order["rwd_points"],
            "est_dt": order["est_dt"],
            "exp_dt_start": order["exp_dt_start"],
            "exp_dt_end": order["exp_dt_end"],
            "ord_dt": order["ord_dt"],
            "pay_mtd": order["pay_mtd"],
            "shp_mtd": order["shp_mtd"],
            "tb_num": order["tb_num"],
            "pt_cnt": order["party_size"],
            "sv_nm": order["server_name"],
            "ord_itm": order["ord_itm"],
            "cmt_list": order["comments"],
            "taxes": order["taxes"],
        };
    }

    getFullName = (order) => {
        const firstName = _.get(order, "shipping_adr.fn", "");
        const lastName = _.get(order, "shipping_adr.ln", "");
        if (firstName && lastName) {
            return firstName + " " + lastName;
        }
        if (firstName) {
            return firstName;
        }
        if (lastName) {
            return lastName;
        }
        return "";
    };
}
