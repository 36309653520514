import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    deliveryTimeSlots: [],
    editId: null,
    isLoading: false,
};

const slice = createSlice({
    name: "pickupDelivery",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getDeliveryTimeSlotsSuccess: (state, { payload }) => {
            state.deliveryTimeSlots = payload;
        },
        setEditId: (state, { payload }) => {
            state.editId = payload;
        },
    },
});

export const { setState, setEditId, getDeliveryTimeSlotsSuccess } = slice.actions;

export const pickupDelivery = slice.reducer;
