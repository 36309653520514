import { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getIntl } from "locale";
import cx from "classnames";

/**
 * renders CopyIcon component
 *
 * @param copyData - primitive data type that is copied into clipboard
 * @param messageId - refer to the message key in locale files so the tooltip is translated
 * @param show - optional boolean; default to true if not declared in component initialization; used to toggle the visibility style
 * @returns <Tooltip><CopyOutlined/><Tooltip />
 *
 */
function CopyIcon(props: any = {}): JSX.Element {
    const { copyData = " ", messageId = " " } = props;
    let show;
    "show" in props ? (show = props.show) : (show = true); //if the show prop was not in the component

    const str = (id: string, values?: any) => getIntl().formatMessage({ id }, values);

    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [tooltipText, setTooltipText] = useState<string>(str("copy_what", { what: str(messageId) }));

    const handleCopy = (text: string, messageId: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setTooltipText(str("copied_to_clipboard_success", { what: str(messageId) }));
            setCopySuccess(true);
        });
    };

    const onCopyClick = (e: any) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        handleCopy(copyData, messageId);
    };

    const resetTooltip = () => {
        setTimeout(() => {
            // wait for the disappearing animation to finish
            setTooltipText(str("copy_what", { what: str(messageId) }));
            setCopySuccess(false);
        }, 100);
    };

    return (
        <Tooltip
            title={tooltipText}
            placement="top"
            overlayClassName={cx({ "tooltipSuccess": copySuccess })}
            onVisibleChange={resetTooltip}
        >
            <CopyOutlined
                style={{ visibility: show ? "visible" : "hidden" }}
                className="copy-button"
                onClick={onCopyClick}
            />
        </Tooltip>
    );
}

export default CopyIcon;
