import { Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { editProduct } from "../../../../services/products";
import { STATUS_DISABLED, STATUS_ENABLED } from "../../constants";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    allowed: state?.pages?.allowed,
});

interface EnabledRendererProps {
    value: string;
}

function EnabledRenderer(props: EnabledRendererProps): React.ReactChild | null {
    const dispatch = useDispatch();
    const { products, allowed } = useSelector(selector);

    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    const setOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }

        ref.onclick = (e) => {
            dispatch(
                editProduct({
                    product_id: record?.product_id,
                    status: Number(record?.status) === STATUS_DISABLED ? STATUS_ENABLED : STATUS_DISABLED,
                })
            );
            e.stopPropagation();
        };
    };

    return record ? (
        <div ref={setOnClickHandler}>
            <Switch
                checked={Number(record?.status) === STATUS_ENABLED}
                disabled={!allowed}
                onChange={() => {
                    dispatch(
                        editProduct({
                            product_id: record.product_id,
                            status: Number(record?.status) === STATUS_DISABLED ? STATUS_ENABLED : STATUS_DISABLED,
                        })
                    );
                }}
            />
        </div>
    ) : null;
}

export default EnabledRenderer;
