import React from "react";
import { Button, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { HourPicker } from "./hour-picker";
import { DAYS } from "../constants";
import { useEffect } from "react";
import { useState } from "react";

interface Hours {
    MON: Array<string>;
    TUS: Array<string>;
    WED: Array<string>;
    THU: Array<string>;
    FRI: Array<string>;
    SAT: Array<string>;
    SUN: Array<string>;
}

interface HourSelectRowProps {
    day: string;
    dispatch: any;
    hours: Hours;
}

export const HoursSelectRow = (props: HourSelectRowProps): JSX.Element => {
    const intl = useIntl();
    const [invalidIndexes, setInvalidIndexes] = useState<Set<number>>(new Set<number>());

    useEffect(() => {
        setInvalidIndexes(getIndexesOfInvalidTimeRanges());
    }, [props.hours]);

    const getPrevDay = () => {
        let prevDay = "";
        switch (props.day.toLowerCase()) {
            case DAYS.SUN:
                prevDay = DAYS.SAT;
                break;
            case DAYS.MON:
                prevDay = DAYS.SUN;
                break;
            case DAYS.TUE:
                prevDay = DAYS.MON;
                break;
            case DAYS.WED:
                prevDay = DAYS.TUE;
                break;
            case DAYS.THU:
                prevDay = DAYS.WED;
                break;
            case DAYS.FRI:
                prevDay = DAYS.THU;
                break;
            case DAYS.SAT:
                prevDay = DAYS.FRI;
                break;
            default:
                break;
        }

        return prevDay.toUpperCase();
    };

    const getNextDay = () => {
        let nextDay = "";
        switch (props.day.toLowerCase()) {
            case DAYS.SUN:
                nextDay = DAYS.MON;
                break;
            case DAYS.MON:
                nextDay = DAYS.TUE;
                break;
            case DAYS.TUE:
                nextDay = DAYS.WED;
                break;
            case DAYS.WED:
                nextDay = DAYS.THU;
                break;
            case DAYS.THU:
                nextDay = DAYS.FRI;
                break;
            case DAYS.FRI:
                nextDay = DAYS.SAT;
                break;
            case DAYS.SAT:
                nextDay = DAYS.SUN;
                break;
            default:
                break;
        }

        return nextDay.toUpperCase();
    };
    // @ts-ignore
    const dayHours = props.hours[props.day];
    // @ts-ignore
    const prevDayHours = props.hours[getPrevDay()];
    // @ts-ignore
    const nextDayHours = props.hours[getNextDay()];

    const addHour = () => {
        props.dispatch({
            type: "addHour",
            payload: { day: props.day },
        });
    };

    const removeHour =
        (index = 0) =>
        () => {
            props.dispatch({
                type: "removeHour",
                payload: { day: props.day, index },
            });
        };

    const toggleOpen = (checked: boolean) => {
        props.dispatch({
            type: checked ? "setOpen" : "setClosed",
            payload: { day: props.day },
        });
    };

    const areHoursPastCurrDate = (hours: any) => {
        let isPast = false;
        hours.forEach((timeRange: any) => {
            if (isTimeRangePastCurrDate(timeRange)) {
                isPast = true;
            }
        });

        return isPast;
    };

    const isTimeRangePastCurrDate = (timeRange: string[]) => {
        if (timeRange.length !== 2) {
            return false;
        }

        return timeRange[1] < timeRange[0];
    };

    const doesTimeRangesOverlap = (timeRange1: string[], timeRange2: string[]) => {
        if (timeRange1.length !== 2 || timeRange2.length !== 2) {
            return false;
        }

        let doesOverlap;

        if (isTimeRangePastCurrDate(timeRange1)) {
            doesOverlap =
                timeRange2[0] >= timeRange1[0] ||
                timeRange2[1] >= timeRange1[0] ||
                timeRange2[0] <= timeRange1[1] ||
                timeRange2[1] <= timeRange1[1];
        } else if (isTimeRangePastCurrDate(timeRange2)) {
            doesOverlap =
                timeRange1[0] >= timeRange2[0] ||
                timeRange1[1] >= timeRange2[0] ||
                timeRange1[0] <= timeRange2[1] ||
                timeRange1[1] <= timeRange2[1];
        } else {
            doesOverlap =
                (timeRange1[0] >= timeRange2[0] && timeRange1[0] <= timeRange2[1]) ||
                (timeRange2[0] >= timeRange1[0] && timeRange2[0] <= timeRange1[1]);
        }

        return doesOverlap;
    };

    /**
     * Returns the indexes of the invalid time ranges
     *
     * @returns the indexes of the invalid time ranges
     */
    const getIndexesOfInvalidTimeRanges = () => {
        let openHour = "24:00";
        let closeHour = "00:00";
        let smallestHour: string;
        let openIndex = 0;
        let closeIndex = 0;
        const invalidIndexes = new Set<number>();
        const isTodayPast = areHoursPastCurrDate(dayHours);

        dayHours.forEach((timeRange: any, index: number) => {
            if (timeRange.length === 2) {
                if (timeRange[0] < openHour) {
                    openHour = timeRange[0];
                    openIndex = index;
                }
                if (
                    (isTodayPast && timeRange[1] < timeRange[0] && timeRange[1] > closeHour) ||
                    (!isTodayPast && timeRange[1] > closeHour)
                ) {
                    closeHour = timeRange[1];
                    closeIndex = index;
                }
            }
        });

        if (prevDayHours && areHoursPastCurrDate(prevDayHours)) {
            smallestHour = "24:00";
            prevDayHours.forEach((timeRange: any) => {
                if (timeRange.length === 2 && timeRange[1] < smallestHour) {
                    smallestHour = timeRange[1];
                }
            });
            if (smallestHour > openHour) {
                invalidIndexes.add(openIndex);
            }
        }
        if (nextDayHours && isTodayPast) {
            smallestHour = "24:00";
            nextDayHours.forEach((timeRange: any) => {
                if (timeRange.length === 2 && timeRange[0] < smallestHour) {
                    smallestHour = timeRange[0];
                }
            });
            if (smallestHour < closeHour) {
                invalidIndexes.add(closeIndex);
            }
        }

        for (let i = 0; i < dayHours.length; i++) {
            for (let j = i; j < dayHours.length; j++) {
                if (i !== j && dayHours) {
                    const timeRangeI = dayHours[i];
                    const timeRangeJ = dayHours[j];
                    if (timeRangeI.length === 2 && timeRangeJ.length === 2) {
                        if (doesTimeRangesOverlap(timeRangeI, timeRangeJ)) {
                            invalidIndexes.add(i);
                            invalidIndexes.add(j);
                        }
                    }
                }
            }
        }

        return invalidIndexes;
    };

    return dayHours.map((dh: any, i: number) => {
        const value1 = dh[0];
        const value2 = dh[1];
        const isInvalid = invalidIndexes.has(i);

        const is24Hours = () => {
            return value1 === "00:00" && value2 === "00:00";
        };

        const changeHours =
            (index = 0) =>
            (value: any) => {
                props.dispatch({
                    type: "changeHour",
                    payload: {
                        day: props.day,
                        index: i,
                        deepIndex: index,
                        value: value.format("HH:mm"),
                    },
                });
            };

        const set24Hours = () => {
            props.dispatch({ type: "changeHour", payload: { day: props.day, index: 0, deepIndex: 0, value: "00:00" } });
            props.dispatch({ type: "changeHour", payload: { day: props.day, index: 0, deepIndex: 1, value: "00:00" } });
            props.dispatch({ type: "removeHour", payload: { day: props.day, index: 1 } });
            props.dispatch({ type: "removeHour", payload: { day: props.day, index: 1 } });
        };

        const getHourSelects = (
            <React.Fragment>
                <div className="d-flex flex-row">
                    <div className="hours-ranges">
                        <div className={`hours-range-option ${isInvalid ? "hours-range-error" : ""}`}>
                            <HourPicker
                                value={value1}
                                onChange={changeHours(0)}
                                set24={set24Hours}
                                placeholder={intl.formatMessage({ id: "opens_at" })}
                            />
                        </div>
                        <div className="ml-1 mr-2">-</div>
                        <div className={`hours-range-option ${isInvalid ? "hours-range-error" : ""}`}>
                            <HourPicker
                                value={value1 === "closed" ? value1 : value2}
                                onChange={changeHours(1)}
                                set24={set24Hours}
                                placeholder={intl.formatMessage({ id: "closes_at" })}
                            />
                        </div>
                    </div>

                    <div className="hours-actions">
                        {dayHours.length > 1 ? (
                            <Button type="link" onClick={removeHour(i)} className="hours-actions-remove">
                                x
                            </Button>
                        ) : null}
                    </div>
                </div>
                {i === dayHours.length - 1 && i < 2 ? (
                    <div className="hours-actions-add-row">
                        <Button
                            type="link"
                            onClick={addHour}
                            disabled={value1 === "closed"}
                            className="hours-actions-add"
                        >
                            {intl.formatMessage({ id: "add_hours" })}
                        </Button>
                    </div>
                ) : null}
            </React.Fragment>
        );

        const get24HourBlock = (
            <div className="twenty-four-block d-flex justify-content-start w100 align-items-top">
                <FormattedMessage id="24_hours" />
                <Button
                    className="edit-option ml-3"
                    onClick={() => props.dispatch({ type: "setOpen", payload: { day: props.day } })}
                >
                    <FormattedMessage id="edit" />
                </Button>
            </div>
        );

        return (
            <div key={`hour-range-select-key-${i}`} className="hour-range-select">
                <div className="hours-active">
                    {i === 0 ? (
                        <>
                            <Switch checked={value1 !== "closed"} onChange={toggleOpen} />
                            <div className="hours-active-text">
                                <FormattedMessage id={value1 !== "closed" ? "open" : "closed"} />
                            </div>
                        </>
                    ) : null}
                </div>
                {value1 !== "closed" ? (is24Hours() ? get24HourBlock : getHourSelects) : null}
            </div>
        );
    });
};
