import React from "react";
import { Typography } from "antd";
import { FormattedMessage } from "react-intl";

function App(): JSX.Element {
    return (
        <React.Fragment>
            <div className="login-page-head">
                <Typography.Paragraph>
                    <FormattedMessage id="forgot_password" />
                </Typography.Paragraph>
            </div>
        </React.Fragment>
    );
}

export default App;
