import React, { useEffect, useState } from "react";
import _ from "lodash";
import useLanguageSelect from "hooks/useLanguageSelect";
import AssignItemsModal from "components/assign-items-modal";
import { RootState } from "app/reducer";
import { SelectOption } from "components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Radio, Select, Input } from "antd";
import { getProducts } from "services/products";
import helper from "util/helper";
import { useDispatch, useSelector } from "react-redux";
import { messageCustomers, getAllCustomers, getCoupons } from "services/customers";
import { setMessageType, setMessage } from "slices/customers";

function FilterModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    const [sendAll, setSendAll] = useState<number>(NaN);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [product, setProduct] = useState<any>();
    const [selCoupon, setSelCoupon] = useState<any>();
    const [showSelectedCusts, setShowSelectedCusts] = useState<boolean>(false);
    const g_id = useSelector((state: RootState) => state?.store?.records?.g_id);
    const allCustomers = useSelector((state: RootState) => state?.customers?.allCustomers);
    const messageType = useSelector((state: RootState) => state?.customers?.messageType);
    const coupons = useSelector((state: RootState) => state?.customers?.coupons);
    const totalCustomers = useSelector((state: RootState) => state?.customers?.totalCustomers);
    const messageOpen = useSelector((state: RootState) => state?.customers?.messageOpen);
    const selectedCustomers = useSelector((state: RootState) => state?.customers?.selectedCustomers);
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);
    const selCustLen = !Array.isArray(selectedCustomers) || _.isEmpty(selectedCustomers) ? 0 : selectedCustomers.length;
    const STORE_PROMOTION = 0;
    const PRODUCT_PROMOTION = 1;
    const COUPON_PROMOTION = 2;
    const COUPON = 4;
    const PRODUCT_MESSAGE = 2;
    const COUPON_MESSAGE = 14;
    const SEND_ALL = 1;
    const SEND_SEL = 2;

    useEffect(() => {
        dispatch(getProducts({ paging: { limit: totalCustomers } }));
        dispatch(getAllCustomers({ limit: totalCustomers }));
        dispatch(getCoupons({ product_type: COUPON }));
    }, [dispatch, totalCustomers]);

    useEffect(() => {
        setSendAll(selectedCustomers?.length > 0 ? SEND_SEL : SEND_ALL);
    }, [messageOpen, selectedCustomers]);

    const getCouponsAsOptions = () => {
        return (
            coupons?.map((c: any) => ({
                value: c.code,
                label: helper.getTransString(c.name, lan),
            })) ?? []
        );
    };

    const getAllIDs = () => {
        const ids: any = [];
        allCustomers?.map((customer: any) => {
            ids?.push(customer?.id);
        });
        return ids;
    };

    const sendMessage = () => {
        let id,
            code,
            type = undefined;
        if (messageType === COUPON_PROMOTION) {
            type = COUPON_MESSAGE;
            code = selCoupon;
        } else if (messageType === PRODUCT_PROMOTION) {
            type = PRODUCT_MESSAGE;
            id = product?.product_id ?? undefined;
        } else {
            type = COUPON_MESSAGE;
            id = g_id;
        }
        const receiver_ids = sendAll === SEND_ALL ? getAllIDs() : selectedCustomers ?? [];
        dispatch(messageCustomers({ type, receiver_ids, title, content, id, code }));
        dispatch(setMessage({ value: false }));
        setContent("");
        setTitle("");
        setSendAll(NaN);
    };

    const messageTypes: SelectOption[] = [
        { value: STORE_PROMOTION, label: intl.formatMessage({ id: "store_promotion" }) },
        { value: PRODUCT_PROMOTION, label: intl.formatMessage({ id: "product_promotion" }) },
        { value: COUPON_PROMOTION, label: intl.formatMessage({ id: "coupon_promotion" }) },
    ];

    const renderActions = (
        <div className="filter-modal-footer d-flex w100 justify-content-center mt-3">
            <Button type="primary" onClick={() => sendMessage()}>
                <FormattedMessage id="send_message" />
            </Button>
        </div>
    );

    const getMessage = (
        <div className="d-flex flex-column w100">
            <div className="filter-subtitle mr-3 mt-2">
                <FormattedMessage id="title" />:
            </div>
            <Input.TextArea
                className="message-input"
                rows={1}
                onChange={(e: any) => setTitle(e.target.value)}
                value={title}
            />
            <div className="filter-subtitle mr-3 mt-2">
                <FormattedMessage id="body" />:
            </div>
            <Input.TextArea
                className="message-input"
                rows={5}
                onChange={(e: any) => setContent(e.target.value)}
                value={content}
            />
        </div>
    );

    const getSendToRadioGroup = (
        <Radio.Group onChange={() => setSendAll(sendAll === SEND_ALL ? SEND_SEL : SEND_ALL)} value={sendAll}>
            <Radio value={SEND_ALL}>
                <FormattedMessage id="all" /> ({totalCustomers})
            </Radio>
            <Radio value={SEND_SEL} disabled={selectedCustomers?.length === 0}>
                <FormattedMessage id="selected" /> ({selCustLen})
            </Radio>
        </Radio.Group>
    );

    const sendTo = (
        <div className="d-flex align-items-center mb-2">
            <div className="filter-subtitle mr-3">
                <FormattedMessage id="send_to" />:{" "}
            </div>
            {getSendToRadioGroup}
            {selectedCustomers?.length !== 0 ? (
                <div className="see-selected" onClick={() => setShowSelectedCusts(!showSelectedCusts)}>
                    {<FormattedMessage id={showSelectedCusts ? "hide_selected" : "show_selected"} />}...
                </div>
            ) : null}
        </div>
    );

    const renderProductSelect = () => {
        return (
            <div className="d-flex align-items-center mb-3">
                <input className="select-input-product" disabled value={helper.getTransString(product?.name, lan)} />
                <Button
                    type="primary"
                    className="filter-subtitle button mr-3"
                    onClick={() => setShowAssignItemsModal(true)}
                >
                    <FormattedMessage id="choose_product" />
                </Button>
            </div>
        );
    };

    const getSelectedCustomers = () => {
        let custs: string | undefined = undefined;
        selectedCustomers?.map((customer: any) => {
            custs
                ? (custs += `, ${customer?.firstname} ${customer?.lastname}`)
                : (custs = `${customer?.firstname} ${customer?.lastname}`);
        });
        return <div className="show-selected-customers">{custs}</div>;
    };

    const getMessageType = (
        <div className="d-flex align-items-center mt-4 mb-3 w100">
            <div className="filter-subtitle message-title mr-3">
                <FormattedMessage id="message_type" />:{" "}
            </div>
            <Select
                className="select-input"
                value={messageType}
                onChange={(e) => dispatch(setMessageType({ value: e }))}
            >
                {messageTypes.map((option: SelectOption) => (
                    <Select.Option key={option?.value} value={option?.value}>
                        {" "}
                        {option?.label}{" "}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    const renderCouponSelect = () => {
        const couponz = getCouponsAsOptions();
        return (
            <div className="d-flex align-items-center mb-3 w100">
                <div className="filter-subtitle message-title mr-3">
                    <FormattedMessage id="select_coupon" />:{" "}
                </div>
                <Select className="select-input" value={selCoupon} onChange={(e) => setSelCoupon(e)}>
                    {couponz?.map((option: SelectOption) => (
                        <Select.Option key={option?.value} value={option?.value}>
                            {" "}
                            {option?.label}{" "}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        );
    };

    const onSaveAssign = (selected: any) => {
        if (!_.isEmpty(selected)) {
            setProduct(selected[0] ?? undefined);
        }
        setShowAssignItemsModal(false);
    };

    return (
        <div className="customer-message-modal d-flex flex-column justify-content-start align-items-start">
            {sendTo}
            {showSelectedCusts ? getSelectedCustomers() : null}
            {getMessageType}
            {messageType === PRODUCT_PROMOTION ? renderProductSelect() : null}
            {messageType === COUPON_PROMOTION ? renderCouponSelect() : null}
            {getMessage}
            {renderActions}
            {showAssignItemsModal ? (
                <AssignItemsModal
                    title={<FormattedMessage id="choose_product" />}
                    onSave={onSaveAssign}
                    onCancel={() => setShowAssignItemsModal(false)}
                    selectedProducts={[product]}
                    allowMultipleSelection={false}
                />
            ) : null}
        </div>
    );
}

export default FilterModal;
