import React from "react";
import { Image, Modal, Upload } from "antd";
import { HiCamera, HiX } from "react-icons/hi";
import config from "../../config";
import { runImageCacheHelper } from "../../services/image-cache";
import { FormattedMessage, injectIntl } from "react-intl";

function getBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const getImage = (url: string) => {
    if (url.includes("http")) {
        return url;
    }
    return config.IMG_PREFIX + url;
};

const MAX_STORE_IMAGES = 6;

class ImageUploadMulti extends React.Component<any> {
    state = {
        previewVisible: false,
        previewImage: "",
        previewTitle: "",
        fileList: [],
        imageList: [],
    };

    componentDidMount = () => {
        const fileList: any = [];

        this.props.currentImages?.forEach?.((image: any, i: any) => {
            fileList.push({
                uid: "-" + i,
                name: image,
                status: "done",
                url: getImage(image),
            });
        });

        this.setState({ fileList, imageList: this.props.currentImages ?? [] });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file?.url?.substring?.(file?.url?.lastIndexOf?.("/") + 1),
        });
    };

    handleChange = ({ fileList }: any) => {
        this.setState({ fileList });
    };

    uploadToImageCache = async ({ file, onSuccess }: any) => {
        const { imageList } = this.state;
        await runImageCacheHelper({
            action: "add_image_cache",
            type: this.props.type,
            imageType: this.props.imageType ?? "",
            user_id: this.props.user.id,
            cloudinaryImagesCount: this.props.currentImages?.length ?? 0,
            name: file.uid,
            "fileToUpload[]": file,
        });

        const cache: any = await runImageCacheHelper({
            action: "load_image_cache",
            imageType: this.props.imageType ?? "",
            user_id: this.props.user.id,
            type: this.props.type,
        });

        onSuccess("ok");

        const newImages =
            cache?.filter?.(
                (c: any) =>
                    !imageList?.find((i: any) => {
                        return i === c;
                    })
            ) ?? [];

        this.setState({
            imageList: [...imageList, ...newImages],
        });
    };

    deleteImage = (image: string) => (e: any) => {
        e.stopPropagation();
        const { intl } = this.props;
        Modal.confirm({
            title: intl.formatMessage({ id: "confirm_delete" }),
            content: intl.formatMessage({ id: "are_you_sure_delete_image" }),
            onOk: () => {
                const uid = image.split("/")[image.split("/").length - 1];
                const file: any = this.state.fileList?.find?.(
                    // @ts-ignore
                    (file) => file?.uid + ".jpeg" === uid || file?.name?.includes(uid)
                );
                if (file) {
                    runImageCacheHelper({
                        action: "delete_image_cache",
                        type: this.props.type,
                        imageType: this.props.imageType ?? "",
                        user_id: this.props.user.id,
                        image_file: file.uid + ".jpeg",
                    });
                }

                if (this.props.deleteCallback) {
                    this.props.deleteCallback(file.name);
                }

                this.setState({
                    fileList: this.state.fileList?.filter?.((file: any) => {
                        return !file.name.includes(uid);
                    }),
                    imageList: this.state.imageList?.filter?.((image: any) => {
                        return !image.includes(uid);
                    }),
                });
            },
        });
    };

    handleRemove = async (file: any) => {
        await runImageCacheHelper({
            action: "delete_image_cache",
            type: this.props.type,
            imageType: this.props.imageType ?? "",
            user_id: this.props.user.id,
            image_file: file.uid + ".jpeg",
        });

        if (this.props.deleteCallback) {
            this.props.deleteCallback(file.name);
        }
    };

    getImg = (item: string) => {
        return (
            <div className="item-image-container" key={item}>
                <div className="item-image-delete" onClick={this.deleteImage(item)}>
                    <HiX />
                </div>
                <Image src={getImage(item)} />
            </div>
        );
    };

    getImages = () => {
        return (
            <div style={{ display: "flex", padding: 16, flexWrap: "wrap" }}>
                {this.state?.imageList?.map?.(this.getImg)}
            </div>
        );
    };

    render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        return (
            <div>
                <div className="uploader-images">
                    {this.getImages()}
                    {this.state.imageList.length < MAX_STORE_IMAGES ? (
                        <div className="uploader-dragger">
                            <Upload.Dragger
                                // @ts-ignore
                                customRequest={this.uploadToImageCache}
                                // @ts-ignore
                                fileList={fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                onRemove={this.handleRemove}
                            >
                                <div>
                                    <div>
                                        <HiCamera />
                                    </div>
                                    <div>
                                        <FormattedMessage id="add_image" />
                                    </div>
                                </div>
                            </Upload.Dragger>
                        </div>
                    ) : null}
                </div>
                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ImageUploadMulti);
