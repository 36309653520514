import React from "react";
import { View, Image } from "@react-pdf/renderer";
import union_pay from "../../../../../images/icons/icon_union_pay_express.png";
import visa from "../../../../../images/icons/icon_visa.png";
import masterCard from "../../../../../images/icons/icon_master.png";
import applePay from "../../../../../images/icons/icon_apple_pay.png";
import googlePay from "../../../../../images/icons/icon_google_pay.png";
import paypal from "../../../../../images/icons/icon_paypal.png";
import weChat from "../../../../../images/icons/icon_wechat_pay.png";
import aliPay from "../../../../../images/icons/icon_alipay.png";

const paymentMethods = (styles: React.CSSProperties | any, props: any = {}): JSX.Element => (
    <View style={styles.paymentContainer}>
        {props.paymentMethodsChecked.map((item: any, index: any) => {
            switch (item) {
                case "union_pay":
                    return <Image src={union_pay} style={styles.paymentImg} key={index} />;
                case "visa":
                    return <Image src={visa} style={styles.paymentImg} key={index} />;
                case "mastercard":
                    return <Image src={masterCard} style={styles.paymentImg} key={index} />;
                case "braintree_apple":
                    return <Image src={applePay} style={styles.paymentImg} key={index} />;
                case "braintree_google_pay":
                    return <Image src={googlePay} style={styles.paymentImg} key={index} />;
                case "braintree_paypal":
                    return <Image src={paypal} style={styles.paymentImg} key={index} />;
                default:
                    return (
                        <>
                            <Image src={weChat} style={styles.paymentImg} key={item} />
                            <Image src={aliPay} style={styles.paymentImg} key={index} />
                        </>
                    );
            }
        })}
    </View>
);
export default paymentMethods;
