import { Input, Button, Modal, Pagination, Tabs, Spin } from "antd";
import _ from "lodash";
import "./index.scss";
import { BiSliderAlt } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import React, { Component } from "react";
import { getStoreDetails } from "services/store";
import FilterModal from "./_components/filter-modal";
import MessageModal from "./_components/message-modal";
import Wrapper from "components/wrapper";
import BulkActions from "./_components/bulk-actions";
import { getCustomers, getCustomerGiftcards } from "services/customers";
import {
    setFilter,
    setSelected,
    setFilters,
    setSearchValue,
    setMessage,
    setMessageType,
    setAllSelectedCustomers,
} from "slices/customers";
import { FILTER_KEYS } from "../order-history/helper-order-list";
import CustomerTable from "./_hooks/useCustomerTable";
import moment from "moment";
import { setGiftcardState } from "../../slices/giftcards";
import RefreshButton from "../../components/refresh";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { CUSTOMER_TYPES } from "./constants";
import { withRouter } from "react-router-dom";
import qs from "querystring";
import PrintLayoutSwitch from "components/print-layout-switch";

const { TabPane } = Tabs;
const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/customers", breadcrumbName: "customers" },
    ],
};

const PAGE_SIZE_OPTION = ["10", "20", "30", "50"];
const PAGE_LIMIT_DEFAULT = 10;
const COUPON_MESSAGE = 2;
const PAGE_DEFAULT = 1;

class CustomerPage extends Component {
    state = {
        codeSearchValue: "",
        isPrintLayout: false,
    };

    agGridRef = React.createRef();

    componentDidMount() {
        const search = qs.parse(location.search.slice(1)) ?? {};

        this.props.getStoreDetails();
        this.getCustomersOnMount(search);

        this.unlisten = this.props.history.listen(this.locationListener);
    }

    componentDidUpdate(prevsProps) {
        if (this.props.update !== prevsProps.update) {
            this.props.getCustomers({ page: this.state.pageNum, limit: this.state.pageLimit });
        }
    }

    componentWillUnmount = () => {
        this.unlisten();
    };

    locationListener = (location) => {
        if (!location.search.length) {
            this.setState({ codeSearchValue: "" });

            this.removeAllFilters();

            this.props.getCustomers({
                page: PAGE_DEFAULT,
                limit: PAGE_LIMIT_DEFAULT,
                quick_search: "",
            });
        }
    };

    setIsPrintLayout = (value) => {
        this.setState({
            ...this.state,
            isPrintLayout: value,
        });
    };

    hasFilters = () => {
        const filters = this.props.state?.customers?.filters;

        // Returns false if every filter key value is undefined
        return !Object.values(filters).every((filter) => filter === undefined);
    };

    getCustomersOnMount = (search) => {
        if (search) {
            if (search.customer) {
                // If the customer was found using the search input
                this.removeAllFilters();
                this.setState({ codeSearchValue: search.customer });
                this.props.getCustomers({
                    page: PAGE_DEFAULT,
                    limit: PAGE_LIMIT_DEFAULT,
                    quick_search: search.customer,
                });
            } else {
                // If the customer was found using the filter modal
                Object.keys(search).forEach((key) => {
                    if (search[key]) {
                        // Set the non-empty filters to render filter boxes
                        this.props.setFilters({ name: key, value: search[key] });
                    }
                });

                this.setState({ codeSearchValue: "" });
                this.props.getCustomers({ ...search });
            }
        }
    };

    filterCodes = (filter, isQuickSearch = false) => {
        const filters = this.props.state?.customers?.filters;
        let isFilters = { ...filters };

        Object.keys(isFilters).forEach((key) => {
            if (!filters[key] || key === filter) {
                delete isFilters[key];
            }
        });
        this.props.setFilter({ value: false });

        if (!isQuickSearch) {
            this.props.getCustomers({ ...isFilters });

            _.debounce(() => {
                this.props.history.push("/customers?" + qs.stringify(isFilters));
            }, 1000)();
        }
    };

    removeFilter = (filter, isQuickSearch = false) => {
        this.props.setFilters({ name: filter, value: undefined });
        this.filterCodes(filter, isQuickSearch);
    };

    removeAllFilters = (isQuickSearch = false) => {
        const filters = this.props.state?.customers?.filters;

        if (this.hasFilters()) {
            Object.keys(filters).forEach((filter) => {
                if (filters[filter]) this.removeFilter(filter, isQuickSearch);
            });
        }
    };

    renderFilteTypeTab = () => {
        const filters = this.props.state?.customers?.filters;
        return (
            <div className="top-tab">
                <Tabs
                    onChange={(value) => {
                        this.props.getCustomers({
                            ...filters,
                            customer_type: value,
                            page: this.state.pageNum,
                            limit: this.state.pageLimit,
                        });
                    }}
                    defaultActiveKey={CUSTOMER_TYPES.all}
                >
                    {Object.keys(CUSTOMER_TYPES).map((key) => (
                        <TabPane tab={this.props.intl.formatMessage({ id: key })} key={CUSTOMER_TYPES[key]} />
                    ))}
                </Tabs>
            </div>
        );
    };

    renderFilterBar = () => {
        const filters = this.props.state?.customers?.filters;
        const loading = this.props.state?.customers?.loading;
        const selectedCustomers = this.props.state?.customers?.selectedCustomers;

        const refreshPage = () => {
            let isFilters = { ...filters };
            this.props.getCustomers({ page: this.state.pageNum, limit: this.state.pageLimit, ...isFilters });
        };

        const searchHandler = (value) => {
            const query = {
                customer: value,
            };

            this.removeAllFilters(true);
            this.setState({ codeSearchValue: value });
            this.props.getCustomers({
                page: PAGE_DEFAULT,
                limit: PAGE_LIMIT_DEFAULT,
                quick_search: value,
            });
            this.props.setSearchValue(query);

            value.length
                ? this.props.history.push("/customers?" + qs.stringify(query))
                : this.props.history.push("/customers");
        };

        const renderFilterBox = (name, value, filter) => (
            <div className="filter-item-holder d-flex align-items-center mr-2">
                <div className="mr-1">
                    <FormattedMessage id={name} />: {value}
                </div>
                <AiOutlineClose
                    style={{ marginTop: "4px", cursor: "pointer" }}
                    size={15}
                    onClick={() => this.removeFilter(filter)}
                />
            </div>
        );

        const renderFilters = () => {
            const trans = [
                "id",
                "last_order_date_from",
                "last_order_date_to",
                "amt_from",
                "amt_to",
                "qty_from",
                "qty_to",
                "last_name",
                "first_name",
                "email",
                "phone",
            ];
            let filts = [];

            Object.keys(filters).forEach((key, index) => {
                filters[key] && filts.push({ filter: key, value: filters[key], name: trans[index] });
            });

            return (
                <div className="filtersRow my-2 d-flex">
                    {filts?.map((filt) => renderFilterBox(filt.name, filt.value, filt.filter))}
                </div>
            );
        };

        return (
            <div className="">
                <div className="codes-search-bar">
                    <div className="d-flex">
                        <Input.Search
                            className="codes-search-input"
                            id="codes-search-input"
                            placeholder={this.props.intl.formatMessage({ id: "search_dot" })}
                            allowClear
                            size="large"
                            value={this.state.codeSearchValue}
                            onChange={(e) => searchHandler(e.target.value ?? "")}
                        />
                        <Button
                            className="filter-button"
                            icon={<BiSliderAlt size={20} />}
                            onClick={() => this.props.setFilter({ value: true })}
                        >
                            <div className="icon-button-text d-inline">
                                <FormattedMessage id="filters" />
                            </div>
                        </Button>
                        <RefreshButton refreshPage={refreshPage} loading={loading} />
                    </div>
                    <div className="d-flex align-center">
                        {selectedCustomers?.length < 1 ? null : <BulkActions />}
                        <PrintLayoutSwitch
                            isPrintLayout={this.state.isPrintLayout}
                            setIsPrintLayout={this.setIsPrintLayout}
                            agGridRef={this.agGridRef}
                        />
                        <Button
                            type="primary"
                            className="new_customer_button"
                            onClick={() => this.props.setMessage({ value: true })}
                        >
                            <FormattedMessage id="send_message" />
                        </Button>
                        <Button
                            type="primary"
                            className="new_customer_button"
                            onClick={() => this.props.history.push("/customers/new")}
                        >
                            <FormattedMessage id="add_customer" />
                        </Button>
                    </div>
                </div>
                <div className="filters-row">{renderFilters()}</div>
            </div>
        );
    };

    renderTable = () => {
        const loading = this.props.state?.customers?.loading;
        const openOrdersPage = (customer) => {
            const id = customer?.id;
            const startDate = moment().subtract(365, "days").format(FILTER_KEYS.date_format);
            const endDate = moment().format(FILTER_KEYS.date_format);
            this.props.history.push(
                `/orders?${FILTER_KEYS.customer_id}=${id}&${FILTER_KEYS.start_date}=${startDate}&${FILTER_KEYS.end_date}=${endDate}`
            );
        };

        const openCustomerDetailPage = (customer) => {
            const id = customer?.id;
            this.props.history.push(`/customers/customer/${id}`);
        };

        const openCustomerGiftCards = (customer) => {
            this.props.getCustomerGiftcards(
                {
                    c_id: customer?.id,
                },
                () =>
                    this.props.setGiftcardState({
                        showCustomerGiftCardsModal: true,
                        customerFirstName: customer?.firstname,
                        customerLastName: customer?.lastname,
                        customerPhone: customer?.phone,
                        customerEmail: customer?.email,
                    })
            );
        };

        const openCouponMessage = (customer) => {
            this.props.setAllSelectedCustomers({ value: customer });
            this.props.setMessage({ value: true });
            this.props.setMessageType({ value: COUPON_MESSAGE });
        };

        const sendMessage = (customer) => {
            this.props.setAllSelectedCustomers({ value: customer });
            this.props.setMessage({ value: true });
        };

        const showCustomer = () => {
            return true;
        };

        const setSelected = this.props.setSelected;

        const getRef = () => this.agGridRef;

        return (
            <Spin spinning={loading}>
                <CustomerTable
                    {...{
                        openOrdersPage,
                        openCustomerDetailPage,
                        openCustomerGiftCards,
                        openCouponMessage,
                        sendMessage,
                        showCustomer,
                        setSelected,
                        getRef,
                    }}
                />
            </Spin>
        );
    };

    filterModalOnApply = () => {
        const filters = this.props.state?.customers?.filters;

        this.setState({ codeSearchValue: "" });

        if (this.hasFilters()) {
            this.props.setSearchValue(filters);
            this.props.history.push("/customers?" + qs.stringify(filters));
        } else {
            this.props.setSearchValue({});
            this.props.history.push("/customers");
        }
    };

    renderFilterModal = () => {
        const filterOpen = this.props.state?.customers?.filterOpen;
        return (
            <Modal
                visible={filterOpen}
                title={this.props.intl.formatMessage({ id: "customer_filter" })}
                width="600px"
                footer={null}
                onCancel={() => this.props.setFilter({ value: false })}
            >
                <FilterModal onApply={this.filterModalOnApply} />
            </Modal>
        );
    };

    renderMessageModal = () => {
        const messageOpen = this.props.state?.customers?.messageOpen;
        return (
            <Modal
                visible={messageOpen}
                title={this.props.intl.formatMessage({ id: "send_message_to_customers" })}
                width="490px"
                footer={null}
                onCancel={() => this.props.setMessage({ value: false })}
            >
                <MessageModal />
            </Modal>
        );
    };

    renderPagination = () => {
        const totalCustomers = this.props.state?.customers?.totalCustomers;
        const navigationWidth = this.props.state?.ui?.navigationWidth;
        const onPageSizeChange = (current, pageSize) => {
            this.setState({ pageNum: current, pageSize: pageSize });
            this.props.getCustomers({ page: current, limit: pageSize });
        };
        const onPageChange = (page, pageSize) => {
            this.setState({ pageNum: page, pageSize: pageSize });
            this.props.getCustomers({ page, limit: pageSize });
        };
        return (
            <div
                className="w100 d-flex justify-content-end mt-3 pagination-customers"
                style={{
                    width: `calc(100vw - ${navigationWidth}px)`,
                }}
            >
                <Pagination
                    showSizeChanger
                    onShowSizeChange={onPageSizeChange}
                    onChange={onPageChange}
                    defaultCurrent={PAGE_DEFAULT}
                    defaultPageSize={PAGE_LIMIT_DEFAULT}
                    pageSizeOptions={[
                        PAGE_SIZE_OPTION[0],
                        PAGE_SIZE_OPTION[1],
                        PAGE_SIZE_OPTION[2],
                        PAGE_SIZE_OPTION[3],
                    ]}
                    total={totalCustomers}
                />
                <div className="pagination-total">
                    <FormattedMessage id="total" />: {totalCustomers}
                </div>
            </div>
        );
    };

    render() {
        return (
            <Wrapper helmet={{ title: "customers" }} breadcrumb={breadcrumb} paddingBottom={72}>
                <div className="customers-page products-page">
                    <div className="options-description mb-3">
                        <FormattedMessage id="admin_users_desc" />
                    </div>
                    <div className="customers-page-table-top-content">
                        <div>
                            {this.renderFilteTypeTab()}
                            {this.renderFilterBar()}
                        </div>
                    </div>
                    {this.renderTable()}
                    {this.renderFilterModal()}
                    {this.renderMessageModal()}
                    {this.renderPagination()}
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    update: _.get(state, "customers.update", false),
    searchValue: _.get(state, "customers.searchValue", ""),
});

const mapDispatchToProps = {
    getCustomerGiftcards,
    setGiftcardState,
    setAllSelectedCustomers,
    setMessage,
    setMessageType,
    setSelected,
    setFilter,
    setFilters,
    setSearchValue,
    getCustomers,
    getStoreDetails,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerPage)));
