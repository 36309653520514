import React from "react";
import { View, Text } from "@react-pdf/renderer";

const getFontSize = (tableNum: string) => {
    switch (tableNum.length) {
        case 1:
            return 36;
        case 2:
            return 30;
        case 3:
            return 24;
        default:
            return 18;
    }
};

const tableNum = (styles: React.CSSProperties | any, url: { tableNum: string }): JSX.Element => (
    <View style={styles.tableNumCont}>
        <View style={{ ...styles.tableNumHolder, fontSize: getFontSize(url.tableNum) }}>
            <Text>{url.tableNum}</Text>
        </View>
    </View>
);
export default tableNum;
