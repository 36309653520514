import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import actions from "../../../../../slices/order-history";

class BulkUpdateButton extends Component {
    onBulkUpdateClick = () => {
        this.props.setState({ bulkUpdating: true });
    };

    render() {
        return (
            <div
                onClick={() => this.onBulkUpdateClick()}
                className="bulk-update-button order-list-controller-button-type-a"
            >
                <FormattedMessage id="bulk_update" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BulkUpdateButton));
