import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { setState } from "slices/create-order";
import _ from "lodash";
import { Input, Select, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import actions from "slices/live-order";
import { getTransString } from "util/helper";
import { MdRefresh } from "react-icons/md";
import cx from "classnames";

class SearchRow extends Component<any, any> {
    getCurrentMenuId = () => {
        return this.props.menuId;
    };

    getMenues = () => {
        return Array.isArray(this.props.menus) ? this.props.menus : [];
    };

    renderSearchInput = () => {
        const handleSearch = (e: any) => {
            const value = e.target.value;
            let conditions = _.cloneDeep(this.props.searchCondition);
            conditions = _.omit(conditions, ["cids"]);
            conditions.page = 0;
            conditions.reset = true;
            if (!_.isEmpty(value)) {
                conditions.keywords = value;
                this.props.getProducts(conditions);
            }
            this.props.setState({ quickAddModalSearchInput: e.target.value });
        };
        return (
            <Input
                allowClear
                placeholder={this.props.intl.formatMessage({ id: "search_by_item" })}
                style={{ width: "100%" }}
                prefix={<SearchOutlined />}
                onChange={(values) => handleSearch(values)}
                value={this.props.state?.quickAddModalSearchInput}
            />
        );
    };

    renderMenuSelect = () => {
        const handleOnSelect = (value: any) => {
            this.props.setParentState({ menuId: value });
        };
        return _.isEmpty(this.props.menus) ? null : (
            <Select
                onChange={(v) => handleOnSelect(v)}
                style={{ width: "100%" }}
                value={this.getCurrentMenuId()}
                options={this.getMenues().map((menu: any) => ({
                    ...menu,
                    label: getTransString(menu.names, this.props.lan),
                    value: menu.id,
                }))}
            />
        );
    };

    render() {
        const hasMenu = !_.isEmpty(this.props.menus);
        return (
            <div className="quick-search-search-row">
                <Row style={{ width: "100%" }} gutter={hasMenu ? 16 : 0}>
                    <Col span={hasMenu ? 14 : 22}>{this.renderSearchInput()}</Col>
                    <Col className="clear-cache" span={2}>
                        <MdRefresh
                            onClick={() => {
                                this.props.liveOrderSetState({ cachedProducts: {} });
                                this.props.setState({ shouldReloadProducts: true });
                            }}
                            className={cx({
                                "order-list-title-refresh": true,
                                "spin": this.props.isLoadingProducts,
                            })}
                        />
                    </Col>
                    {hasMenu ? <Col span={8}>{this.renderMenuSelect()}</Col> : null}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    isLoadingProducts: _.get(state, "liveOrders.isGettingProducts", false),
    shouldReloadProducts: _.get(state, "createOrder.shouldReloadProducts", false),
});

const mapDispatchToProps = {
    setState,
    getProducts: actions.getProducts,
    liveOrderSetState: actions.setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchRow)));
