import { Button, Popover } from "antd";
import React from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setAddModalState, setRedeemModalState, setReferralsState } from "../../../../slices/referrals";

interface ReferralsActionRendererProps {
    value: any[];
}

function ReferralsActionRenderer(props: ReferralsActionRendererProps): JSX.Element {
    const dispatch = useDispatch();
    const records = useSelector((state: RootState) => state?.referrals?.referrals);
    const search = useSelector((state: RootState) => state?.referrals?.transactionsSearchConditions);
    const record = records?.find((r: any) => r?._id === props?.value);

    const openRedeemModal = () => {
        dispatch(setRedeemModalState(record));
    };

    const openAddModal = () => {
        dispatch(setAddModalState(record));
    };

    const getTransactionHistory = () => {
        dispatch(
            setReferralsState({
                transactionsSearchConditions: {
                    ...search,
                    customer_id: record?.customer_id,
                },
            })
        );
    };

    const getReferralsList = () => {
        dispatch(
            setReferralsState({
                customerReferralsSearchConditions: {
                    ...search,
                    ref_code: record?.referral_code,
                },
            })
        );
    };

    const menu = () => (
        <div className="d-flex flex-column justify-content-start align-items-start">
            <p className="popover-item mb-1" onClick={getTransactionHistory}>
                <FormattedMessage id="view_points_history" />
            </p>
            <p className="popover-item mb-1" onClick={getReferralsList}>
                <FormattedMessage id="view_referrals_list" />
            </p>
            <p className="popover-item mb-1" onClick={openAddModal}>
                <FormattedMessage id="add_reward_points" />
            </p>
            <p className="popover-item mb-1" onClick={openRedeemModal}>
                <FormattedMessage id="redeem_reward_points" />
            </p>
        </div>
    );

    return (
        <div className="d-flex flex-row">
            <Popover content={menu()} placement="left">
                <Button type="link">
                    <HiDotsHorizontal size="20" />
                </Button>
            </Popover>
        </div>
    );
}

export default ReferralsActionRenderer;
