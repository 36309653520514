import React, { Component } from "react";
import _helper from "util/helper";
import { FormattedMessage, injectIntl } from "react-intl";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "./_orderComments.scss";
import _ from "lodash";
import { connect } from "react-redux";
import CommentDialog from "../order-comment-input-dialog";
import Collapse from "@material-ui/core/Collapse";
import { Checkbox } from "antd";
import { requestUtensil, orderShowRequireUtensil, formatOrderId } from "pages/order-history/helper";
import _actions from "slices/live-order";
import cx from "classnames";

const ORDER_COMMENTS_TAB = 0;
const ORDER_HISTORY_TAB = 1;

const CUSTOMER_COMMENT = 0;
const ADMIN_COMMENT = 1;
const ORDER_STATUS_COMMENT = 2;

class OrderComments extends Component {
    state = {
        showExpandIcon: true,
        showComments: true,
        collapsedHeight: 40,
        tabKey: ORDER_COMMENTS_TAB,
        displayedComments: null,
    };

    componentDidMount() {
        this.updateComments(this.state.tabKey)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.order.comments !== this.props.order.comments) {
            this.updateComments(this.state.tabKey);
        }
    }

    setShowExpandIconState = (show) => {
        if (this.state.showExpandIcon !== show) {
            this.setState({ showExpandIcon: show });
        }
    };

    getOrderId = () => {
        return _.get(this.props, "order.ord_id");
    };

    getController = () => _.get(this.props, `state.expansionControllers.${this.getOrderId()}`, {});

    showComments = () => _.get(this.getController(), `showComments`, false);

    getCommentCellId = (i) => {
        return `${_.get(this.props, "order.ord_id")}-comment-${i}`;
    };

    getComments = () => {
        const orderComments = _.cloneDeep(_.get(this.props, "order.comments", []));
        return Array.isArray(orderComments) && !_.isEmpty(orderComments) ? orderComments.reverse() : [];
    };

    getDisplayedComments = (firstComment) => {
        const comments = this.getComments();
        let displayedComments;
        const hasMoreThanOneComment = comments.filter(this.renderComment).length > 1;

        if (firstComment && this.state.showComments && hasMoreThanOneComment) {
            this.setShowExpandIconState(true);
            displayedComments = comments.map(comment => this.renderComment(comment));
        } else if (firstComment) {
            this.setShowExpandIconState(hasMoreThanOneComment);
            displayedComments = (
                <div onClick={hasMoreThanOneComment ? this.toggleComments : undefined}>
                    {this.renderComment(firstComment)}
                </div>
            );
        } else {
            this.setShowExpandIconState(false);
            displayedComments = this.renderNoCommentsAvailable();
        }

        return displayedComments;
    };

    isCommentsTab = () => {
        return this.state.tabKey === ORDER_COMMENTS_TAB;
    };

    isHistoryTab = () => {
        return this.state.tabKey === ORDER_HISTORY_TAB;
    };

    isOrderComment = (comment) => {
        return comment.is_admin === ADMIN_COMMENT || comment.is_admin === CUSTOMER_COMMENT;
    };

    toggleComments = () => {
        this.setState({ showComments: !this.state.showComments });
    };

    toggleCommentDialog = () => {
        this.setState({
            showCommentDialog: true,
        });
    };

    renderCommentCell = (comment, i) => {
        if (comment.comment)
            return (
                <div id={this.getCommentCellId(i)} className="comment-row" key={i}>
                    {comment.is_admin === 2
                        ? this.renderStatusCommentCell(comment, i)
                        : comment.orderId
                            ? this.renderGroupedOrderCommentCell(comment, i)
                            : this.renderNormalCommentCell(comment, i)}
                </div>
            );
    };

    getCommentTime = (time) => {
        return _helper.utcToLocal(time, {
            type: "shortest",
            seperator: " ",
        });
    };

    renderNormalCommentCell = (comment) => {
        return (
            <div>
                <div className="comment-title">
                    <div className="header-subl2-text ">
                        <FormattedMessage
                            id={
                                Number(comment.is_admin) === 1
                                    ? "admin"
                                    : Number(comment.is_admin) === -1
                                        ? "system"
                                        : "customer"
                            }
                        />
                    </div>
                    <div className="comment-time">{this.getCommentTime(comment.create_at)}</div>
                </div>
                <div className="comment-content-text">{comment.comment}</div>
            </div>
        );
    };

    renderGroupedOrderCommentCell = (comment) => {
        return (
            <div className="comment-title ">
                <div className="comment-content-text">{comment.comment}</div>
                <div className="comment-time">#{formatOrderId(comment.orderId)}</div>
            </div>
        );
    };

    renderStatusCommentCell = (comment) => {
        return (
            <div className="comment-title ">
                <div className="comment-content-text"><FormattedMessage id={`comment_${comment.comment}`} /></div>
                <div className="comment-time">{this.getCommentTime(comment.create_at)}</div>
            </div>
        );
    };

    toggleCommentSection = () => {
        let expansionControllers = _.cloneDeep(_.get(this.props, "state.expansionControllers", {}));
        let controller = _.cloneDeep(this.getController());
        controller.showComments = !this.showComments();
        expansionControllers[this.getOrderId()] = controller;
        this.props.setState({
            expansionControllers: expansionControllers,
            activeId: this.getOrderId(),
        });
    };

    renderComments = () => {
        const comments = this.getComments();
        const showComments = this.showComments();
        return (
            <Collapse
                in={showComments}
                collapsedHeight={this.state.collapsedHeight}
                onClick={() => this.toggleCommentSection()}
            >
                {comments.map((comment, i) => {
                    return this.renderCommentCell(comment, i);
                })}
            </Collapse>
        );
    };

    renderAddCommentButton = () => {
        return (
            <div className="add-comment-button-wrapper">
                <div className="add-comment-button-text" onClick={() => this.toggleCommentDialog()}>
                    <FormattedMessage id="add_comment" />
                </div>
            </div>
        );
    };

    renderTab = (key, label, divider) => {
        const selected = this.state.tabKey === key;

        return (
            <div
                className={cx({
                    tab: true,
                    selected: selected,
                })}
                style={{
                    borderRight: divider ? "1px solid rgba(0, 0, 0, 0.1)" : undefined,
                }}
                onClick={() => {
                    if (!selected) {
                        this.setState({ tabKey: key }, () => {
                            this.updateComments(key);
                        });
                    }
                }}
            >
                <FormattedMessage id={label} />
            </div>
        );
    };
    updateComments = (key) => {
        const comments = this.getComments();
        if (key === ORDER_COMMENTS_TAB) {
            this.setState({
                displayedComments: this.getDisplayedComments(comments.find((comment) => this.isOrderComment(comment))),
            });
        }
        if (key === ORDER_HISTORY_TAB) {
            this.setState({
                displayedComments: this.getDisplayedComments(comments.find((comment) => !this.isOrderComment(comment))),
            });
        }
    };

    renderCommentAndHistoryTabs = () => {
        return (
            <div className="comment-history-tab-container">
                {this.renderTab(ORDER_COMMENTS_TAB, "order_comments", true)}
                {this.renderTab(ORDER_HISTORY_TAB, "order_history")}
                {this.state.showExpandIcon && (
                    <div className="expand-icon-container" onClick={this.toggleComments}>
                        {this.state.showComments ? <UpOutlined /> : <DownOutlined />}
                    </div>
                )}
            </div>
        );
    };

    renderOneComment = () => {
        const comments = this.getComments();
        if (comments.length === 1) return <div>{this.renderCommentCell(comments[0])}</div>;
    };

    renderMoreComments = () => {
        const comments = this.getComments();
        if (comments.length > 1) return <div>{this.renderComments()}</div>;
    };

    renderCustomerComment = (comment, create_at) => {
        return (
            <div className="comment-container" key={create_at}>
                <div className="content-container">
                    <div className="name">{this.props.order?.c_nname || this.props.order?.c_name}</div>
                    <div className="message">{comment.comment}</div>
                </div>
                <div className="timestamp text-align-right">{this.getCommentTime(comment.create_at)}</div>
            </div>
        );
    };

    renderAdminComment = (comment) => {
        return (
            <div className="comment-container">
                <div className="timestamp">{this.getCommentTime(comment.create_at)}</div>
                <div className="content-container text-align-right">
                    <div className="name">
                        <FormattedMessage id="admin" />
                    </div>
                    <div className="message">{comment.comment}</div>
                </div>
            </div>
        );
    };

    renderOrderStatusComment = (comment) => {
        return (
            <div className="history-container" key={comment.comment}>
                <div className="content-container">
                    <div className="message"><FormattedMessage id={`comment_${comment.comment}`} /></div>
                </div>
                <div className="timestamp text-align-right">{this.getCommentTime(comment.create_at)}</div>
            </div>
        );
    };

    renderComment = (comment) => {
        switch (comment.is_admin) {
            case CUSTOMER_COMMENT:
                return this.isCommentsTab() ? this.renderCustomerComment(comment, comment.create_at) : null;
            case ADMIN_COMMENT:
                return this.isCommentsTab() ? this.renderAdminComment(comment, comment.create_at) : null;
            case ORDER_STATUS_COMMENT:
                return this.isHistoryTab() ? this.renderOrderStatusComment(comment) : null;
            default:
                return this.isHistoryTab() ? this.renderOrderStatusComment(comment, comment.create_at) : null;
        }
    };

    renderNoCommentsAvailable = () => {
        return (
            <div className="comments-placeholder">
                <FormattedMessage id="no_comments_message" />
            </div>
        );
    };

    renderCommentsSection = () => {
        return <div className="comments-section">{this.state.displayedComments}</div>;
    };

    renderShowLess = () => {
        return (
            <div className="show-less-container">
                <div className="spacing-16" />
                <span className="show-less-text" onClick={this.toggleComments}>
                    <FormattedMessage id="show_less" />
                </span>
            </div>
        );
    };

    renderCommentDialog = () => {
        return <CommentDialog {...this.props} state={this.state} setState={(values) => this.setState(values)} />;
    };

    renderOtherRequest = () => {
        const showOtherRequest = orderShowRequireUtensil(this.props.orderStore, this.props.order);
        if (showOtherRequest) {
            return (
                <div className="other-request-list-item">
                    <Checkbox checked={requestUtensil(this.props.order)}>
                        <FormattedMessage id="require_utensil" />
                    </Checkbox>
                </div>
            );
        }
    };

    render() {
        return (
            <>
                <div className="comments-wrapper">
                    {this.renderOtherRequest()}
                    {this.renderAddCommentButton()}
                    <hr />
                    {this.renderCommentAndHistoryTabs()}
                    <div className="spacing-16" />
                    {this.renderCommentsSection()}
                    {this.state.showExpandIcon && this.state.showComments && this.renderShowLess()}
                </div>
                {this.renderCommentDialog()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderComments));
