import React from "react";
import { View, Text } from "@react-pdf/renderer";

const customTextBox = (
    styles: React.CSSProperties | any,
    props: { customText1: string, customText2: string }
): JSX.Element => {
    return (
        <View style={styles.customTextCont}>
            <View style={styles.CustomtxtHolder}>
                <Text style={styles.CustomtxtBox}>{props.customText1}</Text>
                <Text style={styles.CustomtxtBox}>{props.customText2}</Text>
            </View>
        </View>
    );
};
export default customTextBox;
