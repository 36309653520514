import { Modal, Radio } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import config from "../../../../config";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { changePhone } from "../../../../services/admin-users";
import { setPassword, setShowPassModal, setShowPhoneModal, setUserDetail } from "../../../../slices/admin-users";
import h from "../../helper";
import PasswordModal from "../account-modal/password-modal";
import { SelectOption } from "./../../../../components/form";
import {
    activeStore,
    renderHalfSelectInput,
    renderHalfTxtInput,
    renderHalfTxtSelecPhone,
    renderPasswordInput,
    renderTop,
} from "./account-sections";

const PersonalInformationTab = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const QRCode = require("qrcode.react");
    const countryCode = useSelector((state: RootState) => state?.store?.records?.store_addr?.country_code) ?? "CA";
    const currUser = useSelector((state: RootState) => state?.user);
    const storeInfo = useSelector((state: RootState) => state?.store?.storeInformation);
    const storeName = _.get(storeInfo, `store_nm_${lan}`, "");
    const modalType = useSelector((state: RootState) => state.adminUsers.modalType);
    const user = useSelector((state: RootState) => state.adminUsers?.user);
    const showPhoneModal = useSelector((state: RootState) => state?.adminUsers?.showPhoneModal);
    const showPassModal = useSelector((state: RootState) => state?.adminUsers?.showPassModal);
    const password = useSelector((state: RootState) => state.adminUsers.password);
    const [showWechatModal, setShowWechatModal] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [passView, setPassView] = useState<boolean>(true);
    const notSelf = _.get(user, "id", 0) !== _.get(currUser, "id", 0) ? true : false;
    const ADD_ADMIN = 2;
    const isAdd = modalType === ADD_ADMIN ? true : false;

    const phoneCodes: SelectOption[] = [
        { value: "canada_phone", label: intl.formatMessage({ id: "canada_phone" }) },
        { value: "usa_phone", label: intl.formatMessage({ id: "usa_phone" }) },
    ];

    const setUserState = (name: string, value: any) => {
        dispatch(setUserDetail({ name, value }));
    };

    const toggleIsActive = () => {
        dispatch(setUserDetail({ name: "is_active", value: h.isActive(user?.is_active) ? 0 : 1 }));
    };

    const getPasswordModal = () => (
        <Modal
            width="400px"
            footer={null}
            bodyStyle={{ padding: "10px" }}
            onCancel={() => dispatch(setShowPassModal({ value: false }))}
            visible={showPassModal}
        >
            <PasswordModal />
        </Modal>
    );

    const stepOneURL = "https://weixin.qq.com/r/xijdxT7EV2MorXqm933U";
    const WechatModal = () => (
        <Modal
            width="700px"
            footer={null}
            bodyStyle={{ padding: "10px" }}
            onCancel={() => setShowWechatModal(false)}
            visible={showWechatModal}
        >
            <div className="qr-code-modal-container m-4 d-flex flex-column">
                <div className="payment-subtitle mb-4">
                    <FormattedMessage id="wechat_bind" />
                </div>
                <div className="d-flex flex-wrap justify-space-between">
                    <div className="qr-code-step-container d-flex flex-column justify-content-center mb-3">
                        <div className="payment-description mb-4">
                            <FormattedMessage id="wechat_step_one" />
                        </div>
                        <QRCode value={stepOneURL} size={200} renderAs="svg" />
                    </div>
                    <div className="qr-code-step-container d-flex flex-column justify-content-center">
                        <div className="payment-description mb-4">
                            <FormattedMessage id="wechat_step_two" />
                        </div>
                        <QRCode value={stepOneURL} size={200} renderAs="svg" />
                    </div>
                </div>
            </div>
        </Modal>
    );

    const changePhoneNumber = () => {
        const phone_number = _.get(user, "phone.phone_number", undefined);
        dispatch(
            changePhone({
                phoneInfo: {
                    phone: { area_code: h.getAreaCode(user, "1"), phone_number, country_code: countryCode },
                    vc: verificationCode ?? undefined,
                    action: verificationCode ? h.VERIFY_CODE : h.SEND_VERIFICATION_CODE,
                },
                profile: {
                    vc: verificationCode ?? undefined,
                    phone: { area_code: h.getAreaCode(user, "1"), phone_number },
                    username: _.get(user, "username"),
                    id: _.get(user, "id").toString(),
                },
            })
        );
        setVerificationCode("");
    };

    const PhoneModal = () => (
        <Modal
            width="500px"
            footer={null}
            bodyStyle={{ padding: "20px" }}
            onCancel={() => dispatch(setShowPhoneModal({ value: false }))}
            visible={showPhoneModal}
        >
            {phoneModalContent}
        </Modal>
    );

    const phoneModalContent = (
        <div>
            <div className="payment-title mb-4">
                <FormattedMessage id="change_phone" />
            </div>
            {renderHalfTxtSelecPhone(
                "country_code",
                "phone.area_code",
                h.getAreaCode(user, undefined),
                phoneCodes,
                setUserState
            )}
            {renderHalfTxtInput(
                "phone",
                "phone.phone_number",
                _.get(user, "phone.phone_number", ""),
                setUserState,
                false,
                "tel",
                false
            )}
            <div className="verificationPhone d-flex justify-content-start align-items-start">
                <input
                    type="text"
                    placeholder={intl.formatMessage({ id: "enter_verification_code" })}
                    className="verification-phone-input"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <div className="verification-phone-button" onClick={() => changePhoneNumber()}>
                    {verificationCode ? (
                        <FormattedMessage id="submit_verification_code" />
                    ) : (
                        <FormattedMessage id="send_verification_code" />
                    )}
                </div>
            </div>
        </div>
    );

    const passwordHandler = (name: string, password: any) => {
        dispatch(setPassword({ password }));
    };

    const getPhoneFields = (
        <div
            className="d-flex w100 justify-content-between"
            onClick={() => {
                dispatch(setShowPhoneModal({ value: true }));
            }}
        >
            {renderHalfTxtInput(
                "country_code",
                "phone.area_code",
                h.getAreaCodeStr(user),
                setUserState,
                false,
                "text",
                1,
                undefined,
                "49%"
            )}
            {renderHalfTxtInput(
                "phone",
                "phone.phone_number",
                _.get(user, "phone.phone_number", ""),
                setUserState,
                false,
                "tel",
                1,
                undefined,
                "49%"
            )}
        </div>
    );

    const getPasswordAndWeChat = (
        <div>
            <div
                className="person-layered-block pHover bluishTxt"
                onClick={() => dispatch(setShowPassModal({ value: true }))}
            >
                <FormattedMessage id="change_password" />
            </div>
            <div className="person-layered-block pHover bluishTxt" onClick={() => setShowWechatModal(!showWechatModal)}>
                <FormattedMessage id="wechat_bind" />
            </div>
        </div>
    );

    const lans: any = config.LANGUAGE_MAP;

    const getLanOptions = () =>
        Object.keys(lans ?? {}).map((key: any) => ({
            label: lans[key],
            value: key,
        }));

    const personalInfoFields = (
        <div className="person-layered-block mt-1">
            {renderTop("contact_details", "contact_details_description")}
            <div className="d-flex w100 justify-content-between">
                {renderHalfTxtInput(
                    "username",
                    "username",
                    _.get(user, "username", ""),
                    setUserState,
                    true,
                    "text",
                    0,
                    h.MIN_USERNAME,
                    "49%"
                )}
                {renderHalfTxtInput(
                    "email",
                    "email",
                    _.get(user, "email", ""),
                    setUserState,
                    false,
                    "",
                    0,
                    undefined,
                    "49%"
                )}
            </div>
            <div className="d-flex w100 justify-content-between">
                {renderHalfTxtInput(
                    "first_name",
                    "first_name",
                    _.get(user, "first_name", ""),
                    setUserState,
                    true,
                    "",
                    0,
                    undefined,
                    "49%"
                )}
                {renderHalfTxtInput(
                    "last_name",
                    "last_name",
                    _.get(user, "last_name", ""),
                    setUserState,
                    true,
                    "",
                    0,
                    undefined,
                    "49%"
                )}
            </div>
            {isAdd ? null : getPhoneFields}
            <div className="d-flex w100 justify-content-between">
                {renderHalfTxtInput(
                    "promo_code",
                    "promo_code",
                    _.get(user, "promo_code", intl.formatMessage({ id: "promo_code" })),
                    setUserState,
                    false,
                    undefined,
                    1,
                    undefined,
                    "49%"
                )}
                {isAdd
                    ? renderPasswordInput(
                          "password",
                          "password",
                          password,
                          passwordHandler,
                          passView,
                          setPassView,
                          "49%"
                      )
                    : renderHalfTxtInput(
                          "registration_time",
                          "created",
                          _.get(user, "created", ""),
                          setUserState,
                          false,
                          undefined,
                          1,
                          undefined,
                          "49%"
                      )}
            </div>
            <div className="d-flex w100 justify-content-between">
                {renderHalfSelectInput(
                    "language",
                    "lan",
                    _.get(user, "lan", ""),
                    setUserState,
                    true,
                    getLanOptions(),
                    0,
                    "49%"
                )}

                <div className="person-info-field d-flex flex-column justify-content-start align-items-start is-use-pos-mode">
                    <div className="payment-subtitle">
                        <FormattedMessage id="Admin Create Order Style" />
                    </div>
                    <Radio.Group
                        className="is-use-pos-mode-radio-group"
                        onChange={(e: any) => setUserState("is_use_pos_mode", e?.target?.value)}
                        value={_.get(user, "is_use_pos_mode", config.CREATE_ORDER_POS_MODES.default)}
                    >
                        <Radio value={config.CREATE_ORDER_POS_MODES.pos}>
                            <FormattedMessage id="POS Style" />
                        </Radio>
                        <Radio value={config.CREATE_ORDER_POS_MODES.regular}>
                            <FormattedMessage id="Regular Style" />
                        </Radio>
                    </Radio.Group>
                </div>
            </div>
            {notSelf ? activeStore("active", _.get(user, "is_active", true), storeName, toggleIsActive) : null}
        </div>
    );

    const personalInformation = (
        <React.Fragment>
            {personalInfoFields}
            {isAdd ? null : getPasswordAndWeChat}
            {getPasswordModal()}
            {WechatModal()}
            {PhoneModal()}
        </React.Fragment>
    );

    return personalInformation;
};

export default PersonalInformationTab;
