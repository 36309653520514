import { createSlice } from "@reduxjs/toolkit";

interface StoreManagementState {
    loading: boolean;
    filterDrawer: boolean;
    stores: any[];
    paging: {
        total: number,
    };
    searchConditions?: any;
}

const initialState: StoreManagementState = {
    loading: true,
    filterDrawer: false,
    stores: [],
    paging: {
        total: 0,
    },
};

const slice = createSlice({
    name: "storeManagement",
    initialState,
    reducers: {
        setStoreManagementState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getStoreRecordsSuccess: (state, { payload }) => {
            state.stores = payload?.response?.records;
            state.paging = payload?.response?.paging;
        },
    },
});

export const { setStoreManagementState, getStoreRecordsSuccess } = slice.actions;

export const storeManagement = slice.reducer;
