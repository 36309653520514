import _ from "lodash";
import React, { useEffect, useCallback } from "react";
import locale from "../locale";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import { updateAdminDetails } from "../services/admin";
import _actions from "../slices/live-order";
import { message, Button } from "antd";
import { RootState } from "../app/reducer";

const config = Object.freeze({
    apiKey: "AIzaSyD55oK7zZY_R70CB12yQNTl9iU_jY9axO4",
    authDomain: "goopterbiz.firebaseapp.com",
    databaseURL: "https://goopterbiz.firebaseio.com",
    projectId: "goopterbiz",
    storageBucket: "goopterbiz.appspot.com",
    messagingSenderId: "419233751088",
    appId: "1:419233751088:web:da5116f1fc7bdd3529cdf5",
});

const IS_ADMIN = "1";

export const useNotifications = (): any => {
    const dispatch = useDispatch();

    const { id, bearer, lan } = useSelector((state: RootState) => ({
        token: state?.user?.deviceToken,
        bearer: state?.user?.bearer,
        id: state?.user?.id,
        lan: state?.setting?.lan,
    }));

    const str = useCallback((id: any) => locale.getIntlMessages(lan)[id], [lan]);

    const updateToken = useCallback(
        (device_token: any) =>
            dispatch(
                updateAdminDetails(
                    {
                        profile: {
                            device_token,
                        },
                    },
                    true
                )
            ),
        [dispatch]
    );

    const updateDeviceToken = useCallback(
        (token: any) => {
            if (bearer) {
                updateToken(token);
            }
        },
        [bearer, updateToken]
    );

    const getFirebaseToken = useCallback(
        (messaging: any) => {
            messaging
                .getToken()
                .then(function (currentToken: any) {
                    if (currentToken) {
                        updateDeviceToken(currentToken);
                    } else {
                        // Show permission request.
                    }
                })
                .catch(function () {
                    // Handle error
                });
        },
        [updateDeviceToken]
    );

    const listenForTokenRefresh = useCallback(
        (messaging: any) => {
            messaging.onTokenRefresh(function () {
                messaging
                    .getToken()
                    .then(function (refreshedToken: any) {
                        updateDeviceToken(refreshedToken);
                    })
                    .catch(function () {
                        // Handle error
                    });
            });
        },
        [updateDeviceToken]
    );

    const isMyMessage = useCallback(
        (data: any) => {
            const senderId = String(_.get(data, "notification.body.user_id", ""));
            const isAdmin = String(_.get(data, "notification.body.is_admin", "")) === IS_ADMIN;
            const samePerson = senderId === String(id);
            return samePerson && isAdmin;
        },
        [id]
    );

    const handleMessages = useCallback(
        (payload: string) => {
            try {
                const data = JSON.parse(payload);
                const notiType = _.get(data, "notification.type", "");
                const notiOrderId = _.get(data, "notification.body.id", "");
                const notiCancelledOrderId = _.get(data, "notification.body.id_cancelled", "");
                const shouldUpdateFocus = !_.isEmpty(notiCancelledOrderId);
                if (isMyMessage(data)) return;
                if (notiType === "order") {
                    dispatch(
                        _actions.getLiveOrdersPart({
                            order_ids: [notiOrderId],
                            updateFocus: shouldUpdateFocus,
                            outdatedId: notiCancelledOrderId,
                        })
                    );
                }
            } catch (e) {
                // Handle error
            }
        },
        [dispatch, isMyMessage]
    );

    const listenForMessage = useCallback(() => {
        try {
            navigator.serviceWorker.addEventListener("message", function (event) {
                const messageForegroundPayload = _.get(event, "data.firebaseMessaging.payload.data.data", null);
                const messageBackgroundPayload = _.get(event, "data.data.data", null);
                handleMessages(messageForegroundPayload || messageBackgroundPayload);
            });
        } catch (error) {
            // Handle error
        }
    }, [handleMessages]);

    const handleTokenAndMessage = useCallback(
        (messaging: string) => {
            getFirebaseToken(messaging);
            listenForTokenRefresh(messaging);
            listenForMessage();
        },
        [getFirebaseToken, listenForTokenRefresh, listenForMessage]
    );

    const showAlertMessage = useCallback(() => {
        const content = (
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <span style={{ marginRight: 10 }}>{str("message_disabled_warning")}</span>
                <Button onClick={() => message.destroy()} type="default" className="text-on-white-background">
                    {str("dismiss")}
                </Button>
            </div>
        );
        message.warning(content, 0);
    }, [str]);

    const notify = useCallback(
        (messaging: any) => {
            if (!("Notification" in window)) {
                // Handle browser not supporting notifications
            } else if (Notification.permission === "granted") {
                handleTokenAndMessage(messaging);
            } else if (Notification.permission === "denied") {
                showAlertMessage();
            }
        },
        [handleTokenAndMessage, showAlertMessage]
    );

    const initFCM = useCallback(() => {
        if (!firebase?.apps?.length) {
            const instance = firebase.initializeApp(config);

            try {
                notify(instance.messaging());
            } catch (e) {
                // Handle error
            }
        }
    }, [notify]);

    useEffect(() => {
        initFCM();
    }, [initFCM]);
};

export default useNotifications;
