import { Row, Col } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import helper from "../../../../util/helper";
import cx from "classnames";
import { setReferralsState } from "../../../../slices/referrals";
import { getCustomerGiftcards } from "../../../../services/customers";
import { setGiftcardState } from "../../../../slices/giftcards";
import { getCustomerId } from "./helper";

const SERVER_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

class CustomerSummaryInfo extends Component {

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCustomer = () => this.props.customer;

    getCid = (props = this.props) => getCustomerId(props);

    renderNameAndSendMessage = () => {
        const firstName = _.get(this.getCustomer(), "firstname");
        const lastName = _.get(this.getCustomer(), "lastname");
        const fullName = `${firstName ? `${firstName} ` : ""}${lastName || ""}`;
        return (
            <div className="customer-name-send-message">
                <div className="customer-name">{fullName}</div>
            </div>
        );
    };

    renderAddressAndSince = () => {
        //address
        const defaultAddress = _.get(this.getCustomer(), "default_address", {});
        const addressArray = Object.values(_.pick(defaultAddress, ["city", "region", "cntry"])).filter(
            (value) => value
        );
        //since
        const createdAt = _.get(this.getCustomer(), "created_at");
        const formattedTime = moment(createdAt, SERVER_TIME_FORMAT).isValid()
            ? moment(createdAt, SERVER_TIME_FORMAT).local().format("hh:mm a, MMM DD, YYYY")
            : this.str("unknown");
        return (
            <div className="customer-address-and-since">
                {addressArray.length ? <div className="customer-addess">{addressArray.join(", ")}</div> : ""}
                <div className="since">
                    <FormattedMessage id="customer_since" values={{ time: formattedTime }} />
                </div>
            </div>
        );
    };

    renderNote = () => {
        const note = _.get(this.getCustomer(), "customer_note");
        return (
            <div className="customer-note">
                <div className="customer-note-title">{<FormattedMessage id="customer_note" />}</div>
                <div className="customer-note-content">{note || <FormattedMessage id="no_note_added" />}</div>
            </div>
        );
    };

    renderDivider = () => {
        return <div className="customer-section-divider" />;
    };

    renderOrderSummary = () => {
        const points = _.get(this.getCustomer(), "points_balance");
        const numGiftcards = _.get(this.getCustomer(), "num_giftcards");
        const customer = this.props.customer;

        const getLastOrderTime = () => {
            const lastOrderTime = _.get(this.getCustomer(), "last_ord_dt");
            const formatTime = helper.utcToLocal(lastOrderTime, {
                type: "calendar",
                local: true,
            });
            return _.get(formatTime.split("@"), 0, "").trim();
        };

        const getPointsBalance = () => {
            const p2v = this.props.p2v || 1;
            return p2v == 0 ? 0 : points / p2v;
        };

        const datas = [
            { mainInfo: getLastOrderTime() || "N/A", label: "last_order" },
            {
                mainInfo: helper.formatCurrency(_.get(this.getCustomer(), "total_spent"), this.props.storeCurrency),
                label: "total_spent",
                subInfo: `${helper.formatNumber(_.get(this.getCustomer(), "ord_cnt", 0))} * ${helper.formatCurrency(
                    _.get(this.getCustomer(), "avg_spent"),
                    this.props.storeCurrency
                )}`,
            },
            {
                mainInfo: helper.formatNumber(points),
                label: "points_balance",
                subInfo: helper.formatCurrency(getPointsBalance(), this.props.storeCurrency),
                onClick: points
                    ? () => {
                        this.props.setReferralsState({
                            transactionsSearchConditions: {
                                customer_id: this.getCid(),
                            },
                        });
                    }
                    : null,
            },
            {
                mainInfo: helper.formatNumber(numGiftcards),
                label: "giftcards",
                subInfo: helper.formatCurrency(
                    _.get(this.getCustomer(), "giftcards_balance"),
                    this.props.storeCurrency
                ),
                onClick: numGiftcards
                    ? () => {
                        this.props.getCustomerGiftcards(
                            {
                                c_id: customer?.id,
                            },
                            () =>
                                this.props.setGiftcardState({
                                    showCustomerGiftCardsModal: true,
                                    customerFirstName: customer?.firstname,
                                    customerLastName: customer?.lastname,
                                    customerPhone: customer?.phone,
                                    customerEmail: customer?.email,
                                })
                        );
                    }
                    : null,
            },
        ];

        const renderSectionInfo = (data) => {
            return (
                <Col span={6}>
                    <div onClick={() => data.onClick && data.onClick()} className="order-summary-col">
                        <div className="order-summary-main">{data.mainInfo}</div>
                        <div
                            className={cx({
                                "order-summary-label": true,
                                "order-summary-label-link": data.onClick,
                            })}
                        >
                            <FormattedMessage id={data.label || " "} />
                        </div>
                        <div className="order-summary-subInfo">{data.subInfo || ""}</div>
                    </div>
                </Col>
            );
        };

        return (
            <div className="order-summary">
                <Row gutter={8}>{datas.map((data) => renderSectionInfo(data))}</Row>
            </div>
        );
    };

    render() {
        return (
            <div className="customer-detail-section customer-summary">
                <div className="section-padding ">
                    {this.renderNameAndSendMessage()}
                    {this.renderAddressAndSince()}
                    {this.renderNote()}
                </div>
                {this.renderDivider()}
                <div className="section-padding ">{this.renderOrderSummary()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    p2v: state.store?.storeBasic?.points_to_value,
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setReferralsState,
    getCustomerGiftcards,
    setGiftcardState,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerSummaryInfo)));
