/**
 * Opens new window to print sales report content
 */
export const handlePrintReport = (): boolean => {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    const elem = document.getElementById("sales-operation-sales-report")!;
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    const mywindow = window.open('', 'PRINT', 'height=600,width=800')!;
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write(`<style>
            .sales-operation-sales-report {
                width: 100%;
                padding: 10px 15px;
                font-size: 12px;
                font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
                color: rgba(0, 0, 0, 0.85);
            }

            .header-report {
                font-size: 0.875rem;
                font-weight: 700;
                height: 35px;
                text-align: center;
                border-bottom: 1px solid #dee2e6;
            }
        
            .report-date-text {
                font-size: 12px;
                font-weight: 700;
            }
        
            .text-align-start {
                text-align: start
            }
        
            .text-align-center {
                text-align: center
            }
        
            .text-align-end {
                text-align: end
            }
        
            .larger-text {
                font-size: 0.875rem;
            }
        
            .bold {
                font-weight: 700;
            }
        
            .col-2 {
                float: left;
                width: 8%;
                padding: 0;
            }
        
            .ant-col-3, .col-3 {
                float: left;
                width: 12%;
            }
        
            .col-3x {
                float: left;
                width: 14%;
            }
        
            .col-3xx {
                float: left;
                width: 18%;
            }
        
            .ant-col-4, .col-4 {
                float: left;
                width: 16%;
            }
        
            .col-4x {
                float: left;
                width: 20%;
            }
        
            .col-4xx {
                float: left;
                width: 24%;
            }
        
            .col-5 {
                float: left;
                width: 20%;
            }
        
            .col-5x {
                float: left;
                width: 23%;
            }
        
            .col-5xx {
                float: left;
                width: 14%;
            }
        
            .col-5xxx {
                float: left;
                width: 17%;
            }
        
            .col-6 {
                float: left;
                width: 25%;
            }
        
            .col-7 {
                float: left;
                width: 30%;
            }
        
            .col-8 {
                float: left;
                width: 33%;
            }
        
            .col-12 {
                float: left;
                width: 50%;
            }
        
            .col-24 {
                float: left;
                width: 100%;
            }
        
            .ant-row, .row {
                margin-right: 0;
                margin-left: 0;
                width: 100%;
                display: table;
            }
        
            .ant-row:after, .row:after {
                content: "" ;
                display: table;
                clear: both ;
            }
        
            .border-line-dashed {
                border-bottom: 1px dashed #dee2e6;
            }
        
            .border-line-solid {
                border-bottom: 1px solid #dee2e6;
            }
        
            .border-line-double {
                border-bottom: 3px double #dee2e6;
            }
        
            .border-line-none {
                border-bottom: 0px double #dee2e6;
            }
        </style>`)
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1 class="text-align-center">' + document.title + '</h1>');
    mywindow.document.write(elem.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}