import { Button, Descriptions, Form, Input } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import {
    getDescriptionItem,
    getGoogleDataOptions,
    getSelectInput,
    getTextInput,
} from "../../../../../../components/form";
import usePlacesAutocomplete from "../../../../../../hooks/usePlacesAutoComplete";
import { getStoreDetails, updateStoreDetails } from "../../../../../../services/store";
import ObjectModel from "../../../../../../util/models";
import StoreGeneralInfo from "../../../../../../util/models/store-general-info";
import config from "../../../../../../config";
import { selectWithPlaceId } from "../../../../../../util/helper/google";
import { Prompt, useLocation } from "react-router-dom";
import { MIN_PHONE_LEN, MAX_PHONE_LEN } from "./constants";
import h from "util/helper";

function StoreInformationTab(props: { lan: string }): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const storeInformation = useSelector((state: RootState) => state.store?.storeInformation);
    const currUser = useSelector((state: RootState) => state?.user);
    const records = useSelector((state: RootState) => state.store?.records);
    const {
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete();
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        setTouched(false);
        dispatch(updateStoreDetails(new ObjectModel(StoreGeneralInfo).convertToPostData(data, records, "store_info")));
    };

    const handleAddressSearch = (val: string) => {
        setValue(val);
    };

    const handleAddressSelect = (val: string) => {
        selectWithPlaceId(val, (address: any) => {
            const components = address?.address_components ?? [];
            form.setFieldsValue({
                address_country_code: components.find((c: any) => c?.types?.includes("country"))?.short_name,
                address_city: components.find((c: any) => c?.types?.includes("locality"))?.short_name,
                address_province: components.find((c: any) => c?.types?.includes("administrative_area_level_1"))
                    ?.short_name,
                address_postal_code: components.find((c: any) => c?.types?.includes("postal_code"))?.short_name,
                address_lat: address?.geometry?.location?.lat?.(),
                address_lon: address?.geometry?.location?.lng?.(),
                address_street: `${components.find((c: any) => c?.types?.includes("street_number"))?.short_name} ${
                    components.find((c: any) => c?.types?.includes("route"))?.short_name
                }`,
            });

            const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${address?.geometry?.location?.lat?.()},${address?.geometry?.location?.lng?.()}&timestamp=${Math.round(
                new Date().getTime() / 1000
            ).toString()}&key=${config.GOOGLE_API_KEY}`;

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    form.setFieldsValue({
                        address_time_zone: data?.timeZoneId,
                    });
                });
        });
    };

    const generalInfoSection = () => (
        <div className="grouped-inputs">
            <div style={{ paddingTop: 14 }}>
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="store_info" />
                </h1>
                <p className="ant-descriptions-description">
                    <FormattedMessage id="store_information_description" />
                </p>
            </div>
            {config.LANGUAGES.map((lan) => (
                <Descriptions key={lan} style={{ display: lan !== props.lan ? "none" : "block" }} bordered>
                    {getDescriptionItem("store_name", 3, getTextInput({ name: "store_nm_" + lan, allowClear: true }))}
                    {getDescriptionItem(
                        "store_description",
                        3,
                        getTextInput({ name: "store_desc_" + lan, rows: 5, allowClear: true })
                    )}
                </Descriptions>
            ))}
        </div>
    );

    const contactInfoSection = () => (
        <Descriptions title={intl.formatMessage({ id: "contact_information" })} className="general-section" bordered>
            {getDescriptionItem("owner", 3, getTextInput({ name: "owner", allowClear: true }))}
            {getDescriptionItem(
                "phone",
                3,
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: "phone_number_required" }),
                        },
                        {
                            min: MIN_PHONE_LEN,
                            message: intl.formatMessage(
                                { id: "phone_number_length_invalid" },
                                {
                                    minlen: MIN_PHONE_LEN,
                                    maxlen: MAX_PHONE_LEN,
                                }
                            ),
                        },
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: "type_here" })} maxLength={MAX_PHONE_LEN} />
                </Form.Item>
            )}
        </Descriptions>
    );

    const addressSection = () => (
        <Descriptions title={intl.formatMessage({ id: "business_address" })} className="general-section" bordered>
            {getDescriptionItem(
                "address",
                3,
                getSelectInput(
                    "address",
                    true,
                    getGoogleDataOptions(data ?? []),
                    handleAddressSearch,
                    handleAddressSelect
                )
            )}
            {getDescriptionItem("street", 3, getTextInput({ name: "address_street", disabled: true }))}
            {getDescriptionItem("country", 3, getTextInput({ name: "address_country_code", disabled: true }))}
            {getDescriptionItem("city", 3, getTextInput({ name: "address_city", disabled: true }))}
            {getDescriptionItem("province", 3, getTextInput({ name: "address_province", disabled: true }))}
            {getDescriptionItem("unit", 3, getTextInput({ name: "address_unit", allowClear: true }))}
            {getDescriptionItem("postal_code", 3, getTextInput({ name: "address_postal_code", allowClear: true }))}
            {getDescriptionItem("time_zone", 3, getTextInput({ name: "address_time_zone", disabled: true }))}
            {getDescriptionItem("latitude", 3, getTextInput({ name: "address_lat", allowClear: true }))}
            {getDescriptionItem("longitude", 3, getTextInput({ name: "address_lon", allowClear: true }))}
        </Descriptions>
    );

    const urlInfoSection = () => (
        <Descriptions title={intl.formatMessage({ id: "website_information" })} className="general-section" bordered>
            {getDescriptionItem(
                "website_url",
                3,
                getTextInput({ name: "url", disabled: !h.isSuperUser(currUser), allowClear: true })
            )}
            {getDescriptionItem(
                "mobile_url",
                3,
                getTextInput({ name: "mobile_url", disabled: !h.isSuperUser(currUser), allowClear: true })
            )}
        </Descriptions>
    );

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const handleValuesChanged = (values: any) => {
        if (values?.phone) {
            // validate phone number and remove any non-digits
            const formattedNumber = values.phone.trim().replaceAll(/[^0-9]/g, "");
            form.setFieldsValue({ phone: formattedNumber });
        }
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
    }, [location, form]);

    return !_.isEmpty(storeInformation) ? (
        <React.Fragment>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="general-settings has-floating-submit">
                <Form
                    form={form}
                    name="general-settings"
                    onFinish={onFinish}
                    initialValues={storeInformation}
                    onValuesChange={handleValuesChanged}
                >
                    {generalInfoSection()}
                    {urlInfoSection()}
                    {contactInfoSection()}
                    {addressSection()}
                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreInformationTab;
