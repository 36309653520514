import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const initialTableProps = {
    ruleModalOpen: false,
    handling_fee: 0,
    country: "CA",
    region: undefined,
    postal: undefined,
    price: 0,
};

const initialState: any = {
    tableProps: initialTableProps,
    C2CProps: {
        express: true,
        ruleModalOpen: false,
        country: undefined,
        min_day: undefined,
        max_day: undefined,
        price: undefined,
    },
};

const slice = createSlice({
    name: "shipping",
    initialState,
    reducers: {
        fillTableProps: (state, { payload }) => {
            state.tableProps = payload;
        },
        fillC2CProps: (state, { payload }) => {
            state.C2CProps = payload;
        },
        clearC2CProps: (state) => {
            state.C2CProps.country = "US";
            state.C2CProps.min_day = undefined;
            state.C2CProps.max_day = undefined;
            state.C2CProps.price = undefined;
            state.C2CProps.index = undefined;
        },
        clearTableProps: (state) => {
            state.tableProps = initialTableProps;
        },
        setTableProps: (state, { payload }) => {
            const surCopy = { ...state.tableProps };
            _.set(surCopy, payload?.name, payload?.value);
            state.tableProps = surCopy;
        },
        setC2CProps: (state, { payload }) => {
            const surCopy = { ...state.C2CProps };
            _.set(surCopy, payload?.name, payload?.value);
            state.C2CProps = surCopy;
        },
    },
});

export const { clearTableProps, clearC2CProps, fillC2CProps, fillTableProps, setTableProps, setC2CProps } =
    slice.actions;

export const shipping = slice.reducer;
