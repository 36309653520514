import { Button, Input } from "antd";
import React from "react";
import { BiSliderAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import qs from "querystring";
import { GIFT_CARD_TYPE } from "../../constants";
import FilterTags from "../../_components/filter-tags";
import { getProducts } from "../../../../services/products";
import RefreshButton from "../../../../components/refresh";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";
import { setGiftcardState } from "../../../../slices/giftcards";

function GiftCardActionBar(props: any = {}): JSX.Element {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const search = qs.parse(location.search.slice(1)) ?? {};
    const dispatch = useDispatch();
    const { lan, categories, loading, giftcardsState } = useSelector((state: RootState) => ({
        giftcardsState: state?.giftcards,
        categories: state?.categories?.categories,
        lan: state?.setting?.lan,
        loading: state?.products?.loading,
    }));

    const getSearch = (page?: any, limit?: any) => {
        return {
            product_type: GIFT_CARD_TYPE,
            ...search,
            paging: {
                page: page ?? search?.page ?? 1,
                limit: limit ?? search?.limit ?? DEFAULT_PAGE_SIZE,
            },
        };
    };

    const onChange = (e: any) => {
        const value = e.target.value;
        const newState = { ...giftcardsState };
        const query: any = { ...search };
        query.product_name = value;

        newState.oldSearch = query;

        dispatch(setGiftcardState({ ...newState }));

        history.push("/giftCards?" + qs.stringify(query));
    };

    const addGiftCard = () => {
        history.push({ pathname: "/giftCards/addEditGiftCard" });
    };

    const getFilterTags = () => <FilterTags search={search} categories={categories} lan={lan} />;

    const refreshPage = () => {
        dispatch(getProducts(getSearch()));
    };

    return (
        <div>
            <div className="gift-card-action-bar">
                <div className="gift-card-action-bar-left">
                    <Input
                        allowClear
                        size="large"
                        placeholder={intl.formatMessage({ id: "search_dot" })}
                        onChange={onChange}
                        value={search?.product_name}
                    />
                    <Button
                        className="filter-button"
                        icon={<BiSliderAlt size={20} />}
                        size="large"
                        onClick={() => props.setFilter(true)}
                    >
                        <div className="icon-button-text d-inline">
                            <FormattedMessage id="filters" />
                        </div>
                    </Button>
                </div>
                <div className="gift-card-action-bar-right">
                    <RefreshButton refreshPage={refreshPage} loading={loading} />
                    <Button type="primary" size="large" onClick={addGiftCard} className="add-button-handler">
                        <FormattedMessage id="add_gift_card" />
                    </Button>
                </div>
            </div>
            {getFilterTags()}
        </div>
    );
}

export default GiftCardActionBar;
