import React from "react";
import { Button, Input } from "antd";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import h from "../helper";
import { BsStarHalf, BsStar, BsStarFill } from "react-icons/bs";
import { BiCommentDetail } from "react-icons/bi";

function emptyFunction() {
    // does nothing
}

const COLOURS_NUM = 9;
const colours = ["5c328f", "516fea", "e1158c", "136915", "c03fb1", "c8501f", "0a5b6f", "8766b6", "148386"];

export const revExists = (review: any = []): JSX.Element =>
    (review && !Array.isArray(review?.name)) || (Array.isArray(review?.name) && !_.isEmpty(review?.name));

export const getRating = (stars: number): JSX.Element => (
    <div className="d-flex ml-2">
        {[...Array(5)].map((s: number, i: number) =>
            stars <= i ? (
                <BsStar color="#F1C212" size="12" />
            ) : stars > i + 0.5 ? (
                <BsStarFill color="#F1C212" size="12" />
            ) : (
                <BsStarHalf color="#F1C212" size="12" />
            )
        )}
    </div>
);

export const showProductReplyField = (reviewID: number, pID: number, currReview: any = []): boolean => {
    return currReview?.full?.oid === reviewID && currReview?.specific === pID;
};

export const showServiceReplyField = (reviewID: number, type: string, currReview: any = [], scope: number): boolean => {
    return scope === h.ORDER_SCOPE
        ? currReview?.full?.oid === reviewID && currReview?.type === type
        : currReview?.full?.cid === reviewID && currReview?.type === type;
};

export const getInitials = (name: string): string => {
    let initials = name.charAt(0).toUpperCase();
    for (let i = 0; i < name?.length; i++) {
        if (name[i] === " " && i < name.length - 2 && initials.length < 2) {
            initials += name?.charAt(i + 1).toUpperCase();
        }
    }
    return initials;
};

export const getCancelButton = (setCurrReview: any = emptyFunction): JSX.Element => (
    <Button className="mr-2" onClick={() => setCurrReview(undefined)}>
        {" "}
        <FormattedMessage id="cancel" />{" "}
    </Button>
);

export const getTextfield = (currComment: string | undefined, setCurrComment: any = emptyFunction): JSX.Element => (
    <Input.TextArea
        className="custom-input-textfield ta-l w100 mb-2"
        rows={4}
        value={currComment}
        onChange={(e: any) => setCurrComment(e.target.value)}
    />
);

export const getStoreProfile = (storeName: string): JSX.Element => (
    <div className="profile-picture-section">
        <div className="store-profile-picture">{getInitials(storeName)}</div>
    </div>
);

export const sendProductButton = (
    review: string,
    customerReview: any[],
    sendProductReview: any = emptyFunction
): JSX.Element => (
    <Button
        className="mr-2 text-on-white-background"
        type="default"
        onClick={() => sendProductReview(review, customerReview)}
    >
        <FormattedMessage id="send" />
    </Button>
);

export const sendServiceButton = (
    customerReview: string,
    type: string,
    sendServiceReview: any = emptyFunction
): JSX.Element => (
    <Button
        className="mr-2 text-on-white-background"
        type="default"
        onClick={() => sendServiceReview(customerReview, type)}
    >
        <FormattedMessage id="send" />
    </Button>
);

export const replyHandler = (
    props: any = {},
    setCurrReview: any = emptyFunction,
    setCurrComment: any = emptyFunction
): void => {
    setCurrReview(props);
    if (props?.type) {
        setCurrComment(_.get(props, `full.s_review.${props?.type}.reply.cmt`, ""));
    } else {
        setCurrComment(_.get(props, `full.p_review`, []).find((p: any) => p?.pid === props?.specific)?.reply?.cmt);
    }
};

export const getCustomerProfile = (name: string, i: number): JSX.Element => (
    <div className="profile-picture-section">
        <div className="profile-picture" style={{ background: `#${colours[i % COLOURS_NUM]}` }}>
            {getInitials(name)}
        </div>
    </div>
);

export const renderEmptyWarn = (
    <div className="review-empty-warn w100">
        <BiCommentDetail size="60" color="lightgrey" className="mb-2" />
        <FormattedMessage id="no_reviews" />
    </div>
);
