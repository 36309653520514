import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import config from "config";
import moment from "moment";
import { setState as setAppModalState } from "slices/app-modal";
import { ASAP } from "app/constants";

export const initialCustomerRelatedState = {
    selectedGiftCard: {},
    pointsUsed: 0,
    pointsDialogUsed: 0,
    address: {},
    paymentMethod: null,
    activePaymentKey: null,
};

export const initialPriceRelatedState = {
    payment: {
        tips: 0,
        surCharge: 0,
        subTotal: 0,
        tax: 0,
        storeDiscountValue: null,
        storeDiscountType: null,
        totalDiscount: 0,
        couponDiscount: 0,
        grandTotal: 0,
        shippingFee: 0,
    },
    displayPriceInfo: {},
};

export const noItemsPaymentState = {
    tips: 0,
};

export const quickAddModalState = {
    quickAddModalSearchInput: "",
    shouldReloadProducts: false,
};

const initialState: any = {
    qid: null,
    items: [],
    ...initialCustomerRelatedState,
    ...initialPriceRelatedState,
    ...quickAddModalState,
    shippingMethod: null,
    notes: null,
    storeDiscountNotes: "",
    allowedPeriods: [],
    deliveryDisplayDate: "",
    deliveryDate: "",
    deliveryTime: "",
    tipsConfirm: false,
    partySize: 1,
    tableNumber: "",
    initialData: null,
    saveButtonClicked: false,
    isSaveButtonEnabled: false,
    shouldRedirectPage: false,
    itemChangeObject: null,
    itemChanges: [],
    gp_id: null,
};

export const initialCustomerState = initialState;

export const MIN_POINTS_AMOUNT = 5;

/**
 * Get the list of reformatted item sub options of an item option
 *
 * @param opt - an option of item
 * @returns a list of reformatted item sub options
 */
const getReformattedSubOptions = (opt: any) => {
    const reformattedSubOptions: any = [];
    if (Array.isArray(_.get(opt, "opts"))) {
        _.get(opt, "opts").map((subOpt: any) => {
            reformattedSubOptions.push({
                id: _.get(subOpt, "id"),
            });
        });
    }
    return reformattedSubOptions;
};

/**
 * Get the list of reformatted options of an item
 *
 * @param item - an item in the current cart
 * @returns a list of reformatted item options
 */
const getReformattedItemOptions = (item: any) => {
    const reformattedOptions: any = [];
    if (Array.isArray(_.get(item, "opts"))) {
        _.get(item, "opts").map((opt: any) => {
            reformattedOptions.push({
                id: _.get(opt, "id"),
                opts: getReformattedSubOptions(opt),
            });
        });
    }
    return reformattedOptions;
};

/**
 * Check the item and responseItem are same options
 *
 * @param item - an item in the current cart
 * @param reponseItem - an item from cart total api
 * @returns true if they have same options
 */
const hasSameItemOptions = (item: any, responseItem: any) => {
    return _.isEqual(getReformattedItemOptions(item), getReformattedItemOptions(responseItem));
};

const slice = createSlice({
    name: "createOrder",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state) => {
            Object.assign(state, initialState);
        },
        updateTips: (state, { payload }) => {
            const { amount, tipsType } = payload;
            const amountNemeric = _.isNaN(amount) ? 0 : Number(amount);
            state.payment.tips = amountNemeric;
            state.payment.tipsType = tipsType;
            state.payment.tipsPercent = amountNemeric;
            state.payment = _.get(_.clone(state), "payment", {});
            state.tipsConfirm = true;
        },
        updateDisplayPrice: (state, { payload }) => {
            //update tips
            const updateReason = _.get(payload, "updateReason");
            const store = _.clone(_.get(state, `store`, {}));
            const payment = _.get(state, "payment");
            const tipsType = _.get(payment, "tipsType", "");
            const tipsPercentage = _.get(payment, "tipsPercent", 0);
            const subTotal = _.get(payment, "subTotal", 0);
            const shippingFee = _.get(payment, "shippingFee", 0);
            const tax = _.get(payment, "tax", 0);
            const storeDiscountValue = _.get(payment, "storeDiscountValue", null);
            const storeDiscountType = _.get(payment, "storeDiscountType", null);

            if (tipsType === "percent") {
                payment.tips = _.round((subTotal + shippingFee) * (tipsPercentage * 0.01), 2);
                state.payment = payment;
            }

            let storeDiscountAmount = 0;
            if (storeDiscountType === config.STORE_DISCOUNT_TYPES.percentage) {
                storeDiscountAmount = _.round(subTotal * (storeDiscountValue * 0.01), 2);
            } else {
                storeDiscountAmount = storeDiscountValue;
            }

            const orderTotal = _.round(subTotal + shippingFee + payment.tips + tax - storeDiscountAmount, 2);
            let totalPaid = 0,
                pointsPaid = 0,
                giftCardPaid = 0,
                tips = 0;

            //update points payment
            const p2v = store?.storeBasic?.points_to_value;
            const selectedGiftCard = state.selectedGiftCard;
            if (!_.isEmpty(selectedGiftCard)) {
                //logic added to only allow points or giftcard
                state.pointsUsed = 0;
                state.pointsDialogUsed = 0;
            } else if (orderTotal < MIN_POINTS_AMOUNT) {
                state.pointsUsed = 0;
                state.pointsDialogUsed = 0;
            } else if (p2v > 0 && state.pointsUsed >= 0) {
                const remainingOrderTotal = orderTotal - totalPaid;
                pointsPaid = parseFloat(state.pointsUsed) / parseFloat(p2v);
                const amountDecrease = pointsPaid > remainingOrderTotal;
                const pointsBalance = state.payment.pointsBalance;

                const usingPoints = state.paymentMethod === config.PAYMENT_MAPPING_TO_NUMBERIC.points; //points payment
                const amountIncrease = remainingOrderTotal > pointsPaid;
                const balanceSuffice = parseFloat(pointsBalance) / parseFloat(p2v) > remainingOrderTotal;
                const shouldPayMoreWithPoints = amountIncrease && balanceSuffice && usingPoints;
                const notUpdateByPoints = updateReason !== "pointUsedIsDiff";
                if ((shouldPayMoreWithPoints || amountDecrease) && notUpdateByPoints) {
                    const newPointsUsed = String(remainingOrderTotal * 100);
                    state.pointsUsed = parseInt(newPointsUsed);
                    pointsPaid = parseFloat(newPointsUsed) / parseFloat(p2v);
                }
                totalPaid += pointsPaid;
            }

            //update giftcard payment
            if (state.pointsUsed) {
                //logic added to only allow either points or giftcard
                state.selectedGiftCard = null;
            } else if (selectedGiftCard && orderTotal > totalPaid) {
                const remainingOrderTotal = orderTotal - totalPaid;
                if (remainingOrderTotal > parseFloat(selectedGiftCard.balance)) {
                    totalPaid += parseFloat(selectedGiftCard.balance);
                    giftCardPaid = selectedGiftCard.balance;
                } else if (remainingOrderTotal <= parseFloat(selectedGiftCard.balance)) {
                    totalPaid += parseFloat(String(remainingOrderTotal));
                    giftCardPaid = remainingOrderTotal;
                }
            }

            // payment method change if total paid for the whole order total
            const paymentMap = config.PAYMENT_MAPPING_TO_NUMBERIC;
            if (state.items.length > 0 && orderTotal === 0) {
                state.paymentMethod = paymentMap.free; //free
                tips = 0;
            } else if (totalPaid === orderTotal) {
                state.card = initialState.card;
                if (pointsPaid && giftCardPaid) {
                    state.paymentMethod = paymentMap.points_and_giftcard;
                    state.activePaymentKey = null;
                } else if (pointsPaid > 0) {
                    state.paymentMethod = paymentMap.points;
                    state.activePaymentKey = null;
                } else if (giftCardPaid) {
                    state.paymentMethod = paymentMap.giftcard;
                    state.activePaymentKey = null;
                }
            } else if (
                [paymentMap.giftcard, paymentMap.points, paymentMap.points_and_giftcard].includes(
                    state.paymentMethod
                ) ||
                (orderTotal > 0 && state.paymentMethod === paymentMap.free)
            ) {
                state.paymentMethod = null;
                state.activePaymentKey = null;
            }

            const totalDue = orderTotal - totalPaid > 0 ? orderTotal - totalPaid : 0;
            const totalBeforeTax = orderTotal - tax - tips - totalPaid > 0 ? orderTotal - tax - tips - totalPaid : 0;

            state.displayPriceInfo.orderTotal = _.round(orderTotal, 2);
            state.displayPriceInfo.totalBeforeTax = _.round(totalBeforeTax, 2);
            state.displayPriceInfo.pointsPayment = _.round(pointsPaid, 2);
            state.displayPriceInfo.giftcardPayment = _.round(giftCardPaid, 2);
            state.displayPriceInfo.totalPaid = _.round(totalPaid, 2);
            state.displayPriceInfo.storeDiscountAmount = _.round(storeDiscountAmount, 2);
            state.displayPriceInfo.totalDue = _.round(totalDue, 2);
        },
        saveDeliveryTime: (state, { payload }) => {
            const { name, value } = payload;
            if (name === "deliveryTime") {
                state.deliveryTime = value;
                state.deliveryDate = state.deliveryDisplayDate;
                state.displayDeliveryTimePicker = false;
            } else if (name === "deliveryDisplayDate") {
                state.deliveryDisplayDate = value;
            } else if (name === "deliveryDate") {
                state.deliveryDisplayDate = value;
                state.deliveryDate = value;
            }
        },
        checkoutCartTotalSuccess: (state, { payload }) => {
            const oos: any = payload?.records?.outofstock;
            const disabled: any = payload?.records?.disabled;
            const records = payload?.records;
            const oosItems: any = [];
            const responseItems: any = payload?.records?.items;

            let newItems = _.clone(state.items);

            //handle store record
            state.store = payload?.store;

            //handle first cart total response
            if (_.isNil(state.qid)) {
                state.draftOrderCreatedAt = moment.utc().toString();
            }

            state.qid = records?.qid;
            state.notes = records?.notes ?? state.notes;
            state.storeDiscountNotes = records?.store_dis_notes ?? state.storeDiscountNotes;

            //handle payment
            if (records) {
                state.payment = {
                    ..._.get(state, "payment", {}),
                    tips: records?.tips,
                    surCharge: records?.surcharge,
                    subTotal: records?.subtotal,
                    tax: records?.tax,
                    grandTotal: records?.g_total,
                    giftCardsCount: records?.gc_count,
                    pointsBalance: records?.p_bal,
                };
            }

            //set up pickup location period
            if (!_.isEmpty(records?.pickup_locations) && !_.isEqual(state.pickupLocations, records?.pickup_locations)) {
                state.pickupLocations = records?.pickup_locations;
                const pickupLocation = _.get(state.pickupLocations, "0", {});
                const id = _.get(pickupLocation, "id");
                const times = _.isEmpty(_.get(pickupLocation, "timeslots")) ? {} : _.get(pickupLocation, "timeslots");
                const date = _.get(Object.keys(times), "0", "");
                const time = _.get(times, `${date}.0`, "");
                state.pickupLocationId = id;
                state.allowedPeriods = times;
                state.deliveryDisplayDate = date;
                state.deliveryDate = date;
                state.deliveryTime = time;
            } else if (_.isEmpty(records?.pickup_locations)) {
                state.pickupLocations = []
                state.pickupLocation = {}
                state.pickupLocationId = null;
            }

            //set up allowed period
            if (!_.isEmpty(_.get(records, "allowed_periods"))) {
                const allowedPeriods = _.get(records, "allowed_periods", {});
                if (Object.keys(allowedPeriods).length && !_.isEqual(state.allowedPeriods, allowedPeriods)) {
                    const firstDate = Object.keys(allowedPeriods)[0];
                    const firstDatePeriod = allowedPeriods[firstDate];
                    // settings default delivery/pickup time to first date first time, usually the ASAP field.
                    state.allowedPeriods = allowedPeriods;
                    //set display date to asap or full date of first available period
                    if (firstDate && firstDatePeriod) {
                        state.deliveryDisplayDate = firstDate;
                        state.deliveryDate = firstDate;
                        state.deliveryTime = firstDatePeriod[0];
                    }
                } else if (Object.keys(allowedPeriods).length === 0) {
                    state.allowedPeriods = [];
                    state.deliveryDisplayDate = "";
                    state.deliveryDate = "";
                    state.deliveryTime = ASAP;
                }
            }

            //handle oos
            if (_.isObject(oos)) {
                Object.keys(oos).forEach((id: string) => {
                    const index = _.findIndex(newItems, (item: any) => Number(item.pid) === Number(id));
                    //@ts-ignore
                    const reducedQty = oos[id];
                    if (index !== -1) {
                        const oldItem = newItems[index];
                        newItems[index] = {
                            ...oldItem,
                            qty: reducedQty,
                        };
                        oosItems.push(_.get(oldItem, "nm"));
                    }
                });
            }
            //handle disable
            if (Array.isArray(disabled)) {
                disabled.forEach((id: any) => {
                    const index = _.findIndex(newItems, (item: any) => Number(item.pid) === Number(id));
                    if (index !== -1) {
                        const oldItem = newItems[index];
                        newItems[index] = {
                            ...oldItem,
                            qty: 0,
                        };
                        oosItems.push(_.get(oldItem, "nm"));
                    }
                });
            }
            //display oos message
            if (oosItems.length) {
                setAppModalState({
                    visible: true,
                    title: "warning",
                    content: "item_oos",
                    code: "with-values",
                    additionalInfo: { oos_items: oosItems.join(",") },
                });
            }
            // if the response Items have item_id, update the item_id of items in the cart
            if (Array.isArray(responseItems)) {
                responseItems.forEach((responseItem: any) => {
                    const index = _.findIndex(newItems, (item: any) => {
                        return Number(item.pid) === Number(responseItem.pid) && hasSameItemOptions(item, responseItem);
                    });
                    if (index !== -1) {
                        const oldItem = newItems[index];
                        newItems[index] = {
                            ...oldItem,
                            item_id: responseItem?.item_id,
                        };
                    }
                });
            }

            newItems = newItems.filter((item: any) => item?.qty > 0);
            state.items = newItems;

            //re-init initial data
            state.initialData = _.cloneDeep(state);
        },
    },
});

export const { setState, clearData, checkoutCartTotalSuccess, updateTips, updateDisplayPrice, saveDeliveryTime } =
    slice.actions;

export const createOrder = slice.reducer;
