import { InputNumber, Modal } from "antd";
import React, { useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "app/reducer";
import { createMessage } from "components/message";
import { getGiftcards, manageGiftCard } from "services/giftcards";
import { setGiftcardState, updateGiftcardBalance } from "slices/giftcards";
import { CHARGE_CREDIT } from "../../constants";
import helper, { formatCurrency } from "util/helper";

const selector = (state: RootState) => ({
    giftcardaccount_id: state?.giftcards?.chargeId,
    oldSearch: state?.giftcards?.oldSearch,
    maxAmount: state?.giftcards?.maxAmount,
});

function GiftCardChargeModal(): JSX.Element {
    const [value, setValue] = useState<string | number | undefined>(0);
    const { giftcardaccount_id, oldSearch, maxAmount } = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(setGiftcardState({ chargeId: "" }));
    };

    const handleOk = () => {
        if (Number(value) > Number(maxAmount)) {
            const maxAmountRounded = formatCurrency(maxAmount ?? 0);
            Modal.confirm({
                title: intl.formatMessage({ id: "insufficient_balance" }),
                content: intl.formatMessage({ id: "insufficient_balance_message" }, { maxAmountRounded }),
            });
        } else {
            dispatch(
                manageGiftCard(
                    {
                        giftcardaccount_id,
                        action: CHARGE_CREDIT,
                        amount: value,
                    },
                    () => {
                        dispatch(
                            updateGiftcardBalance({
                                updatedGifitcardAccountId: giftcardaccount_id,
                                amount: -Number(value),
                            })
                        );
                        dispatch(setGiftcardState({ chargeId: "" }));
                        dispatch(getGiftcards(oldSearch));
                        toast(
                            createMessage(
                                intl.formatMessage({ id: "successfully_charged_giftcard" }, { value }),
                                HiInformationCircle
                            )
                        );
                    }
                )
            );
        }
    };

    return (
        <Modal
            visible={giftcardaccount_id ? true : false}
            onCancel={handleClose}
            title={<FormattedMessage id="charge" />}
            onOk={handleOk}
        >
            <InputNumber
                style={{ width: "100%" }}
                value={Number(value)}
                formatter={(value: any) => helper.formatMoney(value, intl)}
                parser={(value: any) => helper.parseMoney(value, intl)}
                onChange={(value) => setValue(typeof value === "number" ? value : value?.replace?.(/[^0-9.]/g, ""))}
            />
        </Modal>
    );
}

export default GiftCardChargeModal;
