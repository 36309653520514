import { Button, Dropdown, Menu, Modal } from "antd";
import _ from "lodash";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { HiDotsHorizontal, HiMenu } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import AddEditOption from "./add-edit-option";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import useAddEditOption from "./useAddEditOption";
import useCallbackModal from "../../../../../../hooks/useCallbackModal";
import config from "../../../../../../config";
import { getOptionsChangeSuccess } from "../../../../../../slices/products";
import { useDispatch } from "react-redux";
function emptyFunction() {
    // does nothing
}

interface OptionsTabProps {
    options: any;
    setOptions: any;
    lan: string;
}

function OptionsTab(props: OptionsTabProps): JSX.Element {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { options, setOptions } = props;
    const [selectedOption, setSelectedOption] = useState<any>(undefined);
    const addEditOptionsProps = useAddEditOption();
    const { openModal, closeModal, getModal } = useCallbackModal("options-modal", () => {
        setSelectedOption(undefined);
    });
    const { lan, languages, products } = useSelector((state: RootState) => ({
        product: state?.products?.product,
        lan: state?.setting?.lan,
        languages: state?.store?.storeDisplayOptions?.product_display_languages,
        products: state?.products,
    }));

    const t = (id: any) => intl.formatMessage({ id });

    const renderNameColumn = (_: any, record: any) => (
        <div>
            {/* if addeditoptionsduplicate exists then use that instead of value inside record. As record is original values not updated */}
            {products?.addEditItemOptionDuplicate?.nm?.[lan ?? ""]
                ? products?.addEditItemOptionDuplicate?.nm?.[lan ?? ""]
                : record?.nm?.[lan ?? ""]}
        </div>
    );

    const renderOptionsColumn = (_: any, record: any) => (
        <div>{record?.opts?.map((opt: any) => opt?.nm?.[lan ?? ""]).join(", ")}</div>
    );

    const renderType = (_: any, record: any) => <div>{t(record?.type ?? " ")}</div>;

    const handleEdit = (record: any) => {
        setSelectedOption(record);
        openModal();
    };

    const renderMenu = (value: any, record: any) => {
        const menu = () => (
            <Menu>
                <Menu.Item onClick={() => handleEdit(record)}>
                    <FormattedMessage id="modify_options" />
                </Menu.Item>
                <Menu.Item
                    onClick={(menu) => {
                        menu.domEvent.stopPropagation();
                        onDelete(record?.id);
                    }}
                    danger
                >
                    <FormattedMessage id="remove_from_item" />
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu()}>
                <Button type="link">
                    <HiDotsHorizontal />
                </Button>
            </Dropdown>
        );
    };

    const getColumns = (): ColumnsType<any> => [
        { render: renderNameColumn },
        { render: renderOptionsColumn },
        { render: renderType },
        { render: renderMenu },
    ];

    const getFooter = () => (
        <div className="options-footer">
            <Button
                onClick={() => {
                    openModal();
                }}
            >
                <FormattedMessage id="add_option" />
            </Button>
        </div>
    );

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        backgroundColor: isDragging ? "lightgreen" : "white",
        ...draggableStyle,
    });

    const onDragEnd = (result: DropResult) => {
        setOptions((prev: any) => {
            const list = _.cloneDeep(prev);
            const [removed] = list.splice(result.source?.index, 1);
            list.splice(result.destination?.index ?? 0, 0, removed);
            return list.map((item: any, i: number) => ({
                ...item,
                id: i + 1,
            }));
        });
    };

    const renderOptions = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div ref={provided.innerRef}>
                        {options.map((option: any, i: number) => (
                            <Draggable key={option.id} draggableId={option.id.toString()} index={i}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        className="option-row"
                                        onClick={() => handleEdit(option)}
                                    >
                                        <div className="draggable-indicator">
                                            <HiMenu />
                                        </div>
                                        {getColumns().map((column, i) => (
                                            <div key={`option-col-${i}`} className={"option-col-" + i}>
                                                {column?.render?.("", option, i)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {getFooter()}
        </DragDropContext>
    );

    const handleClose = () => {
        setSelectedOption(undefined);
        closeModal();
    };

    const onDelete = (id: any) => {
        Modal.confirm({
            title: t("confirm_delete"),
            content: t("are_you_sure_delete_option"),
            onOk: () => {
                setOptions([
                    ...options
                        .filter((opt: any) => opt.id !== id)
                        .map((opt: any, i: number) => ({
                            ...opt,
                            id: i + 1,
                        })),
                ]);
            },
        });
    };

    const onSaveOption = (option: any, callBack = emptyFunction) => {
        let originalValues: any;
        if (!option.id) {
            option.id = options.length ? options.length + 1 : 1;
        } else {
            originalValues = options.find((opt: any) => Number(opt?.id) === option?.id);
        }

        const getSameNames = () => {
            const same: any = {};
            languages.forEach((language: any) => {
                if (option?.nm?.[language] && option?.nm?.[language] === originalValues?.nm?.[language]) {
                    same[language] = option?.nm?.[language];
                }
            });
            return same;
        };

        const getNewNames = () => {
            const newNames: any = {};
            languages.forEach((language: any) => {
                if (option?.nm?.[language] && option?.nm?.[language] !== originalValues?.nm?.[language]) {
                    newNames[language] = option?.nm?.[language];
                }
            });
            return newNames;
        };

        const sameNames = getSameNames();
        const newNames = getNewNames();

        let valid = option?.nm?.[languages?.find((language: any) => option?.nm?.[language])] ?? "";
        languages.forEach((language: string) => {
            /**
             * TODO: Fix Type Error
             */
            // if (!_.isEmpty(option?.nm?.[language]) && !_.isEmpty(valid)) {
            //     Object.assign(option?.nm[language], valid);
            // }

            option?.opts?.forEach((opt: any, i: number) => {
                valid =
                    option?.opts?.[i]?.nm?.[languages?.find((language: any) => option?.opts?.[i]?.nm?.[language])] ??
                    "";

                if (!option?.opts?.[i]?.nm?.[language] && !_.isEmpty(option.opts[i].nm[language])) {
                    option.opts[i].nm[language] = valid;
                }
            });
        });

        const submit = () => {
            let newOptions = [...options];

            if (newOptions.find((no: any) => no.id === option.id)) {
                newOptions[newOptions.findIndex((no: any) => no.id === option.id)] = option;
            } else {
                newOptions = [...newOptions, option];
            }
            setOptions(newOptions);
            //update options redux when submit
            dispatch(getOptionsChangeSuccess(newOptions));
            callBack();
            handleClose();
        };

        if (!_.isEmpty(sameNames) && !_.isEmpty(newNames)) {
            const lans = Object.keys(sameNames)?.join(", ");
            const values = lans
                .split(", ")
                .map((l) => `"${sameNames?.[l]}"`)
                .join(", ");
            const changedLans = Object.keys(newNames)?.join(", ");
            const changed = changedLans
                .split(", ")
                .map((l) => `"${newNames?.[l]}"`)
                .join(", ");
            const languagesFullNames = Object.keys(sameNames)
                ?.map((lan) => {
                    // @ts-ignore
                    return config.LANGUAGES_FULL_NAME[lan];
                })
                ?.join(", ");
            const changedLanguagesFullNames = Object.keys(newNames)
                ?.map((lan) => {
                    // @ts-ignore
                    return config.LANGUAGES_FULL_NAME[lan];
                })
                ?.join(", ");
            Modal.confirm({
                title: intl.formatMessage({ id: "option_title" }),
                content: intl.formatMessage(
                    { id: "option_title_language_content" },
                    { languagesFullNames, changedLanguagesFullNames, values, changed }
                ),
                onOk() {
                    submit();
                },
            });
        } else {
            submit();
        }
    };

    const onCancelAddEditOption = (close: any) => {
        if (!_.isEqual(addEditOptionsProps.addEditOptionState, selectedOption)) {
            Modal.confirm({
                title: intl.formatMessage({ id: "unsaved_changes" }),
                content: intl.formatMessage({ id: "alert_leaving_without_save" }),
                onOk() {
                    handleClose();
                    close();
                },
            });
        } else {
            handleClose();
            close();
        }
    };

    return (
        <div className="item-options-tab">
            {renderOptions()}

            {/* Modals */}
            {getModal(
                <AddEditOption
                    lan={props.lan}
                    option={selectedOption}
                    onSaveOption={onSaveOption}
                    {...addEditOptionsProps}
                    dispatchAddEditOptionState={addEditOptionsProps.dispatchAddEditOptionState}
                    addEditOptionState={addEditOptionsProps.addEditOptionState}
                />,
                {
                    title: t("add_edit_option"),
                    onCancel: onCancelAddEditOption,
                    footer: null,
                    width: 1028,
                }
            )}
        </div>
    );
}

export default OptionsTab;
