import { createSlice } from "@reduxjs/toolkit";
import { AppModalData } from "models/app-modal";

const initialState: AppModalData = {
    visible: false,
};

const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setState: (state, data: { payload: AppModalData }) => {
            state = Object.assign(state, data.payload);
        },
        closeModal: (state) => {
            Object.keys(state).forEach((key) => {
                if (key !== "visible") {
                    //@ts-ignore
                    delete state[key];
                } else {
                    //@ts-ignore
                    state[key] = false;
                }
            });
        },
    },
});

export const { setState, closeModal } = slice.actions;
export const modal = slice.reducer;
