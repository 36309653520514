import { Switch, Button } from "antd";
import _ from "lodash";
import { isBool, bin2Number, num2Binary, strToCharArray } from "../../.././../util/helper";
import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import { getStoreDetails, updateStoreDetails } from "./../../../../services/store";
import { setCheckoutState } from "./../../../../slices/store";
import ObjectModel from "./../../../../util/models";
import CheckoutModel from "../../../../util/models/store-checkout";
import { Prompt } from "react-router-dom";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/checkout", breadcrumbName: "checkout_settings" },
    ],
};

const perms: any = {
    IN_STORE: 0,
    PICKUP: 1,
    DELIVERY: 2,
    INSTANT_PAY: 3,
    INSTANT_CHECKOUT: 4,
};

const App = (): JSX.Element | null => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const checkoutSettings = useSelector((state: RootState) => state.store?.storeCheckout);
    const flag = useSelector((state: RootState) => state.store?.storeCheckout?.guest_checkout);
    const offFlag = useSelector((state: RootState) => state.store?.storeCheckout?.offline_payment);
    const records = useSelector((state: RootState) => state.store?.records);
    const [options, setOptions] = useState<boolean[]>([]);
    const [original, setOriginal] = useState(undefined);
    const [offline, setOffline] = useState<boolean[]>([]);
    const [origOffline, setOrigOffline] = useState(undefined);
    const [online, setOnline] = useState<boolean[]>([]);

    const setBinaryOptions = useCallback((binary: any) => {
        const newOptions: any = [];
        const binaries = num2Binary(binary, Object.keys(perms).length);
        const array = strToCharArray(binaries);
        Object.keys(perms).forEach((perm: any, i: any) => {
            newOptions.push(isBool(array[i]));
        });
        setOptions(newOptions);
    }, []);

    const setOfflineOptions = useCallback((binary: any) => {
        const newOffline: any = [];
        const binaries = num2Binary(binary, Object.keys(perms).length);
        const array = strToCharArray(binaries);
        Object.keys(perms).forEach((perm: any, i: any) => {
            newOffline.push(isBool(array[i]));
        });
        setOffline(newOffline);
    }, []);

    const setOnlineOfflineOptions = () => {
        const numOfPerms = Object.keys(perms).length;
        const binary = num2Binary(offFlag, numOfPerms * 2);
        const onlineBins = [];
        const offlineBins = [];
        for (let i = 0; i < numOfPerms * 2; i++) {
            if (i < numOfPerms) {
                onlineBins.push(Boolean(parseInt(binary[i])));
            } else {
                offlineBins.push(Boolean(parseInt(binary[i])));
            }
        }

        setOnline(onlineBins);
        setOffline(offlineBins);
    };

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);
    useEffect(() => {
        setBinaryOptions(flag);
        setOnlineOfflineOptions();
        if (original === undefined && flag) {
            setOriginal(flag);
        }
        if (origOffline === undefined && offFlag) {
            setOrigOffline(offFlag);
        }
    }, [checkoutSettings, original, flag, origOffline, offFlag, setBinaryOptions, setOfflineOptions]);

    const onSave = () => {
        setOriginal(flag);
        setOrigOffline(offFlag);
        dispatch(
            updateStoreDetails(
                new ObjectModel(CheckoutModel).convertToPostData(checkoutSettings, records, "checkout_settings")
            )
        );
    };

    const handleSwitch = (e: any, perm: number, name: string, onlinePayment?: boolean) => {
        let binaryArr: any[] = [];
        const isGuestCheckout = name === "guest_checkout";
        if (isGuestCheckout) {
            binaryArr = _.cloneDeep(options);
        } else {
            online.forEach((b) => {
                binaryArr.push(b);
            });
            offline.forEach((b) => {
                binaryArr.push(b);
            });
        }
        if (onlinePayment || isGuestCheckout) {
            binaryArr[perm] = e;
        } else {
            binaryArr[perm + Object.keys(perms).length] = e;
        }

        dispatch(setCheckoutState({ name, value: bin2Number(binaryArr) }));
    };

    const getSwitch = (perm: number, name: string) => (
        <div className="d-flex mb-4 switch-container" key={perm}>
            <div className="mr-3 switch-title">
                <FormattedMessage id={name ?? " "} />
            </div>
            <div className="d-flex">
                <div className="switch-holder">
                    <Switch checked={options[perm]} onChange={(e) => handleSwitch(e, perm, "guest_checkout")} />
                </div>
                <div className="switch-holder">
                    <Switch checked={offline[perm]} onChange={(e) => handleSwitch(e, perm, "offline_payment")} />
                </div>
                <div className="switch-holder">
                    <Switch checked={online[perm]} onChange={(e) => handleSwitch(e, perm, "offline_payment", true)} />
                </div>
            </div>
        </div>
    );

    const renderSwitches = (
        <div className="w100">
            <div className="header mb-3">
                <div className="text-holder">
                    <FormattedMessage id="guest_checkout" />
                </div>
                <div className="text-holder">
                    <FormattedMessage id="offline_payment" />
                </div>
                <div className="text-holder">
                    <FormattedMessage id="block_online_payment" />
                </div>
            </div>
            {Object.keys(perms)
                .filter((perm: any) => isNaN(perm))
                .map((perm: any, index: number) => getSwitch(index, perm.toLowerCase()))}
        </div>
    );

    const checkChange = () => {
        return Number(flag) !== Number(original) || Number(offFlag) !== Number(origOffline);
    };

    return !_.isEmpty(checkoutSettings) ? (
        <Wrapper helmet={{ title: "checkout_settings" }} breadcrumb={breadcrumb} paddingBottom={true}>
            <Prompt when={checkChange()} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="guest-checkout-page white-layered-block">{renderSwitches}</div>
            <div className="setting-actions">
                <Button
                    type="primary"
                    size="large"
                    onClick={() => onSave()}
                    className="save-button-handler"
                    disabled={parseInt(flag) === Number(original) && parseInt(offFlag) === Number(origOffline)}
                >
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        </Wrapper>
    ) : null;
};

export default App;
