import { Button, Col, Descriptions, Form, Row, Select, Steps } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import Wrapper from "../../components/wrapper";
import { StoreSignUpStep } from "./constants";
import _ from "lodash";
import "./index.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { getDescriptionItem } from "../../components/form";
import useStoreSignUp from "./_hooks/useStoreSignUp";

const breadcrumb = {
    routes: [
        {
            path: "storeSignUp",
            breadcrumbName: "store_sign_up",
        },
    ],
};

function StoreSignUpPage(): JSX.Element {
    const intl = useIntl();
    const { step, errors, control, steps, records, currentPlan, setStep, submitForReview, disableSubmit } =
        useStoreSignUp();

    const renderSteps = () => {
        return (
            <Steps current={step}>
                <Steps.Step title={intl.formatMessage({ id: "price_plan_contact_details" })} />
                <Steps.Step title={intl.formatMessage({ id: "general_information" })} />
                <Steps.Step title={intl.formatMessage({ id: "store_type_categories" })} />
            </Steps>
        );
    };

    const renderCols = (input: any, k: number, col: any) => {
        return col !== null && !input.hide ? (
            <Col key={`input-${input?.name}`} className="signup-col">
                <Form.Item
                    name={input?.name}
                    label={intl.formatMessage({ id: input?.name ?? " " })}
                    help={getErrorMessage(errors?.[input?.name]?.message)}
                    validateStatus={errors?.[input?.name]?.message && "error"}
                >
                    <Controller
                        as={input?.component}
                        control={control}
                        name={input?.name}
                        size="large"
                        {...input?.props}
                    >
                        {input?.options?.length
                            ? input?.options.map((option: any) => {
                                  return (
                                      <Select.Option key={option.value} value={option.value}>
                                          {option.label}
                                      </Select.Option>
                                  );
                              })
                            : null}
                    </Controller>
                </Form.Item>
            </Col>
        ) : (
            <Col key={k} />
        );
    };

    const renderRows = (col: any, j: number, row: any) =>
        row !== null ? (
            <Row gutter={18} key={`row-${j}`}>
                {col.map((input: any, k: number) => renderCols(input, k, col))}
            </Row>
        ) : null;

    const renderStepsGroups = (row: any, i: number) => (
        <div className="signup-form-group" key={`section-${i}`}>
            {row.map((col: any, j: number) => renderRows(col, j, row))}
        </div>
    );

    const renderStepsContent = () => {
        return steps.map((inputs: any, stepNumber: any) => (
            <div key={stepNumber} style={{ display: step === stepNumber ? "block" : "none" }}>
                {inputs.map(renderStepsGroups)}
            </div>
        ));
    };

    const renderButtons = () => {
        return (
            <div className="submit-step-btns">
                {step > StoreSignUpStep.PricePlanContactDetails ? (
                    <Button onClick={() => setStep(step - 1)} type="primary">
                        <FormattedMessage id="back" />
                    </Button>
                ) : null}
                {step < StoreSignUpStep.StoreTypeCategories ? (
                    <Button onClick={() => setStep(step + 1)} type="primary">
                        <FormattedMessage id="next" />
                    </Button>
                ) : null}
                {/* {step === StoreSignUpStep.StoreTypeCategories ? <Button type="primary" onClick={handleSubmit(onSubmit)}><FormattedMessage id="submit" /></Button> : null} */}
                {records?.g_id && step === StoreSignUpStep.StoreTypeCategories ? (
                    <Button type="primary" onClick={submitForReview} disabled={disableSubmit}>
                        <FormattedMessage id={disableSubmit ? "pending_for_review" : "submit_application"} />
                    </Button>
                ) : null}
            </div>
        );
    };

    const getErrorMessage = (message: any) => {
        return message ? (
            <React.Fragment>{intl.formatMessage({ id: message?.split(" ")?.[0] + "_error" })}.</React.Fragment>
        ) : null;
    };

    const renderCurrentPricePlan = () =>
        !_.isEmpty(currentPlan) && step === StoreSignUpStep.PricePlanContactDetails ? (
            <div className="grouped-inputs general-section" style={{ marginBottom: 18 }}>
                <div className="form-title-description">
                    <h1 className="ant-descriptions-title">
                        <FormattedMessage id="price_plan" />
                    </h1>
                    <p className="ant-descriptions-description">
                        <FormattedMessage id="price_plan_description" />
                    </p>
                </div>
                <Descriptions bordered>
                    {getDescriptionItem("name", 3, currentPlan?.name)}
                    {getDescriptionItem("details", 3, currentPlan?.details)}
                    {getDescriptionItem("setup_fee", 3, currentPlan?.setup_fee)}
                    {getDescriptionItem("monthly_fee", 3, currentPlan?.monthly_fee)}
                    {getDescriptionItem("maximum_languages", 3, currentPlan?.lan_count)}
                </Descriptions>
            </div>
        ) : null;

    return (
        <Wrapper helmet={{ title: "store_sign_up" }} breadcrumb={breadcrumb} paddingBottom={72}>
            <Form className="store-signup-form" layout="vertical">
                {renderSteps()}
                {renderStepsContent()}
                {renderCurrentPricePlan()}
                {renderButtons()}
            </Form>
        </Wrapper>
    );
}

export default StoreSignUpPage;
