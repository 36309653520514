import { Button, Dropdown, Menu, Modal } from "antd";
import React from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { manageStore } from "../../../../../services/store-management";
import { StoreStatus } from "../../../../store-sign-up/constants";

function ReferralsActionRenderer(props: any = {}): JSX.Element {
    const dispatch = useDispatch();
    const intl = useIntl();
    const records = useSelector((state: RootState) => state?.storeManagement?.stores);
    const search = useSelector((state: RootState) => state?.storeManagement?.searchConditions);
    const record: any = records?.find((r: any) => r?.signup_id === props?.value);

    const reject = () => {
        dispatch(
            manageStore(
                {
                    sid: record?.signup_id,
                    action: StoreStatus.Pending,
                },
                search
            )
        );
    };

    const approve = () => {
        dispatch(
            manageStore(
                {
                    sid: record?.signup_id,
                    action: StoreStatus.Approved,
                },
                search
            )
        );
    };

    const deleteStore = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: "confirm_delete" }),
            content: intl.formatMessage({ id: "are_you_sure_delete_store" }),
            onOk() {
                dispatch(
                    manageStore(
                        {
                            sid: record?.signup_id,
                            action: StoreStatus.Delete,
                        },
                        search
                    )
                );
            },
        });
    };

    const isDisabled =
        Number(record?.status) === StoreStatus.Approved || Number(record?.status) === StoreStatus.Pending;

    const menu = () => (
        <Menu>
            <Menu.Item onClick={approve} disabled={isDisabled}>
                <FormattedMessage id="approve" />
            </Menu.Item>
            <Menu.Item onClick={reject} disabled={isDisabled} danger>
                <FormattedMessage id="reject" />
            </Menu.Item>
            <Menu.Item onClick={deleteStore} disabled={isDisabled} danger>
                <FormattedMessage id="delete" />
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="d-flex flex-row">
            <Dropdown overlay={menu()}>
                <Button type="link">
                    <HiDotsHorizontal size="20" />
                </Button>
            </Dropdown>
        </div>
    );
}

export default ReferralsActionRenderer;
