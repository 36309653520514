import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { RootState } from "app/reducer";
import { SelectOption } from "components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal } from "antd";
import { getProducts } from "services/products";
import { getCategories } from "services/categories";
import useLanguageSelect from "hooks/useLanguageSelect";
import AssignItemsModal from "components/assign-items-modal";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAsOptions } from "pages/categories/helper";
import { getProductsAsOptions } from "pages/products/helper";
import { updateCoupon, validateCouponCode } from "services/discounts";
import {
    setCreate,
    setCurrDisc,
    clearCurrDisc,
    removeSelectedProduct,
    setSelProdOptions,
    setState,
} from "slices/discounts";
import {
    renderHalfDateInput,
    renderHalfTxtInput,
    renderHalfSwitch,
    renderHalfNumInput,
    renderHalfTxtSelec,
    renderFullMultiSel,
    renderMockMultiSel,
    renderHalfTextInput,
} from "./form-functions";
import "../../discounts.scss";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
});

function CreateModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    const { categories, products } = useSelector(categoriesPageSelector);
    const discountCodes = useSelector((state: RootState) => state.discounts?.storeDiscountCodes);
    const currCode = useSelector((state: RootState) => state.discounts?.currentDisc);
    const selectProds = useSelector((state: RootState) => state.discounts?.selectedProducts);
    const selectOptions = useSelector((state: RootState) => state.discounts?.selProdSkus);
    const createOpen = useSelector((state: RootState) => state.discounts?.create);
    const {
        errorTitle = "",
        errorMessage = "",
        invalidCouponCode = false,
    } = useSelector((state: RootState) => state.discounts);
    const gid = useSelector((state: RootState) => state.store?.storeInformation?.g_id);
    const enable = _.get(currCode, "status");
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);

    useEffect(() => {
        dispatch(getProducts({ paging: { limit: 1000 } }));
        dispatch(getCategories());
    }, [dispatch]);

    const setCurr = useCallback(
        (name: string, value: any) => {
            dispatch(setCurrDisc({ name, value }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (isNaN(enable)) {
            setCurr("status", 1);
            setCurr("from_date", moment().format("YYYY-MM-DD"));
            setCurr("type", "by_percent");
            setCurr("condition.type", "all");
            setCurr("discount.amount", 10);
        }
    }, [enable, setCurr, createOpen]);

    useEffect(() => {
        if (errorTitle && errorMessage) {
            Modal.error({
                visible: errorTitle && errorMessage,
                title: intl.formatMessage({ id: errorTitle }),
                content: intl.formatMessage({ id: errorMessage }),
                onOk: () => {
                    dispatch(setState({ errorTitle: "", errorMessage: "" }));
                },
            });
        }
    }, [errorTitle, errorMessage]);

    const discountTypes: SelectOption[] = [
        { value: "by_percent", label: intl.formatMessage({ id: "by_percent" }) },
        { value: "by_fixed", label: intl.formatMessage({ id: "by_fixed" }) },
        { value: "cart_fixed", label: intl.formatMessage({ id: "cart_fixed" }) },
    ];

    const byType: SelectOption[] = [
        { value: "all", label: intl.formatMessage({ id: "apply_to_all" }) },
        { value: "sku", label: intl.formatMessage({ id: "by_products" }) },
        { value: "category_ids", label: intl.formatMessage({ id: "by_categories" }) },
    ];

    const onSaveAssign = (selected: any[]) => {
        const options = selected.map((prd) => prd.sku) ?? [];
        dispatch(setSelProdOptions([...options]));
        setShowAssignItemsModal(false);
    };

    const getConditions = (apply_to_products: any) => {
        const { category_ids } = currCode;
        const cond = {
            condition: {
                type: _.get(currCode, "condition.type", "all"),
                list: [],
                min_amt: _.get(currCode, "condition.min_amt", 0),
            },
        };
        cond.condition.list =
            String(_.get(currCode, "condition.type", "")) === "category_ids"
                ? category_ids ?? []
                : String(_.get(currCode, "condition.type", "")) === "sku"
                ? apply_to_products ?? []
                : [];
        return cond;
    };

    const checkFilled = () => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        const unique = intl.formatMessage({ id: "use_unique_code" });
        let filled = true;

        if (!currCode.to_date) {
            Modal.error({ title: intl.formatMessage({ id: "missing_to_date" }), content: req });
            filled = false;
        } else if (!currCode.discount) {
            Modal.error({ title: intl.formatMessage({ id: "discount_missing" }), content: req });
            filled = false;
        } else if (!currCode.name) {
            Modal.error({ title: intl.formatMessage({ id: "name_missing" }), content: req });
            filled = false;
        } else if (!currCode.from_date) {
            Modal.error({ title: intl.formatMessage({ id: "missing_from_date" }), content: req });
            filled = false;
        } else if (!currCode.condition) {
            Modal.error({ title: intl.formatMessage({ id: "name_missing" }), content: req });
            filled = false;
        } else if (!currCode?.product_id && Array.isArray(discountCodes)) {
            discountCodes.forEach((code: any) => {
                if (code.code === currCode.code) {
                    Modal.error({ title: intl.formatMessage({ id: "code_exists" }), content: unique });
                    filled = false;
                }
            });
        }

        return filled;
    };

    const saveCode = () => {
        if (!checkFilled()) {
            return;
        }
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        const {
            condition,
            code,
            name,
            discount,
            from_date,
            to_date,
            category_ids,
            per_coupon,
            per_customer,
            publicly_available,
        } = currCode;
        let { type, status } = currCode;
        let hasErrors = false;
        if (!status) {
            setCurr("status", 1);
        }
        if (String(condition?.type) === "sku" && _.isEmpty(selectOptions)) {
            Modal.error({ title: intl.formatMessage({ id: "products_missing" }), content: req });
            hasErrors = true;
        } else if (String(condition?.type) === "category_ids" && _.isEmpty(category_ids)) {
            Modal.error({ title: intl.formatMessage({ id: "categories_missing" }), content: req });
            hasErrors = true;
        }
        if (hasErrors) {
            return;
        }

        const cond = getConditions(selectOptions);
        type = type ?? "by_percent";
        status = status ?? 2;
        dispatch(
            updateCoupon({
                add: true,
                coupon: {
                    product_type: 4,
                    product_id: 0,
                    publicly_available,
                    type,
                    ...cond,
                    name,
                    discount,
                    from_date,
                    to_date,
                    apply_to_products: selectOptions,
                    category_ids,
                    code,
                    per_coupon,
                    per_customer,
                    status,
                },
            })
        );
    };

    const clearCode = () => {
        dispatch(clearCurrDisc());
        dispatch(setCreate({ value: false }));
    };

    const deselect = (e: any) => {
        dispatch(removeSelectedProduct({ value: e }));
    };

    const renderActionButtons = () => {
        return (
            <div className="filter-modal-footer d-flex justify-content-between">
                <Button type="link" onClick={() => clearCode()}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button type="primary" onClick={() => saveCode()}>
                    <FormattedMessage id="save_discount_code" />
                </Button>
            </div>
        );
    };

    const requiredWarn = (
        <div className="required-warn-cont d-flex w100">
            {" "}
            <FormattedMessage id="required_fields_warn" />
        </div>
    );

    const renderAssignItemsModal = () => {
        return showAssignItemsModal ? (
            <AssignItemsModal
                title={<FormattedMessage id="assigning_items" />}
                onSave={onSaveAssign}
                onCancel={() => setShowAssignItemsModal(false)}
                selectedProducts={selectProds}
            />
        ) : null;
    };

    const renderCreateForm = () => (
        <div className="filter-modal d-flex flex-wrap justify-content-start align-items-start">
            {requiredWarn}
            {renderHalfTxtInput("discount_name", `name.${lan}`, _.get(currCode, `name.${lan}`, ""), setCurr, true)}
            {renderHalfTextInput({
                title: "discount_code",
                name: "code",
                value: currCode?.code ?? "",
                onChange: setCurr,
                onBlur: () => {
                    if (currCode?.code) {
                        dispatch(
                            validateCouponCode({
                                data: {
                                    gid: gid,
                                    coupon_cd: currCode?.code,
                                    lan,
                                },
                            })
                        );
                    }
                },
                errorMessage: invalidCouponCode ? "code_exists" : "",
            })}
            {renderHalfDateInput("date_start", "from_date", currCode?.from_date ?? null, setCurr, true)}
            {renderHalfDateInput("date_expire", "to_date", currCode?.to_date ?? null, setCurr, true)}
            {renderHalfSwitch("publicly_available", "publicly_available", currCode?.publicly_available, setCurr)}
            {renderHalfSwitch("enable", "status", currCode?.status, setCurr)}
            {renderHalfNumInput(
                "uses_per_coupon",
                "per_coupon",
                currCode?.per_coupon ?? NaN,
                setCurr,
                false,
                "use_per_coup_tip"
            )}
            {renderHalfNumInput(
                "uses_per_customer",
                "per_customer",
                currCode?.per_customer ?? NaN,
                setCurr,
                false,
                "use_per_cust_tip"
            )}
            {renderHalfTxtSelec(
                "discount_type",
                "type",
                currCode?.type,
                discountTypes,
                setCurr,
                true,
                "discount_type_tip"
            )}
            {renderHalfNumInput(
                "discount_amount",
                "discount.amount",
                _.get(currCode, "discount.amount", NaN),
                setCurr,
                true
            )}
            {renderHalfNumInput(
                "min_order_amount",
                "condition.min_amt",
                _.get(currCode, "condition.min_amt", NaN),
                setCurr,
                false,
                "min_amount_tip"
            )}
            {renderHalfTxtSelec(
                "discount_rule",
                "condition.type",
                _.get(currCode, "condition.type", undefined),
                byType,
                setCurr,
                true
            )}
            {String(currCode?.condition?.type) === "category_ids"
                ? renderFullMultiSel(
                      "apply_to_categories",
                      "category_ids",
                      currCode?.category_ids,
                      getCategoriesAsOptions(categories, lan),
                      setCurr
                  )
                : null}
            {String(currCode?.condition?.type) === "sku"
                ? renderMockMultiSel(
                      "apply_to_products",
                      "apply_to_products",
                      selectOptions,
                      getProductsAsOptions(products, lan),
                      setCurr,
                      () => setShowAssignItemsModal(true),
                      deselect
                  )
                : null}
            {renderActionButtons()}
            {renderAssignItemsModal()}
        </div>
    );

    return renderCreateForm();
}

export default CreateModal;
