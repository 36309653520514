import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { RootState } from "app/reducer";
import { store } from "app/store";
import { createMessage } from "components/message";
import { getIntl } from "locale";
import _ from "lodash";
import { AssociatedProduct } from "models/products";
import { ALL_TYPES } from "pages/products/constants";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { setState as setAppModalState } from "slices/app-modal";
import { getCategoryProductsSuccess } from "slices/categories";
import {
    addProductSuccess,
    getProductsSuccess,
    getProductSuccess,
    Product,
    setLastSearch,
    setProductState,
    setValuesSuccess,
    updateProductSuccess,
} from "slices/products";
import { oauth } from "util/api";
import helper, { buildQuery } from "util/helper";
import ObjectModel from "../util/models";
import StoreProduct from "../util/models/store-product";

function emptyFunction() {
    // does nothing
}

export const getProducts =
    (p?: Record<string, any> | any, forCategories = false, infinite = false) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setProductState({ loading: true }));
        try {
            const params = _.cloneDeep(p);
            if (params.keywords) {
                params.product_name = params.keywords;
            }

            if (params) {
                if (!params?.paging) {
                    params.paging = {
                        page: params.page,
                        limit: params.limit,
                    };
                }
            }

            Object.keys(params).forEach((key) => {
                if (_.isNil(params[key])) {
                    delete params[key];
                }
            });

            const response: any = await oauth("PRODUCTS")({
                method: "POST",
                body: JSON.stringify({ search_condition: { product_type: ALL_TYPES, ...params } ?? {} }),
            });

            if (!forCategories) {
                switch (response.RC) {
                    case 200:
                        _.set(response, "infinite", infinite);
                        dispatch(getProductsSuccess(response));
                        if (p && window.location.href?.includes("/products?")) {
                            delete p.paging;
                            delete p.search;
                            dispatch(setLastSearch(p));
                        }
                        break;
                    default:
                        break;
                }
            } else {
                switch (response.RC) {
                    case 200:
                        dispatch(getCategoryProductsSuccess(response.records));
                        break;
                    default:
                        break;
                }
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setProductState({ loading: false }));
    };

export const getProduct =
    (id: number, callBack = emptyFunction) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setProductState({ loading: true }));
        try {
            const response: any = await oauth(
                "PRODUCT_DETAILS",
                undefined,
                id?.toString() ?? id
            )({
                method: "GET",
            });

            switch (response.RC) {
                case 200:
                    dispatch(getProductSuccess(response.records));
                    callBack();
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setProductState({ loading: false }));
    };

export const editProduct =
    (
        params?: Record<string, any>,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        search = {},
        successCallBack = (productId: any) => {
            return productId;
        },
        setOriginalValues?: (state: Record<string, any>) => void
    ) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setProductState({ loading: true }));
        try {
            const intl = getIntl();
            const nonNullNumberFields = ["price", "stock"];
            if (params) {
                nonNullNumberFields.forEach((field) => {
                    if (params[field] === null) {
                        params[field] = 0;
                    }
                });
            }

            const response: any = await oauth("PRODUCT_DETAILS")({
                method: "POST",
                body: JSON.stringify({ data: params ?? {} }),
            });

            let state, lastSearch;
            switch (response.RC) {
                case 200:
                    state = store.getState();
                    // @ts-ignore
                    lastSearch = state?.products?.lastSearch ?? {};
                    if (params?.product_id) {
                        // @ts-ignore
                        params.images = state?.products?.imageList?? {};
                        const res = new ObjectModel(StoreProduct, params).getData();
                        setOriginalValues?.({ ...res });
                        dispatch(setValuesSuccess({ duplicate: false }));
                        dispatch(updateProductSuccess(response.records));
                        toast(createMessage(intl.formatMessage({ id: "product_updated" }), HiInformationCircle));
                    } else {
                        dispatch(addProductSuccess(response.records));
                        toast(createMessage(intl.formatMessage({ id: "product_created" }), HiInformationCircle));
                    }
                    successCallBack(response?.records?.product_id);
                    break;
                default:
                    Modal.error({
                        title: intl.formatMessage({ id: "error" }),
                        content: intl.formatMessage(
                            { id: "error_message_product" },
                            { message: response?.msg ?? response?.message }
                        ),
                    });
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setProductState({ loading: false }));
    };

export const editProducts =
    (
        pids: Record<string, any> | any,
        params?: Record<string, any> | any,
        search = {},
        successCallBack = emptyFunction,
        paramsModifier: any = {}
    ) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const intl = getIntl();
            const state = store.getState();
            // @ts-ignore
            const products: Product[] = state?.products?.products;

            const successful: any = [];
            const failure: any = [];

            Promise.all(
                pids.map(async (pid: any) => {
                    params.product_id = pid;

                    Object.keys(params).forEach((key) => {
                        params[key] = paramsModifier[key]
                            ? paramsModifier[key](
                                  params[key],
                                  products.find((p) => Number(p?.product_id) === Number(pid))
                              )
                            : params[key];
                    });

                    const response = await oauth("PRODUCT_DETAILS")({
                        method: "POST",
                        body: JSON.stringify({ data: params ?? {} }),
                    });

                    if (response?.RC === 200) {
                        successful.push(pid);
                    } else {
                        failure.push(pid);
                    }

                    return response;
                })
            ).then(() => {
                const state = store.getState();
                // @ts-ignore
                const lan = state?.setting?.lan;
                // @ts-ignore
                const lastSearch = state?.products?.lastSearch ?? {};
                // @ts-ignore
                dispatch(getProducts({ ...lastSearch, search }));

                Modal.confirm({
                    title: intl.formatMessage({ id: "products_updated" }, { count: pids?.length }),
                    content: intl.formatMessage(
                        { id: "products_updated_message" },
                        {
                            success: successful
                                .map((s: any) =>
                                    helper.getTransString(
                                        products?.find((product) => Number(product?.product_id) === Number(s))?.name,
                                        lan
                                    )
                                )
                                .join(", "),
                            fail: failure
                                .map((s: any) =>
                                    helper.getTransString(
                                        products?.find((product) => Number(product?.product_id) === Number(s))?.name,
                                        lan
                                    )
                                )
                                .join(", "),
                            br: React.createElement("br", {}, null),
                        }
                    ),
                    cancelButtonProps: {
                        style: {
                            display: "none",
                        },
                    },
                });

                successCallBack();
            });
        } catch (e) {
            // Handle error
        }
    };

export const deleteProduct =
    (params: { pid: any; search?: any; force?: boolean; lan?: string }) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const { pid, search = {}, force = false, lan } = params;

        try {
            const intl = getIntl();
            const response: any = await oauth(
                "PRODUCT_DETAILS",
                buildQuery({ force_delete: force ? 1 : undefined, lan: lan }),
                `delete/${pid}`
            )({
                method: "DELETE",
            });

            let state,
                lastSearch,
                associatedProducts: AssociatedProduct[] = [];
            switch (response.RC) {
                case 200:
                    state = store.getState();
                    // @ts-ignore
                    lastSearch = state?.products?.lastSearch ?? {};
                    dispatch(getProducts({ ...lastSearch, ...search }));
                    toast(createMessage(intl.formatMessage({ id: "product_updated" }), HiInformationCircle));
                    break;
                case 222:
                    associatedProducts = _.get(response, "record.associated_products", []);
                    dispatch(
                        setAppModalState({
                            visible: true,
                            title: "warning",
                            content: "delete_related_product_warning_message",
                            code: "force-delete-product",
                            additionalInfo: { currentProductId: pid, associatedProducts },
                        })
                    );
                    break;
                case 223:
                    associatedProducts = _.get(response, "record.associated_products", []);
                    dispatch(
                        setAppModalState({
                            visible: true,
                            title: "warning",
                            content: "delete_upsell_product_warning_message",
                            code: "force-delete-product",
                            additionalInfo: { currentProductId: pid, associatedProducts },
                        })
                    );
                    break;
                case 224:
                    dispatch(
                        setAppModalState({
                            visible: true,
                            title: "warning",
                            content: "delete_discounted_upsell_product_warning_message",
                            code: "force-delete-product",
                            additionalInfo: { currentProductId: pid },
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const deleteProducts =
    (pids: Record<string, any> | any, search = {}) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const intl = getIntl();
            const successful: any = [];
            const failure: any = [];
            Promise.all(
                pids.map(async (pid: any) => {
                    const response = await oauth(
                        "PRODUCT_DETAILS",
                        "",
                        "delete/" + pid
                    )({
                        method: "DELETE",
                    });

                    if (response?.RC === 200) {
                        successful.push(pid);
                    } else {
                        failure.push(pid);
                    }

                    return response;
                })
            ).then(() => {
                const state = store.getState();
                // @ts-ignore
                const lan = state?.setting?.lan;
                // @ts-ignore
                const lastSearch = state?.products?.lastSearch ?? {};
                // @ts-ignore
                const products: Product[] = state?.products?.products;

                dispatch(getProducts({ ...lastSearch, search }));

                Modal.confirm({
                    title: intl.formatMessage({ id: "products_deleted" }, { count: pids?.length }),
                    content: intl.formatMessage(
                        { id: "products_deleted_message" },
                        {
                            success: successful
                                .map((s: any) =>
                                    helper.getTransString(
                                        products?.find((product) => Number(product?.product_id) === Number(s))?.name,
                                        lan
                                    )
                                )
                                .join(", "),
                            fail: failure
                                .map((s: any) =>
                                    helper.getTransString(
                                        products?.find((product) => Number(product?.product_id) === Number(s))?.name,
                                        lan
                                    )
                                )
                                .join(", "),
                            br: React.createElement("br", {}, null),
                        }
                    ),
                    cancelButtonProps: {
                        style: {
                            display: "none",
                        },
                    },
                });
            });
        } catch (e) {
            // Handle error
        }
    };

export const duplicateProduct =
    (pid: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const intl = getIntl();
            const response: any = await oauth("DUPLICATE")({
                method: "POST",
                body: JSON.stringify({ product_id: pid }),
            });

            let id: any;
            switch (response?.RC) {
                case 200:
                    id = response?.msg?.split?.(": ")?.[1];
                    Modal.confirm({
                        title: intl.formatMessage({ id: "product_duplicated" }),
                        content: intl.formatMessage({ id: "product_duplicated_message" }),
                        onOk: () => {
                            window.location.href = `/#/products/addEditItem/${id}`;
                        },
                        onCancel: () => {
                            window.location.href = `/#/products/addEditItem/${id}`;
                        },
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const validateProductBarcode = async (barcode: string, productId?: number): Promise<string> => {
    try {
        const body = {
            data: {
                barcode,
                product_id: productId,
            },
        };
        const response: any = await oauth("VALIDATE_BARCODE")({
            method: "POST",
            body: JSON.stringify(body),
        });

        switch (response?.RC) {
            case 200:
                return "validated";
            case 400:
                return "invalid_request_body";
            case 424:
                return "barcode_not_unique";
            case 425:
                return "barcode_length_invalid";
            case 500:
                return "invalid_request_body";
            default:
                return "invalid_request_body";
        }
    } catch (e) {
        // Handle error
        return "invalid_request_body";
    }
};
