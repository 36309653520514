import { RootState } from "app/reducer";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
    addContentPageSuccess,
    updateContentPageSuccess,
    getContentPageDetailsSuccess,
    setState,
    deleteContentPageSuccess,
    deleteContentPageUnsuccessful,
    updateContentPageListSuccess,
} from "slices/content-pages";
import { oauth } from "util/api";
import {
    ContentPageRequest,
    CreateContentPageRequest,
    GetContentPageParams,
    UpdateContentPageRequest,
    UpdateContentPagesRequest,
    UpdatedContentPage,
} from "pages/settings/pages/content-pages/models/content-pages-requests";
import {
    CreateContentPageResponse,
    DeleteContentPageResponse,
    GetContentPagesResponse,
    UpdateContentPageResponse,
} from "pages/settings/pages/content-pages/models/content-pages-responses";
import config from "config";
import { buildQuery } from "util/helper";

const buildIdString = (ids: string[]) => {
    let idStr = "";
    for (let i = 0; i < ids.length; i++) {
        idStr += ids[i];
        if (i !== ids.length - 1) {
            idStr += ",";
        }
    }
    return idStr;
};

export const getContentPages =
    (params?: GetContentPageParams) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isFetching: true }));
        try {
            const response: GetContentPagesResponse = await oauth(
                "CONTENT_PAGES",
                buildQuery(params)
            )({
                method: "GET",
            });

            switch (response.RC) {
                case 200:
                    dispatch(setState({ contentPageList: response.res }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isFetching: false }));
    };

export const updateContentPageList =
    (updatedPages: UpdatedContentPage[]) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isUpdating: true }));
        try {
            const body: UpdateContentPagesRequest = {
                data: {
                    pages: updatedPages,
                },
            };

            const response: UpdateContentPageResponse = await oauth("CONTENT_PAGES")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                    dispatch(updateContentPageListSuccess());
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isUpdating: false }));
    };

export const getContentPage =
    (params: GetContentPageParams) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isFetching: true }));
        try {
            const response: UpdateContentPageResponse = await oauth(
                "CONTENT_PAGE",
                buildQuery(params)
            )({
                method: "GET",
            });

            switch (response.RC) {
                case 200:
                    dispatch(getContentPageDetailsSuccess(response.res));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isFetching: false }));
    };

export const addContentPage =
    (contentPage: ContentPageRequest, saveContinue?: boolean) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isUpdating: true }));
        try {
            const body: CreateContentPageRequest = {
                data: {
                    action: config.CONTENT_PAGE_POST_ACTION_MAPPING.create,
                    ...contentPage,
                },
            };

            const response: CreateContentPageResponse = await oauth("CONTENT_PAGE")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                    dispatch(
                        addContentPageSuccess({
                            redirectUrl: saveContinue ? undefined : "/settings/contentPages",
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isUpdating: false }));
    };

export const updateContentPage =
    (contentPage: ContentPageRequest, id: string, saveContinue?: boolean) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isUpdating: true }));
        try {
            const body: UpdateContentPageRequest = {
                data: {
                    action: config.CONTENT_PAGE_POST_ACTION_MAPPING.update,
                    page_id: id,
                    ...contentPage,
                },
            };

            const response: CreateContentPageResponse = await oauth("CONTENT_PAGE")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                    dispatch(
                        updateContentPageSuccess({ redirectUrl: saveContinue ? undefined : "/settings/contentPages" })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isUpdating: false }));
    };

export const removeContentPage =
    (ids: string[]) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isUpdating: true }));
        try {
            const response: DeleteContentPageResponse = await oauth(
                "DELETE_CONTENT_PAGE",
                "",
                buildIdString(ids)
            )({
                method: "DELETE",
            });

            switch (response.RC) {
                case 200:
                    dispatch(deleteContentPageSuccess());
                    break;
                case 400:
                    dispatch(deleteContentPageUnsuccessful());
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isUpdating: false }));
    };
