import React from "react";
import { injectIntl } from "react-intl";
import cx from "classnames";
import _ from "lodash";
import "./style-option-mupltiple-picker-button-style.scss";
import { Button } from "antd";

class PickerMulitiButton extends React.Component {
    getLeftButtonId = () => {
        return `multiple-picker-button-left-style-button-${this.props.id}`;
    };
    getRightButtonId = () => {
        return `multiple-picker-button-right-style-button-${this.props.id}`;
    };
    //to controll
    renderQtyRealButtons = () => {
        const qt = this.props.quantity;
        const allowAdd = _.isBoolean(this.props.allowAdd) ? this.props.allowAdd : true;
        const allowRemove = _.isBoolean(this.props.allowRemove) ? this.props.allowRemove : !qt;

        return (
            <div className="multiple-picker-button-style-buttons multiple-picker-button-style-real-buttons">
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (allowRemove && this.props.onMinusClick) this.props.onMinusClick();
                    }}
                    onMouseEnter={() => {
                        const el = document.getElementById(this.getLeftButtonId());
                        if (el) el.focus();
                    }}
                    onMouseLeave={() => {
                        const el = document.getElementById(this.getLeftButtonId());
                        if (el) el.blur();
                    }}
                    className="multiple-picker-button-button left-button"
                />
                <div className="button-style-divider" />
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        if (allowAdd && this.props.onPlusClick) this.props.onPlusClick();
                    }}
                    onMouseEnter={() => {
                        const el = document.getElementById(this.getRightButtonId());
                        if (el) el.focus();
                    }}
                    onMouseLeave={() => {
                        const el = document.getElementById(this.getRightButtonId());
                        if (el) el.blur();
                    }}
                    className="multiple-picker-button-button right-button"
                />
            </div>
        );
    };

    //to display ui only
    renderQtyUIButtons = () => {
        return (
            <div className="multiple-picker-button-style-buttons">
                <Button id={this.getLeftButtonId()} danger type={"primary"} className="multiple-picker-left-button">
                    {" "}
                </Button>
                <Button id={this.getRightButtonId()} danger type={"primary"} className="multiple-picker-right-button">
                    {" "}
                </Button>
            </div>
        );
    };

    renderProductName = () => {
        return (
            <div
                className={cx({
                    error: this.props.error,
                    "multiple-picker-button-style-info": true,
                })}
            >
                <div className="multiple-picker-button-style-name-wrapper">
                    <div className="multiple-picker-button-style-name"> {this.props.name}</div>
                </div>
                <div className="multiple-picker-button-style-price">
                    {this.props.pc || ""}
                    {this.props.pc && this.props.quantity ? ` · ` : ""}
                    {this.props.quantity || ""}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div
                onClick={() => this.props.onPlusClick && this.props.onPlusClick()}
                className="multiple-picker-button-style"
            >
                {this.renderProductName()}
                {this.renderQtyUIButtons()}
                {this.renderQtyRealButtons()}
            </div>
        );
    }
}

export default injectIntl(PickerMulitiButton);
