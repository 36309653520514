import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Input } from "antd";
import { GoSearch } from "react-icons/go";
import { setState } from "slices/create-order";
import { ITEM_CHANGE_ACTION_SEARCH_BAR } from "pages/liveorder/_components/order-items/helper";

class SearchProducts extends Component<any, any> {
    state = {};

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    showSearchDialog = () => {
        this.props.setParentState({
            showItemEditDetailDialog: true,
            itemChangeObject: { action: ITEM_CHANGE_ACTION_SEARCH_BAR },
            item: {
                pid: `add_${Math.random().toString(36).substr(2, 9)}`,
            },
        });
    };

    renderSearchInput = () => {
        return (
            <div id="search-products-row" className="search-products-row" onClick={() => this.showSearchDialog()}>
                <Input readOnly size="large" placeholder={this.str("search_by_item")} prefix={<GoSearch />} />
            </div>
        );
    };

    render() {
        return <div className="sub-section">{this.renderSearchInput()}</div>;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(SearchProducts)));
