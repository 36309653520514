import React, { FocusEvent } from "react";
import { ResizableBox } from "react-resizable";
import { Jodit } from "jodit";
import _ from "lodash";

interface JoditEditorProps {
    id: string;
    name?: string;
    value?: string;
    containerWidth: number;
    containerHeight: number;
    maxContraints: any;
    minContraints: any;
    options?: any;
    onChange?: (value: string) => any;
    onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => any;
    resize?: boolean;
}

interface JoditEditorState {
    editor: any;
}

const emptyFunction = () => {
    // default function
};

class JoditEditor extends React.Component<JoditEditorProps, JoditEditorState> {
    state = { editor: null };

    componentDidMount(): void {
        this.buildJoditEditor();
    }

    componentDidUpdate(prevProps: JoditEditorProps): void {
        const editor = _.cloneDeep(this.state.editor);

        if (prevProps.resize !== this.props.resize && this.props.resize) {
            // @ts-ignore
            editor?.events.fire("resize");
            this.setState({ editor: editor });
        }
        if (prevProps.value !== this.props.value) {
            if (editor) {
                // @ts-ignore
                editor.value = this.props.value;
                this.setState({ editor: editor });
            }
        }
    }

    componentWillUnmount(): void {
        const editor = _.cloneDeep(this.state.editor);

        // @ts-ignore
        editor?.events.destruct();
        this.setState({ editor: editor });
    }

    buildJoditEditor = (): void => {
        const editor = new Jodit(`#${this.props.id}`, this.props.options);

        editor.events.on("change", this.props.onChange ?? emptyFunction);
        editor.events.on("blur", this.props.onBlur ?? emptyFunction);

        this.setState({
            editor: editor,
        });
    };

    render = (): JSX.Element => {
        return (
            <ResizableBox
                width={this.props.containerWidth}
                height={this.props.containerHeight}
                maxConstraints={this.props.maxContraints}
                minConstraints={this.props.minContraints}
            >
                <textarea id={this.props.id} name={this.props.name} defaultValue={this.props.value} />
            </ResizableBox>
        );
    };
}

export default JoditEditor;
