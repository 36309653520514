import React from "react";
import Wrapper from "../../../components/wrapper";
import config from "../../../config";
import "./index.scss";
import StoreSwitchForm from "./_components/store-switch-form";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            breadcrumbName: "Super Admin",
        },
        {
            path: "superAdmin/storeSwitch",
            breadcrumbName: "Store Switch",
        },
    ],
};

function App(): JSX.Element {
    return (
        <Wrapper helmet={{ title: "Store Switch" }} breadcrumb={breadcrumb} permission={[config.ROLE_ID.SUPER_USER]}>
            <StoreSwitchForm />
        </Wrapper>
    );
}

export default App;
