import React from "react";
import { SwitchDesc } from "./switch-desc";

interface ArbitrarySwitchProps {
    openArbitraryCode: boolean;
    setArbitraryCode: (openArbitraryCode: boolean) => void;
    switchOnChange: (value: any, name: any, local: any) => void;
}

export const ArbitrarySwitch = (props: ArbitrarySwitchProps): JSX.Element => {
    const { openArbitraryCode, setArbitraryCode, switchOnChange } = props;

    return (
        <div>
            <SwitchDesc
                description={"arbitrary_qr_code"}
                value={openArbitraryCode}
                name=""
                local={setArbitraryCode}
                switchOnChange={switchOnChange}
            />
        </div>
    );
};
