const admin = `
    profile.id,                                     id,                                 false;
    profile.first_name,                             first_name,                         false;
    profile.last_name,                              last_name,                          false;
    profile.username,                               username,                           false;
    profile.local_printer_setting,                  local_printer_settings,             false;
    profile.email,                                  email,                              false;
    profile.phone,                                  phone,                              false;
    profile.current_store,                          current_store,                      false;
    profile.g_ids,                                  g_ids,                              false;
    profile.mobile_os,                              mobile_os,                          false;
    profile.device_token,                           device_token,                       false;
    profile.managed_stores,                         managed_store,                      false;
    profile.current_store,                          current_store,                      false;
    profile.push_notification_enabled,              push_enabled,                       false;
    profile.email_notification_enabled,             email_enabled,                      false;
    profile.text_notification_enabled,              text_enabled,                       false;
    profile.require_pwd_to_cancel_order,            require_pwd_to_cance_order,         false;
    profile.enable_order_sound_alert,               enable_order_sound_alert,           false;
    profile.enable_order_printing_check,            enable_order_rinting_check,         false;
    profile.enable_pending_order_check,             enable_pending_order_check,         false;
    profile.scheduled_order_reminder,               scheduled_order_reminder,           false;
    profile.roles,                                  roles,                              false;
    profile.access_flags[0],                        access_flags,                       false;
    profile.is_active,                              is_active,                          false;
    profile.lan,                                    lan,                                false;
    profile.is_use_pos_mode,                        is_use_pos_mode,                    false;
`

export default admin;