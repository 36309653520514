import React from "react";
import helper from "../../../../../util/helper";
import { View, Text } from "@react-pdf/renderer";

const storeInfo = (
    styles: React.CSSProperties | any,
    props: { storeName: string, storeURL: string | any, phone: number | string }
): JSX.Element => (
    <React.Fragment>
        <View style={styles.storeName}>
            <Text>{props.storeName}</Text>
        </View>
        <View style={styles.websiteURL}>
            <Text>{props.storeURL}</Text>
        </View>
        <View style={styles.phone}>
            <Text>{helper.formatPhone(props.phone)}</Text>
        </View>
    </React.Fragment>
);
export default storeInfo;
