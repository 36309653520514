import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import {
    getOrderId,
    getCustomerName,
    getCustomerPhone,
    getOrderMainStatusText,
    getStore,
    getOrderType,
    getOrderTable,
    getOrderTotalStr,
    getPaymentIcon,
    getOrderTime,
    getDueTimeInfo,
    isSameOrder,
    findOrderInListAndPerformAction,
} from "../../helper-order-list";
import { formatOrderId, allowBulkUpdate } from "../../helper";
import helper from "../../../../util/helper";
import { Checkbox } from "antd";
import cx from "classnames";
import actions from "../../../../slices/order-history";
import liveOrderActions from "../../../../slices/live-order";
import config from "../../../../config";
import CopyArea from "components/copy-area";

const ORDER_LIST_REF = "order_list_ref_";

class OrderListTableListItem extends Component {
    componentDidMount() {
        this.checkOrderDetailUpdate({});
    }

    componentDidUpdate(prevProps) {
        this.checkOrderDetailUpdate(prevProps);
    }

    checkOrderDetailUpdate = (prevProps) => {
        const update = this.getOrderDetailId() !== this.getOrderDetailId(prevProps) && this.getOrderDetailId();
        const matchId = this.getOrderDetailId() === this.getListOrderId();
        if (update && matchId) this.scrollToCurrentOrder();
    };

    str = (id, values) => {
        this.props.intl.formatMessage({ id }, values);
    };

    showBulkContoller = () => {
        return _.get(this.props, "state.bulkUpdating", false);
    };

    getSelectedOrders = () => {
        return Array.isArray(_.get(this.props, "state.selectedOrders"))
            ? _.get(this.props, "state.selectedOrders")
            : [];
    };

    getSelectedOrdersIndex = () => {
        const orderId = getOrderId(this.getOrder());
        return _.findIndex(this.getSelectedOrders(), (order) => getOrderId(order) === orderId);
    };

    getOrder = (props = this.props) => _.get(props, `order`, {});

    getListOrderId = (props = this.props) => getOrderId(this.getOrder(props));

    getOrderDetailId = (props = this.props) => getOrderId(props.orderDetail);

    getStore = () => {
        const order = this.getOrder();
        const stores = _.get(this.props, `storesData`, []);
        return getStore(order, stores);
    };

    getClassNames = () => {
        return {
            "order-list-list-item": true,
            "order-list-list-item-active": this.getOrderDetailId() === this.getListOrderId(),
        };
    };

    updateExportSelectionList = () => {
        const updatedSelectedExportOrders = _.cloneDeep(this.props.selectedExportOrders);
        findOrderInListAndPerformAction(
            updatedSelectedExportOrders,
            this.getOrder(),
            (index) => updatedSelectedExportOrders.splice(index, 1),
            () => updatedSelectedExportOrders.push(this.getOrder())
        );
        this.props.setState({ selectedExportOrders: updatedSelectedExportOrders });
    };

    isExportOrderSelected = () => {
        return (
            this.props.selectedExportOrders.find((selectedOrder) => {
                return isSameOrder(selectedOrder, this.getOrder());
            }) !== undefined
        );
    };

    handleOnClickExportCheckbox = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const isSelected = this.isExportOrderSelected();
        if (isSelected) {
            this.removeSelectedExportOrders();
        } else {
            const updatedSelectedExportOrdersList = _.unionWith(
                this.getOrderList(),
                this.props.selectedExportOrders,
                isSameOrder
            );
            this.props.setState({ selectedExportOrders: updatedSelectedExportOrdersList });
        }
    };

    scrollToCurrentOrder = () => {
        const ref = this[`${ORDER_LIST_REF}${this.getListOrderId()}`];
        if (ref) {
            ref.scrollIntoView({
                block: "nearest",
            });
        }
    };

    renderCheckbox = () => {
        const checked = this.getSelectedOrdersIndex() !== -1;
        return <Checkbox disabled={!allowBulkUpdate(this.getOrder())} checked={checked} />;
    };

    renderExportSelectedCheckbox = () => {
        return <Checkbox checked={this.isExportOrderSelected()} />;
    };

    renderFirstRow = () => {
        const order = this.getOrder();
        const orderId = formatOrderId(getOrderId(order));
        const store = this.getStore();
        const orderStatus = getOrderMainStatusText(order, store);
        let statusClassName = "order-list-list-main-status";
        if (orderStatus.status === config.ORDER_STATUS_MAPPING_TO_NUMERIC.canceled) {
            statusClassName = "order-list-list-canceled-status";
        }
        return (
            <div className="order-list-list-first-row">
                {this.showBulkContoller() ? this.renderCheckbox() : ""}
                {this.props.useExportSelection ? this.renderExportSelectedCheckbox() : null}
                <span className="order-list-list-order-id">
                    <CopyArea iconPosition="start" copyData={getOrderId(order)} messageId="order_id">
                        {`#${orderId}`}
                    </CopyArea>
                </span>
                <span>&nbsp;·&nbsp;</span>
                <span className={statusClassName}>
                    <FormattedMessage id={orderStatus.text} />
                </span>
            </div>
        );
    };

    renderSecondRow = () => {
        const order = this.getOrder();
        const store = this.getStore();
        const payMemthodIcon = getPaymentIcon(order);
        const orderType = getOrderType(order, store);
        return (
            <div className="order-list-list-second-row space-between">
                <span className="order-list-list-type">
                    <FormattedMessage id={orderType} />
                    {getOrderTable(order, store) ? ` · ${getOrderTable(order, store)}` : ""}
                </span>
                <span className="order-list-list-total">
                    {helper.isPPCP3dsOrder(order) ? <b id="lock-icon">&#128274;</b> : null}
                    {<img src={payMemthodIcon} alt="" className="order-list-list-payment-method" />}
                    {getOrderTotalStr(order, store)}
                </span>
            </div>
        );
    };

    renderThirdRow = () => {
        const order = this.getOrder();
        return (
            <div className="order-list-list-third-row space-between">
                <CopyArea
                    copyData={getCustomerName(order)}
                    messageId="customer_name"
                    iconPosition="start"
                    className="order-list-list-customer-name"
                >
                    {getCustomerName(order)}
                </CopyArea>

                <CopyArea copyData={getCustomerPhone(order)} messageId="phone_number" iconPosition="start">
                    <span className="order-list-list-customer-phone">
                        {helper.formatPhone(getCustomerPhone(order))}
                    </span>
                </CopyArea>
            </div>
        );
    };

    renderFourthRow = () => {
        const order = this.getOrder();
        const store = this.getStore();
        const dueTime = getDueTimeInfo(order, store)["main-info"].trim();
        const timeColor = getDueTimeInfo(order, store)["show-red"] ? "red" : "#1890ff";
        return (
            <div className="order-list-list-fourth-row space-between">
                <span className="order-list-list-order-time">
                    {this.str("order_time")}
                    &nbsp;
                    {getOrderTime(order, store)}
                </span>
                {dueTime ? (
                    <span className="order-list-list-order-time" style={{ color: timeColor }}>
                        <FormattedMessage
                            id={
                                getDueTimeInfo(order, store)["sub-info"]
                                    ? getDueTimeInfo(order, store)["sub-info"]
                                    : " "
                            }
                        />
                        &nbsp;
                        <FormattedMessage id={getDueTimeInfo(order, store)["main-info"]} />
                    </span>
                ) : null}
            </div>
        );
    };

    onOrderRowClick = () => {
        if (this.showBulkContoller()) {
            this.updateBulkUpdateList();
        } else if (this.props.useExportSelection) {
            this.updateExportSelectionList();
        } else {
            this.fetchOrderDetail();
        }
    };

    fetchOrderDetail = () => {
        const order = _.get(this.props, `order`, {});
        const orderId = getOrderId(order);
        this.props.fetchOrderDetail({ orderId });
        this.props.setLiveOrderState({
            expansionControllers: {
                [orderId]: { showDetail: true },
            },
        });
    };

    updateBulkUpdateList = () => {
        const index = this.getSelectedOrdersIndex();
        const checked = index !== -1;
        let result = _.cloneDeep(this.getSelectedOrders());
        if (!checked) {
            result.push(this.getOrder());
        } else {
            result.splice(index, 1);
        }
        this.props.setState({
            selectedOrders: result,
        });
    };

    render() {
        return (
            <div
                ref={(el) => (this[`${ORDER_LIST_REF}${this.getListOrderId()}`] = el)}
                onClick={() => this.onOrderRowClick()}
                className={cx(this.getClassNames())}
            >
                {this.renderFirstRow()}
                {this.renderSecondRow()}
                {this.renderThirdRow()}
                {this.renderFourthRow()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    storesData: _.get(state, "managedStores.activeStores", []),
    state: _.get(state, "orders-page", {}),
    orderDetail: _.get(state, "orders-page.orderDetailInstance", []),
    useExportSelection: _.get(state, "orders-page.useExportSelection", false),
    selectedExportOrders: _.get(state, "orders-page.selectedExportOrders", []),
});

const mapDispatchToProps = {
    setState: actions.setState,
    fetchOrderDetail: actions.fetchOrderDetail,
    setLiveOrderState: liveOrderActions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTableListItem));
