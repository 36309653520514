const StoreGeneralInfo = `
    general_info.store_nm,      store_nm,               true;
    general_info.store_desc,    store_desc,             true;
    general_info.owner,         owner;
    general_info.phone,         phone;
    store_addr.lat,             address_lat;
    store_addr.lon,             address_lon;
    store_addr.province,        address_province;
    store_addr.country,         address_country;
    store_addr.country_code,    address_country_code;
    store_addr.timezone,        address_time_zone;
    store_addr.street,          address_street;
    store_addr.city,            address_city;
    store_addr.unit,            address_unit;
    store_addr.post_code,       address_postal_code;
    store_flg,                  store_categories,       false,              2;
    store_flg,                  sub_categories,         false,              3;
    general_info.url,           url;
    general_info.mobile_url,    mobile_url;
    store_flg,                  price_plan,             false,              0;
    general_info.store_status,  store_status;
    general_info.lan_lst,       product_display_languages,          false;
    general_info.g_id,          g_id;
    price_plan,                 pp;
    c_id,                       c_id;
`

export default StoreGeneralInfo;