import React, { Component } from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import OnePageStyle from "../order-list-one-page-style";
import ListStyle from "../order-list-list-style";
import StyleSwitcher from "../style-switcher";
import BulkUpdateDialog from "../../../order-history/page-components/order-list-controllers/bulk-update-controller/bulk-update-process-modal";
import _actions from "../../../../slices/live-order";

class LiveOrderListSection extends Component {
    showListStyle = () => {
        return _.get(this.props, "state.listStyle") === "list";
    };

    //due to bulk update are resulting order detail component removed and re-rendering ,
    //if bulk update is rendered on order detail componnent, if will caused set state on un-mounted component error
    //bulk update dialog can only be placed in component that is stable
    renderBulkUpdateDialog = () => {
        return (
            <BulkUpdateDialog
                state={_.get(this.props, "state.bulkUpdateData", {})}
                setState={(values) =>
                    this.props.setState({
                        bulkUpdateData: Object.assign({}, _.get(this.props, "state.bulkUpdateData", {}), values),
                    })
                }
            />
        );
    };

    render() {
        return (
            <div>
                <StyleSwitcher />
                {this.showListStyle() ? <ListStyle /> : <OnePageStyle />}
                {this.renderBulkUpdateDialog()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

const LiveOrderListHandler = injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveOrderListSection));

export default LiveOrderListHandler;
