import React from "react";
import { MdRefresh } from "react-icons/md";
import cx from "classnames";
import "./index.scss";

function RefreshButton(props: any = {}): JSX.Element {
    const { refreshPage, loading } = props;
    return (
        <div
            className="page-refresh-container"
            onClick={() => {
                refreshPage();
            }}
        >
            <MdRefresh
                className={cx({
                    "page-refresh": true,
                    "spin": loading,
                })}
            />
        </div>
    );
}

export default RefreshButton;
