import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import { switchSubTab } from "../../../../../../slices/discounts";
import DeliverySubTab from "./delivery";
import PickupSubTab from "./pickup";
import ShippingSubTab from "./shipping";
import InStoreSubTab from "./inStore";
import { IsMobileContext } from "../../../../../../util/helper/isMobileContext";
import UserSubTab from "./user";
import { SelectOption } from "../../../../../../components/form";
import { Select } from "antd";

const AutoDiscountTab = (): JSX.Element => {
    const { isMobile } = useContext(IsMobileContext);
    const intl = useIntl();
    const currTab = useSelector((state: RootState) => state?.discounts.currTab);
    const dispatch = useDispatch();
    const deliverySubTab = 0;
    const pickupSubTab = 1;
    const instoreSubTab = 2;
    const shippingSubTab = 3;
    const userSubTab = 4;

    const tab = (title: string, t: number) => (
        <div className={"tabTitle " + (currTab === t ? "active" : "")} onClick={() => dispatch(switchSubTab(t))}>
            <FormattedMessage id={title} />
        </div>
    );

    const tabTitles = (
        <div className="tabTitles-row d-flex">
            {tab("delivery_discount", deliverySubTab)}
            {tab("pickup_discount", pickupSubTab)}
            {tab("instore_discount", instoreSubTab)}
            {tab("free_shipping_rule", shippingSubTab)}
            {tab("new_user_discount", userSubTab)}
        </div>
    );

    const tabOptions: SelectOption[] = [
        { value: deliverySubTab, label: intl.formatMessage({ id: "delivery_discount" }) },
        { value: pickupSubTab, label: intl.formatMessage({ id: "pickup_discount" }) },
        { value: instoreSubTab, label: intl.formatMessage({ id: "instore_discount" }) },
        { value: shippingSubTab, label: intl.formatMessage({ id: "free_shipping_rule" }) },
        { value: userSubTab, label: intl.formatMessage({ id: "new_user_discount" }) },
    ];

    const tabMobileSelect = (
        <Select
            bordered={false}
            className="tabTitles-row d-flex"
            value={currTab}
            onChange={(val) => dispatch(switchSubTab(val))}
        >
            {tabOptions.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    );

    const subTabHolder = (
        <div className="auto-discounts-container ">
            {isMobile() ? tabMobileSelect : tabTitles}
            {currTab === deliverySubTab ? <DeliverySubTab /> : null}
            {currTab === pickupSubTab ? <PickupSubTab /> : null}
            {currTab === instoreSubTab ? <InStoreSubTab /> : null}
            {currTab === shippingSubTab ? <ShippingSubTab /> : null}
            {currTab === userSubTab ? <UserSubTab /> : null}
        </div>
    );

    return subTabHolder;
};

export default AutoDiscountTab;
