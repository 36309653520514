import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { FORMAT } from "../constants";

interface Hours {
    MON: Array<string>;
    TUS: Array<string>;
    WED: Array<string>;
    THU: Array<string>;
    FRI: Array<string>;
    SAT: Array<string>;
    SUN: Array<string>;
}

interface HoursViewProps {
    day: string;
    hours: Hours;
}

export const HoursView = (props: HoursViewProps): JSX.Element => {
    const intl = useIntl();

    // @ts-ignore
    const dayHours = props.hours[props.day];

    return dayHours.map((dh: any, i: number) => {
        const value1 = dh[0];
        const value2 = dh[1];

        // Check if two time range is equal. Since it is looping through same array, same index can be equal.
        const isValid = _.isEmpty(
            dayHours.find((jdh: any, j: number) => {
                return i !== j && _.isEqual(dh, jdh);
            })
        );

        const is24Hours = () => {
            return value1 === "00:00" && value2 === "00:00";
        };

        const getHourSelects = (
            <div className="d-flex flex-row">
                <div className="hours-ranges">
                    <div className={`hours-range-option ${isValid ? "" : "hours-range-error"}`}>
                        {value1 === "closed"
                            ? intl.formatMessage({ id: "closed" })
                            : moment(value1, "HH:mm").format(FORMAT)}
                    </div>
                    {value1 !== "closed" && (
                        <>
                            <div className="mx-2">-</div>
                            <div className={`hours-range-option ${isValid ? "" : "hours-range-error"}`}>
                                {moment(value2, "HH:mm").format(FORMAT)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );

        const get24HourBlock = (
            <div className="d-flex flex-row">
                <div className="hours-ranges">
                    <FormattedMessage id="24_hours" />
                </div>
            </div>
        );

        return (
            <div key={`hour-range-select-key-${i}`} className="hour-range-select">
                {is24Hours() ? get24HourBlock : getHourSelects}
            </div>
        );
    });
};
