import React from "react";
import { Collapse, InputNumber, Checkbox } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { ImageUploadSection } from "./format-common/image-upload-section";

export const VariablesProperties = (props: any = {}): JSX.Element => {
    const intl = useIntl();
    const {
        storeImages,
        user,
        uploadCallback,
        deleteCallback,
        customDiscount,
        onChangeDiscount,
        onChangePayment,
        customOrderLabel,
        customBottomLabel,
        onChangeCustomOrderLabel,
        onChangeCustomBottomLabel,
    } = props;

    const renderDiscountInputs = () => {
        return (
            <div className="flex-row">
                <div>
                    <FormattedMessage id="discount_value" />
                    <InputNumber
                        className="custom-text-input w200px ta-left"
                        onChange={onChangeDiscount}
                        defaultValue={customDiscount}
                    />
                </div>
            </div>
        );
    };

    const renderPaymentIcons = () => {
        const paymentmethods = [
            "visa",
            "mastercard",
            "braintree_apple",
            "braintree_google_pay",
            "braintree_paypal",
            "wechat_pay",
            "alipay",
            "union_pay",
        ];

        return (
            <div className=" mb-3">
                <FormattedMessage id="payment_icons" />
                <Checkbox.Group onChange={onChangePayment}>
                    {paymentmethods.map((item, index) => (
                        <Checkbox key={index} value={item}>
                            <FormattedMessage id={item} />
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </div>
        );
    };

    const renderBottomLabelOptions = () => {
        return (
            <div>
                <FormattedMessage id="order_types_label" />
                <input
                    className="custom-text-input w200px ta-left"
                    onChange={onChangeCustomOrderLabel}
                    value={customOrderLabel}
                />
                <FormattedMessage id="bottom_label" />
                <input
                    className="custom-text-input w200px ta-left"
                    onChange={onChangeCustomBottomLabel}
                    value={customBottomLabel}
                />
            </div>
        );
    };

    const renderVariableOptions = () => {
        return (
            <div>
                {renderDiscountInputs()}
                <ImageUploadSection
                    storeImages={storeImages}
                    user={user}
                    uploadCallback={uploadCallback}
                    deleteCallback={deleteCallback}
                />
                {renderPaymentIcons()}
                {renderBottomLabelOptions()}
            </div>
        );
    };

    return (
        <div className="white-layered-block d-flex flex-row justify-content-start align-items-start">
            <div className="serve-bottom-left-section">
                <div className="store_name-title">
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
                        <Collapse.Panel header={intl.formatMessage({ id: "variables_title" })} key="2">
                            {renderVariableOptions()}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};
