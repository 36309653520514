import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import "./order-page.scss";
import Wrapper from "components/wrapper";
import { getManagedStores } from "services/managed-stores";
import _ from "lodash";
import OrderHistoryMain from "./main-component";
import ShippingOptionsModal from "components/shipping-options-modal";
import actions from "slices/order-history";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "/orders",
            breadcrumbName: "order_history",
        },
    ],
};

class OrderHistoryPage extends Component {
    componentDidMount() {
        const { getManagedStores } = this.props;
        getManagedStores();
    }

    render() {
        return (
            <Wrapper paddingBottom={0} helmet={{ title: "order_history" }} breadcrumb={breadcrumb}>
                <div className="order-page-container">
                    <div className="order-page-content">
                        <OrderHistoryMain />
                    </div>
                </div>
                <ShippingOptionsModal
                    {...{
                        shippingOptionsModal: this.props.shippingOptionsModal,
                        setState: (values) => this.props.setState(values),
                    }}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    shippingOptionsModal: _.get(state, "orders-page.shippingOptionsModal", false),
});

const mapDispatchToProps = {
    setState: actions.setState,
    getManagedStores,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderHistoryPage)));
