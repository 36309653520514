import { FormattedMessage } from "react-intl";
import { getIntl } from "../../../../locale";
import { EditModalPages } from "../../../../slices/delivery_request";

getIntl();

export const getEditModalTitleByID = (id: EditModalPages): React.ReactNode => {
    switch (id) {
        case EditModalPages.NEW_TASK_ITEM_NAME:
        case EditModalPages.UPDATE_ITEM_NAME:
            return (
                <div>
                    <FormattedMessage id="item_name" />
                </div>
            );
        case EditModalPages.NEW_TASK_ITEM_OPTION:
        case EditModalPages.UPDATE_ITEM_OPTION:
            return (
                <div>
                    <FormattedMessage id="option" />
                </div>
            );
        case EditModalPages.NEW_TASK_ADD_ITEM_OPTION:
        case EditModalPages.UPDATE_ITEM_ADD_OPTION:
            return (
                <div>
                    <FormattedMessage id="new_option" />
                </div>
            );

        case EditModalPages.UPDATE_ORDER_VALUE_AND_TIP_AMOUNT:
        case EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT:
            return (
                <div style={{ width: "100%", textAlign: "center" }}>
                    <FormattedMessage id="value_or_tips" />
                </div>
            );
        default:
            return " ";
    }
};
