const GenerateQr = `
    store_flg,              allow_eatin,                          false,          8;
    store_flg,              require_store_poster,                    false,          13;
    store_flg,              require_arbitrary_code,                 false,          39;
    store_flg,              require_due_time_for_in_store_order,  false,          76;
    store_addr,             store_addr,                           false;
    general_info.phone,     phone,                                false;
    general_info.store_nm,  store_name,                           true;
    general_info.g_id,      store_id,                             false;
`;

export default GenerateQr;
