import { Button } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import { getFormItem, getMultiSelectInput } from "components/form";
import useModal from "hooks/useModal";
import { getProducts } from "services/products";
import helper from "util/helper";
import AssignItemsModal from "components/assign-items-modal";
import ArrangeRelatedUpsellModal from "./arrange-related-upsell";

interface RelatedUpsellProdProps {
    form: any;
    values: any;
    setValues: any;
}

function RelatedUpsellProducts(props: RelatedUpsellProdProps): JSX.Element {
    const { form, values, setValues } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showRelatedProductModal, setShowRelatedProductModal] = useState(false);
    const [showUpsellProductModal, setShowUpsellProductModal] = useState(false);
    const {
        openModal: openModalArrange,
        getModal: getModalArrange,
        closeModal: closeModalArrange,
    } = useModal("assign-items");
    const {
        openModal: openModalArrangeUpsell,
        getModal: getModalArrangeUpsell,
        closeModal: closeModalArrangeUpsell,
    } = useModal("assign-items");

    const { products, lan } = useSelector((state: RootState) => ({
        product: state?.products?.product,
        products: state?.products?.products,
        lan: state?.setting?.lan,
    }));

    const getProductsList = () => {
        const p = [...products];

        const appendProduct = (item: any) => {
            if (_.isEmpty(p.find((child) => child.product_id === item.product_id))) {
                p.push(item);
            }
        };

        values?.related_product_list?.forEach(appendProduct);
        values?.upsell_product_list?.forEach(appendProduct);

        return p;
    };

    const getProductsAsOptions = () =>
        getProductsList()?.map?.((p: any) => ({
            value: p.product_id,
            label: helper.getTransString(p.name, lan),
        }));

    const getButtonForProductList = () => (
        <>
            <Button
                size="middle"
                style={{ marginLeft: 16 }}
                type="primary"
                onClick={() => setShowRelatedProductModal(true)}
            >
                <FormattedMessage id="choose" />
            </Button>
            <Button size="middle" style={{ marginLeft: 16 }} type="primary" onClick={openModalArrange}>
                <FormattedMessage id="arrange_products" />
            </Button>
        </>
    );

    const getButtonForUpsellList = () => (
        <>
            <Button
                size="middle"
                style={{ marginLeft: 16 }}
                type="primary"
                onClick={() => setShowUpsellProductModal(true)}
            >
                <FormattedMessage id="choose" />
            </Button>
            <Button size="middle" style={{ marginLeft: 16 }} type="primary" onClick={openModalArrangeUpsell}>
                <FormattedMessage id="arrange_products" />
            </Button>
        </>
    );

    const onSave = (selected: any) => {
        const save = {
            related_products: selected.map((s: any) => s.product_id),
        };

        form.setFieldsValue(save);

        setValues(Object.assign({}, values, save));

        setShowRelatedProductModal(false);
        closeModalArrange();
    };

    const onSaveUpsell = (selected: any) => {
        const save = {
            upsell_products: selected.map((s: any) => s.product_id),
        };

        form.setFieldsValue(save);

        setValues(Object.assign({}, values, save));

        setShowUpsellProductModal(false);
        closeModalArrangeUpsell();
    };

    const renderRelatedProductModal = () => {
        return showRelatedProductModal ? (
            <AssignItemsModal
                name={intl.formatMessage({ id: "related_products" })}
                title={<FormattedMessage id="related_products" />}
                onSave={onSave}
                onCancel={() => {
                    setShowRelatedProductModal(false);
                }}
                selectedProducts={values?.related_products?.map?.((p: any) => ({ product_id: p }))}
                desc={false}
            />
        ) : null;
    };

    const renderUpsellProductModal = () => {
        return showUpsellProductModal ? (
            <AssignItemsModal
                name={intl.formatMessage({ id: "upsell_products" })}
                title={<FormattedMessage id="upsell_products" />}
                onSave={onSaveUpsell}
                onCancel={() => setShowUpsellProductModal(false)}
                selectedProducts={values?.upsell_products?.map?.((p: any) => ({ product_id: p }))}
                desc={false}
            />
        ) : null;
    };

    const renderArrangeRelatedProductModal = () => {
        return getModalArrange(
            <ArrangeRelatedUpsellModal
                name={intl.formatMessage({ id: "arrange_products" })}
                onSave={onSave}
                onCancel={closeModalArrange}
                selectedProducts={values?.related_products?.map?.((p: any) =>
                    getProductsList()?.find((product) => Number(product?.product_id) === Number(p))
                )}
            />,
            {
                title: <FormattedMessage id="arrange_products" />,
                onCancel: closeModalArrange,
                footer: null,
            }
        );
    };

    const renderArrangeUpsellProductModal = () => {
        return getModalArrangeUpsell(
            <ArrangeRelatedUpsellModal
                name={intl.formatMessage({ id: "arrange_products" })}
                onSave={onSaveUpsell}
                onCancel={closeModalArrangeUpsell}
                selectedProducts={values?.upsell_products?.map?.((p: any) =>
                    getProductsList()?.find((product) => Number(product?.product_id) === Number(p))
                )}
            />,
            {
                title: <FormattedMessage id="arrange_products" />,
                onCancel: closeModalArrangeUpsell,
                footer: null,
            }
        );
    };

    const getModals = () => {
        return (
            <>
                {renderRelatedProductModal()}
                {renderUpsellProductModal()}
                {renderArrangeRelatedProductModal()}
                {renderArrangeUpsellProductModal()}
            </>
        );
    };

    useEffect(() => {
        dispatch(getProducts({}));
    }, [dispatch]);

    return (
        <div className="related-upsell-products">
            <div>
                {getFormItem(
                    "related_products",
                    getMultiSelectInput("related_products", false, getProductsAsOptions()),
                    "related_products_desc",
                    getButtonForProductList()
                )}
                {getFormItem(
                    "up_sell_products",
                    getMultiSelectInput("upsell_products", false, getProductsAsOptions()),
                    "up_sell_products_desc",
                    getButtonForUpsellList()
                )}
            </div>
            {getModals()}
        </div>
    );
}

export default RelatedUpsellProducts;
