import React from "react";
import { View, Text } from "@react-pdf/renderer";

const customDiscount = (styles: React.CSSProperties | any, props: { customDiscount: number }): JSX.Element => {
    return (
        <View style={[styles.customDiscount, { zIndex: "3000" }]}>
            <Text>{props.customDiscount.toString()}% OFF</Text>
        </View>
    );
};
export default customDiscount;
