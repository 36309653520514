import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal, message, Button } from "antd";
import "./bulk-update-controller.scss";
import { ORDER_DETAIL_ACTIONS } from "../../../helper";
import { getOrderId } from "../../../helper-order-list";
import { formatOrderId } from "../../../helper";
import { getStoreWithGid } from "../../../../liveorder/helper";
import { ERROR_MAP } from "../../../../../slices/live-order";
import { FaPlay, FaPause, FaStop, FaCheck, FaExclamation } from "react-icons/fa";
import Copier from "../../../../../components/clipboard-copier";
import actions from "../../../../../slices/order-history";
import liveOrderActions from "../../../../../slices/live-order";

export const BULK_UPDATE_STATUES = {
    updating: "updating",
    pause: "pause",
    cancel: "cancel",
    complete: "complete",
};

export const BULK_UPDATE_API_TYPE = {
    live_order: 1,
    delivery_status: 2,
};

export const CANCEL_RC = 4001;

class BulkUpdateController extends Component {
    state = {};

    componentDidUpdate = (prevsProps) => {
        this.checkStatusUpdate(prevsProps);
    };

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getPropsState = (props = this.props) => _.get(props, "state", {});

    getUpdateStatus = (props = this.props) => _.get(this.getPropsState(props), "updateStatus");

    getUpdatedResults = () => (Array.isArray(_.get(this.state, "results")) ? _.get(this.state, "results") : []);

    getOrderStore = (order) => {
        const orderGid = _.get(order, "g_id", "");
        const storesData = _.get(this.props, "storesData", []);
        return getStoreWithGid(orderGid, storesData);
    };

    isInProgress = () => this.getUpdatedResults().length < this.getSelectedOrders().length;

    showShow = () => this.getPropsState().showProcessingDialog;

    setPropsState = (values) => (this.props.setState ? this.props.setState(values) : () => {});

    getSelectedOrders = () => {
        return Array.isArray(_.get(this.props, "orderState.selectedOrders"))
            ? _.get(this.props, "orderState.selectedOrders")
            : [];
    };

    getRemainingTasks = () =>
        _.pullAllWith(
            _.cloneDeep(this.getSelectedOrders()),
            this.getUpdatedResults(),
            (a, b) => getOrderId(a) === getOrderId(b)
        );

    checkStatusUpdate = (prevsProps) => {
        const statusUpdate = this.getUpdateStatus(prevsProps) !== this.getUpdateStatus();
        const isUpdating = this.getUpdateStatus() === BULK_UPDATE_STATUES.updating;
        if (statusUpdate && isUpdating) {
            this.updateOrders();
        }
    };

    updateRemainingResult = (order, RC) => {
        let results = this.getUpdatedResults();
        results.push({
            ...order,
            RC,
        });
        this.setState({
            results,
        });
    };

    updateOrders = async () => {
        const remainings = this.getRemainingTasks();
        const isUpdating = () => this.getUpdateStatus() === BULK_UPDATE_STATUES.updating;
        const isCanceled = () => this.getUpdateStatus() === BULK_UPDATE_STATUES.cancel;

        for (const order of remainings) {
            await new Promise((resolve) => {
                if (isUpdating()) {
                    const apiAction =
                        this.getPropsState().apiType === BULK_UPDATE_API_TYPE.delivery_status
                            ? this.props.updateLiveOrderDeliveryStatus
                            : this.props.updateLiveOrder;
                    apiAction({
                        ...this.getPropsState().postData,
                        order_id: getOrderId(order),
                        store: this.getOrderStore(order),
                        withMessage: false,
                        callBack: (response) => {
                            this.updateRemainingResult(order, _.get(response, "RC"));
                            resolve(200);
                        },
                    });
                } else if (isCanceled()) {
                    this.updateRemainingResult(order, CANCEL_RC);
                    resolve(200);
                } else {
                    resolve(200);
                }
            });

            if (!this.isInProgress()) {
                this.setPropsState({
                    updateStatus: BULK_UPDATE_STATUES.complete,
                });
            }
        }
    };

    closeDialog = () => {
        const updating = this.getUpdateStatus() === BULK_UPDATE_STATUES.updating;
        const remainings = this.getRemainingTasks();
        if (updating && !_.isEmpty(remainings)) {
            message.error(this.str("bulk_update_none_closable_message"));
        } else {
            this.setPropsState({ showProcessingDialog: false });
            this.setState({ results: [] });
            this.props.setOrdersState({ selectedOrders: [], bulkUpdating: false });
        }
    };

    renderContentMessage = () => {
        const getStatus = () => {
            const action = _.get(this.getPropsState(), "postData.action");
            const STATUS_MAP = {
                [ORDER_DETAIL_ACTIONS.to_processing]: "proccesing",
                [ORDER_DETAIL_ACTIONS.to_cancel]: "cancel",
                [ORDER_DETAIL_ACTIONS.to_complete]: "complete",
            };
            return this.str(STATUS_MAP[action] ? STATUS_MAP[action] : " ");
        };

        return (
            <div className="bulk-update-processing-dialog-main-message">
                <FormattedMessage
                    id="processing_orders"
                    values={{
                        status: getStatus(),
                    }}
                />
            </div>
        );
    };

    renderProcessingResults = () => {
        const resultMap = _.cloneDeep(ERROR_MAP);
        resultMap[200] = "success";
        resultMap[CANCEL_RC] = "no_change";

        const getResultStr = (order) => _.get(resultMap, _.get(order, "RC"), "unexpect_error");

        const getResultIcon = (order) => {
            const isSuccess = _.get(order, "RC") === 200;
            if (isSuccess) {
                return <FaCheck className="bulk-update-processing-dialog-result-item-icon success" />;
            }
            return <FaExclamation className="bulk-update-processing-dialog-result-item-icon error" />;
        };

        const renderResultItem = (order) => {
            return (
                <div className="bulk-update-processing-dialog-result-item">
                    <span className="bulk-update-processing-dialog-result-item-order-id">
                        #{formatOrderId(getOrderId(order))}
                    </span>
                    <span className="bulk-update-processing-dialog-result-item-copier">
                        <Copier copyContent={getOrderId(order)} copyLabel="order_id" />:
                    </span>
                    <span>{getResultIcon(order)}</span>
                    <span className="bulk-update-processing-dialog-result-item-result">
                        <FormattedMessage id={getResultStr(order) || " "} />
                    </span>
                </div>
            );
        };
        return (
            <div className="bulk-update-processing-dialog-result-container">
                {this.getUpdatedResults().map((order) => renderResultItem(order))}
            </div>
        );
    };

    renderControlButton = () => {
        const status = this.getUpdateStatus();

        const ICON_MAP = {
            [BULK_UPDATE_STATUES.updating]: (
                <FaPause className="bulk-update-processing-dialog-controll-button-icon pause" />
            ),
            [BULK_UPDATE_STATUES.pause]: <FaPlay className="bulk-update-processing-dialog-controll-button-icon play" />,
        };

        const STRING_MAP = {
            [BULK_UPDATE_STATUES.updating]: "pause",
            [BULK_UPDATE_STATUES.pause]: "resume",
        };

        const ACTION_MAP = {
            [BULK_UPDATE_STATUES.updating]: BULK_UPDATE_STATUES.pause,
            [BULK_UPDATE_STATUES.pause]: BULK_UPDATE_STATUES.updating,
        };

        const onStopClick = () => {
            this.setPropsState({
                updateStatus: BULK_UPDATE_STATUES.cancel,
            });
            if (status === BULK_UPDATE_STATUES.pause) {
                this.getRemainingTasks().map((order) => this.updateRemainingResult(order, CANCEL_RC));
            }
        };

        const onPausePlayClick = () => {
            this.setPropsState({
                updateStatus: ACTION_MAP[status],
            });
        };

        if (this.isInProgress())
            return (
                <div className="bulk-update-processing-dialog-controll-button">
                    {STRING_MAP[status] ? (
                        <Button onClick={() => onPausePlayClick()}>
                            <div className="bulk-update-processing-dialog-controll-button-content">
                                {ICON_MAP[status]}
                                <FormattedMessage id={STRING_MAP[status] || " "} />
                            </div>
                        </Button>
                    ) : (
                        ""
                    )}
                    <Button onClick={() => onStopClick()}>
                        <div className="bulk-update-processing-dialog-controll-button-content">
                            <FaStop className="bulk-update-processing-dialog-controll-button-icon stop" />
                            <FormattedMessage id="stop" />
                        </div>
                    </Button>
                </div>
            );
    };

    renderCompleteMessage = () => {
        const isCancel = !_.isEmpty(
            this.getUpdatedResults().find((order) => {
                const RC = _.get(order, "RC");
                return RC === CANCEL_RC;
            })
        );

        if (!this.isInProgress())
            return (
                <div className="bulk-update-processing-dialog-complete-message">
                    <FormattedMessage id={isCancel ? "task_canceled" : "task_complete"} />
                </div>
            );
    };

    renderCloseButton = () => {
        return (
            <div className="bulk-update-processing-dialog-close-button">
                <Button
                    onClick={() => {
                        this.closeDialog();
                    }}
                >
                    <FormattedMessage id="close" />
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Modal visible={this.showShow()} closable={false} onCancel={() => this.closeDialog()} footer={null}>
                <div className="order-list-bulk-update-process-dialog">
                    {this.renderContentMessage()}
                    {this.renderProcessingResults()}
                    {this.renderControlButton()}
                    {this.renderCompleteMessage()}
                    {this.renderCloseButton()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    orderState: _.get(state, "orders-page", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
});

const mapDispatchToProps = {
    setOrdersState: actions.setState,
    updateLiveOrder: (data) => liveOrderActions.updateOrder({ data }),
    updateLiveOrderDeliveryStatus: liveOrderActions.updateLiveOrderDeliveryStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BulkUpdateController));
