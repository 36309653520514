import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import Wrapper from "components/wrapper";
import { deleteCategory, getCategories, updateCategories, updateCategory } from "services/categories";
import { HiChevronDown, HiChevronRight, HiDotsHorizontal, HiMenu } from "react-icons/hi";
import { Button, Dropdown, Input, List, Menu, Modal, Switch } from "antd";
import "./index.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { getProducts } from "services/products";
import useModal from "hooks/useModal";
import AssignItemsModal from "components/assign-items-modal";
import { Product } from "slices/products";
import RenameCategoryModal from "./_components/rename-category-modal";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Category, updateCategoryOrder } from "slices/categories";
import { useHistory } from "react-router-dom";
import ArrangeItemsModal from "./_components/arrange-items-modal";
import helper from "util/helper";
import AddEditCategoryDrawer from "./pages/addEditCategory/add-edit-category-drawer";
import RefreshButton from "components/refresh";
import config from "config";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "categories",
            breadcrumbName: "categories",
        },
    ],
};

const SELECTED_CATEGORY_PRODUCT_AMOUNT = 1000;

const categoriesPageSelector = (state: RootState) => ({
    categories: state?.categories?.categories,
    lan: state?.setting?.lan,
    loading: state?.categories?.loading,
});

function CategoriesPage(): JSX.Element {
    const intl = useIntl();
    const history = useHistory();
    const { categories: cats, lan, loading } = useSelector(categoriesPageSelector);
    const [selectedId, setSelectedId] = useState<number | boolean | null>(null);
    const [search, setSearch] = useState<string>("");
    const [editId, setEditId] = useState<number | boolean | null>(null);
    const [hidden, setHidden] = useState<number[]>([]);
    const [showAssignItemsModal, setShowAssignItemsModal] = useState(false);
    const {
        openModal: openModalRename,
        getModal: getModalRename,
        closeModal: closeModalRename,
    } = useModal("rename-category");
    const {
        openModal: openModalArrange,
        getModal: getModalArrange,
        closeModal: closeModalArrange,
    } = useModal("assign-items");
    const categories = cats.filter((c) => c.level !== config.CATEGORY_LEVELS.root);
    const displayedCategories = categories?.filter((c) =>
        helper.getTransString(c?.name, lan)?.toLowerCase()?.includes(search?.toLowerCase())
    );
    const dispatch = useDispatch();

    const hasSubCategory = (categoryID: number) => {
        return categories.find((c) => c.parent_id === categoryID) !== undefined;
    };

    const getPreviousLevel = (categoryID: number) => {
        return categories.find((c) => c.category_id === categoryID)?.level ?? config.CATEGORY_LEVELS.root;
    };

    const updateCategoryList = (updateArray: any) => {
        dispatch(updateCategoryOrder(updateArray));
        dispatch(updateCategories(updateArray));
    };

    const moveCategory = (result: DropResult) => {
        const updatedCategoryID = Number(result.draggableId);
        const updateArray: any[] = [];
        const updateValue: any = {
            category_id: updatedCategoryID,
            position: result.destination?.index,
            level: config.CATEGORY_LEVELS.main,
        };

        if (!hasSubCategory(updatedCategoryID)) {
            updateValue.parent_id = categories[result.destination?.index ?? -1]?.parent_id;
            updateValue.level = categories[result.destination?.index ?? -1]?.level;
        }

        updateArray.push(updateValue);

        categories
            .filter((c) => c.category_id !== updatedCategoryID)
            .forEach((c, i) => {
                updateArray.push({
                    category_id: c.category_id,
                    position: i < (result?.destination?.index ?? 0) ? i : i + 1,
                });
            });
        updateCategoryList(updateArray);
    };

    const moveToSubCategory = (result: DropResult) => {
        const updatedCategoryID = Number(result.draggableId);
        const updateArray: any[] = [
            {
                category_id: updatedCategoryID,
                parent_id: Number(result.combine?.draggableId),
                level: getPreviousLevel(updatedCategoryID) + 1,
            },
        ];
        updateCategoryList(updateArray);
    };

    const onDragEnd = (result: DropResult) => {
        const updatedCategoryID = Number(result.draggableId);
        if (result.source.index !== result.destination?.index) {
            if (_.isEmpty(result.combine)) {
                moveCategory(result);
            } else if (
                getPreviousLevel(updatedCategoryID) === config.CATEGORY_LEVELS.main &&
                !hasSubCategory(updatedCategoryID)
            ) {
                moveToSubCategory(result);
            }
        }
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        backgroundColor: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = () => ({
        backgroundColor: "white",
    });

    const renderListItem = (category: Category, i: number) => {
        const openAssignItems = (e: any) => {
            e.stopPropagation();
            setSelectedId(category.category_id);
            dispatch(
                getProducts(
                    { category_id: category.category_id, paging: { limit: SELECTED_CATEGORY_PRODUCT_AMOUNT } },
                    true
                )
            );
            setShowAssignItemsModal(true);
        };

        const openArrangeItems = () => {
            setSelectedId(category.category_id);
            dispatch(
                getProducts(
                    { category_id: category.category_id, paging: { limit: SELECTED_CATEGORY_PRODUCT_AMOUNT } },
                    true
                )
            );
            openModalArrange();
        };

        const openRename = () => {
            setSelectedId(category.category_id);
            openModalRename();
        };

        const onSave = (selected: Product[]) => {
            dispatch(
                updateCategory({
                    category_products: selected.map((product) => product.product_id),
                    category_id: category.category_id,
                })
            );

            setShowAssignItemsModal(false);
            closeModalArrange();
        };

        const onSaveRename = (name: string) => {
            dispatch(
                updateCategory({
                    name,
                    category_id: category.category_id,
                })
            );

            closeModalRename();
        };

        const onEdit = () => {
            setEditId(category?.category_id);
            dispatch(
                getProducts(
                    { category_id: category.category_id, paging: { limit: SELECTED_CATEGORY_PRODUCT_AMOUNT } },
                    true
                )
            );
        };

        const menu = () => (
            <Menu onClick={(e) => e.domEvent.stopPropagation()}>
                <Menu.Item onClick={openRename}>
                    <FormattedMessage id="rename" />
                </Menu.Item>
                <Menu.Item onClick={openArrangeItems}>
                    <FormattedMessage id="arrange_products" />
                </Menu.Item>
                <Menu.Item onClick={onEdit}>
                    <FormattedMessage id="edit" />
                </Menu.Item>
                <Menu.Item danger onClick={onDelete}>
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );

        const onDelete = () => {
            Modal.confirm({
                title: intl.formatMessage({ id: "confirm_delete" }),
                content: intl.formatMessage(
                    { id: "are_you_sure_delete_category" },
                    { category: category.name?.[lan ?? ""] }
                ),
                onOk() {
                    dispatch(deleteCategory(category.category_id));
                },
            });
        };

        const onRowClick = () => {
            onEdit();
        };

        const showArrow = () => {
            return (
                category.level === config.CATEGORY_LEVELS.main &&
                Boolean(cats.find((c) => c.parent_id === category.category_id))
            );
        };

        const renderAssignItemsModal = () => {
            return showAssignItemsModal ? (
                <AssignItemsModal
                    name={category.name?.[lan ?? ""]}
                    title={<FormattedMessage id="assign_items_to" values={{ category: category.name?.[lan ?? ""] }} />}
                    category_id={category?.category_id}
                    onSave={onSave}
                    onCancel={() => setShowAssignItemsModal(false)}
                />
            ) : null;
        };

        const renderRenameModal = () => {
            return getModalRename(
                <RenameCategoryModal name={category.name} onSave={onSaveRename} onCancel={closeModalRename} />,
                {
                    title: (
                        <FormattedMessage id="renaming_category" values={{ category: category.name?.[lan ?? ""] }} />
                    ),
                    onCancel: closeModalRename,
                    footer: null,
                }
            );
        };

        const renderArrangeModal = () => {
            return getModalArrange(
                <ArrangeItemsModal
                    name={category.name?.[lan ?? ""]}
                    category_id={category?.category_id}
                    onSave={onSave}
                    onCancel={closeModalArrange}
                />,
                {
                    title: (
                        <FormattedMessage id="arranging_category" values={{ category: category.name?.[lan ?? ""] }} />
                    ),
                    onCancel: closeModalArrange,
                    footer: null,
                }
            );
        };

        const renderModals = () =>
            selectedId === category?.category_id ? (
                <>
                    {renderAssignItemsModal()}
                    {renderRenameModal()}
                    {renderArrangeModal()}
                </>
            ) : null;

        const onChangeAvailability = (checked: boolean) => {
            const value = checked ? 1 : 0;
            dispatch(
                updateCategory({
                    is_active: value,
                    category_id: category.category_id,
                })
            );
        };

        const onArrowClick = (e: any) => {
            e.stopPropagation();
            if (showArrow()) {
                if (hidden.includes(category.category_id)) {
                    setHidden((prev) => prev.filter((p) => p !== category.category_id));
                } else {
                    setHidden((prev) => [...prev, category.category_id]);
                }
            }
        };

        const renderCategory = (dragHandleProps: any) => (
            <List.Item
                key={category.category_id}
                actions={
                    category?.level !== config.CATEGORY_LEVELS.root
                        ? [
                              <Switch
                                  key={`category-switch-${category.category_id}`}
                                  checked={category.is_active === 1}
                                  onChange={onChangeAvailability}
                              />,
                              <Button
                                  key={`category-button-${category.category_id}`}
                                  type="link"
                                  onClick={openAssignItems}
                              >
                                  <FormattedMessage id="assign_items" />
                              </Button>,
                              <Dropdown key={`category-dropdown-${category.category_id}`} overlay={menu()}>
                                  <Button type="link" onClick={(e) => e.stopPropagation()}>
                                      <HiDotsHorizontal />
                                  </Button>
                              </Dropdown>,
                          ]
                        : []
                }
                style={{
                    paddingLeft: (category.level - 2) * 32 === 0 ? 12 : (category.level - 1) * 32,
                }}
            >
                <List.Item.Meta
                    title={
                        <div className="category-title" onClick={onRowClick}>
                            <div className="arrange-drag-indicator" {...dragHandleProps}>
                                <HiMenu />
                            </div>
                            <div>{helper.getTransString(category.name, lan) + ` (${category?.product_count})`}</div>
                            {showArrow() ? (
                                <div className="category-arrow" onClick={onArrowClick}>
                                    {!hidden.includes(category.category_id) ? <HiChevronDown /> : <HiChevronRight />}{" "}
                                </div>
                            ) : null}
                        </div>
                    }
                />
                {renderModals()}
            </List.Item>
        );

        return !hidden.includes(category?.parent_id) ? (
            <Draggable
                key={category.category_id}
                draggableId={category.category_id.toString()}
                index={i}
                isDragDisabled={category?.level === config.CATEGORY_LEVELS.root}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="draggable-category"
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                        {renderCategory(provided.dragHandleProps)}
                    </div>
                )}
            </Draggable>
        ) : null;
    };

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getProducts({}));
    }, [dispatch]);

    const getSearch = () => (
        <div className="category-search">
            <Input
                value={search}
                onChange={(e) => setSearch(e.target?.value)}
                placeholder={intl.formatMessage({ id: "type_to_search" })}
                size="large"
                allowClear
            />
        </div>
    );

    const refreshPage = () => {
        dispatch(getCategories());
    };

    return (
        <Wrapper helmet={{ title: "categories" }} breadcrumb={breadcrumb} description={"categories_desc"}>
            <div className="cat-action-bar">
                <div className="action-left">
                    {getSearch()}
                    <RefreshButton refreshPage={refreshPage} loading={loading} />
                </div>
            </div>

            <AddEditCategoryDrawer
                cid={editId}
                visible={editId}
                onClose={() => setEditId(null)}
                className="edit-popup"
            />
            <div className="category-page">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" isCombineEnabled>
                        {(provided) => (
                            <div ref={provided.innerRef} style={getListStyle()}>
                                <List dataSource={displayedCategories} renderItem={renderListItem} />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => history.push("/categories/addEditCategories")}
                        className="add-button-handler"
                    >
                        <FormattedMessage id="add_category" />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default CategoriesPage;
