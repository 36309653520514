import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { formatCurrency, formatPhone, utcToLocal } from "util/helper";
import { Quote } from "../../models/quote";
import "./styles.scss";
import { useState } from "react";
import _ from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { setState } from "slices/quotes";
import { getQuoteDetail } from "services/quotes";
import { getCustomer } from "services/customers";
import history from "app/history";
import { RootState } from "app/reducer";
import { RiGroupLine } from "react-icons/ri";
import { getShippingMethodMappingInfo } from "pages/quotes/helper";
import helper from "util/helper";

interface QuoteCardProps extends Quote, PropsFromRedux {
    isDineIn: boolean;
}

interface QuoteStateProps {
    selectedQuotes: string[];
    lan?: string;
    isEditMode: boolean;
}

const FORMAT_TIME_OPTS = {
    type: "short",
    time: true,
    date: false,
    asap: false,
    local: true,
};

const FORMAT_DATE_OPTS = {
    type: "calendar",
    time: false,
    date: true,
    asap: false,
    local: true,
    shouldOmitToday: true,
};

const QuoteCard = (props: QuoteCardProps) => {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    });

    const renderShippingMethod = () => {
        const [shippingMethodStringID, appendedClassName] = getShippingMethodMappingInfo(props.shp_mtd, props.isDineIn);
        return (
            <div className={"quote-card-shp-elem quote-card-shp-" + appendedClassName}>
                <FormattedMessage id={shippingMethodStringID} />
            </div>
        );
    };

    const renderTableNo = () => {
        return props.table_no ? <div className="quote-card-shp-tblno">#{props.table_no}</div> : null;
    };

    const renderQuoteId = () => {
        return <div className="quote-card-qid-elem">#{props.quote_id}</div>;
    };

    const renderTime = () => {
        return <div className="quote-card-time-elem">{utcToLocal(props.updated_at, FORMAT_TIME_OPTS)}</div>;
    };

    const renderDate = () => {
        const formatDate = utcToLocal(props.updated_at, FORMAT_DATE_OPTS).split("@");
        return formatDate.length > 1 ? <div className="quote-card-date-elem">{formatDate[0].trim()}</div> : null;
    };

    const renderCustomerName = () => {
        let customerName = "";
        if (props.customer_firstname && props.customer_lastname) {
            customerName = props.customer_firstname + " " + props.customer_lastname[0];
        } else if (props.customer_firstname) {
            customerName = props.customer_firstname;
        } else if (props.customer_lastname) {
            customerName = props.customer_lastname;
        }
        return <div className="quote-card-qid-customer-name">{customerName}</div>;
    };

    const renderPartySize = () => {
        return (
            <div className="quote-card-pts-elem">
                <RiGroupLine className="quote-card-pts-icon" size={18} />
                {props.party_size}
            </div>
        );
    };

    const renderPhoneNumber = () => {
        return props.customer_phone ? (
            <div className="quote-card-phone-elem">{formatPhone(props.customer_phone)}</div>
        ) : null;
    };

    const renderItemSummary = () => {
        return (
            <div className="quote-card-item-summary-elem">
                {props.item_count + " "}
                <FormattedMessage id="item_s" />
                {": " + formatCurrency(props.g_total)}
            </div>
        );
    };

    /**
     * If the user is in edit mode, select the quote when clicked,
     * otherwise load the quote in create order page.
     */
    const handleQuoteClick = () => {
        if (props.isEditMode || !_.isEmpty(props.selectedQuotes)) {
            // Will also be in edit state if there are selected quotes
            return selectQuote();
        }
        props.getCustomer({ customer_id: props.customer_id });
        props.getQuoteDetail({
            qid: props.quote_id,
            lan: props.lan,
            callBack: () => {
                helper.goToCreateOrderPage(window, history);
            },
        });
    };

    /**
     * Remove the quote from selected if the quote is already
     * in the selected list, otherwise add the quote to the selected list
     */
    const selectQuote = () => {
        if (isSelected) {
            const selectionRemoved = props.selectedQuotes.filter((qid) => {
                return qid !== props.quote_id;
            });

            props.setState({ selected: selectionRemoved });
            setIsSelected(false);
        } else {
            const selectedClone = _.cloneDeep(props.selectedQuotes);
            selectedClone.push(props.quote_id);
            props.setState({ selected: selectedClone });
            setIsSelected(true);
        }
    };

    useEffect(() => {
        if (props.selectedQuotes.includes(props.quote_id)) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [props.isEditMode, props.selectedQuotes]);

    return (
        <div className={"quote-card-wrapper"}>
            <div
                className={isSelected ? "quote-card quote-card-selected-border" : "quote-card"}
                onClick={handleQuoteClick}
            >
                <div className="quote-card-top-row">
                    {renderShippingMethod()}
                    {props.isDineIn ? renderTableNo() : null}
                </div>
                <div className="quote-card-contents">
                    <div className="quote-card-qid-time-row">
                        {renderQuoteId()}
                        {renderTime()}
                    </div>
                    <div className="quote-card-date-row">{renderDate()}</div>
                    <div className="quote-card-customer-name-phone-row">
                        {renderCustomerName()}
                        {renderPhoneNumber()}
                    </div>
                    <hr />
                    <div className="quote-card-pts-gtotal-row">
                        {props.isDineIn ? renderPartySize() : <div></div>}
                        {renderItemSummary()}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const stateProps: QuoteStateProps = {
        selectedQuotes: _.get(state, "quotes.selected", []),
        lan: state?.setting?.lan,
        isEditMode: _.get(state, "quotes.isEditMode", false),
    };
    return stateProps;
};

const mapDispatchToProps = {
    setState,
    getCustomer,
    getQuoteDetail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuoteCard);
