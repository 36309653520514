import { Button, Radio } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { SignUpView } from "../../constants";

function SelectSignupMethod(props: Record<string, any>): JSX.Element {
    const [selectedView, setSelectedView] = useState<number>(SignUpView.SelfSignUpMethod);
    const { setView } = props;
    return (
        <div className="select-signup-methods">
            <div className="select-signup-method-button" onClick={() => setSelectedView(SignUpView.SelfSignUpMethod)}>
                <div className="select-signup-radio">
                    <Radio checked={selectedView === SignUpView.SelfSignUpMethod} />
                </div>
                <div className="select-signup-content">
                    <div className="select-signup-method-button-title">
                        <FormattedMessage id="self_serve_signup" />
                    </div>
                    <div className="select-signup-method-button-description">
                        <FormattedMessage id="self_serve_signup_desc" />
                    </div>
                </div>
            </div>
            <div className="select-signup-method-button" onClick={() => setSelectedView(SignUpView.AssistedMethod)}>
                <div className="select-signup-radio">
                    <Radio checked={selectedView === SignUpView.AssistedMethod} />
                </div>
                <div className="select-signup-content">
                    <div className="select-signup-method-button-title">
                        <FormattedMessage id="assisted_signup" />
                    </div>
                    <div className="select-signup-method-button-description">
                        <FormattedMessage id="assisted_signup_desc" />
                    </div>
                </div>
            </div>
            <Button onClick={() => setView(selectedView)} size="large" type="primary">
                <FormattedMessage id="next" />
            </Button>
        </div>
    );
}

export default SelectSignupMethod;
