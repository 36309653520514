import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { HiInformationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import { RootState } from "../app/reducer";
import { store } from "../app/store";
import { createMessage } from "../components/message";
import { getIntl } from "../locale";
import { ReferralTransactionActions } from "../pages/referrals/constants";
import { getCustomerPointsHistorySuccess } from "../slices/customers";
import {
    getReferralsListSuccess,
    getReferralsTransactionsHistorySuccess,
    setAddModalState,
    setRedeemModalState,
    getCustomerReferralsSuccess,
    getOrderHistorySuccess,
    setReferralsState,
} from "../slices/referrals";
import { oauth } from "../util/api";
import { getCustomer } from "./customers";

export const getReferralsList = (params: any = {}) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
    try {
        const response: any = await oauth("REFERRAL_LIST")({
            method: "POST",
            body: JSON.stringify({ data: params }),
        });

        switch (response?.RC) {
            case 200:
                dispatch(
                    getReferralsListSuccess({
                        referrals: response?.res?.res,
                        paging: response?.res?.paging,
                        searchConditions: params,
                    })
                );
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const makeReferralTransaction =
    (params: any = {}) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            // @ts-ignore
            const state: RootState = store.getState();
            const response: any = await oauth("REFERRAL_TRANSACTIONS")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    dispatch(getReferralsList(state?.referrals?.searchConditions));
                    dispatch(setRedeemModalState({ customer_id: "", customer_name: "", refresh_customer: false }));
                    dispatch(setAddModalState({ customer_id: "", customer_name: "", refresh_customer: false }));
                    if (params.refresh_customer) {
                        dispatch(getCustomer(params));
                    }
                    if ((state?.referrals?.transactions ?? [])?.length > 0) {
                        dispatch(getReferralTransaction(state?.referrals?.transactionsSearchConditions));
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getReferralTransaction = (params: any={}) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
    const intl = getIntl();
    try {
        // @ts-ignore
        const state: RootState = store.getState();
        const response: any = await oauth("REFERRAL_TRANSACTIONS")({
            method: "POST",
            body: JSON.stringify({
                data: {
                    action: ReferralTransactionActions.View,
                    ...(state?.referrals?.transactionsSearchConditions ?? {}),
                    ...params,
                },
            }),
        });

        switch (response?.RC) {
            case 200:
                if (params?.customer_record && response?.res?.res?.length) {
                    dispatch(getCustomerPointsHistorySuccess(response));
                } else if (response?.res?.res?.length > 0) {
                    dispatch(
                        getReferralsTransactionsHistorySuccess({
                            transactions: response?.res?.res,
                            paging: response?.res?.paging,
                        })
                    );
                    dispatch(setReferralsState({ noRecords: false }));
                } else {
                    dispatch(setReferralsState({ noRecords: true }));
                    toast(createMessage(intl.formatMessage({ id: "no_record" }), HiInformationCircle));
                }
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const getCustomerReferralList =
    (params: any={}) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            // @ts-ignore
            const response: any = await oauth("REFERRALS_LIST")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });

            switch (response?.RC) {
                case 200:
                    if (response?.res?.res?.length > 0) {
                        dispatch(
                            getCustomerReferralsSuccess({
                                customerReferrals: response?.res?.res,
                                paging: response?.res?.paging,
                            })
                        );
                        dispatch(setReferralsState({ noRecords: false }));
                    } else {
                        dispatch(setReferralsState({ noRecords: true }));
                        toast(createMessage(intl.formatMessage({ id: "no_record" }), HiInformationCircle));
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getReferralOrderHistory =
    (params: any={}) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            // @ts-ignore
            const response: any = await oauth("REFERRAL_ORDER_HISTORY")({
                method: "POST",
                body: JSON.stringify({
                    data: params,
                }),
            });

            switch (response?.RC) {
                case 200:
                    if (response?.res?.res?.length > 0) {
                        dispatch(
                            getOrderHistorySuccess({
                                orderHistory: response?.res?.res,
                                paging: response?.res?.paging,
                            })
                        );
                        dispatch(setReferralsState({ noRecords: false }));
                    } else {
                        dispatch(setReferralsState({ noRecords: true }));
                        toast(createMessage(intl.formatMessage({ id: "no_record" }), HiInformationCircle));
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
