import React, { Component } from "react";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./order-list-list-pane.scss";
import TitleSection from "../title-section";
import SearchBar from "../search-bar";
import OrderList from "./order-list";
import ViewHistoryButton from "./view-hitory-button";

class LiveOrderListStyle extends Component {
    getFilteredStatus = () => {
        return _.get(this.props, "state.listFilteredStatus", "all");
    };

    getListData = () => {
        var data = {};
        const sortedList = _.get(this.props, "state.groupedLiveOrderList", {});
        const filteredStatus = this.getFilteredStatus();
        //filter list with data
        if (filteredStatus === "all") {
            data = sortedList;
        } else {
            data[filteredStatus] = _.get(sortedList, filteredStatus, []);
        }
        return data;
    };

    isEmpty = () => {
        var allListArray = Object.values(this.getListData());
        const flattenArray = Array.prototype.concat.apply([], allListArray);
        return flattenArray.length === 0;
    };

    renderList = () => {
        return (
            <div>
                <TitleSection />
                <SearchBar />
                <OrderList list={this.getListData()} />
                <ViewHistoryButton />
            </div>
        );
    };

    renderEmpty = () => {
        return (
            <div>
                <TitleSection />
                <SearchBar />
                <div className="no-order-message">
                    <FormattedMessage id="no_order" />
                </div>
                <ViewHistoryButton />
            </div>
        );
    };

    render() {
        return (
            <div className="live-order-list-list-pane-container">
                {this.isEmpty() ? this.renderEmpty() : this.renderList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveOrderListStyle));
