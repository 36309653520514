import React from "react";
import _ from "lodash";
import { Checkbox, Popover } from "antd";
import { FormattedMessage } from "react-intl";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { getIntl } from "../../../locale";
import h from "../helper";
import helper from "../../../util/helper";
import moment from "moment";
import { ROLE_MAPPING } from "config/config.json";

const roles = Object.values(ROLE_MAPPING);
const ID_COL = 0;
const ACTION_COL = 7;

function emptyFunction() {
    // does nothing
}

export const renderHeader = (cols: any[], activeCols: any[], onChange: any = emptyFunction): JSX.Element => (
    <div className="admin-header">
        {cols.map((col: string, index: number) =>
            index === ID_COL && activeCols[ID_COL] ? (
                <div style={{ width: "60px" }}>
                    <FormattedMessage id={col ?? " "} />
                </div>
            ) : index === ACTION_COL ? (
                renderColMenu(cols, activeCols, onChange)
            ) : !activeCols[index] ? null : (
                <div style={{ flex: "1 1 15%" }}>
                    <FormattedMessage id={col} />
                </div>
            )
        )}
    </div>
);
const getColMenu = (cols: any, activeCols: any, onChange: any = emptyFunction) => (
    <div className="admin-colMenu-container">
        {cols
            ?.filter((c: any) => c !== " ")
            .map((col: any, index: number) => (
                <div key={`admin-colMenu-${col}-${index}`}>
                    <Checkbox checked={activeCols[index]} onChange={() => onChange(index)}>
                        <FormattedMessage id={col ?? " "} />
                    </Checkbox>
                </div>
            ))}
    </div>
);

const renderColMenu = (cols: any, activeCols: any, onChange: any = emptyFunction) => (
    <div style={{ width: "30px" }} className="d-flex justify-content-center">
        <Popover placement="bottomRight" content={getColMenu(cols, activeCols, onChange)}>
            <AiOutlinePlusCircle color="grey" size="22" />
        </Popover>
    </div>
);

export const renderEmptyWarn = (active: boolean): JSX.Element => (
    <div className="inactive-empty-warn w100">
        <BsFillPersonFill size="60" color="lightgrey" className="mb-2" />
        <FormattedMessage id={active ? "no_active_users" : "no_inactive_users"} />
    </div>
);

export const showUser = (user: any = {}, searchValue: string, active: boolean): boolean => {
    const doesInclude = (val: string) => val?.toUpperCase()?.includes(searchValue?.toUpperCase());
    if (h.isActive(user?.is_active) !== active) return false;
    if (searchValue) {
        const filters = [
            _.get(user, "username", ""),
            getName(user),
            _.get(user, "email", ""),
            getRole(user),
            getPhone(_.get(user, "phone", "")),
            _.get(user, "id", "").toString(),
            getSince(_.get(user, "created", "")),
        ];
        return filters?.find((filter: string) => doesInclude(filter));
    }
    return true;
};

const getRole = (user: any) => {
    const intl = getIntl();
    return intl.formatMessage({ id: roles[user?.role] ?? "store_assistant" });
};

export const getSince = (since: string): string => moment(since).format("YYYY-MM-DD HH:MM:SS");
export const getName = (user: any = {}): string => `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
export const getPhone = (phone: any = {}): string | null => {
    return phone?.area_code && phone?.phone_number
        ? `+${phone?.area_code} ${helper.formatPhone(phone?.phone_number)}`
        : "";
};
