import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import "./order-menus.scss";
import { getOrderMenuString, getGroupProductId } from "../../../order-history/helper";
import { HiExternalLink } from "react-icons/hi";
import { getGSaleURL } from "util/helper";

class OrderMenus extends Component {
    getOrder = () => (this.props.order ? this.props.order : {});

    renderOrderMenuAndGroupId = () => {
        const menu = getOrderMenuString(this.getOrder(), this.props.lan);
        const groupProductId = getGroupProductId(this.getOrder());

        const renderMenu = () => {
            return (
                <span>
                    <FormattedMessage id="menu" />: {menu}
                </span>
            );
        };

        const renderGroupPurchaseId = () => {
            const link = (
                <a
                    href={getGSaleURL(groupProductId, this.props.lan)}
                    target="_blank"
                    className="order-group-purchase-link"
                    rel="noreferrer"
                >
                    #{groupProductId}
                    <span className="h-spacing-8" />
                    <HiExternalLink className="external-link-icon" />
                </a>
            );
            return (
                <span>
                    <FormattedMessage id="group_purchase_product" />: {link}
                </span>
            );
        };

        const renderSeparator = () => {
            return <span> | </span>;
        };

        let content = null;
        if (menu && groupProductId) {
            content = (
                <>
                    {renderMenu()}
                    {renderSeparator()}
                    {renderGroupPurchaseId()}
                </>
            );
        } else if (menu) {
            content = renderMenu();
        } else if (groupProductId) {
            content = renderGroupPurchaseId();
        }

        return content ? <div className="order-menu-str">{content}</div> : null;
    };

    render() {
        return <div className="order-menu-container">{this.renderOrderMenuAndGroupId()}</div>;
    }
}

const mapStateToProps = (state) => ({
    state: _.get(state, "liveOrders", {}),
    lan: _.get(state, "settings.lan", "en"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderMenus));
