import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { Select, DatePicker } from "antd";
import { FILTER_KEYS, FILER_DATES_OPTIONS, getFilterDayLabel } from "../../../helper-order-list";
import actions from "../../../../../slices/order-history";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DISPLAY_DATE_FORMAT = "MM/DD/YYYY";

class FilterDrawerCollapseDate extends Component {
    getCurrentValue = (key) => {
        const filterDrawerValue = _.get(this.props, `drawerState.${key}`, "");
        const modalState = _.get(this.props, `modalState.${key}`, "");
        return modalState || filterDrawerValue;
    };

    getStartDate = () => {
        const startDate = this.getCurrentValue(FILTER_KEYS.start_date);
        const startDateMoment = moment(startDate, FILTER_KEYS.date_format);
        return startDateMoment;
    };

    getEndDate = () => {
        const endDate = this.getCurrentValue(FILTER_KEYS.end_date);
        const endDateMoment = moment(endDate, FILTER_KEYS.date_format);
        return endDateMoment;
    };

    updateState = (updateObject) => {
        if (this.props.setDrawerState) {
            this.props.setDrawerState(updateObject);
        }
        if (this.props.setModalState) {
            this.props.setModalState(updateObject);
        }
    };

    renderSelects = () => {
        const DRAWER_DATE_CUSTOMER_KEY = "date_custom";

        const getCurrentValue = () => {
            const startDate = this.getStartDate().format(FILTER_KEYS.date_format);
            const endDate = this.getEndDate().format(FILTER_KEYS.date_format);
            const isCustom = this.getCurrentValue(DRAWER_DATE_CUSTOMER_KEY);
            const label = getFilterDayLabel(startDate, endDate);
            return isCustom || !label ? "custom" : label;
        };

        const onChange = (value) => {
            let updateObject = {
                [DRAWER_DATE_CUSTOMER_KEY]: value === "custom",
            };
            if (value !== "custom") {
                updateObject[FILTER_KEYS.start_date] = FILER_DATES_OPTIONS[value].s_date;
                updateObject[FILTER_KEYS.end_date] = FILER_DATES_OPTIONS[value].e_date;
            }
            this.updateState(updateObject);
        };

        return (
            <Select onChange={(value) => onChange(value)} value={getCurrentValue()}>
                {Object.keys(FILER_DATES_OPTIONS).map((option) => (
                    <Option key={option} value={option}>
                        <FormattedMessage id={option} />
                    </Option>
                ))}
            </Select>
        );
    };

    renderDatePicker = () => {
        const disabledDate = (current) => {
            return current && current > moment().endOf("day");
        };

        const onChange = (dates) => {
            this.updateState({
                [FILTER_KEYS.start_date]: dates[0].format(FILTER_KEYS.date_format),
                [FILTER_KEYS.end_date]: dates[1].format(FILTER_KEYS.date_format),
            });
        };

        return (
            <RangePicker
                onChange={onChange}
                disabledDate={disabledDate}
                value={[this.getStartDate(), this.getEndDate()]}
                allowClear={false}
                format={DISPLAY_DATE_FORMAT}
            />
        );
    };

    render() {
        return (
            <div className="filter-drawer-collaspse-date">
                {this.renderSelects()}
                {this.renderDatePicker()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    orderState: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    setOrderState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterDrawerCollapseDate));
