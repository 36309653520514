import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Logo from "../../components/logo";
import Wrapper from "../../components/wrapper";
import { SignUpView } from "./constants";
import "./signup.scss";
import AssistedSignup from "./_components/assisted-signup";
import SelfSignup from "./_components/self-signup";
import SelectSignupMethod from "./_components/select-signup-method";

function Signup(): JSX.Element {
    const history = useHistory();
    const [view, setView] = useState<SignUpView>(SignUpView.SelectMethod);

    return (
        <Wrapper helmet={{ title: "signup" }} public={true}>
            <div className="login-page">
                <div className="login-page-container">
                    <Logo />
                    {view === SignUpView.SelectMethod ? <SelectSignupMethod setView={setView} /> : null}
                    {view === SignUpView.AssistedMethod ? <AssistedSignup setView={setView} /> : null}
                    {view === SignUpView.SelfSignUpMethod ? <SelfSignup setView={setView} /> : null}
                </div>
                <div>
                    <Button type="link" onClick={() => history.push("/")}>
                        <FormattedMessage id="signin_instead" />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default Signup;
