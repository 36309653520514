import { useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import { store } from "../../app/store";
import helper from "../../util/helper";

function useProductsComparator(): any {
    const { lan } = useSelector((state: RootState) => ({
        lan: state.setting?.lan,
    }));
    const getProductsForSort = (p1Id: any, p2Id: any) => {
        const state = store.getState();
        // @ts-ignore
        const products: Product[] = state?.products?.products;
        const p1 = products.find((p) => Number(p.product_id) === Number(p1Id));
        const p2 = products.find((p) => Number(p.product_id) === Number(p2Id));
        return [p1, p2];
    };

    const productNameComparator = (p1Id: any, p2Id: any) => {
        const [p1, p2] = getProductsForSort(p1Id, p2Id);
        const p1Name = helper.getTransString(p1?.name, lan);
        const p2Name = helper.getTransString(p2?.name, lan);

        return p1Name.localeCompare(p2Name);
    };

    const productStockComparator = (p1Id: any, p2Id: any) => {
        const [p1, p2] = getProductsForSort(p1Id, p2Id);
        const p1Stock = p1?.stock ?? 0;
        const p2Stock = p2?.stock ?? 0;

        return p1Stock - p2Stock;
    };

    const productSkuComparator = (p1Id: any, p2Id: any) => {
        const [p1, p2] = getProductsForSort(p1Id, p2Id);
        const p1Stock = p1?.sku ?? 0;
        const p2Stock = p2?.sku ?? 0;

        return p1Stock - p2Stock;
    };

    const productPriceComparator = (p1Id: any, p2Id: any) => {
        const [p1, p2] = getProductsForSort(p1Id, p2Id);
        const p1Price = p1?.price ?? 0;
        const p2Price = p2?.price ?? 0;

        return p1Price - p2Price;
    };

    return {
        productNameComparator,
        productStockComparator,
        productPriceComparator,
        productSkuComparator,
    };
}

export default useProductsComparator;
