import _ from "lodash";
import { isValidPostal, isValidPhone } from "../../util/helper";

const REF_ENABLED = 1;

enum bulkActions {
    ENABLE = 0,
    DISABLE = 1,
    GENERATE = 2,
    DELETE = 3,
}

export default {
    REF_ENABLED,
    bulkActions,
};

export const DEFAULT_ADDRESS: any = {
    country_code: "CA",
};
export const validateAddress = (address: any = {}): any => {
    const defaultAddress = address || {};
    const errorFields: any = {};
    const withDefaultAddress = Object.keys(defaultAddress).find((key) => {
        return defaultAddress[key] && !DEFAULT_ADDRESS[key];
    });
    const requireFields = ["street", "zipcd"];
    if (!_.isEmpty(withDefaultAddress)) {
        //performace valid if default address existed
        requireFields.forEach((key) => {
            const invalidKey = `${key}Invalid`;
            let invalid = false;
            if (key === "zipcd") {
                const cntry = defaultAddress.cntry;
                const postCode = defaultAddress.zipcd;
                invalid = !isValidPostal(postCode, cntry);
            }
            if (!invalid) {
                invalid = !defaultAddress[key];
            }
            errorFields[invalidKey] = invalid;
        });
        const addressPhone = String(_.get(defaultAddress, "tn", "")).trim();
        if (addressPhone.length > 0) {
            errorFields["tnInvalid"] = !isValidPhone(addressPhone);
        }
    } else {
        //else set back to all valid
        requireFields.forEach((key) => {
            const invalidKey = `${key}Invalid`;
            errorFields[invalidKey] = false;
        });
    }
    const valid = _.isEmpty(Object.keys(errorFields).find((key) => errorFields[key]));
    return {
        valid,
        errorFields,
        withDefaultAddress,
    };
};

export const validateCustomer = (customer: any = {}): any => {
    const customerInfo = customer || {};
    const errorFields: any = {};
    ["firstname", "phone"].forEach((key) => {
        const invalidKey = `${key}Invalid`;
        const trimmedVal = String(_.get(customerInfo, key, "")).trim();
        const invalid = trimmedVal === "" || (key === "phone" && !isValidPhone(trimmedVal));
        errorFields[invalidKey] = invalid;
    });
    const valid = _.isEmpty(Object.keys(errorFields).find((key) => errorFields[key]));
    return {
        valid,
        errorFields,
    };
};

export const getCustomerFullName = (customer: any = {}): string => {
    const firstName = _.get(customer, "firstname");
    const lastName = _.get(customer, "lastname");
    const fullName = `${firstName ? `${firstName} ` : ""}${lastName || ""}`;
    return fullName;
};

export const PASSWORD_ACTIONS = {
    reset: 1,
    update: 2,
};
