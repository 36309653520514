import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Row, Col, Modal, Form, Select, Button, Input, message } from "antd";
import _ from "lodash";
import { setState } from "../../../../slices/create-order";
import NumberInput from "../../../../components/number-input";
import { formatCurrency } from "../../../../util/helper";
import config from "config";

class StoreDiscountDialog extends Component<any, any> {
    state: any = {};

    str = (id = " ", values = {}) => this.props.intl.formatMessage({ id }, values);

    isPercentage = () => this.getStoreDiscountType() === config.STORE_DISCOUNT_TYPES.percentage;

    isValid = () => {
        return this.isPercentage() ? this.state.storeDiscountValue <= 100 
                                    : this.state.storeDiscountValue <= this.props.subTotal;
    };

    closeModal = () => {
        this.props.setState({
            showStoreDiscountModal: false,
        });
        this.setState({
            storeDiscountType: null,
            storeDiscountValue: null,
        });
    };

    getStoreDiscountType = () => this.state.storeDiscountType 
                                || this.props.storeDiscountType 
                                || config.STORE_DISCOUNT_TYPES.amount;

    getStoreDiscountValue = () => this.state.storeDiscountValue 
                                || this.props.storeDiscountValue;

    renderStoreDiscountType = () => {
        return (
            <Form.Item label={<FormattedMessage id="discount_type" />}>
                <Select
                    onChange={(value) => this.setState({ storeDiscountType: value })}
                    value={this.getStoreDiscountType()}
                    options={Object.keys(config.STORE_DISCOUNT_TYPES).map((key) => ({
                        value: _.get(config.STORE_DISCOUNT_TYPES, key),
                        label: <FormattedMessage id={key} />,
                    }))}
                />
            </Form.Item>
        );
    };

    renderStoreDiscountValue = () => {
        const isPercent = this.isPercentage();
        return (
            <Form.Item label={<FormattedMessage id="discount_value" />}>
                <NumberInput
                    precision={2}
                    placeholder={formatCurrency(0, this.props.storeCurrency, undefined, false)}
                    value={this.getStoreDiscountValue()}
                    onChange={(value: any) => {
                        this.setState({ storeDiscountValue: value });
                    }}
                    min={0}
                    max={isPercent ? 100 : this.props.subTotal}
                    prefix={isPercent ? "" : "$"}
                    suffix={isPercent ? "%" : ""}
                />
            </Form.Item>
        );
    };

    renderReason = () => {
        return (
            <Form.Item help={<FormattedMessage id="reason_helper" />} label={<FormattedMessage id="reason" />}>
                <Input
                    onChange={(e) => this.props.setState({ storeDiscountNotes: e.target.value })}
                    value={this.props.storeDiscountNotes}
                />
            </Form.Item>
        );
    };

    renderButtons = () => {
        const result = [];
        if (this.props.storeDiscountValue) {
            result.push(
                <Button
                    className="cancel-button"
                    style={{ float: "left" }}
                    onClick={() => {
                        this.props.setState({
                            storeDiscountNotes: "",
                            payment: {
                                ...this.props.payment,
                                storeDiscountType: config.STORE_DISCOUNT_TYPES.amount,
                                storeDiscountValue: 0,    
                            }
                        });
                        this.closeModal();
                    }}
                    danger
                    type="default"
                >
                    <FormattedMessage id="remove_discount" />
                </Button>
            );
        }

        result.push(
            <Button
                className="confirm-button"
                onClick={() => {
                    if (this.state.storeDiscountType || this.state.storeDiscountValue) {
                        //update to redux to trigger ctotal call
                        if (this.isValid()) {
                            this.props.setState({
                                payment: {
                                    ...this.props.payment,
                                    storeDiscountType: this.getStoreDiscountType(),
                                    storeDiscountValue: this.getStoreDiscountValue(),    
                                }
                            });
                            this.closeModal();
                        } else {
                            message.error(this.str("invalid_input"));
                        }
                    } else {
                        this.closeModal();
                    }
                }}
                type="primary"
            >
                <FormattedMessage id="done" />
            </Button>
        );
        return result;
    };

    render() {
        return (
            <Modal
                footer={this.renderButtons()}
                title={<FormattedMessage id="edit_discount" />}
                onCancel={() => this.closeModal()}
                visible={this.props.showStoreDiscountModal}
            >
                <Form layout="vertical">
                    <Row gutter={8}>
                        <Col span={12}>{this.renderStoreDiscountType()}</Col>
                        <Col span={12}>{this.renderStoreDiscountValue()}</Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>{this.renderReason()}</Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "setting.lan", "en"),
    payment: _.get(state, "createOrder.payment", {}),
    subTotal: _.get(state, "createOrder.payment.subTotal", 0),
    showStoreDiscountModal: _.get(state, "createOrder.showStoreDiscountModal", false),
    storeDiscountType: _.get(state, "createOrder.payment.storeDiscountType", null),
    storeDiscountValue: _.get(state, "createOrder.payment.storeDiscountValue", 0),
    storeDiscountNotes: _.get(state, "createOrder.storeDiscountNotes", ""),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(StoreDiscountDialog)));
