import React from 'react';
import { SelectOption } from '../../../../../../components/form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/reducer';
import h from '../../helper'
import { switchNum, formatMoney, parseMoney } from "../../../../../../util/helper";
import {
    renderEnableTop,
    renderNumberInput,
    renderSelectInput,
    renderTextInput,
    renderMultiSelectInput,
    renderCountriesSelection
} from '../shipping-functions'
import { setShippingState } from '../../../../../../slices/store'


function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const enable = useSelector((state: RootState) => state.store?.storeShipping?.fedexEnable);
    const accountID = useSelector((state: RootState) => state.store?.storeShipping?.fedexAccountID);
    const meterNumber = useSelector((state: RootState) => state.store?.storeShipping?.fedexMeterNumber);
    const password = useSelector((state: RootState) => state.store?.storeShipping?.fedexPassword);
    const key = useSelector((state: RootState) => state.store?.storeShipping?.fedexKey);
    const packaging = useSelector((state: RootState) => state.store?.storeShipping?.fedexPckaging);
    const dropoff = useSelector((state: RootState) => state.store?.storeShipping?.fedexDropoff);
    const weightUnit = useSelector((state: RootState) => state.store?.storeShipping?.fedexWeightUnit);
    const maxWeight = useSelector((state: RootState) => state.store?.storeShipping?.fedexMaxWeight);
    const handlingFee = useSelector((state: RootState) => state.store?.storeShipping?.fedexHandlingFee);
    const handlingApply = useSelector((state: RootState) => state.store?.storeShipping?.fedexHandlingApply);
    const calcHandlingFee = useSelector((state: RootState) => state.store?.storeShipping?.fedexCalcHandlingFee);
    const allowMethods = useSelector((state: RootState) => state.store?.storeShipping?.fedexAllowMethods);
    const residential = useSelector((state: RootState) => state.store?.storeShipping?.fedexResidential);
    const allowCountry = useSelector((state: RootState) => state.store?.storeShipping?.fedexAllowCountry);
    const applicableCntry = useSelector((state: RootState) => state.store?.storeShipping?.fedexShipApplicableCntry);
    const NO = "0"
    const YES = "1"

    const handleTypes: SelectOption[] = [
        { value: h.handleType.PER_ORDER, label: intl.formatMessage({ id: "per_order" }) },
        { value: h.handleType.PER_ITEM, label: intl.formatMessage({ id: "per_item" }) },
    ]
    const handleCalcs: SelectOption[] = [
        { value: h.handleCalcs.FIXED, label: intl.formatMessage({ id: "fixed" }) },
        { value: h.handleCalcs.PERCENTAGE, label: intl.formatMessage({ id: "percentage" }) },
    ]
    const weightUnits: SelectOption[] = [
        { value: "LBS", label: intl.formatMessage({ id: "lbs" }) },
        { value: "KGS", label: intl.formatMessage({ id: "kgs" }) },
    ]
    const yesNo: SelectOption[] = [
        { value: NO, label: intl.formatMessage({ id: "no" }) },
        { value: YES, label: intl.formatMessage({ id: "yes" }) }
    ]
    const packages: SelectOption[] = [
        { value: "FEDEX_ENVELOPE", label: intl.formatMessage({ id: "fedex_envelope" }) },
        { value: "FEDEX_PAK", label: intl.formatMessage({ id: "fedex_pak" }) },
        { value: "FEDEX_BOX", label: intl.formatMessage({ id: "fedex_box" }) },
        { value: "FEDEX_TUBE", label: intl.formatMessage({ id: "fedex_tube" }) },
        { value: "FEDEX_10KG_BOX", label: intl.formatMessage({ id: "fedex_10_box" }) },
        { value: "FEDEX_25KG_BOX", label: intl.formatMessage({ id: "fedex_25_box" }) },
        { value: "YOUR_PACKAGING", label: intl.formatMessage({ id: "your_packaging" }) },
    ]
    const dropoffs: SelectOption[] = [
        { value: "REGULAR_PICKUP", label: intl.formatMessage({ id: "regular_pickup" }) },
        { value: "REQUEST_COURIER", label: intl.formatMessage({ id: "request_courier" }) },
        { value: "DROP_BOX", label: intl.formatMessage({ id: "drop_box" }) },
        { value: "BUSINESS_SERVICE_CENTER", label: intl.formatMessage({ id: "business_service_center" }) },
    ]
    const shippingMethods: SelectOption[] = [
        { value: "EUROPE_FIRST_INTERNATIONAL_PRIORITY", label: intl.formatMessage({ id: "europe_national_priority" }) },
        { value: "FEDEX_1_DAY_FREIGHT", label: intl.formatMessage({ id: "fedex_1_freight" }) },
        { value: "FEDEX_2_DAY_FRRIGHT", label: intl.formatMessage({ id: "fedex_2_freight" }) },
        { value: "FEDEX_2_DAY", label: intl.formatMessage({ id: "fedex_2_day" }) },
        { value: "FEDEX_2_DAY_AM", label: intl.formatMessage({ id: "fedex_2_day_am" }) },
        { value: "FEDEX_3_DAY_FREIGHT", label: intl.formatMessage({ id: "fedex_3_day_freight" }) },
        { value: "FEDEX_EXPRESS_SAVER", label: intl.formatMessage({ id: "fedex_express_saver" }) },
        { value: "FEDEX_GROUND", label: intl.formatMessage({ id: "fedex_ground" }) },
        { value: "FIRST_OVERNIGHT", label: intl.formatMessage({ id: "first_overnight" }) },
        { value: "GROUND_HOME_DELIVERY", label: intl.formatMessage({ id: "ground_home_delivery" }) },
        { value: "INTERNATIONAL_ECONOMY", label: intl.formatMessage({ id: "international_economy" }) },
        { value: "INTERNATIONAL_ECONOMY_FREIGHT", label: intl.formatMessage({ id: "international_economy_freight" }) },
        { value: "INTERNATIONAL_FIRST", label: intl.formatMessage({ id: "international_first" }) },
        { value: "INTERNATIONAL_GROUND", label: intl.formatMessage({ id: "international_ground" }) },
        { value: "INTERNATIONAL_PRIORITY", label: intl.formatMessage({ id: "international_priority" }) },
        { value: "INTERNATIONAL_PRIORITY_FREIGHT", label: intl.formatMessage({ id: "international_priority_freight" }) },
        { value: "PRIORITY_OVERNIGHT", label: intl.formatMessage({ id: "priority_overnight" }) },
        { value: "SMART_POST", label: intl.formatMessage({ id: "smart_post" }) },
        { value: "STANDARD_OVERNIGHT", label: intl.formatMessage({ id: "standard_overnight" }) },
        { value: "FEDEX_FREIGHT", label: intl.formatMessage({ id: "fedex_freight" }) },
        { value: "FEDEX_NATIONAL_FREIGHT", label: intl.formatMessage({ id: "fedex_national_freight" }) },
    ]

    const setState = (name: string, value: any) => { dispatch(setShippingState({ name, value })) }
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value).toString() }))

    const renderCountriesSection = (
        <div className="d-flex w100 align-items-center justify-content-between country-selector">
            {renderCountriesSelection("fedexShipApplicableCntry", applicableCntry, "fedexAllowCountry", allowCountry, setState, intl)}
        </div>
    )

    return (
        <div className="white-layered-block w100 table-rate-tab">
            {renderEnableTop("fedexEnable", enable, switchChange)}
            {renderCountriesSection}
            <div className="d-flex flex-wrap w100 mt-3">
                {renderTextInput("user_id", "fedexAccountID", accountID, setState)}
                {renderTextInput("meter_number", "fedexMeterNumber", meterNumber, setState)}
                {renderTextInput("account_key", "fedexKey", key, setState)}
                {renderTextInput("password", "fedexPassword", password, setState)}
                {renderSelectInput("apply_handling", "fedexHandlingApply", handlingApply, setState, handleTypes)}
                {renderSelectInput("packaging", "fedexContainer", packaging, setState, packages)}
                {renderSelectInput("dropoff", "fedexDropoff", dropoff, setState, dropoffs)}
                {renderSelectInput("weight_unit", "fedexWeightUnit", weightUnit, setState, weightUnits)}
                {renderNumberInput("max_weight", "fedexMaxWeight", maxWeight, setState)}
                {renderNumberInput("handling_fee", "fedexHandlingFee", handlingFee, setState, {
                    formatter: (value: any) => formatMoney(value, intl),
                    parser: (value: any) => parseMoney(value, intl)
                })}
                {renderSelectInput("calc_handling_fee", "fedexCalcHandlingFee", calcHandlingFee, setState, handleCalcs)}
                {renderSelectInput("is_residential_delivery", "fedexResidential", residential, setState, yesNo)}
                {renderMultiSelectInput("allowed_methods", "fedexAllowMethods", allowMethods, setState, shippingMethods)}
            </div>
        </div>
    )
}

export default App;