import { Button, Form, Tabs } from "antd";
import _ from "lodash";
import moment from "moment";
import qs from "querystring";
import { useCallback, useEffect, useRef, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "../../../../app/reducer";
import { createMessage } from "../../../../components/message";
import Wrapper from "../../../../components/wrapper";
import config from "../../../../config";
import { runImageCacheHelper } from "../../../../services/image-cache";
import { editProduct, getProduct } from "../../../../services/products";
import { resetProduct } from "../../../../slices/products";
import { oauth } from "../../../../util/api";
import ObjectModel from "../../../../util/models";
import StoreProduct from "../../../../util/models/store-product";
import { GIFT_CARD_TYPE, status } from "../../constants";
import ItemInformationTab from "./_components/item-information";
import ItemSEOTab from "./_components/item-seo";
import RelatedUpsellProducts from "./_components/related-upsell-products";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "giftCards",
            breadcrumbName: "gift_card",
        },
        {
            path: "addEditGiftCard",
            breadcrumbName: "add_edit_gift_card",
        },
    ],
};
export enum ItemTabs {
    ItemInformation = "item_info",
    Fullfilment = "fullfilment",
    Options = "options",
    Seo = "seo",
    RelatedUpsellProducts = "related_upsell_products",
}

const tabs = [
    {
        key: ItemTabs.ItemInformation,
        tab: ItemInformationTab,
    },
    {
        key: ItemTabs.Seo,
        tab: ItemSEOTab,
    },
    {
        key: ItemTabs.RelatedUpsellProducts,
        tab: RelatedUpsellProducts,
    },
];

interface LocationState {
    addEditItem: any;
}

function AddEditGiftCard(): JSX.Element | null {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { pid = "" } = useParams<{ pid?: string }>();
    const tab = String(qs.parse(location.search)?.["?tab"] ?? ItemTabs.ItemInformation);
    const useDuplicate = String(qs.parse(location.search)?.["?duplicate"]) === "1";
    const [form] = Form.useForm();
    const { product, products, lastSearch, gid, user, addEditItemDuplicate } = useSelector((state: RootState) => ({
        product: state?.products?.product,
        products: state?.products?.products,
        user: state?.user,
        gid: state?.store?.records?.g_id,
        lastSearch: state?.giftcards?.oldSearch ?? "",
        addEditItemDuplicate: state?.products?.addEditItemDuplicate,
    }));
    const [touched, setTouched] = useState(false);
    const [images, setImages] = useState<any>([]);

    const getCurrentIndex = () => products?.findIndex?.((p) => Number(p.product_id) === Number(pid));

    const getLastDisabled = () => {
        const current = getCurrentIndex();

        if (current === -1) {
            return true;
        }

        if (current === 0) {
            return true;
        }

        return products?.length === 0;
    };

    const getNextDisabled = () => {
        const current = getCurrentIndex();

        if (current === -1) {
            return true;
        }

        if (current === products.length - 1) {
            return true;
        }

        return products?.length === 0;
    };

    const getFirst = () => {
        history.push(`/giftCards/addEditGiftCard/${products?.[0]?.product_id}`);
    };

    const getLast = () => {
        history.push(`/giftCards/addEditGiftCard/${products?.[products?.length - 1]?.product_id}`);
    };

    const getPrev = () => {
        history.push(`/giftCards/addEditGiftCard/${products?.[getCurrentIndex() - 1]?.product_id}`);
    };

    const getNext = () => {
        history.push(`/giftCards/addEditGiftCard/${products?.[getCurrentIndex() + 1]?.product_id}`);
    };

    const [values, setValues] = useState<any>({});
    const [options, setOptions] = useState<any[]>([]);

    const getValues = () => {
        const v: any = new ObjectModel(
            StoreProduct,
            pid
                ? product
                : {
                      product_type: GIFT_CARD_TYPE,
                      tax_class: 1, // no tax goods
                      status: status.DISABLED,
                  }
        ).getData();

        const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];

        dks.forEach((dk) => {
            v[dk] = moment(v[dk] ?? new Date(), "YYYY-MM-DD hh:mm:ss");
        });

        return Object.assign({}, v, useDuplicate && addEditItemDuplicate ? addEditItemDuplicate : {});
    };

    const shareProduct = () => {
        const url = config.H5_URL + `product/${pid}`;
        navigator?.clipboard?.writeText(url).then(
            function () {
                toast(createMessage(intl.formatMessage({ id: "product_url_copied" }), HiInformationCircle));
            },
            function (err) {
                // Handle error
                console.error(err);
            }
        );
    };

    const handleValuesChanged = (_: any, values: any) => {
        setTouched(form.isFieldsTouched());
        setValues(values);
    };
    const onFinish = async (data: any) => {
        if (product?.product_id) {
            data.product_id = product?.product_id;
        }

        const upload = await oauth("IMAGE_UPLOAD")({
            method: "POST",
            body: JSON.stringify({
                data: {
                    imgs: images
                        .filter((i: any) => i.includes("http"))
                        .map((i: any) => ({
                            url: i,
                            type: "product",
                        })),
                    gid,
                },
            }),
        });

        const post = Object.assign({}, data, values);
        post.options = options;

        post.images = await Promise.all(
            images.map(async (image: any) => {
                if (image.includes("http")) {
                    return upload?.records?.[image];
                }

                return image;
            })
        );

        const dks = ["special_from_date", "special_to_date", "start_date", "end_date"];

        dks.forEach((dk) => {
            if (data[dk] && moment(data[dk]).format("YYYY-MM-DD HH:mm:ss") !== "Invalid date") {
                post[dk] = moment(data[dk]).format("YYYY-MM-DD HH:mm:ss");
            } else {
                post[dk] = "";
            }
        });

        Object.keys(post ?? {}).forEach((key) => {
            if (key !== "product_id" && !dks.includes(key)) {
                //@ts-ignore
                if (_.isEqual(post?.[key], product?.[key])) {
                    delete post[key];
                }
            }
        });

        dispatch(
            editProduct(new ObjectModel(StoreProduct).convertToPostData(post), {}, () => {
                clearForm();
                history.push("/giftCards");
                setTouched(false);
            })
        );
    };

    const onChangeTab = (tab: string) => {
        const search = qs.parse(location.search.slice(1));
        search.tab = tab;
        history.push(
            (pid ? `/giftCards/addEditGiftCard/${pid}?` : "/giftCards/addEditGiftCard?") + qs.stringify(search)
        );
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const showForm = useCallback(() => {
        if (pid) {
            return !_.isEmpty(product);
        }

        return true;
    }, [pid, product]);

    useEffect(() => {
        runImageCacheHelper({
            action: "clear_image_cache",
            user_id: user.id,
            imageType: "",
            type: "store",
        });
    }, [user.id]);

    useEffect(() => {
        dispatch(resetProduct());

        if (pid) {
            dispatch(getProduct(Number(pid)));
        }

        form.resetFields();
    }, [dispatch, pid, form]);

    useEffect(() => {
        setValues(new ObjectModel(StoreProduct, product).getData());
        setImages(product?.images ?? []);
        form.resetFields();
    }, [product, form]);

    useEffect(() => {
        if (!location.pathname.includes("addEditItem")) {
            setTouched(false);
            form.resetFields();
        }
    }, [location, form]);

    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current && showForm()) initialRender.current = false;
        else {
            setTouched(true);
        }
    }, [showForm, options, images]);

    const renderSettingActionBar = () => (
        <div className="setting-actions floating-actions setting-flex-between" style={{ marginTop: 14 }}>
            <div></div>
            <div className="prev-next-buttons">
                {pid ? (
                    <Button.Group>
                        <Button onClick={getFirst} disabled={getLastDisabled()}>
                            <FormattedMessage id="first" />
                        </Button>
                        <Button onClick={getPrev} disabled={getLastDisabled()}>
                            <FormattedMessage id="previous" />
                        </Button>
                        <Button onClick={getNext} disabled={getNextDisabled()}>
                            <FormattedMessage id="next" />
                        </Button>
                        <Button onClick={getLast} disabled={getNextDisabled()}>
                            <FormattedMessage id="last" />
                        </Button>
                    </Button.Group>
                ) : null}
            </div>
            <div>
                <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!touched}
                    className="save-button-handler"
                >
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        </div>
    );

    const renderTabs = () => (
        <Tabs
            type="card"
            activeKey={tab}
            defaultActiveKey={tab}
            onChange={onChangeTab}
            size="large"
            style={{ marginTop: 14 }}
        >
            {tabs.map((tab) => (
                <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                    <tab.tab
                        pid={pid}
                        images={images}
                        setImages={setImages}
                        values={values}
                        setValues={setValues}
                        options={options}
                        setOptions={setOptions}
                        initialValues={getValues()}
                        form={form}
                    />
                </Tabs.TabPane>
            ))}
        </Tabs>
    );

    return showForm() ? (
        <Wrapper
            helmet={{ title: "add_edit_gift_card" }}
            breadcrumb={breadcrumb}
            paddingBottom={true}
            onBack={() => history.push("/giftCards?" + qs.stringify(lastSearch))}
        >
            {pid ? (
                <div className="setting-actions">
                    <Button type="primary" onClick={shareProduct}>
                        <FormattedMessage id="share_product" />
                    </Button>
                </div>
            ) : null}
            <Form
                className="items-info-form"
                initialValues={getValues()}
                form={form}
                onValuesChange={handleValuesChanged}
                size="large"
                onFinish={onFinish}
                style={{ width: "100%" }}
            >
                {renderTabs()}
                {renderSettingActionBar()}
            </Form>
        </Wrapper>
    ) : null;
}

export default AddEditGiftCard;
