import { RootState } from "app/reducer";
import _ from "lodash";
import React, { FocusEventHandler } from "react";
import { connect, ConnectedProps } from "react-redux";
import Wrapper from "../../../../../../components/wrapper";
import { Tabs, Button, Form, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { addContentPage, getContentPage, getContentPages, updateContentPage } from "services/content-pages";
import { getFormItem, getTextInput } from "components/form";
import "./styles.scss";
import useLanguageSelect from "hooks/useLanguageSelect";
import config from "config";
import { ContentPageRequest, GetContentPageParams } from "../../models/content-pages-requests";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ContentPageResponse, ContentPageSummary } from "../../models/content-pages-responses";
import { LanguageStrings } from "models/language-strings";
import { setState } from "slices/content-pages";
import { toast } from "react-toastify";
import { createMessage } from "components/message";
import { NamePath } from "antd/lib/form/interface";
import { getPublish } from "services/publish";

import { Jodit } from "jodit";
import { ResizableBox } from "react-resizable";

const META_DESC_DEFAULT_ROW_COUNT = 3;
const JODIT_EDITOR_HEIGHT = 500;
const COMPARE_CHANGE_DELAY = 1500;

interface AddEditContentPageStateProps {
    displayLanguages: string[];
    isFetching: boolean;
    isUpdating: boolean;
    contentPageList: ContentPageSummary[];
    contentPageDetails: ContentPageResponse;
    redirectUrl: string;
    message: string;
    lan: string;
}

interface AddEditContentPageProps extends AddEditContentPageStateProps, PropsFromRedux {}

interface FieldData {
    errors?: string[];
    name: NamePath;
    touched?: boolean;
    validating?: boolean;
    value?: any;
}

const joditEditors: any = {};
let initialFormData: any = {};

const AddEditContentPage = (props: AddEditContentPageProps) => {
    const history = useHistory();
    const intl = useIntl();
    const { lan, getLanguageSelect, getInputLanguageSelect } = useLanguageSelect();
    const [activeTabKey, setActiveTabKey] = useState("content");
    const { id = "" } = useParams<{ id?: string }>();
    const [form] = Form.useForm();

    const [hasFormChanges, setHasFormChanges] = useState(false);
    const initializeChangedFieldsData = () => {
        const changedFields: any = {};
        const fields = ["name", "content", "meta_title", "meta_keywords", "meta_desc"];
        fields.forEach((field) => {
            Object.keys(config.LANGUAGE_MAP).forEach((lan) => {
                changedFields[`${field}_${lan}`] = false;
            });
        });

        return changedFields;
    };
    const [changedFormFields, setChangedFormFields] = useState<any>(initializeChangedFieldsData());
    const [discardChanges, setDiscardChanges] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    /**
     * Initialize an empty list of timers for each field
     *
     * @returns a list timers for each field
     */
    const initializeTimers = () => {
        const timers: any = {};
        const fields = ["name", "content", "meta_title", "meta_keywords", "meta_desc"];
        fields.forEach((field) => {
            props.displayLanguages.forEach((lan) => {
                timers[`${field}_${lan}`] = [];
            });
        });
        timers.url_key = [];

        return timers;
    };
    /**
     * If fieldName is provided clear timers of specific field,
     * otherwise clear all timers
     *
     * @param fieldName - specific field name timers to clear
     */
    const clearTimers = (fieldName?: string) => {
        if (fieldName) {
            timers[fieldName]?.forEach((timer: any) => {
                clearTimeout(timer);
            });
            return;
        }

        Object.keys(timers).forEach((fieldName) => {
            timers[fieldName].forEach((timer: any) => {
                clearTimeout(timer);
            });
        });
    };
    const timers = initializeTimers();

    /**
     * Build Jodit editors for the content field
     */
    const buildJoditEditors = () => {
        props.displayLanguages.forEach((language) => {
            joditEditors[`content_${language}`] = new Jodit(`#jodit-content-${language}`, {
                height: JODIT_EDITOR_HEIGHT,
                allowResizeY: true,
                allowResizeX: false,
            });

            const editor = joditEditors[`content_${language}`];

            editor.events.on("change", (value: string) => {
                form.setFieldsValue({ [`content_${language}`]: value });
                onFieldChange(`content_${language}`);
            });

            editor.events.on("blur", () => {
                clearTimers(`content_${language}`);
                compareChanges(`content_${language}`);
            });
        });
    };

    const setJoditEditorsFormData = (formData: any) => {
        Object.keys(joditEditors).forEach((fieldName) => {
            joditEditors[fieldName].value = formData[fieldName];
        });
    };

    const resizeJoditEditors = () => {
        Object.keys(joditEditors).forEach((fieldName) => {
            joditEditors[fieldName].events.fire("resize");
        });
    };

    useEffect(() => {
        buildJoditEditors();
        setInitialLoad(false);
        if (!id) {
            return;
        }

        fetchContentPage();
        getContentPages();
    }, []);

    useEffect(() => {
        if (!id || props.isFetching || initialLoad) {
            return;
        }

        const formData = convertContentPageDetailsToFormData();
        initialFormData = formData;
        form.setFieldsValue(formData);
        setJoditEditorsFormData(formData);
    }, [props.isFetching]);

    useEffect(() => {
        if (!props.isUpdating && !initialLoad) {
            props.getPublish();
            const formData = form.getFieldsValue();
            initialFormData = formData;
        }
    }, [props.isUpdating]);

    useEffect(() => {
        if (initialLoad) {
            return;
        }
        handleOnNameBlur();
    }, [activeTabKey]);

    useEffect(() => {
        if (initialLoad) {
            return;
        }

        setHasFormChanges(Object.keys(changedFormFields).find((key) => changedFormFields[key]) ? true : false);
    }, [changedFormFields]);

    useEffect(() => {
        if (props.redirectUrl) {
            const url = props.redirectUrl;
            props.setState({ redirectUrl: "" });
            history.push(url);
        }
    }, [props.redirectUrl]);

    useEffect(() => {
        if (props.message) {
            toast(createMessage(intl.formatMessage({ id: props.message })));
            props.setState({ message: "" });
        }
    }, [props.message]);

    useEffect(() => {
        if (discardChanges && !hasFormChanges) {
            history.push("/settings/contentPages");
        }
    }, [hasFormChanges, discardChanges]);

    useEffect(() => {
        resizeJoditEditors();
    }, [lan]);

    const fetchContentPage = () => {
        const contentPageParams: GetContentPageParams = {
            page_id: id,
            lan: lan,
        };

        props.getContentPage(contentPageParams);
    };

    const getContentPages = () => {
        const params: GetContentPageParams = {
            lan: lan,
        };

        props.getContentPages(params);
    };

    /**
     * Converts the content page detail response from the api to antd's form fields data
     *
     * @returns the content page detail received from the api as data that can be read by antd's form
     */
    const convertContentPageDetailsToFormData = (): any => {
        const formData = {};

        Object.keys(props.contentPageDetails).forEach((key) => {
            // @ts-ignore
            if (typeof props.contentPageDetails[key] === "object") {
                // @ts-ignore
                Object.keys(props.contentPageDetails[key]).forEach((lan) => {
                    // @ts-ignore
                    formData[key + "_" + lan] = props.contentPageDetails[key][lan];
                });
            } else {
                // @ts-ignore
                formData[key] = props.contentPageDetails[key];
            }
        });

        return formData;
    };

    const handleChangeTab = (tabKey: string) => {
        setActiveTabKey(tabKey);
    };

    /**
     * Filters through langauges in the app, and returns the languages that are not used
     * as the store's display language
     *
     * @returns the list of languages that are not used as the store's display language
     */
    const getUnusedLanguages = () => {
        return Object.keys(config.LANGUAGE_MAP).filter((lan) => !props.displayLanguages.includes(lan));
    };

    /**
     * Modifies the form data by coping over values of each language field
     * to its empty language counter parts
     *
     * @param data - form data
     * @param languageFields - a list of field names that contain values for different languages
     */
    const setBlankLanguageFieldsToExistingAlternative = (data: any, languageFields: string[]) => {
        const unusedLanguages = getUnusedLanguages();

        languageFields.forEach((field) => {
            const valid =
                data?.[field + "_" + props.displayLanguages.find((language: any) => data?.[field + "_" + language])] ??
                "";
            props.displayLanguages.forEach((language: any) => {
                if (!data?.[`${field}_${language}`] || data?.[`${field}_${language}`] == null) {
                    data[`${field}_${language}`] = valid;
                }
            });

            // remove any languages that are not in use from language fields
            unusedLanguages.forEach((unusedLanguage) => {
                delete data[`${field}_${unusedLanguage}`];
            });
        });
    };

    /**
     * Builds a LanguageString object from the data provided
     *
     * @param data - specifically named form data
     * @param prefix - the base name of the form's field to be converted to a LanguageString
     * @returns the built LanguageString from the data provided
     */
    const buildLanguageStrings = (data: any, prefix: string) => {
        const lanStrings: LanguageStrings = {};
        lanStrings.en = data[prefix + "_en"];
        lanStrings.zh = data[prefix + "_zh"];
        lanStrings["zh-Hant"] = data[prefix + "_zh-Hant"];
        lanStrings.fr = data[prefix + "_fr"];

        return lanStrings;
    };

    /**
     * Builds a ContentPageRequest object using the data provided
     *
     * @param data - form data
     * @returns the built ContentPageRequest from the data provided
     */
    const buildContentPageRequest = (data: any): ContentPageRequest => {
        return {
            name: buildLanguageStrings(data, "name"),
            content: buildLanguageStrings(data, "content"),
            meta_title: buildLanguageStrings(data, "meta_title"),
            meta_keywords: buildLanguageStrings(data, "meta_keywords"),
            meta_desc: buildLanguageStrings(data, "meta_desc"),
            url_key: data.url_key,
        };
    };

    /**
     * Checks whether all the different languages in the field are empty
     *
     * @param fieldName - base language field name
     * @returns whether all the different languages in the field are empty
     */
    const isEmptyLanguageField = (fieldName: string) => {
        const languageFieldNames: string[] = [];
        props.displayLanguages.forEach((lan) => {
            languageFieldNames.push(`${fieldName}_${lan}`);
        });
        const fields = form.getFieldsValue(languageFieldNames);
        let isEmpty = true;
        Object.keys(fields).forEach((field) => {
            if (fields[field]) {
                isEmpty = false;
            }
        });

        return isEmpty;
    };

    /**
     * Validates the form;
     * Checks that the Name and URL field are not blank
     *
     * @returns form validation errors
     */
    const validateForm = async () => {
        try {
            const values = await form.validateFields(["url_key"]);
            const errors: any[] = [];
            if (isEmptyLanguageField("name")) {
                props.displayLanguages.forEach((lan) => {
                    errors.push({
                        name: [`name_${lan}`],
                        errors: [intl.formatMessage({ id: "field_required" })],
                    });
                });
            }
            if (values.url_key == undefined || values.url_key === "") {
                errors.push({
                    name: ["url_key"],
                    errors: [intl.formatMessage({ id: "field_required" })],
                });
            } else if (isDuplicateUrl()) {
                errors.push({
                    name: ["url_key"],
                    errors: [intl.formatMessage({ id: "duplicate_url_key" })],
                });
            }

            return errors;
        } catch (errorInfo: any) {
            return errorInfo.errorFields;
        }
    };

    /**
     * Process form data, then make the add/update content page request
     *
     * @param formData - form data
     * @param saveContinue - whether the user clicked on save and continue
     */
    const addEditContentPage = (formData: any, saveContinue?: boolean) => {
        const languageFields = ["name", "content", "meta_title", "meta_keywords", "meta_desc"];
        setBlankLanguageFieldsToExistingAlternative(formData, languageFields);
        const contentPageRequest = buildContentPageRequest(formData);

        Object.keys(contentPageRequest).forEach((key) => {
            // @ts-ignore
            if (contentPageRequest[key] === undefined) {
                // @ts-ignore
                delete contentPageRequest[key];
            }
        });

        setHasFormChanges(false);
        if (id) {
            props.updateContentPage(contentPageRequest, id, saveContinue);
        } else {
            contentPageRequest.enabled = true;
            props.addContentPage(contentPageRequest, saveContinue);
        }
    };

    /**
     * Perform form validation and if there are no errors,
     * submit the form
     *
     * @param saveContinue - whether the user clicked on save and continue
     */
    const handleSave = async (saveContinue?: boolean) => {
        const errors = await validateForm();
        if (!_.isEmpty(errors)) {
            const fieldDataList: FieldData[] = errors.map((error: any) => {
                const fieldData: FieldData = {
                    name: error.name,
                    errors: error.errors,
                };
                return fieldData;
            });
            form.setFields(fieldDataList);
            setActiveTabKey("content");
            return;
        }

        const formData = form.getFieldsValue(true);
        addEditContentPage(formData, saveContinue);
    };

    const handleDiscardChanges = () => {
        setHasFormChanges(false);
        setDiscardChanges(true);
    };

    /**
     * Returns whether the inputted URL has already been used by another content page
     *
     * @returns whether the url is already used by another content page
     */
    const isDuplicateUrl = () => {
        const fields = form.getFieldsValue();
        const url = fields.url_key;

        return props.contentPageList.find((contentPage) => contentPage._id !== id && contentPage.url_key === url)
            ? true
            : false;
    };

    /**
     * Compare form changes after user finishes typing
     *
     * @param fieldName - field name
     */
    const onFieldChange = (fieldName: string) => {
        const timer = timers[fieldName];
        if (timer.length > 0) {
            const prevTimer = timer.pop();
            clearTimeout(prevTimer);
        }

        timer.push(setTimeout(() => compareChanges(fieldName), COMPARE_CHANGE_DELAY));
    };

    /**
     * Modifies the input value to URL format
     *
     * @param e - on input change event
     */
    const handleOnUrlChange = (e: any) => {
        const value = convertStrToUrl(e.target.value);
        const fields = form.getFieldsValue();
        fields.url_key = value;
        form.setFieldsValue(fields);
        onFieldChange("url_key");
    };

    /**
     * Compares the current field value to its original value
     * and sets whether the field has been modified
     *
     * @param fieldName - field name
     */
    const compareChanges = (fieldName: string) => {
        let hasChanges = false;
        const value = form.getFieldValue(fieldName);
        if (value === "" && initialFormData[fieldName] == undefined) {
            hasChanges = false;
        } else if (value !== initialFormData[fieldName]) {
            hasChanges = true;
        }

        setChangedFormFields({ ...changedFormFields, [fieldName]: hasChanges });
    };

    /**
     * Changes name to lowercase and replaces whitespace with dashes
     *
     * @param name - the string to convert to URL format
     * @returns the converted string
     */
    const convertStrToUrl = (name: string) => {
        if (!name) {
            return "";
        }

        return name.toLowerCase().replaceAll(" ", "-");
    };

    /**
     * Converts the value to URL format and sets it in the URL field
     *
     * @param value - the input value
     */
    const setDefaultUrl = (value: string) => {
        const fields = form.getFieldsValue();
        if (fields.url_key != undefined && fields.url_key !== "") {
            return;
        }

        fields.url_key = convertStrToUrl(value);
        form.setFieldsValue(fields);
    };

    /**
     * Set the SEO field to match the name field data
     *
     * @param data - name fields data
     * @param seoField - name of the SEO field
     */
    const setDefaultSeoField = (data: LanguageStrings, seoField: string) => {
        const fields = form.getFieldsValue();
        props.displayLanguages.map((lan) => {
            const key = `${seoField}_${lan}`;
            // @ts-ignore
            const fieldValue = data[lan];
            if ((fields[key] == undefined && initialFormData[key] == undefined) || fields[key] === "") {
                fields[key] = fieldValue;
            }
        });

        form.setFieldsValue(fields);
    };

    const setDefaultMetaTitle = (data: LanguageStrings) => {
        setDefaultSeoField(data, "meta_title");
    };

    const setDefaultMetaDescription = (data: LanguageStrings) => {
        setDefaultSeoField(data, "meta_desc");
    };

    const setDefaultMetaKeywords = (data: LanguageStrings) => {
        setDefaultSeoField(data, "meta_keywords");
    };

    /**
     * Set the URL and SEO fields based on the name if the fields are blank
     */
    const handleOnNameBlur = () => {
        const nameLanguageFields: string[] = props.displayLanguages.map((lan) => {
            return `name_${lan}`;
        });
        const nameFields = form.getFieldsValue(nameLanguageFields);
        const nameLanStr = buildLanguageStrings(nameFields, "name");
        setDefaultMetaTitle(nameLanStr);
        setDefaultMetaDescription(nameLanStr);
        setDefaultMetaKeywords(nameLanStr);
        setDefaultUrl(nameFields.name_en);
    };

    const renderLanguageSelectInput = (
        name: string,
        keyPrefix: string,
        rows?: number,
        onChange?: any,
        required?: boolean,
        allowClear?: boolean,
        desc?: string,
        onBlur?: FocusEventHandler<HTMLInputElement>
    ) => {
        return getInputLanguageSelect(
            config.LANGUAGES.map((language) => {
                const defaultOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
                    clearTimers(`${keyPrefix}_${language}`);
                    if (onBlur) {
                        onBlur(e);
                    }
                    compareChanges(`${keyPrefix}_${language}`);
                };

                const defaultOnChange = () => {
                    if (onChange) {
                        onChange();
                    }
                    onFieldChange(`${keyPrefix}_${language}`);
                };

                return (
                    <div style={{ display: language !== lan ? "none" : "block" }} key={`${keyPrefix}-${language}`}>
                        {getFormItem(
                            name,
                            getTextInput({
                                name: `${keyPrefix}_${language}`,
                                rows,
                                required,
                                allowClear,
                                onChange: defaultOnChange,
                                onBlur: defaultOnBlur,
                            }),
                            desc
                        )}
                    </div>
                );
            }),
            undefined,
            `${keyPrefix}`
        );
    };

    const renderContentEditor = () => {
        return getInputLanguageSelect(
            config.LANGUAGES.map((language) => {
                return (
                    <div style={{ display: language !== lan ? "none" : "block" }} key={`content-${language}`}>
                        {getFormItem(
                            "content",
                            <Form.Item name={"content_" + language}>
                                <ResizableBox
                                    width={Infinity}
                                    height={JODIT_EDITOR_HEIGHT}
                                    maxConstraints={[Infinity, Infinity]}
                                    minConstraints={[470, 200]}
                                >
                                    <textarea
                                        id={`jodit-content-${language}`}
                                        value={form.getFieldValue("content_" + language)}
                                    />
                                </ResizableBox>
                            </Form.Item>
                        )}
                    </div>
                );
            })
        );
    };

    const renderContentTab = () => {
        return (
            <div className="edit-content-page-container">
                <div className="edit-content-page-content">
                    {renderLanguageSelectInput(
                        "name",
                        "name",
                        undefined,
                        undefined,
                        undefined,
                        true,
                        undefined,
                        handleOnNameBlur
                    )}
                    {getFormItem(
                        "url_label",
                        getTextInput({
                            name: "url_key",
                            onChange: handleOnUrlChange,
                            allowClear: true,
                            onBlur: () => compareChanges("url_key"),
                        })
                    )}
                    {renderContentEditor()}
                </div>
            </div>
        );
    };

    const renderSEOTab = () => {
        return (
            <div className="edit-content-page-container">
                <div className="edit-content-page-content">
                    {renderLanguageSelectInput(
                        "meta_title",
                        "meta_title",
                        undefined,
                        undefined,
                        false,
                        true,
                        "meta_title_tip_content_pages"
                    )}
                    {renderLanguageSelectInput(
                        "meta_description",
                        "meta_desc",
                        META_DESC_DEFAULT_ROW_COUNT,
                        undefined,
                        false,
                        true,
                        "meta_description_tip"
                    )}
                    {renderLanguageSelectInput(
                        "meta_keywords",
                        "meta_keywords",
                        undefined,
                        undefined,
                        false,
                        true,
                        "meta_keywords_tip_content_pages"
                    )}
                </div>
            </div>
        );
    };

    const renderSettingsActions = () => {
        return (
            <div className="setting-actions floating-actions">
                <Button type="default" size="large" danger disabled={!hasFormChanges} onClick={handleDiscardChanges}>
                    <FormattedMessage id="discard" />
                </Button>
                <span className="h-spacing-12" />
                <Button type="primary" size="large" disabled={!hasFormChanges} onClick={() => handleSave(false)}>
                    <FormattedMessage id="save_n_close" />
                </Button>
                {id ? (
                    <>
                        <span className="h-spacing-12" />
                        <Button type="primary" size="large" disabled={!hasFormChanges} onClick={() => handleSave(true)}>
                            <FormattedMessage id="save_n_continue" />
                        </Button>
                    </>
                ) : null}
            </div>
        );
    };

    const breadcrumb = {
        routes: [
            { path: "dashboard", breadcrumbName: "nav_dashboard" },
            { path: "/settings", breadcrumbName: "settings_overview" },
            { path: "/settings/contentPages", breadcrumbName: "content_pages" },
            {
                path: "/settings/contentPages/addEditContentPage",
                breadcrumbName: id ? "edit_content_page" : "add_content_page",
            },
        ],
    };

    return (
        <Wrapper helmet={{ title: id ? "edit_content_page" : "add_content_page" }} breadcrumb={breadcrumb}>
            <Prompt
                when={hasFormChanges}
                message={() => {
                    return intl.formatMessage({ id: "alert_leaving_without_save" });
                }}
            />
            <div className="edit-content-page-wrapper">
                <Spin spinning={props.isFetching || props.isUpdating}>
                    <Form form={form} onFinish={addEditContentPage}>
                        <Tabs type="card" activeKey={activeTabKey} onChange={handleChangeTab} size="large">
                            <Tabs.TabPane key={"content"} tab={<FormattedMessage id="content" />}>
                                {renderContentTab()}
                            </Tabs.TabPane>
                            <Tabs.TabPane key="seo" tab={<FormattedMessage id="seo" />}>
                                {renderSEOTab()}
                            </Tabs.TabPane>
                        </Tabs>
                        {getLanguageSelect()}
                        {renderSettingsActions()}
                    </Form>
                </Spin>
            </div>
        </Wrapper>
    );
};

const mapStateToProps = (state: RootState) => {
    const stateProps: AddEditContentPageStateProps = {
        isFetching: _.get(state, "contentPages.isFetching", false),
        isUpdating: _.get(state, "contentPages.isUpdating", false),
        displayLanguages: _.get(state, "store.storeDisplayOptions.product_display_languages", [
            config.LANGUAGE_CODES.en,
        ]),
        contentPageList: _.get(state, "contentPages.contentPageList", []),
        contentPageDetails: _.get(state, "contentPages.contentPageDetails", {}),
        redirectUrl: _.get(state, "contentPages.redirectUrl", ""),
        message: _.get(state, "contentPages.message", ""),
        lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
    };
    return stateProps;
};

const mapDispatchToProps = {
    setState,
    addContentPage,
    getContentPage,
    updateContentPage,
    getPublish,
    getContentPages,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddEditContentPage);
