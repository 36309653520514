import React, { Component } from "react";
import GroupTitle from "../group-title/order-list-group-title";
import TableRow from "./order-list-table-row";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import "./order-list-table.scss";
import { getOrderId } from "../../helper-order-list";
import Collapse from "@material-ui/core/Collapse";

class OrderListTable extends Component {
    state = { showList: true };

    renderGroupTitle = () => {
        const data = this.props.title;
        const show = this.state.showList;
        return (
            <div onClick={() => this.setState({ showList: !show })}>
                <GroupTitle {...data} show={show} />
            </div>
        );
    };

    renderGroupList = () => {
        const list = this.props.list;
        return (
            <Collapse in={this.state.showList}>
                {list.map((order) => (
                    <TableRow key={getOrderId(order)} order={order} />
                ))}
            </Collapse>
        );
    };

    render() {
        return (
            <div key={this.props.key}>
                {this.renderGroupTitle()}
                {this.renderGroupList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    stateList: _.get(state, "orders-page.orderList", []),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTable));
