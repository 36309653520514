import React, { useEffect, useState } from "react";
import { Button, Switch } from "antd";
import _ from "lodash";
import { renderToolTip } from "./../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import h from "util/helper";
import { bin2Number, isBool, num2Binary, strToCharArray, switchNum } from "../../../../util/helper";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import { getAdminDetails, updateNotifications } from "../../../../services/admin";
import { setNotifyState } from "../../../../slices/store";
import { setAdminState } from "../../../../slices/admin";
import { getPricePlanList } from "./../../../../services/price-plan";
import config from "../../../../config";
import ObjectModel from "../../../../util/models";
import StoreNotifications from "../../../../util/models/store-notifications";
import { Prompt } from "react-router-dom";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/notifications", breadcrumbName: "notifications_setting" },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state.user);
    const storeCurrencyAndPricePlan = useSelector((state: RootState) => state.store?.storeCurrencyAndPricePlan);
    const plans = useSelector((state: RootState) => state.pricePlan?.plans);
    const currentPlan = plans.find((p) => p.id.toString() === storeCurrencyAndPricePlan?.price_plan);
    const notifications = useSelector((state: RootState) => state.store?.storeNotifications);
    const records = useSelector((state: RootState) => state.store?.records);
    const admin = useSelector((state: RootState) => state.admin?.profile);
    const lan = useSelector((state: RootState) => state.setting.lan);
    const email_enabled = _.get(admin, "email_enabled", false);
    const text_enabled = _.get(admin, "text_enabled", false);
    const push_enabled = _.get(admin, "push_enabled", false);
    const text_order_notification = _.get(notifications, "text_order_notification", 0);
    const enable_notification_validation = _.get(notifications, "enable_notification_validation", 0);
    const [originalValues, setOriginalValues] = useState<any>(true);
    const disableSMS = currentPlan?.name === "Flex Plan" && h.isSuperUser(currUser) ? false : true;
    const disableTextNoti = h.isSuperUser(currUser) ? false : true;

    useEffect(() => {
        dispatch(getPricePlanList({ lan }));
        dispatch(getAdminDetails());
        dispatch(getStoreDetails());
    }, [dispatch, lan]);

    const setStore = (name: string, value: any) => {
        dispatch(setNotifyState({ name, value }));
        setOriginalValues(false);
    };
    const setAdmin = (name: string, value: any) => {
        dispatch(setAdminState({ name, value }));
        setOriginalValues(false);
    };

    const saveChanges = () => {
        const data = {
            id: admin?.id,
            push_notification_enabled: push_enabled,
            email_notification_enabled: email_enabled,
            text_notification_enabled: email_enabled,
        };
        if (disableSMS) {
            delete data.text_notification_enabled;
        }
        dispatch(updateNotifications({ ...data }));
        const storeData = _.cloneDeep(records);
        if (disableTextNoti) {
            delete storeData.text_order_notification;
        }
        dispatch(
            updateStoreDetails(
                new ObjectModel(StoreNotifications).convertToPostData(notifications, storeData, "notifications_setting")
            )
        );
        setOriginalValues(true);
    };

    const renderSwitchInput = (
        label: string,
        name: string,
        value: any,
        onChange: any = () => {
            // do nothing
        },
        tip?: any,
        desc?: string,
        dis?: boolean
    ) => (
        <div className="options-description mt-2 mb-4 d-flex align-items-center">
            <FormattedMessage id={label ?? " "} />
            {tip && renderToolTip(tip)}
            <Switch
                checked={isBool(value)}
                disabled={dis}
                onChange={() => onChange(name, switchNum(value))}
                className="ml-3 mr-3"
            />
            <div className="options-sub-description">
                <FormattedMessage id={desc ?? " "} />
            </div>
        </div>
    );

    const renderOrderNotification = (
        <div className="white-layered-block">
            <div className="options-header">
                <FormattedMessage id={"order_notification"} />
            </div>
            {renderSwitchInput(
                "notify_by_text_message",
                "text_enabled",
                text_enabled,
                setStore,
                "no_flex_tip",
                "receive_order_by_sms",
                disableSMS
            )}
            {renderSwitchInput(
                "notify_by_email",
                "email_enabled",
                email_enabled,
                setAdmin,
                "notify_by_email_tip",
                "receive_order_by_email"
            )}
            {renderSwitchInput(
                "notify_by_push",
                "push_enabled",
                push_enabled,
                setAdmin,
                "notify_by_push_tip",
                "receive_order_by_phone"
            )}
        </div>
    );

    const renderNoticationValidationSwitch = (
        <div className="options-description mt-2 mb-4 d-flex flex-column align-items-start">
            <div className="mb-1">
                <strong>
                    <FormattedMessage id={"enable_notification_validation"} />
                </strong>
                <Switch
                    checked={isBool(enable_notification_validation)}
                    onChange={() =>
                        setStore("enable_notification_validation", switchNum(enable_notification_validation))
                    }
                    className="ml-3 mr-3"
                />
            </div>
            <div className="options-sub-description">
                <FormattedMessage id={"enable_notification_validation_tip"} />
            </div>
        </div>
    );
    const renderNotificationValidation = (
        <div className="white-layered-block">
            <div className="options-header">
                <FormattedMessage id={"order_notification_confirmation"} />
            </div>
            {renderNoticationValidationSwitch}
        </div>
    );

    const shippingMap = config.SHIPPING_MAPPING_TO_NUMERIC;
    const TEXT_NOTIFICATION_BINARY_INDEX = {
        [shippingMap.eatin]: 0,
        [shippingMap.pickup]: 1,
        [shippingMap.delivery]: 2,
        [shippingMap.free_shipping]: 3,
        [shippingMap.quick_pay]: 4,
    };
    const handleTextSwitch = (method: any) => {
        const binaryStr = num2Binary(text_order_notification, Object.keys(TEXT_NOTIFICATION_BINARY_INDEX).length);
        const enableValues = strToCharArray(binaryStr);
        const index = TEXT_NOTIFICATION_BINARY_INDEX[method];
        const currentUpdatedValue = String(enableValues?.[index]) === "1" ? "0" : "1";
        enableValues[index] = currentUpdatedValue;
        setStore("text_order_notification", bin2Number(enableValues));
    };
    const getSwitchValue = (method: any) => {
        const binaryStr = num2Binary(text_order_notification, Object.keys(TEXT_NOTIFICATION_BINARY_INDEX).length);
        const enableValues = strToCharArray(binaryStr);
        const index = TEXT_NOTIFICATION_BINARY_INDEX[method];
        return String(enableValues?.[index]) === "1";
    };
    const renderOrderTextNotification = (
        <div className="white-layered-block">
            <div className="options-header">
                <FormattedMessage id={"customer_order_notification_by_text_message"} />
            </div>
            {renderSwitchInput(
                "in_store",
                " ",
                getSwitchValue(shippingMap.eatin),
                () => handleTextSwitch(shippingMap.eatin),
                "",
                " ",
                disableTextNoti
            )}
            {renderSwitchInput(
                "pickup",
                " ",
                getSwitchValue(shippingMap.pickup),
                () => handleTextSwitch(shippingMap.pickup),
                "",
                " ",
                disableTextNoti
            )}
            {renderSwitchInput(
                "delivery",
                " ",
                getSwitchValue(shippingMap.delivery),
                () => handleTextSwitch(shippingMap.delivery),
                "",
                " ",
                disableTextNoti
            )}
            {renderSwitchInput(
                "instant_pay",
                "push_enabled",
                getSwitchValue(shippingMap.free_shipping),
                () => handleTextSwitch(shippingMap.free_shipping),
                "",
                " ",
                disableTextNoti
            )}
            {renderSwitchInput(
                "quick_pay",
                " ",
                getSwitchValue(shippingMap.quick_pay),
                () => handleTextSwitch(shippingMap.quick_pay),
                "",
                " ",
                disableTextNoti
            )}
        </div>
    );

    const renderSave = () => (
        <div className="setting-actions" style={{ marginRight: "10px" }}>
            <Button
                type="primary"
                size="large"
                disabled={originalValues}
                onClick={() => saveChanges()}
                className="save-button-handler"
            >
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    const renderUnsavePrompt = () => {
        return (
            <Prompt
                when={!originalValues}
                message={() => {
                    return intl.formatMessage({ id: "alert_leaving_without_save" });
                }}
            />
        );
    };

    return !_.isEmpty(notifications) && !_.isEmpty(admin) ? (
        <Wrapper helmet={{ title: "notifications_setting" }} breadcrumb={breadcrumb}>
            {renderUnsavePrompt()}
            <div className="notify-page">
                {renderOrderNotification}
                {renderNotificationValidation}
                {/* {renderOrderPrinter} */}
                {renderOrderTextNotification}
                {renderSave()}
            </div>
        </Wrapper>
    ) : null;
}

export default App;
