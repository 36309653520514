import React, { useEffect, useContext } from "react";
import _ from "lodash";
import dH from "../helper";
import { RootState } from "../../../../../../app/reducer";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popover, Modal } from "antd";
import { modType } from "../helper";
import { HiDotsHorizontal } from "react-icons/hi";
import { getProducts } from "../../../../../../services/products";
import { getCategories } from "../../../../../../services/categories";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDiscounts } from "../../../../../../services/discounts";
import AddDelModal from "./add-mode-modal";
import { updateStoreDiscounts } from "../../../../../../services/discounts";
import ObjectModel from "../../../../../../util/models";
import DiscountsModel from "../../../../../../util/models/store-auto-discounts";
import {
    setAddDelModal,
    setModType,
    fillCurrData,
    setAutoDiscounts,
    clearCurrData,
    setEditIndex,
} from "../../../../../../slices/discounts";
import { IsMobileContext } from "../../../../../../util/helper/isMobileContext";
import "./styles.scss";

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
    lan: state?.setting?.lan,
});

function DeliverySubTab(): JSX.Element | null {
    const { isMobile } = useContext(IsMobileContext);
    const intl = useIntl();
    const dispatch = useDispatch();
    const openModal = useSelector((state: RootState) => state.discounts?.addDelModal);
    const discounts = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts);
    const storeCurrency: TypeKey =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const data = _.get(discounts, "disc_delivery.data", []);
    const { categories, products, lan } = useSelector(categoriesPageSelector);
    const excludeCats = _.get(discounts, "disc_delivery.exclude_category_ids", []);
    const excludeProd = _.get(discounts, "disc_delivery.exclude_product_skus", []);
    const percent = _.get(discounts, "disc_delivery.type") === 1 ? true : false;
    const type = _.isEmpty(excludeCats) ? (_.isEmpty(excludeProd) ? dH.ALL : dH.PRODUCT) : dH.CATEGORY;
    const rows: any = [];
    let exclude: any = [];
    if (Array.isArray(excludeProd) && Array.isArray(excludeCats)) {
        exclude = excludeCats.concat(excludeProd);
    }

    useEffect(() => {
        dispatch(getStoreDiscounts());
        dispatch(getProducts({ paging: { limit: 1000 } }));
        dispatch(getCategories());
    }, [dispatch]);

    const createNewRule = () => {
        dispatch(clearCurrData());
        dispatch(setModType({ value: modType.delivery }));
        dispatch(setAddDelModal({ value: true }));
    };

    const saveChanges = () => {
        dispatch(
            updateStoreDiscounts(
                new ObjectModel(DiscountsModel).convertToPostData(discounts, null, "delivery_discounts")
            )
        );
    };

    const deleteRow = (row: any) => {
        const value = _.cloneDeep(data);
        delete value[row.min];
        dispatch(setAutoDiscounts({ name: "disc_delivery.data", value }));
        dispatch(clearCurrData());
    };

    const editRow = (row: any, index: number) => {
        const condition = _.get(
            discounts,
            "currData.condition",
            _.isEmpty(excludeCats) ? (_.isEmpty(excludeProd) ? dH.ALL : dH.PRODUCT) : dH.CATEGORY
        );
        dispatch(setModType({ value: modType.delivery }));
        dispatch(setAddDelModal({ value: true }));
        dispatch(
            fillCurrData({
                data: _.get(discounts, "disc_delivery", {}),
                min: row.min,
                discount: row.discount,
                key: row.min,
                condition,
            })
        );
        dispatch(setEditIndex({ editIndex: index }));
    };

    const actions = (row: any, index: number) => (
        <React.Fragment>
            <p className="discount-rule-action popover-item" onClick={() => editRow(row, index)}>
                <FormattedMessage id="edit" />
            </p>
            <p
                className="discount-rule-action popover-item danger"
                onClick={() => dH.deleteRowConfirm(row, (row: any) => deleteRow(row))}
            >
                <FormattedMessage id="delete" />
            </p>
        </React.Fragment>
    );

    const renderActs = (row: any, index: number) =>
        isMobile() ? (
            <div className="actions-col">{actions(row, index)}</div>
        ) : (
            <div className="actions-col">
                <Popover content={actions(row, index)}>
                    <HiDotsHorizontal size={25} color="#3898c8" />
                </Popover>
            </div>
        );

    const renderClickableColumn = (row: any, type: any, percent: any, index: any, children: any) => (
        <div className="clickable-row-parent">
            <div className="clickable-row" onClick={() => editRow(row, index)}></div>
            {children}
        </div>
    );

    const columns = [
        {
            width: "20%",
            component: (row: any, type: any, percent: boolean, index: any) =>
                renderClickableColumn(row, type, percent, index, dH.renderMini(row, storeCurrency)),
        },
        {
            width: "20%",
            component: (row: any, type: any, percent: boolean, index: any) =>
                renderClickableColumn(row, type, percent, index, dH.renderDisc(row, percent, storeCurrency)),
        },
        {
            width: "45%",
            component: (row: any, type: any, percent: boolean, index: any) =>
                renderClickableColumn(row, type, percent, index, dH.renderCond(row, type)),
        },
        {
            width: "15%",
            component: (row: any, type: any, percent: boolean, index: number) => renderActs(row, index),
        },
    ];

    const renderRows = () => {
        const excluded = dH.getExcludedNames(exclude, type, products, categories, lan);
        Object.keys(data).forEach((key) => rows.push({ min: key, discount: data[key], excluded }));
        return Array.isArray(rows) && !_.isEmpty(rows)
            ? rows.map((row: any, index: number) =>
                  !isMobile() ? (
                      <div className="table-row d-flex" key={index}>
                          {columns.map((col, i) => (
                              <div className="table-col d-flex" style={{ width: col.width }} key={i}>
                                  {col.component(row, type, percent, index)}
                              </div>
                          ))}
                      </div>
                  ) : (
                      dH.renderRowMobile(true, index, columns, row, type, percent)
                  )
              )
            : dH.renderEmptySet;
    };

    const renderAddModal = (
        <Modal
            visible={openModal}
            title={intl.formatMessage({ id: "create_automatic_delivery_discount" })}
            width="700px"
            footer={null}
            onCancel={() => dispatch(setAddDelModal({ value: false }))}
        >
            <AddDelModal />
        </Modal>
    );

    const renderAddRuleButton = (
        <div className="w100 d-flex mt-3 justify-content-end">
            <Button
                type="default"
                className="text-on-white-background"
                disabled={Object.keys(data).length >= 3}
                onClick={() => createNewRule()}
            >
                <FormattedMessage id="add_rule" />
            </Button>
            <Button type="primary" className="ml-2 save-button-handler" onClick={() => saveChanges()}>
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return !_.isEmpty(products) && !_.isEmpty(discounts) ? (
        <div className="subTab-table-container">
            {!isMobile() && !_.isEmpty(data) ? dH.renderTableHeader(columns) : null}
            {renderRows()}
            {renderAddRuleButton}
            {renderAddModal}
        </div>
    ) : null;
}

export default DeliverySubTab;
