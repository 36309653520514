import React, { useState } from "react";
import { Modal } from "antd";
import _ from "lodash";
import { useIntl } from "react-intl";
import aH from "./../helper";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { toggleActiveCols, toggleEditDrawer, setCurrentUser } from "../../../slices/admin-users";
import { deleteAdminUser } from "../../../services/admin-users";
import { RootState } from "../../../app/reducer";
import helper, { isBool } from "../../../util/helper";
import { renderHeader, renderEmptyWarn, showUser, getName, getPhone, getSince } from "./users-functions";

interface activeUsersTabProps {
    props: any;
}
const ActiveUsersTab = (props: activeUsersTabProps): JSX.Element | null => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state.user);
    const users = useSelector((state: RootState) => state.adminUsers.users);
    const activeCols = useSelector((state: RootState) => state.adminUsers.activeCols);
    const searchValue = useSelector((state: RootState) => state.adminUsers.searchValue);
    const defaultRoles = useSelector((state: RootState) => state.adminUsers.defaultRoles);
    const storeRoles = useSelector((state: RootState) => state.adminUsers.storeRoles);
    const [showAllRoles, setShowAllRoles] = useState<number[]>([]);
    const lan = useSelector((state: RootState) => state?.setting?.lan);
    const cols = ["id", "username", "name", "phone", "email", "admin_role", "user_since", " "];
    const userID = _.get(currUser, "id");
    const IS_ACTIVE = !isBool(props.props);

    const toggleActiveColumns = (index: number) => {
        dispatch(toggleActiveCols({ index }));
    };

    const openEditDrawer = (user: any) => {
        dispatch(setCurrentUser(user));
        dispatch(toggleEditDrawer());
    };

    const deleteUser = (user: any) => {
        if (aH.isSuperManagerUser(currUser) && Number(user.id) !== userID) {
            Modal.confirm({
                title: intl.formatMessage({ id: "delete_user" }),
                content: intl.formatMessage({ id: "are_you_sure" }),
                onOk() {
                    dispatch(deleteAdminUser({ id: user.id }));
                },
                okType: "danger",
            });
        } else {
            Modal.warning({
                title: intl.formatMessage({ id: "cannot_delete_user" }),
                content: intl.formatMessage({ id: "cannot_delete_own_account" }),
            });
        }
    };

    const renderRows = () => {
        let isEmpty = true;
        return (
            <React.Fragment>
                {Array.isArray(users) && users.length > 0
                    ? users?.map((user: any, index: number) => {
                          return showUser(user, searchValue, IS_ACTIVE) ? (
                              <div className="admin-row-container pHover d-flex">
                                  <div className="d-flex w100" onClick={() => openEditDrawer(user)}>
                                      {activeCols?.[0] ? <div style={{ width: "60px" }}>{user?.id}</div> : null}
                                      {activeCols?.[1] ? <div style={{ flex: "1 1 15%" }}>{user?.username}</div> : null}
                                      {activeCols?.[2] ? <div style={{ flex: "1 1 15%" }}>{getName(user)}</div> : null}
                                      {activeCols?.[3] ? (
                                          <div style={{ flex: "1 1 15%" }}>{getPhone(user?.phone)}</div>
                                      ) : null}
                                      {activeCols?.[4] ? <div style={{ flex: "1 1 15%" }}>{user.email}</div> : null}
                                      {activeCols?.[5] ? getRoles(user, index) : null}
                                      {activeCols?.[6] ? (
                                          <div style={{ flex: "1 1 15%" }}>{getSince(user?.created)}</div>
                                      ) : null}
                                  </div>
                                  <div className="delete-col" onClick={() => deleteUser(user)}>
                                      {aH.isSuperManagerUser(currUser) ? (
                                          <AiOutlineClose size="19" color="grey" />
                                      ) : null}
                                  </div>
                                  {(isEmpty = false)}
                              </div>
                          ) : null;
                      })
                    : null}
                {isEmpty ? renderEmptyWarn(IS_ACTIVE) : null}
            </React.Fragment>
        );
    };

    const getAllRolesTxt = (user: any) => {
        const roles = _.get(user, "roles", []);
        const DEFAULT_ROLES_NUM = 4;
        let allRoles = "";
        const isDefaultRole = (i: number) => {
            defaultRoles?.map((role: any) => {
                if (parseInt(role?.role_id) === parseInt(roles?.[i])) {
                    const roleName = role?.role_name;
                    allRoles += allRoles ? `, ${roleName}` : roleName;
                }
            });
        };
        const isStoreRole = (i: number) => {
            storeRoles?.map((role: any) => {
                if (parseInt(role?._id) === parseInt(roles?.[i])) {
                    const roleName = helper.getTransString(role?.name, lan);
                    allRoles += allRoles ? `, ${roleName}` : roleName;
                }
            });
        };
        if (Array.isArray(roles)) {
            for (let i = 0; i < roles?.length; i++) {
                roles?.[i] <= DEFAULT_ROLES_NUM ? isDefaultRole(i) : isStoreRole(i);
            }
        }
        return allRoles;
    };

    const getRoles = (user: any, index: number) => {
        const allRoles = getAllRolesTxt(user);
        const updateShowAllRoles = () => {
            dispatch(toggleEditDrawer());
            const allRolesCpy = _.cloneDeep(showAllRoles);
            if (showAllRolesHere()) {
                allRolesCpy?.splice(allRolesCpy?.indexOf(index), 1);
            } else {
                allRolesCpy?.push(index);
            }
            setShowAllRoles(allRolesCpy);
        };
        const showAllRolesHere = () => {
            if (Array.isArray(showAllRoles) && !_.isEmpty(showAllRoles)) {
                return showAllRoles?.find((el: any) => el === index) !== undefined ? true : false;
            }
            return false;
        };
        return (
            <div className="roles-more" style={{ flex: "1 1 15%" }} onClick={() => updateShowAllRoles()}>
                {allRoles}
            </div>
        );
    };

    const activeTable = (
        <div>
            {renderHeader(cols, activeCols, toggleActiveColumns)}
            {renderRows()}
        </div>
    );

    return !_.isEmpty(users) ? activeTable : null;
};

export default ActiveUsersTab;
