import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import "./gift-card-section.scss";
import { FaAngleRight } from "react-icons/fa";
import { setState } from "../../../../slices/create-order";
import GiftCardsPickerDialog from "./gift-cards-picker-dialog";
import { Modal } from "antd";
import { formatCurrency } from "../../../../util/helper";
import { getGiftcardAccounts } from "services/giftcards";

class GiftCardsPicker extends React.Component<any, any> {
    componentDidMount = () => {
        if (this.props.state?.selectedGiftCard?.code) {
            this.props.getGiftcardAccounts({ c_id: this.props.state?.cid, page: 1, limit: this.props.giftCardsCount });
        }
    };

    componentDidUpdate = () => {
        if (
            this.props.giftCards.length > 0 &&
            this.props.state?.selectedGiftCard?.code &&
            this.props.state?.selectedGiftCard?.balance === undefined
        ) {
            this.props.setState({
                selectedGiftCard: this.props.giftCards.find(
                    (giftcard: any) => giftcard?.code === this.props.state?.selectedGiftCard?.code
                ),
            });
        }
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    getActualStr = () => {
        const placeHolder = this.props.intl.formatMessage({ id: "set_giftcard" });
        return this.getGiftCardStr() ? this.getGiftCardStr() : placeHolder;
    };

    getGiftCardStr = () => {
        return this.props.selectedGiftCard?.code ? (
            <div>
                <div> {this.props.selectedGiftCard?.code} </div>
                <div style={{ fontSize: 15, textAlign: "end" }}>
                    <FormattedMessage
                        id="point_balance"
                        values={{ balance: formatCurrency(this.props.selectedGiftCard?.balance) }}
                    />
                </div>
            </div>
        ) : null;
    };

    onRowClick = () => {
        const isPointsUsed = this.props.pointsUsed;
        if (isPointsUsed) {
            Modal.warning({
                okText: this.str("confirm"),
                content: this.str("gift_cards_points_alert"),
            });
        } else {
            this.props.setState({ displayModalName: "giftcards" });
        }
    };

    renderItemValue = () => (
        <div className="payment-method-picker-containter">
            <div className={`payment-picker-text ${this.getGiftCardStr() ? "" : "grey-out"}`}>
                {_.isString(this.getActualStr()) ? <FormattedMessage id={this.getActualStr()} /> : this.getActualStr()}
            </div>
            {this.props.giftCardsCount ? (
                <span className="payment-picker-value-icon">
                    <FaAngleRight />
                </span>
            ) : null}
        </div>
    );

    render() {
        return this.props.giftCardsCount ? (
            <>
                <div className="createOrder-list-item-container" onClick={() => this.onRowClick()}>
                    <div className="createOrder-list-item-section-one">
                        <FormattedMessage id="giftcards" />
                    </div>
                    <div className="createOrder-list-item-section-two"> {this.renderItemValue()} </div>
                </div>
                <GiftCardsPickerDialog />
            </>
        ) : null;
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    lan: state.setting.lan,
    selectedGiftCard: _.get(state, "createOrder.selectedGiftCard", {}),
    pointsUsed: _.get(state, "createOrder.pointsUsed", 0),
    giftCardsCount: _.get(state, "createOrder.payment.giftCardsCount", 0),
    giftCards: _.get(state, "giftcards.accounts", []),
});

const mapDispatchToProps = {
    setState,
    getGiftcardAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GiftCardsPicker));
