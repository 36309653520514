import React, { Component } from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style-switcher.scss";
import { AiTwotoneAppstore } from "react-icons/ai";
import { FaThList } from "react-icons/fa";
import cx from "classnames";
import _actions from "../../../../slices/live-order";

class StyleSwicher extends Component {
    getCurrentStyle = () => _.get(this.props, "state.listStyle", "list");

    updateStyle = (key) => this.props.updateStyle({ style: key });

    renderListStyleIcon = () => {
        const KEY = "list";
        const isAcive = this.getCurrentStyle() === KEY;
        return (
            <div
                onClick={() => this.updateStyle(KEY)}
                className={cx({
                    "list-style-icon": true,
                    "style-icon": true,
                    "active-icon": isAcive,
                })}
            >
                <FaThList />
            </div>
        );
    };

    renderOnePageStyleIcon = () => {
        const KEY = "one_page";
        const isAcive = this.getCurrentStyle() === KEY;
        return (
            <div
                onClick={() => this.updateStyle(KEY)}
                className={cx({
                    "one-page-style-icon": true,
                    "style-icon": true,
                    "active-icon": isAcive,
                })}
            >
                <AiTwotoneAppstore />
            </div>
        );
    };

    render() {
        return (
            <div className="live-order-list-style-switcher">
                {this.renderListStyleIcon()}
                {this.renderOnePageStyleIcon()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
});

const mapDispatchToProps = {
    updateStyle: _actions.updateStyle,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StyleSwicher));
