import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import qs from "querystring";
import { getPlansSuccess } from "../slices/price-plan";

export const getPricePlanList =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "PPL",
                qs.stringify(params)
            )({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getPlansSuccess(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
