import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";

function StoreManagementValueRenderer(props: any = {}): JSX.Element | null {
    const { renderValue } = props;
    const records = useSelector((state: RootState) => state?.storeManagement?.stores);

    const record = records?.find((r: any) => r?.signup_id === props?.value);

    return record ? <div>{renderValue(record)}</div> : null;
}

export default StoreManagementValueRenderer;
