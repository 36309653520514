import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { FormattedMessage } from "react-intl";
import { RootState } from "../../../../app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../../../services/products";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import config from "../../../../config";
import { validateStrInput } from "../../helper";
import { MAX_LEN_SHORT_DESC } from "../../constants";
import _ from "lodash";
import { copyDataToEmptyLanguageFieldsSingleField } from "util/helper/forms";

const selector = (state: RootState) => ({
    product: state?.products?.product,
    displayLanguages: state?.store?.storeDisplayOptions?.product_display_languages ?? [],
});

interface ProdShortDescProps {
    onClose: any;
}

function ProductShortDescription(props: ProdShortDescProps): JSX.Element {
    const dispatch = useDispatch();
    const { product, displayLanguages } = useSelector(selector);
    const [value, setValue] = useState<any>({});
    const { lan, getModalLanguageSelect } = useLanguageSelect();

    const onSubmit = () => {
        const clonedValues = _.cloneDeep(value);
        copyDataToEmptyLanguageFieldsSingleField({
            values: clonedValues,
            displayLanguages: displayLanguages,
        });

        dispatch(
            editProduct({
                product_id: product?.product_id,
                short_description: clonedValues,
            })
        );

        props.onClose();
    };

    useEffect(() => {
        if (product?.short_description) {
            setValue(product?.short_description);
        } else {
            setValue({});
        }
    }, [product]);

    return (
        <div style={{ position: "relative" }}>
            <div className="modal-lan-select">{getModalLanguageSelect()}</div>
            {config.LANGUAGES.map((l) => (
                <Input.TextArea
                    key={`short_description_${l}`}
                    style={{ display: lan === l ? "block" : "none" }}
                    value={value[l] ?? ""}
                    onChange={(e) => {
                        const fieldsToCheck = [{ prefix: l, maxLen: MAX_LEN_SHORT_DESC }];
                        const validatedValues = validateStrInput({ [l]: e?.target?.value }, fieldsToCheck);
                        setValue((prev: any) => ({ ...prev, ...validatedValues }));
                    }}
                />
            ))}
            <div className="footer-item-changes">
                <Button type="primary" onClick={onSubmit}>
                    <FormattedMessage id="apply" />
                </Button>
            </div>
        </div>
    );
}

export default ProductShortDescription;
