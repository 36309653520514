const referrals = `
    store_flg,                              valueToPoints,              false,          15;
    store_flg,                              pointsToValue,              false,          16;
    store_flg,                              allowCustRefs,              false,          62;
    store_flg,                              allowOrdRefs,               false,          63;
    store_flg,                              refExpiry,                  false,          64;
    store_flg,                              custValToPoints,            false,          65;
    store_flg,                              ordsValToPoints,            false,          66;
    store_flg,                              batchProcess,               false,          67;
    store_flg,                              autoGenRefCode,             false,          83;
`;

export default referrals;
