import React, { Component } from "react";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Tabs } from "antd";
import config from "config";
import helper, { reviseDeliveryMethodWithStore } from "util/helper";
import { groupList } from "../../helper";
import _actions from "slices/live-order";
import { getShippingMethodNumber } from "pages/order-history/helper";

const { TabPane } = Tabs;

const INCLUDES_METHODS = [
    config.SHIPPING_MAPPING_TO_NUMERIC.delivery,
    config.SHIPPING_MAPPING_TO_NUMERIC.pickup,
    config.SHIPPING_MAPPING_TO_NUMERIC.eatin,
];

class ShippingMethodTabs extends Component {
    componentDidMount() {
        this.checkTabWidthChange();
    }

    componentDidUpdate(prevsProps) {
        this.checkChangeTabValue(prevsProps);
        this.checkTabWidthChange(prevsProps);
    }

    str = (id) => this.props.intl.formatMessage({ id });

    getLiveOrder = () =>
        Array.isArray(_.get(this.props, "state.liveOrderList")) ? _.get(this.props, "state.liveOrderList") : [];

    getTabsData = (props = this.props) => {
        const methods = config.SHIPPING_MAPPING_TO_NUMERIC;

        const getAllOrdersLength = (method) => {
            const groupedList = groupList(this.getLiveOrder(), {
                ...props.state,
                filteredShippingMethod: method,
            });
            var allListArray = Object.values(groupedList);
            const flattenArray = Array.prototype.concat.apply([], allListArray);
            return flattenArray.length;
        };

        let result = [];

        INCLUDES_METHODS.forEach((method) => {
            const label = helper.getKeyByValue(methods, method);
            const length = getAllOrdersLength(method);
            if (length) {
                result.push({
                    label: this.str(reviseDeliveryMethodWithStore(this.props.storeRecords, label)),
                    length: length,
                    key: method,
                });
            }
        });

        result.unshift({
            label: this.str("all"),
            length: getAllOrdersLength(0),
            key: "0",
        });

        return result;
    };

    getTabValue = () => {
        return String(_.get(this.props, "state.filteredShippingMethod", "0"));
    };

    checkChangeTabValue = (prevsProps) => {
        const orderListChange = this.props.isListLoading !== prevsProps.isListLoading && !this.props.isListLoading;
        const withThisMethod = this.getLiveOrder().find((order) => {
            return getShippingMethodNumber(order) === Number(this.props.filteredShippingMethod);
        });
        const notAll = this.getTabValue() !== "0";

        if (orderListChange && _.isEmpty(withThisMethod) && notAll) {
            this.onChange("0");
        }
    };

    /**
     * Check if the width of filter tabs should be changed
     * If the order list is loaded or list style is changed, change the width
     *
     * @param prevsProps - previous Props
     */
    checkTabWidthChange = (prevsProps = {}) => {
        const styleChange = this.props.listStyle !== prevsProps.listStyle;
        const listLoaded = this.props.isListLoading !== prevsProps.isListLoading && !this.props.isListLoading;
        const shouldChange = styleChange || listLoaded;

        const setTabWidth = () => {
            const fullTab = document.getElementById("live-order-list-drawer-title-lower-section");
            if (fullTab) {
                const fullWidth = fullTab.offsetWidth;
                const width = parseInt(fullWidth / this.getTabsData().length - 40);
                this.props.setState({ titleShippingTabWidth: width });
            }
        };
        if (shouldChange) {
            setTabWidth();
        }
    };

    onChange = (value) => {
        const groupedList = groupList(this.getLiveOrder(), {
            ...this.props.state,
            filteredShippingMethod: value,
        });
        var allListArray = Object.values(groupedList);
        const flattenArray = Array.prototype.concat.apply([], allListArray);
        const firstOrderId = _.get(flattenArray, "0.ord_id");
        let updateObject = {
            groupedLiveOrderList: groupedList,
            activeId: firstOrderId,
            filteredShippingMethod: value,
            setHeight: !this.props.state?.setHeight,
        };
        if (firstOrderId && this.props.listStyle === "list") {
            updateObject.expansionControllers = {
                [firstOrderId]: { showDetail: true },
            };
        }
        this.props.setState(updateObject);
    };

    renderTabContent = () => {
        const renderTab = (tab) => {
            return (
                <div
                    style={{
                        width: this.props.state.titleShippingTabWidth,
                    }}
                    className="tab-content-row"
                >
                    <div>{tab.label}</div>
                    <div>({tab.length})</div>
                </div>
            );
        };

        return this.getTabsData().map((tab) => {
            return <TabPane tab={renderTab(tab)} key={tab.key} />;
        });
    };

    render() {
        return this.getTabsData().length > 2 ? (
            <Tabs
                activeKey={this.getTabValue()}
                onTabClick={(activeKey) => this.onChange(activeKey)}
                type="card"
                centered
            >
                {this.renderTabContent()}
            </Tabs>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    storeRecords: _.get(state, "store.records", {}),
    isListLoading: _.get(state, "liveOrders.isListLoading", false),
    listStyle: _.get(state, "liveOrders.listStyle", "list"),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShippingMethodTabs));
