import { DatePicker, Popover, Select, Switch } from "antd";
import React from "react";
import { HiOutlineCog } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setDashboardState, ViewControls } from "../../../../slices/dashboard";
import moment from "moment";
import { getDashboard } from "../../../../services/dashboard";
import RefreshButton from "../../../../components/refresh";
import { PERIODS, DATE_FORMAT } from "../../constants";
import { getDateTimeStr } from "../../helper";

const { ENTER_PERIOD, LAST_SEVEN_DAYS, LAST_THIRTY_DAYS, THIS_WEEK, THIS_MONTH, LAST_MONTH, THIS_YEAR, LAST_YEAR } =
    PERIODS;

const selector = (state: RootState) => ({
    parameters: state?.dashboard?.parameters,
    loading: state?.dashboard?.loading,
});

function DashboardActionBar(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { parameters, loading } = useSelector(selector);

    const onApplyClick = () => {
        dispatch(getDashboard(parameters));
    };

    const datePeriodOptions: any = {
        [ENTER_PERIOD]: intl.formatMessage({ id: "enter_period" }),
        [LAST_SEVEN_DAYS]: intl.formatMessage({ id: "last_seven_days" }),
        [LAST_THIRTY_DAYS]: intl.formatMessage({ id: "last_thirty_days" }),
        [THIS_WEEK]: intl.formatMessage({ id: "this_week" }),
        [THIS_MONTH]: intl.formatMessage({ id: "this_month" }),
        [LAST_MONTH]: intl.formatMessage({ id: "last_month" }),
        [THIS_YEAR]: intl.formatMessage({ id: "this_year" }),
        [LAST_YEAR]: intl.formatMessage({ id: "last_year" }),
    };

    const jsxDatePeriodOptions = Object.keys(datePeriodOptions).map((key) => (
        <Select.Option key={key} value={Number(key)}>
            {datePeriodOptions[key]}
        </Select.Option>
    ));

    const onPeriodChange = (val: any) => {
        const value = val.toString();

        const setDates = (dates: any = {}) =>
            dispatch(
                setDashboardState({
                    parameters: {
                        ...parameters,
                        period: Number(value),
                        ...dates,
                    },
                })
            );

        if (value === THIS_WEEK) {
            setDates({
                s_date: getDateTimeStr(moment().subtract(6, "days")),
                e_date: getDateTimeStr(moment().add(1, "days").local()),
            });
        } else if (value === THIS_MONTH) {
            setDates({
                s_date: getDateTimeStr(moment().startOf("month")),
                e_date: getDateTimeStr(moment().add(1, "days").local()),
            });
        } else if (value === LAST_MONTH) {
            setDates({
                s_date: getDateTimeStr(moment().subtract(1, "month").startOf("month")),
                e_date: getDateTimeStr(moment().startOf("month")),
            });
        } else if (value === THIS_YEAR) {
            setDates({
                s_date: getDateTimeStr(moment().startOf("year")),
                e_date: getDateTimeStr(moment().add(1, "days").local()),
            });
        } else if (value === LAST_YEAR) {
            setDates({
                s_date: getDateTimeStr(moment().subtract(1, "year").startOf("year")),
                e_date: getDateTimeStr(moment().startOf("year")),
            });
        } else {
            setDates({});
        }
    };

    const onRangePickerChange = (value: any) => {
        dispatch(
            setDashboardState({
                parameters: {
                    ...parameters,
                    s_date: value?.[0]?.format(DATE_FORMAT),
                    e_date: value?.[1]?.format(DATE_FORMAT),
                },
            })
        );
    };

    const refreshPage = () => {
        onApplyClick();
    };

    const getDashboardActions = () => {
        return (
            <div className="dashboard-action-left dashboard-actions">
                <div className="dashboard-action">
                    <Select style={{ width: 250 }} value={parameters?.period} onChange={onPeriodChange}>
                        {jsxDatePeriodOptions}
                    </Select>
                </div>
                {parameters?.period === 0 ? (
                    <div className="dashboard-action">
                        <DatePicker.RangePicker
                            value={[
                                parameters?.s_date ? moment(parameters?.s_date, DATE_FORMAT) : moment(),
                                parameters?.s_date ? moment(parameters?.e_date, DATE_FORMAT) : moment(),
                            ]}
                            onChange={onRangePickerChange}
                        />
                    </div>
                ) : null}
                <div className="dashboard-action">
                    <RefreshButton refreshPage={refreshPage} loading={loading} />
                </div>
            </div>
        );
    };

    const getDashboardControlSwitch = ({ name = " ", value }: any) => {
        return (
            <div className="dashboard-action-control">
                <div className="dashboard-action-control-label">{intl.formatMessage({ id: name })}</div>
                <div className="dashboard-action-control-input">
                    <Switch
                        checked={parameters?.view_control?.includes(value)}
                        onChange={() => {
                            dispatch(
                                setDashboardState({
                                    parameters: {
                                        ...parameters,
                                        view_control: parameters?.view_control?.includes(value)
                                            ? parameters?.view_control?.filter((control) => control !== value)
                                            : [...(parameters?.view_control ?? []), value],
                                    },
                                })
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    const getDashboardControls = () => {
        const content = (
            <div>
                {getDashboardControlSwitch({ name: "sales_and_orders", value: ViewControls.OrderSales })}
                {getDashboardControlSwitch({ name: "customer_statistics", value: ViewControls.Customers })}
                {getDashboardControlSwitch({ name: "top_items_by_sale", value: ViewControls.ItemSales })}
                {getDashboardControlSwitch({ name: "least_items_by_sale", value: ViewControls.LeastSaleItems })}
                {getDashboardControlSwitch({ name: "referrals", value: ViewControls.Referrals })}
            </div>
        );

        return (
            <div className="dashboard-action-right dashboard-actions">
                <Popover title={intl.formatMessage({ id: "view_controls" })} content={content} placement="bottomRight">
                    <HiOutlineCog />
                </Popover>
            </div>
        );
    };

    return (
        <div className="dashboard-action-bar">
            {getDashboardActions()}
            {getDashboardControls()}
        </div>
    );
}

export default DashboardActionBar;
