import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { fetchReportSuccess, startLoadingReport } from "../slices/report";
import { oauth } from "../util/api";

export const fetchReport = (params: Record<string, any>) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(startLoadingReport());
    try {
        const { startDate, endDate, staffId, scope, showTaxDetails } = params;
        const search: any = {};

        if (startDate) search.start_dt = startDate;
        if (endDate) search.end_dt = endDate;
        if (staffId) search.customer_ids = staffId;
        if (scope) search.scope = scope ? scope : 1;
        if (showTaxDetails) search.show_full_tax = 1;

        search.include_order = 1;

        const response: any = await oauth("SALES_REPORT")({
            method: "POST",
            body: JSON.stringify({
                search_condition: search,
            }),
        });

        switch (response.RC) {
            case 200:
                dispatch(fetchReportSuccess(response.records));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};
