import React, { Component } from "react";
import _ from "lodash";
import { Form } from "antd";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import helper from "../../../../util/helper";
import InputNumber from "../../../../components/number-input";
import config from 'config';

const currencySymbols = config.CURRENCY_SYMBOL;

class SurchargeInput extends Component {
    handleInputChange = (value) => {
        this.props.setState({ surcharge: value });
    };

    getValue = () => {
        return this.props.surcharge;
    };

    getLabel = () => {
        const selfLabel = _.get(this.props, "state.orderDetailInstance.data.surcharge_label", {});
        const surchargeLabel = this.props.intl.formatMessage({ id: "order_surcharge" });
        return helper.getTransString(selfLabel, this.props.lan) || surchargeLabel;
    };

    render() {
        return (
            <div>
                <Form.Item label={this.getLabel()} {...this.props.form}>
                    <InputNumber
                        style={{ width: `100%` }}
                        prefix={currencySymbols[this.props.storeCurrency]}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={2}
                        value={this.getValue()}
                        min={0}
                        max={10000}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                </Form.Item>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SurchargeInput));
