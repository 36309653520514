import { RootState } from "app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentState } from "slices/store";
import { isBool } from "util/helper";
import { renderTop, renderHalfTxtInput, renderFullSwitch } from "./payment-functions";

const GlobalPaymentSettings = (): JSX.Element => {
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const {
        global_payment_enabled,
        global_payment_app_id,
        global_payment_auth_key,
        global_payment_api_key,
        global_payment_api_secret,
        global_payment_is_debug,
    } = paymentOptions;

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "global_payment",
                "global_payment_setting_tip",
                "global_payment_enabled",
                global_payment_enabled,
                switchChange
            )}
            {global_payment_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput(
                        "app_id",
                        "global_payment_app_id",
                        global_payment_app_id,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput(
                        "auth_key",
                        "global_payment_auth_key",
                        global_payment_auth_key,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput(
                        "api_key",
                        "global_payment_api_key",
                        global_payment_api_key,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput(
                        "api_secret",
                        "global_payment_api_secret",
                        global_payment_api_secret,
                        setState,
                        false,
                        true
                    )}
                    {renderFullSwitch(
                        "debug",
                        "global_payment_is_debug",
                        isBool(global_payment_is_debug),
                        switchChange
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default GlobalPaymentSettings;
