import { Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectOption } from "../../../../components/form";
import { DEFAULTS, PAYMENT_TYPES } from "./constants";

interface PaymentTypeProps {
    paymentType: number;
    setState: any;
}

export const PaymentType = (props: PaymentTypeProps): JSX.Element => {
    const intl = useIntl();

    const paymentTypeOptions: any[] = [
        { value: PAYMENT_TYPES.ANY, label: intl.formatMessage({ id: "auto_accept_payment_any" }) },
        { value: PAYMENT_TYPES.ONLINE, label: intl.formatMessage({ id: "auto_accept_payment_online" }) },
        { value: PAYMENT_TYPES.OFFLINE, label: intl.formatMessage({ id: "auto_accept_payment_offline" }) },
    ];

    return (
        <div className="white-single-block">
            <div className="d-flex w100 flex-column justify-content-start align-items-start">
                <div className="option-title" style={{ marginBottom: "10px" }}>
                    <FormattedMessage id="auto_accept_payment_type" />
                </div>
                <Select
                    className="surTax-field"
                    value={props.paymentType ?? DEFAULTS.PAYMENT_TYPE}
                    onChange={(val) => props.setState("payment_type", val)}
                >
                    {paymentTypeOptions.map((option: SelectOption) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};
