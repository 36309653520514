import { Button, Popover } from "antd";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { getReferralCode } from "../../../../services/customers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMessage } from "../../../../components/message";
import helper from "../../../../util/helper";
import rH from "../../../settings/pages/referral-settings/helper";
import "../../index.scss";

interface CustomerRefcodeProps {
    value: [];
}

function CustomerRefcodeRenderer(props: CustomerRefcodeProps): JSX.Element | null {
    const intl = useIntl();
    const currUser = useSelector((state: RootState) => state.user);
    const customers = useSelector((state: RootState) => state?.customers?.customers);
    const gid = useSelector((state: RootState) => state.store?.records?.g_id);
    const dispatch = useDispatch();

    const record = customers?.find((customer: any) => Number(customer?.id) === Number(props?.value));

    const getReferralSwitch = (ref_status: number, customer_id: any) => (
        <div>
            {ref_status === rH.REFERRAL_CODE.ON ? (
                <div
                    className="cust-referral-actions"
                    onClick={() =>
                        dispatch(getReferralCode({ gid, user_id: customer_id, action: rH.REFERRAL_ACTION.DISABLE }))
                    }
                >
                    <FormattedMessage id="disable" />
                </div>
            ) : (
                <div
                    className="cust-referral-actions"
                    onClick={() =>
                        dispatch(getReferralCode({ gid, user_id: customer_id, action: rH.REFERRAL_ACTION.ENABLE }))
                    }
                >
                    <FormattedMessage id="enable" />
                </div>
            )}
        </div>
    );

    const getRefCodeString = (ref_status: number, ref_code: any) => (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ color: ref_status === rH.REFERRAL_CODE.ON ? "#3898c8" : "black" }}
        >
            <div className="ref-code-string">{ref_code}</div>
            {ref_status === rH.REFERRAL_CODE.ON ? null : (
                <div className="mini-disable-text">
                    <FormattedMessage id="disabled" />
                </div>
            )}
        </div>
    );

    const deleteRefCode = (customer: any) => {
        if (!customer?.referral_trxn_count ?? false) {
            dispatch(getReferralCode({ gid, user_id: customer?.id, action: rH.REFERRAL_ACTION.DELETE }));
        }
    };

    const getCopyCode = (ref_code: string) => {
        const copyRefCode = () => {
            navigator.clipboard.writeText(ref_code).then(
                function () {
                    toast(createMessage(intl.formatMessage({ id: "referral_code_copied" }), HiInformationCircle));
                },
                function () {
                    // Handle error
                }
            );
        };
        return (
            <div className="cust-referral-actions" onClick={() => copyRefCode()}>
                <FormattedMessage id="copy_referral_code" />
            </div>
        );
    };

    const refActions = (customer: any) => (
        <div className="d-flex flex-column">
            {!helper.isSuperUser(currUser) ? null : (
                <div
                    className="mr-2 cust-referral-actions"
                    onClick={() => deleteRefCode(customer)}
                    style={{ color: customer?.referral_trxn_count ?? false ? "grey" : "black" }}
                >
                    <FormattedMessage id="delete" />
                </div>
            )}
            {getReferralSwitch(customer?.referral_code_status ?? rH.REFERRAL_CODE.OFF, customer?.id)}
            {getCopyCode(customer?.referral_code)}
        </div>
    );

    const getUserRefs = (customer: any) => (
        <div className="d-flex justify-content-start align-items-center w100">
            <Popover content={refActions(customer)}>
                {getRefCodeString(customer?.referral_code_status ?? rH.REFERRAL_CODE.OFF, customer?.referral_code)}
            </Popover>
        </div>
    );

    const getRefCode = (customer: any) => (
        <React.Fragment>
            {customer?.referral_code ? (
                getUserRefs(customer)
            ) : (
                <Button
                    size="small"
                    style={{ fontSize: "10px" }}
                    type="default"
                    className="text-on-white-background"
                    onClick={() =>
                        dispatch(getReferralCode({ gid, user_id: customer?.id, action: rH.REFERRAL_ACTION.GENERATE }))
                    }
                >
                    <FormattedMessage id="generate_referral_code" />
                </Button>
            )}
        </React.Fragment>
    );

    return record ? <div className="refColumn">{getRefCode(record)}</div> : null;
}

export default CustomerRefcodeRenderer;
