import { createSlice } from "@reduxjs/toolkit";

interface ReferralsState {
    searchConditions: {
        c_name?: string,
        ref_code?: string,
        max_num_ref?: number,
        min_num_ref?: number,
        max_num_ord?: number,
        min_num_ord?: number,
        max_ref_ord_amt?: number,
        min_ref_ord_amt?: number,
        max_points_bal?: number,
        min_points_bal?: number,
        page: number,
        limit: number,
    };
    paging: {
        total?: number,
        page?: number,
        limit?: number,
    };
    referrals: {
        _id?: string,
        customer_id?: string,
        customer_name?: string,
        customer_phone?: string,
        customer_email?: string,
        referral_code?: string,
        customer_referrals_count?: number,
        order_referrals_count?: number,
        order_referrals_amt?: number,
        reward_balance: number,
    }[];
    filterDrawer?: boolean;
    redeemModal?: {
        customer_id?: string,
        customer_name?: string,
        reward_balance?: number,
        refresh_customer?: boolean,
    };
    addModal?: {
        customer_id?: string,
        customer_name?: string,
        reward_balance?: number,
        refresh_customer?: boolean,
    };
    transactions?: {
        _id: string,
        created_at?: string,
        order_id?: string,
        points_balance?: number,
        points_delta?: number,
        type?: number,
    }[];
    transactionsSearchConditions: {
        c_name?: string,
        ref_code?: string,
        max_num_ref?: number,
        min_num_ref?: number,
        max_num_ord?: number,
        min_num_ord?: number,
        max_ref_ord_amt?: number,
        min_ref_ord_amt?: number,
        max_points_bal?: number,
        min_points_bal?: number,
        customer_id?: string,
        order_id?: string,
        page: number,
        limit: number,
    };
    transactionsPaging: {
        total?: number,
        page?: number,
        limit?: number,
    };
    customerReferrals: {
        _id: string,
        customer_name?: string,
        customer_phone?: string,
        referred_order_count?: number,
        referred_order_amt?: number,
        points_earned?: number,
    }[];
    customerReferralsPaging: {
        total?: number,
        page?: number,
        limit?: number,
    };
    customerReferralsSearchConditions: {
        c_name?: string,
        ref_code?: string,
        max_num_ref?: number,
        min_num_ref?: number,
        max_num_ord?: number,
        min_num_ord?: number,
        max_ref_ord_amt?: number,
        min_ref_ord_amt?: number,
        max_points_earned?: number,
        min_points_earned?: number,
        phone?: string,
        page: number,
        limit: number,
    };
    orderHistory?: {
        order_id?: string,
        amt?: number,
        created_at?: string,
        rewart_pts?: number,
    }[];
    orderHistorySearchConditions: {
        ref_code?: string,
        user_id?: number,
        order_id?: string,
        max_amt?: number,
        min_amt?: number,
        s_date?: string,
        e_date?: string,
        max_reward_pts?: number,
        min_reward_pts?: number,
        page: number,
        limit: number,
    };
    orderHistoryPaging: {
        total?: number,
        page?: number,
        limit?: number,
    };
    noRecords?: boolean;
}

const initialState: ReferralsState = {
    searchConditions: { page: 1, limit: 20 },
    paging: {},
    transactionsSearchConditions: { page: 1, limit: 20 },
    customerReferralsSearchConditions: { page: 1, limit: 20 },
    orderHistorySearchConditions: { page: 1, limit: 20 },
    transactionsPaging: {},
    customerReferralsPaging: {},
    orderHistoryPaging: {},
    referrals: [],
    transactions: [],
    customerReferrals: [],
    orderHistory: [],
};

const slice = createSlice({
    name: "referrals",
    initialState,
    reducers: {
        setReferralsState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getReferralsListSuccess: (state, { payload }) => {
            const { referrals, paging, searchConditions } = payload;

            state.referrals = referrals;
            state.paging = paging;
            state.searchConditions = searchConditions;
        },
        setRedeemModalState: (state, { payload }) => {
            state.redeemModal = payload;
        },
        setAddModalState: (state, { payload }) => {
            state.addModal = payload;
        },
        getReferralsTransactionsHistorySuccess: (state, { payload }) => {
            const { transactions, paging } = payload;

            state.transactions = transactions;
            state.transactionsPaging = paging;
        },
        getCustomerReferralsSuccess: (state, { payload }) => {
            const { customerReferrals, paging } = payload;

            state.customerReferrals = customerReferrals;
            state.customerReferralsPaging = paging;
        },
        getOrderHistorySuccess: (state, { payload }) => {
            const { orderHistory, paging } = payload;

            state.orderHistory = orderHistory;
            state.orderHistoryPaging = paging;
        },
    },
});

export const {
    setReferralsState,
    getReferralsListSuccess,
    setRedeemModalState,
    setAddModalState,
    getReferralsTransactionsHistorySuccess,
    getCustomerReferralsSuccess,
    getOrderHistorySuccess,
} = slice.actions;

export const referrals = slice.reducer;
