import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "app/reducer";
import {
    DeleteOrderTrackingParams,
    GetOrderTrackingParams,
    AddOrderTrackingRequestBody,
    UpdateOrderTrackingRequestBody,
} from "components/shipment-tracking/models/shipment-tracking-requests";
import {
    GetCourierMethodsResponse,
    OrderTrackingResponse,
} from "components/shipment-tracking/models/shipment-tracking-responses";
import { setState } from "slices/shipment-tracking";
import { oauth } from "util/api";

export const getCourierMethods = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(setState({ isLoading: true }));
    try {
        const response: GetCourierMethodsResponse = await oauth("COURIER_METHODS")({
            method: "GET",
        });

        switch (response.RC) {
            case 200:
                dispatch(setState({ courierMethods: response.res }));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(setState({ isLoading: false }));
};

export const getOrderTrackingInfo =
    (params: GetOrderTrackingParams) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            const response: OrderTrackingResponse = await oauth(
                "ORDER_TRACKING",
                undefined,
                params.order_id
            )({
                method: "GET",
            });

            switch (response.RC) {
                case 200:
                    dispatch(setState({ orderCarriers: response.res }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };

export const addOrderTrackingInfo =
    (body: AddOrderTrackingRequestBody) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            const response: OrderTrackingResponse = await oauth("ORDER_TRACKING")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                    dispatch(setState({ orderCarriers: response.res }));
                    break;
                case 400:
                    dispatch(setState({ errorMessage: "duplicate_order_tracking_message" }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };

export const updateOrderTrackingInfo =
    (body: UpdateOrderTrackingRequestBody) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            const response: OrderTrackingResponse = await oauth("ORDER_TRACKING")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                    dispatch(setState({ orderCarriers: response.res }));
                    break;
                case 400:
                    dispatch(setState({ errorMessage: "duplicate_order_tracking_message" }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };

export const deleteOrderTrackingInfo =
    (params: DeleteOrderTrackingParams) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            const response: OrderTrackingResponse = await oauth(
                "DELETE_ORDER_TRACKING",
                undefined,
                String(params.track_id)
            )({
                method: "DELETE",
            });

            switch (response.RC) {
                case 200:
                    dispatch(setState({ orderCarriers: response.res }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };
