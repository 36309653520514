import React from "react";
import { IconType } from "react-icons/lib";

interface MessageProps {
    icon?: any;
    message?: any;
    color?: string;
    textColor: string;
}

const Message = (props: MessageProps): JSX.Element => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {props.icon ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: 18,
                        fontSize: "1.3em",
                        color: props.color,
                    }}
                >
                    {<props.icon />}
                </div>
            ) : null}
            <div
                style={{
                    color: props.textColor,
                }}
            >
                {props.message}
            </div>
        </div>
    );
};

export const createMessage = (message: string, icon?: IconType, color?: string, textColor?: string): JSX.Element => (
    <Message icon={icon} message={message} color={color ?? "#3898c8"} textColor={textColor ?? "black"} />
);

export default Message;
