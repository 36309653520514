import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";
import { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { transactionType } from "../../constants";
import ReferralsModalValueRenderer from "./value";
import helper from "../../../../util/helper";
import _ from "lodash";

const selector = (state: RootState) => ({
    records: state?.referrals?.transactions,
    paging: state?.referrals?.transactionsPaging,
    searchConditions: state?.referrals?.transactionsSearchConditions,
    noRecords: state?.referrals?.noRecords,
});

function ModalTable(): JSX.Element | null {
    const { records, noRecords } = useSelector(selector);
    const intl = useIntl();

    let gridApi: any;

    const columnDefs = [
        {
            field: "_id",
            width: 160,
            headerName: intl.formatMessage({ id: "date_time" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => helper.utcToLocal(record?.created_at),
            },
        },
        {
            field: "_id",
            width: 80,
            headerName: intl.formatMessage({ id: "order_id" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) =>
                    _.isNil(record?.order_id) ? "N/A" : `#${record?.order_id?.split("-")?.[0]?.slice(-4)}`,
            },
        },
        {
            field: "_id",
            width: 450,
            headerName: intl.formatMessage({ id: "transaction_type" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => {
                    const value = Number(record?.type);
                    return intl.formatMessage({
                        id: transactionType?.[value] ?? " ",
                    });
                },
            },
        },
        {
            field: "_id",
            width: 120,
            headerName: intl.formatMessage({ id: "points_change" }),
            headerClass: "center-align",
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            cellRendererParams: {
                renderValue: (record: any) =>
                    helper.formatNumber(record?.points_delta > 0 ? "+" : "") +
                    helper.formatNumber(record?.points_delta),
            },
        },
        {
            field: "_id",
            width: 120,
            headerName: intl.formatMessage({ id: "points_balance" }),
            headerClass: "center-align",
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            cellRendererParams: {
                renderValue: (record: any) => helper.formatNumber(record?.points_balance),
            },
        },
    ];

    const getTableObject: any = () => ({
        columnDefs,
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: ReferralsModalValueRenderer,
        },
        rowData: noRecords ? [] : records,
        getRowHeight: () => 50,
        getRowNodeId: (row: any) => row?._id,
        immutableData: true,
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const getTable = () => {
        const tableObject = getTableObject();
        return records?.length
            ? React.createElement(
                  "div",
                  {
                      className: "customers-table-container referral-modal-table ag-theme-alpine",
                  },
                  React.createElement(AgGridReact, { ...tableObject })
              )
            : null;
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    return getTable();
}

export default ModalTable;
