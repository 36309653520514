import routes from "./routes";
import config from "../../config";
import OAuth from "oauth-1.0a";
import { store } from "../../app/store";
import { Modal } from "antd";
import { userLogoutSuccess, User } from "../../slices/user";
import { getIntl } from "../../locale";
import { getPublish } from "../../services/publish";

const publishRoutes = [
    "PRODUCT_DETAILS",
    "CATEGORIES",
    "CATEGORIES_DELETE",
    "STORE_DETAILS",
    "STORE_DETAILS_PARTS",
    "MENU",
];

const getUserOauth = () => ({
    //@ts-ignore
    token: store.getState()?.user?.token,
    //@ts-ignore
    secret: store.getState()?.user?.secret,
});

//@ts-ignore
const getUser = (): User => store.getState()?.user;

const auth = new OAuth({
    consumer: {
        key: "8fb7ec71f8b4e1f2ec28d2f8c3f7785a",
        secret: "af035f0f340e090d5b51870f9a168acd",
    },
});

const isLocalApi = (api: string): boolean => {
    return ["IMAGE_CACHE"].includes(api);
};

let lastRC: any = null;

export const api =
    (route: string) =>
    async (params: any = {}) : Promise<any> => {
        const intl = getIntl();

        try {
            const { bearer } = getUser();
            const response = await fetch((isLocalApi(route) ? config.ROOT_URL : config.API_ROOT) + routes[route], {
                ...params,
                headers: new Headers({
                    ...params.headers,
                    ...(bearer && {
                        Authorization: "Bearer " + bearer,
                    }),
                    ...(!(params.body instanceof FormData) && {
                        "Content-Type": "application/json",
                    }),
                }),
            });

            const json = await response.json();

            if (json?.RC === 403) {
                const isLogin = route === "LOGIN";
                if (json?.RC !== lastRC) {
                    lastRC = json?.RC;
                    Modal.confirm({
                        title: intl.formatMessage({ id: "error" }),
                        content: isLogin
                            ? intl.formatMessage({ id: "incorrect_username_or_password" })
                            : intl.formatMessage({ id: "force_logout_message" }),
                        onOk() {
                            const path = window?.location?.hash;
                            store.dispatch(userLogoutSuccess({ path }));
                            lastRC = null;
                        },
                        onCancel() {
                            store.dispatch(userLogoutSuccess({}));
                            lastRC = null;
                        },
                        cancelButtonProps: {
                            style: {
                                display: "none",
                            },
                        },
                    });
                }
            }

            if (publishRoutes.includes(route)) {
                store.dispatch(getPublish());
            }

            return json;
        } catch (e) {
            // Handle error
        }
    };

export const oauth =
    (route: string, query = "", append = "") =>
    async (params: any = {}) : Promise<any> => {
        const intl = getIntl();
        try {
            const url =
                (isLocalApi(route) ? config.ROOT_URL : config.API_ROOT) +
                routes[route] +
                (append ? "/" + append : "") +
                (query ? "?" + query : "");
            const { token, secret } = getUserOauth();

            const header = {
                key: token,
                secret: secret,
            };

            const request = {
                url,
                method: params.method,
            };

            const response = await fetch(url, {
                ...params,
                headers: new Headers({
                    ...params.headers,
                    ...auth.toHeader(auth.authorize(request, header)),
                    ...(!(params.body instanceof FormData) && {
                        "Content-Type": "application/json",
                    }),
                }),
            });

            const json = await response.json();

            if (json?.RC === 401) {
                if (json?.RC !== lastRC) {
                    lastRC = json?.RC;
                    Modal.confirm({
                        title: intl.formatMessage({ id: "error" }),
                        content: intl.formatMessage({ id: "force_logout_message" }),
                        onOk() {
                            const path = window?.location?.hash;
                            store.dispatch(userLogoutSuccess({ path }));
                            lastRC = null;
                        },
                        onCancel() {
                            store.dispatch(userLogoutSuccess({}));
                            lastRC = null;
                        },
                        cancelButtonProps: {
                            style: {
                                display: "none",
                            },
                        },
                    });
                }
            }

            if (publishRoutes.includes(route) && params?.method === "POST") {
                store.dispatch(getPublish());
            }

            return json;
        } catch (e) {
            // Handle error
        }
    };

export const oauthBlob =
    (route: string, query = "", append = "") =>
    async (params: any = {}) : Promise<any> => {
        const intl = getIntl();
        try {
            const url =
                (isLocalApi(route) ? config.ROOT_URL : config.API_ROOT) +
                routes[route] +
                (query ? "?" + query : "") +
                (append ? "/" + append : "");

            const { token, secret } = getUserOauth();

            const header = {
                key: token,
                secret: secret,
            };

            const request = {
                url,
                method: params.method,
            };

            const response = await fetch(url, {
                ...params,
                headers: new Headers({
                    ...params.headers,
                    ...auth.toHeader(auth.authorize(request, header)),
                    ...(!(params.body instanceof FormData) && {
                        "Content-Type": "application/json",
                    }),
                }),
            });

            const status = await response.status;

            if (status === 401 && status !== lastRC) {
                lastRC = status;
                Modal.confirm({
                    title: intl.formatMessage({ id: "error" }),
                    content: intl.formatMessage({ id: "force_logout_message" }),
                    onOk() {
                        store.dispatch(userLogoutSuccess({}));
                        lastRC = null;
                    },
                    onCancel() {
                        store.dispatch(userLogoutSuccess({}));
                        lastRC = null;
                    },
                    cancelButtonProps: {
                        style: {
                            display: "none",
                        },
                    },
                });
            }

            const blob = await response.blob();
            return blob;
        } catch (e) {
            // Handle error
        }
    };
