import React from "react";
import { Select, Switch } from "antd";
import { FormattedMessage } from "react-intl";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { isBool } from "./../../../../util/helper";
import h from "../../helper";

export interface SelectOption {
    value: string | number;
    label: string;
}

function emptyFunction() {
    // does nothing
}

export const renderTop = (title: string, desc: string): React.ReactChild => (
    <React.Fragment>
        <div className="payment-title-container">
            <FormattedMessage id={title ?? " "} />
        </div>
        <div className="payment-desc">
            <FormattedMessage id={desc ?? " "} />
        </div>
    </React.Fragment>
);

export const activeStore = (
    name: string,
    value: string | number,
    storeName: string,
    onChange: any = emptyFunction
): React.ReactElement => (
    <div className="w100 d-flex justify-content-start align-items-start">
        <div className="person-info-field mb-0 d-flex">
            <div className="payment-desc mr-3 mb-0">
                <b>
                    <FormattedMessage id="active" />
                </b>
            </div>
            <Switch checked={isBool(value)} onChange={() => onChange(name, value)} />
        </div>
        <div className="person-info-field payment-desc mr-3 mb-0">
            <b>
                <FormattedMessage id="current_store" />:
            </b>{" "}
            {storeName}
        </div>
    </div>
);

export const renderHalfTxtInput = (
    title: string,
    name: string,
    value: string | number,
    onChange: any = emptyFunction,
    req: boolean,
    type?: string,
    disabled: any = 1,
    length: any = 0,
    fieldWidth: any = ""
): JSX.Element => (
    <div
        className="person-info-field d-flex flex-column justify-content-start align-items-start"
        style={{ width: fieldWidth }}
    >
        <div className="payment-subtitle">
            <FormattedMessage id={title ?? " "} />
            {req ? <span className="reqed">*</span> : ""}
        </div>
        <input
            value={value}
            type={type ?? "text"}
            minLength={length ?? 0}
            className="custom-input-textfield halfish-size-input"
            disabled={disabled ? true : false}
            onChange={(e) => onChange(name, e.target.value)}
        />
    </div>
);

export const renderHalfSelectInput = (
    title: string,
    name: string,
    value: string | number,
    onChange: any = emptyFunction,
    req: boolean,
    options: any = [],
    disabled: any = 1,
    fieldWidth: any = ""
): JSX.Element => (
    <div
        className="person-info-field d-flex flex-column justify-content-start align-items-start"
        style={{ width: fieldWidth }}
    >
        <div className="payment-subtitle">
            <FormattedMessage id={title ?? " "} />
            {req ? <span className="reqed">*</span> : ""}
        </div>
        <select
            className="custom-input-textfield halfish-size-input"
            value={value}
            disabled={disabled ? true : false}
            onChange={(e) => onChange(name, e.target.value)}
        >
            {options.map((opt: any) => (
                <option key={opt.value} value={opt.value}>
                    {opt.label}
                </option>
            ))}
        </select>
    </div>
);

export const renderHalfTxtSelecPhone = (
    title: string,
    name: string,
    value: any = [],
    options: any = [],
    onChange: any = emptyFunction
): JSX.Element => (
    <div className="person-info-field d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle">
            <FormattedMessage id={title ?? "title"} />
        </div>
        <Select
            className="halfish-size-input"
            value={value?.label}
            defaultValue={options[0]?.value?.label}
            onChange={(e) => onChange(name, h.AREA_CODES[e] ?? "1")}
        >
            {options.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderPasswordInput = (
    title: string,
    name: string,
    value: string | number,
    onChange: any = emptyFunction,
    passView: boolean,
    setPassView: any = emptyFunction,
    fieldWidth?: string
): JSX.Element => (
    <div
        className="person-info-field d-flex flex-column justify-content-start align-items-start"
        style={{ width: fieldWidth }}
    >
        <div className="payment-subtitle">
            <FormattedMessage id={title ?? " "} />
            <span className="reqed">*</span>
        </div>
        <div className="d-flex w100 container-input-passview">
            <input
                autoComplete="new-password"
                type={passView ? "password" : "text"}
                className="custom-input-textfield halfish-size-input"
                value={value}
                onChange={(e) => onChange(name, e.target.value)}
            />
            {passView ? (
                <AiFillEyeInvisible
                    className="pHover mt-2 ml-1 showPassword"
                    onClick={() => setPassView(false)}
                    size={25}
                />
            ) : (
                <AiFillEye className="pHover mt-2 ml-1 showPassword" onClick={() => setPassView(true)} size={25} />
            )}
        </div>
    </div>
);
