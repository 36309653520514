import { Button, Modal, Input, Popconfirm } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateCustomerPassword } from "../../../../services/customers";
import { PASSWORD_ACTIONS } from "../../helper";
import { IoIosSend } from "react-icons/io";
import { getCustomerId } from "./helper";

class ResetPassword extends Component {
    componentDidMount() {}

    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCid = (props = this.props) => getCustomerId(props);

    renderTitle = () => {
        return (
            <div className="customer-section-title">
                <FormattedMessage id="reset_password" />
            </div>
        );
    };

    renderResetMessage = () => {
        return (
            <div className="customer-reset-password-message">
                <FormattedMessage id="reset_password_message" />
            </div>
        );
    };

    renderButtons = () => {
        return (
            <div className="customer-reset-password-buttons">
                <Popconfirm
                    title={this.str("are_you_sure")}
                    onConfirm={() => {
                        this.props.updateCustomerPassword({
                            cid: this.getCid(),
                            action: PASSWORD_ACTIONS.reset,
                        });
                    }}
                    okText={this.str("confirm")}
                    cancelText={this.str("cancel")}
                >
                    <Button type="link" icon={<IoIosSend />}>
                        <FormattedMessage id="sent_reset_link" />
                    </Button>
                </Popconfirm>
                <Button onClick={() => this.setState({ editModal: true })} type="link">
                    <FormattedMessage id="update_password" />
                </Button>
            </div>
        );
    };

    renderResetModal = () => {
        const renderTitle = () => (
            <div className="edit-cusotmer-modal-title">
                <FormattedMessage id="update_password" />
            </div>
        );

        const renderInput = () => (
            <Input.Password
                onChange={(e) => this.setState({ password: e.target?.value })}
                value={this.state.password}
                placeholder={this.str("enter_password")}
            />
        );

        const renderSave = () => (
            <div className="edit-cusotmer-modal-save-button">
                <Button
                    onClick={() => {
                        if (this.state.password) {
                            this.props.updateCustomerPassword({
                                cid: this.getCid(),
                                password: this.state.password,
                                action: PASSWORD_ACTIONS.update,
                            });
                        }
                        this.setState({ editModal: false });
                    }}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="save" />
                </Button>
            </div>
        );

        return (
            <Modal
                onCancel={() => this.setState({ editModal: false })}
                destroyOnClose
                footer={null}
                title={null}
                visible={this.state.editModal}
            >
                {renderTitle()}
                {renderInput()}
                {renderSave()}
            </Modal>
        );
    };

    render() {
        return (
            <div className="customer-detail-section reset-password section-padding">
                {this.renderTitle()}
                {this.renderResetMessage()}
                {this.renderButtons()}
                {this.renderResetModal()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

const mapDispatchToProps = {
    updateCustomerPassword,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));
