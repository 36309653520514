import React from "react";
import { Collapse } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { ImageUploadSection } from "./format-common/image-upload-section";
import { CodeStyle } from "./format-common/code-style";

interface ArbitraryStyleProps {
    storeImages: {
        store_header_image: string,
        store_logo: string,
        store_banner: string,
        store_mobile_image: string,
        store_qr_logo: string,
        store_images: string[],
    };
    user: {
        access?: string;
        bearer?: string | undefined;
        current_store?: {
            id: number;
            status: number;
        }
        deviceToken?: any | null;
        firstName?: string;
        id?: number | undefined;
        is_use_pos_mode?: number | undefined;
        lastName?: string | undefined;
        permission?: number | undefined;
        roles?: number[] | undefined;
        secret?: string | undefined;
        stores?: {id: number, status:number, name: string, names: any}[] | undefined;
        token?: string | undefined;
        username?: string | undefined;
    };
    uploadCallback: any;
    deleteCallback: any;
    qrStyle: string;
    setQRStyle: any;
}

export const ArbitraryStyle = (props: ArbitraryStyleProps): JSX.Element => {
    const intl = useIntl();
    const { storeImages, user, uploadCallback, deleteCallback, qrStyle, setQRStyle } = props;

    const renderUrl = () => {
        return (
            <div>
                <FormattedMessage id="url_label" />
                <input className="custom-input-textfield w200px ta-left" />
            </div>
        );
    };

    return (
        <div className="white-layered-block d-flex flex-row justify-content-start align-items-start">
            <div className="serve-bottom-left-section">
                <div className="store_name-title">
                    <Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
                        <Collapse.Panel header={intl.formatMessage({ id: "style_settings" })} key="2">
                            {renderUrl()}
                            <ImageUploadSection
                                storeImages={storeImages}
                                user={user}
                                uploadCallback={uploadCallback}
                                deleteCallback={deleteCallback}
                            />
                            <CodeStyle qrStyle={qrStyle} setQRStyle={setQRStyle} />
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );
};
