import { Button, Select, Popover } from "antd";
import useLanguageSelect from "hooks/useLanguageSelect";
import { getCategoriesAsOptions } from "pages/products/helper";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "services/products";
import { RootState } from "../../../../app/reducer";
import helper from "../../../../util/helper";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    allowed: state?.pages?.allowed,
    categories: state?.categories?.categories,
});

interface CategoriesRendererProps {
    value: string;
}

function CategoriesRenderer(props: CategoriesRendererProps): JSX.Element {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { products, categories, lan, allowed } = useSelector(selector);
    const [currentEditPopover, setCurrentEditPopover] = useState<any>(undefined);
    const [currentHoverPopover, setCurrentHoverPopover] = useState<any>(undefined);
    const [value, setValue] = useState<any>(undefined);
    const { lan: popoverLan, getModalLanguageSelect } = useLanguageSelect();

    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));
    const categoryNames = categories
        .filter((cat) => record?.category_ids?.includes(cat?.category_id))
        .map((cat) => helper.getTransString(cat.name, lan))
        .join(", ");

    const getPopover = (record: any, name = "", content: any, child: any, translated = false) => {
        const id = record?.product_id;
        const onSubmit = () => {
            dispatch(editProduct({ product_id: record.product_id, [name]: value }));
            setCurrentEditPopover("");
        };

        const editKey = `edit-${id}-${name}`;
        const hoverKey = `hover-${id}-${name}`;

        const onVisibleChange = () => {
            if (currentEditPopover === editKey) {
                setCurrentEditPopover("");
                setValue(undefined);
            } else {
                setCurrentEditPopover(editKey);
            }
        };

        const contentProp = (
            <div className="edit-popover name-edit-popover">
                {translated ? <div className="modal-lan-select-popover">{getModalLanguageSelect()}</div> : null}
                <div>{content}</div>
                <div className="edit-popover-buttons">
                    <Button onClick={onVisibleChange}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button type="primary" onClick={onSubmit} className="save-button-handler">
                        <FormattedMessage id="submit" />
                    </Button>
                </div>
            </div>
        );

        return (
            <Popover
                placement="bottomLeft"
                content={contentProp}
                title={intl.formatMessage({ id: "edit" })}
                trigger=""
                onVisibleChange={onVisibleChange}
                visible={currentEditPopover === editKey && allowed}
            >
                <div
                    className="popover-area"
                    onMouseEnter={() => setCurrentHoverPopover(hoverKey)}
                    onMouseLeave={() => setCurrentHoverPopover("")}
                >
                    <div className="popover-child overflow-dots">
                        <span>{child || <FormattedMessage id="not_set" />}</span>
                    </div>
                    <div
                        className="popover-icon"
                        style={{ opacity: currentHoverPopover === hoverKey && allowed ? 1 : 0 }}
                    >
                        <HiOutlinePencilAlt />
                    </div>
                </div>
            </Popover>
        );
    };

    const getSelectInput = (record: any, name: any) => {
        const v = record?.[name] || [];
        const editValue = value;
        return (
            <Select
                value={editValue ?? v}
                onChange={(values: any) => {
                    setValue(values);
                }}
                mode="multiple"
                options={getCategoriesAsOptions(categories, popoverLan)}
                allowClear
                className="quick-category-select"
            />
        );
    };

    const setContentOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }
        const editKey = `edit-${record?.product_id}-category_ids`;
        ref.onclick = (e) => {
            setCurrentEditPopover(editKey);
            e.stopPropagation();
        };
    };

    useEffect(() => {
        setValue(record?.category_ids);
    }, [record]);

    return (
        <div className="product-title-image" ref={setContentOnClickHandler}>
            <div className="popover-container">
                {getPopover(
                    record,
                    "category_ids",
                    getSelectInput(record, "category_ids"),
                    record ? categoryNames : null,
                    true
                )}
            </div>
        </div>
    );
}

export default CategoriesRenderer;
