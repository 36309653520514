import React from "react";
import helper from "../../../../../util/helper";
import PaymentIcons from "../../../../../images/icons/payment-icon.png";
import { View, Image, Text } from "@react-pdf/renderer";

const NumberPayment = (styles: React.CSSProperties | any, props: { phone: number | string }): JSX.Element => (
    <React.Fragment>
        <View style={styles.phone}>
            <Text>{helper.formatPhone(props.phone)}</Text>
        </View>
        <View style={styles.paymentContainer}>
            <Image src={PaymentIcons} />
        </View>
    </React.Fragment>
);
export default NumberPayment;
