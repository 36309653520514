import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import "./checkout-button.scss";
import { checkoutOrder } from "services/create-order";
import { setState, clearData } from "slices/create-order";
import { FaLock } from "react-icons/fa";
import { formatCurrency } from "util/helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "classnames";
import { getShippingMethodStrID } from "../../helper";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Modal } from "antd";
import { deleteQuotes } from "services/quotes";
import config from "config";
import helper from "util/helper";
import history from "app/history";
import { ITEM_CHANGE_ACTION_SEARCH_BAR } from "pages/liveorder/_components/order-items/helper";

class CheckoutButton extends React.Component<any, any> {
    state = {
        shouldDeleteQuote: false,
    };

    componentDidUpdate = () => {
        if (this.state.shouldDeleteQuote) {
            this.props.deleteQuotes({
                quote_ids: [this.props.state?.qid],
                shouldShowQuoteDeletedMsg: true,
                lan: this.props.lan,
            });
            helper.goBackPreviousPage(history);
        }
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    getTableNumber = () => {
        return _.get(this.props, "state.tableNumber");
    };

    shouldCheckout = () => {
        const errorMessage = this.getCheckoutButtonMessage();
        const readyToCheckout = errorMessage === "place_order_with_x";
        return readyToCheckout;
    };

    shouldCheckoutWithNormal = () => {
        const isNormal = this.getButtonType() === "normal";
        return this.shouldCheckout() && isNormal;
    };

    isCheckoutLoading = () => {
        return this.props.state?.checkoutButtonLoading;
    };

    getButtonType = () => {
        return "normal";
    };

    getShippingMethodStrID = () => {
        return this.props.intl.formatMessage({ id: getShippingMethodStrID(this.props) });
    };

    getAmountDue = () => {
        return this.props.state?.displayPriceInfo?.totalDue;
    };

    showAmountDue = () => {
        return this.getCheckoutButtonMessage() === "place_order_with_x";
    };

    getCheckoutButtonMessage = () => {
        const { paymentMethod, shippingMethod, card, customer } = this.props;
        const shippingAddress = !_.isEmpty(this.props.address)
            ? this.props.address
            : this.props.customer?.default_address || {};

        if (_.isEmpty(this.props.items)) {
            return "please_add_some_items";
        }

        // A customer is not mandatory if the order type is in store or instant checkout
        // if no customer is provided, it will default to the default guest user
        if (
            !helper.isEatIn(shippingMethod) &&
            !helper.isInstantCheckout(shippingMethod) &&
            (_.isEmpty(customer) || customer?.id === 0)
        ) {
            return "click_to_choose_a_customer";
        }

        if (helper.isDelivery(shippingMethod) && _.isEmpty(shippingAddress)) {
            return "click_to_input_shipping_addresss";
        }

        if (!this.props.state?.qid) {
            return "please_add_some_items";
        }

        if (!_.isNumber(shippingMethod)) {
            return "please_input_shipping_method";
        }

        if (!_.isNumber(paymentMethod) && this.getAmountDue() > 0) {
            return "click_to_select_payment_method";
        }

        if (paymentMethod === config.PAYMENT_MAPPING_TO_NUMBERIC.credit_card && _.isEmpty(card)) {
            return "please_input_valid_credit_card";
        }

        return "place_order_with_x";
    };

    getCheckoutButtonMessageKeywords = () => {
        const message = this.getCheckoutButtonMessage();
        if (message === "place_order_with_x") {
            return { x: this.getShippingMethodStrID() };
        }
        return {};
    };

    avoidDoubleEvent = (e: any) => {
        if (!e) e = window.event;

        //IE9 & Other Browsers
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        //IE8 and Lower
        else {
            e.cancelBubble = true;
        }
    };

    onCheckoutClick = (e: any) => {
        if (this.isCheckoutLoading()) return {};
        this.avoidDoubleEvent(e);
        const { setState } = this.props;
        const errorMessage = this.getCheckoutButtonMessage();
        if (errorMessage === "please_add_some_items") {
            setState({
                showItemEditDetailDialog: true,
                itemChangeObject: { action: ITEM_CHANGE_ACTION_SEARCH_BAR },
                item: {
                    pid: `add_${Math.random().toString(36).substr(2, 9)}`,
                },
            });
        } else if (errorMessage === "click_to_choose_a_customer") {
            document.getElementById("create-order-customer-search-select")?.focus();
        } else if (errorMessage === "please_input_shipping_method") {
            document.getElementById("create-order-shipping-method-select")?.focus();
        } else if (errorMessage === "click_to_input_shipping_addresss") {
            setState({ "openEditAddress": true });
        } else if (errorMessage === "click_to_select_payment_method") {
            document.getElementById("create-order-payment-method-select")?.focus();
        } else if (errorMessage === "please_input_valid_credit_card") {
            setState({ "displayModalName": "paymentMethod" });
        }

        if (errorMessage !== "place_order_with_x") {
            setState({ "showError": !_.get(this.props, "state.showError", false) });
        } else if (this.shouldCheckoutWithNormal()) {
            this.checkoutOrder();
        }
    };

    /**
     * handle when the Save&Close or Close button is clicked
     *
     * save and close button can have one of two labels which are "Save&Close" and "Close"
     * if there is something to save, it shows "Save&Close"
     * Case1: if Save&Close button is clicked, call cart total and redirect to quotes page
     * Case2: if Close button is clicked, don't call cart total and redirect to quotes page
     */
    onSaveAndCloseButtonClicked = () => {
        if (this.props.state?.isSaveButtonEnabled) {
            this.props.setState({
                shouldRedirectPage: true,
                saveButtonClicked: true,
                isSaveButtonEnabled: false,
            });
        } else {
            helper.goBackPreviousPage(history);
        }
    };

    checkoutOrder = () => {
        this.props.setState({ shouldRedirectPage: true });
        this.props.checkoutOrder({
            state: this.props?.state,
            customer: this.props?.customer,
            callBack: () => {
                this.props.history.push("/liveOrders");
            },
        });
    };

    renderCheckoutButton = () => {
        const renderButton = () => (
            <div
                className={cx({
                    "checkout-button-button": true,
                    "checkout-button-button-disabled": !this.showAmountDue(),
                    "checkout-button-button-loading": this.isCheckoutLoading(),
                })}
                onClick={(e) => this.onCheckoutClick(e)}
            >
                <div className="checkout-button-start-text">{renderLoading()}</div>
                <div className="checkout-button-center-text">
                    <b>
                        <FormattedMessage
                            id={this.getCheckoutButtonMessage()}
                            values={this.getCheckoutButtonMessageKeywords()}
                        />
                    </b>
                </div>
                <div className="checkout-button-end-text">
                    {this.showAmountDue() ? formatCurrency(this.getAmountDue(), this.props.storeCurrency) : ""}
                </div>
            </div>
        );

        const renderLoading = () => {
            return this.isCheckoutLoading() ? (
                <div className="checkout-button-loading-bar">
                    <CircularProgress size={24} color="secondary" />
                </div>
            ) : null;
        };

        return <div>{renderButton()}</div>;
    };

    renderSecuredInfo = () => {
        return (
            <div className="secure-inner-wrapper">
                <FaLock style={{ marginRight: "5px" }} />
                <FormattedMessage id="secure_connection" />
            </div>
        );
    };

    renderDeleteAndCloseButton = () => {
        return (
            <Button
                className="checkout-delete-button"
                type="default"
                disabled={!this.props.state?.qid}
                onClick={() => {
                    const deleteQuote = () => {
                        this.setState({ shouldDeleteQuote: true });
                    };
                    Modal.confirm({
                        title: this.str("warning"),
                        content: this.str("delete_draft_confirmation"),
                        onOk() {
                            deleteQuote();
                        },
                    });
                }}
            >
                <FormattedMessage id="delete_n_close" />
            </Button>
        );
    };

    renderSaveAndCloseButton = () => {
        return (
            <Button className="checkout-save-button" type="default" onClick={() => this.onSaveAndCloseButtonClicked()}>
                {this.props.state?.isSaveButtonEnabled ? (
                    <FormattedMessage id="save_n_close" />
                ) : (
                    <FormattedMessage id="close" />
                )}
            </Button>
        );
    };

    render() {
        return (
            <div id="checkout-button-container" className="checkout-button-container">
                {
                    <Row gutter={8}>
                        <Col span={4}>{this.renderDeleteAndCloseButton()}</Col>
                        <Col span={4}>{this.renderSaveAndCloseButton()}</Col>
                        <Col span={16}>{this.renderCheckoutButton()}</Col>
                    </Row>
                }
                {this.renderSecuredInfo()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", true),
    lan: _.get(state, "setting.lan", true),
    items: _.get(state, "createOrder.items", []),
    customer: _.get(state, "customers.customer", {}),
    payment: _.get(state, "createOrder.payment", {}),
    paymentMethod: _.get(state, "createOrder.paymentMethod", ""),
    address: _.get(state, "createOrder.address", {}),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    //from service
    deleteQuotes,
    checkoutOrder,
    clearData,
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckoutButton)));
