import { Button } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getReferralCode } from "../../../../services/customers";
import h from "../../../settings/pages/referral-settings/helper";
import CopyArea from "components/copy-area";
import cx from "classnames";
import { getCustomerId } from "./helper";

class ReferralCode extends Component {
    componentDidMount() {}

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCid = (props = this.props) => getCustomerId(props);

    isEnable = () => {
        return String(this.props.customer?.referral_code_status) === h.REFERRAL_STATUS.ENABLE;
    };

    getRefCode = () => this.props.customer?.referral_code;

    updateReferral = (action) => {
        this.props.getReferralCode({
            gid: this.props.gid,
            user_id: this.getCid(),
            action,
            refreshCustomer: true,
        });
    };

    renderTitle = () => {
        return (
            <div className="customer-section-title">
                <FormattedMessage id="referral_code" />
                {this.getRefCode() ? (
                    <Button
                        onClick={() => {
                            this.updateReferral(this.isEnable() ? h.REFERRAL_ACTION.DISABLE : h.REFERRAL_ACTION.ENABLE);
                        }}
                        size="large"
                        type="link"
                        danger={this.isEnable()}
                    >
                        <FormattedMessage id={this.isEnable() ? "disable" : "enable"} />
                    </Button>
                ) : null}
            </div>
        );
    };

    renderReferralContent = () => {
        const renderReferralCode = () => (
            <CopyArea
                className={cx({
                    "referral-code": true,
                    "referral-code-disable": !this.isEnable(),
                })}
                copyData={this.getRefCode()}
                messageId="referral_code"
                iconPosition="end"
            >
                <span className="referral-code-text">{this.getRefCode()}</span>
            </CopyArea>
        );

        const renderGenerateButton = () => (
            <Button
                type="default"
                className="text-on-white-background"
                onClick={() => {
                    this.updateReferral(h.REFERRAL_ACTION.GENERATE);
                }}
            >
                <FormattedMessage id="generate_referral_code" />
            </Button>
        );

        return <div> {this.getRefCode() ? renderReferralCode() : renderGenerateButton()}</div>;
    };

    render() {
        return (
            <div className="customer-detail-section referral-code-section section-padding">
                {this.renderTitle()}
                {this.renderReferralContent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    gid: state.store?.records?.g_id,
    customer: state?.customers?.customer,
});

const mapDispatchToProps = {
    getReferralCode,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReferralCode)));
