import { Tabs } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import Wrapper from '../../../../components/wrapper';
import qs from 'querystring';
import StoreInformationTab from './_components/store-information-tab';
import useLanguageSelect from '../../../../hooks/useLanguageSelect';
import StoreSEOTab from './_components/store-seo-tab';
import StorePricePlanTab from './_components/store-price-plan-tab';
import StoreImagesTab from './_components/store-images-tab';
import StoreBasicTab from './_components/store-basic-tab';
import StoreRestaurantExtrasTab from './_components/store-restaurant-extras-tab';
import StoreTypeAndCategory from './_components/store-type-and-category-tab';

const breadcrumb = {
    routes: [
        {
            path: '',
            breadcrumbName: 'nav_dashboard',
        },
        {
            path: '/settings',
            breadcrumbName: 'settings_overview',
        },
        {
            path: '/settings/general',
            breadcrumbName: 'general',
        }
    ],
};

export enum GeneralTabs {
    StoreInformation = "store_info",
    StoreTypeAndCategory = "store_type_and_categories",
    StoreImages = "store_images",
    SEO = "seo",
    PricePlanAndCurrency = "price_plan_and_currency",
    Basic = "basic_setting",
    Extras = "business_features",
}

const tabs = [
    {
        key: GeneralTabs.StoreInformation,
        tab: StoreInformationTab
    },
    {
        key: GeneralTabs.StoreTypeAndCategory,
        tab: StoreTypeAndCategory
    },
    {
        key: GeneralTabs.StoreImages,
        tab: StoreImagesTab
    },
    {
        key: GeneralTabs.SEO,
        tab: StoreSEOTab
    },
    {
        key: GeneralTabs.PricePlanAndCurrency,
        tab: StorePricePlanTab
    },
    {
        key: GeneralTabs.Basic,
        tab: StoreBasicTab
    },
    {
        key: GeneralTabs.Extras,
        tab: StoreRestaurantExtrasTab
    },
]

function App(): JSX.Element {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const { lan, getLanguageSelect } = useLanguageSelect();
    const tab = String(qs.parse(location.search)?.["?tab"] ?? GeneralTabs.StoreInformation);

    const onChangeTab = (tab: string) => {
        history.push('/settings/general?tab=' + tab);
    }
    return <Wrapper
        helmet={{ title: 'general' }}
        breadcrumb={breadcrumb}
        paddingBottom={true}
    >
        <div className="settings-general-page">
            <div className="setting-actions">{getLanguageSelect()}</div>
            <Tabs type="card" activeKey={tab} defaultActiveKey={tab} onChange={onChangeTab} size="large" style={{ marginTop: 14 }}>
                {tabs.map(tab => (
                    <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                        <tab.tab lan={lan} />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    </Wrapper>;
}

export default App;
