import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import "./gift-card-section.scss";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { setState } from "../../../../slices/create-order";
import { MdCardGiftcard, MdDelete } from "react-icons/md";
import { Modal } from "antd";
import helper from "../../../../util/helper";

class GiftCardsListItem extends React.Component<any, any> {
    state: any = {};

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    getSelectedGiftCard = () => {
        return this.props.selectedGiftCard ? this.props.selectedGiftCard : {};
    };

    getGiftCard = () => {
        return this.props.card ? this.props.card : {};
    };

    isChecked = () => {
        return !_.isEqual(this.getSelectedGiftCard(), {}) && this.getSelectedGiftCard().code === this.getGiftCardGC();
    };

    getGiftCardGC = () => {
        return this.getGiftCard().code;
    };

    onItemClick = () => {
        this.selectGiftCard(true);
    };

    selectGiftCard = (select: any) => {
        const selectedGiftCard = select ? this.getGiftCard() : {};
        this.props.setState({
            selectedGiftCard,
            displayModalName: "",
        });
    };

    getItemCheckBox = () => {
        return (
            <div className="list-item-gift-card-check-box">
                <Checkbox
                    edge="start"
                    checked={this.isChecked()}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": this.getGiftCardGC() }}
                />
            </div>
        );
    };

    getValueText = () => {
        const currency = this.getGiftCard().curr;
        const balance = this.getGiftCard().balance;
        const bookValue = this.getGiftCard().book_value;
        const $ = (v: any) => helper.formatCurrency(v, currency);
        return `${$(balance)}/${$(bookValue)}`;
    };

    getItemPrimaryText = () => {
        return (
            <div className="gift-card-list-item-primary-text">
                <span className="gift-card-list-item-text-title">
                    <FormattedMessage id="balance" />:
                </span>
                <span className="gift-card-list-item-text-value">{this.getValueText()}</span>
            </div>
        );
    };

    getExpiryDate = () => {
        return this.getGiftCard().date_expires;
    };

    getItemSecondaryText = () => {
        return (
            <div className="gift-card-list-item-secondary-text">
                <span className="gift-card-list-item-text-title">
                    <FormattedMessage id="expiry_date" />:
                </span>
                <span className="gift-card-list-item-text-value">{this.getExpiryDate()}</span>
            </div>
        );
    };

    getGiftCardIcon = () => {
        return (
            <div className="list-item-gift-card-icon">
                {this.isChecked() ? <MdDelete onClick={() => this.openConfirmDialog()} /> : <MdCardGiftcard />}
            </div>
        );
    };

    openConfirmDialog = () => {
        Modal.confirm({
            onOk: () => this.selectGiftCard(false),
            content: this.str("are_you_sure_remove_gift_card"),
            okText: this.str("ok"),
            cancelText: this.str("cancel"),
        });
    };

    render() {
        return (
            <ListItem key={this.getGiftCardGC()} role={undefined} dense button onClick={() => this.onItemClick()}>
                {this.getItemCheckBox()}
                <ListItemText
                    id={this.getGiftCardGC()}
                    primary={this.getItemPrimaryText()}
                    secondary={this.getItemSecondaryText()}
                />
                <ListItemSecondaryAction>{this.getGiftCardIcon()}</ListItemSecondaryAction>
            </ListItem>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state.setting.lan,
    selectedGiftCard: _.get(state, "createOrder.selectedGiftCard", {}),
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GiftCardsListItem));
