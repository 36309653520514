import React, { useEffect, useContext } from "react";
import _ from "lodash";
import dH from "../helper";
import { RootState } from "../../../../../../app/reducer";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Popover, Modal } from "antd";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiCouponFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDiscounts } from "../../../../../../services/discounts";
import { setAutoDiscounts, setUserModal } from "../../../../../../slices/discounts";
import { updateStoreDiscounts } from "../../../../../../services/discounts";
import ObjectModel from "../../../../../../util/models";
import DiscountsModel from "../../../../../../util/models/store-auto-discounts";
import UserModal from "./user-modal";
import { IsMobileContext } from "../../../../../../util/helper/isMobileContext";
import "./styles.scss";
import config from "config";

type TypeKey = "CAD" | "GBP" | "CNY" | "USD" | "EUR";
type Type = {[key in TypeKey]: string}
const currencySymbols: Type = config.CURRENCY_SYMBOL;

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    categories: state?.categories?.categories,
    language: state?.setting?.lan,
});

function UserSubTab(): JSX.Element | null {
    const { isMobile } = useContext(IsMobileContext);
    const intl = useIntl();
    const dispatch = useDispatch();
    const openModal = useSelector((state: RootState) => state.discounts?.userModal);
    const discounts = useSelector((state: RootState) => state.discounts?.storeAutoDiscounts);
    const storeCurrency: TypeKey = useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const { categories, language, products } = useSelector(categoriesPageSelector);
    const rewards = _.get(discounts, "disc_new_user.data.rewards", NaN);
    const discount = _.get(discounts, "disc_new_user.data.discount", NaN);
    const discT = _.get(discounts, "disc_new_user.data.discount_type", NaN);
    const scope = _.get(discounts, "disc_new_user.data.scope", NaN);
    const product_skus = _.get(discounts, "disc_new_user.data.product_skus", []);
    const category_ids = _.get(discounts, "disc_new_user.data.category_ids", []);
    let exclude: any = [];
    if (Array.isArray(product_skus) && Array.isArray(category_ids)) {
        exclude = product_skus.concat(category_ids);
    }
    const conditionType = _.isEmpty(category_ids) ? (_.isEmpty(product_skus) ? dH.ALL : dH.PRODUCT) : dH.CATEGORY;
    const widths = ["25%", "20%", "40%", "15%"];
    const titles = ["user_reward_points", "discount", "condition_type", " "];
    const ALL = 1;

    useEffect(() => {
        dispatch(getStoreDiscounts());
    }, [dispatch]);

    const getNames = () => {
        let excluded = "";
        if (!_.isEmpty(exclude)) {
            if (conditionType === dH.PRODUCT) {
                exclude.forEach((item: any) => {
                    products.forEach(
                        (prod) => String(item) === prod.sku && (excluded += ` ${_.get(prod, `name.${language}`)},`)
                    );
                });
                excluded = excluded.slice(0, -1);
            } else {
                exclude.forEach((item: any) => {
                    categories.forEach(
                        (c) => Number(item) === c.category_id && (excluded += `${_.get(c, `name.${language}`)}, `)
                    );
                });
                excluded = excluded.slice(0, -1);
            }
        }
        return dH.removeComma(excluded);
    };

    const saveChanges = () => {
        dispatch(
            updateStoreDiscounts(new ObjectModel(DiscountsModel).convertToPostData(discounts, null, "user_discounts"))
        );
    };

    const deleteRowConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: "delete_discount_rule" }),
            content: intl.formatMessage({ id: "are_you_sure" }),
            onOk() {
                deleteRow();
            },
            okType: "danger",
        });
    };

    const deleteRow = () => {
        dispatch(setAutoDiscounts({ name: "disc_new_user", value: undefined }));
    };

    const actions = () => (
        <React.Fragment>
            <p className="discount-rule-action popover-item" onClick={() => dispatch(setUserModal(true))}>
                <FormattedMessage id="edit" />
            </p>
            <p className="discount-rule-action popover-item danger" onClick={() => deleteRowConfirm()}>
                <FormattedMessage id="delete" />
            </p>
        </React.Fragment>
    );

    const renderActs = isMobile() ? (
        <div className="actions-col">{actions()}</div>
    ) : (
        <div className="actions-col">
            <Popover content={actions()}>
                <HiDotsHorizontal size={25} color="#3898c8" />
            </Popover>
        </div>
    );

    const getMessage = () => {
        if (Number(scope) === ALL) {
            return (
                <div>
                    <FormattedMessage id="apply_to_all" />
                </div>
            );
        }
        if (Number(scope) === dH.INCLUDE) {
            return (
                <div>
                    <b>
                        <FormattedMessage
                            id={
                                conditionType === dH.PRODUCT
                                    ? "include_selected_products"
                                    : "include_selected_categories"
                            }
                        />
                        :{" "}
                    </b>
                    {getNames()}
                </div>
            );
        }
        if (Number(scope) === dH.EXCLUDE) {
            return (
                <div>
                    <b>
                        <FormattedMessage
                            id={
                                conditionType === dH.PRODUCT
                                    ? "exclude_selected_products"
                                    : "exclude_selected_categories"
                            }
                        />
                        :{" "}
                    </b>
                    {getNames()}
                </div>
            );
        }
        return "";
    };

    

    const columns = [
        {
            width: "25%",
            component: (
                <div>
                    {isNaN(rewards) ? 0 : rewards} <FormattedMessage id="points" />
                </div>
            ),
        },
        {
            width: "20%",
            component: (
                <div>
                    {Number(discT) === dH.AMOUNT && currencySymbols[storeCurrency]}
                    {discount}
                    {Number(discT) === dH.PERCENT && "%"}
                </div>
            ),
        },
        { width: "40%", component: getMessage() },
        { width: "15%", component: renderActs },
    ];

    const renderRow = () => {
        return (
            <div className="table-row d-flex">
                {discount
                    ? columns.map((col, i) => (
                        <div key={`table-col-${i}`} className="table-col d-flex" style={{ width: col.width }} onClick={() => dispatch(setUserModal(true))}>
                            {col.component}
                        </div>
                    ))
                    : renderEmptySet}
            </div>
        );
    };

    const renderEmptySet = (
        <div className="emptySet">
            <RiCouponFill size={80} color="#e1e0e0" />
            <div className="empty-text-holder">
                <div className="empty-head mb-2">
                    <FormattedMessage id="no_discount_rules" />
                </div>
                <div className="empty-body">
                    <FormattedMessage id="create_new_rule" />
                </div>
            </div>
        </div>
    );

    const renderCol = (width: string, value: any, trans: boolean) => (
        <div className="table-col" style={{ width: width }}>
            {trans ? <FormattedMessage id={value} /> : value}
        </div>
    );

    const renderTableHeader = (
        <div className="table-header d-flex">{widths.map((width, index) => renderCol(width, titles[index], true))}</div>
    );

    const renderRowMobile = discount ? (
        <div className="d-flex flex-column">
            {columns.map((col, index) => (
                <div className="d-flex flex-row table-header-value my-1" key={index}>
                    <div className="table-col table-header mr-1" style={{ width: "40%" }}>
                        <FormattedMessage id={titles[index] ?? " "} />
                    </div>
                    <div className="table-col table-value" style={{ width: "60%" }}>
                        {col.component}
                    </div>
                </div>
            ))}
        </div>
    ) : (
        renderEmptySet
    );

    const userModal = (
        <Modal
            visible={openModal}
            title={intl.formatMessage({ id: "create_automatic_user_discount" })}
            width="700px"
            footer={null}
            onCancel={() => dispatch(setUserModal(false))}
        >
            <UserModal />
        </Modal>
    );

    const renderAddRuleButton = (
        <div className="w100 d-flex mt-3 justify-content-end">
            <Button
                type="default"
                className="text-on-white-background"
                disabled={discount}
                onClick={() => dispatch(setUserModal(true))}
            >
                <FormattedMessage id="add_rule" />
            </Button>
            <Button type="primary" className="ml-2 save-button-handler" onClick={() => saveChanges()}>
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return !_.isEmpty(discounts) ? (
        <div className="subTab-table-container">
            {!isMobile() && discount ? renderTableHeader : null}
            {!isMobile() ? renderRow() : renderRowMobile}
            {renderAddRuleButton}
            {userModal}
        </div>
    ) : null;
}

export default UserSubTab;
