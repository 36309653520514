import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import config from "../../../../config";
import { editProduct } from "../../../../services/products";
import { getOptions } from "../../../../util/helper";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
    type: state?.store?.storeInformation?.store_categories,
    allowed: state?.pages?.allowed,
});

interface AvailabilityRendererProps {
    value: string;
}

function AvailabilityRenderer(props: AvailabilityRendererProps): React.ReactChild | null {
    const dispatch = useDispatch();
    const { products, allowed } = useSelector(selector);
    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    const availabilityOptions = getOptions(config.AVAILABILITY_OPTIONS).map((option: any) => (
        <Select.Option key={option.value} value={option.value}>
            {option.label}
        </Select.Option>
    ));

    const setOnClickHandler = (ref: HTMLDivElement | null) => {
        if (!ref) {
            return;
        }

        ref.onclick = (e) => {
            e.stopPropagation();
        };
    };

    const getAvailabilityInput = (record: any) => (
        <div ref={setOnClickHandler}>
            <Select
                value={record?.availability}
                disabled={!allowed}
                onChange={(e) => {
                    if (record?.sku !== e) {
                        dispatch(editProduct({ product_id: record?.product_id, availability: e }));
                    }
                }}
                style={{ width: "100%" }}
            >
                {availabilityOptions}
            </Select>
        </div>
    );

    return record ? getAvailabilityInput(record) : null;
}

export default AvailabilityRenderer;
