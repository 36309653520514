import React from "react";
import PaymentIcons from "../../../../../images/icons/payment-icon.png";
import { View, Image, Text } from "@react-pdf/renderer";

const tablePaymentPoints = (styles: React.CSSProperties | any, url: { tableNum: number | string }): JSX.Element => (
    <View style={styles.tableNumCont}>
        <View style={styles.tableNumHolder}>
            <Text>{url.tableNum}</Text>
        </View>
        <View style={styles.paymentContainer}>
            <Image src={PaymentIcons} />
        </View>
        <View style={styles.earnPoints}>
            <Text> Order online to earn rewards </Text>
        </View>
    </View>
);
export default tablePaymentPoints;
