import { Form, Select, Row, Col, Input, Button } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { PHONE_MAX_LEN } from "../../constants";
import "./new-customer.scss";
import { PhoneCodes } from "../../../../slices/setting";
import MeetAtDoorIMG from "../../../../images/icons/person.png";
import LeaveAtDoorIMG from "../../../../images/icons/door.png";
import MeetOutsideIMG from "../../../../images/icons/leave_house.png";
const DELIVERY_OPTIONS = ["leave_at_door", "meet_at_door", "meet_outside"];
const DELIVERY_IMG_STYLE = [
    { width: "20px", height: "20px", marginRight: "2px" },
    { marginRight: "3px", paddingBottom: "5px", width: "18px" },
    { width: "20px", height: "20px", marginRight: "7px" },
];
const DELIVERY_OPTIONS_IMG = [LeaveAtDoorIMG, MeetAtDoorIMG, MeetOutsideIMG];
const { Option } = Select;

const MIN_SEARCH_LENGTH = 6;
const DEFAULT_LAT = 40.73;
const DEFAULT_LNG = -73.93;

class AddressInfoForm extends Component {
    state = {
        suggestions: [],
        default_address: {},
    };

    setCustomerInfoData = (values) => {
        this.props.setFormCustomerState({
            customerInfo: {
                ...(this.getFormData()?.customerInfo || {}),
                ...values,
            },
        });
    };

    mapFormNameToTranslationId = (formName) => {
        switch (formName) {
            case "tn":
                return "phone";
            case "zipcd":
                return "postal_code";
            default:
                return formName;
        }
    };

    getSharedFormItemProps = (name = " ") => {
        const inValid = _.get(this.getFormData(), `${name}Invalid`);
        return {
            validateStatus: inValid ? "error" : "success",
            help: inValid
                ? this.str("please_input_valid_x", { x: this.str(this.mapFormNameToTranslationId(name)) })
                : "",
        };
    };

    getSharedInputProps = (name) => {
        return {
            value: _.get(this.props.formState, `default_address.${name}`),
            onChange: (e) => {
                const value = e?.target ? e?.target?.value : e;
                const formattedValue = name === "tn" ? value.replaceAll(/[^0-9]/g, "") : value;
                this.setDefaultAddressData({
                    [name]: formattedValue,
                });
            },
        };
    };

    getFormData = () => this.props.formState || {};

    getDefaultAddressData = (key) => _.get(this.getFormData(), `default_address.${key}`);

    setDefaultAddressData = (values) => {
        this.props.setFormState({
            default_address: {
                ...(this.getFormData().default_address || {}),
                ...values,
            },
        });
    };

    getDisplayLatLon = () => {
        return {
            lat: _.get(this.state, "latLngDisplay.lat", DEFAULT_LAT),
            lng: _.get(this.state, "latLngDisplay.lng", DEFAULT_LNG),
        };
    };

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCurrentLocation = (callBack = () => {}) => {
        const getLocationFail = () => {
            this.setState({ displayCurrentButton: false });
        };

        const getLocaiontSuccess = (p) => {
            const latitude = _.get(p, "coords.latitude", null);
            const longitude = _.get(p, "coords.longitude", null);
            if (latitude && longitude) {
                this.setState({
                    userLatLon: { lat: latitude, lng: longitude },
                    latLngDisplay: { lat: latitude, lng: longitude },
                    displayCurrentButton: true,
                });
            } else {
                getLocationFail({ code: 2 });
            }
            callBack();
        };

        const options = {
            enableHighAccuracy: true,
            maximumAge: "infinity", //maximum cached position age.
            timeout: 10000, //  integer (milliseconds) ,amount of time before the error callback is invoked, if 0 it will never invoke.
        };

        this.setState({ loading: true });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (p) => getLocaiontSuccess(p),
                (e) => getLocationFail(e),
                options
            );
        } else {
            getLocationFail({ code: 2 });
        }
        this.setState({ loading: false });
    };

    handleSearch = (value) => {
        const address = value;
        this.setState({ search: address });
        const googleAutoService = new window.google.maps.places.AutocompleteService();
        const addressLengthSuffice = address.length >= MIN_SEARCH_LENGTH;

        if (addressLengthSuffice && googleAutoService) {
            googleAutoService.getPlacePredictions(
                {
                    input: address,
                    location: new window.google.maps.LatLng({
                        lat: this.getDisplayLatLon().lat,
                        lng: this.getDisplayLatLon().lng,
                    }),
                    radius: 100 * 1000,
                    language: "en",
                },
                (displaySuggestions) => {
                    const suggestions = _.isEmpty(displaySuggestions) ? [] : displaySuggestions;
                    this.setState({ suggestions });
                    this.forceUpdate();
                }
            );
        }
    };

    handleAutoPlaceOptionSelect = (placeId) => {
        this.setState({ loading: true, suggestions: [] });
        this.searchWithPlaceId(placeId);
        this.setState({ loading: false });
    };

    searchWithPlaceId = (placeId) => {
        this.setDefaultAddressData({ placeId });
        try {
            const request = {
                placeId,
                language: "en",
            };

            new Promise((resolve, reject) => {
                new window.google.maps.places.PlacesService(document.createElement("div")).getDetails(
                    request,
                    (place, status) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            reject();
                        }
                    }
                );
            }).then((addressObj) => {
                const component = _.get(addressObj, "address_components", []);
                const location = _.get(addressObj, "geometry.location", {});
                this.onSearchWithPlaceIdFinish(component, {
                    lat: location.lat(),
                    lng: location.lng(),
                });
            });
        } catch (e) {
            // Handle error
        }
    };

    onSearchWithPlaceIdFinish = (component, latLng) => {
        let default_address = {};
        if (Array.isArray(component)) {
            component.forEach((component) => {
                const { types } = component;

                if (types.includes("street_number")) {
                    default_address.street = component["short_name"];
                } else if (types.includes("route")) {
                    default_address.street += `${default_address?.street?.length ? " " : ""}${component["long_name"]}`;
                } else if (types.includes("locality") || types.includes("sublocality")) {
                    default_address.city = component["long_name"];
                } else if (types.includes("administrative_area_level_1")) {
                    default_address.region = component["short_name"];
                } else if (types.includes("country")) {
                    default_address.cntry = component["short_name"];
                } else if (types.includes("postal_code")) {
                    default_address.zipcd = component["short_name"];
                }
            });
        }
        default_address.lat = _.get(latLng, "lat", null);
        default_address.lon = _.get(latLng, "lng", null);

        //verify zipcd

        this.props.setFormState({
            default_address: {
                ...(this.getFormData().default_address || {}),
                ...default_address,
            },
        });
    };

    renderTitle = () => {
        return (
            <div className="new-customer-form-title">
                <FormattedMessage id="address" />
            </div>
        );
    };

    renderCompanyInput = () => {
        return (
            <Form.Item {...this.getSharedFormItemProps("company")} label={this.str("company")}>
                <Input {...this.getSharedInputProps("company")} />
            </Form.Item>
        );
    };

    renderStreetInput = () => {
        return (
            <div style={{ marginBottom: this.props.powerByBottomMargin || "-5px" }}>
                <Form.Item
                    style={{ marginBottom: 0 }}
                    {...this.getSharedFormItemProps("street")}
                    label={this.str("address")}
                >
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        style={{ width: "100%" }}
                        onSearch={(value) => this.handleSearch(value)}
                        placeholder={this.str("map_search_placeholder")}
                        onSelect={(value) => this.searchWithPlaceId(value)}
                        value={this.getDefaultAddressData("placeId")}
                        options={this.state.suggestions.map((option) => {
                            return {
                                label: _.get(option, "description", ""),
                                value: _.get(option, "place_id"),
                            };
                        })}
                        filterOption={() => {
                            return true;
                        }}
                    />
                </Form.Item>
                <div className="poweredByGoogleOption">Powered by Google.</div>
            </div>
        );
    };

    renderPostalCodeInput = () => {
        return (
            <Form.Item {...this.getSharedFormItemProps("zipcd")} label={this.str("postal_code")}>
                <Input {...this.getSharedInputProps("zipcd")} />
            </Form.Item>
        );
    };

    renderBuzzUnitInput = () => {
        return (
            <div className="new-customer-form-row">
                <Row style={{ width: "100%" }} gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            {...this.getSharedFormItemProps("buzz")}
                            style={{ width: "100%" }}
                            label={this.str("buzzer")}
                        >
                            <Input {...this.getSharedInputProps("buzz")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            {...this.getSharedFormItemProps("unit")}
                            style={{ width: "100%" }}
                            label={this.str("unit")}
                        >
                            <Input {...this.getSharedInputProps("unit")} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    renderPhoneInput = () => {
        //Country Code of the address selected in general settings
        const countryCode = this.props.state.store.records.store_addr.country_code;

        const prefixSelector = (
            <Form.Item {...this.getSharedFormItemProps("country_code")} noStyle>
                <Select
                    {...this.getSharedInputProps("country_code")}
                    style={{ width: 70 }}
                    //If no codes found for the country selected, default CA country code
                    defaultValue={Object.keys(PhoneCodes).includes(countryCode) ? countryCode.toUpperCase() : "CA"}
                >
                    {Object.entries(PhoneCodes).map(([key, value]) => (
                        //default EN to CA country code
                        <Option key={key} value={key === "EN" ? "CA" : key}>
                            {value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
        return (
            <div className="new-customer-form-row">
                <Form.Item {...this.getSharedFormItemProps("tn")} style={{ width: "100%" }} label={this.str("phone")}>
                    <Input {...this.getSharedInputProps("tn")} addonBefore={prefixSelector} maxLength={PHONE_MAX_LEN} />
                </Form.Item>
            </div>
        );
    };
    renderDeliveryTitle = () => {
        return this.props.withoutTitle ? null : (
            <div className="new-customer-form-title">
                <FormattedMessage id="delivery_options" />
            </div>
        );
    };
    renderDeliveryOptionsButtons = () => {
        return (
            <div className="d-flex justify-content-start padding-left-{-8px}">
                {DELIVERY_OPTIONS.map((e, i) => {
                    return this.renderDeliveryOptionButton(e, i);
                })}
            </div>
        );
    };

    renderDeliveryOptionButton = (name, key) => {
        return (
            <Button
                type={name === this.getFormData().customerInfo?.delivery_options ? "primary" : "secondary"}
                style={{
                    backgroundColor: name === this.getFormData().customerInfo?.delivery_options ? "#318DC1" : "#EEEEEF",
                    border: "0px",
                    margin: "0px !important",
                }}
                className="m-2"
                onClick={() => {
                    this.setCustomerInfoData({ delivery_options: DELIVERY_OPTIONS[key] });
                }}
            >
                <div className="d-flex align-items-center justify-content-center">
                    <img
                        src={DELIVERY_OPTIONS_IMG[key]}
                        alt="img"
                        style={{
                            width: "25px",
                            height: "25px",
                            filter: name === this.getFormData().customerInfo?.delivery_options ? "brightness(100)" : "",
                            border: "0px",
                            radius: "15px",
                            ...DELIVERY_IMG_STYLE[key],
                        }}
                    />
                    <div
                        style={{
                            color: name !== this.getFormData().customerInfo?.delivery_options ? "#318DC1" : "#FFFFFF",
                        }}
                    >
                        <FormattedMessage id={name} />
                    </div>
                </div>
            </Button>
        );
    };
    renderInputOption = (title) => {
        return (
            <div className={"mb-4 mt-3"}>
                <div>
                    <FormattedMessage id={title} />
                </div>

                <Input
                    value={this.getFormData().customerInfo?.delivery_instructions}
                    onChange={(e) => this.setCustomerInfoData({ delivery_instructions: e.target.value })}
                />
            </div>
        );
    };
    render() {
        return (
            <div className="address-info-form">
                <Form layout="vertical">
                    {this.renderTitle()}
                    {this.renderCompanyInput()}
                    {this.renderStreetInput()}
                    {this.renderPostalCodeInput()}
                    {this.renderBuzzUnitInput()}
                    {this.renderPhoneInput()}
                    {this.renderDeliveryTitle()}
                    {this.renderDeliveryOptionsButtons()}
                    {this.renderInputOption("delivery_instructions")}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: _.get(state, "customers.customer", {}),
});

const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddressInfoForm));
