import { createSlice } from "@reduxjs/toolkit";
import { OrderTrackingInfo } from "components/shipment-tracking/models/shipment-tracking-requests";

interface ShipmentTrackingState {
    courierMethods?: any[];
    isLoading?: boolean;
    orderCarriers?: OrderTrackingInfo[];
}

const initialState: ShipmentTrackingState = {
    courierMethods: [],
    isLoading: false,
};

const slice = createSlice({
    name: "shipmentTracking",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
    },
});

export const { setState } = slice.actions;
export const shipmentTracking = slice.reducer;
