import { Modal, Button } from "antd";
import { RootState } from "app/reducer";
import config from "config";
import _ from "lodash";
import { AppModalData } from "models/app-modal";
import { AssociatedProduct } from "models/products";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { createCustomers } from "services/customers";
import { deleteProduct, getProduct } from "services/products";
import { setState, closeModal } from "slices/app-modal";
import "./styles.scss";

interface AppModalStateProps {
    modalData: AppModalData;
    lan: string;
    state: RootState;
}

interface AppModalProps extends AppModalStateProps, PropsFromRedux {}

const AppModal = (props: AppModalProps) => {
    const { modalData } = props;
    const { visible, title, content, additionalInfo, code } = modalData;

    const renderForceDeleteProductFooter = () => {
        const currentProductId = additionalInfo?.currentProductId;

        return (
            <>
                <Button type="primary" key="back" onClick={() => props.closeModal()}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    type="default"
                    danger
                    key="submit"
                    onClick={() => {
                        props.deleteProduct({ pid: currentProductId, force: true });
                        props.closeModal();
                    }}
                >
                    <FormattedMessage id="delete" />
                </Button>
            </>
        );
    };

    const renderCreateCustomerAdditionalInfo = (info: string) => {
        if (!additionalInfo[info]) {
            switch (info) {
                case "phone":
                    return <FormattedMessage id={"no_phone_number"} />
                case "email":
                    return <FormattedMessage id={"no_email"} />
            }
        }

        return additionalInfo[info];
    }

    const renderCreateCustomerContent = () => (
        <>
            <FormattedMessage id={content} />

            <div className="additional-info d-flex flex-column">
                <div>
                    <FormattedMessage id={"name"} />: <em>{renderCreateCustomerAdditionalInfo("name")}</em>
                </div>
                <div>
                    <FormattedMessage id={"email"} />: <em>{renderCreateCustomerAdditionalInfo("email")}</em>
                </div>
                <div>
                    <FormattedMessage id={"phone"} />: <em>{renderCreateCustomerAdditionalInfo("phone")}</em>
                </div>
            </div>
        </>
    );

    const renderForceDeleteProductItemList = () => {
        const associatedProducts: AssociatedProduct[] = additionalInfo?.associatedProducts ?? [];

        const formattedItemList = (
            <>
                <br />
                <br />
                <ul>
                    {associatedProducts.map((product) => {
                        return <li key={`parent-products-${product.id}`}>{product.name}</li>;
                    })}
                </ul>
            </>
        );

        return <FormattedMessage id={content} values={{ item_list: formattedItemList }} />;
    };

    const renderCreateCustomerInOtherStoreFooter = () => {
        const { phone, email } = additionalInfo;

        return (
            <>
                <Button type="primary" key="back" onClick={() => props.closeModal()}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    type="default"
                    danger
                    key="submit"
                    onClick={() => {
                        props.createCustomers({ action: 1, phone, email });
                        props.closeModal();
                    }}
                >
                    <FormattedMessage id="add" />
                </Button>
            </>
        );
    };

    const renderModalTitle = () => {
        return <FormattedMessage id={title} />;
    };

    const renderMessageWithValues = () => {
        return <FormattedMessage id={content} values={additionalInfo} />;
    };

    const renderModalContent = () => {
        switch (code) {
            case "force-delete-product":
                return renderForceDeleteProductItemList();
            case "with-values":
                return renderMessageWithValues();
            case "customer-phone-email-exists": 
            case "confirm-create-customer-in-other-store":
                return renderCreateCustomerContent();
            default:
                return <FormattedMessage id={content} />;
        }
    };

    const renderModalFooter = () => {
        switch (code) {
            case "force-delete-product":
                return renderForceDeleteProductFooter();
            case "confirm-create-customer-in-other-store":
                return renderCreateCustomerInOtherStoreFooter();
            default:
                return (
                    <>
                        <Button key="back" onClick={() => props.closeModal()}>
                            <FormattedMessage id="ok" />
                        </Button>
                    </>
                );
        }
    }

    return (
        <Modal
            title={renderModalTitle()}
            visible={visible}
            footer={renderModalFooter()}
            onCancel={() => props.closeModal()}
        >
            {renderModalContent()}
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    const AppModalStateProps: AppModalStateProps = {
        modalData: _.get(state, "modal", { visible: false, }),
        lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
        state,
    };
    return AppModalStateProps;
};

const mapDispatchToProps = {
    setState,
    closeModal,
    deleteProduct,
    getProduct,
    createCustomers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppModal);
