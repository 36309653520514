import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import helper from "../../../../util/helper";
import "./styles.scss";

interface CustomerGiftCardAccountCurrencyProps {
    value: string;
}

function CustomerGiftCardAccountCurrencyRenderer(props: CustomerGiftCardAccountCurrencyProps): JSX.Element | null {
    const accounts = useSelector((state: RootState) => state?.giftcards?.accounts);
    const currency =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";

    const record = accounts?.find((account: any) => Number(account?.giftcardaccount_id) === Number(props?.value));

    return record ? (
        <div className="gift-card-currency-cell">
            {/* @ts-ignore */}
            {helper.formatCurrency(record?.[props?.name ?? " "], currency ?? "CAD")}
        </div>
    ) : null;
}

export default CustomerGiftCardAccountCurrencyRenderer;
