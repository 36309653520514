import React from "react";
import { SelectOption } from "../../../../../../components/form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import h from "../../helper"
import helper from "../../../../../../util/helper"
import { switchNum } from "../../../../../../util/helper";
import { renderEnableTop, renderNumberInput, renderSelectInput, renderCountriesSelection } from "../shipping-functions"
import { setShippingState } from "../../../../../../slices/store";

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const enable = useSelector((state: RootState) => state.store?.storeShipping?.flatEnable);
    const handleType = useSelector((state: RootState) => state.store?.storeShipping?.flatHandleType);
    const handlingFee = useSelector((state: RootState) => state.store?.storeShipping?.flatHandlingFee);
    const price = useSelector((state: RootState) => state.store?.storeShipping?.flatPrice);
    const shipCountryType = useSelector((state: RootState) => state.store?.storeShipping?.flatShipCountryType);
    const calcHandlingFee = useSelector((state: RootState) => state.store?.storeShipping?.flatCalcHandlingFee);
    const allowCountry = useSelector((state: RootState) => state.store?.storeShipping?.flatAllowCountry);

    const handleTypes: SelectOption[] = [
        { value: h.handleType.PER_ORDER, label: intl.formatMessage({ id: "per_order" }) },
        { value: h.handleType.PER_ITEM, label: intl.formatMessage({ id: "per_item" }) },
    ];

    const handleCalcs: SelectOption[] = [
        { value: h.handleCalcs.FIXED, label: intl.formatMessage({ id: "fixed" }) },
        { value: h.handleCalcs.PERCENTAGE, label: intl.formatMessage({ id: "percentage" }) },
    ];

    const setState = (name: string, value: any) => {
        dispatch(setShippingState({ name, value }));
    };
    const switchChange = (name: string, value: any) => dispatch(setShippingState({ name, value: switchNum(value) }));

    const renderCountriesSection = (
        <div className="d-flex w100 align-items-center justify-content-between country-selector">
            {renderCountriesSelection(
                "flatShipCountryType",
                shipCountryType,
                "flatAllowCountry",
                allowCountry,
                setState,
                intl
            )}
        </div>
    );

    return (
        <div className="white-layered-block w100 table-rate-tab">
            {renderEnableTop("flatEnable", enable, switchChange)}
            {renderCountriesSection}
            <div className="d-flex flex-wrap w100 mt-3">
                {renderNumberInput("base_price", "flatPrice", price, setState, {
                    formatter: (value: any) => helper.formatMoney(value, intl),
                    parser: (value: any) => helper.parseMoney(value, intl),
                })}
                {renderSelectInput("handle_type", "flatHandleType", handleType, setState, handleTypes)}
                {renderNumberInput("handling_fee", "flatHandlingFee", handlingFee, setState, {
                    formatter: (value: any) => helper.formatMoney(value, intl),
                    parser: (value: any) => helper.parseMoney(value, intl),
                })}
                {renderSelectInput("calc_handling_fee", "flatCalcHandlingFee", calcHandlingFee, setState, handleCalcs)}
            </div>
        </div>
    );
}

export default App;
