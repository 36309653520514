import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";

const qrScan = (
    styles: React.CSSProperties | any,
    url: { dataUrl: string | any },
    props: { storeLogo: string | any }
): JSX.Element => (
    <View style={styles.qrHolder}>
        <View style={styles.qrBlock}>
            <Image src={url.dataUrl} />
        </View>
        <View style={styles.logo}>
            <Image src={props.storeLogo} />
        </View>
        <View style={styles.qrCodeTextCont}>
            <Text>Scan to Order</Text>
        </View>
    </View>
);
export default qrScan;
