import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Radio, Checkbox } from "antd";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { TiArrowSortedDown } from "react-icons/ti";
import h from "util/helper";
import aH from "../../helper";
import { getAdminUsersDetails, getAdminRoles, getDefaultRoles } from "../../../../services/admin-users";
import {
    setPermissionTab,
    makeAccessFlagsUsefull,
    setFlag,
    setAllFlags,
    setUserDetail,
} from "../../../../slices/admin-users";
import config from "../../../../config";

const PermissionRoles = (): JSX.Element => {
    const [isRoleSuper, setIsRoleSuper] = useState(false);
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    const selectedUser = useSelector((state: RootState) => state.adminUsers?.user);
    const selectedRoles = useSelector((state: RootState) => state.adminUsers?.user?.roles);
    const flags = useSelector((state: RootState) => state.adminUsers?.flags);
    const defaultRoles = useSelector((state: RootState) => state.adminUsers?.defaultRoles);
    const storeRoles = useSelector((state: RootState) => state.adminUsers?.storeRoles);
    const activePermissionTab = useSelector((state: RootState) => state.adminUsers?.activePermissionTab);
    const adminUsers = useSelector((state: RootState) => state.adminUsers?.users);

    const currUser = useSelector((state: RootState) => state.user);
    const currUserID = _.get(currUser, "id");
    const isCurrUser = selectedUser.id === currUserID;

    /**
     * Check if editing a user's role should be disabled depending on
     * current user's roles.
     *
     * @remarks
     * Super Admin can edit roles of any user except themselves.
     * Franchise Manager can edit roles of any user except Super Admin and Franchise Manager.
     * Store Manager can edit roles of any user except Super Admin, Franchise Manager and Super Admin.
     *
     * All other roles cannot edit other users permissions.
     *
     * @param id - ID of the selected user.
     */

    useEffect(() => {
        setIsRoleSuper(isRoleDisabled());
    }, []);


    const isRoleDisabled = () => {
        // Current user cannot edit their own roles
        if (isCurrUser) {
            return false;
        }

        // Get user roles
        const currUserRoles = adminUsers?.find((u: any) => u.id === currUserID)?.roles ?? [];
        const selectedUserRoles = _.isArray(selectedRoles) ? selectedRoles.map((r: any) => Number(r)) : [];

        // If current user is a super user, roles are not disabled
        const isSuperUser = h.isSuperUser(currUser);
        if (isSuperUser) {
            return true;
        }

        // Determine user's permission level
        const isManagerUser = aH.isManagerUser(currUser);
        const canEditAdminUsers = aH.getPermission(currUser, aH.ADMIN_USERS) === aH.READ_WRITE;
        const hasGreaterRoles = aH.hasGreaterRoles(currUserRoles, selectedUserRoles);
        const hasSameOrGreaterRole = aH.hasSameOrGreaterRole(currUserRoles, selectedUserRoles);
        const canEditRoles = aH.getPermission(currUser, aH.ROLES_PERMISSIONS_SETTINGS) === aH.READ_WRITE;

        return isManagerUser && canEditAdminUsers && (hasGreaterRoles || (hasSameOrGreaterRole && canEditRoles));
    };

    /**
     * Checks to see if editing permissions should be disabled.
     *
     * @remarks
     * Check is based on:
     *
     * 1) If current user is allowed to read and write roles and permissions
     *    and has at least the same role as the selected user.
     *
     * 2) Current user cannot edit themself.
     *
     * 3) If current user's roles has higher priority than user being edited.
     *
     * @example
     * Franchise Manager can edit Store Managers and other users,
     * but not Super users or themselves.
     *
     * @example
     * Super user can edit anyone but themselves.
     */
    const isPermissionsDisabled = () => {
        // Current user cannot edit their own roles

        if (isCurrUser) {
            return true;
        }

        // Get user roles
        const currUserRoles = adminUsers?.find((u: any) => u.id === currUserID)?.roles ?? [];
        const selectedUserRoles = _.isArray(selectedRoles) ? selectedRoles.map((r: any) => Number(r)) : [];

        // If current user is a super user, permissions are not disabled
        const isSuperUser = h.isSuperUser(currUser);
        if (isSuperUser) {
            return false;
        }

        // Determine user's permission level
        const isManagerUser = aH.isManagerUser(currUser);
        const canEditAdminUsers = aH.getPermission(currUser, aH.ADMIN_USERS) === aH.READ_WRITE;
        const hasGreaterRoles = aH.hasGreaterRoles(currUserRoles, selectedUserRoles);
        const hasSameOrGreaterRole = aH.hasSameOrGreaterRole(currUserRoles, selectedUserRoles);
        const canEditRoles = aH.getPermission(currUser, aH.ROLES_PERMISSIONS_SETTINGS) === aH.READ_WRITE;

        return !(isManagerUser && canEditAdminUsers && (hasGreaterRoles || (hasSameOrGreaterRole && canEditRoles)));
    };

    const order_access_flags = [
        { name: "order_history", index: aH.ORDER_HISTORY },
        { name: "live_order", index: aH.LIVE_ORDER },
        { name: "order_export", index: aH.ORDER_EXPORT },
        { name: "local_delivery", index: aH.LOCAL_DELIVERY },
        { name: "create_order", index: aH.CREATE_ORDER },
    ];
    const settings_access_flags = [
        { name: "setting_pickup_delivery", index: aH.PICKUP_DELIVERY_SETTINGS },
        { name: "setting_self_serve", index: aH.SELF_SERVE_SETTINGS },
        { name: "setting_hours", index: aH.HOURS_SETTINGS },
        { name: "setting_managed_stores", index: aH.MANAGED_STORES_SETTINGS },
        { name: "setting_surcharge", index: aH.SURCHARGE_SETTINGS },
        {
            name: "setting_item_availability",
            index: aH.ITEM_AVAILABILITY_SETTINGS,
        },
        { name: "setting_switch_store", index: aH.SWITCH_STORE_SETTINGS },
        { name: "setting_menu", index: aH.MENU_SETTINGS },
        { name: "display_settings", index: aH.DISPLAY_SETTINGS },
        { name: "setting_payments", index: aH.PAYMENT_SETTINGS },
        { name: "tips_settings", index: aH.TIPS_SETTINGS },
        { name: "notification_settings", index: aH.NOTIFICATION_SETTINGS },
        {
            name: "roles_permission_settings",
            index: aH.ROLES_PERMISSIONS_SETTINGS,
        },
        {
            name: "setting_order_notifications",
            index: aH.ORDER_NOTIFICATION_SETTINGS,
        },
        { name: "store_availability", index: aH.STORE_AVAILABILITY },
    ];
    const products_access_flags = [
        { name: "product_availability", index: aH.PRODUCT_AVAILABILITY },
        { name: "category_management", index: aH.CATEGORY_MANAGEMENT },
        { name: "product_management", index: aH.PRODUCT_MANAGEMENT },
    ];
    const store_access_flags = [
        { name: "setting_general_info", index: aH.GENERAL_INFO_SETTINGS },
        { name: "product_review", index: aH.PRODUCT_REVIEW },
        { name: "customer_review", index: aH.CUSTOMER_REVIEW },
        { name: "create_store", index: aH.CREATE_STORE },
        { name: "store_info", index: aH.STORE_INFO },
        { name: "order_review", index: aH.ORDER_REVIEW },
    ];
    const marketing_access_flags = [
        { name: "giftcard_management", index: aH.GIFTCARD_MANAGEMENT },
        { name: "coupon_management", index: aH.COUPON_MANAGEMENT },
        { name: "setting_discount", index: aH.DISCOUNT_SETTINGS },
        { name: "referral_page", index: aH.REFERRAL_PAGE },
    ];
    const common_access_flags = [
        { name: "dashboard", index: aH.DASHBOARD },
        { name: "customer", index: aH.CUSTOMER },
        { name: "publish", index: aH.PUBLISH },
        { name: "user_profile", index: aH.USER_PROFILE },
        { name: "report", index: aH.REPORT },
        { name: "admin_users", index: aH.ADMIN_USERS },
        { name: "public_notice", index: aH.PUBLIC_NOTICE },
        { name: "business_hours", index: aH.BUSINESS_HOURS },
    ];
    const permTabs = [
        common_access_flags,
        store_access_flags,
        settings_access_flags,
        order_access_flags,
        products_access_flags,
        marketing_access_flags,
    ];
    const permissionTabs = [
        "common",
        "store_info_review",
        "settings",
        "order_management",
        "products_categories",
        "marketing",
    ];

    useEffect(() => {
        dispatch(getDefaultRoles());
        dispatch(getAdminRoles());
        dispatch(getAdminUsersDetails());
        dispatch(makeAccessFlagsUsefull());
    }, [dispatch]);

    const permissionMenu = (value: number, index: number) => (
        <React.Fragment>
            {value !== aH.NO_ACCESS ? (
                <Menu className="perm-dropdown-menu">
                    <Radio.Group
                        className="d-flex flex-column"
                        onChange={(e: any) => dispatch(setFlag({ index, value: e.target.value }))}
                        value={value}
                    >
                        <Radio value={aH.READ_ONLY}>
                            <FormattedMessage id="read_only" />
                        </Radio>
                        <Radio value={aH.READ_WRITE}>
                            <FormattedMessage id="read_write" />
                        </Radio>
                    </Radio.Group>
                </Menu>
            ) : null}
        </React.Fragment>
    );

    const getCheck = (title: string, index: number, value: any) => (
        <div className="perm-checkmarks d-flex flex-column mb-2">
            <div
                className={`w100 perm-check-button d-flex align-items-center ${isPermissionsDisabled() && "prevent-cursor"
                    }`}
            >
                <div
                    className={`w100 ${isPermissionsDisabled() && "prevent-actions"}`}
                    onClick={() =>
                        !isPermissionsDisabled() &&
                        dispatch(
                            setFlag({
                                index,
                                value: value === aH.NO_ACCESS ? aH.READ_ONLY : aH.NO_ACCESS,
                            })
                        )
                    }
                >
                    <div className={`dropdown-container-perm ${value !== aH.NO_ACCESS ? "active" : ""}`}>
                        <FormattedMessage id={title} />
                    </div>
                </div>
                {isPermissionsDisabled() ? null : (
                    <Dropdown overlay={permissionMenu(value, index)} disabled={isPermissionsDisabled()}>
                        <button className={`perm-button-drop ${value !== aH.NO_ACCESS ? "active" : ""}`}>
                            <TiArrowSortedDown size="18" color={value !== aH.NO_ACCESS ? "white" : "lightgrey"} />
                        </button>
                    </Dropdown>
                )}
            </div>
            <div className="access_button-text">
                {value === aH.NO_ACCESS ? <FormattedMessage id="cannot_access" /> : null}
                {value === aH.READ_ONLY ? <FormattedMessage id="read_only" /> : null}
                {value === aH.READ_WRITE ? <FormattedMessage id="read_write" /> : null}
            </div>
        </div>
    );

    const getFlags = (access_flags: string) => {
        const flags: any = [];
        const accessFlagsCpy = access_flags;
        const tmp = accessFlagsCpy.split(",");
        tmp.forEach((element: string) => {
            flags.push(parseInt(element.charAt(0)));
        });

        return flags;
    };

    const getPermissions = (tabIndex: number) => (
        <div className="perm-detail-checks">
            {permTabs?.[tabIndex]?.map((flag: any) => {
                if (Array.isArray(flags)) {
                    return getCheck(flag.name, flag.index, flags[flag.index] ?? 0);
                } else {
                    return getCheck(flag.name, flag.index, 0);
                }
            })}
        </div>
    );

    const roleHandler = (roleID: number) => {
        const rolesCpy = Array.isArray(selectedRoles) ? _.cloneDeep(selectedRoles) : [];
        //handle role
        if (rolesCpy?.includes(roleID)) {
            rolesCpy?.splice(rolesCpy?.indexOf(roleID), 1);
        } else {
            rolesCpy?.push(roleID);
        }
        //handle flags by given role
        const newFlags: any = [];
        const storeRolesCopy = Array.isArray(storeRoles) ? storeRoles : [];
        const defaultRolesCopy = Array.isArray(defaultRoles) ? defaultRoles : [];
        const allRoles = storeRolesCopy.concat(defaultRolesCopy);
        rolesCpy.forEach((roleId) => {
            //based on each role update new flags
            const foundRole = allRoles.find((r) => Number(_.get(r, "role_id")) === Number(roleId));
            if (foundRole) {
                const roleFlags = getFlags(foundRole.permissions);
                roleFlags.forEach((permision: any, i: any) => {
                    //only get the higheset permission
                    const currentValue = newFlags?.[i] ?? 0;
                    newFlags[i] = permision > currentValue ? permision : currentValue;
                });
            }
        });
        dispatch(setAllFlags({ flags: newFlags }));
        dispatch(setUserDetail({ name: "roles", value: rolesCpy }));
    };

    const roleCheck = (name: string, flags: any, roleID: number) => {
        return roleID === config.ROLE_ID.SUPER_USER && !h.isSuperUser(currUser) ? null : (
            <div className="roleChecks">
                <Checkbox
                    disabled={!isRoleSuper}
                    onChange={() => roleHandler(roleID)}
                    checked={selectedRoles?.find((elem: any) => Number(elem) === roleID)}
                >
                    <FormattedMessage id={name} />
                </Checkbox>
            </div>
        );
    };

    const getStoreRoleChecks = (
        <React.Fragment>
            {Array.isArray(storeRoles)
                ? storeRoles.map((role: any) =>
                    roleCheck(_.get(role, `name.${lan}`, ""), getFlags(role.access_flags), role._id)
                )
                : null}
        </React.Fragment>
    );

    const role = (
        <React.Fragment>
            <div className="payment-title-container">
                <FormattedMessage id="role" />
            </div>
            <div className="d-flex justify-content-center align-items-center w100">
                <div className="roleChecks-container d-flex justify-content-start flex-wrap">
                    {Array.isArray(storeRoles) ? getStoreRoleChecks : null}
                    {Array.isArray(defaultRoles)
                        ? defaultRoles.map((role: any) =>
                            roleCheck(role.role_name, getFlags(role.permissions), role.role_id)
                        )
                        : null}
                </div>
            </div>
        </React.Fragment>
    );

    const permissions = (
        <React.Fragment>
            <div className="payment-title-container mt-3">
                <FormattedMessage id="permissions" />
            </div>
            <div className="permissions-detail-container">
                <div className="perm-tab-options">
                    {permissionTabs.map((tab: any, index: number) => (
                        <div
                            key={index}
                            className={`perm-option ${activePermissionTab === index ? "perm-active" : ""}`}
                            onClick={() => dispatch(setPermissionTab(index))}
                        >
                            <FormattedMessage id={tab} />
                            {activePermissionTab === index ? <div className="perm-blue-underline" /> : null}
                        </div>
                    ))}
                </div>
                {getPermissions(activePermissionTab)}
            </div>
        </React.Fragment>
    );

    return (
        <div className="white-layered-block">
            {role}
            {permissions}
        </div>
    );
};

export default PermissionRoles;
