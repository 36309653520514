import React from "react";
import helper from "../../../../../util/helper";
import GoopterLogo from "../../../../../images/icons/official-goopter-logo.png";
import { View, Image, Text } from "@react-pdf/renderer";

const LogoNumber = (styles: React.CSSProperties | any, props: { phone: number | string }): JSX.Element => (
    <React.Fragment>
        <View style={styles.phone}>
            <Text>{helper.formatPhone(props.phone)}</Text>
        </View>
        <View style={styles.goopterContainer}>
            <View style={styles.poweredBy}>
                <Text>Powered by </Text>
            </View>
            <View style={styles.goopterLogo}>
                <Image src={GoopterLogo} />
            </View>
        </View>
    </React.Fragment>
);
export default LogoNumber;
