import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import OrderCard from "../../liveorder/_components/liveOrderCard";
import "./order-detail.scss";
import { Spin, Empty } from "antd";
import Controller from "./order-detail-controller";
import { getOrderId } from "../helper-order-list";
import actions from "../../../slices/order-history";

class OrderDetail extends Component {
    state = {};

    showOrder = () => {
        const order = this.getOrder();
        if (this.props.showCondition) {
            return this.props.showCondition(order);
        } else {
            const orderList = Array.isArray(this.props.orderList) ? this.props.orderList : [];
            const insideList = orderList.find((ord) => getOrderId(order) === getOrderId(ord));
            return !_.isEmpty(insideList);
        }
    };

    getOrder = (props = this.props) => _.get(props, "order", {});

    fetchOrderDetail = () => this.props.fetchOrderDetail({ orderId: getOrderId(this.getOrder()) });

    getHeight = () => {
        const minusRowIds = ["order-detail-detail-controller"];
        var result = this.props.height ? this.props.height : 0;
        minusRowIds.forEach((id) => {
            const row = document.getElementById(id);
            if (row && row.offsetHeight) {
                result -= row.offsetHeight;
            }
        });
        return result;
    };

    getCardHeight = () => {
        const detailHeight = this.getHeight();
        const cardTitle = _.get(document.getElementsByClassName("ant-card-head"), 0);
        const cardTttleHeight = cardTitle && cardTitle.offsetHeight ? cardTitle.offsetHeight : 0;
        return detailHeight - cardTttleHeight - 12;
    };

    renderOrder = () => {
        return (
            <div style={{ height: this.getHeight() }} className="order-page-order-detail">
                <OrderCard
                    bodyStyle={{
                        height: this.getCardHeight(),
                        overflowY: "auto",
                    }}
                    isOrderDetail={true}
                    withoutButton={true}
                    order={this.getOrder()}
                    fetchOrderDetail={() => this.fetchOrderDetail()}
                    isDetailLoading={this.props.isLoading}
                    standAlone={this.props.standAlone}
                />
            </div>
        );
    };

    renderControlButtons = () => {
        return <Controller />;
    };

    renderEmpty = () => {
        return (
            <div className="order-detail-empty-container" style={{ height: this.getHeight() }}>
                <Empty />
            </div>
        );
    };

    renderOrderDetail = () => {
        return (
            <>
                {this.renderOrder()}
                {this.renderControlButtons()}
            </>
        );
    };

    render() {
        return (
            <Spin spinning={this.props.isLoading}>
                {this.showOrder() ? this.renderOrderDetail() : this.renderEmpty()}
            </Spin>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    orderList: _.get(state, "orders-page.orderList", []),
    isLoading: _.get(state, "orders-page.isDetailLoading", false) || _.get(state, "liveOrders.isListLoading", false),
    order: _.get(state, "orders-page.orderDetailInstance", []),
    state: _.get(state, "orders-page", {}),
    height: _.get(state, "orders-page.contentHeight", {}),
});

const mapDispatchToProps = {
    fetchOrderDetail: actions.fetchOrderDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetail));
