import React from "react";
import { Button } from "antd";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/reducer";
import moment from "moment";
import { getGiftcardAccountHistory } from "../../../../services/giftcards";
import { setGiftcardState } from "../../../../slices/giftcards";
import { FormattedMessage } from "react-intl";

interface CustomerGiftCardAccountActionProps {
    value: string;
}

function CustomerGiftCardAccountActionMenuRenderer(props: CustomerGiftCardAccountActionProps): JSX.Element {
    const dispatch = useDispatch();
    const accounts = useSelector((state: RootState) => state?.giftcards?.accounts);
    const record = accounts?.find((account: any) => Number(account?.giftcardaccount_id) === Number(props?.value));

    const expires = moment(record?.date_expires);
    const disabled = expires.isBefore();
    const onTransactionHistoryClick = () => {
        dispatch(
            getGiftcardAccountHistory({
                giftcardaccount_id: record?.giftcardaccount_id,
                page: 1,
            })
        );
    };

    const onAddCreditClick = () => {
        dispatch(setGiftcardState({ addCreditId: record?.giftcardaccount_id }));
    };

    const onChargeClick = () => {
        dispatch(setGiftcardState({ chargeId: record?.giftcardaccount_id }));
    };

    return (
        <div className="d-flex">
            <div className="mr-1">
                <Button
                    className="ant-btn-sm transaction-history-button box-shadow-none"
                    type="primary"
                    onClick={onTransactionHistoryClick}
                >
                    {<FormattedMessage id="transactions" />}
                </Button>
            </div>
            <div className="mr-1">
                <Button className="ant-btn-sm box-shadow-none" onClick={onAddCreditClick} disabled={disabled}>
                    {<AiOutlinePlus className="mt-1" size="14" />}
                </Button>
            </div>
            <div>
                <Button className="ant-btn-sm box-shadow-none" onClick={onChargeClick} disabled={disabled}>
                    <AiOutlineMinus className="mt-1" size="14" />
                </Button>
            </div>
        </div>
    );
}

export default CustomerGiftCardAccountActionMenuRenderer;
