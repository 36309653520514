import { Tabs, Input, Button } from "antd";
import "./admin-users.scss";
import qs from "querystring";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../app/reducer";
import Wrapper from "../../components/wrapper";
import ActiveUsersTab from "./_components/users-table";
import EditDrawer from "./_components/edit-drawer";
import { getAdminRoles, getAdminUsersDetails } from "./../../services/admin-users";
import {
    setSearchValue,
    setAccountModal,
    setAccountModalType,
    clearUser,
    setPassword,
} from "./../../slices/admin-users";
import RefreshButton from "../../components/refresh";
import _ from "lodash";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/adminUsers", breadcrumbName: "admin_users" },
    ],
};

export enum UsersTabs {
    Active = "active",
    Inactive = "inactive",
}

const ADMIN_USERS_FLAG_INDEX = 26;

const hasAdminUserAccess = (currUserAccessFlags: string[]) =>
    !_.isEmpty(currUserAccessFlags) && +currUserAccessFlags[ADMIN_USERS_FLAG_INDEX] === 2;

function App(): JSX.Element {
    const tabs: any = [
        { key: UsersTabs.Active, tab: ActiveUsersTab },
        { key: UsersTabs.Inactive, tab: ActiveUsersTab },
    ];
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const loading = useSelector((state: RootState) => state.adminUsers.loading);
    const currUser = useSelector((state: RootState) => state.user);
    const tab = String(qs.parse(location.search)?.["?tab"] ?? UsersTabs.Active);
    const onChangeTab = (tab: string) => history.push("/adminUsers?tab=" + tab);
    const ADD_ADMIN_USER = 2;
    let currUserAccessFlags = currUser.access?.split(",") ?? [""];
    currUserAccessFlags = currUserAccessFlags.map((flag: string) => flag.split(":")[0]);

    useEffect(() => {
        dispatch(getAdminRoles());
    }, [dispatch]);

    const addAdmin = () => {
        dispatch(setPassword({ password: undefined }));
        dispatch(clearUser());
        dispatch(setAccountModalType(ADD_ADMIN_USER));
        dispatch(setAccountModal(true));
    };

    const refreshPage = () => {
        dispatch(getAdminUsersDetails());
    };

    const renderFilterBar = (
        <div className="d-flex w100 justify-content-between">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    <Input.Search
                        className="codes-search-input"
                        placeholder={intl.formatMessage({ id: "search_dot" })}
                        allowClear
                        size="large"
                        onChange={(e: any) => dispatch(setSearchValue(e?.target?.value ?? ""))}
                    />
                </div>
            </div>
            <div className="admin-action-right">
                <RefreshButton refreshPage={refreshPage} loading={loading} />
                <Button
                    disabled={!hasAdminUserAccess(currUserAccessFlags)}
                    className="filter-button"
                    onClick={() => addAdmin()}
                    type="primary"
                >
                    <div className="icon-button-text d-inline">
                        <FormattedMessage id="add_admin" />
                    </div>
                </Button>
            </div>
        </div>
    );

    return (
        <Wrapper helmet={{ title: "admin_users" }} breadcrumb={breadcrumb}>
            <div className="admin-users-page w100 white-layered-block">
                <div className="options-description mb-3">
                    <FormattedMessage id="admin_users_desc" />
                </div>
                {renderFilterBar}
                <Tabs
                    type="card"
                    activeKey={tab}
                    defaultActiveKey={tab}
                    className="w100"
                    onChange={onChangeTab}
                    style={{ marginTop: 14 }}
                >
                    {tabs?.map((tab: any, index: number) => (
                        <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                            <tab.tab props={index} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
                <EditDrawer />
            </div>
        </Wrapper>
    );
}

export default App;
