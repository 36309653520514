import React from "react";
import { SwitchDesc } from "./switch-desc";

interface PosterSwitchProps {
    openPosterStyle: boolean;
    setPosterStyle: (openPosterStyle: boolean) => void;
    switchOnChange: (value: any, name: any, local: any) => void;
}

export const PosterSwitch = (props: PosterSwitchProps): JSX.Element => {
    const { openPosterStyle, setPosterStyle, switchOnChange } = props;

    return (
        <div>
            <SwitchDesc
                description={"store_poster"}
                value={openPosterStyle}
                name=""
                local={setPosterStyle}
                switchOnChange={switchOnChange}
            />
        </div>
    );
};
