enum qrStyles {
    DOTS = "dots",
    SQUARES = "squares",
}

enum paperSizes {
    A4 = "A4",
    A3 = "A3",
    LEGAL = "LEGAL",
}

enum bgColours {
    BLUE = "#2B7CB4",
    GREEN = "#2BB46B",
    PURPLE = "#AD2DD3",
    RED = "#D32D49",
    ORANGE = "#D3972D",
    TURQUOISE = "#20928A",
}

enum codeTid {
    TABLE = 1,
    PARKING_LOT = 2,
    KIOSK_ORDER = 3,
}

export default {
    qrStyles,
    paperSizes,
    bgColours,
    codeTid,
};
