import { api } from "../util/api";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";

export const runImageCacheHelper = async (params: any = {}): Promise<any> => {
    try {
        const body = new FormData();

        Object.keys(params).forEach((key) => {
            body.append(key, params[key]);
        });

        const response: any = await api("IMAGE_CACHE")({
            method: "POST",
            body,
        });

        if (response?.records?.error && !response?.records?.error.includes("not exists")) {
            toast(createMessage(response?.records?.error, HiInformationCircle));
        }
        return response?.records?.images ?? [];
    } catch (e) {
        // Handle error
    }
};
