import React from "react";
import { View, Text } from "@react-pdf/renderer";

const customOrderLabel = (styles: React.CSSProperties | any, props: { customOrderLabel: string }): JSX.Element => {
    return (
        <View style={styles.customOrderLabel}>
            <Text>{props.customOrderLabel}</Text>
        </View>
    );
};
export default customOrderLabel;
