import React, { useEffect } from "react";
import { Button, Modal, Switch } from "antd";
import _ from "lodash";
import { BiFoodMenu } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { getStoreAvailability } from "./../../../../services/store-availability";
import { getMenuDetails, updateMenuDetails } from "../../../../services/menu";
import { setModal } from "../../../../slices/menu";
import { getStoreDetails } from "../../../../services/store";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import helper, { isBool } from "../../../../util/helper";
import MenuModal from "./_components/menu-modal";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/menu", breadcrumbName: "menu_setting" },
    ],
};

function emptyFunction() {
    // does nothing
}

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const store = useSelector((state: RootState) => state.store);
    const menus = useSelector((state: RootState) => state.menu?.menus);
    const times = useSelector((state: RootState) => state.storeAvailability?.timeSettings);
    const modal = useSelector((state: RootState) => state.menu?.modalOpen);
    const currMenu = useSelector((state: RootState) => state.menu?.currMenu);
    const { lan, getLanguageSelect } = useLanguageSelect();
    const widths = ["25%", "45%", "30%"];
    const cols = ["name", "time", "actions"];

    useEffect(() => {
        dispatch(getStoreDetails());
        dispatch(getMenuDetails());
        dispatch(getStoreAvailability());
    }, [dispatch]);

    const getTime = (code: string) => {
        return !_.isEmpty(times) && Array.isArray(times)
            ? helper.formatDayAndHours(times.find((time: any) => String(time.code) === code))
            : "";
    };

    const deleteMenu = (id: number) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "delete_menu" }),
            content: intl.formatMessage({ id: "are_you_sure" }),
            onOk() {
                dispatch(updateMenuDetails({ action: helper.MENU_ACTION.CONFIRM, id }));
            },
            okType: "danger",
        });
    };

    const getEnableSwitch = (title: string, name: string, value: number, onChange = emptyFunction) => (
        <div className="switch-box d-flex align-items-center mr-5 justify-content-start">
            <div className="mr-2 ml-3 menu-switch">
                <FormattedMessage id={title} />
            </div>
            <Switch checked={isBool(value)} onChange={onChange} />
        </div>
    );

    const renderMenuRow = (menu: any, key: number) => {
        return (
            <div className="menu-table-row-container" key={key}>
                <div
                    className="clickable-row"
                    onClick={() => dispatch(setModal({ open: true, edit: true, menu }))}
                ></div>
                <div className="menu-table-col" style={{ width: widths[0] }}>
                    {_.get(menu, `names.${lan}`, " ")}
                </div>
                <div
                    className="menu-table-col"
                    style={{ width: widths[1] }}
                    dangerouslySetInnerHTML={{ __html: getTime(menu.time) }}
                ></div>
                <div className="menu-table-col" style={{ width: widths[2] }}>
                    <Button
                        type="default"
                        className="text-on-white-background"
                        style={{ marginRight: "8px", borderRadius: "3px" }}
                        onClick={() => dispatch(setModal({ open: true, edit: true, menu }))}
                    >
                        <FormattedMessage id="view_edit" />
                    </Button>
                    <Button
                        type="default"
                        className="text-on-white-background"
                        style={{ borderRadius: "3px" }}
                        onClick={() => deleteMenu(menu.id)}
                    >
                        <FormattedMessage id="delete" />
                    </Button>

                    {getEnableSwitch(
                        "enabled",
                        "is_available",
                        _.get(menu, "is_available", 0),
                        // @ts-ignore
                        (is_available: any) => {
                            const { ctids, id, names, sflg, time } = menu;
                            dispatch(
                                updateMenuDetails({
                                    action: helper.MENU_ACTION.SET,
                                    ctids,
                                    id,
                                    is_available,
                                    names,
                                    sflg,
                                    time,
                                })
                            );
                        }
                    )}
                </div>
            </div>
        );
    };

    const renderTableHeader = (
        <div className="menu-header">
            {widths.map((col, index) => (
                <div key={`header-text-key-${index}`} className="header-text" style={{ width: col }}>
                    <FormattedMessage id={cols[index]} />
                </div>
            ))}
        </div>
    );

    const renderNoMenus = (
        <div className="menu-table-row-container">
            <div className="inactive-empty-warn w100">
                <BiFoodMenu size="60" color="lightgrey" className="mb-2" />
                <FormattedMessage id="no_menus" />
            </div>
        </div>
    );

    const renderMenuTable = (
        <div className="menuTable-container">
            {renderTableHeader}
            {Array.isArray(menus) && menus.length > 0
                ? menus.map((menu: any, key: number) => renderMenuRow(menu, key))
                : renderNoMenus}
        </div>
    );

    const renderCreateButton = (
        <div className="add-button-container">
            <Button
                type="default"
                className="text-on-white-background"
                style={{ borderRadius: "3px" }}
                onClick={() => dispatch(setModal({ open: true, edit: false }))}
            >
                <FormattedMessage id="add_new" />
            </Button>
        </div>
    );

    const renderMenuArea = (
        <div className="white-layered-block menuSetting-page">
            <div className="options-description mb-3">
                <FormattedMessage id="menu_desc" />
            </div>
            {renderMenuTable}
            {renderCreateButton}
        </div>
    );

    const renderMenuModal = () => {
        const title = !_.isEmpty(currMenu) ? "edit_menu" : "create_menu";
        return (
            <Modal
                visible={modal}
                title={intl.formatMessage({ id: title })}
                width="600px"
                footer={null}
                onCancel={() => dispatch(setModal({ open: false, edit: false }))}
            >
                <MenuModal />
            </Modal>
        );
    };

    return !_.isEmpty(store) && !_.isEmpty(times) ? (
        <Wrapper helmet={{ title: "menu_setting" }} breadcrumb={breadcrumb}>
            <div className="setting-actions">{getLanguageSelect()}</div>
            {renderMenuModal()}
            {renderMenuArea}
        </Wrapper>
    ) : null;
}

export default App;
