import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "antd";
import CustomerForm from "../../../customers/_components/new-customer-page/customer-form";
import { getCustomer } from "../../../../services/customers";
import { initialCustomerRelatedState, setState } from "../../../../slices/create-order";

class AddCustomerDialog extends Component<any, any> {
    state = { submitTrigger: true };

    showShow = () => this.props.parentState?.showAddCustomer;

    onCancel = () =>
        this.props.setParentState({
            showAddCustomer: false,
        });

    getFooter = () => {
        return (
            <Button form="new-customer-form" type="primary" htmlType="submit">
                <FormattedMessage id="submit" />
            </Button>
        )
    }

    render() {
        return (
            <Modal
                bodyStyle={{ maxHeight: "50vh", overflowX: "hidden", overflowY: "scroll" }}
                onCancel={() => this.onCancel()}
                visible={this.showShow()}
                title={<FormattedMessage id="customer_info" />}
                footer={this.getFooter()}
            >
                <div className="add-customer-dialog">
                    <CustomerForm
                        withoutInfoTitle={true}
                        style={{ padding: 0 }}
                        colLayout={{
                            span: 24,
                        }}
                        submitTrigger={this.state.submitTrigger}
                        seperateSubmit={true}
                        onFinish={(id: any) => {
                            this.props.getCustomer({
                                customer_id: id,
                            });
                            this.props.setState(initialCustomerRelatedState);
                        }}
                    />
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    getCustomer,
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddCustomerDialog)));
