export const ALL_TYPES = -1;
export const ALL_STATUS = 0;
export const STATUS_ENABLED = 1;
export const STATUS_DISABLED = 2;
export const ALWAYS_AVAILABLE = 1;

export enum ProductTypes {
    NORMAL_PRODUCT = 0,
    GIFT_CARD = 1,
    SELF_INPUT = 2,
    CALL_VENDOR = 3,
    COUPON = 4,
    GROUP_PRODUCT = 6,
}

export const MAX_LEN_NAME = 100;
export const MAX_LEN_SHORT_DESC = 160;

export const MIN_BARCODE_LEN = 4;
export const MAX_BARCODE_LEN = 15;

export const NAME_PREFIX = "name_";
export const DESCRIPTION_PREFIX = "description_";
export const SHORT_DESCRIPTION_PREFIX = "short_description_";

export const JODIT_EDITOR_PROPS = {
    containerWidth: Infinity,
    containerHeight: 200,
    maxContraints: [Infinity, Infinity],
    minContraints: [470, 200],
    options: {
        buttons: [
            "source",
            "|",
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "|",
            "ul",
            "ol",
            "|",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "paragraph",
            "|",
            "table",
            "link",
            "|",
            "align",
            "|",
            "hr",
            "eraser",
            "|",
            "symbol",
        ],
        readonly: false,
        //@ts-ignore
        toolbarAdaptive: false,
        height: 200,
        allowResizeY: true,
        allowResizeX: false,
    },
};
