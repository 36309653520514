import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { setState } from "../../../../../slices/create-order";
import _ from "lodash";
import { Row, Col, Dropdown, Menu } from "antd";
import { AiOutlineDown } from "react-icons/ai";
import actions from "../../../../../slices/live-order";
import { IconContext } from "react-icons/lib";
import SearchRow from "./search-row";

class Title extends Component<any, any> {
    state = { opts: [] };

    renderStartSections = () => {
        return <div />;
    };

    renderCenterSections = () => {
        return (
            <div className="add-item-title-end">
                <FormattedMessage id="add_items" />
            </div>
        );
    };

    renderEndSection = () => {
        const lans = Array.isArray(this.props.lans) ? this.props.lans : [];

        const menu = () => {
            return (
                <Menu>
                    {lans.map((lan: any) => (
                        <Menu.Item
                            key={lan}
                            onClick={() => {
                                if (this.props.searchCondition.reset) {
                                    const conditions = _.cloneDeep(this.props.searchCondition);
                                    conditions.gid = this.props.store?.g_id;
                                    conditions.cids = this.props.currentCat;
                                    conditions.lan = lan;
                                    conditions.reset = true;
                                    conditions.cachedProducts = this.props.cachedProducts;
                                    this.props.getProducts(conditions);
                                    this.props.setState({ lan });
                                } else {
                                    this.props.setState({ lan, shouldReloadProducts: true });
                                }
                            }}
                        >
                            <FormattedMessage id={lan || " "} />
                        </Menu.Item>
                    ))}
                </Menu>
            );
        };

        return (
            <Dropdown className="add-item-title-end" overlay={menu()} placement="bottom" arrow>
                <div className="add-item-title-end-text">
                    <FormattedMessage id={this.props.lan || " "} />
                    <IconContext.Provider value={{ className: "lan-dropdown-icon" }}>
                        <AiOutlineDown />
                    </IconContext.Provider>
                </div>
            </Dropdown>
        );
    };

    render() {
        return (
            <Row className="add-item-title" gutter={12}>
                <Col span={18}>
                    <SearchRow {...this.props} />
                </Col>
                <Col span={6}>{this.renderEndSection()}</Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
    lans: _.get(state, "store.storeDisplayOptions.product_display_languages", []),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    store: _.get(state, "store.records", {}), //detail
    cachedProducts: _.get(state, "liveOrders.cachedProducts", {}),
});

const mapDispatchToProps = {
    setState,
    getProducts: actions.getProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Title)));
