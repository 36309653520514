import { Drawer } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import ReferralsFilter from "./referrals-filter";
import qs from "querystring";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setReferralsState } from "../../../../slices/referrals";
import { FormattedMessage } from "react-intl";

function ReferralFilterDrawer(): JSX.Element {
    const location = useLocation();
    const search = qs.parse(location.search.slice(1));
    const dispatch = useDispatch();
    const { filter } = useSelector((state: RootState) => ({
        filter: state?.referrals?.filterDrawer,
    }));

    const setFilter = (value: boolean) => {
        dispatch(
            setReferralsState({
                filterDrawer: value,
            })
        );
    };

    return (
        <Drawer visible={filter} onClose={() => setFilter(false)} title={<FormattedMessage id="filter" />} width={380}>
            <ReferralsFilter search={search} closeModal={() => setFilter(false)} />
        </Drawer>
    );
}

export default ReferralFilterDrawer;
