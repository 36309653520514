import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import "./create-order.scss";
import { withRouter } from "react-router-dom";
import Wrapper from "components/wrapper";
import { Row, Col } from "antd";
import { getCustomers } from "services/customers";
import { setCustomersState } from "slices/customers";
import { getManagedStores } from "services/managed-stores";
import { clearData, updateDisplayPrice } from "slices/create-order";
import OrderDetails from "./page-components/order-detail";
import Customer from "./page-components/customer-info";
import CartAssistant from "./page-components/cart-assistant";
import ShippingMethod from "./page-components/shipping-method";
import CheckoutButton from "./page-components/checkout-button";
import OrderPayment from "./page-components/order-payment";
import { getStoreDetails } from "services/store";
import PriceAssistant from "./page-components/price-assistant";
import { Prompt } from "react-router-dom";
import _ from "lodash";
import { deleteQuotes } from "services/quotes";
import helper from "util/helper";

const breadcrumb = {
    routes: [
        {
            path: "/create_order",
            breadcrumbName: "create_order",
        },
    ],
};

class CreateOrder extends Component<any, any> {
    state = {
        height: window.innerHeight,
    };

    componentDidMount = () => {
        this.initData();
        this.setListeners();
    };

    componentWillUnmount = () => {
        if (this.isQuoteCartEmpty()) {
            this.props.deleteQuotes({ quote_ids: [this.props.state?.qid], lan: this.props.lan });
        }
        this.props.clearData();
        this.props.setCustomersState({ customer: null });
        this.removeListeners();
    };

    getItems = () => {
        return Array.isArray(this.props.state.items) ? this.props.state.items : [];
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    setListeners = () => {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    setHeight = () => {
        const minusRowIds: any = ["checkout-button-container"];
        let result = window.innerHeight - 30;
        minusRowIds.forEach((id: any) => {
            const minus = document.getElementById(id);
            if (minus && minus.offsetHeight) {
                result -= minus.offsetHeight;
            }
        });
        this.setState({ height: result > 0 ? result : "67vw" });
    };

    initData = () => {
        this.props.getCustomers();
        this.props.getStoreDetails();
        this.props.getManagedStores();
    };

    /**
     * Check if the cart is empty and has quote id.
     *
     * @returns true if the cart has the quote id and no items.
     */
    isQuoteCartEmpty = () => {
        return _.isEmpty(this.getItems()) && !this.props.state?.saveButtonClicked && this.props.state?.qid;
    };

    renderPrompt = () => {
        if (!this.props.state?.isSaveButtonEnabled) {
            return null;
        } else {
            return <Prompt message={this.str("alert_leaving_without_save")} />;
        }
    };

    render() {
        return (
            <Wrapper
                withoutHeader={true}
                paddingBottom={0}
                breadcrumb={breadcrumb}
                helmet={{ title: "create_order" }}
                onBack={() => helper.goBackPreviousPage(this.props)}
            >
                <div className="create-order-container" style={{ height: this.state.height }}>
                    <Row style={{ height: "100%", overflowY: "auto" }} gutter={16}>
                        <Col span={14} style={{ height: "100%" }}>
                            <OrderDetails />
                        </Col>
                        <Col span={10}>
                            <ShippingMethod />
                            <Customer />
                            <OrderPayment />
                        </Col>
                    </Row>
                    <CheckoutButton />
                </div>
                <CartAssistant />
                <PriceAssistant />
                {this.renderPrompt()}
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder"),
    lan: state?.setting?.lan,
});

const mapDispatchToProps = {
    getCustomers,
    getStoreDetails,
    getManagedStores,
    deleteQuotes,
    updateDisplayPrice,
    clearData,
    setCustomersState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateOrder)));
