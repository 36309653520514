import { Form, Select, Input, Button } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "app/reducer";
import { setState } from "slices/quotes";
import { getShippingOptions } from "util/helper";
import { QuotesRequestBody } from "../../models/get-quotes-request";

interface QuotesFilterProp extends PropsFromRedux {
    closeDrawer: () => void;
}

interface QuotesFilterStateProps {
    filterOptions: QuotesRequestBody;
    storeRecords: any;
}

const ORDER_TYPE = "order_type";
const MIN_PRICE = "min_price";
const MAX_PRICE = "max_price";
const MIN_ITEM = "min_item";
const MAX_ITEM = "max_item";

const QuotesFilter = (props: QuotesFilterProp) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields([
            {
                name: ORDER_TYPE,
                value: props.filterOptions?.shp_mtd,
            },
            {
                name: MIN_PRICE,
                value: props.filterOptions?.min_total,
            },
            {
                name: MAX_PRICE,
                value: props.filterOptions?.max_total,
            },
            {
                name: MIN_ITEM,
                value: props.filterOptions?.min_item_count,
            },
            {
                name: MAX_ITEM,
                value: props.filterOptions?.max_item_count,
            },
        ]);
    });

    const getAvailableShippingMethods = () => {
        return getShippingOptions(props.storeRecords);
    };

    /**
     * Prepares all the data to send to the api in the filter form by
     * removing empty fields, then updates the filter options, and closes
     * filter drawer.
     *
     * @param data filter form data
     */
    const applyFilters = (data: any) => {
        const cleanedData = _.cloneDeep(data);

        _.each(data, (value, key) => {
            if (value == null || value === "") {
                delete cleanedData[key];
            }
        });

        const filterOptions: QuotesRequestBody = {
            ...props.filterOptions,
            shp_mtd: cleanedData[ORDER_TYPE],
            min_total: cleanedData[MIN_PRICE],
            max_total: cleanedData[MAX_PRICE],
            min_item_count: cleanedData[MIN_ITEM],
            max_item_count: cleanedData[MAX_ITEM],
        };

        props.setState({ filterOptions });
        props.closeDrawer();
    };

    /**
     * Reset filter options that are modifiable in this form, except current page,
     * then close the filter drawer
     */
    const resetFilters = () => {
        form.resetFields();
        props.setState({
            filterOptions: {
                quick_search: props.filterOptions?.quick_search,
                sorta: props.filterOptions?.sorta,
                sortd: props.filterOptions?.sortd,
                limit: props.filterOptions?.limit,
                lan: props.filterOptions?.lan,
            },
        });
        props.closeDrawer();
    };

    return (
        <Form labelCol={{ span: 6 }} form={form} onFinish={(data) => applyFilters(data)}>
            <FormattedMessage id="order_type" />
            <Form.Item name={ORDER_TYPE}>
                <Select options={getAvailableShippingMethods()}></Select>
            </Form.Item>

            <FormattedMessage id="price_range" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name={MIN_PRICE}>
                    <Input placeholder={intl.formatMessage({ id: "price_from" })} />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name={MAX_PRICE}>
                    <Input placeholder={intl.formatMessage({ id: "price_to" })} />
                </Form.Item>
            </div>

            <FormattedMessage id="quantity_range" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name={MIN_ITEM}>
                    <Input placeholder={intl.formatMessage({ id: "quantity_from" })} />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name={MAX_ITEM}>
                    <Input placeholder={intl.formatMessage({ id: "quantity_to" })} />
                </Form.Item>
            </div>
            <div className="footer-filter">
                <Button type="link" onClick={resetFilters}>
                    <FormattedMessage id="reset_filters" />
                </Button>
                <Button type="primary" htmlType="submit" className="save-button-handler">
                    <FormattedMessage id="apply" />
                </Button>
            </div>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => {
    const stateProps: QuotesFilterStateProps = {
        filterOptions: _.get(state, "quotes.filterOptions", {}),
        storeRecords: _.get(state, "store.records", {}),
    };
    return stateProps;
};

const mapDispatchToProps = {
    setState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuotesFilter);
