import React from "react";
import { getFormItem, getTextInput } from "../../../../../../components/form";
import config from "../../../../../../config";
import useLanguageSelect from "../../../../../../hooks/useLanguageSelect";

function ItemSEOTab(): JSX.Element {
    const { lan, getLanguageSelect } = useLanguageSelect();

    const getForm = () => (
        <div style={{ width: "70%" }}>
            {config.LANGUAGES.map((l) => (
                <div key={`meta-title-${l}`} style={{ display: l !== lan ? "none" : "block" }}>
                    {getFormItem(
                        "meta_title",
                        getTextInput({ name: "meta_title_" + l, allowClear: true }),
                        "meta_title_tip"
                    )}
                    {getFormItem(
                        "meta_description",
                        getTextInput({ name: "meta_desc_" + l, rows: 3, allowClear: true }),
                        "meta_description_tip"
                    )}
                    {getFormItem(
                        "meta_keywords",
                        getTextInput({ name: "meta_keywords_" + l, allowClear: true }),
                        "meta_keywords_tip"
                    )}
                </div>
            ))}

            {getFormItem("url_key", getTextInput({ name: "url_key", allowClear: true }))}
        </div>
    );

    return (
        <div className="items-tab">
            {getForm()}

            {getLanguageSelect()}
        </div>
    );
}

export default ItemSEOTab;
