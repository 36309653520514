import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Modal, Button } from "antd";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import "./simplify-modal.scss";

class WarningModal extends Component<any, any> {
    renderTitle = () => {
        const renderIcon = () => (
            <span className="gooptercon gooptercon-exclamation-circle">
                <AiOutlineExclamationCircle role="img" aria-label="exclamation-circle" />
            </span>
        );

        const renderTitle = () => (
            <span className="goopter-modal-confirm-title">
                <FormattedMessage id={this.props.title || "warning"} />
            </span>
        );

        return (
            <div>
                {renderIcon()}
                {renderTitle()}
            </div>
        );
    };

    renderContent = () => {
        return (
            <div className="goopter-modal-confirm-content">
                {this.props.content || <FormattedMessage id={this.props.contentText || " "} />}
            </div>
        );
    };

    renderButtons = () => {
        return (
            <div className="goopter-modal-confirm-btns">
                <Button onClick={() => this.props.onCancel && this.props.onCancel()} className="goopter-btn">
                    <b>
                        <FormattedMessage id="cancel" />
                    </b>
                </Button>
                <Button type="primary" onClick={() => this.props.onOk && this.props.onOk()} className="goopter-btn">
                    <b>
                        <FormattedMessage id="ok" />
                    </b>
                </Button>
            </div>
        );
    };

    render() {
        return (
            <Modal
                width={416}
                maskClosable={false}
                closable={false}
                footer={null}
                title={null}
                visible={this.props.visible}
            >
                <div className="goopter-modal-confirm-body-wrapper">
                    <div className="goopter-modal-confirm-body">
                        {this.renderTitle()}
                        {this.renderContent()}
                    </div>
                    {this.renderButtons()}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WarningModal));
