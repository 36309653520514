import React from "react";
import { injectIntl } from "react-intl";
import { Button } from "antd";
import "./style-option-picker-button-style.scss";

import { FaCheck } from "react-icons/fa";
import { Badge, withStyles } from "@material-ui/core";

const StyledBadge = withStyles(() => ({
    badge: {
        right: 12,
        top: 6,
        backgroundColor: "#ed2d29",
        border: `2px solid white`,
        padding: "8px 2px",
        color: "white",
    },
}))(Badge);

class PickerButton extends React.Component {
    render() {
        const { isSelected, onSelect, children } = this.props;
        return (
            <StyledBadge badgeContent={isSelected ? <FaCheck size={10} /> : 0}>
                <Button
                    style={this.props.style}
                    danger
                    type={isSelected ? "primary" : "default"}
                    onClick={onSelect}
                    className="style-option-picker-button"
                >
                    <div className="style-option-picker-button-inner"> {children}</div>
                </Button>
            </StyledBadge>
        );
    }
}

export default injectIntl(PickerButton);
