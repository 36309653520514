import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Select, Divider } from "antd";
import { GoSearch } from "react-icons/go";
import { MdAddCircle } from "react-icons/md";
import { getCustomerFullName } from "pages/customers/helper";
import helper, { formatPhone } from "util/helper";
import { getCustomers, getCustomer } from "services/customers";
import { setState } from "slices/create-order";
import { CUSTOMER_ROLES } from "pages/customers/constants";

const { Option } = Select;

class CustomerSearch extends Component<any, any> {
    state = {};

    getCustomers = () => {
        return Array.isArray(this.props.customers) ? this.props.customers : [];
    };

    showAddCustomerModal = () => {
        this.props.setParentState({
            showAddCustomer: true,
        });
    };

    renderPlaceHolder = () => (
        <div className="create-order-search-customer-placeholder">
            <GoSearch />
            &nbsp;
            <FormattedMessage id="search_dot" />
        </div>
    );

    renderOption = (customer: any) => {
        const isDefault = customer?.role === CUSTOMER_ROLES.default;

        const firstLine = isDefault ? customer?.firstname : getCustomerFullName(customer);
        const secondLine = isDefault
            ? customer?.lastname
            : formatPhone(customer.phone) || customer.email || <FormattedMessage id="no_phone" />;

        return (
            <Option key={customer.id} value={customer.id}>
                <div>
                    <b> {firstLine}</b>
                </div>
                <div>{secondLine} </div>
            </Option>
        );
    };

    renderDropDown = (menu: any) => (
        <div>
            <div onClick={() => this.showAddCustomerModal()} className="create-order-create-customer-drop-down">
                <MdAddCircle />
                &nbsp;
                <FormattedMessage id="create_a_new_customer" />
            </div>
            <Divider style={{ margin: "4px 0" }} />
            {menu}
        </div>
    );

    renderSelect = () => {
        return (
            <div id="create-order-customer-search-select-area">
                <Select
                    id="create-order-customer-search-select"
                    size="large"
                    showSearch
                    showAction={["click", "focus"]}
                    showArrow={false}
                    onFocus={() => this.props.getCustomers({ quick_search: "" })}
                    onSearch={(value) => {
                        this.props.getCustomers({ quick_search: value });
                    }}
                    onChange={(value) => {
                        this.props.getCustomer({
                            customer_id: value,
                        });
                    }}
                    filterOption={() => true}
                    style={{ width: `100%` }}
                    placeholder={this.renderPlaceHolder()}
                    dropdownRender={(menu) => this.renderDropDown(menu)}
                >
                    {this.getCustomers().map((customer: any) => {
                        // We do not allow default guest customers to be selected on delivery
                        if (
                            helper.isDelivery(this.props.shippingMethod) &&
                            (customer.role === CUSTOMER_ROLES.default || customer.role === CUSTOMER_ROLES.guest)
                        ) {
                            return;
                        }

                        return this.renderOption(customer);
                    })}
                </Select>
            </div>
        );
    };

    render() {
        return <>{this.renderSelect()}</>;
    }
}

const mapStateToProps = (state: any) => ({
    customers: _.get(state, "customers.customers", []),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
});

const mapDispatchToProps = {
    getCustomers,
    getCustomer,
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerSearch)));
