import React from "react";
import GoopterLogo from "../../../../../images/icons/official-goopter-logo.png";
import { View, Image, Text } from "@react-pdf/renderer";
import { GOOPTER_PHONE, GOOPTER_EMAIL } from "../constants";

const BottomInfoBar = (styles: React.CSSProperties | any): JSX.Element => (
    <View style={styles.goopterContainer}>
        <View style={styles.flexRow}>
            <View style={styles.poweredBy}>
                <Text>Powered by </Text>
            </View>
            <View style={styles.goopterLogo}>
                <Image src={GoopterLogo} />
            </View>
        </View>
        <View style={styles.flexRow}>
            <View style={styles.poweredBy}>
                <Text>Support</Text>
            </View>
            <View style={styles.poweredBy}>
                <Text>{GOOPTER_PHONE}</Text>
            </View>
        </View>
        <View style={styles.flexRow}>
            <View style={styles.poweredBy}>
                <Text>{GOOPTER_EMAIL}</Text>
            </View>
        </View>
    </View>
);
export default BottomInfoBar;
