import React, { useEffect, useState } from "react";
import _ from "lodash";
import { RootState } from "../../../../../app/reducer";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, Button, Switch, Modal } from "antd";
import { getCategories } from "./../../../../../services/categories";
import { setCurrMenu, setModal } from "../../../../../slices/menu";
import { updateMenuDetails } from "../../../../../services/menu";
import { useDispatch, useSelector } from "react-redux";
import { getStoreAvailability } from "../../../../../services/store-availability";
import useLanguageSelect from "../../../../../hooks/useLanguageSelect";
import helper, { isBool, isNum } from "../../../../../util/helper";
import config from "config";

const categoriesPageSelector = (state: RootState) => ({ categories: state?.categories?.categories });

function MenuModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currMenu = useSelector((state: RootState) => state.menu?.currMenu);
    const menus = useSelector((state: RootState) => state.menu?.menus);
    const times = useSelector((state: RootState) => state.storeAvailability?.timeSettings);
    const edit = useSelector((state: RootState) => state.menu?.edit);
    const { categories } = useSelector(categoriesPageSelector);
    const { lan, getModalLanguageSelect } = useLanguageSelect();
    const [showTime, setShowTime] = useState<boolean>(false);


    useEffect(() => {
        dispatch(getCategories());
        dispatch(getStoreAvailability());
    }, [dispatch]);

    const setState = (name: string, value: any) => dispatch(setCurrMenu({ name, value }));

    const getUniqueId = () => {
        // Consider fixing this code, not sure why logic is implemented this way
        // eg. what happens, hypothetically, if all 1000 ids are in use? we would have infinite loop
        // even when it's not infinite loop, this method is still inefficient
        let notUnique = true;
        let id: any;
        while (notUnique) {
            id = Math.floor(Math.random() * 1000) + 1;
            notUnique = menus.find((menu: any) => Number(menu.id) === Number(id)) ? true : false;
        }
        return id;
    };

    const allFilled = () => {
        const req = intl.formatMessage({ id: "please_fill_all_fields" });
        if (!currMenu.names) {
            Modal.error({ title: intl.formatMessage({ id: "missing_name" }), content: req });
            return false;
        }
        return true;
    };

    const saveMenu = () => {
        if (allFilled()) {
            const action = edit ? 2 : 1;
            const { ctids, is_available, names, sflg, time } = currMenu;
            let id = currMenu?.id;
            if (!id) {
                id = getUniqueId();
            }
            if (validateForm()) {
                dispatch(updateMenuDetails({ action, ctids, id, is_available, names, sflg, time }));
                dispatch(setModal({ open: false, edit: false }));
            }
        }
    };
    
    const validateForm = () => {
        if (!edit) {
            for (let i = 0; menus.length > i; i++) {
                if (menus[i].names[lan].toLowerCase() === currMenu.names[lan].toLowerCase()) {
                    // check for duplicate names
                    Modal.error({
                        title: intl.formatMessage({ id: "error" }),
                        content: intl.formatMessage({ id: "duplicate_name_msg" }),
                    });
                    return false;
                }
            }
        }
        return true;
    };

    const handleCategoryChange = (value: any, getCategories: any) => {
        const newCategory = value.filter((category: any) => typeof category === "string");
        newCategory.forEach((category: any) => {
            const newCtid = getCategories.find((categories: any) => categories.label === category).value;
            value.splice(value.indexOf(category), 1, newCtid);
        });
        return value;
    };

    const getCategoriesValues = (ctid: any, getCategories: any) => {
        const categories: string[] = [];
        ctid && !_.isEmpty(getCategories)
            ? ctid.forEach((ctid: any) => {
                categories.push(getCategories.find((category: any) => category.value === ctid)?.label);
            })
            : null;
        return categories;
    };

    const getTime = (code: string) => {
        return !_.isEmpty(times) && Array.isArray(times)
            ? helper.formatDayAndHours(times.find((time: any) => String(time.code) === code))
            : " ";
    };

    const getCategoriesAsOptions = () => {
        if (Array.isArray(categories)) {
            return (
                categories
                    .filter((c) => c.level < config.CATEGORY_LEVELS.sub)
                    .map((c) => ({
                        value: c.category_id,
                        label: helper.getTransString(c.name, lan),
                    })) ?? []
            );
        }
        return [];
    };

    const getTimeCodesAsOptions = () => {
        if (Array.isArray(times)) {
            return (
                times.map((t) => ({
                    value: t.code,
                    label: t.name,
                })) ?? []
            );
        }
        return [];
    };

    const renderName = (
        <div className="d-flex flex-column w100">
            <div className="options-sub-header">
                <FormattedMessage id="name" />
            </div>
            <input
                value={_.get(currMenu, `names.${lan}`, "")}
                className="name-input-field"
                placeholder={intl.formatMessage({ id: "type_here" })}
                onChange={(e) => setState(`names.${lan}`, e.target.value)}
            />
        </div>
    );

    const renderShowTime = (
        <div className="showTimes-container" dangerouslySetInnerHTML={{ __html: getTime(currMenu.time) }} />
    );

    const renderShowTimes = (
        <div className="showTimes">
            <div className="showTimes-text options-sub-header pHover" onClick={() => setShowTime(!showTime)}>
                <FormattedMessage id="show_time_table" />
                {!showTime ? "" : renderShowTime}
            </div>
        </div>
    );

    const renderTimeCodeSection = () => {
        const timeCodes = getTimeCodesAsOptions();
        return (
            <React.Fragment>
                <div className="options-sub-header mt-3">
                    <FormattedMessage id="available_time_setting" />
                </div>
                <Select className="menu-select-field" value={currMenu.time ?? []} onChange={(e) => setState("time", e)}>
                    {timeCodes.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
                {!currMenu?.time ? null : renderShowTimes}
            </React.Fragment>
        );
    };

    const renderCategorySelect = () => {
        const getCategories = getCategoriesAsOptions();

        return (
            <React.Fragment>
                <div className="options-sub-header mt-3">
                    <FormattedMessage id="categories" />
                </div>
                <Select
                    mode="multiple"
                    className="multi-menu-input d-flex align-items-center "
                    value={getCategoriesValues(currMenu.ctids, getCategories)}
                    onChange={(e) => setState("ctids", handleCategoryChange(e, getCategories))}
                >
                    {getCategories.map((option: any) => (
                        <Select.Option key={option.value} value={option.label}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </React.Fragment>
        );
    };

    const getSwitch = (title: string, name: string, value: number) => (
        <div className="switch-box d-flex align-items-center mr-5 mt-3 justify-content-start">
            <div className="options-sub-header">
                <FormattedMessage id={title} />
            </div>
            <Switch checked={isBool(value)} onChange={() => setState(name, parseInt(isNum(!isBool(value))))} />
        </div>
    );

    const renderSwitches = (
        <div className="switch-container d-flex align-items-center flex-wrap w100">
            {getSwitch("delivery", "sflg[0]", _.get(currMenu, "sflg[0]", 0))}
            {getSwitch("in_store", "sflg[1]", _.get(currMenu, "sflg[1]", 0))}
            {getSwitch("pickup", "sflg[2]", _.get(currMenu, "sflg[2]", 0))}
            {getSwitch("enabled", "is_available", _.get(currMenu, "is_available", 0))}
        </div>
    );

    const renderActions = (
        <div className="w100 filter-modal-footer d-flex justify-content-between mt-4">
            <Button type="link" onClick={() => dispatch(setModal({ open: false, edit: false }))}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button type="primary" onClick={() => saveMenu()}>
                <FormattedMessage id="save_menu" />
            </Button>
        </div>
    );

    const renderCreateForm = () => (
        <div className="menu-modal d-flex flex-column justify-content-start align-items-start">
            {getModalLanguageSelect(true)}
            {renderName}
            {renderTimeCodeSection()}
            {renderCategorySelect()}
            {renderSwitches}
            {renderActions}
        </div>
    );

    return renderCreateForm();
}

export default MenuModal;
