import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { setState } from "slices/create-order";
import _ from "lodash";
import { Button, Row, Col } from "antd";
import { formatCurrency } from "util/helper";
import { getProductPrice } from "../../../helper";
import cx from "classnames";

class Total extends Component<any, any> {
    getItems = (): any[] => (Array.isArray(this.props.items) ? this.props.items : []);

    getTotal = (): number => {
        return this.getItems().reduce((acc, item: any) => {
            acc += Number(item.qty) || 0;
            return acc;
        }, 0);
    };

    getAmountTotal = (): number => {
        return this.getItems().reduce((acc, item: any) => {
            //get product price
            const signlePrice = getProductPrice(item);
            const qty = Number(item?.qty) || 1;
            acc += signlePrice * qty;
            return acc;
        }, 0);
    };

    showItemSummaryModal = () => {
        this.props.setState({ showCart: true });
    };

    renderTotalInfo = () => {
        return (
            <div className="quick-add-total-info" onClick={this.showItemSummaryModal}>
                <div className="quick-add-total-info-col1">
                    <div>
                        {this.getTotal()} <FormattedMessage id="item_s" />
                    </div>
                    <div>{formatCurrency(this.getAmountTotal(), this.props.storeCurrency)}</div>
                </div>
                <div className="quick-add-total-info-col2">View cart</div>
            </div>
        );
    };

    renderCancelButton = () => {
        return (
            <Button
                type="default"
                size="large"
                danger
                className={cx({
                    "quick-add-cancel-button": true,
                    "quick-add-button": true,
                })}
                onClick={() => this.props.closeModal()}
            >
                <FormattedMessage id="cancel" />
            </Button>
        );
    };

    renderConfirmButton = () => {
        return (
            <Button
                className={cx({
                    "quick-add-confirm-button": true,
                    "quick-add-button": true,
                })}
                type="default"
                size="large"
                onClick={() => {
                    this.props.setState({
                        items: _.cloneDeep(this.props.items),
                    });
                    this.props.closeModal();
                }}
            >
                <FormattedMessage id="continue" />
            </Button>
        );
    };

    render() {
        return (
            <Row gutter={16}>
                <Col span={6}>{this.renderTotalInfo()}</Col>
                <Col span={2}></Col>
                <Col span={4}>{this.renderCancelButton()}</Col>
                <Col span={4}>{this.renderConfirmButton()}</Col>
                <Col span={8}></Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD")
});

const mapDispatchToProps = {
    setState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Total)));
