import React from "react";
import scanOrderIcons from "../_components/scan-payment-goopterlogo-comp";
import QR from "../_components/qr-comp";
import storeInfo from "../_components/storeInfo";
import tableNum from "../_components/tablenum-comp";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import "../serve-print.scss";

const STORE_NAME_HEIGHT = "1.75cm";
const URL_HEIGHT = "0.5cm";
const PHONE_HEIGHT = "0.5cm";
const TABLE_NUMBER_HEIGHT = "2cm";
const TABLE_NUMBER_RADIUS = "1cm";
const QR_HEIGHT = "3.5cm";
const SCAN_ORDER_HEIGHT = "0.75cm";
const PAYMENT_HEIGHT = "0.5cm";
const GOOPTER_HEIGHT = "0.5cm";
const STORE_NAME_MAX_LINES = 3;

interface Format4Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    customdark: boolean | any;
    dataURLS: string | any;
    paperSize: string | any;
    dark: boolean | any;
    storeName: string;
    storeURL: string | any;
    phone: string | number;
    storeLogo: string | any;
}

function Format4(props: Format4Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#ffffff",
            padding: `${props.bleedingMargin}mm`,
        },
        qrPrintPdfContainer: {
            position: "relative",
            width: "5.06cm",
            height: "11cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            marginBottom: `${props.bleedingMargin ?? 0}mm`,
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: props.dark ? "#504F4F" : props.bgColor ?? "#20817b",
            borderRadius: "10pt",
            fontSize: "16pt",
        },
        qrCodeCardBlock: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
        },
        tableNumHolder: {
            display: "flex",
            height: TABLE_NUMBER_HEIGHT,
            width: TABLE_NUMBER_HEIGHT,
            borderRadius: TABLE_NUMBER_RADIUS,
            backgroundColor: props.dark ? "#504F4F" : "white",
            justifyContent: "center",
            alignItems: "center",
            color: props.dark ? "white" : "red",
            fontSize: "24pt",
            fontWeight: 700,
            opacity: 0.85,
        },
        qrHolder: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: QR_HEIGHT,
            width: QR_HEIGHT,
            borderRadius: "4pt",
            overflow: "hidden",
        },
        qrCodeTextCont: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            height: SCAN_ORDER_HEIGHT,
            fontWeight: "bold",
            fontSize: "12pt",
            color: props.dark ? "white" : "red",
            backgroundColor: props.dark ? "#504F4F" : "white",
            opacity: 0.85,
            borderRadius: "4pt",
            padding: "0pt 4pt",
        },
        paymentContainer: {
            width: "70%",
            height: PAYMENT_HEIGHT,
            padding: "0pt 12pt",
        },
        goopterContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: GOOPTER_HEIGHT,
            backgroundColor: props.dark ? "#504F4F" : "white",
            borderRadius: "4pt",
            opacity: 0.85,
            padding: "0pt 4pt",
        },
        poweredBy: {
            display: "flex",
            fontSize: "7pt",
            fontWeight: 600,
            color: props.dark ? "white" : "#626161",
        },
        goopterLogo: {
            width: "1cm",
            height: " 7pt",
            marginTop: "2px",
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        storeName: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            height: "auto",
            textTransform: "uppercase",
            fontWeight: "extrabold",
            fontSize: "14pt",
            color: "white",
            textAlign: "center",
            padding: "0pt 12pt",
            maxHeight: STORE_NAME_HEIGHT,
            maxLines: STORE_NAME_MAX_LINES,
        },
        websiteURL: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            color: "white",
            fontWeight: "bold",
            fontSize: "9pt",
            height: URL_HEIGHT,
            textAlign: "center",
            padding: "0pt 2pt",
            textOverflow: "ellipsis",
        },
        phone: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            color: "white",
            fontWeight: 500,
            fontSize: "10pt",
            textAlign: "center",
            height: PHONE_HEIGHT,
            padding: "0pt 12pt",
        },
        logo: {
            position: "absolute",
            width: "24pt",
            height: "24pt",
        },
        spacing_01_cm: {
            height: "0.1cm",
        },
        payment_spacing: {
            height: "0.1cm",
        },
    });

    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() =>
                        props.dataURLS?.map((url: any, index: number) => (
                            <View
                                key={`qrPrint-pdf-container-${index}`}
                                style={styles.qrPrintPdfContainer}
                                wrap={false}
                            >
                                <View style={styles.qrCodeCardBlock}>
                                    {storeInfo(styles, props)}
                                    {tableNum(styles, url)}
                                    <View style={styles.spacing_01_cm} />
                                    {QR(styles, url, props)}
                                    <View style={styles.spacing_01_cm} />
                                    {scanOrderIcons(styles)}
                                </View>
                            </View>
                        ))
                    }
                />
            </Page>
        </Document>
    );
}
export default Format4;
