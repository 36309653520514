import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./../../../../app/reducer";
import { useHistory, useLocation } from "react-router-dom";
import Wrapper from "../../../../components/wrapper";
import qs from "querystring";
import { isBool } from "../../../../util/helper";
import PickupTab from "./_components/pickup-tab";
import DeliveryTab from "./_components/delivery-tab";
import ThirdPartyDelivery from "./_components/local-delivery-tab";
import { getDeliveryZoneTimeSlots } from "../../../../services/pickup-delivery";
import { getStoreDetails } from "../../../../services/store";
import _ from "lodash";
import config from "config";
import { PREFERRED_DELIVERY_METHOD_VALUES } from "./constants";
import { getValueOfStoreFlag } from "../../../../util/helper/index";
const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/pickupDelivery", breadcrumbName: "pickup_delivery" },
    ],
};

export enum PickupDeliveryTabs {
    Pickup = "pickup",
    Delivery = "delivery",
    ThirdPartyDelivery = "third_party_local_delivery_service",
}

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const pickupDelivery = useSelector((state: RootState) => state.store?.storePickupDelivery);
    const allowDelivery = useSelector((state: RootState) => state.store?.storePickupDelivery?.allow_delivery);
    const store = useSelector((state: RootState) => state.store);
    const tab = String(qs.parse(location.search)?.["?tab"] ?? PickupDeliveryTabs.Pickup);
    const onChangeTab = (tab: string) => {
        history.push("/settings/pickupDelivery?tab=" + tab);
    };
    const showThirdPartyDeliveryTab = () => {
        const thirdPartyDeliveryOn = 1;
        return (
            (_.get(pickupDelivery, "preferred_delivery_method") ===
                PREFERRED_DELIVERY_METHOD_VALUES.third_party_local &&
                isBool(allowDelivery)) ||
            getValueOfStoreFlag(store, config.STORE_FLAG_INDEX_MAP.enable_3rdparty_local_delivery) ==
                thirdPartyDeliveryOn
        );
    };
    const getTabs = () => {
        const tabs = [
            { key: PickupDeliveryTabs.Pickup, tab: PickupTab },
            { key: PickupDeliveryTabs.Delivery, tab: DeliveryTab },
        ];

        if (showThirdPartyDeliveryTab()) {
            tabs.push({ key: PickupDeliveryTabs.ThirdPartyDelivery, tab: ThirdPartyDelivery });
        }
        return tabs;
    };

    useEffect(() => {
        dispatch(getDeliveryZoneTimeSlots());
        dispatch(getStoreDetails());
    }, [dispatch]);

    return (
        <Wrapper helmet={{ title: "pickup_delivery" }} breadcrumb={breadcrumb}>
            <div className="settings-pickup-page">
                <Tabs
                    type="card"
                    activeKey={tab}
                    defaultActiveKey={tab}
                    onChange={onChangeTab}
                    style={{ marginTop: 14 }}
                >
                    {getTabs().map((tab: any) => (
                        <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                            <tab.tab />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </Wrapper>
    );
}

export default App;
