import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import "./quick-add-modal.scss";
import "app/styles/mixins/_caret.scss";
import { setState } from "slices/create-order";
import _ from "lodash";
import { Modal } from "antd";
import actions from "slices/live-order";
import Categories from "./categories";
import Products from "./products";
import Total from "./total";
import { getDefaultOpts } from "pages/order-history/helper";
import CategoriesDrawer from "./categories-drawer";
import { getMenuAvailableCategories } from "pages/create-order/helper";
import { DESKTOP_WIDTH } from "pages/settings/constants";
import { IoMdArrowDropup, IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import Title from "./title";
import ShoppingCartModal from "./shopping-cart-modal";
import { getStoreWithGid } from "pages/liveorder/helper";
import OptionItemsModal from "pages/liveorder/_components/order-items/item-edit-dialog/item-detail-editor-dialog-main";
import config from "config";

const MODAL_MAX_WIDTH = 900;
const PRODUCT_CONTAINER_ID = "products-container";
const SCROLL_STEP = 70;

class QuickAddModal extends Component<any, any> {
    initialState = () => ({
        width: window.innerWidth * 0.9 > MODAL_MAX_WIDTH ? MODAL_MAX_WIDTH : window.innerWidth * 0.9,
        height: window.innerHeight * 0.9,
        loadAll: false, //if load all cids products when menu is loaded
        itemsErrors: [],
    });

    state: any = this.initialState();

    componentDidMount = () => {
        this.setListeners();
    };

    componentWillUnmount = () => {
        this.removeListeners();
    };

    getMenues = () => {
        const menus = _.isObject(_.get(this.props, "store.menus")) ? _.get(this.props, "store.menus") : {};
        const menuesValues = Object.values(menus);
        //return those with categories only
        return menuesValues.filter((menu) => !_.isEmpty(_.get(menu, "ctids")));
    };

    getCategories = () => {
        //find the current menu
        const menu = this.getMenues().find((menu: any) => this.getMenu() === menu.id);
        const matchedStore = getStoreWithGid(this.props.store?.g_id, this.props.storesData);
        const uncategorizedItems = { id: config.CATEGORY_ID_SEARCH_OPTIONS.uncategorized_products, is_hidden: 0 };
        const categories = getMenuAvailableCategories(menu, _.get(matchedStore, "cat_list_all"));
        return !_.isEmpty(categories) ? [..._.cloneDeep(categories), uncategorizedItems] : [];
    };

    getMenu = () => {
        return this.state?.menuId || _.get(this.getMenues(), "0.id");
    };

    loadAll = () => this.state?.loadAll;

    getCurrentCategory = () => {
        const activeCat = this.getCategories().find((cat: any) => this.state?.catId === cat.id);
        return activeCat ? this.state?.catId : _.get(this.getCategories(), "0.id");
    };

    getItems = () => (Array.isArray(this.state.items) ? this.state.items : this.props.items);

    hasOptions = () => {
        if (this.state.product) {
            if (this.state.product.opt) {
                return this.state.product.opt.length > 0;
            }
        }

        return false;
    };

    getProductOptions = () =>
        _.isEmpty(this.state.opts) ? getDefaultOpts(this.state.product, this.props.lan) : this.state.opts;

    getSharedProps = () => ({
        parentState: this.state,
        setParentState: (values: any) => this.setState(values),
        menus: this.getMenues(),
        categories: this.getCategories(),
        menuId: this.getMenu(),
        loadAll: this.loadAll(),
        currentCat: this.getCurrentCategory(),
        items: this.getItems(),
        product: this.state.product,
        opts: this.getProductOptions(),
        qty: this.state.qty || 1,
        closeModal: () => this.closeModal(),
        itemsErrors: this.state.itemsErrors,
        lan: this.props.lan,
    });

    setListeners = () => {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    setHeight = () => {
        const width = window.innerWidth * 0.9 > MODAL_MAX_WIDTH ? MODAL_MAX_WIDTH : window.innerWidth * 0.9;
        this.setState({ height: window.innerHeight * 0.9, width });
    };

    closeModal = () => {
        this.props.setState({
            quickAddModal: false,
        });
        let updateStateObject: any = {};
        Object.keys(this.state).forEach((key) => (updateStateObject[key] = null));
        updateStateObject = Object.assign({}, updateStateObject, this.initialState());
        this.setState(updateStateObject);
    };

    renderUpScroller = () => {
        return (
            <div className="spacing">
                <div className="product-up-scroller">
                    <div
                        className="icon-container"
                        onClick={() => {
                            const container = document.getElementById(PRODUCT_CONTAINER_ID);
                            if (container) container.scrollTop -= SCROLL_STEP;
                        }}
                    >
                        <IconContext.Provider value={{ color: "red", size: "3em" }}>
                            <IoMdArrowDropup />
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        );
    };

    renderDownScroller = () => {
        return (
            <div className="spacing">
                <div className="product-down-scroller">
                    <div
                        className="icon-container"
                        onClick={() => {
                            const container = document.getElementById(PRODUCT_CONTAINER_ID);
                            if (container) container.scrollTop += SCROLL_STEP;
                        }}
                    >
                        <IconContext.Provider value={{ color: "red", size: "3em" }}>
                            <IoMdArrowDropdown />
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
        );
    };

    renderCloseIcon = () => {
        return (
            <div className="quick-add-close-icon-container">
                <IconContext.Provider value={{ size: "1.5em" }}>
                    <IoMdClose className="quick-add-close-icon" />
                </IconContext.Provider>
            </div>
        );
    };

    render() {
        return (
            <Modal
                maskClosable={false}
                width={window.innerWidth < DESKTOP_WIDTH ? this.state.width : DESKTOP_WIDTH}
                onCancel={() => this.closeModal()}
                style={{
                    top: 20,
                }}
                bodyStyle={{
                    maxHeight: this.state.height,
                    overflowX: "hidden",
                    overflowY: "auto",
                    padding: 8,
                }}
                title={null}
                footer={null}
                visible={this.props.state?.quickAddModal}
                closeIcon={this.renderCloseIcon()}
            >
                <div className="quick-add-modal">
                    <Title {...this.getSharedProps()} />
                    <Categories {...this.getSharedProps()} />
                    <div id="quick-add-modal-categoreis-product-divider" className="divider" />
                    {this.renderUpScroller()}
                    <Products {...this.getSharedProps()} />
                    {this.renderDownScroller()}
                    <div className="divider" />
                    <Total {...this.getSharedProps()} />
                    <CategoriesDrawer {...this.getSharedProps()} />
                    {this.props.state.showCart ? <ShoppingCartModal {...this.getSharedProps()} /> : null}
                    {this.props.state.quickAddModal ? (
                        <OptionItemsModal
                            id="option-items-modal"
                            state={this.props.state}
                            orderStore={this.props.store}
                            setState={(values: any) => this.props.setState(values)}
                            {...this.getSharedProps()}
                        />
                    ) : null}
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    store: _.get(state, "store.records", {}), //detail
    storesData: _.get(state, "managedStores.activeStores", []), //managed stores
    items: _.get(state, "createOrder.items", []),
    lan: _.get(state, "createOrder.lan") || _.get(state, "setting.lan", "en"),
});

const mapDispatchToProps = {
    setState,
    getProducts: actions.getProducts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(QuickAddModal)));
