import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Drawer, Input, Pagination, PaginationProps, Select, Spin } from "antd";
import { DEFAULT_PAGE_SIZE } from "app/constants";
import _, { isString } from "lodash";
import qs from "querystring";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BiSliderAlt } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../app/reducer";
import PrintLayoutSwitch from "../../components/print-layout-switch";
import RefreshButton from "../../components/refresh";
import useModal from "../../hooks/useModal";
import { getCategories } from "../../services/categories";
import { getProduct, getProducts } from "../../services/products";
import { getOptions } from "../../util/helper";
import { ALL_TYPES } from "./constants";
import "./index.scss";
import useProductListDuplicate from "./pages/addEditProduct/useProductListDuplicate";
import useProductsBulkEdit from "./useProductBulkEdit";
import useProductsComparator from "./useProductsComparator";
import BulkEdit from "./_components/bulk-edit";
import FilterTags from "./_components/filter-tags";
import ProductFilter from "./_components/product-filter";
import ActionsRenderer from "./_components/table-components/actions-renderer";
import AvailabilityRenderer from "./_components/table-components/availability-renderer";
import CategoriesRenderer from "./_components/table-components/categories-renderer";
import EnabledRenderer from "./_components/table-components/enabled-renderer";
import ImageRenderer from "./_components/table-components/image-renderer";
import InputRenderer from "./_components/table-components/input-renderer";
import NameRenderer from "./_components/table-components/name-renderer";
import NoRowsRender from "./_components/table-components/no-rows";
import TableLoadingRender from "./_components/table-components/table-loading";
import TypeRenderer from "./_components/table-components/type-renderer";

const { Option } = Select;

let searchNameTimeOut: any;
let gridApi: any;

const DEBOUNCE_TIME = 500;

const COLUMN_WIDTHS = {
    IMAGE_MIN: 115,
    IMAGE_MAX: 115,
    NAME_MIN: 120,
    TYPE_MIN: 80,
    SKU_MIN: 80,
    CATEGORIES_MIN: 120,
    STOCK_MIN: 40,
    PRICE_MIN: 40,
    AVAILABILITY_MIN: 80,
    ENABLED_MIN: 62,
    ENABLED_MAX: 70,
    ACTIONS_MIN: 90,
    ACTIONS_MAX: 90,
};

function useProductsPage(): any {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const intl = useIntl();
    const tableRef = useRef<any>(null);
    const [selected, setSelected] = useState<any>([]);
    const [filter, setFilter] = useState(false);
    const [table, setTable] = useState(true);
    const [isPrintLayout, setIsPrintLayout] = useState(false);
    const agGridRef = useRef<any>(null);
    const { closeModal, getModal } = useModal("filter-modal");
    const {
        openModal: openImageModal,
        closeModal: closeImageModal,
        getModal: getImageModal,
    } = useModal("item-images-modal");
    const {
        openModal: openDescriptionModal,
        closeModal: closeDescriptionModal,
        getModal: getDescriptionModal,
    } = useModal("description-modal");
    const {
        openModal: openShortDescriptionModal,
        closeModal: closeShortDescriptionModal,
        getModal: getShortDescriptionModal,
    } = useModal("description-modal");
    const {
        openModal: openQuickAddModal,
        closeModal: closeQuickAddModal,
        getModal: getQuickAddModal,
    } = useModal("description-modal");
    const { products, lan, total, categories, loading, navigationWidth, limit, storeCurrency } = useSelector(
        (state: RootState) => ({
            categories: state?.categories?.categories,
            products: state?.products?.products,
            total: state?.products?.totalProducts,
            lan: state?.setting?.lan,
            type: state?.store?.storeInformation?.store_categories,
            loading: state?.products?.loading,
            navigationWidth: state?.ui?.navigationWidth,
            page: state?.products?.lastSearch?.page,
            limit: state?.products?.lastSearch?.limit,
            storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
        })
    );

    const { getBulkEdit, getBulkEditModal, onCloseBulkEditModal, bulkEdit } = useProductsBulkEdit(selected);
    const { productNameComparator, productPriceComparator, productStockComparator, productSkuComparator } =
        useProductsComparator();

    const { getDuplicateModal } = useProductListDuplicate();
    const [searchName, setSearchName] = useState<string>("");
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);

    const search = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);

    const onSearch = (e: any) => {
        const value = e?.target?.value;
        setSearchName(value);
    };

    /**
     * Resize the width of the columns to fill the table when the data is first rendered
     *
     * @param params - AgGrid params
     */
    const onFirstDataRendered = (params: any) => {
        params.api.sizeColumnsToFit();
    };

    /**
     * When the table size is changed, resize the columns to fill the table
     * If the table size is changed such that the minimum width of each column
     * exceeds the table size, add a horizontal scroll bar to the table.
     *
     * @param params - AgGrid params
     */
    const onGridSizeChanged = (params: any) => {
        const gridWidth = document.getElementById("products-table-container")?.offsetWidth;
        if (!gridWidth) {
            return;
        }
        const columnsToShow = [];
        const columnsToHide = [];
        let totalColsWidth = 0;
        const allColumns = params.columnApi.getAllColumns();
        for (let i = 0; i < allColumns.length; i++) {
            const column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }

        if (_.isEmpty(columnsToHide)) {
            params.api.sizeColumnsToFit();
        }
    };

    useEffect(() => {
        if (search?.product_name && isString(search.product_name)) {
            setSearchName(search?.product_name ?? "");
        }
    }, [search]);

    useEffect(() => {
        clearTimeout(searchNameTimeOut);

        if (isPageLoaded && search?.product_name !== searchName) {
            searchNameTimeOut = setTimeout(() => {
                const query: any = { ...search };
                if (query.page) {
                    delete query.page;
                }
                if (searchName) {
                    query.product_name = searchName;
                } else if (query.product_name) {
                    delete query.product_name;
                }
                history.push("/products?" + qs.stringify(query));
            }, DEBOUNCE_TIME);
        }
    }, [searchName]);

    const getFilterTags = () => <FilterTags search={search} categories={categories} lan={lan} />;

    const refreshPage = () => {
        dispatch(getProducts(getSearch()));
    };

    const getActionBar = () => (
        <div>
            <div className="action-bar">
                <div className="action-left">
                    <Input
                        style={{ width: "300px" }}
                        size="large"
                        placeholder={intl.formatMessage({ id: "type_to_search" })}
                        onChange={onSearch}
                        value={searchName}
                        allowClear
                    />
                    <Button
                        className="filter-button"
                        type="default"
                        icon={<BiSliderAlt size={20} />}
                        onClick={() => setFilter(true)}
                    >
                        <div className="icon-button-text d-inline">
                            <FormattedMessage id="filters" />
                        </div>
                    </Button>
                </div>
                <div className="action-right">
                    <RefreshButton refreshPage={refreshPage} loading={loading} />
                    <PrintLayoutSwitch
                        isPrintLayout={isPrintLayout}
                        setIsPrintLayout={setIsPrintLayout}
                        agGridRef={agGridRef}
                    />
                    {getBulkEdit()}
                    {getBulkEditModal(
                        <BulkEdit
                            pids={selected}
                            type={bulkEdit}
                            getOptions={getOptions}
                            onClose={onCloseBulkEditModal}
                        />,
                        {
                            title: <FormattedMessage id={`update_${bulkEdit}`} />,
                            onCancel: onCloseBulkEditModal,
                            footer: null,
                            width: 480,
                        }
                    )}
                    <Button
                        className="add-button-handler"
                        type="default"
                        size="large"
                        style={{ marginRight: 14 }}
                        onClick={openQuickAddModal}
                    >
                        <FormattedMessage id="quick_add" />
                    </Button>
                    <Button
                        className="add-button-handler"
                        type="primary"
                        size="large"
                        onClick={() => history.push("/products/addEditItem")}
                    >
                        <FormattedMessage id="create_item" />
                    </Button>
                </div>
            </div>
            <div className="filter-tags-bar">{getFilterTags()}</div>
        </div>
    );

    const openProductImages = (id: number) => () => {
        dispatch(getProduct(id));
        openImageModal();
    };

    const openProductDescription = (id: number) => () => {
        dispatch(getProduct(id));
        openDescriptionModal();
    };

    const openProductShortDescription = (id: number) => () => {
        dispatch(getProduct(id));
        openShortDescriptionModal();
    };

    const handleRowClicked = (row: any) => {
        try {
            if (
                Array.prototype.slice
                    .call(row?.event?.target?.children)
                    .find((el: any) => el.className.match("ag-selection-checkbox"))
            )
                return;
            history.push("/products/addEditItem/" + row.data?.product_id);
        } catch (e) {
            console.error(e);
            return;
        }
    };

    const getTableObject = () => ({
        columnDefs: [
            // renders the item image
            {
                field: "product_id",
                headerName: "",
                minWidth: COLUMN_WIDTHS.IMAGE_MIN,
                maxWidth: COLUMN_WIDTHS.IMAGE_MAX,
                resizable: false,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellRenderer: "imageRenderer",
                cellRendererParams: {
                    openImageModal,
                    openProductImages,
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // renders the item name
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "item" }),
                minWidth: COLUMN_WIDTHS.NAME_MIN,
                sortable: true,
                unSortIcon: true,
                comparator: productNameComparator,
                cellRenderer: "nameRenderer",
                cellRendererParams: {
                    openProductImages,
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item product type
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "product_type" }),
                // @ts-ignore
                cellRenderer: "typeRenderer",
                minWidth: COLUMN_WIDTHS.TYPE_MIN,
                sortable: true,
                unSortIcon: true,
                cellRendererParams: {
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item sku
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "sku" }),
                minWidth: COLUMN_WIDTHS.SKU_MIN,
                sortable: true,
                unSortIcon: true,
                cellRenderer: "inputRenderer",
                comparator: productSkuComparator,
                cellRendererParams: {
                    name: "sku",
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item categories
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "categories" }),
                minWidth: COLUMN_WIDTHS.CATEGORIES_MIN,
                cellRenderer: "categoriesRenderer",
                cellRendererParams: {
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item stock count
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "stock" }),
                minWidth: COLUMN_WIDTHS.STOCK_MIN,
                sortable: true,
                unSortIcon: true,
                comparator: productStockComparator,
                cellRenderer: "inputRenderer",
                type: "numericColumn",
                cellRendererParams: {
                    name: "stock",
                    type: "number",
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item price
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "price" }),
                minWidth: COLUMN_WIDTHS.PRICE_MIN,
                sortable: true,
                unSortIcon: true,
                comparator: productPriceComparator,
                cellRenderer: "inputRenderer",
                type: "numericColumn",
                cellRendererParams: {
                    name: "price",
                    type: "number",
                    format: "currency",
                    openProductDescription,
                    openProductShortDescription,
                    currency: storeCurrency,
                },
            },
            // render item product availability
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "product_availability" }),
                minWidth: COLUMN_WIDTHS.AVAILABILITY_MIN,
                cellRenderer: "availabilityRenderer",
                cellRendererParams: {
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item enable/disable status
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "enabled" }),
                cellRenderer: "enabledRenderer",
                minWidth: COLUMN_WIDTHS.ENABLED_MIN,
                maxWidth: COLUMN_WIDTHS.ENABLED_MAX,
                cellRendererParams: {
                    openProductDescription,
                    openProductShortDescription,
                },
            },
            // render item actions
            {
                field: "product_id",
                headerName: intl.formatMessage({ id: "actions" }),
                minWidth: COLUMN_WIDTHS.ACTIONS_MIN,
                maxWidth: COLUMN_WIDTHS.ACTIONS_MAX,
                resizable: false,
                cellRenderer: "actionsRenderer",
                cellRendererParams: {
                    openProductDescription,
                    openProductShortDescription,
                },
            },
        ],
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        rowData: products,
        frameworkComponents: {
            actionsRenderer: ActionsRenderer,
            enabledRenderer: EnabledRenderer,
            nameRenderer: NameRenderer,
            availabilityRenderer: AvailabilityRenderer,
            inputRenderer: InputRenderer,
            typeRenderer: TypeRenderer,
            noRows: NoRowsRender,
            loadingRenderer: TableLoadingRender,
            categoriesRenderer: CategoriesRenderer,
            imageRenderer: ImageRenderer,
        },
        noRowsOverlayComponent: "noRows",
        loadingOverlayComponent: "loadingRenderer",
        getRowHeight: () => 80,
        onRowClicked: handleRowClicked,
        stopEditingWhenCellsLoseFocus: true,
        singleClickEdit: true,
        rowSelection: "multiple",
        gridOptions: {
            rowBuffer: 30,
        },
    });

    useEffect(() => {
        if (loading) {
            gridApi?.showLoadingOverlay?.();
        } else {
            gridApi?.hideOverlay?.();
        }
    }, [loading]);

    const onSelectionChange = (event: any) => {
        setSelected(event?.api?.getSelectedNodes().map((node: any) => node?.data?.product_id));
    };

    // renders the product list
    const getTable = () => (
        <Spin spinning={loading}>
            <div id="products-table-container" className="ag-theme-alpine" ref={tableRef}>
                {table ? (
                    // @ts-ignore
                    <AgGridReact
                        {...getTableObject()}
                        suppressRowClickSelection={true}
                        getRowNodeId={(row) => row.product_id}
                        immutableData
                        onSelectionChanged={onSelectionChange}
                        onGridReady={(params) => {
                            params?.api?.sizeColumnsToFit?.();
                            params?.api?.resetRowHeights();
                            gridApi = params?.api;
                        }}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={onGridSizeChanged}
                        ref={agGridRef}
                    />
                ) : null}
            </div>
        </Spin>
    );

    const onResize = () => {
        if (tableRef?.current?.firstChild?.style?.height) {
            tableRef.current.firstChild.style.height = tableRef?.current?.offsetHeight + "px";
        }
    };

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const getPagination = () => {
        const size = Number(search?.limit ?? DEFAULT_PAGE_SIZE);
        const current = Number(search?.page ?? 1);
        const onChange = (page: any, pageSize: any) => {
            const prevPage = _.isNaN(parseInt(page)) ? 1 : parseInt(page);
            const prevLimit = _.isNaN(parseInt(limit)) ? DEFAULT_PAGE_SIZE : parseInt(limit);

            let newPage = page;
            if (pageSize !== prevLimit) {
                const position = (prevPage - 1) * prevLimit;
                newPage = Math.floor(position / pageSize + 1);
            }

            const query = _.cloneDeep(search);
            if (pageSize) {
                query.limit = pageSize.toString();
                query.page = newPage.toString();
            }
            history.push("/products?" + qs.stringify(query));
        };
        const paginationProps: PaginationProps = {
            total,
            pageSize: size,
            current: current,
            showSizeChanger: false,
            onChange: (page, pageSize) => onChange(page, pageSize),
        };
        const renderPageSelect = () => {
            const options = [];

            for (let i = 0, j = 1; i < total; i += size, j++) {
                options.push(j);
            }
            return (
                <Select
                    style={{ marginRight: 3, marginLeft: 3 }}
                    value={current}
                    onChange={(page) => onChange(page, size)}
                >
                    {options.map((opt) => (
                        <Option value={opt} key={opt}>
                            {opt}
                        </Option>
                    ))}
                </Select>
            );
        };
        const renderSizer = () => {
            const sizers = [10, 20, 50];
            return (
                <Select value={size} onChange={(pageSize) => onChange(current, pageSize)}>
                    {sizers.map((size) => (
                        <Option value={size} key={size}>
                            {size} / page
                        </Option>
                    ))}
                </Select>
            );
        };
        return (
            <div
                className="pagination-products"
                style={{
                    width: `calc(100vw - ${navigationWidth}px)`,
                }}
            >
                <Pagination {...paginationProps} />
                {renderPageSelect()}
                {renderSizer()}
                <div className="pagination-products-total">
                    <FormattedMessage id="total" />: {total}
                </div>
            </div>
        );
    };

    const getSearch = useCallback(() => {
        return {
            product_type: ALL_TYPES,
            ...search,
            paging: {
                page: search?.page ?? 1,
                limit: search?.limit ?? DEFAULT_PAGE_SIZE,
            },
        };
    }, [search]);

    const onSaveImage = () => {
        closeImageModal();
    };

    const resizeCallback = () => {
        gridApi?.sizeColumnsToFit?.();
    };

    const showTable = () => {
        setTable(true);
    };

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    useEffect(() => {
        if (!isPageLoaded) {
            setIsPageLoaded(true);
        }
        dispatch(getProducts(getSearch()));
    }, [location.search, dispatch, history, search, getSearch]);

    useEffect(() => {
        setTable(false);
        setTimeout(showTable, 100);
    }, [lan]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, []);

    const getFilterDrawer = () => (
        <Drawer visible={filter} onClose={() => setFilter(false)} title={<FormattedMessage id="filter" />} width={380}>
            <ProductFilter search={search} closeModal={() => setFilter(false)} />
        </Drawer>
    );

    const renderDuplicate = () => getDuplicateModal();

    return {
        getActionBar,
        getTable,
        getModal,
        getImageModal,
        getDescriptionModal,
        getShortDescriptionModal,
        getQuickAddModal,
        search,
        closeModal,
        onSaveImage,
        closeImageModal,
        closeDescriptionModal,
        closeShortDescriptionModal,
        closeQuickAddModal,
        getPagination,
        getFilterDrawer,
        renderDuplicate,
    };
}

export default useProductsPage;
