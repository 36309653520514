import { Modal } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";
import { RootState } from "../../../../app/reducer";
import { getReferralOrderHistory } from "../../../../services/referrals";
import { setReferralsState } from "../../../../slices/referrals";
import ReferralModalFilterBar from "./filter";
import ModalPagination from "./pagination";
import ModalTable from "./table";

const selector = (state: RootState) => ({
    transactions: state?.referrals?.orderHistory,
    paging: state?.referrals?.orderHistoryPaging,
    searchConditions: state?.referrals?.orderHistorySearchConditions,
});

function OrderHistory(): JSX.Element {
    const dispatch = useDispatch();
    const { transactions, searchConditions } = useSelector(selector);

    const onClose = () => {
        dispatch(
            setReferralsState({
                orderHistory: [],
                orderHistoryPaging: {},
                orderHistorySearchConditions: {
                    page: 1,
                    limit: DEFAULT_PAGE_SIZE,
                },
            })
        );
    };

    useEffect(() => {
        if (searchConditions?.ref_code) {
            dispatch(getReferralOrderHistory(searchConditions));
        }
    }, [searchConditions, dispatch]);

    return (
        <Modal
            title={<FormattedMessage id="referral_order_history" />}
            visible={(transactions?.length ?? 0) > 0}
            onCancel={onClose}
            width={900}
            footer={null}
        >
            <ReferralModalFilterBar />
            <ModalTable />
            <ModalPagination />
        </Modal>
    );
}

export default OrderHistory;
