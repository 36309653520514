import React from "react";
import "./payment.scss";
import union_pay from "../../../../../../images/icons/icon_union_pay_express.png";
import visa from "../../../../../../images/icons/icon_visa.png";
import masterCard from "../../../../../../images/icons/icon_master.png";
import applePay from "../../../../../../images/icons/icon_apple_pay.png";
import googlePay from "../../../../../../images/icons/icon_google_pay.png";
import paypal from "../../../../../../images/icons/icon_paypal.png";
import weChat from "../../../../../../images/icons/icon_wechat_pay.png";
import aliPay from "../../../../../../images/icons/icon_alipay.png";

const PaymentCont = (paymentMethodsChecked: { paymentMethodsChecked: string[] }): JSX.Element => {
    return (
        <div className="payment-container">
            {paymentMethodsChecked.paymentMethodsChecked.map((item: any, index: any) => {
                if (item === "mastercard") {
                    return <img src={masterCard} alt="" key={index} />;
                }
                if (item === "visa") {
                    return <img src={visa} alt="" key={index} />;
                }
                if (item === "union_pay") {
                    return <img src={union_pay} alt="" key={index} />;
                }
                if (item === "braintree_apple") {
                    return <img src={applePay} alt="" key={index} />;
                }

                if (item === "braintree_google_pay") {
                    return <img src={googlePay} alt="" key={index} />;
                }
                if (item === "braintree_paypal") {
                    return <img src={paypal} alt="" key={index} />;
                } else {
                    return (
                        <>
                            <img src={weChat} alt="" key={index} />
                            <img src={aliPay} alt="" key={index} />
                        </>
                    );
                }
            })}
        </div>
    );
};

export default PaymentCont;
