import { Steps } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import React from "react";
import { AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { AnyStyle } from "../../constants";
import "../../style.scss";
import { getTaskStatusByCode } from "../helper";

interface RowProps {
    children: React.ReactNode | React.ReactNode[];
    containerStyle?: AnyStyle;
}
const TaskDeliveryStatusTab: React.FC<PropsFromRedux> = ({ onActiveTask }) => {
    const intl = useIntl();
    const Row: React.FC<RowProps> = (props) => {
        return (
            <div className="row" style={props.containerStyle}>
                {props.children}
            </div>
        );
    };

    const renderDriverInfo = () => {
        const getName = () => {
            const firstName = onActiveTask?.dasher?.first_name ?? "";
            const lastName = onActiveTask?.dasher?.last_name ?? "";
            return <span>{!_.isEmpty(firstName) ? `${firstName} ${lastName}` : "no records"}</span>;
        };
        return (
            <div className="task-delivery-status-driver-info-container">
                <Row>
                    <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "driver_info_label" })}</span>
                </Row>
                <Row>
                    <AiOutlineUser className="icons" />
                    {getName()}
                </Row>
                <Row>
                    <AiOutlinePhone className="icons" />
                    <span>{onActiveTask?.dasher?.dasher_phone_number_for_customer ?? "no records"}</span>
                </Row>
                <Row>
                    <GoLocation className="icons" />
                    <span>{onActiveTask?.dasher?.location ?? "no records"}</span>
                </Row>
            </div>
        );
    };

    const renderDeliveryStatus = () => {
        const initStepsData = () => {
            if (onActiveTask?.status_history) {
                let isEnd = false;
                const arr = Object.entries(onActiveTask?.status_history);
                if (onActiveTask?.status === 100) arr.reverse();
                const steps: React.ReactNode[] = arr?.map(([k, v], i) => {
                    if ((arr.length - 1 === i || i === 0) && onActiveTask?.status === Number(k)) isEnd = true;
                    return (
                        <Steps.Step
                            key={i}
                            title={intl.formatMessage({ id: getTaskStatusByCode(parseInt(k)) })}
                            description={v}
                        />
                    );
                });

                if (!isEnd) {
                    steps.push(
                        <Steps.Step
                            key={arr.length}
                            title={intl.formatMessage({ id: getTaskStatusByCode(onActiveTask.status) })}
                        />
                    );
                }

                const currStep = isEnd ? steps.length : steps.length - 1;

                return { steps, currStep };
            } else {
                return { steps: [], currStep: 0 };
            }
        };

        const stepsData = initStepsData();

        return (
            <div className="task-delivery-status-delivery-status-container">
                <Row>
                    <span style={{ paddingRight: "10px", fontWeight: "bold" }}>
                        {intl.formatMessage({ id: "status" })}
                    </span>
                    <span style={{ fontWeight: "bold", color: "#3898c8" }}>
                        {intl.formatMessage({ id: getTaskStatusByCode(onActiveTask?.status) })}
                    </span>
                </Row>
                <Steps direction="vertical" current={stepsData?.currStep}>
                    {stepsData?.steps}
                </Steps>
            </div>
        );
    };

    return (
        <div className="task-delivery-status-tab-container">
            {renderDriverInfo()}
            {renderDeliveryStatus()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        onActiveTask: state.deliveryRequests.onActiveTask,
    };
};

const mapDispatchToProps = {};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(TaskDeliveryStatusTab);
