import React from "react";
import storeNameURL from "../_components/storeinfo-nophone";
import tableNum from "../_components/tablenum-comp";
import qrScan from "../_components/qrcode-scantxt";
import GoopterIcon from "../_components/goopter-icon";
import customTextBox from "../_components/custom-text-cont";
import paymentIcons from "../_components/payment-comp";
import Phone from "../_components/phone-cont";
import pointsCont from "../_components/points";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import "../serve-print.scss";

interface Format12Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    customdark: boolean | any;
    dataURLS: string | any;
    paperSize: string | any;
    storeName: string;
    storeURL: string | any;
    storeLogo: string | any;
    phone: string | number;
    customText1: string;
    customText2: string;
}

function Format12(props: Format12Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            padding: `${props.bleedingMargin}mm`,
        },
        qrPrintPdfContainer: {
            position: "relative",
            width: "10.12cm",
            height: "11cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            marginBottom: `${props.bleedingMargin ?? 0}mm`,
            justifyContent: "center",
            backgroundColor: props.customdark ? "#504F4F" : props.bgColor ?? "#20817b",
            flexDirection: "column",
            borderRadius: "10pt",
            fontSize: "16pt",
        },
        qrCodeCardBlock: {
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: "100%",
            padding: "15pt",
        },
        tableNumCont: {
            position: "relative",
            width: "10%",
            display: "flex",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.85,
            marginLeft: "40px",
        },
        tableNumHolder: {
            position: "relative",
            height: "2.3cm",
            width: "2.3cm",
            borderRadius: "2cm",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            justifyContent: "center",
            alignItems: "center",
            color: props.customdark ? "white" : "red",
            fontSize: "24pt",
            fontWeight: 700,
        },
        qrHolder: {
            position: "relative",
            height: "110pt",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        qrCodeTextCont: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100pt",
            marginTop: "7pt",
            height: "25pt",
            borderRadius: "5pt",
            fontWeight: 500,
            fontSize: "14pt",
            marginBottom: "50px",
            color: props.customdark ? "white" : "red",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            opacity: 0.85,
        },
        paymentContainer: {
            width: `2.5cm`,
            marginBottom: "10pt",
            marginTop: "5pt",
        },
        goopterContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            width: "120pt",
            alignItems: "center",
            justifyContent: "center",
            height: "20pt",
            backgroundColor: props.customdark ? "#504F4F" : "white",
            borderRadius: "5pt",
            opacity: 0.85,
            transform: "rotate(-90deg)",
            marginTop: "10px",
        },
        poweredBy: {
            fontSize: "12pt",
            fontWeight: 600,
            color: props.customdark ? "white" : "#626161",
            opacity: 1,
        },
        goopterLogo: {
            width: "1.5cm",
            marginTop: "2px",
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        storeName: {
            textTransform: "uppercase",
            fontWeight: 500,
            fontSize: "16pt",
            color: "white",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            margin: "5pt",
            height: "auto",
        },
        websiteURL: {
            display: "flex",
            position: "relative",
            color: "white",
            fontWeight: "bold",
            fontSize: "2vw",
            width: "100%",
            height: "15px",
            justifyContent: "center",
            textAlign: "center",
        },
        phone: {
            position: "relative",
            color: "white",
            fontWeight: 500,
            fontSize: "15pt",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            width: "45%",
            height: "auto",
        },
        logo: {
            position: "absolute",
            display: "flex",
            width: "24pt",
            height: "24pt",
            top: "5px",
        },
        Title: {
            display: "flex",
            position: "relative",
            width: "100%",
            height: "50pt",
            top: "-60pt",
        },
        Top: {
            display: "flex",
            position: "relative",
            flexDirection: "row",
            width: "100%",
            height: "100pt",
        },
        topLeft: {
            display: "flex",
            position: "relative",
            flexDirection: "row",
            width: "40%",
            height: "100%",
        },
        topRight: {
            display: "flex",
            position: "relative",
            flexDirection: "row",
            width: "60%",
            height: "100%",
            justifyContent: "center",
        },
        Bottom: {
            display: "flex",
            position: "absolute",
            flexDirection: "row",
            width: "100%",
            height: "50pt",
            bottom: "-60pt",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "20pt",
        },
        qrBox: {
            display: "flex",
            position: "relative",
        },
        tableBox: {
            display: "flex",
            position: "relative",
            justifyContent: "center",
        },
        customTextCont: {
            display: "flex",
            position: "relative",
            color: "white",
            textAlign: "left",
            justifyContent: "center",
            width: "55%",
            height: "100%",
            marginLeft: "10px",
        },
        CustomtxtHolder: {
            justifyContent: "center",
            fontSize: "12pt",
        },
        goopterLogoCont: {
            display: "flex",
            position: "absolute",
            left: "90px",
        },
        tableCont: {
            display: "flex",
            position: "absolute",
            marginBottom: "10px",
            bottom: "65px",
            left: "-13px",
        },
        earnPoints: {
            fontSize: "6pt",
            color: "white",
        },
        pointsCont: {
            display: "flex",
            marginTop: "30px",
            width: "100%",
        },
        CustomtxtBox: {
            marginBottom: "3px",
        },
    });

    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() =>
                        props.dataURLS?.map((url: any, index: number) => (
                            <View
                                key={`qrPrint-pdf-container-${index}`}
                                style={styles.qrPrintPdfContainer}
                                wrap={false}
                            >
                                <View style={styles.qrCodeCardBlock}>
                                    <View style={styles.Title}>{storeNameURL(styles, props)}</View>
                                    <View style={styles.Top}>
                                        <View style={styles.topLeft}>
                                            <View style={styles.qrBox}>{qrScan(styles, url, props)}</View>
                                        </View>
                                        <View style={styles.topRight}>
                                            <View style={styles.tableBox}>
                                                <View style={styles.tableCont}>{tableNum(styles, url)}</View>
                                                <View style={styles.pointsCont}>{pointsCont(styles)}</View>
                                                {paymentIcons(styles)}
                                            </View>
                                            <View style={styles.goopterLogoCont}>{GoopterIcon(styles)}</View>
                                        </View>
                                    </View>
                                    <View style={styles.Bottom}>
                                        {Phone(styles, props)}
                                        {customTextBox(styles, props)}
                                    </View>
                                </View>
                            </View>
                        ))
                    }
                />
            </Page>
        </Document>
    );
}
export default Format12;
