import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { Collapse } from "antd";
import Date from "./filter-drawer-collapse-date";
import SearchBy from "./filter-drawer-collapse-search-by";
import StatusType from "./filter-drawer-collapse-status-type";

const { Panel } = Collapse;

class FilterDrawerCollapse extends Component {
    getPanelDatas = () => {
        return [
            { title: "date", component: Date },
            { title: "search_order_with", component: SearchBy },
            { title: "order_status", component: StatusType, props: { type: "status" } },
            { title: "order_type", component: StatusType, props: { type: "type" } },
        ];
    };

    renderPanels = () => {
        return this.getPanelDatas().map((panel) => this.renderPanel(panel));
    };

    renderPanel = (panel) => {
        return (
            <Panel header={<FormattedMessage id={panel.title ? panel.title : " "} />} key={panel.title}>
                <panel.component {...this.props} {...(panel.props ? panel.props : {})} />
            </Panel>
        );
    };

    render() {
        return <Collapse defaultActiveKey={["date", "search_order_with"]}>{this.renderPanels()}</Collapse>;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FilterDrawerCollapse));
