import React, { useEffect } from "react";
import { Button, Switch } from "antd";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { getStoreDetails } from "../../../../services/store";
import { setManagedStores } from "../../../../slices/managed-stores";
import { getManagedStores, getStoreList, updateManagedStores } from "../../../../services/managed-stores";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/managedStores", breadcrumbName: "managed_stores" },
    ],
};

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const stores = useSelector((state: RootState) => state?.managedStores?.stores);
    const activeStores = useSelector((state: RootState) => state?.managedStores?.activeStores);
    const managedStores: any = useSelector((state: RootState) => state?.managedStores?.managedStores);
    const status = ["store_prepare_for_submission", "store_pending_approval", "store_rejected", "store_approved"];
    const statusTxt = intl.formatMessage({ id: "status" });

    useEffect(() => {
        dispatch(getStoreList());
        dispatch(getStoreDetails());
        dispatch(getManagedStores());
    }, [dispatch]);

    const saveChanges = () => {
        dispatch(updateManagedStores({ managed_stores: managedStores }));
    };

    const isActive = (storeId: any) => {
        let isActive = false;
        managedStores.find((store: any) => (store === storeId ? (isActive = true) : null));
        return isActive;
    };

    const setManagedStoresState = (id: any) => {
        isActive(id) ? dispatch(setManagedStores({ id, ad: false })) : dispatch(setManagedStores({ id, ad: true }));
    };

    const renderStoreCard = (store: string, approved: string, value: boolean, id: any, key: number) => (
        <div className="store-card d-flex justify-content-between align-items-center" key={key}>
            <div className="text-hold d-flex flex-column">
                <div className="options-sub-header">{store}</div>
                <div className="options-description">
                    {statusTxt}: <FormattedMessage id={approved} />
                </div>
            </div>
            <div className="switch-hold">
                <Switch checked={value} onChange={() => setManagedStoresState(id)} />
            </div>
        </div>
    );

    const renderManagedStores = () => (
        <div className="white-layered-block w100 d-flex flex-column">
            <div className="content-font-size d-flex mb-1">
                <FormattedMessage id="managed_stores_desc" />
            </div>
            {stores.map((s: any, key: number) => renderStoreCard(s.name, status[s.status], isActive(s.id), s.id, key))}
        </div>
    );

    const renderSave = () => (
        <div className="setting-actions" style={{ marginRight: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return !_.isEmpty(stores) && !_.isEmpty(activeStores) ? (
        <Wrapper helmet={{ title: "managed_stores" }} breadcrumb={breadcrumb}>
            <div className="managed-store-page">
                {renderManagedStores()}
                {renderSave()}
            </div>
        </Wrapper>
    ) : null;
}

export default App;
