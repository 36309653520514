import { InputNumber, Modal, Row, Col, Button } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setAddModalState } from "../../../../slices/referrals";
import { FormattedMessage } from "react-intl";
import { makeReferralTransaction } from "../../../../services/referrals";
import { ReferralTransactionActions } from "../../constants";
import { AiOutlineSwap } from "react-icons/ai";
import { formatCurrency, formatNumber } from "../../../../util/helper";
import { displayCurrValue } from "../redeem-rewards";

function AddRewards(): JSX.Element {
    const dispatch = useDispatch();
    const [points, setPoints] = useState<number>(0);
    const [dollarValue, setDollarValue] = useState<number>(0);
    const { addModal, redeemBalance, pointsToValue, valueToPoints, storeCurrency } = useSelector(
        (state: RootState) => ({
            addModal: state?.referrals?.addModal,
            redeemBalance: Number(state?.referrals?.addModal?.reward_balance) ?? 0,
            pointsToValue: Number(state?.store?.storeReferrals?.pointsToValue) ?? 1,
            valueToPoints: Number(state?.store?.storeReferrals?.valueToPoints) ?? 1,
            storeCurrency: state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD",
        })
    );
    const [pointsLeft, setPointsLeft] = useState<boolean>(true);

    const renderPointsInput = (
        <Col span={11} className="add-points-col">
            <FormattedMessage id="add_by_points" />
            <InputNumber
                value={formatNumber(points)}
                formatter={(value) => `${value}`.replace(/^[1-9]*S/g, "")}
                onChange={(point: number) => {
                    setDollarValue(pointsToValue !== 0 ? point / pointsToValue : 0);
                    setPoints(point);
                }}
                disabled={!pointsLeft}
            />
        </Col>
    );

    const renderValueInput = (
        <Col span={11} className="add-dollar-value-col">
            <FormattedMessage id="add_by_value" />
            <InputNumber
                value={dollarValue}
                formatter={(value) => String(formatCurrency(value ?? 0, storeCurrency))}
                onChange={(dollarValue: number) => {
                    setPoints(dollarValue * valueToPoints);
                    setDollarValue(dollarValue);
                }}
                disabled={pointsLeft}
            />
        </Col>
    );

    const renderPointsBalance = <>{displayCurrValue("balance", redeemBalance, pointsToValue, storeCurrency)}</>;

    const renderSwapButton = (
        <Col span={2} className="swap-icon-col">
            <div>
                <Button
                    className="swap-btn"
                    onClick={() => {
                        const order = !pointsLeft;
                        setPointsLeft(order);
                    }}
                >
                    <AiOutlineSwap />
                </Button>
            </div>
        </Col>
    );

    const addPoints = () => {
        if (points > 0) {
            dispatch(
                makeReferralTransaction({
                    action: ReferralTransactionActions.AddPoints,
                    points,
                    customer_id: addModal?.customer_id,
                    refresh_customer: addModal?.refresh_customer,
                })
            );
        }
    };

    const renderModalBody = (col1: any, col2: any, col3: any) => {
        return (
            <Modal
                destroyOnClose={true}
                visible={Boolean(addModal?.customer_id)}
                onOk={addPoints}
                onCancel={() =>
                    dispatch(setAddModalState({ customer_id: "", customer_name: "", refresh_customer: false }))
                }
                title={<FormattedMessage id="add_rewards_points_title" values={addModal} />}
            >
                {renderPointsBalance}
                <div className="add-rewards-modal">
                    <Row align="middle" justify="center">
                        {col1}
                        {col2}
                        {col3}
                    </Row>
                </div>
            </Modal>
        );
    };

    return pointsLeft
        ? renderModalBody(renderPointsInput, renderSwapButton, renderValueInput)
        : renderModalBody(renderValueInput, renderSwapButton, renderPointsInput);
}

export default AddRewards;
