import React, { Component } from "react";
import _ from "lodash";
import _helper from "util/helper";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Col, Row, Input, Select, List, Checkbox, Modal, Spin } from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import actions from "slices/live-order";
import { getDefaultOpts } from "pages/order-history/helper";
import cx from "classnames";

const { Option } = Select;

class ReplaceItemEditorSearch extends Component {
    componentDidMount() {
        this.checkItemUpdate({});
    }

    componentDidUpdate(prevsProps) {
        this.checkItemUpdate(prevsProps);
    }

    getAllCatStr = () => {
        const catList = Array.isArray(_.get(this.props, "orderStore.cat_list", []))
            ? _.get(this.props, "orderStore.cat_list", [])
            : [];
        let allCidsStr = "";
        catList.map((cat) => (allCidsStr += `,${_.get(cat, "id")}`));
        return allCidsStr;
    };

    getOriginalItem = (props = this.props) => _.get(props, "state.item", {});

    getReplacedItem = (props = this.props) => _.get(props, "state.replacedItem", {});

    getProducts = () => {
        return Array.isArray(this.props.products) ? this.props.products : [];
    };

    getItemChangeObject = () => _.get(this.props, "state.itemChangeObject", {});

    checkItemUpdate = (prevsProps) => {
        const prevItemPid = String(_.get(this.getOriginalItem(prevsProps), "pid", ""));
        const currentItemPid = String(_.get(this.getOriginalItem(), "pid", ""));
        const itemUpdate = prevItemPid !== currentItemPid && currentItemPid;
        const itemCids = _.get(this.getOriginalItem(), "cids.0", "");
        const allCidsStr = this.getAllCatStr();
        const cids = itemCids && allCidsStr.includes(itemCids) ? itemCids : allCidsStr;
        if (itemUpdate) {
            let postData = {
                page: 1,
                limit: 20,
                gid: this.props.gid,
                cids: cids,
                is_available: 1,
                lan: this.props.lan,
            };
            this.props.getProducts(postData);
        }
    };

    renderSearchBar = () => {
        const size = "large";

        const renderSearchInput = () => {
            const handleSearch = (e) => {
                const value = e.target.value;
                var conditions = _.cloneDeep(this.props.searchCondition);
                conditions.keywords = value;
                conditions.page = 1;
                conditions.reset = true;
                this.props.getProducts(conditions);
                this.props.setLiveOrderState({ itemEditDialogSearchInput: e.target.value });
            };
            return (
                <Input
                    allowClear
                    placeholder={this.props.intl.formatMessage({ id: "search_by_item" })}
                    style={{ width: "100%" }}
                    size={size}
                    prefix={<SearchOutlined />}
                    onChange={(values) => handleSearch(values)}
                    value={this.props.itemEditDialogSearchInput}
                />
            );
        };

        const renderCatSelect = () => {
            const catList = Array.isArray(_.get(this.props, "orderStore.cat_list", []))
                ? _.get(this.props, "orderStore.cat_list", [])
                : [];
            const allCidsStr = this.getAllCatStr();
            const searchValue = _.get(this.props, "searchCondition.cids", "");
            const value = searchValue === allCidsStr ? "all" : searchValue;
            const handleOnSelect = (value) => {
                var conditions = _.cloneDeep(this.props.searchCondition);
                conditions.cids = value;
                conditions.page = 1;
                conditions.reset = true;
                if (value === "all") {
                    conditions.cids = allCidsStr;
                }
                this.props.getProducts(conditions);
            };
            return (
                <Select onChange={(v) => handleOnSelect(v)} style={{ width: "100%" }} value={value} size={size}>
                    <Option value="all">
                        <FormattedMessage id="all" />
                    </Option>
                    {catList.map((cat) => (
                        <Option key={cat.id} value={cat.id}>
                            <FormattedMessage id={cat.name ? cat.name : " "} />
                        </Option>
                    ))}
                </Select>
            );
        };

        const renderInStockOption = () => {
            const handleOnSelect = () => {
                const checked = String(_.get(this.props, "searchCondition.is_available", 0)) === "1" ? 0 : 1;
                var conditions = _.cloneDeep(this.props.searchCondition);
                conditions.is_available = checked;
                conditions.page = 1;
                conditions.reset = true;
                this.props.getProducts(conditions);
            };
            return (
                <Checkbox
                    className="replace-item-in-stock-filter-checkbox"
                    checked={_.get(this.props, "searchCondition.is_available", 1)}
                    onChange={(e) => handleOnSelect(e)}
                >
                    <FormattedMessage id={"show_available_only"} />
                </Checkbox>
            );
        };

        return (
            <Row className="replace-item-search-bar">
                <Col span={8} style={{ height: "40px", display: "flex", alignItems: "center" }}>
                    {renderInStockOption()}
                </Col>
                <Col span={11} style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                    {renderSearchInput()}
                </Col>
                <Col span={5}>{renderCatSelect()}</Col>
            </Row>
        );
    };

    loadMore = () => {
        let page = _.get(this.props, "searchCondition.page", 1);
        var conditions = _.cloneDeep(this.props.searchCondition);
        conditions.reset = false;
        conditions.page = ++page;
        this.props.getProducts(conditions);
    };

    hasMore = () => {
        if (!this.props.isLoadingProducts) {
            const current = _.get(this.props, "products", []).length;
            const total = _.get(this.props, "pagination.total", 1);
            return current < total;
        }
        return false;
    };

    handleScroll = () => {
        let elem = this.scrollParentRef;
        if (elem.scrollHeight - elem.scrollTop === elem.clientHeight && this.hasMore()) {
            this.loadMore();
        }
    };

    renderProductsList = () => {
        return (
            <div
                style={{ height: this.props.height - 100 }}
                onScroll={this.handleScroll}
                ref={(ref) => (this.scrollParentRef = ref)}
                className="replace-items-products-list-container"
            >
                <Spin spinning={this.props.isLoadingProducts}>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.getProducts()}
                        renderItem={(item) => this.renderProduct(item)}
                    />
                </Spin>
            </div>
        );
    };

    renderProductPriceWithStock = (product) => {
        const hasSpecialPrice = _helper.hasSpecialPrice(product);
        const specialPrice = _helper.getCurrencyFormattedString(product?.spc, this.props.storeCurrency);
        const price = _helper.getCurrencyFormattedString(_.get(product, "pc", 0), this.props.storeCurrency);
        const qt = _.get(product, "sq", 0);
        const dot = <b>&nbsp;&nbsp;•&nbsp;&nbsp;</b>;
        return (
            <>
                <div className="product-price">
                    <div className={cx({ "product-original-price-line-through": hasSpecialPrice })}>{price}</div>
                    {hasSpecialPrice ? (
                        <>
                            <span className="product-special-price">{specialPrice}</span>
                        </>
                    ) : null}
                </div>
                {dot}
                {qt}
            </>
        );
    };

    renderProduct = (product) => {
        const name = _.get(product, "nm");
        const qt = _.get(product, "sq", 0);

        const onClick = () => {
            if (qt <= 0) {
                this.renderOutOfStockWarningDialog();
                return;
            }
            let itemChangeObject = _.clone(this.getItemChangeObject());

            itemChangeObject.new_product = {
                "pid": product?.pid,
                "cnt": 1,
                "pc": null,
                "pcIn": _helper.getPrice(product),
                "opts": getDefaultOpts(product, this.props.lan),
                "nm": name,
                "sku": product?.sku,
                "sq": qt,
            };
            this.props.setState({
                itemChangeObject,
                optionsErrors: [],
            });
        };

        const renderTitle = () => {
            return (
                <Row className="replace-item-search-list-title-container">
                    <Col className="product-name" span={16}>
                        {name}
                    </Col>
                    <Col className="product-price-with-stock" span={8}>
                        {this.renderProductPriceWithStock(product)}
                    </Col>
                </Row>
            );
        };

        return (
            <List.Item onClick={() => onClick()}>
                <List.Item.Meta title={renderTitle()} />
            </List.Item>
        );
    };

    renderOutOfStockWarningDialog = () => {
        Modal.info({
            icon: <ExclamationCircleOutlined />,
            title: this.props.intl.formatMessage({ id: "warning" }),
            content: this.props.intl.formatMessage({ id: "out_of_stock_warning_message" }),
            okText: this.props.intl.formatMessage({ id: "ok" }),
            cancelText: "",
        });
    };

    render() {
        return (
            <div>
                {this.renderSearchBar()}
                <div className="replace-item-in-stock-filter-checkbox"></div>
                {this.renderProductsList()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    products: _.get(state, "liveOrders.products", []),
    pagination: _.get(state, "liveOrders.pagination", {}),
    productDetail: _.get(state, "liveOrders.productDetail", []),
    isLoadingProducts: _.get(state, "liveOrders.isGettingProducts", false),
    searchCondition: _.get(state, "liveOrders.lastSearch", {}),
    itemEditDialogSearchInput: _.get(state, "liveOrders.itemEditDialogSearchInput", ""),
    gid: state?.store?.records?.g_id,
});

const mapDispatchToProps = {
    setLiveOrderState: actions.setState,
    getProducts: actions.getProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReplaceItemEditorSearch));
