import { AgGridReact } from "ag-grid-react";
import { Spin } from "antd";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import config from "../../../../config";
import StoreManagementActionRenderer from "../_components/table/store-management-action-renderer";
import StoreManagementValueRenderer from "../_components/table/store-management-value-renderer";

function useStoreManagementTable(): any {
    const stores = useSelector((state: RootState) => state?.storeManagement?.stores);
    const spinning = useSelector((state: RootState) => state?.storeManagement?.loading);
    const tableRef = useRef<any>(null);
    const intl = useIntl();
    const [data, setData] = useState<any>({});

    let gridApi: any;

    const columnDefs = [
        {
            field: "signup_id",
            width: 100,
            headerName: intl.formatMessage({ id: "signup_id" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.signup_id,
            },
        },
        {
            field: "signup_id",
            width: 100,
            headerName: intl.formatMessage({ id: "admin_id" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.admin_id,
            },
        },
        {
            field: "signup_id",
            width: 100,
            headerName: intl.formatMessage({ id: "category_id" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.category_id,
            },
        },
        {
            field: "signup_id",
            width: 200,
            headerName: intl.formatMessage({ id: "name" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => record?.default_name,
            },
        },
        {
            field: "signup_id",
            width: 200,
            headerName: intl.formatMessage({ id: "price_plan" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => record?.price_plan,
            },
        },
        {
            field: "signup_id",
            width: 200,
            headerName: intl.formatMessage({ id: "status" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                // @ts-ignore
                renderValue: (record: any) =>
                    // @ts-ignore
                    intl.formatMessage({ id: config.STORE_STATUS_MAPPING?.[Number(record?.status)] ?? " " }),
            },
        },
        {
            field: "signup_id",
            width: 150,
            resizable: false,
            headerName: intl.formatMessage({ id: "actions" }),
            cellRenderer: "actionRenderer",
        },
    ];

    const getTableObject: any = () => ({
        columnDefs,
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: StoreManagementValueRenderer,
            actionRenderer: StoreManagementActionRenderer,
        },
        rowData: stores,
        getRowHeight: () => 50,
        getRowNodeId: (row: any) => row?.signup_id,
        immutableData: true,
        onRowClicked: (e: any) => {
            setData(e?.data);
        },
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const getTable = () => {
        const tableObject = getTableObject();
        return React.createElement(
            Spin,
            { spinning },
            React.createElement(
                "div",
                { className: "customers-table-container ag-theme-alpine" },
                React.createElement(AgGridReact, { ...tableObject })
            )
        );
    };

    const onResize = () => {
        if (tableRef?.current?.firstChild?.style?.height) {
            tableRef.current.firstChild.style.height = tableRef?.current?.offsetHeight + "px";
        }
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    useEffect(() => {
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return {
        getTable,
        data,
        setData,
    };
}

export default useStoreManagementTable;
