import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Button as AntdButton } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./index.scss";

class DoordashTrackingModal extends Component {
    renderFooter = () => {
        return (
            <div className="footer-confirm-button">
                <AntdButton
                    size="large"
                    type="primary"
                    style={{ justifyContent: "center", width: "200px" }}
                    onClick={() => {
                        this.props?.onCloseClick();
                    }}
                >
                    <b>
                        <FormattedMessage id="close" />
                    </b>
                </AntdButton>
            </div>
        );
    };

    render() {
        return (
            <Modal
                width={600}
                bodyStyle={{ height: "70vh" }}
                className="modalContent"
                onCancel={() => {
                    this.props?.onCloseClick();
                }}
                visible={this.props?.showModal}
                footer={this.renderFooter()}
                closeIcon={
                    <CloseCircleOutlined
                        style={{ outline: "none", color: "white", fontSize: "250%", marginLeft: "70px" }}
                    />
                }
            >
                <iframe
                    src={this.props?.link}
                    title="doordash"
                    style={{ width: 580, height: "65vh", marginLeft: -14 }}
                />
            </Modal>
        );
    }
}

export default injectIntl(DoordashTrackingModal);
