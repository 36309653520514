import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { getAdminUsersSuccess, getAdminRolesSuccess, setAdminUserState } from "../slices/admin-users";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import { BiError } from "react-icons/bi";
import { getDefaultRolesSuccess, setShowPhoneModal, setShowPassModal, setAccountModal } from "../slices/admin-users";
import h from "../pages/admin-users/helper";
import { toast } from "react-toastify";
import { getIntl } from "../locale";

export const getAdminUsersDetails =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setAdminUserState({ loading: true }));
        try {
            const response: any = await oauth("ADMIN_USERS")({
                method: "GET",
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getAdminUsersSuccess(response?.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setAdminUserState({ loading: false }));
    };

export const saveAdminProfile =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("ADMIN_USERS")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params.profile },
                }),
            });
            const intl = getIntl();
            switch (response?.RC) {
                case 200:
                    dispatch(getAdminUsersDetails());
                    dispatch(setAccountModal(false));
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "admin_user" })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                case 409:
                    toast(createMessage(`${intl.formatMessage({ id: "error_email_taken" })}`, HiInformationCircle));
                    break;
                case 448:
                    toast(createMessage(`${intl.formatMessage({ id: "error_username_taken" })}`, HiInformationCircle));
                    break;
                case 452:
                    toast(createMessage(`${intl.formatMessage({ id: "error_username_taken" })}`, HiInformationCircle));
                    break;
                default:
                    toast(
                        createMessage(`${intl.formatMessage({ id: "error_please_try_again" })}`, HiInformationCircle)
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getAdminRoles =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("ADMIN_ROLES")({
                method: "GET",
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getAdminRolesSuccess(response?.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getDefaultRoles =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("ADMIN_ROLE_PERMISSIONS")({
                method: "GET",
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getDefaultRolesSuccess(response?.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const deleteAdminUser =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "ADMIN_USERS_DELETE",
                "",
                params?.id
            )({
                method: "DELETE",
            });
            const intl = getIntl();
            switch (response?.RC) {
                case 200:
                    dispatch(getAdminUsersDetails());
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "admin_user" })} ${intl.formatMessage({ id: "deleted" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                case 403:
                    toast(
                        createMessage(`${intl.formatMessage({ id: "admin_user_delete_perm_error" })}`, BiError, "red")
                    );
                    break;
                default:
                    toast(createMessage(`${intl.formatMessage({ id: "admin_user_delete_error" })}`, BiError, "red"));
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const changePassword =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("ADMIN_USERS")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params },
                }),
            });
            const intl = getIntl();
            switch (response?.RC) {
                case 200:
                    dispatch(setShowPassModal({ value: false }));
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "admin_user" })} ${intl.formatMessage({
                                id: "password_changed",
                            })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                default:
                    toast(
                        createMessage(`${intl.formatMessage({ id: "error_please_try_again" })}`, HiInformationCircle)
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const changePhone =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("PHONE_VERIFICATION")({
                method: "POST",
                body: JSON.stringify({ ...params?.phoneInfo }),
            });
            const RC: any = response?.RC;
            const intl = getIntl();
            if (RC === 200) {
                if (params?.phoneInfo?.action === h.VERIFY_CODE) {
                    dispatch(setShowPhoneModal({ value: false }));
                    toast(createMessage(intl.formatMessage({ id: "phone_number_changed" }), HiInformationCircle));
                    dispatch(saveAdminProfile({ profile: params?.profile }));
                } else {
                    toast(createMessage(intl.formatMessage({ id: "verification_code_sent" }), HiInformationCircle));
                }
            } else {
                const message = h.PHONE_RC_MESSAGE_MAP[RC] ?? "an_error_occurred";
                toast(createMessage(intl.formatMessage({ id: message }), HiInformationCircle));
            }
        } catch (e) {
            // Handle error
        }
    };
