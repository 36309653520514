import React from "react";
import { isBool } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput } from "./payment-functions";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { paypal_exp_enabled, paypal_exp_secret, paypal_exp_id } = paymentOptions;

    const setState = (name: string, value: string) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: string) => dispatch(setPaymentState({ name, value: isBool(!value) }));

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "paypal_express_checkout",
                "paypal_express_checkout_tip",
                "paypal_exp_enabled",
                paypal_exp_enabled,
                switchChange
            )}
            {paypal_exp_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("ID", "paypal_exp_id", paypal_exp_id, setState, false, true)}
                    {renderHalfTxtInput("Secret", "paypal_exp_secret", paypal_exp_secret, setState, false, true)}
                </div>
            ) : null}
        </div>
    );
}

export default App;
