import React from "react";
import helper from "../../../../../util/helper";
import { View, Text, Image } from "@react-pdf/renderer";
import PhoneIcon from "../../../../../images/icons/phone.png";

const Phone = (styles: React.CSSProperties | any, props: { phone: number }): JSX.Element => (
    <React.Fragment>
        <View style={styles.phone}>
            <View style={styles.phoneIconContainer}>
                <Image src={PhoneIcon} style={styles.PhoneIcon} />
                <Text>{helper.formatPhone(props.phone)}</Text>
            </View>
        </View>
    </React.Fragment>
);
export default Phone;
