import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteProducts, editProducts } from "../../services/products";
import { Button, Dropdown, Menu, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import useModal from "../../hooks/useModal";
import { STATUS_DISABLED, STATUS_ENABLED } from "./constants";
import { RootState } from "../../app/reducer";

function useProductsBulkEdit(selected: Array<string>): any {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [bulkEdit, setBulkEdit] = useState<any>("");
    const { allowed } = useSelector((state: RootState) => ({ allowed: state?.pages?.allowed }));
    const {
        openModal: openBulkEditModal,
        closeModal: closeBulkEditModal,
        getModal: getBulkEditModal,
    } = useModal("description-modal");
    const stableOpenBulkEditModal = useCallback(openBulkEditModal, []);

    const getBulkEdit = () => {
        const disabled = selected.length === 0;

        const onDeleteProducts = () =>
            Modal.confirm({
                title: intl.formatMessage({ id: "confirm_delete_products" }),
                content: intl.formatMessage({ id: "confirm_delete_products_content" }, { count: selected?.length }),
                onOk() {
                    dispatch(deleteProducts(selected));
                },
            });

        const onBulkEdit = (value: string) => () => setBulkEdit(value);

        const setEnabled = () =>
            Modal.confirm({
                title: intl.formatMessage({ id: "confirm_enable_products" }),
                content: intl.formatMessage({ id: "confirm_enable_products_content" }, { count: selected?.length }),
                onOk() {
                    dispatch(editProducts(selected, { status: STATUS_ENABLED }));
                },
            });

        const setDisabled = () =>
            Modal.confirm({
                title: intl.formatMessage({ id: "confirm_disable_products" }),
                content: intl.formatMessage({ id: "confirm_disable_products_content" }, { count: selected?.length }),
                onOk() {
                    dispatch(editProducts(selected, { status: STATUS_DISABLED }));
                },
            });

        const menu = (
            <Menu>
                <Menu.Item onClick={onBulkEdit("price")}>
                    <FormattedMessage id="update_price" />
                </Menu.Item>
                <Menu.Item onClick={onBulkEdit("stock")}>
                    <FormattedMessage id="update_stock" />
                </Menu.Item>
                <Menu.Item onClick={onBulkEdit("availability")}>
                    <FormattedMessage id="update_availability" />
                </Menu.Item>
                <Menu.Item onClick={onBulkEdit("tax_class")}>
                    <FormattedMessage id="update_tax" />
                </Menu.Item>
                <Menu.Item onClick={onBulkEdit("category_ids")}>
                    <FormattedMessage id="update_categories" />
                </Menu.Item>
                <Menu.Item onClick={setEnabled}>
                    <FormattedMessage id="set_enabled" />
                </Menu.Item>
                <Menu.Item onClick={setDisabled}>
                    <FormattedMessage id="set_disabled" />
                </Menu.Item>
                <Menu.Item danger onClick={onDeleteProducts}>
                    <FormattedMessage id="delete" />
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu} disabled={disabled || !allowed}>
                <Button type="default" size="large" style={{ marginRight: 14 }} disabled={disabled || !allowed}>
                    <>
                        <FormattedMessage id="bulk_edit" /> ({selected.length})
                    </>
                </Button>
            </Dropdown>
        );
    };

    useEffect(() => {
        if (bulkEdit) {
            stableOpenBulkEditModal();
        }
    }, [bulkEdit, stableOpenBulkEditModal]);

    const onCloseBulkEditModal = () => {
        closeBulkEditModal();
        setBulkEdit("");
    };

    return {
        onCloseBulkEditModal,
        getBulkEditModal,
        getBulkEdit,
        bulkEdit,
    };
}

export default useProductsBulkEdit;
