import { createSlice } from "@reduxjs/toolkit";
import { GIFT_CARD_ACCOUNT_DEFAULT_PAGE_SIZE } from "pages/gift-card-account/constants";
import _ from "lodash";

interface Giftcard {
    product_name: any;
    product_id: string;
    count: string;
    balance: string;
    book_value: string;
}

interface GiftCardAccounts {
    balance: number;
    book_value: number;
    code: string;
    customer_data: {
        firstname: string;
        email: string;
        lastname: string;
        phone: string;
    };
    date_created: string;
    date_expires: string;
    giftcardaccount_id: string;
    is_redeemable: string;
    last_updated: string;
    order_id: string;
    product_id: string;
    product_name: string;
    purchase_price: string;
    state: string;
    status: string;
}

export interface GiftCardAccountHistory {
    action: string;
    additional_info: string;
    balance_amount: string;
    balance_delta: string;
    giftcardaccount_id: string;
    history_id: string;
    updated_at: string;
}

interface GiftcardState {
    giftcards: Giftcard[];
    accounts: GiftCardAccounts[];
    accountsPaging: any;
    accountsPageSize: number;
    history: GiftCardAccountHistory[];
    historyTotal: number;
    historyRecordGiftcardAccountId: string;
    total: number;
    title: string;
    addCreditId: string;
    chargeId: string;
    currentId: string;
    oldSearch?: any;
    showCustomerGiftCardsModal: boolean;
    showGiftCardAccountModal: boolean;
    customerFirstName: string;
    customerLastName: string;
    customerPhone: string;
    customerEmail: string;
    maxAmount?: number;
    isHideZeroBalance: number;
    loading: boolean;
}

const initialState: GiftcardState = {
    giftcards: [],
    accounts: [],
    accountsPaging: {},
    accountsPageSize: GIFT_CARD_ACCOUNT_DEFAULT_PAGE_SIZE,
    history: [],
    total: 0,
    title: "",
    addCreditId: "",
    chargeId: "",
    currentId: "",
    historyTotal: 0,
    historyRecordGiftcardAccountId: "",
    showCustomerGiftCardsModal: false,
    showGiftCardAccountModal: false,
    customerFirstName: "",
    customerLastName: "",
    customerPhone: "",
    customerEmail: "",
    isHideZeroBalance: 1,
    loading: false,
};

const slice = createSlice({
    name: "giftcards",
    initialState,
    reducers: {
        setGiftcardState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getGiftcardsSuccess: (state, { payload }) => {
            state.giftcards = payload?.giftcards;
            state.oldSearch = payload?.oldSearch;
            state.total = payload?.giftcards?.length;
        },
        getGiftcardAccountsSuccess: (state, { payload }) => {
            state.accounts = payload?.records;
            state.accountsPaging = payload?.paging;
        },
        getGiftcardAccountHistorySuccess: (state, { payload }) => {
            state.history = payload?.records;
            state.historyTotal = payload?.total;
            state.historyRecordGiftcardAccountId = payload?.historyRecordGiftcardAccountId;
        },
        updateGiftcardBalance: (state, { payload }) => {
            const giftcardAccounts = _.cloneDeep(state.accounts);
            const updatedGiftcard = giftcardAccounts.find(
                (account) => account.giftcardaccount_id === payload.updatedGifitcardAccountId
            );
            if (updatedGiftcard !== undefined) {
                updatedGiftcard.balance += payload.amount;
            }
            state.accounts = giftcardAccounts;
        },
        setGiftcardLoading: (state, { payload }) => {
            state.loading = payload.loading;
        },
    },
});

export const {
    setGiftcardState,
    getGiftcardsSuccess,
    getGiftcardAccountsSuccess,
    getGiftcardAccountHistorySuccess,
    updateGiftcardBalance,
    setGiftcardLoading,
} = slice.actions;

export const giftcards = slice.reducer;
