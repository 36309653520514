import { Modal } from "antd";
import React, { useState } from "react";

function emptyFunction() {
    // does nothing
}

const useCallbackModal = (className = "", closeCallback = emptyFunction): any => {
    const [open, setModal] = useState(false);

    const openModal = () => setModal(true);

    const closeModal = () => setModal(false);

    const getModal = (child: any, modalProps: any) => {
        const onCancel = () => {
            if (modalProps.onCancel) {
                modalProps.onCancel(() => {
                    closeCallback();
                    closeModal();
                });
            } else {
                closeCallback();
                closeModal();
            }
        };

        return (
            <Modal visible={open} {...modalProps} className={className} onCancel={onCancel}>
                {child}
            </Modal>
        );
    };

    return {
        getModal,
        setModal,
        openModal,
        closeModal,
    };
};

export default useCallbackModal;
