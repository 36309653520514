import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { getIntl } from "../locale";
import { RootState } from "../app/reducer";
import { oauth, api } from "../util/api";
import { toast } from "react-toastify";
import ObjectModel from "../util/models";
import qs from "querystring";
import dH from "../pages/settings/pages/discounts/_components/helper";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import StoreAutoDiscounts from "../util/models/store-auto-discounts";
import {
    getDiscountCodesSuccess,
    getDiscountsSuccess,
    clearDiscountCodes,
    setCreate,
    clearCurrDisc,
    setState,
} from "../slices/discounts";
import { CouponCodeValidationRequestBody } from "pages/settings/pages/discounts/models/coupon-requests";

export const getCoupons =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        if (!params.product_id) {
            dispatch(clearDiscountCodes());
        }
        try {
            const response: any = await oauth("PRODUCTS")({
                method: "POST",
                body: JSON.stringify({ search_condition: params }),
            });
            switch (response.RC) {
                case 200:
                    response.records.map((coupon: any) => {
                        dispatch(getDiscountCodesSuccess({ data: coupon, used: coupon?.used, one: coupon?.one }));
                    });
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getCouponInfo =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "COUPON",
                qs.stringify({ code: params.code })
            )({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getDiscountCodesSuccess({ data: response.records, used: params?.used, one: params.one }));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const deleteCoupon =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "COUPON_DELETE",
                "",
                String(params)
            )({
                method: "DELETE",
            });
            switch (response.RC) {
                case 200:
                    dispatch(
                        getCoupons({
                            product_type: dH.COUPON,
                            product_status: dH.ALL_PRODUCTS,
                            product_id: params.product_id,
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateCoupon =
    (params: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("COUPON")({
                method: "POST",
                body: JSON.stringify({ data: { ...params?.coupon } }),
            });
            switch (response?.RC) {
                case 200:
                    if (params?.add) {
                        dispatch(clearCurrDisc());
                    }
                    dispatch(
                        getCoupons({
                            product_type: dH.COUPON,
                            product_status: dH.ALL_PRODUCTS,
                            product_id: params.product_id,
                        })
                    );
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: "discount_codes" })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    dispatch(setCreate({ value: false }));
                    break;
                case 400:
                    dispatch(
                        setState({
                            errorTitle: "error",
                            errorMessage: "unexpect_error",
                        })
                    );
                    break;
                case 514:
                    dispatch(
                        setState({
                            errorTitle: "code_exists",
                            errorMessage: "use_unique_code",
                        })
                    );
                    break;
                default:
                    dispatch(
                        setState({
                            errorTitle: "error",
                            errorMessage: "unexpect_error",
                        })
                    );
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getStoreDiscounts =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("STORE_DETAILS")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(
                        getDiscountsSuccess({
                            storeAutoDiscounts: new ObjectModel(StoreAutoDiscounts, response.records).getData(),
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateStoreDiscounts =
    (params?: Record<string, any> | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("STORE_DETAILS")({
                method: "POST",
                body: JSON.stringify({ data: { discount: { ...params.discount } } }),
            });
            switch (response.RC) {
                case 200: {
                    dispatch(getStoreDiscounts());
                    const savedModel = params.changeLocation ?? "store_information";
                    toast(
                        createMessage(
                            `${intl.formatMessage({ id: savedModel })} ${intl.formatMessage({ id: "saved" })}`,
                            HiInformationCircle
                        )
                    );
                    break;
                }
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const validateCouponCode =
    (body: CouponCodeValidationRequestBody) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await api("VALIDATE_COUPON")({
                method: "POST",
                body: JSON.stringify(body),
            });

            switch (response.RC) {
                case 200:
                case 400:
                    dispatch(setState({ invalidCouponCode: true }));
                    break;
                case 204:
                default:
                    dispatch(setState({ invalidCouponCode: false }));
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
