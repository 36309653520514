import { Button, Descriptions, Form } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import { getDescriptionItem, getTextInput } from "../../../../../../components/form";
import { getStoreDetails, updateStoreDetails } from "../../../../../../services/store";
import ObjectModel from "../../../../../../util/models";
import config from "../../../../../../config";
import StoreSEO from "../../../../../../util/models/store-seo";
import { Prompt, useLocation } from "react-router-dom";

function StoreSEOTab(props: { lan: string }): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const storeSEO = useSelector((state: RootState) => state.store?.storeSEO);
    const records = useSelector((state: RootState) => state.store?.records);
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        if (storeSEO["url_key"] === data["url_key"]) {
            data = _.omit(data, ["url_key"]);
        }
        setTouched(false);
        dispatch(updateStoreDetails(new ObjectModel(StoreSEO).convertToPostData(data, records, "seo")));
    };

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
    }, [location, form]);

    return !_.isEmpty(storeSEO) ? (
        <React.Fragment>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="general-settings has-floating-submit">
                <Form
                    form={form}
                    name="general-settings"
                    onFinish={onFinish}
                    initialValues={storeSEO}
                    className="grouped-inputs"
                    onValuesChange={handleValuesChanged}
                >
                    <div className="form-title-description">
                        <h1 className="ant-descriptions-title">
                            <FormattedMessage id="seo_title" />
                        </h1>
                        <p className="ant-descriptions-description">
                            <FormattedMessage id="seo_description" />
                        </p>
                    </div>
                    {config.LANGUAGES.map((lan) => (
                        <Descriptions key={lan} style={{ display: lan !== props.lan ? "none" : "block" }} bordered>
                            {getDescriptionItem(
                                "meta_title",
                                3,
                                getTextInput({ name: "store_meta_" + lan, allowClear: true })
                            )}
                            {getDescriptionItem(
                                "meta_keywords",
                                3,
                                getTextInput({ name: "store_meta_keywords", rows: 3, allowClear: true })
                            )}
                            {getDescriptionItem(
                                "meta_description",
                                3,
                                getTextInput({ name: "store_meta_desc_" + lan, rows: 3, allowClear: true })
                            )}
                        </Descriptions>
                    ))}

                    <Descriptions className="general-section" bordered>
                        {getDescriptionItem("url_key", 3, getTextInput({ name: "url_key", allowClear: true }))}
                    </Descriptions>

                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreSEOTab;
