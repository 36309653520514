import _ from "lodash";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/reducer";
import usePlacesAutocomplete from "../../../hooks/usePlacesAutoComplete";

function useStoreSignUpOptions(): any {
    const intl = useIntl();
    const { plans, types, storeInformation } = useSelector((state: RootState) => ({
        lan: state?.setting?.lan,
        plans: state?.pricePlan?.plans,
        types: state.store?.types,
        records: state.store?.records,
        store: state.store,
        storeInformation: state?.store?.storeInformation,
    }));
    const {
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete();

    const locationsOptions = [
        { value: "1", label: "1" },
        { value: "2-5", label: "2-5" },
        { value: "6-10", label: "6-1" },
        { value: "11-25", label: "11-25" },
        { value: "25+", label: "25+" },
    ];

    const deliveryOptions = [
        { value: 1, label: intl.formatMessage({ id: "yes" }) },
        { value: 0, label: intl.formatMessage({ id: "no" }) },
    ];

    const languages = [
        { value: "en", label: intl.formatMessage({ id: "en" }) },
        { value: "zh", label: intl.formatMessage({ id: "zh" }) },
        { value: "zh-Hant", label: intl.formatMessage({ id: "zh-Hant" }) },
        { value: "fr", label: intl.formatMessage({ id: "fr" }) },
    ];

    const getGoogleOptions = () => {
        return data.map((option: any) => ({
            value: option?.place_id,
            label: option?.description,
        }));
    };

    const getPlanOptions = () => {
        return plans.map((p) => ({
            value: p.id.toString(),
            label: p.name,
        }));
    };

    const getTypeOptions = (type: any) => {
        if (Array.isArray(types[type])) {
            return types[type]
                ?.filter?.((child: any) => child?.name !== "All")
                ?.reduce?.((acc: any, sub: any) => {
                    if (sub.children) {
                        acc = [...acc, ...sub.children.filter((child: any) => child?.name !== "All")];
                    } else {
                        acc.push(sub);
                    }
                    return acc;
                }, [])
                .map((option: any) => ({
                    label: option?.name,
                    value: Number(option?.id),
                }));
        }
        return [];
    };

    const getCategoryValues = () => {
        const info = _.cloneDeep(storeInformation);
        return info?.sub_categories?.split?.(":")?.reduce?.((acc: any, category: any) => {
            if (getTypeOptions(1)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.restaurant = [...(acc?.restaurant ?? []), Number(category)];
            }
            if (getTypeOptions(4)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.shopping = [...(acc?.shopping ?? []), Number(category)];
            }
            if (getTypeOptions(5)?.find((option: any) => Number(option.value) === Number(category))) {
                acc.service = [...(acc?.service ?? []), Number(category)];
            }
            return acc;
        }, {});
    };

    return {
        setValue,
        locationsOptions,
        deliveryOptions,
        languages,
        getGoogleOptions,
        getPlanOptions,
        getCategoryValues,
        getTypeOptions,
    };
}

export default useStoreSignUpOptions;
