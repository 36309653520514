import { Button, Row, Col } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import helper from "../../../../util/helper";
import { IoMdAdd } from "react-icons/io";
import { setAddModalState, setRedeemModalState, setReferralsState } from "../../../../slices/referrals";
import { getReferralTransaction } from "../../../../services/referrals";
import moment from "moment";
import { formatOrderId } from "../../../order-history/helper";
import { getCustomerId } from "./helper";

class PointsBalance extends Component {
    state = {};

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    getCid = (props = this.props) => getCustomerId(props);

    getName = () => {
        const firstName = _.get(this.props, "customer.firstname");
        const lastName = _.get(this.props, "customer.lastname");
        const fullName = `${firstName ? `${firstName} ` : ""}${lastName || ""}`;
        return fullName;
    };

    getHistory = () => (Array.isArray(this.props.pointsHistory) ? this.props.pointsHistory : []);

    showHistory = () => {
        return this.state.showHistory && !_.isEmpty(this.getHistory());
    };

    renderTitle = () => {
        const points = this.props.customer?.points_balance;
        return (
            <div className="customer-section-title">
                <FormattedMessage id="points_balance" />
                {points ? (
                    <Button
                        onClick={() => {
                            this.props.setReferralsState({
                                transactionsSearchConditions: {
                                    customer_id: this.getCid(),
                                },
                            });
                        }}
                        size="large"
                        type="link"
                    >
                        <FormattedMessage id="view_all_records" />
                    </Button>
                ) : null}
            </div>
        );
    };

    renderSummaryInfo = () => {
        const points = this.props.customer?.points_balance;
        const pointsValue = points / (this.props.p2v != 0 ? this.props.p2v : 1);
        if (points)
            return (
                <div className="customer-points-balance-summary">
                    <div>
                        {helper.formatNumber(points)}&nbsp;·&nbsp;
                        {helper.formatCurrency(pointsValue, this.props.storeCurrency)}
                    </div>
                    {this.showHistory() ? null : (
                        <div>
                            <Button
                                onClick={() => {
                                    this.props.getReferralTransaction({
                                        page: 1,
                                        limit: 3,
                                        customer_record: true,
                                        customer_id: this.getCid(),
                                    });
                                    this.setState({
                                        showHistory: true,
                                    });
                                }}
                                size="large"
                                type="link"
                            >
                                <FormattedMessage id="show_last_3_records" />
                            </Button>
                        </div>
                    )}
                </div>
            );
    };

    renderHistory = () => {
        return this.showHistory() ? (
            <div className="customer-history-table">
                <Row className="customer-history-title-row">
                    <Col span={10}>
                        <FormattedMessage id="date" />
                    </Col>
                    <Col span={4}></Col>
                    <Col style={{ textAlign: "end" }} span={10}>
                        <FormattedMessage id="points_change" />
                    </Col>
                </Row>
                <div>
                    {this.getHistory().map((history, index) => (
                        <Row
                            key={`customer-history-table-row-${history.order_id}-${index}`}
                            className="customer-history-table-row"
                        >
                            <Col span={10}>
                                {moment(history.created_at, "YYYY-MM-DD hh:mm:ss").format("MMM D, hh:mm A")}
                            </Col>
                            <Col span={4}>{history.order_id ? `#${formatOrderId(history.order_id)}` : ""}</Col>
                            <Col style={{ textAlign: "end" }} span={10}>
                                {history.points_delta > 0 ? "+" : "-"}
                                {helper.formatNumber(Math.abs(history.points_delta))}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        ) : null;
    };

    renderActions = () => {
        const points = this.props.customer?.points_balance;
        return (
            <div className="customer-points-balance-summary">
                <Button
                    icon={<IoMdAdd />}
                    onClick={() =>
                        this.props.setAddModalState({
                            customer_id: this.getCid(),
                            customer_name: this.getName(),
                            reward_balance: this.props.customer?.points_balance,
                            refresh_customer: true,
                        })
                    }
                    size="large"
                    type="link"
                >
                    <FormattedMessage id="add_points" />
                </Button>
                {points ? (
                    <Button
                        onClick={() => {
                            this.props.setRedeemModalState({
                                customer_id: this.getCid(),
                                customer_name: this.getName(),
                                reward_balance: this.props.customer?.points_balance,
                                refresh_customer: true,
                            });
                        }}
                        size="large"
                        type="link"
                    >
                        <FormattedMessage id="redeem_points" />
                    </Button>
                ) : null}
            </div>
        );
    };

    render() {
        return (
            <div className="customer-detail-section customer-points-balance section-padding">
                {this.renderTitle()}
                {this.renderSummaryInfo()}
                {this.renderHistory()}
                {this.renderActions()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    p2v: state.store?.storeBasic?.points_to_value,
    pointsHistory: state?.customers?.pointsHistory,
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setReferralsState,
    setAddModalState,
    setRedeemModalState,
    getReferralTransaction,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PointsBalance)));
