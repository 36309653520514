const StoreBasic = `
    surcharge_setting.is_enabled,            is_enabled,             false;
    surcharge_setting.surcharge_label,       surcharge_label,        true;
    surcharge_setting.surcharge,             surcharge,              false;
    surcharge_setting.surcharge_tax_class,   surcharge_tax_class,    false;
    surcharge_setting.surcharge_type,        surcharge_type,         false;
    surcharge_setting.surcharge_order_type,  surcharge_order_type,   false;
    surcharge_setting.surcharge_min,         surcharge_min,          false;
    surcharge_setting.surcharge_max,         surcharge_max,          false;
`;

export default StoreBasic;
