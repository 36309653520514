import { createSlice } from "@reduxjs/toolkit";
import { Product } from "./products";

export interface Category {
    category_id: number;
    is_active: number;
    level: number;
    menu_ids: number[];
    name: any;
    parent_id: number;
    path: string;
    position: number;
    product_count: number;
    draggableId?: string;
}

interface CategoryState {
    categories: Category[];
    products: Product[];
    loading: boolean;
}

const initialState: CategoryState = {
    categories: [],
    products: [],
    loading: false,
};

const slice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setCategoriesState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getCategoriesSuccess(state, { payload }) {
            const categories: Category[] = payload
                .map((category: any) => category)
                .sort((a: Category, b: Category) => a.position - b.position);
            const sortedByLevelAndParents: Category[] = [];

            categories.forEach((category) => {
                if (category.parent_id === 1) {
                    sortedByLevelAndParents.push(category);
                    categories.forEach((nestedCategory) => {
                        if (category.category_id === nestedCategory.parent_id) {
                            sortedByLevelAndParents.push(nestedCategory);
                            categories.forEach((deepNestedCategory) => {
                                if (nestedCategory.category_id === deepNestedCategory.parent_id) {
                                    sortedByLevelAndParents.push(deepNestedCategory);
                                }
                            });
                        }
                    });
                }
            });

            state.categories = sortedByLevelAndParents.map((c) => ({
                ...c,
                draggableId: c.category_id + "-" + c.position,
            }));
        },
        getCategoryProductsSuccess(state, { payload }) {
            state.products = payload.map((category: any) => category);
        },
        updateCategoryOrder(state, { payload }: { payload: Category[] }) {
            payload.forEach((category) => {
                const index = state.categories.findIndex((c) => c.category_id === category.category_id);
                state.categories[index] = { ...state.categories[index], ...category };
            });

            const categories = state.categories.sort((a: Category, b: Category) => a.position - b.position);

            const sortedByLevelAndParents: Category[] = [];

            categories.forEach((category) => {
                if (category.parent_id === 1) {
                    sortedByLevelAndParents.push(category);
                    categories.forEach((nestedCategory) => {
                        if (category.category_id === nestedCategory.parent_id) {
                            sortedByLevelAndParents.push(nestedCategory);
                            categories.forEach((deepNestedCategory) => {
                                if (nestedCategory.category_id === deepNestedCategory.parent_id) {
                                    sortedByLevelAndParents.push(deepNestedCategory);
                                }
                            });
                        }
                    });
                }
            });

            state.categories = sortedByLevelAndParents.map((c) => ({
                ...c,
                draggableId: c.category_id + "-" + c.position,
            }));
        },
    },
});

export const { getCategoriesSuccess, getCategoryProductsSuccess, updateCategoryOrder, setCategoriesState } =
    slice.actions;

export const categories = slice.reducer;
