import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { RootState } from "./../../../app/reducer";
import { SelectOption } from "./../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../../services/customers";
import { setFilters, setFilter, clearFilters } from "../../../slices/customers";

interface FilterModalProps {
    onApply: () => void
}

function FilterModal(props: FilterModalProps): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [rangeType, setRangeType] = useState<number>(0);
    const filters = useSelector((state: RootState) => state?.customers?.filters);
    let timezone = useSelector((state: RootState) => state?.store?.storeInformation?.address_time_zone);
    timezone = timezone ?? "America/Vancouver";
    const last_order_date_from = _.get(filters, "last_order_date_from", "");
    const last_order_date_to = _.get(filters, "last_order_date_to", "");
    const amt_from = _.get(filters, "amt_from", "");
    const amt_to = _.get(filters, "amt_to", "");
    const qty_from = _.get(filters, "qty_from", "");
    const qty_to = _.get(filters, "qty_to", "");
    const firstname = _.get(filters, "firstname", "");
    const lastname = _.get(filters, "lastname", "");
    const email = _.get(filters, "email", "");
    const phone = _.get(filters, "phone", "");
    const id = _.get(filters, "id", "");
    const DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
    const ANY_DATE = 0;
    const TODAY = 1;
    const YESTERDAY = 2;
    const THIS_WEEK = 3;
    const THIS_MONTH = 4;
    const THIS_YEAR = 5;
    const CUSTOM = 6;

    const dateRanges: SelectOption[] = [
        { value: ANY_DATE, label: intl.formatMessage({ id: "any_date" }) },
        { value: TODAY, label: intl.formatMessage({ id: "today" }) },
        { value: YESTERDAY, label: intl.formatMessage({ id: "yesterday" }) },
        { value: THIS_WEEK, label: intl.formatMessage({ id: "this_week" }) },
        { value: THIS_MONTH, label: intl.formatMessage({ id: "this_month" }) },
        { value: THIS_YEAR, label: intl.formatMessage({ id: "this_year" }) },
        { value: CUSTOM, label: intl.formatMessage({ id: "custom_range" }) },
    ];

    const clearFilter = () => {
        dispatch(clearFilters());
        dispatch(setFilter({ value: false }));
        dispatch(getCustomers({ page: 1, limit: 10 }));
    };

    const updateDateRange = (e: any) => {
        setRangeType(e);
        const today = moment().tz(timezone).format("YYYY-MM-DD");
        const time = moment().tz(timezone).format("hh:mm:ss");
        const after6AM = moment().tz(timezone).isAfter(`${today} 06:00:00`);
        const yesterday = moment().tz(timezone).subtract(1, "day").format("YYYY-MM-DD");

        if (e === TODAY) {
            if (after6AM) {
                dispatch(setFilters({ name: "last_order_date_from", value: `${today} 06:00:00` }));
                dispatch(setFilters({ name: "last_order_date_to", value: `${today} ${time}` }));
            } else {
                dispatch(setFilters({ name: "last_order_date_from", value: `${yesterday} 06:00:00` }));
                dispatch(setFilters({ name: "last_order_date_to", value: `${today} ${time}` }));
            }
        } else if (e === YESTERDAY) {
            const yesterday2 = moment().tz(timezone).subtract(2, "day").format("YYYY-MM-DD");
            if (after6AM) {
                dispatch(setFilters({ name: "last_order_date_from", value: `${yesterday} 06:00:00` }));
                dispatch(setFilters({ name: "last_order_date_to", value: `${today} 06:00:00` }));
            } else {
                dispatch(setFilters({ name: "last_order_date_from", value: `${yesterday2} 06:00:00` }));
                dispatch(setFilters({ name: "last_order_date_to", value: `${yesterday} 06:00:00` }));
            }
        } else if (e === THIS_WEEK) {
            const lastWeek = moment().tz(timezone).subtract(7, "day").format("YYYY-MM-DD");
            dispatch(setFilters({ name: "last_order_date_from", value: `${lastWeek} 06:00:00` }));
            dispatch(setFilters({ name: "last_order_date_to", value: `${today} ${time}` }));
        } else if (e === THIS_MONTH) {
            const lastMonth = moment().tz(timezone).subtract(1, "month").format("YYYY-MM-DD");
            dispatch(setFilters({ name: "last_order_date_from", value: `${lastMonth} 06:00:00` }));
            dispatch(setFilters({ name: "last_order_date_to", value: `${today} ${time}` }));
        } else if (e === THIS_YEAR) {
            const lastYear = moment().tz(timezone).subtract(1, "year").format("YYYY-MM-DD");
            dispatch(setFilters({ name: "last_order_date_from", value: `${lastYear} 06:00:00` }));
            dispatch(setFilters({ name: "last_order_date_to", value: `${today} ${time}` }));
        }
    };

    const filterCodes = () => {
        const isFilters = { ...filters };
        Object.keys(isFilters)?.forEach((key) => {
            if (!filters[key]) {
                delete isFilters[key];
            }
        });
        dispatch(setFilter({ value: false }));
        dispatch(getCustomers({ ...isFilters }));
    };

    const applyFiltersOnClick = () => {
        filterCodes();
        props.onApply();
    }

    const renderActions = (
        <div className="filter-modal-footer d-flex justify-content-between">
            <Button type="link" onClick={() => clearFilter()}>
                <FormattedMessage id="reset" />
            </Button>
            <Button type="primary" onClick={applyFiltersOnClick}>
                <FormattedMessage id="apply_filters" />
            </Button>
        </div>
    );

    const getFilterInput = (title: string, name: string, value: any, type: string) => (
        <div className="w100 mb-3">
            <div className="filter-subtitle">
                <FormattedMessage id={title} />
            </div>
            <input
                className="filter-input-field-long"
                value={value}
                placeholder={intl.formatMessage({ id: title })}
                onChange={(e: any) => dispatch(setFilters({ name, value: e.target.value }))}
                type={type}
            />
        </div>
    );

    const getDoubleFilterInput = (
        title1: string,
        name1: string,
        value1: any,
        title2: string,
        name2: string,
        value2: any
    ) => (
        <div className="w100 mb-3 d-flex justify-content-between">
            <div style={{ width: "48%" }}>
                <div className="filter-subtitle">
                    <FormattedMessage id={title1} />
                </div>
                <input
                    className="filter-input-field-double"
                    value={value1}
                    placeholder={intl.formatMessage({ id: title1 })}
                    onChange={(e: any) => dispatch(setFilters({ name: name1, value: e.target.value }))}
                    type={name1 === "phone" ? "number" : "text"}
                />
            </div>
            <div style={{ width: "48%" }}>
                <div className="filter-subtitle">
                    <FormattedMessage id={title2} />
                </div>
                <input
                    className="filter-input-field-double"
                    value={value2}
                    placeholder={intl.formatMessage({ id: title2 })}
                    onChange={(e: any) => dispatch(setFilters({ name: name2, value: e.target.value }))}
                    type="text"
                />
            </div>
        </div>
    );

    const getRangeFilter = (title: string, nameF: string, nameT: string, valueF: any, valueT: any) => (
        <React.Fragment>
            <div className="filter-subtitle mt-3">
                <FormattedMessage id={title} />
            </div>
            <div className="d-flex w100 justify-content-between">
                <input
                    className="custom-input-textfield filter-range"
                    type="number"
                    value={valueF ?? NaN}
                    placeholder={intl.formatMessage({ id: "from" })}
                    onChange={(e: any) => dispatch(setFilters({ name: nameF, value: e.target.value }))}
                />
                <hr className="dash" />
                <input
                    className="custom-input-textfield filter-range"
                    type="number"
                    value={valueT ?? NaN}
                    placeholder={intl.formatMessage({ id: "to" })}
                    onChange={(e: any) => dispatch(setFilters({ name: nameT, value: e.target.value }))}
                />
            </div>
        </React.Fragment>
    );

    const renderDateRangeSelect = (
        <div className="w100 mb-2">
            <div className="filter-subtitle">
                <FormattedMessage id="last_order_date_range" />
            </div>
            <Select className="select-input" value={rangeType} onChange={(e) => updateDateRange(e)}>
                {dateRanges?.map((option: SelectOption) => (
                    <Select.Option key={option?.value} value={option?.value}>
                        {" "}
                        {option?.label}{" "}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );

    const renderDateRange = (
        <React.Fragment>
            <div className="filter-subtitle mt-2">
                <FormattedMessage id="date_range" />
            </div>
            <div className="d-flex">
                <div className="d-flex flex-column mr-4">
                    <DatePicker
                        className="datepicker custom-input-textfield"
                        value={last_order_date_from ? moment(last_order_date_from) : undefined}
                        format={DATE_FORMAT}
                        placeholder={intl.formatMessage({ id: "from" })}
                        onChange={(date) => formatDate(date, "FROM")}
                    />
                </div>
                <div className="d-flex flex-column">
                    <DatePicker
                        className="filter-range custom-input-textfield"
                        value={last_order_date_to ? moment(last_order_date_to) : undefined}
                        format={DATE_FORMAT}
                        placeholder={intl.formatMessage({ id: "to" })}
                        onChange={(date) => formatDate(date, "TO")}
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const formatDate = (date: any, name: string) => {
        dispatch(
            setFilters({
                name: name === "TO" ? "last_order_date_to" : "last_order_date_from",
                value: date.format(DATE_FORMAT),
            })
        );
    };

    const getLongFilters = (
        <React.Fragment>
            {getFilterInput("id", "id", id, "number")}
            {getDoubleFilterInput("first_name", "firstname", firstname, "last_name", "lastname", lastname)}
            {getDoubleFilterInput("customer_phone", "phone", phone, "customer_email", "email", email)}
        </React.Fragment>
    );

    const renderOrderRanges = (
        <React.Fragment>
            {getRangeFilter("total_spent", "amt_from", "amt_to", amt_from, amt_to)}
            {getRangeFilter("total_number_orders", "qty_from", "qty_to", qty_from, qty_to)}
        </React.Fragment>
    );

    const renderFilterForm = () => (
        <div className="customer-filter-modal d-flex flex-column justify-content-start align-items-start">
            {getLongFilters}
            {renderDateRangeSelect}
            {rangeType === CUSTOM ? renderDateRange : null}
            {renderOrderRanges}
            {renderActions}
        </div>
    );

    return renderFilterForm();
}

export default FilterModal;
