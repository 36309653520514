import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";

interface ReferralsModalValueRendererProps {
    renderValue: (p: any) => any;
    value: any[];
}

function ReferralsModalValueRenderer(props: ReferralsModalValueRendererProps): JSX.Element | null {
    const records = useSelector((state: RootState) => state?.referrals?.orderHistory);
    const { renderValue } = props;

    const record = records?.find((r: any) => r?.order_id === props?.value);

    return record ? <div>{renderValue(record)}</div> : null;
}

export default ReferralsModalValueRenderer;
