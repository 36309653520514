import { Popover } from "antd";
import React from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setReferralsState } from "../../../../slices/referrals";

interface CustomerActionsProps {
    openOrdersPage: (value: any) => any;
    openCustomerDetailPage: (value: any) => any;
    openCouponMessage: (value: any) => any;
    openCustomerGiftCards: (value: any) => any;
    sendMessage: (value: any) => any;
    value: any[];
}

function CustomerActionsRenderer(props: CustomerActionsProps): JSX.Element | null {
    const { openOrdersPage, openCustomerDetailPage, openCouponMessage, openCustomerGiftCards, sendMessage } = props;
    const customers = useSelector((state: RootState) => state?.customers?.customers);
    const dispatch = useDispatch();
    const record = customers?.find((customer: any) => Number(customer?.id) === Number(props?.value));
    const v2p = useSelector((state: RootState) => Number(state?.store?.storeReferrals?.valueToPoints));

    const search = useSelector((state: RootState) => state?.referrals?.transactionsSearchConditions);

    const getTransactionHistory = () => {
        dispatch(
            setReferralsState({
                transactionsSearchConditions: {
                    ...search,
                    customer_id: record?.id,
                },
            })
        );
    };

    const actions = (customer: any) => (
        <React.Fragment>
            <p className="popover-item" onClick={() => openCustomerDetailPage(customer)}>
                <FormattedMessage id="view_edit" />
            </p>
            <p className="popover-item" onClick={() => openOrdersPage(customer)}>
                <FormattedMessage id="view_orders" />
            </p>
            <p className="popover-item" onClick={() => openCouponMessage(customer)}>
                <FormattedMessage id="send_coupon" />
            </p>
            <p className="popover-item" onClick={() => openCustomerGiftCards(customer)}>
                <FormattedMessage id="view_giftcard" />
            </p>
            <p className="popover-item" onClick={() => sendMessage(customer)}>
                <FormattedMessage id="send_message" />
            </p>
            {v2p > 0 ? (
                <p className="popover-item" onClick={() => getTransactionHistory()}>
                    <FormattedMessage id="view_points_history" />
                </p>
            ) : null}
        </React.Fragment>
    );

    return record ? (
        <div>
            <Popover content={actions(record)} placement="left">
                <HiDotsHorizontal size="19" color="#3898c8" className="customers-actions-button" />
            </Popover>
        </div>
    ) : null;
}

export default CustomerActionsRenderer;
