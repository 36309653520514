import React from "react";
import { FormattedMessage } from "react-intl";
import { Radio } from "antd";
import sH from "../../helper";

export const CodeStyle = (props: { qrStyle: string, setQRStyle: any }): JSX.Element => (
    <div className="d-flex mb-3">
        <div className="store_name-title mr-3">
            <FormattedMessage id="qr_code_style" />:
        </div>
        <Radio.Group onChange={(e: any) => props.setQRStyle(e.target.value)} value={props.qrStyle}>
            <Radio value={sH.qrStyles.SQUARES}>
                <FormattedMessage id="squares" />
            </Radio>
            <Radio value={sH.qrStyles.DOTS}>
                <FormattedMessage id="dots" />
            </Radio>
        </Radio.Group>
    </div>
);
