import React from "react";
import { Item } from "../../../slices/delivery_request";
import { FormInputProps, SelectOption } from "../_components/Form/FormInput";

export const DELIVERY_TASK_STATUS = {
    all: 0,
    assigning_courier: 1,
    confirmed: 2,
    enroute_to_pickup: 3,
    arrived_at_pickup: 4,
    picked_up: 5,
    enroute_to_dropoff: 6,
    arrived_at_dropoff: 7,
    delivered: 8,
    cancelled: 9,
};

export const DELIVERY_STATUS_PENDING = 110;

export const TIME_PICKER_PLACEHOLDER = "pick_a_date";

export const DATE_FORMAT = "YYYY-MM-DD";

export const PAGE_LIMIT_DEFAULT = 20;

export const PAGE_SIZE_OPTIONS_DEFAULT = ["10", "20", "50"];

export const REQUEST_TIME_FORMAT = {
    sameDay: "hh:mm A, [Today][\n]ddd, MMM DD",
    nextDay: "hh:mm A, [Tomorrow][\n]ddd, MMM DD",
    nextWeek: "hh:mm A[\n]ddd, MMM DD ",
    lastDay: "hh:mm A, [Yesterday][\n]ddd, MMM DD",
    lastWeek: "hh:mm A[\n]ddd, MMM DD ",
    sameElse: "hh:mm A[\n]ddd, MMM DD ",
};

export const DUE_TIME_FORMAT = {
    sameDay: "hh:mm A, [Today]",
    nextDay: "hh:mm A, [Tomorrow]",
    nextWeek: "hh:mm A, ddd MMM DD ",
    lastDay: "hh:mm A, [Yesterday]",
    lastWeek: "hh:mm A, ddd MMM DD ",
    sameElse: "hh:mm A, ddd MMM DD ",
};

export const SHORT_TIME_FORMAT = {
    sameDay: "hh:mm A",
    nextDay: "hh:mm A",
    nextWeek: "hh:mm A",
    lastDay: "hh:mm A",
    lastWeek: "hh:mm A",
    sameElse: "hh:mm A",
};

export const TIME_PICKER_DATE_FORMAT = {
    sameDay: "ddd, MMM D",
    nextDay: "ddd, MMM D",
    nextWeek: "ddd, MMM D",
    lastDay: "ddd, MMM D",
    lastWeek: "ddd, MMM D",
    sameElse: "ddd, MMM D",
};

export const TIME_PICKER_STATUS = {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    sameElse: "ddd, MMM D",
};

export const PRODUCT_CATEGORY_PICKER_OPTIONS = [
    "food",
    "grocery",
    "cake",
    "flower",
    "document",
    "electronic_item",
    "appliance",
    "furniture",
    "other",
];

export const TAX_OPTIONS: SelectOption[] = [
    { name: "0", label: 0, value: 0 },
    { name: "5%", label: "5%", value: 0.05 },
    { name: "10%", label: "10%", value: 0.1 },
    { name: "11%", label: "11%", value: 0.11 },
    { name: "12%", label: "12%", value: 0.12 },
    { name: "13%", label: "13%", value: 0.13 },
    { name: "15%", label: "15%", value: 0.15 },
];

export const PAYMENT_STATUS_CODE_MAPPING = {
    0: "not_paid",
    1: "pending",
    2: "paid",
    3: "cancelled",
    4: "failed",
    5: "partial_refunded",
    6: "full_refunded",
    7: "voided",
};

export const TASK_STATUS_CODE_MAPPING = {
    100: "cancelled",
    110: "pending",
    200: "assigning_courier",
    220: "confirmed",
    230: "enroute_to_pickup",
    240: "arrived_at_pickup",
    250: "picked_up",
    260: "enroute_to_dropoff",
    270: "arrived_at_dropoff",
    280: "delivered",
};

export const TASK_STATUS_CODE_CATEGORY_MAPPING = {
    "new_pending": [110, 200],
    "ongoing": [220, 230, 240, 250, 260, 270],
    "delivered": [280],
    "cancelled": [100],
};

export const TASK_BG_COLOR_MAPPING = {
    "new_pending": "#FCE3C7",
    "ongoing": "#D5EDEE",
    "delivered": "#E6F4E8",
    "cancelled": "#DCDCDC",
};

export const TASK_TEXT_COLOR_MAPPING = {
    "new_pending": "#F78A17",
    "ongoing": "#38B6B3",
    "delivered": "#3BA64D",
    "cancelled": "#676767",
};

export const DASHER_NOT_FOUND_ERROR_MESSAGE = {
    "new_pending": "Driver info will appear soon",
    "ongoing": "Vander is heading to store",
    "delivered": "Task Delivered",
    "cancelled": "Task Cancelled",
};

export const TASK_STAUS_DESC_MAPPING = {
    110: "Order has not been sent to the delivery partner",
    200: "Dasher info will appear soon",
    220: "Dasher assigned",
    230: "Dasher is en route to the pick up location.",
    240: "Dasher has arrived at the pick up location. ",
    250: "Dasher has picked up the items.",
    260: "Dasher is en route to the drop off location.",
    270: "Dasher has arrived at the drop off location. ",
    280: "Delivery has been completed.",
    100: "Delivery has been canceled. ",
};

export const MANDATORY_FIELDS_MAPPING = {
    order_id: "orderId",
    order_value: "additionalInfo.orderValue",
    items: "additionalInfo.itemsInfo",
    tips: "additionalInfo.tipAmount",
    tip: "additionalInfo.tipAmount",
    pickup_time: "pickUp.time",
    delivery_time: "dropOff.time",
    address: "address",
    amount: "balanceInfo.amount",
};

export const ADD_NEW_ITEM_KEY = "addItem";

export const ADD_NEW_OPTION = "addOption";

export const EMPTY_ITEM: Item = {
    name: "",
    price: 0,
    quantity: 0,
    options: [],
};

export const DEFAULT_LAT = 40.73;

export const DEFAULT_LNG = -73.93;

export const ADDRESS_INFOS_REQUIRED_FIELDS = ["address", "firstName", "lastName", "phone", "countryCode", "zipCode"];

export const TIME_ASAP = "ASAP";

export const TIME_ASAP_DATE = "2000-01-01 00:00:00";

export const NAV_HEIGHT = 120;

export const HEADER_HEIGHT = 72 - 32; // 32px padding

export const FILTERS_HEIGHT = 50;

export const FOOTER_HEIGHT = 50;

export const COLLAPSED_PADDING_HEIGHT = 64;

export const UNCOLLAPSED_PADDING_HEIGHT = 40;

export const COUNTRY_CODE_MAPPING: SelectOption[] = [
    { name: "CA", value: "CA", label: "CA (+1)" },
    { name: "US", value: "US", label: "US (+1)" },
];

export const DELIVERY_GOOPTER_ORDER = 1;
export const DELIVERY_WITHOUT_GOOPTER_ORDER = 2;

export const NUMBER_INPUT_REG_EX = /^[0-9]{0,10}\.?[0-9]{1,2}$/;
/**
 * interfaces
 */
export interface AnyStyle {
    [key: string]: string;
}
export interface Row<TState> extends FormInputProps<TState> {
    label?: React.ReactNode;
    extraContent?: React.ReactNode;
    contentStyle?: AnyStyle;
}

