import { Button, Spin } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import {
    DeliveryTaskComponent,
    TableHeaderComponent,
    TaskDetailHeaderComponent,
    TasksPaginationComponent,
    TaskStyle,
} from ".";
import { RootState } from "../../../app/reducer";
import { usePrevious } from "../../../hooks/usePrevious";
import {
    ACTIONS_MAPPING,
    getDeliveryTasks,
    updateDeliveryTask,
    UpdateTaskRequest,
} from "../../../services/delivery_request";
import {
    clearUpdateTaskForm,
    MessageModalPages,
    setCurrentMessageModalPage,
    setOnActiveTask,
    setShouldMessageModalOpen,
    setShouldRefresh,
} from "../../../slices/delivery_request";
import { Task } from "../../../types/get-delivery-task-respone";
import { buildOnActiveTaskState, buildSearchRequestBody, getContainerHeight } from "./helper";
import { TaskTabsLayoutComponent } from "./tabs";

const Layout: React.FC<StateFromRedux> = ({
    isLoading,
    deliveryTasksList,
    paginations,
    getDeliveryTasks,
    isDrawerOpen,
    setOnActiveTask,
    onActiveTask,
    updatedTask,
    clearUpdateTaskForm,
    updateDeliveryTask,
    isFiltersCollapsed,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    shouldRefresh,
    setShouldRefresh,
    timesLot,
    storeInformation,
    originalOnActivetask,
}) => {
    const { limit, currentPage, searchConditions } = paginations;
    const { statuses, quickSearch } = searchConditions;
    const prevPaginations = usePrevious(paginations);
    const intl = useIntl();
    const _shouldDisableSubmit = _.isEmpty(Object.values(updatedTask).find((v) => !_.isEmpty(v)));

    useEffect(() => {
        if (!isDrawerOpen && (!_.isEqual(prevPaginations, paginations) || shouldRefresh)) {
            setShouldRefresh(false);
            getDeliveryTasks(buildSearchRequestBody(paginations, onActiveTask));
        }
    }, [limit, currentPage, statuses, quickSearch, shouldRefresh]);

    const handleOnSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        const req: UpdateTaskRequest = {
            action: ACTIONS_MAPPING.UPDATE_DELIVERY,
            delivery_id: onActiveTask?.delivery_id ?? "",
            order_number: onActiveTask?.order_number ?? "",
            pickup_window_start_time:
                onActiveTask?.pickup_window_start_time !== originalOnActivetask?.pickup_window_start_time
                    ? onActiveTask?.pickup_window_start_time ?? undefined
                    : undefined,
            delivery_window_start_time:
                onActiveTask?.delivery_window_start_time !== originalOnActivetask?.delivery_window_start_time
                    ? onActiveTask?.delivery_window_start_time ?? undefined
                    : undefined,
            order_value: updatedTask.additionalInfo?.orderValue ?? undefined,
            tip: updatedTask.additionalInfo?.tipAmount ?? undefined,
            item_category: updatedTask.additionalInfo?.itemsCategory,
            contains_alcohol: updatedTask.additionalInfo?.containsAlcohol ? 1 : 0,
            items: updatedTask.additionalInfo?.itemsInfo,
            tax: updatedTask.additionalInfo?.orderTax ?? undefined,
            sub_total: updatedTask.additionalInfo?.subTotal ?? undefined,
        };
        if (updatedTask.isPickUpAddress) {
            req.pickup_address_id = updatedTask.address;
        } else {
            req.dropoff_address_id = updatedTask.address;
        }
        updateDeliveryTask(req);
    };

    const handleClearUpdateForm = () => {
        clearUpdateTaskForm();
    };

    const handleCancelTask = () => {
        setCurrentMessageModalPage(MessageModalPages.CANCEL_DELIVERY_TASK_CONFIRM);
        setShouldMessageModalOpen(true);
    };

    const handleTrackDeliveryClick = () => {
        setCurrentMessageModalPage(
            !_.isEmpty(onActiveTask?.delivery_tracking_url)
                ? MessageModalPages.TRACK_DELIVERY_TASK
                : MessageModalPages.TRACK_DELIVERY_TASK_ERR
        );
        setShouldMessageModalOpen(true);
    };

    const renderTasks = (displayStyle: TaskStyle): React.ReactNode => {
        return deliveryTasksList.length > 0 ? (
            deliveryTasksList.map((task: Task) => {
                return (
                    <DeliveryTaskComponent
                        displayStyle={displayStyle}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.preventDefault();
                            setOnActiveTask(buildOnActiveTaskState(task, storeInformation));
                        }}
                        key={task.delivery_id}
                        task={task}
                        onActive={onActiveTask?.order_number === task.order_number}
                        timesLot={timesLot}
                    />
                );
            })
        ) : (
            <div className="task-container" style={{ textAlign: "center" }} key="no tasks records">
                <FormattedMessage id="alert_no_result_found" />
            </div>
        );
    };

    const renderNormalLayout = () => {
        return (
            <>
                <div
                    style={{
                        height: getContainerHeight(isFiltersCollapsed),
                        overflow: "auto",
                    }}
                >
                    {renderTasks(TaskStyle.TABLE)}
                </div>
                <TasksPaginationComponent />
            </>
        );
    };

    // task detail section when clicking on the task
    const renderOnActiveLayout = () => {
        const shouldShowActions = onActiveTask?.status !== 100 && onActiveTask?.status !== 280;
        return (
            <div style={{ display: "flex", height: getContainerHeight(isFiltersCollapsed) }}>
                <div style={{ width: "40%" }}>
                    <div style={{ height: "100%", overflow: "auto" }}>{renderTasks(TaskStyle.FLEX)}</div>
                    <TasksPaginationComponent />
                </div>
                <div
                    style={{
                        width: "60%",
                        marginLeft: "8px",
                        marginRight: "20px",
                        height: `calc(100% ${shouldShowActions ? "" : "+ 40px"})`,
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            backgroundColor: "white",
                        }}
                    >
                        <TaskDetailHeaderComponent />
                        <TaskTabsLayoutComponent />
                    </div>
                    {shouldShowActions && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                height: "40px",
                                marginTop: "8px",
                                backgroundColor: "white",
                                alignItems: "center",
                                padding: "25px",
                            }}
                        >
                            <div>
                                <Button className={"red-button"} onClick={handleCancelTask}>
                                    {intl.formatMessage({ id: "cancel_task" })}
                                </Button>
                            </div>
                            <div style={{ display: "flex", marginTop: "8px" }}>
                                <Button
                                    className="outlined-blue-button"
                                    style={{ marginRight: "35px" }}
                                    onClick={handleTrackDeliveryClick}
                                >
                                    {intl.formatMessage({ id: "track_delivery" })}
                                </Button>
                                <Button
                                    className="outlined-blue-button"
                                    style={{ marginRight: "35px" }}
                                    onClick={handleClearUpdateForm}
                                    disabled={_shouldDisableSubmit}
                                >
                                    {intl.formatMessage({ id: "cancel_changes" })}
                                </Button>
                                <Button
                                    className="outlined-blue-button"
                                    disabled={_shouldDisableSubmit}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        handleOnSaveClick(e);
                                    }}
                                >
                                    {intl.formatMessage({ id: "save" })}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            <TableHeaderComponent />
            <Spin spinning={isLoading}>{_.isEmpty(onActiveTask) ? renderNormalLayout() : renderOnActiveLayout()}</Spin>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.deliveryRequests.isLoading ?? true,
        deliveryTasksList: state.deliveryRequests.deliveryTasks ?? [],
        paginations: state.deliveryRequests.paginations ?? {},
        isDrawerOpen: state.deliveryRequests.drawer.shouldOpen,
        onActiveTask: state.deliveryRequests.onActiveTask,
        originalOnActivetask: state.deliveryRequests.originalOnActivetask,
        updatedTask: state.deliveryRequests.forms.updateTask,
        isFiltersCollapsed: state.deliveryRequests.isFiltersCollapsed,
        shouldRefresh: state.deliveryRequests.shouldRefresh,
        timesLot: state.deliveryRequests.forms.inputSelections.timesLot,
        storeInformation: state.store.storeInformation ?? {},
    };
};

const mapDispatchToProps = {
    getDeliveryTasks,
    setOnActiveTask,
    clearUpdateTaskForm,
    updateDeliveryTask,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    setShouldRefresh,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type StateFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(Layout);
