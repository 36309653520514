// UI components
import { Button, Modal } from "antd";
// lodash features make coding easier
import _ from "lodash";
// react features
import React, { Component } from "react";
// locale and translation features
import { FormattedMessage, injectIntl } from "react-intl";
// redux connector features
import { connect } from "react-redux";
// routing features
import { withRouter } from "react-router-dom";
// global helper functions
import helper from "../../../../util/helper";
// react icons
import { AiOutlineMail } from "react-icons/ai";
// edit customer info modal
import EditInfoModal from "./edit-customer-info-model";
// edit customer address modal
import EditAddressModal from "./edit-customer-address-modal";
// customer reducers
import { setAllSelectedCustomers, setMessage } from "../../../../slices/customers";
// customer message modal
import MessageModal from "../../_components/message-modal";
// validateCustomer helper method used for validating customer info fields
import { validateCustomer } from "../../helper";
import CopyArea from "components/copy-area";

// customer info section
class CustomerInfo extends Component {
    //translation string
    str = (id, values) => this.props.intl.formatMessage({ id }, values);
    // update state everytime customer info edit modal is opened
    // should always contain customer info data currently in the store
    openCustomerInfo = () => {
        this.setState({
            openEditInfo: true,
            customerInfo: {
                country_code: _.get(this.props, "customer.country_code", "CA"),
                language: _.get(this.props, "customer.language", "en"),
                firstname: _.get(this.props, "customer.firstname", ""),
                lastname: _.get(this.props, "customer.lastname", ""),
                email: _.get(this.props, "customer.email", ""),
                phone: _.get(this.props, "customer.phone", ""),
                customer_note: _.get(this.props, "customer.customer_note", ""),
            },
        });
    };
    // render customer info section
    renderCustomerInfo = () => {
        // get phone from props
        const phone = _.get(this.props, "customer.phone");

        // get email from props
        const email = _.get(this.props, "customer.email");

        // render section title
        const renderTitle = () => (
            <div className="customer-section-title">
                <FormattedMessage id="customer_info" />
                <Button onClick={this.openCustomerInfo} size="large" type="link">
                    <FormattedMessage id="edit" />
                </Button>
            </div>
        );

        // render customer phone number section
        const renderPhoneNumber = () => {
            return phone ? (
                <div className="customer-detail-customer-info-phone">
                    <div className="customer-detail-customer-info-title">
                        <FormattedMessage id="phone_number" />
                    </div>
                    <CopyArea
                        className="customer-detail-customer-info-value"
                        copyData={phone}
                        messageId="phone_number"
                        iconPosition="end"
                    >
                        {helper.formatPhone(phone)}
                    </CopyArea>
                </div>
            ) : null;
        };

        // render customer email section
        const renderEmail = () => (
            <div className="customer-detail-customer-info-email">
                <div>
                    {email ? (
                        <>
                            <div className="customer-detail-customer-info-title">
                                <FormattedMessage id="email" />
                            </div>
                            <CopyArea
                                className="customer-detail-customer-info-value"
                                copyData={email}
                                messageId="email"
                                iconPosition="end"
                            >
                                {email}
                            </CopyArea>
                        </>
                    ) : null}
                </div>
                <div
                    onClick={() => {
                        if (phone || email) {
                            this.props.setAllSelectedCustomers({ value: this.props.customer });
                            this.props.setMessage({ value: true });
                        }
                    }}
                    className="customer-detail-customer-info-icon"
                >
                    {phone || email ? <AiOutlineMail /> : null}
                </div>
            </div>
        );

        return (
            <div className="section-padding">
                {renderTitle()}
                {renderPhoneNumber()}
                {renderEmail()}
            </div>
        );
    };

    // render default address section
    renderDefaultAddress = () => {
        const renderTitle = () => (
            <div className="customer-section-title">
                <FormattedMessage id="default_address" />
                <Button onClick={() => this.setState({ openEditAddress: true })} size="large" type="link">
                    <FormattedMessage id="edit" />
                </Button>
            </div>
        );

        const renderAddress = () => {
            const address = _.get(this.props, "customer.default_address");
            //name
            const name = `${_.get(address, "fn") ? `${_.get(address, "fn")} ` : ""}${_.get(address, "ln") || ""}`;
            //company
            const company = _.get(address, "company");
            //unit + street
            const addressFirstLine = `${_.get(address, "unit") ? `#${_.get(address, "unit")}-` : ""}${
                _.get(address, "street") || ""
            }`;
            //city + region + zipcp
            const addressSecondLine = [_.get(address, "city"), _.get(address, "region"), _.get(address, "zipcd")]
                .filter((value) => value)
                .join(",");
            //buzz
            const addressThirdLine = `${_.get(address, "buzz") ? `Buzz: ${_.get(address, "buzz")}` : ""}`;
            //phone
            const phone = _.get(address, "tn") ? helper.formatPhone(_.get(address, "tn")) : "";

            const infos = [name, company, addressFirstLine, addressSecondLine, addressThirdLine, phone].filter(
                (value) => value
            );

            if (infos.length && _.get(address, "tn")) {
                infos.splice(infos.length - 1, 0, <div>&nbsp;</div>);
            }

            const COPY_ICON_RENDER_LOCATION_IN_ADDRESS = 2;

            return (
                <div>
                    {infos.length ? (
                        infos.map((value, index) => (
                            <div key={value}>
                                {index === COPY_ICON_RENDER_LOCATION_IN_ADDRESS ? ( //render the icon on 2nd address line
                                    <CopyArea
                                        copyData={`${addressFirstLine} ${addressSecondLine}`}
                                        messageId="address"
                                        iconPosition="end"
                                    >
                                        {value}
                                    </CopyArea>
                                ) : (
                                    value
                                )}
                            </div>
                        ))
                    ) : (
                        <FormattedMessage id="no_default_address_set" />
                    )}
                </div>
            );
        };

        return (
            <div className="section-padding">
                {renderTitle()}
                {renderAddress()}
            </div>
        );
    };

    // add validation fields to state
    // validate customer info fields everytime any of the fields are modified
    validateCustomerInfo = () => {
        const customerInfo = this.state?.customerInfo || {};
        const { errorFields, valid } = validateCustomer(customerInfo);
        this.setState(errorFields);
        return valid;
    };

    // render edit customer info modal
    // current component sets state for customer info modal data
    renderEditCustomerModal = () => (
        <EditInfoModal
            parentState={this.state}
            setParentState={(values) => this.setState(values, () => this.validateCustomerInfo())}
        />
    );

    // render edit customer address modal
    renderEditAddressModal = () => (
        <EditAddressModal parentState={this.state} setParentState={(values) => this.setState(values)} />
    );

    // render customer message modal
    renderMessageModal = () => {
        const messageOpen = this.props.state?.customers?.messageOpen;
        return (
            <Modal
                visible={messageOpen}
                title={this.props.intl.formatMessage({ id: "send_message_to_customers" })}
                width="490px"
                footer={null}
                onCancel={() => this.props.setMessage({ value: false })}
            >
                <MessageModal />
            </Modal>
        );
    };

    // render customer detail sections
    // render modals as well, but are mostly hidden based on each modal's visibility checks
    render() {
        return (
            <div className="customer-detail-section customer-info">
                {this.renderCustomerInfo()}
                <div className="customer-section-divider" />
                {this.renderDefaultAddress()}
                {this.renderEditCustomerModal()}
                {this.renderEditAddressModal()}
                {this.renderMessageModal()}
            </div>
        );
    }
}

// redux feature - map store state to component props with given selectors
// only need lan, state, and customer for current component
const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

// redux feature - map reducers to component
// only need setAllSelectedCustomers and setMessage reducers for current component
const mapDispatchToProps = { setAllSelectedCustomers, setMessage };

// export component with:
// react router features in props
// intl (translation) features
// redux features in props
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerInfo)));
