import React from "react";
import helper from "../../../../../util/helper";
import { View, Text } from "@react-pdf/renderer";

const NumberPoints = (styles: React.CSSProperties | any, props: { phone: number | string }): JSX.Element => (
    <React.Fragment>
        <View style={styles.phone}>
            <Text>{helper.formatPhone(props.phone)}</Text>
        </View>
        <View style={styles.earnPoints}>
            <Text> Order online to earn rewards </Text>
        </View>
    </React.Fragment>
);
export default NumberPoints;
