import { Modal } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE_SIZE } from "../../../../app/constants";
import { RootState } from "../../../../app/reducer";
import { getReferralTransaction } from "../../../../services/referrals";
import { setReferralsState } from "../../../../slices/referrals";
import ReferralModalFilterBar from "./filter";
import ModalPagination from "./pagination";
import ModalTable from "./table";

const selector = (state: RootState) => ({
    transactions: state?.referrals?.transactions,
    paging: state?.referrals?.transactionsPaging,
    searchConditions: state?.referrals?.transactionsSearchConditions,
});

function ReferralTransactionHistory(): JSX.Element {
    const dispatch = useDispatch();
    const { transactions, searchConditions } = useSelector(selector);

    const onClose = () => {
        dispatch(
            setReferralsState({
                transactions: [],
                transactionsPaging: {},
                transactionsSearchConditions: {
                    page: 1,
                    limit: DEFAULT_PAGE_SIZE,
                },
            })
        );
    };

    useEffect(() => {
        if (searchConditions?.customer_id) {
            dispatch(getReferralTransaction(searchConditions));
        }
    }, [searchConditions, dispatch]);

    return (
        <Modal
            title={<FormattedMessage id="points_history" />}
            visible={(transactions?.length ?? 0) > 0}
            onCancel={onClose}
            width={1028}
            footer={null}
        >
            <div className="points-history-table">
                <ReferralModalFilterBar />
                <ModalTable />
                <ModalPagination />
            </div>
        </Modal>
    );
}

export default ReferralTransactionHistory;
