import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Button, Modal, Radio } from "antd";
import { DESKTOP_WIDTH } from "pages/settings/constants";
import { getShippingOptions } from "util/helper";
import "./shipping-options-modal.scss";
import { IoMdClose } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import config from "config";
import { getAdminDetails } from "services/admin";
import { clearData } from "slices/create-order";
import { setCustomersState } from "slices/customers";
import { setState as setCreateOrderState } from "slices/create-order";
import helper from "util/helper";
import history from "app/history";

const MODAL_MAX_WIDTH = 300;

class ShippingOptionsModal extends Component<any, any> {
    initialState = () => ({
        width: window.innerWidth * 0.9,
        height: window.innerHeight * 0.9,
        itemsErrors: [],
        shippingOptions: [],
    });

    state: any = this.initialState();

    componentDidMount = () => {
        this.props.getAdminDetails();
        if (this.props.storeRecords?.store_flg) {
            this.setState({ shippingOptions: getShippingOptions(this.props.storeRecords) }, () => {
                this.selectDefaultShippingOption();
            });
        }
        this.setListeners();
    };

    componentDidUpdate = (prevProps: any) => {
        if (
            (!_.isEqual(this.props.storeRecords?.store_flg, prevProps.storeRecords?.store_flg) ||
                prevProps.lan !== this.props.lan) &&
            _.isEmpty(this.props.items)
        ) {
            this.setState({ shippingOptions: getShippingOptions(this.props.storeRecords) }, () => {
                this.selectDefaultShippingOption();
            });
        }
    };

    componentWillUnmount = () => {
        this.removeListeners();
    };

    setListeners = () => {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    };

    removeListeners = () => {
        window.removeEventListener("resize", this.setHeight);
    };

    setHeight = () => {
        const width = window.innerWidth * 0.9 > MODAL_MAX_WIDTH ? MODAL_MAX_WIDTH : window.innerWidth * 0.9;
        this.setState({ height: window.innerHeight * 0.9, width });
    };

    renderShippingRadioButton = (shippingOption: any) => {
        return (
            <div key={shippingOption.value} className="radio-wrapper">
                <Radio value={shippingOption.value}>{shippingOption.label}</Radio>
            </div>
        );
    };

    onContinueClicked = () => {
        this.props.setState({
            shippingOptionsModal: false,
        });
        helper.goToCreateOrderPage(window, history);
    };

    /**
     * Check a shipping option is in the list of the current shipping options.
     *
     * @param shippingOption - a shipping option
     * @returns true if the shipping option is in the list of the current shipping options.
     */
    isInCurrentShippingOptions = (shippingOptionValue: any) => {
        return (
            this.state.shippingOptions.find(
                (currentShippingOption: any) => currentShippingOption.value === shippingOptionValue
            ) !== undefined
        );
    };

    /**
     * select an default shipping option in modal
     */
    selectDefaultShippingOption = () => {
        if (this.isInCurrentShippingOptions(config.SHIPPING_MAPPING_TO_NUMERIC.eatin)) {
            this.props.setCreateOrderState({ shippingMethod: config.SHIPPING_MAPPING_TO_NUMERIC.eatin });
        } else if (this.isInCurrentShippingOptions(config.SHIPPING_MAPPING_TO_NUMERIC.pickup)) {
            this.props.setCreateOrderState({ shippingMethod: config.SHIPPING_MAPPING_TO_NUMERIC.pickup });
        } else if (this.isInCurrentShippingOptions(config.SHIPPING_MAPPING_TO_NUMERIC.delivery)) {
            this.props.setCreateOrderState({ shippingMethod: config.SHIPPING_MAPPING_TO_NUMERIC.delivery });
        } else {
            this.props.setCreateOrderState({ shippingMethod: config.SHIPPING_MAPPING_TO_NUMERIC.quick_pay });
        }
    };

    renderTitle = () => {
        return <FormattedMessage id="select_shipping_options_description" />;
    };

    renderFooter = () => {
        return (
            <Button
                className="shipping-options-modal-continue-button"
                key="shipping-options-modal-continue"
                type="primary"
                size="large"
                onClick={() => this.onContinueClicked()}
            >
                <b>
                    <FormattedMessage id="continue" />
                </b>
            </Button>
        );
    };

    closeButtonClicked = () => {
        this.props.setState({
            shippingOptionsModal: false,
        });
        this.selectDefaultShippingOption();
    };

    renderCloseIcon = () => {
        return (
            <div
                className="shipping-options-modal-close-icon-container"
                onClick={() => {
                    this.closeButtonClicked();
                }}
            >
                <IconContext.Provider value={{ size: "1.25em" }}>
                    <IoMdClose className="shipping-options-modal-close-icon" />
                </IconContext.Provider>
            </div>
        );
    };

    renderShippingRadioGroup = () => {
        return (
            <Radio.Group
                className="shipping-options-modal-radio-group"
                onChange={(e: any) => {
                    this.props.setCreateOrderState({ shippingMethod: e.target.value });
                }}
                value={this.props.state?.shippingMethod}
            >
                {this.state.shippingOptions.map(this.renderShippingRadioButton)}
            </Radio.Group>
        );
    };

    render() {
        return (
            <Modal
                maskClosable={false}
                width={window.innerWidth < DESKTOP_WIDTH ? this.state.width : DESKTOP_WIDTH * 0.5}
                style={{ top: 100 }}
                bodyStyle={{ maxHeight: this.state.height, overflowX: "hidden", overflowY: "auto", padding: 8 }}
                title={this.renderTitle()}
                footer={this.renderFooter()}
                visible={this.props.shippingOptionsModal && !_.isEmpty(this.state.shippingOptions)}
                closeIcon={this.renderCloseIcon()}
            >
                <div className="shipping-options-modal">
                    <div className="shipping-options-modal-contents">
                        <div className="radio-group-wrapper">{this.renderShippingRadioGroup()}</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "setting.lan", config.LANGUAGE_CODES.en),
    items: _.get(state, "createOrder.items", []),
    state: _.get(state, "createOrder", {}),
    storeRecords: _.get(state, "store.records", {}),
});

const mapDispatchToProps = {
    getAdminDetails,
    clearData,
    setCustomersState,
    setCreateOrderState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShippingOptionsModal)));
