import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    modType: undefined,
    addDelModal: false,
    addPicModal: false,
    editDelPick: false,
    showSummary: false,
    shipModal: false,
    userModal: false,
    filter: false,
    filters: {
        discountType: undefined,
        date_start: NaN,
        date_expire: NaN,
        price_from: NaN,
        price_to: NaN,
        product_name: "",
        product_type: 4,
    },
    create: false,
    currentDisc: {},
    selectedProducts: [],
    selProdSkus: [],
    currTab: 0,
    currData: {},
};

const slice = createSlice({
    name: "discounts",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            Object.assign(state, payload);
        },
        setShowSummary: (state, { payload }) => {
            state.showSummary = payload;
        },
        setFilters: (state, { payload }) => {
            const newV = _.cloneDeep(current(state.filters));
            _.set(newV, payload.name, payload.value);
            state.filters = newV;
        },
        clearFilters: (state) => {
            const COUPON = 4;
            const cleared = {
                price_from: NaN,
                price_to: NaN,
                product_name: "",
                discountType: undefined,
                product_type: COUPON,
                startDiscount: NaN,
                expireDiscount: NaN,
            };
            state.reload = Math.random();
            state.filters = cleared;
        },
        setAutoDiscounts: (state, { payload }) => {
            const newV = _.cloneDeep(current(state.storeAutoDiscounts));
            _.set(newV, payload.name, payload.value);
            state.storeAutoDiscounts = newV;
        },
        fillCurrData: (state, { payload }) => {
            const { key, min, discount, condition } = payload;
            state.currData = {};
            state.currData = { ...payload.data, min, key, discount, condition };
        },
        setCurrData: (state, { payload }) => {
            const currCopy = _.cloneDeep(state.currData);
            _.set(currCopy, payload.name, payload.value);
            state.currData = currCopy;
        },
        setCurrDisc: (state, { payload }) => {
            const currCopy = _.cloneDeep(state.currentDisc);
            _.set(currCopy, payload.name, payload.value);
            state.currentDisc = currCopy;
        },
        setSelectedCategories: (state, { payload }) => {
            const currCopy = _.cloneDeep(state.currentDisc);
            const numberCopy = payload.value.map((value: string) => Number(value)); // category_ids only accepts numbers
            currCopy.condition.list = payload.value;
            currCopy.category_ids = numberCopy;
            state.currentDisc = currCopy;
        },
        removeSelectedProduct: (state, { payload }) => {
            const currSel = _.cloneDeep(state.selProdSkus);
            const currProds = _.cloneDeep(state.selectedProducts);
            const index = currSel.indexOf(payload.value);
            currSel.splice(index, 1);
            currProds.splice(index, 1);
            state.selProdSkus = currSel;
            state.selectedProducts = currProds;
        },
        clearCurrSelProds: (state) => {
            state.selectedProducts = [];
            state.selProdSkus = [];
        },
        updateEnabled: (state, { payload }) => {
            let codes = Array.isArray(state.storeDiscountCodes) ? _.cloneDeep(state.storeDiscountCodes) : [];
            codes = codes.map((code: any) => {
                if (String(payload.product_id) === String(code.product_id)) {
                    code.status = payload.status;
                }
                return code;
            });
            state.storeDiscountCodes = codes;
        },
        clearCurrDisc: (state) => {
            state.currentDisc = undefined;
            state.currentDisc = {};
        },
        clearDiscountCodes: (state) => {
            state.storeDiscountCodes = [];
        },
        setSelectedProds: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setSelProdOptions: (state, { payload }) => {
            state.selProdSkus = payload;
        },
        resetCurrentDisc: (state, { payload }) => {
            state.currentDisc = payload;
        },
        setEditMode: (state, { payload }) => {
            state.editMode = payload.value;
        },
        setCreate: (state, { payload }) => {
            state.create = payload.value;
        },
        setStartDiscount: (state, { payload }) => {
            state.startDiscount = payload.value;
        },
        setExpireDiscount: (state, { payload }) => {
            state.expireDiscount = payload.value;
        },
        setDiscountType: (state, { payload }) => {
            state.discountType = payload.value;
        },
        setFilter: (state, { payload }) => {
            state.filter = payload.value;
        },
        getDiscountsSuccess: (state, { payload }) => {
            state.storeAutoDiscounts = payload.storeAutoDiscounts;
        },
        updateStatus: (state, { payload }) => {
            state.storeDiscountCodes[payload.i].status = payload.v;
        },
        resetFilter: (state) => {
            state.startDiscount = NaN;
            state.expireDiscount = NaN;
            state.discountType = undefined;
        },
        updateFilter: (state) => {
            state.reload = Math.random();
        },
        clearCurrData: (state) => {
            state.currData = {};
        },
        setShipModal: (state, { payload }) => {
            state.shipModal = payload;
        },
        setUserModal: (state, { payload }) => {
            state.userModal = payload;
        },
        setEditDelPick: (state, { payload }) => {
            state.editDelPick = payload.value;
        },
        switchSubTab: (state, { payload }) => {
            state.currTab = payload;
        },
        setAddDelModal: (state, { payload }) => {
            state.addDelModal = payload.value;
        },
        setModType: (state, { payload }) => {
            state.modType = payload.value;
        },
        setEditIndex: (state, { payload }) => {
            state.editIndex = payload.editIndex;
        },
        finishEdit: (state) => {
            state.editIndex = undefined;
        },
        getDiscountCodesSuccess: (state, { payload }) => {
            state.filter = false;
            let codes = Array.isArray(state.storeDiscountCodes) ? _.cloneDeep(state.storeDiscountCodes) : [];
            const combineData = { ...payload.data };
            combineData.used = payload.used;
            if (payload?.one) {
                codes = codes.map((code: any) => {
                    if (String(payload.data.product_id) === String(code.product_id)) {
                        return combineData;
                    }
                    return code;
                });
            } else {
                codes.push(combineData);
            }
            state.storeDiscountCodes = codes;
        },
    },
});

export const {
    setState,
    finishEdit,
    setEditIndex,
    setModType,
    getDiscountsSuccess,
    getDiscountCodesSuccess,
    clearDiscountCodes,
    setFilter,
    setShowSummary,
    updateFilter,
    setDiscountType,
    setExpireDiscount,
    setStartDiscount,
    updateStatus,
    setCreate,
    resetCurrentDisc,
    setCurrDisc,
    clearCurrDisc,
    setSelectedProds,
    setSelProdOptions,
    removeSelectedProduct,
    setSelectedCategories,
    clearCurrSelProds,
    switchSubTab,
    setAddDelModal,
    setCurrData,
    setAutoDiscounts,
    clearCurrData,
    fillCurrData,
    setShipModal,
    setUserModal,
    setFilters,
    clearFilters,
    updateEnabled,
} = slice.actions;

export const discounts = slice.reducer;
