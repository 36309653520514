import { Button } from "antd";
import { RootState } from "app/reducer";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { TipsSettings } from "types/store-details";
import config from "../../../../config";
import {
    addNewOption,
    addNewUpdateOption,
    EditModalPages,
    setEditModal,
    setNewTaskFormState,
    setNewTaskItemsInfoState,
    setUpdateForm,
    updateOnActiveTask,
    UpdateTaskPath,
} from "../../../../slices/delivery_request";
import { Paths } from "../../../../types";
import { DoordashSetting } from "../../../../types/doordash-setting";
import { NUMBER_INPUT_REG_EX } from "../../constants";
import { FormInputComponent, INPUT_STATUS, INPUT_TYPE } from "../Form";
import { buildUpdatedOnActivedTask, getTipsOptions, getTipsValue } from "../helper";

const EditModalLayout: React.FC<PropsFromRedux> = ({
    //state,
    additionalInfo,
    editModal,
    onActiveTask,
    symbol,
    //setter
    setNewTaskItemsInfoState,
    updateOnActiveTask,
    setEditModal,
    setUpdateForm,
    addNewOption,
    addNewUpdateOption,
    setNewTaskFormState,
    // from other slices
    storeTipsSettings,
    defaultDeliverySettings,
}) => {
    interface Values {
        value?: string;
        orderValue?: number;
        tipAmount?: number;
    }

    const intl = useIntl();

    const [values, setValues] = useState<Values>({});
    const { currPage, itemIndex, optionIndex } = editModal;

    const { itemsInfo, orderValue, tipAmount } = additionalInfo;
    const { default_order_value, default_tip_amount } = defaultDeliverySettings;

    useEffect(() => {
        if (currPage !== EditModalPages.HIDDEN) setValues(getDefaultValue());
    }, [currPage]);

    const getDefaultValue = (): Values => {
        switch (currPage) {
            case EditModalPages.NEW_TASK_ITEM_OPTION:
                return { value: _.get(itemsInfo, `${itemIndex}.options.${optionIndex}`, "") };
            case EditModalPages.NEW_TASK_ITEM_NAME:
                return { value: _.get(itemsInfo, `${itemIndex}.name`, "") };
            case EditModalPages.UPDATE_ITEM_NAME:
                return { value: _.get(onActiveTask, `items.${itemIndex}.name`, "") };
            case EditModalPages.UPDATE_ITEM_OPTION:
                return { value: _.get(onActiveTask, `items.${itemIndex}.options.${optionIndex}`, "") };
            case EditModalPages.UPDATE_ORDER_VALUE_AND_TIP_AMOUNT:
                return {
                    orderValue: _.get(onActiveTask, "order_value", 0) as number,
                    tipAmount: _.get(onActiveTask, "tip", 0) as number,
                };
            case EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT:
                return {
                    orderValue: Number(orderValue) > 0 ? Number(orderValue) : default_order_value,
                    tipAmount: Number(tipAmount) > 0 ? Number(tipAmount) : default_tip_amount,
                };

            default:
                return {};
        }
    };

    const handleUpdateTask = (key: UpdateTaskPath, value: any) => {
        setUpdateForm({ key: key as UpdateTaskPath, value });
        const updatedTask = buildUpdatedOnActivedTask(onActiveTask, key, value);
        if (updatedTask) updateOnActiveTask(updatedTask);
    };

    const handleUpdateTaskValueAndTip = () => {
        setUpdateForm({ key: `additionalInfo.orderValue`, value: values.orderValue });
        setUpdateForm({ key: `additionalInfo.tipAmount`, value: values.tipAmount });
        const updatedTask = buildUpdatedOnActivedTask(
            buildUpdatedOnActivedTask(onActiveTask, "additionalInfo.orderValue", values.orderValue),
            "additionalInfo.tipAmount",
            values.tipAmount
        );
        if (updatedTask) updateOnActiveTask(updatedTask);
    };

    const shouldDisable = (): boolean => {
        return (
            !NUMBER_INPUT_REG_EX.test(String(values.orderValue)) || !NUMBER_INPUT_REG_EX.test(String(values.tipAmount))
        );
    };

    const handleConfirmClick = () => {
        const value = values.value ?? "";
        switch (currPage) {
            case EditModalPages.NEW_TASK_ITEM_OPTION:
                setNewTaskItemsInfoState({
                    key: `${itemIndex}.options.${optionIndex}` as Paths<typeof itemsInfo>,
                    value,
                });
                break;
            case EditModalPages.NEW_TASK_ITEM_NAME:
                setNewTaskItemsInfoState({ key: `${itemIndex}.name` as Paths<typeof itemsInfo>, value });
                break;
            case EditModalPages.NEW_TASK_ADD_ITEM_OPTION:
                if (!_.isEmpty(value)) addNewOption({ index: itemIndex ?? 0, content: value });
                break;
            case EditModalPages.UPDATE_ITEM_NAME:
                handleUpdateTask(`additionalInfo.itemsInfo.${itemIndex}.name` as UpdateTaskPath, value);
                break;
            case EditModalPages.UPDATE_ITEM_OPTION:
                handleUpdateTask(
                    `additionalInfo.itemsInfo.${itemIndex}.options.${optionIndex}` as UpdateTaskPath,
                    value
                );
                break;
            case EditModalPages.UPDATE_ITEM_ADD_OPTION:
                if (!_.isEmpty(value) && itemIndex !== null) {
                    addNewUpdateOption({ index: itemIndex, content: value });
                    handleUpdateTask(`additionalInfo.itemsInfo.${itemIndex}.options` as UpdateTaskPath, value);
                }
                break;
            case EditModalPages.UPDATE_ORDER_VALUE_AND_TIP_AMOUNT:
                handleUpdateTaskValueAndTip();
                break;
            case EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT:
                setNewTaskFormState({ key: "additionalInfo.orderValue", value: Number(values.orderValue) });
                setNewTaskFormState({ key: "additionalInfo.tipAmount", value: Number(values.tipAmount) });
                break;
            default:
                break;
        }
        closeModal();
    };

    const closeModal = () => {
        setEditModal({ currPage: EditModalPages.HIDDEN });
    };

    const editItemOptionModal = (
        <>
            <FormInputComponent
                name={`edit option / name`}
                state={values}
                inputDataPath={"value"}
                inputType={INPUT_TYPE.TEXT_AREA}
                handleOnChange={({ key, value }: { key: Paths<typeof values>; value: any }) => {
                    setValues({ ...values, [key]: value });
                }}
                allowClear={true}
            />
            <Button onClick={handleConfirmClick}>Confirm</Button>
        </>
    );

    const renderTipsPicker = () => {
        const [tipsOptions, isPercentOptions] = getTipsOptions(storeTipsSettings, values.orderValue ?? 0);

        const handleTipsSelectionClick = (value: number) => {
            const shouldToUpdateAmount = getTipsValue(values.orderValue, value, isPercentOptions) ?? 0;
            setValues({ ...values, tipAmount: shouldToUpdateAmount });
        };

        const renderTipsOptions = () => {
            return tipsOptions.map((option) => {
                return (
                    <div
                        key={option}
                        onClick={() => {
                            handleTipsSelectionClick(option);
                        }}
                        className="new-delivery-task-tip-picker"
                    >
                        {option}
                        {isPercentOptions && "%"}
                    </div>
                );
            });
        };

        return <div style={{ display: "flex" }}>{renderTipsOptions()}</div>;
    };

    const editOrderValueModal = (
        <div>
            <FormInputComponent
                name={`actived task order value`}
                state={values}
                inputDataPath={"orderValue"}
                inputType={INPUT_TYPE.INPUT_NUMBER}
                handleOnChange={({ key, value }: { key: Paths<typeof values>; value: any }) => {
                    setValues({
                        ...values,
                        [key]: value,
                        tipAmount: Number((value * ((storeTipsSettings?.default_pct_delivery ?? 0) / 100)).toFixed(2)),
                    });
                }}
                inputStatus={NUMBER_INPUT_REG_EX.test(String(values.orderValue)) ? undefined : INPUT_STATUS.ERR}
                style={{ width: "100%" }}
                allowClear={true}
                addonBefore={symbol}
                placeholder={intl.formatMessage({ id: "order_value" })}
            />
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", paddingTop: 12 }}>
                <FormInputComponent
                    name={`actived task order tip amount`}
                    state={values}
                    inputDataPath={"tipAmount"}
                    inputType={INPUT_TYPE.INPUT_NUMBER}
                    handleOnChange={({ key, value }: { key: Paths<typeof values>; value: any }) => {
                        setValues({ ...values, [key]: value });
                    }}
                    style={{ width: "100%" }}
                    allowClear={true}
                    addonBefore={symbol}
                    placeholder={intl.formatMessage({ id: "tips" })}
                    inputStatus={NUMBER_INPUT_REG_EX.test(String(values.tipAmount)) ? undefined : INPUT_STATUS.ERR}
                />
                {renderTipsPicker()}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                <Button className="outlined-blue-button" onClick={closeModal}>
                    <FormattedMessage id={"cancel"} />
                </Button>
                <Button
                    className="blue-button"
                    style={{ marginLeft: "10px" }}
                    disabled={shouldDisable()}
                    onClick={handleConfirmClick}
                >
                    <FormattedMessage id={"confirm"} />
                </Button>
            </div>
        </div>
    );

    const renderLayout = (): React.ReactNode => {
        switch (currPage) {
            case EditModalPages.NEW_ORDER_VALUE_AND_TIP_AMOUNT:
            case EditModalPages.UPDATE_ORDER_VALUE_AND_TIP_AMOUNT:
                return editOrderValueModal;
            default:
                return editItemOptionModal;
        }
    };

    return <>{renderLayout()}</>;
};

const mapStateToProps = (state: RootState) => {
    const currency: keyof typeof config.CURRENCY_SYMBOL =
        state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
    const symbol = config.CURRENCY_SYMBOL[currency];
    return {
        editModal: state.deliveryRequests.modal.editModal,
        onActiveTask: state.deliveryRequests.onActiveTask,
        additionalInfo: state.deliveryRequests.forms.newTask.additionalInfo,

        // from other slices
        defaultDeliverySettings: (state.store.storeThirdPartyDelivery.doordash ?? {}) as DoordashSetting,
        storeTipsSettings: (state.store.storeTipsSettings ?? {}) as TipsSettings,
        symbol,
    };
};

const mapDispatchToProps = {
    //setter
    updateOnActiveTask,
    setNewTaskFormState,
    setNewTaskItemsInfoState,
    setEditModal,
    setUpdateForm,
    addNewOption,
    addNewUpdateOption,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(EditModalLayout);
