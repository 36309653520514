import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button, Popover, Dropdown, Menu, Modal, Switch } from "antd";
import { HiInformationCircle } from "react-icons/hi";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducer";
import { FiShare } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Languages, setLanguage } from "slices/setting";
import { setUserState, userLogoutSuccess } from "slices/user";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMessage } from "../message";
import { setAccountModal, setAccountModalType, setAdminUserState } from "slices/admin-users";
import { getAdminUsersDetails, getAdminRoles, getDefaultRoles } from "services/admin-users";
import { getOnlineStatus, getStoreDetails, setOnlineStatus } from "services/store";
import config from "config";
import helper, { updateMoment } from "util/helper";
import "./index.scss";
import adminH from "pages/admin-users/helper";
import { usePermission, PermissionFeatures } from "hooks/usePermission";
import { routes } from "app";
import { AiOutlineDown } from "react-icons/ai";
import { getPublish, updatePublish } from "services/publish";
import { pubLoad } from "slices/publish";
import { PublishActions } from "pages/publish/constants";
import { StoreStatus } from "pages/store-sign-up/constants";
import { Link } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { DESKTOP_WIDTH } from "pages/settings/constants";
import { setPagesState } from "slices/pages";

function useWrapper(props: any = {}): any {
    const { hasAccess } = usePermission();
    const location = useLocation();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [showQR, setShowQR] = useState<boolean>(false);
    const [showPoster, setShowPoster] = useState<boolean>(false);
    const currUser = useSelector((state: RootState) => state.user);
    const currUserRoles = useSelector((state: RootState) => state.user?.roles ?? []);
    const users = useSelector((state: RootState) => state.adminUsers.users);
    const lan = useSelector((state: RootState) => state.setting.lan);
    const defaultRoles = useSelector((state: RootState) => state.adminUsers.defaultRoles);
    const storeRoles = useSelector((state: RootState) => state.adminUsers.storeRoles);
    const storeName = useSelector((state: RootState) =>
        helper.getTransString(state.store?.records?.general_info?.store_nm, lan)
    );
    const storeGid = useSelector((state: RootState) => state.store?.records?.g_id);
    const storeStatus = useSelector((state: RootState) => state.store?.records?.general_info?.store_status);
    const store = useSelector((state: RootState) => state.store?.records ?? {});
    const isOnline = useSelector((state: RootState) => state.store?.online) === 1;
    const publish = useSelector((state: RootState) => state?.publishManager);
    const independentDomain = useSelector((state: RootState) => state?.store?.records?.general_info?.url);
    const history = useHistory();
    const [height, setHeight] = useState<number>(window.innerHeight);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const storeLogoUrl = useSelector((state: RootState) => state?.store?.storeImages?.store_logo);
    const isPageRefreshed = useSelector((state: RootState) => state?.pages?.isPageRefreshed);

    const onLogout = () => {
        dispatch(userLogoutSuccess({}));
    };

    const onChangeLanguage = (lan: string) => {
        dispatch(setLanguage(lan));
    };

    const canViewPage = () => {
        const currentRoute: any = routes.find((r: any) => location?.pathname === r?.path) ?? {};
        const permitted = currentRoute?.index !== undefined ? hasAccess(currentRoute.index) : true;
        if (!permitted) {
            return permitted;
        }
        if (props.permission) {
            return currUserRoles.some((role: any) => props.permission.includes(role));
        } else {
            return true;
        }
    };

    const onStoreFrontShared = () => {
        window.open(config.H5_URL + `store/${storeGid}?lan=${lan}`);
    };

    const onStoreFrontIndependent = () => {
        window.open(`https://${independentDomain}`);
    };

    const onSwitchStore = () => {
        history.push("/storeSwitch");
    };

    const openProfileModal = () => {
        dispatch(setAccountModalType(adminH.ADMIN_PROFILE));
        dispatch(setAccountModal(true));
        dispatch(setAdminUserState({ selfEdit: true, activeAccountTab: 0 }));
    };

    const getRole = () => {
        const roles = users?.find((admin: any) => admin?.id === currUser.id)?.roles;
        let roleName = "";
        const isDefaultRole = (i: number) => {
            defaultRoles?.map((role: any) => {
                if (parseInt(role?.role_id) === parseInt(roles?.[i])) {
                    roleName = role?.role_name;
                }
            });
        };
        const isStoreRole = (i: number) => {
            storeRoles?.map((role: any) => {
                if (parseInt(role?._id) === parseInt(roles?.[i])) {
                    roleName = helper.getTransString(role?.name, lan);
                }
            });
        };
        roles?.[0] <= helper.DEFAULT_ROLES_NUM ? isDefaultRole(0) : isStoreRole(0);
        return roleName;
    };

    const menu = (
        <Menu>
            <div
                className="pHover"
                onClick={() => openProfileModal()}
                style={{ padding: 12, borderBottom: "1px solid rgba(0,0,0,0.05)" }}
            >
                <div>{[currUser.firstName, currUser.lastName].filter((item: any) => item).join(" ")}</div>
                {/* @ts-ignore */}
                <div style={{ color: "rgba(0,0,0,0.3)", fontSize: "0.8em" }}>{getRole() ? getRole() : null}</div>
            </div>
            <Menu.Item onClick={onStoreFrontShared}>
                <FormattedMessage id="store_front_shared" />
            </Menu.Item>
            {independentDomain ? (
                <Menu.Item onClick={onStoreFrontIndependent}>
                    <FormattedMessage id="store_front_independent" />
                </Menu.Item>
            ) : null}
            {hasAccess(PermissionFeatures.SettingSwitchStore) && (
                <Menu.Item onClick={onSwitchStore}>
                    <FormattedMessage id="store_switcher" />
                </Menu.Item>
            )}
            <Menu.Item onClick={onLogout}>
                <FormattedMessage id="sign_out" />
            </Menu.Item>
        </Menu>
    );

    const onChangeOnlineStatus = () => {
        if (isOnline) {
            Modal.confirm({
                title: intl.formatMessage({ id: "confirm_online_status" }),
                content: intl.formatMessage({ id: "confirm_online_status_content" }),
                onOk() {
                    dispatch(setOnlineStatus(0));
                },
            });
        } else {
            dispatch(setOnlineStatus(1));
        }
    };

    const isInSync = () => {
        return (
            publish.published &&
            publish.last_update !== null &&
            Date.parse(publish.last_updated) < Date.parse(publish.last_published)
        );
    };

    const publishStore = () => {
        if (!isInSync()) {
            Modal.confirm({
                title: intl.formatMessage({ id: "publish" }),
                content: intl.formatMessage({ id: "publish_confirm" }),
                onOk() {
                    dispatch(pubLoad());
                    dispatch(updatePublish({ action: PublishActions.Publish }));
                },
            });
        }
    };

    const getOnlineStatusMenuSection = () => {
        return (
            <div key="online-status-section" className="online-status-section">
                <div className="status-container">
                    <div className={`publish-tag ${isInSync() ? "tag-published" : ""}`} onClick={publishStore}></div>
                    <div className="status-label">
                        <FormattedMessage id={isInSync() ? "in_sync" : "out_sync"} />
                    </div>
                </div>
                <div className="status-container">
                    <Switch checked={isOnline} onChange={onChangeOnlineStatus} className="online-switch" />
                    <div className="status-label">
                        <FormattedMessage id={isOnline ? "online" : "offline"} />
                    </div>
                </div>
                <div className="online-status-title">
                    <Dropdown overlay={menu}>
                        <Button type="link" style={{ margin: 0, padding: 0 }}>
                            {storeName}
                        </Button>
                    </Dropdown>
                    {shareSection()}
                </div>
            </div>
        );
    };

    const getResponsiveOnlineStatusMenuSection = () => {
        return [
            <div key="sync" className="status-container">
                <div className={`publish-tag ${isInSync() ? "tag-published" : ""}`} onClick={publishStore}></div>
                <div className="status-label">
                    <FormattedMessage id={isInSync() ? "in_sync" : "out_sync"} />
                </div>
            </div>,
            <div key="status" className="status-container">
                <Switch checked={isOnline} onChange={onChangeOnlineStatus} className="online-switch" />
                <div className="status-label">
                    <FormattedMessage id={isOnline ? "online" : "offline"} />
                </div>
            </div>,
            <div key="store-name" className="online-status-title">
                <Dropdown overlay={menu}>
                    <Button type="link" style={{ margin: 0, padding: 0 }}>
                        {storeName}
                    </Button>
                </Dropdown>
                {shareSection()}
            </div>,
        ];
    };

    const shareSection = () => {
        const social_url = `${config.H5_URL}store/${storeGid}?lan=${lan}`;
        const message = intl.formatMessage({ id: "share_store" });

        const share = () => {
            return (
                <div className="d-flex flex-column justofy-content-start align-items-start">
                    <FacebookShareButton url={social_url} quote={message}>
                        <p className="popover-item mb-1">
                            <FormattedMessage id="share_facebook" />
                        </p>
                    </FacebookShareButton>
                    <a
                        target="_blank"
                        href={`mailto:?subject=${message}&body=${message} ${social_url}`}
                        rel="noopener noreferrer"
                    >
                        <p className="popover-item mb-1">
                            <FormattedMessage id="share_email" />
                        </p>
                    </a>
                    <TwitterShareButton url={social_url} title={message} via={message}>
                        <p className="popover-item mb-1">
                            <FormattedMessage id="share_twitter" />
                        </p>
                    </TwitterShareButton>
                    <p className="popover-item mb-1" onClick={() => openWeChatQRModal()}>
                        <FormattedMessage id="share_qrcode" />
                    </p>
                    <p className="popover-item mb-1" onClick={() => openPosterModal()}>
                        <FormattedMessage id="poster" />
                    </p>
                    <p className="popover-item mb-1" onClick={() => copyProduct(social_url)}>
                        <FormattedMessage id="copy_store_url" />
                    </p>
                </div>
            );
        };
        const openWeChatQRModal = () => {
            setShowQR(true);
        };
        const openPosterModal = () => {
            setShowPoster(true);
        };

        const copyProduct = (url: string) => {
            navigator.clipboard.writeText(url).then(
                function () {
                    toast(createMessage(intl.formatMessage({ id: "store_url_copied" }), HiInformationCircle));
                },
                function () {
                    // Handle error
                }
            );
        };

        return (
            <div className="d-flex flex-column">
                <Popover content={share()} placement="bottomLeft">
                    <Button type="link" className="share-icon-button">
                        <FiShare size="20" />
                    </Button>
                </Popover>
            </div>
        );
    };

    const langMenu = (languages: any) => {
        return (
            <Menu>
                <Menu.ItemGroup title={intl.formatMessage({ id: "localized" })}>
                    {languages.map((language: any) => {
                        //@ts-ignore
                        const lan = Languages[language];
                        return (
                            //@ts-ignore
                            <Menu.Item key={lan} onClick={() => onChangeLanguage(lan)}>
                                {/*@ts-ignore*/}
                                <FormattedMessage id={lan ?? " "} />

                                {/*@ts-ignore*/}
                                {String(lan) === "en" ? (
                                    <span style={{ color: "#9d9e9e", marginLeft: "25px" }}>
                                        {intl.formatMessage({ id: "primary" })}
                                    </span>
                                ) : null}
                            </Menu.Item>
                        );
                    })}
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const getPageHeaderExtras = () => [
        <Dropdown key={"extraPageHeader"} overlay={langMenu(Object.keys(Languages))} placement="bottom" arrow>
            <Button
                style={{
                    border: "none",
                    boxShadow: "none",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#3898c8",
                }}
                size="small"
            >
                {intl.formatMessage({ id: lan ?? "" })}
                <AiOutlineDown size="14" style={{ marginLeft: "8px" }} />
            </Button>
        </Dropdown>,
        !props.hideNav ? getOnlineStatusMenuSection() : null,
    ];

    const responsivePageHeaderExtras = () => {
        return (
            <Dropdown key="more" overlay={responsivePageHeaderMenuItems()}>
                <Button
                    style={{
                        border: "none",
                        padding: 0,
                    }}
                >
                    <EllipsisOutlined
                        style={{
                            fontSize: 20,
                            verticalAlign: "top",
                        }}
                    />
                </Button>
            </Dropdown>
        );
    };

    const responsivePageHeaderMenuItems = () => {
        return (
            <Menu>
                <Menu.Item>{getPageHeaderExtras()[0]}</Menu.Item>
                {getResponsiveOnlineStatusMenuSection().map((ext, index) => {
                    return <Menu.Item key={index}>{ext}</Menu.Item>;
                })}
            </Menu>
        );
    };

    const getPageHeaderBreadcrumbsTranslated = () => {
        const routes: any = [];
        if (props.breadcrumb?.routes) {
            props.breadcrumb?.routes.forEach((route: any) => {
                routes.push({
                    path: route.path,
                    breadcrumbName: intl.formatMessage({ id: route.breadcrumbName }),
                });
            });
        }
        return props.breadcrumb
            ? {
                  routes,
                  // eslint-disable-next-line
                  itemRender: (route: any, params: any, routes: any, paths: any) => {
                      const last = routes.indexOf(route) === routes.length - 1;
                      let to = route?.path?.includes("/") ? route?.path : `/${paths.join("/")}`;

                      //remove preceding '/dashboard' from the path of nested routes
                      if (paths.length >= 2) to = to.replace("/dashboard", "");
                      return last ? <span>{route.breadcrumbName}</span> : <Link to={to}>{route.breadcrumbName}</Link>;
                  },
              }
            : {};
    };

    const isMobile = () => !isNaN(Number(width)) && Number(width) < DESKTOP_WIDTH;

    const prevRoute = () => {
        if (props.breadcrumb?.routes && props.breadcrumb?.routes.length > 2) {
            return props.breadcrumb?.routes[props.breadcrumb?.routes.length - 2].path;
        }
        return "/dashboard";
    };

    const defaultOnBack = () => {
        history.replace(prevRoute());
    };

    const pageHeaderProps = () => ({
        title: props.helmet?.title ? (
            <div className="wrapper-title-line">{intl.formatMessage({ id: props.helmet?.title })}</div>
        ) : null,
        breadcrumb: !isMobile() ? getPageHeaderBreadcrumbsTranslated() : {},
        extra: [
            ...(props.extra ? props.extra : []),
            ...(isMobile() ? [responsivePageHeaderExtras()] : getPageHeaderExtras()),
        ].filter((i) => i !== null),
        style: {
            backgroundColor: "white",
            marginBottom: 8,
        },
        onBack: props.onBack || defaultOnBack,
    });

    useEffect(() => {
        function resetHeight() {
            setHeight(window.innerHeight);
        }

        const throttledResetHeight = _.throttle(resetHeight, 500);

        window.addEventListener("resize", throttledResetHeight);

        return () => {
            window.removeEventListener("resize", throttledResetHeight);
        };
    }, []);

    useEffect(() => {
        function resetWidth() {
            setWidth(window.innerWidth);
        }

        const throttledResetWidth = _.throttle(resetWidth, 500);

        window.addEventListener("resize", throttledResetWidth);

        return () => {
            window.removeEventListener("resize", throttledResetWidth);
        };
    }, []);

    useEffect(() => {
        if (props.public && currUser?.token && currUser?.logoutPath) {
            history.replace("/" + currUser?.logoutPath);
            dispatch(setUserState({ logoutPath: "" }));
        }

        if (!props.public && !currUser?.token) {
            history.replace("/");
        }
    }, [currUser, dispatch, props.public, history]);

    useEffect(() => {
        if (currUser?.token) {
            // To avoid redundant api calls, the below apis are called only once
            if (isPageRefreshed) {
                dispatch(getStoreDetails());
                dispatch(getOnlineStatus());
                dispatch(getAdminUsersDetails());
                dispatch(getAdminRoles());
                dispatch(getDefaultRoles());
                dispatch(setPagesState({ isPageRefreshed: false }));
            }
            dispatch(getPublish());
        }
    }, [dispatch]);

    useEffect(() => {
        updateMoment(lan);
    }, [lan]);

    useEffect(() => {
        if (currUser?.token) {
            if (
                !["storeSignUp", "storePendingReview", "storeSwitch"].some((path) => location.pathname.includes(path))
            ) {
                if (_.isEmpty(store) || storeStatus <= StoreStatus.Pending) {
                    history.push("/storeSignUp");
                }
            }
        }
    }, [currUser, storeStatus, store, history, location.pathname]);

    return {
        currUser,
        pageHeaderProps,
        canViewPage,
        height,
        isMobile,
        storeName,
        storeGid,
        setShowQR,
        lan,
        storeLogoUrl,
        showQR,
        intl,
        showPoster,
        setShowPoster,
    };
}

export default useWrapper;
