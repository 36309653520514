const StoreShipping = `
    shipping_setting.c2c.sid,                                           c2cSID,                         false;
    shipping_setting.c2c.snm,                                           c2cSnm,                         false;
    shipping_setting.c2c.methods[0],                                    c2cRegShip,                     false;
    shipping_setting.c2c.methods[1],                                    c2cExpShip,                     false;

    shipping_setting.table.enable,                                      tableEnable,                    false;
    shipping_setting.table.rules,                                       tableRules,                     false;
    shipping_setting.table.ship_to_country_type,                        tableShipCountryType,           false;
    shipping_setting.table.allow_country,                               tableAllowCountry,              false;

    shipping_setting.flat.enable,                                       flatEnable,                     false;
    shipping_setting.flat.handle_type,                                  flatHandleType,                 false;
    shipping_setting.flat.price,                                        flatPrice,                      false;
    shipping_setting.flat.calculate_handling_fee,                       flatCalcHandlingFee,            false;
    shipping_setting.flat.handling_fee,                                 flatHandlingFee,                false;
    shipping_setting.flat.ship_to_country_type,                         flatShipCountryType,            false;
    shipping_setting.flat.allow_country,                                flatAllowCountry,               false;

    shipping_setting.free.enable,                                       freeEnable,                     false;
    shipping_setting.free.min_order_amount,                             freeMinOrderAmount,             false;
    shipping_setting.free.ship_to_country_type,                         freeShipCountryType,            false;
    shipping_setting.free.allow_country,                                freeAllowCountry,               false;

    shipping_setting.ups.enable,                                        upsEnable,                      false;
    shipping_setting.ups.user_id,                                       upsUserID,                      false;
    shipping_setting.ups.access_key,                                    upsAccessKey,                  false;
    shipping_setting.ups.password,                                      upsPassword,                    false;
    shipping_setting.ups.package_request_type,                          upsPackageRequestType,          false;
    shipping_setting.ups.container,                                     upsContainer,                   false;
    shipping_setting.ups.destination_type,                              upsDestinationType,             false;
    shipping_setting.ups.weight_unit,                                   upsWeightUnit,                  false;
    shipping_setting.ups.pickup_method,                                 upsPickupMethod,                false;
    shipping_setting.ups.max_package_weight,                            upsMaxWeight,                   false;
    shipping_setting.ups.min_package_weight,                            upsMinWeight,                   false;
    shipping_setting.ups.handling_fee,                                  upsHandlingFee,                 false;
    shipping_setting.ups.handling_apply,                                upsHandlingApply,               false;
    shipping_setting.ups.calculate_handling_fee,                        upsCalcHandlingFee,             false;
    shipping_setting.ups.allow_methods,                                 upsAllowMethods,                false;

    shipping_setting.fedex.enable,                                      fedexEnable,                    false;
    shipping_setting.fedex.account_id,                                  fedexAccountID,                 false;
    shipping_setting.fedex.meter_number,                                fedexMeterNumber,               false;
    shipping_setting.fedex.account_key,                                 fedexKey,                       false;
    shipping_setting.fedex.account_password,                            fedexPassword,                  false;
    shipping_setting.fedex.packaging,                                   fedexPackaging,                 false;
    shipping_setting.fedex.dropoff,                                     fedexDropoff,                   false;
    shipping_setting.fedex.weight_unit,                                 fedexWeightUnit,                false;
    shipping_setting.fedex.max_weight,                                  fedexMaxWeight,                 false;
    shipping_setting.fedex.handling_fee,                                fedexHandlingFee,               false;
    shipping_setting.fedex.handling_apply,                              fedexHandlingApply,             false;
    shipping_setting.fedex.calculate_handling_fee,                      fedexCalcHandlingFee,           false;
    shipping_setting.fedex.allow_methods,                               fedexAllowMethods,              false;
    shipping_setting.fedex.is_residential_delivery,                     fedexResidential,               false;
    shipping_setting.fedex.allow_country,                               fedexAllowCountry,              false;
    shipping_setting.fedex.ship_to_applicable_country,                  fedexShipApplicableCntry,       false;
`



export default StoreShipping;