import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    stores: [],
    activeStores: [],
    managedStores: [],
};

const slice = createSlice({
    name: "managedStores",
    initialState,
    reducers: {
        setManagedStores: (state, { payload }) => {
            const copy: any = _.cloneDeep(state.managedStores);
            if (payload.ad) {
                copy.push(payload.id);
            } else {
                copy.splice(copy.indexOf(payload.id), 1);
            }
            state.managedStores = copy;
        },
        getStoreListSuccess: (state, { payload }) => {
            state.stores = payload.stores;
        },
        getManagedStoresSuccess: (state, { payload }) => {
            const managedS: any = [];
            payload.map((store: any) => managedS.push(store?.general_info?.g_id));
            state.managedStores = managedS;
            state.activeStores = payload;
        },
    },
});

export const { getStoreListSuccess, getManagedStoresSuccess, setManagedStores } = slice.actions;

export const managedStores = slice.reducer;
