import React from "react";
import _ from "lodash";
import { SelectOption } from "../../../../../../components/form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import helper from "../../../../../../util/helper";
import { renderNumberInput, renderSelectInput, renderTextInput } from "../shipping-functions";
import { setTableProps } from "../../../../../../slices/shipping";
import regionList from "../../../../../../util/data/regionList.json";
import { getCountrySelectOptions } from "../../helper";

function RuleModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const handlingFee = useSelector((state: RootState) => state.shipping?.tableProps?.handling_fee);
    const country = useSelector((state: RootState) => state.shipping?.tableProps?.country);
    const region = useSelector((state: RootState) => state.shipping?.tableProps?.region);
    const postal = useSelector((state: RootState) => state.shipping?.tableProps?.postal);
    const price = useSelector((state: RootState) => state.shipping?.tableProps?.price);
    const lan = useSelector((state: RootState) => state?.setting?.lan ?? "en");

    const setState = (name: string, value: any) => {
        dispatch(setTableProps({ name, value }));
    };

    const getTerritoryOptions = () => {
        return regionList?.records
            ?.find((el: any) => el.country === country)
            ?.territories.map((territory: any) => {
                return { value: territory?.code, label: _.get(territory, `name.${lan ?? "en"}`, " ") };
            });
    };

    const showRegion = () => {
        return country == "US" || country == "CA";
    };

    return (
        <div className="white-layered-block w100 table-rate-tab">
            <div className="d-flex flex-wrap w100 mt-3">
                {renderNumberInput("shipping_fee", "handling_fee", handlingFee, setState, {
                    formatter: (value: any) => helper.formatMoney(value, intl),
                    parser: (value: any) => helper.parseMoney(value, intl),
                })}
                {renderSelectInput("country", "country", country, setState, getCountrySelectOptions())}
                {showRegion() && renderSelectInput("region", "region", region, setState, getTerritoryOptions())}
                {renderTextInput("postal", "postal", postal, setState)}
                {renderNumberInput("price", "price", price, setState, {
                    formatter: (value: any) => helper.formatMoney(value, intl),
                    parser: (value: any) => helper.parseMoney(value, intl),
                    min: 0,
                })}
            </div>
        </div>
    );
}

export default RuleModal;
