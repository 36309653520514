import { Modal, Button } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import h from "../../../admin-users/helper";
import { deleteCustomer } from "../../../../services/customers";
import { getCustomerId } from "./helper";

class CustomerDelete extends Component {
    state = {};

    getCid = (props = this.props) => getCustomerId(props);
    adminCanDelete = (props = this.props) => h.getPermission(props.currAdmin, h.CUSTOMER) === h.READ_WRITE;
    handleDeleteSuccess = (props = this.props) => props.deleteCallback;
    renderDeleteButton = () => (
        <div className="delete-customer-button-section">
            <Button
                className="delete-customer-button"
                onClick={() => this.setState({ openEditInfo: true })}
                size="large"
                type="link"
            >
                <FormattedMessage id="delete_customer" />
            </Button>
        </div>
    );

    renderDeleteModal = () => {
        const renderTitle = () => (
            <div className="delete-customer-modal-title">
                <FormattedMessage id="delete_customer_warning" />
            </div>
        );

        const renderDelete = () => (
            <div className="confirm-delete-customer-button-section">
                <Button
                    className="confirm-delete-customer-button"
                    onClick={() => {
                        this.props.deleteCustomer(
                            {
                                id: this.getCid(),
                            },
                            this.handleDeleteSuccess()
                        );
                        this.setState({ openEditInfo: false });
                    }}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="delete_customer" />
                </Button>
            </div>
        );

        return (
            <Modal
                onCancel={() => this.setState({ openEditInfo: false })}
                destroyOnClose
                visible={this.state.openEditInfo}
                title={null}
                footer={null}
            >
                <div className="delete-customer-modal-section">
                    {renderTitle()}
                    {renderDelete()}
                </div>
            </Modal>
        );
    };

    render() {
        const customer = this.props.customer;
        const showBtn =
            this.adminCanDelete() &&
            (!customer?.ord_cnt || Number(customer?.ord_cnt) === 0) &&
            (!customer?.points_balance || Number(customer?.points_balance) === 0) &&
            (!customer?.giftcards_balance || Number(customer?.giftcards_balance) === 0) &&
            (_.isEmpty(customer?.referral_code) || String(customer?.referral_code).trim() === "");

        return showBtn ? (
            <div className="customer-detail-section section-padding">
                {this.renderDeleteButton()}
                {this.renderDeleteModal()}
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    currAdmin: state?.user,
});

const mapDispatchToProps = {
    deleteCustomer,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerDelete)));