import React from "react";
import Wrapper from "../../components/wrapper";
import "./index.scss";
import { FormattedMessage } from "react-intl";
import ProductImages from "./_components/product-images";
import ProductDescription from "./_components/product-description";
import ProductShortDescription from "./_components/short-description";
import ProductQuickAdd from "./_components/product-quick-add";
import useProductsPage from "./useProductsPage";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "dashboard",
        },
        {
            path: "products",
            breadcrumbName: "items",
        },
    ],
};

function ProductsPage(): JSX.Element {
    const {
        getActionBar,
        getImageModal,
        getDescriptionModal,
        getShortDescriptionModal,
        getQuickAddModal,
        onSaveImage,
        closeImageModal,
        closeDescriptionModal,
        closeShortDescriptionModal,
        closeQuickAddModal,
        getFilterDrawer,
        getTable,
        getPagination,
        renderDuplicate,
    } = useProductsPage();

    return (
        <Wrapper helmet={{ title: "item_list" }} breadcrumb={breadcrumb} paddingBottom={64}>
            <div className="products-page">
                {getActionBar()}
                {getTable()}
                {getFilterDrawer()}
                {renderDuplicate()}
                {getImageModal(<ProductImages onSave={onSaveImage} />, {
                    title: <FormattedMessage id="item_images" />,
                    onCancel: closeImageModal,
                    width: 790,
                    footer: null,
                })}
                {getDescriptionModal(<ProductDescription onClose={closeDescriptionModal} />, {
                    title: <FormattedMessage id="description" />,
                    onCancel: closeDescriptionModal,
                    footer: null,
                })}
                {getShortDescriptionModal(<ProductShortDescription onClose={closeShortDescriptionModal} />, {
                    title: <FormattedMessage id="short_description" />,
                    onCancel: closeShortDescriptionModal,
                    footer: null,
                })}
                {getQuickAddModal(<ProductQuickAdd onClose={closeQuickAddModal} />, {
                    title: <FormattedMessage id="quick_add" />,
                    onCancel: closeQuickAddModal,
                    footer: null,
                    width: 768,
                })}
                {getPagination()}
            </div>
        </Wrapper>
    );
}

export default ProductsPage;
