import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import "./not-found-page.scss";
import { FaRegSadTear } from "react-icons/fa";

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className="not-found-container">
                <div className="not-found">
                    <div className="not-found-404">
                        <FaRegSadTear size={140} />
                    </div>
                    <div className="not-found-title">404</div>
                    <div className="not-found-description">
                        <FormattedMessage id="not_found_description" />
                    </div>
                    <div className="not-found-detail-description">
                        <FormattedMessage id="not_found_detail_description" />
                    </div>
                    <a href="/">
                        <FormattedMessage id="back_to_home" />
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NotFoundPage));
