import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducer";
import { getDashboard } from "../../services/dashboard";

const selector = (state: RootState) => ({
    parameters: state?.dashboard?.parameters,
});

function useDashboard(): void {
    const dispatch = useDispatch();
    const { parameters } = useSelector(selector);
    const view_control = parameters?.view_control;
    useEffect(() => {
        dispatch(getDashboard(parameters));
    }, [dispatch, parameters, view_control]);
}

export default useDashboard;
