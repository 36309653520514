import React from "react";
import QR from "../_components/qr-comp";
import { Document, Page, View, StyleSheet, Image } from "@react-pdf/renderer";
import customOrderLabel from "../_components/custom-order-label";
import customDiscount from "../_components/custom-discount";
import BottomInfoBar from "../_components/bottom-info-bar";
import Cursor from "../../../../../images/icons/pointer.png";
import Star from "../../../../../images/icons/star.png";
import iPhone from "../../../../../images/icons/iphone-02.png";
import storeInfo from "../_components/storeInfo";
import ScanOrder from "../_components/scantxt-comp";
import Phone from "../_components/phone-cont";
import CustomTextBox from "../_components/custom-text-cont";
import PaymentMethods from "../_components/payment-methods";

import "../generate-print.scss";

interface Format1Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    dark: boolean | any;
    paperSize: string | any;
    storeURL: string | any;
    customDiscount: number | any;
    customOrderLabel: string | any;
    dataURLS: string | any;
    storeLogo: string | any;
    phone: number | any;
    customBottomLabel: string | any;
}

function Format1(props: Format1Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            padding: `${props.bleedingMargin}mm`,
        },

        qrPrintPdfContainer: {
            position: "absolute",
            width: "20cm",
            height: "29cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            backgroundColor: props.dark ? "#504F4F" : props.bgColor ?? "#20817b",
            borderRadius: "10pt",
            fontSize: "16pt",
            paddingTop: "35pt",
        },

        qrHolder: {
            position: "relative",
            height: "230pt",
            width: "230pt",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "280pt",
            left: "172pt",
        },
        scanTxt: {
            display: "flex",
            position: "absolute",
            left: "185pt",
            top: "240pt",
            color: "red",
            fontSize: "37pt",
            fontWeight: "extrabold",
            textAlign: "left",
        },
        paymentContainer: {
            position: "relative",
            top: "290pt",
            width: "100%",
            margin: "5pt",
            padding: "0 10%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        paymentImg: {
            height: "16pt",
            marginLeft: "5pt",
        },
        goopterContainer: {
            display: "flex",
            flexDirection: "row",
            marginBottom: "10pt",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            height: "35pt",
            backgroundColor: "white",
            opacity: 1,
            paddingLeft: "30pt",
            paddingRight: "30pt",
            paddingTop: "5pt",
            paddingBottom: "5pt",
            borderTop: "5pt solid red",
        },
        poweredBy: {
            fontSize: "10pt",
            fontWeight: 600,
            color: "black",
        },
        goopterLogo: {
            width: "57pt",
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        logo: {
            position: "absolute",
            display: "flex",
            backgroundColor: "white",
            width: "48pt",
            height: "48pt",
        },
        qr_code_url_subtext: {
            textAlign: "center",
            minWidth: "100%",
            display: "flex",
            color: "red",
            fontWeight: "bold",
            fontSize: "40pt",
            backgroundColor: "white",
        },
        cursor: {
            width: "90pt",
            height: "90pt",
            position: "absolute",
            right: "10%",
            top: "5%",
        },
        star: {
            display: "flex",
            position: "absolute",
            left: "50pt",
            top: "15pt",
            height: "187.5pt",
        },
        iphone: {
            display: "flex",
            position: "absolute",
            left: "115pt",
            top: "135pt",
            width: "340pt",
        },
        customDiscount: {
            display: "flex",
            position: "absolute",
            top: "80pt",
            fontSize: "50pt",
            color: "white",
            textAlign: "center",
        },
        customOrderLabel: {
            display: "flex",
            position: "absolute",
            top: "140pt",
            fontSize: "37.5pt",
            color: "white",
            textAlign: "center",
        },
        phone: {
            color: "red",
            position: "absolute",
            top: "500pt",
            left: "185pt",
            fontWeight: "bold",
            textAlign: "left",
        },
        PhoneIcon: {
            width: "55pt",
            position: "relative",
            left: "17pt",
            top: "-14pt",
        },
        customTextCont: {
            color: "white",
            position: "absolute",
            top: "550pt",
            fontWeight: "bold",
            fontSize: "40pt",
        },
        CustomLabelBox: {
            fontSize: "25pt",
            color: "red",
            marginBottom: "70pt",
        },
        qrCodeCardBlock: {
            position: "relative",
            width: "100%",
            display: "flex",
            textAlign: "center",
        },
        flexRow: {
            display: "flex",
            flexDirection: "row",
        },
        phoneIconContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        scanTxtFirst: {
            fontSize: "20pt",
            marginLeft: "5pt",
            paddingTop: "15pt",
        },
        scanTxtSecond: {
            marginLeft: "5pt",
        },
    });

    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() => (
                        <View style={styles.qrPrintPdfContainer} wrap={false}>
                            {/* content above iphone */}
                            <View style={styles.qrCodeCardBlock}>
                                {storeInfo(styles, props)}
                                <Image src={Cursor} style={styles.cursor} />
                                <Image src={Star} style={styles.star} />
                                {customDiscount(styles, props)}
                                {customOrderLabel(styles, props)}
                            </View>
                            <View style={[styles.qrCodeCardBlock, { height: "700pt" }]}>
                                <Image src={iPhone} style={styles.iphone} />
                                {ScanOrder(styles)}
                                {QR(styles, props)}
                                {Phone(styles, props)}
                                {PaymentMethods(styles, props)}
                                {CustomTextBox(styles, props)}
                            </View>
                            <View>{BottomInfoBar(styles)}</View>
                        </View>
                    )}
                />
            </Page>
        </Document>
    );
}

export default Format1;
