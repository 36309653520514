enum REFERRAL_ACTION {
    GENERATE = 1,
    GET = 2,
    DISABLE = 3,
    ENABLE = 4,
    DELETE = 5,
}

enum REFERRAL_STATUS {
    ENABLE = "1",
    DISABLE = "0",
}

enum BULK {
    TRUE = "true",
    FALSE = "false",
    LAST = "last",
}

enum REFERRAL_CODE {
    ON = 1,
    OFF = 2,
}

const MAX_RATIO = 1000;
const MAX_EXPIRY = 30;
const MIN = 0;
const MIN_STRING = "0";

export default {
    BULK,
    REFERRAL_CODE,
    REFERRAL_ACTION,
    REFERRAL_STATUS,
    MAX_RATIO,
    MAX_EXPIRY,
    MIN,
    MIN_STRING,
};
