import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import "./order-list-inaccurate-alert.scss";
import { Alert, Button } from "antd";
import actions from "../../../../slices/order-history";

class OrderListInaccurateAlert extends Component {
    showAlert = () => _.get(this.props, "state.showListAlert", true);

    onRefreshClick = () => {
        this.props.fetchOrderList();
    };

    onCloseClick = () => {
        this.props.setState({ showListAlert: false });
    };

    renderAlertAction = () => {
        return (
            <Button
                onClick={() => this.onRefreshClick()}
                size="small"
                type="default"
                className="text-on-white-background"
            >
                <FormattedMessage id="refresh" />
            </Button>
        );
    };

    renderAlertMessage = () => {
        return (
            <div onClick={() => this.onRefreshClick()} className="order-list-inaccurate-alert-message">
                <div className="order-list-inaccurate-alert-message-message">
                    <FormattedMessage id="inaccurate_alert_message" />
                </div>
                <div className="order-list-inaccurate-alert-message-action">{this.renderAlertAction()}</div>
            </div>
        );
    };

    renderAlert = () => {
        return (
            <Alert onClose={() => this.onCloseClick()} message={this.renderAlertMessage()} type="warning" closable />
        );
    };

    render() {
        return this.showAlert() ? (
            <div id="order-list-inaccurate-alert" className="order-list-inaccurate-alert">
                {this.renderAlert()}
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    state: _.get(state, "orders-page", {}),
});

const mapDispatchToProps = {
    fetchOrderList: actions.fetchOrderList,
    setState: actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListInaccurateAlert));
