import { Button, Descriptions, Form } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useLocation } from "react-router-dom";
import { RootState } from "../../../../../../app/reducer";
import {
    getDescriptionItem,
    getSelectInput,
    getSwitchInput,
    SelectOption,
    mapBooleansToNumbers,
} from "../../../../../../components/form";
import h from "../../../../../../util/helper";
import { getStoreDetails, updateStoreDetails } from "../../../../../../services/store";
import ObjectModel from "../../../../../../util/models";
import StoreBasic from "../../../../../../util/models/store-basic";

function StoreBasicTab(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const store = useSelector((state: RootState) => state.store);
    const storeInformation = useSelector((state: RootState) => state.store?.storeBasic);
    const records = useSelector((state: RootState) => state.store?.records);
    const [touched, setTouched] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const mapped = mapBooleansToNumbers(data, [
            "allow_online_order",
            "allow_preorder_when_closed",
            "allow_upselling",
            "allow_qr_checkout",
        ]);
        dispatch(updateStoreDetails(new ObjectModel(StoreBasic).convertToPostData(mapped, records, "basic_setting")));
        setTouched(false);
    };

    const priceRangeOptions: SelectOption[] = [
        {
            value: "1",
            label: intl.formatMessage({ id: "under_10" }),
        },
        {
            value: "2",
            label: intl.formatMessage({ id: "10_30" }),
        },
        {
            value: "3",
            label: intl.formatMessage({ id: "30_60" }),
        },
        {
            value: "4",
            label: intl.formatMessage({ id: "60_99" }),
        },
        {
            value: "5",
            label: intl.formatMessage({ id: "above_100" }),
        },
    ];

    const stopOrdersBeforeCloseOptions: SelectOption[] = [
        {
            value: "0",
            label: "0",
        },
        {
            value: "10",
            label: "10",
        },
        {
            value: "20",
            label: "20",
        },
        {
            value: "30",
            label: "30",
        },
        {
            value: "40",
            label: "40",
        },
        {
            value: "50",
            label: "50",
        },
        {
            value: "60",
            label: "60",
        },
    ];

    const unpaidCancellationOptions: SelectOption[] = [
        {
            value: "15",
            label: "15",
        },
        {
            value: "30",
            label: "30",
        },
        {
            value: "45",
            label: "45",
        },
        {
            value: "60",
            label: "60",
        },
        {
            value: "120",
            label: "120",
        },
        {
            value: "180",
            label: "180",
        },
        {
            value: "240",
            label: "240",
        },
        {
            value: "480",
            label: "480",
        },
        {
            value: "720",
            label: "720",
        },
        {
            value: "1080",
            label: "1080",
        },
        {
            value: "1440",
            label: "1440",
        },
    ];

    const preorderBeforeTimeOptions = (): SelectOption[] => {
        const preorderBeforeTimeIntervals = [];
        preorderBeforeTimeIntervals.push({
            value: "-1",
            label: intl.formatMessage({ id: "anytime" }),
        });
        preorderBeforeTimeIntervals.push({
            value: "0",
            label: intl.formatMessage({ id: "not_allowed" }),
        });

        const FIFTEEN_MIN = 15;
        const THIRTY_MIN = 30;
        const SIXTY_MIN = 60;
        const TWO_HOUR = 120;
        const TEN_HOURS = 600;

        for (let i = FIFTEEN_MIN; i <= TEN_HOURS; ) {
            preorderBeforeTimeIntervals.push({
                value: String(i),
                label: String(i),
            });

            if (i < SIXTY_MIN) {
                // 15 min interval options in the first hour
                i += FIFTEEN_MIN;
            } else if (i < TWO_HOUR) {
                // 30 min interval options between an hour and 2 hours
                i += THIRTY_MIN;
            } else {
                // 60 min interval options between 2 hours and 10 hours
                i += SIXTY_MIN;
            }
        }

        return preorderBeforeTimeIntervals;
    };

    const utensilOptions: SelectOption[] = Object.keys(h.UTENSIL_OPTIONS_MAP).map((key) => {
        return { value: h.UTENSIL_OPTIONS_MAP[key], label: intl.formatMessage({ id: key }) };
    });

    const utensilPrintOptions: SelectOption[] = Object.keys(h.UTENSIL_PRINT_OPTIONS).map((key) => {
        return { value: h.UTENSIL_PRINT_OPTIONS[key], label: intl.formatMessage({ id: key }) };
    });

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setTouched(false);
    };

    const location = useLocation();

    useEffect(() => {
        setTouched(false);
        form.resetFields();
    }, [location, form]);

    const getUtensilOptions = (
        <React.Fragment>
            {getDescriptionItem(
                "require_utensils",
                3,
                getSelectInput("require_utensil", false, utensilOptions),
                "require_utensil_tip"
            )}
            {getDescriptionItem(
                "print_request_utensil",
                3,
                getSelectInput("print_utensil", false, utensilPrintOptions),
                "print_request_utensil_description"
            )}
        </React.Fragment>
    );

    return !_.isEmpty(storeInformation) ? (
        <React.Fragment>
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <div className="general-settings has-floating-submit">
                <Form
                    form={form}
                    name="general-settings"
                    onFinish={onFinish}
                    initialValues={storeInformation}
                    onValuesChange={handleValuesChanged}
                >
                    <Descriptions bordered>
                        {getDescriptionItem("price_range", 3, getSelectInput("price_range", false, priceRangeOptions))}
                        {getDescriptionItem(
                            "allow_online_order",
                            3,
                            getSwitchInput("allow_online_order", storeInformation),
                            "allow_online_order_tip"
                        )}
                        {h.isRestaurantFromFlags(_.get(store, "records.store_flg", "")) ? getUtensilOptions : null}
                        {getDescriptionItem(
                            "allow_preorder_time",
                            3,
                            getSelectInput("preorder_x_time_before_open", false, preorderBeforeTimeOptions())
                        )}
                        {getDescriptionItem(
                            "stop_order_minutes_before_close",
                            3,
                            getSelectInput("stop_x_time_before_close", false, stopOrdersBeforeCloseOptions)
                        )}
                        {getDescriptionItem(
                            "unpaid_order_cancellation",
                            3,
                            getSelectInput("unpaid_cancellation_time", false, unpaidCancellationOptions),
                            "unpaid_order_cancellation_desc"
                        )}
                        {getDescriptionItem(
                            "allow_up_sale",
                            3,
                            getSwitchInput("allow_upselling", storeInformation),
                            "up_sale_tip"
                        )}
                    </Descriptions>
                    <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                        <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={!touched}
                            className="save-button-handler"
                        >
                            <FormattedMessage id="save_changes" />
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    ) : null;
}

export default StoreBasicTab;
