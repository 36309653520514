import React from "react";
import { injectIntl } from "react-intl";
import { FaCheck } from "react-icons/fa";
import "./_time-picker.scss";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { to24Hours } from "../../../../../util/helper";
import { Modal } from "antd";
import { setState } from "slices/create-order";

const SERVER_TIME_DATE_FORMAT = "YYYY-MM-DD";

const TIME_FORMAT_DISPLAY = "hh:mmA";

class TimePicker extends React.Component<any, any> {
    string = (id: any) => this.props.intl.formatMessage({ id: id || " " });

    getDate = (date: any) => {
        const seperator = ":";
        const isChinese = this.props.lan === "zh" || this.props.lan === "zh-Hant";
        const DATE_FORMAT_DISPLAY = isChinese ? "ddd, MMMDo" : "ddd, MMM D";
        const formats = {
            nextDay: `[${this.string("tomorrow")}${seperator}]${DATE_FORMAT_DISPLAY}`,
            sameDay: `[${this.string("today")}${seperator}]${DATE_FORMAT_DISPLAY}`,
            lastDay: `[${this.string("yesterday")}${seperator}]${DATE_FORMAT_DISPLAY}`,
            sameWeek: `${DATE_FORMAT_DISPLAY}`,
            nextWeek: `${DATE_FORMAT_DISPLAY}`,
            lastWeek: `${DATE_FORMAT_DISPLAY}`,
            sameYear: `${DATE_FORMAT_DISPLAY}`,
            sameElse: `${DATE_FORMAT_DISPLAY}`,
        };
        return moment(date, SERVER_TIME_DATE_FORMAT).isValid()
            ? moment(date, SERVER_TIME_DATE_FORMAT).calendar(null, formats).split(seperator)
            : [];
    };

    renderDate = (date: any) => {
        const dates = this.getDate(date);
        if (dates.length === 2)
            return (
                <div className="date-double-text">
                    <div className="date-upper-text">{dates[0]}</div>
                    <div className="date-bottom-text">{dates[1]}</div>
                </div>
            );
        if (dates.length === 1) return <div> {dates[0]} </div>;
    };

    getTime = (time: any) => {
        const times = time.split("-");
        const startTime = _.get(times, "0");
        const endTime = _.get(times, "1");

        const startMoment = moment(to24Hours(startTime), "HH:mm");
        const endMoment = moment(to24Hours(endTime), "HH:mm");

        const startStr = startMoment.isValid() ? startMoment.format(TIME_FORMAT_DISPLAY) : "";
        const endStr = endMoment.isValid() ? endMoment.format(TIME_FORMAT_DISPLAY) : "";

        if (startStr && endStr) {
            return `${startStr}-${endStr}`;
        }
        if (startStr) {
            return startStr;
        }
        if (endStr) {
            return endStr;
        }
        return time;
    };

    renderContent = () => {
        const { allowedPeriods, deliveryTime, deliveryDisplayDate, saveDeliveryTime } = this.props;
        return (
            <div className="time-picker-container">
                <div className="flex-1 date-list-wrapper">
                    {Object.keys(this.props.allowedPeriods).map((date) => {
                        return (
                            <div
                                className="flexbox flex-justify-content-space-between flex-align-items-center date-wrapper"
                                onClick={() => {
                                    saveDeliveryTime("deliveryDisplayDate", date);
                                    this.props.setState({
                                        expectedDeliveryTimeStart: null,
                                        expectedDeliveryTimeEnd: null,
                                    });
                                }}
                                key={date}
                            >
                                {this.renderDate(date)}
                                {deliveryDisplayDate === date ? <FaCheck color="#04BE02" /> : ""}
                            </div>
                        );
                    })}
                </div>

                <div className="flex-1 time-list-wrapper">
                    {allowedPeriods[deliveryDisplayDate] &&
                        allowedPeriods[deliveryDisplayDate].map((time: any, i: any) => {
                            return (
                                <div
                                    className="flexbox flex-justify-content-space-between flex-align-items-center time-wrapper"
                                    key={`${deliveryDisplayDate}_${time}_${i}`}
                                    onClick={() => {
                                        saveDeliveryTime("deliveryTime", time);
                                        this.props.setState({
                                            expectedDeliveryTimeStart: null,
                                            expectedDeliveryTimeEnd: null,
                                        });
                                    }}
                                >
                                    <span>{this.getTime(time)}</span>
                                    {deliveryTime === time ? <FaCheck color="#04BE02" /> : ""}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    render() {
        const { title, show, allowedPeriods, deliveryDisplayDate, toggleDeliveryTimePopup } = this.props;

        if (allowedPeriods && deliveryDisplayDate) {
            return (
                <Modal title={title} footer={null} visible={show} onCancel={() => toggleDeliveryTimePopup(false)}>
                    {this.renderContent()}
                </Modal>
            );
        } else {
            return "";
        }
    }
}
const mapStateToProps = (state: any) => ({
    lan: state.setting.lan,
});

const mapDispatchToProps = {
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TimePicker));
