import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../app/reducer";
import qs from "querystring";
import { getStoreManagementList } from "../../../../services/store-management";
import { setStoreManagementState } from "../../../../slices/store-management";

const selector = (state: RootState) => ({
    searchConditions: state?.referrals?.searchConditions,
});

function useStoreManagementSearchCondition(): any {
    const dispatch = useDispatch();

    const { searchConditions } = useSelector(selector);
    const { search: s } = useLocation();

    const search = s.substring(1);

    useEffect(() => {
        dispatch(setStoreManagementState({ searchConditions: qs.parse(search) }));
        dispatch(getStoreManagementList(qs.parse(search)));
    }, [dispatch, search]);

    return {
        searchConditions,
    };
}

export default useStoreManagementSearchCondition;
