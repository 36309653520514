import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../app/reducer";
import { store } from "../app/store";
import { getDefaultFilters, getOrderId, sortFilterSearchValues } from "../pages/order-history/helper-order-list";
import { oauth } from "../util/api";

const initialState: any = {
    actionLoading: false,
    orderList: null,
    orderListPaging: {
        page: "1",
        total: null,
        limit: "20",
    },
    summary: {},
    searchConditions: {},
    paneSize: null,
    orderDetailInstance: {},
    orderDetailDisplay: false,
    isDetailloading: false,
    isListLoading: false,
    cachedNewIds: [],
    nextPrev: "",
    showBulkUpdate: true,
    showRefundDialog: false,
    allowRefundSubmit: true,
};

const slice = createSlice({
    name: "orders-page",
    initialState,
    reducers: {
        setState(state, { payload }) {
            state = Object.assign(state, payload);
        },
        fetchOrderListSuccess(state, { payload }) {
            const { orderListResponse, searchConditions } = payload?.response ?? {};
            if (orderListResponse && Number(orderListResponse.RC) === 200) {
                state.orderList = _.cloneDeep(_.get(orderListResponse, "records", []));
                state.searchConditions = searchConditions;
                state.orderListPaging.page = _.get(orderListResponse, "paging.page", 1);
                state.orderListPaging.total = _.get(orderListResponse, "paging.total", 0);
                state.orderListPaging.size = _.get(orderListResponse, "paging.perPage", 20);
                state.summary = _.get(orderListResponse, "paging", {});
                state.showListAlert = false;
            }
        },
        clearOrderList(state, { payload }) {
            const { searchConditions } = payload?.response ?? {};
            state.orderList = [];
            state.searchConditions = searchConditions;
            state.orderListPaging.page = 1;
            state.orderListPaging.total = 0;
            state.orderListPaging.size = _.get(searchConditions, "search_condition.paging.limit", 20);
            state.showListAlert = false;
        },
        fetchOrderDetailSuccess(state, { payload }) {
            const order = _.get(payload, "response.records");
            if (!_.isEmpty(order)) {
                state.orderDetailInstance = order;

                //update list
                const orderId = getOrderId(order);
                const orderList = Array.isArray(state.orderList) ? _.cloneDeep(state.orderList) : [];
                const index = _.findIndex(orderList, (ord) => getOrderId(ord) === orderId);
                if (index !== -1) {
                    orderList[index] = order;
                }
                state.orderList = orderList;
            }
        },
        updateOrderDetail(state, { payload }) {
            const records =
                _.get(payload, "response.records.old_order") ||
                _.get(payload, "response.res.old_order") ||
                _.get(payload, "response.order") ||
                _.get(payload, "response.records") ||
                _.get(payload, "response.res") ||
                {};
            const RC = _.get(payload, "response.RC");
            const newOrder = _.get(payload, "response.res.new_order") || _.get(payload, "response.records.new_order");
            const list = Array.isArray(records) ? records : [records];
            const orderList = Array.isArray(state.orderList) ? _.cloneDeep(state.orderList) : [];
            const orderInstance = state.orderDetailInstance;
            list.forEach((order) => {
                const orderId = getOrderId(order);
                const index = _.findIndex(orderList, (ord) => getOrderId(ord) === orderId);
                if (index !== -1) {
                    orderList[index] = order;
                }
                if (orderId === getOrderId(orderInstance)) {
                    state.orderDetailInstance = order;
                }
            });
            if (newOrder) {
                state.orderDetailInstance = newOrder;
                orderList.unshift(newOrder);
            }
            if (Number(RC) === 200) {
                state.showRefundDialog = false;
            }
            state.orderList = orderList;
            state.showListAlert = true;
        },
        fetchOrderComments(state, { payload }) {
            const comments = _.get(payload, "response.records", []);
            state.orderDetailInstance.data.cmt_list = comments;
        },
    },
});

export const ordersPage = slice.reducer;

const actions: any = _.cloneDeep(slice.actions);

const selectWithOrderState = () => {
    const state = store.getState();
    return _.get(state, "orders-page", {});
};
const getFetchOrderDetailId = (response: any, state: any) => {
    const order = _.get(state, "orderDetailInstance", {});
    const orderList = Array.isArray(_.get(response, "records", [])) ? _.get(response, "records", []) : [];
    const inList = orderList.find((ord: any) => getOrderId(ord) === getOrderId(order));
    if (inList) return false;
    if (_.isEmpty(order)) return false;
    return getOrderId(_.get(orderList, 0, {}));
};
const currentLan = () => {
    const state = store.getState();
    return _.get(state, "settings.lan", "en");
};

actions.fetchOrderList = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isListLoading: true }));
    try {
        const { clear, callBack } = payload ?? {};
        const selectWithOrder = selectWithOrderState();
        const oldSearch = _.get(selectWithOrder, "searchConditions.search_condition");
        const originalSearchValue = clear
            ? getDefaultFilters()
            : {
                  ...oldSearch,
                  ...(payload ?? {}),
              };
        const searchConditions = {
            "search_condition": originalSearchValue,
        };
        const sortedSearchValues = sortFilterSearchValues(originalSearchValue);
        const sortedSearchConditions = {
            "search_condition": sortedSearchValues,
        };
        if (sortedSearchValues) {
            const p = {
                method: "POST",
                body: JSON.stringify(sortedSearchConditions),
            };
            const orderListResponse = await oauth("ORDER_HISTORY")(p);
            dispatch(actions.fetchOrderListSuccess({ response: { orderListResponse, searchConditions } }));
            const orderId = getFetchOrderDetailId(orderListResponse, selectWithOrder);
            if (orderId) dispatch(actions.fetchOrderDetail({ orderId }));
        } else {
            dispatch(actions.clearOrderList({ response: { searchConditions } }));
        }
        if (callBack) {
            callBack();
        }
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isListLoading: false }));
};

actions.fetchOrderDetail = (payload: any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    dispatch(actions.setState({ isDetailLoading: true }));
    try {
        const { orderId } = payload ?? {};
        let response = {};
        //get from api
        const lan = currentLan();
        const append = `${orderId}?lan=${lan}&require_cid=1`;
        const p = {
            method: "GET",
        };
        response = await oauth("ORDER_DETAIL", "", append)(p);
        dispatch(actions.fetchOrderDetailSuccess({ response }));
    } catch (e) {
        // Handle error
    }
    dispatch(actions.setState({ isDetailLoading: false }));
};

export default actions;
