import React from "react";
import QR from "../_components/qr-comp";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import "../serve-print.scss";

interface Format9Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    customdark: boolean | any;
    dataURLS: string | any;
    paperSize: string | any;
    storeLogo: string | any;
}

function Format9(props: Format9Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            padding: `${props.bleedingMargin}mm`,
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        qrPrintPdfContainer: {
            position: "relative",
            width: "4.8cm",
            height: "4.8cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            marginBottom: `${props.bleedingMargin ?? 0}mm`,
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: "10pt",
            fontSize: "16pt",
        },
        logo: {
            position: "absolute",
            display: "flex",
            width: "35pt",
            height: "35pt",
            top: "50px",
        },
        qrHolder: {
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    });

    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() =>
                        props.dataURLS?.map((url: any, index: number) => (
                            <View
                                key={`qrPrint-pdf-container-${index}`}
                                style={styles.qrPrintPdfContainer}
                                wrap={false}
                            >
                                {QR(styles, url, props)}
                            </View>
                        ))
                    }
                />
            </Page>
        </Document>
    );
}
export default Format9;
