import { Modal } from "antd";
import React, { useState } from "react";

function emptyFunction() {
    // does nothing
}

const useModal = (className = "", closeCallback = emptyFunction): any => {
    const [open, setModal] = useState(false);

    const openModal = () => setModal(true);

    const closeModal = () => setModal(false);

    const getModal = (child: any, modalProps: any) => {
        return (
            <Modal
                visible={open}
                {...modalProps}
                className={className}
                onCancel={() => {
                    closeCallback();
                    closeModal();
                    if (modalProps.onCancel) {
                        modalProps.onCancel();
                    }
                }}
            >
                {child}
            </Modal>
        );
    };

    return {
        getModal,
        setModal,
        openModal,
        closeModal,
    };
};

export default useModal;
