import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { getStoreList, switchStore } from "../../../../../services/store-switch";
import { ApprovedStore, WorkInProgressStore } from "../../../../../slices/store-switch";

function StoreSwitchForm(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const approvedStores = useSelector((state: RootState) => state.storeSwitch.approvedStores);
    const wipStores = useSelector((state: RootState) => state.storeSwitch.wipStores);
    const [selectedApprovedStore, setSelectedApprovedStore] = useState<ApprovedStore>();
    const [selectedWipStore, setSelectedWipStore] = useState<WorkInProgressStore>();

    const getOptions = (type: string) => {
        const stores = type === "approved" ? approvedStores : wipStores;
        return stores.map((store) => ({
            key: store.id,
            value: `${store.name}|${store.id}`,
            label: (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <span>{store.name}</span>
                    <span>{store.id}</span>
                </div>
            ),
        }));
    };

    const handleSelectApproved = (value: any) => {
        const id = Number(value?.split?.("|")?.[1]);
        const store = approvedStores.find((store) => store.id === id);
        setSelectedApprovedStore(store);
    };

    const handleSelectWip = (value: any) => {
        const id = Number(value?.split?.("|")?.[1]);
        const store = wipStores.find((store) => store.id === id);

        setSelectedWipStore(store);
    };

    const onSwitchApproved = () => {
        dispatch(switchStore(selectedApprovedStore));
    };

    const onSwitchWip = () => {
        dispatch(switchStore(selectedWipStore));
    };

    useEffect(() => {
        dispatch(getStoreList());
    }, [dispatch]);

    return (
        <div className="store-switcher-container">
            <div>
                <Select
                    showSearch
                    placeholder={intl.formatMessage({ id: "approved_store" })}
                    style={{ width: "100%" }}
                    onSelect={handleSelectApproved}
                >
                    {getOptions("approved")?.map((opt: any, i: any) => (
                        <Select.Option key={`${opt.key}-${i}`} value={opt.value}>
                            {opt.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ paddingTop: 12 }}>
                <Select
                    showSearch
                    placeholder={intl.formatMessage({ id: "wip_store" })}
                    style={{ width: "100%" }}
                    onSelect={handleSelectWip}
                >
                    {getOptions("wip")?.map((opt: any, i: any) => (
                        <Select.Option key={`${opt.key}-${i}`} value={opt.value}>
                            {opt.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ paddingTop: 12 }}>
                <Button type="primary" disabled={!selectedApprovedStore} onClick={onSwitchApproved}>
                    <FormattedMessage id="switch_approved" />
                </Button>
                <Button type="primary" disabled={!selectedWipStore} style={{ marginLeft: 12 }} onClick={onSwitchWip}>
                    <FormattedMessage id="switch_wip" />
                </Button>
            </div>
        </div>
    );
}

export default StoreSwitchForm;
