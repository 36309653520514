import { Button, Modal, Popover, Radio, Checkbox, Select } from "antd";
import React, { useState, useEffect } from "react";
import { FiShare } from "react-icons/fi";
import { HiDotsHorizontal, HiInformationCircle } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { toast } from "react-toastify";
import { RootState } from "../../../../app/reducer";
import { createMessage } from "../../../../components/message";
import config from "../../../../config";
import { deleteProduct, getProduct } from "../../../../services/products";
import { QRCode } from "react-qrcode-logo";
import helper, { getGSaleURL, getProductURL } from "../../../../util/helper";
import _ from "lodash";
import { ProductTypes } from "../../constants";
import PosterDialog from "../../../../components/poster-dialog";
import { setProductState } from "../../../../slices/products";

const selector = (state: RootState) => ({
    products: state?.products?.products,
    lan: state?.setting?.lan,
});

interface ActionsRendererProps {
    openProductShortDescription: any;
    openProductDescription: any;
    value: string;
}

function ActionsRenderer(props: ActionsRendererProps): JSX.Element {
    const { openProductShortDescription, openProductDescription } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const { products, lan } = useSelector(selector);
    const [showQR, setShowQR] = useState<boolean>(false);
    const [prodQR, setProdQR] = useState<boolean>(true);
    const [QRCodeType, setQRCodeType] = useState<string>("regular_product_qr");
    const [paymentMethod, setPaymentMethod] = useState<string>("gift_card");
    const [confCheckout, setConfCheckout] = useState<boolean>(true);
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [showPoster, setShowPoster] = useState<boolean>(false);
    const [currProduct, setCurrProduct] = useState<number>();
    const [currProductName, setCurrProductName] = useState<string>("");
    const storeLogoUrl = useSelector((state: RootState) => state?.store?.storeImages?.store_logo);

    const record = products?.find?.((p) => Number(p?.product_id) === Number(props?.value));

    const social_url: string =
        record?.product_type === ProductTypes.GROUP_PRODUCT
            ? getGSaleURL(record?.product_id ?? "", lan)
            : getProductURL(record?.product_id ?? "", lan);
    const instant_pay_url = `${config.H5_URL}checkout?pid=${record?.product_id}`;
    const message = intl.formatMessage({ id: "share_product" });

    const onCopyToNewProduct = () => {
        const r: any = _.cloneDeep(record);
        //@ts-ignore
        dispatch(
            getProduct(Number(r?.product_id), () => {
                dispatch(setProductState({ duplicate: true }));
            })
        );
    };

    const handleDelete = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: "warning" }),
            content: intl.formatMessage({ id: "confirm_delete_product" }),
            cancelText: intl.formatMessage({ id: "cancel" }),
            okText: intl.formatMessage({ id: "ok" }),
            onOk() {
                dispatch(deleteProduct({ pid: record?.product_id }));
            },
        });
    };

    const menu = () => (
        <div className="d-flex flex-column justify-content-start align-items-start">
            <p
                className="popover-item mb-1"
                onClick={() => history.push("/products/addEditItem/" + record?.product_id)}
            >
                <FormattedMessage id="view_edit" />
            </p>
            <p className="popover-item mb-1" onClick={onCopyToNewProduct}>
                <FormattedMessage id="copy_to_new_product" />
            </p>
            {record?.product_type === ProductTypes.GROUP_PRODUCT ? null : (
                <p className="popover-item mb-1" onClick={openProductShortDescription(record?.product_id)}>
                    <FormattedMessage id="short_description" />
                </p>
            )}
            <p className="popover-item mb-1" onClick={openProductDescription(record?.product_id)}>
                <FormattedMessage id="description" />
            </p>
            <p className="popover-item danger mb-1" onClick={handleDelete}>
                <FormattedMessage id="delete" />{" "}
            </p>
        </div>
    );
    const share = () => {
        return (
            <div className="d-flex flex-column justofy-content-start align-items-start">
                <FacebookShareButton url={social_url} quote={message}>
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_facebook" />
                    </p>
                </FacebookShareButton>
                <a
                    target="_blank"
                    href={`mailto:?subject=${message}&body=${message} ${social_url}`}
                    rel="noopener noreferrer"
                >
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_email" />
                    </p>
                </a>
                <TwitterShareButton url={social_url} title={message} via={message}>
                    <p className="popover-item mb-1">
                        <FormattedMessage id="share_twitter" />
                    </p>
                </TwitterShareButton>
                <p className="popover-item mb-1" onClick={() => openWeChatQRModal(record?.name)}>
                    <FormattedMessage id="share_qrcode" />
                </p>
                <p className="popover-item mb-1" onClick={() => openPosterModal()}>
                    <FormattedMessage id="poster" />
                </p>
                <p className="popover-item mb-1" onClick={() => window.open(social_url, "_blank")}>
                    <FormattedMessage id="open_product_webpage" />
                </p>
                <p className="popover-item mb-1" onClick={() => copyProduct(social_url)}>
                    <FormattedMessage id="copy_product_url" />
                </p>
            </div>
        );
    };
    const openWeChatQRModal = (name: string) => {
        setShowQR(true);
        setCurrProductName(helper.getTransString(name, lan));
        setCurrProduct(record?.product_id);
    };

    const openPosterModal = () => {
        setShowPoster(true);
    };

    const copyProduct = (url: string) => {
        navigator.clipboard.writeText(url).then(
            function () {
                toast(createMessage(intl.formatMessage({ id: "product_url_copied" }), HiInformationCircle));
            },
            function () {
                // Handle error
            }
        );
    };

    useEffect(() => {
        if (QRCodeType === "regular_product_qr") {
            resetQRState();
        } else {
            setOpenOptions(true);
            setProdQR(false);
        }
    }, [QRCodeType]);

    useEffect(() => {
        if (paymentMethod === "user_input") {
            setConfCheckout(false); //to set the QR code url param '&confirm=1'
        }
    }, [paymentMethod]);

    const QrCode = () => {
        const canvas: any = document.getElementById("react-qrcode-logo");
        const pngUrl = canvas?.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");

        downloadLink.href = pngUrl;
        downloadLink.download = `${currProductName}-${currProduct}${prodQR ? "" : "-gc"}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setShowQR(false);
    };

    const resetQRState = () => {
        setOpenOptions(false);
        setProdQR(true);
        setConfCheckout(true);
        setPaymentMethod("gift_card");
    };
    const DEFAULT_QR_OPTION = ["regular_product_qr", "instant_co_qr"];
    const radioOption = DEFAULT_QR_OPTION.map((value) => ({
        value,
        label: intl.formatMessage({ id: value ?? " " }),
        disabled:
            value === "instant_co_qr" &&
            record?.product_type !== ProductTypes.NORMAL_PRODUCT &&
            record?.product_type !== ProductTypes.GIFT_CARD,
    }));
    const DEFAULT_PAYMENT_OP = ["gift_card", "user_input"];
    const selectOption = DEFAULT_PAYMENT_OP.map((value) => ({
        value,
        label: intl.formatMessage({ id: value ?? " " }),
    }));

    const renderQROption = () => {
        const onRadioChange = (e: any) => {
            setQRCodeType(e.target.value);
        };
        const onCheckChange = (e: any) => {
            setConfCheckout(e.target.checked);
        };
        const onOptionChange = (value: any) => {
            setPaymentMethod(value);
        };
        const renderCheckBox = () => {
            const isPayment = paymentMethod === "user_input";
            return (
                <Checkbox
                    onChange={onCheckChange}
                    checked={isPayment ? false : confCheckout}
                    disabled={isPayment ? true : false}
                >
                    <FormattedMessage id="ignore_conf_on_checkout" />
                </Checkbox>
            );
        };
        return (
            <>
                <Radio.Group options={radioOption} onChange={onRadioChange} value={QRCodeType} />
                {openOptions ? (
                    <div className="mt-2">
                        <label>
                            <FormattedMessage id="payment_method" />
                        </label>
                        <Select className="select-input ml-2" value={paymentMethod} onChange={onOptionChange}>
                            {selectOption.map((option) => (
                                <Select.Option key={option?.value} value={option?.value}>
                                    {" "}
                                    {option?.label}{" "}
                                </Select.Option>
                            ))}
                        </Select>
                        {renderCheckBox()}
                    </div>
                ) : null}
            </>
        );
    };

    const getQRModal = () => {
        const isUserInput = paymentMethod === "user_input";
        const url = prodQR
            ? social_url
            : instant_pay_url + `&confirm=${confCheckout ? "0" : "1"}&p_mtd=${isUserInput ? "-1" : "3"}`;
        const title = prodQR ? "share_qrcode" : "share_qrcode_instant";
        return (
            <Modal
                width="300px"
                footer={null}
                title={intl.formatMessage({ id: title })}
                onCancel={() => {
                    resetQRState();
                    setShowQR(false);
                    setQRCodeType("regular_product_qr");
                }}
                bodyStyle={{ padding: "15px", margin: "0px" }}
                visible={showQR}
            >
                {renderQROption()}
                <div className="d-flex flex-column mt-1 align-items-center">
                    <QRCode
                        value={`${url}`}
                        size={250}
                        logoImage={`${config.IMG_PREFIX}${storeLogoUrl}`}
                        enableCORS={true}
                    />
                    <div className="product-qr-code mt-2" onClick={() => QrCode()}>
                        <FormattedMessage id="download_QR" />
                    </div>
                </div>
            </Modal>
        );
    };

    const renderPosterModal = (
        <PosterDialog type="product" product={record} open={showPoster} onClose={() => setShowPoster(false)} />
    );

    return (
        <div className="d-flex flex-row product-buttons">
            <Popover content={share()} placement="left">
                <Button type="link">
                    <FiShare size="20" />
                </Button>
            </Popover>
            <Popover content={menu()} placement="left">
                <Button type="link">
                    <HiDotsHorizontal size="20" />
                </Button>
            </Popover>
            {getQRModal()}
            {renderPosterModal}
        </div>
    );
}

export default ActionsRenderer;
