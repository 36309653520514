import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import "./gift-card-section.scss";
import { setState } from "../../../../slices/create-order";
import GiftCardsList from "./gift-cards-list";
import { getGiftcardAccounts } from "../../../../services/giftcards";
import { Modal } from "antd";

class GiftCardsPickerDialog extends React.Component<any, any> {
    componentDidUpdate(prevProps: any) {
        this.checkShouldInitData(prevProps);
    }

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    getGiftCards = (props = this.props) => {
        return Array.isArray(props.giftCards) ? props.giftCards : [];
    };

    getGiftCardsSample = (props = this.props) => {
        return !_.isEmpty(this.getGiftCards(props)) ? this.getGiftCards(props)[0] : {};
    };

    getGiftCardsCount = (props = this.props) => {
        return _.get(props, "giftCardsCount", 0);
    };

    checkShouldInitData = (prevProps: any) => {
        const dialogOpen = !_.isEqual(this.shouldOpen(prevProps), this.shouldOpen()) && this.shouldOpen();
        if (dialogOpen) {
            this.initData();
        }
    };

    initData = () => {
        this.props.getGiftcardAccounts({ c_id: this.props.cid, page: 1, limit: this.getGiftCardsCount() });
    };

    shouldOpen = (props = this.props) => {
        return props.createOrder.displayModalName === "giftcards";
    };

    closeDialog = () => {
        this.props.setState({ displayModalName: "" });
    };

    render() {
        return (
            <Modal
                footer={null}
                key="gift-card-picker-dialog"
                visible={this.shouldOpen()}
                onCancel={() => this.closeDialog()}
            >
                <div className="gift-card-picker-dialog-container">
                    <GiftCardsList />
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: state.setting.lan,
    createOrder: _.get(state, "createOrder", {}),
    giftCards: _.get(state, "giftcards.accounts", []),
    giftCardsCount: _.get(state, "createOrder.payment.giftCardsCount", 0),
    cid: _.get(state, "customers.customer.id", ""),
});

const mapDispatchToProps = {
    setState,
    getGiftcardAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GiftCardsPickerDialog));
