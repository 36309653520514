import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import { FaAngleRight } from "react-icons/fa";
import PeriodsPicker from "./order-allowed-periods-picker";
import moment from "moment";
import { setState } from "slices/create-order";
import config from "config";
import helper from "util/helper";
import { ASAP } from "app/constants";

class CustomerInfoSection extends React.Component<any, any> {
    static defaultProps = {};

    hasPeriods = () => {
        const peroids = _.get(this.props, "state.allowedPeriods", []);
        return !_.isEmpty(peroids);
    };

    getCheckoutState = () => {
        return this.props.state;
    };

    openTimePeriodPicker = () => {
        if (this.hasPeriods()) this.props.setState({ displayDeliveryTimePicker: true });
    };

    getExpectedTimeFieldName = () => {
        const shippingMethod = this.props.shippingMethod;
        let result;
        if (helper.isDelivery(shippingMethod)) {
            result = "delivery_time";
        } else if (helper.isPickup(shippingMethod)) {
            result = "pickup_time";
        } else {
            result = "expected_ready_time";
        }

        return result;
    };

    getDeliveryDate = () => {
        const SERVER_TIME_DATE_FORMAT = "YYYY-MM-DD";
        const string = (id: any) => this.props.intl.formatMessage({ id });
        const getDate = (date: any) => {
            const seperator = ":";
            const DATE_FORMAT_DISPLAY = "ddd, MMM DD,";
            const formats = {
                nextDay: `[${string("tomorrow")}]`,
                sameDay: `[${string("today")}]`,
                lastDay: `[${string("yesterday")}]`,
                sameWeek: `${DATE_FORMAT_DISPLAY}`,
                nextWeek: `${DATE_FORMAT_DISPLAY}`,
                lastWeek: `${DATE_FORMAT_DISPLAY}`,
                sameYear: `${DATE_FORMAT_DISPLAY}`,
                sameElse: `${DATE_FORMAT_DISPLAY}`,
            };
            return moment(date, SERVER_TIME_DATE_FORMAT).isValid()
                ? moment(date, SERVER_TIME_DATE_FORMAT).calendar(null, formats).split(seperator)
                : [];
        };
        return this.getCheckoutState().deliveryDate ? getDate(this.getCheckoutState().deliveryDate) : " ";
    };

    getDeliveryTime = () => {
        return this.getCheckoutState().deliveryTime ? this.getCheckoutState().deliveryTime : ASAP;
    };

    /**
     * Using two expected delivery times, make an expected delivery time range
     *
     * @example Today 03:20pm-03:30pm
     *
     * @param expectedDeliveryTimeStart - expected delivery start time
     * @param expectedDeliveryTimeEnd - expected delivery end time
     * @returns a date time format of delivery time.
     */
    getDeliveryDateTime = (expectedDeliveryTimeStart: any, expectedDeliveryTimeEnd: any) => {
        if (_.isEqual(expectedDeliveryTimeStart, config.DELIVERY_TIME_ASAP)) {
            return ASAP;
        }

        let expectedDeliveryDate = null;
        let expectedDeliveryTime = null;
        const DATE_TIME_FORMAT = "YYYY_MM_DD hh:mm A";
        const DATE_FORMAT = "YYYY-MM-DD";
        const TIME_FORMAT = "hh:mmA";
        const string = (id: any) => this.props.intl.formatMessage({ id });
        const getDate = (date: any) => {
            const seperator = ":";
            const DATE_FORMAT_DISPLAY = "ddd, MMM DD,";
            const formats = {
                nextDay: `[${string("tomorrow")}]`,
                sameDay: `[${string("today")}]`,
                lastDay: `[${string("yesterday")}]`,
                sameWeek: `${DATE_FORMAT_DISPLAY}`,
                nextWeek: `${DATE_FORMAT_DISPLAY}`,
                lastWeek: `${DATE_FORMAT_DISPLAY}`,
                sameYear: `${DATE_FORMAT_DISPLAY}`,
                sameElse: `${DATE_FORMAT_DISPLAY}`,
            };
            return moment(date, DATE_FORMAT).isValid()
                ? moment(date, DATE_FORMAT).calendar(null, formats).split(seperator)
                : [];
        };
        expectedDeliveryDate = getDate(expectedDeliveryTimeStart) ?? "";
        if (
            moment(expectedDeliveryTimeStart, TIME_FORMAT).isValid() &&
            moment(expectedDeliveryTimeEnd, TIME_FORMAT).isValid()
        ) {
            expectedDeliveryTime = `${moment(expectedDeliveryTimeStart, DATE_TIME_FORMAT).format(TIME_FORMAT)}-${moment(
                expectedDeliveryTimeEnd,
                DATE_TIME_FORMAT
            ).format(TIME_FORMAT)}`;
        }
        return `${expectedDeliveryDate} ${expectedDeliveryTime}`;
    };

    getDeliveryDisplayTime = () => {
        const str = (id: any) => this.props.intl.formatMessage({ id });
        if (!this.hasPeriods()) {
            return helper.isDelivery(this.props.shippingMethod)
                ? str("please_input_delivery_time")
                : str("please_input_pickup_time");
        } else {
            return this.props.state?.expectedDeliveryTimeStart && this.props.state?.expectedDeliveryTimeEnd
                ? this.getDeliveryDateTime(
                      this.props.state?.expectedDeliveryTimeStart,
                      this.props.state?.expectedDeliveryTimeEnd
                  )
                : this.getDeliveryTime().includes(ASAP)
                ? ASAP
                : `${this.getDeliveryDate()} ${this.getDeliveryTime()}`;
        }
    };

    renderItemValue = () => (
        <div className={`payment-method-picker-containter`}>
            <div className={`payment-picker-text`}>{this.getDeliveryDisplayTime()}</div>
            <div className="payment-picker-value-icon">
                <FaAngleRight />
            </div>
        </div>
    );

    render() {
        return this.hasPeriods() ? (
            <>
                <div className="createOrder-list-item-container" onClick={() => this.openTimePeriodPicker()}>
                    <div className="createOrder-list-item-section-one">
                        <FormattedMessage id={this.getExpectedTimeFieldName()} />
                    </div>
                    <div className="createOrder-list-item-section-two"> {this.renderItemValue()} </div>
                </div>
                <PeriodsPicker />
            </>
        ) : null;
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", true),
    lan: _.get(state, "setting.lan", true),
    shippingMethod: _.get(state, "createOrder.shippingMethod", null),
});

const mapDispatchToProps = {
    //from service
    setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerInfoSection));
