import { Button, Form, Modal, Table, Tooltip, Switch, Radio, Input, Spin, Space } from "antd";
import _, { isString } from "lodash";
import { HiQuestionMarkCircle } from "react-icons/hi";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { RootState } from "../../../../../../app/reducer";
import {
    getDescBlock,
    getNumberInput,
    getSingleBlock,
    getSwitchDescBlock,
    getSwitchInput,
    mapBooleansToNumbers,
} from "../../../../../../components/form";
import useModal from "../../../../../../hooks/useModal";
import usePlacesAutocomplete from "../../../../../../hooks/usePlacesAutoComplete";
import {
    getDeliveryZoneTimeSlots,
    createUpdateDeliveryZoneTimeSlots,
    deleteDeliveryZoneTimeSlot,
    overwriteDeliveryZoneTimeSlots,
    appendDeliveryZoneTimeSlots,
} from "../../../../../../services/pickup-delivery";
import { updateStoreDetails } from "../../../../../../services/store";
import { setPickupDeliveryState } from "../../../../../../slices/store";
import { setEditId } from "../../../../../../slices/pickup-delivery";
import helper, {
    isBool,
    isNum,
    readTextFileFromInput,
    saveTextToFile,
    convertInitialValuesToBool,
} from "../../../../../../util/helper";
import ObjectModel from "../../../../../../util/models";
import StorePickupDelivery from "../../../../../../util/models/store-pickup-delivery";
import ZoneEditorModalContent from "../delivery-tab/zone-editor-modal-content";
import { RESTORE_OPTIONS } from "../../constants";
import "./styles.scss";

const DAYS_KEY = "days";
const AVAILABILITY_KEY = "availability";

const PickupTab = (): JSX.Element | null => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const lan = useSelector((state: RootState) => state.setting?.lan);
    const initialValues = useSelector((state: RootState) => state.store?.storePickupDelivery);
    const specifyPickup = useSelector((state: RootState) => state.store?.storePickupDelivery?.specify_pickup);
    const pickupSlots = useSelector((state: RootState) => state.pickupDelivery?.deliveryTimeSlots);
    const records = useSelector((state: RootState) => state.store?.records);
    const editId = useSelector((state: RootState) => state.pickupDelivery?.editId);
    const isLoading = useSelector((state: RootState) => state.pickupDelivery?.isLoading);
    const [specify, setSpecify] = useState<boolean>(isBool(specifyPickup));
    const [originalSpecify, setOriginalSpecify] = useState<boolean>();
    const [allow, setAllow] = useState(false);
    const [showRestoreOptions, setShowRestoreOptions] = useState(false);
    const [restoreOption, setRestoreOption] = useState(RESTORE_OPTIONS.overwrite);

    const { openModal, getModal, closeModal } = useModal();
    const {
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete();
    const [touched, setTouched] = useState(false);
    const MIN_LOCATION = 1;

    useEffect(() => {
        setOriginalSpecify(isBool(specifyPickup));
    }, [specifyPickup]);

    useEffect(() => {
        dispatch(getDeliveryZoneTimeSlots());
    }, [dispatch]);

    const resetFormatFields = useCallback(() => {
        const resetFields: any = [];
        const oldValues = form.getFieldsValue(true);
        Object.keys(oldValues).forEach((key) => (oldValues[key] === undefined ? delete oldValues[key] : {}));
        Object.keys(oldValues).forEach((key) => resetFields.push(key));
        form.resetFields(resetFields);
        form.setFieldsValue(oldValues);
    }, [form]);

    useEffect(() => {
        resetFormatFields();
    }, [lan, resetFormatFields]);

    useEffect(() => {
        setAllow(initialValues?.allow_takeout === "1");
    }, [initialValues]);

    const getPickupSlots = useCallback(() => {
        return pickupSlots?.filter((ds: any) => Object.prototype.hasOwnProperty.call(ds, "pickup_location"));
    }, [pickupSlots]);

    useEffect(() => {
        if (getPickupSlots()?.length < MIN_LOCATION) {
            form.setFieldsValue({ "specify_pickup": false });
            setSpecify(false);
        } else {
            setSpecify(isBool(specifyPickup));
        }
    }, [getPickupSlots, form, pickupSlots, specifyPickup]);

    const onAllowChange = (checked: boolean) => {
        setAllow(checked);
    };

    const enableAndOrderAhead = (switcher: any, days: any) => (
        <div className="white-layered-block d-flex flex-row">
            <div className="switch-title">
                <FormattedMessage id="allow_takeout" />
                {switcher}
            </div>
            {allow ? (
                <div className="switch-title">
                    <div className="mr-0">
                        <FormattedMessage id="order_ahead_days" />
                    </div>
                    <div className="toolTip-container mr-3">
                        <Tooltip title={intl.formatMessage({ id: "order_ahead_days_tip" })}>
                            <HiQuestionMarkCircle />
                        </Tooltip>
                    </div>
                    {days}
                </div>
            ) : null}
        </div>
    );

    const switchSpecify = (e: any) => {
        setSpecify(e);
        dispatch(setPickupDeliveryState({ name: "specify_pickup", value: isNum(e).toString() }));
        form.setFieldsValue({ "specify_pickup": e });
        setTouched(e !== originalSpecify);
    };

    const getSwitchSpecify = () => (
        <Form.Item name={"specify_pickup"} style={{ marginBottom: "0px", marginLeft: "30px" }}>
            <Switch defaultChecked={isBool(specifyPickup)} onChange={(e) => switchSpecify(e)} checked={specify} />
        </Form.Item>
    );

    const renderForm = () => (
        <>
            {enableAndOrderAhead(
                getSwitchInput("allow_takeout", initialValues, onAllowChange),
                getNumberInput(
                    "order_ahead_days",
                    undefined,
                    false,
                    {
                        formatter: (value: any) => helper.formatDays(value, intl),
                    },
                    false,
                    "days",
                    "50px"
                )
            )}
            {allow ? (
                <>
                    {getDescBlock(
                        "estimated_preparation_time",
                        true,
                        "estimated_preparation_time_desc",
                        getNumberInput(
                            "pickup_preparation_time",
                            undefined,
                            false,
                            {
                                formatter: (value: any) => helper.formatMinutes(value, intl),
                                max: 9999,
                            },
                            false,
                            "minutes",
                            "50px"
                        )
                    )}
                    {getDescBlock(
                        "prepare_order_threshold",
                        true,
                        "prepare_order_threshold_desc",
                        getNumberInput(
                            "prepare_order_threshold",
                            undefined,
                            false,
                            {
                                formatter: (value: any) => helper.formatMinutes(value, intl),
                                max: 9999,
                            },
                            false,
                            "minutes",
                            "50px"
                        )
                    )}
                    {getSwitchDescBlock("specify_pickup", "specify_pickup_desc", getSwitchSpecify())}
                    {!specify ? (
                        <>
                            {getDescBlock(
                                "pickup_time_range",
                                true,
                                "pickup_time_range_desc",
                                getNumberInput(
                                    "pickup_time_range",
                                    undefined,
                                    false,
                                    {
                                        formatter: (value: any) => helper.formatMinutes(value, intl),
                                        max: 9999,
                                        min: 5,
                                    },
                                    false,
                                    "minutes",
                                    "50px"
                                )
                            )}
                            {getSingleBlock(
                                "min_pickup_amount",
                                true,
                                getNumberInput("min_pickup_amount", undefined, false, {
                                    formatter: (value: any) => helper.formatMoney(value, intl),
                                    parser: (value: any) => helper.parseMoney(value, intl),
                                    max: 9999,
                                })
                            )}
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    );

    const onFinish = (data: any) => {
        if (specify && getPickupSlots()?.length < MIN_LOCATION) {
            Modal.error({ title: intl.formatMessage({ id: "need_atleast_address_for_multiple" }) });
        } else {
            setTouched(false);
            const mapped = mapBooleansToNumbers(data, ["allow_takeout", "specify_pickup"]);
            dispatch(
                updateStoreDetails(
                    new ObjectModel(StorePickupDelivery).convertToPostData(mapped, records, "pickup_delivery")
                )
            );
        }
    };

    function tConvert(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }

    const renderDays = (_: any, record: any) => {
        return record?.availabilities?.map?.((a: any) => (
            <div key={`${DAYS_KEY}-${record._id}`} onClick={() => rowOnClick(record)}>
                {a?.days?.map?.((d: any) => (isString(d) ? d.charAt(0).toUpperCase() + d.slice(1) : ""))?.join?.(", ")}
            </div>
        ));
    };

    const renderAvailability = (_: any, record: any) => {
        return record?.availabilities?.map?.((a: any, index: number) => {
            return (
                <div key={`${AVAILABILITY_KEY}-${record._id}`} onClick={() => rowOnClick(record)}>
                    {a?.hours?.map?.((h: any) => (
                        <div key={`${AVAILABILITY_KEY}-${record._id}-${index}`}>
                            {tConvert(h?.open)} - {tConvert(h?.close)}
                        </div>
                    ))}
                    {a?.hours === "ALL" ? <FormattedMessage id="all" /> : null}
                </div>
            );
        });
    };

    const rowOnClick = (record: any) => {
        dispatch(setEditId(record?._id ?? null));
        openModal();
    };

    const renderAddress = (_: any, record: any) => (
        <div onClick={() => rowOnClick(record)}>
            {helper.formatAddress(record?.pickup_location?.address, {}).join(", ")}
        </div>
    );

    const renderActions = (_: any, record: any) => (
        <div className="clickable-row-parent">
            <div className="clickable-row" onClick={() => rowOnClick(record)}></div>
            <Button
                style={{ marginRight: 12 }}
                onClick={() => {
                    dispatch(setEditId(record?._id ?? null));
                    openModal();
                }}
            >
                <FormattedMessage id="update" />
            </Button>
            <Button
                danger
                onClick={() => {
                    Modal.confirm({
                        title: intl.formatMessage({ id: "confirm_delete" }),
                        content: intl.formatMessage({ id: "are_you_sure_delete_slot" }),
                        onOk: () => deletePickup(record?._id),
                    });
                }}
            >
                <FormattedMessage id="delete" />
            </Button>
        </div>
    );

    const deletePickup = (id: any) => {
        if (pickupSlots?.length === 1) {
            form.setFieldsValue({ "specify_pickup": false });
            setSpecify(false);
            setTouched(false !== originalSpecify);
        }
        dispatch(deleteDeliveryZoneTimeSlot(id));
    };

    const renderLocationName = (_: any, record: any) => (
        <div className="clickable-row-parent">
            <div className="clickable-row" onClick={() => rowOnClick(record)}>
                {record?.name}
            </div>
        </div>
    );

    const getTableColumns = () => [
        { title: intl.formatMessage({ id: "location_name" }), render: renderLocationName },
        { title: intl.formatMessage({ id: "address" }), render: renderAddress },
        { title: intl.formatMessage({ id: "days" }), render: renderDays },
        { title: intl.formatMessage({ id: "availability" }), render: renderAvailability },
        { title: intl.formatMessage({ id: "actions" }), render: renderActions },
    ];

    const savePickupSettingsToFile = () => {
        saveTextToFile(JSON.stringify(getPickupSlots()), "pickup-settings.json");
    };

    const restorePickupSlots = (e: React.ChangeEvent<HTMLInputElement>) => {
        const onLoad = (e: ProgressEvent<FileReader>) => {
            try {
                const result = e.target?.result;
                if (!result) {
                    return;
                }

                // @ts-ignore
                const data = JSON.parse(result);

                if (restoreOption === RESTORE_OPTIONS.overwrite) {
                    const pickupTimeSlotIds: any[] = [];
                    getPickupSlots().forEach((pickupTimeSlot: any) => {
                        pickupTimeSlotIds.push(pickupTimeSlot._id);
                    });
                    dispatch(overwriteDeliveryZoneTimeSlots(data, pickupTimeSlotIds));
                } else if (restoreOption === RESTORE_OPTIONS.append) {
                    dispatch(appendDeliveryZoneTimeSlots(data));
                }
            } catch (e) {
                // Handle error
            }
        };

        readTextFileFromInput(e, onLoad);
    };

    const renderRestoreOptionModal = () => {
        return (
            <Modal
                visible={showRestoreOptions}
                title={<FormattedMessage id="restore_options" />}
                onCancel={() => setShowRestoreOptions(false)}
                footer={[
                    <Button key="submit">
                        <label>
                            <FormattedMessage id="continue" />
                            <Input type="file" className="file-input" onChange={restorePickupSlots} accept=".json" />
                        </label>
                    </Button>,
                ]}
            >
                <FormattedMessage id="restore_options_message_pickup" />
                <div className="spacing-8" />
                <Radio.Group onChange={(e) => setRestoreOption(e.target.value)} value={restoreOption}>
                    <Space direction="vertical">
                        <Radio value={RESTORE_OPTIONS.overwrite}>
                            <FormattedMessage id="overwrite_existing_records" />
                        </Radio>
                        <Radio value={RESTORE_OPTIONS.append}>
                            <FormattedMessage id="append_to_existing_records" />
                        </Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        );
    };

    const renderTable = () => (
        <Spin spinning={isLoading}>
            <Table
                size="large"
                columns={getTableColumns()}
                dataSource={getPickupSlots()}
                pagination={false}
                rowClassName="pickup-location-row"
                footer={() => (
                    <div className="delivery-zone-table-footer">
                        <div>
                            <Button onClick={savePickupSettingsToFile}>
                                <FormattedMessage id="backup" />
                            </Button>
                            <span className="h-spacing-8" />
                            <Button onClick={() => setShowRestoreOptions(true)}>
                                <FormattedMessage id="restore" />
                            </Button>
                        </div>
                        <div>
                            <Button onClick={openModal} className="add-button-handler">
                                <FormattedMessage id="add_pickup_availability" />
                            </Button>
                        </div>
                    </div>
                )}
            />
        </Spin>
    );

    const validateZone = (zone: any): any[] => {
        const requiredKeys = [
            { lanKey: "name", inputKey: "name" },
            { lanKey: "phone", inputKey: "pickup_location.phone" },
            { lanKey: "city", inputKey: "pickup_location.address.city" },
            { lanKey: "country", inputKey: "pickup_location.address.country" },
            { lanKey: "postal_code", inputKey: "pickup_location.address.postal_code" },
            { lanKey: "province", inputKey: "pickup_location.address.region" },
            { lanKey: "street", inputKey: "pickup_location.address.street" },
        ];

        const missingKeys: any[] = [];

        requiredKeys.forEach((req) => {
            if (!_.get(zone, req.inputKey)) {
                missingKeys.push(intl.formatMessage({ id: req.lanKey }));
            }
        });

        return missingKeys;
    };

    const onSaveModal = (zone: any) => {
        if (!zone.group_id) {
            zone.group_id = records.g_id;
        }
        const missingKeys = validateZone(zone);
        if (missingKeys.length < 1) {
            dispatch(setEditId(null));
            dispatch(createUpdateDeliveryZoneTimeSlots(zone));
            closeModal();
            return true;
        } else {
            const missingString = missingKeys.join(", ");
            Modal.error({
                title: intl.formatMessage({ id: "missing_fields" }),
                content: intl.formatMessage({ id: "missing_fields_message" }, { missing: missingString }),
            });
        }
    };

    const handleValuesChanged = () => {
        setTouched(form.isFieldsTouched());
    };

    const clearForm = () => {
        form.resetFields();
        setAllow(initialValues?.allow_takeout === "1")
        setTouched(false);
    };

    return !_.isEmpty(initialValues) ? (
        <div className="display-options-page has-floating-submit">
            <Prompt when={touched} message={intl.formatMessage({ id: "unsaved_changes" })} />
            <Form
                name="delivery_settings"
                form={form}
                initialValues={convertInitialValuesToBool(initialValues)}
                onFinish={onFinish}
                onValuesChange={handleValuesChanged}
            >
                {renderForm()}
                {specify && allow ? renderTable() : null}
                <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
                    <Button size="large" disabled={!touched} style={{ marginRight: 14 }} onClick={clearForm}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!touched}
                        className="save-button-handler"
                    >
                        <FormattedMessage id="save_changes" />
                    </Button>
                </div>
            </Form>

            {pickupSlots.map((slot: any) =>
                slot?._id === editId ? (
                    <>
                        {getModal(<ZoneEditorModalContent onSave={onSaveModal} places={{ data, setValue }} />, {
                            title: intl.formatMessage({ id: "edit_zone" }),
                            width: 1368,
                            onCancel: () => dispatch(setEditId(null)),
                            footer: null,
                        })}
                    </>
                ) : null
            )}
            {editId === null
                ? getModal(<ZoneEditorModalContent onSave={onSaveModal} places={{ data, setValue }} />, {
                      title: intl.formatMessage({ id: "edit_zone" }),
                      width: 1368,
                      onCancel: () => dispatch(setEditId(null)),
                      footer: null,
                  })
                : null}
            {renderRestoreOptionModal()}
        </div>
    ) : null;
};

export default PickupTab;
