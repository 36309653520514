import { createSlice } from "@reduxjs/toolkit";
import helper, { updateMoment } from "../util/helper";
import _ from "lodash";

export interface Setting {
    lan: string;
    requireDineInConfirmation: boolean;
}

export enum Languages {
    English = "en",
    Chinese = "zh",
    ChineseTraditional = "zh-Hant",
    Korean = "kr",
    German = "de",
    French = "fr",
}

export enum PhoneCodes {
    "CA" = "+1",
    "DE" = "+49"
}

const initialState: Setting = {
    lan: Languages.English,
    requireDineInConfirmation: _.isBoolean(helper.getLocalStorage("requireDineInConfirmation"))
        ? helper.getLocalStorage("requireDineInConfirmation")
        : false,
};

const slice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setLanguage: (state, { payload }) => {
            updateMoment(payload);
            state.lan = payload;
        },
        setConfirmationDineIn: (state, { payload }) => {
            helper.setLocalStorage("requireDineInConfirmation", payload);
            state.requireDineInConfirmation = payload;
        },
    },
});

export const { setLanguage, setConfirmationDineIn } = slice.actions;

export const setting = slice.reducer;
