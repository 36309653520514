import React, { useEffect } from "react";
import { Button, Tabs } from "antd";
import _ from "lodash";
import qs from "querystring";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import TableRateTab from "./_components/table-rate";
import FlatRateTab from "./_components/flat-rate";
import FreeShippingTab from "./_components/free-shipping";
import UPSTab from "./_components/ups";
import FedexTab from "./_components/fedex";
import C2CTab from "./_components/c2c";
import ObjectModel from "../../../../util/models";
import "./index.scss";
import StoreShippingModel from "../../../../util/models/store-shipping";
import { getStoreDetails, updateStoreDetails } from "../../../../services/store";
import { toast } from "react-toastify";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/shipping", breadcrumbName: "shipping_setting" },
    ],
};

const tabs: any = [
    { key: "table_rate", tab: TableRateTab },
    { key: "flat_rate", tab: FlatRateTab },
    { key: "free_shipping_rule", tab: FreeShippingTab },
    { key: "ups", tab: UPSTab },
    { key: "fedex", tab: FedexTab },
    { key: "c2c", tab: C2CTab },
];

function App(): JSX.Element | null {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const shippingDetails = useSelector((state: RootState) => state.store?.storeShipping);
    const shippingUpdateCopy = useSelector((state: RootState) => state.store?.storeShippingCopy);
    const records = useSelector((state: RootState) => state.store?.records);
    const tab = String(qs.parse(location.search)?.["?tab"] ?? "table_rate");
    const onChangeTab = (tab: string) => {
        const shippingInfo = _.cloneDeep(shippingUpdateCopy);
        history.push("/settings/shipping?tab=" + tab);
        delete shippingInfo?.tableRules;
    };

    useEffect(() => {
        dispatch(getStoreDetails());
    }, [dispatch]);

    const removeEmpty = (obj: any) => {
        const finalObj: any = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key] && typeof obj[key] === "object" && Object.keys(obj[key]).length > 1) {
                const nestedObj = removeEmpty(obj[key]);
                if (Object.keys(nestedObj).length) {
                    finalObj[key] = nestedObj;
                }
            } else if (obj[key] !== "" && obj[key] !== undefined && obj[key] !== null) {
                finalObj[key] = obj[key];
            }
        });
        return finalObj;
    };

    const saveChanges = () => {
        const shippingInfo = _.cloneDeep(shippingUpdateCopy);
        const hasTableAllowedCountries =
            shippingInfo?.tableShipCountryType === "1" && _.isEmpty(shippingInfo?.tableAllowCountry ?? []);
        const hasFlatAllowedCountries =
            shippingInfo?.flatShipCountryType === "1" && _.isEmpty(shippingInfo?.flatAllowCountry ?? []);
        const hasFreeAllowedCountries =
            shippingInfo?.freeShipCountryType === "1" && _.isEmpty(shippingInfo?.freeAllowCountry ?? []);
        if (hasTableAllowedCountries || hasFlatAllowedCountries || hasFreeAllowedCountries) {
            toast(intl.formatMessage({ id: "error_enter_allowed_country_field" }));
            return;
        }
        const formattedShippingInfo: any = {};
        if (_.isEmpty(shippingInfo?.tableRules)) {
            delete shippingInfo?.tableRules;
        }
        if (shippingInfo.c2cExpShip) {
            formattedShippingInfo.c2cExpShip = shippingInfo.c2cExpShip;
        }
        if (shippingInfo.c2cRegShip) {
            formattedShippingInfo.c2cRegShip = shippingInfo.c2cRegShip;
        }
        const updatedObject = new ObjectModel(StoreShippingModel).convertToPostData(
            shippingInfo,
            records,
            "shipping_setting"
        );
        const filteredResult = _.cloneDeep(removeEmpty(updatedObject));
        // the following code is for handling c2c methods
        let c2cMethods = [];
        if (Object.keys(filteredResult).length > 0 && filteredResult.shipping_setting.c2c) {
            // turned methods into an array
            filteredResult.shipping_setting.c2c.methods = Object.values(filteredResult.shipping_setting.c2c.methods);
            c2cMethods = filteredResult.shipping_setting.c2c.methods;
            if (c2cMethods && c2cMethods.length === 2) {
                for (let i = 0; i < c2cMethods.length; i++) {
                    c2cMethods[i].code = i === 0 ? "c2c_regular" : "c2c_express";
                    c2cMethods[i].enable !== undefined
                        ? c2cMethods[i].enable
                        : (c2cMethods[i].enable = records.shipping_setting.c2c.methods[i].enable);
                    c2cMethods[i].settings
                        ? c2cMethods[i].settings
                        : (c2cMethods[i].settings = records.shipping_setting.c2c.methods[i].settings);
                }
            }
            if (c2cMethods && c2cMethods.length === 1) {
                if (Object.keys(formattedShippingInfo)[0] === "c2cExpShip") {
                    c2cMethods[0].code = "c2c_express";
                    c2cMethods[0].settings
                        ? c2cMethods[0].settings
                        : (c2cMethods[0].settings = records.shipping_setting.c2c.methods[1].settings);
                    c2cMethods[0].enable !== undefined
                        ? c2cMethods[0].enable
                        : (c2cMethods[0].enable = records.shipping_setting.c2c.methods[1].enable);
                    c2cMethods.unshift({
                        enable: records.shipping_setting.c2c.methods[0].enable,
                        settings: records.shipping_setting.c2c.methods[0].settings,
                        code: "c2c_regular",
                    });
                } else if (Object.keys(formattedShippingInfo)[0] === "c2cRegShip") {
                    c2cMethods[0].code = "c2c_regular";
                    c2cMethods[0].settings
                        ? c2cMethods[0].settings
                        : (c2cMethods[0].settings = records.shipping_setting.c2c.methods[0].settings);
                    c2cMethods[0].enable !== undefined
                        ? c2cMethods[0].enable
                        : (c2cMethods[0].enable = records.shipping_setting.c2c.methods[0].enable);
                    c2cMethods.push({
                        enable: records.shipping_setting.c2c.methods[1].enable,
                        settings: records.shipping_setting.c2c.methods[1].settings,
                        code: "c2c_express",
                    });
                }
            }
        }

        dispatch(updateStoreDetails(filteredResult));
    };

    const renderSaveSection = () => (
        <div className="setting-actions" style={{ marginTop: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return !_.isEmpty(shippingDetails) ? (
        <Wrapper helmet={{ title: "shipping_setting" }} breadcrumb={breadcrumb}>
            <Tabs type="card" activeKey={tab} defaultActiveKey={tab} onChange={onChangeTab} style={{ marginTop: 14 }}>
                {tabs.map((tab: any) => (
                    <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                        <tab.tab />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            {renderSaveSection()}
        </Wrapper>
    ) : null;
}

export default App;
