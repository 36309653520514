import { createSlice } from "@reduxjs/toolkit";

export interface TimeListItem {
    available_time?: any;
    code: string;
    is_assigned: number;
    name: string;
}

interface State {
    timeList: TimeListItem[];
}

const initialState: State = {
    timeList: [],
};

const slice = createSlice({
    name: "time",
    initialState,
    reducers: {
        getTimeListSuccess(state, { payload }) {
            state.timeList = payload?.records?.time_setting;
        },
    },
});

export const { getTimeListSuccess } = slice.actions;

export const time = slice.reducer;
