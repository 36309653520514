import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { getStoreRecordsSuccess, setStoreManagementState } from "../slices/store-management";
import { oauth } from "../util/api";
import qs from "querystring";
import { DEFAULT_PAGE_SIZE } from "../app/constants";
import helper from "../util/helper";
import _ from "lodash";

export const getStoreManagementList = (params?: Record<string, any> | any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(setStoreManagementState({ loading: true }));
    try {
        if (!params.limit) {
            params.limit = DEFAULT_PAGE_SIZE;
        }

        const response: any = await oauth(
            "STORE_MANAGEMENT",
            qs.stringify(params)
        )({
            method: "GET",
        });

        switch (response?.RC) {
            case 200:
                dispatch(getStoreRecordsSuccess({ response }));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(setStoreManagementState({ loading: false }));
};

export const manageStore =
    (params?: Record<string, any>, search = {}) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
        dispatch(setStoreManagementState({ loading: true }));
        try {
            let response: any = await oauth("STORE_MANAGEMENT")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            if (helper.isString(response)) {
                response = JSON.parse(response);
            }

            switch (response?.RC) {
                case 200:
                    dispatch(getStoreManagementList(_.cloneDeep(search)));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setStoreManagementState({ loading: false }));
    };
