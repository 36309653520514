import { Tabs } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { TaskDeliveryStatusTabComponent, TaskDetailComponent, TaskItemsTabComponent } from ".";
import "../../style.scss";

const TaskTabsLayout: React.FC = () => {
    const intl = useIntl();
    const TABS_LIST = [
        {
            label: intl.formatMessage({ id: "task_detail" }),
            key: "task-detail",
            children: <TaskDetailComponent />,
        },
        {
            label: intl.formatMessage({ id: "task_items" }),
            key: "task-items",
            children: <TaskItemsTabComponent />,
        },
        {
            label: intl.formatMessage({ id: "task_delivery_status" }),
            key: "task-delivery-status",
            children: <TaskDeliveryStatusTabComponent />,
        },
    ];

    return (
        <div className="delivery-tasks-tabs-container">
            <Tabs>
                {TABS_LIST.map((task) => {
                    return (
                        <Tabs.TabPane key={task.key} tab={task.label}>
                            {task.children}
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default TaskTabsLayout;
