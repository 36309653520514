import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { getIntl } from "../locale";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import { getPublishSuccess } from "../slices/publish";

export const getPublish = () => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    try {
        const response: any = await oauth("PUBLISH")({
            method: "GET",
        });
        switch (response.RC) {
            case 200:
                dispatch(getPublishSuccess(response.records));
                break;
            default:
                break;
        }
    } catch (e) {
        // Handle error
    }
};

export const updatePublish = (params?: Record<string, any> | any) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    const intl = getIntl();
    const hide = toast(createMessage(intl.formatMessage({ id: "publishing" }), HiInformationCircle));
    try {
        const response: any = await oauth("PUBLISH")({
            method: "POST",
            body: JSON.stringify({ action: params.action }),
        });
        const PUBLISH = 1;
        switch (response.RC) {
            case 200:
                dispatch(getPublish());
                params.action === PUBLISH
                    ? toast(createMessage(intl.formatMessage({ id: "alert_success_publish" }), HiInformationCircle))
                    : toast(createMessage(intl.formatMessage({ id: "alert_success_unpublish" }), HiInformationCircle));
                break;
            default:
                params.action === PUBLISH
                    ? toast(createMessage(intl.formatMessage({ id: "alert_failed_publish" }), HiInformationCircle))
                    : toast(createMessage(intl.formatMessage({ id: "alert_failed_unpublish" }), HiInformationCircle));
                break;
        }
    } catch (e) {
        // Handle error
    }
    toast.dismiss(hide);
};
