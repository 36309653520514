import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import ObjectModel from "../util/models";
import { getAdminSuccess } from "../slices/admin";
import { getIntl } from "../locale";
import admin from "../util/models/admin";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import { updateProfileSuccess } from "../slices/user";
import { setAdminStateUser } from "../slices/admin-users";
export const getAdminDetails =
    () =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth("PROFILE")({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getAdminSuccess({ admin: new ObjectModel(admin, response.records).getData() }));
                    dispatch(updateProfileSuccess(response.records));
                    dispatch(setAdminStateUser(response.records));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateAdminDetails =
    (params?: Record<string, any> | any, notification = false) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();
        try {
            const response: any = await oauth("PROFILE")({
                method: "POST",
                body: JSON.stringify({
                    data: { ...params.profile },
                }),
            });
            switch (response.RC) {
                case 200: {
                    dispatch(getAdminDetails());
                    const savedModel = params.changeLocation ?? "store_information";
                    if (!notification)
                        toast(
                            createMessage(
                                `${intl.formatMessage({ id: savedModel })} ${intl.formatMessage({ id: "saved" })}`,
                                HiInformationCircle
                            )
                        );
                    break;
                }
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const updateNotifications = (params: Record<string, any>) => async (): Promise<any> => {
    try {
        const response: any = await oauth("ADMIN_USERS")({
            method: "POST",
            body: JSON.stringify({
                data: { ...params },
            }),
        });
        const intl = getIntl();
        switch (response?.RC) {
            case 200:
                toast(createMessage(intl.formatMessage({ id: "notifications_settings_changed" }), HiInformationCircle));
                break;
            default:
                toast(createMessage(`${intl.formatMessage({ id: "error_please_try_again" })}`, HiInformationCircle));
                break;
        }
    } catch (e) {
        // Handle error
    }
};
