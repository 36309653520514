import React from "react";
import GoopterIcon from "../_components/goopter-icon";
import tableNum from "../_components/tablenum-comp";
import paymentIcons from "../_components/payment-comp";
import scanOrder from "../_components/scantxt-comp";
import QR from "../_components/qr-comp";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import "../serve-print.scss";

interface Format2Props {
    addMargin: boolean | any;
    bgColor: string | any;
    bleedingMargin: number | any;
    customdark: boolean | any;
    dataURLS: string | any;
    paperSize: string | any;
    storeLogo: string | any;
    dark: boolean | any;
}

function Format2(props: Format2Props): JSX.Element {
    const styles = StyleSheet.create({
        page: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            padding: `${props.bleedingMargin}mm`,
        },
        qrPrintPdfContainer: {
            position: "relative",
            width: "5.06cm",
            height: "11cm",
            display: "flex",
            marginRight: props.addMargin ? "1pt" : "0pt",
            marginBottom: `${props.bleedingMargin ?? 0}mm`,
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: props.dark ? "#504F4F" : props.bgColor ?? "#20817b",
            borderRadius: "10pt",
            fontSize: "16pt",
        },
        qrCodeCardBlock: {
            position: "relative",
            width: "100%",
            display: "flex",
            padding: "28pt",
        },
        tableNumCont: {
            position: "relative",
            width: "100%",
            display: "flex",
            height: "20%",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "35pt",
            opacity: 0.85,
        },
        tableNumHolder: {
            position: "relative",
            height: "2cm",
            width: "2cm",
            marginTop: "20pt",
            borderRadius: "1cm",
            backgroundColor: props.dark ? "#504F4F" : "white",
            justifyContent: "center",
            alignItems: "center",
            color: props.dark ? "white" : "red",
            fontSize: "24pt",
            fontWeight: 700,
        },
        qrHolder: {
            position: "relative",
            height: "90pt",
            width: "90pt",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        scanTxt: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "20pt",
            borderRadius: "5pt",
            fontWeight: 500,
            fontSize: "12pt",
            color: props.dark ? "white" : "red",
            backgroundColor: props.dark ? "#504F4F" : "white",
            marginBottom: "10pt",
            opacity: 0.85,
        },
        paymentContainer: {
            position: "relative",
            width: "100%",
            height: "20pt",
            marginTop: "10pt",
            marginBottom: "5pt",
            padding: "0 10%",
        },
        goopterContainer: {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "14pt",
            marginTop: "5pt",
            backgroundColor: props.dark ? "#504F4F" : "white",
            borderRadius: "5pt",
            opacity: 0.85,
        },
        poweredBy: {
            fontSize: "7pt",
            fontWeight: 600,
            color: props.dark ? "white" : "#626161",
        },
        goopterLogo: {
            width: "1cm",
            marginTop: "2px",
        },
        pageContainer: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        logo: {
            position: "absolute",
            display: "flex",
            width: "24pt",
            height: "24pt",
        },
    });

    return (
        <Document>
            <Page size={props.paperSize ?? "A4"} style={styles.page}>
                <View
                    style={styles.pageContainer}
                    render={() =>
                        props.dataURLS?.map((url: any, index: number) => (
                            <View
                                key={`qrPrint-pdf-container-${index}`}
                                style={styles.qrPrintPdfContainer}
                                wrap={false}
                            >
                                <View style={styles.qrCodeCardBlock}>
                                    {scanOrder(styles)}
                                    {QR(styles, url, props)}
                                    {tableNum(styles, url)}
                                    {paymentIcons(styles)}
                                    {GoopterIcon(styles)}
                                </View>
                            </View>
                        ))
                    }
                />
            </Page>
        </Document>
    );
}
export default Format2;
