import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import { setState, getQuotesSuccess } from "../slices/quotes";
import _ from "lodash";
import { getIntl } from "../locale";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { HiInformationCircle } from "react-icons/hi";
import { getCreateOrderState } from "../pages/quotes/helper";
import { setState as setCreateOrderState } from "../slices/create-order";
import config from "../config";

export const getQuotes = (params: Record<string, any>) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(setState({ gettingQuotes: true }));
    try {
        const retrieveWithParams = {
            ...params,
            action: config.QUOTE_API_POST_ACTION_MAPPING.retrieve_quotes,
        };
        const response = await oauth("QUOTES")({
            method: "POST",
            body: JSON.stringify({ data: retrieveWithParams }),
        });
        dispatch(getQuotesSuccess({ response, retrieveWithParams }));
    } catch (e) {
        // Handle error
    }
    dispatch(setState({ gettingQuotes: false }));
};

export const getQuoteDetail = (params: Record<string, any>) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(setState({ gettingQuotes: true }));
    try {
        const response: any = await oauth(
            "QUOTES",
            "",
            `${params?.qid}/${params?.lan}`
        )({
            method: "GET",
        });
        dispatch(getQuotesSuccess({ response, params }));
        dispatch(
            setCreateOrderState({
                ...getCreateOrderState(response.res),
                initialData: getCreateOrderState(response.res),
            })
        );
        if (params?.callBack) params?.callBack();
    } catch (e) {
        // Handle error
    }
    dispatch(setState({ gettingQuotes: false }));
};

export const deleteQuotes = (params: Record<string, any>) => async (dispatch: ThunkDispatch<RootState, void, AnyAction>) : Promise<any> => {
    dispatch(setState({ gettingQuotes: true }));
    try {
        _.assign(params, { action: config.QUOTE_API_POST_ACTION_MAPPING.delete_quote });
        const response = await oauth("QUOTES")({
            method: "POST",
            body: JSON.stringify({ data: params }),
        });
        switch (response?.RC) {
            case 200:
                if (params?.shouldShowQuoteDeletedMsg) {
                    toast(
                        createMessage(
                            `${getIntl().formatMessage({ id: "deleted_quotes_successfully" })}`,
                            HiInformationCircle
                        )
                    );
                }
                dispatch(getQuotes({ lan: params?.lan }));
                break;
            default:
                toast(createMessage(`${getIntl().formatMessage({ id: "unexpect_error" })}`, HiInformationCircle));
                break;
        }
    } catch (e) {
        // Handle error
    }
    dispatch(setState({ gettingQuotes: false }));
};
