import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectIntl } from "react-intl";
import {
    getOrderId,
    getCustomerName,
    getOrderMainStatusText,
    getOrderSubStatusText,
    showStoreName,
    getStore,
    getStoreName,
    getOrderType,
    getOrderTable,
    getOrderTotalStr,
    getPaymentIcon,
    getOrderTime,
    getDueTimeInfo,
    isSameOrder,
    findOrderInListAndPerformAction,
    getCustomerPhone,
} from "../../helper-order-list";
import { formatOrderId, allowBulkUpdate } from "../../helper";
import helper, { isRestaurant } from "../../../../util/helper";
import { Checkbox } from "antd";
import actions from "../../../../slices/order-history";
import liveOrderActions from "../../../../slices/live-order";
import CopyArea from "components/copy-area";

class OrderListTableListItem extends Component {
    state = {
        height: window.innerHeight,
        width: window.innerWidth,
        isExportOrderSelected: false,
        // toggle display of copy icon
    };

    componentDidMount() {
        window.addEventListener("resize", (e) => this.updateWindowDimensions(e));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.selectedExportOrders, this.props.selectedExportOrders)) {
            this.setState({ isExportOrderSelected: this.isExportOrderSelected() });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions());
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    showBulkController = () => {
        return _.get(this.props, "state.bulkUpdating", false);
    };

    getSelectedOrders = () => {
        return Array.isArray(_.get(this.props, "state.selectedOrders"))
            ? _.get(this.props, "state.selectedOrders")
            : [];
    };

    getSelectedOrdersIndex = () => {
        const orderId = getOrderId(this.getOrder());
        return _.findIndex(this.getSelectedOrders(), (order) => getOrderId(order) === orderId);
    };

    isExportOrderSelected = () => {
        return (
            this.props.selectedExportOrders.find((selectedOrder) => {
                return isSameOrder(selectedOrder, this.getOrder());
            }) !== undefined
        );
    };

    getOrder = (props = this.props) => _.get(props, `order`, {});

    onRowClick = () => {
        if (this.showBulkController()) {
            this.updateBulkUpdateList();
        } else if (this.props.useExportSelection) {
            this.updateExportSelectionList();
        } else {
            this.fetchOrderDetail();
        }
    };

    updateBulkUpdateList = () => {
        const index = this.getSelectedOrdersIndex();
        const checked = index !== -1;
        let result = _.cloneDeep(this.getSelectedOrders());
        if (!checked) {
            result.push(this.getOrder());
        } else {
            result.splice(index, 1);
        }
        this.props.setState({
            selectedOrders: result,
        });
    };

    updateExportSelectionList = () => {
        const updatedSelectedExportOrders = _.cloneDeep(this.props.selectedExportOrders);
        findOrderInListAndPerformAction(
            updatedSelectedExportOrders,
            this.getOrder(),
            (index) => updatedSelectedExportOrders.splice(index, 1),
            () => updatedSelectedExportOrders.push(this.getOrder())
        );
        this.props.setState({ selectedExportOrders: updatedSelectedExportOrders });
    };

    fetchOrderDetail = () => {
        const order = _.get(this.props, `order`, {});
        const orderId = getOrderId(order);
        this.props.setLiveOrderState({
            expansionControllers: {
                [orderId]: { showDetail: true },
            },
        });
        this.props.fetchOrderDetail({ orderId });
        this.props.setState({ showOrderDetail: true });
    };

    str = (id, values) => this.props.intl.formatMessage({ id: id || " " }, values);

    getTableColumnsData = () => {
        const order = _.get(this.props, `order`, {});
        const stores = _.get(this.props, `storesData`, []);
        const store = getStore(order, stores);
        let result = [
            {
                key: "order-id",
                mainInfo: this.renderOrderIdRow(order),
                subInfo: showStoreName(stores) ? getStoreName(store, this.props.lan) : "",
                width: "15%",
            },
            {
                mainInfo: this.str(getOrderMainStatusText(order, store).text),
                subInfo: getOrderSubStatusText(order, store) ? this.str(getOrderSubStatusText(order, store)) : "",
                mainInfoStyle: {
                    color: getOrderMainStatusText(order, store).color,
                },
                subInfoStyle: {
                    fontWeight: "bold",
                },
                width: "23.5%",
            },
            {
                mainInfo: getCustomerName(order),
                subInfo: this.renderPhoneInfo(order),
                width: "16%",
            },
            {
                mainInfo: this.str(getOrderType(order, store)),
                subInfo: getOrderTable(order, store)
                    ? `${this.str("table_number_no_pound_sign")} ${getOrderTable(order, store)}`
                    : "",
                width: "10%",
            },
            {
                mainInfo: this.renderPriceRow(order, store),
            },
            {
                mainInfo: getOrderTime(order, store),
                subInfo: this.str("order_time"),
            },
            {
                mainInfo: getDueTimeInfo(order, store)["main-info"],
                subInfo: getDueTimeInfo(order, store)["sub-info"]
                    ? this.str(getDueTimeInfo(order, store)["sub-info"])
                    : "",
                subInfoStyle: {
                    color: getDueTimeInfo(order, store)["show-red"] ? "red" : "#1890ff",
                },
                width: "10%",
            },
        ];

        if (this.showBulkController()) {
            result.unshift({
                key: "check-box",
                checkbox: this.renderCheckbox(),
                width: 30,
            });
        } else if (this.props.useExportSelection) {
            result.unshift({
                key: "check-box",
                checkbox: this.renderExportCheckbox(),
                width: 30,
            });
        }

        //calculate and adjust the remain width of other columns
        const totalWidth = `100`;
        const remainWidth = result.reduce((acc, item) => {
            const widthStr = String(_.get(item, "width", 0));
            const widthFloat = parseFloat(_.get(item, "width", 0));
            const percentage = widthStr.includes("%");
            acc -= percentage ? widthFloat : widthFloat / this.state.width;
            return acc;
        }, totalWidth);
        const remainColumns = result.filter((item) => !_.get(item, "width", 0));
        const remianColumnWidth = remainWidth / remainColumns.length;
        result.map((column) => {
            if (!_.get(column, "width", 0)) column.width = `${remianColumnWidth}%`;
            return column;
        });
        return result;
    };

    renderOrderIdRow = (order) => {
        const orderId = isRestaurant(getStore(order, this.props.storesData))
            ? formatOrderId(getOrderId(order))
            : getOrderId(order);
        return (
            <CopyArea
                iconPosition="end"
                copyData={getOrderId(order)}
                messageId="order_id"
                className="order-list-table-order-id-wrapper"
            >
                #{orderId}
            </CopyArea>
        );
    };

    renderPriceRow = (order, store) => (
        <div className="order-list-table-column-main-info-wrapper">
            {helper.isPPCP3dsOrder(order) ? <b id="lock-icon">&#128274;</b> : null}
            <img src={getPaymentIcon(order)} alt="" className="order-list-table-row-payment-icon"></img>
            {getOrderTotalStr(order, store)}
        </div>
    );

    renderCheckbox = () => {
        const checked = this.getSelectedOrdersIndex() !== -1;
        return <Checkbox disabled={!allowBulkUpdate(this.getOrder())} checked={checked} />;
    };

    renderExportCheckbox = () => {
        return <Checkbox checked={this.state.isExportOrderSelected} />;
    };

    renderPhoneInfo = (order) => {
        const formattedPhoneNumber = getCustomerPhone(order) ? helper.formatPhone(getCustomerPhone(order)) : "";
        return (
            <>
                {formattedPhoneNumber ? (
                    <CopyArea
                        iconPosition="end"
                        copyData={formattedPhoneNumber.replace(/[^0-9]+/gi, "")}
                        messageId="phone_number"
                    >
                        {formattedPhoneNumber}
                    </CopyArea>
                ) : null}
            </>
        );
    };

    renderSubInfo = ({ subInfo, subInfoStyle, onMouseEnter, onMouseLeave }) => {
        if (subInfo) {
            return (
                <div
                    style={subInfoStyle}
                    className="order-list-table-column-sub-info"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {subInfo}
                </div>
            );
        } else {
            return null;
        }
    };

    renderOrderTableColumn = (data) => {
        const order = _.get(this.props, `order`, {});
        const {
            mainInfo,
            mainInfoStyle,
            subInfo,
            subInfoStyle,
            width = 145,
            key,
            checkbox,
            onMouseEnter,
            onMouseLeave,
        } = data;

        return (
            <div
                key={`${key ?? mainInfo}-${getOrderId(order)}`}
                style={{ width: width }}
                className="order-list-table-column"
            >
                {checkbox ? (
                    checkbox
                ) : (
                    <>
                        <div style={mainInfoStyle} className="order-list-table-column-main-info">
                            {mainInfo}
                        </div>
                        {this.renderSubInfo({ subInfo, subInfoStyle, onMouseEnter, onMouseLeave })}
                    </>
                )}
            </div>
        );
    };

    render() {
        return (
            <div onClick={() => this.onRowClick()} className="order-list-table-row">
                {this.getTableColumnsData().map((data, index) => this.renderOrderTableColumn(data, index))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    storesData: _.get(state, "managedStores.activeStores", []),
    state: _.get(state, "orders-page", {}),
    useExportSelection: _.get(state, "orders-page.useExportSelection", false),
    selectedExportOrders: _.get(state, "orders-page.selectedExportOrders", []),
});

const mapDispatchToProps = {
    setState: actions.setState,
    fetchOrderDetail: actions.fetchOrderDetail,
    setLiveOrderState: liveOrderActions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderListTableListItem));
