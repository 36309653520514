import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import helper from "../../../../util/helper";

interface CustomerCurrencyProps {
    value: any[];
    name: string;
}

function CustomerCurrencyRenderer(props: CustomerCurrencyProps): JSX.Element | null {
    const customers = useSelector((state: RootState) => state?.customers?.customers);
    const currency =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";

    const record = customers?.find((customer: any) => Number(customer?.id) === Number(props?.value));

    return record ? <div>{helper.formatCurrency(record?.[props?.name ?? " "], currency ?? "CAD")}</div> : null;
}

export default CustomerCurrencyRenderer;
