import { Button } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import helper from "util/helper";
import { getStoreWithGid } from "pages/liveorder/helper";
import { formatOrderId, getDisplayTotal, getOrderTypeText } from "pages/order-history/helper";
import { FILTER_KEYS, getOrderId, getOrderMainStatusText } from "pages/order-history/helper-order-list";
import cx from "classnames";
import { setCustomersState } from "slices/customers";
import CopyArea from "components/copy-area";

class RecentOrders extends Component {
    componentDidMount() {}

    getReceintOrders = () => {
        const recentLists = _.get(this.props, "customer.last_orders");
        const recentArray = Array.isArray(recentLists) ? recentLists : [];
        return recentArray;
    };

    pushToOrderDetail = (order) => {
        this.props.history.push(`/orderDetails?ord_id=${getOrderId(order)}&back=${window.location.pathname.substr(1)}`);
    };

    renderTitle = () => {
        return (
            <div className="customer-section-title recent-orders-title">
                <FormattedMessage id="recent_orders" />
            </div>
        );
    };

    renderRecentList = () => {
        return <div>{this.getReceintOrders().map((order) => this.renderOrder(order))}</div>;
    };

    renderOrder = (order) => {
        const renderFirstRow = () => {
            //order id
            const orderId = getOrderId(order);
            //shipping method
            const store = getStoreWithGid(this.props.gid, this.props.storesData);
            const type = getOrderTypeText(order, store, this.props.lan);
            //status
            const status = getOrderMainStatusText(order, store).text;
            //time
            const time = _.get(order, "ord_dt");
            const formatttedTime = helper.utcToLocal(time, {
                type: "calendar",
            });
            const timeSection = _.get(_.cloneDeep(formatttedTime).split("@"), 1);
            const dateSection = _.get(_.cloneDeep(formatttedTime).split("@"), 0);
            return (
                <div className="recent-order-first-row">
                    <CopyArea
                        onClick={() => this.pushToOrderDetail(order)}
                        copyData={orderId}
                        messageId="order_id"
                        iconPosition="start"
                        className="recent-order-first-row"
                    >
                        <span className="recent-order-id-and-method ">
                            #{formatOrderId(orderId)} · {type}
                        </span>
                        <span className="recent-order-status">
                            <FormattedMessage id={status} />
                        </span>
                        <div className="recent-order-time">
                            {timeSection}, {dateSection}
                        </div>
                    </CopyArea>
                </div>
            );
        };

        const renderSecondRow = () => {
            const total = helper.formatCurrency(getDisplayTotal(order), this.props.storeCurrency);
            return <div className="recent-order-second-row">{total}</div>;
        };

        const renderThirdRow = () => {
            const total = helper.formatNumber(_.get(order, "num_items"));
            return (
                <div className="recent-order-third-row">
                    <div>
                        <FormattedMessage id={"total_item_count"} values={{ total }} />
                    </div>
                    <div className="link-button" onClick={() => this.pushToOrderDetail(order)}>
                        <FormattedMessage id={"view_detail"} values={{ total }} />{" "}
                    </div>
                </div>
            );
        };
        return (
            <div
                className={cx({
                    "recent-order-item": true,
                    "last-order-border-none": this.getReceintOrders().length < 3,
                })}
            >
                {renderFirstRow()}
                {renderSecondRow()}
                {renderThirdRow()}
            </div>
        );
    };

    seeMoreButton = () => {
        return (
            <div className="see-more-button-row">
                {this.getReceintOrders().length >= 3 ? (
                    <Button
                        size="large"
                        type="link"
                        onClick={() => {
                            const id = this.props.customer?.id;
                            const startDate = moment().subtract(365, "days").format(FILTER_KEYS.date_format);
                            const endDate = moment().format(FILTER_KEYS.date_format);
                            this.props.history.push(
                                `/orders?${FILTER_KEYS.customer_id}=${id}&${FILTER_KEYS.start_date}=${startDate}&${FILTER_KEYS.end_date}=${endDate}`
                            );
                        }}
                    >
                        <FormattedMessage id="see_more_orders" />
                    </Button>
                ) : null}
                <Button
                    size="large"
                    onClick={() => {
                        this.props.setCustomersState({
                            shippingOptionsModal: true,
                        });
                    }}
                    type="primary"
                >
                    <FormattedMessage id="create_order" />
                </Button>
            </div>
        );
    };
    render() {
        return (
            <div className="customer-detail-section  customer-detail-recent-orders">
                {this.renderTitle()}
                {this.getReceintOrders().length ? (
                    <React.Fragment>
                        {this.renderRecentList()}
                        {this.seeMoreButton()}
                    </React.Fragment>
                ) : (
                    <span className="no-orders-found-message">
                        <FormattedMessage id="no_orders_found" />
                    </span>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
    storesData: _.get(state, "managedStores.activeStores", []),
    gid: state.store?.records?.g_id,
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setCustomersState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecentOrders)));
