const StoreAutoAcceptOrder = `
    store_flg,         auto_accept_order_enabled,              false,                82;
    auto_accept_order_setting.order_type,                      order_type,           false;
    auto_accept_order_setting.order_time,                      order_time,           false;
    auto_accept_order_setting.due_time_setting,                due_time_setting,     false;
    auto_accept_order_setting.due_time_value,                  due_time,             false;
    auto_accept_order_setting.order_status,                    order_status,         false;
    auto_accept_order_setting.payment_type,                    payment_type,          false;
`;

export default StoreAutoAcceptOrder;