import React, { Component } from "react";
import _helper from "../../../../util/helper";
import { FormattedMessage, injectIntl } from "react-intl";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import _ from "lodash";
import { connect } from "react-redux";
import _actions from "../../../../slices/live-order";
import { Table } from "antd";
import { getStore } from "../../../order-history/helper-order-list";
import { getStoreTimeZone } from "../../helper";
import "./index.scss";
import moment from "moment";
import Collapse from "@material-ui/core/Collapse";

const transactionTypes: any = {
    authorization: "Authorization",
    capture: "Delayed Capture",
    refund: "Refund",
    sale: "Sale",
};

const transactionDateFormat = "MMM D, YYYY h:mm:ss A";

class OrderTransactions extends Component<any, any> {
    state = {
        expandTransactions: false,
    };

    setExpandTransactions = () => {
        this.setState({
            expandTransactions: !this.state.expandTransactions,
        });
    };

    columns = () => [
        {
            title: this.props.intl.formatMessage({ id: "transaction_id_title" }),
            dataIndex: "txn_id",
            key: "txn_id",
            render: (id: string) => <span className="transaction-id">{id}</span>,
        },
        {
            title: this.props.intl.formatMessage({ id: "transaction_type_title" }),
            dataIndex: "txn_type",
            key: "txn_type",
        },
        {
            title: this.props.intl.formatMessage({ id: "transaction_time_title" }),
            dataIndex: "txn_time",
            key: "txn_time",
        },
    ];

    getTableData = () => {
        if (_.isEmpty(this.props.transactions)) {
            return [];
        }
        const transactions = this.props.transactions.map((trans: any) => ({
            key: trans.txn_id,
            txn_id: trans.txn_id,
            txn_type: this.getTransactionType(trans.txn_type),
            txn_time: this.getTransactionTime(trans.created_at),
        }));
        transactions.sort((transA: any, transB: any) => {
            // sort by descending transaction time
            return moment(transB.txn_time).diff(moment(transA.txn_time));
        });
        return transactions;
    };

    getTransactionType = (type: any) => {
        if (Object.keys(transactionTypes).includes(type)) {
            return transactionTypes[type];
        }
        return type;
    };

    getTransactionTime = (time: any) => {
        const store = getStore(this.props.order, this.props.storesData);
        return _helper.utcToLocal(time, {
            type: transactionDateFormat,
            timeZone: getStoreTimeZone(store),
            time: false,
        });
    };

    renderTransactionShowMoreRow = () => {
        return (
            <div className="transaction-show-more-row" onClick={() => this.setExpandTransactions()}>
                <div className="payment-transactions-title">
                    <FormattedMessage id="payment_transactions" />
                </div>
                <div className="transaction-sum-row">
                    {this.state.expandTransactions ? <DownOutlined /> : <RightOutlined />}
                </div>
            </div>
        );
    };

    renderTransactionsSection = () => {
        return (
            <Collapse in={this.state.expandTransactions}>
                <Table columns={this.columns()} dataSource={this.getTableData()} />
            </Collapse>
        );
    };

    render() {
        return (
            <div className="transactions-wrapper">
                {this.renderTransactionShowMoreRow()}
                {this.renderTransactionsSection()}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: _.get(state, "liveOrders", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
});

const mapDispatchToProps = {
    setState: _actions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderTransactions));
