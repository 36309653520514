import React from 'react';
import { SelectOption } from '../../../../../../components/form';
import { useIntl } from 'react-intl';
import helper from '../../../../../../util/helper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/reducer';
import { renderNumberInput, renderSelectInput } from '../shipping-functions';
import { setC2CProps } from '../../../../../../slices/shipping';

function RuleModal(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const min_day = useSelector((state: RootState) => state.shipping?.C2CProps?.min_day);
    const max_day = useSelector((state: RootState) => state.shipping?.C2CProps?.max_day);
    const country = useSelector((state: RootState) => state.shipping?.C2CProps?.country);
    const price = useSelector((state: RootState) => state.shipping?.C2CProps?.price);

    const countries: SelectOption[] = [
        { value: "US", label: intl.formatMessage({ id: "united_states" }) },
        { value: "CA", label: intl.formatMessage({ id: "canada" }) },
        { value: "UK", label: intl.formatMessage({ id: "united_kingdom" }) },
        { value: "CN", label: intl.formatMessage({ id: "china" }) },
    ]

    const setState = (name: string, value: any) => { dispatch(setC2CProps({ name, value })) }

    return (
        <div className="white-layered-block w100 table-rate-tab">
            <div className="d-flex flex-wrap w100 mt-3">
                {renderSelectInput("country", "country", country, setState, countries)}
                <div className="d-flex w100">
                    {renderNumberInput("min_shipping_days", "min_day", min_day, setState)}
                    {renderNumberInput("max_shipping_days", "max_day", max_day, setState)}
                </div>
                {renderNumberInput("price", "price", price, setState, {
                    formatter: (value: any) => helper.formatMoney(value, intl),
                    parser: (value: any) => helper.parseMoney(value, intl)
                })}
            </div>
        </div>
    )
}

export default RuleModal;