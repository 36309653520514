import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import qs from "querystring";
import { getDeliveryTimeSlotsSuccess, setState } from "../slices/pickup-delivery";
import { toast } from "react-toastify";
import { createMessage } from "../components/message";
import { getIntl } from "../locale";
import { HiInformationCircle } from "react-icons/hi";

const intl = getIntl();

export const getDeliveryZoneTimeSlots =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "DELIVERY_TIME_SLOTS",
                qs.stringify(params)
            )({
                method: "GET",
            });
            switch (response.RC) {
                case 200:
                    dispatch(getDeliveryTimeSlotsSuccess(response?.records ?? []));
                    break;
                case 204:
                    dispatch(getDeliveryTimeSlotsSuccess(response?.records ?? []));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const createUpdateDeliveryZoneTimeSlots =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        const intl = getIntl();

        try {
            const response: any = await oauth("DELIVERY_TIME_SLOTS")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    toast(createMessage(intl.formatMessage({ id: "store_information_saved" }), HiInformationCircle));
                    dispatch(getDeliveryZoneTimeSlots());
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const deleteDeliveryZoneTimeSlot =
    (id: number | any) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "DELIVERY_TIME_SLOTS_DELETE",
                "",
                id
            )({
                method: "DELETE",
            });
            switch (response?.RC) {
                case 200:
                    dispatch(getDeliveryZoneTimeSlots());
                    toast(createMessage(intl.formatMessage({ id: "deleted_successfully" }), HiInformationCircle));
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const appendDeliveryZoneTimeSlots =
    (data: any[]) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            for (let i = 0; i < data.length; i++) {
                await oauth("DELIVERY_TIME_SLOTS")({
                    method: "POST",
                    body: JSON.stringify({ data: data[i] }),
                });
            }

            dispatch(getDeliveryZoneTimeSlots());
            toast(createMessage(intl.formatMessage({ id: "store_information_saved" }), HiInformationCircle));
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };

export const overwriteDeliveryZoneTimeSlots =
    (newData: any[], oldIds: any[]) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setState({ isLoading: true }));
        try {
            for (let i = 0; i < oldIds.length; i++) {
                await oauth(
                    "DELIVERY_TIME_SLOTS_DELETE",
                    "",
                    oldIds[i]
                )({
                    method: "DELETE",
                });
            }

            dispatch(appendDeliveryZoneTimeSlots(newData));
        } catch (e) {
            // Handle error
        }
        dispatch(setState({ isLoading: false }));
    };
