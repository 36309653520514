import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    users: [],
    activeCols: [0, 1, 1, 0, 1, 1, 0, 1],
    editDrawer: false,
    user: {},
    searchValue: "",
    showAccountModal: false,
    activeAccountTab: 0,
    modalType: 0,
    showPassModal: false,
    password: undefined,
    modalTypes: ["account_settings", "edit_account", "add_admin_user", "admin_profile"],
    activePermissionTab: 0,
    storeRoles: [],
    flags: [],
    defaultRoles: [],
    showPhoneModal: false,
    storeCashierPerms: [0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    loading: false,
};

const isString = (str: any) => (str && typeof str === "string") || str instanceof String;

const slice = createSlice({
    name: "adminUsers",
    initialState,
    reducers: {
        setAdminUserState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setShowPassModal: (state, { payload }) => {
            state.showPassModal = payload.value;
        },
        setShowPhoneModal: (state, { payload }) => {
            state.showPhoneModal = payload.value;
        },
        setAccountModalType: (state, { payload }) => {
            state.modalType = payload;
        },
        setUserDetail: (state, { payload }) => {
            const userCopy = _.cloneDeep(state.user);
            _.set(userCopy, payload.name, payload.value);
            state.user = userCopy;
        },
        setPassword: (state, { payload }) => {
            state.password = payload.password;
        },
        setAllFlags: (state, { payload }) => {
            state.flags = Array.isArray(payload.flags) ? _.cloneDeep(payload.flags) : [];
        },
        setFlag: (state, { payload }) => {
            const flagsCpy = _.cloneDeep(current(state.flags));
            flagsCpy[payload.index] = payload.value;
            state.flags = flagsCpy;
        },
        makeAccessFlagsUsefull: (state) => {
            state.flags = [];
            const accessFlagsCpy = _.cloneDeep(state.user?.access_flags);
            if (accessFlagsCpy && isString(accessFlagsCpy)) {
                const tmp = accessFlagsCpy.split(",");
                const flagNums: any = [];
                tmp.forEach((element: string) => {
                    flagNums.push(parseInt(element.charAt(0)));
                });
                state.flags = flagNums;
            } else {
                state.flags = state.storeCashierPerms;
            }
        },
        clearUser: (state) => {
            state.user = {};
            state.flags = [];
        },
        setPermissionTab: (state, { payload }) => {
            state.activePermissionTab = payload;
        },
        setAccountModal: (state, { payload }) => {
            state.showAccountModal = payload;

            if (payload === false) {
                state.selfEdit = false;
            }
        },
        setAccountTab: (state, { payload }) => {
            state.activeAccountTab = payload;
        },
        toggleAccountTab: (state) => {
            state.activeAccountTab = state.activeAccountTab === 1 ? 0 : 1;
        },
        setSearchValue: (state, { payload }) => {
            state.searchValue = payload;
        },
        setCurrentUser: (state, { payload }) => {
            if (payload) {
                state.user = payload;
                const accessFlags = _.isString(_.get(payload, "access_flags")) ? _.get(payload, "access_flags") : "";
                const newFlags = accessFlags.split(",").map((flag: any) => Number(_.get(flag.split(":"), 0)));
                state.flags = newFlags;
            }
        },
        toggleEditDrawer: (state) => {
            state.editDrawer = !_.cloneDeep(state.editDrawer);
        },
        toggleActiveCols: (state, { payload }) => {
            const activeColsCopy = _.cloneDeep(state.activeCols);
            activeColsCopy[payload.index] = activeColsCopy[payload.index] ? 0 : 1;
            state.activeCols = activeColsCopy;
        },
        setAdminState: (state, { payload }) => {
            const adCopy = { ...state.profile };
            _.set(adCopy, payload.name, payload.value);
            state.profile = adCopy;
        },
        getAdminUsersSuccess: (state, { payload }) => {
            state.users = [...payload.users];
        },
        getAdminRolesSuccess: (state, { payload }) => {
            state.storeRoles = payload;
        },
        getDefaultRolesSuccess: (state, { payload }) => {
            state.defaultRoles = payload;
        },
        setAdminStateUser: (state, { payload }) => {
            state.user = payload?.profile;
        },
    },
});

export const {
    setShowPhoneModal,
    setShowPassModal,
    setPassword,
    setFlag,
    setAllFlags,
    clearUser,
    setAdminState,
    setUserDetail,
    setAccountTab,
    toggleAccountTab,
    setAccountModal,
    setPermissionTab,
    setCurrentUser,
    setSearchValue,
    toggleEditDrawer,
    toggleActiveCols,
    setAccountModalType,
    getAdminUsersSuccess,
    getAdminRolesSuccess,
    getDefaultRolesSuccess,
    makeAccessFlagsUsefull,
    setAdminUserState,
    setAdminStateUser,
} = slice.actions;

export const adminUsers = slice.reducer;
