import { Button, DatePicker, Form, Input, Select, Switch } from "antd";
import { RootState } from "app/reducer";
import { getFormItem } from "components/form";
import Wrapper from "components/wrapper";
import config from "config";
import useLanguageSelect from "hooks/useLanguageSelect";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { updateStoreDetails } from "services/store";
import ObjectModel from "util/models";
import StoreReviewBooster from "util/models/store-review-booster";
import { setReviewBooster } from "../../../../slices/store";
import GooglePlaceModal from "./google-place-modal";

enum REVIEW_BOOSTER_TYPE {
    SWTICH,
    INPUT,
    SELECT,
}

interface RENDERPROPS {
    display: any;
    name: string;
    clasName: string;
    type: REVIEW_BOOSTER_TYPE;
    onChange: (key: any, value: boolean | string) => void;
    path: string;
    value: any;
    options: number[] | string[];
    button: boolean;
    placeholder: string;
    defaultValue: string;
    visible: boolean;
    actual: number[];
    useTranslation?: boolean;
}

const REVIEW_DELAY_TIME = [
    "0",
    "5 min",
    "15 min",
    "30 min",
    "45 min",
    "1 h",
    "2 h",
    "3 h",
    "5 h",
    "8 h",
    "16 h",
    "24 h",
];
const REVIEW_DELAY_TIME_INT_VAL = [0, 5, 15, 30, 45, 60, 120, 180, 300, 480, 960, 1440];
const REVIEW_STYLE = ["rate_and_review", "rate_first_review_next"];
const NO_DEPLICATE_REVIEW_SAME_DAY = ["No", "Yes"];
const MESSAGE_LIMIT_PER_MONTH = ["100", "200", "300", "500", "1000", "1500", "2000", "3000", "5000"];

const breadcrumb = {
    routes: [
        {
            path: "",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "/settings",
            breadcrumbName: "settings_overview",
        },
        {
            path: "/settings/reviewBooster",
            breadcrumbName: "review_booster",
        },
    ],
};

function ReviewBoosterSetting(): JSX.Element | null {
    const { lan, getInputLanguageSelect } = useLanguageSelect();
    const is_super_admin = useSelector((state: RootState) => state.user?.roles)?.includes(999);
    const store_review_booster = useSelector((state: RootState) => state.store?.storeReviewBooster);
    const enable_automated_review_sms = store_review_booster?.enable_automated_review_sms;
    const review_booster_style = store_review_booster?.style;
    const social_review_url = store_review_booster?.social_review_url;
    const review_hint = store_review_booster?.review_hint;
    const review_delay_time = store_review_booster?.review_delay_time;
    const no_duplicate_review_same_day = store_review_booster?.no_duplicate_review_same_day;
    const message_limit_per_month = store_review_booster?.message_limit_per_month;
    const reward_coupon_code = store_review_booster?.reward_coupon_code;
    const coupon_hint_message = store_review_booster?.coupon_hint_message;
    const coupon_expire_dt = store_review_booster?.coupon_expire_dt;
    const [showGoogleModal, setShowGoogleModal] = useState(false);
    const dispatch = useDispatch();
    const intl = useIntl();
    const saveChanges = () => {
        const surchargeClone = _.cloneDeep(store_review_booster);
        dispatch(updateStoreDetails(new ObjectModel(StoreReviewBooster).convertToPostData(surchargeClone)));
    };

    const renderSwitch = (props: RENDERPROPS) => (
        <Switch
            checked={props.value}
            onChange={(isChecked) => props.onChange(props.path, isChecked)}
            disabled={!is_super_admin}
        />
    );

    const renderSelect = (props: RENDERPROPS) => (
        <Select
            value={props.value}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            style={{ width: 300 }}
            onChange={(value) => props.onChange(props.path, value)}
            disabled={!is_super_admin}
        >
            {props.options?.map((option: any, index: number) => {
                return (
                    <Select.Option
                        key={option}
                        value={props.path === "review_delay_time" ? props.actual[index] : option}
                    >
                        {props.path === "review_delay_time" || props.path === "message_limit_per_month"
                            ? option
                            : intl.formatMessage({ id: props.display[index] ?? " " })}
                    </Select.Option>
                );
            })}
        </Select>
    );

    const textInput = (props: RENDERPROPS, l?: string) => {
        const _PATH = l ? `${props.path}.${l}` : props.path;
        const muti = _.isEqual(l, lan);
        return (
            <Input.Group>
                {(muti || _.isEmpty(l)) && (
                    <Input
                        value={l ? props?.value[l] : props?.value}
                        placeholder={props?.placeholder ?? ""}
                        onChange={(e) => {
                            props.onChange(_PATH, e.target.value);
                        }}
                        onFocus={() => !props?.value && props?.onChange(_PATH, props?.placeholder)}
                        style={{
                            width: 420,
                            marginBottom: "10px",
                            borderRadius: "unset",
                            display: "block",
                        }}
                        disabled={!is_super_admin}
                        key={`input_field_${l}`}
                    ></Input>
                )}
                {props.button && (
                    <div>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                setShowGoogleModal(true);
                            }}
                            disabled={!is_super_admin}
                        >
                            <FormattedMessage id="generate_google_review" />
                        </Button>
                        <Button
                            type="primary"
                            disabled={social_review_url?.length < 5 || !_isUrl(social_review_url) || !is_super_admin}
                            onClick={() =>
                                !social_review_url.includes("https")
                                    ? window.open("https://" + social_review_url)
                                    : window.open(social_review_url)
                            }
                        >
                            <FormattedMessage id="validate" />
                        </Button>
                    </div>
                )}
            </Input.Group>
        );
    };

    const renderInput = (props: RENDERPROPS) => {
        if (props?.path === "review_hint") {
            return getInputLanguageSelect(
                config?.LANGUAGES?.map((l) => (
                    <div
                        key={`review_hint_${l}`}
                        style={{ marginBottom: -40, marginTop: -10, display: l !== lan ? "none" : "block" }}
                    >
                        {getFormItem("‎", textInput(props, l))}
                    </div>
                ))
            );
        } else if (props?.path === "coupon_hint_message") {
            return getInputLanguageSelect(
                config?.LANGUAGES?.map((l) => (
                    <div
                        key={`coupon_hint_message_${l}`}
                        style={{ marginBottom: -40, marginTop: -10, display: l !== lan ? "none" : "block" }}
                    >
                        {getFormItem("‎", textInput(props, l))}
                    </div>
                ))
            );
        } else {
            return textInput(props);
        }
    };

    const renderInputContent = (props: any) => {
        switch (props.type) {
            case REVIEW_BOOSTER_TYPE.SWTICH:
                return renderSwitch(props);
            case REVIEW_BOOSTER_TYPE.SELECT:
                return renderSelect(props);
            case REVIEW_BOOSTER_TYPE.INPUT:
                return renderInput(props);
        }
    };

    const handleValueChange = (name: string, value: string | boolean) => {
        dispatch(setReviewBooster({ name, value }));
    };

    const _isUrl = (URL: string): boolean => {
        const regex = new RegExp(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
        );
        return regex.test(URL);
    };

    const REWVIEW_BOOSTER_LAYOUT = [
        {
            name: "enable_automated_review_sms",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.SWTICH,
                onChange: handleValueChange,
                path: "enable_automated_review_sms",
                value: enable_automated_review_sms,
            }),
            visible: true,
        },
        {
            name: "sms_review_reminder_style",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.SELECT,
                onChange: handleValueChange,
                options: [1, 2],
                path: "style",
                value: review_booster_style,
                display: REVIEW_STYLE,
                defaultValue: intl.formatMessage({ id: "rate_and_review" }),
            }),
            visible: true,
        },
        {
            name: "social_review_url",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.INPUT,
                onChange: handleValueChange,
                path: "social_review_url",
                value: social_review_url,
                button: true,
            }),
            visible: true,
        },
        {
            name: "review_hint",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.INPUT,
                onChange: handleValueChange,
                path: "review_hint",
                placeholder: intl.formatMessage({ id: "tell_us_about_our_service" }),
                value: review_hint,
            }),
            visible: true,
        },
        {
            name: "review_delay_time",
            className: "white-layered-block w50",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.SELECT,
                onChange: handleValueChange,
                options: REVIEW_DELAY_TIME,
                path: "review_delay_time",
                value: review_delay_time,
                defaultValue: "5min",
                placeholder: intl.formatMessage({ id: "click_to_select" }),
                actual: REVIEW_DELAY_TIME_INT_VAL,
            }),
            visible: true,
        },
        {
            name: "no_duplicate_review_same_day",
            className: "white-layered-block w50",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.SWTICH,
                onChange: handleValueChange,
                options: [0, 1],
                path: "no_duplicate_review_same_day",
                value: no_duplicate_review_same_day,
                display: NO_DEPLICATE_REVIEW_SAME_DAY,
            }),
            visible: true,
        },
        {
            name: "message_limit_per_month",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.SELECT,
                onChange: handleValueChange,
                options: MESSAGE_LIMIT_PER_MONTH,
                path: "message_limit_per_month",
                defaultValue: "300",
                value: message_limit_per_month,
                placeholder: "300",
            }),
            visible: is_super_admin,
        },
        {
            name: "reward_coupon_code",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.INPUT,
                onChange: handleValueChange,
                path: "reward_coupon_code",
                value: reward_coupon_code,
                placeholder: intl.formatMessage({ id: "reward_coupon_code" }),
                button: false,
            }),
            visible: true,
        },
        {
            name: "coupon_hint_message",
            className: "white-layered-block w100",
            content: renderInputContent({
                type: REVIEW_BOOSTER_TYPE.INPUT,
                onChange: handleValueChange,
                path: "coupon_hint_message",
                value: coupon_hint_message,
                placeholder: intl.formatMessage({ id: "coupon_hint_message" }),
                button: false,
                useTranslation: true,
            }),
            visible: true,
        },
        {
            name: "coupon_expire_dt",
            className: "white-layered-block w100",
            content: (
                <Form.Item style={{ marginBottom: "auto" }}>
                    <DatePicker
                        showTime
                        onChange={(date: any) => handleValueChange("coupon_expire_dt", date)}
                        placeholder={intl.formatMessage({ id: "select_date" })}
                        showSecond={false}
                        format={"YYYY-MM-DD"}
                        value={moment(coupon_expire_dt)}
                        allowClear
                    />
                </Form.Item>
            ),
            visible: true,
        },
    ];

    const renderSave = () => (
        <div className="setting-actions" style={{ marginRight: "10px" }}>
            <Button type="primary" size="large" onClick={() => saveChanges()} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    return (
        <>
            <GooglePlaceModal
                showGoogleModal={showGoogleModal}
                setShowGoogleModal={(val: boolean) => setShowGoogleModal(val)}
                onChange={(e) => {
                    handleValueChange("social_review_url", e);
                }}
            />
            <Wrapper helmet={{ title: "review_booster" }} breadcrumb={breadcrumb} paddingBottom={true}>
                {!_.isEmpty(store_review_booster) ? (
                    <>
                        <div className="d-flex flex-wrap">
                            {REWVIEW_BOOSTER_LAYOUT.map((item) => {
                                return (
                                    item.visible && (
                                        <div key={item.name} className={item.className}>
                                            <div className="d-flex justify-content-start">
                                                <div
                                                    className="option-title"
                                                    style={{
                                                        marginBottom: "10px",
                                                        marginTop:
                                                            item.name == "review_hint" ||
                                                            item.name == "coupon_hint_message"
                                                                ? "20px"
                                                                : "0",
                                                    }}
                                                >
                                                    <FormattedMessage id={item.name} />
                                                </div>
                                                <span>{item.content}</span>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                        {renderSave()}
                    </>
                ) : (
                    <></>
                )}
            </Wrapper>
        </>
    );
}

export default ReviewBoosterSetting;
