import { Button, Input, Modal, Switch } from "antd";
import config from "config";
import _ from "lodash";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import orderHistoryActions from "slices/order-history";
import InputNumber from "../../../../components/number-input";
import { ORDER_DETAIL_ACTIONS } from "../../helper";
import "./_order-refund-dialog.scss";

const currencySymbols = config.CURRENCY_SYMBOL;

class OrderRefundDialog extends Component {
    static defaultProps = {
        title: " ",
        allowRequestClose: true,
        isRefundAllClicked: false,
        buttons: [
            {
                label: "Close",
                type: "default",
                onClick: () => {},
            },
        ],
    };

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            refundAmount: 0,
            comment: "",
        };
    }

    str = (values) => this.props.intl.formatMessage(values);

    getCurrencySymbol = () => {
        return currencySymbols[this.props.storeCurrency];
    };

    hasPointsOrGiftCard = () => {
        return this.props.rewardPointsAmount || this.props.giftCardAmount;
    };

    getMaxRefundAmount = (isRefundAllClicked) => {
        // if refund all is clicked, return the total paid amount including reward points and gift card
        return isRefundAllClicked
            ? (
                  this.props.amountPaid +
                  this.props.rewardPointsAmount +
                  this.props.giftCardAmount -
                  this.props.totalRefunded
              ).toFixed(2)
            : (this.props.amountPaid - this.props.totalRefunded).toFixed(2);
    };

    handleRefundAmtChange = (value) => {
        this.setState({ refundAmount: value });
    };

    refundAllClicked = (checked) => {
        this.setState({
            isRefundAllClicked: checked,
            refundAmount: checked ? this.getMaxRefundAmount(checked) : 0,
        });
    };

    handleCommentChange = (e) => {
        this.setState({ comment: e.target.value });
    };

    handleConfirmClick = () => {
        let data = {};
        if (this.state.refundAmount === 0) return;
        if (this.state.refundAmount !== 0) data["amt_refund"] = this.state.refundAmount;
        if (this.state.comment.length !== 0) data["comment"] = this.state.comment;
        if (Object.keys(data).length !== 0) {
            data["order_id"] = this.props.orderId;
            data["action"] = ORDER_DETAIL_ACTIONS.refund;
            this.props.setOrderHistoryState({ allowRefundSubmit: false });
            // Call the submit function and handle the success scenario
            this.props
                .onSubmitClick(data)
                .then(() => {
                    // Submission successful, reset refund amount
                    this.setState({ refundAmount: 0, comment: "", isRefundAllClicked: false });
                    this.props.setOrderHistoryState({ allowRefundSubmit: true });
                })
                .catch((error) => {
                    // Handle any submission error here
                    console.error("Refund submission failed", error);
                    this.props.setOrderHistoryState({ allowRefundSubmit: true });
                });
        }
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
        this.setState({ refundAmount: 0, comment: "", isRefundAllClicked: false });
    };

    renderRewardPointsRedeemedColumn = () => {
        // reward points amount is actuall money amount ex) $5
        // reward points number is the number of points ex) 500, $5 = 500 points
        return (
            <div>
                <div className="item header-text">{this.str({ id: "reward_points_redeemed" })}</div>
                <div className="item">{`${this.getCurrencySymbol()}${this.props.rewardPointsAmount} · ${
                    this.props.rewardPointsNumber
                } points`}</div>
            </div>
        );
    };

    renderGiftCardRedeemedColumn = () => {
        return (
            <div>
                <div className="item header-text">{this.str({ id: "gift_card_redeemed" })}</div>
                <div className="item">{`${this.getCurrencySymbol()}${this.props.giftCardAmount}`}</div>
            </div>
        );
    };

    renderAmountPaidTotalRefunded = () => {
        let { amountPaid, totalRefunded } = this.props;
        return (
            <div className="column-wrapper order-refund-dialog-row">
                <div>
                    <div className="item header-text">{this.str({ id: "amount_paid" })}</div>
                    <div className="item">{`${this.getCurrencySymbol()}${amountPaid}`}</div>
                </div>
                {this.props.rewardPointsAmount ? this.renderRewardPointsRedeemedColumn() : null}
                {this.props.giftCardAmount ? this.renderGiftCardRedeemedColumn() : null}
                <div>
                    <div className="item header-text">{this.str({ id: "total_refunded" })}</div>
                    <div className="item"> {`${this.getCurrencySymbol()}${totalRefunded}`} </div>
                </div>
                {!this.hasPointsOrGiftCard() ? <div /> : null}
            </div>
        );
    };

    renderRefundActionRow = () => {
        return (
            <div className="column-wrapper order-refund-dialog-row">
                <div className="order-refund-refund-amount">
                    <div className="item header-text">{this.str({ id: "refund_amount" })}</div>
                    <div style={{ display: "flex" }} className="item">
                        <InputNumber
                            style={{ width: 360 }}
                            type="number"
                            prefix={currencySymbols[this.props.storeCurrency]}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            precision={2}
                            value={this.state.refundAmount}
                            min={0}
                            max={this.getMaxRefundAmount(this.state.isRefundAllClicked)}
                            onChange={this.handleRefundAmtChange}
                            readOnly={this.state.isRefundAllClicked}
                        />
                    </div>
                </div>
                <div>
                    <div className="item header-text ">{this.str({ id: "refund_all" })}</div>
                    <Switch
                        className="order-refund-refund-all-switch"
                        checked={this.state.isRefundAllClicked}
                        onClick={this.refundAllClicked}
                    />
                </div>
            </div>
        );
    };

    renderRemarkRow = () => {
        return (
            <div className="order-refund-dialog-row">
                <div className="item header-text">{this.str({ id: "refund_remark" })}</div>
                <div className="item">
                    <Input
                        value={this.state.comment}
                        placeholder={this.str({ id: "refund_remark" })}
                        onChange={(e) => this.handleCommentChange(e)}
                    />
                </div>
            </div>
        );
    };

    renderPartialRefundLimitDescription = () => {
        return <div>{this.str({ id: "partial_refund_limit_description" })}</div>;
    };

    renderFooter = () => {
        return (
            <>
                <Button
                    onClick={() => {
                        this.handleCancel();
                    }}
                >
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    disabled={!this.props.allowRefundSubmit}
                    onClick={() => {
                        this.handleConfirmClick();
                    }}
                >
                    <FormattedMessage id="submit" />
                </Button>
            </>
        );
    };

    render() {
        return (
            <div>
                <Modal
                    visible={this.props.show}
                    title={this.str({ id: "refund" })}
                    onCancel={this.handleCancel}
                    footer={this.renderFooter()}
                >
                    <div className="dialog-wrapper">
                        {this.renderAmountPaidTotalRefunded()}
                        {this.renderRefundActionRow()}
                        {this.hasPointsOrGiftCard() ? this.renderPartialRefundLimitDescription() : null}
                        {this.renderRemarkRow()}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
    allowRefundSubmit: _.get(state, "orders-page.allowRefundSubmit", false),
});

const mapDispatchToProps = {
    setOrderHistoryState: orderHistoryActions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderRefundDialog));
