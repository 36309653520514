import { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { updateDisplayPrice } from "../../../../slices/create-order";
import _ from "lodash";

//a renderless component to assist with updating price detail
class PriceAssistant extends Component<any, any> {
    componentDidMount = () => {
        this.props.updateDisplayPrice();
    };

    componentDidUpdate = (prevsProps: any) => {
        this.updateDisplayPrice(prevsProps);
    };

    shouldComponentUpdate = () => {
        // if quote id exists, update display price
        if (_.isEmpty(this.props.items) && this.props.state.qid) {
            return true;
        }
        return !_.isEmpty(this.props.items);
    };

    updateDisplayPrice = (prevsProps: any) => {
        const customerIsDiff = !_.isEqual(this.props.customer, prevsProps.customer);
        const paymentIsDiff = !_.isEqual(this.props.payment, prevsProps.payment);
        const giftCardIsDiff = !_.isEqual(this.props.selectedGiftCard, prevsProps.selectedGiftCard);
        const selectedShippingMehtodIsDfif = !_.isEqual(
            this.props.selectedShippingType,
            prevsProps.selectedShippingType
        );
        const pointUsedIsDiff = !_.isEqual(this.props.pointsUsed, prevsProps.pointsUsed);
        if (paymentIsDiff || giftCardIsDiff || selectedShippingMehtodIsDfif || pointUsedIsDiff || customerIsDiff) {
            const updateReason = paymentIsDiff
                ? "paymentIsDiff"
                : giftCardIsDiff
                ? "giftCardIsDiff"
                : selectedShippingMehtodIsDfif
                ? "selectedShippingMehtodIsDfif"
                : pointUsedIsDiff
                ? "pointUsedIsDiff"
                : "";
            this.props.updateDisplayPrice({ updateReason });
        }
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state: any) => ({
    state: _.get(state, "createOrder", {}),
    selectedShippingType: _.get(state, "createOrder.selectedShippingType", []),
    pointsUsed: _.get(state, "createOrder.pointsUsed", {}),
    payment: _.get(state, "createOrder.payment", ""),
    selectedGiftCard: _.get(state, "createOrder.selectedGiftCard", ""),
    customer: _.get(state, "customers.customer", []),
    items: _.get(state, "createOrder.items", []),
});

const mapDispatchToProps = {
    updateDisplayPrice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PriceAssistant)));
