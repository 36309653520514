import { Pagination, PaginationProps } from "antd";
import _ from "lodash";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../app/reducer";
import { setPaginations } from "../../../slices/delivery_request";
import { AnyStyle } from "../constants";
import "../style.scss";

export interface Props {
    style?: AnyStyle;
    extraContent?: React.ReactNode;
}

export interface TasksPaginationProps extends StateFromRedux, Props {}

const TasksPagination: React.FC<TasksPaginationProps> = ({
    paginations,
    setPaginations,
    style,
    extraContent,
    onActiveTask,
}) => {
    const paginationProps: PaginationProps = {
        total: paginations.total,
        current: paginations.currentPage,
        pageSize: paginations.limit,
        pageSizeOptions: paginations.pageLimitOptions,
        onChange(page, pageSize) {
            setPaginations({ limit: pageSize, currentPage: page });
        },
    };

    return (
        <div className="delivery-request-pagination-container" style={style}>
            {extraContent}
            <Pagination size={_.isEmpty(onActiveTask) ? "default" : "small"} {...paginationProps} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        paginations: state?.deliveryRequests?.paginations,
        onActiveTask: state.deliveryRequests.onActiveTask,
    };
};

const mapDispatchToProps = {
    setPaginations,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type StateFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(TasksPagination);
