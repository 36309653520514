import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { api } from "../../../util/api";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SelectOption } from "../../../components/form";
import {
    renderHalfTxtSelecPhone,
    renderHalfTxtInput,
} from "../../admin-users/_components/account-modal/account-sections";
import { getIntl } from "../../../locale";
import { toast } from "react-toastify";
import { createMessage } from "../../../components/message";
import { HiInformationCircle } from "react-icons/hi";
import "../forgot.scss";
import { action } from "../constants";

function App(): JSX.Element {
    const intl = useIntl();
    const [countryCode, setCountryCode] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [verificationCode, setVerificationCode] = useState<string>("");
    const history = useHistory();

    const phoneCodes: SelectOption[] = [
        { value: "canada_phone", label: intl.formatMessage({ id: "canada_phone" }) },
        { value: "usa_phone", label: intl.formatMessage({ id: "usa_phone" }) },
    ];

    function phoneVerification(verificationCode: any, onRequestSuccess: () => void) {
        const requestBody: any = {
            phone: {
                phone_number: phoneNumber,
                area_code: countryCode,
                country_code: "CA",
            },
        };
        if (verificationCode === undefined || verificationCode === null || String(verificationCode) === "") {
            requestBody.action = action.FORGOT_PASSWORD;
        } else {
            requestBody.vc = verificationCode;
            requestBody.action = action.VERIFY_VC;
        }

        api("PHONE_VERIFICATION")({
            method: "POST",
            body: JSON.stringify(requestBody),
        }).then((response) => {
            const RC: any = response?.RC;
            const intl = getIntl();
            if (RC === 200) {
                onRequestSuccess();
            } else {
                toast(createMessage(intl.formatMessage({ id: "an_error_occurred" }), HiInformationCircle));
            }
        });
    }

    function sendVerificationCode() {
        if (verificationCode === undefined || verificationCode === null || String(verificationCode) === "") {
            phoneVerification(undefined, () => {
                toast(createMessage(intl.formatMessage({ id: "verification_code_sent" }), HiInformationCircle));
            });
        } else {
            phoneVerification(verificationCode, () => {
                history.push("/forgot/new-password?phone=" + phoneNumber + "&code=" + verificationCode);
            });
        }
    }

    function resetByEmail() {
        api("RESET_PASSWORD")({
            method: "POST",
            body: JSON.stringify({
                email: email,
            }),
        }).then((response) => {
            const RC: any = response?.RC;
            const intl = getIntl();
            if (RC === 200) {
                toast(createMessage(intl.formatMessage({ id: "verification_email_sent" }), HiInformationCircle));
            }
        });
    }

    const resetPasswordByPhoneContainer = () => (
        <div className="white-single-block codes-container flex-column">
            {/* @ts-ignore */}
            {renderHalfTxtSelecPhone("country_code", "phone.area_code", countryCode, phoneCodes, (name, value) =>
                setCountryCode(value)
            )}
            {/* @ts-ignore */}
            {renderHalfTxtInput(
                "phone",
                "phone.phone_number",
                phoneNumber,
                (name: string, value: string) => setPhoneNumber(value),
                true,
                "tel",
                false
            )}
            <div className="verificationPhone d-flex justify-content-start align-items-start">
                <input
                    type="text"
                    placeholder={intl.formatMessage({ id: "enter_verification_code" })}
                    className="verification-phone-input"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <div className="verification-phone-button" onClick={() => sendVerificationCode()}>
                    {verificationCode ? (
                        <FormattedMessage id="submit_verification_code" />
                    ) : (
                        <FormattedMessage id="send_verification_code" />
                    )}
                </div>
            </div>
        </div>
    );

    const resetPasswordByEmailContainer = () => (
        <div className="white-single-block forgot-password-reset-container codes-container flex-column">
            {/* @ts-ignore */}
            {renderHalfTxtInput(
                "email",
                "email",
                email,
                (name: string, value: string) => setEmail(value),
                false,
                "",
                false,
                undefined,
                "100%"
            )}
            <Button
                type="primary"
                htmlType="submit"
                className="save-button-handler w-100"
                style={{ height: "42px" }}
                onClick={() => resetByEmail()}
            >
                <FormattedMessage id="submit" />
            </Button>
        </div>
    );

    return (
        <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane tab={intl.formatMessage({ id: "by_phone" })} key="1">
                {resetPasswordByPhoneContainer()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage({ id: "by_email" })} key="2">
                {resetPasswordByEmailContainer()}
            </Tabs.TabPane>
        </Tabs>
    );
}

export default App;
