import React, { useState } from "react";
import { Radio, Modal } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { setSelfServeState } from "../../../../slices/store";
import { RootState } from "../../../../app/reducer";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import globalHelper, { isBool } from "../../../../util/helper";
import Format1 from "./qr-formats/format1";
import Format10 from "./qr-formats/format10";
import { FORMATS } from "./qr-formats";
import { CUSTOMFORMATS } from "./qr-formats";
import { BlobProvider } from "@react-pdf/renderer";
import "./serve-print.scss";

function FormatModal(props: Record<string, any>): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const selfServe = useSelector((state: RootState) => state.store?.storeSelfServe);
    const storeURL = useSelector((state: RootState) => state.store.records?.general_info?.url);
    const phone = useSelector((state: RootState) => state.store?.storeInformation?.phone);
    const storeLogo = useSelector((state: RootState) => state.store.records["logo-png"]);

    const setCustomText = useSelector((state: RootState) => state.store?.setCustomText);

    const customText1 = useSelector((state: RootState) => state.store?.customText1);
    const customText2 = useSelector((state: RootState) => state.store?.customText2);

    const showDownloadModal = _.get(selfServe, "showDownloadModal", false);
    const [selectedFormat, setSelectedFormat] = useState("format1");
    const g_id = _.get(selfServe, "store_id", "");
    const { lan } = useLanguageSelect();
    const startNum = _.get(props, "startNum", "1");
    const statNum = _.get(props, "statNum", 4);
    const label = _.get(props, "label");
    const exclude = _.get(props, "exclude");
    const bgColor = _.get(props, "bgColor");
    const paperSize = _.get(props, "paperSize");
    const showRewards = _.get(props, "showRewards");
    const addMargin = _.get(props, "addMargin");
    const instructions = _.get(props, "instructions");
    const dataURLS = _.get(props, "dataURLS", []);
    const qrStyle = _.get(props, "qrStyle", []);
    const codeType = _.get(props, "codeType", []);
    const bleedingMargin = _.get(props, "bleedingMargin", []);

    const setState = (name: string, value: any) => dispatch(setSelfServeState({ name, value }));

    const renderDownloadPDFButton = () => {
        const format = FORMATS.find((format) => selectedFormat === format.value);
        const customformat = CUSTOMFORMATS.find((customformat) => selectedFormat === customformat.value);
        const props = {
            g_id,
            label,
            exclude,
            statNum,
            phone,
            storeURL,
            startNum,
            storeName: _.get(selfServe, `store_name_${lan}`, ""),
            showTable: isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num),
            showInstructions: instructions,
            dataURLS,
            dark: format?.dark ?? false,
            customdark: customformat?.customdark ?? false,
            storeLogo: globalHelper.getImage(storeLogo),
            bgColor,
            paperSize,
            showRewards,
            addMargin,
            bleedingMargin,
            qrStyle,
            customText1,
            customText2,
        };
        const getDocument = () => {
            if (!setCustomText) {
                const Cmp = format ? format.component : Format1;
                //@ts-ignore
                return <Cmp {...props} />;
            } else {
                const Cmp = customformat ? customformat.component : Format10;
                //@ts-ignore
                return <Cmp {...props} />;
            }
        };
        const resetOnDownload = () => {
            setState("showDownloadModal", false);
            setTimeout(() => window.location.reload(), 1500);
        };
        return (
            <div
                className="d-flex justify-content-center align-items-center mt-4 100"
                onClick={() => resetOnDownload()}
            >
                <BlobProvider document={getDocument()}>
                    {({ url }) => (
                        <a
                            href={url != null ? url : ""}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                padding: "10px",
                                backgroundColor: "#3898c8",
                                borderRadius: "4px",
                            }}
                        >
                            <span style={{ color: "white" }}>{intl.formatMessage({ id: "download_pdf" })}</span>
                        </a>
                    )}
                </BlobProvider>
            </div>
        );
    };

    // rendered as a part of renderFormatOptions, Renders each individual
    const renderFormatOption = (value: string, format: any, info: Record<string, unknown>, key: number) => {
        const props = {
            g_id,
            label,
            exclude,
            statNum,
            phone,
            storeURL,
            startNum,
            storeName: _.get(selfServe, `store_name_${lan}`, ""),
            showTable: isBool(selfServe.allow_eatin) && isBool(selfServe.require_table_num),
            showInstructions: instructions,
            dataURLS,
            dark: info?.dark,
            customdark: info?.customdark,
            logoImage: globalHelper.getImage(storeLogo),
            bgColor,
            showRewards,
            qrStyle,
            codeType,
            customText1,
            customText2,
        };
        return (
            <div className="formatOption-container d-flex mb-4" onClick={() => setSelectedFormat(value)} key={key}>
                <Radio className="format-option-radio" checked={selectedFormat === value} />
                {format(props)}
            </div>
        );
    };

    const optionFilter = () => {
        return true;
    };

    const customOptionFilter = () => {
        return true;
    };

    // renders the QR Format modal (no custom text)
    const renderFormatOptions = () => {
        return !setCustomText
            ? FORMATS.filter(() => optionFilter()).map((format, key) =>
                  renderFormatOption(format.value, format.renderer, format, key)
              )
            : null;
    };

    // renders the QR Format modal (with custom text)
    const renderFormatCustomOptions = () => {
        return setCustomText
            ? CUSTOMFORMATS.filter(() => customOptionFilter()).map((customformat, key) =>
                  renderFormatOption(customformat.value, customformat.renderer, customformat, key)
              )
            : null;
    };

    const renderFormatSection = () => (
        <div className="format-modal">
            <div className="format-options-container d-flex">{renderFormatOptions()}</div>
        </div>
    );

    const renderFormatCustomSection = () => (
        <div className="format-modal">
            <div className="format-options-container d-flex">{renderFormatCustomOptions()}</div>
        </div>
    );

    const renderPrintModal = (
        <Modal
            visible={showDownloadModal}
            width="200px"
            footer={null}
            onCancel={() => setState("showDownloadModal", false)}
        >
            {showDownloadModal ? renderDownloadPDFButton() : null}
        </Modal>
    );

    return (
        <React.Fragment>
            {renderFormatSection()}
            {renderFormatCustomSection()}
            {renderPrintModal}
        </React.Fragment>
    );
}

export default FormatModal;
