import { switchNum } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setEMTState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput } from "./payment-functions";
import { renderToolTipInline } from "components/form";

const EMT_ACCOUNT_MAX_LEN = 100;

const EMTPaymentSettings = (): JSX.Element => {
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setEMTState({ [name]: value }));
    const switchChange = (name: string, value: any) => dispatch(setEMTState({ [name]: switchNum(value) }));
    const { emtAccount, emtEnabled, emtPassword } = useSelector((state: RootState) => {
        return {
            emtAccount: state.store?.emt_setting?.emt_account,
            emtEnabled: state.store?.emt_setting?.is_enabled,
            emtPassword: state.store?.emt_setting?.emt_password,
        };
    });

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop("emt", "emt_desc", "is_enabled", emtEnabled, switchChange)}
            {emtEnabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput(
                        "emt_account",
                        undefined,
                        emtAccount,
                        setState,
                        false,
                        true,
                        EMT_ACCOUNT_MAX_LEN
                    )}
                    {renderHalfTxtInput(
                        "emt_password",
                        undefined,
                        emtPassword,
                        setState,
                        false,
                        false,
                        undefined,
                        renderToolTipInline("emt_password_hint")
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default EMTPaymentSettings;
