import React from "react";
import { View, Text } from "@react-pdf/renderer";

const customTextBox = (
    styles: React.CSSProperties | any,
    props: { customOrderLabel: string, customBottomLabel: string }
): JSX.Element => {
    return (
        <View style={styles.customTextCont}>
            <View style={styles.CustomtxtHolder}>
                <Text style={[styles.CustomLabelBox, styles.title]}>{props.customOrderLabel}</Text>
                <Text style={styles.CustomtxtBox}>{props.customBottomLabel}</Text>
            </View>
        </View>
    );
};
export default customTextBox;
