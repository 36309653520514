import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import "./gift-card-section.scss";
import InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import GiftCard from "./gift-cards-list-item";

class GiftCardListItem extends React.Component {
    getGiftCards = () => {
        return Array.isArray(this.props.giftCards) ? this.props.giftCards : [];
    };

    getGiftCardsCount = (props = this.props) => {
        return _.get(props, "accountTotal", 0);
    };

    getLoader = () => {
        return (
            <div className="gift-card-picker-loader-container">
                <CircularProgress size={24} color="secondary" />
            </div>
        );
    };

    getListItems = () => {
        return (
            <List>
                {this.getGiftCards().map((card, index) => (
                    <GiftCard key={`giftcard-${card?.code}-${index}`} card={card} />
                ))}
            </List>
        );
    };

    getListLoader = () => {
        return <div className="gift-card-picker-dialog-loader-container">{this.getLoader()}</div>;
    };

    getCardLists = () => {
        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={() => {}}
                hasMore={false}
                loader={this.getLoader()}
                useWindow={false}
            >
                {this.getListItems()}
            </InfiniteScroll>
        );
    };

    render() {
        return (
            <div className="gift-card-picker-list-container">
                {_.isEmpty(this.getGiftCards()) ? this.getListLoader() : this.getCardLists()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: state.setting.lan,
    giftCardsCount: _.get(state, "createOrder.payment.giftCardsCount", 0),
    giftCards: _.get(state, "giftcards.accounts", []),
    cid: _.get(state, "customers.customer.id", ""),
    accountTotal: _.get(state, "giftcards.accountTotal", []),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GiftCardListItem));
