import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    timeSettings: [],
    showEditModal: 0,
    editCodeIndex: 0,
    addingCode: 0,
};

const defaultDays = ["mo", "tu", "we", "th", "fr", "sa", "su"];
const defaultHours = { open: "11:00", close: "21:00" };

const removeDayHours = (newTime: any, day: any) => {
    for (let i = 0; i < newTime.length; i++) {
        let daysLength = newTime[i].days?.length;
        for (let x = 0; x < daysLength; x++) {
            if (String(newTime[i].days[x]) === String(day)) {
                Number(newTime[i].days.length) === 1 ? newTime.splice(i, 1) : newTime[i].days.splice(x, 1);
                daysLength--;
            }
        }
    }
};

const hoursAlreadyExist = (newTime: any, freshHours: any, day: any) => {
    let alreadyExists = false;
    for (let i = 0; i < newTime.length; i++) {
        if (Number(newTime[i].hours.length) === Number(freshHours.hours.length)) {
            for (let y = 0; y < Number(newTime[i].hours.length); y++) {
                if (
                    String(newTime[i].hours[y].open) === String(freshHours.hours[y].open) &&
                    String(newTime[i].hours[y].close) === String(freshHours.hours[y].close)
                ) {
                    newTime[i].days.push(day);
                    alreadyExists = true;
                }
            }
        }
    }
    if (!alreadyExists) {
        newTime.push(freshHours);
    }
};

const slice = createSlice({
    name: "storeAvailability",
    initialState,
    reducers: {
        setEditIndex: (state, { payload }) => {
            state.editCodeIndex = payload;
        },
        setShowEditModal: (state, { payload }) => {
            state.showEditModal = payload;
        },
        setAddingCode: (state, { payload }) => {
            state.addingCode = payload;
        },
        cancelAddCode: (state) => {
            if (Number(state.addingCode) === 1) {
                const newTimeSettings = _.cloneDeep(current(state.timeSettings));
                newTimeSettings.pop();
                state.timeSettings = newTimeSettings;
                state.editCodeIndex = 0;
                state.addingCode = 0;
            }
        },
        addNewCode: (state) => {
            const newTimeSettings = _.cloneDeep(current(state.timeSettings));
            newTimeSettings.push({
                code: "",
                name: "",
                available_time: [{ days: defaultDays, hours: [defaultHours] }],
            });
            state.addingCode = 1;
            state.editCodeIndex = newTimeSettings.length - 1;
            state.timeSettings = newTimeSettings;
            state.showEditModal = 1;
        },
        setTime: (state, { payload }) => {
            const { day, code, hourSet } = payload;
            const newTime = _.cloneDeep(current(state.timeSettings[code].available_time));
            const freshHours = { days: [day], hours: [..._.cloneDeep(hourSet)] };
            removeDayHours(newTime, day);
            hoursAlreadyExist(newTime, freshHours, day);
            state.timeSettings[payload.code].available_time = newTime;
        },
        toggleOpenHours: (state, { payload }) => {
            const { day, code } = payload;
            const newTime = _.cloneDeep(current(state.timeSettings[code].available_time));
            const freshHours = { days: [day], hours: [defaultHours] };
            payload.value ? hoursAlreadyExist(newTime, freshHours, day) : removeDayHours(newTime, day);
            state.timeSettings[payload.code].available_time = newTime;
        },
        addHours: (state, { payload }) => {
            const { day, code, hours } = payload;
            const newTime = _.cloneDeep(current(state.timeSettings[code].available_time));
            const freshHours = { days: [day], hours: [..._.cloneDeep(hours), defaultHours] };
            removeDayHours(newTime, day);
            hoursAlreadyExist(newTime, freshHours, day);
            state.timeSettings[payload.code].available_time = newTime;
        },
        removeHours: (state, { payload }) => {
            const { i, day, code, hours } = payload;
            const newTime = _.cloneDeep(current(state.timeSettings[code].available_time));
            removeDayHours(newTime, day);
            const freshHours = { days: [day], hours: [..._.cloneDeep(hours)] };
            freshHours.hours.splice(i, 1);
            hoursAlreadyExist(newTime, freshHours, day);
            state.timeSettings[code].available_time = newTime;
        },
        updateCodeName: (state, { payload }) => {
            const { code, value } = payload;
            state.timeSettings[code].name = value;
        },
        nameCode: (state, { payload }) => {
            const { code, value } = payload;
            state.timeSettings[code].code = value;
        },
        getStoreAvailabilitySuccess: (state, { payload }) => {
            if (payload.time_setting.length > 0 && _.isEmpty(state.openEditCodeDrawers)) {
                state.openEditCodeDrawers = new Array(payload.time_setting.length).fill(0);
            }
            state.timeSettings = payload.time_setting;
        },
    },
});

export const {
    getStoreAvailabilitySuccess,
    setTime,
    toggleOpenHours,
    addHours,
    removeHours,
    updateCodeName,
    setEditIndex,
    setShowEditModal,
    addNewCode,
    nameCode,
    cancelAddCode,
    setAddingCode,
} = slice.actions;

export const storeAvailability = slice.reducer;
