import { RootState } from "app/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentState } from "slices/store";
import { isBool } from "util/helper";
import { renderTop, renderHalfTxtInput, renderFullSwitch } from "./payment-functions";

const CloverPaymentSettings = (): JSX.Element => {
    const dispatch = useDispatch();
    const setState = (name: string, value: any) => dispatch(setPaymentState({ name, value }));
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const { clover_enabled, clover_is_debug, clover_merch_id, clover_access_token, clover_api_key } = paymentOptions;

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop("clover", "clover_setting_tip", "clover_enabled", clover_enabled, switchChange)}

            {clover_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("merchant_id", "clover_merch_id", clover_merch_id, setState, false, true)}
                    {renderHalfTxtInput(
                        "access_token",
                        "clover_access_token",
                        clover_access_token,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput("api_key", "clover_api_key", clover_api_key, setState, false, true)}
                    {renderFullSwitch("debug", "clover_is_debug", isBool(clover_is_debug), switchChange)}
                </div>
            ) : null}
        </div>
    );
};

export default CloverPaymentSettings;
