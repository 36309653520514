import React from "react";
import _ from "lodash";
import { DAYS } from "./constants";

interface HoursReducerProps {
    MON: Array<string>;
    TUE: Array<string>;
    WED: Array<string>;
    THU: Array<string>;
    FRI: Array<string>;
    SAT: Array<string>;
    SUN: Array<string>;
}

interface ActionPayload {
    MON: Array<string>;
    TUE: Array<string>;
    WED: Array<string>;
    THU: Array<string>;
    FRI: Array<string>;
    SAT: Array<string>;
    SUN: Array<string>;
}

interface ActionReducerProps {
    type: string;
    payload: ActionPayload;
}

export const reducer = (hours: HoursReducerProps | any, action: ActionReducerProps | any): React.ReactNode => {
    const day = action?.payload?.day;
    const index = action?.payload?.index;
    const deepIndex = action?.payload?.deepIndex;
    const newHours = _.cloneDeep(hours);

    switch (action.type) {
        case "loadHours":
            return action.payload;
        case "changeHour":
            if (newHours?.[day]?.[index]) {
                newHours[day][index][deepIndex] = action?.payload?.value;
            }
            return newHours;
        case "addHour":
            return {
                ...hours,
                [day]: [...hours[day], ["09:00", "17:00"]],
            };
        case "removeHour": {
            const d = _.cloneDeep(hours[day]);
            d.splice(index, 1);
            return {
                ...hours,
                [day]: d,
            };
        }
        case "setClosed":
            return {
                ...hours,
                [day]: [["closed"]],
            };
        case "setOpen":
            return {
                ...hours,
                [day]: [["09:00", "17:00"]],
            };
        case "setEnabled":
            return Object.keys(DAYS).reduce((acc: any, day: any) => {
                acc[day] = [["closed"]];
                return acc;
            }, {});
        case "setDisabled":
            return -1;
        default:
            return hours;
    }
};

interface HoursFormatted {
    business_hours: string;
    delivery_hours: string;
}

export const getHoursFormattedAsObject = (type: string, hours: HoursFormatted | any): React.ReactNode => {
    const formatted: any = {};
    if (hours) {
        const original = hours[type];

        if (hours[type] === -1 || hours[type] === "-1" || hours[type] == undefined) {
            // if hours for this type is -1, return closed for all days as hours
            Object.keys(DAYS).forEach((d) => {
                formatted[d] = [["closed"]];
            });
            return formatted;
        }

        const split = original?.split(";");

        split?.forEach((d: string) => {
            const arr = d.split(":");
            const day = arr.shift();
            const hours = arr
                .join(":")
                .split(",")
                .map((h) => h.split("-"));
            if (day) {
                formatted[day] = hours;
            }
        });
        return formatted;
    }
    // if hours is empty or doesn't exist, default to closed for all days
    Object.keys(DAYS).forEach((d) => {
        formatted[d] = [["closed"]];
    });
    return formatted;
};

export const formatHoursAsString = (hours: HoursReducerProps | any): React.ReactNode => {
    if (hours === -1) {
        return -1;
    }

    if (!_.isEmpty(hours)) {
        const formatted: any = [];
        Object.keys(hours).forEach((key) => {
            const times = hours[key].map((h: any[]) => h.join("-"));
            formatted.push(`${key}:${times.join(",")}`);
        });
        return formatted.join(";");
    }
    return null;
};

const validateDay = (ranges: Array<string>): React.ReactNode => {
    // Check if two time range is equal. Since it is looping through same array, same index can be equal.
    const range = ranges.find((iRange: any, i: any) => {
        return !_.isEmpty(
            ranges.find((jRange: any, j: any) => {
                return i !== j && _.isEqual(iRange, jRange);
            })
        );
    });

    return _.isEmpty(range);
};

const validate = (hours: any) => {
    return Object.keys(hours).filter((key) => !validateDay(hours[key])).length === 0;
};

export const canSaveChanges = (allHours: HoursReducerProps[] | any): React.ReactNode => {
    return Array.isArray(allHours) && !allHours.map((hours) => validate(hours)).includes(false);
};
