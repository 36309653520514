export const DEFAULTS = {
    ORDER_STATUS: 120,
    ORDER_TIME: 1,
    PAYMENT_TYPE: 1,
};

export const ORDER_TYPES: any = {
    IN_STORE: 0,
    PICKUP: 1,
    DELIVERY: 2,
    INSTANT_PAY: 3,
    INSTANT_CHECKOUT: 4,
};

export const DUE_TIME_SETTINGS: any = {
    ANY: 16,
    ASAP: 8,
    SAME_DAY: 4,
    FUTURE_DAY: 2,
    SPECIFY: 1,
};

export const ORDER_STATUSES: any = {
    ACCEPTED: 115,
    PREPARING: 120,
};

export const ORDER_TIMES: any = {
    ANY: 0,
    STORE_HOURS: 1,
};

export const PAYMENT_TYPES: any = {
    ANY: 0,
    ONLINE: 1,
    OFFLINE: 2,
};

// Max possible specified due time in minutes (48 hours)
export const MAX_DUE_TIME_MINUTES = 48 * 60;
