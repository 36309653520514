export enum StoreSignUpStep {
    PricePlanContactDetails,
    GeneralInformation,
    StoreTypeCategories,
}

export enum StoreStatus {
    NewlyCreated,
    NeedApproval,
    Pending,
    Approved,
    Delete
}