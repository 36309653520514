import React from "react";
import Wrapper from "../../components/wrapper";
import SalesOperations from "./_components/sales-operations";
import "./index.scss";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "salesReport",
            breadcrumbName: "sales_report",
        },
    ],
};

export default function SalesReportPage(): JSX.Element {
    return (
        <Wrapper helmet={{ title: "sales_report" }} breadcrumb={breadcrumb}>
            <div className="sales-report-container">
                <SalesOperations />
            </div>
        </Wrapper>
    );
}
