export const CUSTOMER_TYPES = {
    "all": 1,
    "customer_new": 2,
    "customer_returning": 3,
    "abandoned_carts": 4,
};

export const CUSTOMER_ROLES = {
    "guest": "Guest",
    "customer": "Customer",
    "default": "Default",
};

export enum customerActions {
    GET = 1,
    UPDATE = 2,
    ADDRESS = 3,
}

export const PHONE_MAX_LEN = 11;
