import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Store {
    id: number;
    status: number;
    name: string;
    names: any;
}

export interface User {
    id?: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    permission?: number;
    roles?: number[]

    token?: string;
    secret?: string;
    bearer?: string;
    deviceToken?: string;
    access?: any;

    stores?: Store[];
    current_store?: any;

    logoutPath?: any;
    is_use_pos_mode?: number;
}

const initialState: User = {};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        userLoginSuccess: (state, { payload }: PayloadAction<any>) => {
            state.id = payload?.profile?.id;
            state.username = payload?.profile?.username;
            state.firstName = payload?.profile?.first_name;
            state.lastName = payload?.profile?.last_name;
            state.roles = payload?.profile?.roles;
            state.stores = payload?.profile?.g_ids;

            state.token = payload?.oauth?.token;
            state.secret = payload?.oauth?.secret;
            state.bearer = payload?.user_session?.bearer_token;
            state.deviceToken = payload?.profile?.device_token;
            state.access = payload?.profile?.access_flags;
            state.current_store = payload?.profile?.current_store;
        },
        updateProfileSuccess: (state, { payload }) => {
            state.deviceToken = payload?.profile?.device_token;
            state.id = payload?.profile?.id;
            state.username = payload?.profile?.username;
            state.firstName = payload?.profile?.first_name;
            state.lastName = payload?.profile?.last_name;
            state.stores = payload?.profile?.g_ids;
            state.access = payload?.profile?.access_flags;
            state.current_store = payload?.profile?.current_store;
            state.is_use_pos_mode = payload?.profile?.is_use_pos_mode;
        },
        userLogoutSuccess: (state, action?: any) => {
            const payload = action?.payload;

            state.username = undefined;
            state.firstName = undefined;
            state.lastName = undefined;
            state.permission = undefined;

            state.token = undefined;
            state.secret = undefined;
            state.bearer = undefined;

            if (payload?.path) {
                // Removing hash from beginning of path because history.push does not take the hash.
                const routeArr = payload.path?.split("/");
                routeArr.shift();
                state.logoutPath = routeArr?.join("/");
            }
        },
        updateUserTokenSecret: (state, { payload }) => {
            state.token = payload?.token;
            state.secret = payload?.secret;
            if (payload.callBack) payload.callBack();
        },
    },
});

export const { userLoginSuccess, userLogoutSuccess, updateProfileSuccess, setUserState, updateUserTokenSecret } =
    slice.actions;

export const user = slice.reducer;
