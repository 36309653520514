import { Button } from "antd";
import { RootState } from "app/reducer";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import config from "../../../../config";
import {
    addNewUpdateTaskItem,
    EditModalPages,
    setEditModal,
    setUpdateForm,
    updateOnActiveTask,
    UpdateTaskPath,
} from "../../../../slices/delivery_request";
import { Paths } from "../../../../types";
import { Item } from "../../../../types/get-delivery-task-respone";
import { ADD_NEW_ITEM_KEY, PRODUCT_CATEGORY_PICKER_OPTIONS } from "../../constants";
import "../../style.scss";
import { FormInputComponent, INPUT_TYPE } from "../Form";
import { buildUpdatedOnActivedTask, getOrderValues, popArrayByIndex } from "../helper";

const TaskItemsTab: React.FC<PropsFromRedux> = ({
    // state
    onActiveTaskState,
    updateTaskState,
    symbol,
    // setter
    setUpdateForm,
    setEditModal,
    updateOnActiveTask,
    // adder
    addNewUpdateTaskItem,
}) => {
    const { additionalInfo } = updateTaskState;
    const intl = useIntl();

    useEffect(() => {
        if (additionalInfo) {
            const [subTotal] = getOrderValues(additionalInfo?.itemsInfo, additionalInfo?.orderTaxPercent);

            handleOnChange({ key: "additionalInfo.subTotal", value: subTotal });
        }
    }, [additionalInfo?.itemsInfo]);

    /**
     * on change listener
     */
    const handleOnChange = ({ key, value }: { key: Paths<typeof updateTaskState>; value: any }) => {
        setUpdateForm({ key: key as UpdateTaskPath, value });
        const updatedTask = buildUpdatedOnActivedTask(onActiveTaskState, key, value);
        if (updatedTask) updateOnActiveTask(updatedTask);
    };

    const handleAddNewItem = () => {
        addNewUpdateTaskItem();
        const updatedTask = buildUpdatedOnActivedTask(onActiveTaskState, ADD_NEW_ITEM_KEY);
        if (updatedTask) updateOnActiveTask(updatedTask);
    };

    const handleRemoveItem = (index: number) => {
        if (onActiveTaskState) {
            const arr = popArrayByIndex(index, [...onActiveTaskState?.items]);
            const result = {
                ...onActiveTaskState,
                items: arr,
            };
            updateOnActiveTask(result);
            setUpdateForm({ key: "additionalInfo.itemsInfo", value: arr });
        }
    };

    // : EditModalPages.UPDATE_ITEM_OPTION
    const handleEditOption = (itemIndex: number, optionIndex: number | null, currPage: EditModalPages) => {
        setEditModal({ itemIndex, optionIndex, currPage });
    };

    const handleAddNewOption = (itemIndex: number) => {
        setEditModal({ itemIndex, currPage: EditModalPages.UPDATE_ITEM_ADD_OPTION });
    };

    /**
     * layout renderer
     */
    const renderCategorisePicker = () => {
        const intl = useIntl();
        return (
            <>
                <div style={{ margin: "12px 0px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>{`${intl.formatMessage({
                        id: "category",
                    })} ${onActiveTaskState?.item_category ? `(${onActiveTaskState?.item_category})` : ""}`}</span>
                </div>
                <div className="new-delivery-request-categorise-picker">
                    {PRODUCT_CATEGORY_PICKER_OPTIONS.map((v) => {
                        const isActive = onActiveTaskState?.item_category?.toLowerCase?.() === v.toLowerCase();
                        // if there is no category selected initially, select the "food" category
                        return (
                            <div
                                onClick={() => {
                                    handleOnChange({ key: "additionalInfo.itemsCategory", value: v });
                                }}
                                className={`categorise-picker-options ${isActive ? "active" : ""}`}
                                key={v}
                            >
                                {intl.formatMessage({ id: v })}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const renderItems = () => {
        return (
            <div>
                <div style={{ margin: "12px 0px" }}>
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>{`${intl.formatMessage({ id: "items" })} (${
                        onActiveTaskState?.items?.length
                    })`}</span>
                </div>
                <div>{onActiveTaskState?.items?.map((item, i) => renderItem(item, i))}</div>
                <div>{renderNewItemBtn()}</div>
            </div>
        );
    };

    const renderItem = (item: Item, index: number) => {
        return (
            <div key={item.name + index} className="tabs-items-item-container">
                <div
                    style={{
                        display: "flex",
                        padding: "8px 0px",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <FormInputComponent
                            name={`actived task item ${index} name`}
                            state={updateTaskState}
                            inputDataPath={`additionalInfo.itemsInfo.${index}.name` as Paths<typeof updateTaskState>}
                            inputType={INPUT_TYPE.TEXT}
                            defaultValue={item.name}
                            placeholder="item name"
                            handleOnClick={() => {
                                handleEditOption(index, null, EditModalPages.UPDATE_ITEM_NAME);
                            }}
                        />
                    </div>
                    <div>
                        <FormInputComponent
                            name={`actived task item ${index} price`}
                            state={updateTaskState}
                            inputDataPath={`additionalInfo.itemsInfo.${index}.price` as Paths<typeof updateTaskState>}
                            inputType={INPUT_TYPE.INPUT_NUMBER}
                            defaultValue={item.price}
                            handleOnChange={handleOnChange}
                            addonBefore={symbol}
                            placeholder="price"
                        />
                    </div>
                    <div>X</div>
                    <div>
                        <FormInputComponent
                            name={`actived task item ${index} quantity`}
                            state={updateTaskState}
                            inputDataPath={
                                `additionalInfo.itemsInfo.${index}.quantity` as Paths<typeof updateTaskState>
                            }
                            inputType={INPUT_TYPE.INPUT_NUMBER}
                            defaultValue={item.quantity}
                            handleOnChange={handleOnChange}
                            addonBefore={symbol}
                            placeholder="quantity"
                        />
                    </div>
                    <Button onClick={() => handleRemoveItem(index)}>X</Button>
                </div>
                {renderItemOptions(item?.options, index)}
            </div>
        );
    };

    const renderNewItemBtn = () => {
        return (
            <Button className="blue-button" onClick={handleAddNewItem}>{`${intl.formatMessage({
                id: "new_item",
            })}`}</Button>
        );
    };

    const renderItemOptions = (options: Item["options"], itemIndex: number) => {
        return (
            <div style={{ margin: "20px 0px" }}>
                <div style={{ margin: "12px 0px" }}>
                    {options?.map((option, index) => {
                        return renderOption(option, itemIndex, index);
                    })}
                </div>
                <div style={{ margin: "12px 0px" }}>{renderNewOptionButton(itemIndex)}</div>
            </div>
        );
    };

    const renderOption = (option: string, itemIndex: number, index: number) => {
        return (
            <div
                key={option + index}
                onClick={() => {
                    handleEditOption(itemIndex, index, EditModalPages.UPDATE_ITEM_OPTION);
                }}
                style={{ backgroundColor: "#f8f8f8", margin: "8px 4px", paddingLeft: "8px", cursor: "pointer" }}
            >
                {option}
            </div>
        );
    };

    const renderNewOptionButton = (itemIndex: number) => {
        return (
            <Button
                className="blue-button"
                onClick={() => {
                    handleAddNewOption(itemIndex);
                }}
            >{`${intl.formatMessage({ id: "new_option" })}`}</Button>
        );
    };

    const getTotel = (subTotal: null | undefined | number, tax: null | undefined | number): number => {
        if (!subTotal) subTotal = 0;
        if (!tax) tax = 0;
        return subTotal + tax;
    };

    const getRoundedNumber = (input: number | undefined | null): number => {
        if (!input) return 0;
        return Math.round(input * 100) / 100;
    };

    const renderPriceInfo = () => {
        const style = { display: "flex", width: "100%", justifyContent: "flex-end" };
        return (
            <div>
                <div style={style}>
                    <div>{`${intl.formatMessage({ id: "sub_total" })}:`} </div>
                    <div style={{ margin: "0px 5px" }}>
                        <FormInputComponent
                            name={`actived task subtotal`}
                            state={updateTaskState}
                            inputDataPath={"additionalInfo.subTotal"}
                            inputType={INPUT_TYPE.INPUT_NUMBER}
                            defaultValue={getRoundedNumber(onActiveTaskState?.sub_total)}
                            handleOnChange={handleOnChange}
                            addonBefore={symbol}
                        />
                    </div>
                </div>
                <div style={style}>
                    <div>{`${intl.formatMessage({ id: "tax" })}:`} </div>
                    <div style={{ margin: "0px 5px" }}>
                        <FormInputComponent
                            name={`actived task subtotal`}
                            state={updateTaskState}
                            inputDataPath={"additionalInfo.orderTax"}
                            inputType={INPUT_TYPE.INPUT_NUMBER}
                            defaultValue={getRoundedNumber(onActiveTaskState?.tax)}
                            handleOnChange={handleOnChange}
                            addonBefore={symbol}
                        />
                    </div>
                </div>
                <div style={style}>
                    <div>{`${intl.formatMessage({ id: "total" })}:`} </div>
                    <div style={{ margin: "0px 5px" }}>
                        <FormInputComponent
                            name={`actived task subtotal`}
                            state={updateTaskState}
                            inputDataPath={"additionalInfo.totalValue"}
                            inputType={INPUT_TYPE.INPUT_NUMBER}
                            defaultValue={getRoundedNumber(
                                getTotel(onActiveTaskState?.tax, onActiveTaskState?.sub_total)
                            )}
                            handleOnChange={handleOnChange}
                            disabled={true}
                            addonBefore={symbol}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderCategorisePicker()}
            {renderItems()}
            {renderPriceInfo()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const currency: keyof typeof config.CURRENCY_SYMBOL =
        state?.store?.storeCurrencyAndPricePlan?.store_currency ?? "CAD";
    const symbol = config.CURRENCY_SYMBOL[currency];
    return {
        onActiveTaskState: state.deliveryRequests.onActiveTask,
        updateTaskState: state.deliveryRequests.forms.updateTask,
        symbol,
    };
};

const mapDispatchToProps = {
    // setter
    updateOnActiveTask,
    setUpdateForm,
    setEditModal,
    // adder
    addNewUpdateTaskItem,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default reduxConnenter(TaskItemsTab);
