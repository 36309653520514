import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import React from "react";
import { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import helper from "../../../../util/helper";
import ReferralsModalValueRenderer from "./value";

const selector = (state: RootState) => ({
    records: state?.referrals?.orderHistory,
    paging: state?.referrals?.orderHistoryPaging,
    searchConditions: state?.referrals?.orderHistorySearchConditions,
    noRecords: state?.referrals?.noRecords,
});

function ModalTable(): JSX.Element | null {
    const { records, noRecords } = useSelector(selector);
    const intl = useIntl();

    let gridApi: any;

    const columnDefs = [
        {
            field: "order_id",
            width: 350,
            headerName: intl.formatMessage({ id: "order_id" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => record?.order_id?.split("-")?.[0]?.slice(-4),
            },
        },
        {
            field: "order_id",
            width: 350,
            headerName: intl.formatMessage({ id: "created_at" }),
            cellRenderer: "valueRenderer",
            cellRendererParams: {
                renderValue: (record: any) => helper.utcToLocal(record?.created_at),
            },
        },
        {
            field: "order_id",
            width: 350,
            headerName: intl.formatMessage({ id: "amount" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => helper.formatCurrency(record?.amt),
            },
        },
        {
            field: "order_id",
            width: 350,
            headerName: intl.formatMessage({ id: "referral_points" }),
            cellRenderer: "valueRenderer",
            cellClass: "right-align",
            type: "numericColumn",
            cellRendererParams: {
                renderValue: (record: any) => record?.reward_pts,
            },
        },
    ];

    const getTableObject: any = () => ({
        columnDefs,
        defaultColDef: {
            resizable: true,
            wrapText: false,
        },
        frameworkComponents: {
            valueRenderer: ReferralsModalValueRenderer,
        },
        rowData: noRecords ? [] : records,
        getRowHeight: () => 50,
        getRowNodeId: (row: any) => row?.order_id,
        immutableData: true,
        onGridReady: (params: any) => {
            params?.api?.sizeColumnsToFit?.();
            gridApi = params?.api;
        },
    });

    const getTable = () => {
        const tableObject = getTableObject();
        return records?.length
            ? React.createElement(
                  "div",
                  {
                      className: "customers-table-container referral-modal-table ag-theme-alpine",
                  },
                  React.createElement(AgGridReact, { ...tableObject })
              )
            : null;
    };

    const resizeCallback = useCallback(() => {
        gridApi?.sizeColumnsToFit?.();
    }, [gridApi]);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, [resizeCallback]);

    return getTable();
}

export default ModalTable;
