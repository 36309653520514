import { Modal } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import { setGiftcardState } from "../../../../slices/giftcards";
import CustomerGiftCardsTable from "./customer-gift-cards-table";
import GiftCardAddCreditModal from "../../../gift-card-account/_components/gift-card-account-modal/gift-card-add-credit-modal";
import GiftCardChargeModal from "../../../gift-card-account/_components/gift-card-account-modal/gift-card-charge-modal";
import GiftCardHistoryModal from "../../../gift-card-account/_components/gift-card-account-modal/gift-card-history-modal";

const selector = (state: RootState) => ({
    accounts: state?.giftcards?.accounts,
    history: state?.giftcards?.history,
    showCustomerGiftCardsModal: state?.giftcards?.showCustomerGiftCardsModal,
});

function CustomerGiftCardsModal(): JSX.Element {
    const { showCustomerGiftCardsModal, accounts } = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(setGiftcardState({ accounts: [], showCustomerGiftCardsModal: false }));
    };

    return (
        <Modal
            visible={showCustomerGiftCardsModal && (accounts?.length ?? 0) > 0}
            onCancel={handleClose}
            width={1024}
            title={intl.formatMessage({ id: "giftcards" })}
            footer={null}
        >
            <CustomerGiftCardsTable />
            <GiftCardAddCreditModal />
            <GiftCardChargeModal />
            <GiftCardHistoryModal />
        </Modal>
    );
}

export default CustomerGiftCardsModal;
