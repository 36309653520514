import { createSlice } from "@reduxjs/toolkit";

interface UI {
    navigationWidth: number;
}

const initialState: UI = {
    navigationWidth: 200,
};

const slice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setUiState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
    },
});

export const { setUiState } = slice.actions;

export const ui = slice.reducer;
