import Wrapper from "components/wrapper";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/reducer";
import {
    clearInputRefs,
    clearOnActiveTask,
    clearQuickSearch,
    clearSearchQuery,
    EditModalPages,
    MessageModalPages,
    setCurrentMessageModalPage,
    setEditModal,
    setIsLoading,
    setItemModal,
    setShouldMessageModalOpen,
    setShouldModalOpen,
} from "../../slices/delivery_request";
import { DoordashSetting } from "../../types/doordash-setting";
import { DeliveryFlags } from "../../types/store-delivery-flags";
import { ThirdPartyDeliverySettings } from "../../types/store-third-party-delivery-setting";
import "./style.scss";
import { DeliveryDrawerFilterComponent, LayoutComponent } from "./_components";
import { auth, closeDialogActions, getEditModalTitleByID, getMessageModalWidth } from "./_components/helper";
import {
    DeliveryRequestModal,
    EditModalLayout,
    GetModalContent,
    GetModalTitle,
    ModalFooterLayout,
    PopUpMessageModalFooter,
    PopUpMessageModalHeader,
    PopUpMessageModalLayout,
} from "./_components/Modals";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        {
            path: "/local_delivery",
            breadcrumbName: "local_delivery",
        },
    ],
};

const DeliveryRequestPage: React.FC<StateFromRedux> = ({
    // setter
    setShouldModalOpen,
    setIsLoading,
    setEditModal,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    // clear
    clearSearchQuery,
    clearOnActiveTask,
    clearInputRefs,
    // state
    newTaskModalCurrPage,
    shouldNewTaskModalOpen,
    shouldMessageModalOpen,
    editModal,
    messageModalCurrPage,
    updatedTask,
    deliveryFlags,
    storeThirdPartyDelivery,
    newTaskState,
    onActiveTask,
    defaultDeliverySettings,
    // actions
    closeDialogActions,
}): JSX.Element => {
    // clear search bar when open
    useEffect(() => {
        initData();
        return () => {
            setShouldModalOpen(false);
            setShouldMessageModalOpen(false);
            clearSearchQuery();
            clearOnActiveTask();
            clearInputRefs();
        };
    }, []);

    const initData = async () => {
        // reset state
        clearInputRefs();
        clearSearchQuery();
        setShouldModalOpen(false);
        setItemModal(false);
        setIsLoading(false);
        clearOnActiveTask();
        if (auth(deliveryFlags, storeThirdPartyDelivery)) {
            setShouldMessageModalOpen(false);
        } else {
            setShouldMessageModalOpen(true);
            setCurrentMessageModalPage(MessageModalPages.NOT_AUTHORIZED_DELIVERY);
        }
    };

    const renderModals = () => {
        return (
            <>
                {/* new tasks modal */}
                <DeliveryRequestModal
                    shouldOpen={shouldNewTaskModalOpen}
                    title={<GetModalTitle />}
                    width={700}
                    footer={<ModalFooterLayout />}
                    handleCancel={() => {
                        closeDialogActions(
                            newTaskModalCurrPage,
                            newTaskState,
                            onActiveTask,
                            updatedTask,
                            defaultDeliverySettings
                        );
                    }}
                >
                    <GetModalContent />
                </DeliveryRequestModal>
                {/* edit item name or options */}
                <DeliveryRequestModal
                    title={getEditModalTitleByID(editModal.currPage)}
                    handleOk={() => {
                        setEditModal({ currPage: EditModalPages.HIDDEN });
                    }}
                    handleCancel={() => {
                        setEditModal({ currPage: EditModalPages.HIDDEN });
                    }}
                    shouldOpen={editModal.currPage !== EditModalPages.HIDDEN}
                    footer={null}
                    zIndex={1050}
                >
                    <EditModalLayout />
                </DeliveryRequestModal>
                {/* error message modal */}
                <DeliveryRequestModal
                    title={<PopUpMessageModalHeader />}
                    closable={messageModalCurrPage !== MessageModalPages.DELIVERY_FEE_DETAIL}
                    handleCancel={() => {
                        setShouldMessageModalOpen(false);
                    }}
                    footer={<PopUpMessageModalFooter />}
                    shouldOpen={shouldMessageModalOpen}
                    zIndex={1500}
                    width={getMessageModalWidth(messageModalCurrPage)}
                >
                    <PopUpMessageModalLayout />
                </DeliveryRequestModal>
            </>
        );
    };

    return (
        <Wrapper paddingBottom={0} helmet={{ title: "local_delivery" }} breadcrumb={breadcrumb}>
            <div className="request-delivery-page-container">
                <div className="request-delivery-content">
                    <LayoutComponent />
                </div>
            </div>
            {/* drawer filter */}
            <DeliveryDrawerFilterComponent />
            {/* modals */}
            {renderModals()}
        </Wrapper>
    );
};

const mapStateToProps = (state: RootState) => {
    const deliveryRequestsState = state.deliveryRequests;
    const { modal } = deliveryRequestsState;
    return {
        shouldNewTaskModalOpen: modal.newTaskModal.shouldOpen,
        shouldMessageModalOpen: modal.messageModal.shouldOpen,
        messageModalCurrPage: modal.messageModal.currPage,
        newTaskModalCurrPage: modal.newTaskModal.currentPage,
        editModal: modal.editModal,
        updatedTask: deliveryRequestsState.forms.updateTask,
        newTaskState: deliveryRequestsState.forms.newTask,
        onActiveTask: deliveryRequestsState.onActiveTask,
        defaultDeliverySettings: (state.store.storeThirdPartyDelivery.doordash ?? {}) as DoordashSetting,
        deliveryFlags: state.store.storePickupDelivery as DeliveryFlags,
        storeThirdPartyDelivery: state.store.storeThirdPartyDelivery as ThirdPartyDeliverySettings,
    };
};

const mapDispatchToProps = {
    // setter
    setIsLoading,
    setShouldModalOpen,
    setShouldMessageModalOpen,
    setCurrentMessageModalPage,
    setEditModal,
    // clear
    clearSearchQuery,
    clearQuickSearch,
    clearOnActiveTask,
    clearInputRefs,
    // actions
    closeDialogActions,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type StateFromRedux = ConnectedProps<typeof reduxConnenter>;

export default reduxConnenter(DeliveryRequestPage);
