// UI components
import { Modal, Select, Row, Col, Input, Form, Button } from "antd";
// lodash features make coding easier
import _ from "lodash";
// react features
import React, { Component } from "react";
// locale and translation features
import { FormattedMessage, injectIntl } from "react-intl";
// redux connector features
import { connect } from "react-redux";
// routing features
import { withRouter } from "react-router-dom";
// update customer reducer
import { updateCustomer } from "../../../../services/customers";
// all languages
import { Languages } from "../../../../slices/setting";
// helper function for getting customer id
import { getCustomerId } from "./helper";
// max phone length constant
import { PHONE_MAX_LEN } from "../../constants";

// modal component for editing customer info
class EditCustomerModal extends Component {
    // helper function to simplify translations
    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    // modal layout styles
    layout = () => ({
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    });

    // validation messages for given inputs
    validateMessages = () => ({
        // eslint-disable-next-line no-template-curly-in-string
        required: this.str("please_input_valid_x", { x: "${label}" }),
        types: {
            // eslint-disable-next-line no-template-curly-in-string
            email: this.str("please_input_valid_x", { x: "${label}" }),
            // eslint-disable-next-line no-template-curly-in-string
            number: this.str("please_input_valid_x", { x: "${label}" }),
        },
    });

    // default values used in case props are empty
    getDefautlValues = () => ({
        country_code: "CA",
        language: _.get(Object.values(Languages), 0),
        ...this.props.customer,
    });

    // determine if modal should be shown
    shouldOpen = () => this.props?.parentState?.openEditInfo;

    // method to close current modal
    closeModal = () => {
        this.props.setParentState({ openEditInfo: false });
    };

    // request body is mostly composed of parentState.customerInfo
    // as that keeps track of current form data
    onFinish = (values) => {
        this.props.updateCustomer({
            id: getCustomerId(this.props),
            ...values,
            ...(this.props?.parentState?.customerInfo ?? {}),
        });
        this.props.setParentState({ customerInfo: {} });
        this.closeModal();
    };

    // add translation mapping for input validation messages
    mapFormNameToTranslationId = (formName) => {
        switch (formName) {
            case "firstname":
                return "first_name";
            case "phone":
                return "customer_phone";
            default:
                return formName;
        }
    };

    // get props for Form.Item elements
    getSharedFormItemProps = (name = " ") => {
        const inValid = _.get(this.props, `parentState.${name}Invalid`);
        return {
            validateStatus: inValid ? "error" : "success",
            help: inValid
                ? this.str("please_input_valid_x", { x: this.str(this.mapFormNameToTranslationId(name)) })
                : "",
        };
    };

    // get props for Input, Select elements
    getSharedInputProps = (name) => ({
        value: this.getCustomerInfoData(name),
        onChange: (e) => {
            const value = e?.target ? e?.target?.value : e;
            const formattedValue = name === "phone" ? value.replaceAll(/[^0-9]/g, "") : value;
            this.setCustomerInfoData({ [name]: formattedValue });
        },
    });

    // get customer info data for this modal
    getCustomerInfoData = (key) =>
        _.get(this.props, `parentState.customerInfo.${key}`);

    // update customer info data for this modal
    setCustomerInfoData = (values) => {
        this.props.setParentState({
            customerInfo: {
                ...(this.props?.parentState?.customerInfo || {}),
                ...values,
            },
        });
    };

    // render title section
    renderTitle = () => (
        <div className="edit-cusotmer-modal-title">
            <FormattedMessage id="edit_customer_info" />
        </div>
    );

    // render first name and last name inputs
    renderNamesInput = () => (
        <div className="new-customer-form-row">
            <Row style={{ width: "100%" }} gutter={8}>
                <Col span={12}>
                    <Form.Item
                        {...this.getSharedFormItemProps("firstname")}
                        style={{ width: "100%" }}
                        label={this.str("first_name")}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input {...this.getSharedInputProps("firstname")} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        {...this.getSharedFormItemProps("lastname")}
                        style={{ width: "100%" }}
                        label={this.str("last_name")}
                    >
                        <Input {...this.getSharedInputProps("lastname")} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    // render customer email input
    renderEmailInput = () => (
        <Form.Item
            {...this.getSharedFormItemProps("email")}
            label={this.str("email")}
            rules={[
                {
                    required: true,
                    type: "email",
                },
            ]}
        >
            <Input {...this.getSharedInputProps("email")} />
        </Form.Item>
    );

    // render customer phone input
    renderPhoneInput = () => {
        const prefixSelector = (
            <Form.Item {...this.getSharedFormItemProps("country_code")} noStyle>
                <Select {...this.getSharedInputProps("country_code")} style={{ width: 70 }}>
                    <Select.Option value="CA">+1</Select.Option>
                </Select>
            </Form.Item>
        );

        return (
            <div className="new-customer-form-row">
                <Form.Item
                    {...this.getSharedFormItemProps("phone")}
                    style={{ width: "100%" }}
                    label={this.str("phone")}
                    rules={[{ required: true }]}
                >
                    <Input
                        {...this.getSharedInputProps("phone")}
                        addonBefore={prefixSelector}
                        maxLength={PHONE_MAX_LEN}
                    />
                </Form.Item>
            </div>
        );
    };

    // render customer languages select input
    renderLanguages = () => {
        const lansArray = Object.values(Languages);
        return (
            <div className="new-customer-form-row ">
                <Form.Item
                    {...this.getSharedFormItemProps("language")}
                    style={{ width: "100%" }}
                    label={this.str("language")}
                >
                    <Select {...this.getSharedInputProps("language")}>
                        {lansArray.map((key) => (
                            <Select.Option key={key} value={key}>
                                {<FormattedMessage id={key} />}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    };

    // render customer notes input
    renderNote = () => (
        <Form.Item {...this.getSharedFormItemProps("customer_note")} label={this.str("note")}>
            <Input {...this.getSharedInputProps("customer_note")} placeholder={this.str("note_place_holder")} />
        </Form.Item>
    );

    // render form submit button
    renderSaveButton = () => (
        <Form.Item wrapperCol={{ span: 24, offset: 11 }}>
            <Button size="large" type="link" htmlType="submit">
                <FormattedMessage id="save" />
            </Button>
        </Form.Item>
    );

    // render form with all above form item elements
    renderForm = () => {
        return (
            <Form
                {...this.layout()}
                onFinish={this.onFinish}
                validateMessages={this.validateMessages()}
                initialValues={this.props?.parentState?.customerInfo || this.getDefautlValues()}
            >
                {this.renderNamesInput()}
                {this.renderEmailInput()}
                {this.renderPhoneInput()}
                {this.renderLanguages()}
                {this.renderNote()}
                {this.renderSaveButton()}
            </Form>
        );
    };

    // render modal with all form elements and custom features
    render() {
        return (
            <Modal
                destroyOnClose
                onCancel={() => this.closeModal()}
                visible={this.shouldOpen()}
                title={null}
                footer={null}
            >
                <div className="edit-customer-info-modal">
                    {this.renderTitle()}
                    {this.renderForm()}
                </div>
            </Modal>
        );
    }
}

// redux feature - map store state to component props with given selectors
// only need lan, state, and customer for current component
const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
    customer: state?.customers?.customer,
});

// redux feature - map reducers to component
// only need updateCustomer reducer for current component
const mapDispatchToProps = { updateCustomer };

// export component with:
// react router features in props
// intl (translation) features
// redux features in props
export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditCustomerModal)));
