import { Modal, Select, Skeleton } from "antd";
import html2canvas from "html2canvas";
import _ from "lodash";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { QRCode } from "react-qrcode-logo";
import { connect } from "react-redux";
import config from "../../config";
import images from "../../images";
import helper, { getGSaleURL, getPrice, getProductURL } from "../../util/helper";
import "./poster-share-dialog.scss";

const { Option } = Select;

const STORE_FLAGS_CID_INDEX = 2;

const DOWNLOAD_IMAGES_QUALITY_OPTIONS = {
    "low": 3,
    "medium": 10,
    "high": 15,
};

const QRCODE_SIZE = 100;

class StoreQrDialog extends React.Component {
    state = {
        scale: DOWNLOAD_IMAGES_QUALITY_OPTIONS.low,
    };

    componentDidMount() {
        this.checkQRUrlChange({});
    }

    componentDidUpdate(prevsProps, prevState) {
        this.checkQRUrlChange(prevsProps, prevState);
    }

    str = (id) => this.props.intl.formatMessage({ id: id ?? " " });

    isProduct = (props = this.props) => props.type === "product" || props.type === "group-purchase";

    shouldOpen = () => this.props.open;

    closeDialog = () => this.props?.onClose();

    getStore = (props = this.props) => _.get(props, "store.records");

    getProduct = (props = this.props) => {
        return _.get(props, "product");
    };

    getStoreLogo = (props = this.props) => _.get(this.getStore(props), "logo");

    getStoreName = (props = this.props) => {
        const storeNameObj = _.get(this.getStore(props), "general_info.store_nm");
        return helper.getTransString(storeNameObj, this.props.lan);
    };

    getStoreCid = (props = this.props) => {
        const storeFlags = helper.isString(_.get(this.getStore(props), "store_flg"))
            ? _.get(this.getStore(props), "store_flg").split(",")
            : [];
        const cids = helper.isString(_.get(storeFlags, STORE_FLAGS_CID_INDEX))
            ? _.get(storeFlags, STORE_FLAGS_CID_INDEX).split(":")
            : [];
        const cid = String(_.get(cids, 0));
        return cid;
    };

    getStoreCidStr = (props = this.props) => {
        const c_id = this.getStoreCid(props);
        return helper.getCategoryString(c_id);
    };

    getGid = (props = this.props) => _.get(this.getStore(props), "g_id");

    getPid = (props = this.props) => _.get(this.getProduct(props), "product_id");

    getProductName = (props = this.props) => {
        const nameObj = _.get(this.getProduct(props), "name");
        return helper.getTransString(nameObj, this.props.lan);
    };

    getProductType = (props = this.props) => {
        return String(_.get(this.getProduct(props), "product_type"));
    };

    getProductPrice = (props = this.props) => {
        return _.get(this.getProduct(props), "price");
    };

    getName = (props = this.props) => {
        return this.isProduct(props) ? this.getProductName() : this.getStoreName(props);
    };

    getDownloadName = (props = this.props) => {
        const name = this.getName(props);
        const nameStr = helper.isString(name) ? name : "";
        return nameStr.toLowerCase().replace(/\s/g, "-");
    };

    getImage = (props = this.props) => {
        return this.isProduct(props) ? _.get(this.getProduct(), "img", "") : this.getStoreLogo();
    };

    getLogo = (props = this.props) => {
        const image = this.getImage(props);
        return helper.getImageUrl(image, ",c_fill");
    };

    getQRCodeLogo = (props = this.props) => {
        const image = this.getStoreLogo(props);
        if (image) {
            return helper.getImageUrl(image);
        }
    };

    getOriginalImage = (props = this.props) => {
        const image = this.getImage(props);
        if (image) {
            return helper.getImageUrlOriginal(image);
        }
    };

    getUrl = (props = this.props) => {
        const product = this.getProduct();
        const productType = product?.product_type;

        let result = "";
        if (productType === config.PRODUCT_TYPE_TO_NUMERIC.group_purchase) {
            result = getGSaleURL(this.getPid(props), props.lan);
        } else {
            result = getProductURL(this.getPid(props), props.lan);
        }

        if (this.props?.refCode) {
            result = result + "&ref=" + this.props.refCode;
        }

        return result;
    };

    getBackgroundImage = () => {
        return images["i_shopping_medium_grey"]?.default;
    };

    getContentId = () => `poster-dialog-content-${this.getUrl()}`;

    checkQRUrlChange = (prevsProps) => {
        const urlChange = this.getUrl(prevsProps) !== this.getUrl() && this.getUrl();
        if (urlChange) {
            this.setState({ qrUrl: this.getUrl(), QRReady: true });
        }
    };

    covertImage = (fromImage, fromQRCode, scale) => {
        const div = document.getElementById(this.getContentId());
        const setState = (values) => this.setState(values);
        const imageReady = fromImage || this.state.imageReady;
        const QRReady = fromQRCode || this.state.QRReady;
        const scaleSize = scale || this.state.scale;
        if (imageReady && QRReady && div) {
            document.documentElement.classList.add("hide-scrollbar");
            html2canvas(div, {
                useCORS: true,
                scrollX: 0,
                scrollY: 0,
                backgroundColor: null,
                scale: scaleSize,
            }).then(function (canvas) {
                const pngUrl = canvas.toDataURL("image/png");
                setState({ posterUrl: pngUrl, loaded: true });
            });
            document.documentElement.classList.remove("hide-scrollbar");
        }
    };

    downloadImage = () => {
        html2canvas(document.getElementById("canvas-poster"), { useCORS: true, scale: this.state.scale }).then(
            (canvas) => {
                document.body.appendChild(canvas);
                const data = canvas.toDataURL("image/png");

                const link = document.createElement("a");
                link.download = this.getDownloadName();
                link.href = data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                document.body.removeChild(canvas);
            }
        );
    };

    renderCustomerInfo = () => {
        const firstName = _.get(this.props, "user.firstName");
        const who = firstName ? firstName : this.str("guest_user");
        const isRestaurant = this.getStoreCid() === "1";
        const recommentMessage = this.isProduct()
            ? "recommend_a_good_product"
            : isRestaurant
            ? "recommend_a_good_restaurant"
            : "recommend_a_good_merchat";
        return (
            <div className="user-share-message">
                <div className="user-share-message-user-name">{who}</div>
                <div className="user-share-message-message">
                    <FormattedMessage id={recommentMessage} />
                </div>
            </div>
        );
    };

    renderImage = () => {
        const shouldCover = this.isProduct();
        const renderLogoImage = () => (
            <React.Fragment>
                <img
                    ref={(img) => (this.img = img)}
                    alt={this.getName()}
                    src={this.getLogo()}
                    style={{ objectFit: shouldCover ? "cover" : "contain" }}
                    className="poster-image-on-error-detector"
                    onError={() => {
                        this.img.src = this.getBackgroundImage();
                        this.covertImage(true, false);
                    }}
                    onLoad={() => {
                        this.covertImage(true, false);
                        this.setState({ imageReady: true });
                    }}
                />
            </React.Fragment>
        );

        return <div className="poster-image">{renderLogoImage()}</div>;
    };

    renderPrice = () => {
        const product = _.get(this.props, "product", {});
        const currency = _.get(this.props, "state?.store?.storeCurrencyAndPricePlan?.store_currency,", "CAD");
        const price = this.getProductPrice();
        const currentPriceAmt = getPrice(product);
        const currentPrice = helper.formatCurrency(currentPriceAmt, currency, "", false);
        const pricesArray = currentPrice && currentPrice.split(".");
        const symbol = config.CURRENCY_SYMBOL["CAD"];
        const beforeDecimal = _.get(pricesArray, "0", "");
        const afterDecimal = _.get(pricesArray, "1", "") ? `.${_.get(pricesArray, "1", "")}` : 0;
        const regularPrice = helper.formatCurrency(price, currency);
        if (price && this.props.type === "product")
            return (
                <div className="poster-share-dialog-price">
                    <div className="store-product-price ">
                        <span className="store-product-price-symbol">{symbol}</span>
                        {beforeDecimal ? (
                            <span className="store-product-price-before-decimal">{beforeDecimal}</span>
                        ) : (
                            ""
                        )}
                        {afterDecimal ? <span className="store-product-price-after-decimal">{afterDecimal}</span> : ""}
                    </div>
                    {currentPriceAmt !== price && price ? (
                        <div className="store-product-price original-price">
                            <small>
                                {" "}
                                <s> {regularPrice}</s>
                            </small>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
    };

    renderName = () => {
        const lan = helper.isString(this.props.lan) ? this.props.lan : "en";
        const isChinese = lan.includes("zh");
        return (
            <div
                style={{
                    wordBreak: isChinese ? "normal" : "keep-all",
                }}
                className="poster-dialog-name"
            >
                {this.getName()}
            </div>
        );
    };

    renderQRCode = () => {
        return (
            <QRCode
                value={this.state.qrUrl}
                logoImage={helper.getImageUrl(this.getStoreLogo())}
                size={QRCODE_SIZE}
                enableCORS={true}
            />
        );
    };

    renderPosterContent = () => {
        return (
            <div id={this.getContentId()} className="poster-dialog-content">
                {this.renderCustomerInfo()}
                <div className="poster-dialog-actual-content">
                    {this.renderImage()}
                    {this.renderPrice()}
                    <div className="poster-dialog-name-qr-code">
                        {this.renderName()}
                        {this.renderQRCode()}
                    </div>
                </div>
            </div>
        );
    };

    renderQualitySelect = () => {
        return (
            <div className="poster-dialog-image-quality-select-wrapper">
                <FormattedMessage id="image_quality" />
                <Select
                    className="poster-dialog-image-quality-select"
                    size="small"
                    onChange={(value) => {
                        this.setState({
                            scale: value,
                            loaded: false,
                        });
                        this.covertImage(false, false, value);
                    }}
                    value={this.state.scale}
                >
                    {Object.keys(DOWNLOAD_IMAGES_QUALITY_OPTIONS).map((key) => (
                        <Option key={key} value={DOWNLOAD_IMAGES_QUALITY_OPTIONS[key]}>
                            <FormattedMessage id={key ?? " "} />
                        </Option>
                    ))}
                </Select>
            </div>
        );
    };

    renderDownloadText = () => {
        if (this.state.loaded)
            return (
                <div onClick={() => this.downloadImage()} className="download-image-text">
                    <FormattedMessage id="download_poster" />
                </div>
            );
    };

    renderPosterImage = () => {
        const name = this.getDownloadName() + ".png";
        return (
            <div className="poster-converted-image-wrapper">
                <img
                    alt={name}
                    download={name}
                    name={name}
                    className="poster-converted-image"
                    src={this.state.posterUrl}
                />
            </div>
        );
    };

    renderRenderingSkeleton = () => {
        if (!this.state.loaded)
            return (
                <div className="poster-dialog-rendering-skeleton">
                    <Skeleton active={true} paragraph={false} className="poster-dialog-rendering-skeleton-item" />
                    <Skeleton.Image className="poster-dialog-rendering-skeleton-item" />
                </div>
            );
    };

    render() {
        return (
            <Modal
                width={350}
                bodyStyle={{ padding: 0 }}
                title={<FormattedMessage id="poster" />}
                onCancel={this.closeDialog}
                visible={this.shouldOpen()}
                footer={null}
            >
                <div className="poster-dialog">
                    {this.renderQualitySelect()}
                    <div className="poster-dialog-content-without-select">
                        {this.renderRenderingSkeleton()}
                        <div className="poster-poster-image-content" id="canvas-poster">
                            {this.renderPosterContent()}
                        </div>
                        {this.renderDownloadText()}
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "settings.lan", "en"),
    user: _.get(state, "user", {}),
    refCode: state?.stores?.refCode,
    store: _.get(state, "store", {}),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StoreQrDialog));
