import AppModal from "components/app-modal";
import DeliveryRequestPage from "pages/delivery_request";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import Preloader from "../components/preloader";
import useNotifications from "../hooks/useNotifications";
import { PermissionFeatures } from "../hooks/usePermission";
import locale from "../locale";
import NotFoundPage from "../pages/404-page";
import AdminUsers from "../pages/admin-users";
import CategoriesPage from "../pages/categories";
import AddEditCategory from "../pages/categories/pages/addEditCategory";
import CreateOrder from "../pages/create-order";
import Customers from "../pages/customers";
import CustomerDetail from "../pages/customers/_components/customer-detail-page";
import CustomersNew from "../pages/customers/_components/new-customer-page";
import Dashboard from "../pages/dashboard";
import Forgot from "../pages/forgot";
import NewPassword from "../pages/forgot/new-password";
import GiftCardPage from "../pages/gift-card";
import GiftCardAccountPage from "../pages/gift-card-account";
import AddEditGiftCard from "../pages/gift-card/pages/addEditGiftCard";
import LiveOrdersPage from "../pages/liveorder";
import Login from "../pages/login";
import OrderDetailsPage from "../pages/order-details";
import OrderHistoryPage from "../pages/order-history";
import PasswordReset from "../pages/password-reset";
import ProductsPage from "../pages/products";
import AddEditProduct from "../pages/products/pages/addEditProduct";
import PublishManager from "../pages/publish";
import QuotesPage from "../pages/quotes/";
import ReferralsPage from "../pages/referrals";
import Reviews from "../pages/reviews";
import SalesReport from "../pages/sales-report";
import Settings from "../pages/settings";
import AutoAcceptOrderSettings from "../pages/settings/pages/auto-accept-order";
import AvailabilitySettings from "../pages/settings/pages/availability-settings";
import BusinessHours from "../pages/settings/pages/business-hours";
import CheckoutSettings from "../pages/settings/pages/checkout-settings";
import ContentPages from "../pages/settings/pages/content-pages";
import AddEditContentPage from "../pages/settings/pages/content-pages/pages/add-edit-content-page";
import DiscountOptions from "../pages/settings/pages/discounts";
import DisplayOptionSettings from "../pages/settings/pages/display-options";
import GeneralSettings from "../pages/settings/pages/general";
import GenerateQrSettings from "../pages/settings/pages/generateqr-settings";
import ManagedStores from "../pages/settings/pages/managed-stores";
import MenuSettings from "../pages/settings/pages/menu-settings";
import NotificationSettings from "../pages/settings/pages/notifications";
import PaymentOptionSettings from "../pages/settings/pages/payment-options";
import PickupDeliverySettings from "../pages/settings/pages/pickup-delivery";
import SettintsProxy from "../pages/settings/pages/proxy";
import PublicNoticeSettings from "../pages/settings/pages/public-notice";
import ReferralSettings from "../pages/settings/pages/referral-settings";
import ReviewBooster from "../pages/settings/pages/review-booster-setting";
import RewardPointsSettings from "../pages/settings/pages/reward-points-settings";
import SelfServeSettings from "../pages/settings/pages/self-serve";
import ShippingSettings from "../pages/settings/pages/shipping-settings";
import SurchargeSettings from "../pages/settings/pages/surcharge";
import TipsSettings from "../pages/settings/pages/tips-settings";
import Signup from "../pages/signup";
import StoreSignUpPage from "../pages/store-sign-up";
import StorePendingReview from "../pages/store-sign-up/pending-review";
import CustomerStoreSwitch from "../pages/store-switch";
import NewStoreManagement from "../pages/super-admin/new-store-management";
import StoreSwitch from "../pages/super-admin/store-switch";
import history from "./history";
import { RootState } from "./reducer";
import "./styles/bootstrap.scss";

export const routes = [
    {
        path: "/",
        Page: Login,
    },
    {
        path: "/signup",
        Page: Signup,
    },
    {
        path: "/forgot",
        Page: Forgot,
    },
    {
        path: "/forgot/new-password",
        Page: NewPassword,
    },
    {
        path: "/password-reset",
        Page: PasswordReset,
    },
    {
        path: "/storeSignUp",
        Page: StoreSignUpPage,
    },
    {
        path: "/storePendingReview",
        Page: StorePendingReview,
    },
    {
        path: "/dashboard",
        Page: Dashboard,
    },
    {
        path: "/salesReport",
        Page: SalesReport,
    },
    {
        path: "/settings",
        Page: Settings,
    },
    {
        path: "/settings/proxy",
        Page: SettintsProxy,
    },
    {
        path: "/settings/general",
        Page: GeneralSettings,
    },
    {
        path: "/settings/publicNotice",
        Page: PublicNoticeSettings,
    },
    {
        path: "/settings/displayOptions",
        Page: DisplayOptionSettings,
    },
    {
        index: PermissionFeatures.SettingPayment,
        path: "/settings/paymentOptions",
        Page: PaymentOptionSettings,
    },
    {
        index: PermissionFeatures.CustomerReview,
        path: "/reviews",
        Page: Reviews,
    },
    {
        path: "/settings/checkout",
        Page: CheckoutSettings,
    },
    {
        index: PermissionFeatures.SettingTips,
        path: "/settings/tipsSettings",
        Page: TipsSettings,
    },
    {
        index: PermissionFeatures.SettingTips,
        path: "/settings/shipping",
        Page: ShippingSettings,
    },
    {
        index: PermissionFeatures.SettingItemAvailability,
        path: "/settings/availability",
        Page: AvailabilitySettings,
    },
    {
        index: PermissionFeatures.SettingHours,
        path: "/settings/businessHours",
        Page: BusinessHours,
    },
    {
        index: PermissionFeatures.SettingPickupDelivery,
        path: "/settings/pickupDelivery",
        Page: PickupDeliverySettings,
    },
    {
        index: PermissionFeatures.Customer,
        path: "/customers",
        Page: Customers,
    },
    {
        index: PermissionFeatures.Customer,
        path: "/customers/new",
        Page: CustomersNew,
    },
    {
        index: PermissionFeatures.Customer,
        path: "/customers/customer/:cid",
        Page: CustomerDetail,
    },
    {
        path: "/settings/selfServe",
        Page: SelfServeSettings,
    },
    {
        path: "/settings/generateQr",
        Page: GenerateQrSettings,
    },
    {
        index: PermissionFeatures.SettingDiscount,
        path: "/settings/discountOptions",
        Page: DiscountOptions,
    },
    {
        index: PermissionFeatures.SettingSurcharge,
        path: "/settings/surcharge",
        Page: SurchargeSettings,
    },
    {
        index: PermissionFeatures.SettingMenu,
        path: "/settings/menu",
        Page: MenuSettings,
    },
    {
        index: PermissionFeatures.SettingNotification,
        path: "/settings/notifications",
        Page: NotificationSettings,
    },
    {
        index: PermissionFeatures.SettingDiscount,
        path: "/settings/discount",
        Page: PublishManager,
    },
    {
        index: PermissionFeatures.SettingDiscount,
        path: "/settings/referrals",
        Page: ReferralSettings,
    },
    {
        index: PermissionFeatures.SettingManagedStores,
        path: "/settings/managedStores",
        Page: ManagedStores,
    },
    {
        path: "/settings/autoAcceptOrder",
        Page: AutoAcceptOrderSettings,
    },
    {
        path: "/settings/contentPages",
        Page: ContentPages,
    },
    {
        path: "/settings/contentPages/addEditContentPage",
        Page: AddEditContentPage,
    },
    {
        path: "/settings/contentPages/addEditContentPage/:id",
        Page: AddEditContentPage,
    },
    {
        path: "/settings/rewardPoints",
        Page: RewardPointsSettings,
    },
    {
        path: "/settings/reviewBooster",
        Page: ReviewBooster,
    },
    {
        index: PermissionFeatures.CategoryManagement,
        path: "/categories",
        Page: CategoriesPage,
    },
    {
        index: PermissionFeatures.CategoryManagement,
        path: "/categories/addEditCategories",
        Page: AddEditCategory,
    },
    {
        index: PermissionFeatures.CategoryManagement,
        path: "/categories/addEditCategories/:catId",
        Page: AddEditCategory,
    },
    {
        index: PermissionFeatures.Publish,
        path: "/publish",
        Page: PublishManager,
    },
    {
        index: PermissionFeatures.AdminUsers,
        path: "/adminUsers",
        Page: AdminUsers,
    },
    {
        index: PermissionFeatures.SettingSwitchStore,
        path: "/storeSwitch",
        Page: CustomerStoreSwitch,
    },
    {
        path: "/superAdmin/storeSwitch",
        Page: StoreSwitch,
    },
    {
        path: "/superAdmin/newStoreManagement",
        Page: NewStoreManagement,
    },
    {
        index: PermissionFeatures.ProductManagement,
        path: "/products",
        Page: ProductsPage,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCards",
        Page: GiftCardPage,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCardAccount",
        Page: GiftCardAccountPage,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCards/addEditGiftCard",
        Page: AddEditGiftCard,
    },
    {
        index: PermissionFeatures.GiftcardManagement,
        path: "/giftCards/addEditGiftCard/:pid",
        Page: AddEditGiftCard,
    },
    {
        index: PermissionFeatures.ProductManagement,
        path: "/products/addEditItem",
        Page: AddEditProduct,
    },
    {
        index: PermissionFeatures.ProductManagement,
        path: "/products/addEditItem/:pid",
        Page: AddEditProduct,
    },
    {
        index: PermissionFeatures.LiveOrder,
        path: "/liveOrders",
        Page: LiveOrdersPage,
    },
    {
        index: PermissionFeatures.OrderHistory,
        path: "/orders",
        Page: OrderHistoryPage,
    },
    {
        index: PermissionFeatures.OrderHistory,
        path: "/create_order",
        Page: CreateOrder,
    },
    {
        path: "/referrals",
        Page: ReferralsPage,
    },
    {
        index: PermissionFeatures.OrderHistory,
        path: "/orderDetails",
        Page: OrderDetailsPage,
    },
    {
        path: "/quotes",
        Page: QuotesPage,
    },
    {
        path: "/local_delivery",
        Page: DeliveryRequestPage,
    },
    {
        path: "*",
        Page: NotFoundPage,
    },
];

function App(): JSX.Element {
    useNotifications();
    const lan = useSelector((state: RootState) => state?.setting?.lan ?? "en");
    return (
        // @ts-ignore
        <Router history={history}>
            <IntlProvider locale={lan} messages={locale.getIntlMessages(lan)}>
                <Switch>
                    {routes.map((route) => {
                        const { Page } = route;
                        return (
                            <Route
                                render={() => (
                                    <Preloader route={route}>
                                        <Page />
                                    </Preloader>
                                )}
                                key={route.path}
                                path={route.path}
                                exact={true}
                            />
                        );
                    })}
                </Switch>
                <AppModal />
            </IntlProvider>
        </Router>
    );
}

export default App;
