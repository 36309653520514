// @ts-nocheck
import { Button, Input, Select, Switch, Checkbox } from "antd";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";
import {
    testLocalDeliveryConnection,
    updateStoreDetails,
    testDoordashDeliveryConnection,
} from "../../../../../../services/store";
import { setThirdPartyDeliveryState } from "../../../../../../slices/store";
import { isBool, switchNum } from "../../../../../../util/helper";
import { getIntl } from "../../../../../../locale";
import { toast } from "react-toastify";
import { createMessage } from "../../../../../../components/message";
import { useState } from "react";
import { MANDATORY_FIELDS_DISPLAY } from "../../constants";

const ThirdPartyDelivery = (): JSX.Element | null => {
    const dispatch = useDispatch();

    const records = useSelector((state: RootState) => state.store?.records);

    //get third party delivery details
    const deliveryDetails = useSelector((state: RootState) => state.store?.storeThirdPartyDelivery);

    //get deliveryxp from third party delivery details
    const deliveryxp_deliveryDetails = deliveryDetails?.deliveryxp;

    //infomation inside deliveryxp
    const deliveryxp_api_key = deliveryxp_deliveryDetails?.api_key;
    const deliveryxp_is_production = deliveryxp_deliveryDetails?.is_production;
    const deliveryxp_store_id = deliveryxp_deliveryDetails?.store_id;
    const deliveryxp_store_name = deliveryxp_deliveryDetails?.store_name;
    const deliveryxp_is_enabled = deliveryxp_deliveryDetails?.is_enabled;

    //get doordash info from third party delivery details
    const doordash_deliveryDetails = deliveryDetails?.doordash;
    //infomation inside doordash
    const doordash_is_enabled = doordash_deliveryDetails?.is_enabled;
    const doordash_signing_secret = doordash_deliveryDetails?.signing_secret;
    const doordash_key_id = doordash_deliveryDetails?.key_id;
    const doordash_dev_id = doordash_deliveryDetails?.developer_id;
    const doordash_is_production = doordash_deliveryDetails?.is_production;
    const doordash_item_name = doordash_deliveryDetails?.default_item_name;
    const doordash_order_value = doordash_deliveryDetails?.default_order_value;
    const doordash_tip_amount = doordash_deliveryDetails?.default_tip_amount;
    const doordash_pick_up_instructions = doordash_deliveryDetails?.pick_up_instructions;
    const doordash_contains_alcohol = doordash_deliveryDetails?.contains_alcohol;
    const doordash_timeslot_gap = doordash_deliveryDetails?.timeslot_gap;
    const doordash_timeslot_delta = doordash_deliveryDetails?.timeslot_delta;
    const doordash_default_pick_up_delay_time = doordash_deliveryDetails?.default_pick_up_delay_time;
    const doordash_pre_order_days = doordash_deliveryDetails?.pre_order_days;
    const doordash_delivery_fee_credit_limit = doordash_deliveryDetails?.delivery_fee_credit_limit;
    const doordash_should_send_notifications = doordash_deliveryDetails?.should_send_notifications;
    const doordash_delivery_fee_markup = doordash_deliveryDetails?.delivery_fee_markup;

    const create_delivery_task = records?.store_flg.split(",");
    const connected = deliveryDetails?.connected;
    const VIEW_DOORDASH_TAB = 1;
    const VIEW_DELIVERYXP_TAB = 0;
    const [thirdPartyOption, setThirdPartyOption] = useState(
        doordash_is_enabled == 1 ? VIEW_DOORDASH_TAB : VIEW_DELIVERYXP_TAB
    );
    const roles = useSelector((state: RootState) => state?.user?.roles);
    const TIMESLOTS = [0, 10, 15, 30, 60, 120, 240, 300, 360, 420];
    const TIMESLOTS_DISPLAY = ["0 min", "10 min", "15 min", "30 min", "60 min", "2h", "3h", "4h", "6h", "7h"];
    const TIME_GAP = [15, 30, 60];
    const TIME_GAP_DISPLAY = ["15 min", "30 min", "60 min"];
    const PICKUP_DELAY_TIME = [0, 10, 15, 30, 60, 120];
    const PICKUP_DELAY_TIME_DISPLAY = ["0 min", "10 min", "15 min", "30 min", "60 min", "2h"];
    const DEFAULT_ITEM_ARR = [
        "Food",
        "Grocery",
        "Flower",
        "Cake",
        "electronic_item",
        "Appliance",
        "Furniture",
        "Document",
        "Other",
    ];
    const MANDATORY_FIELDS_ARR = _.get(records, "3rd_party_local_delivery_setting.0.mandatory_fields");
    const hasAddress = MANDATORY_FIELDS_ARR.includes("address")
        ? MANDATORY_FIELDS_ARR
        : [...MANDATORY_FIELDS_ARR, "address"];

    const setState = (name: string, value: any) => {
        dispatch(setThirdPartyDeliveryState({ name, value }));
    };

    const [mandatory_fields, setMandatory_fields] = useState(hasAddress);
    const handleMandatoryFields = (name: string, isChecked: boolean) => {
        const res = isChecked ? [...mandatory_fields, name] : mandatory_fields.filter((item) => item !== name);
        setMandatory_fields(res);
        return setThirdPartyDeliveryState({ name: "doordash.mandatory_fields", value: res });
    };

    const onSave = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!doordash_is_enabled && !deliveryxp_is_enabled) {
            const intl = getIntl();
            const message = "both_disabled_error";
            toast(createMessage(intl.formatMessage({ id: message })));
        } else {
            const reqBody = structureAPIPayload();
            dispatch(
                updateStoreDetails({
                    "3rd_party_local_delivery_setting": Object.values(reqBody),
                    store_flg: create_delivery_task.join(","),
                })
            );
        }
    };

    const structureAPIPayload = () => {
        let reqBody = {};
        if (doordash_is_enabled && !deliveryxp_is_enabled) {
            setState("deliveryxp.is_enabled", 0);
            reqBody = {
                doordash: {
                    ...deliveryDetails?.doordash,
                },
            };
        } else if (deliveryxp_is_enabled && !doordash_is_enabled) {
            setState("doordash.is_enabled", 0);
            reqBody = {
                deliveryxp: {
                    ...deliveryDetails?.deliveryxp,
                },
            };
        }

        return reqBody;
    };

    const getInputOption = (
        title: string,
        name: string,
        value: any,
        className: string,
        disabled = false,
        type = "any",
        prefix?: string
    ) => (
        <div className={className}>
            <div className="d-flex justify-content-start align-items-center">
                <div className="local-title mr-2">
                    <FormattedMessage id={title} />
                </div>
                <Input
                    type={type}
                    disabled={disabled}
                    className="ml-3 local-input w-100px"
                    value={value}
                    onChange={(e: any) => {
                        setState(name, e.target.value);
                    }}
                    prefix={prefix}
                />
            </div>
        </div>
    );

    const getSwitchOption = (title: string, name: string, value: any, onChange?: (e: boolean) => void) => (
        <div className="white-layered-block w50 content-center">
            <div className="d-flex justify-content-start align-items-center">
                <div className="local-switch mr-2">
                    <FormattedMessage id={title} />
                </div>
                <Switch
                    className="ml-2"
                    checked={isBool(value)}
                    onChange={(e) => {
                        if (onChange) {
                            onChange?.(e);
                        } else {
                            setState(name, switchNum(value));
                        }
                    }}
                />
            </div>
        </div>
    );

    const getEnableSwitchOption = (title: string, name: string, value: any) => {
        let isDisabled = false;
        //if deliveryxp is_enable === 1 then disable doordash_is_enabled button
        if (name === "doordash.is_enabled" && deliveryxp_is_enabled == 1 && doordash_is_enabled != 1) {
            isDisabled = true;
            //if doordash is_enable === 1 then disable deliveryxp_is_enabled button
        } else if (name === "deliveryxp.is_enabled" && doordash_is_enabled == 1 && deliveryxp_is_enabled != 1) {
            isDisabled = true;
            //if both doordash and deliveryxp is_enable === 1 then disable deliveryxp is_enable and set doordash.is_enable = 1
        } else if (doordash_is_enabled == 1 && deliveryxp_is_enabled == 1) {
            setState("doordash.is_enabled", 1);
            setState("deliveryxp.is_enabled", 0);
        }
        return (
            <div className="white-layered-block w50">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="local-switch mr-2">
                        <FormattedMessage id={title} />
                    </div>
                    <Switch
                        className="ml-2"
                        checked={isBool(value)}
                        onChange={() => {
                            setState(name, switchNum(value));
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {isDisabled && <FormattedMessage id="third_party_delivery_error" />}
            </div>
        );
    };

    const serviceProviderOption = () => {
        const intl = useIntl();
        const options = ["goopter_delivery", "doordash_delivery"];
        return (
            <div className="white-layered-block w100">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="local-switch mr-2">
                        <FormattedMessage id="service_provider" />
                    </div>
                    <div className=" d-flex flex-column justify-content-start align-items-start">
                        <Select
                            className="ml-3 local-input"
                            value={intl.formatMessage({ id: options[thirdPartyOption] })}
                            defaultValue={options[0]}
                            onChange={(e) => {
                                dispatch(setThirdPartyDeliveryState({ name: "connected", value: false }));
                                setThirdPartyOption(e);
                            }}
                        >
                            <Select.Option key={0} value={0}>
                                <FormattedMessage id={options[0]} />
                            </Select.Option>
                            <Select.Option key={1} value={1}>
                                <FormattedMessage id={options[1]} />
                            </Select.Option>
                        </Select>
                    </div>
                </div>
            </div>
        );
    };

    const getDropDownOption = (
        title: string,
        name: string,
        arr: number[],
        value: any,
        display: string[],
        className?: string,
        desc: string
    ) => {
        const index = arr.indexOf(value);
        return (
            <div className={className}>
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div className="local-title mr-2">
                        <FormattedMessage id={title} />
                    </div>
                    <Select
                        className="ml-3 local-input w-100px"
                        value={display[index]}
                        onChange={(e) => {
                            dispatch(setThirdPartyDeliveryState({ name: name, value: arr[e] }));
                        }}
                    >
                        {display.map((e, i) => {
                            return <Select.Option key={i}>{e}</Select.Option>;
                        })}
                    </Select>
                </div>
                {desc && (
                    <div style={{ marginTop: "10px", marginBottom: "0px", color: "#9d9e9e" }}>
                        <p className="ant-descriptions-description">
                            <FormattedMessage id={desc ?? " "} />
                        </p>
                    </div>
                )}
            </div>
        );
    };

    const getCheckboxOption = () => {
        return (
            <div className="white-layered-block w100">
                <div className="d-flex flex-row justify-content-start align-items-center">
                    <div className="local-title mr-2" style={{ display: "flex", justifyContent: "space-around" }}>
                        {MANDATORY_FIELDS_DISPLAY.map(({ name, disable, type }) => {
                            switch (type) {
                                case "TEXT":
                                    return (
                                        <span key={name} style={{ paddingRight: "20px" }} className="local-title mr-2">
                                            <FormattedMessage id={name} />
                                        </span>
                                    );
                                case "CHECKBOX":
                                    return (
                                        <Checkbox
                                            onChange={(e) => dispatch(handleMandatoryFields(name, e.target.checked))}
                                            defaultChecked={mandatory_fields.includes(name)}
                                            disabled={disable}
                                            key={name}
                                        >
                                            <FormattedMessage id={name} />
                                        </Checkbox>
                                    );
                                default:
                                    <></>;
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const renderOptions = (): JSX.Element | null => {
        const deliveryxpFormCase = 0;
        const doordashFormCase = 1;
        switch (thirdPartyOption) {
            case deliveryxpFormCase:
                return (
                    <div className="d-flex flex-wrap">
                        {getEnableSwitchOption("enable", "deliveryxp.is_enabled", deliveryxp_is_enabled)}
                        {getInputOption(
                            "store_name",
                            "deliveryxp.store_name",
                            deliveryxp_store_name,
                            "white-layered-block w50",
                            true
                        )}
                        {getInputOption("api_key", "deliveryxp.api_key", deliveryxp_api_key, "white-layered-block w50")}
                        {getInputOption(
                            "store_id",
                            "deliveryxp.store_id",
                            deliveryxp_store_id,
                            "white-layered-block w50"
                        )}
                        {getSwitchOption("live_mode", "deliveryxp.is_production", deliveryxp_is_production)}
                    </div>
                );
            case doordashFormCase:
                return (
                    <div className="d-flex flex-wrap">
                        {getEnableSwitchOption("enable", "doordash.is_enabled", doordash_is_enabled)}
                        {getInputOption("dev_id", "doordash.developer_id", doordash_dev_id, "white-layered-block w50")}
                        {getInputOption("key_id", "doordash.key_id", doordash_key_id, "white-layered-block w50")}
                        {getInputOption(
                            "signing_secret",
                            "doordash.signing_secret",
                            doordash_signing_secret,
                            "white-layered-block w50"
                        )}
                        {getDropDownOption(
                            "default_item_name",
                            "doordash.default_item_name",
                            DEFAULT_ITEM_ARR,
                            doordash_item_name.charAt(0).toUpperCase() + doordash_item_name.slice(1),
                            DEFAULT_ITEM_ARR,
                            "white-layered-block w50"
                        )}
                        {getInputOption(
                            "pre_order_days",
                            "doordash.pre_order_days",
                            doordash_pre_order_days,
                            "white-layered-block w50",
                            false,
                            "number"
                        )}
                        {getSwitchOption("contains_alcohol", "doordash.contains_alcohol", doordash_contains_alcohol)}
                        {getInputOption(
                            "default_tip_amount",
                            "doordash.default_tip_amount",
                            doordash_tip_amount,
                            "white-layered-block w50",
                            false,
                            "number",
                            "$"
                        )}
                        {getInputOption(
                            "default_order_value",
                            "doordash.default_order_value",
                            doordash_order_value,
                            "white-layered-block w100",
                            false,
                            "number",
                            "$"
                        )}
                        {getDropDownOption(
                            "default_pick_up_delay_time",
                            "doordash.default_pick_up_delay_time",
                            PICKUP_DELAY_TIME,
                            doordash_default_pick_up_delay_time,
                            PICKUP_DELAY_TIME_DISPLAY,
                            "white-layered-block w100",
                            "pickup_delay_time_hint"
                        )}
                        {getDropDownOption(
                            "timeslot_gap_title",
                            "doordash.timeslot_gap",
                            TIME_GAP,
                            doordash_timeslot_gap,
                            TIME_GAP_DISPLAY,
                            "white-layered-block w100",
                            "timeslot_gap_hints"
                        )}
                        {getDropDownOption(
                            "timeslot_delta_title",
                            "doordash.timeslot_delta",
                            TIMESLOTS,
                            doordash_timeslot_delta,
                            TIMESLOTS_DISPLAY,
                            "white-layered-block w100",
                            "timeslot_delta_hints"
                        )}

                        {getInputOption(
                            "default_pick_up_instructions",
                            "doordash.pick_up_instructions",
                            doordash_pick_up_instructions,
                            "white-layered-block w50"
                        )}
                        {getSwitchOption("live_mode", "doordash.is_production", doordash_is_production)}
                        {roles.includes(999) &&
                            getInputOption(
                                "delivery_fee_credit_limit",
                                "doordash.delivery_fee_credit_limit",
                                doordash_delivery_fee_credit_limit,
                                "white-layered-block w50",
                                false,
                                "number",
                                "$"
                            )}
                        {roles.includes(999) &&
                            getInputOption(
                                "delivery_fee_markup",
                                "doordash.delivery_fee_markup",
                                doordash_delivery_fee_markup,
                                "white-layered-block w50",
                                false,
                                "number",
                                "$"
                            )}
                        {getCheckboxOption()}
                        {getSwitchOption(
                            "doordash_should_send_notifications",
                            "doordash.should_send_notifications",
                            doordash_should_send_notifications
                        )}
                    </div>
                );
            default:
                return null;
        }
    };
    return !_.isEmpty(deliveryDetails) ? (
        <div className="third-party-delivery-page">
            {serviceProviderOption()}
            {renderOptions()}
            <div className="setting-actions d-flex mr-2 w100 mb-3">
                <Button
                    size="large"
                    onClick={() => {
                        if (thirdPartyOption == 0) {
                            const store_id = deliveryxp_store_id;
                            const api_key = deliveryxp_api_key;
                            dispatch(testLocalDeliveryConnection({ store_id, api_key }));
                        } else if (thirdPartyOption == 1) {
                            dispatch(
                                testDoordashDeliveryConnection({
                                    doordash_dev_id,
                                    doordash_signing_secret,
                                    doordash_key_id,
                                })
                            );
                        }
                    }}
                >
                    <FormattedMessage id="test_connection" />
                </Button>
                <Button type="primary" size="large" disabled={!connected} onClick={() => onSave()} className="ml-3">
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        </div>
    ) : null;
};

export default ThirdPartyDelivery;
