import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import helper, { formatCurrency, formatPhone } from "util/helper";
import Actions from "./actions";
import moment from "moment";
import { setState } from "slices/quotes";
import { getCustomer } from "services/customers";
import { setState as setCreateOrderState } from "slices/create-order";
import { getQuoteDetail } from "services/quotes";
import config from "config";
import { DINE_IN, IN_STORE } from "app/constants";
import { RiGroupLine } from "react-icons/ri";
import history from "app/history";

const TIME_FORMAT = "YYYY-MM-DD HH:mm:dd";
const GRID_COLUMNS_FIELD_NAMES = {
    QUOTE_ID: "quote_id",
    DATE: "updated_at",
    CUSTOMER_NAME: "customer_firstname",
    ITEMS: "items",
    GRAND_TOTAL: "grandTotal",
    SHIPPING_METHOD: "shp_mtd",
    PARTY_SIZE: "party_size",
    ACTIONS: "actions",
};
const GRID_COLUMNS_WIDTHS = {
    QUOTE_ID_MIN_WIDTH: 60,
    QUOTE_ID_MAX_WIDTH: 100,
    DATE_MIN_WIDTH: 70,
    DATE_MAX_WIDTH: 170,
    CUSTOMER_NAME_MIN_WIDTH: 70,
    CUSTOMER_NAME_MAX_WIDTH: 150,
    ITEMS_MIN_WIDTH: 120,
    GRANDTOTAL_MIN_WIDTH: 40,
    GRANDTOTAL_MAX_WIDTH: 100,
    STORE_TYPE_MIN_WIDTH: 40,
    STORE_TYPE_INSTORE_MAX_WIDTH: 80,
    STORE_TYPE_DINEIN_MAX_WIDTH: 180,
    PARTYSIZE_MIN_WIDTH: 40,
    PARTYSIZE_MAX_WIDTH: 110,
    ACTION_MIN_WIDTH: 30,
    ACTION_MAX_WIDTH: 80,
};

class Table extends Component<any, any> {
    gridApi: any;

    state = {};

    componentDidUpdate = (prevsProps: any) => {
        this.checkLoading();
        this.checkDataLoaded(prevsProps);
        if (this.gridApi && this.gridApi.getDisplayedRowCount() === 0) {
            this.gridApi.showNoRowsOverlay();
        }
    };

    str = (id: any) => this.props.intl.formatMessage({ id: id ?? " " });

    onSelectionChange = (event: any) => {
        this.props.setState({
            selected: event?.api?.getSelectedNodes().map((node: any) => node?.data?.quote_id),
        });
    };

    /**
     * Resize the width of the columns to fill the table when the data is first rendered
     *
     * @param params AgGrid params
     */
    onFirstDataRendered = (params: any) => {
        params.api.sizeColumnsToFit();
    };

    /**
     * When the table size is changed, resize the columns to fill the table
     * If the table size is changed such that the minimum width of each column
     * exceeds the table size, add a horizontal scroll bar to the table.
     *
     * @param params AgGrid params
     */
    onGridSizeChanged = (params: any) => {
        const gridWidth = document.getElementById("quotes-container")?.offsetWidth;
        if (!gridWidth) {
            return;
        }
        const columnsToShow = [];
        const columnsToHide = [];
        let totalColsWidth = 0;
        const allColumns = params.columnApi.getAllColumns();
        for (let i = 0; i < allColumns.length; i++) {
            const column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }

        if (_.isEmpty(columnsToHide)) {
            params.api.sizeColumnsToFit();
        }
    };

    onGridReady = (params: any) => {
        params?.api?.sizeColumnsToFit?.();
        params?.api?.resetRowHeights();
        this.gridApi = params?.api;

        this.gridApi.getRenderedNodes().forEach((node: any) => {
            node.setSelected(this.props.selectedQuotes.includes(node.data.quote_id));
        });
    };

    getTableObject = () => ({
        columnDefs: [
            {
                field: GRID_COLUMNS_FIELD_NAMES.QUOTE_ID,
                headerName: this.str("id"),
                minWidth: GRID_COLUMNS_WIDTHS.QUOTE_ID_MIN_WIDTH,
                maxWidth: GRID_COLUMNS_WIDTHS.QUOTE_ID_MAX_WIDTH,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                sortable: true,
                unSortIcon: true,
                comparator: (a: number, b: number) => a - b,
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.DATE,
                headerName: this.str("last_updated"),
                minWidth: GRID_COLUMNS_WIDTHS.DATE_MIN_WIDTH,
                maxWidth: GRID_COLUMNS_WIDTHS.DATE_MAX_WIDTH,
                cellRenderer: "timeRender",
                sortable: true,
                unSortIcon: true,
                comparator: (a: any, b: any) => moment(a, TIME_FORMAT).valueOf() - moment(b, TIME_FORMAT).valueOf(),
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.CUSTOMER_NAME,
                headerName: this.str("name"),
                minWidth: GRID_COLUMNS_WIDTHS.CUSTOMER_NAME_MIN_WIDTH,
                maxWidth: GRID_COLUMNS_WIDTHS.CUSTOMER_NAME_MAX_WIDTH,
                sortable: true,
                unSortIcon: true,
                cellRenderer: "namePhoneNumberRenderer",
                comparator: (a: string, b: string) => String(a || "").localeCompare(String(b || "")),
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.SHIPPING_METHOD,
                headerName: this.str("type"),
                minWidth: GRID_COLUMNS_WIDTHS.STORE_TYPE_MIN_WIDTH,
                maxWidth: this.props.isDineIn
                    ? GRID_COLUMNS_WIDTHS.STORE_TYPE_DINEIN_MAX_WIDTH
                    : GRID_COLUMNS_WIDTHS.STORE_TYPE_INSTORE_MAX_WIDTH,
                sortable: true,
                unSortIcon: true,
                cellRenderer: "typeRenderer",
                comparator: (a: number, b: number) => a - b,
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.ITEMS,
                headerName: this.str("items"),
                cellRenderer: "itemsRenderer",
                minWidth: GRID_COLUMNS_WIDTHS.ITEMS_MIN_WIDTH,
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.GRAND_TOTAL,
                headerName: this.str("total"),
                minWidth: GRID_COLUMNS_WIDTHS.GRANDTOTAL_MIN_WIDTH,
                maxWidth: GRID_COLUMNS_WIDTHS.GRANDTOTAL_MAX_WIDTH,
                sortable: true,
                unSortIcon: true,
                type: "rightAligned",
                cellClass: "ag-right-aligned-cell",
                cellRenderer: "grandTotalRenderer",
                comparator: (a: number, b: number) => a - b,
            },
            {
                field: GRID_COLUMNS_FIELD_NAMES.ACTIONS,
                headerName: this.str("actions"),
                minWidth: GRID_COLUMNS_WIDTHS.ACTION_MIN_WIDTH,
                maxWidth: GRID_COLUMNS_WIDTHS.ACTION_MAX_WIDTH,
                cellRenderer: "actionsRenderer",
            },
        ],
        defaultColDef: {
            resizable: true,
            wrapText: true,
        },
        rowData: this.props.quotes,
        suppressCellSelection: true,
        frameworkComponents: {
            namePhoneNumberRenderer: (values: any) => {
                const data = values?.data;
                const firstName = data?.customer_firstname;
                const lastName = data?.customer_lastname;
                const fullName = `${firstName ? `${firstName} ` : ""}${lastName || ""}`;
                const phoneNumber = formatPhone(data?.customer_phone);
                return (
                    <div>
                        {fullName}
                        <br />
                        {phoneNumber}
                    </div>
                );
            },
            timeRender: (values: any) => {
                const formatTime = helper.utcToLocal(values.value, {
                    type: "calendar",
                    local: true,
                    shouldOmitToday: true,
                });
                return <div>{formatTime}</div>;
            },
            grandTotalRenderer: (values: any) => {
                return formatCurrency(values?.data?.g_total, this.props.storeCurrency);
            },
            typeRenderer: (values: any) => {
                const tableNo = values?.data?.table_no;
                const partySize = values?.data?.party_size;
                return (
                    <div className="quote-page-item-type">
                        {this.formatShippingMethod(values.data)}
                        {tableNo && this.props.isDineIn ? this.renderTableNo(tableNo) : null}
                        {partySize && this.props.isDineIn ? this.renderPartySize(partySize) : null}
                    </div>
                );
            },
            itemsRenderer: (values: any) => {
                const itemSummary = values?.data?.item_summary;
                return <div>{itemSummary}</div>;
            },
            actionsRenderer: (values: any) => <Actions {...values} />,
        },
        getRowHeight: (values: any) => {
            const itemEle = document.getElementById(`quote-page-item-${values?.data?.quote_id}`);
            return itemEle?.offsetHeight;
        },
        onCellClicked: (e: any) => {
            if (
                e.column.colId !== GRID_COLUMNS_FIELD_NAMES.QUOTE_ID &&
                e.column.colId !== GRID_COLUMNS_FIELD_NAMES.ACTIONS
            ) {
                const data = e.node.data;
                this.props.getCustomer({ customer_id: data?.customer_id });
                this.props.getQuoteDetail({
                    qid: data?.quote_id,
                    lan: this.props.lan,
                    callBack: () => {
                        helper.goToCreateOrderPage(window, history);
                    },
                });
            }
        },
        stopEditingWhenCellsLoseFocus: true,
        singleClickEdit: true,
        rowSelection: "multiple",
        onBodyScroll: () => {
            this.gridApi?.resetRowHeights();
        },
    });

    checkLoading = () => {
        if (this.props.gettingQuotes || this.props.gettingStore) {
            this.gridApi?.showLoadingOverlay();
        } else {
            this.gridApi?.hideOverlay();
        }
    };

    checkDataLoaded = (prevsProps: any) => {
        //after loading
        if (_.isEqual(this.props.quotes, prevsProps.quotes) && !_.isEmpty(this.props.quotes)) {
            setTimeout(() => {
                this.gridApi?.resetRowHeights();
            }, 200);
        }
    };

    formatShippingMethod = (data: any) => {
        let formattedShippingMethod: React.ReactNode = "";

        if (helper.isEatIn(data.shp_mtd)) {
            formattedShippingMethod = this.props.isDineIn ? (
                <FormattedMessage id={DINE_IN} />
            ) : (
                <FormattedMessage id={IN_STORE} />
            );
        } else {
            formattedShippingMethod = (
                <FormattedMessage
                    // @ts-ignore
                    id={config.SHIPPING_MAPPING_TO_TEXT[data.shp_mtd.toString()]}
                />
            );
        }
        return formattedShippingMethod;
    };

    renderTableNo = (tableNo: string) => {
        return " · #" + tableNo;
    };

    renderPartySize = (partySize: number) => {
        return (
            <>
                {" · "} <RiGroupLine className="quote-page-item-type-icon" size={18} /> {partySize}
            </>
        );
    };

    render() {
        return (
            <AgGridReact
                /* @ts-ignore */
                className="quotes-list-view-table"
                {...this.getTableObject()}
                suppressRowClickSelection={true}
                getRowNodeId={(row) => row.quote_id}
                immutableData
                onGridReady={this.onGridReady.bind(this)}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                onSelectionChanged={this.onSelectionChange}
                ref={this.props.agGridRef}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    gettingQuotes: _.get(state, "quotes.gettingQuotes", false),
    gettingStoreDetails: _.get(state, "store.loading", false),
    quotes: _.get(state, "quotes.quotes", false),
    filterValue: _.get(state, "quotes.filterValue", false),
    lan: state?.setting?.lan,
    selectedQuotes: _.get(state, "quotes.selected", []),
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
});

const mapDispatchToProps = {
    setState,
    getCustomer,
    setCreateOrderState,
    getQuoteDetail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Table)));
