import { Form, Input, Row, Col, Select, Checkbox } from "antd";
import "./new-customer.scss";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Languages, PhoneCodes } from "../../../../slices/setting";
import { PHONE_MAX_LEN } from "../../constants";

const { Option } = Select;

class AddressInfoForm extends Component {
    componentDidMount() {}

    str = (id, values) => this.props.intl.formatMessage({ id }, values);

    mapFormNameToTranslationId = (formName) => {
        switch (formName) {
            case "firstname":
                return "first_name";
            case "phone":
                return "customer_phone";
            default:
                return formName;
        }
    };

    getSharedFormItemProps = (name = " ") => {
        const inValid = _.get(this.getFormData(), `${name}Invalid`);
        return {
            validateStatus: inValid ? "error" : "success",
            help: inValid
                ? this.str("please_input_valid_x", { x: this.str(this.mapFormNameToTranslationId(name)) })
                : "",
        };
    };

    getSharedInputProps = (name) => {
        return {
            onChange: (e) => {
                const value = e?.target ? e?.target?.value : e;
                const formattedValue = name === "phone" ? value.replaceAll(/[^0-9]/g, "") : value;
                this.setCustomerInfoData({
                    [name]: formattedValue,
                });
            },
            onPaste: (e) => {
                const value = e?.target ? e?.target?.value : e;
                const formattedValue = name === "phone" ? value.replaceAll(/[^0-9]/g, "") : value;
                this.setCustomerInfoData({
                    [name]: formattedValue,
                });
            },
        };
    };

    getFormData = () => this.props.formState || {};

    getCustomerInfoData = (key) => _.get(this.getFormData(), `customerInfo.${key}`);

    setCustomerInfoData = (values) => {
        this.props.setFormState({
            customerInfo: {
                ...(this.getFormData().customerInfo || {}),
                ...values,
            },
        });
    };

    isPhoneInputValid = (e) => {
        if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "Backspace", "Delete"].includes(e.key)) {
            return true;
        } else if ((e.ctrlKey || e.metaKey) && (e.key === "v" || e.key === "c" || e.key === "x" || e.key === "z")) {
            return true;
        } else {
            return false;
        }
    };

    renderTitle = () => {
        return this.props.withoutTitle ? null : (
            <div className="new-customer-form-title">
                <FormattedMessage id="customer_info" />
            </div>
        );
    };

    renderNamesInput = () => {
        return (
            <div className="new-customer-form-row">
                <Row style={{ width: "100%" }} gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            style={{ width: "100%" }}
                            label={this.str("first_name")}
                            name="first name"
                            rules={[
                                {
                                    required: true,
                                    message: this.str("please_input_valid_x", {
                                        x: this.str(this.mapFormNameToTranslationId("firstname")),
                                    }),
                                },
                            ]}
                        >
                            <Input {...this.getSharedInputProps("firstname")} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item style={{ width: "100%" }} label={this.str("last_name")} name="last name">
                            <Input {...this.getSharedInputProps("lastname")} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    renderEmailInput = () => {
        return (
            <Form.Item
                label={this.str("email")}
                name="Email"
                rules={[
                    {
                        type: "email",
                        message: this.str("please_input_valid_x", {
                            x: this.str(this.mapFormNameToTranslationId("email")),
                        }),
                    },
                ]}
            >
                <Input {...this.getSharedInputProps("email")} />
            </Form.Item>
        );
    };

    renderPhoneInput = () => {
        //Country Code of the address selected in general settings
        const countryCode = this.props.state.store.records.store_addr.country_code;

        const prefixSelector = (
            <Form.Item noStyle>
                <Select
                    {...this.getSharedInputProps("country_code")}
                    style={{ width: 70 }}
                    //If no codes found for the country selected, default CA code
                    defaultValue={Object.keys(PhoneCodes).includes(countryCode) ? countryCode.toUpperCase() : "CA"}
                >
                    {Object.entries(PhoneCodes).map(([key, value]) => (
                        //Set EN to CA country code
                        <Option key={key} value={key === "EN" ? "CA" : key}>
                            {value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );

        return (
            <div className="new-customer-form-row">
                <Form.Item
                    style={{ width: "100%" }}
                    label={this.str("phone")}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/^[0-9]+$/),
                            message: this.str("please_input_valid_x", {
                                x: this.str(this.mapFormNameToTranslationId("phone")),
                            }),
                        },
                    ]}
                >
                    <Input
                        {...this.getSharedInputProps("phone")}
                        onKeyDown={(e) => !this.isPhoneInputValid(e) && e.preventDefault()}
                        addonBefore={prefixSelector}
                        maxLength={PHONE_MAX_LEN}
                    />
                </Form.Item>
            </div>
        );
    };

    renderCustomerSubcriberCheckbox = () => {
        return (
            <div className="new-customer-form-row mt-2">
                <Form.Item style={{ marginBottom: 0 }}>
                    <Checkbox
                        checked={this.getCustomerInfoData("subscribe_email")}
                        onChange={(e) => this.setCustomerInfoData({ subscribe_email: e.target.checked })}
                    />
                </Form.Item>
                <span className="subscribe-message">
                    <FormattedMessage id="subscribe_message" />
                </span>
            </div>
        );
    };

    renderLanguages = () => {
        const lansArray = Object.values(Languages);
        return (
            <div className="new-customer-form-row ">
                <Form.Item style={{ width: "100%" }} label={this.str("language")}>
                    <Select {...this.getSharedInputProps("language")}>
                        {lansArray.map((key) => (
                            <Option key={key} value={key}>
                                {<FormattedMessage id={key} />}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    };

    renderNote = () => {
        return (
            <Form.Item label={this.str("note")}>
                <Input {...this.getSharedInputProps("customer_note")} placeholder={this.str("note_place_holder")} />
            </Form.Item>
        );
    };

    renderTags = () => {
        return (
            <Form.Item label={this.str("tags")}>
                <Select {...this.getSharedInputProps("tags")} mode="tags" style={{ width: "100%" }} />
            </Form.Item>
        );
    };

    render() {
        return (
            <div className="customer-info-form">
                {this.renderTitle()}
                {this.renderNamesInput()}
                {this.renderEmailInput()}
                {this.renderPhoneInput()}
                {this.renderCustomerSubcriberCheckbox()}
                {this.renderLanguages()}
                {this.renderNote()}
                {this.renderTags()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lan: _.get(state, "setting.lan", "en"),
    state: state,
});

const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddressInfoForm));
