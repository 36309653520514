import React, { useEffect } from "react";
import { Tabs, Button, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import PersonalInformationTab from "./personal-information";
import PermitionRoleTab from "./permission-role";
import ObjectModel from "./../../../../util/models";
import AdminProfile from "../../../../util/models/admin";
import { RootState } from "../../../../app/reducer";
import { ROLE_ID } from "config/config.json";
import h from "util/helper";
import aH from "../../helper";
import { saveAdminProfile } from "../../../../services/admin-users";
import _ from "lodash";
import { toast } from "react-toastify";
import { createMessage } from "components/message";

import {
    setAccountModal,
    toggleAccountTab,
    makeAccessFlagsUsefull,
    setCurrentUser,
} from "../../../../slices/admin-users";

export enum AccountTabs {
    Personal = "personal_information",
    Permissions = "permission_role",
}

const tabs: any = [
    { key: AccountTabs.Personal, tab: PersonalInformationTab },
    { key: AccountTabs.Permissions, tab: PermitionRoleTab },
];

const ADMIN_USERS_FLAG_INDEX = 26;

const AdminAccount = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currUser = useSelector((state: RootState) => state.user);
    const user = useSelector((state: RootState) => state.adminUsers.user);
    const users = useSelector((state: RootState) => state.adminUsers.users);
    const modalType = useSelector((state: RootState) => state.adminUsers.modalType);
    const modalTypes = useSelector((state: RootState) => state.adminUsers.modalTypes);
    const flags = useSelector((state: RootState) => state.adminUsers.flags);
    const password = useSelector((state: RootState) => state.adminUsers.password);
    const selfEdit = useSelector((state: RootState) => state.adminUsers.selfEdit);
    const modalHeader = modalTypes[modalType];
    const activeAccountTab = useSelector((state: RootState) => state.adminUsers.activeAccountTab);
    const accountTabs = ["personal_information", "permission_role"];
    const tab: string = accountTabs[activeAccountTab];

    useEffect(() => {
        dispatch(makeAccessFlagsUsefull());
    }, [dispatch]);

    useEffect(() => {
        if (modalType === aH.ADMIN_PROFILE) {
            dispatch(setCurrentUser(users?.find((user: any) => user?.id === currUser?.id)));
        }
    }, [modalType, users, currUser, dispatch]);

    const checkFilled = (isNewAdmin: boolean) => {
        let errorMessage = undefined;
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        if (!user?.username) {
            errorMessage = "username";
        } else if (!user?.first_name) {
            errorMessage = "first_name";
        } else if (!user?.last_name) {
            errorMessage = "last_name";
        } else if (isNewAdmin && !password) {
            errorMessage = "password";
        }
        if (errorMessage) {
            Modal.error({
                title: intl.formatMessage({ id: "missing_x" }, { x: intl.formatMessage({ id: errorMessage }) }),
                content: req,
            });
            return false;
        } else {
            return true;
        }
    };

    const userPassLongEnough = () => {
        let errorField = undefined;
        let length = undefined;
        if (user?.username?.length < aH.MIN_USERNAME) {
            errorField = "username";
            length = aH.MIN_USERNAME;
        } else if (password?.length < aH.MIN_PASSWORD) {
            errorField = "password";
            length = aH.MIN_PASSWORD;
        }
        if (errorField) {
            Modal.error({
                title: intl.formatMessage(
                    { id: "x_field_not_long_enough" },
                    { x: intl.formatMessage({ id: errorField }) }
                ),
                content: intl.formatMessage({ id: "please_ensure_field_x_long" }, { x: length }),
            });
            return false;
        } else {
            return true;
        }
    };

    const saveAdminAccount = () => {
        const isNewAdmin = modalType === aH.ADD_ADMIN_USER ? true : false;
        let currUserAccessFlags = currUser.access?.split(",") ?? [""];
        currUserAccessFlags = currUserAccessFlags.map((flag: string) => flag.split(":")[0]);
        
        if (!_.isEmpty(currUserAccessFlags) && +currUserAccessFlags[ADMIN_USERS_FLAG_INDEX] === 2) {
            if (checkFilled(isNewAdmin) && userPassLongEnough()) {
                if (isNewAdmin) {
                    const acc = {
                        profile: {
                            ...user,
                            id: 0,
                            password,
                            roles: _.isEmpty(user.roles) ? [ROLE_ID.CASHIER] : user.roles,
                        },
                    };
                    acc.profile.access_flags = aH.permFlagsToString(flags);
                    dispatch(saveAdminProfile(acc));
                } else {
                    const tmpAccount = new ObjectModel(AdminProfile).convertToPostData(user);
                    if (!h.isSuperUser(currUser)) {
                        delete tmpAccount.profile.permission_level;
                    }
                    if (!selfEdit) {
                        tmpAccount.profile.access_flags = aH.permFlagsToString(flags);
                    } else {
                        delete tmpAccount.profile.access_flags;
                    }
                    dispatch(saveAdminProfile(tmpAccount));
                }
            }
        } else {
            toast(
                createMessage(
                    intl.formatMessage({ id: "no_admin_user_access" })
                )
            );
        }
    };

    const getDisableSave = () =>
        aH.getPermission(currUser, aH.ROLES_PERMISSIONS_SETTINGS) !== aH.READ_WRITE && tab === "permission_role";

    const renderActionButtons = (
        <Button
            className="mr-4 text-on-white-background"
            disabled={getDisableSave()}
            type="default"
            onClick={() => saveAdminAccount()}
        >
            Save
        </Button>
    );

    const renderAccountHeader = (
        <div className="adminHeader">
            <div className="closeBox pHover" onClick={() => dispatch(setAccountModal(false))}>
                <AiOutlineClose size="20" />
            </div>
            <div className="name">
                <FormattedMessage id={modalHeader} />
            </div>
            {renderActionButtons}
        </div>
    );

    const renderTabs = (tab: any) =>
        selfEdit && tab.key === AccountTabs.Permissions ? null : (
            <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                <tab.tab />
            </Tabs.TabPane>
        );

    const adminAccount = (
        <div className="admin-account-page w100">
            {renderAccountHeader}
            <Tabs
                type="card"
                activeKey={tab}
                defaultActiveKey={tab}
                className="m-3"
                onChange={() => dispatch(toggleAccountTab())}
                style={{ marginTop: 14 }}
            >
                {tabs?.map(renderTabs)}
            </Tabs>
        </div>
    );

    return adminAccount;
};

export default AdminAccount;
