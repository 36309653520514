export const copyDataToEmptyLanguageFieldsFormData = (params: {
    languageFieldNames: string[],
    delimiter?: string,
    formData: any, // ex. { name_en: __, name_zh: __, ... }
    displayLanguages: string[],
}) : Record<string,any> | any => {
    const { languageFieldNames, delimiter, formData, displayLanguages } = params;
    if (!formData) {
        return;
    }

    const DELIMITER = delimiter ?? "_";
    let firstFilledKey: any;
    let firstFilledValue: any;
    languageFieldNames.forEach((lanFieldName) => {
        firstFilledKey =
            displayLanguages.find((displayLanguage) => formData[`${lanFieldName}${DELIMITER}${displayLanguage}`]) ?? "";
        firstFilledValue = firstFilledKey ? formData[`${lanFieldName}${DELIMITER}${firstFilledKey}`] : "";

        displayLanguages.forEach((displayLanguage) => {
            if (!formData[`${lanFieldName}${DELIMITER}${displayLanguage}`]) {
                formData[`${lanFieldName}${DELIMITER}${displayLanguage}`] = firstFilledValue;
            }
        });
    });
};

export const copyDataToEmptyLanguageFieldsSingleField = (params: {
    values: any, // { en: __, zh: __ }
    displayLanguages: string[],
}) : Record<string,any> | any => {
    const { values, displayLanguages } = params;
    if (!values) {
        return;
    }

    const firstFilledKey = displayLanguages.find((displayLanguage: any) => values?.[displayLanguage]) ?? "";
    const firstFilledValue = firstFilledKey ? values[firstFilledKey] : "";

    displayLanguages.forEach((lan) => {
        if (!values[lan]) {
            values[lan] = firstFilledValue;
        }
    });
};
