import React from "react";
import { Select, Switch, DatePicker, Tooltip, Button } from "antd";
import moment from "moment";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { getIntl } from "../../../../../../locale";
import { FormattedMessage } from "react-intl";

function emptyFunction() {
    // does nothing
}

export interface SelectOption {
    value: string | number;
    label: string;
}

const renReq = () => <div style={{ display: "inline-block", color: "red" }}>*</div>;

export const renderToolTip = (tip: string): JSX.Element => {
    const intl = getIntl();
    return (
        <Tooltip title={intl.formatMessage({ id: tip ?? " " })} overlayClassName="tooltip-class">
            <HiOutlineInformationCircle />
        </Tooltip>
    );
};

export const renderHalfNumInput = (
    title: string,
    name: string,
    value: number | any,
    onChange: any = emptyFunction,
    req?: boolean,
    tip?: string,
    summary?: boolean
): JSX.Element => (
    <div
        className={`${
            summary ? "halfish-sum-size" : "halfish-size"
        } d-flex flex-column justify-content-start align-items-start`}
    >
        <div className="payment-subtitle d-flex">
            <FormattedMessage id={title} />
            {tip ? renderToolTip(tip) : null}
            {req && renReq()}
        </div>
        <input
            type="number"
            className="custom-input-textfield halfish-size-input m-0"
            value={`${value}`}
            onChange={(e) => onChange(name, parseInt(e.target.value))}
            min="0"
        />
    </div>
);

export const renderHalfTextInput = (opts: {
    title: string,
    name: string,
    value: string | any,
    onChange?: any,
    req?: boolean,
    disabled?: boolean,
    summary?: boolean,
    onBlur?: any,
    errorMessage?: string,
}): JSX.Element => {
    const { title, name, value, onChange, req, disabled, summary, onBlur, errorMessage } = opts;
    return renderHalfTxtInput(title, name, value, onChange, req, disabled, summary, onBlur, errorMessage);
};

export const renderHalfTxtInput = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction,
    req?: boolean,
    disabled?: boolean,
    summary?: boolean,
    onBlur: any = emptyFunction,
    errorMessage?: string
): JSX.Element => (
    <div
        className={`${
            summary ? "halfish-sum-size" : "halfish-size"
        } d-flex flex-column justify-content-start align-items-start`}
    >
        <div className="payment-subtitle">
            <FormattedMessage id={title} /> {req && renReq()}
        </div>
        <input
            type="text"
            disabled={disabled ? disabled : false}
            className="custom-input-textfield halfish-size-input m-0"
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            onBlur={() => onBlur()}
        />
        {errorMessage && (
            <div className="error-message">
                <FormattedMessage id={errorMessage} />
            </div>
        )}
    </div>
);

export const renderHalfDateInput = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction,
    req?: boolean,
    summary?: boolean
): JSX.Element => (
    <div
        className={`${
            summary ? "halfish-sum-size" : "halfish-size"
        } d-flex flex-column justify-content-start align-items-start`}
    >
        <div className="payment-subtitle">
            <FormattedMessage id={title} /> {req && renReq()}
        </div>
        <DatePicker
            className="custom-input-textfield halfish-size-input filter-range"
            value={value ? moment(value) : undefined}
            placeholder={" "}
            onChange={(e) => onChange(name, e ? moment(e).format("YYYY-MM-DD") : "")}
        />
    </div>
);

export const renderHalfTxtSelec = (
    title: string,
    name: string,
    value: string | any,
    options: string | any,
    onChange: any = emptyFunction,
    req?: boolean,
    tip?: string,
    summary?: boolean
): JSX.Element => (
    <div
        className={`${
            summary ? "halfish-sum-size" : "halfish-size"
        } d-flex flex-column justify-content-start align-items-start`}
    >
        <div className="payment-subtitle d-flex">
            <FormattedMessage id={title} /> {req && renReq()}
            {tip ? renderToolTip(tip) : null}
        </div>
        <Select
            className="halfish-size-input filter-input full-H-input"
            value={value}
            onChange={(e) => onChange(name, e)}
        >
            {options.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderFullMultiSel = (
    title: string,
    name: string,
    value: string | any,
    options: string | any,
    onChange: any = emptyFunction,
    req?: boolean,
    tip?: string
): JSX.Element => (
    <div className="full-width d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle d-flex">
            <FormattedMessage id={title} /> {req && renReq()}
            {tip ? renderToolTip(tip) : null}
        </div>
        <Select mode="multiple" className="full-H-input" value={value} onChange={(e) => onChange(name, e)}>
            {options.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderMockMultiSel = (
    title: string,
    name: string,
    value: string | any,
    options: string | any,
    onChange: any = emptyFunction,
    openModal: any = emptyFunction,
    deselect: any = emptyFunction
): JSX.Element => (
    <div className="full-width mock-input d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle w-100 d-flex align-items-center justify-content-between mb-2">
            <FormattedMessage id={title} />
            <Button type="default" className="ml-4 text-on-white-background" onClick={openModal}>
                <FormattedMessage id="choose_products" />
            </Button>
        </div>
        <Select
            mode="multiple"
            className="full-H-input"
            value={value}
            onDeselect={(e: any) => deselect(e)}
            dropdownStyle={{ display: "none" }}
            onChange={(e) => onChange(name, e)}
        >
            {options.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

const isBool2 = (num: any) => (num === 1 ? true : false);
const switchNum2 = (num: any) => (num === 1 || num === true ? (num = 2) : (num = 1));

export const renderHalfSwitch = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction,
    summary?: boolean
): JSX.Element => (
    <div className={`${summary ? "" : "halfish-size"} d-flex justify-content-start align-items-center`}>
        <div className="payment-subtitle mr-3">
            <FormattedMessage id={title} />
        </div>
        <Switch checked={isBool2(value)} className="mb-1" onChange={() => onChange(name, switchNum2(value))} />
    </div>
);
