import { Button, Input, InputNumber, Select, Switch, Tooltip } from "antd";
import React, { useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { HiMenu, HiQuestionMarkCircle, HiX } from "react-icons/hi";
import useLanguageSelect from "../../../../../../hooks/useLanguageSelect";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { ColumnsType } from "antd/lib/table";
import helper, { formatNumberInput, formatNumberInputNegativeAllowed } from "../../../../../../util/helper";
import { AddEditOptionTypes } from "./useAddEditOption";
import locale from "../../../../../../locale";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/reducer";

interface AddEditOptionProps {
    dispatchAddEditOptionState: any;
    addEditOptionState: any;
    onSaveOption: any;
    option: string;
    lan: string;
}

function AddEditOption(props: AddEditOptionProps): JSX.Element {
    const intl = useIntl();
    const { dispatchAddEditOptionState, addEditOptionState } = props;
    const stableDispatchAddEditOptionState = useCallback(dispatchAddEditOptionState, [props]);
    const { lan, getInputLanguageSelect, getModalLanguageSelect } = useLanguageSelect("modal-language-select");
    const languages = useSelector(
        (state: RootState) => state?.store?.storeDisplayOptions?.product_display_languages ?? []
    );
    const t = (id: any) => intl.formatMessage({ id });
    const scrollableOptions = useRef<HTMLDivElement>(null);

    const getSelectAfter = (name: any) => {
        const options = [
            "size",
            "number",
            "option",
            "flavor",
            "cooking_style",
            "color",
            "model",
            "format",
            "width",
            "length",
            "weight",
            "material",
            "edition",
            "platform",
            "addon",
        ];

        const onChange = (value: any) => {
            languages.forEach((language: any) => {
                dispatchAddEditOptionState({
                    type: AddEditOptionTypes.OnChange,
                    payload: {
                        name,
                        value: locale?.getIntlMessages?.(language)?.[value],
                        isTranslated: true,
                        lan: language,
                    },
                });
            });
        };

        const selectOptions = options.map((option, i) => (
            <Select.Option value={option} key={i}>
                {locale?.getIntlMessages?.(lan)?.[option]}
            </Select.Option>
        ));

        return name === "nm" ? (
            <Select
                placeholder={intl.formatMessage({ id: "option" })}
                className="option-title-presets"
                // @ts-ignore
                value={null}
                onChange={onChange}
            >
                {selectOptions}
            </Select>
        ) : null;
    };

    const isMultiOptions = () => {
        return helper.isBool(addEditOptionState?.qty_input);
    };

    const getInputProps = (name: any, { isTranslated = false, valueType = "" } = {}) => ({
        value:
            valueType === "bool"
                ? helper.isBool(addEditOptionState?.[name])
                : isTranslated
                ? addEditOptionState?.[name ?? ""]?.[lan ?? ""]
                : addEditOptionState?.[name],
        checked: helper.isBool(addEditOptionState?.[name]),
        formatter: formatNumberInput,
        addonAfter: getSelectAfter(name),
        min: 0,
        max: 99,
        onChange: (e: any) => {
            const value = e?.target?.value ?? e;

            dispatchAddEditOptionState({
                type: AddEditOptionTypes.OnChange,
                payload: {
                    name,
                    value,
                    isTranslated,
                    lan,
                    valueType,
                },
            });
        },
    });

    const getTableInputProps = (name: any, index: number, record: any, isTranslated = false, max = 0) => ({
        value: isTranslated
            ? record?.[name ?? ""]?.[lan ?? ""]
            : name === "max"
            ? record?.[name]
                ? record?.[name]
                : 1
            : record?.[name],
        formatter: name === "pc" || name === "sq" ? formatNumberInputNegativeAllowed : (v: any) => v,
        max: max ? max : name === "sq" ? 99999 : 9999,
        onChange: (e: any) => {
            const value = e?.target?.value ?? e;
            dispatchAddEditOptionState({
                type: AddEditOptionTypes.OnChangeOption,
                payload: {
                    name,
                    value,
                    isTranslated,
                    lan,
                    index,
                },
            });
        },
    });

    const getTypeOptions = () =>
        ["drop_down", "radio", "checkbox", "multiple"].map((option) => (
            <Select.Option value={option} key={option}>
                {t(option)}
            </Select.Option>
        ));

    const getFormItem = ({ name, Component, props, desc }: any) => (
        <div className="option-item">
            {name ? (
                <div className="option-item-title">
                    {t(name)}
                    {desc ? (
                        <Tooltip placement="right" title={t(name + "_desc")}>
                            <HiQuestionMarkCircle />
                        </Tooltip>
                    ) : null}
                </div>
            ) : null}
            <div className="option-item-component">
                <Component {...props} />
            </div>
        </div>
    );

    const getFormBasics = () => (
        <div>
            {getInputLanguageSelect(
                getFormItem({
                    name: "option_title",
                    Component: Input,
                    desc: true,
                    props: {
                        ...getInputProps("nm", {
                            isTranslated: true,
                        }),
                    },
                })
            )}
            <div className="option-input-row">
                <div className="col-left">
                    {getFormItem({
                        name: "option_type",
                        Component: Select,
                        desc: true,
                        props: { ...getInputProps("type"), disabled: isMultiOptions(), children: getTypeOptions() },
                    })}
                </div>
                <div className="col-right">
                    {getFormItem({
                        name: "quantity_input",
                        Component: Switch,
                        desc: true,
                        props: { ...getInputProps("qty_input", { valueType: "qty_input" }) },
                    })}
                </div>
            </div>
            <div className="option-input-row">
                <div className="col-left">
                    {getFormItem({
                        name: "min_select",
                        Component: InputNumber,
                        desc: true,
                        props: { ...getInputProps("min") },
                    })}
                </div>
                <div className="col-right">
                    {getFormItem({ name: "max_select", Component: InputNumber, props: { ...getInputProps("max") } })}
                </div>
            </div>
        </div>
    );

    const getFooter = () => (
        <div className="options-footer">
            <Button
                size="middle"
                onClick={() => {
                    dispatchAddEditOptionState({ type: AddEditOptionTypes.AddOption });
                    setTimeout(() => {
                        if (scrollableOptions?.current) {
                            scrollableOptions.current.scrollIntoView();
                        }
                    }, 100);
                }}
            >
                <FormattedMessage id="add_option" />
            </Button>
        </div>
    );

    const getColumns = (): ColumnsType<any> => {
        const datas = [
            {
                render: (_: any, record: any, index: any) =>
                    getInputLanguageSelect(
                        getFormItem({ Component: Input, props: { ...getTableInputProps("nm", index, record, true) } }),
                        { right: true }
                    ),
            },
            {
                render: (_: any, record: any, index: any) =>
                    getFormItem({ Component: InputNumber, props: { ...getTableInputProps("pc", index, record) } }),
            },
            {
                render: (_: any, record: any, index: any) =>
                    getFormItem({ Component: InputNumber, props: { ...getTableInputProps("sq", index, record) } }),
            },
        ];
        if (isMultiOptions()) {
            datas.push(
                {
                    render: (_: any, record: any, index: any) =>
                        getFormItem({
                            Component: InputNumber,
                            props: { ...getTableInputProps("min", index, record, false, addEditOptionState.max) },
                        }),
                },
                {
                    render: (_: any, record: any, index: any) =>
                        getFormItem({
                            Component: InputNumber,
                            props: { ...getTableInputProps("max", index, record, false, addEditOptionState.max) },
                        }),
                }
            );
        }
        datas.push(
            {
                render: (_: any, record: any) => renderSwitch(record),
            },
            {
                render: (_: any, record: any) => renderButton(record),
            }
        );
        return datas;
    };

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        backgroundColor: isDragging ? "lightgreen" : "white",
        ...draggableStyle,
    });

    const onDragEnd = (result: DropResult) => {
        dispatchAddEditOptionState({
            type: AddEditOptionTypes.SwapOptionPostions,
            payload: result,
        });
    };

    const getHeaderData = () => {
        const datas = [
            { width: 25, label: " " }, //to reserver for dragable indicator
            { width: isMultiOptions() ? 475 : 500, label: "name" },
            { width: isMultiOptions() ? 100 : 166, label: "price", tooltip: true },
            { width: isMultiOptions() ? 100 : 166, label: "stock" },
        ];
        if (isMultiOptions()) {
            datas.push(
                {
                    width: 100,
                    label: "min",
                },
                {
                    width: 100,
                    label: "max",
                }
            );
        }
        datas.push(
            {
                width: 76,
                label: "enabled",
            },
            {
                width: 80,
                label: "actions",
            },
            {
                width: 16,
                label: " ", //to reserver for scroll bar
            }
        );
        return datas;
    };

    const renderSwitch = (record: any) => {
        return (
            <Switch
                checked={record.availability === 1}
                onChange={(checked) => {
                    const isChecked = checked ? 1 : 0;
                    dispatchAddEditOptionState({
                        type: AddEditOptionTypes.OnChangeOption,
                        payload: {
                            name: "availability",
                            value: isChecked,
                            isTranslated: false,
                            lan,
                            index: record.id - 1,
                        },
                    });
                }}
            />
        );
    };
    const renderButton = (record: any) => {
        return (
            <Button
                size="small"
                danger
                onClick={() => {
                    dispatchAddEditOptionState({
                        type: AddEditOptionTypes.RemoveOption,
                        payload: record.id,
                    });
                }}
            >
                <HiX className="mt-1" />
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className="option-table-header">
                {getHeaderData().map((head, index) => (
                    <div key={`option-table-header-data-${index}`} style={{ width: head.width }}>
                        {t(head.label)}
                        {head.tooltip ? (
                            <Tooltip placement="right" title={t(`option_table_header_${head.label}_desc`)}>
                                <HiQuestionMarkCircle />
                            </Tooltip>
                        ) : null}
                    </div>
                ))}
            </div>
        );
    };

    const renderOpts = (option: any, i: number) => (
        <Draggable key={option?.id} draggableId={option?.id?.toString?.()} index={i}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    className="option-row"
                >
                    <div style={{ width: 25 }} className="draggable-indicator">
                        <HiMenu />
                    </div>
                    {getColumns().map((column, j) => (
                        <div
                            key={j}
                            style={{
                                width: _.get(getHeaderData(), `${j + 1}.width`, 0),
                            }}
                            className={"option-col"}
                        >
                            {column?.render?.("", option, i)}
                        </div>
                    ))}
                </div>
            )}
        </Draggable>
    );

    const renderOptions = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            {renderHeader()}
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div className="option-table-values">
                        <div ref={provided?.innerRef}>
                            {addEditOptionState.opts.map(renderOpts)}
                            {provided.placeholder}
                        </div>
                        <div ref={scrollableOptions} />
                    </div>
                )}
            </Droppable>
            {getFooter()}
        </DragDropContext>
    );

    useEffect(() => {
        stableDispatchAddEditOptionState({
            type: AddEditOptionTypes.Init,
            payload: props.option,
        });
    }, [props.option, stableDispatchAddEditOptionState]);

    return (
        <div style={{ position: "relative" }}>
            <div className="modal-lan-select">{getModalLanguageSelect()}</div>
            {getFormBasics()}
            {renderOptions()}
            <div className="option-modal-footer">
                <Button
                    type="primary"
                    onClick={() => {
                        props.onSaveOption(addEditOptionState, () => {
                            dispatchAddEditOptionState({
                                type: AddEditOptionTypes.Init,
                                payload: addEditOptionState,
                            });
                        });
                    }}
                >
                    <FormattedMessage id="ok" />
                </Button>
            </div>
        </div>
    );
}

export default AddEditOption;
