const StoreDisplayOptions = `
    general_info.lan_lst,       product_display_languages,          false;
    dflt_lan,                   default_language,                   false;     
    store_flg,                  store_display_style,                false,  57;
    store_flg,                  product_display_format,             false,  12;
    store_flg,                  product_detail_display_format,      false,  46;
    store_flg,                  pagination,                         false,  47;
    store_flg,                  show_product_place_holder_image,    false,  45;
    store_flg,                  use_land,                           false,  51;
    store_flg,                  hot_category_enabled,               false,  41;
    store_flg,                  onsale_category_enabled,            false,  42;
    store_flg,                  store_qty_display,                  false,  75;
    store_flg,                  distance_unit,                      false,  70;
    store_flg,                  sold_qty_display,                   false,  84;
`;

export default StoreDisplayOptions;
