import React, { useEffect, useState, useCallback } from "react";
import { Button } from "antd";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "../../../../app/reducer";
import { getStoreDetailsParts, updateStoreDetailsParts, updateStoreDetails } from "../../../../services/store";
import { setAutoAcceptOrderState } from "../../../../slices/store";
import { isBool, num2Binary, strToCharArray } from "../../.././../util/helper";
import { ORDER_TYPES, DUE_TIME_SETTINGS } from "./constants";
import ObjectModel from "./../../../../util/models";
import StoreAutoAcceptOrder from "../../../../util/models/store-auto-accept-order";
import "./auto-accept-order.scss";

import { EnableAutoAcceptOrder } from "./enable-auto-accept-order-section";
import { OrderStatus } from "./order-status";
import { PaymentType } from "./payment-type";
import { OrderTime } from "./order-time";
import { OrderType } from "./order-type";
import { DueTimeSetting } from "./due-time-setting";
import { DueTime } from "./due-time";

const breadcrumb = {
    routes: [
        {
            path: "dashboard",
            breadcrumbName: "nav_dashboard",
        },
        {
            path: "/settings",
            breadcrumbName: "settings_overview",
        },
        {
            path: "/settings/autoAcceptOrder",
            breadcrumbName: "auto_accept_order_settings",
        },
    ],
};

const App = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const autoAcceptOrder = useSelector((state: RootState) => state.store?.autoAcceptOrder);
    const records = useSelector((state: RootState) => state.store?.records);
    const [orderType, setOrderType] = useState<any>({});
    const [dueTimeSetting, setDueTimeSetting] = useState<any>({});

    const setState = (name: string, value: any) => dispatch(setAutoAcceptOrderState({ name, value }));

    const setOrderTypes = useCallback((num: any) => {
        const newOrderTypes: any = {};
        const binType = num2Binary(Number(num), Object.keys(ORDER_TYPES).length);
        const binTypeArray = strToCharArray(binType);
        Object.keys(ORDER_TYPES).forEach((type: any, i) => {
            // get binary string of type
            newOrderTypes[type] = isBool(binTypeArray[i]);
        });
        setOrderType(newOrderTypes);
    }, []);

    const setDueTimeSettings = useCallback((num: any) => {
        const newDueTimeSettings: any = {};
        const binType = num2Binary(Number(num), Object.keys(DUE_TIME_SETTINGS).length);
        const binTypeArray = strToCharArray(binType);
        Object.keys(DUE_TIME_SETTINGS).forEach((setting: any, i) => {
            // get binary string of setting
            newDueTimeSettings[setting] = isBool(binTypeArray[i]);
        });
        setDueTimeSetting(newDueTimeSettings);
    }, []);

    useEffect(() => {
        dispatch(getStoreDetailsParts({ page: "auto_accept_order_setting" }));
    }, [dispatch]);

    useEffect(() => {
        setOrderTypes(autoAcceptOrder.order_type);
    }, [setOrderTypes, autoAcceptOrder.order_type]);

    useEffect(() => {
        setDueTimeSettings(autoAcceptOrder.due_time_setting);
    }, [setDueTimeSettings, autoAcceptOrder.due_time_setting]);

    const saveChanges = () => {
        dispatch(updateStoreDetails(new ObjectModel(StoreAutoAcceptOrder).convertToPostData(autoAcceptOrder, records)));
        dispatch(
            updateStoreDetailsParts({
                auto_accept_order_setting: autoAcceptOrder,
            })
        );
    };

    const renderSave = () => (
        <div className="setting-actions" style={{ marginRight: "10px" }}>
            <Button type="primary" size="large" onClick={saveChanges} className="save-button-handler">
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    const isEnabled = () => Number(autoAcceptOrder?.auto_accept_order_enabled) === 1;

    return !_.isEmpty(autoAcceptOrder) ? (
        <Wrapper helmet={{ title: "auto_accept_order_settings" }} breadcrumb={breadcrumb}>
            <div className="auto-accept-order-container">
                <EnableAutoAcceptOrder checked={isEnabled()} setState={setState} />
                {isEnabled() ? (
                    <>
                        <OrderType orderType={orderType} setState={setState} />
                        <PaymentType paymentType={autoAcceptOrder?.payment_type} setState={setState} />
                        <OrderTime orderTime={autoAcceptOrder?.order_time} setState={setState} />
                        <DueTimeSetting
                            dueTimeSetting={dueTimeSetting}
                            currSetting={Number(autoAcceptOrder?.due_time_setting)}
                            setState={setState}
                        />
                        {dueTimeSetting.SPECIFY ? (
                            <DueTime
                                setState={setState}
                                currHours={Math.floor(Number(_.get(autoAcceptOrder, "due_time_value", 0)) / 60)}
                                currMins={Number(_.get(autoAcceptOrder, "due_time_value", 0)) % 60}
                            />
                        ) : null}
                        <OrderStatus orderStatus={autoAcceptOrder?.order_status} setState={setState} />
                    </>
                ) : null}
                {renderSave()}
            </div>
        </Wrapper>
    ) : null;
};

export default App;
