import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState: any = {
    menus: [],
    modalOpen: false,
    currMenu: {},
    edit: false,
};

const slice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setModal: (state, { payload }) => {
            payload.menu ? (state.currMenu = payload.menu) : (state.currMenu = {});
            state.modalOpen = payload.open;
            state.edit = payload.edit;
        },
        setCurrMenu: (state, { payload }) => {
            const currMenuCpy = { ...state.currMenu };
            _.set(currMenuCpy, payload.name, payload.value);
            state.currMenu = currMenuCpy;
        },
        getMenuSuccess: (state, { payload }) => {
            state.menus = [...payload.menus];
        },
    },
});

export const { setCurrMenu, getMenuSuccess, setModal } = slice.actions;

export const menu = slice.reducer;
