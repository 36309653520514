import { createSlice } from "@reduxjs/toolkit";

interface Permissions {
    _id: number;
    name: any;
    level: string;
    access_flags: string;
    group_id: string;
}

interface PermissionsState {
    permissions: Permissions[];
}

const initialState: PermissionsState = {
    permissions: [],
};

const slice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        setPermissionState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getPermissionsSuccess: (state, { payload }) => {
            state.permissions = payload;
        },
    },
});

export const { setPermissionState, getPermissionsSuccess } = slice.actions;

export const permission = slice.reducer;
