import { Button, Input, Popover, Modal, Switch } from "antd";
import _ from "lodash";
import dH from "../helper";
import { MdClose } from "react-icons/md";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { BiSliderAlt } from "react-icons/bi";
import { RiCouponFill } from "react-icons/ri";
import { FiShare } from "react-icons/fi";
import config from "../../../../../../config";
import { AiOutlineClose } from "react-icons/ai";
import DiscountSummary from "./discount-summary";
import { getProducts } from "../../../../../../services/products";
import { getCategories } from "../../../../../../services/categories";
import { RootState } from "../../../../../../app/reducer";
import { getCoupons, deleteCoupon, updateCoupon } from "../../../../../../services/discounts";
import { updateEnabled } from "../../../../../../slices/discounts";
import useLanguageSelect from "../../../../../../hooks/useLanguageSelect";
import { isBool, formatCurrency } from "../../../../../../util/helper";
import {
    setFilter,
    setCreate,
    resetCurrentDisc,
    clearCurrDisc,
    setSelectedProds,
    setSelProdOptions,
    clearCurrSelProds,
    setFilters,
    updateFilter,
    setShowSummary,
} from "../../../../../../slices/discounts";
import FilterModal from "./filter-modal";
import CreateModal from "./create-modal";
import { getStoreDetails } from "../../../../../../services/store";

const categoriesPageSelector = (state: RootState) => ({
    products: state?.products?.products,
    language: state?.setting?.lan,
    categories: state?.categories?.categories,
});

const DiscountCodesTab = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { lan } = useLanguageSelect();
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const QRCode = require("qrcode.react");
    const showSummary = useSelector((state: RootState) => state.discounts?.showSummary) ?? false;
    const store_id = useSelector((state: RootState) => state.store?.records?.g_id);
    const adminName = useSelector((state: RootState) => state.user?.firstName);
    const g_url = useSelector((state: RootState) => state.store?.records?.general_info?.url);
    const storeName = useSelector((state: RootState) => state.store?.records?.general_info?.store_nm);
    const discountCodes = useSelector((state: RootState) => state.discounts?.storeDiscountCodes);
    const currCode = useSelector((state: RootState) => state.discounts?.currentDisc);
    const { products, language, categories } = useSelector(categoriesPageSelector);
    const discounts = useSelector((state: RootState) => state.discounts);
    const storeCurrency =
        useSelector((state: RootState) => state?.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const subject = `${_.get(storeName, lan, "")} ${intl.formatMessage({ id: "discount" })}`;
    const filters = _.get(discounts, "filters", {});
    const discType = _.get(discounts, "filters.discountType", undefined);
    const discStart = _.get(discounts, "filters.startDiscount", NaN);
    const discExpire = _.get(discounts, "filters.expireDiscount", NaN);
    const price_from = _.get(discounts, "filters.price_from", undefined);
    const price_to = _.get(discounts, "filters.price_to", undefined);
    const product_name = _.get(discounts, "filters.product_name", undefined);
    const filterOpen = _.get(discounts, "filter");
    const createOpen = _.get(discounts, "create");
    const reload = _.get(discounts, "reload", 42);
    const [QRO, setQRO] = useState<boolean>();
    const [currDiscountCode, setCurrDiscountCode] = useState();
    const qr_URL = `${config.H5_URL}store/${store_id}?coupon=`;
    const social_url: string = _.isEmpty(g_url ?? []) ? `${config.H5_URL}store/${store_id}` : g_url;

    useEffect(() => {
        dispatch(getStoreDetails());
        dispatch(getProducts({ paging: { limit: 1000 } }));
        dispatch(getCategories());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCoupons({ price_from, price_to, product_name, product_type: dH.COUPON }));
    }, [dispatch, price_from, price_to, product_name, reload]);

    const onSearch = (e: any) => {
        dispatch(getCoupons({ price_from, price_to, product_name: e, product_type: dH.COUPON }));
    };

    const deleteCode = (code: any) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "delete_discount_code" }),
            content: intl.formatMessage({ id: "are_you_sure" }),
            onOk() {
                dispatch(deleteCoupon(code.product_id));
            },
            okType: "danger",
        });
    };

    const onCreateNewCode = () => {
        dispatch(setCreate({ value: true }));
        dispatch(clearCurrSelProds());
    };

    const onCreateCancel = () => {
        dispatch(clearCurrDisc());
        dispatch(setCreate({ value: false }));
    };

    const renderFilterBar = () => (
        <div className="codes-search-bar d-flex">
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <Input.Search
                            className="codes-search-input"
                            placeholder={intl.formatMessage({ id: "search_dot" })}
                            allowClear
                            size="large"
                            onSearch={(e: any) => onSearch(e)}
                        />
                        <Button
                            className="filter-button"
                            icon={<BiSliderAlt size={20} />}
                            onClick={() => dispatch(setFilter({ value: true }))}
                        >
                            <div className="icon-button-text d-inline">
                                <FormattedMessage id="filters" />
                            </div>
                        </Button>
                    </div>
                    {renderFilters()}
                </div>
            </div>
            <Button type="primary" className="create-button" onClick={() => onCreateNewCode()}>
                <FormattedMessage id="create_disc_code" />
            </Button>
        </div>
    );

    const renderFilterBox = (name: string, value: any, filter: string, t: boolean) => (
        <div className="filter-item-holder d-flex align-items-center mr-2">
            {t ? (
                <div className="mr-1">
                    <FormattedMessage id={name} />: <FormattedMessage id={value} />
                </div>
            ) : (
                <div className="mr-1">
                    <FormattedMessage id={name} />: {value}
                </div>
            )}
            <AiOutlineClose style={{ marginTop: "4px" }} size={15} onClick={() => removeFilter(filter)} />
        </div>
    );

    const removeFilter = (filter: any) => {
        dispatch(setFilters({ name: filter, value: undefined }));
        dispatch(updateFilter());
    };

    const renderFilters = () => {
        const trans = ["discount_type", "date_start", "date_expire", "discount_from", "discount_to"];
        const filts: any = [];
        Object.keys(filters).forEach((key, index: any) => {
            if (Number(index) === 0) {
                filters[key] && filts.push({ filter: key, value: filters[key], name: trans[index], t: true });
            } else if (Number(index) < 5) {
                filters[key] && filts.push({ filter: key, value: filters[key], name: trans[index], t: false });
            }
        });
        return (
            <div className="filtersRow my-2 d-flex">
                {filts.map((filt: any) => renderFilterBox(filt.name, filt.value, filt.filter, filt.t))}
            </div>
        );
    };

    const renderFilterModal = () => (
        <Modal
            visible={filterOpen}
            title={intl.formatMessage({ id: "filter_discount_codes" })}
            width="570px"
            footer={null}
            onCancel={() => dispatch(setFilter({ value: false }))}
        >
            <FilterModal />
        </Modal>
    );

    const renderCreateModal = () => {
        const title = !_.isEmpty(currCode) ? "edit_discount_code" : "create_discount_code";
        return (
            <Modal
                visible={createOpen}
                title={intl.formatMessage({ id: title })}
                width="1000px"
                footer={null}
                onCancel={onCreateCancel}
            >
                <CreateModal />
            </Modal>
        );
    };

    const getItemsList = (type: number, items: any) => {
        let itemList = "";
        if (!_.isEmpty(items)) {
            if (type === dH.PRODUCT) {
                items.forEach((item: any) => {
                    products.forEach((prod) => {
                        if (String(item) === prod.sku) {
                            itemList += ` ${_.get(prod, `name.${lan}`)}, `;
                        }
                    });
                });
                itemList = itemList.slice(0, -1);
            } else {
                items.forEach((item: any) => {
                    categories.forEach((c) => {
                        if (Number(item) === c.category_id) {
                            itemList += ` ${_.get(c, `name.${lan}`)}, `;
                        }
                    });
                });
                itemList = itemList.slice(0, -1);
            }
        }
        return itemList;
    };

    const getDiscountMessage = (code: any) => {
        let message = "";
        const val = code.discount?.amount;
        const disc = `${String(code.type) === "by_fixed" ? "$" : " "} ${val} ${
            String(code.type) === "by_fixed" ? "" : "%"
        } `;
        const wouldShare = intl.formatMessage({ id: "would_share" });
        const off = intl.formatMessage({ id: "off_discount_coupon" });
        const store = intl.formatMessage({ id: "may_store" });
        const type = String(code.type) === "all" ? 0 : String(code.type) === "category_ids" ? 2 : 1;
        const items = type === 0 ? [] : getItemsList(type, code?.list);
        message = `${adminName} ${wouldShare} ${disc} ${off} ${code.code}.${store} `;
        type !== 0 ? (message += `: ${items} `) : (message += " ");
        message += ` @${_.get(storeName, lan, "")} : ${social_url} `;
        return message.replace(/&/gi, "%26");
    };

    const share = (code: any) => (
        <div className="d-flex flex-column justofy-content-start align-items-start">
            <FacebookShareButton url={social_url} quote={getDiscountMessage(code)}>
                <p className="popover-item">
                    <FormattedMessage id="share_facebook" />
                </p>
            </FacebookShareButton>
            <a
                target="_blank"
                href={`mailto:?subject=${subject}&body=${getDiscountMessage(code)}`}
                rel="noopener noreferrer"
            >
                <p className="popover-item">
                    <FormattedMessage id="share_email" />
                </p>
            </a>
            <TwitterShareButton
                url={social_url}
                title={`${_.get(storeName, lan, "")} ${intl.formatMessage({ id: "discount" })} `}
                via={getDiscountMessage(code)}
            >
                <p className="popover-item">
                    <FormattedMessage id="share_twitter" />
                </p>
            </TwitterShareButton>
            <p className="popover-item" onClick={() => onQrCode(code)}>
                <FormattedMessage id="share_qrcode" />
            </p>
        </div>
    );

    const onQrCode = (code: any) => {
        setCurrDiscountCode(code?.code);
        setQRO(true);
    };

    const renderGeneratedQR = (
        <Modal
            visible={QRO}
            title={intl.formatMessage({ id: "store_qr_code" })}
            width="300px"
            footer={null}
            onCancel={() => setQRO(false)}
        >
            <div className="qr-code">
                <QRCode value={`${qr_URL}${currDiscountCode}`} size={200} renderAs="svg" />
            </div>
        </Modal>
    );

    const showCode = (code: any) => {
        if (discType && discType !== code.type) {
            return false;
        }
        if (discStart && discStart > code.from_date) {
            return false;
        }
        if (discExpire && discExpire < code.to_date) {
            return false;
        }
        return true;
    };

    const compareNames = (a: any, b: any) => {
        if (typeof _.get(a, `name.${lan} `, "z") === "string" && typeof _.get(b, `name.${lan} `, "z") === "string") {
            const nameA = _.get(a, `name.${lan} `, "z").toUpperCase();
            const nameB = _.get(b, `name.${lan} `, "z").toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        return 0;
    };

    const allFiltered = () => {
        let allFiltered = true;
        if (Array.isArray(discountCodes)) {
            discountCodes.map((code: any) => showCode(code) && (allFiltered = false));
        }
        return allFiltered;
    };

    const updateStatus = (code: any) => {
        dispatch(
            updateEnabled({
                product_id: code.product_id,
                status: Number(code.status) === dH.ENABLED ? dH.DISABLED : dH.ENABLED,
            })
        );

        dispatch(
            updateCoupon({
                add: false,
                coupon: {
                    product_id: code.product_id,
                    status: Number(code.status) === dH.ENABLED ? dH.DISABLED : dH.ENABLED,
                },
            })
        );
    };

    const openDiscountSummary = (code: any) => {
        dispatch(resetCurrentDisc({ ...code }));
        const skus = _.get(code, "apply_to_products", []);
        if (Array.isArray(skus) && !_.isEmpty(skus)) {
            const prds =
                products
                    .filter((c) => skus.find((sku) => String(sku.sku) === c.sku))
                    .map((c) => ({ ...c, name: c.name[language ?? ""] })) ?? [];
            const options = prds.map((prd) => prd.sku) ?? [];
            dispatch(setSelProdOptions([...options]));
            dispatch(setSelectedProds([...prds]));
        }
        dispatch(setShowSummary(true));
    };

    const getCodeInfo = (code: any) => {
        const { type, per_customer } = code;
        const amount = _.get(code, "price", 0);
        const condType = _.get(code, "condition.type", "all");
        let min = _.get(code, "condition.min_amt", undefined);
        const off = intl.formatMessage({ id: "off_l" });
        let cond =
            String(type) === "cart_fixed"
                ? "entire_cart"
                : String(condType) === "sku"
                ? "select_products"
                : String(condType) === "all"
                ? "any_product"
                : "select_product_categories";
        const value =
            String(type) === "by_percent" ? `${Math.round(amount)}% ` : formatCurrency(amount, storeCurrency ?? "CAD");
        const limit = per_customer ? `&bull; ${per_customer} ${intl.formatMessage({ id: "use_per_cust" })} ` : "";
        min = min
            ? `&bull; ${intl.formatMessage({ id: "with_min_purchase" })} ${formatCurrency(
                  min,
                  storeCurrency ?? "CAD"
              )} `
            : "";
        cond = intl.formatMessage({ id: cond });
        return (
            <div
                className="code-table-sub"
                dangerouslySetInnerHTML={{ __html: `${value} ${off} ${cond} ${min} ${limit} ` }}
            />
        );
    };

    const renderRows = () => {
        const sortedCodes = _.cloneDeep(discountCodes);
        if (Array.isArray(sortedCodes)) {
            sortedCodes.sort(compareNames);
        }
        return Array.isArray(sortedCodes) && sortedCodes.length > 0 && !allFiltered()
            ? sortedCodes.map((code: any, index: any) => {
                  return showCode(code) ? (
                      <div className="code-row-container d-flex" key={index}>
                          <div className="name-info-col d-flex flex-column" onClick={() => openDiscountSummary(code)}>
                              <div className="code-table-tex">{_.get(code, `name.${lan}`, "")}</div>
                              <FormattedMessage id="code" />: {code.code}
                              {getCodeInfo(code)}
                          </div>
                          <div className="code-row-actions">
                              <div className="used-enabled-col">
                                  <Switch checked={isBool(code.status)} onChange={() => updateStatus(code)} />
                              </div>
                              <div className="used-code-col">
                                  {code.used} <FormattedMessage id="used" />
                              </div>
                              <div className="save-code-col">
                                  <Popover content={share(code)}>
                                      <FiShare size={22} color="#3898c8" />
                                  </Popover>
                              </div>
                              <div className="options-code-col" onClick={() => deleteCode(code)}>
                                  <MdClose size={25} color="#3898c8" />
                              </div>
                          </div>
                      </div>
                  ) : null;
              })
            : renderEmptySet;
    };

    const renderEmptySet = (
        <div className="emptySet">
            <RiCouponFill size={80} color="#e1e0e0" />
            <div className="empty-text-holder">
                <div className="empty-head mb-2">
                    <FormattedMessage id="no_discount_codes" />
                </div>
                <div className="empty-body">
                    <FormattedMessage id="create_new_code_option" />
                </div>
            </div>
        </div>
    );

    const renderHeader = (
        <React.Fragment>
            {Array.isArray(discountCodes) && discountCodes.length > 0 ? (
                <div className="diCode-header">
                    <div className="name-info-col">
                        <FormattedMessage id="name" />
                    </div>
                    <div className="used-code-col"></div>
                </div>
            ) : null}
        </React.Fragment>
    );

    const renderDiscountCodes = (
        <div className="white-single-block codes-container flex-column">
            {renderFilterBar()}
            {renderHeader}
            {renderRows()}
            {renderFilterModal()}
            {renderCreateModal()}
            {renderGeneratedQR}
        </div>
    );

    return showSummary ? <DiscountSummary /> : renderDiscountCodes;
};

export default DiscountCodesTab;
