const StoreDisplayOptions = `
    general_info.tips.is_enabled,   tips_enabled,               false;  
    general_info.tips.limit,        tips_order_limit,           false;

    general_info.tips.dflt_pct[0],  default_pct_delivery,       false;
    general_info.tips.dflt_pct[1],  default_pct_in_store,       false;
    general_info.tips.dflt_pct[2],  default_pct_pickup,         false;

    general_info.tips.dflt_amt[0],  default_amt_delivery,       false;
    general_info.tips.dflt_amt[1],  default_amt_in_store,       false;
    general_info.tips.dflt_amt[2],  default_amt_pickup,         false;

    general_info.tips.amt[0],       tips_amount_0,              false;
    general_info.tips.amt[1],       tips_amount_1,              false;
    general_info.tips.amt[2],       tips_amount_2,              false;
    general_info.tips.amt[3],       tips_amount_3,              false;
    general_info.tips.amt[4],       tips_amount_4,              false;

    general_info.tips.pct[0],       tips_percent_0,             false;
    general_info.tips.pct[1],       tips_percent_1,             false;
    general_info.tips.pct[2],       tips_percent_2,             false;
    general_info.tips.pct[3],       tips_percent_3,             false;
    general_info.tips.pct[4],       tips_percent_4,             false;
`
export default StoreDisplayOptions;