import { Tag } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Category } from "../../../../slices/categories";
import helper from "../../../../util/helper";
import _ from "lodash";
import qs from "querystring";
import config from "../../../../config";
import { ALL_TYPES, ALWAYS_AVAILABLE, ALL_STATUS } from "../../constants";

function FilterTags({ search, categories, lan }: { search: any, categories: Category[], lan: any }): JSX.Element {
    const history = useHistory();
    const intl = useIntl();
    return (
        <div className="filter-tags">
            {Object.keys(search)
                .filter((key) => key !== "page" && key !== "limit" && key !== "product_name")
                .map((key) => {
                    let value;

                    if (key === "category_id") {
                        value = helper.getTransString(
                            categories.find((cat) => Number(cat?.category_id) === Number(search[key]))?.name,
                            lan
                        );
                    }

                    if (key === "availability") {
                        // @ts-ignore
                        value = intl.formatMessage({ id: config?.AVAILABILITY_FILTER_OPTIONS?.[search[key]] ?? " " });
                        if (Number(search[key]) === ALWAYS_AVAILABLE) {
                            value = "hide";
                        }
                    }
                    if (key === "status") {
                        // @ts-ignore
                        value = intl.formatMessage({ id: config?.STATUS_FILTER_OPTIONS?.[search[key]] ?? " " });
                        if (Number(search[key]) === ALL_STATUS) {
                            value = "hide";
                        }
                    }

                    if (key === "product_type") {
                        // @ts-ignore
                        value = intl.formatMessage({ id: config?.TYPE_OPTIONS?.[search[key]] ?? " " });

                        if (Number(search[key]) === ALL_TYPES) {
                            value = "hide";
                        }
                    }

                    if (
                        key === "price_to" ||
                        key === "price_from" ||
                        key === "quantity_to" ||
                        key === "quantity_from"
                    ) {
                        value = intl.formatMessage({ id: key ?? " " }) + `: ${search[key]}`;
                    }

                    if (key === "product_sku") {
                        value = `${intl.formatMessage({ id: "SKU" })}: ${search[key]}`;
                    }

                    if (key === "barcode") {
                        value = `${intl.formatMessage({ id: "barcode_short" })}: ${search[key]}`;
                    }

                    return value !== "hide" ? (
                        <Tag
                            closable
                            key={key}
                            onClose={() => {
                                const newSearch = _.cloneDeep(search);

                                if (key === "price_to" || key === "price_from") {
                                    delete newSearch["price_to"];
                                    delete newSearch["price_from"];
                                } else if (key === "quantity_to" || key === "quantity_from") {
                                    delete newSearch["quantity_to"];
                                    delete newSearch["quantity_from"];
                                } else {
                                    delete newSearch[key];
                                }

                                history.push("/products?" + qs.stringify(newSearch));
                            }}
                        >
                            {value ?? intl.formatMessage({ id: key ?? " " })}
                        </Tag>
                    ) : null;
                })}
        </div>
    );
}

export default FilterTags;
