import React from "react";
import { FormattedMessage } from "react-intl";
import ImageUpload from "../../../../../../components/form/image-upload";

export const ImageUploadSection = (props: {
    storeImages: { store_qr_logo: string },
    user: any,
    uploadCallback: any,
    deleteCallback: any,
}): JSX.Element => {
    return (
        <div className="logo-area-container">
            <div className="general-section">
                <h1 className="ant-descriptions-title">
                    <FormattedMessage id="qr_logo" />
                </h1>
            </div>
            <div className="d-flex ta-left">
                <ImageUpload
                    currentImage={props.storeImages.store_qr_logo}
                    user={props.user}
                    type="store"
                    imageType="qrLogo"
                    uploadCallback={props.uploadCallback}
                    deleteCallback={props.deleteCallback}
                    imageExt="png"
                />
            </div>
        </div>
    );
};
