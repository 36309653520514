import React from "react";
import { injectIntl } from "react-intl";
import { InputNumber } from "antd";
import "./number-input.scss";
import cx from "classnames";

class BetterNumberInput extends React.Component {
    render() {
        const { prefix, suffix } = this.props;
        const propsCopy = {
            ...this.props,
            prefix: undefined
        }
        return (
            <span className="goopter-desktop-app-number-input">
                <span className="ant-input-affix-wrapper">
                    {prefix ? <span className="ant-input-prefix"> {prefix} </span> : ""}
                    <InputNumber
                        style={{ padding: 0, flexGrow: 1 }}
                        className={cx({
                            "ant-input": true,
                            "input-number-wrapper": true,
                        })}
                        {...propsCopy}
                    />
                    {suffix ? (
                        <span style={{ marginRight: 10 }} className="ant-input-suffix">
                            {suffix}
                        </span>
                    ) : null}
                </span>
            </span>
        );
    }
}

export default injectIntl(BetterNumberInput);
