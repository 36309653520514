import { Button, Form, InputNumber } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setReferralsState } from "../../../../slices/referrals";
import { RootState } from "../../../../app/reducer";
import { DEFAULT_DATE_FORMAT } from "../../../../app/constants";

function emptyFunction() {
    // does nothing
}

const selector = (state: RootState) => {
    return {
        transactions: state?.referrals?.customerReferrals,
        paging: state?.referrals?.customerReferralsPaging,
        searchConditions: state?.referrals?.customerReferralsSearchConditions,
        referrals: state?.referrals?.referrals,
    };
};

interface ReferralsFilterProps {
    closeModal: () => void;
    search: any;
}

const ReferralsFilter = (props: ReferralsFilterProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { searchConditions } = useSelector(selector);
    const [form] = Form.useForm();

    const onFinish = (data: any) => {
        const cloned: any = _.cloneDeep(data);

        _.each(cloned, (value, key) => {
            if (_.isUndefined(value) || value === "") {
                delete cloned[key];
            }
        });

        if (cloned["date_range"]) {
            cloned.s_date = cloned["date_range"][0].utc().format(DEFAULT_DATE_FORMAT);
            cloned.e_date = cloned["date_range"][1].utc().format(DEFAULT_DATE_FORMAT);
        }

        delete cloned["date_range"];

        dispatch(
            setReferralsState({
                customerReferralsSearchConditions: {
                    ref_code: searchConditions?.ref_code,
                    ...cloned,
                    page: 1,
                },
            })
        );

        props.closeModal();
    };

    const resetFilters = () => {
        dispatch(
            setReferralsState({
                customerReferralsSearchConditions: {
                    ref_code: searchConditions?.ref_code,
                    page: searchConditions?.page,
                    limit: searchConditions?.limit,
                },
            })
        );

        const setter: any = {};

        Object.keys(searchConditions).forEach((key) => {
            setter[key] = undefined;
        });

        form.setFieldsValue(setter);

        props.closeModal();
    };

    const resetFields = (arr: any) => {
        const setter: any = {};

        arr.forEach((key: any) => {
            setter[key] = undefined;
        });

        form.setFieldsValue(setter);
    };

    const clearButton = (callback = emptyFunction) => (
        <div className="clear-filter-btn-container">
            <Button type="link" className="clear-filter-btn" onClick={callback}>
                <FormattedMessage id="clear" />
            </Button>
        </div>
    );

    const renderOrder = (
        <>
            <FormattedMessage id="orders" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_num_ord">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_num_ord">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
            {clearButton(() => {
                resetFields(["min_num_ord", "max_num_ord"]);
            })}
        </>
    );

    const renderAmount = (
        <>
            <FormattedMessage id="amount" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_ref_ord_amt">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_ref_ord_amt">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
            {clearButton(() => {
                resetFields(["min_ref_ord_amt", "max_ref_ord_amt"]);
            })}
        </>
    );

    const renderPoints = (
        <>
            <FormattedMessage id="points" />
            <div className="d-flex w100" style={{ justifyContent: "stretch" }}>
                <Form.Item name="min_points_earned">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "min_placeholder",
                        })}
                    />
                </Form.Item>
                <hr className="dash" />
                <Form.Item name="max_points_earned">
                    <InputNumber
                        className="w100"
                        placeholder={intl.formatMessage({
                            id: "max_placeholder",
                        })}
                    />
                </Form.Item>
            </div>
            {clearButton(() => {
                resetFields(["min_points_earned", "max_points_earned"]);
            })}
        </>
    );

    useEffect(() => {
        form.setFieldsValue(searchConditions);
    }, [form, searchConditions]);

    return (
        <div className="referral-filter">
            <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish} initialValues={props.search}>
                {renderOrder}
                {renderAmount}
                {renderPoints}
                <div className="footer-filter">
                    <Button type="link" onClick={resetFilters}>
                        <FormattedMessage id="reset_filters" />
                    </Button>
                    <Button type="primary" htmlType="submit" className="save-button-handler">
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ReferralsFilter;
