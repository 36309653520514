import { Button, Collapse } from "antd";
import { RootState } from "app/reducer";
import React from "react";
import { BsPencilSquare } from "react-icons/bs";
import { injectIntl, useIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import CopyArea from "../../../components/copy-area";
import {
    clearOnActiveTask,
    setCurrentModalPage,
    setShouldModalOpen,
    setUpdateForm,
    TaskModalPages,
} from "../../../slices/delivery_request";
import { DUE_TIME_FORMAT } from "../constants";
import "../style.scss";
import { formatPrice, getTime } from "./helper";

const _COLLAPSE_SECTION_KEY = "collapse_header";
const TaskDetailHeader: React.FC<PropsFromRedux> = ({
    clearOnActiveTask,
    onActiveTask,
    setCurrentModalPage,
    setShouldModalOpen,
    setUpdateForm,
    shouldUpdateAddressBtnDisabled,
}) => {
    const intl = useIntl();

    const taskIDEditOnClick = () => {
        if (shouldUpdateAddressBtnDisabled) return;
        setUpdateForm({ key: "orderId", value: onActiveTask?.order_number });
        setCurrentModalPage(TaskModalPages.EDIT_ORDER_ID);
        setShouldModalOpen(true);
    };

    const renderTitleLine = () => {
        return (
            <div className="delivery-task-detail-title-container">
                {onActiveTask && (
                    <div className="delivery-task-detail-title-content">
                        <div className="title">
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>{`${intl.formatMessage({
                                id: "order_id",
                            })}${onActiveTask.order_number.substring(0, 4)}`}</span>
                            <div className="orderListDot" />
                            <span style={{ fontWeight: "bold", fontSize: "20px", color: "#3e91f7" }}>
                                {String(onActiveTask.order_number).substring(
                                    String(onActiveTask.order_number).length - 4
                                )}
                            </span>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <CopyArea
                                iconPosition="end"
                                copyData={onActiveTask?.order_number ? onActiveTask.order_number : " "}
                                messageId="order_id"
                                showAlways={true}
                            />
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <BsPencilSquare style={{ cursor: "pointer" }} onClick={taskIDEditOnClick} />
                        </div>
                    </div>
                )}
                <div className="delivery-task-detail-title-close-btn-container">
                    <Button
                        onClick={() => {
                            clearOnActiveTask();
                        }}
                    >
                        X
                    </Button>
                </div>
            </div>
        );
    };

    const renderCollapseHeader = () => {
        return (
            <div className="delivery-task-detail-header-container">
                <div>
                    <span style={{ color: "#ababab", fontSize: "18px" }}>
                        {intl.formatMessage({ id: "create_on" })}
                    </span>
                    <span style={{ paddingLeft: "10px", color: "grey", fontSize: "18px" }}>
                        {getTime(onActiveTask?.create_dt ?? "", DUE_TIME_FORMAT)}
                    </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ color: "grey" }}>{intl.formatMessage({ id: "total_delivery_fee" })}$</span>
                    <span
                        style={{
                            color: "#3e91f7",
                            fontSize: "20px",
                            paddingLeft: "10px",
                            width: "80px",
                            textAlign: "right",
                        }}
                    >
                        {formatPrice(getTotal())}
                    </span>
                </div>
            </div>
        );
    };

    const getTotal = (): number => {
        if (onActiveTask?.delivery_fee && onActiveTask?.tax && onActiveTask?.tip) {
            return Math.round((onActiveTask?.delivery_fee + onActiveTask?.tax + onActiveTask?.tip) * 100) / 100;
        }

        return onActiveTask?.delivery_fee ? Math.round(onActiveTask?.delivery_fee * 100) / 100 : 0;
    };

    const renderCollapseSection = () => {
        const _PAYMENT_INFO_SESSION: { title: React.ReactNode; value: React.ReactNode }[] = [
            { title: "Delivery Fee", value: formatPrice(onActiveTask?.delivery_fee) },
            {
                title: "Tax(GST + PST)",
                value: formatPrice(onActiveTask?.tax ? Math.round(onActiveTask?.tax * 100) / 100 : 0),
            },
            { title: "Tips", value: formatPrice(onActiveTask?.tip ? onActiveTask?.tip : 0) },
            { title: "Total Fee", value: formatPrice(getTotal()) },
        ];

        return (
            <Collapse expandIconPosition="right" ghost>
                <Collapse.Panel key={_COLLAPSE_SECTION_KEY} header={renderCollapseHeader()} style={{ padding: "0px" }}>
                    <div className="delivery-task-collapse-content-container">
                        {_PAYMENT_INFO_SESSION.map((v) => (
                            <div className="row payment-info" key={v.title + "detailheader"}>
                                <span style={{ color: "grey", fontWeight: "bold" }}>{v.title}</span>
                                <span className="value">{v.value}</span>
                            </div>
                        ))}
                    </div>
                </Collapse.Panel>
            </Collapse>
        );
    };

    return (
        <div>
            {renderTitleLine()}
            {renderCollapseSection()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    const onActiveTask = state.deliveryRequests.onActiveTask;
    const shouldUpdateAddressBtnDisabled = false; //TODO
    return {
        onActiveTask,
        shouldUpdateAddressBtnDisabled,
    };
};

const mapDispatchToProps = {
    clearOnActiveTask,
    setCurrentModalPage,
    setShouldModalOpen,
    setUpdateForm,
};

const reduxConnenter = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnenter>;
export default injectIntl(reduxConnenter(TaskDetailHeader));
