import { Button, Image, Modal, Progress, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import _, { isNumber } from "lodash";
import { beforeUpload } from "pages/products/helper";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { HiCamera, HiX } from "react-icons/hi";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/reducer";
import config from "../../../../config";
import { runImageCacheHelper } from "../../../../services/image-cache";
import { editProduct, getProduct } from "../../../../services/products";
import { oauth } from "../../../../util/api";

const PROGRESS_INDICATOR_WIDTH = 64;

const selector = (state: RootState) => ({
    product: state?.products?.product,
    user: state?.user,
});

const getImage = (url: string) => {
    return config.IMG_PREFIX + url;
};

interface ProdImgProps {
    onSave: any;
    onChange?: any;
}

const ProductImages = (props: ProdImgProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageList, setImagelist] = useState<string[]>([]);
    const { product, user } = useSelector(selector);

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        userSelect: "none",
        backgroundColor: isDragging ? "lightgreen" : "white",
        marginRight: 16,
        ...draggableStyle,
    });

    const onDragEnd = (result: DropResult) => {
        setImagelist((prev) => {
            const list = _.cloneDeep(prev);
            const [removed] = list.splice(result.source?.index, 1);
            list.splice(result.destination?.index ?? 0, 0, removed);
            return list;
        });
    };

    // handleChange is only called when the user upload the image
    // it will not be called when the image is uploaded successfully
    const handleChange = ({ fileList }: UploadChangeParam) => {
        setFileList(fileList);
    };
    const uploadToImageCache = async ({ file, onSuccess }: any) => {
        await runImageCacheHelper({
            action: "clear_image_cache",
            user_id: user.id,
            imageType: "",
            type: "store",
        });

        await runImageCacheHelper({
            action: "add_image_cache",
            type: "product",
            imageType: "general",
            user_id: user.id,
            cloudinaryImagesCount: product?.images?.length ?? 0,
            name: file.uid,
            "fileToUpload[]": file,
        });

        const cache: any = await runImageCacheHelper({
            action: "load_image_cache",
            user_id: user.id,
            imageType: "general",
            type: "product",
        });

        const upload = await oauth("IMAGE_UPLOAD")({
            method: "POST",
            body: JSON.stringify({
                data: {
                    imgs: cache.map((img: string) => ({
                        url: img,
                        type: "product",
                    })),
                    pid: product?.product_id,
                },
            }),
        });

        dispatch(
            editProduct(
                {
                    product_id: product?.product_id,
                    images: [...imageList, ...Object.keys(upload?.records ?? {}).map((key) => upload.records[key])],
                },
                {},
                () => {
                    if (isNumber(product?.product_id) && product?.product_id !== undefined) {
                        dispatch(getProduct(product?.product_id));
                    }
                }
            )
        );

        onSuccess("ok");
    };

    const handleRemove = async (file: any) => {
        await runImageCacheHelper({
            action: "delete_image_cache",
            type: "product",
            imageType: "general",
            user_id: user.id,
            image_file: file.uid + ".jpeg",
        });
    };

    const onSubmit = async () => {
        dispatch(
            editProduct({
                product_id: product?.product_id,
                images: [...imageList],
            })
        );

        props.onSave();
    };

    const deleteImage = (image: string) => (e: any) => {
        e.stopPropagation();
        Modal.confirm({
            title: intl.formatMessage({ id: "confirm_delete" }),
            content: intl.formatMessage({ id: "are_you_sure_delete_image" }),
            onOk: () => {
                dispatch(
                    editProduct(
                        {
                            product_id: product?.product_id,
                            images: [...imageList.filter((img) => img !== image)],
                        },
                        {},
                        () => {
                            if (isNumber(product?.product_id) && product?.product_id !== undefined) {
                                dispatch(getProduct(product?.product_id));
                            }
                        }
                    )
                );
            },
        });
    };

    const getImg = (item: string, i: number) => {
        return (
            <Draggable key={item} draggableId={item} index={i}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-image"
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="item-image-container">
                            <div className="item-image-delete" onClick={deleteImage(item)}>
                                <HiX />
                            </div>
                            <Image src={getImage(item)} />
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    const renderUploading = (file: any) => {
        return file?.status === "uploading" ? (
            <div className="item-image-container">
                <div className="upload-progress-container">
                    <div className="upload-progress-text">
                        <FormattedMessage id="uploading" />
                    </div>
                    <div>
                        <Progress type="circle" percent={file.percent} width={PROGRESS_INDICATOR_WIDTH} />
                    </div>
                </div>
            </div>
        ) : null;
    };

    const getImages = () => {
        return (
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <div ref={provided.innerRef} style={{ display: "flex", padding: 16 }}>
                        {imageList.map(getImg)}
                        {fileList.map(renderUploading)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    const getUploader = () =>
        imageList.length >= 5 ? null : (
            <div className="item-images-uploader">
                <ImgCrop grid={true}>
                    <Upload.Dragger
                        customRequest={uploadToImageCache}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        fileList={fileList}
                        onRemove={handleRemove}
                        disabled={imageList.length >= 5}
                    >
                        <div>
                            <div>
                                <HiCamera />
                            </div>
                            <div>
                                <FormattedMessage id="add_image" />
                            </div>
                        </div>
                    </Upload.Dragger>
                </ImgCrop>
            </div>
        );

    useEffect(() => {
        if (product?.images) {
            setImagelist(product?.images);
        } else {
            setImagelist([]);
        }
    }, [product]);

    const { onChange } = props;
    useEffect(() => {
        if (onChange && imageList.length) {
            onChange(imageList);
        }
    }, [onChange, imageList]);

    return (
        <div>
            <div className="item-images-desc">
                <FormattedMessage id="item_images_desc" />
            </div>
            <div className="item-images-uploads">
                <DragDropContext onDragEnd={onDragEnd}>{getImages()}</DragDropContext>
                {getUploader()}
            </div>
            {props.onSave ? (
                <div className="footer-item-changes">
                    <Button type="primary" onClick={onSubmit}>
                        <FormattedMessage id="apply" />
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

export default ProductImages;
