import React, { useEffect } from "react";
import { Switch } from "antd";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { isBool } from "../../../../../util/helper";
import { renderTop, renderHalfTxtInput } from "./payment-functions";
import { getPreviouslySetCredentialCode, getPreviouslySetMerchantCode } from "../helper";

function App(): JSX.Element {
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const {
        alipay_merch_id,
        alipay_credential,
        alipay_enabled,
        alipay_exc_merch_id,
        alipay_exc_credential,
        alipay_exc_enabled,
    } = paymentOptions;

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, []);

    useEffect(() => {
        copyDataOfCertainBlankFields();
    }, [alipay_enabled]);

    const copyDataOfCertainBlankFields = () => {
        if (!alipay_enabled) {
            return;
        }

        if (!alipay_merch_id) {
            setState("alipay_merch_id", getPreviouslySetMerchantCode(paymentOptions));
        }
        if (!alipay_credential) {
            setState("alipay_credential", getPreviouslySetCredentialCode(paymentOptions));
        }
    };

    const setState = (name: string, value: any) => {
        dispatch(setPaymentState({ name, value }));
        dispatch(setPaymentState({ name: "alipay_is_from", value: 0 }));
        dispatch(setPaymentState({ name: "alipay_is_from_one", value: 1 }));
    };
    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop("alipay_account_setting", "alipay_account_tip", "alipay_enabled", alipay_enabled, switchChange)}
            {alipay_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("merchant_id", "alipay_merch_id", alipay_merch_id, setState, false, true)}
                    {renderHalfTxtInput("credential", "alipay_credential", alipay_credential, setState, false, true)}
                    <div className="payment-subtitle mb-3 w100 d-flex align-items-center">
                        <FormattedMessage id="alipay_exclusive_account" />
                        <Switch
                            className="ml-3"
                            checked={alipay_exc_enabled}
                            onChange={() => {
                                setState("alipay_exc_enabled", !alipay_exc_enabled);
                            }}
                        />
                    </div>
                    {alipay_exc_enabled
                        ? renderHalfTxtInput(
                              "merchant_id",
                              "alipay_exc_merch_id",
                              alipay_exc_merch_id,
                              setState,
                              false,
                              true
                          )
                        : null}
                    {alipay_exc_enabled
                        ? renderHalfTxtInput(
                              "credential",
                              "alipay_exc_credential",
                              alipay_exc_credential,
                              setState,
                              false,
                              true
                          )
                        : null}
                </div>
            ) : null}
        </div>
    );
}

export default App;
