import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../app/reducer";
import { oauth } from "../util/api";
import {
    getGiftcardAccountHistorySuccess,
    getGiftcardAccountsSuccess,
    getGiftcardsSuccess,
    setGiftcardState,
    setGiftcardLoading,
} from "../slices/giftcards";
import qs from "querystring";

function emptyFunction() {
    // does nothing
}

export const getGiftcards =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "GIFT_CARD_LIST",
                qs.stringify(params)
            )({
                method: "GET",
            });

            switch (response?.RC) {
                case 200:
                    dispatch(
                        getGiftcardsSuccess({
                            giftcards: response?.records,
                            oldSearch: params,
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const getGiftcardAccounts =
    (params?: Record<string, any>, callback = emptyFunction) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        dispatch(setGiftcardLoading({ loading: true }));
        try {
            const response: any = await oauth("GIFT_CARD_ACCOUNTS")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    dispatch(setGiftcardState({ title: params?.product_name, currentId: params?.product_id }));
                    dispatch(getGiftcardAccountsSuccess(response));
                    callback();
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
        dispatch(setGiftcardLoading({ loading: false }));
    };

export const getGiftcardAccountHistory =
    (params?: Record<string, any>) =>
    async (dispatch: ThunkDispatch<RootState, void, AnyAction>): Promise<any> => {
        try {
            const response: any = await oauth(
                "GIFT_CARD_HISTORY",
                qs.stringify(params)
            )({
                method: "GET",
            });

            switch (response?.RC) {
                case 200:
                    dispatch(
                        getGiftcardAccountHistorySuccess({
                            records: response?.records,
                            total: response?.paging?.total,
                            historyRecordGiftcardAccountId: params?.giftcardaccount_id,
                        })
                    );
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };

export const manageGiftCard =
    (params?: Record<string, any>, callback = emptyFunction) =>
    async (): Promise<any> => {
        try {
            const response: any = await oauth("GIFT_CARD_MANAGEMENT")({
                method: "POST",
                body: JSON.stringify({ data: params }),
            });

            switch (response?.RC) {
                case 200:
                    callback();
                    break;
                default:
                    break;
            }
        } catch (e) {
            // Handle error
        }
    };
