import { useSelector } from "react-redux";
import { RootState } from "../app/reducer";
import h from "util/helper";

export enum PermissionTypes {
    Forbidden,
    ReadOnly,
    ReadWrite,
}

export enum PermissionFeatures {
    Dashboard, // 0
    OrderHistory,
    LiveOrder,
    ProductAvailability,
    StoreAvailability,
    SettingMenu, // 5
    SettingGeneralInfo,
    SettingPickupDelivery,
    SettingSelfServe,
    SettingHours,
    SettingDiscount, // 10
    SettingManagedStores,
    SettingOrderNotification,
    SettingPayment,
    SettingSurcharge,
    SettingItemAvailability, // 15
    SettingSwitchStore,
    CategoryManagement,
    ProductManagement,
    CouponManagement,
    GiftcardManagement, // 20
    Customer,
    Publish,
    ProductReview,
    CustomerReview,
    SalesReport, // 25
    AdminUsers,
    OrderExport,
    UserProfile,
    CreateStore,
    DisplaySetting, // 30
    SettingRolesAndPermissions,
    SettingTips,
    SettingNotification,
    StoreInfo,
    LocalDelivery, // 35
    OrderReview,
    PublicNotice,
    BusinessHours,
    ReferralPage,
    CreateOrder, // 40
    SettingTextNotification,
}

export function usePermission(): any {
    const { user } = useSelector((state: RootState) => ({
        user: state?.user ?? {},
    }));

    const flags = user?.access?.split(",") ?? [];
    const getFlag = (index: number) => (h.isSuperUser(user) ? "2" : flags?.[index]?.split(":")?.[0] ?? "0");
    // const getFlag = (index: any) => "2";// For development
    const hasAccess = (index: number) => {
        return (
            getFlag(index) === PermissionTypes.ReadOnly.toString() ||
            getFlag(index) === PermissionTypes.ReadWrite.toString() ||
            h.isSuperUser(user)
        );
    };

    return {
        getFlag,
        hasAccess,
    };
}
