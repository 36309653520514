export const downloadXLSX = (args: Record<string,any>) : Record<string,any> | any => {
    let filename = args?.filename || "export";

    let blob;
    if (args?.blob) {
        blob = args?.blob;
    }

    if (!blob) {
        return;
    }

    filename = `${filename}.xlsx`;

    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
