import React, { useEffect, useCallback } from "react";
import h from "../helper";
import { useIntl } from "react-intl";
import { Prompt } from "react-router-dom";
import { isBool } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "../../../../../components/form";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderTop, renderHalfTxtInput, renderHalfMultiSel, renderHalfTxtSelec } from "./payment-functions";

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const {
        paypal_merch_login,
        paypal_merch_name,
        paypal_merch_phone,
        paypal_partner,
        paypal_api_pass,
        paypal_api_user,
        paypal_card_types,
        paypal_enabled,
        paypal_x_type,
    } = paymentOptions;

    const payAction: SelectOption[] = [{ value: "authorize", label: intl.formatMessage({ id: "authorization" }) }];

    const partnerOptions: SelectOption[] = [
        { value: "PayPalCA", label: "PayPalCA" },
        { value: "PayPal", label: "PayPal" },
    ];

    const cardTypes: SelectOption[] = [
        { value: "VI", label: "Visa" },
        { value: "MC", label: "Mastercard" },
        { value: "AE", label: "American Express" },
        { value: "MC", label: "Mastercard" },
        { value: "DI", label: "Discover" },
        { value: "SM", label: "Switch/Maestro" },
    ];

    const setState = useCallback((name: string, value: any) => dispatch(setPaymentState({ name, value })), [dispatch]);

    useEffect(() => {
        if (!paypal_partner) {
            setState("paypal_partner", "PayPalCA");
        }
    }, [paypal_partner, setState]);

    const switchChange = (name: string, value: any) => dispatch(setPaymentState({ name, value: isBool(!value) }));

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            <Prompt
                when={h.checkRequiredFilled(paymentOptions, "paypal_account_setting")}
                message={intl.formatMessage({ id: "payment_option_disabled_unless_required" })}
            />
            {renderTop(
                "paypal_account_setting",
                "paypal_payment_pro_tip",
                "paypal_enabled",
                paypal_enabled,
                switchChange
            )}
            {paypal_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("merchant_name", "paypal_merch_name", paypal_merch_name, setState, false, true)}
                    {renderHalfTxtInput(
                        "merchant_contact_number",
                        "paypal_merch_phone",
                        paypal_merch_phone,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtSelec("partner", "paypal_partner", paypal_partner, partnerOptions, setState, true)}
                    {renderHalfTxtInput(
                        "merchant_login",
                        "paypal_merch_login",
                        paypal_merch_login,
                        setState,
                        false,
                        true
                    )}
                    {renderHalfTxtInput("api_username", "paypal_api_user", paypal_api_user, setState, false, true)}
                    {renderHalfTxtInput("api_password", "paypal_api_pass", paypal_api_pass, setState, true, true)}
                    {renderHalfTxtSelec("payment_action", "paypal_x_type", paypal_x_type, payAction, setState, true)}
                    {renderHalfMultiSel(
                        "allowed_credit_card_types",
                        "paypal_card_types",
                        paypal_card_types,
                        cardTypes,
                        setState,
                        true
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default App;
